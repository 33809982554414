import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { showAgeRestriction } from "../../../actions/ageRestriction";
import AlbumTileBackground from "./AlbumTileBackground";
import SafeSearchOverlay
  from "../../../components/galleryComponents/SafeSearchOverlay/SafeSearchOverlay";
import { isSafeSearchEnabled } from "../../../selectors/account"
import isMobile from "../../../components/Responsive/isMobile"
import { useTheme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_SIZE_SMALL } from "../../../helpers/constants";

const AlbumTile = ({
  onAlbumClicked,
  albumItem,
  creatable,
  safeSearch,
  onAlbumEditClicked,
  account
}) => {
  const isOnMobile = isMobile();
  const { palette } = useTheme();
  const {
    title,
    totalImages,
    hasNudity,
    banner,
    icon,
    collectionAvgPrice,
    images
  } = albumItem || {};

  let nickName;
  let avatar;
  if (albumItem) {
    const { pikchaUser: artist } = albumItem;
    nickName = artist?.nickName;
    avatar = artist?.avatar;
  }

  const tile = useRef(null);
  const [hoverState, setHoverState] = useState(false);
  const [lowestPrice, setLowestPrice] = useState();
  const [itemWidth, setItemWidth] = useState(0);
  const handleResize = () => {
    const { width } = tile.current ? window.getComputedStyle(tile.current) : {};
    const widthValue = parseFloat(width);
    setItemWidth(widthValue);
  };
  useEffect(() => {
    handleResize();
  }, [tile.current, hoverState]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseOver = () => {
    setHoverState(true);
  };
  const handleMouseLeave = () => {
    setHoverState(false);
  };
  const onClicked = () => {
    onAlbumClicked(albumItem);
  };
  const onEditClicked = (event) => {
    event.stopPropagation();
    onAlbumEditClicked(albumItem);
  };

  useEffect(() => {
    let lowestPrice = images[0].setPrice;
    images.map((img) => {
      if(img.setPrice < lowestPrice){
        lowestPrice = img.setPrice
      }
    })
    setLowestPrice(lowestPrice);
  }, [images])

  return (
    <div
      ref={tile}
      style={{
        cursor: "pointer",
        width: "100%",
        height: !isOnMobile && itemWidth,
        backgroundColor: '#FFF',
        display: "flex",
        overflow: "hidden",
        border: '1px solid #DBE0E4',
        position: "relative",
        marginLeft: isOnMobile && '1.5em',
      }}
      onClick={creatable ? onClicked : !hasNudity ? onClicked : onClicked}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {hoverState && (
        <div
          style={{
            position: "absolute",
            borderRadius: 6,
            width: itemWidth,
            height: itemWidth,
            zIndex: 10,
            backgroundColor: palette.text.disabled,
            display: "flex",
          }}
        >
          {creatable && (
            <div
              style={{
                padding: "0.5em",
                borderRadius: 6,
                marginLeft: "auto",
                marginRight: "0.5em",
                marginTop: "0.5em",
                height: "fit-content",
                backgroundColor: palette.grey.lightest,
              }}
              onClick={onEditClicked}
            >
              <EditIcon
                style={{
                  ...ICON_SIZE_SMALL,
                  color: palette.text.primary,
                }}
              />
            </div>
          )}
        </div>
      )}
      {!creatable && hasNudity && safeSearch && (
        <SafeSearchOverlay
          onClick={onClicked}
        />
      )}
      <AlbumTileBackground
        nickName={nickName}
        avatar={avatar}
        title={title}
        totalImages={totalImages}
        hasNudity={hasNudity}
        creatable={creatable}
        safeSearch={safeSearch}
        banner={banner}
        icon={icon}
        collectionAvgPrice={collectionAvgPrice}
        lowestPrice={lowestPrice}
        targetCurrency={account?.user?.preferredCurrency}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  safeSearch: isSafeSearchEnabled(state),
  account: state.accountReducer
})

export default connect(mapStateToProps, {
  showAgeRestriction
})(AlbumTile);
