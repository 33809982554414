import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  colors
} from "@pikcha/typography";
import { isMobile } from "../../env_utils/device_screen";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from "@material-ui/core";
import Gesture from "../../assets/images/gesture_black.svg";
import UploadIcon from "../../assets/images/file_upload_black.svg";
import { setSvgColor } from "../../components/SignatureCanvas/utils/getViewBoxCropData";
import EditSignatureModal
  from "../../containers/modals/UploadFlowModal/EditSignatureModal";
import axios from "axios";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { handleSignatureTab } from "../../actions/signature";
import { getCropData } from "../../components/SignatureCanvas/utils/getViewBoxCropData";
import outline from '../../assets/images/uploadArt/outline.svg';
import filled from '../../assets/images/uploadArt/filled.svg';
import uploadArt from '../../assets/images/uploadArt/uploadArt.png';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { PikchaIcon, PikchaLoader } from '../../components/baseComponents';

const useStyles = makeStyles(() => ({
  img: {
    width: "50%",
    height: "50%"
  },
}));

const UploadModalToolTip = withStyles({
  tooltip: {
    background: "rgba(0, 0, 0, 0.85)",
    padding: '3em',
    maxWidth: '400px',
    width: '100%'
  }
})(Tooltip);

const Step1 = ({
  handleNextStep,
  hasSignature,
  signatureData,
  setHasSignature,
  setSignatureData,
  account,
  handleSignatureTab,
  setAlert,
  signatureBackup,
  setSignatureBackup,
  setAlreadySigned
}) => {
  const classes = useStyles();
  const svgWrapper = useRef(null);
  const hiddenInputRef = useRef(null);
  let fileReaderRef;
  const [showEditSignatureModal, setShowEditSignatureModal] = useState(false)
  const [modalSignature, setModalSignature] = useState([]);
  const [signaturePending, setSignaturePending] = useState(false);
  const [clearSig, setClearSig] = useState(false);

  const handleClick = (value) => {
    handleSignatureTab(value);
    setShowEditSignatureModal(true);
  }

  const handleCurrentSignature = useCallback((state) => {
    setModalSignature(state);
  }, []);

  const handleSignatureData = React.useCallback((data) => {
    if (data.org) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(data.org, "text/xml");
      const parsedSvg = doc.getElementsByTagName("svg")[0];
      if (parsedSvg && parsedSvg.style) {
        parsedSvg.style.maxWidth = "100%";
        parsedSvg.style.maxHeight = "100%";
      }
      setSignatureData(parsedSvg?.outerHTML);
      setModalSignature(parsedSvg?.outerHTML);
    } else {
      var parser = new DOMParser();
      var doc = parser.parseFromString(data, "text/xml");
      const parsedSvg = doc.getElementsByTagName("svg")[0];
      if (parsedSvg && parsedSvg.style) {
        parsedSvg.style.maxWidth = "100%";
        parsedSvg.style.maxHeight = "100%";
      }
      setSignatureData(parsedSvg?.outerHTML);
    }
  }, []);

  useLayoutEffect(() => {
    if (svgWrapper.current) {
      const signaturePreview = svgWrapper.current.firstChild;
      if (signaturePreview && signaturePreview.style) {
        signaturePreview.style.maxWidth = "100%";
        signaturePreview.style.maxHeight = "100%";
      }
    }
  }, [signatureData, svgWrapper.current]);

  const signaturePreview = useCallback(() => {
    return (
      <>
        <div
          className="mb-1-em"
          style={{
            width: "100%",
            height: 200,
            border: `1px solid ${colors.Neutral.grey30}`,
            borderRadius: "4px",
            padding: "1em"
          }}
        >
          {hasSignature && !signaturePending && (
            <div
              ref={svgWrapper}
              dangerouslySetInnerHTML={{
                __html: signatureData.indexOf("<svg") !== -1 ?
                  setSvgColor(signatureData, "#000000") :
                  ""
              }}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                height: "100%",
                width: "100%"
              }}
            />
          )}
          {!hasSignature && signaturePending && (
            <div 
              className="flex full-width justify-center full-height"
              style={{
                flexDirection: "column"
              }}
            >
              <PikchaLoader />
              <TypographyBody
                style={{
                  textAlign: "center"
                }}
              >
                Creating your signature 
              </TypographyBody>
            </div>
          )}
        </div>
        <TypographyButton
          className="mb-1-em"
          component={TYPOGRAPHY_BUTTON_TYPE.Outline}
          buttonStyle={{
            marginLeft: "auto"
          }}
          disabled={signaturePending}
          onClick={() => {
            setHasSignature(false)
          }}
        >
          Change Signature
        </TypographyButton>
      </>
    )
  }, [signatureData, svgWrapper.current, hasSignature, signaturePending]);

  const handleUseuploadedSignature = (signatureData) => {
    if (signatureData) {
      getCropData(signatureData, true, "#000", 12.5)
        .then(({ orgSigSVG }) => {
          handleSignatureData(orgSigSVG);
          setSignatureBackup(orgSigSVG);
        }).then(() => {
          setHasSignature(false);
          setShowEditSignatureModal(false);
          setHasSignature(true);
        })
    }
  }

  const onChangeImage = () => {
    hiddenInputRef.current.click();
  };

  const onUploadFileChange = (event) => {
    if (!event) {
      return;
    }

    event.preventDefault();
    let imageFilename;
    if (event.dataTransfer) {
      imageFilename = event.dataTransfer.files[0];
    } else if (event.target) {
      imageFilename = event.target.files[0];
    }

    let isAnSvgFile;
    if (
      event.target &&
      event.target.files &&
      event.target.files.length > 0 &&
      event.target.files[0].type.split("/")[1] === "svg+xml" || "png" || "jpeg" || "jpg"
    ) {
      isAnSvgFile = true;
    }

    if (!isAnSvgFile) {
      setAlert("Unsupported file type!");
      return;
    }
    if(event.target.files[0]?.type.split("/")[1] === "svg+xml"){
      setSignaturePending(true)
      fileReaderRef = new FileReader();
      fileReaderRef.addEventListener("load", async (fileEvent) => {
        const result = fileEvent.target.result;
        handleUseuploadedSignature(result);
      });
      fileReaderRef.readAsText(imageFilename);
      setSignaturePending(false);
    } else {
      setSignaturePending(true)
      const dto = new FormData();
      dto.append("signatureImageFile", imageFilename);
      axios.post("/api/Image/converttosvg", dto, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }).then((response) => {
        console.log(response);
        handleUseuploadedSignature(response.data);
        setSignaturePending(false);
      }).catch((err) => {
        console.log(err);
        setAlert("converting signature failed, please re-try later", "error")
        setSignaturePending(false);
      })
    }
  };
  const [iconVisible, setIconVisible] = useState(true);

  return (
    <>
      <EditSignatureModal
        open={showEditSignatureModal}
        setHasSignature={setHasSignature}
        signatureData={signatureData}
        setSignatureData={setSignatureData}
        initialStep={!(account.user || {}).hasUploaded &&
          !account.loadingUser && !hasSignature &&
          signatureData?.indexOf("<svg") === -1 && !clearSig
          ? 1
          : 2}
        setClearSig={setClearSig}
        showFirstUploadWarning={!(account.user || {}).hasUploaded &&
          !account.loadingUser && !hasSignature &&
          signatureData?.indexOf("<svg") === -1}
        handleModalClose={() => {
          setHasSignature(false);
          setShowEditSignatureModal(false);
        }}
        signatureBackup={signatureBackup}
        handleCurrentSignature={handleCurrentSignature}
        handleSignatureData={handleSignatureData}
        currentSignature={modalSignature}
        setAlert={setAlert}
        setSignatureBackup={setSignatureBackup}
        handleAlreadySignedClick={(value) => {
          setAlreadySigned(value);
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TypographySubtitle
          component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
        >
          Sign your artwork
        </TypographySubtitle>
        <UploadModalToolTip
          onOpen={() => setIconVisible(false)}
          onClose={() => setIconVisible(true)}
          interactive
          enterDelay={500}
          leaveDelay={0}
          placement="top-center"
          title={
            <>
              <TypographyBody
                style={{ color: '#fff', paddingBottom: '1em ' }}
                size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                Your digital signautre is imposed on
                your artworks.
              </TypographyBody>
              <TypographyBody
                style={{ color: '#fff', paddingBottom: '1em ' }}
                size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                See how you can sign below:
              </TypographyBody>
              <img style={{ width: '100%' }} src={uploadArt} alt="" />
            </>
          }
        >
          <span 
            className="flex"
            style={{
              alignItems: "center"
            }}
          >
            <PikchaIcon
              onMouseEnter={() => setIconVisible(false)}
              onMouseLeave={() => setIconVisible(true)}
              size="large"
              noMarginAuto
              icon={iconVisible ? <img src={outline} /> : <img src={filled} />}
              style={{
                height: "24px",
                width: "24px",
                fontSize: '24px',
                marginLeft: '0.25em'
              }}
            />
          </span>
        </UploadModalToolTip>
      </div>
      <TypographyBody
        style={{
          margin: "1em 0"
        }}
      >
        Create a digital signature to sign your artwork. This will appear on your prints.
      </TypographyBody>
      {!hasSignature && !signaturePending && (
        <>
          <div
            className="full-width flex"
            style={{
              padding: "1em",
              backgroundColor: colors.Neutral.white00,
              marginBottom: "1em",
              borderRadius: 4,
              border: "1px solid rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              alignItems: "center",
              minHeight: isMobile ? null : 105
            }}
            onClick={() => {
              handleClick(0);
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={Gesture}
              style={{
                marginRight: "1em",
                width: 56,
                height: 56,
                borderRadius: 28,
                border: "1px solid #E0E0E0",
                backgroundColor: colors.Neutral.white00
              }}
              classes={{
                img: classes.img
              }}
            />
            <div
              className="flex"
              style={{
                justifyContent: "start",
                flexDirection: "column"
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                color="neutral/ink90"
                style={{
                  lineHeight: null
                }}
              >
                Draw your signature
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                color="neutral/ink90"
              >
                Use our Smooth Signature pad.
              </TypographyBody>
            </div>
          </div>

          <div
            className="full-width flex"
            style={{
              padding: "1em",
              backgroundColor: colors.Neutral.white00,
              marginBottom: "1em",
              borderRadius: 4,
              border: "1px solid rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
              alignItems: "center",
              minHeight: isMobile ? null : 105
            }}
            onClick={() => {
              onChangeImage()
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src={UploadIcon}
              style={{
                marginRight: "1em",
                width: 56,
                height: 56,
                borderRadius: 28,
                border: "1px solid #E0E0E0",
                backgroundColor: colors.Neutral.white00
              }}
              classes={{
                img: classes.img
              }}
            />
            <div
              className="flex"
              style={{
                justifyContent: "start",
                flexDirection: "column"
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                color="neutral/ink90"
                style={{
                  lineHeight: null
                }}
              >
                Upload from your dekstop
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                color="neutral/ink90"
              >
                PNG, JPG, SVG accepted.
              </TypographyBody>
            </div>
          </div>
        </>
      )}
      {(hasSignature || signaturePending) && (
        signaturePreview()
      )}
      <TypographyButton
        buttonStyle={{ width: "100%" }}
        onClick={handleNextStep}
        disabled={signaturePending}
      >
        Next
      </TypographyButton>
      <input
        type="file"
        accept=".svg,.png,.jpeg,.jpg"
        onChange={onUploadFileChange}
        ref={hiddenInputRef}
        style={{
          display: "none",
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  account: state.accountReducer
})

export default connect(mapStateToProps, {
  handleSignatureTab,
  setAlert
})(Step1)