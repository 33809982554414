import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuList from "@material-ui/core/MenuList";

export const PikchaMenuList = withStyles((_) => ({
  root: {
    outline: 0,
    paddingTop: 0,
    paddingBottom: 0,
    //transition: 'all 0.2s ease-in-out',
  },
}))(MenuList);
