import React, {useEffect, useState} from "react";
import axios from "axios";
import Img from "react-image";
import GestureIcon from '@material-ui/icons/Gesture';

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useTheme, withStyles} from "@material-ui/core/styles";

import {
  PikchaCheckbox,
  PikchaDropdown,
  PikchaLoader,
  PikchaTextField,
  PriceField,
  TagsPicker,
} from "../../../components/baseComponents";
import LocationPicker from "../../../components/LocationPicker";
import DescriptionTextArea
  from "../../../components/DescriptionTextArea/DescriptionTextArea";
import LocationIcon from "../../../assets/images/location_on-black-24dp.svg";
import VisibilityOffRoundedIcon from "@material-ui/icons/VisibilityOffRounded";

import DateSeparatePicker
  from '../../../components/DateSeparatePicker/DateSeparatePicker';
import isTablet from "../../../components/Responsive/isTablet";
import * as Vibrant from "node-vibrant";
import {usePalette} from "color-thief-react";

const StyledLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey.light,
  },
}))(LinearProgress);

export default ({
                  imageDetails: {title, location, caption},
                  thumbnailImage,
                  signatureImage,
                  updateImageRights,
                  previewProgress,
                  handleImageDetails,
                  updateImageType,
                  onViewAllArtworkPrices,
                  futureDate,

                  uploadedImage,
                }) => {
  const isOnTablet = isTablet()

  const {typography, palette} = useTheme();
  const [vibrantColors, setVibrantColors] = useState([]);
  const [imagePrimaryColor, setImagePrimaryColor] = useState("");

  const [fields, setFields] = useState({
    allColours: [],
    allTags: [],
    allImageTypes: [],
    allCategories: [],
    selectedTags: [],
    selectedCategory: null,
    currentTag: null,
  });
  const [artworkType, setArtworkType] = useState({
    label: null,
    value: null,
  });
  const [category, setCategory] = useState([]);
  const [nudityCheck, setNudityCheck] = useState(false);
  const [imageRights, setImageRights] = useState(false);
  const [tagInput /*setTagInput*/] = useState();
  const [allTags, setAllTags] = useState([]);
  const [allColours, setAllColours] = useState([]);
  const [onHovering, setOnHovering] = useState(false);
  const [enterPrice, setEnterPrice] = useState(false);
  const [chooseColor, setChooseColor] = useState(false);
  const [DatePick, setDatePick] = useState("00-00-0000");
  const [selectedSignatureColor, setSelectedSignatureColor] = useState(
    "#000000"
  );

  useEffect(() => {
    const hexList = [];
    if (uploadedImage) {
      Vibrant.from(uploadedImage).getPalette((err, palette) => {
        const length = Object.keys(palette).length;
        for (let i = 0; i < length; i++) {
          const key = Object.keys(palette)[i];

          if (!palette[key]) {
            break;
          }

          const hexValue = palette[key].hex;
          hexList.push(hexValue);
          if (i === 0) {
            defaultColorSelected(hexValue);
          }
        }
      });
    }
    setVibrantColors(hexList);
  }, [uploadedImage]);

  const {
    data,
    // loading,
    // error
  } = usePalette(uploadedImage, (2), "hex");


  useEffect(() => {
    if (!data) {
      return;
    }

    const retrieveData = () => {
      axios({
        method: "GET",
        url: `api/image/prerequisites`,
        data: null,
      })
        .then((cat) => {
          const cats = [];
          const types = [];
          const colours = [];

          cat.data.categories.forEach((cat) => {
            cats.push({label: cat.name, value: cat});
          });
          cat.data.colours.forEach((colour) => {
            colours.push(colour.hexValue);
          });
          setAllColours(colours);
          cat.data.types.forEach((type) =>
            types.push({
              label: type.name,
              value: type,
            })
          );

          setFields({
            allCategories: cats,
            allTags: [],
            allImageTypes: types,
            allColours: cat.data.colours,
          });
          setArtworkType(cat.data.types[0].id);
          updateImageType(cat.data.types[0].id);

          if (data) {
            setImagePrimaryColor(data[0]);
            handleImageDetails("majorColour", data[0]);
          }
        });
    };
    
    retrieveData();

  }, [data]);

  const handleArtworkChange = (label, value) =>
    setArtworkType({
      label: label,
      value: value,
    });

  const handleTagAdd = (tag) => {
    if (tag.startsWith("#")) {
      tag = tag.substring(1);
    }
    setAllTags([...allTags, tag.trim()]);
    handleImageDetails("tags", [...allTags, tag.trim()]);
  };

  const handleDatePickerChange = (value) => {
    setDatePick(value);
    handleImageDetails("dateCreated", value);
  }

  const handleTagDelete = (tag, index) => {
    allTags.splice(index, 1);
    setAllTags(allTags);
    handleImageDetails("tags", allTags);
  };

  const handleImageRightsAcceptance = () => {
    setImageRights(!imageRights);
    updateImageRights(!imageRights);
  };
  const handleNudityCheck = () => {
    setNudityCheck(!nudityCheck);
    handleImageDetails("isNudity", !nudityCheck);
  };
  const defaultColorSelected = (color) => {
    setChooseColor(false);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          flexGrow: 0.9,
        }}
      >
        <div
          style={{
            marginLeft: 24,
            color: "grey",
            fontSize: typography.small,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
            paddingTop: "1em",
          }}
        >
          <span> Thumbnail </span>
        </div>
        <div
          className="ImagePreview-modalSection flex-stretch flex-grow-1 pt-1-em pb-1-em"
          style={{
            height: "100%",
            paddingTop: "unset",
            flexDirection: "column"
          }}
        >
          <div
            className="ImagePreview-container ProductItem-imageFrame p-1-em"
            style={{
              width: "100%",
              position: "relative"
            }}
          >
            {thumbnailImage ? (
              <Img
                className="UploadModalContainer-previewImage block"
                src={thumbnailImage}
                alt="Image Preview"
              />
            ) : (
              <div className="flex-column full-width full-height">
                <StyledLinearProgress
                  style={{
                    width: "100%",
                  }}
                  variant="determinate"
                  value={previewProgress}
                />
                <div
                  style={{
                    justifyContent: "center",
                  }}
                  className="flex-center flex-grow-1 Step2-pikchaLoader"
                >
                  <PikchaLoader/>
                </div>
              </div>
            )}
            {onHovering && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 2,
                  bottom: 5,
                  left: 15
                }}
              >
                <img
                  src={signatureImage}
                  alt="signature-image"
                  style={{
                    width: "auto",
                    height: "auto",
                    borderRadius: 10,
                    boxShadow: "5px 4px 15px 5px rgba(0, 0, 0, 0.25)"
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              fontSize: "0.75rem",
              fontWeight: 500,
              color: !onHovering ? palette.text.disabled : palette.text.primary,
              cursor: "pointer",
              backgroundColor: !onHovering ? null : palette.grey.lighter,
              width: 155,
              height: 20,
              borderRadius: 3
            }}
            onMouseEnter={() => {
              setOnHovering(true)
            }}
            onMouseLeave={() => {
              setOnHovering(false)
            }}
          >
            <GestureIcon
              style={{
                fontSize: "0.85rem",
                marginRight: "5px"
              }}
            />
            View Signing Sample
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: !isOnTablet ? 560 : 488,
          marginRight: "1em",
        }}
      >
        <div className="form-section ModalForm pt-1-em">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PikchaTextField
                label="Title"
                required
                type="text"
                value={title}
                onChange={({target: {value}}) =>
                  handleImageDetails("title", value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  width: 16,
                  height: 16,
                  backgroundImage: `url(${LocationIcon})`,
                  backgroundSize: "cover",
                  marginLeft: "3.4rem",
                  marginTop: -18,
                }}
              />
              <LocationPicker
                placeholder="New York, United States"
                label="Location"
                type="text"
                value={location}
                onChange={({target: {value}}) =>
                  handleImageDetails("location", value)
                }
              />
            </Grid>
          </Grid>
          <DateSeparatePicker
            handleDatePickerChange={(value) => {
              handleDatePickerChange(value)
            }}
            futureDate={futureDate}
          />
          <DescriptionTextArea
            rows={4}
            value={caption}
            handleImageDetails={handleImageDetails}
            maxChars={600}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PikchaDropdown
                label="Image type"
                onChange={({label, value}) => {
                  handleArtworkChange(label, value);
                  updateImageType(value);
                }}
                options={fields.allImageTypes}
                value={artworkType}
                placeholder=""
              />
            </Grid>
            <Grid item xs={12}>
              <PikchaDropdown
                label="Category (3)"
                onChange={(items) => {
                  if (items) {
                    if (items.length <= 3) {
                      setCategory(items);
                      let categoryIdList = [];
                      items.map((item) => {
                        categoryIdList.push(item.value.id);
                      })
                      handleImageDetails("categoryId", categoryIdList);
                    } else {
                      items.pop();
                      setCategory(items);
                      let categoryIdList = [];
                      items.map((item) => {
                        categoryIdList.push(item.value.id);
                      })
                      handleImageDetails("categoryId", categoryIdList);
                    }
                  } else {
                    setCategory([]);
                    handleImageDetails("categoryId", []);
                  }
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                options={fields.allCategories}
                value={category}
                placeholder=""
              />
              <div className="pt-1-em">
                <TagsPicker
                  value={allTags}
                  handleTagAdd={handleTagAdd}
                  handleTagDelete={handleTagDelete}
                />
              </div>
            </Grid>
          </Grid>
          <div className="flex-center">
            <div
              style={{
                width: "100%",
                marginTop: "-2.8em",
              }}
            >
              <PriceField
                required
                onValueChange={(values) =>
                  handleImageDetails("price", values.value)
                }
                style={{
                  marginRight: "1em",
                }}
              />
              <div
                onClick={onViewAllArtworkPrices}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 3,
                  borderRadius: 3,
                  background: enterPrice ? "rgba(0, 0, 238, 0.05)" : null
                }}
                onMouseEnter={() => {
                  setEnterPrice(true)
                }}
                onMouseLeave={() => {
                  setEnterPrice(false)
                }}
              >
                <span
                  className="material-icons"
                  style={{
                    fontSize: 16,
                    color: palette.link.main,
                    marginRight: 3
                  }}
                >
                  table_view
                </span>
                <span
                  style={{
                    fontWeight: typography.fontWeightSemiMedium,
                    fontSize: typography.small,
                    color: "#0000ee",
                  }}
                >
                  View artwork prices
                </span>
              </div>
            </div>
            <div className="ImageRights-checkboxContainer">
              <PikchaCheckbox
                value={nudityCheck}
                checked={nudityCheck}
                onChange={handleNudityCheck}
                label="This image contains Nudity, graphic violence or depictions of deceased persons"
                textIcon={<VisibilityOffRoundedIcon fontSize="inherit"/>}
              />
              <div style={{marginTop: "8px"}}>
                <PikchaCheckbox
                  value={imageRights}
                  checked={imageRights}
                  onChange={handleImageRightsAcceptance}
                  label="I own the rights to sell this image and I will exclusively
                sell this image on Pikcha and not distribute it anywhere else"
                  boldText
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
