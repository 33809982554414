import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import SignatureFirstUploadWarning from "../UploadModal/SignatureFirstUploadWarning";
import {
  PikchaModal,
} from "../../../components/baseComponents";
import {removeSignature} from "../../../actions/account";
import StepSignature from "./StepSignature";
import {getCropData} from "../../../components/SignatureCanvas/utils/getViewBoxCropData";
import SignatureWarning from './SignatureWarning';

const EditSignatureModal = ({
  handleAlreadySignedClick,
  setHasSignature,
  brushStroke,
  currentSignature,
  setAlert,
  signatureData,
  open,
  handleCurrentSignature,
  handleModalClose,
  selectedSignatureColor,
  handleSignatureData,
  removeSignature,
  setSignatureData,
  showFirstUploadWarning,
  account,
  signatureBackup,
  setSignatureBackup,
  initialStep=1,
  setClearSig
}) => {
  const [uploadStep, setUploadStep] = useState(initialStep);
  const [omitSignature, setOmitSignature] = useState(true);
  const [usingUploadedSignature, setUsingUploadedSignature] = useState(false);
  const [usingPreviousSignature, setUsingPreviousSignature] = useState(false);
  const [showNoneSignatureWarning, setShowNoneSignatureWarning] = useState(false);

  const handleUsingUploadedSignature = (state) => {
    setUsingUploadedSignature(state);
  }

  useEffect(() => {
    setUploadStep(initialStep);
  }, [initialStep])

  const currentStep = () => {
    return (
      <>
        {showFirstUploadWarning && uploadStep === 1 && (
          <SignatureFirstUploadWarning />
        )}
        {uploadStep === 2 && !showNoneSignatureWarning && (
          <StepSignature
            usingUploadedSignature={usingUploadedSignature}
            signatureData={signatureData}
            currentSignature={currentSignature}
            handleCurrentSignature={handleCurrentSignature}
            setSignatureData={setSignatureData}
            selectedSignatureColor={selectedSignatureColor}
            handleSignatureData={handleSignatureData}
            handleUsingUploadedSignature={handleUsingUploadedSignature}
            handleBackClick={() => {
              setUploadStep(1)
            }}
            handleUseSignature={() => {
              setUploadStep(1)
            }}
            setClearSig={setClearSig}
            setUsingPreviousSignature={setUsingPreviousSignature}
            hasUploaded={(account.user || {}).hasUploaded}
          />
        )}
        {showNoneSignatureWarning && (
          <SignatureWarning
            handleBack={()=>{
              setShowNoneSignatureWarning(true);
            }}
            handleClick={(value)=>{
              handleAlreadySignedClick(value);
              setShowNoneSignatureWarning(false);
              handleModalClose();
            }}
          />
        )}
      </>
    )
  }

  const handleClick = () => {
    setUploadStep(uploadStep + 1);
  }

  const handleUseSignature = () => {
    setShowNoneSignatureWarning(false);
    if (!usingUploadedSignature && currentSignature.length === 0 && !usingPreviousSignature) {
      return setAlert("Please provide your signature", "error");
    }

    if (signatureData) {
      getCropData(signatureData, true, selectedSignatureColor || "#000", brushStroke || 12.5)
        .then(({orgSigSVG}) => {
          handleSignatureData(orgSigSVG);
          setSignatureBackup(orgSigSVG);
        }).then(() => {
          handleModalClose();
          setHasSignature(true);
        })
    }
  }

  const handleOmitSignature = (state) => {
    setOmitSignature(state);
  }

  return (
    <PikchaModal
      className="upload-modal"
      fullWidth
      disableBackdropClick
      onClose={()=>{
        setShowNoneSignatureWarning(false);
        setSignatureData(signatureBackup);
        handleModalClose();
      }}
      open={open}
      minHeight="initial"
      paperStyle={{
        maxWidth: 1600,
        maxHeight: "90%",
        height: "100%",
      }}
      FooterActionProps={uploadStep === 1 ? {
        primary: {
          action: () => {
            handleClick()
          },
        },
      } : uploadStep === 2 && !showNoneSignatureWarning ? {
        primary: {
          action: () => {
            handleUseSignature()
          },
          text: "Use Signature",
          color: "black"
        },
        secondary: {
          action: () => {
            setShowNoneSignatureWarning(true);
            handleOmitSignature(true);
            removeSignature();
            setSignatureData("");
          },
          buttonType: "octonary",
          text: "No Signature"
        }
      }:null}
    >
      {currentStep()}
    </PikchaModal>
  )
}

const mapStateToProps = (state) => ({
  account: state.accountReducer
})

export default connect(mapStateToProps, {
  removeSignature
})(EditSignatureModal)