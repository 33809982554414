import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";
import "./PikchaReusableTab.css";

const useStyles = makeStyles(({ palette, typography }) => ({
  tab: {
    textTransform: "capitalize",
    fontWeight: typography.fontWeightMedium,
  },
  tabSelected: {
    color: palette.text.primary,
  },
}));

const PikchaReusableTab = withStyles(({ palette, typography }) => ({
  root: ({ sideDrawer: { payload } }) => ({
    fontSize: typography.standard,
    fontWeight: typography.fontWeightMedium,
    background: "transparent",
    color: palette.text.primary,
    border: 0,
    padding: 0,
    textTransform: "capitalize",
    minWidth: 0,
  }),
  selected: {
    fontSize: typography.large,
    color: palette.text.primary,
    fontWeight: typography.fontWeightBold,
    border: 0,
    padding: 0,
    background: "transparent",
    minWidth: 0,
  },
  wrapper: ({ isOnMobile })=>({
    flexDirection: "row",
    fontSize: isOnMobile ? typography.verysmall : typography.smaller,
  }),
  labelIcon: {
    "& > *:first-child": {
      marginRight: ".25rem",
      marginBottom: 3,
    },
  },
}))(({ sideDrawer, isOnMobile, ...rest }) => {
  const classes = useStyles();

  return (
    <Tab
      style={{
        width: 150,
      }}
      classes={{
        root: classes.tab,
        selected: classes.tabSelected,
      }}
      {...rest}
    />
)});

const mapStateToProps = (state) => ({
  sideDrawer: state.sideDrawerReducer,
});

export default connect(mapStateToProps)(PikchaReusableTab);
