import React, { useCallback, useEffect, useState } from "react";
import Refresh from "@material-ui/icons/Refresh";
import { PikchaDropdown } from "../baseComponents";
import { TypographyButton, TYPOGRAPHY_BUTTON_TYPE, colors } from "@pikcha/typography";
import "./DateSeparatePicker.css";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const DatePicker = ({
  label,
  className = "",
  selected,
  style = {},
  onDateUpdate,
  DateCreated,
  PickerLabel = "Date Art Created",
  ...rest
}) => {
  const [year, setYear] = useState(
    DateCreated && DateCreated.split("-")[2] !== "0000" ? {
      value: DateCreated.split("-")[2],
      label: DateCreated.split("-")[2],
    } : {
      value: "0000",
      label: "YYYY",
    });
  const [month, setMonth] = useState(
    DateCreated && DateCreated.split("-")[1] !== "00" ? {
      value: months[parseInt(DateCreated.split("-")[1]) - 1],
      label: months[parseInt(DateCreated.split("-")[1]) - 1],
    } : {
      value: "00",
      label: "MM",
    });
  const [day, setDay] = useState(
    DateCreated && DateCreated.split("-")[0] !== "00" ? {
      value: String(parseInt(DateCreated.split("-")[0])),
      label: String(parseInt(DateCreated.split("-")[0])),
    } : {
      value: "00",
      label: "DD",
    });

  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  const renderYears = () => {
    const years = [];
    for (let start = 1900; start <= new Date().getFullYear(); start++) {
      years.push({ value: start, label: start });
    }

    // Reverse the final array to show years in descending order.
    return years.reverse();
  };

  const renderMonths = () => {
    return months.map((_month, index) => {
      return { value: _month, label: _month };
    });
  };

  const renderDays = () => {
    const days = [];
    const [startDate, endDate] = [
      1,
      daysInMonth(months.indexOf(month.label) + 1, year.label),
    ];
    for (let start = startDate; start <= endDate; start++) {
      days.push({ value: start, label: start });
    }
    return days;
  };

  const onDateChanged = useCallback(() => {
    const selectedMonth = months.indexOf(month.value) + 1;

    const givenMonthTotalDays = daysInMonth(
      selectedMonth,
      year.value,
    ) || 31;

    // A year, month, and day are all selected.
    if (day.value !== "00" && day.value <= givenMonthTotalDays) {
      onDateUpdate(`${day.value < 10 ? "0" + day.value : day.value
        }-${(selectedMonth) < 10
          ? "0" + (selectedMonth)
          : selectedMonth
        }-${year.value}`);
      // The day is not provided, but a year and month are.
    } else if (month.value !== "00" && selectedMonth !== 0) {
      onDateUpdate(`00-${(selectedMonth) < 10
        ? "0" + (selectedMonth)
        : selectedMonth
        }-${year.value}`);
      // Only the year is selected
    } else if (year.value) {
      onDateUpdate(`00-00-${year.value}`);
    }
  }, [day.value, month.value, onDateUpdate, year.value]);

  useEffect(() => {
    year && month && day && onDateChanged();
  }, [year, month, day]);

  useEffect(() => {
    if (DateCreated) {
      const day = DateCreated.split("-")[0];
      const month = DateCreated.split("-")[1];
      const year = DateCreated.split("-")[2];
      if (year === "0000") {
        setYear({
          value: "0000",
          label: "YYYY",
        });
        setMonth({
          value: "00",
          label: "MM",
        });
        setDay({
          value: "00",
          label: "DD",
        });
      } else if (month === "00") {
        setYear({
          value: year,
          label: year,
        });
        setMonth({
          value: "00",
          label: "MM",
        });
        setDay({
          value: "00",
          label: "DD",
        });
      } else if (day === "00") {
        setYear({
          value: year,
          label: year,
        });
        setMonth({
          value: months[parseInt(month) - 1],
          label: months[parseInt(month) - 1],
        });
        setDay({
          value: "00",
          label: "DD",
        });
      } else {
        setYear({
          value: year,
          label: year,
        });
        setMonth({
          value: months[parseInt(month) - 1],
          label: months[parseInt(month) - 1],
        });
        setDay({
          value: String(parseInt(day)),
          label: String(parseInt(day)),
        });
      }
    }
  }, [DateCreated]);

  return (
    <div
      className={`${className} full-width`}
      {...rest}
    >
      <div
        className="flex flex-grow-1 justify-around"
        style={{ alignItems: "center" }}
      >
        <PikchaDropdown
          FieldLabelProps={{}}
          label={<div style={{ width: 300 }}>{PickerLabel}</div>}
          noFieldset
          noHelperClass
          options={renderYears()}
          value={year}
          searchable
          valueColourDisabled={year.label === "YYYY"}
          //fullWidth
          style={{
            display: "flex",
            flexGrow: 1,
            //minWidth: 100,
            marginRight: 10,
          }}
          placeholder="yyyy"
          onChange={(value) => {
            setYear(value);
          }}
        />
        <PikchaDropdown
          noFieldset
          noHelperClass
          options={renderMonths()}
          value={month}
          searchable
          disabled={year.label === "YYYY"}
          style={{
            display: "flex",
            flexGrow: 1,
            //minWidth: 80,
            marginRight: 10,
          }}
          placeholder="mm"
          onChange={(value) => {
            setMonth(value);
          }}
        />
        <PikchaDropdown
          noFieldset
          noHelperClass
          options={renderDays()}
          searchable
          value={day}
          disabled={month.label === "MM"}
          style={{
            flexGrow: 1,
            display: "flex",
            //minWidth: 70
          }}
          placeholder="dd"
          onChange={(value) => {
            setDay(value);
          }}
        />
        <div className="ml-half-em">
          <TypographyButton
            component={TYPOGRAPHY_BUTTON_TYPE.Outline}
            className="date-reset"
            buttonStyle={{
              padding: "10px 3px"
            }}
            onClick={(e) => {
              e.preventDefault();
              setYear({
                value: "0000",
                label: "YYYY",
              });
              setMonth({
                value: "00",
                label: "MM",
              });
              setDay({
                value: "00",
                label: "DD",
              });
            }}
            icon={
              <Refresh
                style={{
                  fontSize: "16px",
                  color: colors.Neutral.ink70
                }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
