import React from "react";
import { 
  TypographyBody, 
  TYPOGRAPHY_BODY_SIZE, 
  TypographySelector 
} from "@pikcha/typography";

export default ({ 
  fields, 
  handleFields, 
  hasABN, 
  handleHasABN, 
  disabled 
}) => {
  const handleChange = (e) => {
    if (e.target.value === "true") {
      handleHasABN(true);
      handleFields({ ...fields, _isAudGst: false });
    } else {
      handleHasABN(false);
    }
  };

  return (
    <>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
        style={{color : disabled && '#878D96'}}
      >
        Do you have an ABN?
      </TypographyBody>
      <TypographySelector
        disabled={disabled}
        aria-label="hasABN"
        selectorType="radio"
        handleChange={handleChange}
        value={hasABN}
        options={[
          {
            label: "Yes",
            value: true
          },
          {
            label: "No",
            value: false
          },
        ]}
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "1em",
          flexWrap: "wrap"
        }}
        optionStyle={{
          marginRight: "1em",
        }}
      />
    </>
  );
};
