import React from "react";
import { useTheme } from "@material-ui/core";

export default ({
  children,
  style,
  extraLarge,
  larger,
  smaller,
  lineHeightReduced,
  color,
  ...rest
}) => {
  const { palette, typography } = useTheme();

  return (
    <h2
      style={{
        paddingBottom: "0.5em",
        fontWeight: typography.fontWeightMedium,
        lineHeight: lineHeightReduced
          ? typography.h1.lineHeightReduced
          : typography.h1.lineHeight,
        letterSpacing: typography.h1.letterSpacing,
        color: color || palette.primary.dark,
        fontSize: typography.h2.fontSize,
        ...style,
      }}
      {...rest}
    >
      {children}
    </h2>
  );
};
