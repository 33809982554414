import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import AuthorizeService from "../../../auth/AuthorizeService";
import { payoutUrl } from "../../../selectors/settings";
import isMobile from "../../../components/Responsive/isMobile";
import isTablet from "../../../components/Responsive/isTablet";
import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import MySalesWrapper from "./MySalesWrapper/MySalesWrapper";

const DATA_LENGTH = 5;

const MySales = ({
  account,
  salesHistoryRoute,
  paymentHistoryRoute,
  payoutUrl,
}) => {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [historyData, setHistoryData] = useState();
  const [paymentHistoryData, setPaymentHistoryData] = useState();
  const [mySalesData, setMySalesData] = useState(null);
  const [salesSummaryData, setSalesSummaryData] = useState();
  const [paymentSummaryData, setPaymentSummaryData] = useState();
  const [showHistory, setShowHistory] = useState(salesHistoryRoute);
  const [showPaymentHistory, setShowPaymentHistory] = useState(
    paymentHistoryRoute
  );
  const [viewingSalesOfPayment, setViewingSalesOfPayment] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const retrieveData = async () => {
    setIsLoading(true);
    const token = await AuthorizeService.getAccessToken();
    axios
      .get(`api/image/${account.user.id}/mySales`, {
        params: {
          count: DATA_LENGTH,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const newData = resp.data;

        setData(newData);
        setIsLoading(false);

        const doesHaveMore = newData.length >= DATA_LENGTH;
        setHasMore(doesHaveMore);
      })
      .catch((e) => {
        setIsLoading(false);
      });
    axios
      .get(`api/image/${account.user.id}/mySalesData`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setMySalesData(resp.data);
      });
    axios
      .get(`api/image/${account.user.id}/MySalesHistory`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setHistoryData(resp.data);
      });
    axios
      .get(`api/image/${account.user.id}/mySalesSummary`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setSalesSummaryData(resp.data);
      });
    axios
      .get(`api/Payment/PaymentSummary/${account.user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        setPaymentSummaryData(resp.data);
      });
  };
  const retrieveMoreData = async () => {
    const token = await AuthorizeService.getAccessToken();
    axios
      .get(`api/image/${account.user.id}/mySales`, {
        params: {
          skip: data.length,
          count: DATA_LENGTH,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const newData = [...data, ...resp.data];
        setData(newData);

        const doesHaveMore = resp.data.length >= DATA_LENGTH;
        setHasMore(doesHaveMore);
      })
      .catch((e) => {
        setHasMore(false);
      });
  };

  useEffect(() => {
    retrieveData();
  }, []);
  useEffect(() => {
    const retrievePaymentHistory = async () => {
      const artistId = account.user.id;
      const token = await AuthorizeService.getAccessToken();
      axios
        .get(`${payoutUrl}/PaymentHistory`, {
          params: {
            artistId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (resp && resp.data) {
            setPaymentHistoryData(resp.data);
          }
        });
    };
    if (payoutUrl) {
      retrievePaymentHistory();
    }
  }, [payoutUrl]);
  const downloadHistory = () => {
    let rows = historyData.imageSaleHistory.map((item) => {
      return [
        item.orderDate,
        item.shipDate,
        item.orderNumber,
        item.title,
        item.quantity,
        item.retailPrice,
        item.pikchaFee,
        item.artistMargin,
        item.paymentStatus,
      ];
    });
    rows.unshift([
      "Order Date",
      "Ship Date",
      "Order Number",
      "Title",
      "Quantity",
      "Retail Price (USD)",
      "Pikcha Fee (USD)",
      "Artist Margin (USD)",
      "Payment Status",
    ]);
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    let a = document.createElement("a");
    a.href = encodedUri;
    a.download =
      "pikcha_sales_history_" + moment().format("YYYY-MM-DD") + ".csv";
    a.click();
  };

  const downloadPaymentHistory = () => {
    let rows = paymentHistoryData.payoutBase.payoutbase.map((item) => {
      return [
        moment(item.PaymentDate, "DD/MM/YYYY").format("D-M-YYYY"),
        item.PayoutMethod,
        item.SourceAmount,
        item.SourceCurrency,
        item.FxRate,
        item.TransferFee,
        item.SourceAmount,
        item.PayoutStatus,
        // item.pikchaFee,
        // item.artistMargin,
        // item.paymentStatus,
      ];
    });
    rows.unshift([
      "Payment Date",
      "Payout Method",
      "Amount (USD)",
      "Payout Currency",
      "FxRate",
      "Transfer Fee",
      "$ Payout",
      "Payment Status",
    ]);
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    let a = document.createElement("a");
    a.href = encodedUri;
    a.download =
      "pikcha_payment_history_" + moment().format("YYYY-MM-DD") + ".csv";
    a.click();
  };

  const viewSalesHistory = () => {
    window.history.pushState({}, "", "/account/sales/sales-history");
    setShowHistory(true);
  };
  const goBackFromSalesHistory = () => {
    if (!salesHistoryRoute) {
      window.history.back();
    } else {
      window.history.pushState({}, "", "/account/sales");
    }
    setShowHistory(false);
  };

  const viewPaymentHistory = () => {
    window.history.pushState({}, "", "/account/sales/payment-history");
    setShowPaymentHistory(true);
  };
  const goBackFromPaymentHistory = () => {
    if (!paymentHistoryRoute) {
      window.history.back();
    } else {
      window.history.pushState({}, "", "/account/sales");
    }
    setShowPaymentHistory(false);
  };

  const viewSalesOfPayment = (item) => {
    setViewingSalesOfPayment(item);
  };
  const goBackFromViewingSalesOfPayment = () => {
    setViewingSalesOfPayment(null);
  };

  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const isOnDesktopHD = isDesktopHD();

  return (
    <div
      style={{
        marginLeft: isOnTablet ? "2em" : isOnMobile ? '1em' : '2em',
        marginRight: isOnTablet ? "2em" : isOnMobile ? '1em' : '2em',
        marginTop: "1em",
        ...(isOnDesktopHD && {
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: 1032,
          width: "100%",
        }),
      }}
    >
      <MySalesWrapper
        showHistory={showHistory}
        showPaymentHistory={showPaymentHistory}
        isLoading={isLoading}
        data={data}
        mySalesData={mySalesData}
        salesSummaryData={salesSummaryData}
        viewSalesHistory={viewSalesHistory}
        goBackFromSalesHistory={goBackFromSalesHistory}
        viewPaymentHistory={viewPaymentHistory}
        goBackFromPaymentHistory={goBackFromPaymentHistory}
        retrieveMoreData={retrieveMoreData}
        hasMore={hasMore}
        downloadHistory={downloadHistory}
        downloadPaymentHistory={downloadPaymentHistory}
        historyData={historyData}
        paymentHistoryData={paymentHistoryData}
        paymentSummaryData={paymentSummaryData}
        viewSalesOfPayment={viewSalesOfPayment}
        viewingSalesOfPayment={viewingSalesOfPayment}
        goBackFromViewingSalesOfPayment={goBackFromViewingSalesOfPayment}
        user={account.user}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  payoutUrl: payoutUrl(state),
});

export default connect(mapStateToProps, {})(MySales);
