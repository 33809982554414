import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { PikchaLoader, PikchaCloseButton } from "../../../../components/baseComponents";
import Timer from "../../../../components/Timer/Timer";
import TimerOffIcon from '@material-ui/icons/TimerOff';
import PerfectScrollbar from "react-perfect-scrollbar";
import ExpiredItemsGrid from "./ExpiredItemsGrid";
import HeaderDesktop from "../../../../components/checkoutComponents/HeaderDesktop";
import successSvg from "../../../../assets/images/Cart/successSvg.svg";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TEXT,
  TYPOGRAPHY_BUTTON_TYPE
} from "@pikcha/typography";
import CartItems from "../../../../components/checkoutComponents/CartItems";
import { makeStyles } from "@material-ui/styles";
import isMobile from "../../../../components/Responsive/isMobile";
import { connect } from "react-redux";
import {
  notifyQueueStatus,
  removeProductFromShoppingCart,
} from "../../../../actions/shoppingCart";
import { removeTimer } from "../../../../actions/timer";
import { id } from "../../../../selectors/shoppingCart";
import {
  hideNotification,
} from "../../../../actions/notification";
import Popover from '@material-ui/core/Popover';


const useStyles = (isOnMobile) => makeStyles({
  container: {
    animation: "fadeIn",
    animationDuration: ".5s",
    position: "fixed",
    top: "4em",
    right: !isOnMobile && "5%",
    width: isOnMobile && '100%',
    borderRadius: "6px",
    background: "white",
    display: "flex",
    flexDirection: "column",
    maxHeight: '85vh',
    height: 'fit-content'
  }
})

const CartItemNotificationModal = ({
  image,
  onClose,
  isAnExpiryNotification,
  isAddToCartLoading,
  addBackToCart,
  handleTimerClose,
  shoppingItemId,
  viewQueue,
  notifyQueueStatus,
  removeTimer,
  removeProductFromShoppingCart,
  id,
  hideNotification,
  open
}) => {
  const isOnMobile = isMobile()
  const classes = useStyles(isOnMobile)()
  const theme = useTheme();
  const { palette, typography } = theme;
  // const [anchorEl, setAnchorEl] = useState(null);

  const deleteItem = async () => {
    await removeProductFromShoppingCart(id, shoppingItemId);
    notifyQueueStatus(id);
    removeTimer(shoppingItemId);
    onClose()
    handleTimerClose()
    hideNotification()
  };

  return (
    <>
      {<div className="notificationOverlay" />}
      <Popover
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        id='simple-popover'
        open={open}
        // anchorEl={anchorEl}
        onClose={onClose}
      >
        <div className={classes.container}>
          {!isAnExpiryNotification ?
            <>
              <HeaderDesktop />
              <div className="flex" style={{ padding: '0 1em 1em' }}>
                <img
                  src={successSvg}
                />
                <TypographyBody
                  style={{
                    display: 'inline-block',
                    paddingLeft: '1em'
                  }}
                >
                  Artwork added successfully!
                </TypographyBody>
              </div>
              <div style={{ margin: "1em" }}>
                <CartItems
                  imageRender={image.imageRender}
                  imageTitle={image.imageTitle}
                  userHandle={image.artist ? image.artist.userHandle : image.sellerFriendlyUrl}
                  nickName={image.artist ? image.artist.nickName : image.sellerFName}
                  type={image.type}
                  material={image.material}
                  size={image.size}
                  border={image.border}
                  frame={image.frame}
                  wrap={image.wrap}
                  galleryCardColor={image.galleryCardColor}
                  productNumber={image.expectedProductNumber}
                  totalEditions={image.totalEditions}
                  totalPrice={image?.totalPrice}
                  showDeleteButton={true}
                  deleteItem={deleteItem}
                  isPortrait={image.aspRatioX < image.aspRatioX}
                  backgroundContain={false}
                />
              </div>
              <hr />
              <div style={{ textAlign: 'center' }} >
                <div
                  style={{
                    margin: '1em 1em',
                  }}
                >
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                  >
                    Delivery and promotions calculated at checkout
                  </TypographyBody>
                </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                  <TypographyButton
                    onClick={viewQueue}
                    buttonText={TYPOGRAPHY_BUTTON_TEXT.Regular}
                    buttonStyle={{
                      width: '60%',
                    }}
                  >
                    View cart
                  </TypographyButton>
                  <TypographyButton
                    component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
                    buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
                    onClick={onClose}
                    buttonStyle={{
                      margin: '1em 0',
                    }}
                    textStyle={{
                      fontWeight: 700
                    }}
                  >
                    Continue shopping
                  </TypographyButton>
                </div>
              </div>
            </>
            :
            <>
              <div
                style={{
                  height: "2em",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <PikchaCloseButton
                  smallVariant
                  onClick={onClose}
                  style={{
                    zIndex: 9999,
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "2em",
                  flexDirection: isOnMobile && "column",
                  alignItems: isOnMobile && "center"
                }}
              >
                <Grid item xs={!isOnMobile && 6}>
                  {isAnExpiryNotification && (
                    <>
                      <ExpiredItemsGrid />
                    </>
                  )}
                </Grid>
                <Grid
                  style={{
                    marginLeft: !isOnMobile && "3em",
                  }}
                  item
                  xs={!isOnMobile ? 6 : 12}
                  justify="center"
                >
                  {isAnExpiryNotification && isAddToCartLoading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <PikchaLoader />
                    </div>
                  )}
                  {isAnExpiryNotification && !isAddToCartLoading && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: isOnMobile && "2em"
                        }}
                      >
                        <TimerOffIcon fontSize={'small'} />
                        <div
                          style={{
                            fontSize: typography.tiny,
                            fontWeight: typography.fontWeightMedium,
                            color: palette.text.primary,
                            margin: "0 5px"
                          }}
                        >
                          TIME TO CHECKOUT
                        </div>
                      </div>
                      <Timer overrideTimerClass="danger" />
                      <TypographyBody
                        style={{
                          margin: '2em 0 1em',
                          textAlign: 'center'
                        }}
                      >
                        Your time to checkout with these items has expired <br />
                        Do you want to add these items back to cart?
                      </TypographyBody>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <TypographyButton
                          fullWidth
                          component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                          onClick={(e) => addBackToCart()}
                        >
                          <div className="flex"
                            style={{
                              alignItems: "center",
                            }}>
                            <ShoppingCartIcon
                              style={{
                                width: 20,
                                height: 20
                              }}
                            />
                            &nbsp;Add To Cart
                          </div>
                        </TypographyButton>
                      </div>
                    </>
                  )}
                </Grid>
              </div>
            </>
          }

          {/* Adding false as condition for now, as the design is not confirmed whether to display this part. */}
          {false &&
            <div
              style={{
                backgroundColor: '#000000',
                padding: '1em',
                textAlign: 'center'
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                color='neutral/grey30'
              >
                LIMITED OFFER
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                color='neutral/white00'
                style={{
                  margin: '1em 0'
                }}
              >
                $20 USD off your first order when  you <br />
                sign-up to Pikcha.
              </TypographyBody>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TypographyButton
                  buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
                  buttonStyle={{
                    border: '1px solid white',
                    alignSelf: 'center',
                  }}
                >
                  Sign up now
                </TypographyButton>
              </div>
            </div>
          }
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state) => ({
  id: id(state)
})

export default connect(
  mapStateToProps,
  {
    notifyQueueStatus,
    removeTimer,
    removeProductFromShoppingCart,
    hideNotification
  }
)(CartItemNotificationModal)
