import React, { lazy, Suspense, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { addRouteToHistory } from "../actions/routes";
import { ApplicationPaths } from "../auth/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "../auth/ApiAuthorizationRoutes";
import AuthorizeRoute from "../auth/AuthorizeRoute";
import { handleSideDrawer } from "../actions/sideDrawer";
import FollowingSection from "../containers/FollowingSection/FollowingSection";
import Saved from "../pages/Liked";
import {
  GalleryPageSkeleton,
  LandingPageSkeletonLoader,
  LoginPageSkeleton,
  PoliciesPageSkeleton,
  RegistrationPageSkeleton,
} from "../components/skeletonLoaders";
import isMobile from "../components/Responsive/isMobile";
import isTablet from "../components/Responsive/isTablet";
import isDesktop from "../components/Responsive/isDesktop";
import isDesktopHD from "../components/Responsive/isDesktopHD";
import Pikcha100 from "../pages/Pikcha100";
import Upload from "../pages/Upload/Upload";
import Auth from "../pages/Auth/Auth";
import Search from "../pages/Search";
import Customise from "../pages/Customise/Customise";
import Account from "../pages/Account";
import Notifications from "../pages/Notifications";
// import EditImage from "../pages/EditImage/EditImage";
import EditImage from "../pages/Edit/Edit";
import Product from "../pages/Product";
import Checkout from "../pages/Checkout/Checkout";
import ImageDetails from "../pages/ImageDetails";
import AlbumDetails from "../pages/AlbumDetails";
import InterPages from "../pages/InterPages";
import QRImageDetails from "../pages/QRImageDetails";
import VerifyPayPal from "../pages/VerifyPayPal";
import VerifyMyPayPal from "../pages/VerifyMyPayPal";
import SuccessPayPal from "../pages/SuccessPayPal";
import { checkCustomizationPage } from "../hooks/useRouteWithFooter";
import SvgTest from "../pages/SvgTest";

const NotFoundPage = lazy(() => import("../pages/404Page"));
const SignUpPage = lazy(() => import("../pages/SignupRedirection"));
const ServerDownPage = lazy(() => import("../pages/500Page"));
const Trending = lazy(() => import("../pages/Trending"));
const Unsubscribe = lazy(() => import("../pages/Unsubscribe"));
const About = lazy(() => import("../pages/About/About"));
const Guides = lazy(() => import("../pages/Guides/Guides"));
const Contact = lazy(() => import("../pages/Contact/Contact"));
const Policies = lazy(() => import("../pages/Policies/Policies"));
const Profile = lazy(() => import("../pages/Profile"));
const SearchPage = lazy(() => import("../pages/SearchPage/index"));
const SellArt = lazy(() => import("../pages/SellArt/SellArt"));
const ProductPage = lazy(() => import("../pages/ProductPage/ProductPage"));
const OrderSearch = lazy(() => import("../pages/Search/OrderSearch/OrderSearch"));
const OrderSearchApi = lazy(() => import("../pages/Search/OrderSearch/OrderSearchApi"));
const PoliciesV2 = lazy(() => import("../pages/PoliciesV2"));
const GiftCard = lazy(() => import("../pages//GiftCard/GiftCard"));

const Routes = ({ handleSideDrawer, addRouteToHistory }) => {
  const { pathname } = useLocation();

  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const isOnDesktop = isDesktop();
  const isOnDesktopHD = isDesktopHD();

  const renderPageSkeleton = useCallback((page) => {
    // if (page.includes("/profile")) {
    //   if (isOnMobile) {
    //     return <ProfilePageSkeletonMobile />;
    //   } else if (isOnTablet) {
    //     return <ProfilePageSkeletonTablet />;
    //   } else {
    //     return <ProfilePageSkeleton />;
    //   }
    // }
    // TODO: Properly implement skeleton loaders for each page.

    // Based on the current route, we determine which loading skeleton
    // component should be rendered on the screen.
    switch (page) {
      case "/auth/login":
        return <LoginPageSkeleton />;
      case "/app":
        return <GalleryPageSkeleton />;
      case "/auth/register":
        return <RegistrationPageSkeleton />;
      case "/policies":
        return <PoliciesPageSkeleton />;
      case "/":
        return <LandingPageSkeletonLoader />;
      default:
        return null;
    }
  }, []);

  useEffect(() => {
    addRouteToHistory(pathname);
  }, [pathname]);

  useEffect(() => {
    if (
      pathname.includes("/auth") ||
      // pathname.includes("/guides") ||
      pathname === "/print" ||
      pathname === "/checkout" ||
      pathname === "/upload" ||
      pathname.includes("/edit") ||
      checkCustomizationPage(pathname)
    ) {
      handleSideDrawer(false);
      return;
    }

    if (isOnDesktopHD) {
      handleSideDrawer(true);
    } else if (isOnMobile || isOnTablet || isOnDesktop) {
      handleSideDrawer(false);
    }
  }, [pathname, isOnMobile, isOnTablet, isOnDesktop, isOnDesktopHD]);

  return (
    <Suspense fallback={renderPageSkeleton(pathname)}>
      <Switch>
        {/* <Redirect exact from="/" to="/trending"/> */}
        <Route exact path="/" component={Trending} />
        <Redirect exact from="/auth" to="/auth/register" />
        <Route strict path="/auth" component={Auth} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route strict path="/guides" component={Guides} />
        <Route exact path="/order-search" component={OrderSearch} />
        <Route exact path="/gift-card" component={GiftCard} />
        <Route
          exact
          path="/policies"
          component={() => <Redirect to="/policies/privacy" />}
        />
        <Route exact path="/policies/privacy" component={PoliciesV2} />
        <Route exact path="/policies/ip" component={PoliciesV2} />
        <Route exact path="/policies/user-agreement" component={PoliciesV2} />
        <Route exact path="/policies/terms-of-use" component={PoliciesV2} />
        <Route exact path="/pikcha100" component={Pikcha100} />
        <Route
          exact
          path="/artist100"
          render={(props) => <Pikcha100 routePageType="artist100" {...props} />}
        />
        <Route exact path="/following" component={FollowingSection} />
        <Route exact path="/Liked" component={Saved} />
        <Route
          exact
          path={[
            "/search/:query",
            "/search/artist/:query",
            "/search/album/:query",
          ]}
          component={Search}
        />
        <Route path="/order-search/:orderNumber" component={OrderSearchApi} />
        {/* <Route exact padt="/search/artist/:query" component={SearchArtist} /> */}
        <Route exact path="/customise" component={NotFoundPage} />
        <Route exact path="/@:userid" component={Profile} />
        <Route exact path="/search" component={SearchPage} />
        <Route exact path="/notifications" component={Notifications} />
        <AuthorizeRoute exact path="/product/:productid" component={Product} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/verify-paypal" component={VerifyPayPal} />
        <Route exact path="/verify-my-paypal" component={VerifyMyPayPal} />
        <Route exact path="/success-paypal" component={SuccessPayPal} />
        <Route exact path="/sell-your-art" component={SellArt} />
        <Route exact path="/prints-and-frames" component={ProductPage} />
        <Route strict path="/account" component={Account} />
        <Route
          path={ApplicationPaths.ApiAuthorizationPrefix}
          component={ApiAuthorizationRoutes}
        />
        <Route exact path="/artwork/:imageid" component={ImageDetails} />
        <Route exact path="/artwork/:imageid/print" component={Customise} />
        <Route
          exact
          path="/album/:albumid"
          component={AlbumDetails}
        />
        <Route
          exact
          path="/collection/:collectionid"
          component={AlbumDetails}
        />
        <Route
          exact
          path="/inter/artwork/:imageid"
          component={InterPages}
        />
        <Route
          exact
          path="/edit/:imageid"
          component={EditImage}
        />
        <Route
          exact
          path="/inter/@:userid"
          component={InterPages}
        />
        <Route
          exact
          path="/inter/album/:albumid"
          component={InterPages}
        />
        <Route
          exact
          path="/inter/collection/:collectionid"
          component={InterPages}
        />
        <Route exact path="/unsubscribe/:id" component={Unsubscribe} />
        <Route exact path="/500Page" component={ServerDownPage} />
        <Route exact path="/inter/sign-up" component={InterPages} />
        <Route exact path="/sign-up" component={SignUpPage} />
        <Route exact path="/upload" component={Upload} />
        <Route exact path="/svg-test" component={SvgTest} />
        <Route exact path="/sitemap.xml" />
        <Route exact path="/generic_urls_sitemap.xml" />
        <Route exact path="/image_urls_sitemap.xml" />
        <Route exact path="/artist_urls_sitemap.xml" />
        <Route exact path="/temporary_urls_sitemap.xml" />
        <Route component={NotFoundPage} />
        <Route exact path="/notFound" component={NotFoundPage} /> 
      </Switch>
    </Suspense>
  );
};

export default connect(null, {
  handleSideDrawer,
  addRouteToHistory,
})(Routes);
