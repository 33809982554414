import React, {Fragment} from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from "react-router-dom";

import {PRIMARY_LINEAR_GRADIENT} from "../../../helpers/constants";

import isMobile from "../../../components/Responsive/isMobile";

/**
 * The main button component used across the web app.
 *
 * @author [Jordan Lee](https://gitlab.com/jkhaui)
 */
export const PikchaButtonActual = withStyles(
  ({typography, spacing, palette, shape}) => ({
    root: ({
             action = "primary",
             color,
             iconButton,
             noHoverEffect,
             noPadding,
             active,
             noBorder,
             fullWidth,
             small,
             mainStyle,
             disabled,
           }) => ({
      color:
        color === "black"
          ? palette.common.white
          : disabled
          ? palette.grey.mediumLight
          : action === "primary"
            ? palette.common.white
            : action === "secondary"
              ? palette.grey.dark
              : action === "tertiary"
                ? palette.primary.main
                : action === "quaternary"
                  ? palette.grey.mediumLight
                  : action === "quinary"
                    ? palette.common.white
                    : action === "senary"
                      ? palette.grey.dark
                      : action === "septenary"
                        ? palette.text.primary
                        : action === "octonary"
                          ? palette.common.black
                          : null,
      minWidth: !noBorder ? null : 0,
      "@media(hover: none)": {
        "&:hover": {
          backgroundColor: `${
            color === "black"
              ? palette.grey.dark
              : (action === "secondary" ||
              action === "senary" ||
              action === "septenary" ||
              action === "octonary")
              ? palette.grey.lightest
              : action === "quinary"
                ? palette.error.main
                : action === "quaternary"
                  ? undefined
                  : "rgba(215, 183, 253, 0.25)"
          }`,
        }
      },
      borderColor:
        color === "black"
          ? palette.grey.darkest
          : disabled
          ? palette.grey.mediumLight
          : action === "primary" || action === "tertiary"
            ? palette.primary.main
            : action === "quaternary"
              ? palette.grey.mediumLight
              : action === "quinary"
                ? palette.error.main
                : action === "senary"
                  ? "transparent"
                  : action === "septenary"
                    ? palette.text.primary
                    : action === "octonary"
                      ? palette.grey.light
                      : active
                        ? palette.primary.main
                        : palette.grey.mediumDark,
      //border: action === "secondary" ? palette.grey.mediumDark : null,
      minHeight: small ? spacing(5) : null,
      width: iconButton ? spacing(4) : null,
      height: iconButton ? spacing(4) : null,
      fontSize:
        action === "octonary" ? typography.smallest : typography.standard,
      borderRadius: shape.borderRadius,
      boxShadow:
        action === "octonary" ? "0px 1px 4px rgba(0, 0, 0, 0.25)" : "none",
      padding: small ? "4px 12px" : !noPadding ? "8px 16px" : 2,
      fontWeight:
        action === "octonary"
          ? typography.fontWeightMedium
          : small || noBorder
          ? typography.fontWeightMedium
          : typography.fontWeightBold,
      lineHeight: small ? 1.34 : fullWidth ? 1.75 : 1.5,
      //margin: 'auto',
      textTransform: "capitalize",
      textDecoration: "none",
      maxHeight: !iconButton ? 44 : null,
      background:
        color === "black"
          ? palette.grey.darkest
          : disabled
          ? palette.grey.light
          : action === "primary"
            ? PRIMARY_LINEAR_GRADIENT
            : action === "quaternary"
              ? palette.grey.lightest
              : action === "quinary"
                ? palette.error.main
                : action === "octonary"
                  ? palette.common.white
                  : "transparent",
      transition: `${
        action === "primary" ? "background" : "background-color"
      } 0.1s ease-in-out`,
      borderWidth: action === "octonary" ? 1 : !noBorder ? 2 : null,
      //borderWidth: noBorder
      //  ? 0
      //  : action === "tertiary" || action === "secondary"
      //  ? 2
      //  : 1,
      borderStyle: "solid",
      "& > .MuiButton-label":
        action !== "tertiary"
          ? {
            background: "transparent",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": disabled
              ? palette.grey.mediumLight
              : action === "primary"
                ? palette.common.white
                : action === "secondary" && active
                  ? palette.primary.main
                  : action === "quaternary"
                    ? palette.grey.mediumLight
                    : action === "quinary"
                      ? palette.common.white
                      : action === "septenary"
                        ? palette.text.primary
                        : action === "octonary"
                          ? palette.common.black
                          : palette.grey.dark,
          }
          : {
            // Webkit attributes are necessary for the gradient text effect.
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            background: PRIMARY_LINEAR_GRADIENT,
            display: "inline",
          },
      "&:active": !color ? undefined : {
        boxShadow: "none",
        background: palette.grey.darker
      },
      "&:hover": noHoverEffect
        ? {
          boxShadow: "none",
        }
        : {
          transition: `${
            action === "primary" ? "background" : "background-color"
          } 0.15s ease-in-out`,
          boxShadow:
            action === "octonary"
              ? "0px 2px 4px rgba(0, 0, 0, 0.25)"
              : "none",
          background: `${
            action === "primary" && !color
              ? "linear-gradient(45deg, #6200ea 20.83%, #7c4dff 76.04%)"
              : (color === "black" ||
              action === "secondary" ||
              action === "tertiary" ||
              action === "quinary")
              ? "transparent"
              : null
          }`,
          backgroundColor: `${
            color === "black"
              ? palette.grey.dark
              : (action === "secondary" ||
              action === "senary" ||
              action === "septenary" ||
              action === "octonary")
              ? palette.grey.lightest
              : action === "quinary"
                ? palette.error.main
                : action === "quaternary"
                  ? undefined
                  : "rgba(215, 183, 253, 0.25)"
          }`,
        },
      ...mainStyle,
    }),
    label: ({minWidth, labelStyle}) => ({
      minWidth: minWidth ? minWidth : undefined,
      ...labelStyle,
    }),
  })
)(
  ({
     children,
     action = "primary",
     small = false,
     fullWidth = false,
     noBorder = false,
     to = null,
     ...rest
   }) => {
    if (
      action !== "primary" &&
      action !== "secondary" &&
      action !== "tertiary" &&
      action !== "quaternary" &&
      action !== "quinary" &&
      action !== "senary" &&
      action !== "septenary" &&
      action !== "octonary"
    ) {
      throw new Error(
        'The `action` prop must have a value of "primary", "secondary", or "tertiary"'
      );
    }

    return (
      <Fragment>
        {to ? (
          <Link
            to={to}
            className={`no-underline ${fullWidth ? "full-width" : ""}`}
          >
            <Button fullWidth={fullWidth} {...rest}>
              {children}
            </Button>
          </Link>
        ) : (
          <Button fullWidth={fullWidth} {...rest}>
            {children}
          </Button>
        )}
      </Fragment>
    );
  }
);

export const PikchaButton = ({noHoverEffect, ...rest} = {}) => {
  const isOnMobile = isMobile();

  let noHoverEffectValue = noHoverEffect;
  if (isOnMobile) {
    noHoverEffectValue = true;
  }

  return <PikchaButtonActual noHoverEffect={noHoverEffectValue} {...rest} />;
};

export const PikchaPrimarySmallButton = withStyles(({shape, typography}) => ({
  root: {
    color: "#FFF !important",
    border: "1px solid",
    padding: "14px 18px",
    fontSize: typography.smallest,
    borderRadius: shape.borderRadius,
    boxShadow: "none",
    lineHeight: 1,
    marginRight: 4,
    textTransform: "none",
    textDecoration: "none",
    background: PRIMARY_LINEAR_GRADIENT,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#A700FF",
    },
  },
}))(({small, children, ...rest}) => <Button {...rest}>{children}</Button>);
