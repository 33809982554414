import React, { useEffect, useState } from "react";
import {
  PikchaDropdown,
} from "../../../components/baseComponents";
import {COUNTRIES} from "../../../config/countries";
import {connect} from "react-redux";
import { PikchaToggleButtonGroup } from "../../../components/baseComponents/buttons/PikchaToggleButtonGroup";
import { PikchaToggleButton } from "../../../components/baseComponents/buttons/PikchaToggleButton";
import { FB_PIXEL_EVENT_TYPES } from "../../../hooks/useFbPixelEvent/event-constants";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { getSavedUtmDetails, isProductionSite } from "../../../helpers/utils";
import { withOrientationChange } from 'react-device-detect';
import { isMobile } from '../../../env_utils/device_screen';
import {useTheme} from "@material-ui/core/styles";
import {
  TypographySubtitle, 
  TYPOGRAPHY_SUBTITLE_COMPONENT, 
  TYPOGRAPHY_BODY_SIZE,
  TypographyBody, 
  TypographyButton, 
  colors
} from "@pikcha/typography";

let Step1 = ({
  user,
  setAlert,
  setUserAccountName,
  handleCurrentPage,
  userCountry, setUserCountry,
  userCurrency, setUserCurrency,
  userLanguage, setUserLanguage,
  userMeasureUnit, setUserMeasureUnit,
  currencyLabels,
  languageLabels
}) => {
  useEffect(() => {
    if (isProductionSite()) {
      ReactPixel.track(FB_PIXEL_EVENT_TYPES.Lead, {
        content_name: "Registration",
        category_name: "Registration",
        ...getSavedUtmDetails(),
      });
      ReactGA.event(
        "sign_up", {
        ...getSavedUtmDetails(),
      });
    }
  }, []);

  const { palette } = useTheme();

  const accountName = !user ? "" : user.nickName
    ? user.nickName
    : `${user.fName} ${user.lName}`;

  const [effectTrigger, setEffectTrigger] = useState(false);

  return (
    <div
      className="flex-column flex full-width space-between"
      style={{
        flex: "1 1",
        paddingLeft : isMobile ? '0' : "3em",
        paddingRight : isMobile ? '0' : "3em",
        textAlign: "center",
        paddingTop: "5em",
        paddingBottom: "1em",
        maxHeight: "100%"
      }}
    >
      <div>
        <TypographySubtitle
          component={isMobile ? TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H3_Bold : TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
          style={{
            marginBottom: "1em"
          }}
        >
          Language & region
        </TypographySubtitle>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
          className="full-width"
          style={{
            margin: "0 auto"
          }}
        >
          Help us show the right prices and your preferred measurement units.
        </TypographyBody>
      </div>
      <div className="full-width">
        <PikchaDropdown
          style={{
            marginBottom: isMobile? 30 : "2em",
          }}
          compactListSize
          label={
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{
                lineHeight: 1,
                marginBottom: "0.25em"
              }}
            >
              Country
            </TypographyBody>
          }
          className="Component-textField-150"
          noHelperClass
          onChange={(value) => {
            setUserCountry(value.label);
          }}
          options={COUNTRIES}
          placeholder={userCountry}
        />

        {!isMobile && (
          <PikchaDropdown
            style={{
              marginBottom: isMobile? 30 : "2em",
            }}
            // required
            compactListSize
            label={
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                style={{
                  lineHeight: 1,
                  marginBottom: "0.25em"
                }}
              >
                Language
              </TypographyBody>
            }
            className="Component-textField-150"
            noHelperClass
            onChange={(value)=> {
              setUserLanguage(value.label);
            }}
            options={languageLabels}
            placeholder={userLanguage}
          />
        )}

        {isMobile && (
          <PikchaDropdown
            style={{
              marginBottom: 30,
            }}
            // required
            compactListSize
            label={
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                style={{
                  lineHeight: 1,
                  marginBottom: "0.25em"
                }}
              >
                Currency
              </TypographyBody>
            }
            className="Component-textField-150"
            noHelperClass
            onChange={(value)=> {
              setUserLanguage(value.label);
            }}
            options={languageLabels}
            placeholder={userLanguage}
          />
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? undefined : 'space-between',
            // backgroundColor: 'red',
          }}
        >
          <PikchaDropdown
            style={{
              width: "60%",
              marginRight: isMobile ? 20 : undefined,
            }}
            // required
            compactListSize
            label={
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                style={{
                  lineHeight: 1,
                  marginBottom: "0.25em"
                }}
              >
                Currency
              </TypographyBody>
            }
            className="Component-textField-150"
            noHelperClass
            onChange={(value) => {
              setUserCurrency(value.label);
            }}
            options={currencyLabels}
            placeholder={userCurrency}
          />  
          <div style={{width: "35%", marginTop: "-1.25em"}}>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{
                lineHeight: "1rem",
                marginBottom: "0.25em",
                textAlign: "start",
                fontSize: "0.8125rem"
              }}
            >
              Units
            </TypographyBody>
            <PikchaToggleButtonGroup
              style={{
                width: "100%",
              }}
              value={userMeasureUnit}
              aria-label="Page Type Selector"
            >
              <PikchaToggleButton
                style={{
                  backgroundColor: userMeasureUnit === "cm" ? colors.Neutral.grey20 : null,
                  boxShadow: "rgb(189 189 189) 0px 0px 0px 0px inset",
                  border: "1px solid #D5D8DF",
                  width: "50%"
                }}
                value="cm"
                aria-label="CM"
                onClick={() => {
                  setUserMeasureUnit("cm");
                }}
              >
                cm
              </PikchaToggleButton>
              <PikchaToggleButton
                style={{
                  backgroundColor: userMeasureUnit === "inch" ? colors.Neutral.grey20 : null,
                  boxShadow: "rgb(189 189 189) 0px 0px 0px 0px inset",
                  border: "1px solid #D5D8DF",
                  width: "50%"
                }}
                value="inch"
                aria-label="INCH"
                onClick={() => {
                  setUserMeasureUnit("inch");
                }}
              >
                inch
              </PikchaToggleButton>
            </PikchaToggleButtonGroup>
          </div>
        </div>
      </div>
      <TypographyButton
        buttonStyle={{
          marginBottom: '1em',
          width: '100%',
        }}
        onClick={() => {
          if (!accountName) {
            return setAlert("Please provide a valid account name", "error");
          }
          setUserAccountName(accountName);
          handleCurrentPage(2);
          setEffectTrigger(!effectTrigger);
        }}
      >
        Next
      </TypographyButton>
    </div>
  );
};

Step1 = withOrientationChange(Step1);

const mapStateToProps = (state) => {
  return {
    user_ip: state.locationReducer?.user_ip,
    user_country: state.locationReducer?.user_country,
  };
};

export default connect(mapStateToProps, {})(Step1);
