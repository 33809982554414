import { MODAL_CREATED, MODAL_REMOVED } from "./types";

import {
  modalTypes,
  modalType,
  modalData,
  modalStack,
} from "../selectors/modal";

export const showProductModal = (image) => {
  return (dispatch) => {
    const { friendlyUrl } = image;
    if (friendlyUrl) {
      const friendlyUrlParsed = new URL(
        window.location.origin + "/" + friendlyUrl
      );
      window.history.pushState({}, "", friendlyUrlParsed.pathname);
    }

    dispatch({
      type: MODAL_CREATED,
      payload: { type: modalTypes.product, data: image },
    });
  };
};

export const showCreateCollectionModal = () => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CREATED,
      payload: { type: modalTypes.createCollection, data: {} },
    });
  };
};

export const showEditAlbumModal = (album) => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CREATED,
      payload: { type: modalTypes.editAlbum, data: album },
    });
  };
};

export const showViewAlbumModal = (album) => {
  return (dispatch) => {
    const { friendlyUrl } = album.albumItem;
    if (friendlyUrl) {
      const friendlyUrlParsed = new URL(
        window.location.origin + "/" + friendlyUrl
      );
      window.history.pushState({}, "", friendlyUrlParsed.pathname);
    }
    dispatch({
      type: MODAL_CREATED,
      payload: { type: modalTypes.viewAlbum, data: album },
    });
  };
};

// Remove modals
export const removeModal = ({ moveForward } = {}) => {
  return (dispatch, getState) => {
    const modalsType = modalType(getState());
    const modalsData = modalData(getState());
    const modalsStack = modalStack(getState());

    const routeHistory = getState().routesReducer.routeHistory;
    const lastVisitedRoute = routeHistory[routeHistory.length - 1];
    window.history.replaceState(null, null, lastVisitedRoute);

    // window.history.pushState({}, "Pikcha", "/");

    // if (!moveForward) {
    //   if (modalsType === modalTypes.product && modalsStack > 0) {
    //     window.history.go(-1 * modalsStack);
    //   }
    // }

    dispatch({
      type: MODAL_REMOVED,
    });
  };
};

// unused modals?

export const createDPModal = () => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CREATED,
      payload: { type: "DP", data: "" },
    });
  };
};

export const createCoverModal = () => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CREATED,
      payload: { type: "Cover" },
    });
  };
};

export const createFullscreenModal = (image) => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CREATED,
      payload: { type: "Fullscreen", data: image },
    });
  };
};

export const createPromoteModal = () => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CREATED,
      payload: { type: "Promote" },
    });
  };
};

export const createUploadImageModal = () => {
  return (dispatch) => {
    dispatch({
      type: MODAL_CREATED,
      payload: { type: "UploadImage" },
    });
  };
};
