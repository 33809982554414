import React from "react";
import { useTheme } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";

export default ({ containerStyle, ...otherProps }) => {
  const { palette } = useTheme();

  return (
    <div style={containerStyle}>
      <ContentLoader
        width={240}
        height={56}
        viewBox="0 0 240 56"
        backgroundColor={palette.grey.lighter}
        foregroundColor={palette.grey.lighter}
        {...otherProps}
      >
        <circle cx="24" cy="28" r="20" />
        <circle cx="78" cy="28" r="20" />
        <circle cx="132" cy="28" r="20" />
        <circle cx="186" cy="28" r="20" />
      </ContentLoader>
    </div>
  );
};
