export const PROFILE_LEFT_COLUMN_WIDTH = 140;

export const SVG_CIRCULAR_MASK_MARKUP = `<mask id="my-mask"><rect x="0" y="0" width="100%" height="100%" fill="white"/>
<circle cx="50%" cy="50%" r="50%" fill="black"/></mask>
<rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
<circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>`;

export const PROFILE_PHOTO_LOADING_MESSAGE = 'Loading your profile picture';
export const PROFILE_PHOTO_SAVING_MESSAGE = 'Saving your photo';
export const PROFILE_PHOTO_BUTTON_CONFIRM_TEXT = 'Save Profile Picture';

export const PROFILE_PHOTO_EDIT_OPTIONS = ['crop', 'filter', 'color'];
