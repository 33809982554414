export const isGalleryLoading = (state) => {
  let gallery;
  if (state) {
    gallery = state.galleryReducer;
  }

  let isGalleryLoading = false;
  if (gallery) {
    isGalleryLoading = gallery.loading;
  }

  return isGalleryLoading;
};

export const getImageType = (state) => {
  let gallery;
  if (state) {
    gallery = state.galleryReducer;
  }

  let imageType = "";
  if (gallery) {
    imageType = gallery.imageType;
  }

  return imageType;
}