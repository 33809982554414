import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core";

import { PikchaCloseButton } from "../index";
import { removeModalOverlay } from "../../../helpers/utils";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(({ palette: { grey }, typography }) => ({
  root: ({ reduceVerticalPadding, darkHeader, headerZIndex }) => ({
    userSelect: "none",
    position: "absolute",
    zIndex: headerZIndex ? headerZIndex : 10,
    height: 64,
    width: "100%",
    display: "flex",
    paddingLeft: "1em",
    fontWeight: typography.fontWeightMedium,
    paddingRight: 0,
    paddingTop: reduceVerticalPadding ? ".5em !important" : "1em !important",
    paddingBottom: reduceVerticalPadding ? ".5em !important" : "1em !important",
    justifyContent: "flex-end",
    //justifyContent: 'space-between',
    alignItems: "center",
    color: darkHeader ? grey.lightest : null,
    background: darkHeader ? grey.darkest : null,
  }),
}));

export const PikchaModalHeader = ({
  children,
  reduceVerticalPadding,
  title,
  hideCloseButton,
  darkHeader,
  onClose,
  headerIcon = null,
  headerZIndex,
  FullscreenPhotoModal,
  headerStyle = {},
  titleStyle= {}
}) => {
  const { typography, palette } = useTheme();
  const { root } = useStyles({
    reduceVerticalPadding,
    darkHeader,
    headerZIndex
  });

  const StyledIconButton = withStyles({
    root: {
      width: 32,
      height: 32,
      marginRight: 8,
      padding: 8,
      borderRadius: 6,
    },
  })(IconButton);

  const handleClose = () => {
    onClose();
    removeModalOverlay();
  };

  return (
    <header className={root} style={{...headerStyle}}>
      {title ? (
        <div
          className="flex-center full-width full-height"
          style={{
            color: palette.primary.contrastText,
            paddingTop: 2,
            fontWeight: typography.fontWeightBold,
            ...titleStyle
          }}
        >
          {title}
        </div>
      ) : (
        children
      )}
      {headerIcon ? (
        <StyledIconButton style={{marginTop:-6, zIndex: 9999 }}>
          {headerIcon}
        </StyledIconButton>
      ) : null}
      {onClose && !hideCloseButton && (
        <div className="flex-center justify-content-end">
          <PikchaCloseButton
            lightVariant={ FullscreenPhotoModal? "true": darkHeader }
            largeVariant={ FullscreenPhotoModal? "true": false }
            onClick={handleClose}
            style={{
              marginTop: -6,
              zIndex: 9999,
            }}
          />
        </div>
      )}
    </header>
  );
};
