import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';

export const ProductItemDesktopLoadingSkeleton = ({
  aspectRatio,
  isOnDesktopHD,
  blurHash,
}) => {
  const [isPortrait] = useState(
    aspectRatio === '2,3'
    || aspectRatio === '3,4'
    || aspectRatio === '4,5',
  );

  const [height, setHeight] = useState(40);
  const [width, setWidth] = useState(40);

  useLayoutEffect(() => {
    switch (aspectRatio) {
      case '2,3':
        setHeight(40);
        setWidth(27);
        break;
      case '3,4':
        setHeight(40);
        setWidth(30);
        break;
      case '4,5':
        setHeight(40);
        setWidth(32);
        break;
      case '3,2':
        setHeight(27);
        setWidth(40);
        break;
      case '4,3':
        setHeight(30);
        setWidth(40);
        break;
      case '5,4':
        setHeight(32);
        setWidth(40);
        break;
      case '1,1':
      default:
        setHeight(40);
        setWidth(40);
        break;
    }
  }, [aspectRatio]);

  const calcWidth = useCallback(() => {
    if (!isOnDesktopHD) {
      if (isPortrait) {
        return width / 1.25;
      }
      return width / 1.1;
    }

    if (isPortrait) {
      return width / 1.4;
    }
    return width / 1.2;
  }, [isOnDesktopHD, isPortrait, width]);

  const calcHeight = useCallback(() => {
    if (!isOnDesktopHD) {
      if (isPortrait) {
        return height / 1.25;
      }
      return height / 1.1;
    }

    if (isPortrait) {
      return height / 1.4;
    }

    return height / 1.2;
  }, [isOnDesktopHD, isPortrait, height]);

  return (
    <Blurhash
      className="ProductItem-loader relative"
      style={{
        backgroundSize: `${width}vW ${height}vW`,
        height: `${calcHeight()}vW`,
        width: `${calcWidth()}vW`,
      }}
      hash={!!blurHash && blurHash.length === 36 ? blurHash : 'UDF7G4?v?wRP_NWAM_j[Rjt8MxIoIVIT%Ne-'}
      height={`${calcHeight()}vW`}
      width={`${calcWidth()}vW`}
      resolutionX={300}
      resolutionY={300}
      punch={1}
    />
  );
}
