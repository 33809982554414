import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";

import { addView } from "../../../actions/gallery";
import {PikchaInvisibleButton} from "../../baseComponents";
import { ICON_SIZE_DEFAULT } from "../../../helpers/constants";

import "./SafeSearchOverlay.css";

const SafeSearchModalOverlay = ({
  onClick,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const { typography, palette } = useTheme();

  const { fontFamilyMontserrat } = typography;

  return (
    <div
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        fontFamily: fontFamilyMontserrat,
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 5,
        cursor: "pointer",
        visibility: "visible",
      }}
      className="SafeSearchOverlay-visible ImageOverlay-wrapper"
    >
      <div className="SafeSearchOverlay-innerContainer flex-center">
        <div
          className="SafeSearchOverlay-block"
          style={{
            textAlign: "center",
            color: palette.text.primary,
            fontWeight: typography.fontWeightRegular,
          }}
        >
          <VisibilityRoundedIcon
            style={{
              ...ICON_SIZE_DEFAULT,
              marginBottom: "-1.25em",
            }}
            fontSize="inherit"
            color="inherit"
          />
          <p className="SafeSearchOverlay-textWrapper">
            <PikchaInvisibleButton
              fullWidth
              boldText
              style={{
                color: palette.text.primary,
                fontSize: typography.verysmall,
                fontWeight: typography.fontWeightSemiMedium,
              }}
            >
              &nbsp;safe search activated
            </PikchaInvisibleButton>
          </p>
          <p>
            <PikchaInvisibleButton
              fullWidth
              style={{
                color: palette.text.primary,
                fontSize: typography.verysmall,
                fontWeight: typography.fontWeightSemiMedium,
              }}
            >
              click to view
            </PikchaInvisibleButton>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ galleryReducer }) => ({
  gallery: galleryReducer,
});

export default connect(mapStateToProps, {
  addView,
})(SafeSearchModalOverlay);
