import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {loadUser} from "../actions/account";
// import {handleInitProfileOnBoarding} from "../actions/profile";
import {initOnboardingFlow} from "../actions/onboarding";
import {promoteToArtist} from "../actions/account";

import AuthorizeService from "../auth/AuthorizeService";
import {
  PikchaLoader,
  PikchaSpacer,
  PikchaTitle,
} from "../components/baseComponents";

const VerifyPayPal = ({
                        promoteToArtist,
                        account,
                        settings: {settings},
                        loadUser,
                        initOnboardingFlow
                      }) => {
  const [generalError, setGeneralError] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);

  const [payPalUserUpdate, setPayPalUserUpdate] = useState(false);
  const [payPalUserCreate, setPayPalUserCreate] = useState(false);
  const [payPalUserError, setPayPalUserError] = useState(false);
  const [payPalUserErrorMsg, setPayPalUserErrorMsg] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (account.user && !awaitingResponse) {
      // Set boolean flag to prevent API call from being executed more than once
      setAwaitingResponse(true);
      const getToken = async () => await AuthorizeService.getAccessToken();

      const {fName, lName} = account.user;
      const location = window.location.search;

      const code = ((location || "").split("code=")[1] || "").split("&")[0];

      getToken()
        .then((token) => {
          if (
            account.user &&
            account.user.paypalVerification &&
            account.user.paypalEmail &&
            account.user.roles.includes("Artist")
          ) {
            setPayPalUserUpdate(true);
            setPayPalUserError(false);
            setPayPalUserCreate(false);
            axios
              .post(
                `api/payment/paypal/UpdateUserInfo/`,
                {
                  code: code,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                if (!res) {
                  return setPayPalUserError(true);
                }

                const {data} = res;

                if (data && data.error_message && data.error_message !== "") {
                  setPayPalUserError(true);
                  return setPayPalUserErrorMsg(data.error_message);
                }
                if (res.status === 200) {
                  const {
                    data: {emails},
                  } = res;

                  const email = emails[0].value;
                  const dto = {
                    // Type: "individual",
                    // FirstName: fName,
                    // Lastname: lName,
                    // Email: email,
                    // Id: account.user.paymentRailsId,
                    // // TODO: do we need to send these empty strings?
                    // Passport: res.data.isNewEmail ? undefined : "",
                    // Dob: res.data.isNewEmail ? undefined : "",
                    // Phone: res.data.isNewEmail ? undefined : "",
                    // userId: account.user.id,
                    // Address: {
                    //   Street1: res.data.address.street_address,
                    //   // TODO: Find a way to extract second line address.
                    //   //Street2: "",
                    //   City: res.data.address.locality,
                    //   Region: res.data.address.region,
                    //   PostalCode: res.data.address.postal_code,
                    //   Country: res.data.address.country,


                    // },

                  // emailAddress: email,
                   emailAddress: account.user.paypalEmail,
                   id: account.user.paymentRailsId,
                  };
                  if (res.data.isNewEmail) {
                    axios
                      .put(
                        `${
                          settings.payoutUrl ||
                          "https://pikcha.local:1900/v1/payout"
                        }/UpdateRecipientAccount`,
                        dto,
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                      .then(async(resp) => {
                        if (
                          resp &&
                          resp.data &&
                          resp.data.error_message &&
                          resp.data.error_message !== ""
                        ) {
                          setPayPalUserError(true);
                          return setPayPalUserErrorMsg(resp.data.error_message);
                        }
                        if (resp && resp.data) {
                          const token = await AuthorizeService.getAccessToken();
                          await axios.get(`/api/Payment/${account.user.paymentRailsId}/UpdatePaymentInformation`, {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            }
                          });
                          await loadUser();
                          setTimeout(
                            () => history.push(`/account/details`),
                            4000
                          );
                          // window.location.reload();
                        } else {
                          setPayPalUserError(true);
                        }
                      })
                      .catch((err) => {
                        setPayPalUserError(true);
                        setPayPalUserErrorMsg(err);
                        console.log("setPayPalUserError ", err);
                      });
                  } else {
                    setPayPalUserError(false);
                    setPayPalUserErrorMsg("");
                    setTimeout(() => {
                      setPayPalUserUpdate(false);
                      setPayPalUserCreate(false);
                      history.push(`/account/details`);
                    }, 4000);
                    // window.location.reload();
                  }
                }
              })
              .catch((err) => {
                console.log("setPayPalUserUpdate ", err);
                setPayPalUserUpdate(false);
                setPayPalUserCreate(false);
                setPayPalUserError(true);
                setPayPalUserErrorMsg(err);
              });
          } else {
            setPayPalUserUpdate(false);
            setPayPalUserError(false);
            setPayPalUserErrorMsg("");
            axios
              .post(
                `api/payment/paypal/userInfo/`,
                {
                  code: code,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                if (!res) {
                  return setPayPalUserError(true);
                }

                const {data} = res;

                // The purpose of this guard block is to stop the flow if a
                // user already has a paypal account registered with a Pikcha
                // account.
                if (data && data.error_message && data.error_message !== "") {
                  setPayPalUserError(true);
                  return setPayPalUserErrorMsg(data.error_message);
                }

                if (res.status === 200 && data.error_message === "") {
                  const {
                    data: {emails},
                  } = res;

                  const email = emails[0].value;
                  const dto = {
                    emailAddress: email,
//                    emailAddress: account.user.email,
                    id: account.user.paymentRailsId,
                  };
                  axios
                    .post(
                      `${
                        settings.payoutUrl ||
                        "https://pikcha.local:1900/v1/payout"
                      }/CreateRecipientAccount`,
                      dto,
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then(async (res) => {
                      if (res && res.data && res.status && res.status === 200) {
                        if (
                          data &&
                          data.error_message &&
                          data.error_message !== ""
                        ) {
                          setPayPalUserError(true);
                          return setPayPalUserErrorMsg(data.error_message);
                        } else {
                          setPayPalUserError(false);
                          setPayPalUserErrorMsg("");
                        }
                        const signature = {
                          org: "",
                          inv: "",
                        };

                        if (localStorage.getItem("drawnSig-org")) {
                          signature.org = localStorage.getItem("drawnSig-org");
                          signature.inv = localStorage.getItem("drawnSig-inv");
                        } else if (localStorage.getItem("typedSig-org")) {
                          signature.org = localStorage.getItem("typedSig-org");
                          signature.inv = localStorage.getItem("typedSig-inv");
                        } else {
                          // TODO: Handle errors
                          setGeneralError(true);
                          //console.log("error");
                        }
                        const token = await AuthorizeService.getAccessToken();
                        await axios.get(`/api/Payment/${account.user.paymentRailsId}/UpdatePaymentInformation`, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          }
                        });
                        await loadUser();
                        history.push("/account/details")
                      } else {
                        setPayPalUserCreate(false);
                        setPayPalUserError(true);
                        setPayPalUserErrorMsg("Something Went Wrong");
                      }
                    })
                    .catch((err) => {
                      setPayPalUserCreate(false);
                      setPayPalUserError(true);
                      setPayPalUserErrorMsg(err);
                    });
                }
              })
              .catch((err) => {
                setPayPalUserCreate(false);
                setPayPalUserError(true);
                setPayPalUserErrorMsg(err);
              });
          }
        })
        .catch((err) => {
          setPayPalUserCreate(false);
          setPayPalUserError(true);
          setPayPalUserErrorMsg(err);
        });
    }
  }, [account]);

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="pl-2-em pr-2-em margin-auto"
        style={{
          maxWidth: "45em",
        }}
      >
        {payPalUserCreate && !payPalUserError && (
          <>
            <PikchaTitle className="justify-center pb-1-em" medium smaller>
              Promoting User to Artist
            </PikchaTitle>
            <PikchaSpacer/>
            <PikchaLoader/>
          </>
        )}
        {payPalUserUpdate && !payPalUserError && (
          <>
            <PikchaTitle className="justify-center pb-1-em" medium smaller>
              Updating Paypal Details
            </PikchaTitle>
            <PikchaSpacer/>
            <PikchaLoader/>
          </>
        )}
        {payPalUserError && (
          <>
            <PikchaTitle className="justify-center pb-1-em" medium smaller>
              Payment Setup Failed
            </PikchaTitle>
            <div>
              Something went wrong. Please try again, or contact the Pikcha team
              at Pikcha admin@pikcha.com
            </div>
            {/*<div>{payPalUserErrorMsg}</div>*/}
            <div>For more information please contact our support team.</div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  settings: state.settingsReducer,
});

export default connect(mapStateToProps, {
  promoteToArtist,
  initOnboardingFlow,
  loadUser
})(VerifyPayPal);
