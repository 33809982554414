import React, { cloneElement } from "react";
import {
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { ICON_SIZE_SMALL } from "../../../helpers/constants";
import { useTheme } from "@material-ui/core/styles";
import {PikchaLink} from "../../baseComponents/PikchaLink";

/**
 * Accepts EITHER a `to` prop (which should be a link to another area of the
 * app, OR an `onClick` callback prop.
 */
const NotificationOptionsItem = ({ to, onClick, text, icon }) => {
  const { palette, typography } = useTheme();

  return (
    <ListItem
      button
      component={to ? (
        props => <PikchaLink to={to} {...props} />
      ) : "div"}
      onClick={!onClick ? undefined : onClick}
      style={{
        fontSize: typography.small,
      }}
    >
      {icon && (
        <ListItemIcon>
          {cloneElement(icon, {
            style: ICON_SIZE_SMALL,
          })}
        </ListItemIcon>
      )}
      <span
        style={{
          //fontWeight: typography.fontWeightMedium,
          color: palette.text.primary,
        }}
      >
        {text}
      </span>
    </ListItem>
  );
};

export default NotificationOptionsItem;
