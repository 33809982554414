import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";

export default withStyles((_) => ({
  root: ({ uploadStep }) => ({
    padding: uploadStep < 3 ? "64px 0 0 0" : "0 0 0 0",
    // overflowY: "hidden",
    //height: 633,
  }),
}))(DialogContent);
