import {
  GET_USED_SIGNATURE,
  GET_USED_SIGNATURE_ERR,
  HANDLE_ACCOUNT_LOAD_FROM_ONBOARDING,
  HANDLE_SAFE_SEARCH,
  HANDLE_UPLOADED,
  JOYRIDE_ACTIVE_STEP,
  JOYRIDE_SHOWN,
  JOYRIDE_WAS_TRIGGERED,
  LOGGED_OUT,
  RESET_UPDATED_FIELDS,
  SIGNATURE_LOADED,
  SIGNATURE_LOADING,
  SIGNATURE_REMOVED,
  USER_ERROR,
  USER_LOADED,
  USER_LOADING,
  USER_SIGNATURE_UPDATED,
  USER_TOKEN,
  USER_UPDATED,
  USER_PAYMENT_CHANGED
} from "../actions/types";

const initialState = {
  user: null,
  loadingUser: true,
  loadingSignature: true,
  errors: null,
  showUploadJoyride: true,
  signature: "",
  userSignatureSVG: "",
  token: '',

  fromOnboarding: false,

  updatedBio: "",
  updatedNationality: "",
  updatedFacebookUrl: "",
  updatedInstagramUrl: "",
  updatedLinkedinUrl: "",

  joyrideActiveStep: 1,
  joyrideWasTriggered: false,

  usedSignatureStart: 1,
  usedSignatureCount: 3,
  usedSignatureList: [],
  hasMoreUsedSignature: true
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        user: {
          ...payload,
        },
        loadingUser: false,
      };
    case JOYRIDE_SHOWN:
      return {
        ...state,
        showUploadJoyride: false
      };
    case USER_SIGNATURE_UPDATED:
      return {
        ...state,
        userSignatureSVG: payload,
      };
    case USER_TOKEN: {
      return {
        ...state,
        token: payload,
      };
    }
    case HANDLE_SAFE_SEARCH:
    // TODO: Is something meant to happen here?
    // return {
    //   ...state,
    // }
    case USER_UPDATED:
      return {
        ...state,
        user: payload,
        loadingUser: false,

        updatedBio: payload.bio,
        updatedNationality: payload.nationality,

        updatedFacebookUrl: payload.links.FacebookUrl,
        updatedInstagramUrl: payload.links.InstagramUrl,
        updatedLinkedinUrl: payload.links.LinkedinUrl
      };
    case LOGGED_OUT:
      return {
        ...state,
        user: null,
        loadingUser: false,
      };
    case HANDLE_UPLOADED:
      return {
        ...state,
        user: {
          ...state.user,
          hasUploaded: true
        }
      };
    case USER_ERROR:
      return {
        ...state,
        errors: payload,
        loadingUser: false,
      };
    case SIGNATURE_LOADED:
      return {
        ...state,
        signature: payload,
        loadingSignature: false,
      };
    case USER_LOADING:
      return {
        ...state,
        loadingUser: true,
      };
    case SIGNATURE_LOADING:
      return {
        ...state,
        loadingSignature: true,
      };
    case HANDLE_ACCOUNT_LOAD_FROM_ONBOARDING:
      return {
        ...state,
        fromOnboarding: true,
      };
    case RESET_UPDATED_FIELDS:
      return {
        ...state,
        updatedBio: "",
        updatedNationality: "",
        updatedFacebookUrl: "",
        updatedInstagramUrl: "",
        updatedLinkedinUrl: "",
      };
    case SIGNATURE_REMOVED:
      return {
        ...state,
        signature: "",
        user: {
          ...state.user,
          sign: ""
        }
      };
    case USER_PAYMENT_CHANGED:
      if(payload.paymentMethod === "bank-transfer"){
        return {
          ...state,
          user: {
            ...state.user,
            paymentMethod: payload.paymentMethod,
            paymentCurrency: payload.currency,
            accountNumber: payload.accountNum,
            branchId: payload.branchId,
            bankName: payload.bankName,
            artistFee: payload.recipientFees
          }
        }
    } else if(payload.paymentMethod === "paypal") {
      return {
        ...state,
        user: {
          ...state.user,
          paymentMethod: payload.paymentMethod,
          paypalEmail: payload.paypalEmail,
          paypalVerification: payload.paypalVerification
        }
      }
    } else {
      return {
        ...state,
        user: {
          ...state.user,
          paymentMethod: ""
        }
      }
    }
    case JOYRIDE_ACTIVE_STEP:
      return {
        ...state,
        joyrideActiveStep: payload
      };
    case JOYRIDE_WAS_TRIGGERED:
      return {
        ...state,
        joyrideWasTriggered: payload
      };
    case GET_USED_SIGNATURE:
      return {
        ...state,
        usedSignatureList: [...state.usedSignatureList, ...payload],
        usedSignatureStart: state.usedSignatureStart + state.usedSignatureCount,
      };
    case GET_USED_SIGNATURE_ERR:
      return {
        ...state,
        hasMoreUsedSignature: false
      };
    default:
      return state;
  }
};
