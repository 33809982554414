import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import CropPortraitIcon from "@material-ui/icons/CropPortrait";
import CropLandscapeIcon from "@material-ui/icons/CropLandscape";

export default ({ onClick, selectedOrientation, orientation }) => {
  const [isHovering, setIsHovering] = useState(false);

  const { palette } = useTheme();

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      style={{
        ...{
          width: "86px",
          height: "64px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
        },
        ...(selectedOrientation === orientation || isHovering
          ? {
              backgroundColor: palette.grey.lighter,
            }
          : {}),
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      onClick={() => {
        setIsHovering(false);
        onClick(orientation);
      }}
    >
      {orientation === "landscape" && (
        <CropLandscapeIcon
          style={{
            width: 48,
            height: 48,
            color: palette.grey.mediumDark,
          }}
        />
      )}
      {orientation === "portrait" && (
        <CropPortraitIcon
          style={{
            width: 48,
            height: 48,
            color: palette.grey.mediumDark,
          }}
        />
      )}
      {orientation === "square" && (
        <CropSquareIcon
          style={{
            width: 48,
            height: 48,
            color: palette.grey.mediumDark,
          }}
        />
      )}
    </div>
  );
};
