import React, {useCallback, useEffect, useState,} from "react";
import {Helmet} from "react-helmet";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { CardElement } from "@stripe/react-stripe-js";
import ExpiredItems
  from "../../components/checkoutComponents/ExpiredItems/ExpiredItems";
import AuthorizeService from "../../auth/AuthorizeService";
import { getCookie } from "../../helpers/utils";
import {
  addProductToShoppingCart,
  addShippingOption,
  getShoppingCart,
  notifyQueueStatus,
  removeProductFromShoppingCart,
  updateTheCart,
} from "../../actions/shoppingCart";
import {
  hideNotification,
  showNotification,
  updateNotificationItem,
  updateNotificationType,
} from "../../actions/notification";
import { removeTimer } from "../../actions/timer";
import { setAlert } from "../../actions/alert";
import {
  id,
  queuedShoppingCartItems,
  readyShoppingCartItems
} from "../../selectors/shoppingCart";
import { PikchaAccordion } from "../../components/baseComponents";
import isDesktopHD from "../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isTablet from "../../components/Responsive/isTablet";
import isMobile from "../../components/Responsive/isMobile";
import Step1Desktop from "../../containers/checkout/Step1Desktop";
import Step2Desktop from "../../containers/checkout/Step2Desktop";
import Step3 from "../../containers/checkout/Step3";
import Step6Desktop from "../../containers/checkout/Step6Checkout";
import {sendCriticalException} from "../../helpers/utils";
import Cart from "../../components/checkoutComponents/CartDetails/Cart";
import { CheckoutOverlay } from "../../components/checkoutComponents/CheckoutOverlay";
import { makeStyles } from "@material-ui/core/styles";

const steps = ["Confirm Items", "Shipping", "Checkout"];

const useStyles = ({ isOnDesktopHD, isOnDesktopSD, isOnTablet, isOnMobile }) => makeStyles(() => ({
  checkoutContainer: {
    flexGrow: 1,
    marginTop: "5em",
    marginLeft: isOnDesktopHD ? "4em" : isOnDesktopSD ? "2em" : isOnTablet ? "1em" : "0.5em",
    marginRight: isOnDesktopHD ? "4em" : isOnDesktopSD ? "2em" : isOnTablet ? "1em" : "0.5em",
    display: "flex",
    justifyContent: "center",
    maxWidth: "100%",
    flexDirection: isOnMobile ? "column-reverse" : undefined,
  },
  cartContainer: {
    flex: 1,
    width: "100%",
    maxWidth: 768
  },
  checkoutSubContainer: {
    width: "100%",
    maxWidth: 850,
    flex: 2,
  },
  summaryExpanded: {
    minHeight: '24px'
  }
}));

const Checkout = ({
  id,
  shoppingCart,
  account,
  notification,
  getShoppingCart,
  removeProductFromShoppingCart,
  addProductToShoppingCart,
  hideNotification,
  showNotification,
  updateNotificationType,
  updateNotificationItem,
  notifyQueueStatus,
  removeTimer,
  addShippingOption,
  updateTheCart,
  setAlert,
  queuedShoppingCartItems,
  readyShoppingCartItems
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  const classes = useStyles({ isOnDesktopHD, isOnDesktopSD, isOnTablet, isOnMobile })();
  const checkoutSteps = [1, 2, 3, 4, 5, 6];

  const userPreferencesReducer = useSelector(({ userPreferencesReducer }) => userPreferencesReducer);

  const areThereQueuedShoppingCartItems = queuedShoppingCartItems.length > 0;
  const areThereReadyShoppingCartItems = readyShoppingCartItems.length > 0;

  const [checkoutStep, setCheckoutStep] = useState(1);
  const [shippingCountries, setShippingCountries] = useState([]);
  const counter = { min: 8, seconds: 0 };
  const [country, setCountry] = useState(
    account.user
      ? account.user.shipCountry
      : null
  );
  const [countryError, setCountryError] = useState();
  const [countryState, setCountryState] = useState();
  const [countryStateError, setCountryStateError] = useState();
  const [allShipTypes, setAllShipTypes] = useState({
    price: [],
    pricePresentments: [],
    estDelTime: [],
    shipType: [],
  });

  const shipType = shoppingCart.shoppingCart?.shipType;
  let shippingEstTime;
  let shipTypeIndex = -1;
  allShipTypes.shipType.forEach((item, index) => {
    if (item === shipType) {
      shipTypeIndex = index;
    }
  });
  if (shipTypeIndex > -1) {
    shippingEstTime = allShipTypes.estDelTime[shipTypeIndex];
  }

  const handleCheckoutStep = useCallback((step) => {
    setCheckoutStep(step);
  }, []);

  const {user} = account;
  const {
    fName,
    lName,
    email: userEmail,
    phoneNumber: userPhoneNumber,
    shipAddr1,
    shipAddr2,
    shipCity: shippingCity,
    shipCountry,
    shipPostal: shippingPostal,
    shipState: shippingState
  } = user || {};

  const [fullName, setFullName] = useState(fName && lName ? `${fName} ${lName}` : "");
  const [email, setEmail] = useState(userEmail);
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber);
  const [shipAddress1, setShipAddress1] = useState(shipAddr1);
  const [shipAddress2, setShipAddress2] = useState(shipAddr2);
  const [shipCity, setShipCity] = useState(shippingCity);
  const [shipPostal, setShipPostal] = useState(shippingPostal);
  const [shipState, setShipState] = useState(shippingState);
  const [shippingCountry, setShippingCountry] = useState(shipCountry);

  useEffect(() => {
    setFullName(fName && lName ? `${fName} ${lName}` : "")
    setEmail(userEmail);
    setPhoneNumber(userPhoneNumber);
    setShipAddress1(shipAddr1);
    setShipAddress2(shipAddr2);
    setShipCity(shippingCity);
    setShipPostal(shippingPostal);
    setShipState(shippingState);
    setShippingCountry(shipCountry);

    if (!country) {
      setCountry(shipCountry);
    }
    if (!countryState) {
      setCountryState(shipState);
    }
  }, [account]);

  const [billToShippingAddress, setBillToShippingAddress] = useState(true);
  const [billAddress1, setBillAddress1] = useState();
  const [billAddress2, setBillAddress2] = useState();
  const [billCity, setBillCity] = useState();
  const [billPostal, setBillPostal] = useState();
  const [billState, setBillState] = useState();
  const [billCountry, setBillCountry] = useState();
  const [addShippingInProgress, setAddShippingInProgress] = useState();
  const [discount, setDiscount] = useState();
  const [cardError, setCardError] = useState();
  const [cardProcessing, setCardProcessing] = useState();
  const [cardComplete, setCardComplete] = useState();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentSuccessResponse, setPaymentSuccessResponse] = useState(null);
  const [allShippingStates, setAllShippingStates] = useState([]);
  const [pendingDiscountCode, setPendingDiscountCode] = useState(false);
  const [IsStep2Filled, setStep2Filled] = useState(false);
  const [cardType, setCardType] = useState("");
  const [cardLast4digit, setCardLast4digit] = useState("");
  const [readyToResetPrice, setReadyToResetPrice] = useState(false);
  const [shippingOptionError, setShippingOptionError] = useState(false);

  useEffect(() => {
    axios.get("/api/Postal/ShippingCountries").then((response) => {
      const countries = response.data.map((item) => ({
        ...item,
        label: item.country,
        iso: item.jondO_ISO,
      }));
      setShippingCountries(countries);
    });
  }, []);

  const getShippingOptions = () => {
    axios.get(`api/Postal/ShippingStates/`).then((resp) => {
      let result = resp.data.map(({state, zone, iso}) => ({
        label: state,
        region: zone,
        iso,
      }));
      setAllShippingStates(result);
    });
  };

  useEffect(() => {
    if (!account.user && id) {
      localStorage.setItem("pikcha-cart-id", id);
    }
  }, [account.user, id]);


  useEffect(() => {
    getShippingOptions();
  }, [country]);

  useEffect(() => {
    if (countryState) {
      refreshShippingState();
    }
  }, [country, countryState]);

  const deleteItem = async (shoppingItemId) => {
    await removeProductFromShoppingCart(id, shoppingItemId);
    notifyQueueStatus(id);
    removeTimer(shoppingItemId);
  };

  const addBackToCart = (shoppingCartItem, onDone) => {
    let id;
    if (shoppingCart.shoppingCart && shoppingCart.shoppingCart.id && shoppingCart.shoppingCart.id !== "undefined") {
      id = shoppingCart.shoppingCart.id;
    } else {
      id = account.user.id;
    }
    hideNotification();
    addProductToShoppingCart(id, {
      previousShoppingItemId: shoppingCartItem.id,
      ImageId: shoppingCartItem.imageId,
      CustomizationIdentifier: shoppingCartItem.customizationIdentifier,
      Wrap: shoppingCartItem.wrap,
      ImageRender: shoppingCartItem.imageRender,
      SelectedProductNumber: shoppingCartItem.productNumber,
      BorderSize: shoppingCartItem.border,
      FrameColor: shoppingCartItem.frame
    }).then((resp) => {
      if(resp?.items?.length > 0){
        onDone();
        updateNotificationType("cartItemNotification");
        if (resp?.items?.length > 0) {
          const newShoppingCartItem = resp.items[resp.items.length - 1];
          updateNotificationItem({
            id: newShoppingCartItem.id,
            currentTime: notification.currentTime,
            startTimer: false,
            image: newShoppingCartItem,
          });
        }
        showNotification();
      }
    });
  };

  const onCountryChanged = ({
    country: newCountry,
    countryError: newCountryError,
  }) => {
    setCountry(newCountry);
    setCountryState('')
    setShipState('')
    setCountryError(newCountryError);
  };

  const onCountryStateChanged = ({ state }) => {
    setCountryState(state);
    setShipState(state)
    setCountryStateError(null);
    refreshShippingState();
  };

  const addShipType = async (shipType) => {
    if (country === "United States") {
      if (!countryState) {
        setCountryStateError(true);
        return;
      } else {
        setCountryStateError(false);
      }
    }
    if (shoppingCart.shoppingCart && country) {
      const validId = id ? id : account.user.id;
      addShippingOption(validId, {
        country,
        selectedOption: shipType,
        state: countryState ? countryState : undefined,
      }, () => {
        setShippingOptionError(false);
      }, () => {
        setAllShipTypes({
          price: [],
          pricePresentments: [],
          estDelTime: [],
          shipType: [],
        })
        setShippingOptionError(true);
      });
    }
  };

  const refreshShippingState = () => {
    if (countryState) {
      const csrfToken = getCookie("XSRF-TOKEN");
      const validId = id ? id : account.user.id;
      axios
        .get(
          `api/shoppingcart/${country}/getShippingOptions?state=${countryState}&id=${validId}`,
          {
            headers: {
              "X-XSRF-TOKEN": csrfToken,
            },
          }
        )
        .then((resp) => {
          setAllShipTypes(resp.data);
          addShipType(resp.data.shipType[0]);
          setReadyToResetPrice(true);
        });
    } else if (
      shoppingCart.shoppingCart &&
      !shoppingCart.shoppingCart.shipCountry
    ) {
      setCountryStateError(true);
    }
  };

  const refreshShippingForCountry = () => {
    const csrfToken = getCookie("XSRF-TOKEN");
    axios
      .get(`api/shoppingcart/${country}/getShippingOptions?id=${id}`, {
        headers: {
          "X-XSRF-TOKEN": csrfToken,
        },
      })
      .then((resp) => {
        setAllShipTypes(resp.data);
        addShipType(resp.data.shipType[0]);
        setReadyToResetPrice(true);
      });
  };

  useEffect(() => {
    setAllShipTypes({
      ...allShipTypes,
      pricePresentments: [shoppingCart?.shoppingCart?.shipCostPresentment]
    });
    setReadyToResetPrice(false);
  }, [readyToResetPrice, shoppingCart?.shoppingCart?.shipCost])

  const onFullNameChanged = (event) => {
    setFullName(event.currentTarget.value);
  };
  const onEmailChanged = (event) => {
    setEmail(event.currentTarget.value);
  };
  const onPhoneNumberChanged = (event) => {
    setPhoneNumber(event);
  };
  const onShipAddress1Changed = (event) => {
    setShipAddress1(event.currentTarget.value);
  };
  const onShipAddress2Changed = (event) => {
    setShipAddress2(event.currentTarget.value);
  };
  const onShipCityChanged = (event) => {
    setShipCity(event.currentTarget.value);
  };
  const onShipPostalChanged = (event) => {
    setShipPostal(event.currentTarget.value);
  };
  const onShipStateChanged = (event) => {
    setShipState(event.currentTarget.value);
  };
  const handleBillToShippingAddress = () => {
    const newBillToShippingAddress = !billToShippingAddress;
    setBillToShippingAddress(newBillToShippingAddress);
  };
  const onBillAddress1Changed = (event) => {
    setBillAddress1(event.currentTarget.value);
  };
  const onBillAddress2Changed = (event) => {
    setBillAddress2(event.currentTarget.value);
  };
  const onBillCityChanged = (event) => {
    setBillCity(event.currentTarget.value);
  };
  const onBillPostalChanged = (event) => {
    setBillPostal(event.currentTarget.value);
  };
  const onBillStateChanged = (event) => {
    setBillState(event.currentTarget.value);
  };
  const onBillCountryChanged = (event) => {
    setBillCountry(event.currentTarget.value);
  };
  const onDiscountChange = (event) => {
    setDiscount(event.target.value);
  };

  const handleDiscountCode = async (discountCode) => {
    if (shoppingCart.shoppingCart?.discountCode === discountCode) {
      setAlert("Do not apply the same discount code", "error");
      return;
    }
    setPendingDiscountCode(true);
    const token = await AuthorizeService.getAccessToken();
    axios.post(
      `${process.env.NODE_ENV === "production"
        ? ""
        : "https://pikcha.local:1900"
      }/v1/shopping/shoppingAggregator/ApplyDiscount`, {
      basketId: id ? id : "",
      discountCode: discountCode
    }, {
      headers: {
        Authorization: `Bearer ${token}`
        // ,
        // "Content-Type": "text/plain;charset=UTF-8",
      },
    }).then((res) => {
      setPendingDiscountCode(false);
      if (res.data === "Discount Applied!") {
        setAlert(res.data, "success");
        getShoppingCart(id);
      } else {
        setAlert(res.data, "error");
      }
    }).catch((err) => {
      setPendingDiscountCode(false);
      console.log(err);
    });
  };

  const validateShipping = () => {
    if (!billToShippingAddress) {
      if (
        !billAddress1 ||
        !billCity ||
        !billPostal ||
        !billState ||
        !billCountry
      ) {
        setStep2Filled(false);
        return false;
      }
    }

    const infoList = [fullName, email, phoneNumber, shipAddress1, shipCity, shipPostal, shipState, country];
    const infoListName = ["Full Name", "Email", "Phone Number", "Address Line1", "Town/City", "Postcode", "State", "country"];
    if (
      !shipAddress1 ||
      !shipCity ||
      !shipPostal ||
      !fullName ||
      !email ||
      !phoneNumber ||
      !shipState ||
      !country
    ) {
      setStep2Filled(false);
      let inValidInfo = [];
      infoList.map((item, index) => {
        if (!item) {
          inValidInfo.push(index);
        }
      });
      const firstMissedField = infoListName[inValidInfo[0]];
      const dto = {
        firstMissedField: firstMissedField,
        value: false
      }
      return dto;
    } else {
      setStep2Filled(true);
      const dto = {
        firstMissedField: "",
        value: true
      }
      return dto;
    }
  };

  const onCardFieldChange = (event) => {
    let message;
    if (event.error) {
      message = event.error.message;
    }
    setCardError(message);
    setCardComplete(event.complete);
  };
  const handlePaymentSubmission = (event, stripe, elements) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (cardError) {
      elements.getElement("card").focus();
      return;
    }
    if (cardComplete) {
      setCardProcessing(true);
    }
    const codeCountry = billToShippingAddress ? country : billCountry;
    let code = "";
    shippingCountries.forEach((country) => {
      if (country.label === codeCountry) {
        code = country.iso;
      }
    });

    const name = fullName;
    const state = !billToShippingAddress
      ? billState
      : country === "United States"
        ? countryState
        : shipState;

    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            city: billToShippingAddress ? shipCity : billCity,
            country: code,
            line1: billToShippingAddress ? shipAddress1 : billAddress1,
            line2: billToShippingAddress ? shipAddress2 : billAddress2,
            postal_code: billToShippingAddress ? shipPostal : billPostal,
            state,
          },
          email,
          name,
        },
      })
      .then((payload) => {
        if (payload.error) {
          const {message} = payload.error;
          setCardError(message);
          setCardProcessing(false);
        } else {
          const {paymentMethod} = payload;
          const {
            id: paymentMethodId,
            card: {last4, brand},
          } = paymentMethod;

          setCardLast4digit(last4);
          setCardType(brand);
          nextStep();
          const csrfToken = getCookie("XSRF-TOKEN");
          const currencySymbol = ((userPreferencesReducer &&
            userPreferencesReducer.currencyOptions &&
            userPreferencesReducer.currencyOptions.symbol) || "$")
          AuthorizeService.getAccessToken().then((token) =>
            axios
              .post(
                "api/shoppingcart/" +
                id +
                "/createOrder?paymentMethodId=" +
                paymentMethodId +
                "&id=" +
                id,
                {
                  last4,
                  brand,
                  currencySymbol,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "X-XSRF-TOKEN": csrfToken,
                  },
                }
              )
              .then((resp) => {
                if (resp.status === 200) {
                  if (resp.data.status === "1") {
                    onPaymentSuccess(resp);
                  } else {
                    prevStep();
                    onOrderError(resp.data);
                  }
                } else {
                  prevStep();
                  setCardProcessing(false);
                  onOrderError(resp);
                }
                setCardProcessing(false);
              })
              .catch((err) => {
                console.log("createPaymentMethod ", err);
                prevStep();
                setCardProcessing(false);
                onOrderError(err);
                sendCriticalException("create order failed, endpoint: /createOrder?paymentMethodId=", `userId: ${id} err-info: ${err}`);
              })
          );
        }
      });
  };

  const onOrderError = (err) => {
    if (err.stripeStatus) {
      setAlert(err.stripeStatus, "error");
    } else {
      setAlert("Your Order Could Not be Placed", "error");
    }
    setPaymentSuccess(false);
  };

  const onPaymentSuccess = (response) => {
    setAlert("Payment Successful", "success");
    setPaymentSuccess(true);
    setPaymentSuccessResponse(response.data);
    getShoppingCart(id);
  };

  useEffect(() => {
    if (paymentSuccess && checkoutStep === 4) {
      nextStep();
    }
  }, [paymentSuccess]);

  useEffect(() => {
    if (checkoutStep === 5) {
      setTimeout(() => {
        nextStep();
      }, 3000);
      if (paymentSuccessResponse && paymentSuccessResponse.orderNumber) {
        window.history.pushState({}, "", `/order/${paymentSuccessResponse.orderNumber}`);
      }
    }
  }, [checkoutStep]);

  const prevStep = () => {
    setCheckoutStep(checkoutStep - 1);
  };

  const nextStep = () => {
    if (checkoutStep === 1 && !country) {
      setAlert("Please enter your shipping country", "error");
      return false;
    }
    if (checkoutStep === 1 && country === "United States" && !countryState) {
      setAlert("Please enter your shipping state", "error");
      return false;
    }
    if (allShippingStates && countryState && country === "United States") {
      const defaultStateValue = (allShippingStates || []).filter((item) => {
        return item.label.toLowerCase() === countryState.toLowerCase();
      });
      if (!defaultStateValue[0]) {
        return false;
      }
    }
    if (checkoutStep === 2) {
      let valid = validateShipping();
      if (!valid.value) {
        setAlert(`Please fill in the ${valid.firstMissedField} fields`, "error");
        return;
      }

      const firstName = fullName.split(" ")[0];
      const lastName = fullName.split(" ")[1];
      const dtoInfo = {
        fName: firstName,
        lName: lastName ? lastName : "",
        phoneNumber,
        email,
        shipAddr1: shipAddress1,
        shipAddr2: shipAddress2,
        shipCity,
        shipPostal,
        shipState: country === "United States" ? countryState : shipState,
        shipCountry: country,
      };
      const billingInfo = {
        BillAddr1: billToShippingAddress ? shipAddress1 : billAddress1,
        BillAddr2: billToShippingAddress ? shipAddress2 : billAddress2,
        BillCity: billToShippingAddress ? shipCity : billCity,
        BillPostal: billToShippingAddress ? shipPostal : billPostal,
        BillState: billToShippingAddress ? country === "United States"
          ? countryState
          : shipState
          : billState,
        BillCountry: billToShippingAddress ? country : billCountry,
      };
      setAddShippingInProgress(true);
      updateTheCart(
        id,
        dtoInfo,
        billingInfo,
        ({shipInfoResponse}) => {
          if (shipInfoResponse) {
            const {errorMessage, verificationStatus} = shipInfoResponse;
            if (
              verificationStatus === "Y" &&
              (!errorMessage || errorMessage === "")
            ) {
              setCheckoutStep(3);
              setAddShippingInProgress(false);
            } else {
              setCheckoutStep(2);
              setAddShippingInProgress(false);
              setAlert("Enter valid shipping details", "error");
            }
          }
        },
        (err) => {
          setCheckoutStep(2);
          setAddShippingInProgress(false);
        }
      );
    }
    if (checkoutStep !== 6) {
      setCheckoutStep(checkoutStep + 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>Checkout | Pikcha</title>
      </Helmet>
      <div className={classes.checkoutContainer} >
        <div className={classes.checkoutSubContainer} >
          {checkoutStep !== 6 && (
            <>
              {(checkoutStep === 4 || checkoutStep === 5) && (
                <CheckoutOverlay step={checkoutStep} />
              )}
              {checkoutSteps.map((step) => (
                <PikchaAccordion
                  key={step}
                  setActiveStep={handleCheckoutStep}
                  title={step === 1 ? "Shipping" : step === 2 ? "Delivery and Details" : "Payments and discounts"}
                  routePrefix="/checkout"
                  activeStep={checkoutStep}
                  forceCollapse={checkoutStep !== 1 && !areThereReadyShoppingCartItems}
                  step={step}
                  smallTitle
                  accordianSummaryStyles={{ minHeight: '2em', maxHeight: '2em', margin: '10px 0 0' }}
                  accordianStyles={{ fontSize: isOnMobile ? '1rem' : '1.125rem', padding: '0.5rem 0.5rem 1.5rem' }}
                  iconButtonStyles = {{boxShadow: 'none'}}
                >
                  {step === 1 ? (
                    <Step1Desktop
                      shippingOptionError={shippingOptionError}
                      setShippingOptionError={setShippingOptionError}
                      checkoutStep={checkoutStep}
                      steps={steps}
                      shippingCountries={shippingCountries}
                      counter={counter}
                      deleteItem={deleteItem}
                      addBackToCart={addBackToCart}
                      country={country}
                      countryError={countryError}
                      onCountryChanged={onCountryChanged}
                      onCountryStateChanged={onCountryStateChanged}
                      refreshShippingForCountry={refreshShippingForCountry}
                      refreshShippingState={refreshShippingState}
                      countryState={countryState}
                      countryStateError={countryStateError}
                      shipType={shipType}
                      allShipTypes={allShipTypes}
                      addShipType={addShipType}
                      nextStep={nextStep}
                      shippingCountry={shippingCountry}
                      shipState={shipState}
                      allShippingStates={allShippingStates}
                      areThereReadyShoppingCartItems={areThereReadyShoppingCartItems}
                      areThereQueuedShoppingCartItems={areThereQueuedShoppingCartItems}
                    />
                  ) : step === 2 ? (
                    <Step2Desktop
                      checkoutStep={checkoutStep}
                      steps={steps}
                      fullName={fullName}
                      onFullNameChanged={onFullNameChanged}
                      email={email}
                      phoneNumber={phoneNumber}
                      onEmailChanged={onEmailChanged}
                      onPhoneNumberChanged={onPhoneNumberChanged}
                      shipAddress1={shipAddress1}
                      onShipAddress1Changed={onShipAddress1Changed}
                      shipAddress2={shipAddress2}
                      onShipAddress2Changed={onShipAddress2Changed}
                      shipCity={shipCity}
                      onShipCityChanged={onShipCityChanged}
                      shipPostal={shipPostal}
                      onShipPostalChanged={onShipPostalChanged}
                      shipState={shipState}
                      countryState={countryState}
                      onShipStateChanged={onShipStateChanged}
                      country={country}
                      billToShippingAddress={billToShippingAddress}
                      handleBillToShippingAddress={handleBillToShippingAddress}
                      billAddress1={billAddress1}
                      onBillAddress1Changed={onBillAddress1Changed}
                      billAddress2={billAddress2}
                      onBillAddress2Changed={onBillAddress2Changed}
                      billCity={billCity}
                      onBillCityChanged={onBillCityChanged}
                      billPostal={billPostal}
                      onBillPostalChanged={onBillPostalChanged}
                      billState={billState}
                      onBillStateChanged={onBillStateChanged}
                      billCountry={billCountry}
                      onBillCountryChanged={onBillCountryChanged}
                      addShippingInProgress={addShippingInProgress}
                      prevStep={prevStep}
                      nextStep={nextStep}
                      IsStep2Filled={IsStep2Filled}
                      areThereReadyShoppingCartItems={areThereReadyShoppingCartItems}
                      areThereQueuedShoppingCartItems={areThereQueuedShoppingCartItems}
                    />
                  ) : (
                    <Step3
                      checkoutStep={checkoutStep}
                      steps={steps}
                      prevStep={prevStep}
                      nextStep={nextStep}
                      discount={discount}
                      onDiscountChange={onDiscountChange}
                      handleDiscountCode={handleDiscountCode}
                      email={email}
                      shipAddress1={shipAddress1}
                      shipAddress2={shipAddress2}
                      shipCity={shipCity}
                      shipPostal={shipPostal}
                      country={country}
                      countryState={countryState}
                      shipState={shipState}
                      paymentSuccess={paymentSuccess}
                      handlePaymentSubmission={handlePaymentSubmission}
                      onCardFieldChange={onCardFieldChange}
                      cardProcessing={cardProcessing}
                      cardError={cardError}
                      pendingDiscountCode={pendingDiscountCode}
                    />
                  )}
                </PikchaAccordion>
              ))}
              {areThereQueuedShoppingCartItems &&
                <ExpiredItems
                  counter={counter}
                  deleteItem={deleteItem}
                  addBackToCart={addBackToCart}
                />
              }
            </>
          )}

          {checkoutStep === 6 && (
            <Step6Desktop
              checkoutStep={checkoutStep}
              steps={steps}
              paymentSuccessResponse={paymentSuccessResponse}
              fullName={fullName}
              email={email}
              phoneNumber={phoneNumber}
              shipAddress1={shipAddress1}
              shipAddress2={shipAddress2}
              shipCity={shipCity}
              shipPostal={shipPostal}
              country={country}
              countryState={countryState}
              shipState={shipState}
              billToShippingAddress={billToShippingAddress}
              billAddress1={billAddress1}
              billAddress2={billAddress2}
              billCity={billCity}
              billPostal={billPostal}
              billState={billState}
              billCountry={billCountry}
              shippingEstTime={shippingEstTime}
              cardType={cardType}
              cardLast4digit={cardLast4digit}
              preferredCurrency={account?.user?.preferredCurrency}
            />
          )}
        </div>
        <div
          className={classes.cartContainer}
        >
          <Cart
            checkoutStep={checkoutStep}
            steps={steps}
            shippingCountries={shippingCountries}
            counter={counter}
            deleteItem={deleteItem}
            addBackToCart={addBackToCart}
            country={country}
            countryError={countryError}
            onCountryChanged={onCountryChanged}
            onCountryStateChanged={onCountryStateChanged}
            refreshShippingForCountry={refreshShippingForCountry}
            refreshShippingState={refreshShippingState}
            countryState={countryState}
            countryStateError={countryStateError}
            shipType={shipType}
            allShipTypes={allShipTypes}
            addShipType={addShipType}
            nextStep={nextStep}
            shippingCountry={shippingCountry}
            shipState={shipState}
            allShippingStates={allShippingStates}
            paymentSuccessResponse={checkoutStep === 6 ? paymentSuccessResponse : null}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCartReducer,
  account: state.accountReducer,
  notification: state.notificationReducer,
  readyShoppingCartItems: readyShoppingCartItems(state),
  queuedShoppingCartItems: queuedShoppingCartItems(state),
  id: id(state),
});

export default connect(mapStateToProps, {
  getShoppingCart,
  removeProductFromShoppingCart,
  addProductToShoppingCart,
  hideNotification,
  showNotification,
  updateNotificationType,
  updateNotificationItem,
  notifyQueueStatus,
  removeTimer,
  addShippingOption,
  updateTheCart,
  setAlert
})(Checkout);
