import { useEffect } from "react";

export const usePayPal = (userLocale) => {
  const shouldUseSandbox = () =>
    process.env.REACT_APP_PAYPAL_LIVE_MODE === "false";

  const PAYPAL_API_KEY = shouldUseSandbox()
    ? process.env.REACT_APP_PAYPAL_TEST_ID
    : process.env.REACT_APP_PAYPAL_LIVE_ID;

  let PAYPAL_RETURN_URL = `${window.location.origin}/verify-paypal`;

  const PAYPAL_CONFIG = {
    ...(shouldUseSandbox() && {
      authend: "sandbox",
    }),
    appid: PAYPAL_API_KEY,
    scopes:
      "openid profile email address https://uri.paypal.com/services/paypalattributes https://uri.paypal.com/services/paypalattributes",
    containerid: "paypal-button",
    buttonShape: "rectangle",
    responseType: "code",
    locale:userLocale, 
    buttonType: "CWP",
    buttonSize: "lg",
    fullPage: "true",
    theme: "neutral",
    returnurl: PAYPAL_RETURN_URL,
  };
  window.paypal.use(["login"], (login) => {
    if (login) login.render(PAYPAL_CONFIG);
  });
};
