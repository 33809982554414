import React from 'react';
import { 
  TypographyBody, 
  TypographySubtitle, 
  TYPOGRAPHY_BODY_SIZE, 
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  colors 
} from "@pikcha/typography";
import { IntlCurrencyFormatter } from "../../../components/NumberFormatters/IntlCurrencyFormatter";

export default ({
  artworkNum,
  lowestPrice,
  highestPrice,
  targetCurrency
}) => {
  return (
    <div
      className='full-width'
      style={{
        border: "1px solid #DBE0E4",
        borderRadius: "8px",
        display: "flex",
        padding: "1em",
        justifyContent: "space-between",
      }}
    >
      <div 
        style={{
          width: "48%",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center"
        }}
      >
        <div>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
            color="neutral/grey50"
          >
            Artworks
          </TypographyBody>
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
          >
            {artworkNum}
          </TypographySubtitle>
        </div>
        <div 
          style={{
            width: "1px",
            height: "100%",
            backgroundColor: colors.Neutral.grey30
          }}
        />
      </div>
      <div
        style={{
          width: "48%"
        }}
      >
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
          color="neutral/grey50"
        >
          Price
        </TypographyBody>
        <TypographySubtitle
          component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
        >
          <IntlCurrencyFormatter targetCurrency={targetCurrency} />{lowestPrice} - <IntlCurrencyFormatter targetCurrency={targetCurrency} />{highestPrice}
        </TypographySubtitle>
      </div>
    </div>
  )
}
