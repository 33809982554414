import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export const UnitFormatter = ({value, appendUnit = false}: { value: string, appendUnit?: boolean }) => {

  // @ts-ignore
  const userPreferencesReducer = useSelector(state => state.userPreferencesReducer);
  const [formattedValue, setFormattedValue] = useState(`${value}`);

  useEffect(() => {
    const separator = (value.includes('×') && '×') || (value.includes('*') && '*');
    const stringArray = value.split(separator);

    if (userPreferencesReducer.preferredUnit.value === 'cm') {
      const convertedUnitsArray = stringArray.map((ele: string) => (Number.parseInt(ele) * 2.54).toFixed(1).toString());
      setFormattedValue(`${convertedUnitsArray.join('×')}${appendUnit ? ' cm' : ''}`);
    } else {
      setFormattedValue(`${stringArray.join('×')}${appendUnit ? '"' : ''}`);
    }
  }, [value, userPreferencesReducer.preferredUnit.value]);

  return (
    <span>{formattedValue}</span>
  );
};
