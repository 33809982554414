import React from 'react';
import { 
  colors,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT 
} from "@pikcha/typography";
import UserDetails from "../../components/UserDetails/UserDetails";
import isTablet from "../../components/Responsive/isTablet";

export default ({
  image
}) => {
  const isOnTablet = isTablet();
  return (
    <div 
      style={{
        backgroundColor: colors.Neutral.grey20,
        padding: "2em",
        marginTop: "2em",
        width: isOnTablet ? "90%" : "100%",
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      <TypographySubtitle
        component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
      >
        {image?.title}
      </TypographySubtitle>
      <UserDetails
        id={image?.artist?.id}
        userHandle={image?.artist?.userHandle}
        avatar={image?.artist?.avatar}
        nickName={image?.artist?.nickName}
        fName={image?.artist?.fName}
        lName={image?.artist?.lName}
      />
    </div>
  )
}
