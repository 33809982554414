import React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import {
  PikchaButton,
} from "../../../components/baseComponents";

const DeleteAccountModalStep2 = ({ onCancel, onStep3, isUserAnArtist, isOnMobile}) => {
  const { typography, palette } = useTheme();
  return (
          <>
            <div
              style={{
                maxWidth:"540px",
              }}
            >
              <p
                style={{
                  fontSize: isOnMobile? typography.larger : typography.xLarge,
                  color: palette.text.primary,
                }}
              >
                What Will Happen With Your Account
              </p>
              

              {isUserAnArtist ?
                (
                <>
                  <p
                    style={{
                      marginTop: "2em",
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    <strong>
                      Sales and Payments
                    </strong>
                  </p>
                  <p
                    style={{
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    The sale of your products will be immideaitely suspended. <br/>
                    Any outstanding orders which have been placed for your products will be processed and fufilled. 
                    Following the shipment of these products, you will receive any outstanding earnings owed to you 
                    at the next Payment Cycle.  
                  </p>


                  <p
                    style={{
                      marginTop: "2em",
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    <strong>
                      Your Images and Profile
                    </strong>
                  </p>
                  <p
                    style={{
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    If you choose to delete your account, your artwork listings and your profile page will remain on the site. 
                    You can see an example of a deleted profile page <a href="#" target="_blank">here, </a>
                    and an image from a deleted account <a href="#" target="_blank">here</a>. 
                  </p>

                  <p
                    style={{
                      marginTop: "2em",
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    <strong>
                      Pikcha's License
                    </strong>
                  </p>
                  <p
                    style={{
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    Pikcha will maintain its exclusive, perpetual, worldwide, royalty free, sublicensable and transferable rights and 
                    licence to your images which it has accrued over the duration of carrying out the Pikcha Services. Any images which
                    have not generated any sales via the platform will not be included in this and will be deleted from the platform.  
                  </p>

                  <p
                  style={{
                    marginTop: "3em",
                    fontSize: typography.small,
                    color: palette.text.primary,
                  }}
                >
                  For more information, we encourage users to review the <a href="/policies/user-agreement" target="_blank">User Agreement </a>
                </p>
                </>
                ): 
                <>
                  <p
                    style={{
                      marginTop: "2em",
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    <strong>
                      Your Profile
                    </strong>
                  </p>
                  <p
                    style={{
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    The listing of your profile pages will be removed immediately.  <br/>
                    All data relating to your profile page will be removed. 
                  </p>

                  <p
                    style={{
                      marginTop: "2em",
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    <strong>
                      Your Financial History
                    </strong>
                  </p>
                  <p
                    style={{
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    By law, we are required to keep a record of all financial history for up to 7 years.  <br/>
                  </p>

                  <p
                    style={{
                      marginTop: "2em",
                      marginBottom: "4em",
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                  >
                    For more information, we encourage users to review the <a href="/policies/user-agreement" target="_blank">User Agreement </a>
                  </p>
                </>
              }

              <div  
                style={{
                  marginTop: isUserAnArtist? "3em": "10em",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PikchaButton action="secondary"
                  style={{marginRight:"1em",}}
                  onClick={onCancel}
                >
                  Cancel
                </PikchaButton>
                <PikchaButton action="primary" onClick={onStep3}>
                {isOnMobile? (<>Continue</>): (<>Continue To Delete</>)}
                </PikchaButton>
              </div>
            </div>
          </>
  );
};

export default connect(null, {})(DeleteAccountModalStep2);
