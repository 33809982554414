import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import { useTheme } from "@material-ui/core/styles";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';

import { ICON_SIZE_DEFAULT } from "../../helpers/constants";

import { PikchaCheckbox } from "../../components/baseComponents";

export default ({
  style,

  imageCategories,
  imageCategory,
  onImageCategoryChange,
}) => {
  const { palette, typography } = useTheme();

  const [open, setOpen] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const [controlWidth, setControlWidth] = useState();

  const dropdown = useRef(null);

  const control = useRef(null);

  const handleResize = () => {
    const { width } = control.current
      ? window.getComputedStyle(control.current)
      : {};

    const widthValue = parseFloat(width);

    setControlWidth(widthValue);
  };
  useEffect(() => {
    handleResize();
  }, [control.current, isHovering]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClick = (e) => {
      if (
        dropdown &&
        dropdown.current &&
        !dropdown.current.contains(e.target) &&
        !control.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => document.removeEventListener("mousedown", handleClick);
  });

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  const onToggleDropdown = (e) => {
    e.preventDefault();
    if (!open) {
      setOpen(true);
    }
  };

  const imageCategoriesSelected = imageCategory.length;

  const dropdownWidth = 348;
  let dropdownMarginLeft = controlWidth - dropdownWidth;

  return (
    <div
      style={{
        cursor: "pointer",
        backgroundColor: palette.common.white,
        padding: "0.25em",
        ...style,
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      ref={control}
    >
      <div
        onClick={onToggleDropdown}
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: typography.smallest,
          fontWeight: typography.fontWeightMedium,

          ...(isHovering
            ? {
              color: palette.text.secondary,
            }
            : {
              color: palette.text.disabled,
            }),
        }}
      >
        <div
          style={{
            marginLeft: "0.25em",
            display: "flex",
          }}
        >
          <div>Category</div>
        </div>
        <div
          style={{
            marginLeft: "auto",
          }}
        >
          {imageCategoriesSelected > 0 && (
            <div
              style={{
                backgroundColor: palette.primary.medium,
                color: palette.common.white,
                borderRadius: 4,
                minWidth: 20,
                maxHeight: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {imageCategoriesSelected}
            </div>
          )}
        </div>
        <div
          style={{
            // marginLeft: "auto",
          }}
        >
          {!open && (
            <ArrowDropDownRoundedIcon
              style={{
                ...ICON_SIZE_DEFAULT,
              }}
            />
          )}
          {open && (
            <ArrowDropUpRoundedIcon
              style={{
                ...ICON_SIZE_DEFAULT,
              }}
            />
          )}
        </div>
      </div>
      {open && (
        <div
          style={{
            width: dropdownWidth,
            zIndex: 10,
            position: "absolute",
            marginTop: "0.25em",
            marginLeft: dropdownMarginLeft,
            backgroundColor: palette.common.white,
            borderRadius: 6,
            // border: `2px solid ${palette.grey.mediumLight}`,
            display: "flex",
            flexWrap: "wrap",
            paddingLeft: "0.5em",
            paddingBottom: "0.5em",
            boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.25), -2px 3px 4px 2px rgba(0, 0, 0, 0.25)"
          }}
          ref={dropdown}
        >
          {imageCategories.map((option, index) => {
            const { name } = option;
            const isChecked = imageCategory.some(
              (checkedType) => checkedType === name
            );
            return (
              <div
                key={index}
                style={{
                  marginRight: 0,
                  marginTop: "0.5em",
                }}
              >
                <div
                  style={{
                    marginLeft: 11,
                    width: 152,
                  }}
                >
                  <PikchaCheckbox
                    value={!!isChecked}
                    checked={!!isChecked}
                    onChange={() => {
                      onImageCategoryChange(name);
                    }}
                    label={name}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
