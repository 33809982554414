import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTheme } from "@material-ui/core/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";
import Step4 from "./Step4.jsx";
import Step5 from "./Step5.jsx";
import Step6 from "./Step6.jsx";
import Step8 from "./Step8.jsx";
import Step7 from "./Step7.jsx";
import { colors } from "@pikcha/typography";
import ImagePreview from "./ImagePreview";
import axios from "axios";
import { connect } from "react-redux";
import { setAlert } from '../../actions/alert.js';
import { MINIMUM_EARNINGS } from "../../helpers/constants";
import AuthorizeService from "../../auth/AuthorizeService";
import { getCropData } from "../../components/SignatureCanvas/utils/getViewBoxCropData";
import { handleArtistPromotionModal } from "../../actions/profile";
import {
  handleUploaded,
  updateUserSignature,
} from "../../actions/account";
import { sendCriticalException } from "../../helpers/utils";
import { Prompt, useHistory } from "react-router-dom";
import ConfirmAlert from "../../components/ConfirmAlert/ConfirmAlert";
import { isUserLoading } from "../../selectors/account";

const Upload = ({
  auth,
  account,
  cropper,
  brushStroke,
  updateUserSignature,
  handleArtistPromotionModal,
  isUserLoading,
  setAlert
}) => {
  const { palette, shape } = useTheme();
  let scrollRef = useRef(null);
  let scrollContainerRef = useRef(null);
  const history = useHistory();
  
  const [imageInput, setImageInput] = useState({
    canvas: "",
    imageId: "",
    paper: "",
    wrap: ""
  });
  const [step3Pending, setStep3Pending] = useState(false);
  const [currentToggle, setCurrentToggle] = useState("right");
  const [signatureData, setSignatureData] = useState("");
  const [signatureBackup, setSignatureBackup] = useState("");
  //add a placeholder for the previous signature to make sure the previous confirmed signature wouldn't be lost if user close the modal
  const [currentStep, setCurrentStep] = useState(1);
  const [productTypeIds, setProductTypeIds] = useState([]);
  const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccessfully, setUploadSuccessfully] = useState(false);
  const [heroShotsPending, setHeroShotsPending] = useState(false);
  const [heroShotsDetails, setHeroShotsDetails] = useState({});
  const [publistMethod, setPublistMethod] = useState("instant");
  const [imageDetails, setImageDetails] = useState({
    title: "",
    location: "",
    caption: "",
    price: "",
    categoryId: [],
    releaseFormList: [],
    colour: "",
    isNudity: false,
    tags: [],
    majorColour: "",
    signature: "",
    TypeId: null,
    imageFile: null,
    dateCreated: "00-00-0000",
    edition: "100",
    publishAt:""
  });
  const [hasSignature, setHasSignature] = useState(true);
  const [VibrantColors, setVibrantColors] = useState([]);
  const [selectedSignatureColor, setSelectedSignatureColor] = useState("#000000");
  const [alreadySigned, setAlreadySigned] = useState(false);
  const [allProductLines, setAllProductLines] = useState([]);
  const [imageResp, setImageResp] = useState({});
  const [selectedHeroShots, setSelectedHeroShots] = useState("");

  const sendCleanUp = async (imageInput) => {
    if (imageInput.imageId) {
      const token = await AuthorizeService.getAccessToken();
      axios.post(
        "/api/Image/CleanMockupInputs",
        imageInput,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
    }
  }

  useEffect(() => {
    const listener = async ev => {
      sendCleanUp(imageInput);
      /*
      ev.preventDefault();
      ev.returnValue='sure to leave？';
      axios.post("111").catch(()=>{
        console.log("1111111")
      })
      */
    };
    window.addEventListener('beforeunload', listener);
    return () => {
      window.removeEventListener('beforeunload', listener)
    }
}, [imageInput]);

  useEffect(() => {
    if (!isUserLoading && (!auth || !auth.isAuthenticated)) {
      window.location.href = "/authentication/login";
    }
  }, [isUserLoading, auth]);

  useEffect(() => {
    if (!isUserLoading && account?.user?.roles) {
      if (!account?.user?.roles.includes("Artist")) {
        history.push(`/${account?.user?.userHandle}`);
        handleArtistPromotionModal(true);
      }
    }
  }, [isUserLoading, account?.user?.roles]);

  const handleImageDetails = (field, metadata) => {
    setImageDetails({
      ...imageDetails,
      [field]: metadata,
    });
  };

  const colorizeExistingSignature = () => signatureData
    .replaceAll("stroke=\"#000\"", `stroke="${selectedSignatureColor}"`)
    .replaceAll("stroke=\"#000000\"", `stroke="${selectedSignatureColor}"`);

  const handlePublish = async (selectedProductLines) => {
    if (!imageDetails.price || imageDetails.price < MINIMUM_EARNINGS) {
      setAlert(`Please add a price above ${MINIMUM_EARNINGS} USD`, "error");
      return false;
    }
    const isAtLeastOneProductLineSelected = () =>
      Object.keys(selectedProductLines)
        .filter(function (key) {
          return selectedProductLines[key] === true;
        }).length !== 0;
    if (!isAtLeastOneProductLineSelected()) {
      setAlert("At least one product line must be selected", "error");
      return false;
    }

    let coloredSignatureData = signatureData;

    if (signatureData && signatureData.indexOf("<svg") !== -1 || signatureData && signatureData.indexOf(
      "<svg") !== -1) {
      await getCropData(
        signatureData,
        true,
        selectedSignatureColor,
        brushStroke || 12.5,
      ).then(({ orgSigSVG, invSigSVG }) => {
        updateUserSignature({
          orgSigSVG: orgSigSVG,
          invSigSVG: invSigSVG,
        });
        coloredSignatureData = invSigSVG;
      }).catch((err) => {
        console.log("getCropData ", err);
      });
    }

    const PublishAt = new Date().toJSON();
    const dto = new FormData();
    dto.append("Id", imageInput.imageId);
    dto.append("DefaultHeroShot", selectedHeroShots);
    dto.append("Title", imageDetails.title);
    dto.append("PublishAt", publistMethod === "instant" ? PublishAt : imageDetails.publishAt.toJSON());
    dto.append("Caption", imageDetails.caption);
    dto.append("Location", imageDetails.location);
    productTypeIds.forEach(id => {
      dto.append("SupportedProductLineIds", id);
    });
    imageDetails.tags.forEach((tag) => {
      dto.append("Tags", tag);
    });

    if (cropper.releasedForms.length > 0) {
      cropper.releasedForms.forEach((file) => {
        dto.append("ReleaseFormList", file);
      });
    }

    dto.append("SetPrice", imageDetails.price);
    if (imageDetails.categoryId.length > 0) {
      imageDetails.categoryId.forEach((item) => {
        dto.append("CategoryIds", item);
      });
    }
    dto.append("imageFile", cropper.orgSrc);
    dto.append("Top", cropper.y);
    dto.append("Left", cropper.x);
    dto.append("Width", cropper.width);
    dto.append("Height", cropper.height);
    dto.append("isNudity", imageDetails.isNudity);
    dto.append("dateCreated", imageDetails.dateCreated);
    dto.append("Signature", account.user.sign);
    dto.append("TypeId", imageDetails.TypeId);
    dto.append("majorColour", imageDetails.majorColour);
    dto.append("AspRatioX", cropper.aspectRatio.split(",")[0]);
    dto.append("AspRatioY", cropper.aspectRatio.split(",")[1]);
    dto.append(
      "Orientation",
      cropper.width > cropper.height
        ? "landscape"
        : cropper.width === cropper.height ? "square" : "portrait",
    );
    dto.append("SignatureColour", selectedSignatureColor);
    dto.append("NumberFont", "Kalam");
    dto.append("ClientLocalTimeOffset", new Date().getTimezoneOffset());
    dto.append("overlayAlignment", currentToggle);
    dto.append("TotalEditions", imageDetails.edition);
    dto.append("IsAlreadySigned", alreadySigned);
    dto.append(
      "SignatureSVG",
      signatureData.indexOf("<svg") === -1
        ? ""
        : coloredSignatureData || colorizeExistingSignature() || "",
    );

    const artistId = account.user.id;

    let token = await AuthorizeService.getAccessToken();
    token = !!token ? token : account.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        setUploadProgress(percentCompleted);
      },
    };

    axios
      .post(
        `api/image/${artistId}/ImageTitleValidation`,
        {
          artistId,
          imageTitle: imageDetails.title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((_) => {
        handleNextStep();
        axios
          .post("api/image", dto, config)
          .then(({ status, data }) => {
            if (status === 200) {
              handleUploaded();
              setUploadSuccessfully(true);
            }
            setImageResp(data);
          })
          .catch(function (err) {
            console.log(err);
            sendCriticalException("upload image failed, endpoint: api/image", `userId: ${artistId} err-info: ${err}`);
            setAlert("Upload failed, please retry later", "error");
          });
      })
      .catch((err) => {
        console.log("getThumbnailOrPreviewImage ", err);
        setAlert("You have previously used this title", "error");
      });
  };

  const CreateHeroShots = () => {
    setHeroShotsPending(true);
    const aspectRatio = `${cropper.aspectRatio.split(",")[1]}x${cropper.aspectRatio.split(",")[0]}`
    const artistId = account.user.id;

    const dto = {
      ImageId: 
        process.env.NODE_ENV === "production"
        ? imageInput.imageId
        : aspectRatio,
      ArtistId: 
        process.env.NODE_ENV === "production"
        ? artistId
        : aspectRatio,
      AspectRatio: aspectRatio
    }

    axios.post(
      `${process.env.NODE_ENV === "production"
      ? ""
      : "https://pikcha.local:1900"
    }/v1/mockup/MockupRequest/CreateHeroShots?ImageId=${process.env.NODE_ENV === "production"
      ? imageInput.imageId
      : aspectRatio
    }&AspectRatio=${aspectRatio}&ArtistId=${process.env.NODE_ENV === "production"? artistId : aspectRatio}`,  
      dto,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setHeroShotsDetails(res.data);
      setSelectedHeroShots(res.data?.paperRender?.BlackFrame);
      setHeroShotsPending(false);
    }).catch((err)=>{
      setHeroShotsPending(false);
      console.log(err)
    })
  }

  const handleCloseModalAndExitAlert = (_, cancelExit = false) => {
    setShowExitConfirmationModal(false);
    if (cancelExit) {
      return;
    }
    setConfirmedNavigation(true);
  };

  const handleExitConfirmationModal = (nextLocation) => {
    if ((account.user || {}).roles.includes("Artist")) {
      if (nextLocation.pathname !== "/upload") {
        setNextLocation(nextLocation.pathname + nextLocation.hash);
        setShowExitConfirmationModal(true);
        if (!confirmedNavigation && !uploadSuccessfully || (uploadSuccessfully && heroShotsPending && !confirmedNavigation)) {
          return false;
        }
        return true;
      }
    }
  };

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      sendCleanUp(imageInput);
      history.push(nextLocation);
    }
  }, [confirmedNavigation, nextLocation, imageInput]);

  useEffect(() => {
    const getSignature = function (url) {
      return new Promise(() => {
        axios
          .get(url, {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            if (response && response.data) {
              setSignatureData(response.data);
              setSignatureBackup(response.data);
              if (response.data.indexOf("<svg") === -1) {
                setHasSignature(false);
              }
            }
          }).catch((err) => {
            console.log(err);
          });
      });
    };
    getSignature((account.user || {}).sign);
  }, [(account.user || {}).sign]);

  useEffect(() => {
    setCurrentStep(1);
    if (signatureData && signatureData.indexOf("<svg") !== -1) {
      setHasSignature(true)
    }
  }, [cropper.src])

  const createInputId = useCallback(async () => {
    setStep3Pending(true);
    let coloredSignatureData = signatureData;
    const token = await AuthorizeService.getAccessToken();
    if (signatureData && signatureData.indexOf("<svg") !== -1 || signatureData && signatureData.indexOf(
      "<svg") !== -1) {
        await getCropData(
          signatureData,
          true,
          selectedSignatureColor,
          12.5,
        ).then(({ orgSigSVG, invSigSVG }) => {
          updateUserSignature({
            orgSigSVG: orgSigSVG,
            invSigSVG: invSigSVG,
          });
          coloredSignatureData = invSigSVG;
        }).catch((err) => {
        console.log("getCropData ", err);
      });
    }

    const dto = new FormData();
    dto.append("imageFile", cropper.orgSrc);
    dto.append("Top", cropper.y);
    dto.append("Left", cropper.x);
    dto.append("Width", cropper.width);
    dto.append("Height", cropper.height);
    dto.append("NumberFont", "Kalam");
    dto.append("Id", account?.user?.id);
    dto.append(
      "SignatureSVG",
      signatureData.indexOf("<svg") === -1
        ? ""
        : coloredSignatureData || colorizeExistingSignature() || "",
    );
    dto.append("SignatureColour", selectedSignatureColor);
    dto.append("AspRatioX", cropper.aspectRatio.split(",")[0]);
    dto.append("AspRatioY", cropper.aspectRatio.split(",")[1]);
    dto.append("overlayAlignment", currentToggle);
    dto.append("TotalEditions", imageDetails.edition);
    await axios.post(
      "api/Image/MockUpInputs",
      dto,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      setImageInput(res.data);
      setStep3Pending(false);
    }).catch((err) => {
      console.log(err);
      setAlert("some problem happens, please retry later", "error");
      setStep3Pending(false);
      sendCriticalException("createImageInput failed, endpoint: api/Image/MockUpInput", `userId: ${account?.user?.id} err-info: ${err}`);
    })
  },[
    account?.user?.id,
    cropper.orgSrc,
    cropper.y,
    cropper.x,
    cropper.width,
    cropper.height,
    cropper.aspectRatio,
    imageDetails.edition,
    signatureData,
    selectedSignatureColor,
    currentToggle
  ]);

  const renderHeadPaginations = useCallback(() => {
    const paginations = [1, 2, 3, 4, 5, 6]
    return (
      <div
        className="flex full-width fixed"
        style={{
          justifyContent: "start",
          zIndex: 9,
          padding: "1em 0",
          marginTop: "-1em",
          backgroundColor: "white"
        }}
      >
        {paginations.map((pagination) =>
          <div
            style={{
              width: 8,
              height: 8,
              backgroundColor: pagination === currentStep ? colors.Primary.primary50 : currentStep > pagination ? colors.Neutral.ink90 : colors.Neutral.grey40,
              borderRadius: 4,
              cursor: currentStep >= pagination ? "pointer" : "auto",
              marginRight: "1em"
            }}
            onClick={() => {
              if (currentStep >= pagination) {
                setCurrentStep(pagination)
              }
            }}
          />
        )}
      </div>
    )
  }, [currentStep]);

  const handleNextStep = () => {
    if (currentStep === 1 && !cropper.src) {
      setAlert("Please upload your image", "error");
      return
    }
    setCurrentStep(currentStep + 1);
  }

  const handleToggleChange = (value) => {
    setCurrentToggle(value)
  }

  const callbackPerfectScrollbarColumn = useCallback(
    () => (
      <PerfectScrollbar
        ref={ref => scrollRef.current = ref}
        containerRef={ref => scrollContainerRef.current = ref}
        component="aside"
        options={{ scrollingThreshold: 3000 }}
        style={{ paddingTop: 24, minWidth: 480, width: 480 }}
        className="mr-1-em mt-4-em relative"
      >
        {currentStep < 7 &&
          renderHeadPaginations()
        }
        {currentStep === 1 && (
          <div
            className="full-width mt-2-em"
            style={{
              backgroundColor: palette.common.white,
              zIndex: 11,
              borderRadius: shape.borderRadius,
              marginBottom: "10px",
            }}
          >
            <Step1
              handleNextStep={handleNextStep}
              hasSignature={hasSignature}
              signatureData={signatureData}
              setHasSignature={setHasSignature}
              setSignatureData={setSignatureData}
              setSignatureBackup={setSignatureBackup}
              signatureBackup={signatureBackup}
              setAlreadySigned={setAlreadySigned}
            />
          </div>
        )}
        {currentStep === 2 && (
          <div
            className="Artwork-details mt-2-em"
          >
            <Step2
              handleNextStep={handleNextStep}
              edition={imageDetails.edition}
              handleImageDetails={handleImageDetails}
            />
          </div>
        )}
        {currentStep === 3 && (
          <div
            className="Artwork-details mt-2-em"
          >
            <Step3
              handleNextStep={handleNextStep}
              createInputId={createInputId}
              step3Pending={step3Pending}
              currentToggle={currentToggle}
              color={selectedSignatureColor}
              editionNumber={imageDetails.edition}
              setSelectedSignatureColor={setSelectedSignatureColor}
              handleToggleChange={(value) => handleToggleChange(value)}
              signatureData={signatureData}
              signaturePreviewImage={cropper.src}
              VibrantColors={VibrantColors}
              signatureColor={selectedSignatureColor}
            />
          </div>
        )}
        {currentStep === 4 && (
          <div
            className="Artwork-details mt-2-em"
          >
            <Step4
              handleNextStep={handleNextStep}
              imageDetails={imageDetails}
              handleImageDetails={handleImageDetails}
              CreateHeroShots={CreateHeroShots}
            />
          </div>
        )}
        {currentStep === 5 && (
          <div
            className="Artwork-details mt-2-em"
          >
            <Step5
              imageDetails={imageDetails}
              handleImageDetails={handleImageDetails}
              handleNextStep={handleNextStep}
              setImageDetails={setImageDetails}
              setProductTypeIds={setProductTypeIds}
              setAllProductLines={setAllProductLines}
              heroShotsDetails={heroShotsDetails}
              heroShotsPending={heroShotsPending}
              selectedHeroShots={selectedHeroShots}
              setSelectedHeroShots={setSelectedHeroShots}
            />
          </div>
        )}
        {currentStep === 6 && (
          <div
            className="Artwork-details mt-2-em"
          >
            <Step6
              imageDetails={imageDetails}
              handleImageDetails={handleImageDetails}
              handleNextStep={handleNextStep}
              aspectRatio={cropper.aspectRatio}
              handlePublish={handlePublish}
              productTypeIds={productTypeIds}
              setProductTypeIds={setProductTypeIds}
              allProductLines={allProductLines}
              publistMethod={publistMethod}
              setPublistMethod={setPublistMethod}
            />
          </div>
        )}
        {currentStep === 7 && (
          <div
            className="Artwork-details"
          >
            <Step7
              uploadProgress={uploadProgress}
              handleNextStep={handleNextStep}
              uploadSuccessfully={uploadSuccessfully}
            />
          </div>
        )}
        {currentStep === 8 && (
          <div
            className="Artwork-details"
          >
            <Step8
              imageResp={imageResp}
              heroShotsDetails={heroShotsDetails}
              setSelectedHeroShots={setSelectedHeroShots}
            />
          </div>
        )}
      </PerfectScrollbar>
    ), [
    VibrantColors,
    currentStep,
    hasSignature,
    signatureData,
    imageDetails,
    selectedSignatureColor,
    currentToggle,
    productTypeIds,
    cropper.src,
    allProductLines,
    cropper.aspectRatio,
    imageResp,
    uploadProgress,
    uploadSuccessfully,
    heroShotsDetails,
    publistMethod,
    setPublistMethod,
    createInputId,
    step3Pending,
    CreateHeroShots,
    heroShotsPending,
    selectedHeroShots,
    setSelectedHeroShots
  ])

  const renderImagePreviews = useCallback(
    () => (
      <ImagePreview
        signatureData={signatureData}
        currentToggle={currentToggle}
        editionNum={imageDetails.edition}
        setVibrantColors={setVibrantColors}
        selectedSignatureColor={selectedSignatureColor}
        setSelectedSignatureColor={setSelectedSignatureColor}
        uploadProgress={uploadProgress}
        uploadSuccessfully={uploadSuccessfully}
        currentStep={currentStep}
        releasedForms={cropper.releasedForms}
        heroShotsPending={heroShotsPending}
        selectedHeroShots={selectedHeroShots}
      />
    ), [
      signatureData,
      currentToggle,
      imageDetails.edition,
      selectedSignatureColor,
      uploadProgress,
      uploadSuccessfully,
      currentStep,
      cropper.releasedForms,
      heroShotsPending,
      heroShotsDetails,
      selectedHeroShots
    ],
  )

  return (
    <div
      className="flex flex-grow-1"
      style={{
        justifyContent: "center",
        height: "calc(100vH - 4em)",
      }}
    >
      <Prompt message={handleExitConfirmationModal} when={true} />
      <ConfirmAlert
        handleCloseModalAndExitAlert={handleCloseModalAndExitAlert}
        open={showExitConfirmationModal}
        onClose={() => {
        }}
      />
      {renderImagePreviews()}
      {callbackPerfectScrollbarColumn()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    account: state.accountReducer,
    cropper: state.cropperReducer,
    isUserLoading: isUserLoading(state),
    auth: state.authReducer
  }
}

export default connect(mapStateToProps, {
  setAlert,
  updateUserSignature,
  handleArtistPromotionModal
})(Upload);