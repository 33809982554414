import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from "clsx";
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = ({ disabled }) =>
  makeStyles(() => ({
    root: {
      paddingLeft: '0.5em',
      paddingTop: '4px !important',
      display: 'flex !important',
      alignItems: 'start',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 50,
      width: '1rem',
      height: '1rem',
      boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#F5F8FA',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#EBF1F5',
      },
    },
    checkedIcon: {
      backgroundColor: 'black',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: '1rem',
        height: '1rem',
        backgroundImage: `radial-gradient(#fff, #fff 28%,transparent 32%)`,
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: 'black',
      },
    },
    formControl: {
      // width: '100%',
    },
    label: {
      color: disabled ? '#A2A9B0 !important' : '#343A3F !important',
      //@ts-ignore
      fontWeight: 'normal' as 'normal',
      //@ts-ignore
      fontSize: '12px',
      marginBottom: '0.5em',
      alignItems: 'start !important'
    },
    disabled: {
      backgroundColor: '#878D96',
    },
    formControlRoot: {
      display: 'flex !important',
      alignItems: 'start',
    }
  }))(FormControlLabel)


export default ({
  disabled,
  labelText,
  value,
  handleChange,
  options = [],
  style = {},
  optionStyle = {}
}) => {

  const classes: any = useStyles({ disabled });

  const renderOptions = () => {
    return options.map((option) => (
      <FormControlLabel
        key={option.value}
        value={option.value}
        classes={{ root: classes.formControlRoot, label: classes.label }}
        control={
          <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            disabled={disabled}
          />}
        label={option.label}
        style={{ ...optionStyle }}
      />
    ))
  }
  return (
    <FormControl
      size="small"
      component="div"
    >
      <FormLabel component="legend">{labelText}</FormLabel>
      <RadioGroup aria-label={labelText} name={labelText} value={typeof value === undefined || value === null ? options[0].value : value} onChange={handleChange} style={{ ...style }}>
        {renderOptions()}
      </RadioGroup>
    </FormControl>
  )
}