import React, { useEffect, useState, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "@material-ui/core/styles";

import {
  PikchaLoader,
  PikchaMasonryTitle,
} from "../../../components/baseComponents";
import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../../components/Responsive/isDesktopSD";
import isDesktop from "../../../components/Responsive/isDesktop";
import isTablet from "../../../components/Responsive/isTablet";
import isMobile from "../../../components/Responsive/isMobile";
import MasonryColumn from "../MasonryColumn";

export default ({
  onMasonryModalClosed,
  title,
  getPhotos,
  onClickGalleryImage,
  fromProfile,
  noContainerMargins,
  restrict = [],
  noBackground,
  noItemHeaders,

  resetGallery,
  images,
} = {}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  const columns = isOnDesktop ? 3 : isOnTablet ? 2 : 1;
  const galleryWrapper = useRef(null);
  const [galleryWrapperWidth, setGalleryWrapperWidth] = useState(galleryWrapper.current ? window.getComputedStyle(galleryWrapper.current) : 119.2);

  useEffect(() => {
    // TODO: Find a way to stop this from triggering if component is
    //  rendered from resizing desktop to mobile.
    resetGallery();
  }, []);

  const { palette, typography } = useTheme();

  const clickImage = () => {
    if (onClickGalleryImage) onClickGalleryImage();
  };
  const separatePhotos = () => {
    let photos = [];

    if (images && images.photos) {
      photos = images.photos;
    } else {
      //photos = galleryPhotos;
    }
    //let photos = images.photos;
    //let photos = galleryPhotos;
    // if (loading) {
    //   for (let i = 0; i < 9; i++) {
    //     photos = [
    //       ...photos,
    //       ...[
    //         {
    //           placeholder: true
    //         }
    //       ]
    //     ];
    //   }
    // }

    photos = photos.reduce((accumulator, item) => {
      const { id } = item;
      if (restrict.some((restrictedItem) => restrictedItem === id)) {
        return accumulator;
      }
      return [...accumulator, ...[item]];
    }, []);

    const res = [...Array(columns).keys()].map((c) =>
      photos.filter((_, i) => i % columns === c)
    );

    const columnsToMap = [...Array(columns)];

    return columnsToMap.map((column, index) => {
      const isFirstColumn = index === 0;
      const isLastColumn = index + 1 === columnsToMap.length;
      return (
        <MasonryColumn
          view={'MasonryGallerySearch'}
          columnNo={index + 1}
          imageClicked={clickImage}
          key={index + 1}
          noItemHeaders={noItemHeaders}
          onMasonryModalClosed={onMasonryModalClosed}
          photos={res[index]}
          galleryWrapperWidth={galleryWrapperWidth}
          style={{
            marginLeft: !isFirstColumn
              ? isOnDesktopHD
                ? "1.5em"
                : isOnDesktopSD
                  ? "1.1em"
                  : isOnTablet
                    ? "1.1em"
                    : isOnMobile
                      ? 0
                      : "0.5em"
              : 0,
            marginRight: !isLastColumn
              ? isOnDesktopHD
                ? "1.5em"
                : isOnDesktopSD
                  ? "1.1em"
                  : isOnTablet
                    ? "1.1em"
                    : isOnMobile
                      ? 0
                      : "0.5em"
              : 0,
          }}
        />
      );
    });
  };

  const handleResize = () => {
    const { width } = galleryWrapper.current ? window.getComputedStyle(galleryWrapper.current) : {};
    const widthValue = isOnDesktopHD ? parseFloat(width) - 128 : isOnDesktopSD ? parseFloat(width) - 64 : isOnTablet ? parseFloat(width) - 32 : parseFloat(width) - 16;
    setGalleryWrapperWidth(widthValue);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize()
  }, [galleryWrapper]);

  return (
    <div>
      {title !== "undefined" && (
        <PikchaMasonryTitle>{title}</PikchaMasonryTitle>
      )}
      <InfiniteScroll
        dataLength={images.photos.length}
        style={{
          overflow: "hidden",
        }}
        next={() => {
          if (images.start > 1) {
            getPhotos(images.count, images.start)
          }
        }}
        hasMore={images.hasMore}
        loader={<PikchaLoader marginTop={typography.xLarge} marginBottom={isOnMobile ? "500px" : "800px"} />}
        endMessage={
          <p
            style={{
              textAlign: "center",
              marginTop: "5em",
              fontSize: typography.small,
              fontWeight: typography.fontWeightSemiMedium,
              color: palette.text.secondary,
            }}
          >
            You've seen all the images
          </p>
        }
      >
        <div
          className="masonry"
          ref={galleryWrapper}
          style={{
            marginTop: "2em",
            paddingLeft:
              noContainerMargins || fromProfile
                ? null
                : isOnDesktopHD
                  ? "4em"
                  : isOnDesktopSD
                    ? "2em"
                    : isOnTablet
                      ? "1em"
                      : "0.5em",
            paddingRight:
              noContainerMargins || fromProfile
                ? null
                : isOnDesktopHD
                  ? "4em"
                  : isOnDesktopSD
                    ? "2em"
                    : isOnTablet
                      ? "1em"
                      : "0.5em",
            background: noBackground ? null : palette.background.default,
          }}
        >
          {separatePhotos()}
        </div>
      </InfiniteScroll>
    </div>
  );
};
