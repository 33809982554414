import {
  HANDLE_REGISTER_FIRST_NAME,
  HANDLE_REGISTER_LAST_NAME,
  HANDLE_REGISTER_LOADING_STATUS,
  HANDLE_REGISTER_PASSWORD,
  HANDLE_REGISTER_USERNAME,
} from "./types";

export const handleRegisterFirstName = ({ target: { value } }) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_REGISTER_FIRST_NAME,
      payload: value,
    });
  };
};

export const handleRegisterLastName = ({ target: { value } }) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_REGISTER_LAST_NAME,
      payload: value,
    });
  };
};

export const handleRegisterUsername = ({ target: { value } }) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_REGISTER_USERNAME,
      payload: value,
    });
  };
};

export const handleRegisterPassword = ({ target: { value } }) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_REGISTER_PASSWORD,
      payload: value,
    });
  };
};

export const handleRegisterLoadingStatus = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_REGISTER_LOADING_STATUS,
      payload: state,
    });
  };
};
