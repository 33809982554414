import AuthorizeService from "../auth/AuthorizeService";
import { authorizeUserForPage, logout } from "./account";

import {
  AUTH_FAILED,
  AUTH_LOADING,
  AUTH_SUCCESS,
  DEAUTHENTICATE,
  USER_LOADED
} from "./types";

// Authenticate User
export const authenticate = (onAuthenticated) => {
  return async (dispatch) => {
    dispatch(setLoading());
    //console.log("authenticate isAuthenticated READY")

    const isAuthenticated = await AuthorizeService.isAuthenticated();

    if (isAuthenticated) {
    //console.log("authenticate isAuthenticated TRUE")

      dispatch(authorizeUserForPage());
      dispatch({
        type: AUTH_SUCCESS,
      });
      dispatch(() => {
        if (onAuthenticated) {
          onAuthenticated(isAuthenticated);
        }
      });
    } else {
    //console.log("authenticate isAuthenticated FALSE")

      dispatch({
        type: AUTH_FAILED,
      });
      dispatch(() => {
        if (onAuthenticated) {
          onAuthenticated(isAuthenticated);
        }
      });
    }
  };
};

// Deauthenticate user
export const deauthenticate = () => {
  return async (dispatch) => {
    dispatch(logout());
    dispatch({ type: DEAUTHENTICATE });
  };
};

// Set Loading
export const setLoading = () => {
  return { type: AUTH_LOADING };
};
