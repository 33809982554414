import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-grid-system";
import { PikchaLoader } from "../../../components/baseComponents";
import Desktop from "../../../components/Responsive/Desktop";
import Tablet from "../../../components/Responsive/Tablet";
import Mobile from "../../../components/Responsive/Mobile";
import isMobile from "../../../components/Responsive/isMobile";
import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import { setAlert } from "../../../actions/alert";
import { getUserCollections, resetCollection } from "../../../actions/myCollections";
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  colors,
} from "@pikcha/typography";
import InfiniteScroll from "react-infinite-scroll-component";
import MyOrdersDesktop from "./MyOrdersDesktop";
import MyOrdersMobile from "./MyOrdersMobile";


const MyOrders = ({ account, getUserCollections, myCollection, resetCollection }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const retrieveData = async () => {
    let result = await getUserCollections(
      account.user.id,
      myCollection.count,
      1
    );
  };

  useEffect(() => {
    resetCollection();
    retrieveData();
  }, []);
  useEffect(() => {
    setData(myCollection.collections);
    setIsLoading(false);
  }, [myCollection]);

  const isOnMobile = isMobile();
  const isOnDesktopHD = isDesktopHD();

  const desktopHDStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    width: '900px'
  };

  return (
    <div
      style={{
        marginLeft: isOnMobile ? "1em" : "1em",
        marginRight: isOnMobile ? "1em" : "1em",
        ...(isOnDesktopHD && desktopHDStyle),
      }}
      className="account-container"
    >
      <div className={"nonPrintable"}>
        {isLoading ? (
          <div
            style={{
              marginTop: "4em",
            }}
          >
            <PikchaLoader />
          </div>
        ) : (
          <form className="account-form">
            {data.length > 0 ? (
              <Row
                style={{
                  marginTop: "2em",
                }}
              >
                <Col lg={12}>
                  <InfiniteScroll
                    style={{
                      overflow: "hidden",
                    }}
                    dataLength={data.length}
                    next={() => {
                      if (myCollection.start > 1) {
                        getUserCollections(
                          account.user.id,
                          myCollection.count,
                          myCollection.start
                        );
                      }
                    }}
                    hasMore={myCollection.hasMore}
                    loader={<PikchaLoader />}
                  >
                    <TypographySubtitle
                      component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}>
                      Order History
                    </TypographySubtitle>
                    {data.map((item, index) => {
                      return (
                        <div key={index}>
                          {index >= 0 && (
                            <div style={{
                              border: `2px solid ${colors.Neutral.grey30}`,
                              margin: '30px 0px'
                            }}>
                              <Desktop>
                                <MyOrdersDesktop
                                  order={item}
                                />
                              </Desktop>
                              <Tablet>
                                <MyOrdersDesktop
                                  order={item}
                                />
                              </Tablet>
                              <Mobile>
                                <MyOrdersMobile
                                  order={item}
                                />
                              </Mobile>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </Col>
              </Row>
            ) : (
              <Row className="mt-2">
                <Col md={12} className="text-center mt-4">
                  <span className="fontSize16">
                    You don’t have any collections to display
                  </span>
                </Col>
              </Row>
            )}
          </form>
        )}
      </div>

    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  myCollection: state.myCollectionReducer,
});

export default connect(mapStateToProps, {
  setAlert,
  getUserCollections,
  resetCollection
})(MyOrders);
