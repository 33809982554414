import React, { useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import {
  ICON_SIZE_DEFAULT,
  ICON_SIZE_SMALL,
} from "../../helpers/constants";
import FilterIcon from "../../assets/images/FilterIcon.svg";
import PublicRoundedIcon from "@material-ui/icons/PublicRounded";

import ArtistRow from "../../containers/search/ArtistRow";

import {
  PikchaButton,
  PikchaDropdown,
  PikchaReusableTabsGroup,
  PikchaReusableTab
} from "../../components/baseComponents";

import "./SearchArtistsMobile.css";

export default ({
  toggleSafeSearch,
  searchTerm,
  isSearchLoading,
  resultsType,
  resultsTypeChange,
  sortBy,
  onSortByChange,
  onApplyFilters,
  countries,
  onCountryChange,
  country,
  artists,
  getArtists,
  onClearFilters,
}) => {
  const [isAddingFilter, setIsAddingFilter] = useState();

  const { palette, typography } = useTheme();

  const addFilter = () => {
    setIsAddingFilter(true);
  };
  const onCancelFiltering = () => {
    setIsAddingFilter(false);
  };
  const applyFilters = () => {
    onApplyFilters();
    setIsAddingFilter(false);
  };

  let artistRows = artists.artists;
  if (isSearchLoading) {
    for (let i = 0; i < 8; i++) {
      artistRows = [
        ...artistRows,
        ...[
          {
            placeholder: true,
          },
        ],
      ];
    }
  }

  const showBlankState = !isSearchLoading && artistRows.length < 1;

  return (
    <div
      style={{
        paddingTop: "4em",
        width: "100%",
      }}
    >
      <div
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2em",
          }}
        >
          <div
            style={{
              alignSelf: "start",
              color: palette.text.primary,
              fontSize: typography.large,
              fontWeight: typography.fontWeightBold,
              textTransform: "capitalize"
            }}
          >
            {searchTerm}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "2em",
              marginLeft: "auto",
              marginRight: "auto",
              justifyContent: "center",
              width: "100%"
            }}
          >
            <PikchaReusableTabsGroup
              value={resultsType}
              onChange={resultsTypeChange}
              aria-label="Results Type Selector"
              style={{
                width: "100%"
              }}
            >
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Pikcha"
                  label="Image"
                  aria-label="Pikcha"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Artist"
                  label="Artist"
                  aria-label="Artist"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                  }}
                  value="Album"
                  label="Album"
                  aria-label="Album"
                />
              </PikchaReusableTabsGroup>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "1em",
              alignItems: "center",
            }}
          >
            {!isAddingFilter && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: "1.85em",
                  justifyContent:"center"
                }}
              >
                <PikchaButton
                  mainStyle={{
                    width: 125,
                    height: 36,
                  }}
                  action="octonary"
                  onClick={addFilter}
                  className="fontSize16"
                >
                  {/* <AddIcon
                    style={{
                      marginRight: "0.5em",
                    }}
                  /> */}
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: "1em",
                      backgroundImage: `url(${FilterIcon})`,
                      backgroundSize: "cover",
                    }}
                  />
                  Filter
                </PikchaButton>
              </div>
            )}
            {isAddingFilter && (
              <div
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  display: "flex",
                  marginTop: "1.85em",
                  justifyContent:"center"
                }}
              >
                <PikchaButton
                  mainStyle={{
                    width: 125,
                    height: 36,
                  }}
                  action="octonary"
                  fullWidth
                  onClick={applyFilters}
                  className="fontSize16"
                >
                  Apply
                </PikchaButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {isAddingFilter && (
        <div
          id="search-artist-mobile-filters"
          className="search-mobile-filters"
          style={{
            backgroundColor: palette.action.disabled,
            zIndex: 10,
            padding: "0.5em",
            marginTop: "2em",
          }}
        >
          <div
            className="search-mobile-filters-modal"
            style={{
              borderRadius: 6,
              backgroundColor: palette.common.white,
              padding: "1em",
              minHeight: "10em",
            }}
          >

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >

              <div
                onClick={onClearFilters}
                style={{
                  display: "flex",
                  marginRight: "auto",
                  maxHeight: 16,
                  cursor: "pointer",
                }}
              >
                <span
                  className="buttonHoverUnderline"
                  style={{
                    fontWeight: 500,
                    fontSize: "0.75rem",
                    color: typography.secondary,
                  }}
                >
                  Clear
                  </span>
                <div
                  style={{
                    width: 16,
                    height: 16,
                    backgroundImage: `url(${FilterIcon})`,
                    backgroundSize: "cover",
                  }}
                />
              </div>

              <div
                style={{
                  // marginTop: "3em",
                  alignSelf: "flex-end",
                }}
              >
                {/* <PikchaBackButton onClick={onCancelFiltering} /> */}
                <CloseIcon
                  style={{
                    ...ICON_SIZE_DEFAULT,
                    color: palette.text.primary,
                  }}
                  onClick={onCancelFiltering}
                />
              </div>

            </div>

            <div
              style={{
                width: "100%",
                marginTop: "1em"
              }}
            >
              <PikchaDropdown
                label="Sort By"
                onChange={onSortByChange}
                options={[
                  { value: "RV", label: "Relevance" },
                  { value: "MV", label: "Most Viewed" },
                  { value: "MF", label: "Most Followed" },
                  { value: "MS", label: "Most Sales" },
                  { value: "PHL", label: "Price High to Low" },
                  { value: "PLH", label: "Price Low to High" },
                ]}
                value={sortBy}
                style={{
                  width: "100%",
                  minWidth: 200,
                }}
              />
            </div>

            <div
              style={{
                // paddingLeft: "2em",
                // paddingRight: "2em",
                display: "flex",
                flexDirection: "column",
              }}
            >

              <div
                style={{
                  marginLeft: "4.3rem",
                  marginBottom: "0.15rem",
                  color: "grey",
                  marginTop: "1rem",
                }}
              >
                <PublicRoundedIcon
                  color="inherit"
                  style={ICON_SIZE_SMALL}
                />
              </div>
              <div
                style={{
                  // marginTop: "3em",
                }}
              >
                <PikchaDropdown
                  label="Nationality"
                  noHelperClass
                  classNameOuter="Dropdown-container"
                  onChange={onCountryChange}
                  style={{ marginTop: 0 }}
                  options={countries}
                  value={country}
                  placeholder={country || ""}
                />
              </div>
            </div>

          </div>
        </div>
        )}
      {resultsType === "Artist" && (
        <div
          style={{
            marginTop: "2.85em",
            textAlign: "center",
          }}
        >
          <InfiniteScroll
            dataLength={artists.artists.length}
            next={() => getArtists(artists.count, artists.start)}
            hasMore={artists.hasMore}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {showBlankState && (
              <div
                style={{
                  textAlign: "start",
                  width: "100%",
                  marginLeft: "1em",
                }}
              >
                <p
                  style={{
                    fontSize: typography.smallest,
                    fontWeight: typography.fontWeightMedium,
                    color: palette.text.primary,
                  }}
                >
                  We couldn’t find any results
                </p>
                <p
                  style={{
                    fontSize: typography.smallest,
                    color: palette.text.secondary,
                  }}
                >
                  try searching for something else!
                </p>
              </div>
            )}
            {artistRows.map((row, index) => (
              <ArtistRow key={index + 1} rank={index + 1} row={row} toggleSafeSearch={toggleSafeSearch} />
            ))}
          </InfiniteScroll>
        </div>
      )}
      <div
        style={{
          paddingBottom: "4em",
        }}
      />
    </div>

  );
};
