import React, { useEffect, useRef, useState } from "react";
import SelectionMasonryItem from "./SelectionMasonryItem.jsx";

export default ({
  photos,
  style,
  isImageSelected,
  onImageSelected,
  onImageDeselected,
  editingCollectionId
}) => {
  const column = useRef(null);

  const [itemWidth, setItemWidth] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  const handleResize = () => {
    const { width } = column.current
      ? window.getComputedStyle(column.current)
      : {};

    const widthValue = parseFloat(width);

    setItemWidth(widthValue);
  };
  useEffect(() => {
    handleResize();
  }, [column.current, isHovering]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      ref={column}
      style={style}
    >
      {photos.map((photo, index) => (
        <SelectionMasonryItem
          key={index}
          index={index}
          photo={photo}
          itemWidth={itemWidth}
          isImageSelected={isImageSelected}
          onImageSelected={onImageSelected}
          onImageDeselected={onImageDeselected}
          editingCollectionId={editingCollectionId}
          style={{
            marginBottom: "0.5em",
          }}
        />
      ))}
    </div>
  );
};
