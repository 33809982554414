import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useTheme } from "@material-ui/core/styles";

import history from "../../routes/history";
import { useDebounce } from "../../hooks/useDebounce";

import { searchAPIUrl } from "../../selectors/settings";

import isDesktop from "../../components/Responsive/isDesktop";
import isTablet from "../../components/Responsive/isTablet";

import { ICON_SIZE_DEFAULT } from "../../helpers/constants";

import SearchAutocomplete from "./SearchAutocomplete";

import search_abstract from "../../assets/images/search_abstract.png";
import search_animals from "../../assets/images/search_animals.png";
import search_architechture from "../../assets/images/search_architechture.png";
import search_backgrounds from "../../assets/images/search_backgrounds.png";
import search_beauty_fashion from "../../assets/images/search_beauty_fashion.png";
import search_food_and_drink from "../../assets/images/search_food_and_drink.png";
import search_landmarks from "../../assets/images/search_landmarks.png";
import search_landscapes from "../../assets/images/search_landscapes.png";
import search_music_film from "../../assets/images/search_music_film.png";
import search_objects from "../../assets/images/search_objects.png";
import search_people from "../../assets/images/search_people.png";
import search_religion_culture from "../../assets/images/search_religion_culture.png";
import search_science_tech from "../../assets/images/search_science_tech.png";
import search_scifi from "../../assets/images/search_scifi.png";
import search_sport from "../../assets/images/search_sport.png";

const search_categories = [
  {
    label: "Abstract",
    value: "abstract",
    icon: search_abstract,
  },
  {
    label: "Animals",
    value: "animals",
    icon: search_animals,
  },
  {
    label: "Architecture",
    value: "architecture",
    icon: search_architechture,
  },
  {
    label: "Backgrounds",
    value: "backgrounds",
    icon: search_backgrounds,
  },
  {
    label: "Beauty & Fashion",
    value: "beauty & fashion",
    icon: search_beauty_fashion,
  },
  {
    label: "Food & Drink",
    value: "food & drink",
    icon: search_food_and_drink,
  },
  {
    label: "Music & Film",
    value: "music & film",
    icon: search_music_film,
  },
  {
    label: "Objects",
    value: "objects",
    icon: search_objects,
  },
  {
    label: "Landmarks",
    value: "landmarks",
    icon: search_landmarks,
  },
  {
    label: "Landscape",
    value: "landscape",
    icon: search_landscapes,
  },
  {
    label: "People",
    value: "people",
    icon: search_people,
  },
  {
    label: "Religion & Culture",
    value: "religion & culture",
    icon: search_religion_culture,
  },
  {
    label: "Science & Tech",
    value: "science & tech",
    icon: search_science_tech,
  },
  {
    label: "Sci-Fi",
    value: "scifi",
    icon: search_scifi,
  },
  {
    label: "Sports",
    value: "sports",
    icon: search_sport,
  },
];

const SearchCategory = ({ category, style, onClick }) => {
  const { label, icon } = category;

  const { typography, palette } = useTheme();

  const [hoverState, setHoverState] = useState();

  const onMouseEnter = () => {
    setHoverState(true);
  };
  const onMouseLeave = () => {
    setHoverState(false);
  };

  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        borderRadius: 20,
        boxShadow:
          "1px 0px 4px rgba(0, 0, 0, 0.25), -1px 1px 4px rgba(0, 0, 0, 0.25)",
        padding: "0.25em",

        ...(hoverState
          ? {
              backgroundColor: palette.grey.lightest,
            }
          : {
              backgroundColor: palette.common.white,
            }),

        ...(style || {}),
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={() => {
        onClick(category);
      }}
    >
      <div
        style={{
          borderRadius: "50%",
          backgroundImage: `url(${icon})`,
          backgroundSize: "cover",
          width: 50,
          height: 50,
        }}
      />
      <div
        style={{
          marginLeft: "0.5em",
          marginRight: "0.5em",
          fontSize: typography.small,
          fontWeight: typography.fontWeightSemiMedium,
          color: palette.text.secondary,
        }}
      >
        {label}
      </div>
    </div>
  );
};

const SearchBar = ({
  pathname,
  searchAPIUrl,
  marginLeft = true,
  autofocus = false,
  center = true,
  border = true,
  height = "",
  backgroundColor = "transparent"
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const formRef = useRef(null);
  const searchContainerRef = useRef(null);
  const searchInputRef = useRef(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  useEffect(() => {
    if (autofocus) {
      searchInputRef.current.focus();
    }
  }, [autofocus]);

  const { typography, palette, shape } = useTheme();

  const handleChange = (value) => {
    setSearchTerm(value);
  };

  const onSubmit = (value) => {
    // e.preventDefault();

    // setSearchTerm("");

    //resetSuggestions();
    setSearchTerm(value);
    history.push(`/search/${value}`);
    window.scrollTo(0, 0);
    
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  };

  const onCategoryClicked = (category) => {
    const { value } = category;

    //console.log(value);

    setSearchTerm(value);
    history.push(`/search/${encodeURIComponent(value)}`);

    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  };

  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();

  let placeholder;
  if (isOnDesktop) {
    placeholder = "Search limited edition prints";
  } else {
    placeholder = "Search";
  }

  return (
    <SearchAutocomplete
      searchAPIUrl={searchAPIUrl}
      value={searchTerm}
      onChange={handleChange}
      onSelect={onSubmit}
      shouldFetchSuggestions={!!(searchTerm && searchTerm.length > 0)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        if (isOnDesktop) {
          suggestions = suggestions.reduce((accumulated, item, index) => {
            if (index > 5) {
              return accumulated;
            }
            return [...accumulated, ...[item]];
          }, []);
        }
        return (
          <div className="search-container-outer">
            <div className="search-container-main" ref={searchContainerRef} style={{justifyContent: center?"center":"center"}}>
              <form
                className="search-bar"
                onSubmit={onSubmit}
                ref={formRef}
                style={{
                  borderRadius: `${shape.borderRadius}px`,
                  // border: border ? `2px solid ${
                  //   isFocused ? palette.grey.medium : palette.grey.mediumLight
                  // }`:null,
                  marginLeft: marginLeft ? "6em" : 0,
                  marginRight: marginLeft ? "6em" : 0,
                  maxWidth: "800px",
                  backgroundColor: backgroundColor,
                  height: height?height:null,
                  boxShadow: isFocused
                    ? `inset 0 2px 5px 0px ${palette.grey.lighter}`
                    : "0px 0px 4px 1.5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div className="flex-stretch search-wrapper">
                  <div
                    className="search-pre-block"
                    style={{
                      fontSize: typography.smaller,
                      fontFamily: typography.fontFamilyMontserrat,
                      borderRadius: `${shape.borderRadius}px 0 0 ${shape.borderRadius}px`,
                    }}
                  >
                    <SearchRoundedIcon
                      fontSize="inherit"
                      style={{
                        fill: isFocused
                          ? palette.grey.medium
                          : palette.grey.mediumLight,
                        ...ICON_SIZE_DEFAULT,
                      }}
                    />
                  </div>
                  <input
                    style={{
                      border: "transparent",
                      backgroundColor: "transparent",
                      outline: 0,
                      //marginTop: "-0.1em",
                      color: palette.text.primary,
                    }}
                    type="text"
                    spellCheck="false"
                    ref={searchInputRef}
                    {...getInputProps({
                      onFocus: () => setIsFocused(true),
                      onBlur: () => setIsFocused(false),
                      placeholder,
                      className: "flex-center flex-grow-1 search-input",
                    })}
                  />
                  <div
                    style={{
                      display: isFocused ? "block" : "none",
                      position: "absolute",
                      top: 40,
                      zIndex: 100,
                      width: "100%",
                      borderColor: palette.grey.mediumLight,
                      backgroundColor: palette.common.white,
                      borderWidth: "2px",
                      borderStyle: "solid",
                      borderRadius: 6,
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 1px 5px inset",
                    }}
                  >
                    {suggestions.map((suggestion, index) => {
                      const suggestionTitle = suggestion
                        ? suggestion.title
                        : "";

                      let titleParts = [];

                      const searchTermSanitized = (searchTerm || "").replace(
                        /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g,
                        "\\$&"
                      );
                      if (searchTermSanitized.length > 0) {
                        const searchTermMatchNoCase = new RegExp(
                          `(${searchTermSanitized})`,
                          "gi"
                        );

                        const splitText = suggestionTitle.split(
                          searchTermMatchNoCase
                        );

                        if (splitText.length <= 1) {
                          titleParts = [
                            { element: suggestionTitle, isMatch: false },
                          ];
                        } else {
                          titleParts = splitText.reduce((arr, element) => {
                            if (!element) {
                              return arr;
                            }

                            if (searchTermMatchNoCase.test(element)) {
                              return [...arr, { element, isMatch: true }];
                            }

                            return [...arr, { element, isMatch: false }];
                          }, []);
                        }
                      } else {
                        titleParts = [
                          { element: suggestionTitle, isMatch: false },
                        ];
                      }

                      const className = suggestion.active
                        ? "pikcha-suggestion-item--active"
                        : "pikcha-suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: palette.grey.light,
                            color: palette.text.primary,
                            cursor: "pointer",
                            paddingLeft: "4em",
                            borderRadius: 6,
                          }
                        : {
                            color: palette.text.primary,
                            cursor: "pointer",
                            paddingLeft: "4em",
                            borderRadius: 6,
                          };
                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          {titleParts.map(({ element, isMatch }) => {
                            if (isMatch) {
                              return <strong>{element}</strong>;
                            } else {
                              return element;
                            }
                          })}
                        </div>
                      );
                    })}
                    {(isOnDesktop) && (
                      <>
                        {suggestions.length > 0 ? <hr /> : null}
                        <div>
                          <p
                            style={{
                              fontSize: typography.smallest,
                              fontWeight: typography.fontWeightSemiMedium,
                              color: palette.text.secondary,
                              padding: "0.5em",
                              paddingLeft: "1em",
                            }}
                          >
                            Popular categories
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              padding: "0.5em",
                              paddingLeft: "1em",
                            }}
                          >
                            {search_categories.map((category, index) => (
                              <div
                                key={index}
                                style={{
                                  marginRight: "0.5em",
                                  marginBottom: "0.5em",
                                }}
                              >
                                <SearchCategory
                                  category={category}
                                  onClick={onCategoryClicked}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </SearchAutocomplete>
  );
};

const mapStateToProps = (state) => ({
  searchAPIUrl: searchAPIUrl(state),
});

export default connect(mapStateToProps, {})(SearchBar);
