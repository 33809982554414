export const accountNotificationsIsLoading = (state) => {
  let accountNotifications;
  if (state) {
    accountNotifications = state.accountNotificationsReducer;
  }

  let isLoading;
  if (accountNotifications) {
    isLoading = accountNotifications.loading;
  }

  return isLoading;
};

export const hasAccountNotifications = (state) => {
  const notifications = accountNotifications(state);

  let hasAccountNotifications;
  if (notifications && notifications.length > 0) {
    hasAccountNotifications = true;
  }

  return hasAccountNotifications;
};

export const hasUnreadAccountNotifications = (state) => {
  const notifications = accountUnreadNotifications(state);

  let hasUnreadAccountNotifications;
  if (notifications && notifications.length > 0) {
    hasUnreadAccountNotifications = true;
  }

  return hasUnreadAccountNotifications;
};

export const accountNotifications = (state) => {
  let accountNotifications;
  if (state) {
    accountNotifications = state.accountNotificationsReducer;
  }

  let notifications = [];
  if (accountNotifications) {
    notifications = accountNotifications.notifications;
  }

  return notifications;
};

export const accountUnreadNotifications = (state) => {
  let accountNotifications;
  if (state) {
    accountNotifications = state.accountNotificationsReducer;
  }

  let notifications = [];
  if (accountNotifications) {
    notifications = accountNotifications.notifications;
  }

  const accountUnreadNotifications = notifications.reduce(
    (accumulator, notification) => {
      const { IsRead } = (notification || {});

      if (!IsRead) {
        return [...accumulator, ...[notification]];
      }

      return accumulator;
    },
    [],
  );

  return accountUnreadNotifications;
};

export const accountNotificationsHasMore = (state) => {
  let accountNotifications;
  if (state) {
    accountNotifications = state.accountNotificationsReducer;
  }

  let accountNotificationsHasMore;
  if (accountNotifications) {
    accountNotificationsHasMore = accountNotifications.hasMore;
  }

  return accountNotificationsHasMore;
};

export const accountNotificationsStart = (state) => {
  let accountNotifications;
  if (state) {
    accountNotifications = state.accountNotificationsReducer;
  }

  let accountNotificationsStart;
  if (accountNotifications) {
    accountNotificationsStart = accountNotifications.start;
  }

  return accountNotificationsStart;
};

export const accountNotificationsCount = (state) => {
  let accountNotifications;
  if (state) {
    accountNotifications = state.accountNotificationsReducer;
  }

  let accountNotificationsCount;
  if (accountNotifications) {
    accountNotificationsCount = accountNotifications.count;
  }

  return accountNotificationsCount;
};
