import React from 'react';
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT
} from "@pikcha/typography";
import isDesktop from '../../../components/Responsive/isDesktop';
import isTablet from '../../../components/Responsive/isTablet';
import isMobile from '../../../components/Responsive/isMobile';

export default ({
  minPrice,
  maxPrice
}) => {
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  return (
    <div
      style={{
        padding: "1em 2em",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <TypographyBody
        size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Small_Bold}
        color="neutral/grey50"
      >
        Price
      </TypographyBody>
      <TypographySubtitle
        component={isOnDesktop ?
          TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold :
          isOnTablet ?
            TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold
            : TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Bold}
      >
        ${minPrice} - ${maxPrice}
      </TypographySubtitle>
    </div>
  )
}
