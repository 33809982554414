import React, { useState } from "react";
import DayjsUtils from "@date-io/dayjs";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, createStyles, ThemeProvider } from "@material-ui/core/styles";
import RefreshOutlined from "@material-ui/icons/RefreshOutlined";
import { IconButton, InputAdornment } from "@material-ui/core";
import { TypographyButton, TYPOGRAPHY_BUTTON_TYPE } from "@pikcha/typography";
import CalendarMonth from "./assets/CalendarMonth"

const myTheme = createTheme({
  overrides: {
    MuiInputBase: createStyles({
      root: {
        '&.Mui-disabled': {
          backgroundColor: '#F2F4F8',
          cursor: 'not-allowed'
        },
      },
      input: {
        fontSize: '16px',
        //@ts-ignore
        fontWeight: '400',
        lineHeight: '28px',
        fontFamily: 'Montserrat',
        color: '#000',
      },
    }),
    MuiButton: createStyles({
      root: {
        backgroundIimage: 'none !important',
        padding: '0 !important',
        transition: 'none !important',
        minWidth: 'unset !important',
      },
    }),
    MuiInputLabel: createStyles({
      root: {
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: '700' as 'bold',
        lineHeight: '24px',
        color: '#343A3F',
        marginBottom: '0.5em',
        '&.Mui-disabled': {
          color: '#343A3F',
        },
        '&.Mui-focused': {
          color: '#343A3F',
        },
      },
      shrink: {
        transform: 'none'
      }
    }),
    MuiInput: createStyles({
      '& $disabled': {
        backgroundColor: '#F2F4F8'
      },
      root: {
        border: '1px solid #DBE0E4',
        borderRadius: '4px',
        minWidth: '248px',
        minHeight: '40px',
        maxHeight: '40px',
        padding: '6px 12px 6px 12px',
        marginTop: '1.5em !important',
      },
      underline: {
        '&::before': {
          border: '1px solid #DBE0E4',
          transition: 'none',
          content: 'none'
        },
        '&::after': {
          border: '1px solid #DBE0E4',
          transition: 'none',
          content: 'none'
        },
        '&:hover': {
          border: '1px solid #DBE0E4',
        }
      }
    }),
    //@ts-ignore
    MuiPickersToolbar: createStyles({
      toolbar: {
        backgroundColor: '#4B01D1'
      }
    }),
    MuiPickerDTTabs: createStyles({
      tabs: {
        backgroundColor: '#4B01D1'
      }
    }),
    MuiPickersDay: createStyles({
      daySelected: {
        backgroundColor: '#4B01D1'
      }
    }),
    MuiPickersClockPointer: createStyles({
      pointer: {
        backgroundColor: '#4B01D1'
      }
    }),
    MuiPickersClock: createStyles({
      pin: {
        backgroundColor: '#4B01D1'
      }
    }),
    MuiPickersClockNumber: createStyles({
      clockNumberSelected: {
        backgroundColor: '#4B01D1'
      }
    }),
  }
});

export default ({
  label,
  value,
  onChange,
  style,
  disabled = false,
  handleDateChange,
  minDate,
  disableFuture,
  disablePast,
  maxDate,
  minDateMessage,
  maxDateMessage,
  initialDate
}) => {
  return (
    <ThemeProvider theme={myTheme}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <div
          style={{
            ...style,
            display: 'flex'
          }}
        >
          <DateTimePicker
            style={{
              width: '100%',
            }}
            disabled={disabled}
            value={value}
            autoOk
            disableFuture={disableFuture}
            disablePast={disablePast}
            allowKeyboardControl={false}
            minDate={minDate}
            maxDate={maxDate}
            minDateMessage={minDateMessage}
            maxDateMessage={maxDateMessage}
            variant="inline"
            label={label}
            onChange={onChange}
            format="DD/MM/YYYY hh:mm a"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CalendarMonth />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TypographyButton
            onClick={() => handleDateChange(initialDate ? new Date(initialDate) : new Date())}
            disabled={disabled}
            buttonStyle={{
              minHeight: '40px',
              maxHeight: '40px',
              minWidth: '40px',
              maxWidth: '40px',
              alignSelf: 'end',
              marginLeft: '0.5em'
            }}
            component={TYPOGRAPHY_BUTTON_TYPE.Outline}
            icon={<RefreshOutlined />}
          >
          </TypographyButton>
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}