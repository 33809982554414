import React, { useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { usePayPal } from "../../../hooks/usePayPal";
import { COUNTRIES } from "../../../config/countries";
import {PikchaModal} from "../../../components/baseComponents/modal/PikchaModal";
import {
  PikchaButton,
  PikchaLoader
} from "../../../components/baseComponents";
import axios from "axios";
import AuthorizeService from "../../../auth/AuthorizeService";
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import { updateUserPaymentMethod } from "../../../actions/account";
import { setAlert } from "../../../actions/alert";

const PaymentMethod = ({
  account,
  settings: {settings},
  hasPaypal,
  paymentMethod,
  paymentInfo,
  setPaymentInfo,
  updateUserPaymentMethod,
  setIsEditingPayment,
  setAlert
}) => {
  const { paypalEmail, paypalVerification } = account.user || {};
  const isPaypalVerified = paypalVerification && paypalVerification !== "false";

  const [showIframe, setShowIframe] = useState(false);
  const [iframeURL, setIframeURL] = useState("");
  const [retrievePending, setRetrievePending] = useState(false);

  const { palette, typography } = useTheme();
  const getCountryLocale = (label) => {
    let code = "";
    COUNTRIES.forEach((country) => {
      if (country.label === label) {
        code = country.localeCode;
      }
    });
    return code;
  };
  var localCountry = account.user && account.user.country ? account.user.country : null;
  var userLocale = localCountry? getCountryLocale(localCountry): null;
  var userLocaleValue = userLocale!= null? userLocale : "en-us";
  usePayPal(userLocaleValue);

  const handleBankTranferClick = () => {
    const dto = {
      PaymentRailId: account.user.paymentRailsId,
      Email: account.user.email,
    }
    axios.post(
      `${
        settings.payoutUrl ||
        "https://pikcha.local:1900/v1/payout"
      }/BankTransferWidget`,
      dto,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res)=>{
      setIframeURL(res.data);
    }).then(()=>{
      setShowIframe(true)
    }).catch((err)=>{
      console.log(err);
    })
  }

  const handleModalClose = async () => {
    setRetrievePending(true);
    const token = await AuthorizeService.getAccessToken();
    await axios.get(`/api/Payment/${account.user.paymentRailsId}/UpdatePaymentInformation`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((res)=>{
      const paymentInfo = res.data;
      const accounts = paymentInfo.accounts;
      if(accounts.length < 1) {
        setPaymentInfo("");
        let info = {
          paymentMethod: ""
        }
        updateUserPaymentMethod(info);
        setRetrievePending(false);
        setShowIframe(false);
        return
      }
      const paymentMethod = accounts.filter((account) => account.primary === "true")[0].type;
      const primaryPaymentMethod = accounts.filter((account) => account.primary === "true")[0];
      const dto = paymentMethod.toLowerCase() === "bank-transfer" ? {
        paymentMethod: "bank-transfer",
        currency: primaryPaymentMethod.currency,
        accountNum: primaryPaymentMethod.accountNum,
        branchId: primaryPaymentMethod.branchId,
        bankName: primaryPaymentMethod.bankName,
        recipientFees: primaryPaymentMethod.recipientFees
      } : {
        paymentMethod: "paypal",
        paypalEmail: primaryPaymentMethod.emailAddress,
        paypalVerification: paypalVerification
      }
      updateUserPaymentMethod(dto);
      setPaymentInfo(paymentInfo);
      setAlert("Payment information has been updated", "success")
    }).catch((err)=>{
      console.log(err);
      if(err.response.status === 404) {
        setPaymentInfo("")
      }
      setAlert("Payment information fails to update, please retry later", "error")
    });
    setRetrievePending(false);
    setShowIframe(false);
    setIsEditingPayment(false);
  }

  return (
    <div
      style={{
        marginBottom: "2.5em"
      }}
    >
    {hasPaypal && paymentMethod === "Paypal" && (
      <div>
        {isPaypalVerified && (
          <div
            style={{
              margin: "1.5em 0",
            }}
          >
            <div>
              <div
                style={{
                  color: palette.text.secondary,
                  fontWeight: typography.fontWeightMedium,
                  fontSize: typography.smallest,
                  display: "flex",
                }}
              >
                <div>
                  <p>
                    Use the following button to connect with or update your <br />
                    Paypal account. Ensure your Paypal account is <br />
                    <a href="https://www.paypal.com/us/smarthelp/article/FAQ1219" target="_blank" >verified</a>. You must be verified to get paid out <br />
                  </p>
                </div>
              </div>
            </div>
            <div
              id="paypal-button"
              style={{
                width: 280,
                marginTop: "1em",
              }}
            />
          </div>
        )}
        {!isPaypalVerified && (
          <div
            style={{
              marginBottom: "3em",
            }}
          >
            <div
              style={{
                color: palette.text.secondary,
                fontWeight: typography.fontWeightMedium,
                fontSize: typography.smallest,
                display: "flex",
              }}
            >
              <div
                style={{
                  fontSize: typography.smallest,
                  fontWeight: typography.fontweightMedium,
                  color: palette.text.secondary
                }}
              >
                <br /><br />All PayPal payouts are made in USD.<br /><br />
                Please ensure your paypal account is <a href="https://www.paypal.com/us/smarthelp/article/FAQ1219" target="_blank" >verified</a>.<br />
                If you don't have a paypal account yet, you can create one using the button below<br />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "1em",
              }}
            >
              <p
                style={{
                  marginLeft: "2em",
                  fontSize: typography.small,
                  fontWeight: typography.fontWeightBold,
                  color: palette.text.secondary,
                }}
              >
                {paypalEmail}
              </p>
            </div>
            <div
              id="paypal-button"
              style={{
                width: 280,
                marginTop: "1em",
              }}
            />
          </div>
        )}
      </div>
      )}
      {!hasPaypal && paymentMethod === "Paypal" && (
        <div
          style={{
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightMedium,
            color: palette.text.secondary,
            marginTop: "2em"
          }}
        >
          Paypal Payouts is not enabled in your country.
          <br />
          Please add a bank transfer payment method.
        </div>
      )}
      {paymentMethod === "BankTransfer" && (
        <div
          style={{
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightMedium,
            color: palette.text.secondary,
            marginTop: "2em"
          }}
        >
          Get paid in your local currency, directly to your bank account.
          <br />
          Choose when you receive your funds.
          <br />
          <br />
          Refer to our <a href="/guides" target="_blank" >payments guide</a> for further information.
          <br />
          <br />
          <PikchaButton
            fullWidth
            style={{
              height: 36,
              width: 275
            }}
            action={paymentInfo ? "octonary" : "primary"}
            onClick={()=>{
              handleBankTranferClick()
            }}
          >
            <AccountBalanceRoundedIcon
              style={{
                width: 24,
                height: 24,
                marginRight: "0.3em"
              }}
            />
            {paymentInfo ? "Update Bank Account" : "Add Bank Account"}
          </PikchaButton>
        </div>
      )}
      <PikchaModal
        onClose={()=>{
          handleModalClose();
        }}
        open={showIframe}
      >
        <iframe
          src={iframeURL}
          style={{
            height: 640
          }}
        />
        {retrievePending && (
          <div
            style={{
              position: "absolute",
              top:0,
              right:0,
              bottom:0,
              left:0,
              textAlign: "center",
              margin: "auto",
              height: "fit-content"
            }}
          >
            <span style={{fontWeight: typography.fontWeightMedium}}>
              Updating Payment information
            </span>
            <div className="mt-1-em">
              <PikchaLoader />
            </div>
          </div>
        )}
      </PikchaModal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  settings: state.settingsReducer,
});

export default connect(mapStateToProps, {
  updateUserPaymentMethod,
  setAlert
})(PaymentMethod);
