import {
  GALLERY_FOLLOWING_ERROR,
  GALLERY_FOLLOWING_LOADED,
  GALLERY_FOLLOWING_LOADING,
  GALLERY_FOLLOWING_RESET,
} from "../actions/types";
import {
  GALLERY_PAGE_DEFAULT,
  GALLERY_ITEMINPAGE_DEFAULT,
} from "../helpers/constants";

const initialState = {
  loading: false,
  error: null,
  photos: [],
  page: GALLERY_PAGE_DEFAULT,
  itemInPage: GALLERY_ITEMINPAGE_DEFAULT,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GALLERY_FOLLOWING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GALLERY_FOLLOWING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: false,
      };
    case GALLERY_FOLLOWING_LOADED:
      return {
        ...state,
        photos: [...state.photos, ...payload],
        page: state.page + 1,
        loading: false,
      };
    case GALLERY_FOLLOWING_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        photos: [],
        page: GALLERY_PAGE_DEFAULT,
        itemInPage: GALLERY_ITEMINPAGE_DEFAULT,
        hasMore: true,
      };
    default:
      return state;
  }
};
