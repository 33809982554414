import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { queuedShoppingCartItems, readyShoppingCartItems, shoppingCartItems, } from "../../selectors/shoppingCart";
import ShippingOptionsDesktop from "./ShippingOptionsDesktop";
import { PikchaLoader } from "../../components/baseComponents";
import { NavHashLink } from "../../components/baseComponents/NavHashLink";
import { IntlCurrencyFormatter } from "../../components/NumberFormatters/IntlCurrencyFormatter";
import { makeStyles } from "@material-ui/styles";
import isMobile from "../../components/Responsive/isMobile";
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE
} from "@pikcha/typography"

const useStyles = ({isOnMobile}) => makeStyles(({ typography }) => ({
  step1Container: {
    position: "relative",
    marginTop: "1em",
    animation: "fadeIn 0.6s",
    background: "white",
    width: "100%"
  },
  emptyCartContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  shippingOptions: {
    display: "flex"
  },
  shippingDetails: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1em",
  },
  notActive: {
    position: "relative",
    animation: "fadeIn 0.6s",
    background: "white",
    width: "100%"
  },
  estimatedContainer: {
    display: "flex",
    alignItems: "space-between",
    flexDirection: isOnMobile && "column"
  },
  shippingToText: {
    fontSize: typography.standard,
  },
  shippingToContainer: {
    maxWidth: 400,
    width: "100%"
  },
  estimatedTextContainer: {
    maxWidth: 400,
    width: "100%",
    textAlign: !isOnMobile &&  "right",
    marginTop: isOnMobile && '1em'
  },
  shippingValue: {
    fontWeight: typography.fontWeightBold
  }
}))

const Step1Desktop = ({
  shippingCountries,
  country,
  countryError,
  onCountryChanged,
  onCountryStateChanged,
  countryState,
  countryStateError,
  refreshShippingForCountry,
  refreshShippingState,
  shipType,
  allShipTypes,
  addShipType,
  nextStep,
  shipState,
  shippingCountry,
  readyShoppingCartItems,
  allShippingStates,
  shoppingCart,
  account,
  isActive,
  areThereReadyShoppingCartItems,
  shippingOptionError,
  setShippingOptionError
}) => {
  const isOnMobile = isMobile();
  const classes = useStyles({isOnMobile})();
  const [shipTypeIndex, setShipTypeIndex] = useState(0);

  useEffect(() => {
    if (allShipTypes.shipType.indexOf(shipType) > -1) {
      setShipTypeIndex(allShipTypes.shipType.indexOf(shipType));
    }
  }, [shipType]);

  return (
    <>
      <div className={[classes.step1Container, !areThereReadyShoppingCartItems ? classes.emptyCartContainer : ''].join(' ')}>
        {account.user && !account.loadingUser && shoppingCart.loading && !areThereReadyShoppingCartItems && (
          <PikchaLoader />
        )}
        {areThereReadyShoppingCartItems && isActive && (
          <>
            <div className={classes.shippingOptions}>
              <ShippingOptionsDesktop
                shippingCountries={shippingCountries}
                country={country}
                countryError={countryError}
                onCountryChanged={onCountryChanged}
                onCountryStateChanged={onCountryStateChanged}
                refreshShippingForCountry={refreshShippingForCountry}
                refreshShippingState={refreshShippingState}
                countryState={countryState}
                countryStateError={countryStateError}
                shipType={shipType}
                allShipTypes={allShipTypes}
                addShipType={addShipType}
                shippingCountry={shippingCountry}
                shipState={shipState}
                allShippingStates={allShippingStates}
                readyShoppingCartItems={readyShoppingCartItems}
                shippingOptionError={shippingOptionError}
                setShippingOptionError={setShippingOptionError}
              />
            </div>
            <div className={classes.shippingDetails}>
              <NavHashLink
                onClick={nextStep}
                to="/checkout#delivery-and-details"
                style = {isOnMobile && {width: '100%'}}
              >
                <TypographyButton
                  component= {TYPOGRAPHY_BUTTON_TYPE.Primary}
                  buttonStyle = {isOnMobile && {width:'100%'}}
                  disabled={shippingOptionError}
                >
                  Continue
                </TypographyButton>
              </NavHashLink>
            </div>
          </>
        )}
        {!isActive && (
          <div className={classes.notActive}>
            <div className={classes.estimatedContainer} >
              <div className={classes.shippingToContainer}>
                  Shipping to {country}{countryState ? ', '+countryState: ''}
              </div>
              <div className={classes.estimatedTextContainer}>
                  Ships in {allShipTypes.estDelTime[shipTypeIndex]} business {allShipTypes.estDelTime[shipTypeIndex] === "1" ? (
                    <span>day</span>) : (<span>days</span>)}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span className={classes.shippingValue}>
                    <IntlCurrencyFormatter value={+shoppingCart.shoppingCart?.shipCostPresentment} />
                  </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  state,
  account: state.accountReducer,
  shoppingCart: state.shoppingCartReducer,
  shoppingCartItems: shoppingCartItems(state),
  readyShoppingCartItems: readyShoppingCartItems(state),
  queuedShoppingCartItems: queuedShoppingCartItems(state),
});
export default connect(mapStateToProps, {})(Step1Desktop);
