import {
  SEARCH_LOADED,
  SEARCH_LOADING,
  SEARCH_ERROR,
  SEARCH_RESET,
  SEARCH_ALBUMS_LOADED,
  SEARCH_ALBUMS_LOADING,
} from "../actions/types";

import {
  GALLERY_ITEMINPAGE_DEFAULT,
  GALLERY_PAGE_DEFAULT,
} from "../helpers/constants";

const initialState = {
  albums:[],
  count: GALLERY_ITEMINPAGE_DEFAULT,
  start: GALLERY_PAGE_DEFAULT,
  error: null,
  hasMore: true,
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_ALBUMS_LOADING:
    case SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_LOADED:
    case SEARCH_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: false,
      };
    case SEARCH_ALBUMS_LOADED:
      const _albumResult = [...state.albums, ...payload];
      return {
        ...state,
        albums: _albumResult,
        loading: false,
        start: state.start + state.count,
        hasMore: payload.length > 0,
      };

    case SEARCH_RESET:
      return {
        ...state,
        albums:[],
        count: GALLERY_ITEMINPAGE_DEFAULT,
        start: GALLERY_PAGE_DEFAULT,
        error: null,
        hasMore: true,
        loading: true,
      };

    default:
      return state;
  }
};
