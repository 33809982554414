import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import ReactJoyride, { EVENTS, STATUS } from "react-joyride";
import LastStep from "./LastStep";
import { FB_PIXEL_EVENT_TYPES } from "../../hooks/useFbPixelEvent/event-constants";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { getSavedUtmDetails, isProductionSite } from "../../helpers/utils";

export default ({ id, handleInitProfileOnBoarding, initOnboardingFlow }) => {
  useEffect(() => {
    if (isProductionSite()) {
      ReactPixel.trackCustom(FB_PIXEL_EVENT_TYPES.ArtistPromotion, {
        ...getSavedUtmDetails(),
      });
      ReactGA.event("artist_promotion", {
        ...getSavedUtmDetails(),
      });
    }
  }, []);

  const { palette, shape } = useTheme();
  const [run, setRun] = useState(true);
  const [showRedo, setShowRedo] = useState(false);
  const [destroyComponent, setDestroyComponent] = useState(false);
  const [showJoyride, setShowJoyride] = useState(true);

  useEffect(() => {
    const overlayEl = document.querySelector(".Onboarding-overlay");
    if (overlayEl && destroyComponent) {
      const parentNode = overlayEl.parentNode;
      parentNode.removeChild(overlayEl);
    }

    if (!showJoyride) {
      initOnboardingFlow(false);
    }
  }, [destroyComponent, showJoyride]);

  const handleRun = (state) => {
    setRun(state);
  };
  const handleDestroyComponent = (state) => {
    setDestroyComponent(state);
  };

  const steps = [
    {
      content:
        "Take a quick tour of the features and get set off on the right foot!",
      target: ".Onboarding-init",
      placement: "center",
      title: "Welcome to the Artist Community!",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      content:
        "To add a profile picture, just press the camera button!",
      target: ".Onboarding-1",
      title: "Your Profile Picture",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      placement: "right",
    },
    {
      target: ".Onboarding-2",
      title: "Update Your Information",
      content:
        "Add a biography and links to your social media so that your collectors and fans can get to know you!",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      placement: "left",
    },
    {
      target: ".Onboarding-3",
      title: "Upload Your First Image!",
      content: (
        <div>
          Make sure you have verified your email address and paypal account, and
          you will be ready to begin uploading!
          <br />
          <br />
          We can't wait to see what you create!
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      placement: "left",
    },
    {
      target: ".Onboarding-4",
      title: "Create Your First Album!",
      content: (
        <div>
          Once you have uploaded 3 images, you will be able to create your first
          Album!
          <br />
          <br />
          Albums are a great way to group common artworks in your collection.
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      placement: "right",
    },
  ];

  const handleJoyrideCallback = ({ _, index, type, status }) => {
    if (status === "finished") {
      setShowRedo(true);
    }

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && run) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER && index === 0) {
      setRun(false);
      setTimeout((_) => {
        setRun(true);
      });
    }
  };

  return (
    <>
      {showRedo && (
        <LastStep
          id={id}
          handleInitProfileOnBoarding={handleInitProfileOnBoarding}
          handleDestroyComponent={handleDestroyComponent}
          handleRun={handleRun}
          handleLastStepClick={(value) => {
            setShowJoyride(value);
            setShowRedo(false);
          }}
        />
      )}
      <ReactJoyride
        run={run}
        steps={steps}
        hideBackButton
        locale={{
          next: "Next",
          back: "Back",
          last: "Close",
        }}
        styles={{
          buttonNext: {
            borderRadius: shape.borderRadius,
            borderColor: palette.primary.main,
            color: palette.primary.main,
            backgroundColor: "transparent",
            borderWidth: 2,
            maxHeight: 44,
            minHeight: 40,
            fontSize: "1rem",
            lineHeight: 1.34,
            padding: "4px 12px",
            fontWeight: 600,
            borderStyle: "solid",
          },
        }}
        scrollToFirstStep
        callback={handleJoyrideCallback}
        showProgress
        continuous
        showSkipButton
      />
    </>
  );
};
