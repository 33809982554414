export const options = [
  {label: "AUD", value: "AUD", symbol: '$', codeSymbol: 'A$', locale: 'en-AU'},
  {label: "CAD", value: "CAD", symbol: '$', codeSymbol: 'CA$', locale: 'en-CA'},
  {label: "GBP", value: "GBP", symbol: '£', codeSymbol: '£', locale: 'en-GB'},
  {label: "NZD", value: "NZD", symbol: '$', codeSymbol: 'NZ$', locale: 'en-NZ'},
  {label: "EUR", value: "EUR", symbol: '€', codeSymbol: '€', locale: 'de-DE'},
  {label: "SGD", value: "SGD", symbol: '$', codeSymbol: 'S$', locale: 'zh-SG'},
  {label: "CHF", value: "CHF", symbol: '₣', codeSymbol: '₣', locale: 'fr-CH'},
  {label: "JPY", value: "JPY", symbol: '¥', codeSymbol: 'JP¥', locale: 'ja-JP'},
  {label: "SEK", value: "SEK", symbol: 'kr', codeSymbol: 'kr', locale: 'en-AU'},
  {label: "HKD", value: "HKD", symbol: '$', codeSymbol: 'HK$', locale: 'zh-HK'},
  {label: "ILS", value: "ILS", symbol: '₪', codeSymbol: '₪', locale: 'en-AU'},
  {label: "CNY", value: "CNY", symbol: '¥', codeSymbol: 'CN¥', locale: 'en-AU'},
  {label: "ZAR", value: "ZAR", symbol: 'R', codeSymbol: 'R', locale: 'en-AU'},
  {label: "BRL", value: "BRL", symbol: '$', codeSymbol: 'R$', locale: 'en-AU'},
  {label: "NOK", value: "NOK", symbol: 'kr', codeSymbol: 'kr', locale: 'en-AU'},
  {label: "DKK", value: "DKK", symbol: 'kr', codeSymbol: 'kr', locale: 'da-DK'},
  {label: "RUB", value: "RUB", symbol: '₽', codeSymbol: '₽', locale: 'en-AU'},
  {label: "MXN", value: "MXN", symbol: '$', codeSymbol: 'MX$', locale: 'en-AU'},
  {label: "INR", value: "INR", symbol: '₹', codeSymbol: '₹', locale: 'en-IN'},
  {label: "KRW", value: "KRW", symbol: '₩', codeSymbol: '₩', locale: 'en-AU'},
  {label: "USD", value: "USD", symbol: '$', codeSymbol: 'US$', locale: 'en-US'}
];

export const units = [
  {label: "Inch", value: "inch", symbol: 'in'},
  {label: "CM", value: "cm", symbol: 'cm'}
];
