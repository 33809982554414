import React from "react";
import ContentLoader from "react-content-loader";
import { useTheme } from "@material-ui/core/styles";

export default (props) => {
  const { spacing, palette } = useTheme();
  
  return (
    <div
      style={{
        marginLeft: spacing(2),
      }}
    >
      <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        // viewBox="0 0 696 414"
        backgroundColor={palette.grey.lighter}
        foregroundColor={palette.grey.lighter}
        {...props}
      >
      </ContentLoader>
    </div>
  );
};
