import React, {useState} from "react";
import Select from "react-select";
import {useTheme} from "@material-ui/core/styles";

import PikchaLoader from "../loaders/PikchaLoader";
import PikchaDropdownMenuList from "./PikchaDropdownMenuList";
import {PikchaFieldWrapper} from "../index";

import "./PikchaDropdown.css";

export default ({
                    label,
                    className = "",
                    style = {},
                    fullWidth,
                    compactListSize,
                    noTopMargin,
                    noHelperClass,
                    required,
                    classNameOuter,
                    disabled,
                    placeholder = "",
                    isMulti = false,
                    closeMenuOnSelect = true,
                    valueColourDisabled,
                    FieldLabelProps = {},
                    ...rest
                }) => {
    const [isFocused, setIsFocused] = useState(false);

    const {palette, shape, typography} = useTheme();
    return (
        <div
            className={`${fullWidth ? "full-width" : ""} ${className}`}
            style={style}
            onFocusCapture={() => setIsFocused(true)}
            onBlurCapture={() => setIsFocused(false)}
        >
            <PikchaFieldWrapper
                classNameOuter="Dropdown-container"
                className={className}
                required={required}
                disabled={disabled}
                {...FieldLabelProps}
                style={{
                    color: isFocused
                        ? palette.primary.main
                        : disabled
                            ? palette.action.disabled
                            : palette.text.primary,
                }}
                label={label}
                noHelperClass={noHelperClass || noTopMargin}
                noFieldSet
                isFocused={isFocused}
            >
                <Select
                    isMulti={isMulti}
                    closeMenuOnSelect={closeMenuOnSelect}
                    className="Dropdown-innerContainer"
                    isDisabled={disabled ? disabled : false}
                    components={{
                        IndicatorSeparator: null,
                        MenuList: (props) => <PikchaDropdownMenuList {...props} />,
                        //DropdownIndicator: props => (
                        //  <PikchaDropdownIndicator {...props} />
                        //),
                    }}
                    aria-label="Select"
                    placeholder={placeholder}
                    loadingMessage={() => <PikchaLoader linearVariant />}
                    noOptionsMessage={() => ""}
                    styles={{
                        container: (provided, {isFocused, isDisabled}) => ({
                            ...provided,
                            width: "100%",
                            padding: 0,
                            fontSize: typography.smallest,
                            fontWeight: typography.fontWeightSemiMedium,
                            background: isDisabled
                                ? palette.action.disabledBackground
                                : palette.common.white,
                            borderRadius: shape.borderRadius,
                            borderColor: isFocused ? palette.primary.main : null,
                        }),
                        control: (provided, {isFocused, isDisabled}) => ({
                            ...provided,
                            padding: 0,
                            borderWidth: 2,
                            borderColor: isFocused
                                ? palette.primary.main
                                : isDisabled
                                    ? palette.action.disabled
                                    : "rgba(0, 0, 0, 0.23)",
                            boxShadow: "none",
                            cursor: "pointer",
                            borderRadius: shape.borderRadius,
                            backgroundColor: "transparent",
                            "&:hover": {
                                borderColor: isDisabled
                                    ? null
                                    : isFocused
                                        ? palette.primary.main
                                        : palette.text.primary,
                            },
                        }),
                        indicatorsContainer: (provided) => ({
                            ...provided,
                            borderRadius: shape.borderRadius,
                            cursor: "default",
                        }),
                        valueContainer: (provided) => ({
                            ...provided,
                            padding: 0,
                            borderRadius: shape.borderRadius,
                        }),
                        input: (provided) => ({
                            ...provided,
                            marginLeft: 14,
                            paddingTop: 0,
                            paddingBottom: 0,
                            margin: 0,
                            padding: 0,
                            //padding: '1px 0 1px 0',
                        }),
                        menuList: () => ({
                            cursor: "default",
                            borderRadius: shape.borderRadius,
                            maxHeight: !compactListSize ? "19rem" : "12rem",
                            paddingBottom: 4,
                            //paddingTop: 4,
                            position: "relative",
                            boxSizing: "border-box",
                        }),
                        menu: (provided) => ({
                            ...provided,
                            marginTop: 0,
                            border: `1px solid ${palette.secondary.main}`,
                            borderRadius: shape.borderRadius,
                            position: "absolute",
                            borderTop: 0,
                            paddingBottom: 8,
                            zIndex: 999,
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            paddingLeft: 14,
                            color: valueColourDisabled && palette.action.disabled,
                            fontSize: typography.smallest,
                        }),
                        singleValue: (provided, {isDisabled}) => ({
                            ...provided,
                            userSelect: isDisabled ? "none" : undefined,
                            color: !isDisabled && !valueColourDisabled
                                ? palette.text.primary
                                : palette.action.disabled,
                            paddingLeft: 14,
                            textTransform: "capitalize",
                        }),
                        option: (provided, {isFocused, isSelected}) => ({
                            ...provided,
                            cursor: "pointer",
                            marginLeft: 1,
                            fontSize: 12,
                            textTransform: "capitalize",
                            color: palette.grey.mediumDark,
                            paddingTop: 4,
                            paddingBottom: 4,
                            backgroundColor: isFocused /* && !isSelected*/
                                ? palette.grey.light
                                : //: isSelected
                                //? palette.secondary.main
                                null,
                            ":active": {
                                ...provided[":active"],
                                color: isSelected ? palette.secondary.contrastText : null,
                                backgroundColor: isSelected ? palette.secondary.main : null,
                            },
                        }),
                    }}
                    {...rest}
                />
            </PikchaFieldWrapper>
        </div>
    );
};
