import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from "@material-ui/core/styles";
import PendingOverlay from "./PendingOverlay";
import SignatureDisplayCropper
  from "../../containers/modals/UploadFlowModal/SignatureDisplayCropper";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  colors
} from "@pikcha/typography";
import { connect } from "react-redux";
import isDesktopHD from "../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isTablet from "../../components/Responsive/isTablet";
import UploadFlowModal
  from "../../containers/modals/UploadFlowModal/UploadFlowModal";
import { setAlert } from "../../actions/alert";
import * as Vibrant from "node-vibrant";
import { PIKCHA_CDN } from "../../helpers/constants";
// import UploadOverlay from "../../components/UploadOverlay/UploadOverlay";
// import Step3Overlay from "./Step3Overlay";

const ImagePreview = ({
  cropper,
  account,
  signature,
  signatureData,
  currentToggle,
  editionNum,
  setVibrantColors,
  selectedSignatureColor,
  setSelectedSignatureColor,
  releasedForms,
  uploadProgress,
  currentStep,
  uploadSuccessfully,
  heroShotsPending,
  selectedHeroShots
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const [signatureSize, setSignatureSize] = useState({
    width: 0,
    height: 0,
  })

  const { palette } = useTheme();
  const imageWrapper = useRef(null);
  const cropperRef = useRef(null);
  const signatureRef = useRef(null);

  const [imageWrapperWidth, setImageWrapperWidth] = useState(imageWrapper.current
    ? window.getComputedStyle(imageWrapper.current)
    : 119.2);
  const [calculateSigPosition, setCalculateSigPosition] = useState(false);
  const [croppedSignatureTop, setCroppedSignatureTop] = useState();
  const [croppedSignatureLeft, setCroppedSignatureLeft] = useState();
  const [cropperSignatureTextSize, setCropperSignatureTextSize] = useState();
  const [uploadImageModalState, setUploadImageModalState] = useState(false);

  const handleResize = () => {
    const { width } = imageWrapper.current ? window.getComputedStyle(
      imageWrapper.current) : {};
    const widthValue = isOnDesktopHD ? parseFloat(width) - 128 : isOnDesktopSD
      ? parseFloat(width) - 64
      : isOnTablet ? parseFloat(width) - 32 : parseFloat(width) - 16;
    setImageWrapperWidth(widthValue);
  };

  const getSignaturePosition = () => {
    if (calculateSigPosition) {
      let cropBox;
      if (cropperRef.current) {
        cropBox = cropperRef.current;
      }
      let signatureBox;
      if (signatureRef.current) {
        signatureBox = signatureRef.current;
      }
      let imageWrapperBox;
      if (imageWrapper.current) {
        imageWrapperBox = imageWrapper.current;
      }
      // let croppedSignatureWidth;
      let croppedSignatureHeight;
      if (cropBox && signatureBox && imageWrapperBox) {
        const squareSigPx = (cropBox.clientWidth * cropBox.clientHeight * 12) / 10000;
        let signatureRatio = signatureSize.width / signatureSize.height;
        if (isNaN(signatureRatio)) {
          signatureRatio = 2;
        }
        croppedSignatureHeight = Math.sqrt(squareSigPx / signatureRatio);

        setCropperSignatureTextSize(croppedSignatureHeight * 0.25);
        if (!isNaN(croppedSignatureHeight) && signatureSize.width > 0) {
          setCroppedSignatureTop(
            cropBox.offsetHeight + (imageWrapperBox.offsetHeight - cropBox.offsetHeight) / 2 -
            signatureSize.height -
            croppedSignatureHeight * 0.25 -
            Math.min(
              cropBox.clientHeight * 0.015,
              cropBox.clientWidth * 0.015,
            ) -
            (imageWrapperWidth * 0.07 / 3),
          );
          setCroppedSignatureLeft(
            currentToggle === "right" ?
              cropBox.offsetWidth + (imageWrapperBox.offsetWidth - cropBox.offsetWidth) / 2 -
              signatureSize.width -
              (imageWrapperWidth * 0.07 / 3) - 8 :
              (imageWrapperBox.offsetWidth - cropBox.offsetWidth) / 2 + (imageWrapperWidth * 0.07 / 3) + 8,
          );
        } else {
          setCroppedSignatureTop(
            cropBox.offsetHeight + (imageWrapperBox.offsetHeight - cropBox.offsetHeight) / 2 -
            (imageWrapperWidth * 0.07 / 3) -
            croppedSignatureHeight * 0.25,
          );
          setCroppedSignatureLeft(
            currentToggle === "right" ?
              cropBox.offsetWidth +
              (imageWrapperBox.offsetWidth - cropBox.offsetWidth) / 2 -
              (imageWrapperWidth * 0.07 / 3) -
              croppedSignatureHeight :
              (imageWrapperBox.offsetWidth - cropBox.offsetWidth) / 2 + (imageWrapperWidth * 0.07 / 3),
          );
        }
      }
    }
  };

  useEffect(() => {
    setCalculateSigPosition(false);
  }, [cropper.src]);

  useEffect(() => {
    getSignaturePosition();
  }, [calculateSigPosition, currentToggle, cropperRef, signatureSize]);

  useEffect(() => {
    if (calculateSigPosition) {
      window.addEventListener("resize", getSignaturePosition);
      return () => window.removeEventListener("resize", getSignaturePosition);
    }
  }, [calculateSigPosition, signatureSize, currentToggle]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [imageWrapper.current]);

  useEffect(() => {
    const hexList = [];
    if (cropper.src) {
      Vibrant.from(cropper.src).getPalette((err, palette) => {
        const length = Object.keys(palette).length;
        for (let i = 0; i < length; i++) {
          const key = Object.keys(palette)[i];

          if (!palette[key]) {
            break;
          }

          const hexValue = palette[key].hex;
          hexList.push(hexValue);
          if (i === 0) {
            setSelectedSignatureColor(hexValue);
          }
        }
      });
    }
    setVibrantColors(hexList);
  }, [cropper.src]);

  return (
    <>
      <UploadFlowModal
        setAlert={setAlert}
        account={account}
        modelStep={cropper.src ? 3 : 1}
        selectingRatio={cropper.selectedRatio}
        parentAspectRatio={cropper.selectedRatio}
        open={uploadImageModalState}
        croppedImgSrc={cropper.orgImgData}
        releasedForms={releasedForms}
        handleClickClose={() => {
          setUploadImageModalState(false);
        }}
      />
      <div className="full-width mr-1-em mt-4-em" style={{ maxWidth: 1000 }}>
        <div
          className="ImagePreview-container ProductItem-imageFrame full-height full-width flex-center justify-center relative p-4-em"
          style={{
            backgroundColor: palette.grey.lightest,
            height: "100%",
            maxHeight: 1000,
            borderRadius: 6,
            border: "0px solid rgba(12, 18, 28, .12)",
            position: "relative"
          }}
          ref={imageWrapper}
        >
          {currentStep > 6 && (
            <PendingOverlay
              progress={uploadProgress}
              uploadSuccessfully={uploadSuccessfully}
              heroShotsPending={heroShotsPending}
              selectedHeroShots={selectedHeroShots}
            />
          )}
          {/*
          {currentStep === 3 && (
            <Step3Overlay
              croppedSignatureTop={croppedSignatureTop}
              croppedSignatureLeft={croppedSignatureLeft}
              currentToggle={currentToggle}
            />
          )}
          //leave as a backup in case need it in future
          */}
          {cropper.src ? (
            <>
              <img
                className="block img-preview"
                ref={cropperRef}
                style={{
                  width: "auto",
                  height: "auto",
                  objectFit: "contain",
                  maxHeight: "100%",
                  border: `${imageWrapperWidth * 0.07 / 3}px solid #fff`,
                  maxWidth: "100%",
                  boxShadow: "0px 0px 4px 2px #999999"
                }}
                src={cropper.src}
                alt="Image Preview"
                onLoad={() => {
                  setCalculateSigPosition(true);
                }}
              />
              {currentStep < 7 && (
                <div
                  className="absolute"
                  style={{
                    bottom: 8,
                    zIndex: 10,
                    left: 8,
                  }}
                >
                  <div className="flex pt-2-em">
                    <TypographyButton
                      buttonStyle={{ backgroundColor: colors.Neutral.white00 }}
                      onClick={() => {
                        setUploadImageModalState(true);
                      }}
                      component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                    >
                      Change image
                    </TypographyButton>
                  </div>
                </div>
              )}
              <div ref={signatureRef}>
                {calculateSigPosition && croppedSignatureTop && croppedSignatureLeft && (
                  <SignatureDisplayCropper
                    textSize={cropperSignatureTextSize}
                    alignInnerPosition={currentToggle}
                    setSignatureSize={setSignatureSize}
                    signatureRef={signatureRef}
                    signatureData={signatureData}
                    signatureFont={signature.signatureFont}
                    style={{
                      position: "absolute",
                      top: croppedSignatureTop,
                      left: croppedSignatureLeft,
                    }}
                    color={selectedSignatureColor}
                    editionNumber={editionNum}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="flex-column full-width full-height">
              <div
                className="flex-center justify-center flex-grow-1 Step2-pikchaLoader"
              >
                <TypographyButton
                  component={TYPOGRAPHY_BUTTON_TYPE.IconLeading}
                  icon={<PublishRoundedIcon />}
                  onClick={() => {
                    setUploadImageModalState(true);
                  }}
                  buttonStyle={{
                    height: 45,
                    marginTop: 16,
                    width: "auto",
                    background: "white",
                    color: "black",
                    padding: "8px 16px",
                    boxShadow: "0px 1px 4px #999999"
                  }}
                  textStyle={{
                    fontWeight: 600
                  }}
                >
                  &nbsp;Select File or Drop
                </TypographyButton>
              </div>
            </div>
          )}
        </div>
        {currentStep > 6 && (
          <a href={`${PIKCHA_CDN}/${selectedHeroShots}`} style={{textDecoration: "none", color: colors.Neutral.white00}} download>
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.IconLeading}
              icon={<PublishRoundedIcon />}
              buttonStyle={{
                width: "100%",
                marginTop: "1em"
              }}
              disabled={heroShotsPending}
            >
              Download
            </TypographyButton>
          </a>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    cropper: state.cropperReducer,
    account: state.accountReducer,
    auth: state.authReducer,
    signature: state.signatureReducer,
  }
}

export default connect(mapStateToProps, {
  setAlert
})(ImagePreview);