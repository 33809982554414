import deepPurple from '@material-ui/core/colors/deepPurple';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import grey from '@material-ui/core/colors/grey';

export default {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  shape: {
    borderRadius: 6,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        color: '#FFF',
        boxShadow: 'none',
        fontWeight: 700,
        fontSize: 16,
        backgroundImage: 'linear-gradient(45deg,#081c69,#44159b 50%,#770fc6)',
        '&:hover': {
          boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
        },
      },
    },
  },
  palette: {
    common: {
      black: '#191919',
      white: '#FFF',
    },
    text: {
      primary: '#212121',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    link: {
      main: '#00E',
      active: 'hsla(240, 100%, 57%, 1)',
      hover: 'hsla(240, 100%, 67%, 1)',
      visited: 'hsla(240, 100%, 47%, 1)',
    },
    background: {
      default: '#FFF',
      // Use the paper background for all raised containers.
      paper: '#FBFBFB',
    },
    action: {
      active: grey[600],
      hover: 'rgba(0, 0, 0, 0.06)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.34)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    divider: 'rgba(0, 0, 0, 0.33)',
    primary: {
      darkest: deepPurple[900],
      darker: deepPurple[700],
      dark: deepPurple[800],
      mediumDark: deepPurple[600],
      medium: deepPurple[500],
      mediumLight: deepPurple[400],
      lighter: deepPurple[300],
      light: deepPurple[200],
      lightest: deepPurple[100],
      main: '#6100FF',
      contrastText: '#FAFAFA',
    },
    purple: {
      medium: '#6202EE',
    },
    secondary: {
      lightest: deepPurple.A100,
      light: deepPurple.A200,
      main: deepPurple.A400,
      dark: deepPurple.A700,
      mediumDark: '#3700B3',
      contrastText: '#FAFAFA',
    },
    // Use grey shades to style basically any element that is not coloured.
    grey: {
      darkest: grey[900],
      dark: grey[800],
      darker: grey[700],
      mediumDark: grey[600],
      medium: grey[500],
      mediumLight: grey[400],
      lighter: grey[300],
      light: grey[200],
      lightest: grey[100],
    },
    error: {
      main: red.A400,
      contrastText: '#FFF',
    },
    warning: {
      main: amber.A400,
      contrastText: '#FFF',
    },
    success: {
      main: green.A400,
      contrastText: '#FFF',
    },
  },
  typography: {
    fontFamily:
      "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI,"
      + 'Noto Sans,Ubuntu,Cantarell,'
      + ' Helvetica Neue,Arial,sans-serif',
    fontFamilySerif: 'Cormorant, serif',
    fontFamilyPermanentMarker: 'Permanent Marker, cursive',
    fontFamilyFugazOne: 'Fugaz One, cursive',
    fontFamilyNothingYouCanDo: 'Nothing You Could Do, cursive',
    fontFamilyMontserrat: 'Montserrat, sans-serif',
    fontFamilyCormorant: "'Cormorant', serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightSemiMedium: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    tiniest: '0.56rem',
    tiny: '.68rem',
    smallest: '.75rem',
    verysmall: '.8rem',
    small: '.875rem',
    smaller: '.93rem',
    standard: '1rem',
    standardLarge: '1.125rem',
    larger: '1.25rem',
    large: '1.5rem',
    xLarge: '1.875rem',
    xxLarge: '2.25rem',
    xxxLarge: '3rem',
    xxxxLarge: '3.75rem',
    largest: '4.5rem',
    fontSize: 16,
    button: {
      fontWeight: 400,
    },
    h1: {
      fontSize: '1.875rem',
      extraLarge: '2.2rem',
      letterSpacing: '-.025rem',
      lineHeight: 1.5,
      lineHeightReduced: 1.23,
      smaller: '1.5rem',
      tiny: '1.25rem',
    },
    h2: {
      fontSize: '1.125rem',
      fontSizeSmaller: '1rem',
      letterSpacing: '-.01rem',
      lineHeight: 1.6,
    },
    body: {
      fontSize: '1rem',
      lineHeight: 1.5,
      maxWidth: '45em',
      letterSpacing: '.002rem',
    },
  },
};
