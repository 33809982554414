import { useLocation } from "react-router-dom";

/**
 * Extracts query parameters from the current location.
 *
 * Optionally accepts an array of strings to reduce and clean unwanted
 * characters.
 */
export function useQueryParams(charsToStrip = [""]) {
  let queryParams = useLocation().search.split("&");

  charsToStrip.reduce((acc, curr, idx, src) => {
    const cleanedParamA = queryParams[idx - 1].replace(src[idx - 1], "");
    const cleanedParamB = queryParams[idx].replace(src[idx], "");

    queryParams = {
      cleanedParamA: cleanedParamA,
      cleanedParamB: cleanedParamB,
    };
  });

  return queryParams;
}
