import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({
  children,
  className = "",
  style = {},
  bodyCopy,
  ...rest
}) => {
  const { typography, palette } = useTheme();

  return (
    <ol
      className={`${className} ordered-list`}
      style={{
        fontFamily: bodyCopy
          ? typography.body.fontFamily
          : typography.fontFamilyMontserrat,
        color: palette.text.primary,
        fontSize: "0.93rem",
        fontWeight: 600,
        lineHeight: typography.body.lineHeight,
        letterSpacing: typography.body.letterSpacing,
        maxWidth: typography.body.maxWidth,
        ...style,
      }}
      {...rest}
    >
      {children}
    </ol>
  );
};
