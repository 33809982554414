export const IMAGE_TOTAL_SOLD = 'totSold';

export const IMAGE_VIEWS = 'views';

export const GALLERY_PAGE_DEFAULT = 1;
export const GALLERY_ITEMINPAGE_DEFAULT = 20;

export const DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP = 340;
export const DROPDOWN_NOTIFICATIONS_WIDTH_MOBILE = 340;

export const PRIMARY_LINEAR_GRADIENT = 'linear-gradient(45deg, #380093 20.83%,#6100FF 76.04%)';

export const ROUTES_WITH_FOOTER = ['/checkout', '/print', '/search', '/guides', '/contact', '/about', '/policies', '/pikcha100', '/artist100', '/following', '/saved', '/profile', '/order-search', '/gift-card', '/prints-and-frames'];
export const ROUTES_WITHOUT_SIDEDRAWER = ['/checkout', '/print', '/upload', '/edit'];

export const ONBOARDING_PORTAL_ID = 'onboardingPortalRoot';

export const IMAGE_COLOURS = [
  'red',
  'pink',
  'purple',
  'darkblue',
  'lightblue',
  'teal',
  'darkgreen',
  'lightgreen',
  'yellow',
  'orange',
  'beige',
  'brown',
  'grey',
  'steelblue',
  'white',
  'black',
];

export const CAROUSEL_DUMMY_DATA = [
  {
    id: 'ace78685-7b7d-438a-aa4e-f8a713d39d0b',
    title: 'The four puppies',
    caption:
      'Harrison’s work, and others included in the New Museum’s 2007 opening exhibition “Unmonumental,” offered a counterpoint or even a rejection of the sculpture that dominated the preceding years: the big, brash, Neo-Pop of artists such as Jeff Koons and Takashi Murakami.',
    location: 'The Azores, Portugal',
    totSold: 2,
    views: 127,
    setPrice: 0,
    category: {
      id: '68ef5d5b-7361-44f3-b810-17bce37557dc',
      name: 'Religion & Culture',
      images: null,
    },
    type: null,
    orientation: 'Landscape',
    colour: null,
    thumbnail:
      'uploads/Test/Images/Thumbnails/ace78685-7b7d-438a-aa4e-f8a713d39d0b.jpg',
    watermark:
      'uploads/Test/Images/Watermarks/ace78685-7b7d-438a-aa4e-f8a713d39d0b.jpg',
    customizable:
      'uploads/Test/Images/Customizables/ace78685-7b7d-438a-aa4e-f8a713d39d0b.jpg',
    isNudity: false,
    imageEarning: 0,
    numberOfSales: 0,
    uploadedAtUtc: '2020-10-05T23:26:03.745547+00:00',
    savedAt: '0001-01-01T00:00:00',
    minimumPrice: 0,
    artist: {
      location: 'Paris, France',
      aggrImViews: '2284',
      totViews: 2284,
      totSales: 10,
      avgPrice: 5133.6,
      id: 'f0d2d6cf-82f9-425b-8d7c-77f7c2d93810',
      fName: 'Aide',
      lName: 'Ghera',
      avatar: 'uploads/Test/Avatars/3.jpg',
      userStatus: 'Active',
    },
    tags: ['Mountain', 'Beach', 'Summer'],
    qrCodeUrl: null,
    friendlyUrl: null,
    aspRatioX: 4,
    aspRatioY: 3,
    imageRanking: 42.57,
    isSaved: false,
  },
  {
    id: '4b9a8f70-f22a-47e3-a034-f8734263ae11',
    title: 'Capture of earth from space',
    caption:
      'Upon entering the Tate’s Turbine Hall, visitors to Eliasson’s The weather project were greeted by a huge glowing orb that hovered near the ceiling. A fine mist filled the hall, diffusing its spellbinding glow throughout the room.',
    location: 'Halong Bay, Vietnam',
    totSold: 0,
    views: 771,
    setPrice: 0,
    category: {
      id: '81722ff5-636f-4c80-b3dc-5be2e5f5f3c3',
      name: 'Objects',
      images: null,
    },
    type: null,
    orientation: 'Portrait',
    colour: null,
    thumbnail:
      'uploads/Test/Images/Thumbnails/4b9a8f70-f22a-47e3-a034-f8734263ae11.jpg',
    watermark:
      'uploads/Test/Images/Watermarks/4b9a8f70-f22a-47e3-a034-f8734263ae11.jpg',
    customizable:
      'uploads/Test/Images/Customizables/4b9a8f70-f22a-47e3-a034-f8734263ae11.jpg',
    isNudity: false,
    imageEarning: 0,
    numberOfSales: 0,
    uploadedAtUtc: '2020-09-20T00:19:23.787875+00:00',
    savedAt: '0001-01-01T00:00:00',
    minimumPrice: 0,
    artist: {
      location: 'California, USA',
      aggrImViews: '2163',
      totViews: 2163,
      totSales: 5,
      avgPrice: 5904.6,
      id: '5b94670f-ccb7-4caf-9af8-d02745117318',
      fName: 'Lenora',
      lName: 'Delacruz',
      avatar: 'uploads/Test/Avatars/2.jpg',
      userStatus: 'Active',
    },
    tags: ['Beach', 'Summer'],
    qrCodeUrl: null,
    friendlyUrl: null,
    aspRatioX: 3,
    aspRatioY: 4,
    imageRanking: 0,
    isSaved: false,
  },
  {
    id: 'b2763d1d-4a07-496d-bc68-5476885c4274',
    title: 'Mountains behind the river',
    caption:
      'Harrison’s work, and others included in the New Museum’s 2007 opening exhibition “Unmonumental,” offered a counterpoint or even a rejection of the sculpture that dominated the preceding years: the big, brash, Neo-Pop of artists such as Jeff Koons and Takashi Murakami.',
    location: 'Melbourne, Australia',
    totSold: 1,
    views: 300,
    setPrice: 0,
    category: {
      id: '81722ff5-636f-4c80-b3dc-5be2e5f5f3c3',
      name: 'Objects',
      images: null,
    },
    type: null,
    orientation: 'Portrait',
    colour: null,
    thumbnail:
      'uploads/Test/Images/Thumbnails/b2763d1d-4a07-496d-bc68-5476885c4274.jpg',
    watermark:
      'uploads/Test/Images/Watermarks/b2763d1d-4a07-496d-bc68-5476885c4274.jpg',
    customizable:
      'uploads/Test/Images/Customizables/b2763d1d-4a07-496d-bc68-5476885c4274.jpg',
    isNudity: false,
    imageEarning: 0,
    numberOfSales: 0,
    uploadedAtUtc: '2020-10-02T00:21:03.824274+00:00',
    savedAt: '0001-01-01T00:00:00',
    minimumPrice: 0,
    artist: {
      location: 'California, USA',
      aggrImViews: '2163',
      totViews: 2163,
      totSales: 5,
      avgPrice: 5904.6,
      id: '5b94670f-ccb7-4caf-9af8-d02745117318',
      fName: 'Lenora',
      lName: 'Delacruz',
      avatar: 'uploads/Test/Avatars/2.jpg',
      userStatus: 'Active',
    },
    tags: ['Beach', 'Summer'],
    qrCodeUrl: null,
    friendlyUrl: null,
    aspRatioX: 4,
    aspRatioY: 5,
    imageRanking: 99.33,
    isSaved: false,
  },
  {
    id: '7164cda5-6bd2-4cd4-be6c-f0e6491dae67',
    title: 'The four puppies',
    caption:
      'The weather project fits into a wider trend, beginning in the late 1990s, in which museums began to regularly install monumental-scale artworks indoors as exhibitions unto themselves. Critics have compared these installations to amusement park rides and viewed their spectacle and pomp as a frivolous pandering to the masses.',
    location: 'Cappadocia, Turkey',
    totSold: 1,
    views: 901,
    setPrice: 0,
    category: {
      id: '8ddb9c55-b1cf-453a-b5e5-af496de07e48',
      name: 'Nature',
      images: null,
    },
    type: null,
    orientation: 'Landscape',
    colour: null,
    thumbnail:
      'uploads/Test/Images/Thumbnails/7164cda5-6bd2-4cd4-be6c-f0e6491dae67.jpg',
    watermark:
      'uploads/Test/Images/Watermarks/7164cda5-6bd2-4cd4-be6c-f0e6491dae67.jpg',
    customizable:
      'uploads/Test/Images/Customizables/7164cda5-6bd2-4cd4-be6c-f0e6491dae67.jpg',
    isNudity: false,
    imageEarning: 0,
    numberOfSales: 0,
    uploadedAtUtc: '2020-09-20T00:29:23.563655+00:00',
    savedAt: '0001-01-01T00:00:00',
    minimumPrice: 0,
    artist: {
      location: 'Paris, France',
      aggrImViews: '2284',
      totViews: 2284,
      totSales: 10,
      avgPrice: 5133.6,
      id: 'f0d2d6cf-82f9-425b-8d7c-77f7c2d93810',
      fName: 'Aide',
      lName: 'Ghera',
      avatar: 'uploads/Test/Avatars/3.jpg',
      userStatus: 'Active',
    },
    tags: ['Mountain', 'Beach', 'Summer', 'Art', 'Sea', 'Forest'],
    qrCodeUrl: null,
    friendlyUrl: null,
    aspRatioX: 3,
    aspRatioY: 2,
    imageRanking: 297.66,
    isSaved: false,
  },
  {
    id: '71a4675a-dbf8-48af-b39f-247bd90a420c',
    title: 'Capture of earth from space',
    caption:
      'The weather project fits into a wider trend, beginning in the late 1990s, in which museums began to regularly install monumental-scale artworks indoors as exhibitions unto themselves. Critics have compared these installations to amusement park rides and viewed their spectacle and pomp as a frivolous pandering to the masses.',
    location: 'Angel Falls, Venezuela',
    totSold: 4,
    views: 1255,
    setPrice: 0,
    category: {
      id: '488cd9bd-2134-47c5-90ec-d89422b689ef',
      name: 'Technology',
      images: null,
    },
    type: null,
    orientation: 'Landscape',
    colour: null,
    thumbnail:
      'uploads/Test/Images/Thumbnails/71a4675a-dbf8-48af-b39f-247bd90a420c.jpg',
    watermark:
      'uploads/Test/Images/Watermarks/71a4675a-dbf8-48af-b39f-247bd90a420c.jpg',
    customizable:
      'uploads/Test/Images/Customizables/71a4675a-dbf8-48af-b39f-247bd90a420c.jpg',
    isNudity: false,
    imageEarning: 0,
    numberOfSales: 0,
    uploadedAtUtc: '2020-09-12T00:21:03.766792+00:00',
    savedAt: '0001-01-01T00:00:00',
    minimumPrice: 0,
    artist: {
      location: 'Paris, France',
      aggrImViews: '2284',
      totViews: 2284,
      totSales: 10,
      avgPrice: 5133.6,
      id: 'f0d2d6cf-82f9-425b-8d7c-77f7c2d93810',
      fName: 'Aide',
      lName: 'Ghera',
      avatar: 'uploads/Test/Avatars/3.jpg',
      userStatus: 'Active',
    },
    tags: ['Mountain', 'Beach', 'Summer', 'Art', 'Sea', 'Forest'],
    qrCodeUrl: null,
    friendlyUrl: null,
    aspRatioX: 5,
    aspRatioY: 4,
    imageRanking: 415.47,
    isSaved: false,
  },
];

export const MINIMUM_MEGA_PIXELS = 15;

export const MINIMUM_EARNINGS = 10;

export const MAXIMUM_PRICE = 999999999;

export const MAXIMUM_TAGS = 10;

export const PIKCHA_INVITE_KEY = 'pikcha-invite';
export const existingInviteCode = localStorage.getItem(PIKCHA_INVITE_KEY)
  ? parseInt(localStorage.getItem(PIKCHA_INVITE_KEY))
  : 0;

export const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_VALIDATION_REGEX = /^(?=\D*\d)\S{8,}$/;

export const EMAIL_NOTIFICATION_TYPE = 'Email Verify';
export const PAYPAL_NOTIFICATION_TYPE = 'Paypal Verify';

export const EMAIL_INVALID_MESSAGE = 'Please check that you entered a valid email address';

export const MAX_FOLLOWERS_TO_DISPLAY = 4;

export const BORDER_WIDTH_RATIO = 0.07/6;

export const BORDER_WIDTH_RATIO_BIG = 0.07/3;

export const MODAL_ENTER_TRANSITION_DURATION = 600;
export const MODAL_EXIT_TRANSITION_DURATION = 100;

export const SIDE_DRAWER_EXPANDED_WIDTH = 208;
export const SIDE_DRAWER_COLLAPSED_WIDTH = 68;

export const PROFILE_PHOTO_DEFAULT_SIZE = {
  width: 120,
  height: 120,
}

export const PROFILE_PHOTO_MEDIUM_SIZE = {
  width: 80,
  height: 80,
}

export const ICON_SIZE_DEFAULT = {
  height: 24,
  width: 24,
};

export const ICON_SIZE_SMALL = {
  height: 16,
  width: 16,
};

export const ICON_SIZE_TINY = {
  height: 12,
  width: 12,
};

export const ICON_SIZE_LARGE = {
  height: 32,
  width: 32,
};

export const ICON_SIZE_EXTRA_LARGE = {
  height: 48,
  width: 48,
};

export const ICON_SIZE_SUPER_LARGE = {
  height: 75,
  width: 75,
};

export const WINDOW_WIDTH = 1320;

export const TAX_EXEMPTION_VALUE_1 = 'Exemption1';
export const TAX_EXEMPTION_VALUE_2 = 'Exemption2';
export const TAX_EXEMPTION_VALUE_3 = 'Exemption3';
export const TAX_EXEMPTION_VALUE_4 = 'Exemption4';

export const PIKCHA_CDN = "https://cdn.pikcha.com";

export const PIKCHA_CONFIG_CDN = "https://cdn.pikcha.com";

export const PIKCHA_GUEST_CART = 'PIKCHA-GUEST-CARRT';

export const UTM_LOCAL_STORAGE_KEY = 'utmDetails';

export const reservationStatusCode = {
  soldOut: 0,
  availableForPurchase: 1,
  reserved: 2,
  selectedByUser: 3,
};
