import React from "react";

import { useTheme } from "@material-ui/core/styles";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { ICON_SIZE_DEFAULT } from "../../../helpers/constants";

import { PikchaModal } from "../../../components/baseComponents";
import { PikchaButton } from "../../../components/buttons";
import isMobile from "../../../components/Responsive/isMobile";

export default ({ open, cancelDeleteConfirmation, doDeleteImage, thumbnail }) => {
  const { palette, typography } = useTheme();
  const isOnMobile = isMobile();
  return (
    <PikchaModal
      open={open}
      onClose={cancelDeleteConfirmation}
      paperStyle={{
        overflow: "hidden",
        minHeight: 0,
        height: 280,
        maxHeight: 280,
        maxWidth: 440,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems:'center',
          height: '150px',
          margin: '30px'
        }}
      >
        <div>
          <img 
            src={thumbnail} 
            alt="thumbnail"
            style={{
              height: 'auto',
              width: 'auto',
              maxWidth: '145px',
              maxHeight: '145px',
            }}
          />
        </div>
      <div
        style={{
          marginLeft: "1em",
          marginRight: isOnMobile? "0em" : "1em",
          // marginTop: "4em",
          // marginBottom: "2em",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <DeleteOutlineIcon
            style={{
              ...ICON_SIZE_DEFAULT,
              color: palette.text.primary,
            }}
          />
          <strong>Delete Image</strong>
        </div>
        <p
          style={{
            marginTop: "1em",
            fontSize: typography.small,
            fontWeight: typography.fontWeightSemiMedium,
            color: palette.text.primary,
          }}
        >
          Are you sure that you want to delete this image?
        </p>
      </div>
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "2em",
          marginRight: "2em",
        }}
      >
        <PikchaButton
          fullWidth
          onClick={(e) => cancelDeleteConfirmation()}
        >
          No
        </PikchaButton>
        <PikchaButton
          action="secondary"
          mainStyle={{
            marginLeft: "1em",
          }}
          fullWidth
          onClick={(e) => doDeleteImage()}
        >
          Yes, Delete
        </PikchaButton>
      </div>
    </PikchaModal>
  );
};
