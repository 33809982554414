import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import CustomiseProduct from "../../containers/customise/CustomiseProduct";
import CustomiseSummary from "../../containers/customise/CustomiseSummary";
import FeatureGallery from "../../containers/customise/FeatureGallery";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isTablet from "../../components/Responsive/isTablet";
import isMobile from "../../components/Responsive/isMobile";
import Desktop from "../../components/Responsive/Desktop";
import ArtistOnboarding from "../../containers/ArtistOnboarding/ArtistOnboarding";
import Aunthentictyimg from "../../assets/images/Authentictyimg.jpg";
import Gallerycard from "../../assets/images/galleryCard.jpg";
import CustomiseProfile from "../../containers/customise/CustomiseProfile";
import { Link } from "react-router-dom";
import { initOnboardingFlow } from "../../actions/onboarding";
import axios from "axios";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TYPOGRAPHY_BUTTON_TYPE,
  TYPOGRAPHY_BUTTON_TEXT,
  TypographyTitle,
  TYPOGRAPHY_TITLE_COMPONENT,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyButton,
  colors,
} from "@pikcha/typography";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CustomiseBenefits from "../../containers/customise/CustomiseBenefits";
import CustomiseIntro from "../../containers/customise/CustomiseIntro";
import ImageTitleAndArtist from "../../containers/customise/ImageTitle&Artist.jsx";

export default ({
  fields,
  setFields,
  postInitialization,
  sideDrawer,
  startCrop,
  summary,
  location,
  imageRenderChanged,
  jondoConfigMaterials,
  addToCart,
  image,
  artist,
  featureChanged,
  updateSummary,
  feature,
  galleryImages,
  onCanvasLoading,
  onCanvasFinishedLoading,
  isPageLoading,
  giveProductPrices,
  expectedProductNumber,
  reservations,
  updateGalleryCard,
  onEditionSelected,
  descriptionCard,
  inverseDescriptionCard,
  isAlreadySigned,
  isSigned,
  signatureAlignment,
  signatureSample,
  isOnboarding,
  authModalOpen,
  authModalOnClose,
  authModalOnLogin,
  authModalOnJoin,
  authModalOnContinueAsGuest,
  modalImage,
  setClickAsGuest,
  user,
  profile,
  account,
  isUser,
  handleFollowClick,
  handleUnfollowClick,
  setAlert,
  handleInitProfileOnBoarding,
  setFinishedOnboarding,
  isOwnProfile,
  setImageLoading,
  imageLoading,
  setShotsGenerated,
  shotsGenerated,
  ...rest
}) => {
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const isOnDesktopSD = isDesktopSD();
  const { palette } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState({});

  useEffect(() => {
    setIsLoading(true)
    const selectedCanvasVar = fields.selectedCanvas?.label?.toLowerCase();
    const selectedMaterialVar = fields.selectedMaterial?.label?.toLowerCase();
    const selectedFrameVar = fields.selectedFrame?.isFramed?.toLowerCase();
    let data;
    if (selectedCanvasVar &&
      selectedMaterialVar &&
      selectedFrameVar) {
      data =
      {
        medium: selectedCanvasVar,
        finish: selectedMaterialVar,
        isFramed: selectedFrameVar
      }
    }
    if (data) {
      axios({
        method: 'post',
        url: '/api/Printer/GetFeatures',
        data: data,
        config: {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      })
        .then((response) => {
          setApiResponse(response.data)
          setIsLoading(false)
        })
        .catch(error => { console.log('the error has occured: ' + error) })
    }
  }, [fields]);

  useEffect(() => {
    /**
     *
     * <script src="https://widget.reviews.io/carousel-inline-iframeless/dist.js?_t=2022040503"></script>
      <link rel="stylesheet" href="https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2022040503">
      <link rel="stylesheet" href="https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2022040503">
    */
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.appendChild(document.createTextNode(`new carouselInlineWidget('reviewsio-carousel-widget',{
      store: 'www.pikcha.com',
      sku: '',
      lang: 'en',
      carousel_type: 'default',
      styles_carousel: 'CarouselWidget--sideHeader',

      options:{
        general:{
          review_type: 'company, product',
          min_reviews: '1',
          max_reviews: '20',
          address_format: 'CITY, COUNTRY',
          enable_auto_scroll: 10000,
        },
        header:{
          enable_overall_stars: true,
          rating_decimal_places: 2,
        },
        reviews: {
          enable_customer_name: true,
          enable_customer_location: true,
          enable_verified_badge: true,
          enable_recommends_badge: true,
          enable_photos: true,
          enable_videos: true,
          enable_review_date: true,
          disable_same_customer: true,
          min_review_percent: 4,
          third_party_source: true,
          hide_empty_reviews: true,
          enable_product_name: true,
          tags: "",
          branch: "",
          enable_branch_name: false,
        },
        popups: {
          enable_review_popups:  true,
          enable_helpful_buttons: true,
          enable_helpful_count: true,
          enable_share_buttons: true,
        },
      },
    //},
    translations: {
        'Verified Customer': 'Verified Customer'
    },
    styles:{
      '--base-font-size': '16px',
      '--base-maxwidth':'100%',
      '--reviewsio-logo-style':'var(--logo-normal)',

      '--common-star-color':' #0E1311',
      '--common-star-disabled-color':' rgba(0,0,0,0.25)',
      '--medium-star-size':' 22px',
      '--small-star-size':'19px',
      '--x-small-star-size':'16px',
      '--x-small-star-display':'inline-flex',

      '--header-order':'1',
      '--header-width':'160px',
      '--header-bg-start-color':'transparent',
      '--header-bg-end-color':'transparent',
      '--header-gradient-direction':'135deg',
      '--header-padding':'0.5em',
      '--header-border-width':'0px',
      '--header-border-color':'rgba(0,0,0,0.1)',
      '--header-border-radius':'0px',
      '--header-shadow-size':'0px',
      '--header-shadow-color':'rgba(0, 0, 0, 0.1)',

      '--header-star-color':'inherit',
      '--header-disabled-star-color':'inherit',
      '--header-heading-text-color':'inherit',
      '--header-heading-font-size':'inherit',
      '--header-heading-font-weight':'inherit',
      '--header-heading-line-height':'inherit',
      '--header-heading-text-transform':'inherit',
      '--header-subheading-text-color':'inherit',
      '--header-subheading-font-size':'inherit',
      '--header-subheading-font-weight':'inherit',
      '--header-subheading-line-height':'inherit',
      '--header-subheading-text-transform':'inherit',

      '--item-maximum-columns':'5',
      '--item-background-start-color':'transparent',
      '--item-background-end-color':'transparent',
      '--item-gradient-direction':'135deg',
      '--item-padding':'0.5em',
      '--item-border-width':'0px',
      '--item-border-color':'rgba(0,0,0,0.1)',
      '--item-border-radius':'0px',
      '--item-shadow-size':'0px',
      '--item-shadow-color':'rgba(0,0,0,0.1)',

      '--heading-text-color':' #0E1311',
      '--heading-text-font-weight':' 600',
      '--heading-text-font-family':' inherit',
      '--heading-text-line-height':' 1.4',
      '--heading-text-letter-spacing':'0',
      '--heading-text-transform':'none',

      '--body-text-color':' #0E1311',
      '--body-text-font-weight':'400',
      '--body-text-font-family':' inherit',
      '--body-text-line-height':' 1.4',
      '--body-text-letter-spacing':'0',
      '--body-text-transform':'none',

      '--scroll-button-icon-color':'#0E1311',
      '--scroll-button-icon-size':'24px',
      '--scroll-button-bg-color':'transparent',

      '--scroll-button-border-width':'0px',
      '--scroll-button-border-color':'rgba(0,0,0,0.1)',

      '--scroll-button-border-radius':'60px',
      '--scroll-button-shadow-size':'0px',
      '--scroll-button-shadow-color':'rgba(0,0,0,0.1)',
      '--scroll-button-horizontal-position':'0px',
      '--scroll-button-vertical-position':'0px',

      '--badge-icon-color':'#0E1311',
      '--badge-icon-font-size':'15px',
      '--badge-text-color':'#0E1311',
      '--badge-text-font-size':'inherit',
      '--badge-text-letter-spacing':'inherit',
      '--badge-text-transform':'inherit',

      '--author-font-size':'inherit',
      '--author-font-weight':'inherit',
      '--author-text-transform':'inherit',

      '--photo-video-thumbnail-size':'60px',
      '--photo-video-thumbnail-border-radius':'0px',

      '--popup-backdrop-color':'rgba(0,0,0,0.75)',
      '--popup-color':'#ffffff',
      '--popup-star-color':'inherit',
      '--popup-disabled-star-color':'inherit',
      '--popup-heading-text-color':'inherit',
      '--popup-body-text-color':'inherit',
      '--popup-badge-icon-color':'inherit',
      '--popup-badge-icon-font-size':'19px',
      '--popup-badge-text-color':'inherit',
      '--popup-badge-text-font-size':'14px',
      '--popup-border-width':'0px',
      '--popup-border-color':'rgba(0,0,0,0.1)',
      '--popup-border-radius':'0px',
      '--popup-shadow-size':'0px',
      '--popup-shadow-color':'rgba(0,0,0,0.1)',
      '--popup-icon-color':'#0E1311',

      '--tooltip-bg-color':'#0E1311',
      '--tooltip-text-color':'#ffffff',
    },
  });`));
    document.body.appendChild(script);
  }, [])

  useEffect(() => {
    setImages([descriptionCard, inverseDescriptionCard]);
  }, [descriptionCard, inverseDescriptionCard])

  /*
  useEffect(() => {
    axios.get(
      `${process.env.NODE_ENV === "production"
      ? ""
      : "https://pikcha.local:1900"
    }/v1/mockup/MockupRequest/GetHeroShots/${location?.state?.image?.id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {

    }).catch((err) => {

    })
  }, [location?.state?.image?.id])
  */

  const maxCanvasWidth = 1000;
  let dimensionsWidth;
  let dimensionsHeight;
  if (isOnMobile) {
    dimensionsWidth = document.body.clientWidth;
    dimensionsHeight = dimensionsWidth;
  } else if (isOnTablet) {
    dimensionsWidth = document.body.clientWidth;
    dimensionsHeight = dimensionsWidth;
  } else if (isOnDesktopSD) {
    dimensionsWidth = document.body.clientWidth * 0.5;
    dimensionsHeight = dimensionsWidth;
  } else {
    dimensionsHeight = document.body.clientHeight * 0.75;
    dimensionsWidth = dimensionsHeight;
  }
  if (dimensionsWidth > maxCanvasWidth) {
    dimensionsWidth = maxCanvasWidth;
    dimensionsHeight = dimensionsWidth;
  }
  const [dimensions, setDimensions] = useState({
    width: dimensionsWidth,
    height: dimensionsHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("deviceorientation", handleResize, true);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("deviceorientation", handleResize);
    };
  }, []);
  const handleResize = () => {
    let windowWidth = document.body.clientWidth;
    let windowHeight = document.body.clientHeight;

    let newWidth;
    let newHeight;
    if (isOnMobile) {
      newWidth = windowWidth;
      newHeight = newWidth;
    } else if (isOnTablet) {
      newWidth = windowWidth;
      newHeight = newWidth;
    } else if (isOnDesktopSD) {
      newWidth = windowWidth * 0.5;
      newHeight = newWidth;
    } else {
      newHeight = windowHeight * 0.75;
      newWidth = newHeight;
    }

    if (newWidth > maxCanvasWidth) {
      newWidth = maxCanvasWidth;
      newHeight = newWidth;
    }

    setDimensions({
      width: newWidth,
      height: newHeight,
    });
  };

  return (
    <div
      style={{
        minHeight: "98vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className="mb-3"
        style={{
          marginTop: "3em",
          maxWidth: 1320
        }}
      >
        {!shotsGenerated && (
          <div
            style={{
              height: 710,
              width: "100%",
              background: colors.Neutral.grey20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TypographySubtitle
              component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
            >
              We have a little problem
            </TypographySubtitle>
            <TypographyBody>
              Please wait a few minutes and try again.
            </TypographyBody>
          </div>
        )}
        {shotsGenerated && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              <CustomiseSummary
                maxCanvasWidth={maxCanvasWidth}
                sideDrawerOpen={sideDrawer.payload}
                startCrop={startCrop}
                summary={summary}
                image={location.state?.image}
                imageRenderChanged={imageRenderChanged}
                onCanvasLoading={onCanvasLoading}
                onCanvasFinishedLoading={onCanvasFinishedLoading}
                isPageLoading={isPageLoading}
                expectedProductNumber={expectedProductNumber}
                authModalOpen={authModalOpen}
                authModalOnClose={authModalOnClose}
                authModalOnLogin={authModalOnLogin}
                authModalOnJoin={authModalOnJoin}
                authModalOnContinueAsGuest={authModalOnContinueAsGuest}
                modalImage={modalImage}
                setClickAsGuest={setClickAsGuest}
                setImageLoading={setImageLoading}
                imageLoading={imageLoading}
                setShotsGenerated={setShotsGenerated}
              />
              <div className="mt-2-em">
                <FeatureGallery
                  photoLimit={8}
                  galleryImages={galleryImages}
                  photoSize={100}
                  dimensionWidth={dimensions.width > 680 ? dimensions.width : 680}
                  selectedMaterial={summary?.selectedCanvas?.id}
                />
              </div>
              <ImageTitleAndArtist image={location.state?.image} />
            </div>
            <div
              style={{
                flexGrow: 1,
                width: "100%",
              }}
            >
              {isPageLoading && (
                <div
                  className="text-left"
                  style={{
                    ...(isOnMobile
                      ? {
                        marginTop: "-2em",

                        padding: "0.25em",
                      }
                      : isOnTablet
                        ? {
                          marginTop: "3em",
                        }
                        : {
                          marginTop: 32,
                          marginLeft: "2em",
                          marginRight: "2em",
                        }),
                    borderRadius: 6,
                    boxShadow:
                      "-2px 2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor: palette.common.white,
                    display: "flex",
                    justifyContent: "center",
                    padding: "1em",
                    height: 707,
                    width: 432,
                  }}
                />
              )}
              {!isPageLoading &&
                jondoConfigMaterials &&
                jondoConfigMaterials.length > 0 && (
                  <CustomiseProduct
                    style={{
                      maxWidth: "max-content",
                    }}
                    dimensions={dimensions}
                    fields={fields}
                    setFields={setFields}
                    postInitialization={postInitialization}
                    onAddToCart={addToCart}
                    onFeatureChanged={featureChanged}
                    JondoConfigMaterials={jondoConfigMaterials}
                    image={location.state?.image}
                    orientation={"landscape"}
                    onChange={updateSummary}
                    onGalleryCardChange={updateGalleryCard}
                    giveProductPrices={giveProductPrices}
                    expectedProductNumber={expectedProductNumber}
                    reservations={reservations}
                    onEditionSelected={onEditionSelected}
                    isAlreadySigned={isAlreadySigned}
                    setImageLoading={setImageLoading}
                    shotsGenerated={shotsGenerated}
                  />
                )
              }
            </div>
          </div>
        )}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => { setIsOpen(false) }}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
            style={{
              maxWidth: 550
            }}
          />
        )}
        <div style={{
          marginTop:'2em',
          backgroundColor:'#F2F4F8'
          }} id="reviewsio-carousel-widget" />
        <CustomiseIntro
          apiResponse ={apiResponse}
          isLoading={isLoading}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3em",
            padding: "2em 0",
            borderRadius: "6px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
            backgroundColor: colors.Neutral.grey20,
            padding:'2em 4em'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "40%",
              borderRadius: "0px 6px 6px 0px",
            }}
          >
            <TypographySubtitle
              component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
            >
              Authenticity
            </TypographySubtitle>
            <div
              style={{
                fontSize: "0.875rem",
                fontWeight: "400px",
                color: "rgba(0, 0, 0, 0.54)",
                marginTop: "2em",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
              >
                Gallery Card
              </TypographyBody>
            </div>
            <div
              style={{
                fontSize: "0.875rem",
                fontWeight: "400px",
                color: "rgba(0, 0, 0, 0.54)",
                marginTop: "2em",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <TypographyBody>
                All Pikcha Prints create a new, unique gallery card, featuring A QR code that links to the artwork listing on Pikcha and a unique number to track authenticity
                <br />The cards come with double sided Velcro straps that can be hung on any surface.
              </TypographyBody>
            </div>
            <div
              style={{
                fontSize: "0.875rem",
                fontWeight: "400px",
                color: "rgba(0, 0, 0, 0.54)",
                marginTop: "2em",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
              >
                Signing
              </TypographyBody>
            </div>
            <div
              style={{
                fontSize: "0.875rem",
                fontWeight: "400px",
                color: "rgba(0, 0, 0, 0.54)",
                marginTop: "2em",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <TypographyBody>
                Pikcha Prints are digitally signed and numbered by the artist.
              </TypographyBody>
            </div>
          </div>
          <div
            style={{
              borderRadius: "6px 0 0 6px",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <img src={Gallerycard}
              style={{
                maxWidth: 423,
                maxHeight: 400,
                border: "1em solid white"
              }}>
            </img>
            <img src={Aunthentictyimg}
              style={{
                marginTop: "1em",
                maxWidth: 423,
                maxHeight: 400,
                border: "1em solid white"
              }}>
            </img>
          </div>
        </div>
        <CustomiseBenefits />
        <div
          style={{
            backgroundColor: palette.common.black,
            padding: "5em"
          }}
        >
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              marginTop: "2em"
            }}
          >
            <TypographyTitle
              component={TYPOGRAPHY_TITLE_COMPONENT.Desktop_H2_Bold}
              color="neutral/white00"
            >
              Need something made custom?
            </TypographyTitle>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
            }}>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Large_Regular}
              color="neutral/white00"
              style={{
                marginTop: "1em",
                marginBottom: "2em"
              }}
            >
              Get in touch with our team and we’ll see how we can make it happen.
            </TypographyBody>
          </div>
          <div
            style={{
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Link
              to="/contact"
            >
              <TypographyButton
                buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
                component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                buttonStyle={{
                  background: colors.Neutral.white00,
                  color: colors.Neutral.ink90,
                  width: 150,
                  marginTop: "1em"
                }}
                textStyle={{
                  fontWeight: 600
                }}
              >
                Get In Touch
              </TypographyButton>
            </Link>
          </div>
        </div>
        <Desktop>
          <CustomiseProfile
            user={user}
            isUser={isUser}
            handleFollowClick={handleFollowClick}
            handleUnfollowClick={handleUnfollowClick}
            artist={artist}
            profile={profile}
            account={account}
            setAlert={setAlert}
            isOwnProfile={isOwnProfile}
            {...rest}
          />
          {isOnboarding &&
            account &&
            account.user &&
            account.user.isUserPromoted === true && (
              <ArtistOnboarding
                handleInitProfileOnBoarding={handleInitProfileOnBoarding}
                profile={profile}
                account={account}
                initOnboardingFlow={initOnboardingFlow}
              />
          )}
        </Desktop>
      </div>
    </div>
  );
};

