import { withStyles } from "@material-ui/core/styles";
import { ToggleButtonGroup } from "@material-ui/lab";
import React from "react";

const TypographyToggleButtonGroup = withStyles(({ palette, shape }) => ({
  root: {
    "& > button:first-child": {
      borderRadius: `4px 0 0 4px`,
    },
    "& > button:last-child": {
      borderRadius: `0 4px 4px 0`,
    },
  },
}))(({ children, ...rest }) => (
  <ToggleButtonGroup exclusive {...rest}>
    {children}
  </ToggleButtonGroup>
));

export default TypographyToggleButtonGroup;
