import React from 'react';

export default ({ iconSize, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={iconSize}
    viewBox="0 0 24 24"
    width={iconSize}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      fill={color}
      d="M19 12v7H5v-7H3v9h18v-9h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z"
    />
  </svg>
);
