import { withStyles } from "@material-ui/core/styles";
import { ToggleButton } from "@material-ui/lab";

export const PikchaToggleButton = withStyles(({ palette, typography }) => ({
  root: ({ width, style }) => ({
    borderTopColor: "grey",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderRightColor: "grey",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderBottomColor: "grey",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderLeftColor: "grey",
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
    padding: "6px 12px",
    fontSize: typography.small,
    textTransform: "capitalize",
    fontWeight: typography.fontWeightMedium,
    color: palette.grey.lighter,
    background: "white",
    width,
    ...style,
  }),
  selected: ({ selectedStyle }) => ({
    boxShadow: `inset 0 0 5px 1px ${palette.grey.mediumLight}`,
    color: `${palette.text.primary} !important`,
    ...selectedStyle,
  }),
}))(ToggleButton);
