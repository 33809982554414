import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { makeStyles } from "@material-ui/styles";
import { connect } from 'react-redux';
import TagsChip from './TagsChip';
import { setAlert } from '../../actions/alert';
import './TagsPicker.css';
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";

const useStyles = makeStyles(() => ({
  firstInput:{
    border:'2px solid rgba(0,0,0,0.23)',
    minHeight:'38px'
  }
}));

const TagsPicker = ({
  value,
  handleTagAdd,
  disabled,
  handleTagDelete,
  setAlert,
  onMouseLeave,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handlePasteRestriction = (e) => {
    setAlert('copying and pasting tags is not allowed', 'error');
    e.preventDefault();
    return false;
  };
  const { palette, typography, shape } = useTheme();
  const classes = useStyles();
  return (
    <div
      onFocusCapture={() => setIsFocused(true)}
      onBlurCapture={() => setIsFocused(false)}
      onMouseLeave={onMouseLeave}
    >
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
        style={{
          lineHeight: undefined
        }}
      >
        Tags (10 max)
      </TypographyBody>
      <ChipInput
        InputProps={{
          classes: {
            input: 'TagsPicker-input',
          },
        }}
        disableUnderline
        disabled={disabled || undefined}
        classes={{
          root: classes.firstInput,
          inputRoot: classes.inputRoot,
          label: classes.label,
          input: classes.input,
          chipContainer: classes.chipContainer,
        }}
        newChipKeys={['Enter', ',', ';', ' ', '#', 'Tab']}
        chipRenderer={({ text }, key) => (
          <TagsChip
            key={key}
            index={key}
            palette={palette}
            typography={typography}
            text={text}
            handleTagDelete={handleTagDelete}
          />
        )}
        style={{
          borderRadius: shape.borderRadius,
        }}
        value={value}
        onPaste={(e) => handlePasteRestriction(e)}
        onAdd={(tag) => handleTagAdd(tag, value)}
        onDelete={(tag, index) => handleTagDelete(tag, index)}
        allowDuplicates={false}
        fullWidth
      />
    </div>
  );
};

export default connect(null, {
  setAlert,
})(TagsPicker);
