import React from 'react';
import isMobile from "../../components/Responsive/isMobile";
import { TypographySubtitle, TYPOGRAPHY_SUBTITLE_COMPONENT, colors, TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";
import PikchaLoader from '../../components/baseComponents/loaders/PikchaLoader'

export default ({ apiResponse, isLoading }) => {
  const isOnMobile = isMobile();
  return (
    <>
      {isLoading ? (
        <div
          style={{
            marginTop: "4em",
          }}
        >
          <PikchaLoader />
        </div>
      ) : (
        <div 
          className="flex"
          style={{
            flexDirection: "column",
            backgroundColor: colors.Neutral.white00,
            padding: isOnMobile ? "2em 1em" : "2em 4em",
            justifyContent: "center"
          }}
        >
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
            style={{
              marginBottom: "2em"
            }}
          >
            Printing and framing
          </TypographySubtitle>
          <div 
            className="full-name flex"
            style={{ flexDirection: "column" }}
          >
            {apiResponse.print &&
              <div
                className="flex full-width"
                style={{
                  flexDirection: isOnMobile ? "column" : "row"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    width: "20%"
                  }}
                >
                  Print
                </TypographyBody>
                <TypographyBody
                  style={{
                    maxWidth: isOnMobile ? "100%" : "80%"
                  }}
                  dangerouslySetInnerHTML={{__html: apiResponse?.print}}
                />
              </div>
            }
            {apiResponse.border &&
              <div
                className="flex full-width mt-2-em"
                style={{
                  flexDirection: isOnMobile ? "column" : "row"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    width: "20%"
                  }}
                >
                  Border
                </TypographyBody>
                <TypographyBody
                  style={{
                    maxWidth: isOnMobile ? "100%" : "80%"
                  }}
                  dangerouslySetInnerHTML={{__html: apiResponse?.border}}
                />
              </div>
            }
            {apiResponse.frame &&
              <div
                className="flex full-width mt-2-em"
                style={{
                  flexDirection: isOnMobile ? "column" : "row"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    width: "20%"
                  }}
                >
                  Frame
                </TypographyBody>
                <TypographyBody
                  style={{
                    maxWidth: isOnMobile ? "100%" : "80%"
                  }}
                  dangerouslySetInnerHTML={{__html: apiResponse?.frame}}
                />
              </div>
            }
            {apiResponse.glass &&
              <div
                className="flex full-width mt-2-em"
                style={{
                  flexDirection: isOnMobile ? "column" : "row"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    width: "20%"
                  }}
                >
                  Glass
                </TypographyBody>
                <TypographyBody
                  style={{
                    maxWidth: isOnMobile ? "100%" : "80%"
                  }}
                  dangerouslySetInnerHTML={{__html: apiResponse?.glass}}
                />
              </div>
            }
            {apiResponse.backing &&
              <div
                className="flex full-width mt-2-em"
                style={{
                  flexDirection: isOnMobile ? "column" : "row"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    width: "20%"
                  }}
                >
                  Backing
                </TypographyBody>
                <TypographyBody
                  style={{
                    maxWidth: isOnMobile ? "100%" : "80%"
                  }}
                  dangerouslySetInnerHTML={{__html: apiResponse?.backing}}
                />
              </div>
            }
            {apiResponse.hanging &&
              <div
                className="flex full-width mt-2-em"
                style={{
                  flexDirection: isOnMobile ? "column" : "row"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    width: "20%"
                  }}
                >
                  Hanging
                </TypographyBody>
                <TypographyBody
                  style={{
                    maxWidth: isOnMobile ? "100%" : "80%"
                  }}
                  dangerouslySetInnerHTML={{__html: apiResponse?.hanging}}
                />
              </div>
            }
            {apiResponse.packaging &&
              <div
                className="flex full-width mt-2-em"
                style={{
                  flexDirection: isOnMobile ? "column" : "row"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    width: "20%"
                  }}
                >
                  Packaging
                </TypographyBody>
                <TypographyBody
                  style={{
                    maxWidth: isOnMobile ? "100%" : "80%"
                  }}
                  dangerouslySetInnerHTML={{__html: apiResponse?.packaging}}
                />
              </div>
            }  
            {apiResponse.wrap &&
              <div
                className="flex full-width mt-2-em"
                style={{
                  flexDirection: isOnMobile ? "column" : "row"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    width: "20%"
                  }}
                >
                  Hanging
                </TypographyBody>
                <TypographyBody
                  style={{
                    maxWidth: isOnMobile ? "100%" : "80%"
                  }}
                  dangerouslySetInnerHTML={{__html: apiResponse?.wrap}}
                />
              </div>
            }
          </div>
        </div>
      )}
    </>
  )
}
