import React, {forwardRef} from "react";
import {connect} from "react-redux";
import Cropper from "react-cropper";

import {handleCropperState} from "../../actions/cropper";
import SignatureDisplayCropper from "./SignatureDisplayCropper";
import {PikchaLoader} from "../baseComponents";
import ImageCropperPlaceholderBlock from "./ImageCropperPlaceholderBlock";

const ImageCropper = forwardRef(({
                                   palette,
                                   typography,
                                   preservedCropData,
                                   setPreservedCropData,

                                   handleCropperState,
                                   cropBox,
                                   rawImageFile,
                                   croppedSignatureTop,
                                   croppedSignatureLeft,

                                   isDragging,
                                   onFileChange,

                                   uploadedImage,
                                   setIsDragging,
                                   imageFileLoading,
                                   onImageChange,
                                   selectedRatio,
                                   logCroppedData,
                                   onChangeImage,
                                   handleBackgroundPreview,
                                   signatureData,
                                   cropper,
                                   ...rest
                                 }, {
                                   hiddenInputRef,
                                   cropperRef
                                 }) => {
  const handleReady = () => {
    handleBackgroundPreview();

    if (cropper.x) {
      cropperRef.current.cropper.setData(cropper)
    }
  }
  
  const handleCropEnd = () => {
    if (cropperRef.current) {
      handleBackgroundPreview();
      handleCropperState(cropperRef.current.cropper.getData(true));
    }
  }

  return (
    <div
      style={{
        background: isDragging ? palette.grey.light : palette.grey.lightest
      }}
      className="UploadImageContainer-overlay text-center"
      onDrop={onFileChange}
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragging(true)
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsDragging(false)
      }}
      onDragEnd={(e) => {
        e.preventDefault();
        setIsDragging(false)
      }}
    >
      {rawImageFile && (
        <Cropper
          responsive
          restore
          autoCrop
          autoCropArea={0.9}
          background={false}
          ref={cropperRef}
          viewMode={1}
          style={{
            cursor: "pointer",
            maxHeight: "100%",
            height: "100%",
            width: "100%",
            borderRadius: 0,
            position: "relative",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: "1 1 0",
          }}
          src={uploadedImage}
          aspectRatio={selectedRatio.cropperVal}
          crop={logCroppedData}
          ready={handleReady}
          cropend={handleCropEnd}
          zoomable={false}
        />
      )}
      {rawImageFile && cropBox && (
        <div
          style={{
            position: "absolute",
            width: "100%",
          }}
        >
          <SignatureDisplayCropper
            signatureData={signatureData}
            style={{
              position: "absolute",
              top: croppedSignatureTop,
              left: croppedSignatureLeft + 8,
            }}
            {...rest}
          />
        </div>
      )}
      {!rawImageFile && !imageFileLoading && (
        <ImageCropperPlaceholderBlock
          ref={hiddenInputRef}
          palette={palette}
          typography={typography}
          onChangeImage={onChangeImage}
        />
      )}
      {imageFileLoading && (
        <div
          className="flex-center"
          style={{
            width: "100%",
            maxWidth: 1920,
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "3 1 0",
          }}
        >
          <div className="flex-center flex-grow-1">
            <PikchaLoader/>
          </div>
        </div>
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  cropper: state.cropperReducer
})

export default connect(mapStateToProps, {
    handleCropperState,
  },
  null,
  {
    forwardRef: true
  })(ImageCropper);
