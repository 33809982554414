import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles, withStyles } from "@material-ui/core";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Accordion, AccordionSummary } from "@material-ui/core";
import AuthorizeService from "../../../auth/AuthorizeService";
import { ICON_SIZE_DEFAULT } from "../../../helpers/constants";
import { getValueIfKeyExists } from "../../../helpers/utils";
import { setAlert } from "../../../actions/alert";
import { updateUserDetails } from "../../../actions/account";
import "react-phone-input-2/lib/style.css";
import { ExpandMore } from "@material-ui/icons";
import {
  TypographyBody, 
  TYPOGRAPHY_BODY_SIZE, 
  colors,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographyTextInput
} from "@pikcha/typography";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    padding: 0,
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);

const PersonalDetails = ({ account, setAlert, updateUserDetails }) => {
  const { palette, typography } = useTheme();

  const shouldOpen = () => {
    const location = window.location.href;
    if(location.endsWith("EmailPreferences")){
      return false;
    }
    else{
      return true;
    }
  }

  let emailConfirmed = false;
  if (account && account.user) {
    emailConfirmed = account.user.emailConfirmed;
  }

  const isUserAnArtist =
    getValueIfKeyExists(account, "user.roles") &&
    account.user.roles.includes("Artist");
  let displayEmailConfirmation = false;
  if (isUserAnArtist) {
    if (!emailConfirmed) {
      displayEmailConfirmation = true;
    }
  }

  let userType;
  let allowNameChange = true;
  if (account && account.user) {
    userType = account.user.userType;
    allowNameChange = account.user.totSold == 0? true : false;
  }
  let allowEmailChange = false;
  if (userType && userType.toLowerCase() === "email") {
    allowEmailChange = true;
  }

  const [personalDetailsOpen, setPersonalDetailsOpen] = useState(shouldOpen());
  const [isEditing, setIsEditing] = useState(false);

  const [nickName, setNickName] = useState(account.user.nickName);    
  
  const [firstName, setFirstName] = useState(account.user.fName);
  const [lastName, setLastName] = useState(account.user.lName);
  const [email, setEmail] = useState(account.user.email);
  const [phoneNumber, setPhoneNumber] = useState(account.user.phoneNumber);

  const classes = useStyles();

  const onPersonalDetailsExpansion = (event, expanded) => {
    setPersonalDetailsOpen(expanded);
  };

  const onBeginEditing = () => {
    setIsEditing(true);
  };

  const onCancelEditing = () => {
    setFirstName(account.user.fName);
    setLastName(account.user.lName);

    setNickName(account.user.nickName);

    setEmail(account.user.email);
    setPhoneNumber(account.user.phoneNumber);

    setIsEditing(false);
  };

  const onSaveEditing = () => {
    if (nickName === ""/* || firstName === "" || lastName === "" */|| email === "") {
      nickName === "" && setAlert("Please fill in your account name", "error");      
      // firstName === "" && setAlert("Please fill in your first name", "error");
      // lastName === "" && setAlert("Please fill in your last name", "error");
      email === "" && setAlert("Please fill in your email", "error");
    } else {
      updateUserDetails({
        links: {
          FacebookUrl:
            account.user.links && account.user.links.FacebookUrl
              ? account.user.links.FacebookUrl
              : "",
          InstagramUrl:
            account.user.links && account.user.links.InstagramUrl
              ? account.user.links.InstagramUrl
              : "",
          TwitterUrl:
            account.user.links && account.user.links.TwitterUrl
              ? account.user.links.TwitterUrl
              : "",
        },
        bio: account.user.bio,
        shipAddr1: account.user.shipAddr1,
        shipAddr2: account.user.shipAddr2,
        shipCountry: account.user.shipCountry,
        shipState: account.user.shipState,
        shipCity: account.user.shipCity,
        shipPostal: account.user.shipPostal,
        dob: account.user.dob,
        userHandle: account.user.userHandle,
        preferredCurrency: account.user.preferredCurrency,
        nationality: account.user.nationality,
        nickName: nickName, 
        
        fName: firstName,
        lName: lastName,
        email,
        phoneNumber,
      });
    }

    setIsEditing(false);
  };

  const onChange = ({ target }) => {
    let key = target.name;

    if (key === "nickName") {
      setNickName(target.value);
    }
    if (key === "firstName") {
      setFirstName(target.value);
    }
    if (key === "lastName") {
      setLastName(target.value);
    }
    if (key === "email") {
      setEmail(target.value);
    }
    if (key === "phoneNumber") {
      setPhoneNumber(target.value);
    }
  };
  const onPhoneNumberChange = (phone) => {
    setPhoneNumber(phone);
  };

  const onSendConfirmationEmail = () => {
    const doSendVerification = async () => {
      const token = await AuthorizeService.getAccessToken();
      axios
        .post("api/Profile/sendemailverification", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAlert("New verification email was sent", "success");
        });
    };
    doSendVerification();
  };

  const areFieldsDisabled = !isEditing;

  return (
    <Accordion
      className={classes.expansionContainer}
      onChange={onPersonalDetailsExpansion}
      expanded={personalDetailsOpen}
      style={{ marginBottom: 16 }}
    >
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            Personal Details
          </TypographyBody>
        </StyledAccordionSummary>
      <ExpansionPanelDetails className={classes.expansionDetails}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 30
          }}
        >
          <div
              style={{
                width: "100%",
              }}
            >
              <TypographyTextInput
                disabled={areFieldsDisabled || !allowNameChange}
                label="Account Name"
                type="text"
                name="nickName"
                value={nickName}
                onChange={onChange}
              />
            </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <TypographyTextInput
              disabled={areFieldsDisabled || !allowNameChange}
              label="First Name"
              type="firstName"
              name="firstName"
              value={firstName}
              onChange={onChange}
            />
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <TypographyTextInput
             disabled={areFieldsDisabled || !allowNameChange}
             label="Last Name"
             type="lastName"
             name="lastName"
             value={lastName}
             onChange={onChange}
            />
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <TypographyTextInput
              disabled={areFieldsDisabled || !allowEmailChange}
              label="Email Address"
              type="email"
              name="email"
              value={email}
              onChange={onChange}
            />
            {account.user.roles.includes("Artist") && displayEmailConfirmation && (
              <div
                style={{
                  color: palette.text.secondary,
                  fontWeight: typography.fontWeightMedium,
                  fontSize: typography.smallest,
                  display: "flex",
                  marginBottom: "3em",
                }}
              >
                <ErrorOutlineIcon
                  style={{
                    ...ICON_SIZE_DEFAULT,
                    color: palette.error.main,
                  }}
                />
                <div
                  style={{
                    marginLeft: "0.5em",
                  }}
                >
                  <p>Email confirmation required</p>
                  <p
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={onSendConfirmationEmail}
                  >
                    send confirmation link again
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              width: "100%",
              marginBottom: "1em",
            }}
          >
            <p
              style={{
                fontFamily: typography.fontFamilyMontserrat,
                fontWeight: typography.fontWeightMedium,
                fontSize: typography.smallest,
                color: palette.grey.mediumDark,
              }}
            >
              Phone Number
            </p>
            <PhoneInput
              preferredCountries = {['au','uk','us','ca','nz','de', 'fr']}  // AUS, UK, USA, CAD, NZ, GER, FRA
              preserveOrder={['preferredCountries']}
              defaultMask = {['... ... ... ... ..']}
              alwaysDefaultMask ="true"
              placeholder=""
              country="us"
              disabled={areFieldsDisabled}
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              enableSearch="true"
                dropdownStyle ={{
                  width: "275px"
                  
                }}
                searchStyle={{
                  width: "85%",
                }}
              inputStyle={{
                width: "100%",
                borderWidth: 2,
                fontSize: typography.small,
                fontWeight: typography.fontWeightSemiMedium,
                borderRadius: "6px",

                ...(areFieldsDisabled
                  ? {
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      borderColor: "rgba(0, 0, 0, 0.34)",
                      color: palette.text.disabled,
                    }
                  : {}),
              }}
              buttonStyle={{
                borderWidth: 2,
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",

                ...(areFieldsDisabled
                  ? {
                      borderColor: "rgba(0, 0, 0, 0.34)",
                    }
                  : {}),
              }}
            />
          </div>

          {!isEditing && (
            <div
              style={{
                display: "flex",
                justifyContent: 'flex-end'
              }}
            >
              <TypographyButton
                onClick={onBeginEditing}
                component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              >
                Edit
              </TypographyButton>
            </div>
          )}

          {isEditing && (
            <div
              style={{
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <TypographyButton
                onClick={onCancelEditing}
                component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              >
                Cancel
              </TypographyButton>
              <TypographyButton
                onClick={onSaveEditing}
                component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                buttonStyle={{
                  marginLeft: "1em",
                }}
              >
                Save
              </TypographyButton>
            </div>
          )}
        </div>
      </ExpansionPanelDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  setAlert,
  updateUserDetails,
})(PersonalDetails);
