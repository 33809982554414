import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import CustomiseProduct from "../../containers/customise/CustomiseProduct";
import CustomiseSummary from "../../containers/customise/CustomiseSummary";
import FeatureGallery from "../../containers/customise/FeatureGallery";
import CustomisationSkeletonMobile from "../../components/skeletonLoaders/CustomisationSkeletonMobile";
import CustomisationSkeletonTablet from "../../components/skeletonLoaders/CustomisationSkeletonTablet";
import Aunthentictyimg from "../../assets/images/Authentictyimg.jpg";
import ArtistOnboarding from "../../containers/ArtistOnboarding/ArtistOnboarding";
import { initOnboardingFlow } from "../../actions/onboarding";
import axios from "axios";
import Gallerycard from "../../assets/images/galleryCard.jpg";
import Tablet from "../../components/Responsive/Tablet";
import Mobile from "../../components/Responsive/Mobile";
import isMobile from "../../components/Responsive/isMobile";
import isTablet from "../../components/Responsive/isTablet";
import CustomiseProfile from "../../containers/customise/CustomiseProfile";
import CustomiseProfileMobile from "../../containers/customise/CustomiseProfileMobile";
import CustomiseBenefits from "../../containers/customise/CustomiseBenefits";
import CustomiseIntro from "../../containers/customise/CustomiseIntro";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TYPOGRAPHY_BUTTON_TYPE,
  TYPOGRAPHY_BUTTON_TEXT,
  TypographyTitle,
  TYPOGRAPHY_TITLE_COMPONENT,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyButton,
  colors,
} from "@pikcha/typography";
import { Link } from "react-router-dom";
import ImageTitleAndArtist from "../../containers/customise/ImageTitle&Artist.jsx";

export default function CustomiseMobile({
  fields,
  setFields,
  postInitialization,
  sideDrawer,
  startCrop,
  summary,
  location,
  imageRenderChanged,
  jondoConfigMaterials,
  addToCart,
  featureChanged,
  updateSummary,
  feature,
  galleryImages,
  onCanvasLoading,
  onCanvasFinishedLoading,
  isPageLoading,
  giveProductPrices,
  expectedProductNumber,
  reservations,
  updateGalleryCard,
  onEditionSelected,
  user,
  isUser,
  handleFollowClick,
  handleUnfollowClick,
  artist,
  profile,
  account,
  setAlert,
  isOwnProfile,
  isOnboarding,
  handleInitProfileOnBoarding,
  isAlreadySigned,
  authModalOpen,
  authModalOnClose,
  authModalOnLogin,
  authModalOnJoin,
  authModalOnContinueAsGuest,
  modalImage,
  setClickAsGuest,
  setImageLoading,
  imageLoading,
  setShotsGenerated,
  shotsGenerated,
  ...rest
}) {
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const { palette } = useTheme();
  const [showFollowingModal, setShowFollowingModal] = useState();

  const onShowFollowing = () => {
    setShowFollowingModal(true);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState({});

  useEffect(() => {
    setIsLoading(true)
    const selectedCanvasVar = fields.selectedCanvas?.label?.toLowerCase();
    const selectedMaterialVar = fields.selectedMaterial?.label?.toLowerCase();
    const selectedFrameVar = fields.selectedFrame?.isFramed?.toLowerCase();
    let data;
    if (selectedCanvasVar &&
      selectedMaterialVar &&
      selectedFrameVar) {
      data =
      {
        medium: selectedCanvasVar,
        finish: selectedMaterialVar,
        isFramed: selectedFrameVar
      }
    }
    if (data) {
      axios({
        method: 'post',
        url: '/api/Printer/GetFeatures',
        data: data,
        config: {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      })
        .then((response) => {
          setApiResponse(response.data)
          setIsLoading(false)
        })
        .catch(error => { console.log('the error has occured: ' + error) })
    }
  }, [fields]);

  useEffect(() => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.appendChild(document.createTextNode(`new carouselInlineWidget('reviewsio-carousel-widget',{
      store: 'www.pikcha.com',
      sku: '',
      lang: 'en',
      carousel_type: 'default',
      styles_carousel: 'CarouselWidget--sideHeader',

      options:{
        general:{
          review_type: 'company, product',
          min_reviews: '1',
          max_reviews: '20',
          address_format: 'CITY, COUNTRY',
          enable_auto_scroll: 10000,
        },
        header:{
          enable_overall_stars: true,
          rating_decimal_places: 2,
        },
        reviews: {
          enable_customer_name: true,
          enable_customer_location: true,
          enable_verified_badge: true,
          enable_recommends_badge: true,
          enable_photos: true,
          enable_videos: true,
          enable_review_date: true,
          disable_same_customer: true,
          min_review_percent: 4,
          third_party_source: true,
          hide_empty_reviews: true,
          enable_product_name: true,
          tags: "",
          branch: "",
          enable_branch_name: false,
        },
        popups: {
          enable_review_popups:  true,
          enable_helpful_buttons: true,
          enable_helpful_count: true,
          enable_share_buttons: true,
        },
      },
    //},
    translations: {
        'Verified Customer': 'Verified Customer'
    },
    styles:{
      '--base-font-size': '16px',
      '--base-maxwidth':'100%',

      '--reviewsio-logo-style':'var(--logo-normal)',

      '--common-star-color':' #0E1311',
      '--common-star-disabled-color':' rgba(0,0,0,0.25)',
      '--medium-star-size':' 22px',
      '--small-star-size':'19px',
      '--x-small-star-size':'16px',
      '--x-small-star-display':'inline-flex',

      '--header-order':'1',
      '--header-width':'160px',
      '--header-bg-start-color':'transparent',
      '--header-bg-end-color':'transparent',
      '--header-gradient-direction':'135deg',
      '--header-padding':'0.5em',
      '--header-border-width':'0px',
      '--header-border-color':'rgba(0,0,0,0.1)',
      '--header-border-radius':'0px',
      '--header-shadow-size':'0px',
      '--header-shadow-color':'rgba(0, 0, 0, 0.1)',

      '--header-star-color':'inherit',
      '--header-disabled-star-color':'inherit',
      '--header-heading-text-color':'inherit',
      '--header-heading-font-size':'inherit',
      '--header-heading-font-weight':'inherit',
      '--header-heading-line-height':'inherit',
      '--header-heading-text-transform':'inherit',
      '--header-subheading-text-color':'inherit',
      '--header-subheading-font-size':'inherit',
      '--header-subheading-font-weight':'inherit',
      '--header-subheading-line-height':'inherit',
      '--header-subheading-text-transform':'inherit',

      '--item-maximum-columns':'5',
      '--item-background-start-color':'transparent',
      '--item-background-end-color':'transparent',
      '--item-gradient-direction':'135deg',
      '--item-padding':'0.5em',
      '--item-border-width':'0px',
      '--item-border-color':'rgba(0,0,0,0.1)',
      '--item-border-radius':'0px',
      '--item-shadow-size':'0px',
      '--item-shadow-color':'rgba(0,0,0,0.1)',

      '--heading-text-color':' #0E1311',
      '--heading-text-font-weight':' 600',
      '--heading-text-font-family':' inherit',
      '--heading-text-line-height':' 1.4',
      '--heading-text-letter-spacing':'0',
      '--heading-text-transform':'none',

      '--body-text-color':' #0E1311',
      '--body-text-font-weight':'400',
      '--body-text-font-family':' inherit',
      '--body-text-line-height':' 1.4',
      '--body-text-letter-spacing':'0',
      '--body-text-transform':'none',

      '--scroll-button-icon-color':'#0E1311',
      '--scroll-button-icon-size':'24px',
      '--scroll-button-bg-color':'transparent',

      '--scroll-button-border-width':'0px',
      '--scroll-button-border-color':'rgba(0,0,0,0.1)',

      '--scroll-button-border-radius':'60px',
      '--scroll-button-shadow-size':'0px',
      '--scroll-button-shadow-color':'rgba(0,0,0,0.1)',
      '--scroll-button-horizontal-position':'0px',
      '--scroll-button-vertical-position':'0px',

      '--badge-icon-color':'#0E1311',
      '--badge-icon-font-size':'15px',
      '--badge-text-color':'#0E1311',
      '--badge-text-font-size':'inherit',
      '--badge-text-letter-spacing':'inherit',
      '--badge-text-transform':'inherit',

      '--author-font-size':'inherit',
      '--author-font-weight':'inherit',
      '--author-text-transform':'inherit',

      '--photo-video-thumbnail-size':'60px',
      '--photo-video-thumbnail-border-radius':'0px',

      '--popup-backdrop-color':'rgba(0,0,0,0.75)',
      '--popup-color':'#ffffff',
      '--popup-star-color':'inherit',
      '--popup-disabled-star-color':'inherit',
      '--popup-heading-text-color':'inherit',
      '--popup-body-text-color':'inherit',
      '--popup-badge-icon-color':'inherit',
      '--popup-badge-icon-font-size':'19px',
      '--popup-badge-text-color':'inherit',
      '--popup-badge-text-font-size':'14px',
      '--popup-border-width':'0px',
      '--popup-border-color':'rgba(0,0,0,0.1)',
      '--popup-border-radius':'0px',
      '--popup-shadow-size':'0px',
      '--popup-shadow-color':'rgba(0,0,0,0.1)',
      '--popup-icon-color':'#0E1311',

      '--tooltip-bg-color':'#0E1311',
      '--tooltip-text-color':'#ffffff',
    },
  });`));
    document.body.appendChild(script);
  }, [])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: isOnMobile && 542,
        }}
      >
        {isPageLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "6em",
            }}
          >
            <Tablet>
              <CustomisationSkeletonTablet />
            </Tablet>
            <Mobile>
              <CustomisationSkeletonMobile />
            </Mobile>
          </div>
        )}
        <div
          className="mb-3"
          style={{
            display: isPageLoading ? "none" : undefined,
            marginTop: "4em",
          }}
        >
          {shotsGenerated && (
            <>
              <CustomiseSummary
                maxCanvasWidth={542}
                sideDrawerOpen={sideDrawer.payload}
                startCrop={startCrop}
                summary={summary}
                image={location.state?.image}
                expectedProductNumber={expectedProductNumber}
                imageRenderChanged={imageRenderChanged}
                onCanvasLoading={onCanvasLoading}
                onCanvasFinishedLoading={onCanvasFinishedLoading}
                authModalOpen={authModalOpen}
                authModalOnClose={authModalOnClose}
                authModalOnLogin={authModalOnLogin}
                authModalOnJoin={authModalOnJoin}
                authModalOnContinueAsGuest={authModalOnContinueAsGuest}
                modalImage={modalImage}
                setClickAsGuest={setClickAsGuest}
                setImageLoading={setImageLoading}
                imageLoading={imageLoading}
                setShotsGenerated={setShotsGenerated}
              />
              <div className="mt-2-em">
                <FeatureGallery
                  photoLimit={8}
                  galleryImages={galleryImages}
                  photoSize="80"
                  style={{
                    justifyContent: "center"
                  }}
                  selectedMaterial={summary?.selectedCanvas?.id}
                />
              </div>
              <div
                style={{
                  margin: isOnMobile && "1em"
                }}
              >
                <ImageTitleAndArtist image={location.state?.image} />
              </div>
              {jondoConfigMaterials && jondoConfigMaterials.length > 0 && (
                <CustomiseProduct
                  style={{
                    ...(isOnMobile
                      ? {
                        padding: "0.25em",
                        paddingBottom: "1.5em",
                      }
                      : {}),
                  }}
                  maxCanvasWidth={542}
                  fields={fields}
                  setFields={setFields}
                  postInitialization={postInitialization}
                  onAddToCart={addToCart}
                  onFeatureChanged={featureChanged}
                  JondoConfigMaterials={jondoConfigMaterials}
                  image={location.state?.image}
                  orientation={"landscape"}
                  onGalleryCardChange={updateGalleryCard}
                  onChange={updateSummary}
                  giveProductPrices={giveProductPrices}
                  expectedProductNumber={expectedProductNumber}
                  reservations={reservations}
                  onEditionSelected={onEditionSelected}
                  isAlreadySigned={isAlreadySigned}
                  setImageLoading={setImageLoading}
                  shotsGenerated={shotsGenerated}
                />
              )}
            </>
          )}
          {!shotsGenerated && (
            <div
              style={{
                height: "50vh",
                width: "100%",
                background: colors.Neutral.grey20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "1em"
              }}
            >
              <TypographySubtitle
                component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
              >
                We have a little problem
              </TypographySubtitle>
              <TypographyBody>
                Please wait a few minutes and try again.
              </TypographyBody>
            </div>
          )}
          <div id="reviewsio-carousel-widget" />
          <CustomiseIntro
            apiResponse={apiResponse}
            isLoading={isLoading}
          />
          <div
            style={{
              marginTop: "3em",
              padding: "3em 1em",
              borderRadius: 6,
              backgroundColor: colors.Neutral.grey20,
            }}
          >
            {isOnMobile && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <TypographySubtitle
                  component={TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Bold}
                  style={{
                    marginBottom: "2em"
                  }}
                >
                  Authenticity
                </TypographySubtitle>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    marginBottom: "1em"
                  }}
                >
                  Gallery Card
                </TypographyBody>
                <TypographyBody
                  style={{
                    marginBottom: "2em"
                  }}
                >
                  All Pikcha Prints create a new, unique gallery card, featuring A QR code that links to the artwork listing on Pikcha and a unique number to track authenticity.
                  <br/>
                  <br/>
                  The cards come with double sided Velcro straps that can be hung on any surface.
                </TypographyBody>
                <div
                  style={{
                    width: isOnTablet ? "fit-content" : "100%",
                    padding: "1em",
                    backgroundColor: palette.common.white,
                    borderRadius: "6px 0 0 6px",
                    margin: "auto"
                  }}
                >
                  <img
                    src={Gallerycard}
                    width="100%"
                    style={{
                      maxWidth: 423,
                      maxHeight: 400,
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ marginTop: "1em" }}>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                      style={{
                        marginTop: "2em",
                        marginBottom: "1em"
                      }}
                    >
                      Signing
                    </TypographyBody>
                    <TypographyBody
                      style={{
                        marginBottom: "2em"
                      }}
                    >
                      Pikcha Prints are digitally signed and numbered by the artist.
                    </TypographyBody>
                    <div
                      style={{
                        width: isOnTablet ? "fit-content" : "100%",
                        padding: "1em",
                        backgroundColor: palette.common.white,
                        borderRadius: "6px 0 0 6px",
                        margin: "auto"
                      }}
                    >
                      <img
                        src={Aunthentictyimg}
                        width="100%"
                        style={{
                          maxWidth: 423,
                          maxHeight: 400,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isOnTablet && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2em 0",
                  borderRadius: "6px",
                  backgroundColor: colors.Neutral.grey20
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "0px 6px 6px 0px",
                    padding: "2em"
                  }}
                >
                  <TypographySubtitle
                    component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
                  >
                    Authenticity
                  </TypographySubtitle>
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "400px",
                      color: "rgba(0, 0, 0, 0.54)",
                      marginTop: "2em",
                      justifyContent: "space-between",
                      display: "flex"
                    }}
                  >
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                    >
                      Gallery Card
                    </TypographyBody>
                  </div>
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "400px",
                      color: "rgba(0, 0, 0, 0.54)",
                      marginTop: "2em",
                      justifyContent: "space-between",
                      display: "flex"
                    }}
                  >
                    <TypographyBody>
                      All Pikcha Prints create a new, unique gallery card, featuring A QR code that links to the artwork listing on Pikcha and a unique number to track authenticity
                      <br />The cards come with double sided Velcro straps that can be hung on any surface.
                    </TypographyBody>
                  </div>
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "400px",
                      color: "rgba(0, 0, 0, 0.54)",
                      marginTop: "2em",
                      justifyContent: "space-between",
                      display: "flex"
                    }}
                  >
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                    >
                      Signing
                    </TypographyBody>
                  </div>
                  <div
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "400px",
                      color: "rgba(0, 0, 0, 0.54)",
                      marginTop: "2em",
                      justifyContent: "space-between",
                      display: "flex"
                    }}
                  >
                    <TypographyBody>
                      Pikcha Prints are digitally signed and numbered by the artist.
                    </TypographyBody>
                  </div>
                </div>
                <div
                  style={{
                    borderRadius: "6px 0 0 6px",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <img src={Gallerycard}
                    style={{
                      maxWidth: 423,
                      maxHeight: 400,
                      border: "1em solid white"
                    }}>
                  </img>
                  <img src={Aunthentictyimg}
                    style={{
                      marginTop: "1em",
                      maxWidth: 423,
                      maxHeight: 400,
                      border: "1em solid white"
                    }}>
                  </img>
                </div>
              </div>
            )}
          </div>
          <CustomiseBenefits />
          <div
            style={{
              backgroundColor: palette.common.black,
              padding: "5em"
            }}
          >
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                marginTop: "2em"
              }}
            >
              <TypographyTitle
                component={TYPOGRAPHY_TITLE_COMPONENT.Desktop_H2_Bold}
                color="neutral/white00"
              >
                Need something made custom?
              </TypographyTitle>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
              }}>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Large_Regular}
                color="neutral/white00"
                style={{
                  marginTop: "1em",
                  marginBottom: "2em"
                }}
              >
                Get in touch with our team and we’ll see how we can make it happen.
              </TypographyBody>
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Link
                to="/contact"
              >
                <TypographyButton
                  buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
                  component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                  buttonStyle={{
                    background: colors.Neutral.white00,
                    color: colors.Neutral.ink90,
                    width: 150,
                    marginTop: "1em"
                  }}
                  textStyle={{
                    fontWeight: 600
                  }}
                >
                  Get In Touch
                </TypographyButton>
              </Link>
            </div>
          </div>
          {isOnMobile ?
            <CustomiseProfileMobile
              user={user}
              isUser={isUser}
              handleFollowClick={handleFollowClick}
              handleUnfollowClick={handleUnfollowClick}
              artist={artist}
              profile={profile}
              account={account}
              setAlert={setAlert}
              onShowFollowing={onShowFollowing}
              isOwnProfile={isOwnProfile}
              {...rest}
            /> :
            <CustomiseProfile
              user={user}
              isUser={isUser}
              handleFollowClick={handleFollowClick}
              handleUnfollowClick={handleUnfollowClick}
              artist={artist}
              profile={profile}
              account={account}
              setAlert={setAlert}
              onShowFollowing={onShowFollowing}
              isOwnProfile={isOwnProfile}
              {...rest}
            />

          }

          {isOnboarding &&
            account &&
            account.user &&
            account.user.isUserPromoted === true && (
              <ArtistOnboarding
                handleInitProfileOnBoarding={handleInitProfileOnBoarding}
                profile={profile}
                account={account}
                initOnboardingFlow={initOnboardingFlow}
              />
            )}
        </div>
      </div>
    </div>
  );
};
