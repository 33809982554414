import React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

import {
  PikchaButton,
} from "../../../components/baseComponents";

const DeleteAccountModalStep4 = ({ onCancel, onDelete, isOnMobile}) => {
  const { typography, palette } = useTheme();
  return (
          <>
          <div>
            <p
              style={{
                fontSize: isOnMobile? typography.larger : typography.xLarge,
                color: palette.text.primary,
              }}
            >
              Complete Account Deletion
            </p>
            <p
              style={{
                marginTop: "1em",
                fontSize: typography.small,
                color: palette.text.primary,
              }}
            >
              This is your final chance to cancel deletion.
            </p>
            <p
              style={{
                marginTop: "3em",
                fontSize: typography.small,
                color: palette.text.primary,
              }}
            >
              <strong>
                Are you sure that you want to delete your account
              </strong>
            </p>
            
          </div>
          <div
            style={{
              marginTop: "2em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 310,
              }}
            >
              <div style={{ width: "100%" }}>
                <PikchaButton onClick={onCancel} fullWidth action="primary">
                  No, Don't Delete
                </PikchaButton>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "1em",
                }}
              >
                <PikchaButton action="quinary" fullWidth onClick={onDelete}>
                  
                  {isOnMobile? (<>Permanently Delete</>): (<>Permanently Delete My Account</>)}
                </PikchaButton>
              </div>
            </div>
          </div>
        </>
        );
      };

export default connect(null, {})(DeleteAccountModalStep4);
