import {
  SEARCH_LOADED,
  SEARCH_LOADING,
  SEARCH_ERROR,
  SEARCH_RESET,
  SEARCH_ARTISTS_LOADED,
  SEARCH_ARTISTS_LOADING,
} from "../actions/types";

import {
  GALLERY_ITEMINPAGE_DEFAULT,
  GALLERY_PAGE_DEFAULT,
} from "../helpers/constants";

const initialState = {
  artists: [],
  count: GALLERY_ITEMINPAGE_DEFAULT,
  start: GALLERY_PAGE_DEFAULT,
  error: null,
  hasMore: true,
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_ARTISTS_LOADING:
    case SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_LOADED:
    case SEARCH_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SEARCH_ARTISTS_LOADED:
      return {
        ...state,
        artists: [...state.artists, ...payload],
        loading: false,
        start: state.start + state.count,
        hasMore: payload.length > 0,
      };

    case SEARCH_RESET:
      return initialState;

    default:
      return state;
  }
};
