import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";

export default withStyles((_) => ({
  root: {
    padding: "64px 0 0 0 !important",
    overflowY: "scroll",
    //height: 633,
  },
}))(DialogContent);
