import { withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";

export const PikchaVerticalTabs = withStyles(({ shape, palette, spacing }) => ({
  root: {
    padding: 1,
    borderRadius: shape.borderRadius,
    // paddingTop: spacing(11),
    transition: "border-color 0.15s ease-in-out",
    "& > div > div > .MuiTab-root": {
      minWidth: 100,
      width: 100,
    },
    "& > .MuiTabs-scroller": {
      width: "auto",
      flex: "none",      
    },
    "& > div > div": {
      // Padding to offset border width.
      padding: 1,
      borderRadius: shape.borderRadius,
      border: "1px solid rgba(0, 0, 0, .2)",
      transition: "border-color 0.15s ease-in-out",
      "&:hover": {
        padding: 1,
        borderRadius: shape.borderRadius,
        transition: "border-color 0.15s ease-in-out",
        border: "1px solid rgba(0, 0, 0, .2)",
      },
    },
  },
  indicator: {
    backgroundColor: "transparent",
  },
}))(Tabs);
