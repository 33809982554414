import React, {useState} from "react";
import Select from "react-select";
import {useTheme} from "@material-ui/core/styles";
import {ArrowDropDownRounded} from "@material-ui/icons";
import PikchaLoader from "../loaders/PikchaLoader";
import PikchaDropdownMenuList from "./PikchaDropdownMenuList";
import "./PikchaDropdown.css";

const PikchaDropdownBorderless = ({
                                    label,
                                    className = "",
                                    style = {},
                                    fullWidth,
                                    compactListSize,
                                    noTopMargin,
                                    noHelperClass,
                                    required,
                                    classNameOuter,
                                    disabled,
                                    placeholder = "",
                                    isMulti = false,
                                    closeMenuOnSelect = true,
                                    valueColourDisabled,
                                    FieldLabelProps = {},
                                    ...rest
                                  }) => {
  const [isFocused, setIsFocused] = useState(false);

  const {palette, shape, typography} = useTheme();
  return (
    <div className={`${fullWidth ? "full-width" : ""} ${className}`}
         style={style}
         onFocusCapture={() => setIsFocused(true)}
         onBlurCapture={() => setIsFocused(false)}>
      <Select
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        className="Dropdown-innerContainer"
        isDisabled={disabled ? disabled : false}
        isSearchable={false}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: () => <ArrowDropDownRounded />,
          MenuList: (props) => <PikchaDropdownMenuList {...props} />
        }}
        aria-label="Select"
        placeholder={placeholder}
        loadingMessage={() => <PikchaLoader linearVariant />}
        noOptionsMessage={() => ""}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '5rem',
            padding: '0',
            borderRadius: shape.borderRadius,
            borderColor: 'none',
            color: style.color ? style.color : palette.text.primary,
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.small,
            background: 'transparent',
          }),
          control: (provided) => ({
            ...provided,
            width: '4rem',
            minHeight: '23px',
            height: '23px',
            padding: 0,
            borderWidth: 0,
            borderColor: 'none',
            boxShadow: "none",
            cursor: "pointer",
            borderRadius: shape.borderRadius,
            background: 'transparent'
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: shape.borderRadius,
            cursor: "pointer",
            width: '18px',
            height: '23px',
            padding: 0,
            margin: 0,
            background: 'transparent'
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
            borderRadius: shape.borderRadius,
            color: style.color ? style.color : palette.text.primary,
            height: '23px',
            background: 'transparent'
          }),
          input: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            margin: 0,
            padding: 0,
            color: style.color ? style.color : palette.text.primary,
            background: 'transparent'
          }),
          menuList: () => ({
            cursor: "default",
            borderRadius: shape.borderRadius,
            maxHeight: !compactListSize ? "17rem" : "12rem",
            paddingBottom: '4',
            position: "relative",
            boxSizing: "border-box",
            background: 'transparent'
          }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            border: `1px solid ${palette.secondary.main}`,
            borderRadius: shape.borderRadius,
            position: "absolute",
            borderTop: 0,
            paddingBottom: 8,
            zIndex: 999
          }),
          placeholder: (provided) => ({
            ...provided,
            paddingLeft: 14,
            color: valueColourDisabled && palette.action.disabled,
            background: 'transparent'
          }),
          singleValue: (provided, {isDisabled}) => ({
            ...provided,
            userSelect: isDisabled ? "none" : undefined,
            color: !isDisabled && !valueColourDisabled
              ? (style.color ? style.color : palette.text.primary)
              : palette.action.disabled,
            paddingLeft: 0,
            background: 'transparent'
          }),
          option: (provided, {isFocused, isSelected}) => ({
            ...provided,
            cursor: "pointer",
            marginLeft: 1,
            fontSize: 12,
            textTransform: "capitalize",
            color: palette.grey.mediumDark,
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: isFocused ? palette.grey.light : null,
            ":active": {
              ...provided[":active"],
              color: isSelected ? palette.secondary.contrastText : null,
              backgroundColor: isSelected ? palette.secondary.main : null,
            },
          }),
        }}
        {...rest}
      />
    </div>
  );
};

export default PikchaDropdownBorderless;
