import {PIKCHA100_ERROR, PIKCHA100_LOADED, PIKCHA100_LOADING, PIKCHA100_RESET,} from "../actions/types";

import {GALLERY_ITEMINPAGE_DEFAULT, GALLERY_PAGE_DEFAULT,} from "../helpers/constants";

const initialState = {
  top100: [],
  count: GALLERY_ITEMINPAGE_DEFAULT,
  start: GALLERY_PAGE_DEFAULT,
  loading: true,
  error: null,
  hasMore: true,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case PIKCHA100_LOADED:
      return {
        ...state,
        top100: [...state.top100, ...payload],
        loading: false,
        start: state.start + state.count,
      };
    case PIKCHA100_ERROR:
      !(payload || {}).data === "You have reached the end." &&
      console.error(payload);
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: !(payload || {}).data === "You have reached the end.",
      };
    case PIKCHA100_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PIKCHA100_RESET:
      return initialState;
    default:
      return state;
  }
};
