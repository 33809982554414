import React from "react";
import { useTheme } from "@material-ui/core/styles";
import isMobile from "../Responsive/isMobile";
import { TypographyButton, TYPOGRAPHY_BUTTON_TYPE } from "@pikcha/typography";
import {
  PikchaTextField,
} from "../baseComponents";

export default ({
  discount,
  onDiscountChange,
  handleDiscountCode,
  pendingDiscountCode,
  shoppingCart
}) => {
  const { typography } = useTheme();
  const isOnMobile = isMobile();

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "2em",
          flexDirection: isOnMobile && 'column'
        }}
      >
        <PikchaTextField
          value={discount}
          onChange={onDiscountChange}
          label="Discounts"
          type="text"
          style={{
            color: 'black',
            fontWeight: typography.fontWeightBold
          }}
          fullWidth={isOnMobile}
          reduceBottomMargin={true}
        />
        <div
          style={{
            marginLeft: !isOnMobile && "1em",
            marginTop: isOnMobile && '1em'
          }}
        >
          <TypographyButton
          component= {TYPOGRAPHY_BUTTON_TYPE.Outline}
            onClick={(e) => {
              e.preventDefault()
              handleDiscountCode(discount);
            }}
            buttonStyle={{ 
              width: isOnMobile && '100%',
              padding: '6px 24px', 
              width: '100%',
            }}
            disabled = {pendingDiscountCode || shoppingCart.shoppingCart?.discountCode }
          >
            {shoppingCart.shoppingCart?.discountCode ? 'Discount Applied' : 'Apply'}
          </TypographyButton>
        </div>
      </div>
    </>
  );
};
