export const followingOptions = {
  following: 'Following',
  notifying: 'Notifying',
  followers: 'Followers',
};

export const noOfFollowing = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let noOfFollowing;
  if (following) {
    noOfFollowing = following.noOfFollowing;
  }

  return noOfFollowing;
};

export const noOfNotifications = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let noOfNotifications;
  if (following) {
    noOfNotifications = following.noOfNotifications;
  }

  return noOfNotifications;
};

export const noOfFollowers = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let noOfFollowers;
  if (following) {
    noOfFollowers = following.noOfFollowers;
  }

  return noOfFollowers;
};

export const followingOption = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let followingOption;
  if (following) {
    followingOption = following.option;
  }

  return followingOption;
};

export const followingSearchQuery = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let followingSearchQuery;
  if (following) {
    followingSearchQuery = following.searchQuery;
  }

  return followingSearchQuery;
};

export const followingStart = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let followingStart;
  if (following) {
    followingStart = following.start;
  }

  return followingStart;
};

export const followingCount = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let followingCount;
  if (following) {
    followingCount = following.count;
  }

  return followingCount;
};

export const followingHasMore = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let followingHasMore;
  if (following) {
    followingHasMore = following.hasMore;
  }

  return followingHasMore;
};

export const followingList = (state) => {
  let following;
  if (state) {
    following = state.followingReducer;
  }

  let followingList;
  if (following) {
    followingList = following.following;
  }

  return followingList;
};
