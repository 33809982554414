import React, { useCallback,useEffect} from "react";
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { useHistory } from 'react-router-dom'
import { setAlert } from "../../../actions/alert";
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TYPOGRAPHY_BODY_SIZE,
  TYPOGRAPHY_BUTTON_TEXT,
  colors,
} from "@pikcha/typography";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  outlineBtn: {
    margin: '2rem 0px',
    marginLeft: '8px',
    fontWeight: '600'
  },
  outlineBtnMbl: {
    margin: "4px 23px 4px 23px",
    fontWeight: '600'
  },
  btnFlexEnd: {
    marginLeft: 'auto',
    alignSelf: 'flex-end',
    order: '2',
    width: '30%',
  },
  smallHeadingMargin: {
    marginLeft: '1em'
  }
}))
const MyOrdersDesktop = ({ order, setAlert }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const totalItems = order.imageProductDTOs.length
  console.log(totalItems);

  const { typography } = useTheme();
  const history = useHistory();
  const apiResponse = order.imageProductDTOs
  const trackOrderBtn = () => {
    return apiResponse.find((array) => (array.shipStatus.toLowerCase() === 'shipped'))
  }
  const deliveredBtn = () => {
    return apiResponse.find((array) => (array.shipStatus.toLowerCase() === 'delivered'))
  }

  const OrderHeading = useCallback(() => {
    let shippedCount = 0;
    let shippingStatusHeading = apiResponse.map((array,index) => {
      return array.shipStatus.toLowerCase()
    })
    console.log(shippingStatusHeading);
    if (shippingStatusHeading.indexOf('delivered') > -1) {
      return (
        <>
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
          >
            Order delivered
          </TypographySubtitle>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Large_Regular}
          >
            Order has been completed. Enjoy!
          </TypographyBody>
        </>
      )
    } else if (shippingStatusHeading.indexOf('shipped') > -1) {
      apiResponse.filter((array) => {
        if (array.shipStatus.toLowerCase() === 'shipped') {
          shippedCount += 1
        }
      })
      return (
        <>
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
          >
            It’s on it’s way!
          </TypographySubtitle>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Large_Regular}
          >
            {shippedCount} of {totalItems} artworks have been shipped
          </TypographyBody>
        </>
      )
    } else if (shippingStatusHeading.indexOf('your invoice will be available once this item is shipped') > -1) {
      return (
        <>
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
          >
            We're creating your artwork
          </TypographySubtitle>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Large_Regular}
          >
            We’ve received your order and are getting ready to ship it.
          </TypographyBody>
        </>

      )
    }

  }, [order.imageProductDTOs])

  const imageFetch = useCallback(() => {
    if (totalItems > 2) {
      let imageArrays = order.imageProductDTOs.map((imageRender,index) => {
        return imageRender.imageRender
      })
      const imageCount = totalItems - 1;

      return (
        <>
          <div
            style={{
              minWidth: '150px',
              height: '150px',
              backgroundColor: '#F2F4F8',
              borderRadius: '4px',
              padding: "1em",
              marginLeft: '36px',
            }}
          >
            <div
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                width: "100%",
                height: "100%",
                backgroundImage: `url(${imageArrays.slice(1, 2)})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
            />
          </div>
          <div
            style={{
              minWidth: '150px',
              height: '150px',
              borderRadius: '4px',
              padding: "1em",
              marginLeft: '8px',
              background: 'rgba(0, 0, 0, 0.4)',
              marginRight: '24px'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: "100%",
                maxWidth: "100%",
                width: "100%",
                height: "100%",
                backgroundImage: `url(${imageArrays.slice(2, 3)})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
            >
              <div
                style={{
                  color: '#fff',
                  fontWeight: '700',
                  fontSize: '36px',
                  fontFamily: 'Montserrat'
                }}
              >
                +{imageCount}
              </div>
            </div>
          </div>
        </>
      )
    }
    else {
      return (
        order.imageProductDTOs.map((imageRender, index) => (
          <div
            key={index}
            style={{
              minWidth: '150px',
              height: '150px',
              backgroundColor: '#F2F4F8',
              borderRadius: '4px',
              marginLeft: index === 0 && '30px',
              marginRight: '24px',
              padding: "1em"
            }}
          >
            <div
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                width: "100%",
                height: "100%",
                backgroundImage: `url(${imageRender.imageRender})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
            />
          </div>
        ))
      )
    }
  }, [order.imageProductDTOs])

  const shippedStatus = useCallback(() => {

    let ShippingStatus = apiResponse.map((array,index) => {
      return array.shipStatus.toLowerCase()
    })
    if (ShippingStatus.indexOf('delivered') > -1) {
      return "ORDER DELIVERED"
    }
    else if (ShippingStatus.indexOf('shipped') > -1) {
      return "ORDER SHIPPED"
    }
    else if (ShippingStatus.indexOf('your invoice will be available once this item is shipped') > -1) {
      return "ORDER RECEIVED"
    }


  }, [order.imageProductDTOs])

  const date = new Date(order.orderDate);

  const OrderNo = order.orderNumber;
  const Email = order.email;

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: 'numeric', month: 'long', year: 'numeric'
  }).replace(/ /g, '-');

  const onSubmit = async (e) => {
    e.preventDefault();
    axios.get(`/api/Search/SearchOrder?OrderNumber=${OrderNo}&Email=${Email}`)
      .then(res => {
        const data = res.data;
        if (res.data) {
          if (!res.data.mesaage && res.data.message != 'No Orders found for this Order number') {
            history.push({
              state: data,
              pathname: `/order-search/${OrderNo}`
            });
          } else if (res.data.message) {
            setAlert(`${res.data.message}`, "error");
          }
        }
      }).catch(error => {
        console.log(error);
      });
  };

  const classes = useStyles();

  return (
    <>
      <div style={{ margin: '20px 36px' }} className={classes.flexColumn}>
        <div className={classes.flexRow}>
          <div className={classes.flexColumn}>
            {OrderHeading()}
          </div>
        </div>
      </div>
      <div className={classes.flexRow}>
        {imageFetch()}
        <div className={classes.flexColumn}>
          <div style={{ marginLeft: '8px', marginBottom: '0.5em' }} className={classes.flexRow}>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              ORDER NO.
            </TypographyBody>
            <TypographyBody className={classes.smallHeadingMargin} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              {OrderNo.toUpperCase()}
            </TypographyBody>
          </div>
          <div style={{ marginLeft: '0.5em', marginBottom: '0.5em' }} className={classes.flexRow}>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              ORDER DATE
            </TypographyBody>
            <TypographyBody className={classes.smallHeadingMargin} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              {formattedDate.toUpperCase()}
            </TypographyBody>
          </div>
          <div style={{ marginLeft: '0.5em', marginBottom: '0.5em' }} className={classes.flexRow}>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              ORDER STATUS
            </TypographyBody>
            <TypographyBody className={classes.smallHeadingMargin} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              {shippedStatus()}
            </TypographyBody>
          </div>
          <div className={classes.flexRow}>
            <TypographyButton
              onClick={onSubmit}
              action="submit"
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
              size='small'
              className={classes.outlineBtn}
              textStyle={{
                fontWeight: typography.fontWeightMedium,
                fontSize: '0.75rem'
              }}
            >
              View Order
            </TypographyButton>
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              buttonStyle={{
                borderColor: !trackOrderBtn() ? colors.Neutral.grey30 : colors.Neutral.ink90
              }}
              buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
              size='small'
              className={classes.outlineBtn}
              textStyle={{
                color: !trackOrderBtn() ? colors.Neutral.grey30 : colors.Neutral.ink90,
                fontWeight: typography.fontWeightMedium,
                fontSize: '0.75rem'
              }}
              disabled={!trackOrderBtn()}
            >
              Track Order
            </TypographyButton>
            <TypographyButton
              buttonStyle={{
                marginRight: '20px',
                borderColor: !deliveredBtn() ? colors.Neutral.grey30 : colors.Neutral.ink90,
              }}
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
              size='small'
              textStyle={{
                color: !deliveredBtn() ? colors.Neutral.grey30 : colors.Neutral.ink90,
                fontWeight: typography.fontWeightMedium,
                fontSize: '0.75rem'
              }}

              className={classes.outlineBtn}
              disabled={!deliveredBtn()}
            >
              Create Return
            </TypographyButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrdersDesktop;