import React, {useState} from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import { useTheme } from "@material-ui/core/styles";

import { ICON_SIZE_SMALL, ICON_SIZE_DEFAULT } from "../../helpers/constants";
import CloseIcon from "@material-ui/icons/Close";

import AlbumRow from "../../containers/search/AlbumRow";

import {
  PikchaDropdown,
  PikchaButton,
  PikchaReusableTabsGroup,
  PikchaReusableTab
} from "../../components/baseComponents";
import FilterIcon from "../../assets/images/FilterIcon.svg";

import isDesktopHD from "../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isDesktop from "../../components/Responsive/isDesktop";
import isTablet from "../../components/Responsive/isTablet";
import AlbumGallery from "../../containers/gallery/AlbumGallery/AlbumGallery";
import isMobile from "../../components/Responsive/isMobile";

import "./SearchAlbumMobile.css";

export default ({
  toggleSafeSearch,
  searchTerm,
  isSearchLoading,
  resultsType,
  resultsTypeChange,
  sortBy,
  onSortByChange,
  onClearFilters,
  onApplyFilters,

  albums,
  getAlbums,
  resetAlbums,
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();

  const { palette, typography } = useTheme();

  const [isAddingFilter, setIsAddingFilter] = useState();

  const onCancelFiltering = () => {
    setIsAddingFilter(false);
  };
  
  let albumRows = albums.albums;
  //console.log(isSearchLoading);
  if (isSearchLoading) {
    for (let i = 0; i < 8; i++) {
      albumRows = [
        ...albumRows,
        ...[
          {
            placeholder: true,
          },
        ],
      ];
    }
  }

  const onGetAlbums = (count, start) => {
    if (albums.loading) {
      return;
    }

    //getAlbums(userProfileId);
  };
  const onResetAlbums = () => {
    resetAlbums();
  };
  const onRefreshAlbums = () => {
    resetAlbums();
    //getAlbums(userProfileId);
  };

  const addFilter = () => {
    setIsAddingFilter(true);
  };

  const applyFilters = () => {
    onApplyFilters();
    setIsAddingFilter(false);
  };

  const showBlankState = !isSearchLoading && albumRows.length < 1;

  return (
    <div
      style={{
        paddingTop: "4em",
        width: "100%",
      }}
    >
      <div
        style={{
          paddingLeft: isOnDesktopHD
            ? "4em"
            : isOnDesktopSD
              ? "2em"
              : isOnTablet
                ? "1em"
                : "1em",
          paddingRight: isOnDesktopHD
            ? "4em"
            : isOnDesktopSD
              ? "2em"
              : isOnTablet
                ? "1em"
                : "1em",
        }}
      >
        <div
          style={{
            marginTop: "2em",
          }}
        >
          <div
            style={{
              display: isOnMobile? "block":"flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                color: palette.text.primary,
                fontSize: typography.large,
                fontWeight: typography.fontWeightBold,
                textTransform: "capitalize"
              }}
            >
              {searchTerm}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
            <div
                style={{
                    display: "flex",
                    marginTop: "2em",
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
              <PikchaReusableTabsGroup
                value={resultsType}
                onChange={resultsTypeChange}
                style={{
                  width: "100%"
                }}
                aria-label="Results Type Selector"
              >
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Pikcha"
                  label="Image"
                  aria-label="Pikcha"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Artist"
                  label="Artist"
                  aria-label="Artist"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                  }}
                  value="Album"
                  label="Album"
                  aria-label="Album"
                />
              </PikchaReusableTabsGroup>
            </div>
        </div>
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "1em",
              alignItems: "center",
            }}
          >
            {!isAddingFilter && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: "1.85em",
                  justifyContent:"center"
                }}
              >
                <PikchaButton
                  mainStyle={{
                    width: 125,
                    height: 36,
                  }}
                  action="octonary"
                  onClick={addFilter}
                  className="fontSize16"
                >
                  {/* <AddIcon
                    style={{
                      marginRight: "0.5em",
                    }}
                  /> */}
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: "1em",
                      backgroundImage: `url(${FilterIcon})`,
                      backgroundSize: "cover",
                    }}
                  />
                  Filter
                </PikchaButton>
              </div>
            )}
            {isAddingFilter && (
              <div
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  display: "flex",
                  marginTop: "1.85em",
                  justifyContent:"center"
                }}
              >
                <PikchaButton
                  mainStyle={{
                    width: 125,
                    height: 36,
                  }}
                  action="octonary"
                  fullWidth
                  onClick={applyFilters}
                  className="fontSize16"
                >
                  Apply
                </PikchaButton>
              </div>
            )}
          </div>

          {isAddingFilter && (

            <div
              id="search-album-mobile-filters"
              className="search-mobile-filters"
              style={{
                backgroundColor: palette.action.disabled,
                zIndex: 10,
                padding: "0.5em",
                marginTop: "2em",
                marginBottom: "-2em",
              }}
            >
              <div
                className="search-mobile-filters-modal"
                style={{
                  borderRadius: 6,
                  backgroundColor: palette.common.white,
                  padding: "1em",
                  minHeight: "10em",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    onClick={onClearFilters}
                    style={{
                      display: "flex",
                      marginRight: "auto",
                      maxHeight: 16,
                      cursor: "pointer",
                    }}
                  >
                    <span
                      className="buttonHoverUnderline"
                      style={{
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        color: typography.secondary,
                      }}
                    >
                      Clear
                    </span>
                  <div
                    style={{
                      width: 16,
                      height: 16,
                      backgroundImage: `url(${FilterIcon})`,
                      backgroundSize: "cover",
                    }}
                  />
                </div>
                <div
                  style={{
                    // marginTop: "3em",
                    alignSelf: "flex-end",
                  }}
                >
                  {/* <PikchaBackButton onClick={onCancelFiltering} /> */}
                  <CloseIcon
                    style={{
                      ...ICON_SIZE_DEFAULT,
                      color: palette.text.primary,
                    }}
                    onClick={onCancelFiltering}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "1em"
                }}
              >
                <PikchaDropdown
                  label="Sort By"
                  onChange={onSortByChange}
                  options={[
                    { value: "RV", label: "Relevance" },
                    { value: "AVW", label: "Most Viewed" },
                    { value: "APS", label: "Most Percentage Sold" },
                    { value: "AVG", label: "Most Avarage Price" },
                    { value: "ATI", label: "Most Images in Album" },
                  ]}
                  value={sortBy}
                  style={{
                    width: "100%",
                    minWidth: 200,
                  }}
                />
              </div>
              <div
                style={{
                  // paddingLeft: "2em",
                  // paddingRight: "2em",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
              </div>
            </div>
          </div>
        )}     
        {resultsType === "Album" && (
          <div
            style={{
              marginTop: "4em",
              textAlign: "center",
            }}
          >
            <AlbumGallery
              albums={albums}
              getAlbums={getAlbums}
              resetAlbums={onResetAlbums}
              refreshAlbums={onRefreshAlbums}
              creatable={false}
              isInSearchPage={true}
              isInProfilePage={false}
              isInRelatedAlbumPage={false}
            />
          </div>
        )}
        <div
          style={{
            paddingBottom: "4em",
          }}
        />
      </div>
    </div>
  );
};
