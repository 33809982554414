import {ADD_ROUTE} from "./types";

export const addRouteToHistory = (route) => {
  return dispatch => {
    dispatch({
      type: ADD_ROUTE,
      payload: route
    })
  }  
};
