export const id = (state) => {
  let shoppingCart;
  if (state) {
    shoppingCart = state.shoppingCartReducer;
  }

  let id;
  if (shoppingCart && shoppingCart.shoppingCart) {
    id = shoppingCart.shoppingCart.id;
  }

  return id;
};

export const canTimeBeExtended = (state) => {
  let shoppingCart;
  if (state) {
    shoppingCart = state.shoppingCartReducer;
  }

  let canTimeBeExtended = false;
  if (shoppingCart.shoppingCart) {
    canTimeBeExtended = shoppingCart.shoppingCart.canTimeBeExtended;
  }

  return canTimeBeExtended;
};

export const shoppingCartItems = (state) => {
  let shoppingCart;
  if (state) {
    shoppingCart = state.shoppingCartReducer;
  }

  let shoppingCartItems = [];
  if (shoppingCart.shoppingCart && shoppingCart.shoppingCart.items) {
    shoppingCartItems = shoppingCart.shoppingCart.items;
  }

  return shoppingCartItems;
};

export const shoppingCartItemTimer = ({ state, shoppingCartItem }) => {
  let timers;
  if (state) {
    timers = state.timerReducer;
  }

  let shoppingItemId;
  if (shoppingCartItem) {
    shoppingItemId = shoppingCartItem.shoppingItemId;
  }

  let timerIndex = -1;
  if (shoppingItemId && timers && timers.timers) {
    timerIndex = timers.timers.findIndex(
      (timer) => shoppingItemId === timer.id,
    );
  }

  let shoppingCartItemTimer;
  if (timerIndex > -1) {
    shoppingCartItemTimer = timers.timers[timerIndex];
  }

  return shoppingCartItemTimer;
};

export const shoppingCartItemTimerFindByBasketItemId = ({
  state,
  basketItemId,
}) => {
  let timers;
  if (state) {
    timers = state.timerReducer;
  }

  let timerIndex = -1;
  if (basketItemId && timers && timers.timers) {
    timerIndex = timers.timers.findIndex((timer) => basketItemId === timer.id);
  }

  let shoppingCartItemTimer;
  if (timerIndex > -1) {
    shoppingCartItemTimer = timers.timers[timerIndex];
  }

  return shoppingCartItemTimer;
};

export const readyShoppingCartItems = (state) => {
  let shoppingCart;
  if (state) {
    shoppingCart = state.shoppingCartReducer;
  }

  let shoppingCartItems = [];
  if (shoppingCart.shoppingCart && shoppingCart.shoppingCart.items) {
    shoppingCartItems = shoppingCart.shoppingCart.items;
  }

  const readyShoppingCartItems = shoppingCartItems.reduce(
    (accumulator, shoppingCartItem, index) => {
      const timer = shoppingCartItemTimer({
        state,
        shoppingCartItem,
      });

      if (shoppingCartItem.isExpired) {
        return accumulator;
      }
      return [...accumulator, ...[{ shoppingCartItem }]];
    },
    [],
  );

  return readyShoppingCartItems;
};

export const queuedShoppingCartItems = (state) => {
  let shoppingCart;
  if (state) {
    shoppingCart = state.shoppingCartReducer;
  }

  let shoppingCartItems = [];
  if (shoppingCart.shoppingCart && shoppingCart.shoppingCart.items) {
    shoppingCartItems = shoppingCart.shoppingCart.items;
  }

  const queuedShoppingCartItems = shoppingCartItems.reduce(
    (accumulator, shoppingCartItem, index) => {
      if (shoppingCartItem.isExpired) {
        // TODO: Does not get hit if guess user??
        return [
          ...accumulator,
          ...[
            {
              shoppingCartItem,
            },
          ],
        ];
      }
      return accumulator;
    },
    [],
  );

  return queuedShoppingCartItems;
};

export const expiredShoppingCartItems = (state) => {
  let shoppingCart;
  if (state) {
    shoppingCart = state.shoppingCartReducer;
  }

  let shoppingCartItems = [];
  if (shoppingCart.shoppingCart && shoppingCart.shoppingCart.items) {
    shoppingCartItems = shoppingCart.shoppingCart.items;
  }

  const expiredShoppingCartItems = shoppingCartItems.reduce(
    (accumulator, shoppingCartItem, index) => {
      if (shoppingCartItem.isExpired) {
        return [...accumulator, ...[shoppingCartItem]];
      }
      return accumulator;
    },
    [],
  );

  return expiredShoppingCartItems;
};

export const areThereReadyShoppingCartItems = (state) => {
  const shoppingCartItems = readyShoppingCartItems(state);

  const areThereReadyShoppingCartItems = shoppingCartItems.length > 0;

  return areThereReadyShoppingCartItems;
};

export const areThereQueuedShoppingCartItems = (state) => {
  const shoppingCartItems = queuedShoppingCartItems(state);

  const areThereQueuedShoppingCartItems = shoppingCartItems.length > 0;

  return areThereQueuedShoppingCartItems;
};
