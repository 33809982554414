// @ts-nocheck

import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { hotjar } from "react-hotjar";
import ReactPixel from "react-facebook-pixel";
import { useTheme } from "@material-ui/core/styles";
import ReactGA from "react-ga4";
// import {Helmet} from "react-helmet";
import { Route, Router } from "react-router-dom";
import { getFollowingPhotos } from "./actions/galleryFollowing";
import { loadUser } from "./actions/account";
import { getUserIpaddress, updateUserCountry } from "./actions/location";

import Routes from "./routes/Routes";
import history from "./routes/history";

import NavBar from "./layout/NavBar/NavBar";
import Main from "./layout/Main/Main";
import SideDrawer from "./layout/SideDrawer/SideDrawer";
import PageFooter from "./layout/PageFooter/PageFooter";
import Modal from "./layout/Modal/Modal";

import Alerts from "./components/Alert/Alert";
import BetaInviteSection
  from "./components/BetaInviteSection/BetaInviteSection";

import Portal from "./containers/Portal/Portal";
import AuthModal from "./containers/modals/AuthModal/AuthModal";
import AgeRestrictionModal
  from "./containers/modals/AgeRestrictionModal/AgeRestrictionModal";

import { authenticate } from "./actions/auth";
import { closeAuthModal } from "./actions/authModal";
import { loadSettings } from "./actions/settings";
import { attach } from "./actions/shoppingCart";

import { BasketNotificationUrl } from "./selectors/settings";

import InviteCodeContext from "./contexts/InviteCodeContext";

import {
  handleUtmData,
  isProductionSite,
  loadFbLoginApi,
  loadGoogleLoginApi,
  removeModalOverlay,
} from "./helpers/utils";
import {
  PIKCHA_GUEST_CART,
} from "./helpers/constants";
import SignalR from "./helpers/singalR";
import { getSavedImages } from "./actions/gallerySaved";
import CookieConsentBanner from "./components/CookieConsentBanner";
import {
  PREFERRED_CURRENCY_CHANGE,
  PREFERRED_UNIT_CHANGE,
  SET_FX_RATE,
} from "./actions/types";
import {
  getLocalCurrencyOption,
  getLocalUnitPref,
} from "./helpers/localUserPref";
import { getFxRate, setProfilePreferences } from "./actions/changeCurrency";

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_API_KEY;
const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
const App = ({
               authenticate,
               authModal,
               BasketNotificationUrl,
               closeAuthModal,
               account,
               loadSettings,
               attach,
               getUserIpaddress,
             }) => {
  const userPreferencesReducer = useSelector((state: any) => state.userPreferencesReducer);
  const accountUserId = useSelector((state: any) => state.accountReducer.user?.id);
  const dispatch = useDispatch();

  const setLocalFxRate = (countryCode) => {
    getFxRate(countryCode).then((fxRate: { data: number }) => {
      if (fxRate.data) {
        dispatch({
          type: SET_FX_RATE,
          payload: fxRate.data,
        });
      }
    });
  };

  useEffect(() => {
    if (getLocalCurrencyOption()) {
      setLocalFxRate(getLocalCurrencyOption().value);
      dispatch({
        type: PREFERRED_CURRENCY_CHANGE,
        payload: getLocalCurrencyOption(),
      });
    }

    if (getLocalUnitPref()) {
      dispatch({
        type: PREFERRED_UNIT_CHANGE,
        payload: getLocalUnitPref(),
      });
    }
  }, []);

  useEffect(() => {
    if (accountUserId) {
      setProfilePreferences({
        currencyCode: userPreferencesReducer.currencyOptions.value,
        measurement: userPreferencesReducer.preferredUnit.value,
      });
    }
  }, [accountUserId]);

  const inviteCodeContext = useContext(InviteCodeContext);

  useEffect(() => {
    // loading user ipaddress
    getUserIpaddress();

    if (window.location.href.includes("authentication")) {
      const guestCart = localStorage.getItem(PIKCHA_GUEST_CART);
      if (guestCart) {
        if (account && account.user) {
          attach(guestCart, account.user.id);
          localStorage.removeItem(PIKCHA_GUEST_CART);
        }
      }

      return;

    }

    authenticate()
      .then(() => {
        loadSettings();
      });

    if (isProductionSite()) {
      // ReactGA.initialize("G-SHR519ZWH8");
      ReactGA.initialize("G-SHR519ZWH8");
      ReactGA.send("pageview");

      hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);

      /*** Begin Facebook Pixel code ***/

      const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
      // The relevant pixel id (i.e. development or production id) is taken from
      // env variables, so we always execute this code block irrelevant of the
      // origin server.
      const options = {
        // set pixel's autoConfig. More info:
        // https://developers.facebook.com/docs/facebook-pixel/advanced/
        autoConfig: true,
        // enable logs
        debug: true,
      };
      // const advancedMatching = { em: "some@email.com" };
      // optional, more info:
      // https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      // @ts-ignore
      ReactPixel.init(FACEBOOK_PIXEL_ID, _, options);
      ReactPixel.pageView();

      /*** End Facebook Pixel code ***/

    }

    loadFbLoginApi(FACEBOOK_APP_ID);
    loadGoogleLoginApi(GOOGLE_APP_ID);

    //saving Utm detials in local storage
    handleUtmData()
    // Demo UTM link
    // https://127.0.0.1:5001/?utm_source=facebook&utm_medium=cpc&utm_id=B%26W_October_2021&utm_content=The_Joy
    // TODO: Need to add this details in the Facebook pixel and google
    // analytics, when purchasing.

    // getting Saved UTM Details
    // const savedUTMDetails = getSavedUtmDetails();
    // console.log(savedUTMDetails);

  }, []);

  const theme = useTheme();
  const [user, setUser] = useState();

  const handleAuthModalClose = () => {
    closeAuthModal();
    removeModalOverlay();
  };

  const { open, origin } = authModal;

  if (!inviteCodeContext.betaInviteCode && window.location.origin.includes(
    "develop")) {
    return (
      <Router history={history}>
        <div
          className="mainView"
          style={{ background: theme.palette.background.default }}
        >
          <BetaInviteSection inviteCodeContext={inviteCodeContext} />
        </div>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <AuthModal
        open={open}
        origin={origin}
        handleClickClose={handleAuthModalClose}
      />
      <AgeRestrictionModal />
      <Main>
        <NavBar />
        {BasketNotificationUrl && (
          <SignalR
            url={`${BasketNotificationUrl}/mybasket`}
            id={(user || {}).id}
          />
          // <SignalR url={`/v1/basket/notifications/queue/current`}
          // id={user.id} />
        )}
        <SideDrawer />
        <Route strict path="/app" />
        <Routes />
        <CookieConsentBanner theme={theme} />
        <Modal />
      </Main>
      <PageFooter />
      <Portal>
        <div className="Alert-container">
          <Alerts />
        </div>
      </Portal>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    authModal: state.authModalReducer,
    account: state.accountReducer,
    BasketNotificationUrl: BasketNotificationUrl(state),
  };
};

export default connect(mapStateToProps, {
  authenticate,
  closeAuthModal,
  loadSettings,
  attach,

  getFollowingPhotos,

  getSavedImages,
  loadUser,
  getUserIpaddress,
  updateUserCountry,

})(App);
