import React, { useEffect, useState } from 'react';
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
} from "@pikcha/typography";
import { PikchaDropdown, TagsPicker, PikchaCheckbox } from '../../components/baseComponents';
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  fieldMargin: {
    margin: '1.5em 0'
  },
  checkbox: {
    paddingTop: '0px',
    marginTop: '-3px'
  },
  btnStyle: {
    width: "100%",
    marginTop: '1.5em'
  }
}))

const Step5 = ({ 
  imageDetails, 
  setAlert, 
  handleImageDetails, 
  allImageTypes,
}) => {
  const classes = useStyles();

  const [tags, setTags] = useState(imageDetails.tags);
  const [artworkType, setArtworkType] = useState({
    label: null,
    value: null,
  });

  useEffect(() => {
    handleImageDetails('tags', tags)
  }, [tags]);

  useEffect(() => {
    if(imageDetails?.type) {
      setArtworkType({
        label: imageDetails?.type.name,
        value: imageDetails?.type.id
      });
      return
    }
    setArtworkType("");

  }, [imageDetails?.type])

  const handleTagAdd = (tag, value) => {
    if (value.length === 10) {
      setAlert("A maximum of 10 tags are allowed", "error");
      return;
    }
    if (tag.startsWith("#")) {
      tag = tag.substring(1);
    }
    setTags([...tags, tag.trim()]);
  };

  const handleTagDelete = (_, index) => {
    tags.splice(index, 1);
    setTags(tags);
  };

  return (
    <>
      <PikchaDropdown
        className={classes.fieldHeight}
        label={              
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
            style={{
              lineHeight: undefined
            }}
          >
            Image Type
          </TypographyBody>
        }
        onChange={({ label, value }) => {
          setArtworkType({
            label,
            value
          })
          handleImageDetails("type", {
            name: label,
            id: value
          });
        }}
        options={allImageTypes}
        value={artworkType}
        placeholder="Please select"
      />
      <div className={classes.fieldMargin}>
        <TagsPicker
          value={tags}
          handleTagAdd={handleTagAdd}
          handleTagDelete={handleTagDelete}
          onMouseLeave={() => handleImageDetails('tags', tags)}
        />
      </div>
      <div className={classes.fieldMargin}>
        <PikchaCheckbox
          style={{
            marginLeft: 0,
          }}
          value={imageDetails.isNudity}
          checked={imageDetails.isNudity}
          onChange={() => {
            handleImageDetails("isNudity", !imageDetails.isNudity);
          }}
          label={
            <div
              className="flex"
              style={{
                alignItems: "center",
              }}
            >
              <TypographyBody
                className={classes.checkbox}
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                Image contains sensitive or mature content
              </TypographyBody>
            </div>
          }
        />
      </div>
    </>
  )
};

export default connect(null, {
  setAlert,
})(Step5);
