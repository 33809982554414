import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import axios from 'axios';

import { isTheUserAnArtist } from "../../../selectors/account";

import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import isMobile from "../../../components/Responsive/isMobile";
import Desktop from "../../../components/Responsive/Desktop";
import Tablet from "../../../components/Responsive/Tablet";
import Mobile from "../../../components/Responsive/Mobile";

import PersonalDetails from "./PersonalDetails";
import PersonalDetailsMobile from "./PersonalDetailsMobile";
import PaymentInformation from "./PaymentInformation";
import ShippingInformation from "./ShippingInformation";
import ResidentialAddressAndTaxInfo from './ResidentialAddressAndTaxInfo';

const MyDetails = ({ account: { fromOnboarding, user }, isTheUserAnArtist }) => {
  React.useEffect(() => {
    // if 
    // window.location.reload();
  }, []);

  const [countries, setCountries] = useState([]);
  const [allShippingStates, setAllShippingStates] = useState([]);
  const [country, setCountry] = useState(user.country);

  useEffect(() => {
    axios.get(`api/Postal/ShippingCountries`).then((response) => {
      const countries = response.data.map((item) => ({
        ...item,
        label: item.country,
        iso: item.jondO_ISO,
      }));
      setCountries(countries);
    });
  }, []);

  useEffect(() => {
    axios.get(`api/Postal/ShippingStates/`).then((resp) => {
      let result = resp.data.map(({ state, zone, iso }) => ({
        label: state,
        region: zone,
        iso,
      }));
        setAllShippingStates(result);
    });
  }, [])

  const isOnDesktopHD = isDesktopHD();
  const isOnMobile = isMobile();

  const desktopHDStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 810,
    width: "100%",
  };

  return (
    <div
      style={{
        marginLeft: isOnMobile ? "2em" : "6em",
        marginRight: isOnMobile ? "2em" : "6em",
        marginTop: "1em",
        ...(isOnDesktopHD && desktopHDStyle),
      }}
    >
      <Desktop>
        <PersonalDetails />
        {(isTheUserAnArtist || fromOnboarding) && <PaymentInformation country={country}/>}
        <ResidentialAddressAndTaxInfo
          country={country}
          setCountry={setCountry} 
        />
        <ShippingInformation countries={countries} allShippingStates={allShippingStates}/>
        <div
          style={{
            paddingBottom: "6em",
          }}
        />
      </Desktop>
      <Tablet>
        <PersonalDetails />
        {(isTheUserAnArtist || fromOnboarding) && <PaymentInformation country={country}/>}
        <ResidentialAddressAndTaxInfo 
          country={country}
          setCountry={setCountry} 
        />
        <ShippingInformation countries={countries} allShippingStates={allShippingStates}/>
        <div
          style={{
            paddingBottom: "6em",
          }}
        />
      </Tablet>
      <Mobile>
        <PersonalDetailsMobile />
        {(isTheUserAnArtist || fromOnboarding) && <PaymentInformation country={country}/>}
        <ResidentialAddressAndTaxInfo 
          country={country}
          setCountry={setCountry} 
        />
        <ShippingInformation countries={countries} allShippingStates={allShippingStates}/>
        <div
          style={{
            paddingBottom: "6em",
          }}
        />
      </Mobile>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  isTheUserAnArtist: isTheUserAnArtist(state),
});

export default connect(mapStateToProps, {})(MyDetails);
