import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Container, Row } from "react-grid-system";
import PikchaLoader from "../../../../components/baseComponents/loaders/PikchaLoader";
import isMobile from "../../../../components/Responsive/isMobile";
import isTablet from "../../../../components/Responsive/isTablet";
import Mobile from '../../../../components/Responsive/Mobile'
import Desktop from "../../../../components/Responsive/Desktop"
import Tablet from "../../../../components/Responsive/Tablet"
import NextPayment from "../NextPayment";
import SoldImage from "../SoldImage";
import SalesOverTime from "../AsyncSalesOverTime";
import SalesandPaymentsStat from "../SalesandPaymentsStat"
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
} from "@pikcha/typography";
import SalesHistoryDesktop from "./SalesHistoryDesktop";
import SalesHistoryMobile from "./SalesHistoryMobile";
import PaymentHistory from "./PaymentHistory";
import PaymentHistoryMobile from "./PaymentHistoryMobile";
import PaymentHistoryBySale from "./PaymentHistoryBySale";

export default ({
  showHistory,
  showPaymentHistory,
  isLoading,
  data,
  mySalesData,
  viewSalesHistory,
  goBackFromSalesHistory,
  viewPaymentHistory,
  goBackFromPaymentHistory,
  user,
  retrieveMoreData,
  hasMore,
  downloadHistory,
  downloadPaymentHistory,
  historyData,
  paymentHistoryData,
  viewSalesOfPayment,
  goBackFromViewingSalesOfPayment,
  viewingSalesOfPayment,
}) => {
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();

  const getPaymentSalesQuantityTotal = () => {
    let quantityTotal = 0;
    viewingSalesOfPayment.PayoutDetails.forEach((item) => {
      const { Qty } = item;
      quantityTotal += Qty;
    });
    return quantityTotal;
  };
  const getPaymentSalesRetailTotal = () => {
    let retailTotal = 0;
    viewingSalesOfPayment.PayoutDetails.forEach((item) => {
      const { FinPrice } = item;
      retailTotal += FinPrice;
    });
    return retailTotal;
  };
  const getPaymentSalesPikchaFeeTotal = () => {
    let pikchaFeeTotal = 0;
    viewingSalesOfPayment.PayoutDetails.forEach((item) => {
      const { FinPrice, SetPrice } = item;
      pikchaFeeTotal += FinPrice - SetPrice;
    });
    return pikchaFeeTotal;
  };
  const getPaymentSalesArtistMarginTotal = () => {
    let artistMarginTotal = 0;
    viewingSalesOfPayment.PayoutDetails.forEach((item) => {
      const { SetPrice } = item;
      artistMarginTotal += SetPrice;
    });
    return artistMarginTotal;
  };
  const getPaymentSalesPaymentStatusTotal = () => {
    let paymentStatus;
    viewingSalesOfPayment.PayoutDetails.forEach((item) => {
      const { PaymentStatus } = item;
      paymentStatus = PaymentStatus;
    });
    return paymentStatus;
  };

  return (
    <>
      {!showHistory && !showPaymentHistory && !viewingSalesOfPayment ? (
        <div>
          {isLoading ? (
            <div
              style={{
                marginTop: "4em",
              }}
            >
              <PikchaLoader />
            </div>
          ) : (
            <form className="account-form">
              {data.length === 0 && (
                <Container fluid>
                  <Row className="text-center">
                    <Col md={12} className="mt-4">
                      <span className="fontSize16">You have no sales yet</span>
                    </Col>
                  </Row>
                </Container>
              )}
              {data.length > 0 && mySalesData && (
                <div
                  style={{
                    ...(isOnMobile
                      ? {}
                      : {
                        display: "flex",
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                      }),
                  }}
                >
                  <div
                    style={{
                      ...(isOnMobile
                        ? {
                          marginTop: "1em",
                        }
                        : {
                          width: "100%",
                        }),
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: isOnMobile || isOnTablet ? "column" : "row",
                        justifyContent: 'space-between'
                      }}
                    >
                      <div style={{ width: isOnMobile || isOnTablet ? "100%" : "49%", }}>
                        <NextPayment
                          historyData={historyData}
                          user={user}
                          paymentHistoryData={paymentHistoryData}
                        />
                      </div>
                      <div style={{ width: isOnMobile || isOnTablet ? "100%" : "49%", }}>
                        <SalesandPaymentsStat
                          viewSalesHistory={viewSalesHistory}
                          viewPaymentHistory={viewPaymentHistory}
                        />
                      </div>
                    </div>
                    <SalesOverTime />
                  </div>
                </div>
              )}
              {data.length > 0 && (
                <div
                  style={{
                    margin: "4em 0",
                    maxWidth: 1032,
                    width: "100%",
                    borderRadius: '4px',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #DBE0E4',
                  }}
                >
                  <TypographySubtitle
                    component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
                    style={{ margin: '1em 0em 0em 1em ' }}
                  >
                    Sale by image
                  </TypographySubtitle>
                  <InfiniteScroll
                    dataLength={data.length}
                    style={{ overflowY: "Hidden" }}
                    next={() => retrieveMoreData()}
                    hasMore={hasMore}
                    loader={<PikchaLoader />}
                  >
                    {data.map((item, index) => (
                      <SoldImage key={index} item={item} />
                    ))}
                  </InfiniteScroll>
                </div>
              )}
            </form>
          )}
        </div>
      ) : showHistory && !showPaymentHistory && !viewingSalesOfPayment ? (
        <>
          <Desktop>
            <SalesHistoryDesktop
              historyData={historyData}
              downloadHistory={downloadHistory}
              goBackFromSalesHistory={goBackFromSalesHistory}
            />
          </Desktop>
          <Tablet>
            <SalesHistoryDesktop
              historyData={historyData}
              downloadHistory={downloadHistory}
              goBackFromSalesHistory={goBackFromSalesHistory}
            />
          </Tablet>
          <Mobile>
            <SalesHistoryMobile
              historyData={historyData}
              downloadHistory={downloadHistory}
              goBackFromSalesHistory={goBackFromSalesHistory}
            />
          </Mobile>
        </>
      ) : !showHistory && showPaymentHistory && !viewingSalesOfPayment ? (
        <>
          <Desktop>
            <PaymentHistory
              goBackFromPaymentHistory={goBackFromPaymentHistory}
              paymentHistoryData={paymentHistoryData}
              viewSalesOfPayment={viewSalesOfPayment}
              downloadPaymentHistory={downloadPaymentHistory}
            />
          </Desktop>
          <Tablet>
            <PaymentHistory
              goBackFromPaymentHistory={goBackFromPaymentHistory}
              paymentHistoryData={paymentHistoryData}
              viewSalesOfPayment={viewSalesOfPayment}
              downloadPaymentHistory={downloadPaymentHistory}
            />
          </Tablet>
          <Mobile>
            <PaymentHistoryMobile
              goBackFromPaymentHistory={goBackFromPaymentHistory}
              paymentHistoryData={paymentHistoryData}
              viewSalesOfPayment={viewSalesOfPayment}
              downloadPaymentHistory={downloadPaymentHistory}
            />
          </Mobile>
        </>
      ) : !showHistory && showPaymentHistory && viewingSalesOfPayment ? (
        <>
          <Desktop>
            <PaymentHistoryBySale
              viewingSalesOfPayment={viewingSalesOfPayment}
              getPaymentSalesQuantityTotal={getPaymentSalesQuantityTotal}
              getPaymentSalesRetailTotal={getPaymentSalesRetailTotal}
              getPaymentSalesPikchaFeeTotal={getPaymentSalesPikchaFeeTotal}
              getPaymentSalesArtistMarginTotal={getPaymentSalesArtistMarginTotal}
              getPaymentSalesPaymentStatusTotal={getPaymentSalesPaymentStatusTotal}
              downloadHistory={downloadHistory}
              goBackFromViewingSalesOfPayment={goBackFromViewingSalesOfPayment}
            />
          </Desktop>
          <Tablet>
            <PaymentHistoryBySale
              viewingSalesOfPayment={viewingSalesOfPayment}
              getPaymentSalesQuantityTotal={getPaymentSalesQuantityTotal}
              getPaymentSalesRetailTotal={getPaymentSalesRetailTotal}
              getPaymentSalesPikchaFeeTotal={getPaymentSalesPikchaFeeTotal}
              getPaymentSalesArtistMarginTotal={getPaymentSalesArtistMarginTotal}
              getPaymentSalesPaymentStatusTotal={getPaymentSalesPaymentStatusTotal}
              downloadHistory={downloadHistory}
              goBackFromViewingSalesOfPayment={goBackFromViewingSalesOfPayment}
            />
          </Tablet>
        </>
      ) : null
      }
    </>
  );
};
