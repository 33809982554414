import React, {useRef} from "react";
import {useTheme} from "@material-ui/core/styles";
import ScreenRotationIcon from "@material-ui/icons/ScreenRotation";
import {ICON_SIZE_EXTRA_LARGE} from "../../../helpers/constants";
import SignatureCanvas
  from "../../../components/SignatureCanvas/AsyncSignatureCanvas";
import isMobilePortrait from "../../../components/Responsive/isMobilePortrait";

export default ({
                  selectedSignatureColor,
                  handleSignatureData,
                  handleBackClick,
                  cancelSignature,
                  handleCancelSignature,
                  hasUploaded,
                  ...rest
                }) => {
  const {palette, typography} = useTheme();
  const isOnMobilePortrait = isMobilePortrait();
  let signatureCanvasRef = useRef(null);

  return (
    <div
      style={{
        padding: "20px 50px",
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div
        style={{
          margin: "auto 0"
        }}
      >
        <div
          style={{alignItems: "center"}}
          className="flex-stretch items-center flex-column flex-grow-1 pl-1-em pr-1-em"
        >
          {isOnMobilePortrait && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                color: palette.text.primary,
                fontSize: typography.standard,
                fontWeight: typography.fontWeightBold,
              }}
            >
              <ScreenRotationIcon
                style={{
                  ...ICON_SIZE_EXTRA_LARGE,
                }}
              />
              <p
                style={{
                  marginTop: "2em",
                }}
              >
                Rotate Your Screen
              </p>
            </div>
          )}
          {!isOnMobilePortrait && (
            <>
              <SignatureCanvas
                ref={signatureCanvasRef}
                selectedSignatureColor={selectedSignatureColor}
                handleSignatureData={handleSignatureData}
                cancelSignature={cancelSignature}
                handleCancelSignature={handleCancelSignature}
                onSave={() => {
                }}
                {...rest}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}