import React from "react";
import {Link} from "react-router-dom";

export const PikchaLink = ({children, onClick, ...rest}) => {

  return (
    <Link
      onClick={() => {
        window.scrollTo(0, 0);
        if (onClick) {
          onClick();
        }
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}
