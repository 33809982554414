import React, { cloneElement, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// variant props: 'large' | 'extraLarge'
export const PikchaSecondaryButton = withStyles(
  ({ shape, palette, typography, spacing }) => ({
    root: (props) => ({
      color: palette.grey.dark,
      border: `2px solid ${palette.grey.dark}`,
      fontSize:
        props.variant && props.variant === "large"
          ? typography.xLarge
          : props.variant && props.variant === "extraLarge"
          ? typography.xxxLarge
          : typography.standard,
      borderRadius: shape.borderRadius,
      padding: props.small
        ? "4px 12px"
        : props.variant && props.variant === "large"
        ? `${spacing(3)}px ${spacing(15)}px`
        : props.variant && props.variant === "extraLarge"
        ? `${spacing(5)}px ${spacing(20)}px`
        : "6px 16px",
      boxShadow: "none",
      fontWeight: props.variant
        ? typography.fontWeightBold
        : typography.fontWeightBold,
      lineHeight: props.small ? 1.34 : 1.5,
      marginRight: 4,
      textTransform: "none",
      textDecoration: "none",
      background: "transparent",
      "&:hover": {
        boxShadow: "none",
        transition: "background-color ease-in-out 0.1s",
        background: palette.action.hover,
      },
      ...((props || {}).mainStyle || {}),
    }),
  })
)(({ children, className, icon, text, to, small, variant, ...rest }) => {
  const { typography } = useTheme();

  return (
    <Fragment>
      {to ? (
        <Link to={to} className={`${className || ""} no-underline`}>
          <Button {...rest}>
            {variant ? (
              <div className="block">
                <div>{text}</div>
                <div
                  className="pt-3-4-em flex-center justify-center"
                  style={{
                    fontSize:
                      variant === "large"
                        ? typography.xLarge
                        : typography.xxxLarge,
                  }}
                >
                  {cloneElement(icon, {
                    fontSize: "inherit",
                    color: "action",
                  })}
                </div>
              </div>
            ) : (
              <Fragment>{children}</Fragment>
            )}
          </Button>
        </Link>
      ) : (
        <Button className={className || ""} {...rest}>
          {children}
        </Button>
      )}
    </Fragment>
  );
});
