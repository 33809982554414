import React from "react";
import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import { withStyles } from "@material-ui/core/styles";

const PikchaTabs = withStyles(({ palette }) => ({
  root: ({ sideDrawer: { payload } }) => ({}),
  indicator: ({ disableUnderline, hasIcon, pathname }) => ({
    display: "flex",
    flexDirection: hasIcon ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    "& > div": {
      marginLeft:
        pathname && pathname === "/app"
          ? "-17px"
          : pathname === "/app/following"
          ? 1
          : null,
      maxWidth: 68,
      width: "100%",
      height: 2,
      backgroundColor: disableUnderline ? "transparent" : palette.primary.main,
    },
  }),
}))(({ disableUnderline, sideDrawer, hasIcon, ...rest }) => (
  <Tabs TabIndicatorProps={{ children: <div /> }} {...rest} />
));

const mapStateToProps = (state) => ({
  sideDrawer: state.sideDrawerReducer,
});

export default connect(mapStateToProps)(PikchaTabs);
