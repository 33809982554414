import React from 'react';
import { colors } from "../../colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

export default ({
  currentOpen,
  index,
  children,
  headingStyle = {},
  accordionTitle,
  onheadingClick,
  disabled
}) => {
  return (
    <>
      <div
        style={{
          height: 40,
          backgroundColor: colors.Neutral.grey20,
          padding: "1em",
          display: 'flex',
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 4,
          marginBottom: "1em",
          cursor: disabled ? "auto" : "pointer",
          opacity: disabled ? 0.3 : 1,
          userSelect: "none",
          ...headingStyle
        }}
        onClick={() => !disabled && onheadingClick()}
      >
        {accordionTitle}
        {currentOpen !== index && (
          <ExpandMoreIcon />
        )}
        {currentOpen === index && (
          <ExpandLessIcon />
        )}
      </div>
      {currentOpen === index && (
        children
      )}
    </>
  )
}
