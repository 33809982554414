import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  makeStyles
} from "@material-ui/core/styles";
import clsx from "clsx";
import { colors } from "../../colors";

const useStyles = (size) =>
  makeStyles(({ palette }) => ({
    root: {
      padding: 0,
      marginRight: ".25em",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: 4,
      border: "1px solid #E0E0E0",
      //boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
      width: size,
      height: size,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: "white",
      //backgroundColor: palette.primary.light,
      //backgroundImage:
      //  "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        // backgroundColor: palette.grey.light,
        backgroundColor: "white",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: colors.Neutral.grey30,
      },
    },
    checkedIcon: {
      backgroundColor: colors.Neutral.ink90,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: Math.abs(parseInt(size) * 0.88),
        height: Math.abs(parseInt(size) * 0.88),
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: "\"\"",
      },
      "input:hover ~ &": {
        backgroundColor: colors.Neutral.ink90
      },
    },
    colorPrimary: {
      color: colors.Neutral.ink90,
      fontSize:'16px !important',
    }
  }))(Checkbox);

export default ({
  gilad,
  handleChange,
  labelText,
  style,
  size = 18,
  disabled,
}) => {
  const classes = useStyles(size);

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={classes.root}
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          checked={gilad}
          onChange={handleChange}
          name={labelText}
          disabled={disabled}
        />
      }
      label={<span className={classes.colorPrimary}>{labelText}</span>}
      style={{
        ...style
      }}
    />
  )
}
