import React from "react";
import {
  TAX_EXEMPTION_VALUE_1,
  TAX_EXEMPTION_VALUE_2,
  TAX_EXEMPTION_VALUE_3,
  TAX_EXEMPTION_VALUE_4,
} from "../../helpers/constants";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE, TypographySelector, colors } from "@pikcha/typography";

const TaxOptions = ({ fields, handleAusTaxCategory, country, disabled }) => {

  const { businessOption } = fields;

  if (country !== "Australia") {
    return null;
  }

  return (
    <>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
        style={{marginBottom:'1.5em'}}
      >
        Taxes and Exemptions
      </TypographyBody>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
        style={{ marginBottom: '2em',color: disabled && colors.Neutral.grey50 }}
      >
        Income that you earn from Pikcha may be
        assessable for tax purposes.
      </TypographyBody>
      <TypographySelector
        disabled={disabled}
        aria-label="Tax Options"
        selectorType="radio"
        options={[
          {
            id: TAX_EXEMPTION_VALUE_1,
            label: "I am selling my digital assets in my capacity as an individual, and the supply is made in the course that an activity that is a private recreational pursuit or hobby.",
            value: TAX_EXEMPTION_VALUE_1
          },
          {
            id: TAX_EXEMPTION_VALUE_2,
            label: "I have no expectation of profit or gain from selling my creative work on Pikcha, and I do not meet the definition of an enterprise for tax purposes.",
            value: TAX_EXEMPTION_VALUE_2
          },
          {
            id: TAX_EXEMPTION_VALUE_3,
            label: "I am a tax exempt entity and my entire income is tax-exempt.",
            value: TAX_EXEMPTION_VALUE_3
          },
          {
            id: TAX_EXEMPTION_VALUE_4,
            label: "None.",
            value: TAX_EXEMPTION_VALUE_4
          }
        ]}
        handleChange={({ target: { value } }) => handleAusTaxCategory(value)}
        value={businessOption}
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "1em",
          flexWrap: "wrap"
        }}
        optionStyle={{
          marginRight: "1em",
        }}
      />
    </>
  );
};

export default TaxOptions;
