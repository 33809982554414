import React from "react";
import EditRoundedIcon from "@material-ui/icons/EditRounded";

import {PikchaIconButton} from "./PikchaIconButton";

export const PikchaEditImageButton = ({
                                   ...rest
                                 }) => {
  return (
    <PikchaIconButton
      size="small"
      icon={<EditRoundedIcon/>}
      {...rest}
    />
  );
}