import React from "react";
import CookieConsent from "react-cookie-consent";

import {PikchaButton} from "./baseComponents";
import {Link} from "react-router-dom";
import isTablet from "./Responsive/isTablet";
import isMobile from "./Responsive/isMobile";

const CookieConsentBanner = ({theme}) => {
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  
  return (
    <CookieConsent
      location="bottom"
      ButtonComponent={({onClick}) => (
        <PikchaButton
          fullWidth
          style={{...(!isOnMobile && {
            width: 280,
          })}}
          action="octonary"
          onClick={onClick}
        >
          Accept
        </PikchaButton>
      )}
      cookieName="pikchaCookie"
      expires={150}
      buttonWrapperClasses={`flex ${!isOnMobile
        ? "flex-end"
        : ""} flex-grow-1 justify-center flex-center align-center ${!isOnMobile
        ? ""
        : ""}`}
      contentStyle={{
        paddingBottom: !isOnMobile ? undefined : "0.5em",
        margin: !isOnMobile ? "1em 1em 1em 0" : "0.5em",
        flex: `1 0 ${!isOnMobile && !isOnTablet ? "5" : "3"}00px`,
        maxWidth: 800,
        fontWeight: theme.typography.fontWeightMedium,
      }}
      style={{
        color: theme.palette.common.white,
        borderRadius: `10px 10px 0 0`,
        fontSize: !isOnMobile ? theme.typography.smallest : theme.typography.tiny,
        alignItems: "center",
        paddingLeft: !isOnMobile ? "4em" : "0.5em",
        paddingRight: !isOnMobile ? "4em" : "0.5em",
        paddingBottom: !isOnMobile ? undefined : "0.5em",
        background: "rgba(0, 0, 0, 0.8)",
        zIndex: 1200
      }}
    >
      We use cookies to offer you a better service.
      <br/>
      Cookies enable us to support features like saving items to your basket
      and analysing site traffic.
      <br/>
      By continuing to use this website, you consent to the use of cookies,
      in accordance with our&nbsp;
      <Link
        to="/policies/privacy"
        style={{
          color: theme.palette.common.white,
          fontWeight: theme.typography.fontWeightBold
        }}
      >
        cookie&nbsp;policy
      </Link>.
    </CookieConsent>
  );
}

export default CookieConsentBanner;
