import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import isMobile from "../../../components/Responsive/isMobile";
import { useTheme } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";

import AuthorizeService from "../../../auth/AuthorizeService";
import { ApplicationPaths } from "../../../auth/ApiAuthorizationConstants";
import { IdentityUrl } from "../../../selectors/settings";
import { getValueIfKeyExists } from "../../../helpers/utils";
import {
  PikchaModal,
  PikchaButton,
  PikchaLoader,
} from "../../../components/baseComponents";
import DeleteAccountModalStep1 from "./DeleteAccountModalStep1";
import DeleteAccountModalStep2 from "./DeleteAccountModalStep2";
import DeleteAccountModalStep3 from "./DeleteAccountModalStep3";
import DeleteAccountModalStep4 from "./DeleteAccountModalStep4";
import { ICON_SIZE_LARGE } from "../../../helpers/constants";
import { setAlert } from "../../../actions/alert";

const DeleteAccountModal = ({ open, onClose, IdentityUrl, account }) => {
  const { typography, palette } = useTheme();

  const isOnMobile = isMobile();

  const isUserAnArtist = getValueIfKeyExists(account, "user.roles") && account.user.roles.includes("Artist");

  const history = useHistory();

  const [isDeleting, setIsDeleting] = useState();
  const [isDeleted, setIsDeleted] = useState();

  const [isInDeletionModalStep1, setIsInDeletionModalStep1] = useState(true);
  const [isInDeletionModalStep2, setIsInDeletionModalStep2] = useState(false);
  const [isInDeletionModalStep3, setIsInDeletionModalStep3] = useState(false);
  const [isInDeletionModalStep4, setIsInDeletionModalStep4] = useState(false);

  const [RemoveAll, setRemoveAll] = useState(false);
  const [RemoveProfileImage, setRemoveProfileImage] = useState(false);
  const [RemoveArtistSignature, setRemoveArtistSignature] = useState(false);
  const [RemoveSocialMediaLinks, setRemoveSocialMediaLinks] = useState(false);
  const [RemoveBiography, setRemoveBiography] = useState(false);
  const [RemoveNationality, setRemoveNationality] = useState(false);
  
  const onRemoveAll = (value) => {
    setRemoveProfileImage(value);
    setRemoveArtistSignature(value);
    setRemoveSocialMediaLinks(value);
    setRemoveBiography(value);
    setRemoveNationality(value);
  };

  const updateRemoveAll =(value) =>{
    if(RemoveAll && !value){
      setRemoveAll(false);
    }
  };

  const onCancel = () => {
    setIsInDeletionModalStep1(true);
    setIsInDeletionModalStep2(false);
    setIsInDeletionModalStep3(false);
    setIsInDeletionModalStep4(false);
    onClose();
  };

  const onStep2 = () => {
    setIsInDeletionModalStep1(false);
    setIsInDeletionModalStep2(true);
  };

  const onStep3 = () => {
    setIsInDeletionModalStep2(false);
    if(isUserAnArtist){
      setIsInDeletionModalStep3(true);
    }
    else{
      setIsInDeletionModalStep4(true);
    }
  };

  const onStep4 = () => {
    setIsInDeletionModalStep3(false);
    setIsInDeletionModalStep4(true);
  };

  const onDelete = () => {
    const doDeletion = async () => {
        AuthorizeService.getAccessToken().then((token) =>
          axios
            .post(
              "/api/Profile/SetDeleteProfilePreference",
              {
                RemoveProfileImage     : RemoveProfileImage,
                RemoveSocialMediaLinks : RemoveSocialMediaLinks,
                RemoveBiography        : RemoveBiography,
                RemoveNationality      : RemoveNationality
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              setAlert("DeleteProfile Preferences Updated", "success");
                  setIsInDeletionModalStep3(false);
                  setIsDeleting(false);
                  setIsDeleted(true);
                  setTimeout(() => {
                    history.push(ApplicationPaths.LogOut);
                  }, 2000);
            })
        );
    };

    setIsDeleting(true);
    doDeletion();
  };

  const onWantsToClose = () => {
    if (isDeleting || isDeleted) {
      return;
    }
    onCancel();
  };

  return (
    <PikchaModal
      fullWidth={false}
      onClose={onWantsToClose}
      hideCloseButton={isDeleting || isDeleted}
      maxWidth="lg"
      open={open}
      PaperProps={{
        style: {
          overflow: "hidden",
        },
      }}
      minHeight={1}
    >
      <div
        style={{
          padding: isOnMobile? "2em 1em" : "3em",
        }}
      >
        {!isDeleting && !isDeleted && isInDeletionModalStep1 &&(
          <DeleteAccountModalStep1
            onCancel = {onCancel}
            onStep2 = {onStep2}
            isOnMobile = {isOnMobile}
          />
        )}

        {isInDeletionModalStep2 && (              
          <DeleteAccountModalStep2
            onCancel = {onCancel}
            onStep3 = {onStep3}
            isUserAnArtist = {isUserAnArtist}
            isOnMobile = {isOnMobile}
          />
        )}

        {isInDeletionModalStep3 && (           
          <DeleteAccountModalStep3
            onCancel = {onCancel}
            onStep4 = {onStep4}
            isOnMobile = {isOnMobile}
            
           
            handleRemoveAll={(value)=>{setRemoveAll(value); onRemoveAll(value);}}
            handleRemoveProfileImage={(value)=>{setRemoveProfileImage(value); updateRemoveAll(value);}}
            handleRemoveSocialMediaLinks={(value)=>{setRemoveSocialMediaLinks(value); updateRemoveAll(value);}}
            handleRemoveBiography={(value)=>{setRemoveBiography(value); updateRemoveAll(value);}}
            handleRemoveNationality={(value)=>{setRemoveNationality(value); updateRemoveAll(value);}}

            RemoveAll ={RemoveAll}
            RemoveProfileImage ={RemoveProfileImage}
            RemoveSocialMediaLinks={RemoveSocialMediaLinks}
            RemoveBiography={RemoveBiography}
            RemoveNationality={RemoveNationality}


          />
        )}

        {isInDeletionModalStep4 && (           
          <DeleteAccountModalStep4
            onCancel = {onCancel}
            onDelete = {onDelete}
            isOnMobile = {isOnMobile}
          />
        )}

        {isDeleting && (
          <div
            style={{
              padding: "5em 7em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PikchaLoader />
            <p
              style={{
                marginTop: "1em",
                color: palette.text.primary,
                fontSize: typography.smallest,
              }}
            >
              Deleting Account
            </p>
          </div>
        )}
        {isDeleted && (
          <div
            style={{
              padding: "5em 7em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DoneIcon
              style={{
                ...ICON_SIZE_LARGE,
                color: palette.success.main,
              }}
            />
            <p
              style={{
                marginTop: "1em",
                color: palette.text.primary,
                fontSize: typography.smallest,
              }}
            >
              Account Deleted
            </p>
          </div>
        )}
      </div>
    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  IdentityUrl: IdentityUrl(state),
});

export default connect(mapStateToProps, {})(DeleteAccountModal);
