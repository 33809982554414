import React from "react";
import {connect} from "react-redux";
import {useTheme} from "@material-ui/core/styles";

import {handleArtistRegistrationForm} from "../../../actions/artistRegistrationForm";
import {
  PikchaButton,
  PikchaModal,
  PikchaTitle,
} from "../../../components/baseComponents";
import {getValueIfKeyExists, removeModalOverlay,} from "../../../helpers/utils";

import AuthPreviewDecor from "../../../assets/images/auth-preview-decor.png";

const BecomeArtistModal = ({
                             initOnboardingFlow,
                             handleClickClose,
                             open,
                             account,
                             handleArtistRegistrationForm,
                           }) => {
  const {palette, typography} = useTheme();

  const handleClick = () => {
    initOnboardingFlow(true);

    handleClickClose();
    handleArtistRegistrationForm(true);
    removeModalOverlay();
  };

  if (!getValueIfKeyExists(account, "user.id")) {
    return null;
  }

  return (
    <PikchaModal
      paperStyle={{
        overflow: "hidden",
        minHeight: 0,
        height: 364,
        maxHeight: 364,
      }}
      fullWidth
      onClose={handleClickClose}
      open={open}
    >
      <div
        style={{
          display: "flex",
          padding: "2em",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <PikchaTitle>Join Our Artist Community</PikchaTitle>
          <div
            style={{
              marginTop: "8em",
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightMedium,
              color: palette.text.disabled,
            }}
          >
            <p>Produce and sell exclusive, limited edition prints</p>
            <p>to customers all around the world.</p>
            <p
              style={{
                marginTop: "1em",
              }}
            >
              Sign up in under 2 minutes!
            </p>
          </div>
          <div
            style={{
              marginTop: "auto",
              marginRight: "4em",
              width: "max-content",
            }}
          >
            <PikchaButton
              onClick={handleClick}
              to={`/${account.user.userHandle}`}
              mainStyle={{
                paddingLeft: "6em",
                paddingRight: "6em",
              }}
            >
              Join Now
            </PikchaButton>
          </div>
        </div>
        <div
          style={{
            marginLeft: "2em",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 300,
              backgroundImage: `url(${AuthPreviewDecor})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          />
        </div>
      </div>
    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  handleArtistRegistrationForm,
})(BecomeArtistModal);
