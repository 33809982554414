import React from "react";

import {PikchaImageMetaText} from "../../../components/baseComponents";
import {getUploadedAt} from "../../../helpers/utils";
import {ICON_SIZE_SMALL} from "../../../helpers/constants";

import ViewIcon from "../../../assets/images/visibility-black-24dp.svg";
import SoldIcon from "../../../assets/images/filter_frames-black-24dp.svg";
import MoneyIcon from "../../../assets/images/attach_money-black-24dp.svg";
import UploadIcon from "../../../components/extraIcons/UploadIcon";

import {useTheme} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import {currencyFormat, IntlCurrencyFormatter} from "../../../components/NumberFormatters/IntlCurrencyFormatter";

export const ProductItemStats = ({
                                   image,
                                   singleColumn
                                 }) => {
  const {palette} = useTheme();
  return (
    <div
      className="ProductItem-stats"
      style={{
        paddingBottom: "1.5em"
      }}
    >

      <div
        style={{
          display: singleColumn ? "block" : "flex",
        }}
      >
        {image && image.views && image.views > 0 ? (
          <Tooltip
            title={<span style={{fontSize: "8px",}}>Total views</span>}
            interactive
            disableTouchListener
            enterDelay={500}
            leaveDelay={0}
            placement="top-start"
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <PikchaImageMetaText
                style={{
                  // width:"50%",
                  color: palette.grey.secondary
                }}
              >
                <div
                  style={{
                    ...ICON_SIZE_SMALL,
                    backgroundImage: `url(${ViewIcon})`,
                    backgroundSize: "cover",
                    marginRight: "1em",
                    opacity: "0.5",
                    marginBottom: singleColumn ? "0.5em" : 0,
                  }}
                />
                {image.views}&nbsp;Views
              </PikchaImageMetaText>
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title={<span style={{fontSize: "8px",}}>Total views</span>}
            interactive
            disableTouchListener
            enterDelay={500}
            leaveDelay={0}
            placement="top-start"
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <PikchaImageMetaText
                style={{
                  // width:"50%",
                  color: palette.grey.secondary
                }}
              >
                <div
                  style={{
                    ...ICON_SIZE_SMALL,
                    backgroundImage: `url(${ViewIcon})`,
                    backgroundSize: "cover",
                    marginRight: "1em",
                    opacity: "0.5",
                    marginBottom: singleColumn ? "0.5em" : 0,
                  }}
                />
                0&nbsp;View
              </PikchaImageMetaText>
            </div>
          </Tooltip>
        )}

        {image && image.totSold && image.totSold > 0 ? (
          <Tooltip
            title={<span style={{fontSize: "8px",}}>Total sales</span>}
            interactive
            disableTouchListener
            enterDelay={500}
            leaveDelay={0}
            placement="top-start"
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <PikchaImageMetaText
                style={{
                  // width:"50%",
                  color: palette.grey.secondary
                }}
              >
                <div
                  style={{
                    ...ICON_SIZE_SMALL,
                    backgroundImage: `url(${SoldIcon})`,
                    backgroundSize: "cover",
                    marginRight: "1em",
                    opacity: "0.5",
                    marginBottom: singleColumn ? "0.5em" : 0,
                  }}
                />
                {image.totSold}&nbsp;sold
              </PikchaImageMetaText>
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            title={<span style={{fontSize: "8px",}}>Total sales</span>}
            interactive
            disableTouchListener
            enterDelay={500}
            leaveDelay={0}
            placement="top-start"
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <PikchaImageMetaText
                style={{
                  // width:"50%",
                  color: palette.grey.secondary
                }}
              >
                <div
                  style={{
                    ...ICON_SIZE_SMALL,
                    backgroundImage: `url(${SoldIcon})`,
                    backgroundSize: "cover",
                    marginRight: "1em",
                    opacity: "0.5",
                    marginBottom: singleColumn ? "0.5em" : 0,
                  }}
                />
                0&nbsp;Sold
              </PikchaImageMetaText>
            </div>
          </Tooltip>
        )}
      </div>

      <div
        style={{
          display: singleColumn ? "block" : "flex",
        }}
      >
        <Tooltip
          title={<span style={{fontSize: "8px",}}>Image uploaded at</span>}
          interactive
          disableTouchListener
          enterDelay={500}
          leaveDelay={0}
          placement="bottom"
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <PikchaImageMetaText
              style={{
                // width:"50%",
                color: palette.grey.secondary
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  marginBottom: singleColumn ? "0.5em" : 0,
                }}
              >
                <UploadIcon
                  color={"#7d7d7d"}
                  iconSize={16}
                  style={{marginRight: "10px"}}
                />
              </div>
              {getUploadedAt(image.uploadedAtUtc)}
            </PikchaImageMetaText>
          </div>
        </Tooltip>
        <Tooltip
          title={<span style={{fontSize: "8px",}}>Avarage price (usd)</span>}
          interactive
          disableTouchListener
          enterDelay={500}
          leaveDelay={0}
          placement="bottom"
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <PikchaImageMetaText
              style={{
                // width:"50%",
                color: palette.grey.secondary
              }}
            >
              <div
                style={{
                  ...ICON_SIZE_SMALL,
                  backgroundImage: `url(${MoneyIcon})`,
                  backgroundSize: "cover",
                  marginRight: "1em",
                  opacity: "0.5",
                  marginBottom: singleColumn ? "0.5em" : 0,
                }}
              />
              <IntlCurrencyFormatter value={+(image && +image.avgPrice ? +image.avgPrice : 0)} localConversion={true}
                                     displayFormat={currencyFormat.code} />&nbsp;Avg&nbsp;
            </PikchaImageMetaText>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
