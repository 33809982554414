import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ zIndex, palette, spacing }) => ({
  root: {
    boxShadow: "0 1px 2px 0 rgba(204,204,204,.2)!important",
    background: palette.common.white,
    minHeight: 0,
    height: spacing(7),
    zIndex: zIndex.drawer + 200
  },
}));
