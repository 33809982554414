import {
  NOTIFICATION_HIDE,
  NOTIFICATION_ITEM,
  NOTIFICATION_MESSAGE,
  NOTIFICATION_SHOW,
  NOTIFICATION_TYPE,
} from "./types";

export const hideNotification = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NOTIFICATION_HIDE,
        payload: false,
      });
    } catch (err) {
      dispatch({ type: NOTIFICATION_MESSAGE, payload: "Notification Error" });
    }
  };
};
export const showNotification = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NOTIFICATION_SHOW,
        payload: true,
      });
    } catch (err) {
      dispatch({ type: NOTIFICATION_MESSAGE, payload: "Notification Error" });
    }
  };
};
export const updateNotificationMessage = (message) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NOTIFICATION_MESSAGE,
        payload: message,
      });
    } catch (err) {
      dispatch({ type: NOTIFICATION_MESSAGE, payload: "Notification Error" });
    }
  };
};
export const updateNotificationType = (type) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NOTIFICATION_TYPE,
        payload: type,
      });
    } catch (err) {
      dispatch({ type: NOTIFICATION_MESSAGE, payload: "Notification Error" });
    }
  };
};
export const updateNotificationItem = (item) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: NOTIFICATION_ITEM,
        payload: item,
      });
    } catch (err) {
      dispatch({ type: NOTIFICATION_MESSAGE, payload: "Notification Error" });
    }
  };
};
