import React, {useState} from "react";
import {connect} from "react-redux";

import {useTheme} from "@material-ui/core";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import Tooltip from '@material-ui/core/Tooltip';
import {addView} from "../../actions/gallery";
import {showProductModal} from "../../actions/modal";

import UserDetails from "../../components/UserDetails/UserDetails";

import {ICON_SIZE_DEFAULT, ICON_SIZE_SMALL} from "../../helpers/constants";
import {formatViews, getCreatedAt} from "../../helpers/utils";
import SoldIcon from "../../assets/images/filter_frames-black-24dp.svg";
import MoneyIcon from "../../assets/images/attach_money-black-24dp.svg";
import ViewIcon from "../../assets/images/visibility-black-24dp.svg";
import {PikchaImageMetaText} from "../../components/baseComponents";
import UploadIcon from "../../components/extraIcons/UploadIcon";
import {IntlCurrencyFormatter} from "../../components/NumberFormatters/IntlCurrencyFormatter";

const moment = require("moment-timezone");

const PikchaRow = ({
                     row,
                     rank,
                     safeSearch,
                     toggleSafeSearch,
                     addView,
                     showProductModal,
                   }) => {
  const {
    id,
    thumbnail,
    isNudity,
    title,
    views,
    totSold,
    avgPrice,
    artist,
    placeholder,
    uploadedAtUtc,
    dateCreated,
  } = row;

  let nationality;
  if (artist) {
    nationality = artist.nationality;
  }

  let safeSearchFilter = isNudity && safeSearch;

  const [hoverState, setHoverState] = useState(null);

  const handleClickOpen = () => {
    if (!row) {
      return;
    }
    if (!safeSearchFilter) {
      addView(id);
      showProductModal(row);
    } else {
      toggleSafeSearch();
    }
  };
  const handleMouseOver = (_id) => {
    setHoverState(_id);
  };
  const handleMouseLeave = (_id) => {
    setHoverState(undefined);
  };
  const {palette, typography} = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "1em",
        marginBottom: "1em",
      }}
    >
      <div
        style={{
          height: 30,
          width: 45,
          background: "darkgrey",
          alignSelf: "center",
          marginLeft: "2em",
          marginRight: "2em",
          borderRadius: 6,
        }}
      >
        <div
          className="pikcha-rank"
          style={{
            alignSelf: "center",
            textAlign: "center",
            // fontSize: typography.large,
          }}
        >
          {rank}
        </div>
      </div>

      {placeholder && (
        <>
          <div
            style={{
              minWidth: 150,
              width: 150,
              height: 150,
              backgroundColor: palette.grey.light,
            }}
          />
          <div
            style={{
              width: "100%",
              marginLeft: "2em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  minWidth: 24,
                  minHeight: 24,
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: palette.grey.light,
                }}
              />
              <div
                style={{
                  marginLeft: "0.5em",
                  borderRadius: 6,
                  width: "50%",
                  height: 24,
                  backgroundColor: palette.grey.light,
                }}
              />
            </div>
            <div
              style={{
                marginTop: "1em",
                borderRadius: 6,
                width: "70%",
                height: 64,
                backgroundColor: palette.grey.light,
              }}
            />
            <div
              style={{
                marginTop: "3em",
                borderRadius: 6,
                width: "60%",
                height: 24,
                backgroundColor: palette.grey.light,
              }}
            />
          </div>
        </>
      )}
      {!placeholder && (
        <>
          <a
            className="pikcha-photo"
            style={{
              cursor: "pointer",
              overflow: "hidden",
              width: "fit-content",
              minWidth: "fit-content",
            }}
            onMouseEnter={() => handleMouseOver(1)}
            onMouseLeave={() => handleMouseLeave(1)}
          >
            {safeSearchFilter && (
              <div
                style={{
                  position: "absolute",
                  height: "150px",
                  maxWidth: "150px",
                  zIndex: 5,
                  backgroundColor: "rgba(255, 255, 255, .8)",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: palette.text.primary,
                }}
              >
                <VisibilityRoundedIcon
                  style={{
                    ...ICON_SIZE_DEFAULT,
                  }}
                />
              </div>
            )}
            {!safeSearchFilter && hoverState === 1 && (
              <div
                onClick={(e) => handleClickOpen()}
                style={{
                  position: "absolute",
                  backgroundColor: palette.text.disabled,
                  height: "150px",
                  maxWidth: "150px",
                  width: "100%",
                  zIndex: 200,
                }}
              />
            )}
            <img
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                ...(safeSearchFilter
                  ? {
                    opacity: ".7",
                    filter: "blur(12px)",
                  }
                  : {}),
              }}
              src={thumbnail}
              alt=""
            />
          </a>


          <div
            className="pikcha-info"
            style={{
              width: "100%",
              marginLeft: "2em",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UserDetails
              style={{
                width: "max-content",
              }}
              id={artist.id}
              userHandle={artist.userHandle}
              avatar={artist.avatar}
              nickName={artist.nickName}
              fName={artist.fName}
              lName={artist.lName}
              smallerVariant
            />
            <div className="photo-name">
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: typography.fontFamilyMontserrat,
                  fontSize: typography.standardLarge,
                  fontWeight: typography.fontWeightMedium,
                  color: palette.text.primary,
                }}
              >
                {title.slice(0, 30)}
                {title.length > 30 ? "..." : ""}
              </p>

              {dateCreated != null && dateCreated != "" && dateCreated != "00-00-0000" && (
                <p
                  style={{
                    color: palette.text.secondary,
                    fontWeight: typography.fontWeightMedium,
                    fontSize: typography.smallest,
                  }}
                >
                  Created - {getCreatedAt(dateCreated)}
                </p>
              )}


            </div>

            <div style={{marginTop: "1.5em"}}>
              <div style={{display: "flex"}}>
                <Tooltip
                  style={{zIndex: '999999'}}
                  title={<span style={{fontSize: "8px",}}>Total sales</span>}
                  interactive
                  disableTouchListener
                  enterDelay={500}
                  leaveDelay={0}
                  placement="top-start"
                >
                  <div
                    style={{
                      flex: 1,
                      maxWidth: 140,
                    }}
                  >
                    <PikchaImageMetaText
                      style={{
                        width: 140,
                        color: palette.grey.secondary
                      }}
                    >
                      <div
                        style={{
                          ...ICON_SIZE_SMALL,
                          backgroundImage: `url(${SoldIcon})`,
                          backgroundSize: "cover",
                          marginRight: "1em",
                          opacity: "0.5"
                        }}
                      />
                      {totSold} &nbsp;Sold
                    </PikchaImageMetaText>
                  </div>
                </Tooltip>

                <Tooltip
                  style={{zIndex: '999999'}}
                  title={<span style={{fontSize: "8px",}}>Total views</span>}
                  interactive
                  disableTouchListener
                  enterDelay={500}
                  leaveDelay={0}
                  placement="top-start"
                >
                  <div
                    style={{
                      flex: 1,
                      maxWidth: 140,
                    }}
                  >
                    <PikchaImageMetaText
                      style={{
                        width: 140,
                        color: palette.grey.secondary
                      }}
                    >
                      <div
                        style={{
                          ...ICON_SIZE_SMALL,
                          backgroundImage: `url(${ViewIcon})`,
                          backgroundSize: "cover",
                          marginRight: "1em",
                          opacity: "0.5"
                        }}
                      />
                      {formatViews(views)} &nbsp;View
                      {views !== "1" && "s"}
                    </PikchaImageMetaText>
                  </div>
                </Tooltip>
              </div>
              <div style={{display: "flex"}}>
                <Tooltip
                  style={{zIndex: '999999'}}
                  title={<span style={{fontSize: "8px",}}>Avarage price (usd)</span>}
                  interactive
                  disableTouchListener
                  enterDelay={500}
                  leaveDelay={0}
                  placement="bottom-start"
                >
                  <div
                    style={{
                      flex: 1,
                      maxWidth: 140,
                    }}
                  >
                    <PikchaImageMetaText
                      style={{
                        width: 140,
                        color: palette.grey.secondary
                      }}
                    >
                      <div
                        style={{
                          ...ICON_SIZE_SMALL,
                          backgroundImage: `url(${MoneyIcon})`,
                          backgroundSize: "cover",
                          marginRight: "1em",
                          opacity: "0.5"
                        }}
                      />
                      <IntlCurrencyFormatter value={+avgPrice} localConversion={true}
                                             />&nbsp;Avg
                    </PikchaImageMetaText>
                  </div>
                </Tooltip>
                <Tooltip
                  style={{zIndex: '999999'}}
                  title={<span style={{fontSize: "8px",}}>Image uploaded at</span>}
                  interactive
                  disableTouchListener
                  enterDelay={500}
                  leaveDelay={0}
                  placement="bottom-start"
                >
                  <div
                    style={{
                      flex: 1,
                      maxWidth: 140,
                    }}
                  >
                    <PikchaImageMetaText
                      style={{
                        width: 140,
                        color: palette.grey.secondary
                      }}
                    >
                      <div
                        style={{
                          marginRight: "1em"
                        }}
                      >
                        <UploadIcon color={"#7d7d7d"} iconSize={16} />
                      </div>

                      {moment(uploadedAtUtc).format("D MMMM YYYY")}
                    </PikchaImageMetaText>
                  </div>
                </Tooltip>
              </div>

            </div>

          </div>


        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  addView,
  showProductModal,
})(PikchaRow);
