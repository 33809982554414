import axios from "axios";
import AuthorizeService from "../auth/AuthorizeService";
import {searchAPIUrl} from "../selectors/settings";

import {
  ADD_TO_ALBUM_IMAGES_ERROR,
  ADD_TO_ALBUM_IMAGES_LOADED,
  ADD_TO_ALBUM_IMAGES_LOADING,
  ADD_TO_ALBUM_IMAGES_RESET,
} from "./types";

export const getAddToAlbumPhotos = (artistId, count, start) => {
  return async (dispatch, getState) => {
    try {

      dispatch({
        type: ADD_TO_ALBUM_IMAGES_LOADING
      });
      const searchApi = searchAPIUrl(getState());
      const token = await AuthorizeService.getAccessToken();
      const parameters = {
        Id: artistId,
        Page: start,
        ItemInPage: count,
      };

      const res = await axios.post(
        `${searchApi}/api/Search/ImagesByArtist`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: ADD_TO_ALBUM_IMAGES_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ADD_TO_ALBUM_IMAGES_ERROR,
        payload: err.response,
      });
    }
  };
};

// Reset Artist Photos
export const resetAddToAlbumPhotos = () => {
  return (dispatch) =>
    dispatch({
      type: ADD_TO_ALBUM_IMAGES_RESET,
    });
};
