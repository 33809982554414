import {
  HANDLE_ADAPTIVE_STROKE, HANDLE_BRUSH_MODE,
  HANDLE_BRUSH_SMOOTHING,
  HANDLE_BRUSH_STROKE,
  HANDLE_DRAWN_SIGNATURE_SVG,
  HANDLE_REDO_SIGNATURE_PAD,
  HANDLE_SIGNATURE_FONT,
  HANDLE_SIGNATURE_TAB,
  HANDLE_SIGNATURE_UPDATE_SUCCESS,
  HANDLE_TYPED_SIGNATURE,
  HANDLE_TYPED_SIGNATURE_SVG,
} from "../actions/types";
import {DEFAULT_TYPED_SIGNATURE_FONT} from "../helpers/signature/constants";

const initialState = {
  signatureTab: 0,
  brushStroke: 12.5,
  brushSmoothing: 1,
  enhancedStroke: "true",
  brushMode: { value: "draw", label: "Draw" },
  signatureFont: DEFAULT_TYPED_SIGNATURE_FONT,
  typedSignature: "",
  drawnSignatureSVG: {
    org: "",
    inv: "",
  },
  typedSignatureSVG: {
    org: "",
    inv: "",
  },
  base64String: "",
  redoSignaturePad: false,
  updateSuccessful: false,
};

export default (state = initialState, {type, payload}) => {
  const {brushStroke} = state;

  switch (type) {
    case HANDLE_SIGNATURE_TAB:
      return {
        ...state,
        signatureTab: payload,
      };
    case HANDLE_SIGNATURE_FONT:
      return {
        ...state,
        signatureFont: payload,
      };
    case HANDLE_TYPED_SIGNATURE:
      return {
        ...state,
        typedSignature: payload,
      };
    case HANDLE_DRAWN_SIGNATURE_SVG:
      return {
        ...state,
        drawnSignatureSVG: payload,
      };
    case HANDLE_TYPED_SIGNATURE_SVG:
      return {
        ...state,
        typedSignatureSVG: payload,
      };
    case HANDLE_BRUSH_STROKE:
      return {
        ...state,
        brushStroke: payload
      };
    case HANDLE_BRUSH_SMOOTHING:
      return {
        ...state,
        brushSmoothing: payload
      };
    case HANDLE_ADAPTIVE_STROKE:
      return {
        ...state,
        enhancedStroke: payload
      };
    case HANDLE_BRUSH_MODE:
      return {
        ...state,
        brushMode: payload
      };
    case HANDLE_REDO_SIGNATURE_PAD:
      return {
        ...state,
        redoSignaturePad: payload,
      };
    case HANDLE_SIGNATURE_UPDATE_SUCCESS:
      return {
        ...state,
        updateSuccessful: payload,
      };
    default:
      return state;
  }
};
