import { HANDLE_AUTH_MODAL, CLOSE_AUTH_MODAL } from "./types";

import {
  ApplicationPaths,
  QueryParameterNames,
} from "../auth/ApiAuthorizationConstants";

export const handleAuthModal = (state, origin) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_AUTH_MODAL,
      payload: {
        state: state,
        origin: origin,
      },
    });
  };
};
export const closeAuthModal = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_AUTH_MODAL,
      payload: {},
    });
  };
};

export const openAuthentication = (history, location, extraState) => {
  return (dispatch) => {
    const state = {
      ...location.state,
      ...(extraState || {}),
    };
    const redirectBackTo = location.pathname; // window.location.href;
    const authPath = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(redirectBackTo)}`;
    history.push(authPath, state);
  };
};

export const openRegistration = (history, location) => {
  return (dispatch) => {
    const state = {
      ...location.state,
    };
    const redirectBackTo = location.pathname; // window.location.href;
    const authPath = `${ApplicationPaths.Register}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(redirectBackTo)}`;
    history.push(authPath, state);
  };
};
