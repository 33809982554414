import React, {useRef,useEffect} from "react";
import { Helmet } from "react-helmet";
import {useLocation, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {removeModal} from "../../actions/modal";
import {modalTypes, modalType, modalData} from "../../selectors/modal";
import PikchaCloseButton
  from "../../components/baseComponents/buttons/PikchaCloseButton";
import {PikchaModal} from "../../components/baseComponents";
import MasonryModal from "../../containers/modals/MasonryModal/MasonryModal";
import ViewingAlbum from "../../containers/modals/ViewingAlbum/ViewingAlbum";
import CreateCollection from "../../containers/modals/CreateCollection/CreateCollection";

const Modal = ({modalType, modalData, removeModal, history, fromProfile = false}) => {
  const {pathname} = useLocation();

  const scrollRef = useRef(null);

  useEffect(() => {
    history.listen(() => {
      // removeModal({
      //   moveForward: true,
      // });
    });
  }, []);

  const onCloseModal = React.useCallback(() => {

    // This line is essential to prevent the page being stuck on the product URL
    // when closing the modal.
    // if (!isInProfilePage) {
    //   history.replace("/")
    // }

    removeModal();
  }, [pathname]);

  if (!modalType) {
    return null;
  }

  let albumTitle = "";
  let albumBy = "";
  let titleValue = "";
  let showTitle = false;

  if(modalData.albumItem && modalData.albumItem.title && modalData.albumItem.pikchaUser && modalData.albumItem.pikchaUser.nickName){
    albumTitle =  modalData.albumItem.title ? modalData.albumItem.title : "";
    albumBy = modalData.albumItem.pikchaUser.nickName? modalData.albumItem.pikchaUser.nickName : "";
    titleValue = albumTitle + " | Album by "+ albumBy;
    showTitle = true;
  }

  return (
    <>
      {(modalType !== modalTypes.createCollection && modalType !== modalTypes.editAlbum) && (
        <>
          {modalType === modalTypes.viewAlbum && showTitle &&
            <Helmet><title>{titleValue}</title></Helmet>
          }

          <PikchaModal
            ref={scrollRef}
            hasCustomHeader
            fullWidth
            maxWidth="xl"
            onClose={onCloseModal}
            open={!!modalType}
            minHeight="initial"
            paperStyle={{
              // maxWidth: 1400,
              maxHeight: "93%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <PikchaCloseButton onClick={onCloseModal} style={{}} />
            </div>
            {modalType === modalTypes.product && <MasonryModal ref={scrollRef} image={modalData} onClose={onCloseModal} />}
            {modalType === modalTypes.viewAlbum && <ViewingAlbum {...modalData} />}
          </PikchaModal>
        </>
      )}
      {modalType === modalTypes.createCollection && <CreateCollection onCloseAlbumModal={onCloseModal}/>}
      {modalType === modalTypes.editAlbum && (
          <CreateCollection albumItem={modalData} onCloseAlbumModal={onCloseModal} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  modalType: modalType(state),
  modalData: modalData(state),
});

export default connect(mapStateToProps, {
  removeModal,
})(withRouter(Modal));

