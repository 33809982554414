import React, { useState } from "react";
import PersonalDetails from "../../components/checkoutComponents/PersonalDetails";
import AddressDetails from "../../components/checkoutComponents/AddressDetails";
import * as yup from "yup";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import isMobile from "../../components/Responsive/isMobile";
import { NavHashLink } from "../../components/baseComponents/NavHashLink";
import { PikchaCheckbox } from "../../components/baseComponents";
import { TypographyBody, TypographyButton, TYPOGRAPHY_BUTTON_TYPE } from "@pikcha/typography";
import { makeStyles } from "@material-ui/styles";

const useStyles = ({ isOnMobile }) => makeStyles(({ palette, typography }) => ({
  step2Container: {
    position: "relative",
    animation: "fadeIn 0.6s",
    background: "white",
    minHeight: 350
  },
  shippingDetailsText: {
    color: palette.text.primary,
    fontSize: typography.smallest,
    fontWeight: typography.fontWeightMedium,
    marginTop: '2em'
  },
  personalDetailsContainer: {
    marginBottom: "2em",
  },
  personalDetails: {
    width: "100%",
  },
  shippingDetails: {
    width: "100%",
    marginTop: "2em",
  },
  checkoutContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  checkout: {
    width: isOnMobile && '100%'
  },
  contactContainer: {
    position: "relative",
    animation: "fadeIn 0.6s",
    background: "white",
    lineHeight: '24px',
    marginTop: '1em'
  },
  deliveryContainer: {
    display: "flex",
    flexDirection: isOnMobile && 'column'
  },
  contact: {
    maxWidth: 400,
    width: "100%",
    marginRight: '1em'
  },
  contactHeader: {
    fontSize: typography.smallest,
    fontWeight: typography.fontWeightBold,
    marginBottom: "1em",
  },
  userDetails: {
    fontSize: typography.standard,
    lineHeight: '24px',
    marginBottom: "1em",
  },
  addressContainer: {
    maxWidth: 400,
    width: "100%",
  },
  addressheader: {
    fontSize: typography.smallest,
    fontWeight: typography.fontWeightBold,
    marginBottom: "1em",
  },
  address: {
    fontSize: typography.standard,
    lineHeight: '24px',
    marginBottom: "1em",
  },
  billingAddressContainer: {
    marginTop: "1em",
    color: '#697077',
    fontWeight: typography.fontWeightBold,
  },
  billingAddressHeader: {
    fontSize: typography.smallest,
    fontWeight: typography.fontWeightBold,
    marginBottom: "1em",
  },
  billingAddress: {
    color: palette.text.secondary,
    fontSize: typography.smallest,
    fontWeight: typography.fontWeightMedium,
    marginBottom: "1em",
  },
  checkboxText: {
    fontSize: typography.small,
    color: palette.text.disabled,
    fontWeight: typography.fontWeightSemiMedium,
    display: 'inline-block',
    marginBottom: '2em'
  }
}))

const Step2Desktop = ({
  setAlert,
  fullName,
  onFullNameChanged,
  email,
  onEmailChanged,
  phoneNumber,
  onPhoneNumberChanged,
  shipAddress1,
  onShipAddress1Changed,
  shipAddress2,
  onShipAddress2Changed,
  shipCity,
  onShipCityChanged,
  shipPostal,
  onShipPostalChanged,
  shipState,
  countryState,
  onShipStateChanged,
  country,
  billToShippingAddress,
  handleBillToShippingAddress,
  billAddress1,
  onBillAddress1Changed,
  billAddress2,
  onBillAddress2Changed,
  billCity,
  onBillCityChanged,
  billPostal,
  onBillPostalChanged,
  billState,
  onBillStateChanged,
  billCountry,
  onBillCountryChanged,
  addShippingInProgress,
  nextStep,
  isActive,
  IsStep2Filled
}) => {
  const isOnMobile = isMobile();
  const classes = useStyles({ isOnMobile })();
  const [InvalidEmail, setInvalidEmail] = useState(false);

  const validation = async () => {
    let schema = yup.string().email();
    const result = await schema.isValid(email);
    if (!result) {
      setInvalidEmail(true);
      setAlert("Please input valid email address", "error");
      return;
    }
    if (result) {
      setInvalidEmail(false);
      nextStep();
    }
  }

  return (
    <>
      {isActive && (
        <>
          <div className={classes.step2Container}>
            <p className={classes.shippingDetailsText}>
              Contact
            </p>
            <div className={classes.personalDetailsContainer} />
            <>
              <div className={classes.personalDetails}>
                <PersonalDetails
                  fullName={fullName}
                  onFullNameChanged={onFullNameChanged}
                  email={email}
                  phoneNumber={phoneNumber}
                  onEmailChanged={onEmailChanged}
                  onPhoneNumberChanged={onPhoneNumberChanged}
                  InvalidEmail={InvalidEmail}
                />
              </div>
              <div className={classes.shippingDetails}>
                <AddressDetails
                  tittle={'Delivery address'}
                  address1={shipAddress1}
                  onAddress1Changed={onShipAddress1Changed}
                  address2={shipAddress2}
                  onAddress2Changed={onShipAddress2Changed}
                  city={shipCity}
                  onCityChanged={onShipCityChanged}
                  postal={shipPostal}
                  onPostalChanged={onShipPostalChanged}
                  state={shipState}
                  onStateChanged={onShipStateChanged}
                  countryState={countryState}
                  country={country}
                  onCountryChanged={onBillCountryChanged}
                />
                <div style={{ padding: '1rem' }}>
                  <PikchaCheckbox
                    value={billToShippingAddress}
                    checked={billToShippingAddress}
                    onChange={(e) => handleBillToShippingAddress()}
                    label={
                      <span className={classes.checkboxText}>
                        Billing address is the same as delivery address.
                      </span>
                    }
                  />
                </div>
                {!billToShippingAddress &&
                  <AddressDetails
                    tittle={'Billing address'}
                    address1={billAddress1}
                    onAddress1Changed={onBillAddress1Changed}
                    address2={billAddress2}
                    onAddress2Changed={onBillAddress2Changed}
                    city={billCity}
                    onCityChanged={onBillCityChanged}
                    postal={billPostal}
                    onPostalChanged={onBillPostalChanged}
                    state={billState}
                    onStateChanged={onBillStateChanged}
                    countryState={countryState}
                    country={billCountry}
                    onCountryChanged={onBillCountryChanged}
                  />
                }
              </div>
            </>
            <div className={classes.checkoutContainer}>
              <div className={classes.checkout}>
                <NavHashLink onClick={validation} to="/checkout#payment" style={isOnMobile && { width: '100%' }}>
                  <TypographyButton
                    component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                    buttonStyle={isOnMobile && { width: '100%' }}
                  >
                    Continue
                  </TypographyButton>
                </NavHashLink>
              </div>
            </div>
          </div>
        </>
      )}
      {
        !addShippingInProgress && !isActive && IsStep2Filled && (
          <div className={classes.contactContainer}>
            <div className={classes.deliveryContainer}>
              <div className={classes.contact}>
                <p className={classes.contactHeader}>
                  Contact
                </p>
                <div className={classes.userDetails}>
                  <TypographyBody
                    style={{
                      lineHeight: '28px',
                      marginTop: "1em",
                    }}
                  >
                    {fullName}
                    <br />
                    {email}
                    <br />
                    {phoneNumber}
                  </TypographyBody>
                </div>
              </div>
              <div className={classes.addressContainer}>
                <p className={classes.addressheader}>
                  Billing Address
                </p>
                <p className={classes.address}>
                  {shipAddress1},
                  < br />
                  {shipAddress2 != "" ?
                    (
                      <>{shipAddress2}, <br /> </>
                    ) : ""
                  }
                  {shipPostal}, &nbsp; {shipCity}
                  < br />
                  {shipState}, &nbsp; {country}
                  < br />
                </p>
                {billToShippingAddress == true ?
                  <p className={classes.billingAddressContainer}>
                    Delivery address same as billing address
                  </p>
                  :
                  <>
                    <p className={classes.billingAddressHeader}>
                      Billing Address
                    </p>
                    <p className={classes.address}>
                      {billAddress1} ,
                      < br />
                      {billAddress2 != "" ?
                        (
                          <>{billAddress2}, <br /> </>
                        ) : ""
                      }
                      {billPostal}, &nbsp; {billCity}
                      < br />
                      {billState}, &nbsp; {billCountry}
                      < br />
                    </p>
                  </>
                }
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default connect(null, {
  setAlert,
})(Step2Desktop);
