import React from "react";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import {useTheme} from "@material-ui/core/styles";

import {
  accountNotifications,
  accountNotificationsHasMore,
  accountNotificationsIsLoading,
  hasAccountNotifications,
} from "../selectors/accountNotifications";

import {getAccountNotifications} from "../actions/accountNotifications";

import PikchaLoader from "../components/baseComponents/loaders/PikchaLoader";

import isDesktopHD from "../components/Responsive/isDesktopHD";
import isDesktopSD from "../components/Responsive/isDesktopSD";
import isTablet from "../components/Responsive/isTablet";
import isMobile from "../components/Responsive/isMobile";

import AccountNotification
  from "../components/dropdowns/NotificationsDropdown/AccountNotification";

const Notifications = ({
                         hasAccountNotifications,
                         accountNotifications,
                         accountNotificationsIsLoading,
                         accountNotificationsHasMore,
                         getAccountNotifications,
                       }) => {
  const {palette, typography} = useTheme();

  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();

  return (
    <div
      style={{
        width: "100%",
        marginTop: "4em",
        marginLeft: isOnDesktopHD
          ? "4em"
          : isOnDesktopSD
            ? "2em"
            : isOnTablet
              ? "1em"
              : "0.5em",
        marginRight: isOnDesktopHD
          ? "4em"
          : isOnDesktopSD
            ? "2em"
            : isOnTablet
              ? "1em"
              : "0.5em",
      }}
    >
      <div
        style={{
          marginTop: "4em",
          fontSize: typography.smallest,
          fontWeight: typography.fontWeightMedium,
          color: palette.text.secondary,
        }}
      >
        Notification History
      </div>
      <div
        style={{
          width: "100%",
          height: 1,
          backgroundColor: palette.grey.medium,
        }}
      />
      {!accountNotificationsIsLoading && !hasAccountNotifications && (
        <div
          style={{
            marginTop: "1em",
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightMedium,
            color: palette.text.disabled,
          }}
        >
          You don't have any notifications
        </div>
      )}
      <InfiniteScroll
        dataLength={accountNotifications.length}
        style={{
          overflow: "hidden",
        }}
        next={() => {
          getAccountNotifications();
        }}
        hasMore={accountNotificationsHasMore}
        loader={
          <div
            style={{
              marginTop: "2em",
            }}
          >
            <PikchaLoader />
          </div>
        }
      >
        {accountNotifications.map((notification, index) => (
          <AccountNotification notification={notification} key={index} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasAccountNotifications: hasAccountNotifications(state),
  accountNotifications: accountNotifications(state),
  accountNotificationsIsLoading: accountNotificationsIsLoading(state),
  accountNotificationsHasMore: accountNotificationsHasMore(state),
});

export default connect(mapStateToProps, {
  getAccountNotifications,
})(Notifications);
