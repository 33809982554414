import React, { useCallback, useState } from "react";

import { withOrientationChange } from "react-device-detect";
import { isMobile } from "../../../env_utils/device_screen";
import {
  PikchaTextField,
} from "../../../components/baseComponents";
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { InputAdornment } from "@material-ui/core";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";
import { USER_INPUT_REGEX } from "../../../helpers/utils";
import {
  TypographySubtitle, 
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton
} from "@pikcha/typography";

interface IStep0Props {
  token: string;
  user: object;
  userHandle: string;
  setUserHandle: (a: string) => void;
  setAlert: (a: string, b: string) => void;
  setUserAccountName: (a: string) => void;
  handleInitialStep: (a: boolean) => void;
  handleCurrentPage: (a: number) => void;
}

const Step0 = ({
                 token,
                 user,
                 userHandle,
                 setUserHandle,
                 setAlert,
                 setUserAccountName,
                 handleInitialStep,
                 handleCurrentPage,
               }: IStep0Props) => {
  const [accountName, setAccountName] = useState(
    !user
      ? ""
      // @ts-ignore
      : (user.nickName
        // @ts-ignore
        ? user.nickName
        // @ts-ignore
        : `${user.fName} ${user.lName}`));
  const [checkStatus, setCheckStatus] = useState(0); // -1: failed / 0:
                                                     // checking / 1:
                                                     // successful
  const [debouncedStatus, setDebouncedStatus] = useState(0);

  useDebouncedEffect(() => {
    setCheckStatus(0);
  }, [userHandle], 500);

  useDebouncedEffect(() => {
    setDebouncedStatus(checkStatus);
  }, [checkStatus], 0);

  // handling functions
  const handleAccountNameInput = ({ target: { value } }) => {
    if (!passesRegex(value)) {
      return;
    }

    if (passesRegex(value)) {
      setAccountName(value.toUpperCase());
    }
  };

  const handleUserHandleInput = ({ target: { value } }) => {
    if (!passesRegex(value)) {
      return;
    }

    if (passesRegex(value)) {
      setUserHandle(value.toLowerCase());
    }
  };

  const sendCheckUserHandle = useCallback(async (userHandle) => {
    await axios.post(
      "api/profile/CheckUserHandleAvailable",
      {
        UserHandle: userHandle ? "@" + userHandle : userHandle,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    )
      .then(res => {
        if (res?.data?.status === "1") {
          setUserAccountName(accountName);
          handleInitialStep(false);
          handleCurrentPage(1);
        } else {
          return setAlert(
            "This user handle has been taken",
            "error",
          );
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [token]);

  const passesRegex = useCallback((value) => !USER_INPUT_REGEX.test(
    value), [userHandle, accountName]);
  // effects
  useDebouncedEffect(() => {
    if (debouncedStatus === 0) {
      axios.post(
        "api/profile/CheckUserHandleAvailable",
        {
          UserHandle: userHandle ? "@" + userHandle : userHandle,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
        .then(resp => {
          if (resp?.data?.status === "1") {
            setCheckStatus(1);
          } else {
            setCheckStatus(-1);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [sendCheckUserHandle, debouncedStatus], 500);

  return (
    <div
      className="full-width flex flex-column flex-center space-between"
      style={{
        paddingLeft: isMobile ? "0" : "3em",
        paddingRight: isMobile ? "0" : "3em",
        paddingTop: "5em",
        paddingBottom: "1em",
        maxHeight: "100%"
      }}
    >
      <div>
        <TypographySubtitle
          component={isMobile ? TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H3_Bold : TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
          style={{
            marginBottom: "1em",
            textAlign: "center"
          }}
        >
          Welcome to Pikcha
        </TypographySubtitle>
        <TypographyBody
          style={{
            textAlign: "center",
            marginBottom: "1em"
          }}
          size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
        >
          Just a few more steps to get your account set up.
        </TypographyBody>
        <TypographyBody
          style={{
            textAlign: "center",
            maxWidth: 300,
            margin: "0 auto"
          }}
          size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
        >
          Please select a Display Name and a Username.
          These will be publicily visible.
        </TypographyBody>
      </div>
      <div className="full-width">
        <PikchaTextField
          type="text"
          label={
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{
                lineHeight: 1,
                marginBottom: "0.25em"
              }}
            >
              Display Name
            </TypographyBody>
          }
          value={accountName}
          onChange={handleAccountNameInput}
          style={{
            marginBottom: 20,
            width: "100%",
          }}
        />
        <PikchaTextField
          type="text"
          label={
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{
                lineHeight: 1,
                marginBottom: "0.25em"
              }}
            >
              Username
            </TypographyBody>
          }
          value={userHandle}
          onChange={handleUserHandleInput}
          style={{
            width: "100%"
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">@</InputAdornment>,
          }}
          endIndicator={
            React.useMemo(() => (<>
                {debouncedStatus === 1 && (
                    <CheckIcon
                      style={{
                        width: 25,
                        height: 25,
                        color: "#0F0",
                      }}
                    />
                  )
                }
                {debouncedStatus === 0 && (
                    <AutorenewIcon
                      style={{
                        width: 25,
                        height: 25,
                        color: "#00000050",
                      }}
                    />
                  )
                }
                {debouncedStatus === -1 && (
                    <ClearIcon
                      style={{
                        width: 25,
                        height: 25,
                        color: "#F00",
                      }}
                    />
                  )
                }
              </>
            ), [debouncedStatus])
          }
        />
      </div>
      <TypographyButton
        buttonStyle={{
          width: "100%",
          marginBottom: "1em"
        }}
        onClick={() => {
          if (!accountName) {
            return setAlert("Please provide a valid account name", "error");
          }
          sendCheckUserHandle(userHandle);
        }}
      >
        Next
      </TypographyButton>
    </div>
  );
};

export default withOrientationChange(Step0);
