import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { handleAccountLoadFromOnboarding } from "../actions/account";

const SuccessPayPal = ({ account /*handleAccountLoadFromOnboarding */ }) => {
  const history = useHistory();

  useEffect(() => {
    if (account && account.user && account.user.id) {
      axios.post(`api/profile/${account.user.id}/promote`).then((res) => {
        if (res.status === 200) {
          //handleAccountLoadFromOnboarding();
          history.push("/account/details");
          window.location.reload();
        }
      });
    } else {
      // TODO: handle any exceptions, such as the paypal account already
      //  being linked to a Pikcha account.
    }
  }, []);
  return (
    <div>
      <div className="margin-auto pt-6-em">Nearly there...</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  handleAccountLoadFromOnboarding,
})(SuccessPayPal);
