export class UtmExtractor {
  private static url: string;
  private url: string;

  static stripValBeforeParams() {
    return this.url = this.url.substr(this.url.indexOf("?")).replace("?", "&");
  }

  static stripNonUtmParams(object) {

    const utmObject = {
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_term: "",
      utm_content: "",
    };

    return UtmExtractor.compareObjsAndRemoveProp(
      utmObject,
      object,
    );
  }

  static compareObjsAndRemoveProp(utmObject, object) {
    for (let prop in object) {
      if (!utmObject.hasOwnProperty(prop)) {
        delete object[prop];
      }
    }
    return object;
  }

  constructor(url) {
    this.url = url;
  }

  extractParamsFromQueryString() {

    let queryObject = Object.create({});
    const arrayOfParameters = this.url.split("&");

    arrayOfParameters.forEach((e) => {
      if (e.includes("=")) {
        const pair = [...e.split("=")];

        if (pair[0].length > 0 && pair[1].length > 0) {
          queryObject[decodeURIComponent(pair[0])] =
            decodeURIComponent(pair[1] || undefined);
        }
      }
    });

    return UtmExtractor.stripNonUtmParams(queryObject);
  }

  get() {
    if (this.url.length === 0) {
      throw new Error("A valid URL is required.");
    }

    if (!this.url.includes("?")) {
      return this.extractParamsFromQueryString();
    }

    if (this.url.includes("?")) {
      this.url = this.url.replace("?", "&");
      return this.get();
    }

    if (this.url.charAt(0) === "?" || this.url.indexOf("?") > 0) {
      UtmExtractor.stripValBeforeParams();
      return this.get();
    }
  }
}
