import {
  FRIENDLY_URL_IMAGE_LOADED,
  FRIENDLY_URL_IMAGE_ERROR,
} from "../actions/types";

const initialState = {
  photos: [],
  // count: 15,
  start: 1,
  loading: true,
  error: null,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
 switch (type) {
    case FRIENDLY_URL_IMAGE_LOADED:
      return {
        ...state,
        photos: payload,
        loading: false,
        start: state.start + 1,
      };
    case FRIENDLY_URL_IMAGE_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        hasMore: false
      };
    default:
      return state;
  }
};
