﻿import React from "react";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { colors } from "../../colors";
import { getColorKey } from "../../utils";

export enum TYPOGRAPHY_BODY_SIZE {
  Small_Regular = "Small_Regular",
  Small_Semibold = "Small_Semibold",
  Small_Bold = "Small_Bold", 
  Default_Regular = "Default_Regular",
  Default_Medium = "Default_Medium",
  Default_Bold = "Default_Bold",
  Large_Regular = "Large_Regular",
  Large_Semibold = "Large_Semibold",
  Large_Bold = "Large_Bold",
}

const typographyBodyHashMap = new Map([
  [
    TYPOGRAPHY_BODY_SIZE.Small_Regular,
    { lineHeight: "24px", fontSize: 0.8, fontWeight: 400 },
  ],
  [
    TYPOGRAPHY_BODY_SIZE.Small_Semibold,
    { lineHeight: "24px", fontSize: 0.8, fontWeight: 600 },
  ],
  [
    TYPOGRAPHY_BODY_SIZE.Small_Bold,
    { lineHeight: "24px", fontSize: 0.8, fontWeight: 700 },
  ],
  [
    TYPOGRAPHY_BODY_SIZE.Default_Regular,
    { lineHeight: "28px", fontSize: 1, fontWeight: 400 },
  ],
  [
    TYPOGRAPHY_BODY_SIZE.Default_Medium,
    { lineHeight: "28px", fontSize: 1, fontWeight: 600 },
  ],
  [
    TYPOGRAPHY_BODY_SIZE.Default_Bold,
    { lineHeight: "28px", fontSize: 1, fontWeight: 700 },
  ],
  [
    TYPOGRAPHY_BODY_SIZE.Large_Regular,
    { lineHeight: "32px", fontSize: 1.12, fontWeight: 400 },
  ],
  [
    TYPOGRAPHY_BODY_SIZE.Large_Semibold,
    { lineHeight: "32px", fontSize: 1.12, fontWeight: 600 },
  ],
  [
    TYPOGRAPHY_BODY_SIZE.Large_Bold,
    { lineHeight: "32px", fontSize: 1.12, fontWeight: 700 },
  ],
]);

const TypographyBody = ({
                          size = TYPOGRAPHY_BODY_SIZE.Default_Regular,
                          color = "neutral/ink90",
                          children,
                          style = {},
                          // Any additional props will be passed to the
                          // underlying material-ui component.
                          ...rest
                        }) => {
  const { typography } = useTheme();
  let colorKey1;
  let colorKey2;
  colorKey1 = getColorKey(color).colorKey1;
  colorKey2 = getColorKey(color).colorKey2;

  return (
    <Typography
      component="p"
      style={{
        fontSize: `${typographyBodyHashMap.get(size).fontSize}rem`,
        fontWeight: typographyBodyHashMap.get(size).fontWeight,
        fontFamily: typography.fontFamily,
        lineHeight: typographyBodyHashMap.get(size).lineHeight,
        color: colors[colorKey1][colorKey2],
        ...style
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TypographyBody;
