import React from "react";
import { useTheme } from "@material-ui/core";

export default ({
  children,
  darker,
  primaryColor,
  small,
  reduceLineHeight,
  smaller,
  style,
  ...rest
}) => {
  const { palette, typography } = useTheme();

  return (
    <h2
      style={{
        lineHeight: reduceLineHeight ? 1.3 : typography.h2.lineHeight,
        letterSpacing: typography.h2.letterSpacing,
        color: darker ? palette.grey.dark : primaryColor ? palette.primary.main : palette.grey.mediumDark,
        fontWeight: 500,
        fontSize: smaller
          ? typography.standard
          : small
          ? typography.smaller
          : typography.standardLarge,
        ...style,
      }}
      {...rest}
    >
      {children}
    </h2>
  );
};
