import React from "react";
import { useTheme } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";

export default ({ containerStyle, ...otherProps }) => {
  const { palette } = useTheme();

  return (
    <div>
      <div
        className="text-left"
        style={{
          marginTop: "-2em",
          // marginLeft: "0.5em",
          // marginRight: "0.5em",
          padding: "0.25em",
          borderRadius: 6,
          boxShadow:
            "-2px 2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25)",
          backgroundColor: palette.common.white,
          display: "flex",
          justifyContent: "center",
          padding: "1em",
          height: 707,
          width: 432,
        }}
      />
      <div style={containerStyle}>
        <ContentLoader
          width={420}
          height={600}
          viewBox="0 0 420 600"
          backgroundColor={palette.grey.lighter}
          foregroundColor={palette.grey.lighter}
          {...otherProps}
        >
          {/* <rect x="16" y="0" rx="6" ry="6" width="288" height="494" />

        <rect x="16" y="532" rx="6" ry="6" width="142" height="46" />
        <rect x="225" y="552" rx="6" ry="6" width="82" height="23" /> */}
          <rect x="66" y="30" rx="6" ry="6" width="288" height="422" />
          <rect x="66" y="474" rx="6" ry="6" width="288" height="46" />
        </ContentLoader>
      </div>
    </div>
  );
};
