import React, { useCallback, useEffect, useState } from "react";
import { TextareaAutosize, withStyles } from "@material-ui/core";

// @ts-ignore
const PikchaTextArea = withStyles(() => ({
  textField: (props) => ({
    height: props.height ? props.height : null,
    width: "100%",
    background:
      props && props.disabled
        ? '#F2F4F8'
        : '#FFFFFF',
    fontSize: '16px',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    padding: "18.5px 14px",
    paddingBottom: "10.5px",
    paddingTop: "10.5px",
    borderRadius: '4px',
    border: '1px solid #DBE0E4',
    resize: "none",
    marginBottom:
      props && (props.charCount || props.footerText || props.reduceBottomMargin)
        ? "0.25rem"
        : "1.75rem",
  }),
  textFieldLabel: () => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    fontSize: '14px',
    fontFamily: 'Montserrat',
    lineHeight: "1rem",
    userSelect: "none",
    color: '#343A3F',
    marginBottom: ".25rem",
  }),
}))(
  ({
    classes: { textFieldLabel, textField, textFieldInput },
    style = {},
    isFullWidth,
    charCount,
    label,
    reduceBottomMargin,
    footerText,
    InputProps,
    startAdornment,
    onFinishEdit,
    ...rest
  }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [previousIsFocused, setPreviousIsFocused] = useState(false);

    InputProps = {
      ...(InputProps || {}),
      ...{
        notched: false,
      },
      ...(startAdornment || {})
    };
    InputProps.className = `${InputProps.className || ""} ${textFieldInput}`;

    useEffect(() => {
      if (!isFocused && previousIsFocused) {
        onFinishEdit && onFinishEdit();
        setPreviousIsFocused(isFocused);
      }
    }, [onFinishEdit, isFocused, previousIsFocused])

    return (
      <div
        className={isFullWidth ? "full-width" : ""}
        style={{
          position: "relative",
          ...style,
        }}
        onFocusCapture={() => {
          setPreviousIsFocused(isFocused);
          setIsFocused(true);
        }}
        onBlurCapture={() => {
          setPreviousIsFocused(isFocused);
          setIsFocused(false);
        }}
      >
        <TextareaAutosize
          style={style}
          // @ts-ignore
          rows="2"
          className={textField}
          InputLabelProps={{
            className: textFieldLabel,
            shrink: true,
            disableAnimation: true,
          }}
          InputProps={InputProps}
          {...rest}
        />
        {(charCount !== undefined || footerText !== undefined) && (
          <div
            style={{
              color: charCount < 1
                ? 'red'
                : '#343A3F',
              fontSize: '12px',
              fontWeight: '400',
              fontFamily: 'Montserrat',
              position: "absolute",
              userSelect: "none",
              cursor: "default",
              bottom: charCount !== 0 ? undefined : 8,
              right: 2,
            }}
          >
            {charCount || charCount === 0 ? charCount : footerText}
          </div>
        )}
      </div>
    );
  }
);

export default ({
  label = "Description",
  rows = 2,
  value,
  onChange,
  maxChars,
  style,
  onFinishEdit,
}) => {
  if (!onChange) {
    throw new Error(
      "An `onChange` must be passed as a prop"
    );
  }

  const handleChange = (e) => {
    const { target: { value } } = e;
    const remainingChars = maxChars - value.length;
    if (remainingChars === -1) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    onChange(value);
  }

  const handlePaste = useCallback((e) => {
    const stringLength = e.clipboardData.getData("text").length;

    if (value.length + stringLength > maxChars) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [value]);

  return (
    <>
      <div
        style={{
          fontSize: '12px',
          fontWeight: '700',
          lineHeight: '24px',
          color: '#343A3F',
          marginBottom: '-2.5em'
        }}>
        {label}
      </div>
      <PikchaTextArea
        // @ts-ignore
        style={style}
        rows={rows}
        label={label}
        onPaste={handlePaste}
        type="text"
        multiline
        value={value}
        onChange={handleChange}
        charCount={maxChars ? maxChars - value.length : undefined}
        onFinishEdit={onFinishEdit}
      />
    </>
  );
};
