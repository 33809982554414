import axios from "axios";
import AuthorizeService from "../auth/AuthorizeService";

import { SETTINGS_LOADED } from "./types";

export const loadSettings = () => {
  return async (dispatch) => {
    try {
      const token = await AuthorizeService.getAccessToken();

      const res = await axios.get(
        "/api/home/settings",
        token
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : null
      );

      return dispatch({
        type: SETTINGS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      console.log("loadSettings ", err);
    }
  };
};
