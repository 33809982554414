import TypographyButton from "../Buttons/TypographyButton";
import React from 'react';

const TypographyToggleButton = ({
  component,
  buttonText,
  icon,
  buttonStyle = {},
  textStyle = {},
  size = "small",
  children,
  disabled = false,
  hovering = false,
  ...rest
}) => {
  return (
    <TypographyButton
      component={component}
      buttonText={buttonText}
      icon={icon}
      buttonStyle={buttonStyle}
      textStyle={textStyle}
      size={size}
      disabled={disabled}
      hovering={hovering}
      {...rest}
    >
      {children}
    </TypographyButton>
  );
};

export default TypographyToggleButton;
