import React from "react";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";
import isMobile from "../../components/Responsive/isMobile";
import { PikchaLink } from "../baseComponents/PikchaLink";
import { UnitFormatter } from "../NumberFormatters/UnitFormatter";
import { IntlCurrencyFormatter, currencyFormat } from "../../components/NumberFormatters/IntlCurrencyFormatter";
import { PikchaIconButton } from "../../components/baseComponents";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";

const CartItems = ({
  imageRender,
  imageTitle,
  userHandle,
  nickName,
  type,
  material,
  size,
  border,
  frame,
  wrap,
  galleryCardColor,
  productNumber,
  totalEditions,
  totalPrice,
  showDeleteButton,
  deleteItem,
  isPortrait,
  backgroundContain = true
}) => {
  const isOnMobile = isMobile();
  let showBorder = type !== "Stretched Canvas";
  if (border === "No Border " || border === "No Border") {
    showBorder = false;
  }
  const showFrame = frame !== "NA";
  const showWrap = type === "Stretched Canvas";

  return (
    <>
      <div style={{ display: "flex" }} >
        <div
          style={{
            width: 164,
            height: 164,
            backgroundImage: `url(${imageRender})`,
            backgroundSize: backgroundContain ? "contain" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: isPortrait ? "left" : "center",
            marginRight: "1em"
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <>
            <TypographyBody
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
            >
              {imageTitle}
            </TypographyBody>
            <PikchaLink to={`/${userHandle}`}>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Small_Bold}
              >
                <span className="underlined-link">
                  {`${nickName}`}
                </span>
              </TypographyBody>
            </PikchaLink>
          </>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
            style={{
              padding: "0.5em 0.5em 0",
              lineHeight: '18px'
            }}
          >
            <span style={{ fontSize: 10, }}>&#9679;</span>&nbsp;&nbsp;{type}<br />
            <span style={{ fontSize: 10, }}>&#9679;</span>&nbsp;&nbsp;{material}<br />
            <span style={{ fontSize: 10, }}>&#9679;</span>
            &nbsp;&nbsp;<UnitFormatter value={size} appendUnit={true} />cm<br />
            {showBorder && border !== "NA" && <><span style={{ fontSize: 10, }}>&#9679;</span>&nbsp;&nbsp;{border}Border<br /></>}
            {showFrame && <><span style={{ fontSize: 10, }}>&#9679;</span>&nbsp;&nbsp;{frame} Frame<br /></>}
            <span style={{ fontSize: 10, }}>&#9679;</span>&nbsp;&nbsp;
            <span>Edition {productNumber} of {totalEditions}</span><br />
          </TypographyBody>
        </div>
      </div>
      {totalPrice > 0 &&
        <div 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: '1.5em'
          }} 
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
            style={{
              backgroundColor: '#F2F4F8',
              padding: '0.4em 1em',
              marginRight: '1em',
              borderRadius: '4px'
            }}
          >
            <IntlCurrencyFormatter value={+(totalPrice)}
              displayFormat={currencyFormat.code}
            />
          </TypographyBody>
          {showDeleteButton &&
            <PikchaIconButton
              onClick={() => deleteItem()}
              icon={<DeleteOutlineRoundedIcon />}
              color="black"
              style={{
                border: '1px solid #343A3F'
              }}
            />
          }
        </div>
      }
    </>
  )
}

export default CartItems;