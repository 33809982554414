import React from "react";
import Img from "react-image";
import { useHistory } from "react-router-dom";

import { PikchaDrawerMenuItem } from "../baseComponents";

export const FollowingMenuItem = ({ id, avatar, nickName, fName, lName, getProfile, userHandle }) => {
  const history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    history.push(`/${userHandle}`);
  };

  return (
    <PikchaDrawerMenuItem
      onClick={handleClick}
      icon={
        <Img
          src={avatar}
          height="auto"
          alt=""
          className="FollowingList-avatar"
        />
      }
    >
      <span>
        {nickName}
      </span>
    </PikchaDrawerMenuItem>
  );
};
