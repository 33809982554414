import React, {cloneElement} from "react";
import {useTheme} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import {ICON_SIZE_SMALL} from "../../../helpers/constants";

export const PikchaIconButton = ({
                                   style = {},
                                   icon,
                                   disabled,
                                   size = "small",
                                   children = null,
                                   ...rest
                                 }) => {

  const {palette, shape, spacing, typography} = useTheme();
  
  if ((icon && !icon.$$typeof) || children) {
    throw new Error("Only an `icon` component prop must be passed without children.");
  }

  return (
    <IconButton
      style={{
        maxHeight: 44,
        lineHeight: 1.5,
        padding: 2,
        borderRadius: shape.borderRadius,
        width: style.width ? style.width : spacing(4),
        height: style.height ? style.height : spacing(4),
        backgroundColor: palette.grey.lightest,
        background: !disabled ? palette.common.white : palette.grey.light,
        borderWidth: 1,
        color: !disabled ? palette.text.secondary : palette.grey.mediumLight,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
        fontSize: typography.smallest,
        fontWeight: typography.medium,
        ...style,
      }}
      {...rest}
    >
      {cloneElement(icon, {
        ...(size === "small" && ({
          style: ICON_SIZE_SMALL
        }))
      })}
    </IconButton>
  );
}
