import React, { Fragment } from "react";
import { CSSTransition } from "react-transition-group";

import PikchaSpacer from "../spacing/PikchaSpacer";

import "./PikchaFormSection.css";

export default ({
  children,
  className = "",
  style = {},
  addTransition = false,
  topMarginSize = 4,
  ...rest
}) => (
  <div className={className} {...rest}>
    {addTransition ? (
      // TODO: Fix this
      <CSSTransition in timeout={200} classNames="my-node">
        <PikchaSpacer size={topMarginSize} />
        {children}
      </CSSTransition>
    ) : (
      <Fragment>
        <PikchaSpacer size={topMarginSize} />
        {children}
      </Fragment>
    )}
  </div>
);
