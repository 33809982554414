import React, {
  Fragment,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import {
  connect,
  useSelector,
} from "react-redux";

import {
  resetSearch,
  search,
  searchArtists,
  searchAlbums,
  resetAlbums,
} from "../../actions/search";
import {
  photoSearchSelector,
  resetGallerySearch,
} from "../../actions/gallerySearch";
import { isUserLoggedIn } from "../../selectors/account";
import { isLoading } from "../../selectors/search";
import { searchAPIUrl } from "../../selectors/settings";

import Desktop from "../../components/Responsive/Desktop";
import Tablet from "../../components/Responsive/Tablet";
import Mobile from "../../components/Responsive/Mobile";

import isDesktop from "../../components/Responsive/isDesktop";
import isTablet from "../../components/Responsive/isTablet";

import SearchDesktop from "./SearchDesktop";
import SearchMobile from "./SearchMobile";

import SearchArtistsDesktop from "./SearchArtistsDesktop";
import SearchArtistsMobile from "./SearchArtistsMobile";

import SearchAlbumsDesktop from "./SearchAlbumsDesktop";
import SearchAlbumsMobile from "./SearchAlbumMobile";

const Search = ({
  search,
  resetGallerySearch,
  searchArtists,
  searchAlbums,
  resetSearch,
  isSearchLoading,
  history,
  hasUserLoggedIn,
  gallery,
  artists,
  albums,
  searchAPIUrl,
}) => {
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();

  const [lastSearchTerm, setLastSearchTerm] = useState();

  const setResultTypeInitial = () => {
    const location = window.location.href;
    const endpoint = location.split("/")[location.split("/").length-2];
    if(endpoint==="search"){
      return "Pikcha";
    } else if(endpoint==="artist") {
      return "Artist";
    }else if(endpoint==="album") {
      return "Album";
    }
  }

  const [resultsType, setResultsType] = useState(setResultTypeInitial());

  const [sortBy, setSortBy] = useState({ value: "RV", label: "Recommended" });

  const [imageCategories, setImageCategories] = useState([]);
  const [imageTypes, setImageTypes] = useState([]);
  const [coloursAvailable, setColoursAvailable] = useState([]);

  const [imageType, setImageType] = useState([]);
  const [imageCategory, setImageCategory] = useState([]);
  const [imageOrientation, setImageOrientation] = useState();
  const [imageColour, setImageColour] = useState();
  const [location, setLocation] = useState();

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState();

  const [forcedGet, setForcedGet] = useState(false);

  const images = useSelector((state) => photoSearchSelector(state));

  const resetResults = () => {
    resetGallerySearch();
  };

  const searchTerm = decodeURIComponent(
    history.location.pathname.substring(
      history.location.pathname.lastIndexOf("/") + 1
    )
  );

  const setUrlChange = (type) => {
    if(type==="Artist"){
      const index = window.location.href.lastIndexOf("\/");
      const baseUrl = window.location.href.substring(0, index+1);
      let state = {title: '', url: baseUrl};
      window.history.pushState(state,'',`search/artist/${searchTerm}`);
    } else if (type==="Pikcha"){
      const index = window.location.href.lastIndexOf("\/");
      const baseUrl = window.location.href.substring(0, index+1);
      let state = {title: '', url: baseUrl};
      window.history.pushState(state,'',`search/${searchTerm}`);
    } else if (type==="Album"){
      const index = window.location.href.lastIndexOf("\/");
      const baseUrl = window.location.href.substring(0, index+1);
      let state = {title: '', url: baseUrl};
      window.history.pushState(state,'',`search/album/${searchTerm}`);
    }
  }

  useEffect(() => {
    if (lastSearchTerm) {
      if(resultsType==="Pikcha"){
        setUrlChange(resultsType);
        resetResults();
        search({ searchTerm });
      }else if(resultsType==="Artist"){
        setUrlChange(resultsType);
        resetSearch();
        searchArtists({ searchTerm });
      }else if(resultsType==="Album"){
        setUrlChange(resultsType);
        resetSearch();
        searchAlbums({ searchTerm });
      }
    }
    setLastSearchTerm(searchTerm);
  }, [searchTerm]);
  useEffect(() => {
    if (searchAPIUrl) {
      if(resultsType==="Pikcha"){
        resetResults();
        search({ searchTerm });
      }else if(resultsType==="Artist"){
        resetSearch();
        searchArtists({ searchTerm });
      }else if(resultsType==="Album"){
        resetSearch();
        searchAlbums({ searchTerm });
      }
    }
  }, [searchAPIUrl]);

  useEffect(() => {
    const retrieveData = () => {
      axios({
        method: "GET",
        url: `api/image/prerequisites`,
        data: null,
      }).then((cat) => {
        const cats = [];
        const types = [];
        const colours = [];

        cat.data.categories.forEach((cat) => {
          cats.push({ name: cat.name });
        });
        setImageCategories(cats);

        cat.data.types.forEach((type) =>
          types.push({
            name: type.name,
          })
        );
        setImageTypes(types);

        cat.data.colours.forEach((colour) => {
          colours.push(colour);
        });
        setColoursAvailable(colours);
      });
    };
    retrieveData();
  }, []);

  useEffect(() => {
    axios.get(`api/profile/nationalities`).then((response) => {
      const shippingCountries = response.data;

      const countriesReadyForSelection = shippingCountries.map(
        ({ label, value }) => ({
          label: label,
          value: value,
        })
      );

      setCountries(countriesReadyForSelection);
    });
  }, []);

  // hooks don't immediately update
  // need to update in an effect
  useEffect(() => {
    if (forcedGet) {
      if (resultsType === "Artist") {
        getArtists(artists.count, artists.start);
      } 
      else if (resultsType === "Album") {
        getAlbums(albums.count, albums.start);
      }
      else {
        getPhotos(gallery.count, gallery.start);
      }
      setForcedGet(false);
    }
  }, [forcedGet]);

  const getPhotos = (count, start) => {
    let sortByValue;
    if (sortBy) {
      sortByValue = sortBy;
    }
    let imageOrientationValue;
    if (imageOrientation) {
      imageOrientationValue = imageOrientation;
    }

    search({
      searchTerm,
      sortBy: sortByValue.value ? sortByValue.value : sortByValue,
      imageType,
      imageCategory,
      location,
      imageOrientation: imageOrientationValue,
      imageColour,
      count,
      start,
    });
  };

  const getArtists = (count, start, reset) => {
    if (reset) resetSearch();
    let sortByValue;
    if (sortBy) {
      sortByValue = sortBy;
    }

    let countryValue;
    if (country) {
      countryValue = country.value;
    }
    searchArtists({
      searchTerm,
      country: countryValue,
      sortBy: sortByValue.value ? sortByValue.value : sortByValue,
      count,
      start,
    });
  };

  const getAlbums = (count, start, reset) => {
    if (reset) resetSearch();
    let sortByValue;
    if (sortBy) {
      sortByValue = sortBy;
    }
    searchAlbums({
      searchTerm,
      sortBy: sortByValue.value ? sortByValue.value : sortByValue,
      count,
      start,
    });
  };




  const forceGetPhotos = () => {
    setForcedGet(true);
  };

  const resetGallery = () => resetResults();

  const resultsTypeChange = (event, newValue) => {
    if(newValue!==resultsType){
      resetResults();
      resetSearch();
      setResultsType(newValue);
      setUrlChange(newValue);
      forceGetPhotos();
    }
  };

  const onSortByChange = (option) => {
    setSortBy(option);
    resetResults();
    resetSearch();
    forceGetPhotos();
  };

  const onApplyFilters = () => {
    resetResults();
    resetSearch();
    forceGetPhotos();
  };

  const onFilterChange = () => {
    if (isOnTablet || isOnDesktop) {
      resetResults();
      forceGetPhotos();
    }
  };

  const onImageTypeChange = (optionName) => {
    const isImageTypeChecked = imageType.some(
      (typeName) => typeName === optionName
    );

    let newImageType;
    if (isImageTypeChecked) {
      newImageType = imageType.reduce((accumulator, value) => {
        if (value !== optionName) {
          return [...accumulator, ...[value]];
        }
        return accumulator;
      }, []);
    } else {
      newImageType = [...imageType, ...[optionName]];
    }
    setImageType(newImageType);
    onFilterChange();
  };
  const onImageCategoryChange = (optionName) => {
    const isImageCategoryChecked = imageCategory.some(
      (typeName) => typeName === optionName
    );

    let newImageCategory;
    if (isImageCategoryChecked) {
      newImageCategory = imageCategory.reduce((accumulator, value) => {
        if (value !== optionName) {
          return [...accumulator, ...[value]];
        }
        return accumulator;
      }, []);
    } else {
      newImageCategory = [...imageCategory, ...[optionName]];
    }
    setImageCategory(newImageCategory);
    onFilterChange();
  };
  const handleOrientationChange = (chosenOrientation) => {
    setImageOrientation(chosenOrientation);
    onFilterChange();
  };
  const handleColourChange = (chosenColor) => {
    let colorToEmit = "";
    coloursAvailable.forEach((col) => {
      if (col.hexValue === chosenColor) {
        colorToEmit = col.hexValue;
      }
    });
    setImageColour(colorToEmit);
    onFilterChange();
  };
  const onLocationChange = (value) => {
    setLocation(value);
    onFilterChange();
  };

  const onCountryChange = (option) => {
    setCountry(option);
    if (resultsType === "Artist") resetSearch();
    onFilterChange();
  };
  const onClearFilters = () => {
    let isChanged = false;
    if (country) {
      isChanged = true;
      setCountry("");
    }
    if (location) {
      isChanged = true;
      setLocation("");
    }
    if (imageColour) {
      isChanged = true;
      setImageColour(undefined);
    }
    if (imageOrientation) {
      isChanged = true;
      setImageOrientation(undefined);
    }
    if (imageCategory && imageCategory.length) {
      isChanged = true;
      setImageCategory([]);
    }
    if (imageType && imageType.length) {
      isChanged = true;
      setImageType([]);
    }
    if(sortBy) {
      setSortBy({ value: "RV", label: "Relevance" })
    }
    if (isChanged) onFilterChange();
  };

  return (
    <Fragment>
      {resultsType === "Pikcha" && (
        <div
          className="main-width-restrict"
          style={{
            width: "100%",
            height: "fit-content",
          }}
        >
          <Desktop>
            <SearchDesktop
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              gallery={gallery}
              images={images}
              getPhotos={getPhotos}
              resetGallery={resetGallery}
              sortBy={sortBy}
              onClearFilters={onClearFilters}
              onSortByChange={onSortByChange}
              onApplyFilters={onApplyFilters}
              imageType={imageType}
              imageTypes={imageTypes}
              onImageTypeChange={onImageTypeChange}
              imageCategories={imageCategories}
              imageCategory={imageCategory}
              onImageCategoryChange={onImageCategoryChange}
              imageOrientation={imageOrientation}
              handleOrientationChange={handleOrientationChange}
              imageColour={imageColour}
              coloursAvailable={coloursAvailable}
              handleColourChange={handleColourChange}
              location={location}
              onLocationChange={onLocationChange}
            />
          </Desktop>
          <Tablet>
            <SearchDesktop
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              gallery={gallery}
              images={images}
              getPhotos={getPhotos}
              resetGallery={resetGallery}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              imageType={imageType}
              imageTypes={imageTypes}
              onImageTypeChange={onImageTypeChange}
              imageCategories={imageCategories}
              imageCategory={imageCategory}
              onImageCategoryChange={onImageCategoryChange}
              imageOrientation={imageOrientation}
              handleOrientationChange={handleOrientationChange}
              imageColour={imageColour}
              coloursAvailable={coloursAvailable}
              handleColourChange={handleColourChange}
              location={location}
              onLocationChange={onLocationChange}
            />
          </Tablet>
          <Mobile>
            <SearchMobile
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              gallery={gallery}
              images={images}
              getPhotos={getPhotos}
              resetGallery={resetGallery}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              imageType={imageType}
              imageTypes={imageTypes}
              onImageTypeChange={onImageTypeChange}
              imageCategories={imageCategories}
              imageCategory={imageCategory}
              onImageCategoryChange={onImageCategoryChange}
              imageOrientation={imageOrientation}
              handleOrientationChange={handleOrientationChange}
              imageColour={imageColour}
              coloursAvailable={coloursAvailable}
              handleColourChange={handleColourChange}
              location={location}
              onLocationChange={onLocationChange}
            />
          </Mobile>
        </div>
      )}
      {resultsType === "Artist" && (
        <div
          className="main-width-restrict"
          style={{
            width: "100%",
            height: "fit-content",
          }}
        >
          <Desktop>
            <SearchArtistsDesktop
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              countries={countries}
              onCountryChange={onCountryChange}
              country={country}
              artists={artists}
              getArtists={getArtists}
            />
          </Desktop>
          <Tablet>
            <SearchArtistsDesktop
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              countries={countries}
              onCountryChange={onCountryChange}
              country={country}
              artists={artists}
              getArtists={getArtists}
            />
          </Tablet>
          <Mobile>
            <SearchArtistsMobile
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              countries={countries}
              onCountryChange={onCountryChange}
              country={country}
              artists={artists}
              getArtists={getArtists}
            />
          </Mobile>
        </div>
      )}



        {resultsType === "Album" && (
        <div
          className="main-width-restrict"
          style={{
            width: "100%",
            height: "fit-content",
          }}
        >
          <Desktop>
            <SearchAlbumsDesktop
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              albums={albums}
              getAlbums={getAlbums}
              resetAlbums={resetSearch}
            />
          </Desktop>
          <Tablet>
            <SearchAlbumsDesktop
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              albums={albums}
              getAlbums={getAlbums}
              resetAlbums={resetSearch}
            />
          </Tablet>
          <Mobile>
            <SearchAlbumsMobile
              searchTerm={searchTerm}
              isSearchLoading={isSearchLoading}
              resultsType={resultsType}
              resultsTypeChange={resultsTypeChange}
              sortBy={sortBy}
              onSortByChange={onSortByChange}
              onClearFilters={onClearFilters}
              onApplyFilters={onApplyFilters}
              albums={albums}
              getAlbums={getAlbums}
              resetAlbums={resetSearch}
            />
          </Mobile>
        </div>
      )}


    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  artists: state.searchArtistsReducer,
  albums: state.searchAlbumsReducer,
  //gallery: state.galleryReducer,
  gallery: state.gallerySearchReducer,
  hasUserLoggedIn: isUserLoggedIn(state),
  isSearchLoading: isLoading(state),
  searchAPIUrl: searchAPIUrl(state),
});

export default connect(mapStateToProps, {
  search,
  searchArtists,
  searchAlbums,
  resetSearch,
  resetGallerySearch,
  //resetAlbums,
})(withRouter(Search));
