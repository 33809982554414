import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
  const { palette } = useTheme();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1875 0H2.8125C1.26169 0 0 1.26169 0 2.8125V21.1875C0 22.7383 1.26169 24 2.8125 24H21.1875C22.7383 24 24 22.7383 24 21.1875V2.8125C24 1.26169 22.7383 0 21.1875 0ZM22.125 21.1875C22.125 21.7044 21.7044 22.125 21.1875 22.125H15.8438V14.4844H18.7402L19.2188 11.5781H15.8438V9.5625C15.8438 8.7668 16.4543 8.15625 17.25 8.15625H19.1719V5.25H17.25C14.863 5.25 12.9385 7.18359 12.9385 9.57066V11.5781H10.125V14.4844H12.9385V22.125H2.8125C2.29556 22.125 1.875 21.7044 1.875 21.1875V2.8125C1.875 2.29556 2.29556 1.875 2.8125 1.875H21.1875C21.7044 1.875 22.125 2.29556 22.125 2.8125V21.1875Z"
        fill={color || palette.grey.medium}
      />
    </svg>
  );
};
