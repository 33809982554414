import React from "react";
import iconCollection from "../../../assets/images/Collection/iconCollection.webp"
import bannerCollection from "../../../assets/images/Collection/bannerCollection.png"
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";
import isMobile from "../../../components/Responsive/isMobile";
import { IntlCurrencyFormatter } from "../../../components/NumberFormatters/IntlCurrencyFormatter";

export default ({
  title,
  hasNudity,
  creatable,
  safeSearch,
  nickName,
  avatar,
  totalImages,
  banner,
  icon,
  lowestPrice,
  targetCurrency
}) => {
  const isOnMobile = isMobile()
  return (
    <>
      <div
        style={{
          opacity: (!creatable && hasNudity && safeSearch) ? "0.7" : undefined,
          filter: (!creatable && hasNudity && safeSearch) ? "blur(12px)" : undefined,
          width: "100%"
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div 
            style={{
              position: 'relative',
              height: isOnMobile ? 180 : '100%',
              background: `url(${banner ? banner : bannerCollection}) center center / cover no-repeat`
            }}
          >
            <div 
              style={{
                position: 'absolute',
                bottom: '-20px',
                left: '10px',
                border: '1px solid #FFF',
                borderRadius: '11px',
                filter: 'drop-shadow(0px 2.47px 2.47px rgba(0, 0, 0, 0.1))',
                width: '56px',
                height: '56px',
                background: `url(${icon ? icon : iconCollection}) center center / cover no-repeat`
              }}
            />
          </div>
          <div 
            style={{
              marginTop: '1.5em',
              marginLeft: '1em',
              marginBottom:'1em'
             }}
          >
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
            >
              {title}
            </TypographyBody>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <img
                style={{
                  width: "16px",
                  height: '16px',
                  borderRadius: '50%',
                  marginRight: '0.5em'
                }}
                src={avatar} 
                alt="User Icon"
              />
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              >
                {nickName}
              </TypographyBody>
            </div>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              style={{ color: '#697077' }}
            >
              {totalImages} Artworks
            </TypographyBody>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              style={{ color: '#697077' }}
            >
              From <IntlCurrencyFormatter targetCurrency={targetCurrency} />{lowestPrice}
            </TypographyBody>
          </div>
        </div>
      </div>
    </>
  );
}
