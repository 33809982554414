import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
  const { palette } = useTheme();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M7.50037 19.8281H4.21912V9.23438H7.50037V19.8281ZM7.82776 5.85919C7.82776 4.79846 6.96716 3.9375 5.90698 3.9375C4.84277 3.9375 3.98438 4.79846 3.98438 5.85919C3.98438 6.92029 4.84277 7.78125 5.90698 7.78125C6.96716 7.78125 7.82776 6.92029 7.82776 5.85919ZM19.7812 13.9999C19.7812 11.1561 19.1805 9.04688 15.8584 9.04688C14.2621 9.04688 13.1906 9.84521 12.7531 10.6754H12.75V9.23438H9.5625V19.8281H12.75V14.5682C12.75 13.1907 13.1006 11.8563 14.8081 11.8563C16.4923 11.8563 16.5469 13.4315 16.5469 14.6558V19.8281H19.7812V13.9999ZM24 21.1875V2.8125C24 1.2616 22.7384 0 21.1875 0H2.8125C1.2616 0 0 1.2616 0 2.8125V21.1875C0 22.7384 1.2616 24 2.8125 24H21.1875C22.7384 24 24 22.7384 24 21.1875ZM21.1875 1.875C21.7044 1.875 22.125 2.29559 22.125 2.8125V21.1875C22.125 21.7044 21.7044 22.125 21.1875 22.125H2.8125C2.29559 22.125 1.875 21.7044 1.875 21.1875V2.8125C1.875 2.29559 2.29559 1.875 2.8125 1.875H21.1875Z"
          fill={color || palette.grey.medium}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
