import defaultTheme from '../../../themes/defaultTheme';

export const socialAuthButtonBaseStyles = {
  fontSize: defaultTheme.typography.standard,
  minWidth: 315,
  fontWeight: 600,
  boxShadow: 'none',
  border: `2px solid ${defaultTheme.palette.grey.medium}`,
  borderRadius: defaultTheme.shape.borderRadius,
  margin: 4,
  color: defaultTheme.palette.text.primary,
  width: '100%',
  padding: '6px 16px',
};
