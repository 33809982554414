import React from 'react'
import ArrowLeft from '@material-ui/icons/ArrowBackIosOutlined'
import DownloadIcon from "../../../../components/extraIcons/DownloadIcon";
import { currencyFormat, IntlCurrencyFormatter } from "../../../../components/NumberFormatters/IntlCurrencyFormatter";
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TYPOGRAPHY_BODY_SIZE,
  TypographyBody
} from "@pikcha/typography";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-grid-system";
import withStyles from "@material-ui/core/styles/withStyles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import { Paper } from "@material-ui/core";
import isTablet from "../../../../components/Responsive/isTablet";
import isMobile from "../../../../components/Responsive/isMobile";

export const StyledTableContainer = withStyles(() => ({
  root: {
    maxHeight: "60vh",
    marginBottom: "8em",
    border: 'none',
    boxShadow: 'none',
  },
}))(TableContainer);

export const StyledTableCell = withStyles(() => ({
  root: {
    minWidth: 225,
    fontFamily: "montserrat",
    border: 'none',
    boxShadow: 'none',
    // backgroundColor: '#F2F4F8',
    height: '44px',
    padding: '0 0 1m 0',
    marginTop: '1em',
  },
}))(TableCell);

const PaymentHistory = ({ 
  goBackFromPaymentHistory, 
  paymentHistoryData, 
  viewSalesOfPayment, 
  downloadPaymentHistory 
}) => {

  const isOnTablet = isTablet();
  const isOnMobile = isMobile();

  return (
    <div
      style={{
        margin: isOnMobile && '0 0.5em'
      }}
    >
      <TypographyButton
        buttonStyle={{ padding: '16px 8x', marginBottom: isOnMobile ? '1.75em' : '2em' }}
        onClick={(e) => goBackFromPaymentHistory()}
        component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
        icon={<ArrowLeft />}
      >
        Back
      </TypographyButton>
      <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: isOnMobile && 'column' }}>
        <TypographySubtitle
          component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
          style={{ marginBottom: isOnMobile && '1em' }}
        >
          Payment history
        </TypographySubtitle>
        <TypographyButton
          onClick={downloadPaymentHistory}
          buttonStyle={{
            color: '#000', marginBottom: isOnMobile ? '2.25em' : '0'
          }}
          textStyle={{ paddingLeft: "0.5em" }}
          component={TYPOGRAPHY_BUTTON_TYPE.Outline}
          icon={
            <DownloadIcon
              color="#000"
              iconSize={16}
            />
          }
        >
          Download
        </TypographyButton>
      </div>
      <div
        style={{
          display: 'flex',
          maxWidth: isOnMobile ? '100%' : isOnTablet ? '60%' : '50%',
          justifyContent: 'space-between',
          border: '1px solid #DBE0E4',
          borderRadius: '4px',
          padding: '2em',
          marginBottom: '1.5em'
        }}
      >
        <div>
          <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
            Payment at Next Payment Cycle
          </TypographyBody>
          <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
            Total sales to be paid
          </TypographyBody>
          <Link
            style={{
              fontSize: '12px',
              fontWeight: 700,
              color: "#4B01D1",
            }}
            to="/guides/artist/getting-paid"
          >
            When will I get paid?
          </Link>
        </div>
        <div>
          <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
            <IntlCurrencyFormatter targetCurrency="USD" />
            {paymentHistoryData?.AmountDue}
          </TypographyBody>
          <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
            <IntlCurrencyFormatter targetCurrency="USD" />
            {paymentHistoryData?.TotalToBePaid}
          </TypographyBody>
        </div>
      </div>
      {paymentHistoryData?.payoutBase?.payoutbase?.length === 0 && (
          <Container fluid>
            <Row className="text-center">
              <Col md={12} className="mt-4">
                <span className="fontSize16">
                  You don’t have any sales yet
                </span>
              </Col>
            </Row>
          </Container>
        )}
      <div style={{ display: "flex" }}>
        <StyledTableContainer
          children={<table></table>}
          component={Paper}
          className={"mt-2"}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align={"center"}
                  style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }}>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                    Payment Date
                  </TypographyBody>
                </StyledTableCell>
                <StyledTableCell align={"center"}
                  style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }}>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                    Payment Method
                  </TypographyBody>
                </StyledTableCell>
                <StyledTableCell align={"center"}
                  style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }}>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                    Amount (USD)
                  </TypographyBody>
                </StyledTableCell>
                <StyledTableCell align={"center"}
                  style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }}>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                    Payout Currency
                  </TypographyBody>
                </StyledTableCell>
                <StyledTableCell align={"center"}
                  style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }}>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                    FX Rate
                  </TypographyBody>
                </StyledTableCell>
                <StyledTableCell align={"center"}
                  style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }}>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                    Transfer Fee
                  </TypographyBody>
                </StyledTableCell>
                <StyledTableCell align={"center"}
                  style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }}>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                    &#36;payout
                  </TypographyBody>
                </StyledTableCell>
                <StyledTableCell align={"center"}
                  style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }}>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                    Payment Status
                  </TypographyBody>
                </StyledTableCell>
                <StyledTableCell align={"center"} style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  background: "white",
                  paddingBottom: '1em',
                  minWidth: '150px'
                }}></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistoryData?.payoutBase?.payoutbase?.map((item, index) => {
                return (
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                      {item.PaymentDate ? moment(item.PaymentDate, "YYYY-MM-DD").format(
                        "D-M-YYYY"
                      ) : "Not Available"}
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                      <TypographyBody
                        style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }}
                        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                      >
                        {item.PayoutMethod}
                      </TypographyBody>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                      <TypographyBody
                        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                      >
                        <IntlCurrencyFormatter targetCurrency="USD" />
                        {item.SourceAmount}
                      </TypographyBody>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                      <TypographyBody
                        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                      >
                        {item.TargetCurrency}
                      </TypographyBody>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                      <TypographyBody
                        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                      >
                        {item.FxRate}
                      </TypographyBody>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                      <TypographyBody
                        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                      >
                        {item.TransferFees}
                      </TypographyBody>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                      <TypographyBody
                        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                      >
                        <IntlCurrencyFormatter value={+(item.TargetAmount)} localConversion={true}
                          targetCurrency={item.TargetCurrency}
                          displayFormat={currencyFormat.code} />
                      </TypographyBody>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                      <TypographyBody
                        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                      >
                        {item.PayoutStatus}
                      </TypographyBody>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align="center" >
                      <TypographyButton
                        buttonStyle={{ height: '32px' }}
                        component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                        onClick={() => viewSalesOfPayment(item)}
                      >
                        View Sales
                      </TypographyButton>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </div>
    </div>
  )
}

export default PaymentHistory