import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ children, style, bodyCopy, ...rest }) => {
  const { typography, palette } = useTheme();

  return (
    <ul
      style={{
        fontFamily: bodyCopy
          ? typography.body.fontFamily
          : typography.fontFamilyMontserrat,
        color: palette.text.primary,
        fontSize: typography.body.fontSize,
        lineHeight: 2,
        letterSpacing: typography.body.letterSpacing,
        maxWidth: typography.body.maxWidth,
        ...style,
      }}
      {...rest}
    >
      {children}
    </ul>
  );
};
