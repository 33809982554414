import React, { useState, useEffect, useRef, useCallback } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import DeleteImageConfirmationModal from "../../containers/modals/EditPhotoModal/DeleteImageConfirmationModal";
import Step1 from "./Step1.jsx";
import Step2 from "./Step2.jsx";
import Step3 from "./Step3.jsx";
import Step4 from "./Step4.jsx";
import Step5 from "./Step5.jsx";
import Step6 from "./Step6.jsx";
import ImagePreview from "./ImagePreview";
import axios from "axios";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert.js";
import { MINIMUM_EARNINGS } from "../../helpers/constants";
import AuthorizeService from "../../auth/AuthorizeService";
import { getCropData } from "../../components/SignatureCanvas/utils/getViewBoxCropData";
import { handleArtistPromotionModal } from "../../actions/profile";
import { userId } from "../../selectors/account";
import { sendCriticalException } from "../../helpers/utils";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import ConfirmAlert from "../../components/ConfirmAlert/ConfirmAlert";
import { isUserLoading } from "../../selectors/account";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyAccordion,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographyTitle,
  TYPOGRAPHY_TITLE_COMPONENT,
} from "@pikcha/typography";
import isDesktop from "../../components/Responsive/isDesktop";

const Upload = ({ userId, account, setAlert }) => {
  const isOnDesktop = isDesktop();
  let scrollRef = useRef(null);
  let scrollContainerRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [signatureBackup, setSignatureBackup] = useState("");
  const [showExitConfirmationModal, setShowExitConfirmationModal] = useState(
    false
  );
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState("");
  const [currentOpen, setCurrentOpen] = useState(1);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [submitPending, setSubmitPending] = useState(false);
  const [allImageTypes, setAllImageTypes] = useState([]);
  const [imageDetails, setImageDetails] = useState({
    title: "",
    location: "",
    caption: "",
    price: "",
    categoryId: [],
    releaseFormList: [],
    colour: "",
    isNudity: false,
    tags: [],
    majorColour: "",
    signature: "",
    TypeId: null,
    imageFile: null,
    dateCreated: "00-00-0000",
    edition: "100",
  });
  const [hasSignature, setHasSignature] = useState(true);
  const [VibrantColors, setVibrantColors] = useState([]);
  const [alreadySigned, setAlreadySigned] = useState(false);
  const [allProductLines, setAllProductLines] = useState([]);
  const [titleBackup, setTitleBackup] = useState("");

  const handleImageDetails = useCallback(
    (field, metadata) => {
      setImageDetails({
        ...imageDetails,
        [field]: metadata,
      });
    },
    [imageDetails]
  );

  useEffect(async () => {
    const token = await AuthorizeService.getAccessToken();
    axios
      .get(`api/image/${location.state?.imageDetails.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setImageDetails(res.data);
        setTitleBackup(res.data.title);
        setSignatureBackup(res.data.signature);
        const derivedProductIds = [];
        res.data.productList.forEach(({ productType: { id } }) =>
          derivedProductIds.push(id)
        );
        setImageDetails({ ...res.data, productTypeIds: derivedProductIds });
      })
      .catch((err) => {
        console.log("editphotomodal ", err);
        sendCriticalException(
          `get image info failed, endpoint: api/image/${location.state?.imageDetails.id}`,
          `userId: ${(account.user || {}).id} err-info: ${err}`
        );
        setAlert("Loading image details failed, please retry later", "error");
      });
  }, [location.state?.imageDetails.id]);

  const handleCloseModalAndExitAlert = (_, cancelExit = false) => {
    setShowExitConfirmationModal(false);
    if (cancelExit) {
      return;
    }
    setConfirmedNavigation(true);
  };

  const handleExitConfirmationModal = (nextLocation) => {
    if ((account.user || {}).roles.includes("Artist")) {
      if (nextLocation.pathname !== "/upload") {
        setNextLocation(nextLocation.pathname + nextLocation.hash);
        setShowExitConfirmationModal(true);
        if (!confirmedNavigation) {
          return false;
        }
        return true;
      }
    }
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `api/image/prerequisites`,
      data: null,
    })
      .then((cat) => {
        const types = [];
        const productLines = [];

        cat.data.types.forEach((type) =>
          types.push({
            label: type.name,
            value: type,
          })
        );
        cat.data.productLines.forEach((product) => {
          productLines.push({ label: product.type, value: product });
        });
        setAllImageTypes(types);
        setAllProductLines(productLines);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      history.push(nextLocation);
    }
  }, [confirmedNavigation, nextLocation]);

  const handleSwitchStep = (step) => {
    if (currentOpen === step) {
      setCurrentOpen("");
    } else {
      setCurrentOpen(step);
    }
  };

  const deletePhotoConfirm = async () => {
    const token = await AuthorizeService.getAccessToken();
    axios({
      method: "DELETE",
      url: `api/image/${imageDetails.id}/${userId}`,
      data: null,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setAlert("successfully deleted", "success");
        setConfirmedNavigation(true);
        setShowDeleteConfirmation(false);
        history.push(`/${imageDetails.artist.userHandle}`);
        window.location.reload();
      })
      .catch((err) => {
        console.log("deletePhotoConfirm ", err);
        setAlert("delete failed", "error");
      });
  };

  const handlePublish = async () => {
    const token = await AuthorizeService.getAccessToken();
    const artistId = account.user?.id;

    const {
      title,
      caption,
      location,
      setPrice,
      isNudity,
      dateCreated,
      type,
      totalEditions,
      signatureAlignment,
      signatureColour,
      signature,
    } = imageDetails;

    if (!title) {
      setCurrentOpen(4);
      setAlert("Please add a title", "error");
      return false;
    }

    if (!setPrice || setPrice < MINIMUM_EARNINGS) {
      setCurrentOpen(6);
      setAlert(`Please add a price above ${MINIMUM_EARNINGS} USD`, "error");
      return false;
    }

    const dto = new FormData();

    if (
      imageDetails.releaseFormList &&
      imageDetails.releaseFormList.length > 0
    ) {
      imageDetails.releaseFormList.forEach((file) => {
        dto.append("ReleaseFormList", file);
      });
    }

    if (
      imageDetails &&
      imageDetails.productList &&
      imageDetails.productList.length > 0
    ) {
      imageDetails.productList.forEach((item) => {
        dto.append("ProductTypeIds", item?.productType?.id);
      });
    }
    if (imageDetails && imageDetails.tags && imageDetails.tags.length > 0) {
      imageDetails.tags.forEach((tag) => {
        dto.append("Tags", tag);
      });
    }

    let coloredSignatureData;

    if (signature && signature.indexOf("<svg") !== -1) {
      await getCropData(signature, true, signatureColour, 12.5)
        .then(({ _, invSigSVG }) => {
          coloredSignatureData = invSigSVG;
        })
        .catch((err) => {
          console.log("getCropData ", err);
        });
    }

    dto.append("Title", title);
    dto.append("Caption", caption);
    dto.append("location", location);
    dto.append("SetPrice", setPrice);
    dto.append("isNudity", isNudity);
    dto.append("totalEditions", totalEditions);
    dto.append("IsAlreadySigned", alreadySigned);
    dto.append("typeId", type?.id?.id);
    dto.append("dateCreated", dateCreated);
    dto.append("OverlayAlignment", signatureAlignment);
    dto.append("SignatureColour", signatureColour);
    dto.append("SignatureSVG", coloredSignatureData);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (title !== titleBackup) {
      axios
        .post(
          `api/image/${artistId}/ImageTitleValidation`,
          {
            artistId,
            imageTitle: title,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setSubmitPending(true);
          setTitleBackup(title);
          setCurrentOpen("");
          axios
            .post(`api/image/${imageDetails.id}`, dto, config)
            .then(() => {
              setSubmitPending(false);
              setAlert("Image Details Updated", "success");
            })
            .catch((err) => {
              setSubmitPending(false);
              console.log("saveImageInfoChange ", err);
              sendCriticalException(
                `submit edit info failed, endpoint: api/image/${imageDetails.id}`,
                `userId: ${artistId} err-info: ${err}`
              );
              setAlert("failed to update image details", "error");
            });
        })
        .catch(() => {
          setAlert("fail to pass image title validation", "error");
        });
    } else {
      setSubmitPending(true);
      setCurrentOpen("");
      axios
        .post(`api/image/${imageDetails.id}`, dto, config)
        .then(() => {
          setSubmitPending(false);
          setAlert("Image Details Updated", "success");
        })
        .catch((err) => {
          setSubmitPending(false);
          console.log("saveImageInfoChange ", err);
          sendCriticalException(
            `submit edit info failed, endpoint: api/image/${imageDetails.id}`,
            `userId: ${artistId} err-info: ${err}`
          );
          setAlert("failed to update image details", "error");
        });
    }
  };

  const callbackPerfectScrollbarColumn = useCallback(() => {
    if (isOnDesktop) {
      return (
        <PerfectScrollbar
          ref={(ref) => (scrollRef.current = ref)}
          containerRef={(ref) => (scrollContainerRef.current = ref)}
          component="aside"
          options={{ scrollingThreshold: 3000 }}
          style={{
            minWidth: isOnDesktop ? 480 : undefined,
            width: isOnDesktop ? 480 : "100%",
          }}
          className="mr-1-em mt-4-em relative"
        >
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={1}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Sign artwork
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(1)}
          >
            <Step1
              hasSignature={hasSignature}
              imageDetails={imageDetails}
              signatureData={imageDetails.signature}
              setHasSignature={setHasSignature} //remove
              setSignatureData={(value) =>
                handleImageDetails("signature", value)
              }
              setSignatureBackup={setSignatureBackup}
              signatureBackup={signatureBackup}
              setAlreadySigned={setAlreadySigned} //remove
            />
          </TypographyAccordion>
          <TypographyAccordion
            disabled={submitPending}
            currentOpen={currentOpen}
            index={2}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Editions
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(2)}
          >
            <Step2
              edition={imageDetails.totalEditions}
              handleImageDetails={handleImageDetails}
            />
          </TypographyAccordion>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={3}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Placement & colour
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(3)}
          >
            <Step3
              currentToggle={imageDetails.signatureAlignment}
              color={imageDetails.signatureColour}
              editionNumber={imageDetails.totalEditions}
              setSelectedSignatureColor={(value) =>
                handleImageDetails("signatureColour", value)
              }
              handleToggleChange={(value) =>
                handleImageDetails("signatureAlignment", value)
              }
              signatureData={imageDetails.signature}
              signaturePreviewImage={imageDetails.rawThumbnail}
              VibrantColors={VibrantColors}
              signatureColor={imageDetails.signatureColour}
            />
          </TypographyAccordion>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={4}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Artwork details
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(4)}
          >
            <Step4
              imageDetails={imageDetails}
              handleImageDetails={handleImageDetails}
              titleBackup={titleBackup}
            />
          </TypographyAccordion>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={5}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Discoverability
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(5)}
          >
            <Step5
              imageDetails={imageDetails}
              handleImageDetails={handleImageDetails}
              allImageTypes={allImageTypes}
            />
          </TypographyAccordion>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={6}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Fees & print settings
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(6)}
          >
            <Step6
              imageDetails={imageDetails}
              handleImageDetails={handleImageDetails}
              allProductLines={allProductLines}
            />
          </TypographyAccordion>
          <div className="flex full-width space-between">
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Primary}
              buttonStyle={{
                width: "47%",
              }}
              onClick={handlePublish}
              disabled={submitPending}
            >
              Save & Update
            </TypographyButton>
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              buttonStyle={{
                width: "47%",
              }}
              onClick={() => history.goBack()}
              disabled={submitPending}
            >
              Cancel
            </TypographyButton>
          </div>
        </PerfectScrollbar>
      );
    } else {
      return (
        <div
          style={
            {
              paddingTop: "1.2rem",
              paddingRight: "1rem",
              paddingLeft: "1rem",
            }
          }
        >
          <TypographyTitle
            component={TYPOGRAPHY_TITLE_COMPONENT.Mobile_H2_Bold}
            style={{ paddingBottom: "20px", textAlign: "center" }}
          >
            Edit your artwork
          </TypographyTitle>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={1}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Sign artwork
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(1)}
          >
            <div style={{ padding: "1em 0" }}>
              <Step1
                hasSignature={hasSignature}
                imageDetails={imageDetails}
                signatureData={imageDetails.signature}
                setHasSignature={setHasSignature} //remove
                setSignatureData={(value) =>
                  handleImageDetails("signature", value)
                }
                setSignatureBackup={setSignatureBackup}
                signatureBackup={signatureBackup}
                setAlreadySigned={setAlreadySigned} //remove
              />
            </div>
          </TypographyAccordion>
          <TypographyAccordion
            disabled={submitPending}
            currentOpen={currentOpen}
            index={2}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Editions
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(2)}
          >
            <div style={{ padding: "1em 0" }}>
              <Step2
                edition={imageDetails.totalEditions}
                handleImageDetails={handleImageDetails}
              />
            </div>
          </TypographyAccordion>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={3}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Placement & colour
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(3)}
          >
            <div style={{ padding: "1em 0" }}>
              <Step3
                currentToggle={imageDetails.signatureAlignment}
                color={imageDetails.signatureColour}
                editionNumber={imageDetails.totalEditions}
                setSelectedSignatureColor={(value) =>
                  handleImageDetails("signatureColour", value)
                }
                handleToggleChange={(value) =>
                  handleImageDetails("signatureAlignment", value)
                }
                signatureData={imageDetails.signature}
                signaturePreviewImage={imageDetails.rawThumbnail}
                VibrantColors={VibrantColors}
                signatureColor={imageDetails.signatureColour}
              />
            </div>
          </TypographyAccordion>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={4}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Artwork details
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(4)}
          >
            <div style={{ padding: "1em 0" }}>
              <Step4
                imageDetails={imageDetails}
                handleImageDetails={handleImageDetails}
                titleBackup={titleBackup}
              />
            </div>
          </TypographyAccordion>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={5}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Discoverability
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(5)}
          >
            <div style={{ padding: "1em 0" }}>
              <Step5
                imageDetails={imageDetails}
                handleImageDetails={handleImageDetails}
                allImageTypes={allImageTypes}
              />
            </div>
          </TypographyAccordion>
          <TypographyAccordion
            currentOpen={currentOpen}
            disabled={submitPending}
            index={6}
            accordionTitle={
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
                Fees & print settings
              </TypographyBody>
            }
            onheadingClick={() => handleSwitchStep(6)}
          >
            <div style={{ padding: "1em 0" }}>
              <Step6
                imageDetails={imageDetails}
                handleImageDetails={handleImageDetails}
                allProductLines={allProductLines}
              />
            </div>
          </TypographyAccordion>
          <div
            className="flex full-width space-between"
            style={{
              paddingRight: "10px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Primary}
              buttonStyle={{
                width: "47%",
              }}
              onClick={handlePublish}
              disabled={submitPending}
            >
              Save & Update
            </TypographyButton>
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              buttonStyle={{
                width: "47%",
              }}
              onClick={() => history.goBack()}
              disabled={submitPending}
            >
              Cancel
            </TypographyButton>
          </div>
        </div>
      );
    }
  }, [
    VibrantColors,
    isOnDesktop,
    hasSignature,
    imageDetails,
    currentOpen,
    allProductLines,
    titleBackup,
    submitPending,
    allImageTypes,
    handleImageDetails,
  ]);

  const renderImagePreviews = useCallback(
    () => (
      <ImagePreview
        currentToggle={imageDetails.signatureAlignment}
        rawThumbnail={imageDetails.rawThumbnail}
        imageSignature={imageDetails.signature}
        editionNum={imageDetails.totalEditions}
        miTotSold={imageDetails.miTotSold}
        setVibrantColors={setVibrantColors}
        submitPending={submitPending}
        setShowDeleteConfirmation={setShowDeleteConfirmation}
        selectedSignatureColor={imageDetails.signatureColour}
      />
    ),
    [
      imageDetails.rawThumbnail,
      submitPending,
      imageDetails.signature,
      imageDetails.totalEditions,
      imageDetails.miTotSold,
      imageDetails.signatureAlignment,
      imageDetails.signatureColour
    ],
  )

  return (
    <div
      className="flex flex-grow-1"
      style={{
        flexDirection: isOnDesktop ? "row" : "column-reverse",
        justifyContent: "center",
        height: isOnDesktop && "calc(100vH - 4em)",
        marginTop: !isOnDesktop && "4em"
      }}
    >
      <DeleteImageConfirmationModal
        open={showDeleteConfirmation}
        cancelDeleteConfirmation={() => {
          setShowDeleteConfirmation(false);
        }}
        doDeleteImage={() => {
          deletePhotoConfirm();
        }}
        thumbnail={imageDetails ? imageDetails.rawThumbnail : ""}
      />
      <Prompt message={handleExitConfirmationModal} when={true} />
      <ConfirmAlert
        handleCloseModalAndExitAlert={handleCloseModalAndExitAlert}
        open={showExitConfirmationModal}
        onClose={() => {}}
      />
      {renderImagePreviews()}
      {callbackPerfectScrollbarColumn()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    account: state.accountReducer,
    isUserLoading: isUserLoading(state),
    auth: state.authReducer,
    userId: userId(state),
  };
};

export default connect(mapStateToProps, {
  setAlert,
  handleArtistPromotionModal,
})(Upload);
