import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles, withStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AuthorizeService from "../../../auth/AuthorizeService";
import { ICON_SIZE_DEFAULT } from "../../../helpers/constants";
import { getValueIfKeyExists } from "../../../helpers/utils";
import { setAlert } from "../../../actions/alert";
import { updateUserDetails } from "../../../actions/account";
import "react-phone-input-2/lib/style.css";
import {
  TypographyBody, 
  TYPOGRAPHY_BODY_SIZE, 
  colors,
  TypographyButton, 
  TypographyTextInput, 
  TYPOGRAPHY_BUTTON_TYPE
} from "@pikcha/typography";
import { Accordion, AccordionSummary } from "@material-ui/core";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    padding: 0,
  },
  contentMargin: {
    backgroundColor: 'white',
    border: '0',
  },
  accordionRoot: {
    boxShadow: 'none',
    backgroundColor: colors.Neutral.grey20,
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);

const PersonalDetails = ({ account, setAlert, updateUserDetails }) => {
  const { palette, typography } = useTheme();

  let emailConfirmed = false;
  if (account && account.user) {
    emailConfirmed = account.user.emailConfirmed;
  }

  const isUserAnArtist =
    getValueIfKeyExists(account, "user.roles") &&
    account.user.roles.includes("Artist");
  let displayEmailConfirmation = false;
  if (isUserAnArtist) {
    if (!emailConfirmed) {
      displayEmailConfirmation = true;
    }
  }

  let userType;
  let allowNameChange = true;
  if (account && account.user) {
    userType = account.user.userType;
    allowNameChange = account.user.totSold == 0 ? true : false;
  }
  let allowEmailChange = false;
  if (userType && userType.toLowerCase() === "email") {
    allowEmailChange = true;
  }

  const [isEditing, setIsEditing] = useState(false);
  const [currentOpen, setCurrentOpen] = useState("Personal details");
  const [firstName, setFirstName] = useState(account.user.fName);
  const [lastName, setLastName] = useState(account.user.lName);
  const [nickName, setNickName] = useState(account.user.nickName);
  const [email, setEmail] = useState(account.user.email);
  const [phoneNumber, setPhoneNumber] = useState(account.user.phoneNumber);
  const classes = useStyles();

  const onPersonalDetailsExpansion = () => {
    if(currentOpen){
      setCurrentOpen("")
    } else {
      setCurrentOpen("Personal details");
    }
  };

  const onBeginEditing = () => {
    setIsEditing(true);
  };

  const onCancelEditing = () => {
    setNickName(account.user.nickName);
    setFirstName(account.user.fName);
    setLastName(account.user.lName);
    setEmail(account.user.email);
    setPhoneNumber(account.user.phoneNumber);

    setIsEditing(false);
  };

  const onSaveEditing = () => {
    if (firstName === "" || lastName === "" || nickName == "" || email === "") {
      firstName === "" && setAlert("Please fill in your first name", "error");
      lastName === "" && setAlert("Please fill in your last name", "error");
      email === "" && setAlert("Please fill in your email", "error");
      nickName === "" && setAlert("Please fill in your account name", "error");
    } else {
      updateUserDetails({
        links: {
          FacebookUrl:
            account.user.links && account.user.links.FacebookUrl
              ? account.user.links.FacebookUrl
              : "",
          InstagramUrl:
            account.user.links && account.user.links.InstagramUrl
              ? account.user.links.InstagramUrl
              : "",
          TwitterUrl:
            account.user.links && account.user.links.TwitterUrl
              ? account.user.links.TwitterUrl
              : "",
        },
        bio: account.user.bio,
        shipAddr1: account.user.shipAddr1,
        shipAddr2: account.user.shipAddr2,
        shipCountry: account.user.shipCountry,
        shipState: account.user.shipState,
        shipCity: account.user.shipCity,
        shipPostal: account.user.shipPostal,
        dob: account.user.dob,
        userHandle: account.user.userHandle,
        preferredCurrency: account.user.preferredCurrency,
        nationality: account.user.nationality,
        nickName: nickName,
        fName: firstName,
        lName: lastName,
        email,
        phoneNumber,
      });
    }

    setIsEditing(false);
  };

  const onChange = ({ target }) => {
    let key = target.name;
    if (key === "nickName") {
      setNickName(target.value);
    }
    if (key === "firstName") {
      setFirstName(target.value);
    }
    if (key === "lastName") {
      setLastName(target.value);
    }
    if (key === "email") {
      setEmail(target.value);
    }
    if (key === "phoneNumber") {
      setPhoneNumber(target.value);
    }
  };
  const onPhoneNumberChange = (phone) => {
    setPhoneNumber(phone);
  };

  const onSendConfirmationEmail = () => {
    const doSendVerification = async () => {
      const token = await AuthorizeService.getAccessToken();
      axios
        .post(
          "api/Profile/sendemailverification",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setAlert("New verification email was sent", "success");
        });
    };
    doSendVerification();
  };

  const areFieldsDisabled = !isEditing;

  return (
    <>
      <Accordion 
        expanded={currentOpen === "Personal details"}
        className={classes.accordionRoot} 
        onChange={onPersonalDetailsExpansion}
        style={{
          marginBottom: "1em"
        }} 
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            Personal details
          </TypographyBody>
        </StyledAccordionSummary>
        <div className={classes.contentMargin}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: '2em 0',
            }}
          >
            <div
              style={{
                width: "49%",
                marginTop: '2em'
              }}
            >
              <TypographyTextInput
                disabled={areFieldsDisabled || !allowNameChange}
                label="Account Name"
                type="text"
                name="nickName"
                value={nickName}
                onChange={onChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                  marginBottom: '1em',
                  marginTop: '1em'
                }}
              >
                <TypographyTextInput
                  disabled={areFieldsDisabled || !allowNameChange}
                  label="First Name"
                  type="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={onChange}
                />
              </div>
              <div
                style={{
                  marginTop: '1em',
                  marginLeft: "1em",
                  width: "100%",
                }}
              >
                <TypographyTextInput
                  disabled={areFieldsDisabled || !allowNameChange}
                  label="Last Name"
                  type="lastName"
                  name="lastName"
                  value={lastName}
                  onChange={onChange}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <TypographyTextInput
                  disabled={areFieldsDisabled || !allowEmailChange}
                  label="Email Address"
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                />
              </div>
              <div
                style={{
                  marginLeft: "1em",
                  width: "100%",
                  marginTop: "-1em"
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                  style={{ lineHeight: '1em', marginBottom: '.25em' }}
                >
                  Phone Number
                </TypographyBody>
                <PhoneInput
                  preferredCountries={['au', 'uk', 'us', 'ca', 'nz', 'de', 'fr']}  // AUS, UK, USA, CAD, NZ, GER, FRA
                  preserveOrder={['preferredCountries']}
                  defaultMask={['... ... ... ... ..']}
                  alwaysDefaultMask="true"
                  placeholder=""
                  country="us"
                  disabled={areFieldsDisabled}
                  value={phoneNumber}
                  onChange={onPhoneNumberChange}
                  enableSearch="true"
                  dropdownStyle={{
                    width: "398px",
                  }}
                  searchStyle={{
                    width: "89%",
                  }}
                  inputStyle={{
                    width: "100%",
                    borderWidth: 1,
                    fontSize: '16px',
                    fontWeight: '400',
                    height: '40px',
                    border: '1px #DBE0E4 solid',
                    ...(areFieldsDisabled
                      ? {
                        cursor: "auto",
                        backgroundColor: "#F2F4F8",
                        borderColor: "#DBE0E4",
                        color: palette.text.disabled,
                      }
                      : {}),
                  }}
                  buttonStyle={{
                    borderWidth: 1,
                    height: '40px',
                    backgroundColor: "#FFF",
                    borderColor: "#DBE0E4",
                    ...(areFieldsDisabled
                      ? {
                        borderColor: "#DBE0E4",
                        backgroundColor: "#F2F4F8",
                      }
                      : {}),
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                {account.user.roles.includes("Artist") &&
                  displayEmailConfirmation && (
                    <div
                      style={{
                        color: palette.text.secondary,
                        fontWeight: typography.fontWeightMedium,
                        fontSize: typography.smallest,
                        display: "flex",
                      }}
                    >
                      <ErrorOutlineIcon
                        style={{
                          ...ICON_SIZE_DEFAULT,
                          color: palette.error.main,
                        }}
                      />
                      <div
                        style={{
                          marginLeft: "0.5em",
                        }}
                      >
                        <TypographyBody
                          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                        >
                          Email confirmation required
                        </TypographyBody>
                        <TypographyBody
                          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                          onClick={onSendConfirmationEmail}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          send confirmation link again
                        </TypographyBody>
                      </div>
                    </div>
                  )}
              </div>
              <div
                style={{
                  width: "100%",
                  marginLeft: "1em",
                }}
              />
            </div>

            {!isEditing && (
              <div
                style={{
                  display: "flex",
                  justifyContent: 'flex-end'
                }}
              >
                <TypographyButton
                  component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                  onClick={onBeginEditing}
                  textStyle={{
                    fontWeight: 600
                  }}
                >
                  Edit
                </TypographyButton>
              </div>
            )}
            {isEditing && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <TypographyButton
                    component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                    onClick={onCancelEditing}
                  >
                    Cancel
                  </TypographyButton>
                  <TypographyButton
                    component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                    onClick={onSaveEditing}
                    buttonStyle={{
                      marginLeft: "1em",
                    }}
                  >
                    Save
                  </TypographyButton>
                </div>
              </>
            )}
          </div>
        </div>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  setAlert,
  updateUserDetails
})(PersonalDetails);
