import React, {
  useEffect,
  useState,
} from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { MINIMUM_EARNINGS } from "../../helpers/constants";
import {
  PikchaCheckbox,
  PikchaSpacer,
  PriceField,
} from "../../components/baseComponents";
import { useTheme } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import { addDoubleQuotationToSize } from "../../helpers/utils";
import ArtworkPricesModal from "../../containers/modals/ArtworkPricesModal";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
} from "@pikcha/typography";

const Step6 = ({
  imageDetails,
  handleImageDetails,
  allProductLines
}) => {
  const { palette, shape, typography } = useTheme();
  const [priceAndProductRange, setPriceAndProductRange] = useState([]);
  const [selectedProductLines, setSelectedProductLines] = useState({
    "Paper": false,
    "Canvas": false,
  });
  const [enterPrice, setEnterPrice] = useState(false);
  const [viewArtworkPrices, setViewArtworkPrices] = useState(false);

  useEffect(() => {
    if(imageDetails.productList?.length === 2){
      setSelectedProductLines({
        "Paper": true,
        "Canvas": true,
      })
      return
    }
    imageDetails.productList?.map((product) => {
      if(product.productType?.type === "Canvas"){
        setSelectedProductLines({
          Paper: false,
          Canvas: true
        })
        return
      }
      if(product.productType?.type === "Paper"){
        setSelectedProductLines({
          Canvas: false,
          Paper: true
        })
        return
      }
    })
  }, [imageDetails.productList])

  useEffect(() => {
    const imgAspectRatio = `${imageDetails.aspRatioX}:${imageDetails.aspRatioY}`;
    if (imageDetails.setPrice && imgAspectRatio) {
      axios
        .get(`/api/Image/${imgAspectRatio}/${parseFloat(imageDetails.setPrice)
          .toFixed(1)}/ProductPriceRange`).then((res) => {
            setPriceAndProductRange(res.data);
          });
    } else if (!imageDetails.setPrice && imgAspectRatio) {
      axios
        .get(`/api/Image/${imgAspectRatio}/3.0/ProductPriceRange`)
        .then((res) => {
          setPriceAndProductRange(res.data);
        });
    }
  }, [imageDetails.setPrice, imageDetails.aspRatioX, imageDetails.aspRatioY]);

  const _handleAddPrice = () => {
    if (imageDetails.setPrice) {
      handleImageDetails("setPrice", parseInt(imageDetails.setPrice) + 1);
    } else {
      handleImageDetails("setPrice", `${MINIMUM_EARNINGS}`);
    }
  };

  const _handleMinusPrice = () => {
    if (parseInt(imageDetails.setPrice) > MINIMUM_EARNINGS) {
      handleImageDetails("setPrice", parseInt(imageDetails.setPrice) - 1);
    }
  };

  const handleChecked = (item, checked) => {
    const { material } = item;
    const selectedProductLine = allProductLines.filter(o => o.label === material)[0];

    if (checked) {
      handleImageDetails(
        "productList", 
        [...imageDetails.productList, {
          productType: {
            id: selectedProductLine.value.id,
            type: selectedProductLine.label
          }
        }]);
    } else {
      const newArray = imageDetails.productList.filter(e => e.productType.type !== selectedProductLine.label);
      handleImageDetails("productList", newArray);
    }

    setSelectedProductLines({ ...selectedProductLines, [material]: checked });
  };

  return (
    <>
      <ArtworkPricesModal
        open={viewArtworkPrices}
        onClose={() => {
          setViewArtworkPrices(false);
        }}
        aspectRatio={`${imageDetails.aspRatioX},${imageDetails.aspRatioY}`}
        setPrice={imageDetails.setPrice.toString()}
      />
      <div style={{ display: 'flex' }} container spacing={2}>
        <div>
          <PriceField
            required
            className="mr-1-em"
            onValueChange={(values) =>
              handleImageDetails("setPrice", values.value)
            }
            value={imageDetails.setPrice}
            disabled={imageDetails.miTotSold > 0 ? true : false}
          />
        </div>
        <div>
          <div
            className="flex"
            style={{
              marginTop: "2em",
            }}
          >
            <TypographyButton
              textStyle={{ fontWeight: '700' }}
              buttonStyle={{ 
                maxWidth: '44px', 
                height: '44px', 
                marginRight: '0.5em',
                padding: 10
              }}
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              onClick={_handleAddPrice}
              icon={<AddRoundedIcon />}
            />
            <TypographyButton
              buttonStyle={{ 
                maxWidth: '44px', 
                height: '44px',
                padding: 10 
              }}
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              onClick={_handleMinusPrice}
              icon={<RemoveRoundedIcon />}
            />
          </div>
        </div>
      </div>
      <Grid style={{ marginTop: 40 }} container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: "flex", width: "100%" }}>
            {priceAndProductRange.map((item) => (
              <div
                style={{
                  width: "50%",
                }}
              >
                <div
                  style={{
                    fontSize: typography.smallest,
                    color: palette.text.primary,
                  }}
                >
                  <div
                    className="flex"
                    style={{
                      justifyContent: "flex-start",
                      marginLeft: 11
                    }}
                  >
                    <PikchaCheckbox
                      checked={selectedProductLines[item.material]}
                      label={
                        <TypographyBody
                          size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                        >
                          {item.material}&nbsp;Products
                        </TypographyBody>
                      }
                      onChange={({ target: { checked } }) => handleChecked(
                        item,
                        checked,
                      )}
                    />
                  </div>
                </div>
                <TypographyBody
                  style={{
                    marginLeft: "1.75em"
                  }}
                >
                  <div>
                    {addDoubleQuotationToSize(item.minSize)}&nbsp;-&nbsp;{addDoubleQuotationToSize(
                      item.maxSize)}
                  </div>
                  <div>
                    ${item.minPrice}-${item.maxPrice}
                  </div>
                </TypographyBody>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
      <div
        className="flex-center pointer"
        onClick={() => {
          setViewArtworkPrices(true);
        }}
        style={{
          marginTop: "1em",
          borderRadius: shape.borderRadius,
          background: enterPrice ? "rgba(0, 0, 238, 0.05)" : null,
        }}
        onMouseEnter={() => {
          setEnterPrice(true);
        }}
        onMouseLeave={() => {
          setEnterPrice(false);
        }}
      >
        <span
          className="material-icons"
          style={{
            fontSize: 16,
            color: palette.link.main,
            marginRight: 3,
          }}
        >
          open_in_new
        </span>
        <span
          style={{
            fontWeight: typography.fontWeightSemiMedium,
            fontSize: typography.small,
            color: "#0000ee",
          }}
        >
          View artwork prices
        </span>
      </div>
      <PikchaSpacer size={4} />
    </>
  )
}

export default Step6