import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";

import { PRIMARY_LINEAR_GRADIENT } from "../../../helpers/constants";

/**
 * Button including image & text.
 *
 * @author [Nick Dam]
 */
export const PikchaImageButton = withStyles(
  ({ typography, spacing, palette, shape }) => ({
    root: ({
      action = "primary",
      iconButton,
      noHoverEffect,
      active,
      noBorder,
      fullWidth,
      small,
    }) => ({
      color:
        action === "primary"
          ? palette.common.white
          : action === "secondary"
          ? active
            ? palette.primary.main
            : palette.grey.dark
          : action === "tertiary"
          ? palette.primary.main
          : null,
      borderColor:
        action === "primary" || action === "tertiary"
          ? palette.primary.main
          : active
          ? palette.primary.main
          : palette.grey.mediumDark,
      //border: action === "secondary" ? palette.grey.mediumDark : null,
      minHeight: small ? spacing(5) : null,
      width: iconButton ? spacing(4) : null,
      height: iconButton ? spacing(4) : null,
      fontSize: typography.standard,
      borderRadius: shape.borderRadius,
      boxShadow: "none",
      padding: 0,
      justifyContent: "space-between",
      fontWeight:
        small || noBorder
          ? typography.fontWeightMedium
          : typography.fontWeightBold,
      lineHeight: small ? 1.34 : fullWidth ? 1.75 : 1.5,
      //margin: 'auto',
      textTransform: "capitalize",
      textDecoration: "none",
      minHeight: "52px",
      maxHeight: "52px",
      minWidth: "165px",
      maxWidth: "165px",
      background:
        action === "primary" ? PRIMARY_LINEAR_GRADIENT : "transparent",
      transition: `${
        action === "primary" ? "background" : "background-color"
      } 0.1s ease-in-out`,
      borderWidth: !noBorder ? 2 : null,
      //borderWidth: noBorder
      //  ? 0
      //  : action === "tertiary" || action === "secondary"
      //  ? 2
      //  : 1,
      borderStyle: "solid",
      "& > .MuiButton-label":
        action !== "tertiary"
          ? {
              background: "transparent",
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color":
                action === "primary"
                  ? palette.common.white
                  : action === "secondary" && active
                  ? palette.primary.main
                  : palette.grey.dark,
            }
          : {
              // Webkit attributes are necessary for the gradient text effect.
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color":
                action === "secondary" && active
                  ? palette.primary.main
                  : palette.grey.dark,
              background: PRIMARY_LINEAR_GRADIENT,
            },
      "&:hover": noHoverEffect
        ? {
            boxShadow: "none",
          }
        : {
            transition: `${
              action === "primary" ? "background" : "background-color"
            } 0.15s ease-in-out`,
            boxShadow: "none",
            background: `${
              action === "primary"
                ? "linear-gradient(45deg, #6200ea 20.83%, #7c4dff 76.04%)"
                : action === "secondary" || action === "tertiary"
                ? "transparent"
                : null
            }`,
            backgroundColor: `${
              action === "secondary"
                ? palette.grey.lightest
                : "rgba(215, 183, 253, 0.25)"
            }`,
          },
    }),
    label: ({ minWidth }) => ({
      minWidth: minWidth ? minWidth : undefined,
    }),
  })
)(
  ({
    children,
    action = "primary",
    small = false,
    fullWidth = false,
    noBorder = false,
    to = null,
    image = "",
    ...rest
  }) => {
    if (
      action !== "primary" &&
      action !== "secondary" &&
      action !== "tertiary"
    ) {
      throw new Error(
        'The `action` prop must have a value of "primary", "secondary", or "tertiary"'
      );
    }

    return (
      <Fragment>
        {to ? (
          <Link
            to={to}
            className={`no-underline ${fullWidth ? "full-width" : ""}`}
          >
            <Button fullWidth={fullWidth} {...rest}>
              <img src={image} />
              {children}
            </Button>
          </Link>
        ) : (
          <Button fullWidth={fullWidth} {...rest}>
            <div className={"imageButton"}>
              <img
                src={image}
                height={"100%"}
                style={{
                  maxHeight: 65,
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  paddingTop: 2,
                  paddingBottom: 2,
                  maxWidth: 60,
                }}
              />
              {children}
            </div>
          </Button>
        )}
      </Fragment>
    );
  }
);

export const PikchaPrimarySmallButton = withStyles(({ shape, typography }) => ({
  root: {
    color: "#FFF !important",
    border: "1px solid",
    padding: "14px 18px",
    fontSize: typography.smallest,
    borderRadius: shape.borderRadius,
    boxShadow: "none",
    lineHeight: 1,
    marginRight: 4,
    textTransform: "none",
    textDecoration: "none",
    background: PRIMARY_LINEAR_GRADIENT,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#A700FF",
    },
  },
}))(({ small, children, ...rest }) => <Button {...rest}>{children}</Button>);
