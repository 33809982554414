import React, {
  cloneElement, useLayoutEffect, useRef, useState,
} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useTheme } from '@material-ui/core';
import { PikchaAccordionOverlay } from './PikchaAccordionOverlay';

import './PikchaAccordion.css';

export const PikchaAccordion = ({
  children,
  step,
  title,
  disabled,
  activeStep,
  setActiveStep,
  forceCollapse,
  accordianSummaryStyles,
  accordianTitleStyles,
  routePrefix = '/',
  smallTitle = false,
  expanded,
  joyrideWasTriggered,
  showJoyride,
  accordianStyles,
  iconButtonStyles,
  ...rest
}) => {
  const _expanded = step === activeStep || expanded;
  const accordionRef = useRef(null);
  const { palette, shape, typography } = useTheme();
  const [listenForChanges, setListenForChanges] = useState(false);
  const [isStepComplete, setIsStepComplete] = useState(false);

  useLayoutEffect(() => {
    if (isStepComplete) {
      return;
    }

    if (step === 2 || step === 3) {
      if (!_expanded && listenForChanges) {
        setListenForChanges(false);
        if (!joyrideWasTriggered) {
          setIsStepComplete(true);
        }

        return;
      }

      if (_expanded && !isStepComplete) {
        setListenForChanges(true);

        return;
      }
    }

    if ((step === 1 && !_expanded) || (step === 1 && expanded)) {
      setIsStepComplete(true);
    }
  }, [step, isStepComplete, listenForChanges, activeStep, _expanded, expanded, joyrideWasTriggered]);

  if (step > 3) {
    return null;
  }

  const _title = title.replace(/\s/g, '-').toLowerCase();

  return (
    <Accordion
      ref={accordionRef}
      id={_title}
      style={{
        borderRadius: shape.borderRadius,
        backgroundColor: palette.common.white,
        boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.15)',
        position: 'relative',
        padding: '0.5em',
        margin: '1em',
        ...accordianStyles
      }}
      expanded={listenForChanges || isStepComplete || step === 1}
      disabled={false}
      TransitionProps={{
        // N.b. This is a somewhat hacky workaround to handle DOM layout
        // issues when joyride is active. Ideally, we would like a
        // transition time of ~1000 ms for smooth UX. However, we override
        // this for joyride to provide enough time for DOM re-painting.
        timeout: joyrideWasTriggered ? 1 : 1000,
      }}
      {...rest}
    >
      <AccordionSummary
        className="relative"
        expandIcon={undefined}
        style={{ 
          cursor: 'default',
          ...accordianSummaryStyles,
        }}
        aria-controls="panel2a-content"
      >
        <PikchaAccordionOverlay
          shouldRender={((!isStepComplete && !_expanded)
            || (isStepComplete && step !== activeStep)) && !showJoyride && !joyrideWasTriggered && !forceCollapse}
          onClick={!isStepComplete ? undefined : () => setActiveStep(step)}
          title={`${routePrefix}#${_title}`}
          iconButtonStyles = {iconButtonStyles}
        />
        <p
          style={{
            marginTop: !smallTitle ? '1.5em' : undefined,
            marginBottom: !smallTitle ? '1.5em' : undefined,
            color: palette.text.primary,
            fontSize: !smallTitle ? typography.large : typography.medium,
            fontWeight: typography.fontWeightMedium,
            ...accordianTitleStyles
          }}
        >
          {title}
        </p>
      </AccordionSummary>
      <AccordionDetails className="relative">
        <PikchaAccordionOverlay
          shouldRender={isStepComplete && step !== activeStep && !showJoyride && !joyrideWasTriggered && !forceCollapse}
        />
        {!forceCollapse ? (
          cloneElement(children, {
            key: step,
            isActive: step === activeStep,
          })) : (
          null
        )}
      </AccordionDetails>
    </Accordion>
  );
};
