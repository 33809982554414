import React, { useEffect, useState, } from "react";
import { connect } from "react-redux";
import { CardElement } from "@stripe/react-stripe-js";
import { useTheme } from "@material-ui/core/styles";
import Discounts from "../../components/checkoutComponents/Discounts";
import visaIcon from "../../assets/images/visa_1.svg";
import masterIcon from "../../assets/images/mastercard_1.svg";
import amexIcon from "../../assets/images/amex_1.svg";
import unionPayIcon from "../../assets/images/unionpay_1.svg";
import dinersIcon from "../../assets/images/diners_1.svg";
import discoverIcon from "../../assets/images/discover_1.svg";
import jcbIcon from "../../assets/images/jcb_1.svg";
import { PikchaLoader } from "../../components/baseComponents";
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE
} from "@pikcha/typography"
import { useMediaQuery } from "react-responsive";

const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: "solid",
  style: {
    root: {
      backgroundColor: "#ffffff",
    },
    base: {
      paddingLeft: 4,
      borderRadius: 6,
      border: "1px solid #bdbdbd",
      backgroundColor: "white",
      iconColor: "#3700B3",
      color: "grey",
      fontWeight: 400,
      fontFamily:
        "'Montserrat',-apple-system,BlinkMacSystemFont,Segoe UI," +
        "Noto Sans,Ubuntu,Cantarell," +
        " Helvetica Neue,Arial,sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#212121",
      },
      "::placeholder": {
        color: "#E0E0E0",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
  },
};

const Payment = ({
  handlePaymentSubmission,
  onCardFieldChange,
  cardProcessing,
  cardError,
  stripe,
  elements,
  discount,
  onDiscountChange,
  handleDiscountCode,
  pendingDiscountCode,
  shoppingCart
}) => {
  const { palette, typography } = useTheme();
  const isOnMobile = useMediaQuery({ maxWidth: 900 });
  const [showPayment, setShowPayment] = useState(false);
  useEffect(() => {
    setShowPayment(false);
    setTimeout(function () {
      setShowPayment(true);
    }, 500);
  }, [elements]);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "start",
        }}
      >
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
        >
          Payment
        </TypographyBody>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <img src={visaIcon} width={26} />
          <img
            style={{
              marginLeft: 4,
            }}
            src={masterIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={amexIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={unionPayIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={dinersIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={discoverIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={jcbIcon}
            width={26}
          />
        </div>
      </div>

      <form
        style={{
          animation: "fade 200ms ease-out",
          marginTop: "1em",
          marginLeft: isOnMobile ? "-1em" : undefined,
          marginRight: isOnMobile ? "-1em" : undefined,
        }}
        onSubmit={(event) => handlePaymentSubmission(event, stripe, elements)}
      >
        <fieldset
          style={{
            borderRadius: 6,
            borderColor: "transparent",
          }}
          className={isOnMobile && "p-5"}
        >
          <label
            style={{
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightBold,
            }}
          >
            Credit or debit card
          </label>
          <div
            style={{
              border: '2px solid #DBE0E4',
              borderRadius: 6,
              paddingLeft: 4,
              marginTop: '4px'
            }}
          >
            {showPayment && <CardElement options={CARD_OPTIONS} onChange={onCardFieldChange} />}
          </div>

          <div
            style={{
              display: !isOnMobile && 'flex'
            }}
          >
            <Discounts
              discount={discount}
              onDiscountChange={onDiscountChange}
              handleDiscountCode={handleDiscountCode}
              pendingDiscountCode={pendingDiscountCode}
              shoppingCart = {shoppingCart}
            />
            <div style={{ display: 'flex', alignItems: 'end', flexGrow: 1, justifyContent: 'end' }}>
              <TypographyButton
                component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                buttonStyle={{ width: isOnMobile && '100%', marginTop: isOnMobile && '1em' }}
                type="submit"
                disabled={cardProcessing || !stripe}
              >
                {cardProcessing ? (
                  "Processing..."
                ) : (
                  <span>Pay Now&nbsp;</span>
                )}
              </TypographyButton>
            </div>
          </div>
        </fieldset>
        {pendingDiscountCode && (
          <div className="flex" style={{ marginLeft: 15, marginTop: 20 }}>
            <PikchaLoader width={null} />
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{
                marginTop: "-14px",
                marginLeft: 8,
                display: 'block'
              }}
            >
              Searching For Discount
            </TypographyBody>
          </div>
        )}
        {cardError && (
          <div
            style={{
              color: "red",
              display: "flex",
              justifyContent: "center",
              padding: "0 15px",
              fontSize: "13px",
              marginTop: "0px",
              width: "100%",
              transform: "translateY(-15px)",
              opacity: "0",
              animation: "fade 150ms ease-out",
              animationDelay: "50ms",
              animationFillMode: "forwards",
              willChange: "opacity, transform",
            }}
            role="alert"
          >
            <svg
              style={{
                marginRight: "10px",
              }}
              width="16"
              height="16"
              viewBox="0 0 17 17"
            >
              <path
                fill="#FFF"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
              />
              <path
                fill="#6772e5"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
              />
            </svg>
            {cardError}
          </div>
        )}
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCartReducer,
});
export default connect(mapStateToProps, {})(Payment);
