import React from 'react'

export default () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 5,
        visibility: "visible",
        backgroundColor: "rgba(255, 255, 255, .8)"
      }}
    />
  )
}
