import {
  GET_USER_IP, GET_USER_LOCATION
} from "../actions/types";

const initialState = {
  user_ip: '0.0.0.0',
  user_location: '',
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_USER_IP:
      return {
        ...state,
        user_ip: payload,
      };
    case GET_USER_LOCATION:
      return {
        ...state,
        user_location: payload,
      };
    default:
      return state;
  }
}
