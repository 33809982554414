import {
  HANDLE_CROPPER_RESET, 
  HANDLE_CROPPER_STATE, 
  GET_CROPPER_SRC, 
  GET_ORG_SRC, 
  GET_ASPECTRATIO, 
  GET_SELECTED_RATIO, 
  GET_ORG_IMG_DATA, 
  HANDLE_UPLOAD,
  GET_RELEASED_FORMS
} from "../actions/types";

const initialState = {
  height: 0,
  rotate: 0,
  scaleX: 0,
  scaleY: 0,
  width: 0,
  x: 0,
  y: 0,
  orgSrc: {},
  //img info sent to the backend
  aspectRatio: "4,5",
  selectedRatio: "1",
  src: "",
  //cropped img src data
  orgImgData: "",
  //img data shown in the cropper 
  uploadStep: 1,
  releasedForms: []
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_CROPPER_SRC:
      return {
        ...state,
        src: payload
      }
    case GET_ORG_SRC:
      return {
        ...state,
        orgSrc: payload
      }
    case GET_SELECTED_RATIO:
      return {
        ...state,
        selectedRatio: payload
      }
    case GET_ORG_IMG_DATA:
      return {
        ...state,
        orgImgData: payload
      }
    case GET_ASPECTRATIO:
      return {
        ...state,
        aspectRatio: payload
      }
    case HANDLE_UPLOAD: 
      return {
        ...state,
        uploadStep: payload
      }
    case HANDLE_CROPPER_STATE:
      const {
        height: _height,
        rotate: _rotate,
        scaleX: _scaleX,
        scaleY: _scaleY,
        width: _width,
        x: _x,
        y: _y
      } = payload;
      return {
        ...state,
        height: _height,
        rotate: _rotate,
        scaleX: _scaleX,
        scaleY: _scaleY,
        width: _width,
        x: _x,
        y: _y
      }
    case GET_RELEASED_FORMS:
      return {
        ...state,
        releasedForms: payload
      }
    default:
      return state;
  }
};
