import React from 'react';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import { PikchaIconButton } from '../index';
import { NavHashLink } from '../NavHashLink';

export const PikchaAccordionOverlay = ({
  onClick,
  shouldRender,
  title,
  iconButtonStyles
}) => {
  if (!shouldRender) {
    return null;
  }

  return (
    <div
      className="AccordionOverlay full-width flex flex-stretch"
      style={{
        position: 'absolute',
        zIndex: 9,
        background: 'rgba(255,255,255, 0.2)',
        top: 0,
        left: 0,
      }}
    >
      {onClick && (
        <div
          className="flex-end flex-grow-1"
          style={{ padding: '0 12px 0 0' }}
        >
          <NavHashLink onClick={onClick} smooth to={title}>
            <PikchaIconButton
              style={{ cursor: 'pointer', ...iconButtonStyles }}
              size="small"
              icon={<EditRoundedIcon alt="Edit Icon"/>}
            />
          </NavHashLink>
        </div>
      )}
    </div>
  );
}
