import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import isMobilePortrait from "../../Responsive/isMobilePortrait";
import isDesktop from "../../Responsive/isDesktop";
import isTablet from "../../Responsive/isTablet";
import isMobileLandscape from "../../Responsive/isMobileLandscape";
import isMobile from "../../Responsive/isMobile";
import {PikchaModalHeader} from "./PikchaModalHeader";
import PikchaModalFooter from "./PikchaModalFooter";
import {lockScrollbar, removeModalOverlay} from "../../../helpers/utils";
import {
  MODAL_ENTER_TRANSITION_DURATION,
  MODAL_EXIT_TRANSITION_DURATION,
} from "../../../helpers/constants";


const useStyles = makeStyles(({zIndex}) => ({
  root: ({isOnMobilePortrait}) => ({
    zIndex: "1400 !important",
    "& > div": {
      alignItems: !isOnMobilePortrait ? "center" : "flex-end",
    }
  }),
  paper: {},
}));

export const PikchaModalNoScroll = ({
                              children,
                              className = "",
                              height,
                              minHeight,
                              hideCloseButton,
                              maxWidth = "md",
                              onClose,

                              FooterActionProps,

                              darkHeader,
                              title,
                              hasCustomHeader,
                              FullscreenPhotoModal,

                              paperStyle = {},
                              PaperProps,
                              ScrollbarContainerProps,

                              footerSecondaryAction,
                              footerPrimaryAction,
                              fullWidth = true,
                              headerIcon = null,
                              headerZIndex,
                              ...rest
                            }) => {
  const isOnMobile = isMobile();
  const isOnMobilePortrait = isMobilePortrait();
  const isOnMobileLandscape = isMobileLandscape();
  
  const {root, paper} = useStyles({
    isOnMobilePortrait,
    isOnMobileLandscape
  });
  useEffect(() => {
    if (!rest.open) {
      removeModalOverlay();
    }
  }, [rest.open]);
  // const isOnTablet = isTablet();
  // const isOnDesktop = isDesktop();

  const handleBackgroundBlur = () => {
    removeModalOverlay();
  };

  const handleClose = () => {
    removeModalOverlay();
    onClose();
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      style={{
        overflow: 'hidden'
      }}
      //scroll="body"
      className={`${className} ${root}`}
      maxWidth={maxWidth}
      transitionDuration={{
        enter: MODAL_ENTER_TRANSITION_DURATION,
        exit: MODAL_EXIT_TRANSITION_DURATION,
      }}
      onEnter={lockScrollbar}
      onEntered={handleBackgroundBlur}
      onClose={handleClose}
      PaperProps={{
        className: `${paper} flex-column flex-stretch`,
        style: {
          maxHeight: isOnMobile || isOnMobileLandscape ? "100%" : null,
          height: height ? height : null,
          width: isOnMobile || isOnMobileLandscape ? "100%" : null,
          minHeight: isOnMobile || isOnMobileLandscape
            ? null
            : height
              ? null
              : minHeight
                ? minHeight
                : 600,
          margin: !isOnMobile && !isOnMobileLandscape ? null : 0,
          overflow: "hidden",
          ...paperStyle,
        },
        ...PaperProps,
      }}
      {...rest}
    >
      {!hasCustomHeader && (
        <PikchaModalHeader
          title={title}
          onClose={handleClose}
          darkHeader={!!darkHeader}
          hideCloseButton={!!hideCloseButton}
          headerIcon={headerIcon}
          headerZIndex={headerZIndex}
          FullscreenPhotoModal= {!!FullscreenPhotoModal}
        />
      )}
        {children}
      {FooterActionProps && (
        <PikchaModalFooter FooterActionProps={FooterActionProps} />
      )}
    </Dialog>
  );
};
