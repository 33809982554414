import axios from "axios";

import {
  FRIENDLY_URL_IMAGE_ERROR,
  FRIENDLY_URL_IMAGE_LOADED,
  FRIENDLY_URL_ALBUM_LOADED
} from "./types";
import { setLoading } from "./gallery";

export const getPhotoFriendlyUrl = (friendlyUrl) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading());
      const res = await axios.get(`api/image/friendly${friendlyUrl}`);

      dispatch({
        type: FRIENDLY_URL_IMAGE_LOADED,
        payload: [res.data],
      });
    } catch (err) {
      dispatch({
        type: FRIENDLY_URL_IMAGE_ERROR,
        payload: err.response,
      });
      window.location.href="/notFound"
    }
  };
};

export const getAlbumFriendlyUrl = (friendlyUrl) => {
  return async (dispatch) => {
    try{
      dispatch(setLoading());
      const res = await axios.get(`api/image/friendly${friendlyUrl}`);
      dispatch({
        type: FRIENDLY_URL_ALBUM_LOADED,
        payload: [res.data],
      });
    } catch (err) {
      dispatch({
        type: FRIENDLY_URL_IMAGE_ERROR,
        payload: err.response,
      });
      window.location.href="/notFound"
    }
  };
}