import React, { useRef, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import SignatureDisplayPreview from "./SignatureDisplayPreview";
import CustomColorPicker from "./CustomColorPicker";
import isMobile from "../../../components/Responsive/isMobile";
import isDesktop from "../../../components/Responsive/isDesktop";
import {
  TypographyToggleButtonGroup,
  TypographyToggleButton,
  TypographyBody,
  TYPOGRAPHY_BUTTON_TYPE,
  TYPOGRAPHY_BODY_SIZE,
} from "@pikcha/typography";

export default ({
  VibrantColors,
  currentToggle,
  signatureData,
  sendSignatureCall,
  color,
  svgSignature,
  signatureFont,
  handleToggleChange,
  onColorChange,
  uploadedImage,
  hiddenInputRef,
  rawImageFile,
  handleUpdateSignature,
  signatureColor,
  setSelectedSignatureColor,
  thiefColors,
  signaturePreviewImage,
  editionNumber,
  setActiveStep,
  ...rest
}) => {
  const { palette, shape } = useTheme();
  const signaturePreviewRef = useRef(null);
  const [chooseColorHover, setChooseColorHover] = useState(false);
  const [chooseColor, setChooseColor] = useState(false);
  const selectedSignature = {
    signature: null,
    isBlack: true,
  };
  const isOnMobile = isMobile();
  const isOnDesktop = isDesktop();
  const signatureType = selectedSignature.isBlack ? "orgSig" : "invSig";

  const onColorSelected = () => {
    setChooseColor(false);
  };

  const onHoverChooseColor = () => {
    setChooseColorHover(true);
  };

  const onHoverLeaveChooseColor = () => {
    setChooseColorHover(false);
  };

  const onCancelChoosingColor = () => {
    setChooseColor(false);
  };

  const onColorChanged = (color) => {
    setSelectedSignatureColor(color.hex);
  };

  return (
    <>
      <div
        className="flex full-width"
        style={
          isOnDesktop
            ? { display: "flex" }
            : { display: "flex", flexDirection: "column" }
        }
      >
        <div className="Signature-preview">
          <div
            ref={signaturePreviewRef}
            style={{
              backgroundImage: `url("${signaturePreviewImage}")`,
              backgroundColor: palette.grey.light,
              borderRadius: shape.borderRadius,
              // marginRight: "1em",
              padding: "0.5em",
              backgroundPosition: `bottom ${currentToggle}`,
              marginTop: "1em",
              marginBottom: "44px",
              height: "88px",
              width: "166px",
            }}
          >
            <SignatureDisplayPreview
              {...rest}
              signatureData={signatureData}
              // userSignatureSVG={userSignatureSVG}
              sendSignatureCall={sendSignatureCall}
              color={color}
              height={50}
              //alignInnerRight
              textSize={12}
              signature={svgSignature}
              signatureType={signatureType}
              signatureFont={signatureFont}
              editionNumber={editionNumber}
              style={{
                width: 150,
              }}
              alignInnerPosition={currentToggle}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - 5px)",
            paddingLeft: isOnMobile ? "0" : "2em",
          }}
        >
          <div
            className="flex-column"
            style={{
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                justifyContent: "flex-start",
                marginTop: 5,
                flexDirection: "column",
              }}
            >
              <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Small_Bold}>
                Label
              </TypographyBody>
              <TypographyToggleButtonGroup
                style={{
                  display: "flex",
                  width: "100%",
                  border: "2px solid rgb(238, 238, 238)",
                }}
              >
                <TypographyToggleButton
                  buttonStyle={{
                    width: "50%",
                    height: 32,
                    border: 0,
                  }}
                  component={
                    currentToggle === "left"
                      ? TYPOGRAPHY_BUTTON_TYPE.Primary
                      : TYPOGRAPHY_BUTTON_TYPE.Outline
                  }
                  onClick={() => {
                    handleToggleChange("left");
                  }}
                >
                  {isOnMobile ? "L" : "Left"}
                </TypographyToggleButton>
                <TypographyToggleButton
                  buttonStyle={{
                    width: "50%",
                    height: 32,
                    border: 0,
                  }}
                  component={
                    currentToggle === "right"
                      ? TYPOGRAPHY_BUTTON_TYPE.Primary
                      : TYPOGRAPHY_BUTTON_TYPE.Outline
                  }
                  onClick={() => {
                    handleToggleChange("right");
                  }}
                >
                  {isOnMobile ? "R" : "Right"}
                </TypographyToggleButton>
              </TypographyToggleButtonGroup>
            </div>
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: 5,
                }}
              >
                <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Small_Bold}>
                  Color
                </TypographyBody>
                <div className="full-width">
                  <div
                    onMouseEnter={onHoverChooseColor}
                    onMouseLeave={onHoverLeaveChooseColor}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      border: chooseColor
                        ? `2px solid ${palette.primary.main}`
                        : chooseColorHover
                        ? `2px solid ${palette.common.black}`
                        : `2px solid ${palette.grey.light}`,
                      borderRadius: shape.borderRadius,
                      backgroundColor: color || "#000000",
                      height: 32,
                      width: "100%",
                      marginRight: "0.25em",
                      marginBottom: "1em",
                    }}
                  />
                </div>
                <CustomColorPicker
                  disableAlpha
                  onCancelChoosingColor={onCancelChoosingColor}
                  onChange={onColorChanged}
                  onApply={onColorSelected}
                  color={signatureColor}
                  vibrantColors={VibrantColors}
                  uploadedImage={uploadedImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
