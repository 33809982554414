export const isImageSelectedForCreateAlbum = (albums, photo) => {
  let selectedImages = [];
  if (albums) {
    selectedImages = albums.selectedImages;
  }
  let isImageSelectedForCreateAlbum = false;
  if (photo && photo.id) {
    isImageSelectedForCreateAlbum = selectedImages.some(
      (selectedImageId) => selectedImageId.id === photo.id
    );
  }

  return isImageSelectedForCreateAlbum;
};

export const selectedImages = (state) => {
  let profile;
  let albums;
  if (state) {
    profile = state.profileReducer;
    albums = state.albumsReducer;
  }

  let selectedImages = [];
  if (albums) {
    selectedImages = albums.selectedImages;
  }

  return selectedImages;
};

export const selectedImagesDetailed = (state) => {
  let profile;
  let albums;
  if (state) {
    profile = state.profileReducer;
    albums = state.albumsReducer;
  }

  let selectedImages = [];
  if (albums) {
    selectedImages = albums.selectedImages;
  }

  let selectedImagesDetailed = [];
  if (profile) {
    selectedImagesDetailed = selectedImages.map((selectedImageId) => {
      const foundPhoto = profile.photos.find(
        ({ id }) => id === selectedImageId
      );
      return foundPhoto || { id: selectedImageId };
    });
  }

  return selectedImagesDetailed;
};
