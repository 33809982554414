import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  PikchaLoader,
} from "../../../components/baseComponents";
import {
  showCreateCollectionModal,
  showEditAlbumModal,
} from "../../../actions/modal";
import { modalTypes, modalType } from "../../../selectors/modal";
import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../../components/Responsive/isDesktopSD";
import isTablet from "../../../components/Responsive/isTablet";
import isMobile from "../../../components/Responsive/isMobile";
import CreateCollection from "../../modals/CreateCollection/CreateCollection";
import ViewingAlbum from "../../modals/ViewingAlbum/ViewingAlbum";
import AlbumItem from "./AlbumItem";
import { useHistory } from "react-router-dom";

const AlbumGallery = ({
  albums,
  getAlbums,
  resetAlbums,
  refreshAlbums,
  creatable,
  modalType,
  showCreateCollectionModal,
  showEditAlbumModal,
  isInSearchPage,
  isInRelatedAlbumPage,
  isInProfilePage,
  setCustomMargin,
}) => {
  const history = useHistory()
  const { palette, typography } = useTheme();
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();

  let albumItems;
  if (albums) {
    albumItems = albums.albums;
    if (albums.loading) {
      for (let i = 0; i < 3; i++) {
        albumItems = [
          ...albumItems,
          ...[
            {
              placeholderTile: true,
            },
          ],
        ];
      }
    }
  }
  if (!albums.loading && creatable) {
    albumItems = [
      ...[
        {
          creationTile: true,
        },
      ],
      ...albumItems,
    ];
  }

  const [creatingAlbum, setCreatingAlbum] = useState(false);
  const [editingAlbum, setEditingAlbum] = useState();
  const [viewingAlbum, setViewingAlbum] = useState();

  const [modalTypeCache, setModalTypeCache] = useState();

  useEffect(() => {
    if(isInProfilePage){
      getAlbums(albums.count, albums.start);
      return () => resetAlbums();
    }
  }, []);

  useEffect(() => {
    if (
      modalTypeCache &&
      modalTypeCache !== modalTypes.viewAlbum &&
      !modalType
    ) {
      refreshAlbums();
    }
    setModalTypeCache(modalType);
  }, [modalType]);

  const onCreateAlbum = () => {
    showCreateCollectionModal();
  };

  const onCreateAlbumClose = () => {
    setCreatingAlbum(false);
    setEditingAlbum(undefined);
    refreshAlbums();
  };
  const onViewAlbumClose = () => {
    setViewingAlbum(undefined);
  };
  const onEditAlbumFromViewing = () => {
    setEditingAlbum(viewingAlbum);
    setCreatingAlbum(true);
    setViewingAlbum(undefined);
  };

  const onAlbumClicked = (albumItem) => {
    const frinedlyURl = albumItem.friendlyUrl
    history.push(`/${frinedlyURl}`);
  };
  const onAlbumEditClicked = (albumItem) => {
    showEditAlbumModal(albumItem);
  };

  let tileWidth;
  if (isOnMobile) {
    tileWidth = "100%";
  } else if (isOnTablet) {
    tileWidth = "50%";
  } else {
    tileWidth = "33%";
  }

  const tileMargin = isOnDesktopHD
    ? "1.5em"
    : isOnDesktopSD
      ? "1.1em"
      : isOnTablet
        ? "1.1em"
        : "0.5em";

  const tileVerticalMargin = isOnDesktopHD
    ? "4em"
    : isOnDesktopSD
      ? "1.1em"
      : isOnTablet
        ? "1.1em"
        : "2em";

  const showBlankState = !albums.loading && albums.albums.length < 1;

  return (
    <>
      <InfiniteScroll
        dataLength={albums.albums.length}
        next={() => {
          if (albums.start > 1) {
            getAlbums(albums.count, albums.start)
          }
        }}
        hasMore={albums.hasMore}
        style={{
          display: isOnMobile ? "block" : "flex",
          flexWrap: "wrap",
          marginTop: setCustomMargin ? setCustomMargin : isOnMobile ? 20 : "4em",
          marginRight: `-${tileMargin}`,
        }}
        loader={<PikchaLoader />}
        endMessage={
          <>
            {!showBlankState && !isInRelatedAlbumPage && (
              <div
                style={{
                  width: "100%",
                }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: typography.small,
                    fontWeight: typography.fontWeightSemiMedium,
                    color: palette.text.secondary,
                  }}
                >
                  You've seen all the collections.
                </p>
              </div>
            )}
          </>
        }
      >
        {showBlankState && isInSearchPage && (
          <div
            style={{
              textAlign: "start",
            }}
          >
            <p
              style={{
                fontSize: typography.smallest,
                fontWeight: typography.fontWeightMedium,
                color: palette.text.primary,
              }}
            >
              We couldn’t find any results
            </p>
            <p
              style={{
                fontSize: typography.smallest,
                color: palette.text.secondary,
              }}
            >
              try searching for something else!
            </p>
          </div>
        )}

        {albumItems.map((albumItem, index) => {
          return (
            <div
              key={index}
              style={{
                width: !isOnMobile && tileWidth,
                maxWidth: isOnMobile && '750px',
              }}
            >
              <div
                style={{
                  marginRight: tileMargin,
                  marginBottom: setCustomMargin ? setCustomMargin : tileVerticalMargin,
                }}
              >
                <AlbumItem
                  key={index}
                  creatable={creatable}
                  albumItem={albumItem}
                  onCreateAlbum={onCreateAlbum}
                  onAlbumClicked={onAlbumClicked}
                  onAlbumEditClicked={onAlbumEditClicked}
                  showUserDetails={isInSearchPage ? true : false}
                />
              </div>
            </div>
          );
        })}
      </InfiniteScroll>
      {creatingAlbum && (
        <CreateCollection
          open={creatingAlbum}
          onClose={onCreateAlbumClose}
          albumItem={editingAlbum}
        />
      )}
      {viewingAlbum && (
        <ViewingAlbum
          open={!!viewingAlbum}
          onClose={onViewAlbumClose}
          albumItem={viewingAlbum}
          creatable={creatable}
          onEditAlbumFromViewing={onEditAlbumFromViewing}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  modalType: modalType(state),
});

export default connect(mapStateToProps, {
  showCreateCollectionModal,
  showEditAlbumModal,
})(AlbumGallery);
