import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { connect } from "react-redux";
import { addTimer, removeTimer, updateTimer } from "../actions/timer";
import {
  getShoppingCart,
  notifyQueueStatus,
  removeProductFromShoppingCart,
} from "../actions/shoppingCart";
import {
  updateNotificationType,
  updateNotificationItem,
  hideNotification,
  showNotification,
} from "../actions/notification";

const SignalR = ({
  id,
  addTimer,
  updateTimer,
  removeProductFromShoppingCart,
  getShoppingCart,
  hideNotification,
  shoppingCart,
  state,
  removeTimer,
  url,
  timers,
  notifyQueueStatus,
  updateNotificationType,
  updateNotificationItem,
  showNotification,
}) => {
  const [connection, setConnection] = useState({
    connectionState: "disconnected",
  });
  const [isSet, setIsSet] = useState(false);
  const [connectedTo, setConnectedTo] = useState();
  useEffect(() => {
    if (
      shoppingCart.shoppingCart.id &&
      connectedTo !== shoppingCart.shoppingCart.id
    ) {
      if (connection !== null && connection.baseUrl && connection.close) {
        connection.close();
      }
      let hasItems = false;
      shoppingCart.shoppingCart.items.forEach((item) => {
        if (!item.isExpired) {
          hasItems = true;
        }
      });
      if (hasItems) connect();
    }
    return () => {
      if (connection !== null && connection.baseUrl && connection.close) {
        connection.close();
      }
    };
  }, [shoppingCart, shoppingCart.shoppingCart, shoppingCart.shoppingCart.id]);
  useEffect(() => {
    if (
      connection &&
      connection.connectionState &&
      connection.connectionState === "Connected"
    ) {
      refreshCart();
    }
  }, [connection.connectionState, shoppingCart]);
  const connect = () => {
    let newConnection = new signalR.HubConnectionBuilder().withUrl(url).build();
    newConnection.on(shoppingCart.shoppingCart.id, function (queueInfo) {
      onNotifReceived(queueInfo);
    });
    newConnection
      .start()
      .then(function () {
        setConnection(newConnection);
        setConnectedTo(shoppingCart.shoppingCart.id);
        //console.log("Basket Connection Started");
        refreshCart();
      })
      .catch(function (err) {
        return console.error(err.toString());
      });
  };

  const refreshCart = () => {
    if (
      connection !== null &&
      shoppingCart.shoppingCart &&
      shoppingCart.shoppingCart.id &&
      !isSet
    ) {
      notifyQueueStatus(shoppingCart.shoppingCart.id);
      setIsSet(true);
    }
  };
  const onNotifReceived = (res) => {
    let timer = {
      minutes: Math.floor(res.secondsToExpire / 60),
      seconds: parseInt(
        res.secondsToExpire - Math.floor(res.secondsToExpire / 60) * 60,
      ),
    };

    if (res.secondsToExpire >= 1) {
      addTimer(
        {
          id: res.basketId,
          time: timer,
          data: res,
        },
        timers,
      );
    }

    getShoppingCart(res.basketId);

    if (res.secondsToExpire < 1) {
      getShoppingCart(res.basketId);
      hideNotification();
      updateNotificationType("cartItemNotification");
      updateNotificationItem({
        id: res.basketId,
        isExpiredItem: true,
      });
      showNotification();

      // if (window.location.pathname === "/checkout") {
      //   window.location.reload();
      // }
    }

    console.warn(res);
  };
  return <span />;
};
const mapStateToProps = (state) => ({
  timers: state.timerReducer,
  shoppingCart: state.shoppingCartReducer,
  state,
});

export default connect(mapStateToProps, {
  addTimer,
  updateTimer,
  removeProductFromShoppingCart,
  getShoppingCart,
  removeTimer,
  hideNotification,
  notifyQueueStatus,
  updateNotificationType,
  updateNotificationItem,
  showNotification,
})(SignalR);
