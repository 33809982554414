import {
  HANDLE_SAVED_IMAGE,
  RELATED_IMAGES_ERROR,
  RELATED_IMAGES_LOADED,
  RELATED_IMAGES_LOADING,
  RELATED_IMAGES_PHOTO_LOADING,
  RELATED_IMAGES_PHOTO_LOADING_ASPECT_RATIO,
  RELATED_IMAGES_PORTRAIT,
  RELATED_IMAGES_RESET
} from "../actions/types";
import {
  GALLERY_ITEMINPAGE_DEFAULT,
  GALLERY_PAGE_DEFAULT
} from "../helpers/constants";

const initialState = {
  photos: [],
  count: GALLERY_ITEMINPAGE_DEFAULT,
  start: GALLERY_PAGE_DEFAULT,
  photosLoading: true,
  photosError: null,
  hasMore: true,

  isPortrait: true,
  photoLoading: false,
  aspectRatio: "1,1"
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case HANDLE_SAVED_IMAGE: {
      const {imageId, saved: isSaved} = payload;
      const photos = state.photos.map((photo) => {
        const {id} = photo;
        if (id === imageId) {
          return {
            ...photo,
            ...{
              isSaved,
            },
          };
        }
        return photo;
      });
      return {
        ...state,
        ...{photos},
      };
    }
    case RELATED_IMAGES_LOADED:
      payload.forEach((d) => {
        const randomised = Math.random() * (30 - 15) + 15;
        d.height = randomised;
      });
      return {
        ...state,
        photos: [...state.photos, ...payload],
        photosLoading: false,
        start: state.start + 1,
        hasMore: payload.length >= state.count,
      };
    case RELATED_IMAGES_LOADING:
      return {
        ...state,
        photosLoading: true,
      };
    case RELATED_IMAGES_ERROR:
      //!payload.data === "You have reached the end." && console.error(payload);
      return {
        ...state,
        photosError: payload,
        loading: false,
        hasMore: false,
      };
    case RELATED_IMAGES_PORTRAIT:
      return {
        ...state,
        isPortrait: payload
      }
    case RELATED_IMAGES_RESET:
      return {
        ...state,
        photos: [],
        start: GALLERY_PAGE_DEFAULT,
        count: GALLERY_ITEMINPAGE_DEFAULT,
        photosLoading: true,
        photosError: null,
        hasMore: true,
      };
    case RELATED_IMAGES_PHOTO_LOADING:
      return {
        ...state,
        photoLoading: payload
      }
    case RELATED_IMAGES_PHOTO_LOADING_ASPECT_RATIO:
      return {
        ...state,
        aspectRatio: payload
      }
    default:
      return state;
  }
};
