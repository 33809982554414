import React from "react";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { colors } from "../../colors";
import { getColorKey } from "../../utils";

export enum TYPOGRAPHY_TITLE_COMPONENT {
  Desktop_H1_Regular = "desktop_h1_regular",
  Desktop_H1_Bold = "desktop_h1_bold",
  Desktop_H2_Regular = "desktop_h2_regular",
  Desktop_H2_Bold = "desktop_h2_bold",
  iPad_H1_Regular = "ipad_h1_regular",
  iPad_H1_Bold = "ipad_h1_bold",
  iPad_H2_Regular = "ipad_h2_regular",
  iPad_H2_Bold = "ipad_h2_bold",
  Mobile_H1_Regular = "mobile_h1_regular",
  Mobile_H1_Bold = "mobile_h1_bold",
  Mobile_H2_Regular = "mobile_h2_regular",
  Mobile_H2_Bold = "mobile_h2_bold",
}
const typographyTitleHashMap = new Map([
  [
    TYPOGRAPHY_TITLE_COMPONENT.Desktop_H1_Regular,
    { lineHeight: "112%", fontSize: "4em", letterSpacing: "0.15px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.Desktop_H1_Bold,
    { lineHeight: "112%", fontSize: "4em", letterSpacing: "-2.3px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.Desktop_H2_Regular,
    { lineHeight: "116%", fontSize: "3em", letterSpacing: "0.15px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.Desktop_H2_Bold,
    { lineHeight: "116%", fontSize: "3em", letterSpacing: "0.2px" },
  ],

  [
    TYPOGRAPHY_TITLE_COMPONENT.iPad_H1_Regular,
    { lineHeight: "114%", fontSize: "3.5em", letterSpacing: "0.15px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.iPad_H1_Bold,
    { lineHeight: "114%", fontSize: "3.5em", letterSpacing: "-2.3px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.iPad_H2_Regular,
    { lineHeight: "120%", fontSize: "2.5em", letterSpacing: "0.15px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.iPad_H2_Bold,
    { lineHeight: "120%", fontSize: "2.5em", letterSpacing: "0.2px" },
  ],

  [
    TYPOGRAPHY_TITLE_COMPONENT.Mobile_H1_Regular,
    { lineHeight: "100%", fontSize: "3em", letterSpacing: "0.15px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.Mobile_H1_Bold,
    { lineHeight: "100%", fontSize: "3em", letterSpacing: "-2.3px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.Mobile_H2_Regular,
    { lineHeight: "125%", fontSize: "2em", letterSpacing: "0.15px" },
  ],
  [
    TYPOGRAPHY_TITLE_COMPONENT.Mobile_H2_Bold,
    { lineHeight: "125%", fontSize: "2em", letterSpacing: "0.2px" },
  ],
]);

const TypographyTitle = ({
                           component = TYPOGRAPHY_TITLE_COMPONENT.Desktop_H1_Regular,
                           color = "neutral/ink90",
                           children,
                           style = {},
                           // Any additional props will be passed to the
                           // underlying material-ui component.
                           ...rest
                         }) => {
  const { typography } = useTheme();
  let colorKey1;
  let colorKey2;
  colorKey1 = getColorKey(color).colorKey1;
  colorKey2 = getColorKey(color).colorKey2;

  return (
    // @ts-ignore
    <Typography
      // @ts-ignore
      component={component.split("_")[1]}
      style={{
        ...(component.includes("bold") && {
          fontWeight: "bold",
        }),
        fontSize: `${typographyTitleHashMap.get(component).fontSize}`,
        fontFamily: typography.fontFamily,
        letterSpacing: typographyTitleHashMap.get(component).letterSpacing,
        lineHeight: typographyTitleHashMap.get(component).lineHeight,
        color: colors[colorKey1][colorKey2],
        ...style
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TypographyTitle;
