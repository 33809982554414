import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import {
  PikchaButton,
} from "../../components/baseComponents";
import { PikchaImageButton } from "../../components/baseComponents/buttons/PikchaImageButton";
import UserDetails from "../../components/UserDetails/UserDetails";
import isTablet from "../../components/Responsive/isTablet";
import isMobile from "../../components/Responsive/isMobile";
import isDesktop from "../../components/Responsive/isDesktop";
import Reservations from "./Reservations";
import "./CustomiseProduct.css";
import ReactPixel from "react-facebook-pixel";
import { FB_PIXEL_EVENT_TYPES } from "../../hooks/useFbPixelEvent/event-constants";
import ReactGA from "react-ga4";
import { getSavedUtmDetails, isProductionSite } from "../../helpers/utils";
import { 
  TypographySelector, 
  TypographyBody, 
  TypographyToggleButtonGroup, 
  TypographyToggleButton, 
  colors, 
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT 
} from "@pikcha/typography";
import { IntlCurrencyFormatter } from "../../components/NumberFormatters/IntlCurrencyFormatter";

const CustomiseProduct = ({
  style,
  dimensions,
  fields,
  setFields,
  postInitialization,
  JondoConfigMaterials,
  onFeatureChanged,
  onAddToCart,
  onChange,
  image,
  giveProductPrices,
  expectedProductNumber,
  reservations,
  onEditionSelected,  
  setImageLoading,
  account,
  shotsGenerated
}) => {
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  const isOnDesktop = isDesktop();
  const { palette, typography } = useTheme();
  const [frameDisabled, setFrameDisabled] = useState(false);
  const [borderDisabled, setBorderDisabled] = useState(false);
  const [borderAdded, setBorderAdded] = useState(true);
  const [colorHovering, setColorHovering] = useState({
    item: "",
    value: false
  });
  const [sizeType, setSizeType] = useState("Cm")
  const [gsmLabel, setgsmLabel] = useState("");
  const [frameAdded, setFrameAdded] = useState(true);

  useEffect(() => {
    onChange(fields);
  }, [fields]);

  useEffect(() => {
    getProductFeatures()
  }, [image.id])

  useEffect(() => {
    if(fields?.selectedMaterial?.id === "Gloss"){
      const materialList = fields?.selectedCanvas?.finish;
      const selectedItem = materialList.filter((item) => {
        return item.id === "Matte" && item.isFramed.toLowerCase() === frameAdded.toString()
      })
      const selecteSize = selectedItem[0].sizes.filter((item) => {
        return item.id === fields.selectedSize.id
      })
      console.log(selecteSize);
      const selectedFramed = selecteSize[0]?.frameColours?.filter((item) => {
        return item.id === fields.selectedFrame.id
      })
      console.log(selectedFramed);
      let selectedBorders;
      if(!selectedFramed){
        return
      }
      if(selectedFramed[0]?.borders?.length === 1){
        selectedBorders = selectedFramed[0]?.borders;
      } else {
        selectedBorders = selectedFramed[0]?.borders?.filter((item) => {
          return item?.hasBorder?.toLowerCase() === borderAdded.toString()
        })
      }
      const switchedAssetNum = selectedBorders && selectedBorders?.length > 0 && selectedBorders[0]?.assetNumber;
      setFields({
        ...fields,
        assetNumber: switchedAssetNum
      })
    }
  }, [fields.assetNumber, frameAdded, borderAdded]);

  useEffect(() => {
    setImageLoading(true)
  }, [fields.assetNumber])

  const init = (mat) => {
    populateDropdowns();
    postInitialization();
  };
  const userPreferencesReducer = useSelector(state => state.userPreferencesReducer);
  const [productPrices, setProductPrices] = useState([]);
  const wrapSelected = "Image";
  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if(fields?.selectedMaterial?.substrate === "HPR"){
      setgsmLabel("Hahnemühle Photo Rag");
      return
    }
    if(fields?.selectedMaterial?.substrate === "HPG"){
      setgsmLabel("Hahnemühle Photo Glossy");
      return
    }    
    if(fields?.selectedMaterial?.substrate === "FAP"){
      setgsmLabel("Ilford Fine Art Textured Silk");
      return
    }    
    if(fields?.selectedMaterial?.substrate === "SGP"){
      setgsmLabel("Citylite HQ200 Paper");
      return
    }
    if(fields?.selectedMaterial?.substrate === "HPL"){
      setgsmLabel("Hahnemühle photo lustre");
      return
    }
    setgsmLabel(fields?.selectedMaterial?.substrate);
  }, [fields?.selectedMaterial?.substrate])

  const changeMaterial = (mat, canvas) => {
    const allSizes = mat.sizes;
    if(allSizes.length === 0){
      return
    }

    const selectedSize  = allSizes[allSizes.length-1];

    const initialFrame = selectedSize.frameColours[0];
    let pickedBorder; 
    if(initialFrame.borders.length === 1) {
      const border = initialFrame.borders[0];
      setBorderDisabled(true);
      setBorderAdded(border.hasBorder==="TRUE" ? true : false);
      if(border.hasBorder==="TRUE"){
        pickedBorder = initialFrame.borders.filter((border) =>
          border.hasBorder.toLowerCase() === "true"
        )
      }else {
        pickedBorder = initialFrame.borders.filter((border) =>
          border.hasBorder.toLowerCase() === "false"
        )
      }
    } else {
      setBorderDisabled(false);
      pickedBorder = initialFrame.borders.filter((border) =>
        border.hasBorder.toLowerCase() === borderAdded.toString()
      )
    }

    setFields({
      ...fields,
      assetNumber: pickedBorder[0].assetNumber,
      selectedMaterial: mat, 
      allSizes: allSizes,
      selectedSize: selectedSize,
      selectedCanvas: canvas[0] || canvas, 
      wrap: frameAdded || (canvas[0] || canvas).id === "Paper" ?  "" : wrapSelected,
      selectedBorder: pickedBorder.length > 0 ? pickedBorder[0] : "",
      selectedFrame: selectedSize.frameColours[0],
      allBorders: selectedSize.frameColours[0].borders,
      allFrames: selectedSize.frameColours,
    });
  };

  const setFrameColor = (key) =>{
    let FrameHexValue = "#FFFFFF";
    switch (key.toLowerCase()) {
      case "black":
        FrameHexValue = "#000000";
        break;
      case "brown":
        FrameHexValue = "#3E2920";
        break;
      case "white":
        FrameHexValue = "#FFFFFF";
        break;
      case "natural":
        FrameHexValue = "#E5D0B5";
        break;
      default:
        break;
    }
    return FrameHexValue;
  };

  const onSubmit = () => {
    onAddToCart();
  };

  const changeSize = (size) => {
    let selectedBorderIndex = 0;
    size.frameColours[0].borders.map((border, index)=>{
      if(border.hasBorder.toLowerCase() === borderAdded.toString()){
        selectedBorderIndex = index
      }
    });
    const initialFrame = size.frameColours[0];
    let iniBorder; 
    if(initialFrame.borders.length === 1) {
      const border = initialFrame.borders[0];
      setBorderDisabled(true);
      setBorderAdded(border.hasBorder==="TRUE" ? true : false);
      if(border.hasBorder==="TRUE"){
        iniBorder = initialFrame.borders.filter((border) =>
          border.hasBorder.toLowerCase() === "true"
        )
      }else {
        iniBorder = initialFrame.borders.filter((border) =>
          border.hasBorder.toLowerCase() === "false"
        )
      }
    } else {
      setBorderDisabled(false);
      iniBorder = initialFrame.borders.filter((border) =>
        border.hasBorder.toLowerCase() === borderAdded.toString()
      )
    }
    setFields({
      ...fields,
      assetNumber: iniBorder[0].assetNumber,
      selectedSize: size,
      allBorders: initialFrame.borders,
      selectedBorder: iniBorder.length > 0 ? iniBorder[0] : "NA",
      selectedFrame: size.frameColours[0],
      wrap: !frameAdded && fields.selectedCanvas.id === "Canvas" ? wrapSelected : "",
      allFrames: size.frameColours
    });
  };
  const changeBorder = (border) => {
    setFields({
      ...fields,
      assetNumber: border.assetNumber,
      selectedBorder: border
    });
    setBorderAdded(!borderAdded);
  };

  const changeFrame = (frame) => {
    let iniBorder; 
    if(frame.borders.length === 1) {
      const border = frame.borders[0];
      setBorderDisabled(true);
      setBorderAdded(border.hasBorder==="TRUE" ? true : false);
      if(border.hasBorder==="TRUE"){
        iniBorder = frame.borders.filter((border) =>
          border.hasBorder.toLowerCase() === "true"
        )
      } else {
        iniBorder = frame.borders.filter((border) =>
          border.hasBorder.toLowerCase() === "false"
        )
      }
    } else {
      setBorderDisabled(false);
      iniBorder = frame.borders.filter((border) =>
        border.hasBorder.toLowerCase() === borderAdded.toString()
      )
    }

    if (frameAdded) {
      setFields({
        ...fields,
        assetNumber: iniBorder[0].assetNumber,
        allBorders: frame.borders,
        selectedBorder: iniBorder.length > 0 ? iniBorder[0] : "NA",
        wrap: "",
        selectedFrame: frame,
      });
    }
  };

  const getProductPrice = () => {
    let resultPrice = 0;
    if (fields.selectedFrame) {
      productPrices.forEach((price) => {
        if (price.code === fields.selectedFrame.skuCode) {
          resultPrice = price.pricePresentment;
        }
      });
    }
    return resultPrice;
  };
  const changeCanvas = (canvas) => {
    changeMaterial(canvas.finish.filter((item)=>{
      return item.isFramed.toLowerCase() === frameAdded.toString()
    })[0], canvas);
  };

  const populateDropdowns = () => {
    if (JondoConfigMaterials && JondoConfigMaterials[0]) {
      const filteredFinishList = JondoConfigMaterials[0].finish.filter((item)=>item.isFramed.toLowerCase() === frameAdded.toString())
      changeMaterial(
        filteredFinishList[0],
        fields.selectedCanvas,
      );
    }
  };

 const createHeroShots = () => {
  const dto = {
    ImageId: 
      process.env.NODE_ENV === "production"
       ? image.id
       : `${image.aspRatioY}x${image.aspRatioX}`,
       ArtistId: 
      process.env.NODE_ENV === "production"
      ? image.artistId
       : `${image.aspRatioY}x${image.aspRatioX}`,
       AspectRatio: `${image.aspRatioY}x${image.aspRatioX}`
    }
   axios.post(
     `${process.env.NODE_ENV === "production"
     ? ""
     : "https://pikcha.local:1900"
     }/v1/mockup/MockupRequest/CreateHeroShots?ImageId=${process.env.NODE_ENV === "production"
     ? image.id
     : `${process.env.NODE_ENV === "production" ? image.aspRatioY : 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`
     }&AspectRatio=${`${process.env.NODE_ENV === "production" ? image.aspRatioY: 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`}&ArtistId=${process.env.NODE_ENV === "production"? image.artistId : `${process.env.NODE_ENV === "production" ? image.aspRatioY : 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`}`,  
     dto,
     {
       headers: {
         "Content-Type": "application/json",
       },
     }
   ).then((resp) => {
     onFeatureChanged(resp.data);
   }).catch((err) => {
     console.log(err);
   })
  }

  const getProductFeatures = () => {
    axios.get(
      `${process.env.NODE_ENV === "production"
      ? ""
      : "https://pikcha.local:1900"
    }/v1/mockup/MockupRequest/GetHeroShots/${image.id}`, 
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((resp) => {
      onFeatureChanged(resp.data);
    }).catch(() => {
      createHeroShots();
    });
  };

  const preferredCurrency = useSelector(state => state.userPreferencesReducer.currencyOptions.value);

  useEffect(() => {
    axios
      .get(
        `api/printer/price?RatioX=${image.aspRatioX}&RatioY=${image.aspRatioY}&imageId=${image.id}&currencyName=${preferredCurrency}`,
      )
      .then((resp) => {
        setProductPrices(resp.data);
        giveProductPrices(resp.data);
      });
    init(JondoConfigMaterials[0]);
  }, [JondoConfigMaterials, preferredCurrency]);

  if (!fields.allMaterials) {
    return null;
  }

  let optionsMarginTop = 32 + scrollPosition;
  const maxVertical = 32 + (dimensions || {}).height + 64;
  const boxHeight = 720;
  const boxMaxVertical = boxHeight + 32;
  const optionsMaxVertical = optionsMarginTop + boxHeight;
  const verticalDifference = maxVertical - optionsMaxVertical;
  if (boxMaxVertical < maxVertical) {
    if (verticalDifference < 0) {
      optionsMarginTop += verticalDifference;
    }
  } else {
    optionsMarginTop = 32;
  }
  const changeFramed = () => {
    const filtededMaterials = fields.selectedCanvas.finish.filter((material)=>
      material.isFramed.toLowerCase() === (!frameAdded).toString()
    )
    const filteredSizes = filtededMaterials.filter((Material)=>{
      return Material.id === fields.selectedMaterial.id
    })[0].sizes;
    const initialFrame = filteredSizes[filteredSizes.length-1].frameColours[0];
    let iniBorder; 
    if(initialFrame.borders.length === 1) {
      const border = initialFrame.borders[0];
      setBorderDisabled(true);
      setBorderAdded(border.hasBorder==="TRUE" ? true : false);
      if(border.hasBorder==="TRUE"){
        iniBorder = initialFrame.borders.filter((border) =>
          border.hasBorder.toLowerCase() === "true"
        )
      }else {
        iniBorder = initialFrame.borders.filter((border) =>
          border.hasBorder.toLowerCase() === "false"
        )
      }
    } else {
      setBorderDisabled(false);
      iniBorder = initialFrame.borders.filter((border) =>
        border.hasBorder.toLowerCase() === borderAdded.toString()
      )
    }
    if(!frameAdded){
      if(fields.selectedCanvas.id!=="Paper"){
        if(initialFrame.borders.length === 1){
          setFields({
            ...fields,
            assetNumber: iniBorder[0].assetNumber,
            selectedMaterial: filtededMaterials.filter((Material)=>{
              return Material.id === fields.selectedMaterial.id
            })[0],
            selectedSize: filteredSizes[filteredSizes.length-1],
            allFrames: filteredSizes[filteredSizes.length-1].frameColours,
            selectedFrame: initialFrame,
            allBorders: initialFrame.borders,
            allSizes: filteredSizes,
            selectedBorder: iniBorder.length > 0 ? iniBorder[0] : "NA",
            wrap: "image"
          })
        } else {
          let selectedBorderIndex;
          initialFrame.borders.map((border, index) => {
            if(border.hasBorder.toLowerCase() === "true"){
              selectedBorderIndex = index;
            }
          });
          setFields({
            ...fields,
            selectedMaterial: filtededMaterials.filter((Material)=>{
              return Material.id === fields.selectedMaterial.id
            })[0],
            selectedSize: filteredSizes[filteredSizes.length-1],
            allFrames: filteredSizes[filteredSizes.length-1].frameColours,
            selectedFrame: initialFrame,
            allBorders: initialFrame.borders,
            allSizes: filteredSizes,
            wrap: "image",
            assetNumber: initialFrame.borders[selectedBorderIndex].assetNumber,
            selectedBorder: initialFrame.borders[selectedBorderIndex]
          });
          setBorderAdded(true);
        }
      } else {
        if(initialFrame.borders.length === 1){
          setFields({
            ...fields,
            assetNumber: iniBorder[0].assetNumber,
            selectedMaterial: filtededMaterials.filter((Material)=>{
              return Material.id === fields.selectedMaterial.id
            })[0],
            selectedSize: filteredSizes[filteredSizes.length-1],
            allFrames: filteredSizes[filteredSizes.length-1].frameColours,
            selectedFrame: initialFrame,
            allBorders: initialFrame.borders,
            allSizes: filteredSizes,
            selectedBorder: iniBorder.length > 0 ? iniBorder[0] : "NA",
            wrap: ""
          })
        } else {
          let selectedBorderIndex;
          initialFrame.borders.map((border, index) => {
            if(border.hasBorder.toLowerCase() === "true"){
              selectedBorderIndex = index;
            }
          });
          setFields({
            ...fields,
            selectedMaterial: filtededMaterials.filter((Material)=>{
              return Material.id === fields.selectedMaterial.id
            })[0],
            selectedSize: filteredSizes[filteredSizes.length-1],
            allFrames: filteredSizes[filteredSizes.length-1].frameColours,
            selectedFrame: initialFrame,
            allBorders: initialFrame.borders,
            allSizes: filteredSizes,
            wrap: "image",
            assetNumber: initialFrame.borders[selectedBorderIndex].assetNumber,
            selectedBorder: initialFrame.borders[selectedBorderIndex]
          });
          setBorderAdded(true);
        }
      }
    } else {
      if(initialFrame.borders.length === 1) {
        setFields({
          ...fields,
          assetNumber: iniBorder[0].assetNumber,
          selectedMaterial: filtededMaterials.filter((Material)=>{
            return Material.id === fields.selectedMaterial.id
          })[0],
          allFrames: filteredSizes[filteredSizes.length-1].frameColours,
          selectedFrame: initialFrame,
          allBorders: initialFrame.borders,
          selectedSize: filteredSizes[filteredSizes.length-1],
          allSizes: filteredSizes,
          selectedBorder: iniBorder.length > 0 ? iniBorder[0] : "NA",
          wrap: wrapSelected
        })
      } else {
        let selectedBorderIndex;
        initialFrame.borders.map((border, index) => {
          if(border.hasBorder.toLowerCase() === "false"){
            selectedBorderIndex = index
          }
        });
        setFields({
          ...fields,
          selectedMaterial: filtededMaterials.filter((Material)=>{
            return Material.id === fields.selectedMaterial.id
          })[0],
          allFrames: filteredSizes[filteredSizes.length-1].frameColours,
          selectedFrame: initialFrame,
          allBorders: initialFrame.borders,
          selectedSize: filteredSizes[filteredSizes.length-1],
          allSizes: filteredSizes,
          wrap: wrapSelected,
          assetNumber: initialFrame.borders[selectedBorderIndex].assetNumber,
          selectedBorder: initialFrame.borders[selectedBorderIndex]
        });
        setBorderAdded(false);
      }
    }
    setFrameAdded(!frameAdded);
  }

  return (
    <div className="relative">
      <div
        className="text-left"
        style={{
          ...(isOnMobile
            ? {
              marginTop: "2em",
              padding: "0.25em",
            }
            : isOnTablet
              ? {
                marginTop: "3em",
              }
              : {
                marginLeft: "2em",
                marginRight: "2em",
              }),
          borderRadius: 6,
          backgroundColor: palette.common.white,
          display: "flex",
          justifyContent: "center",
          padding: "1em",

          ...(style || {}),
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: 400,
            ...(isOnMobile
              ? {}
              : isOnTablet
                ? {
                  minWidth: 400,
                }
                : {
                  minWidth: 400,
                }),
          }}
        >
          {isOnDesktop && (
            <TypographySubtitle
              component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
              style={{
                textAlign: "end",
                width: "100%"
              }}
            >
              {account?.user?.preferredCurrency}<IntlCurrencyFormatter targetCurrency={account?.user?.preferredCurrency} />{fields?.selectedSize?.price}
            </TypographySubtitle>
          )}
          <div
            style={{
              maxWidth: isOnTablet ? undefined : "100%",
              display: "flex",
              flexWrap: "wrap",
              width: isOnMobile ? undefined : "100%",
            }}
          >
            <Grid item xs={12} sm={12} md={12} className="text-left">
              {fields.selectedCanvas && (
                <TypographyBody
                  size="Small_Semibold"
                >
                  Medium
                </TypographyBody>
              )}
            </Grid>
            {image.productList.length == 1 ?
              (
                fields.allMaterials.filter(x => {
                  if(x.id){
                    return x.label.includes(image.productList[0].productType.type);
                  }
                })
                  .map((material, index) => (
                    <div
                      style={{
                        alignItems: "center",
                        width: "50%",
                        marginBottom: index === 0 ? "0.6em" : undefined,
                        marginRight: "0.8em",
                      }}
                      key={index}
                    >
                      <PikchaImageButton
                        active={fields.selectedCanvas.id === material.id}
                        noHoverEffect
                        action={"secondary"}
                        image={material.imageUrl}
                        fullWidth
                        onClick={(e) => changeCanvas(material)}
                      >
                    <span
                      style={{
                        margin: "auto",
                        padding: "2%",
                        lineHeight: "1.1rem",
                      }}
                    >
                      {material.label}
                    </span>
                      </PikchaImageButton>
                    </div>
                  ))) :
              (<TypographyToggleButtonGroup 
                  value={fields.selectedCanvas.label} 
                  className="full-width"
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                {fields.allMaterials.filter(material => material.id)
                  .map((material, index) => (
                    <div
                      style={{
                        alignItems: "center",
                        marginBottom: index === 0 ? "0.6em" : undefined,
                        width: "calc(50% - 0.6em)"
                      }}
                      key={index}
                    >
                    <TypographyToggleButton
                      size="small"
                      component={material.label === fields.selectedCanvas.label ? "primary" : "outline"}
                      buttonText="regular"
                      onClick={(e) => changeCanvas(material)}
                      buttonStyle={{
                        width: "100%",
                        border: material.label === fields.selectedCanvas.label ? "undefined" : `1px ${colors.Neutral.grey30} solid`
                      }}
                      textStyle={{
                        fontWeight: 600
                      }}
                    >
                      {material.label}
                    </TypographyToggleButton>
                    </div>
                  )
                )}
              </TypographyToggleButtonGroup>)
            }
          </div>
          <div className="flex full-width">
            <Grid
              container
              alignContent={"left"}
              style={{
                marginTop: "0.8em",
              }}
            >
              <Grid item xs={12} sm={12} md={12} className="text-left">
                {fields.selectedCanvas && (
                  <>
                    <TypographyBody
                      size="Small_Semibold"
                    >
                      Finish
                    </TypographyBody>
                    <TypographyBody
                      size="Small_Regular"
                      color="neutral/grey50"
                    >
                      {fields?.selectedMaterial?.gsm}gsm {gsmLabel}
                    </TypographyBody>
                  </>
                )}
              </Grid>
              <TypographyToggleButtonGroup 
                style={{
                  display: "flex", 
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: "0.5em",
                  justifyContent: "space-between"
                }}
                value={(fields.selectedMaterial || {}).label}
              >
                {fields && fields.selectedCanvas && fields.selectedCanvas.finish && (
                  fields.selectedCanvas.finish.filter((item) => item.isFramed.toLowerCase() === frameAdded.toString())
                  .map((item, index) => (
                      <div
                        key={index}
                        style={{
                          marginTop: "0.3em",
                          width: "calc(50% - 0.6em)"
                        }}
                      >
                        <TypographyToggleButton
                          size="small"
                          component={item.label === (fields.selectedMaterial || {}).label ? "primary" : "outline"}
                          buttonText="regular"
                          onClick={(e) => changeMaterial(item, fields.selectedCanvas)}
                          buttonStyle={{
                            width: "100%",
                            border: item.label === (fields.selectedMaterial || {}).label ? "undefined" : `1px ${colors.Neutral.grey30} solid`
                          }}
                          textStyle={{
                            fontWeight: 600
                          }}
                        >
                          {item.label}
                        </TypographyToggleButton>
                      </div>
                    ),
                  ))}
              </TypographyToggleButtonGroup>
            </Grid>
          </div>
            <div className="flex full-width">
              <div
                style={{
                  marginTop: "0.8em",
                  maxWidth: "30%",
                  width: "100%"
                }}
                className="pr-3"
              >
                <Grid item xs={12} sm={12} md={12} className="text-left">
                  {fields.selectedCanvas && (
                    <>
                      <TypographyBody
                        size="Small_Semibold"
                      >
                        Frame
                      </TypographyBody>
                    </>
                  )}
                </Grid>
                <TypographySelector
                  style={{
                    marginLeft: 0,
                    marginTop: "0.3em"
                  }}
                  size="30"
                  selectorType="toggle"
                  disabled={frameDisabled}
                  checked={frameAdded}
                  handleChange={changeFramed}
                />
              </div>
              {fields.selectedCanvas.id === "Paper" && (
                <Grid
                  container
                  alignContent={"left"}
                  style={{
                    marginTop: "0.8em",
                    maxWidth: "30%",
                  }}
                  className="pr-3"
                >
                  <Grid item xs={12} sm={12} md={12} className="text-left">
                    {fields.selectedCanvas && (
                      <TypographyBody
                        size="Small_Semibold"
                      >
                        Border
                      </TypographyBody>
                    )}
                  </Grid>
                  <TypographySelector
                    checked={borderAdded}
                    disabled={borderDisabled}
                    selectorType="toggle"
                    handleChange={() => {
                      let selectedBorderIndex;
                      fields.allBorders.map((border, index)=>{
                        if(border.hasBorder === fields.selectedBorder.hasBorder){
                          selectedBorderIndex = index
                        }
                      });
                      changeBorder(fields.allBorders[fields.allBorders.length-1-selectedBorderIndex]);
                    }} 
                    style={{
                      marginLeft: 0,
                      marginTop: "0.3em"
                    }}
                  />
                </Grid>
              )}
              {frameAdded && (
                <div
                  style={{
                    marginTop: "0.8em",
                    maxWidth: "33%",
                  }}
                  className="pr-3 full-width"
                >
                  <Grid item xs={12} sm={12} md={12} className="text-left">
                    {fields.selectedCanvas && (
                      <TypographyBody
                        size="Small_Semibold"
                      >
                        Framing
                      </TypographyBody>
                    )}
                  </Grid>
                  <div className="flex">
                    {fields.selectedSize && fields.selectedSize.frameColours.filter((color)=>color.frameColors!=="NA").map((color) => (
                      <div
                        key={color.id}
                        onMouseEnter={()=>{
                          setColorHovering({
                            item: color.id,
                            value: true
                          })
                        }}
                        onMouseLeave={()=>{
                          setColorHovering({
                            item: color.id,
                            value: false
                          })
                        }}
                        onClick={()=>{
                          changeFrame(color)
                        }}
                        style={{
                          width: 34,
                          height: 34,
                          borderRadius: 4,
                          padding: 3,
                          cursor: "pointer",
                          border: (colorHovering.value && color === colorHovering.item) || fields.selectedFrame === color ? `2px ${colors.Primary.primary50} solid` : undefined
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: setFrameColor(color.label),
                            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                            borderRadius: 4,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          <Grid
            container
            align={"center"}
            style={{
              marginTop: "0.8em",
            }}
          >
            <Grid item xs={12} sm={12} md={12} className="text-left">
              {fields.allSizes.length ? (
                <TypographyBody
                  size="Small_Semibold"
                >
                  Size
                </TypographyBody>
              ) : null}
            </Grid>
            <div className="flex full-width">
              {fields.selectedSize && sizeType === "Cm" && frameAdded && (
                <TypographyBody
                  size="Small_Regular"
                  color="neutral/grey50"
                >
                  {fields.selectedSize.finalSizeInCm}cm
                </TypographyBody>
              )}
              {fields.selectedSize && sizeType === "In" && frameAdded && (
                <TypographyBody
                  size="Small_Regular"
                  color="neutral/grey50"
                >
                  {fields.selectedSize.finalSizeInInch}In
                </TypographyBody>
              )}
              {fields.selectedSize && sizeType === "Cm" && !frameAdded && (
                <TypographyBody
                  size="Small_Regular"
                  color="neutral/grey50"
                >
                  {fields.selectedSize.sizeInCm}cm
                </TypographyBody>
              )}
              {fields.selectedSize && sizeType === "In" && !frameAdded && (
                <TypographyBody
                  size="Small_Regular"
                  color="neutral/grey50"
                >
                  {fields.selectedSize.sizeInInch}In
                </TypographyBody>
              )}
              <div 
                className="flex"
                style={{
                  marginLeft: "auto"
                }}
              >
                <TypographyBody
                  size={sizeType === "In" ? "Small_Semibold" : "Small_Regular"}
                  style={{
                    marginRight: "0.5em",
                    cursor: "pointer",
                    textDecoration: sizeType === "In" ? "underline" : null,
                  }}
                  onClick={() => {
                    if(sizeType!=="In"){
                      setSizeType("In")
                    }
                  }}
                >
                  In
                </TypographyBody>
                <TypographyBody
                  size={sizeType === "Cm" ? "Small_Semibold" : "Small_Regular"}
                  style={{
                    cursor: "pointer",
                    textDecoration: sizeType === "Cm" ? "underline" : null,
                  }}
                  onClick={() => {
                    if(sizeType!=="Cm"){
                      setSizeType("Cm")
                    }
                  }}
                >
                  Cm
                </TypographyBody>
              </div>
            </div>
            <TypographyToggleButtonGroup
              style={{
                display: "flex", 
                flexWrap: "wrap",
                width: "100%",
                marginTop: "0.5em"
              }}
              value={(fields.selectedSize || {}).label}
            >
              {fields.allSizes &&
              fields.allSizes.map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: "0.3em",
                    paddingLeft: (index + 1) % 3 === 1 ? 0 : 2,
                    paddingRight: (index + 1) % 3 === 0 ? 0 : 2,
                    width: isOnDesktop ? `${100/3}%` : isOnTablet ? "50%" : "100%"
                  }}
                >
                  <TypographyToggleButton
                    component={item.label === fields.selectedSize.label ? "primary" : "outline"}
                    onClick={() => changeSize(item)}
                    buttonText="regular"
                    size="small"
                    buttonStyle={{
                      width: "100%",
                      border: item.label === fields.selectedSize.label ? "undefined" : `1px ${colors.Neutral.grey30} solid`
                    }}
                    textStyle={{
                      fontWeight: 600
                    }}
                  >
                    {item.label}&nbsp;-&nbsp;<IntlCurrencyFormatter targetCurrency={account?.user?.preferredCurrency} /> {item.price}
                  </TypographyToggleButton>
                </div>
              ))}
            </TypographyToggleButtonGroup>
          </Grid>
          <div
            style={{
              marginTop: "1em",
              width: "100%"
            }}
          >
            <Reservations
              reservations={reservations}
              onEditionSelected={onEditionSelected}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: isOnTablet ? 0 : "2em",
              alignItems: "center",
            }}
          >
            <div className="full-width">
              <PikchaButton
                className="mt-2"
                onClick={() => {
                  if (isProductionSite()) {
                    ReactPixel.track(FB_PIXEL_EVENT_TYPES.AddToCart, {
                      content_ids: [image.id],
                      content_name: image.title,
                      content_type: "product",
                      ...getSavedUtmDetails()
                    });
                    ReactGA.event("add_to_cart", {
                      currency: userPreferencesReducer.currencyOptions.label,
                      value: +getProductPrice(),
                      items: [{ item_id: image.id, item_name: image.title }],
                      ...getSavedUtmDetails()
                    });
                  }
                  onSubmit();
                }}
                mainStyle={{
                  width: "100%",
                  borderRadius: 8
                }}
                disabled={expectedProductNumber >= 100}
                color="black"
              >
                <ShoppingCartIcon
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: "0.5em",
                    marginLeft: "-1em",
                    marginTop: "0.15em",
                  }}
                />
                Add To Cart
              </PikchaButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {})(CustomiseProduct);
