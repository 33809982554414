import {
    ADD_TO_ALBUM_IMAGES_ERROR,
    ADD_TO_ALBUM_IMAGES_LOADED,
    ADD_TO_ALBUM_IMAGES_LOADING,
    ADD_TO_ALBUM_IMAGES_RESET
} from "../actions/types";

const initialState = {
  photos: [],
  count: 15,
  start: 1,
  loading: true,
  error: null,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
 switch (type) {
     case ADD_TO_ALBUM_IMAGES_LOADED:
      return {
        ...state,
        photos: state.photos.concat(payload),
        loading: false,
        start: state.start + 1,
      };
     case ADD_TO_ALBUM_IMAGES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_ALBUM_IMAGES_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        hasMore: false
      };
    case ADD_TO_ALBUM_IMAGES_RESET:
      return {
          photos: [],
          count: 15,
          start: 1,
          loading: true,
          error: null,
          hasMore: true,
      };
    default:
      return state;
  }
};
