import React from 'react';
import { useTheme } from '@material-ui/core';

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Tooltip from '@material-ui/core/Tooltip';
import GestureRoundedIcon from '@material-ui/icons/GestureRounded';
import { ICON_SIZE_LARGE, ICON_SIZE_SMALL } from '../../helpers/constants';
import { PikchaButton } from '../baseComponents';

export default ({
  isEditSignature, onClick, action = 'primary', ...rest
}) => {
  const { palette } = useTheme();

  return (

    <PikchaButton
      action={action}
      iconButton
      noHoverEffect
      noPadding
      style={{
        ...ICON_SIZE_LARGE,
        minWidth: 0,
        // borderWidth: 0
      }}
      onClick={onClick}
      {...rest}
    >
      <Tooltip
        title={<span style={{ fontSize: '8px' }}>Edit</span>}
        interactive
        disableTouchListener
        enterDelay={500}
        leaveDelay={50}
      >
        {!isEditSignature ? (
          <EditRoundedIcon
            style={{
              ...ICON_SIZE_SMALL,
              color:
                action === 'primary' ? palette.common.white : palette.grey.dark,
            }}
          />
        ) : (
          <GestureRoundedIcon
            style={{
              ...ICON_SIZE_SMALL,
              color:
                action === 'primary' ? palette.common.white : palette.grey.dark,
            }}
          />
        )}
      </Tooltip>
    </PikchaButton>
  );
};
