import React, { useEffect, useState } from 'react'
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton
} from "@pikcha/typography";
import { PikchaTextField } from "../../components/baseComponents";
import axios from "axios";
import DescriptionTextArea from '../../components/DescriptionTextArea/DescriptionTextArea';
import DateSeparatePicker
  from "../../components/DateSeparatePicker/DateSeparatePicker";
import { makeStyles } from '@material-ui/styles';
import AuthorizeService from "../../auth/AuthorizeService";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";

const useStyles = makeStyles(() => ({
  imageMargin: {
    marginBottom: '0.75rem'
  }
}))

const Step4 = ({
  handleNextStep,
  imageDetails,
  handleImageDetails,
  account,
  CreateHeroShots
}) => {
  const classes = useStyles();
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    const artistId = account.user.id;
    const token = AuthorizeService.getAccessToken();
    const delayDebounceFn = setTimeout(() => {
      axios.post(
        `api/image/${artistId}/ImageTitleValidation`,
        {
          artistId,
          imageTitle: imageDetails.title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ).then(()=>{
        setHelperText("");
      })
        .catch(function (err) {
          setHelperText("Title you're entered is previously used");
        });
    }, 1500);
    return () => clearTimeout(delayDebounceFn);
  }, [imageDetails.title]);

  useEffect(() => {
    CreateHeroShots();
  }, [])

  return (
    <>
      <TypographySubtitle
        component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
        style={{ marginBottom: '1rem' }}
      >
        Artwork details
      </TypographySubtitle>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
      >
        Let people know what your artwork is about.
      </TypographyBody>
      <div style={{ marginTop: '3rem' }}>
        <div className={classes.imageMargin}>
          <PikchaTextField
            type="text"
            label={
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                style={{
                  lineHeight: undefined
                }}
              >
                Title<span style={{color: "red"}}>*</span>
              </TypographyBody>
            }
            placeholder="What is your artwork called?"
            autoComplete="off"
            value={imageDetails.title}
            onChange={({ target: { value } }) => handleImageDetails('title', value)}
            helperText={
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                color="System/red50"
              >
                {helperText}
              </TypographyBody>
            }
          />
        </div>
        <div className={classes.imageMargin}>
          <PikchaTextField
            type="text"
            label={
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                style={{
                  lineHeight: undefined
                }}
              >
                Location
              </TypographyBody>
            }
            value={imageDetails.location}
            onChange={({ target: { value } }) => handleImageDetails('location', value)}
            placeholder='Where was your artwork made?'
          />
        </div>
        <div className={classes.imageMargin}>
          <DateSeparatePicker
            PickerLabel={
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                style={{
                  lineHeight: undefined
                }}
              >
                Date created
              </TypographyBody>
            }
            DateCreated={imageDetails.dateCreated}
            onDateUpdate={(date) => {
              handleImageDetails('dateCreated', date)
            }}
          />
        </div>
        <DescriptionTextArea
          rows={4}
          label={
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{
                lineHeight: undefined
              }}
            >
              Description
            </TypographyBody>
          }
          value={imageDetails.caption}
          onChange={(value) => {
            handleImageDetails('caption', value)
          }}
          placeholder='Describe your artwork.'
        />
      </div>
      <TypographyButton
        buttonStyle={{ width: "100%" }}
        onClick={()=>{
          if(!imageDetails.title){
            setHelperText("Artwork must have a title")
            return
          }
          const artistId = account.user.id;
          const token = AuthorizeService.getAccessToken();
          axios.post(
            `api/image/${artistId}/ImageTitleValidation`,
            {
              artistId,
              imageTitle: imageDetails.title,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          ).then(()=>{
            handleNextStep()
          }).catch(() => {
            setHelperText("Title you're entered is previously used");
          });
        }}
      >
        Next
      </TypographyButton>
    </>
  )
}

const mapStateToProps = (state) => ({
  account: state.accountReducer
});

export default connect(mapStateToProps, {
  setAlert,
})(Step4);

