import React, { Fragment } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";

export const PikchaRadioButton = withStyles(({ palette, spacing }) => ({
  root: {
    //padding: 4,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 50,
    width: spacing(2),
    height: spacing(2),
    boxShadow: `inset 0 0 0 3px ${palette.grey.mediumLight}, inset 0 -1px 0 ${palette.grey.mediumLight}`,
    backgroundColor: palette.grey.lightest,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    transition: "background-color 0.25s ease-in-out",
    "input:hover ~ &": {
      //transition: "background-color 0.25s ease-in-out",
      //backgroundColor: palette.grey.lighter
    },
  },
  checkedIcon: {
    backgroundColor: palette.common.white,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: spacing(2),
      height: spacing(2),
      backgroundImage: `radial-gradient(${palette.primary.main},${palette.primary.main} 28%,transparent 32%)`,
      content: '""',
    },
    transition: "background-color 0.25s ease-in-out",
    "input:hover ~ &": {
      //transition: "background-color 0.25s ease-in-out",
      //backgroundColor: palette.grey.lighter
    },
  },
}))(({ classes: { root, icon, checkedIcon }, value, ...rest }) => (
  <Fragment>
    <Radio
      className={root}
      disableRipple
      color="primary"
      checkedIcon={<span className={clsx(icon, checkedIcon)} />}
      icon={<span className={icon} />}
      inputProps={{
        "aria-label": value,
      }}
      value={value}
      {...rest}
    />
  </Fragment>
));
