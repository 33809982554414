import React, { forwardRef } from "react";
import clsx from "clsx";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = () =>
  makeStyles(({ shape, typography, palette }) => ({
    root: {
      padding: 0,
      marginRight: ".25em",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: palette.grey.lightest,
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: palette.grey.light,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: palette.grey.lighter,
      },
    },
    checkedIcon: {
      backgroundColor: palette.grey.lighter,
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: palette.grey.lighter,
      },
    },
  }))(Checkbox);

const StyledCheckbox = forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <Checkbox
      ref={ref}
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
});

export const PikchaCustomCheckbox = withStyles({
  root: {
    alignItems: "start",
  },
  label: ({ spanStyle }) => ({
    marginTop: "-3px",
    ...spanStyle,
  }),
})(
  forwardRef(
    (
      {
        label,
        boldText,
        fontSize,
        required,
        spanStyle,
        labelStyle = {},
        labelPlacement = "end",
        ...rest
      },
      ref
    ) => {
      const { palette, typography } = useTheme();

      return (
        <FormControlLabel
          labelPlacement={labelPlacement}
          control={<StyledCheckbox ref={ref} />}
          label={
            <span
              style={{
                fontWeight: boldText ? 600 : null,
                fontSize: fontSize ? fontSize : null,
                ...labelStyle,
              }}
            >
              {label}
              {required && (
                <span
                  style={{
                    color: palette.error.main,
                    fontWeight: typography.fontWeightMedium,
                  }}
                >
                  *
                </span>
              )}
            </span>
          }
          {...rest}
        />
      );
    }
  )
);
