import React from "react";
import ContentLoader from "react-content-loader";
import { useTheme } from "@material-ui/core/styles";

export default (props) => {
  const { palette } = useTheme();

  return (
    <div className="flex-center justify-center pt-8-em">
      <ContentLoader
        speed={2}
        width={1200}
        height={600}
        viewBox="0 0 1200 600"
        backgroundColor={palette.grey.lighter}
        foregroundColor={palette.grey.lighter}
        {...props}
      >
        <rect x="603" y="105" rx="2" ry="2" width="134" height="42" />
        <rect x="603" y="264" rx="2" ry="2" width="355" height="314" />
        <rect x="603" y="157" rx="2" ry="2" width="146" height="42" />
        <rect x="603" y="209" rx="2" ry="2" width="155" height="42" />
        <circle cx="992" cy="409" r="22" />
        <circle cx="565" cy="409" r="22" />
        <rect x="17" y="223" rx="3" ry="3" width="348" height="50" />
        <rect x="17" y="282" rx="3" ry="3" width="348" height="50" />
        <rect x="17" y="341" rx="3" ry="3" width="348" height="50" />
        <rect x="17" y="430" rx="0" ry="0" width="314" height="12" />
        <rect x="17" y="453" rx="0" ry="0" width="180" height="10" />
        <circle cx="25" cy="491" r="10" />
        <rect x="42" y="486" rx="0" ry="0" width="285" height="11" />
      </ContentLoader>
    </div>
  );
};
