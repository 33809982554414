import React from "react";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { colors } from "../../colors";
import { getColorKey } from "../../utils";

export enum TYPOGRAPHY_SUBTITLE_COMPONENT {
  Desktop_H3_Regular = "desktop_h3_regular",
  Desktop_H3_Bold = "desktop_h3_bold",
  Desktop_H4_Medium = "desktop_h4_medium",
  Desktop_H4_Bold = "desktop_h4_bold",
  iPad_H3_Regular = "ipad_h3_regular",
  iPad_H3_Bold = "ipad_h3_bold",
  iPad_H4_Medium = "ipad_h4_medium",
  iPad_H4_Bold = "ipad_h4_bold",
  Mobile_H3_Regular = "mobile_h3_regular",
  Mobile_H3_Bold = "mobile_h3_bold",
  Mobile_H4_Medium = "mobile_h4_medium",
  Mobile_H4_Bold = "mobile_h4_bold",
}

const typographySubtitleHashMap = new Map([
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Regular,
    { lineHeight: "125%", fontSize: 2, letterSpacing: "-1.3px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold,
    { lineHeight: "125%", fontSize: 2, letterSpacing: "-1.8px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Medium,
    { lineHeight: "133%", fontSize: 1.5, letterSpacing: "-1.3px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold,
    { lineHeight: "133%", fontSize: 1.5, letterSpacing: "-1.3px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.iPad_H3_Regular,
    { lineHeight: "116%", fontSize: 1.5, letterSpacing: "-1.3px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.iPad_H3_Bold,
    { lineHeight: "116%", fontSize: 1.5, letterSpacing: "-1.8px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.iPad_H4_Medium,
    { lineHeight: "120%", fontSize: 1.25, letterSpacing: "-1.3px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.iPad_H4_Bold,
    { lineHeight: "120%", fontSize: 1.25, letterSpacing: "-1.3px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H3_Regular,
    { lineHeight: "116%", fontSize: 1.5, letterSpacing: "-1.3px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H3_Bold,
    { lineHeight: "116%", fontSize: 1.5, letterSpacing: "-1.8px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Medium,
    { lineHeight: "120%", fontSize: 1.25, letterSpacing: "-1.3px" },
  ],
  [
    TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Bold,
    { lineHeight: "120%", fontSize: 1.25, letterSpacing: "-1.3px" },
  ],
]);

const TypographSubtitle = ({
                             component = TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Regular,
                             color = "neutral/ink90",
                             children,
                             style = {},
                             // Any additional props will be passed to the
                             // underlying material-ui component.
                             ...rest
                           }) => {
  const { typography } = useTheme();
  let colorKey1;
  let colorKey2;
  colorKey1 = getColorKey(color).colorKey1;
  colorKey2 = getColorKey(color).colorKey2;

  return (
    // @ts-ignore
    <Typography
      // @ts-ignore
      component={component.split("_")[1]}
      style={{
        ...(component.includes("bold") && {
          fontWeight: "bold",
        }),
        fontSize: `${typographySubtitleHashMap.get(component).fontSize}em`,
        fontFamily: typography.fontFamily,
        letterSpacing: typographySubtitleHashMap.get(component).letterSpacing,
        lineHeight: typographySubtitleHashMap.get(component).lineHeight,
        color: colors[colorKey1][colorKey2],
        ...style
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TypographSubtitle;
