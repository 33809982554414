import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({
  children,
  style = {},
  labelStyle = {},
  childrenContainerStyle = {},
  className = "",
  classNameOuter = "",
  innerClassName = "",
  label,
  subLabel,
  required,
  disabled = "",
  isFocused,
  noHelperClass,
  legend,
  FieldLabelProps = {},
  noFieldSet,
}) => {
  const { palette, typography } = useTheme();

  return (
    <div
      className={`${classNameOuter} ${noHelperClass ? "" : "TextField-containerHelper"
        } MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth`}
      style={{
        color: disabled ? palette.action.disabled : palette.grey.mediumDark,
        ...style,
      }}
    >
      <label
        className="MuiInputLabel-root MuiInputLabel-formControl TextField-labelHelper MuiInputLabel-shrink MuiInputLabel-outlined"
        data-shrink="true"
      >
        <span
          {...FieldLabelProps}
          style={{
            color: disabled
              ? palette.action.disabled
              : !isFocused
                ? palette.grey.mediumDark
                : palette.secondary.main,
            fontSize: typography.smallest,
            ...labelStyle,
          }}
        >
          {label}
        </span>
        {required && !disabled && (
          <span
            style={{
              color: palette.error.main,
              fontWeight: typography.fontWeightMedium,
            }}
          >
            *
          </span>
        )}
        {subLabel && (
          <>
            &nbsp;
            <span style={{ fontWeight: typography.fontWeightRegular }}>
              ({subLabel})
            </span>
          </>
        )}
      </label>
      <div
        className={`${innerClassName} MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullwidth MuiInputBase-formControl`}
        style={{
          ...childrenContainerStyle,
          cursor: disabled ? "default" : undefined,
        }}
      >
        {children}
        {!noFieldSet && (
          <fieldset
            aria-hidden="true"
            className={`${className} TextField-fieldsetHelper`}
          >
            {legend}
            <legend className="TextField-legendHelper" />
          </fieldset>
        )}
      </div>
    </div>
  );
};
