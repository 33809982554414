import React, {Fragment, cloneElement} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import PikchaNoUnderlineLink from "./PikchaNoUnderlineLink";

const useStyles = makeStyles(({spacing, typography, palette}) => ({
  root: ({children}) => ({
    display: "flex",
    color: palette.text.secondary,
    fontWeight: 500,
    paddingLeft: children ? spacing(2) : null,
    alignItems: "center",
    fontSize: "0.85rem",
    fontFamily: typography.fontFamilyMontserrat,
    // fontSize: typography.small,
    height: spacing(5),

    "& > span": {
      maxWidth: "152px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },

    // "&:hover": {
    //   backgroundColor: "rgba(0, 0, 0, .04) !important",
    // },
  }),
  safeSearchClass: ({safeSearchState}) => ({
    fontSize: typography.smallest,
    fontWeight: typography.fontWeightMedium,
    fontFamily: typography.fontFamilyMontserrat,
  }),
  menuItemHome: ({children, pathname}) => ({
    justifyContent: !children ? "center" : null,
    backgroundColor:
      pathname === "/" ? palette.grey.light : "transparent",
    color:
      pathname === "/" ? palette.text.primary : palette.text.secondary,
    fontWeight:
      pathname === "/"
        ? typography.fontWeightMedium
        : typography.fontWeightMedium,
    fontFamily: typography.fontFamilyMontserrat,
    // "&:hover": {
    //   backgroundColor:
    //     pathname === "/" ? "rgba(0, 0, 0, .04) !important" : null,
    // },
  }),
  menuItemSaved: ({children, pathname}) => ({
    justifyContent: !children ? "center" : null,
    backgroundColor: pathname === "/saved" ? palette.grey.light : "transparent",
    fontWeight:
      pathname === "/saved"
        ? typography.fontWeightMedium
        : typography.fontWeightMedium,
    color:
      pathname === "/saved" ? palette.text.primary : palette.text.secondary,
    fontFamily: typography.fontFamilyMontserrat,
    // "&:hover": {
    //   backgroundColor:
    //     pathname === "/saved" ? "rgba(0, 0, 0, .04) !important" : null,
    // },
  }),
  menuItemFollowing: ({children, pathname}) => ({
    justifyContent: !children ? "center" : null,
    backgroundColor:
      pathname === "/following" ? palette.grey.light : "transparent",
    fontWeight:
      pathname === "/following"
        ? typography.fontWeightMedium
        : typography.fontWeightMedium,
    fontFamily: typography.fontFamilyMontserrat,
    color:
      pathname === "/following" ? palette.text.primary : palette.text.secondary,
    // "&:hover": {
    //   backgroundColor:
    //     pathname === "/following" ? "rgba(0, 0, 0, .04) !important" : null,
    // },
  }),
  menuItem100: ({children, pathname}) => ({
    color:
      pathname === "/pikcha100" || pathname === "/artist100" ? palette.text.primary : palette.text.secondary,
    justifyContent: !children ? "center" : null,
    backgroundColor:
      pathname === "/pikcha100" || pathname === "/artist100" ? palette.grey.light : "transparent",
    fontWeight:
      pathname === "/pikcha100" || pathname === "/artist100"
        ? typography.fontWeightMedium
        : typography.fontWeightMedium,
    fontFamily: typography.fontFamilyMontserrat,
    // "&:hover": {
    //   backgroundColor:
    //     pathname === "/pikcha100" || pathname === "/artist100" ? "rgba(0, 0, 0, .04) !important" : null,
    // },
  }),
  menuItemIcon: ({children}) => ({
    "& > svg": {
      width: 24,
      height: 24,
    },
    fontSize: typography.standardLarge,
    fontFamily: typography.fontFamilyMontserrat,
    minWidth: 0,
    paddingRight: children ? spacing(2) : null,
  }),
}));

const PikchaMenuItem = ({
                          classes: {
                            root,
                            menuItemIcon,
                            safeSearchClass,
                            menuItemHome,
                            menuItemSaved,
                            menuItem100,
                            menuItemFollowing,
                          },
                          onClick,
                          palette,
                          safeSearchState,
                          safeSearchButton,
                          icon,
                          to,
                          children,
                          pathname
                        }) => (
  <MenuItem
    className={root}
    onClick={onClick ? onClick : null}
    disableGutters
    ListItemClasses={{
      root:
        !to && !safeSearchButton
          ? null
          : safeSearchButton
          ? safeSearchClass
          : to === "/"
            ? menuItemHome
            : to === "/following"
              ? menuItemFollowing
              : to === "/pikcha100"
                ? menuItem100
                : menuItemSaved,
    }}
  >
    {icon && (
      <ListItemIcon 
        className={menuItemIcon}
        style={{
          color: 
            to === "/" && pathname === "/" || 
            to === "/following" && pathname === "/following" ||
            to === "/saved" && pathname === "/saved" ||
            to === "/pikcha100" && pathname === "/pikcha100"
            ? palette.text.primary : palette.text.secondary,
        }}
      >
        {!children
          ? cloneElement(icon, {
            style: {
              width: 24,
              height: 24,
            },
          })
          : icon}
      </ListItemIcon>
    )}
    {children}
  </MenuItem>
);

export const PikchaDrawerMenuItem = ({
                                       className = "",
                                       pathname,
                                       safeSearchState,
                                       safeSearchButton,
                                       children,
                                       to,
                                       ...rest
                                     }) => {
  const classes = useStyles({children, pathname, safeSearchState});
  const { palette } = useTheme();

  return (
    <>
      {safeSearchButton ? (
        <PikchaMenuItem
          classes={classes}
          children={children}
          pathname={pathname}
          safeSearchState={safeSearchState}
          safeSearchButton={safeSearchButton}
          pathname={pathname}
          palette={palette}
          {...rest}
        />
      ) : (
        <PikchaNoUnderlineLink
          className={`${className}`}
          to={to}
        >
          <PikchaMenuItem
            classes={classes}
            pathname={pathname}
            children={children}
            palette={palette}
            to={to}
            {...rest}
          />
        </PikchaNoUnderlineLink>
      )}
    </>
  );
};
