import React from 'react';
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
    const { palette } = useTheme();
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path 
                    d="M12.004 20.7184C12.002 20.7184 11.9998 20.7184 11.9976 20.7184C11.2734 20.7135 4.87133 20.655 3.05748 20.1645C1.78984 19.8237 0.78917 18.8242 0.447495 17.5563C-0.0271151 15.7745 0.00309741 12.3441 0.00639331 12.0701C0.00328051 11.7975 -0.0272982 8.33862 0.44603 6.54217C0.44658 6.54052 0.446946 6.53869 0.447495 6.53704C0.785325 5.28368 1.80852 4.25316 3.05437 3.91148C3.05748 3.91057 3.06078 3.90984 3.06389 3.90892C4.85723 3.43761 11.272 3.37993 11.9976 3.37498H12.0106C12.7366 3.37993 19.1558 3.43834 20.953 3.92961C22.2173 4.26946 23.2172 5.26775 23.5598 6.53393C24.0522 8.33166 24.0066 11.798 24.0017 12.0906C24.0051 12.3788 24.0339 15.7786 23.562 17.5693C23.5617 17.5712 23.5611 17.5728 23.5608 17.5745C23.2189 18.8423 22.2184 19.8419 20.9493 20.183C20.9476 20.1836 20.9458 20.1839 20.9442 20.1845C19.151 20.6556 12.7361 20.7133 12.0106 20.7184C12.0084 20.7184 12.0062 20.7184 12.004 20.7184ZM2.25841 7.023C1.84166 8.6087 1.88103 12.0234 1.8814 12.0579V12.0826C1.86895 13.0296 1.91271 15.7722 2.25859 17.0711C2.42632 17.6931 2.91979 18.1859 3.54564 18.3541C4.88396 18.7161 10.0983 18.83 12.004 18.8434C13.9147 18.83 19.1367 18.7193 20.4652 18.3717C21.089 18.2029 21.5808 17.7118 21.7497 17.0891C22.0959 15.7711 22.1393 13.0419 22.1267 12.1009C22.1267 12.091 22.1267 12.0811 22.1269 12.0712C22.1441 11.1129 22.1102 8.33825 21.7513 7.02886C21.7509 7.02758 21.7506 7.0263 21.7504 7.02502C21.5819 6.40026 21.0883 5.90752 20.4624 5.73925C19.1371 5.37688 13.9144 5.26336 12.004 5.24999C10.0946 5.26336 4.87774 5.37395 3.54528 5.72112C2.93132 5.89141 2.42669 6.40172 2.25841 7.023ZM22.6555 17.3306H22.656H22.6555ZM9.61432 15.8434V8.24981L16.1768 12.0467L9.61432 15.8434Z" 
                    fill={color || palette.grey.medium}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" transform="translate(0.00585938)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
