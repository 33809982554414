import React, { useRef, useEffect, useState } from "react";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE
} from "@pikcha/typography";
import isMobile from "../../../components/Responsive/isMobile";

export default ({ onCreateAlbum }) => {
  const isOnMobile = isMobile()
  const tile = useRef(null);
  const [itemWidth, setItemWidth] = useState(0);

  const handleResize = () => {
    const { width } = tile.current ? window.getComputedStyle(tile.current) : {};

    const widthValue = parseFloat(width);

    setItemWidth(widthValue);
  };
  useEffect(() => {
    handleResize();
  }, [tile.current]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isOnMobile ? (
        <div
          ref={tile}
          style={{
            backgroundColor: '#121619',
            width: '100%',
            height: itemWidth,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginRight: '4em'
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 1.5em',
            flexGrow: '1',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <TypographyBody
              style={{ color: '#fff' }}
              size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
            >
              Create a Collection
            </TypographyBody>
            <TypographyBody
              style={{ color: '#fff', textAlign: 'center' }}
              size={TYPOGRAPHY_BODY_SIZE.Default_Medium}
            >
              Group a body of work to make a <br /> collection for your art
            </TypographyBody>
          </div>
          <div style={{ margin: '0 1.5em 1.5em' }}>
            <TypographyButton
              textStyle={{
                fontSize: '12px',
                color: '#fff'
              }}
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              buttonStyle={{
                width: '100%',
                borderColor: '#fff'
              }}
              onClick={onCreateAlbum}
            >
              Create
            </TypographyButton>
          </div>
        </div>
      ) :
        (
          <>
            <div style={{
              marginLeft: '1.5em',
              marginRight: '1.5em'
            }}>
              <TypographyButton
                textStyle={{
                  fontSize: '12px',
                  color: '#fff'
                }}
                component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                buttonStyle={{
                  width: '100%',
                }}
                onClick={onCreateAlbum}
              >
                Create a Collection
              </TypographyButton>
            </div>
          </>
        )
      }
    </>
  );
};
