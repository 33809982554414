import React from "react";

import { PikchaModal } from "../../../components/baseComponents";

import Desktop from "../../../components/Responsive/Desktop";
import Tablet from "../../../components/Responsive/Tablet";
import Mobile from "../../../components/Responsive/Mobile";

import AuthModalFormContainer from "./AuthModalFormContainer";
import AuthModalFormMobile from "./AuthModalFormMobile";

import "./AuthModal.css";

export default ({
  open,
  origin,
  handleClickClose,
  fromSafeSearch,
  fromFollowing,
  fromCart,
  ...rest
}) => {
  return (
    <PikchaModal
      onClose={handleClickClose}
      open={open}
      hideCloseButton
      paperStyle={{
        overflow: "hidden",
        minHeight: 0,
        height: 400,
        maxHeight: "100%",
      }}
      {...rest}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          // padding: "2em",
          justifyContent: "center",
          display: "flex",
          alignItems: "stretch",
          flexGrow: 1,
        }}
      >
        <Desktop>
          <AuthModalFormContainer
            origin={origin}
            handleClickClose={handleClickClose}
            fromFollowing={fromFollowing}
            fromSafeSearch={fromSafeSearch}
            fromCart={fromCart}
          />
        </Desktop>
        <Tablet>
          <AuthModalFormContainer
            origin={origin}
            handleClickClose={handleClickClose}
            fromFollowing={fromFollowing}
            fromSafeSearch={fromSafeSearch}
            fromCart={fromCart}
          />
        </Tablet>
        <Mobile>
          <AuthModalFormMobile
            origin={origin}
            handleClickClose={handleClickClose}
            fromFollowing={fromFollowing}
            fromSafeSearch={fromSafeSearch}
            fromCart={fromCart}
          />
        </Mobile>
      </div>
    </PikchaModal>
  );
};
