import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import isDesktop from "../../components/Responsive/isDesktop";
import isTablet from "../../components/Responsive/isTablet";
import isMobile from "../../components/Responsive/isMobile";
import {
  ICON_SIZE_DEFAULT,
  reservationStatusCode,
} from "../../helpers/constants";
import {
  PikchaCheckbox,
  PikchaCustomCheckbox,
  PikchaImageNumber,
} from "../../components/baseComponents";
import { 
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  colors 
} from "@pikcha/typography";

export default ({ reservations, onEditionSelected }) => {
  const location = useLocation();

  const expectedProductNumber = location.state?.image.totSold;

  const { palette, typography } = useTheme();
  const [open, setOpen] = useState(false);
  const [controlWidth, setControlWidth] = useState();
  const dropdown = useRef(null);
  const control = useRef(null);
  
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const isOnDesktop = isDesktop();

  const handleResize = () => {
    // const { width } = control.current
    //   ? window.getComputedStyle(control.current)
    //   : {};
    const widthValue = parseFloat(isOnMobile? '351px' : '384px');
    setControlWidth(widthValue);
  };
  useEffect(() => {
    handleResize();
  }, [control.current]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleDown = (e) => {
      if (
        dropdown &&
        dropdown.current &&
        !dropdown.current.contains(e.target) &&
        control &&
        control.current &&
        !control.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleDown);

    return () => {
      document.removeEventListener("mousedown", handleDown);
    };
  });

  const onToggleDropdown = () => {
    setOpen(!open);
  };

  let numberSelected;
  reservations.forEach(({ productNumber, statusCode }) => {
    if (statusCode === reservationStatusCode.selectedByUser) {
      numberSelected = productNumber;
    }
  });

  return (
    <div
      style={{
        cursor: "pointer",
        marginTop: "1em"
      }}
      ref={control}
    >
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
      >
        Edition
      </TypographyBody>
      <div 
        className="flex full-width"
        style={{
          alignItems: isOnDesktop ? "center" : "start",
          flexDirection: isOnDesktop ? "row" : "column"
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "0.25em",
            fontSize: typography.standard,
            fontWeight: typography.fontWeightBold,
            border: `2px solid ${colors.Neutral.grey30}`,
            padding: "0.5em 1em",
            borderRadius: 6,
            width: isOnDesktop ? "50%" : "100%"
          }}
          onClick={onToggleDropdown}
        >
          <div
            style={{
              marginLeft: "0.25em",
              display: "flex",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <div
              style={{
                textAlign: "start",
              }}
            >
              {numberSelected} / {reservations.length}
            </div>
          </div>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {!open && (
              <ExpandMoreIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}
            {open && (
              <ExpandLessIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}
          </div>
        </div>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
          color="neutral/grey50"
          style={{
            marginLeft: isOnDesktop && "1em",
            marginTop: !isOnDesktop && "0.5em"
          }}
        >
          Limited edition run of {reservations.length}
        </TypographyBody>
      </div>
      {open && (
        <div
          style={{
            zIndex: 10,
            position: "absolute",
            width: controlWidth,
            marginTop: "0.5em",
            marginLeft: "-0.25em",
            marginRight: "-0.25em",
            backgroundColor: palette.common.white,
            borderRadius: 6,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            paddingLeft: "0.5em",
            paddingTop: "1em",
          }}
          ref={dropdown}
        >
          <div
            style={{
              display: "flex",
              marginLeft: "1.5em",
              marginRight: "4em",
              marginBottom: "0.5em",
              alignItems: "center",
              fontSize: typography.tiny,
              fontWeight: typography.fontWeightSemiMedium,
            }}
          >
            <div
              style={{
                borderRadius: "50%",
                width: 7,
                height: 7,
                backgroundColor: palette.grey.medium,
              }}
            />
            <div
              style={{
                marginLeft: "0.5em",
                color: palette.grey.medium,
              }}
            >
              sold
            </div>
            <div
              style={{
                marginLeft: "1em",
                borderRadius: "50%",
                width: 7,
                height: 7,
                backgroundColor: palette.grey.lighter,
              }}
            />
            <div
              style={{
                marginLeft: "0.5em",
                color: palette.grey.lighter,
              }}
            >
              in-cart
            </div>

            <div
              style={{
                marginLeft: "auto",
              }}
            >
              {expectedProductNumber >= 100 && (
                <div>
                  <PikchaImageNumber
                    style={{
                      display: "flex",
                      fontSize: "1em",
                      color: palette.grey.medium,
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "flex-start",
                        marginRight: "0.5em",
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        backgroundColor: palette.error.main,
                      }}
                    />
                    100/100 sold
                  </PikchaImageNumber>
                  {/* <div
                  style={{
                    fontWeight: typography.fontWeightMedium,
                    fontSize: typography.smallest,
                    color: palette.text.secondary,
                  }}
                >
                  0 remaining
                </div> */}
                </div>
              )}
              {expectedProductNumber < 100 && (
                <div>
                  <PikchaImageNumber
                    style={{
                      // textAlign: "end",
                      display: "flex",
                      fontSize: "1em",
                      color: palette.grey.medium,
                    }}
                  >
                    {expectedProductNumber}/100 sold
                  </PikchaImageNumber>
                  {/* <div
                  style={{
                    fontWeight: typography.fontWeightMedium,
                    fontSize: typography.smallest,
                    color: palette.text.secondary,
                    textAlign: "end",
                  }}
                >
                  {100 - expectedProductNumber} remaining
                </div> */}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginLeft: "1em",
              marginBottom: "0.5em",
            }}
          >
            {reservations.map((option, index) => {
              const { productNumber, statusCode } = option;
              const isChecked =
                statusCode === reservationStatusCode.selectedByUser;

              if (statusCode === reservationStatusCode.reserved) {
                return (
                  <div
                    key={index}
                    style={{
                      marginRight: 0,
                      marginTop: "0.5em",
                    }}
                  >
                    <div
                      style={{
                        marginLeft: 11,
                        width: 36,
                      }}
                    >
                      <PikchaCustomCheckbox
                        labelStyle={{
                          color: palette.grey.lighter,
                          fontWeight: typography.fontWeightSemiMedium,
                        }}
                        disabled
                        value={true}
                        checked={true}
                        label={productNumber}
                      />
                    </div>
                  </div>
                );
              }

              return (
                <div
                  key={index}
                  style={{
                    marginRight: 0,
                    marginTop: "0.5em",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 11,
                      width: 36,
                    }}
                  >
                    <PikchaCheckbox
                      labelStyle={{
                        fontWeight: typography.fontWeightSemiMedium,

                        ...(statusCode === reservationStatusCode.soldOut
                          ? {
                              color: palette.grey.medium,
                            }
                          : {}),
                      }}
                      disabled={statusCode === reservationStatusCode.soldOut}
                      value={!!isChecked}
                      checked={!!isChecked}
                      onChange={() => {
                        onEditionSelected(option);
                        setOpen(false);
                      }}
                      label={productNumber}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
