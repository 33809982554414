import React, { useState } from "react";
import CartItems from "../../components/checkoutComponents/CartItems";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE, TypographyButton, TYPOGRAPHY_BUTTON_TYPE } from "@pikcha/typography";

export default ({
  shoppingCartItem,
  deleteItem,
}) => {

  const {
    id: shoppingItemId,
    imageTitle,
    sellerNickName,
    sellerUserHandle,
    type,
    border,
    frame,
    wrap,
    material,
    size,
    totalPricePresentment,
    galleryCardColor,
    totalEditions,
    imageRender
  } = shoppingCartItem;

  let expectedProductNumber = shoppingCartItem.productNumber;
  const [displayDeleteModal, setDisplayDeleteModal] = useState();

  const onCancelDelete = () => {
    setDisplayDeleteModal(false);
  };
  const doDeleteItem = () => {
    deleteItem(shoppingItemId);
    setDisplayDeleteModal(false);
  };

  return (
    <>
      {displayDeleteModal && (
        <>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Medium}
            style={{
              margin: "2em",
              textAlign: "center",
            }}
          >
            Are you sure that you want to remove this item?
          </TypographyBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
              <TypographyButton
                component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                onClick={(e) => onCancelDelete()}
              >
                Cancel
              </TypographyButton>
              <TypographyButton
                onClick={(e) => doDeleteItem()}
                buttonStyle={{
                  marginLeft: "1em",
                }}
              >
                Remove
              </TypographyButton>
          </div>
        </>
      )}
      {!displayDeleteModal && (
        <div
          style={{
            borderRadius: 6,
            padding: ".5em 1em 0em .5em",
            marginBottom: "0.5em"
          }}
        >
          <CartItems
            imageRender={imageRender}
            imageTitle={imageTitle}
            userHandle={sellerUserHandle}
            nickName={sellerNickName}
            type={type}
            material={material}
            size={size}
            border={border}
            frame={frame}
            wrap={wrap}
            galleryCardColor={galleryCardColor}
            productNumber={expectedProductNumber}
            totalEditions={totalEditions}
            totalPrice={totalPricePresentment}
            showDeleteButton={true}
            deleteItem={() => setDisplayDeleteModal(true)}
          />
        </div>
      )}
    </>
  );
};
