import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import ChangePasswordModal from "../../../containers/modals/ChangePasswordModal/ChangePasswordModal";
import { PikchaModal } from "../../../components/baseComponents";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import {
  TypographyBody, TYPOGRAPHY_BODY_SIZE, colors,
  TypographyButton, TYPOGRAPHY_BUTTON_TYPE, TYPOGRAPHY_BUTTON_TEXT
} from "@pikcha/typography";
import isMobile from "../../../components/Responsive/isMobile";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    padding: 0,
  },
  contentMargin: {
    backgroundColor: 'white',
    border: '0',
    paddingBottom: '1.5em !important'
  },
  accordionRoot: {
    boxShadow: 'none',
    backgroundColor: colors.Neutral.grey20,
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);

const Security = ({ account, expandedPanel, handleAccordionChange }) => {
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [twoFactorModal, setTwoFactorModal] = useState(false);

  const classes = useStyles();

  const handleChangePasswordModal = (state) => setChangePasswordModal(state);
  const handleTwoFactorModal = (state) => setTwoFactorModal(state);

  const isOnMobile = isMobile();

  return (
    <>
      <Accordion 
        expanded={expandedPanel === 'panel1'} 
        onChange={handleAccordionChange('panel1')}  
        className={classes.accordionRoot} 
        style={{ margin: 0 }} 
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          classes={{ expanded: classes.expandedSummary }}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            Security
          </TypographyBody>
        </StyledAccordionSummary>
        <AccordionDetails className={classes.contentMargin}>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            {account && account.user && account.user.userType !== "Email" ? (
              <></>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '3em',
                  marginBottom: '1.25em'
                }} >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                >
                  Password
                </TypographyBody>
                <TypographyButton
                  buttonText={isOnMobile ? TYPOGRAPHY_BUTTON_TEXT.Small : TYPOGRAPHY_BUTTON_TEXT.Regular}
                  buttonStyle={{ height: isOnMobile && '40px' }}
                  component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                  onClick={() => handleChangePasswordModal(true)}
                >
                  Update
                </TypographyButton>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                Two-Factor Authentication
              </TypographyBody>
              <TypographyButton
                size="small"
                buttonText={isOnMobile ? TYPOGRAPHY_BUTTON_TEXT.Small : TYPOGRAPHY_BUTTON_TEXT.Regular}
                component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                disabled={true}
                buttonStyle={{ height: isOnMobile && '40px', width: isOnMobile && '145px', alignSelf: 'center' }}
              >
                Coming Soon
              </TypographyButton>
            </div>
          </div>
          <ChangePasswordModal
            open={changePasswordModal}
            onClose={() => handleChangePasswordModal(false)}
          />
          <PikchaModal
            open={twoFactorModal}
            onClose={() => handleTwoFactorModal(false)}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {})(Security);
