import axios from "axios";

import {
  COLLECTION_ERROR,
  COLLECTION_LOADED,
  COLLECTION_LOADING,
  GET_USER_COLLECTION_MORE,
  GET_USER_COLLECTION_MORE_FAILED,
  COLLECTION_RESET
} from "./types";
import AuthorizeService from "../auth/AuthorizeService";

// Get My Collections
export const getUserCollections = (userId, count, start) => {
  return async (dispatch) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      dispatch(setLoading());
      const res = await axios.get(
        `api/image/${userId}/myCollection`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            skip: start - 1,
            count,
          },
        }
      );
      dispatch({
        type: COLLECTION_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: COLLECTION_ERROR,
        payload: err.response,
      });
    }
  };
};
export const getUserCollectionsMore = (userId) => {
  return async (dispatch, getState) => {
    try {
      const {
        myCollectionReducer: { start, count },
      } = getState();

      const token = await AuthorizeService.getAccessToken();

      const res = await axios.get(`api/image/${userId}/myCollection`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          skip: start - 1,
          count,
        },
      });
      dispatch({
        type: GET_USER_COLLECTION_MORE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_USER_COLLECTION_MORE_FAILED,
        payload: [],
      });
      console.log("getUserCollectionsMore ", err);
    }
  };
};
// Set Loading
export const setLoading = () => {
  return { type: COLLECTION_LOADING };
};

export const resetCollection = () => {
  return async (dispatch) => {
    dispatch({
      type: COLLECTION_RESET
    })
  }
}