import React from 'react';
import { Modal, useTheme } from '@material-ui/core';
import { Step4 } from './ExpiredItems/Step4';
import { Step5 } from '../../containers/checkout/Step5';

export const CheckoutOverlay = ({ step }) => {
  const { palette, shape, typography } = useTheme();

  return (
    <Modal
      style={{
        zIndex: 9999999,
      }}
      open
      disabledBackdropClick
      BackdropProps={{ transitionDuration: 1000 }}
    >
      <div
        className="full-width full-height flex-center"
        style={{
          justifyContent: 'center',
        }}
      >
        {step === 5 ? (
          <Step5/>
        ) : (
          <Step4/>
        )}
      </div>
    </Modal>
  );
}
