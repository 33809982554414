import React from 'react';
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
    const { palette } = useTheme();
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path 
                    fill-rule="evenodd" 
                    clip-rule="evenodd" 
                    d="M12.0058 23.9986C5.38882 23.9986 0.00585938 18.6152 0.00585938 11.9994C0.00585938 5.38473 5.38882 0.00141907 12.0058 0.00141907C18.6229 0.00141907 24.0059 5.38473 24.0059 11.9994C24.0059 18.6152 18.6229 23.9986 12.0058 23.9986ZM21.5961 13.5563C21.2642 13.4528 18.5897 12.6538 15.5462 13.1407C16.8166 16.6311 17.3339 19.4759 17.4331 20.0655C19.612 18.5923 21.1638 16.2589 21.5961 13.5563ZM15.8006 20.9555C15.6552 20.1035 15.0913 17.133 13.7276 13.5903C13.707 13.5975 13.6857 13.6038 13.6635 13.6117C8.18576 15.5192 6.21906 19.3203 6.04403 19.6775C7.69027 20.961 9.76056 21.7272 12.0058 21.7272C13.351 21.7271 14.6341 21.4513 15.8006 20.9555ZM4.78979 18.5086C5.00944 18.1316 7.67562 13.7183 12.6848 12.099C12.8116 12.0579 12.9392 12.02 13.0672 11.9844C12.8238 11.4316 12.5587 10.8807 12.2798 10.3354C7.42949 11.7872 2.72269 11.7271 2.29702 11.7177C2.29423 11.8165 2.29271 11.9157 2.29271 12.0144C2.29271 14.5095 3.23831 16.7868 4.78979 18.5086ZM2.49776 10.0256C2.93251 10.031 6.93395 10.0477 11.4776 8.84241C9.86846 5.98102 8.13247 3.57562 7.876 3.22555C5.15882 4.50662 3.12727 7.0116 2.49776 10.0256ZM9.72973 2.57313C9.99842 2.93233 11.7624 5.33418 13.3541 8.25875C16.8086 6.96267 18.2699 4.99907 18.4443 4.75087C16.7293 3.22794 14.4736 2.30363 12.0058 2.30363C11.2219 2.30363 10.46 2.39688 9.72973 2.57313ZM19.5242 5.87277C19.3199 6.15023 17.6915 8.23664 14.1006 9.70151C14.3267 10.1655 14.5431 10.6365 14.7455 11.1091C14.8163 11.2775 14.8861 11.4442 14.9541 11.6102C18.1862 11.204 21.3973 11.8548 21.7186 11.9236C21.6968 9.63118 20.8777 7.52611 19.5242 5.87277Z"
                    fill={color || palette.grey.medium}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" transform="translate(0.00585938)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
