import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import ZoomInRoundedIcon from "@material-ui/icons/ZoomInRounded";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { ProductItemShareButton } from "./ProductItemShareButton";
import { ProductItemFullscreenButton } from "./ProductItemFullscreenButton";
import Tooltip from "@material-ui/core/Tooltip";
import ImageMagnifier from "../../../components/ImageMagnifier/ImageMagnifier";
import {
  PikchaEditImageButton,
  PikchaIcon,
  PikchaImageNumber,
  PikchaImageUploadDate,
  PikchaLikeButton
} from "../../../components/baseComponents";
import {
  getCreatedAt,
  getCustomisePath,
  getImageNumberAsString,
  removeModalOverlay,
} from "../../../helpers/utils";
import { ICON_SIZE_SMALL } from "../../../helpers/constants";
import { handleRelatedImagesPortrait } from "../../../actions/relatedImages";
import { removeModal } from "../../../actions/modal";
import { handleSavedImage } from "../../../actions/account";
import { handleAuthModal } from "../../../actions/authModal";
import { setAlert } from "../../../actions/alert";
import { isUserLoggedIn, userId } from "../../../selectors/account";
import RelatedImages from "../../RelatedImages/RelatedImages";
import RelatedAlbums from "../../RelatedAlbums/RelatedAlbums";
import FullscreenModal from "../../modals/FullscreenModal/FullscreenModal";
import "./ProductItem.css";
import EditPhotoModal
  from "../../../containers/modals/EditPhotoModal/EditPhotoModal";
import { ProductItemPurchaseButton } from "./ProductItemPurchaseButton";
import ReadMore from "../../../components/ReadMore";
import { ProductItemDesktopLoadingSkeleton } from "./ProductItemDesktopLoadingSkeleton";
import Chip from "@material-ui/core/Chip";
import { useMediaQuery } from "react-responsive";
import { Blurhash } from "react-blurhash";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  CountDownTimer,
  colors
} from "@pikcha/typography";
import PriceRange from "./PriceRange";
import Avatar from '@material-ui/core/Avatar';
import { Link } from "react-router-dom";

const titleCase = require("ap-style-title-case");

const ProductItem = ({
  image,
  handleSavedImage,
  modal,
  loggedIn,
  handleAuthModal,
  removeModal,
  userId,
  onClose,
  handleRelatedImagesPortrait,
  relatedImages,
}) => {
  const [productLines, setProductLines] = useState([]);

  useEffect(() => {
    const { productList } = image;
    const newArray = [];

    productList.forEach(o => newArray.push(o.productType.type));

    setProductLines(newArray);
  }, []);

  const [canvasWidth, setCanvasWidth] = useState();
  const [canvasHeight, setCanvasHeight] = useState();

  const isOnDesktopHD = useMediaQuery({ minWidth: 1600 });

  const { photoLoading } = relatedImages;

  let artistStatus;
  if (image) {
    if (image.artist) {
      artistStatus = image.artist.userStatus;
    }
  }

  let isArtistDeleted = artistStatus === "Inactive";

  const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
  const [isFullscreenModalOpen, setIsFullscreenModalOpen] = useState(false);
  const [EditModalShow, setEditModalShow] = useState(false);
  const [isPublished, setIsPublished] = useState(true)

  const getIsPublished = (data) => {
    setIsPublished(data)
  }

  const { palette, typography } = useTheme();

  const history = useHistory();

  const { isSaved } = image;
  const saved = !!isSaved;

  const _handleSavedImage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!loggedIn) {
      handleAuthModal(true, "saved-images");
      return;
    }
    handleSavedImage(image.id);
  };

  const _handleEditImage = (e) => {
    onClose();

    e.preventDefault();
    e.stopPropagation();

    const imgUrl = image.friendlyUrl.split("/")[1];

    history.push({
      pathname: `/edit/${imgUrl}`,
      state: { imageDetails: image },
    });
  };
  const scrollRef = useRef(null);

  const scroll = () => {
    if (document.getElementsByClassName("modalScroll")[0] !== undefined) {
      document.getElementsByClassName("modalScroll")[0].scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  const onPrintAndBuyClicked = () => {
    removeModal({
      moveForward: true,
    });
    removeModalOverlay();

    history.push(getCustomisePath(image), { image: image });
  };

  const onOpenSharingModal = () => {
    setIsSharingModalOpen(true);

  };
  const onCloseSharingModal = () => {
    setIsSharingModalOpen(false);
  };

  const onOpenFullscreenModal = () => {
    setIsFullscreenModalOpen(true);
  };
  const onCloseFullscreenModal = () => {
    setIsFullscreenModalOpen(false);
  };

  const [imageVisible, setImageVisible] = useState(false);

  const renderImage = () => (
    <ImageMagnifier
      handleIsPortrait={handleRelatedImagesPortrait}
      isPortrait={relatedImages.isPortrait}
      image={image}
      needToHandleClick={true}
      handleClick={onOpenFullscreenModal}
      onImageLoad={() => setImageVisible(true)}
    />
  );

  useEffect(() => {
    const photo = document.getElementsByClassName("ProductItem-photo");
    const canvas = photo[0].getElementsByTagName("canvas");
    if (image.aspRatioX <= image.aspRatioY) {
      setCanvasWidth(canvas[0].clientHeight * image.aspRatioX / image.aspRatioY);
    } else {
      setCanvasHeight(canvas[0].clientWidth * image.aspRatioY / image.aspRatioX);
    }
  }, [image.aspRatioX, image.aspRatioY]);

  return (
    <div
      style={{
        paddingTop: "1em",
        paddingBottom: "2em",
        paddingLeft: "2.5em",
        paddingRight: "1em",
      }}
    >
      <div
        ref={scrollRef}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {EditModalShow && (
          <EditPhotoModal
            imageDetails={image}
            onViewAllArtworkPrices={() => {
            }}
            onCloseModal={() => {
              setEditModalShow(false);
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            maxWidth: modal ? undefined : 1200,
            maxHeight: 850,
            height: "80vh",
          }}
        >
          <div
            style={{
              width: "33%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="flex-1-1 flex-column"
            >
              <div className="ProductItem-content">
                <div className="flex">
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  >
                    {image && image.title ? titleCase(image.title) : ""}
                  </TypographyBody>
                  <div
                    className="pl-6-em flex flex-grow-1"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {userId === image.artist.id && (
                      <Tooltip
                        style={{ zIndex: "999999" }}
                        title={
                          <span style={{ fontSize: "8px" }}>Edit this image</span>}
                        interactive
                        disableTouchListener
                        enterDelay={1000}
                        leaveDelay={0}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "0.5em",
                            marginBottom: "4px",
                          }}
                        >
                          <PikchaEditImageButton
                            onClick={(e) => {
                              _handleEditImage(e);
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip
                      style={{ zIndex: "999999" }}
                      title={
                        <span style={{ fontSize: "8px" }}>Share this image</span>}
                      interactive
                      disableTouchListener
                      enterDelay={1000}
                      leaveDelay={0}
                    >
                      <div
                        style={{
                          marginLeft: "0.5em",
                          marginBottom: "4px",
                        }}
                      >
                        <ProductItemShareButton
                          image={image}
                          isSharingModalOpen={isSharingModalOpen}
                          onCloseSharingModal={onCloseSharingModal}
                          onOpenSharingModal={onOpenSharingModal}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      style={{ zIndex: "999999" }}
                      title={
                        <span style={{ fontSize: "8px" }}>Save this image</span>}
                      interactive
                      disableTouchListener
                      enterDelay={1000}
                      leaveDelay={0}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          marginLeft: "0.5em",
                        }}
                      >
                        <PikchaLikeButton
                          saved={saved}
                          onClick={_handleSavedImage}
                          color="black"
                          size="small"
                          noPadding
                          style={{
                            marginBottom: 4,
                          }}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      style={{ zIndex: "999999" }}
                      title={
                        <span style={{ fontSize: "8px" }}>Fullscreen view</span>}
                      interactive
                      disableTouchListener
                      enterDelay={1000}
                      leaveDelay={0}
                    >
                      <div
                        style={{
                          marginLeft: "0.5em",
                          marginBottom: "4px",
                        }}
                      >
                        <ProductItemFullscreenButton
                          image={image}
                          isFullscreenModalOpen={isFullscreenModalOpen}
                          onCloseFullscreenModal={onCloseFullscreenModal}
                          onOpenFullscreenModal={onOpenFullscreenModal}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {image.totSold < image.totalEditions && (
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    color="neutral/grey50"
                  >
                    Edition of {image.totalEditions}
                  </TypographyBody>
                )}
                {image.dateCreated && image.dateCreated !== "00-00-0000" && (
                  <PikchaImageUploadDate
                    style={{
                      fontSize: typography.smallest,
                      fontWeight: typography.fontWeightMedium,
                      color: palette.text.secondary,
                    }}
                  >
                    Created&nbsp;·&nbsp;{getCreatedAt(image.dateCreated)}
                  </PikchaImageUploadDate>
                )}
                {image && image.location && image.location.length > 0 && (
                  <div
                    larger
                    lightGrey
                    medium
                    className="flex-center ProductItem-locationMeta"
                    style={{
                      marginTop: ".1em",
                      marginLeft: "-4px",
                    }}
                  >
                    <PikchaIcon
                      size="large"
                      noMarginAuto
                      icon={<LocationOnRoundedIcon />}
                      style={{
                        height: "16px",
                        width: "16px",
                        fontSize: 16,
                      }}
                    />
                    <div
                      style={{
                        marginLeft: "4px",
                        fontSize: typography.smallest,
                        maxWidth: "300px",
                        fontWeight: typography.fontWeightMedium,
                        color: palette.text.secondary,
                        paddingTop: "2px",
                      }}
                      className="ProductItem-locationText"
                    >
                      {image.location}
                    </div>
                  </div>
                )}
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                  style={{
                    marginTop: "1em"
                  }}
                >
                  <ReadMore characters={700}>
                    {image ? image.caption : ""}
                  </ReadMore>
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                  color="neutral/grey50"
                  style={{
                    marginTop: "1em"
                  }}
                >
                  Artist
                </TypographyBody>
                <div
                  className="flex mb-1-em"
                  style={{
                    alignItems: "center"
                  }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={image.artist.avatar}
                    style={{
                      marginRight: "1em",
                      width: 16,
                      height: 16,
                      borderRadius: 8,
                      border: "1px solid #E0E0E0",
                      backgroundColor: colors.Neutral.white00
                    }}
                  />
                  <Link
                    to={image.artist.userHandle}
                    onClick={onClose}
                  >
                    <TypographyBody
                      style={{
                        textDecoration: "underline"
                      }}
                    >
                      {image.artist.nickName}
                    </TypographyBody>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="flex-column flex-end"
              style={{ marginTop: "auto" }}
            >
              <div
                className="flex"
                style={{
                  borderRadius: 10,
                  border: `1px solid ${colors.Neutral.grey30}`,
                  width: "100%",
                  alignItems: "center",
                  marginBottom: '1.5em'
                }}
              >
                <PriceRange minPrice={image.minimumPrice} maxPrice={image.maximumPrice} />
                {!isPublished &&
                  <div
                    className="ml-1-em mr-1-em"
                    style={{
                      height: 50,
                      width: 1,
                      background: colors.Neutral.grey30
                    }}
                  />
                }
                <CountDownTimer
                  countingDownTime={image.publishAtLocal}
                  getIsPublished={getIsPublished}
                />
              </div>
              <ProductItemPurchaseButton
                image={image}
                isArtistDeleted={isArtistDeleted}
                onClick={onPrintAndBuyClicked}
                productLines={productLines}
              />
            </div>
          </div>
          <div
            className="flex-column"
            style={{
              width: "67%",
            }}
          >
            <div className="flex flex-column flex-grow-1 flex-stretch full-height">
              <div
                style={{
                  flexGrow: 1,
                  padding: "2em",
                  maxHeight: image.totSold >= 100 ? "calc(100% - 36px)" : "100%",
                }}
                className="ProductItem-imageFrame flex-2-1 flex-column flex-center ml-2-em mr-2-em"
              >
                <div
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100vh",
                    height: "100%",
                    width: "100%",
                  }}
                  className="ProductItem-photo flex-1-1 flex-column flex-center margin-auto justify-center"
                >
                  {!photoLoading ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {renderImage()}
                      {!imageVisible &&
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Blurhash
                            hash={!!image.blurHash && image.blurHash.length === 36
                              ? image.blurHash
                              : "UDF7G4?v?wRP_NWAM_j[Rjt8MxIoIVIT%Ne-"}
                            width={image.aspRatioX <= image.aspRatioY
                              ? canvasWidth
                              : "100%"}
                            height={image.aspRatioX <= image.aspRatioY
                              ? "100%"
                              : canvasHeight}
                            resolutionX={300}
                            resolutionY={300}
                            punch={1}
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                            }}
                          />
                        </div>
                      }
                    </div>
                  ) : (
                    <ProductItemDesktopLoadingSkeleton
                      isOnDesktopHD={isOnDesktopHD}
                      aspectRatio={relatedImages.aspectRatio}
                      blurHash={image.blurHash}
                    />
                  )}
                  <div
                    style={{
                      position: "absolute",
                      justifyContent: "flex-end",
                      bottom: 0,
                    }}
                    className="flex full-width"
                  >
                    <ZoomInRoundedIcon
                      style={{
                        ...ICON_SIZE_SMALL,
                        marginRight: 8,
                        marginBottom: 8,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-1-em">
        {image && image.tags && image.tags.length > 0 && (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                marginLeft: "auto",
                paddingRight: "2em",
                textAlign: "right",
                // marginBottom: "2em",
              }}
            >
              {image.tags.map((each) => (
                <span>
                  <Chip
                    variant="outlined"
                    size="small"
                    label={each}
                    component="a"
                    onClick={() => {
                      removeModal();
                      history.push(`/search/${each}`);
                    }}
                    clickable
                    style={{
                      fontWeight: 500,
                      margin: 2,
                      color: palette.text.primary,
                      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                      border: `1px solid rgba(0,0,0,0.1)`,
                    }}
                  />
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: "6em",
          paddingRight: "2em",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <RelatedAlbums
          image={image}
          scrollToTop={scroll}
          style={{
            width: "100%",
            maxWidth: modal ? undefined : 1200,
          }}
        />
      </div>
      <div
        style={{
          paddingRight: "2em",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <RelatedImages
          onClose={() => {
            removeModal({
              moveForward: true,
            });
            removeModalOverlay();
            onClose();
          }}
          image={image}
          scrollToTop={scroll}
          style={{
            width: "100%",
            maxWidth: modal ? undefined : 1200,
          }}
        />
      </div>
      <FullscreenModal
        image={image}
        open={isFullscreenModalOpen}
        onClose={onCloseFullscreenModal}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: isUserLoggedIn(state),
  userId: userId(state),
  relatedImages: state.relatedImagesReducer,
});

export default connect(mapStateToProps, {
  setAlert,
  handleSavedImage,
  handleAuthModal,
  removeModal,

  handleRelatedImagesPortrait,
})(ProductItem);
