import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

export const PikchaPrimaryModalButton = withStyles((theme) => ({
  root: {
    width: '100%',
    color: '#FFF !important',
    boxShadow: 'none',
    textTransform: 'none',
    textDecoration: 'none',
    borderRadius: theme.shape.borderRadius,
    fontSize: 20,
    fontWeight: 600,
    padding: '12px 16px',
    border: '1px solid',
    lineHeight: 1.34,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#a600ff',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
}))(Button);

export const PikchaPrimarySpan = withStyles({
  root: {
    position: 'relative',
    width: '60%',
    textAlign: 'center',
    color: '#FFF !important',
    boxShadow: 'none',
    textTransform: 'none',
    textDecoration: 'none',
    fontSize: 20,
    fontWeight: 600,
    padding: '12px 16px',
    border: '1px solid',
    lineHeight: 1.34,
    // background: '#960ae1',
    backgroundImage: 'linear-gradient(45deg,#081c69,#44159b 50%,#770fc6)',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#a600ff',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(DialogContentText);
