import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";

import Orientation from "./Orientation";

export default ({ onChange, value }) => {
  const [selectedOrientation, setSelectedOrientation] = useState(value);

  const { palette } = useTheme();

  const onSelectOrientation = (orientationToSelect) => {
    let newOrientation;
    if (selectedOrientation !== orientationToSelect) {
      newOrientation = orientationToSelect;
    } else {
      newOrientation = null;
    }
    setSelectedOrientation(newOrientation);
    onChange(newOrientation);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "258px",
          height: "64px",
          backgroundColor: palette.common.white,
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Orientation
            onClick={onSelectOrientation}
            orientation="portrait"
            selectedOrientation={selectedOrientation}
          />
          <Orientation
            onClick={onSelectOrientation}
            orientation="landscape"
            selectedOrientation={selectedOrientation}
          />
          <Orientation
            onClick={onSelectOrientation}
            orientation="square"
            selectedOrientation={selectedOrientation}
          />
        </div>
      </div>
    </div>
  );
};
