// Auth Types
export const DEAUTHENTICATE = 'DEAUTHENTICATE';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const ADD_ROUTE = 'ADD_ROUTE';

// Account Types
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_PAYMENT_CHANGED = "USER_PAYMENT_CHANGED";
export const USER_TOKEN = 'USER_TOKEN';
export const GET_USED_SIGNATURE = 'GET_USED_SIGNATURE';
export const GET_USED_SIGNATURE_ERR = 'GET_USED_SIGNATURE_ERR';
export const SIGNATURE_REMOVED = 'SIGNATURE_REMOVED';
export const HANDLE_UPLOADED = 'HANDLE_UPLOADED';
export const GET_RELEASED_FORMS = "GET_RELEASED_FORMS";
export const JOYRIDE_SHOWN = 'JOYRIDE_SHOWN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const USER_ERROR = 'USER_ERROR';
export const SIGNATURE_LOADING = 'SIGNATURE_LOADING';
export const SIGNATURE_LOADED = 'SIGNATURE_LOADED';
export const ARTIST_FOLLOWED = 'ARTIST_FOLLOWED';
export const ARTIST_UNFOLLOWED = 'ARTIST_UNFOLLOWED';
export const ARTIST_NOTIFICATION_PREFERENCE_CHANGED = 'ARTIST_NOTIFICATION_PREFERENCE_CHANGED';
export const JOYRIDE_ACTIVE_STEP = 'JOYRIDE_ACTIVE_STEP';
export const JOYRIDE_WAS_TRIGGERED = 'JOYRIDE_WAS_TRIGGERED';

export const HANDLE_CROPPER_STATE = 'HANDLE_CROPPER_STATE';
export const HANDLE_CROPPER_RESET = 'HANDLE_CROPPER_RESET';
export const GET_CROPPER_SRC = 'GET_CROPPER_SRC';
export const GET_ORG_SRC = 'GET_ORG_SRC';
export const GET_ASPECTRATIO = 'GET_ASPECTRATIO';
export const GET_SELECTED_RATIO = 'GET_SELECTED_RATIO';
export const GET_ORG_IMG_DATA = 'GET_ORG_IMG_DATA';
export const HANDLE_UPLOAD = 'HANDLE_UPLOAD';

export const USER_SIGNATURE_UPDATED = 'USER_SIGNATURE_UPDATED';

export const REMOVE_TIMER = 'REMOVE_TIMER';
export const ADD_TIMER = 'ADD_TIMER';
export const CHANGE_TIMER = 'CHANGE_TIMER';
export const TIMER_MESSAGE = 'TIMER_MESSAGE';
export const UPDATE_EXISTING_TIMER = 'UPDATE_EXISTING_TIMER';

// Carousel
export const CAROUSEL_LOADING = 'CAROUSEL_LOADING';
export const CAROUSEL_LOADED = 'CAROUSEL_LOADED';
export const CAROUSEL_ERROR = 'CAROUSEL_ERROR';

// Notification Types
export const NOTIFICATION_SHOW = 'NOTIFICATION_SHOW';
export const NOTIFICATION_HIDE = 'NOTIFICATION_HIDE';
export const NOTIFICATION_TYPE = 'NOTIFICATION_TYPE';
export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE';
export const NOTIFICATION_ITEM = 'NOTIFICATION_ITEM';

// ShoppingCart Types
export const SHOPPING_CART_LOADING = 'SHOPPING_CART_LOADING';
export const SHOPPING_CART_LOADED = 'SHOPPING_CART_LOADED';
export const SHOPPING_CART_ADD = 'SHOPPING_CART_ADD';
export const SHOPPING_CART_REMOVE = 'SHOPPING_CART_REMOVE';
export const SHOPPING_CART_UPDATED = 'SHOPPING_CART_UPDATED';
export const SHOPPING_CART_ERROR = 'SHOPPING_CART_ERROR';

// Alert Types
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Shopping Cart Types
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';
// Nav Types
export const DROPDOWN_OPEN = 'DROPDOWN_OPEN';
export const DROPDOWN_CLOSED = 'DROPDOWN_CLOSED';

// Gallery Types
export const GALLERY_LOADED = 'GALLERY_LOADED';
export const GALLERY_LOADING = 'GALLERY_LOADING';
export const GALLERY_ERROR = 'GALLERY_ERROR';
export const GALLERY_RESET = 'GALLERY_RESET';

export const GALLERY_SEARCH_LOADED = 'GALLERY_SEARCH_LOADED';
export const GALLERY_SEARCH_LOADING = 'GALLERY_SEARCH_LOADING';
export const GALLERY_SEARCH_ERROR = 'GALLERY_SEARCH_ERROR';
export const GALLERY_SEARCH_RESET = 'GALLERY_SEARCH_RESET';
export const GALLERY_CHANGE_IMAGE_TYPE = 'GALLERY_CHANGE_IMAGE_TYPE';
export const GALLERY_FILTERED_LOADED = 'GALLERY_FILTERED_LOADED';

export const GALLERY_CACHED_STATE = 'GALLERY_CACHED_STATE';

export const GALLERY_SAVED_LOADED = 'GALLERY_SAVED_LOADED';
export const GALLERY_SAVED_LOADING = 'GALLERY_SAVED_LOADING';
export const GALLERY_SAVED_ERROR = 'GALLERY_SAVED_ERROR';
export const GALLERY_SAVED_RESET = 'GALLERY_SAVED_RESET';

// Collection Types
export const COLLECTION_LOADED = 'COLLECTION_LOADED';
export const COLLECTION_ERROR = 'COLLECTION_ERROR';
export const COLLECTION_LOADING = 'COLLECTION_LOADING';
export const COLLECTION_RESET = 'COLLECTION_RESET';
export const GET_USER_COLLECTION_MORE = 'GET_USER_COLLECTION_MORE';
export const GET_USER_COLLECTION_MORE_FAILED = 'GET_USER_COLLECTION_MORE_FAILED';
export const GALLERY_FILTERED_LOADED_ERROR = 'GALLERY_FILTERED_LOADED_ERROR';

export const GALLERY_FOLLOWING_LOADED = 'GALLERY_FOLLOWING_LOADED';
export const GALLERY_FOLLOWING_LOADING = 'GALLERY_FOLLOWING_LOADING';
export const GALLERY_FOLLOWING_ERROR = 'GALLERY_FOLLOWING_ERROR';
export const GALLERY_FOLLOWING_RESET = 'GALLERY_FOLLOWING_RESET';

export const FRIENDLY_URL_IMAGE_LOADED = 'FRIENDLY_URL_IMAGE_LOADED';
export const FRIENDLY_URL_IMAGE_LOADING = 'FRIENDLY_URL_IMAGE_LOADING';
export const FRIENDLY_URL_IMAGE_ERROR = 'FRIENDLY_URL_IMAGE_ERROR';
export const FRIENDLY_URL_IMAGE_RESET = 'FRIENDLY_URL_IMAGE_RESET';

// ALBUMS types
export const ALBUMS_LOADED = 'ALBUMS_LOADED';
export const ALBUMS_LOADING = 'ALBUMS_LOADING';
export const ALBUMS_ERROR = 'ALBUMS_ERROR';
export const ALBUMS_RESET = 'ALBUMS_RESET';
export const ALBUMS_IMAGE_SELECTED = 'ALBUMS_IMAGE_SELECTED';
export const ALBUMS_IMAGE_DESELECTED = 'ALBUMS_IMAGE_DESELECTED';
export const ALBUMS_IMAGE_REARRANGE = 'ALBUMS_IMAGE_REARRANGE';
export const ALBUMS_SELECTED_IMAGES_RESET = 'ALBUMS_SELECTED_IMAGES_RESET';
export const ALBUMS_IMAGES_SELECTED = 'ALBUMS_IMAGES_SELECTED';
export const FRIENDLY_URL_ALBUM_LOADED = 'FRIENDLY_URL_ALBUM_LOADED';
export const FRIENDLY_URL_ALBUM_ERROR = 'FRIENDLY_URL_ALBUM_ERROR';

// Pikcha100 Types
export const PIKCHA100_LOADED = 'PIKCHA100_LOADED';
export const PIKCHA100_LOADING = 'PIKCHA100_LOADING';
export const PIKCHA100_ERROR = 'PIKCHA100_ERROR';
export const PIKCHA100_RESET = 'PIKCHA100_RESET';

// Artist100 Types
export const ARTIST100_LOADED = 'ARTIST100_LOADED';
export const ARTIST100_LOADING = 'ARTIST100_LOADING';
export const ARTIST100_ERROR = 'ARTIST100_ERROR';
export const ARTIST100_RESET = 'ARTIST100_RESET';

// Product Types
export const GET_PRODUCT = 'GET_PRODUCT';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const SET_SIZE = 'SET_SIZE';
export const SET_MATERIAL = 'SET_MATERIAL';
export const SET_FRAME = 'SET_FRAME';
export const SET_BORDER = 'SET_BORDER';
export const SET_FINISH = 'SET_FINISH';
export const CLEAR_CUSTOMISATIONS = 'CLEAR_CUSTOMISATIONS';

// Profile Types
export const PROFILE_LOADED = 'PROFILE_LOADED';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const ARTIST_PROFILE_INFO_LOADING = 'ARTIST_PROFILE_INFO_LOADING';
export const ARTIST_PROFILE_INFO_LOADED = 'ARTIST_PROFILE_INFO_LOADED';
export const ARTIST_PROFILE_INFO_ERROR = 'ARTIST_PROFILE_INFO_ERROR';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const PROFILE_RESET = 'PROFILE_RESET';
export const ARTIST_PHOTOS_LOADED = 'ARTIST_PHOTOS_LOADED';
export const ARTIST_PHOTOS_LOADING = 'ARTIST_PHOTOS_LOADING';
export const ARTIST_PHOTOS_ERROR = 'ARTIST_PHOTOS_ERROR';
export const ARTIST_PHOTOS_RESET = 'ARTIST_PHOTOS_RESET';
export const PROFILE_ONBOARDING_MODAL = 'PROFILE_ONBOARDING_MODAL';
export const RESET_UPDATED_FIELDS = 'RESET_UPDATED_FIELDS';

export const PROFILE_ONBOARDING_INIT = 'PROFILE_ONBOARDING_INIT';

export const ONBOARDING_FLOW_INIT = 'PROFILE_INIT_ONBOARDING_FLOW';

// Cart Types
export const PRODUCT_ADDED = 'PRODUCT_ADDED';
export const PRODUCT_REMOVED = 'PRODUCT_REMOVED';
export const CLEAR_CART = 'CLEAR_CART';

export const ADD_TO_ALBUM_IMAGES_LOADING = 'ADD_TO_ALBUM_IMAGES_LOADING';
export const ADD_TO_ALBUM_IMAGES_LOADED = 'ADD_TO_ALBUM_IMAGES_LOADED';
export const ADD_TO_ALBUM_IMAGES_RESET = 'ADD_TO_ALBUM_IMAGES_RESET';
export const ADD_TO_ALBUM_IMAGES_ERROR = 'ADD_TO_ALBUM_IMAGES_ERROR';

// Modal Types
export const MODAL_CREATED = 'MODAL_CREATED';
export const MODAL_REMOVED = 'MODAL_REMOVED';

// Related Images
export const RELATED_IMAGES_LOADED = 'RELATED_IMAGES_LOADED';
export const RELATED_IMAGES_ERROR = 'RELATED_IMAGES_ERROR';
export const RELATED_IMAGES_LOADING = 'RELATED_IMAGES_LOADING';
export const RELATED_IMAGES_RESET = 'RELATED_IMAGES_RESET';

export const RELATED_IMAGES_PORTRAIT = 'RELATED_IMAGES_PORTRAIT';
export const RELATED_IMAGES_PHOTO_LOADING = 'RELATED_IMAGES_PHOTO_LOADING';
export const RELATED_IMAGES_PHOTO_LOADING_ASPECT_RATIO = 'RELATED_IMAGES_PHOTO_LOADING_ASPECT_RATIO';

// Related Albums
export const RELATED_ALBUMS_LOADED = 'RELATED_ALBUMS_LOADED';
export const RELATED_ALBUMS_ERROR = 'RELATED_ALBUMS_ERROR';
export const RELATED_ALBUMS_LOADING = 'RELATED_ALBUMS_LOADING';
export const RELATED_ALBUMS_RESET = 'RELATED_ALBUMS_RESET';

// Search
export const SEARCH_LOADED = 'SEARCH_LOADED';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_RESET = 'SEARCH_RESET';
export const SEARCH_ARTISTS_LOADED = 'SEARCH_ARTISTS_LOADED';
export const SEARCH_ARTISTS_LOADING = 'SEARCH_ARTISTS_LOADING';
export const SEARCH_ALBUMS_LOADED = 'SEARCH_ALBUMS_LOADED';
export const SEARCH_ALBUMS_LOADING = 'SEARCH_ALBUMS_LOADING';

// ACCOUNT NOTIFICATIONS
export const ACCOUNT_NOTIFICATIONS_LOADED = 'ACCOUNT_NOTIFICATIONS_LOADED';
export const ACCOUNT_NOTIFICATIONS_ERROR = 'ACCOUNT_NOTIFICATIONS_ERROR';
export const ACCOUNT_NOTIFICATIONS_LOADING = 'ACCOUNT_NOTIFICATIONS_LOADING';
export const ACCOUNT_NOTIFICATIONS_READ = 'ACCOUNT_NOTIFICATIONS_READ';
export const ACCOUNT_NOTIFICATIONS_DELETE = 'ACCOUNT_NOTIFICATIONS_DELETE';
export const ACCOUNT_NOTIFICATIONS_MARK_ALL_AS_READ = 'ACCOUNT_NOTIFICATIONS_MARK_ALL_AS_READ';

// FOLLOWING
export const FOLLOWING_LOADING = 'FOLLOWING_LOADING';
export const FOLLOWING_LOADED = 'FOLLOWING_LOADED';
export const FOLLOWING_ERROR = 'FOLLOWING_ERROR';
export const FOLLOWING_OPTION = 'FOLLOWING_OPTION';
export const FOLLOWING_SEARCH_BEGIN = 'FOLLOWING_SEARCH_BEGIN';

// Settings
export const SETTINGS_LOADED = 'SETTINGS_LOADED';

export const HANDLE_SIDE_DRAWER = 'HANDLE_SIDE_DRAWER';

export const UPDATE_INVITE_CODE = 'UPDATE_INVITE_CODE';

export const HANDLE_SIGNATURE_TAB = 'HANDLE_SIGNATURE_TAB';
export const HANDLE_SIGNATURE_FONT = 'HANDLE_SIGNATURE_FONT';
export const HANDLE_DRAWN_SIGNATURE_SVG = 'HANDLE_DRAWN_SIGNATURE_SVG';
export const HANDLE_TYPED_SIGNATURE_SVG = 'HANDLE_TYPED_SIGNATURE_SVG';
export const HANDLE_TYPED_SIGNATURE = 'HANDLE_TYPED_SIGNATURE';
export const HANDLE_REDO_SIGNATURE_PAD = 'HANDLE_REDO_SIGNATURE_PAD';
export const HANDLE_BRUSH_STROKE = 'HANDLE_BRUSH_STROKE';
export const HANDLE_BRUSH_SMOOTHING = 'HANDLE_BRUSH_SMOOTHING';
export const HANDLE_ADAPTIVE_STROKE = 'HANDLE_ADAPTIVE_STROKE';
export const HANDLE_BRUSH_MODE = 'HANDLE_BRUSH_MODE';
export const HANDLE_SIGNATURE_UPDATE_SUCCESS = 'HANDLE_SIGNATURE_UPDATE_SUCCESS';

export const HANDLE_LOGIN_USERNAME = 'HANDLE_LOGIN_USERNAME';
export const HANDLE_LOGIN_PASSWORD = 'HANDLE_LOGIN_PASSWORD';
export const HANDLE_LOGIN_LOADING_STATUS = 'HANDLE_LOGIN_LOADING_STATUS';

export const HANDLE_REGISTER_FIRST_NAME = 'HANDLE_REGISTER_FIRST_NAME';
export const HANDLE_REGISTER_LAST_NAME = 'HANDLE_REGISTER_LAST_NAME';
export const HANDLE_REGISTER_USERNAME = 'HANDLE_REGISTER_USERNAME';
export const HANDLE_REGISTER_PASSWORD = 'HANDLE_REGISTER_PASSWORD';
export const HANDLE_REGISTER_LOADING_STATUS = 'HANDLE_REGISTER_LOADING_STATUS';

export const SHOW_AUTH_MODAL = 'SHOW_AUTH_MODAL';
export const HIDE_AUTH_MODAL = 'HIDE_AUTH_MODAL';

export const HANDLE_AUTH_MODAL = 'HANDLE_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';
export const FROM_FOLLOWING_SECTION = 'FROM_FOLLOWING_SECTION';

export const SHOW_AGE_RESTRICTION = 'SHOW_AGE_RESTRICTION';
export const CANCEL_AGE_RESTRICTION = 'CANCEL_AGE_RESTRICTION';
export const PASS_AGE_RESTRICTION = 'PASS_AGE_RESTRICTION';

export const HANDLE_SAFE_SEARCH = 'HANDLE_SAFE_SEARCH';

export const HANDLE_SAVED_IMAGE = 'HANDLE_SAVED_IMAGE';
export const GET_SAVED_IMAGES = 'GET_SAVED_IMAGES';

export const HANDLE_ARTIST_REGISTRATION_FORM = 'HANDLE_ARTIST_REGISTRATION_FORM';

export const HANDLE_ACCOUNT_LOAD_FROM_ONBOARDING = 'HANDLE_ACCOUNT_LOAD_FROM_ONBOARDING';
export const FORCE_SIGNATURE_COVER_RERENDER = 'FORCE_SIGNATURE_COVER_RERENDER';

export const BASE_64_STRING = 'BASE_64_STRING';
export const BASE_64_SIGNATURE_STRING = 'BASE_64_SIGNATURE_STRING';

export const FORCE_GALLERY_RERENDER = 'FORCE_GALLERY_RERENDER';

export const ARTIST_FOLLOWING_UPDATING = 'ARTIST_FOLLOWING_UPDATING';
export const ARTIST_NOTIFICATION_UPDATING = 'ARTIST_NOTIFICATION_UPDATING';
export const ARTIST_FOLLOWERS_UPDATING = 'ARTIST_FOLLOWERS_UPDATING';
export const ARTIST_FOLLOWING_NO_UPDATING = 'ARTIST_FOLLOWING_NO_UPDATING';

// get user geo info
export const GET_USER_IP = 'GET_USER_IP';
export const GET_USER_LOCATION = 'GET_USER_LOCATION';

// User Preferences
export const SHOPPING_CART_CURRENCY_CHANGE = 'SHOPPING_CART_CURRENCY_CHANGE';
export const PREFERRED_CURRENCY_CHANGE = 'PREFERRED_CURRENCY_CHANGE';
export const PREFERRED_UNIT_CHANGE = 'PREFERRED_UNIT_CHANGE';
export const SET_FX_RATE = 'SET_FX_RATE';
