import React from 'react';
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';

import {
  PikchaBackButton,
  PikchaButton,
  PikchaSpacer
} from '../../../components/baseComponents';
import {useTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {removeSignature} from '../../../actions/account';
import isTablet from "../../../components/Responsive/isTablet";

import signatureSample from '../../../assets/images/canvas-sig-info-2.jpg';
import {ICON_SIZE_DEFAULT} from "../../../helpers/constants";

const SignatureWarning = ({
                            handleBack,
                            handleClick
                          }) => {
  const isOnTablet = isTablet();

  const {typography, palette} = useTheme();

  return (
    <div
      style={{
        padding: "20px 50px"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div>
          <PikchaBackButton
            onClick={() => {
              handleBack()
            }}
          />
        </div>

        <PikchaSpacer size={6}/>
          <div
            style={{
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightBold,
              textAlign: "center",
              color: palette.text.secondary
            }}
            className="flex flex-center justify-center"
          >
            <PriorityHighRoundedIcon style={ICON_SIZE_DEFAULT} />
              You haven't applied a signature to this image
          </div>
        <PikchaSpacer />  

        <div
          style={{
            textAlign: "center",
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightMedium,
            color: palette.text.secondary
          }}
        >
          To continue uploading, please select one of the following options:
        </div>
        <div
          style={{
            display: "flex",
            marginTop: 20,
            justifyContent: "center"
          }}
        >
          <div
            className="relative"
            style={{
              padding: !isOnTablet ? 48 : 24,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                color: palette.purple.medium,
                fontSize: typography.standard,
                fontWeight: typography.fontWeightMedium,
                whiteSpace: "nowrap"
              }}
            >     
                Already Signed?
            </div>
            <div className="flex">
              <div className="flex">
              <p
                style={{
                  maxWidth: 280,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightSemiMedium,
                  color: palette.text.disabled,
                  margin: "20px 0",
                }}
              >
                If you've already signed your artpiece, 
                do  <strong>not</strong> sign it twice.
              </p>
              {/* <img className="ml-1-em" style={{marginTop: 20,maxWidth: 210}} src={signatureSample} /> */}
              </div>
            </div>

            <p
              style={{
                maxWidth: 280,
                fontSize: typography.smallest,
                fontWeight: typography.fontWeightSemiMedium,
                color: palette.text.disabled,
                margin: "20px 0",
              }}
            >
              You will need to manage the alignment and color of the edition number.
            </p>

            <p
              style={{
                maxWidth: 280,
                fontSize: typography.smallest,
                fontWeight: typography.fontWeightSemiMedium,
                color: palette.text.disabled,
                margin: "20px 0",
              }}
            >
              Please ensure your signature and the edition number do not overlap.
            </p>
            <PikchaButton
              mainStyle={{
                width: !isOnTablet ? "256px" : "232px",
                fontWeight: typography.fontWeightBold,
                position: "absolute",
                margin: "auto",
                bottom: 0
              }}
              onClick={() => {
                handleClick(true);
              }}
              action="octonary"
            >
              I've Already Signed This Piece
            </PikchaButton>
          </div>
          <div
            className="relative"
            style={{
              padding: !isOnTablet ? 48 : 24,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div
              style={{
                color: palette.purple.medium,
                align: "center",
                fontSize: typography.standard,
                fontWeight: typography.fontWeightMedium,
                whiteSpace: "nowrap"
              }}
            >
                Don't Want To Sign?
            </div>
            <p
              style={{
                maxWidth: 280,
                fontSize: typography.smallest,
                fontWeight: typography.fontWeightSemiMedium,
                color: palette.text.disabled,
                margin: "20px 0",
              }}
            >
              If you don't want to sign your art then is ok!
            </p>
            <p
              style={{
                maxWidth: 280,
                fontSize: typography.smallest,
                fontWeight: typography.fontWeightSemiMedium,
                color: palette.text.disabled,
                margin: "20px 0",
              }}
            >
              We are still happy for you to sell limited edition prints on
              Pikcha. <strong>Beware</strong>, images which are not signed are
              missing a vital piece of detail which many collectors appreciate.
            </p>
            <PikchaButton
              mainStyle={{
                width: !isOnTablet ? "256px" : "232px",
                fontWeight: typography.fontWeightBold,
                position: "absolute",
                margin: "auto",
                bottom: 0
              }}
              onClick={() => {
                handleClick(false);
              }}
              action="octonary"
            >
              <strong>I Don't Want to Sign My Work</strong>
            </PikchaButton>

          </div>

        </div>

      </div>

    </div>
  )
}

export default connect(null, {
  removeSignature
})(SignatureWarning)