import React from 'react'
import { IntlCurrencyFormatter } from "../../../../components/NumberFormatters/IntlCurrencyFormatter";
import DownloadIcon from "../../../../components/extraIcons/DownloadIcon";
import { Col, Container, Row } from "react-grid-system";
import isTablet from '../../../../components/Responsive/isTablet';
import isMobile from '../../../../components/Responsive/isMobile';
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT
} from "@pikcha/typography";
import ArrowLeft from '@material-ui/icons/ArrowBackIosOutlined'

const SalesHistoryDesktop = ({ 
  historyData, 
  downloadHistory,
  goBackFromSalesHistory
}) => {
  const isOnMobile = isMobile()
  const isOnTablet = isTablet()
  return (
    <>
      <TypographyButton
        buttonStyle={{
          padding: '16px 8x',
           marginBottom: isOnMobile ? '1.75em' : '2em',
           marginLeft:!isOnMobile && '2em' }}
        onClick={(e) => goBackFromSalesHistory()}
        component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
        icon={<ArrowLeft />}
      >
        Back
      </TypographyButton>
      <div style={{ margin: isOnTablet ? '0 1.5em' : '0 2em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
          >
            Sales history
          </TypographySubtitle>
          <TypographyButton
            onClick={downloadHistory}
            component={TYPOGRAPHY_BUTTON_TYPE.Outline}
            textStyle={{ paddingLeft: '0.5em', marginBottom: '-3px' }}
            icon={
              <DownloadIcon
                color="#000"
                iconSize={16}
              />
            }
          >
            Download
          </TypographyButton>
        </div>
        <div
          style={{
            display: 'flex',
            maxWidth: '50%',
            justifyContent: 'space-between',
            border: '1px solid #DBE0E4',
            borderRadius: '4px',
            padding: '2em',
            marginBottom: '1.5em'
          }}
        >
          <div>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
              Sales to be paid
            </TypographyBody>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
              Pending sales
            </TypographyBody>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
              Cancelled orders
            </TypographyBody>
          </div>
          <div>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              <IntlCurrencyFormatter targetCurrency="USD" />
              {historyData?.totalPayableArtistMargin}
            </TypographyBody>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              <IntlCurrencyFormatter targetCurrency="USD" />
              {+historyData?.totalPendingArtistMargin}
            </TypographyBody>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              <IntlCurrencyFormatter targetCurrency="USD" />
              {historyData?.totalCancelledArtistMargin}
            </TypographyBody>
          </div>
        </div>
        {historyData?.imageSaleHistory?.length === 0 && (
          <Container fluid>
            <Row className="text-center">
              <Col md={12} className="mt-4">
                <span className="fontSize16">
                  You don’t have any sales yet
                </span>
              </Col>
            </Row>
          </Container>
        )}
        <div>
          {historyData?.imageSaleHistory?.map((item) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid #DBE0E4',
                  padding: '2em',
                  marginBottom: '1em'
                }
                }>
                <TypographySubtitle style={{ marginBottom: '1.5em' }} component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}>
                  {item.orderNumber} &nbsp; - &nbsp; {item.title}
                </TypographySubtitle>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <span style={{
                    minWidth: isOnTablet ? '150px' : '175px',
                    height: isOnTablet ? '150px' : '175px',
                    backgroundColor: '#F2F4F8',
                    borderRadius: '4px',
                    marginRight: isOnTablet ? '1em' : '2em'
                  }}>
                    <div
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundImage: `url(${item.imageRender})`,
                      }} />
                  </span>
                  <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                      }} >
                      <span style={{ marginRight: isOnTablet ? '0.5em' : '1em' }}>
                        <TypographyBody
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
                          style={{ marginBottom: '1em' }}
                        >
                          ORDER DATE
                        </TypographyBody>
                        <TypographyBody
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
                          style={{ marginBottom: '1em' }}>
                          ORDER STATUS
                        </TypographyBody >
                        <TypographyBody
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
                          style={{ marginBottom: '1em' }}>
                          ORDER QUANTITY
                        </TypographyBody>
                        <TypographyBody
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
                          style={{ marginBottom: '1em' }}>
                          RETAIL PRICE(USD)
                        </TypographyBody>
                      </span>
                      <span>
                        <TypographyBody
                          style={{ marginBottom: '1em' }}
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                        >
                          {item.orderDate}
                        </TypographyBody>
                        <TypographyBody
                          style={{ marginBottom: '1em' }}
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                        >
                          {item.shipDate}
                        </TypographyBody>
                        <TypographyBody
                          style={{ marginBottom: '1em' }}
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                        >
                          {item.quantity}
                        </TypographyBody>
                        <TypographyBody
                          style={{ marginBottom: '1em' }}
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                        >
                          <IntlCurrencyFormatter targetCurrency="USD" />
                          {(item.retailPrice)}
                        </TypographyBody>
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: '0.5em'
                      }} >
                      <span style={{ marginRight: isOnTablet ? '0.5em' : '1em' }}>
                        <TypographyBody
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
                          style={{ marginBottom: '1em' }}>
                          MANUFACTURING FEE(USD)
                        </TypographyBody>
                        <TypographyBody
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
                          style={{ marginBottom: '1em' }}>
                          ARTIST MARGIN(USD)
                        </TypographyBody>
                        <TypographyBody
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
                          style={{ marginBottom: '1em' }}>
                          PAYMENT STATUS
                        </TypographyBody>
                      </span>
                      <span>
                        <TypographyBody
                          style={{ marginBottom: '1em' }}
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                        >
                          <IntlCurrencyFormatter targetCurrency="USD" />
                          {(item.pikchaFee)}
                        </TypographyBody>
                        <TypographyBody
                          style={{ marginBottom: '1em' }}
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                        >
                          <IntlCurrencyFormatter targetCurrency="USD" />
                          {(item.artistMargin)}
                        </TypographyBody>
                        <TypographyBody
                          style={{ marginBottom: '1em' }}
                          size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                        >
                          {item.paymentStatus}
                        </TypographyBody>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default SalesHistoryDesktop