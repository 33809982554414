import {
  HANDLE_BRUSH_STROKE,
  HANDLE_DRAWN_SIGNATURE_SVG,
  HANDLE_REDO_SIGNATURE_PAD,
  HANDLE_SIGNATURE_FONT,
  HANDLE_SIGNATURE_TAB,
  HANDLE_TYPED_SIGNATURE,
  HANDLE_TYPED_SIGNATURE_SVG,
  HANDLE_SIGNATURE_UPDATE_SUCCESS,
  BASE_64_STRING, HANDLE_BRUSH_SMOOTHING, HANDLE_ADAPTIVE_STROKE, HANDLE_BRUSH_MODE,
} from "./types";

export const handleSignatureTab = (value) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_SIGNATURE_TAB,
      payload: value,
    });
  };
};

export const handleSignatureFont = (value) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_SIGNATURE_FONT,
      payload: value,
    });
  };
};

export const handleTypedSignature = (value) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_TYPED_SIGNATURE,
      payload: value,
    });
  };
};

export const handleDrawnSignatureSVG = (signatures) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_DRAWN_SIGNATURE_SVG,
      payload: signatures,
    });
  };
};

export const handleTypedSignatureSVG = (signatures) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_TYPED_SIGNATURE_SVG,
      payload: signatures,
    });
  };
};

export const handleBase64String = (string) => {
  return (dispatch) => {
    dispatch({
      type: BASE_64_STRING,
      payload: string,
    });
  };
};

export const handleBrushStroke = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_BRUSH_STROKE,
      payload: state,
    });
  };
};
export const handleBrushSmoothing = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_BRUSH_SMOOTHING,
      payload: state,
    });
  };
};
export const handleEnhancedStroke = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_ADAPTIVE_STROKE,
      payload: state,
    });
  };
};
export const handleBrushMode = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_BRUSH_MODE,
      payload: state,
    });
  };
};

export const handleRedoSignaturePad = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_REDO_SIGNATURE_PAD,
      payload: state,
    });
  };
};

export const handleSignatureUpdateSuccess = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_SIGNATURE_UPDATE_SUCCESS,
      payload: state,
    });
  };
};
