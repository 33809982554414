import React from "react";
import { useTheme } from "@material-ui/core/styles";

import { PRIMARY_LINEAR_GRADIENT } from "../../../helpers/constants";

export default ({ children, hasText = false, style, ...rest }) => {
  const { palette, shape, typography } = useTheme();

  return (
    <button
      //className="pointer flex-center"
      style={{
        zIndex: 999,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        width: 32,
        height: 32,
        boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        outline: 0,
        border: 0,
        background: (style.backgroundColor || PRIMARY_LINEAR_GRADIENT),
        color: palette.common.white,
        borderRadius: shape.borderRadius,
        padding: 4,
        fontSize: !hasText ? typography.standard : typography.smaller,
        //fontWeight: typography.fontWeightMedium,
        ...style,
      }}
      {...rest}
    >
      {children}
    </button>
  );
};
