import React, {
  useEffect,
  useState,
} from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { MINIMUM_EARNINGS } from "../../helpers/constants";
import {
  PikchaCheckbox,
  PikchaSpacer,
  PriceField,
} from "../../components/baseComponents";
import { useTheme } from "@material-ui/core";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import lightiningBlack from "../../assets/images/uploadArt/lightiningBlack.png";
import lightiningWhite from "../../assets/images/uploadArt/lightiningWhite.png";
import clockBlack from "../../assets/images/uploadArt/clockBlack.png";
import clockWhite from "../../assets/images/uploadArt/clockWhite.png";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import { addDoubleQuotationToSize } from "../../helpers/utils";
import ArtworkPricesModal from "../../containers/modals/ArtworkPricesModal";
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographyToggleButtonGroup,
  TypographyToggleButton,
  TypographyDateandTimePicker
} from "@pikcha/typography";

const Step6 = ({
  imageDetails,
  handleImageDetails,
  handlePublish,
  aspectRatio,
  setProductTypeIds,
  productTypeIds,
  allProductLines,
  publistMethod,
  setPublistMethod
}) => {
  const { palette, shape, typography } = useTheme();
  const [priceAndProductRange, setPriceAndProductRange] = useState([]);
  const [selectedProductLines, setSelectedProductLines] = useState({
    "Paper": true,
    "Canvas": true,
  });
  const [enterPrice, setEnterPrice] = useState(false);
  const [viewArtworkPrices, setViewArtworkPrices] = useState(false);

  const getAfterOneWeekDate = () => {
    var now = new Date();
    now.setDate(now.getDate() + 7);
    return now;
  }
  const getAfterOneYearDate = () => {
    var now = new Date();
    now.setFullYear(now.getFullYear() + 1);
    now.setDate(now.getDate() + 7)
    return now;
  }
  const [selectedDate, handleDateChange] = useState(getAfterOneWeekDate());

  useEffect(() => {
    const imgAspectRatio = aspectRatio.replace(",", ":");
    if (imageDetails.price && aspectRatio) {
      axios
        .get(`/api/Image/${imgAspectRatio}/${parseFloat(imageDetails.price)
          .toFixed(1)}/ProductPriceRange`).then((res) => {
            setPriceAndProductRange(res.data);
          });
    } else if (!imageDetails.price && imgAspectRatio) {
      axios
        .get(`/api/Image/${imgAspectRatio}/3.0/ProductPriceRange`)
        .then((res) => {
          setPriceAndProductRange(res.data);
        });
    }
  }, [imageDetails.price, aspectRatio]);

  useEffect(() => {
    handleImageDetails("publishAt", selectedDate)
  }, [selectedDate]);

  const _handleAddPrice = () => {
    if (imageDetails.price) {
      handleImageDetails("price", parseInt(imageDetails.price) + 1);
    } else {
      handleImageDetails("price", `${MINIMUM_EARNINGS}`);
    }
  };

  const _handleMinusPrice = () => {
    if (parseInt(imageDetails.price) > MINIMUM_EARNINGS) {
      handleImageDetails("price", parseInt(imageDetails.price) - 1);
    }
  };

  const handleChecked = (item, checked) => {
    const { material } = item;
    const selectedProductLine = allProductLines.filter(o => o.label === material);
    const selectedProductLineId = selectedProductLine[0].value.id;

    if (checked) {
      setProductTypeIds(
        [...productTypeIds, selectedProductLineId],
      );
    } else {
      const newArray = productTypeIds.filter(e => e !== selectedProductLineId);

      setProductTypeIds(newArray);
    }

    setSelectedProductLines({ ...selectedProductLines, [material]: checked });
  };

  const LightningIconOutline = () => {
    return (
      <>
        <div
          style={{
            minWidth: '56px',
            minHeight: '56px',
            maxHeight: '56px',
            maxWidth: '56px',
            border: '1px solid #DBE0E4',
            borderRadius: '50%',
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <img style={{ width: '16px', height: '20px' }} src={lightiningBlack} alt="Lightning Icon" />
        </div>
      </>
    )
  }

  const LightningIconFilled = () => {
    return (
      <>
        <div
          style={{
            minWidth: '56px',
            minHeight: '56px',
            maxHeight: '56px',
            maxWidth: '56px',
            border: '1px solid #DBE0E4',
            borderRadius: '50%',
            backgroundColor: '#343A3F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <img style={{ width: '16px', height: '20px' }} src={lightiningWhite} alt="Lightning Icon" />
        </div>
      </>
    )
  }

  const ClockOutlinedIcon = () => {
    return (
      <>
        <div
          style={{
            minWidth: '56px',
            minHeight: '56px',
            maxHeight: '56px',
            maxWidth: '56px',
            border: '1px solid #DBE0E4',
            borderRadius: '50%',
            backgroundColor: '#343A3F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <img style={{ width: '24px', height: '24px' }} src={clockWhite} alt="Lightning Icon" />
        </div>
      </>
    )
  }

  const ClockFilledIcon = () => {
    return (
      <>
        <div
          style={{
            minWidth: '56px',
            minHeight: '56px',
            maxHeight: '56px',
            maxWidth: '56px',
            border: '1px solid #DBE0E4',
            borderRadius: '50%',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <img style={{ width: '24px', height: '24px' }} src={clockBlack} alt="Lightning Icon" />
        </div>
      </>
    )
  }

  return (
    <>
      <TypographySubtitle
        component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
        style={{
          marginBottom: "0.75em"
        }}
      >
        Price & Publish
      </TypographySubtitle>
      <TypographyBody>
        Select your fee and enable paper and canvas printing
      </TypographyBody>
      <TypographyToggleButtonGroup
        style={{
          display: "flex",
          marginTop: "2em"
        }}
      >
        <TypographyToggleButton
          buttonStyle={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: publistMethod === "instant" ? '#121619' : '#FFF',
            border: '1px solid #DBE0E4',
            borderRadius: '4px',
            minWidth: '225px',
            minHeight: '160px',
            maxWidth: '225px',
            maxHeight: '160px',
            marginRight: '0.5em'
          }}
          onClick={() => {
            setPublistMethod("instant");
          }}        
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {publistMethod === "instant" ? <LightningIconFilled /> : <LightningIconOutline />}
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{ color: publistMethod === "instant" ? '#FFF' : '#343A3F', }}
            >
              Instant release
            </TypographyBody>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              style={{ color: publistMethod === "instant" ? '#FFF' : '#343A3F', }}
            >
              Release your artwork <br /> instantly.
            </TypographyBody>
          </div>
        </TypographyToggleButton>
        <TypographyToggleButton
          buttonStyle={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: publistMethod === "timed" ? '#121619' : '#FFF',
            border: '1px solid #DBE0E4',
            borderRadius: '4px',
            minWidth: '225px',
            minHeight: '160px',
            maxWidth: '225px',
            maxHeight: '160px'
          }}
          onClick={() => {
            setPublistMethod("timed");
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {publistMethod === "timed" ? <ClockOutlinedIcon /> : <ClockFilledIcon />}
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{ color: publistMethod === "timed" ? '#FFF' : '#343A3F', }}
            >
              Timed release
            </TypographyBody>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              style={{ color: publistMethod === "timed" ? '#FFF' : '#343A3F', }}
            >
              Set a time and date <br /> for your release.
            </TypographyBody>
          </div>
        </TypographyToggleButton>
      </TypographyToggleButtonGroup>
      {publistMethod === "timed" && (
        <div style={{maxWidth:'350px'}}>
          <TypographyDateandTimePicker
            label="Release date and time"
            style={{ marginTop: '2em' }}
            value={selectedDate}
            onChange={handleDateChange}
            handleDateChange={handleDateChange}
            initialDate={getAfterOneWeekDate()}
            minDate={getAfterOneWeekDate()}
            minDateMessage={"Please Choose Date For Release After One Week"}
            maxDateMessage={"Please Choose Date For Release Less Then a Year"}
            maxDate={getAfterOneYearDate()}
          />
          <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Small_Regular}>
            Date and time is in your current timezone.
          </TypographyBody>
        </div>
      )
      }
      <ArtworkPricesModal
        open={viewArtworkPrices}
        onClose={() => {
          setViewArtworkPrices(false);
        }}
        aspectRatio={aspectRatio}
        setPrice={imageDetails.price}
      />
      <div style={{ marginTop: '1.5em', display: 'flex' }} container spacing={2}>
        <div style={{width:'100%'}}>
          <PriceField
            required
            className="mr-1-em"
            onValueChange={(values) =>
              handleImageDetails("price", values.value)
            }
            value={imageDetails.price}
          />
        </div>
        <div>
          <div
            className="flex"
            style={{
              marginTop: "2em",
            }}
          >
            <TypographyButton
              textStyle={{ fontWeight: '700' }}
              buttonStyle={{
                maxWidth: '44px',
                height: '44px',
                marginRight: '0.5em',
                padding: 10
              }}
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              onClick={_handleAddPrice}
              icon={<AddRoundedIcon />}
            />
            <TypographyButton
              buttonStyle={{
                maxWidth: '44px',
                height: '44px',
                padding: 10
              }}
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              onClick={_handleMinusPrice}
              icon={<RemoveRoundedIcon />}
            />
          </div>
        </div>
      </div>
      <Grid style={{ marginTop: 40 }} container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: "flex", width: "100%" }}>
            {priceAndProductRange.map((item) => (
              <div
                style={{
                  width: "50%",
                }}
              >
                <div
                  style={{
                    fontSize: typography.smallest,
                    color: palette.text.primary,
                  }}
                >
                  <div
                    className="flex"
                    style={{
                      justifyContent: "flex-start",
                      marginLeft: 11
                    }}
                  >
                    <PikchaCheckbox
                      checked={selectedProductLines[item.material]}
                      label={
                        <TypographyBody
                          size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                        >
                          {item.material}&nbsp;Products
                        </TypographyBody>
                      }
                      onChange={({ target: { checked } }) => handleChecked(
                        item,
                        checked,
                      )}
                    />
                  </div>
                </div>
                <TypographyBody
                  style={{
                    marginLeft: "1.75em"
                  }}
                >
                  <div>
                    {addDoubleQuotationToSize(item.minSize)}&nbsp;-&nbsp;{addDoubleQuotationToSize(
                      item.maxSize)}
                  </div>
                  <div>
                    ${item.minPrice}-${item.maxPrice}
                  </div>
                </TypographyBody>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
      <div
        className="flex-center pointer"
        onClick={() => {
          setViewArtworkPrices(true);
        }}
        style={{
          marginTop: "1em",
          borderRadius: shape.borderRadius,
          background: enterPrice ? "rgba(0, 0, 238, 0.05)" : null,
        }}
        onMouseEnter={() => {
          setEnterPrice(true);
        }}
        onMouseLeave={() => {
          setEnterPrice(false);
        }}
      >
        <span
          className="material-icons"
          style={{
            fontSize: 16,
            color: palette.link.main,
            marginRight: 3,
          }}
        >
          open_in_new
        </span>
        <span
          style={{
            fontWeight: typography.fontWeightSemiMedium,
            fontSize: typography.small,
            color: "#0000ee",
          }}
        >
          View artwork prices
        </span>
      </div>
      <PikchaSpacer size={4} />
      <TypographyButton
        buttonStyle={{ width: "100%" }}
        onClick={() => handlePublish(selectedProductLines)}
      >
        Publish Artwork
      </TypographyButton>
    </>
  )
}

export default Step6