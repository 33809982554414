import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { isSafeSearchEnabled } from "../../../selectors/account";
import clicked from "../../../assets/images/Collection/clicked.png"
import unClicked from "../../../assets/images/Collection/unClicked.png";
import SelectionMasonryItemOverlay from "./SelectionMasonryItemOverlay.jsx";

const SelectionMasonryItem = ({
  photo,
  itemWidth,
  isImageSelected,
  onImageSelected,
  onImageDeselected,
  style,
  safeSearch,
  editingCollectionId
}) => {
  const { id, placeholder, thumbnail, isNudity, aspRatioX, aspRatioY, collectionId } = photo;
  const isSelected = isImageSelected(photo);

  let placeholderHeightScale = 1;
  if (placeholder) {
    placeholderHeightScale = 2 / 3;
  } else {
    if (aspRatioX !== 0 && aspRatioY !== 0) {
      placeholderHeightScale = aspRatioY / aspRatioX;
    } else {
      placeholderHeightScale = 2 / 3;
    }
  }

  let displayHeight = itemWidth * placeholderHeightScale;
  if (isNaN(displayHeight)) {
    displayHeight = 200;
  }

  let safeSearchFilter = isNudity && safeSearch;

  const { palette } = useTheme();

  const [hoverState, setHoverState] = useState(false);
  const [mainImage, setMainImage] = useState(false);

  useEffect(() => {
    if (placeholder || !thumbnail) {
      setMainImage(false);
    }
  }, [placeholder, thumbnail]);
  const handleMouseOver = () => {
    if (mainImage) {
      setHoverState(true);
    }
  };
  const handleMouseLeave = () => {
    setHoverState(false);
  };
  const onMainImageLoaded = () => {
    setMainImage(true);
  };

  const onImageClicked = () => {
    if (!mainImage || placeholder) {
      return;
    }

    if (isSelected) {
      onImageDeselected(id, photo);
    } else {
      onImageSelected(id, photo);
    }
  };

  return (
    <div 
      className="relative"
      style={{
        ...style
      }}
    >
      {collectionId && collectionId !== editingCollectionId && (
        <SelectionMasonryItemOverlay />
      )}
      <div
        style={{
          cursor: "pointer",
          position:'relative',
        }}
        onClick={onImageClicked}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {hoverState && (
          <div
            style={{
              position: "absolute",
              width: itemWidth,
              height: displayHeight,
              zIndex: 10,
            }}
          />
        )}
        {isSelected && (
          <div
            style={{
              position: "absolute",
              top: '10px',
              right: '10px',
              width: '16px',
              height: '16px',
              zIndex: 10,
            }}
          >
            <img src={clicked} alt="" />
          </div>
        )}
        {!isSelected && (
          <div
            style={{
              position: "absolute",
              top: '10px',
              right: '10px',
              width: '16px',
              height: '16px',
              zIndex: 10,
            }}
          >
            <img src={unClicked} alt="" />
          </div>
        )}
        {!mainImage && (

          <div
            style={{
              width: "100%",
              height: displayHeight,
              backgroundColor: palette.grey.light,
            }}
          />
        )}
        {!placeholder && (
          <>
            <div style={{ position: 'relative' }} class="container">
              <img
                style={{
                  display: !mainImage ? "none" : "block",
                  width: "100%",
                  height: "auto",
                }}
                src={thumbnail}
                onLoad={onMainImageLoaded}
                alt="Gallery Image"
                className={`${safeSearchFilter ? "SelectionMasonryItem-filtered" : ""
                  } no-select`}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  safeSearch: isSafeSearchEnabled(state),
});

export default connect(mapStateToProps, {})(SelectionMasonryItem);
