import React from 'react';
import RadioSelector from "./RadioSelector";
import CheckboxSelector from "./CheckboxSelector";
import ToggleSelector from "./ToggleSelector";

export default ({
  selectorType,
  checked = true,
  handleChange = () => { },
  value,
  options,
  labelText,
  disabled,
  size,
  style,
  optionStyle,
  ...rest
}) => {
  switch (selectorType) {
    case "radio":
      return (
        <RadioSelector
          disabled={disabled}
          value={value}
          handleChange={handleChange}
          options={options}
          style={style}
          optionStyle={optionStyle}
          labelText={labelText}
        />
      )
    case "checkbox":
      return (
        <CheckboxSelector
          gilad={checked}
          handleChange={handleChange}
          labelText={labelText}
          style={style}
          disabled={disabled}
          size={size}
        />
      )
    case "toggle":
      return (
        <ToggleSelector
          checked={checked}
          handleChange={handleChange}
          labelText={labelText}
          style={style}
          disabled={disabled}
          {...rest}
        />
      )
    default:
      return null;
  }
}
