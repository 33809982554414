export const activeTimer = (state) => {
  let timers;
  if (state) {
    timers = state.timerReducer;
  }
  let activeTimer;
  if (timers && timers.timers) {
    timers.timers.forEach((timer) => {
      const { secondsToExpire } = timer.data;
      if (activeTimer) {
        const activeSecondsToExpire = activeTimer.data.secondsToExpire;

        if (secondsToExpire > activeSecondsToExpire) {
          activeTimer = timer;
        }
      } else {
        activeTimer = timer;
      }
    });
  }

  return activeTimer;
};
