import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { GoogleLoginButton } from "react-social-login-buttons";

import { socialAuthButtonBaseStyles } from "./styles";
import GoogleIcon from "../../extraIcons/GoogleIcon";

export default ({ children, ...rest }) => {
  const { palette } = useTheme();

  return (
    <GoogleLoginButton
      className="SocialAuthButton"
      style={{
        ...socialAuthButtonBaseStyles,
        background: "transparent",
      }}
      activeStyle={{
        ...socialAuthButtonBaseStyles,
        background: palette.action.hover,
      }}
      icon={(props) => <GoogleIcon iconSize={22} {...props} />}
      align="center"
      {...rest}
    >
      {children}
    </GoogleLoginButton>
  );
};
