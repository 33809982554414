import React from 'react';
import ContentLoader from 'react-content-loader';

export default () => (
  <ContentLoader
    speed={3}
    width={900}
    height={600}
    viewBox="0 0 900 600"
    backgroundColor="#cfcfcf"
    foregroundColor="#ecebeb"
  >
    <rect x="486" y="13" rx="12" ry="12" width="157" height="34" />
    <rect x="486" y="55" rx="12" ry="12" width="179" height="34" />
    <rect x="486" y="97" rx="12" ry="12" width="183" height="34" />

    <rect x="30" y="150" rx="2" ry="2" width="306" height="306" />
    <rect x="485" y="105" rx="2" ry="2" width="386" height="386" />
  </ContentLoader>
);
