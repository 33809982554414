import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";

import Swatch from "./Swatch";
import isTablet from "../Responsive/isTablet";

export default ({ onChange, disabled, value, colors }) => {
  const isOnTablet = isTablet();
  const { palette, shape } = useTheme();
  
  const [selectedColor, setSelectedColor] = useState(value);

  useEffect(() => {
    if(value){
      setSelectedColor(value);
    }
  }, [value]);

  const onSelectColor = (colorToSelect) => {
    let newColor;
    if (selectedColor !== colorToSelect) {
      newColor = colorToSelect;
    } else {
      newColor = null;
    }
    setSelectedColor(newColor);
    onChange(newColor);
  };

  return (
    <div className="relative">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: !isOnTablet ? 256 : 234,
          height: "64px",
          backgroundColor: palette.common.white,
          borderRadius: shape.borderRadius,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {colors.map((color, index) => {
            if (index < 8) {
              return (
                <Swatch
                  style={{
                    zIndex: disabled && value === color ? 200 : 0,
                  }}
                  innerStyle={{
                    boxShadow:
                      disabled && value === color
                        ? `0 0 2px ${palette.grey.light}`
                        : "",
                  }}
                  disabled={disabled}
                  key={color}
                  onClick={onSelectColor}
                  color={color}
                  selectedColor={selectedColor}
                />
              );
            }
          })}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {colors.map((color, index) => {
            if (index >= 8 && index < 16) {
              return (
                <Swatch
                  style={{
                    zIndex: disabled && selectedColor === color ? 200 : 0,
                  }}
                  innerStyle={{
                    boxShadow:
                      disabled && selectedColor === color
                        ? `0 0 2px ${palette.grey.light}`
                        : "",
                  }}
                  disabled={disabled}
                  key={color}
                  onClick={onSelectColor}
                  color={color}
                  selectedColor={selectedColor}
                />
              );
            }
          })}
        </div>
      </div>
      {disabled && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "rgba(158,158,158,0.85)",
            width: !isOnTablet ? 256 : 234,
            height: "64px",
            zIndex: 5,
            borderRadius: shape.borderRadius,
          }}
        />
      )}
    </div>
  );
};
