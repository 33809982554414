import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import AuthorizeService from "../../../auth/AuthorizeService";
import { IntlCurrencyFormatter } from "../../../components/NumberFormatters/IntlCurrencyFormatter";
import isMobile from "../../../components/Responsive/isMobile";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT
} from "@pikcha/typography";

const SalesandPaymentsStat = ({ account, viewSalesHistory, viewPaymentHistory }) => {
  const isOnMobile = isMobile()
  const [last30DaysSales, setLast30DaysSales] = useState();
  const [thisYearsales, setThisYearSales] = useState();

  const [last30DaysPayments, setLast30DaysPayments] = useState();
  const [thisYearPayments, setThisYearPayments] = useState()

  useEffect(() => {
    const doSalesNetworkCall = async () => {
      const artistId = account.user.id;
      const token = await AuthorizeService.getAccessToken();
      axios
        .get(`api/image/${artistId}/mySalesSummary`, {
          artistId,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (resp.data) {
            const { saleIn30Days, saleInYear } = resp.data;
            setLast30DaysSales(saleIn30Days);
            setThisYearSales(saleInYear);
          }
        });
    };

    const doPaymentNetworkCall = async () => {
      const artistId = account.user.id;
      const token = await AuthorizeService.getAccessToken();
      axios
        .get(`api/Payment/PaymentSummary/${artistId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (resp.data) {
            const { saleIn30Days, saleInYear } = resp.data;
            setLast30DaysPayments(saleIn30Days);
            setThisYearPayments(saleInYear);
          }
        });
    };
    doSalesNetworkCall();
    doPaymentNetworkCall()
  }, []);
  return (
    <>
      <div
        style={{
          border: '1px solid #DBE0E4',
          borderRadius: 4,
          marginBottom: "4em",
          padding: "1em",
          position: "relative",
          minHeight: !isOnMobile && '601px'
        }}
      >
        <TypographySubtitle
          style={{ marginTop: '1em', marginBottom: '1.5em' }}
          component={isOnMobile ? TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Bold : TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
        >
          History
        </TypographySubtitle>

        <div>
          <TypographyBody
            style={{ marginBottom: '0.5em' }}
            size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            Sales History
          </TypographyBody>

          <div
            className="full-width"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: 'space-between'
            }}
          >
            <div>
              <TypographyBody
                style={{ marginBottom: '0.5em' }}
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                Last 30 days
              </TypographyBody>
              <TypographyBody
                style={{ marginBottom: '0.5em' }}
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                Last 6 months
              </TypographyBody>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '1.5em' }}
              >
                Last 12 months
              </TypographyBody>
            </div>
            <div>
              <TypographyBody
                style={{ marginBottom: '0.5em' }}
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                <IntlCurrencyFormatter targetCurrency="USD" />
                {parseInt(last30DaysSales).toFixed(2) === "NaN" ? 0.00 : parseInt(last30DaysSales).toFixed(2)}
              </TypographyBody>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                <IntlCurrencyFormatter targetCurrency="USD" />
                {(thisYearsales / 2).toFixed(2) === "NaN" ? 0.00 : (thisYearsales / 2).toFixed(2)}
              </TypographyBody>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '1.5em' }}
              >
                <IntlCurrencyFormatter targetCurrency="USD" />
                {parseInt(thisYearsales).toFixed(2) === "NaN" ? 0.00 : parseInt(thisYearsales).toFixed(2)}
              </TypographyBody>
            </div>
          </div>
          <TypographyButton
            onClick={(e) => {
              viewSalesHistory();
            }}
            component={TYPOGRAPHY_BUTTON_TYPE.Outline}
            buttonStyle={{ 
              marginBottom: '2.5em',
              width: isOnMobile && "100%" 
            }}
          >
            View Sales History
          </TypographyButton>
          <TypographyBody
            style={{ marginBottom: '0.5em' }}
            size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}

          >
            Payment History
          </TypographyBody>
          <div
            className="full-width"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: 'space-between'
            }}
          >
            <div>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                Last 30 days
              </TypographyBody>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                Last 6 months
              </TypographyBody>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '1.5em' }}
              >
                Last 12 months
              </TypographyBody>
            </div>
            <div>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                <IntlCurrencyFormatter targetCurrency="USD" />
                {parseInt(last30DaysPayments).toFixed(2) === "NaN" ? 0.00 : parseInt(last30DaysPayments).toFixed(2)}
              </TypographyBody>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                <IntlCurrencyFormatter targetCurrency="USD" />
                {(thisYearPayments / 2).toFixed(2) === "NaN" ? 0.00 : (thisYearPayments / 2).toFixed(2)}
              </TypographyBody>
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '1.5em' }}
              >
                <IntlCurrencyFormatter targetCurrency="USD" />
                {parseInt(thisYearPayments).toFixed(2) === "NaN" ? 0.00 : parseInt(thisYearPayments).toFixed(2)}
              </TypographyBody>
            </div>
          </div>
          <TypographyButton
            onClick={(e) => {
            viewPaymentHistory();
            }}
            component={TYPOGRAPHY_BUTTON_TYPE.Outline}
            buttonStyle={{ 
              marginBottom: '2.5em',
              width: isOnMobile && "100%"
            }}
          >
            View Payment History
          </TypographyButton>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {})(SalesandPaymentsStat);