import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import { PRIMARY_LINEAR_GRADIENT } from "../../../helpers/constants";

export const UploadNavButton = withStyles(({ palette, shape }) => ({
  root: {
    height: 32,
    width: 32,
    background: PRIMARY_LINEAR_GRADIENT,
    boxShadow: "none",
    border: `1px solid ${palette.primary.medium}`,
    borderRadius: shape.borderRadius,
    lineHeight: 1.5,
    padding: 4,
  },
}))(IconButton);
