import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import isMobile from "../../../components/Responsive/isMobile";
import { makeStyles, withStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import AuthorizeService from "../../../auth/AuthorizeService";
import { setAlert } from "../../../actions/alert";
import { getValueIfKeyExists } from "../../../helpers/utils";
import DeleteAccountModal from "../../../containers/modals/DeleteAccountModal/DeleteAccountModal";
import ChangePasswordModal from "../../../containers/modals/ChangePasswordModal/ChangePasswordModal";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import {
  TypographyBody, TYPOGRAPHY_BODY_SIZE, colors,
  TYPOGRAPHY_BUTTON_TYPE, TypographyToggleButtonGroup, TypographyToggleButton,
} from "@pikcha/typography";
import {
  PikchaModal
} from "../../../components/baseComponents";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    padding: 0,
  },
  contentMargin: {
    backgroundColor: 'white',
    border: '0',
  },
  accordionRoot: {
    boxShadow: 'none',
    backgroundColor: colors.Neutral.grey20,
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);


const NotificationSettings = ({ account, setAlert, expandedPanel, handleAccordionChange }) => {
  const isOnMobile = isMobile();

  const isUserAnArtist = getValueIfKeyExists(account, "user.roles") && account.user.roles.includes("Artist");

  const [ImagesInMyCollection, setImagesInMyCollection] = useState(null);
  const [ArtistInMyCollection, setArtistInMyCollection] = useState(null);
  const [Sales, setSales] = useState(null);
  const [Payments, setPayments] = useState(null);
  const [Followers, setFollowers] = useState(null);
  const [LikesAndComments, setLikesAndComments] = useState(null);

  useEffect(() => {
    AuthorizeService.getAccessToken().then((token) =>
      axios
        .get(`/api/Profile/GetInAppNotificationPreference`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setImagesInMyCollection(response.data.imagesInMyCollection);
          setArtistInMyCollection(response.data.artistInMyCollection);
          setSales(response.data.sales);
          setPayments(response.data.payments);
          setFollowers(response.data.followers);
          setLikesAndComments(response.data.likesAndComments);
        })
    );
  }, []);

  const onSettingInAppNotificationPreferences = (type, value) => {
    AuthorizeService.getAccessToken().then((token) =>
      axios
        .post(
          "/api/Profile/SetInAppNotificationPreference",
          {
            ImagesInMyCollection: type === "ImagesInMyCollection" ? value : ImagesInMyCollection,
            ArtistInMyCollection: type === "ArtistInMyCollection" ? value : ArtistInMyCollection,
            Sales: type === "Sales" ? value : Sales,
            Payments: type === "Payments" ? value : Payments,
            Followers: type === "Followers" ? value : Followers,
            LikesAndComments: type === "LikesAndComments" ? value : LikesAndComments
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setAlert("Notification Preferences Updated", "info");
        })
    );
  };

  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [twoFactorModal, setTwoFactorModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  
  const classes = useStyles();

  const handleChangePasswordModal = (state) => setChangePasswordModal(state);
  const handleTwoFactorModal = (state) => setTwoFactorModal(state);
  const handleDeleteAccountModal = (state) => setDeleteAccountModal(state);

  const handleToggleChange = (field, value) => {
    if (value === null || field === null) return;
    onSettingInAppNotificationPreferences(field, value);
  }

  return (
    <>
      <Accordion 
        expanded={expandedPanel === 'panel2'} 
        onChange={handleAccordionChange('panel2')} 
        className={classes.accordionRoot} 
        style={{ margin: 0 }}
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          classes={{ expanded: classes.expandedSummary }}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            Notification Settings
          </TypographyBody>
        </StyledAccordionSummary>
        <AccordionDetails className={classes.contentMargin}>
          <div
            style={{
              display: 'flex',
              flexDirection: "column",
            }}
          >
            <div
              style={{ 
                display: 'flex', 
                flexDirection: 'row', 
                width: '100%', 
                justifyContent: 'space-between', 
                marginBottom: '1em', 
                marginTop: isOnMobile ? '2em' : '1.75em' 
              }}
            >
              <TypographyBody 
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                Notify me about images in my collection
              </TypographyBody>
              <TypographyToggleButtonGroup
                style={{
                  maxWidth: "126px",
                  maxHeight: '40px',
                  border: "2px solid rgb(238, 238, 238)",
                }}
              >
                <TypographyToggleButton
                  buttonStyle={{
                    width: "50%",
                    height: 36,
                    border: 0
                  }}
                  component={
                    ImagesInMyCollection === true ?
                      TYPOGRAPHY_BUTTON_TYPE.Primary :
                      TYPOGRAPHY_BUTTON_TYPE.Outline
                  }
                  onClick={() => {
                    handleToggleChange("ImagesInMyCollection", true);
                    setImagesInMyCollection(true);
                  }}
                >
                  On
                </TypographyToggleButton>
                <TypographyToggleButton
                  buttonStyle={{
                    width: "50%",
                    height: 36,
                    border: 0
                  }}
                  component={
                    ImagesInMyCollection === false ?
                      TYPOGRAPHY_BUTTON_TYPE.Primary :
                      TYPOGRAPHY_BUTTON_TYPE.Outline
                  }
                  onClick={() => {
                    handleToggleChange("ImagesInMyCollection", false);
                    setImagesInMyCollection(false);
                  }}
                >
                  Off
                </TypographyToggleButton>
              </TypographyToggleButtonGroup>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: '1.25em' }}
            >
              <TypographyBody 
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                Notify me about artists in my collection
              </TypographyBody>
              <TypographyToggleButtonGroup
                style={{
                  display: "flex",
                  maxWidth: "126px",
                  maxHeight: '40px',
                  border: "2px solid rgb(238, 238, 238)",
                }}
              >
                <TypographyToggleButton
                  buttonStyle={{
                    width: "50%",
                    height: 36,
                    border: 0
                  }}
                  component={
                    ArtistInMyCollection === true ?
                      TYPOGRAPHY_BUTTON_TYPE.Primary :
                      TYPOGRAPHY_BUTTON_TYPE.Outline
                  }
                  onClick={() => {
                    handleToggleChange("ArtistInMyCollection", true);
                    setArtistInMyCollection(true);
                  }}
                >
                  On
                </TypographyToggleButton>
                <TypographyToggleButton
                  buttonStyle={{
                    width: "50%",
                    height: 36,
                    border: 0
                  }}
                  component={
                    ArtistInMyCollection === false ?
                      TYPOGRAPHY_BUTTON_TYPE.Primary :
                      TYPOGRAPHY_BUTTON_TYPE.Outline
                  }
                  onClick={() => {
                    handleToggleChange("ArtistInMyCollection", false);
                    setArtistInMyCollection(false);
                  }}
                >
                  Off
                </TypographyToggleButton>
              </TypographyToggleButtonGroup>
            </div>
            {isUserAnArtist ?
              (
                <>
                  <div
                    style={{ 
                      display: 'flex', 
                      flexDirection: 'row', 
                      width: '100%', 
                      justifyContent: 'space-between', 
                      marginBottom: '1.25em' 
                    }}
                  >
                    <TypographyBody 
                      size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                    >
                      Notify me about my sales
                    </TypographyBody>
                    <TypographyToggleButtonGroup
                      style={{
                        display: "flex",
                        maxWidth: "126px",
                        maxHeight: '40px',
                        border: "2px solid rgb(238, 238, 238)",
                      }}
                    >
                      <TypographyToggleButton
                        buttonStyle={{
                          width: "50%",
                          height: 36,
                          border: 0
                        }}
                        component={
                          Sales === true ?
                            TYPOGRAPHY_BUTTON_TYPE.Primary :
                            TYPOGRAPHY_BUTTON_TYPE.Outline
                        }
                        onClick={() => {
                          handleToggleChange("Sales", true);
                          setSales(true);
                        }}
                      >
                        On
                      </TypographyToggleButton>
                      <TypographyToggleButton
                        buttonStyle={{
                          width: "50%",
                          height: 36,
                          border: 0
                        }}
                        component={
                          Sales === false ?
                            TYPOGRAPHY_BUTTON_TYPE.Primary :
                            TYPOGRAPHY_BUTTON_TYPE.Outline
                        }
                        onClick={() => {
                          handleToggleChange("Sales", false);
                          setSales(false);
                        }}
                      >
                        Off
                      </TypographyToggleButton>
                    </TypographyToggleButtonGroup>
                  </div>
                  <div
                    style={{ 
                      display: 'flex', 
                      flexDirection: 'row', 
                      width: '100%', 
                      justifyContent: 'space-between', 
                      marginBottom: '1.25em' 
                    }}
                  >
                    <TypographyBody 
                      size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                    >
                      Notify me about my payments
                    </TypographyBody>
                    <TypographyToggleButtonGroup
                      style={{
                        display: "flex",
                        maxWidth: "126px",
                        maxHeight: '40px',
                        border: "2px solid rgb(238, 238, 238)",
                      }}
                    >
                      <TypographyToggleButton
                        buttonStyle={{
                          width: "50%",
                          height: 36,
                          border: 0
                        }}
                        component={
                          Payments === true ?
                            TYPOGRAPHY_BUTTON_TYPE.Primary :
                            TYPOGRAPHY_BUTTON_TYPE.Outline
                        }
                        onClick={() => {
                          handleToggleChange("Payments", true);
                          setPayments(true);
                        }}
                      >
                        On
                      </TypographyToggleButton>
                      <TypographyToggleButton
                        buttonStyle={{
                          width: "50%",
                          height: 36,
                          border: 0
                        }}
                        component={
                          Payments === false ?
                            TYPOGRAPHY_BUTTON_TYPE.Primary :
                            TYPOGRAPHY_BUTTON_TYPE.Outline
                        }
                        onClick={() => {
                          handleToggleChange("Payments", false);
                          setPayments(false);
                        }}
                      >
                        Off
                      </TypographyToggleButton>
                    </TypographyToggleButtonGroup>
                  </div>
                  <div
                    style={{ 
                      display: 'flex', 
                      flexDirection: 'row', 
                      width: '100%', 
                      justifyContent: 'space-between', 
                      marginBottom: '1.25em' 
                    }}
                  >
                    <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>Notify me about my followers</TypographyBody>
                    <TypographyToggleButtonGroup
                      style={{
                        display: "flex",
                        maxWidth: "126px",
                        maxHeight: '40px',
                        border: "2px solid rgb(238, 238, 238)",
                      }}
                    >
                      <TypographyToggleButton
                        buttonStyle={{
                          width: "50%",
                          height: 36,
                          border: 0
                        }}
                        component={
                          Followers === true ?
                            TYPOGRAPHY_BUTTON_TYPE.Primary :
                            TYPOGRAPHY_BUTTON_TYPE.Outline
                        }
                        onClick={() => {
                          handleToggleChange("Followers", true);
                          setFollowers(true);
                        }}
                      >
                        On
                      </TypographyToggleButton>
                      <TypographyToggleButton
                        buttonStyle={{
                          width: "50%",
                          height: 36,
                          border: 0
                        }}
                        component={
                          Followers === false ?
                            TYPOGRAPHY_BUTTON_TYPE.Primary :
                            TYPOGRAPHY_BUTTON_TYPE.Outline
                        }
                        onClick={() => {
                          handleToggleChange("Followers", false);
                          setFollowers(false);
                        }}
                      >
                        Off
                      </TypographyToggleButton>
                    </TypographyToggleButtonGroup>
                  </div>
                </>
              ) : null}
          </div>
          <ChangePasswordModal
            open={changePasswordModal}
            onClose={() => handleChangePasswordModal(false)}
          />
          <PikchaModal
            open={twoFactorModal}
            onClose={() => handleTwoFactorModal(false)}
          />
          <DeleteAccountModal
            open={deleteAccountModal}
            onClose={() => handleDeleteAccountModal(false)}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  setAlert,
})(NotificationSettings);
