import React, { Fragment, useState } from "react";

import { connect } from "react-redux";

import { Link, useHistory, useLocation } from "react-router-dom";

import { useTheme, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import SearchIcon from "@material-ui/icons/Search";

import Img from "react-image";

import {
  handleAuthModal,
  openAuthentication,
  openRegistration,
} from "../../actions/authModal";
import { openMenu, openProfile } from "../../actions/nav";

import { hasUnreadAccountNotifications } from "../../selectors/accountNotifications";

import SearchBar from "../../components/search/SearchBar";
import {
  PikchaBackButton,
  PikchaBadge,
  PikchaButton,
} from "../../components/baseComponents";
import NavigationSkeletonLoader
  from "../../components/skeletonLoaders/NavigationSkeletonLoader";

import Logo from "../../assets/images/logo-compact.svg";

import { ICON_SIZE_DEFAULT } from "../../helpers/constants";
import { getValueIfKeyExists } from "../../helpers/utils";

import { useStyles } from "./NavBar.styles";
import NotificationsDropdown
  from "../../components/dropdowns/NotificationsDropdown/NotificationsDropdown";
import { PikchaLink } from "../../components/baseComponents/PikchaLink";
import { ScrollingBanner } from "../../components/ScrollingBanner/ScrollingBanner";
import Marquee from "react-fast-marquee";

const StyledToolbar = withStyles(() => ({
  root: {
    height: "100%",
  },
}))(Toolbar);

const constructBannerNode = msgText => msgText;

const NavBarMobile = ({
  navBarRef,
  menuIconRef,
  pathname,
  handleSideDrawer,
  payload,
  isLoading,
  loggedIn,
  handleUploadClick,

  hasUnreadAccountNotifications,
  account,
  shoppingCart,
  openMenu,
  openProfile,
  handleAuthModal,
  openAuthentication,
  openRegistration,

  handleClick: handleTouch,
  handleClose,
  anchorEl,
  open
}) => {
  const [isSearching, setIsSearching] = useState(false);

  const classes = useStyles();

  const { palette, typography, zIndex: { drawer }, spacing } = useTheme();

  const history = useHistory();
  const location = useLocation();

  const openSearch = () => {
    setIsSearching(true);
  };
  const closeSearch = () => {
    setIsSearching(false);
  };

  return (
    <>
      <AppBar className={`AppBar-top ${classes.root}`} ref={navBarRef}>
        <StyledToolbar disableGutters>
          {!isSearching && (
            <div
              className="navbar-top nonPrintable"
              style={{
                justifyContent: "normal",
              }}
            >
              {!pathname.includes("/auth") && (
                <div
                  ref={menuIconRef}
                  style={{
                    paddingLeft: "1em",
                    paddingRight: "0.5em",
                  }}
                >
                  <IconButton
                    onClick={() => handleSideDrawer(!payload)}
                    className="NavButton"
                  >
                    <MenuRoundedIcon
                      style={ICON_SIZE_DEFAULT}
                      fontSize="inherit"
                      color="action"
                    />
                  </IconButton>
                </div>
              )}
              <div className="nav-logo">
                <div className="pl-half-em">
                  <PikchaLink to="/">
                    <Img src={Logo} alt="" className="Pikcha-logo" />
                  </PikchaLink>
                </div>
              </div>
              {!isLoading && loggedIn && (
                <div
                  className="auth-items"
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <div className="user-item">
                    <div
                      onClick={openSearch}
                      style={{
                        borderRadius: "10px",
                        // border: `2px solid ${palette.grey.mediumLight}`,
                        boxShadow: "0px 0px 4px 1.5px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        padding: "0.4em",
                      }}
                    >
                      <IconButton className="NavButton">
                        <SearchIcon
                          style={{
                            color: "rgb(66, 66, 66)",
                            ...ICON_SIZE_DEFAULT,
                          }}
                        />
                      </IconButton>
                    </div>
                  </div>
                  <div className="user-item">
                    <PikchaLink to="/checkout">
                      <IconButton
                        className="NavButton"
                        style={{
                          paddingTop: 2,
                        }}
                      >
                        <PikchaBadge
                          badgeContent={
                            getValueIfKeyExists(
                              shoppingCart,
                              "shoppingCart.totalItems"
                            ) || 0
                          }
                        >
                          <ShoppingCartRoundedIcon
                            style={{
                              color: "rgb(66, 66, 66)",
                              ...ICON_SIZE_DEFAULT,
                            }}
                          />
                        </PikchaBadge>
                      </IconButton>
                    </PikchaLink>
                  </div>
                  <div className="user-item">
                    <IconButton
                      // onTouchStartCapture={handleTouch}
                      onClick={handleTouch}
                      className="NavButton">
                      {hasUnreadAccountNotifications && (
                        <div
                          style={{
                            marginLeft: "-0.45em",
                            alignSelf: "flex-start",
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            backgroundColor: palette.error.main,
                            position: "absolute",
                            right: 1,
                          }}
                        />
                      )}
                      <NotificationsRoundedIcon
                        style={{
                          color: "rgb(66, 66, 66)",
                          ...ICON_SIZE_DEFAULT,
                        }}
                      />
                    </IconButton>
                    <NotificationsDropdown
                      open={open}
                      onClose={handleClose}
                      anchorEl={anchorEl}
                    />
                  </div>
                  <div className="user-item">
                    <IconButton
                      className="NavButton"
                      style={{
                        paddingTop: 4.5,
                      }}
                    >
                      {getValueIfKeyExists(account, "user.avatar") ===
                        "Data/Test/Avatars/default-avatar.jpg" ||
                        getValueIfKeyExists(account, "user.avatar") === "" ? (
                        <PersonRoundedIcon
                          onMouseDown={() => openProfile()}
                          style={ICON_SIZE_DEFAULT}
                        />
                      ) : (
                        <Img
                          onMouseDown={() => openProfile()}
                          className="user-pic --opaque"
                          src={getValueIfKeyExists(account, "user.avatar")}
                          alt=""
                        />
                      )}
                    </IconButton>
                  </div>
                </div>
              )}
              {!isLoading && !loggedIn && (
                <div
                  className="auth-links"
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <div className="user-item">
                    <div
                      onClick={openSearch}
                      style={{
                        borderRadius: "10px",
                        // border: `2px solid ${palette.grey.mediumLight}`,
                        boxShadow: "0px 0px 4px 1.5px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        padding: "0.4em",
                      }}
                    >
                      <IconButton className="NavButton">
                        <SearchIcon
                          style={{
                            color: "rgb(66, 66, 66)",
                            ...ICON_SIZE_DEFAULT,
                          }}
                        />
                      </IconButton>
                    </div>
                  </div>
                  <div className="NavItem">
                    {pathname.includes("auth") ? (
                      <PikchaLink to="/auth">
                        <IconButton className="NavButton">
                          <PikchaBadge
                            badgeContent={
                              getValueIfKeyExists(
                                shoppingCart,
                                "shoppingCart.totalItems"
                              ) || 0
                            }
                          >
                            <ShoppingCartRoundedIcon
                              style={{
                                color: "rgb(66, 66, 66)",
                                ...ICON_SIZE_DEFAULT,
                              }}
                            />
                          </PikchaBadge>
                        </IconButton>
                      </PikchaLink>
                    ) : (
                      <PikchaLink to="/checkout">
                        <div className="NavItem">
                          <PikchaBadge
                            badgeContent={
                              getValueIfKeyExists(
                                shoppingCart,
                                "shoppingCart.totalItems"
                              ) || 0
                            }
                          >
                            <ShoppingCartRoundedIcon
                              style={{
                                color: "rgb(66, 66, 66)",
                                ...ICON_SIZE_DEFAULT,
                              }}
                            />
                          </PikchaBadge>
                        </div>
                      </PikchaLink>
                    )}
                  </div>
                  <div className="NavItem">
                    <IconButton onMouseDown={openMenu} className="NavButton">
                      <NotificationsRoundedIcon
                        style={{
                          color: "rgb(66, 66, 66)",
                          ...ICON_SIZE_DEFAULT,
                        }}
                      />
                    </IconButton>
                  </div>
                  <div
                    className="NavItem"
                    onClick={() => openRegistration(history, location)}
                    style={{
                      width: "max-content",
                    }}
                  >
                    <PikchaButton
                      style={{
                        marginLeft: "0.5em",
                        minWidth: 100,
                        height: 36,
                        fontSize: typography.small,
                        color: palette.text.primary
                      }}
                      middle
                      action="octonary"
                    >
                      Sign in
                    </PikchaButton>
                  </div>
                </div>
              )}
              {isLoading && (
                <div
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <NavigationSkeletonLoader />
                </div>
              )}
            </div>
          )}
          {isSearching && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <PikchaBackButton
                style={{
                  paddingLeft: "1em",
                  marginLeft: "0.5em",
                  marginRight: "0.5em",
                }}
                onClick={closeSearch}
                showLabel={false}
              />
              <SearchBar pathname={pathname} marginLeft={false} autofocus />
            </div>
          )}
        </StyledToolbar>
      </AppBar>
      {/*
      <ScrollingBanner
        mapDataToNode={constructBannerNode}
        list={[
          {
            msgText: "Black Friday Sale",
            bold: true,
            uppercase: true,
          }, {
            msgText:
              <span>20% off promotion - use code <span style={{ fontWeight: 600 }}>‘Blackfriday’</span></span>,
            bold: false,
            uppercase: true,
          }]}
        //delayBetweenScroll="4000"
      />
      */}


      {/* {["/", "/pikcha100", "/artist100"].includes(pathname) && (
        <Marquee
          style={{
            position: "fixed",
            backgroundColor: "#000",
            top: spacing(7),
            height: 44,
            zIndex: drawer - 1,
            color: "#FFFFFF",
            textTransform: "uppercase",
          }}
          speed={25}
          gradient={false}
        >
          <span style={{ fontWeight: 600 }}> Sign-up to Pikcha </span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>
          <span>$50 USD off your first order</span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>


          <span style={{ fontWeight: 600 }}> Sign-up to Pikcha </span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>
          <span>$50 USD off your first order</span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>

        </Marquee>
        )} */}
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  shoppingCart: state.shoppingCartReducer,
  hasUnreadAccountNotifications: hasUnreadAccountNotifications(state),
});

export default connect(mapStateToProps, {
  openMenu,
  openProfile,
  handleAuthModal,
  openAuthentication,
  openRegistration,
})(NavBarMobile);
