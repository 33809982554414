import React, { Fragment } from "react";

import {
  PikchaFormControlLabel,
  PikchaFormSectionLabel,
  PikchaRadioGroup,
} from "../baseComponents";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";
import { useStyles } from "./radioStyles";

export default ({
  disabled,
  isGstOutsideAu,
  fields,
  handleFields,
  handleSelectedTaxCountry,
  handleIsGstOutsideAu,
}) => {
  if (disabled) {
    return null;
  }

  const classes = useStyles();

  const handleChange = ({ target: { value } }) => {
    if (value === "true") {
      handleFields({
        ...fields,
        _isAudGst: true,
      });
      handleIsGstOutsideAu(true);
    } else {
      handleFields({
        ...fields,
        _isAudGst: false,
        taxCountry: "",
        vatNumber: "",
      });
      handleSelectedTaxCountry("");
      handleIsGstOutsideAu(false);
    }
  };

  return (
    <Fragment>
      <PikchaFormSectionLabel>
        Are you registered for GST/VAT?
      </PikchaFormSectionLabel>

      <PikchaRadioGroup
        style={{
          paddingTop: "0.15em",
          paddingLeft: "0.5em",
          paddingBottom: "0.5em",
        }}
        row
        aria-label="GST VAT Options"
        value={isGstOutsideAu}
        onChange={handleChange}
      >
        <PikchaFormControlLabel
          control={
            <Radio
              value={true}
              className={classes.root}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
            />
          }
          label="Yes"
        />
        <PikchaFormControlLabel
          control={
            <Radio
              value={false}
              className={classes.root}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
            />
          }
          label="No"
        />
      </PikchaRadioGroup>
      <PikchaRadioGroup row></PikchaRadioGroup>
    </Fragment>
  );
};
