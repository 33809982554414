import React, { useRef, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import isMobilePortrait from "../../Responsive/isMobilePortrait";
import isMobile from "../../Responsive/isMobile";
import { PikchaModalHeader } from "./PikchaModalHeader";
import PikchaModalFooter from "./PikchaModalFooter";
import {
  MODAL_ENTER_TRANSITION_DURATION,
  MODAL_EXIT_TRANSITION_DURATION,
} from "../../../helpers/constants";
import "./PikchaModal.css";

const bodyScrollLock = require("body-scroll-lock");
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const targetElement = document.querySelector("body");

const useStyles = makeStyles(_ => ({
  root: ({ isOnMobilePortrait, title }) => ({
    zIndex: "1400 !important",
    "& > div": {
      alignItems: !isOnMobilePortrait
        ? "center"
        : title
          ? "stretch"
          : "flex-end",
      // alignItems: !isOnMobilePortrait ? "center" : "flex-end",
      "& > div": !isOnMobilePortrait ? undefined : {
        position: "absolute",
        bottom: 0,
        maxHeight: "92%",
      }
    }
  }),
  paper: {},
}));

export const PikchaModal = ({
  children,
  className = "",
  height,
  minHeight,
  hideCloseButton,
  maxWidth = "md",
  onClose,
  scrollToTop,
  FooterActionProps,
  darkHeader,
  title,
  hasCustomHeader,
  FullscreenPhotoModal,
  paperStyle = {},
  PaperProps,
  ScrollbarContainerProps,
  footerSecondaryAction,
  footerPrimaryAction,
  fullWidth = true,
  headerIcon = null,
  headerZIndex,
  headerStyle = {},
  titleStyle = {},
  ...rest
}) => {
  const isOnMobile = isMobile();
  const isOnMobilePortrait = isMobilePortrait();

  let scrollRef = useRef(null);

  const { root, paper } = useStyles({
    isOnMobilePortrait,
    title
  });

  useEffect(() => {
    if (scrollToTop !== undefined) {
      const refCur = scrollRef.current;
      if (refCur) {
        refCur.scrollTop = 0;
      }
    }
  }, [scrollToTop])

  // useEffect(() => {
  //   if (!rest.open) {
  //     // removeModalOverlay();
  //   } else {
  //     // enableBodyScroll(targetElement);
  //   }
  // }, [rest.open]);
  // const isOnTablet = isTablet();
  // const isOnDesktop = isDesktop();

  const handleClose = () => {
    enableBodyScroll(targetElement);
    // removeModalOverlay();
    onClose();
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      scroll="paper"
      className={`${className} ${root}`}
      maxWidth={maxWidth}
      transitionDuration={{
        enter: MODAL_ENTER_TRANSITION_DURATION,
        exit: MODAL_EXIT_TRANSITION_DURATION,
      }}
      // It appears that the body scroll lock function must be called
      // `onEntered`, not `onEnter`,
      onEntered={() => enableBodyScroll(targetElement)}
      onClose={handleClose}
      PaperProps={{
        className: `${paper} flex-column flex-stretch`,
        style: {
          // maxHeight: isOnMobile ? "100%" : null,
          height: height ? height : null,
          width: isOnMobile ? "100%" : null,
          minHeight: isOnMobile
            ? null
            : height
              ? null
              : minHeight
                ? minHeight
                : 600,
          margin: !isOnMobile ? null : 0,
          overflow: "hidden",
          ...paperStyle,
        },
        ...PaperProps,
      }}
      {...rest}
    >
      {!hasCustomHeader && (
        <PikchaModalHeader
          headerStyle={headerStyle}
          titleStyle={titleStyle}
          title={title}
          onClose={handleClose}
          darkHeader={!!darkHeader}
          hideCloseButton={!!hideCloseButton}
          headerIcon={headerIcon}
          headerZIndex={headerZIndex}
          FullscreenPhotoModal={!!FullscreenPhotoModal}
        />
      )}
      <PerfectScrollbar
        containerRef={el => (scrollRef.current = el)}
        style={{
          ...(ScrollbarContainerProps && ScrollbarContainerProps.style
            ? ScrollbarContainerProps.style
            : {}),
          // Compensate for the height of the header if a title is provided.
          // ...(title
          //   ? {
          //       paddingTop: 64,
          //     }
          //   : null),
        }}
        id="Modal-scrollContainer"
        className={`${ScrollbarContainerProps && ScrollbarContainerProps.className
          ? ScrollbarContainerProps.className
          : ""
          } flex-column flex-grow-1 flex-stretch modalScroll`}
        options={{
          suppressScrollX: true,
          handlers: ["click-rail", "drag-thumb", "keyboard", "wheel", "touch"],
          wheelPropagation: !isOnMobilePortrait
        }}
      >
        {children}
      </PerfectScrollbar>
      {FooterActionProps && (
        <PikchaModalFooter FooterActionProps={FooterActionProps} />
      )}
    </Dialog>
  );
};
