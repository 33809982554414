import { useLocation } from "react-router-dom";

import { ROUTES_WITH_FOOTER, ROUTES_WITHOUT_SIDEDRAWER } from "../helpers/constants";

export const useRouteWithFooter = () => {
  const { pathname } = useLocation();
  if(checkCustomizationPage(pathname)){
    return true
  }
  return ROUTES_WITH_FOOTER.includes(
    pathname
  )  ||
  ROUTES_WITH_FOOTER.includes(
    "/"+pathname.split('/')[1]
  ) 
  
  /*||
    pathname.includes("/auth")*/;
};

export const checkCustomizationPage = (pathname) => {
  let nameReg = /^\/artwork.*\/print$/;
  if(nameReg.test(pathname)){
    return true
  } else {
    return false
  }
}

export const useRouteWithoutSideDrawer = () => {
  const { pathname } = useLocation();
  if(checkCustomizationPage(pathname)){
    return true
  }
  if(pathname)
  return ROUTES_WITHOUT_SIDEDRAWER.includes(
    pathname
  )  ||
  ROUTES_WITHOUT_SIDEDRAWER.includes(
    "/"+pathname.split('/')[1]
  );
};
