import React from 'react';
import ContentLoader from "react-content-loader";
import { useTheme } from "@material-ui/core/styles";

export default (props) => {
  const { palette } = useTheme();
  return (
    <ContentLoader
      viewBox="0 0 100% 900"
      width="100%"
      height="75vh"
      backgroundColor={palette.grey.lighter}
      foregroundColor={palette.grey.lighter}
      speed={3}
      style={{
        marginTop: 56
      }}
      {...props}
    >
      <rect width="100%" height="100%" />

      {/* <rect x="45%" y="450" rx="10" ry="10" width="10%" height="40" />
      <rect x="44%" y="510" rx="5" ry="5" width="12%" height="20" />
      <rect x="44%" y="535" rx="5" ry="5" width="12%" height="20" />

      <rect x="10%" y="570" width="15%" height="200"/>
      <rect x="35%" y="590" width="30%" height="160"/>
      <rect x="75%" y="570" width="15%" height="200"/> */}
    </ContentLoader>
  )
}
