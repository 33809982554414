import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import './TagsChip.css';

export default ({
  palette,
  typography,
  text,
  index,
  handleTagDelete,
  deletable = true,
  backgroundColor,
}) => {
  const [hover, setHover] = useState(false);

  const handleHover = (state) => {
    setHover(state);
  };

  return (
    <CSSTransition in timeout={300} classNames="alert">
      <div className="TagsChip mb-half-em">
        <div
          className="TagsChip-chip"
          style={{
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.smallest,
            background: backgroundColor || 'rgb(33, 33, 33)',
            color: backgroundColor === 'white' ? palette.grey.darker : palette.grey.light,
            boxShadow: backgroundColor === 'white' ? '0px 0px 4px 0px rgb(0 0 0 / 0.15)' : null,
          }}
        >
          <span className="TagsChip-text">{text}</span>
          {deletable && (
            <svg
              onClick={() => handleTagDelete(text, index)}
              stroke="#FFF"
              fill="#FFF"
              style={{
                cursor: 'pointer',
                marginRight: '0.25em',
                enableBackground: 'new 0 0 24 24',
              }}
              version="1.1"
              viewBox="0 0 24 24"
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8,12l3.6-3.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L8.4,5.6c-0.8-0.8-2-0.8-2.8,0   c-0.8,0.8-0.8,2,0,2.8L9.2,12l-3.6,3.6c-0.8,0.8-0.8,2,0,2.8C6,18.8,6.5,19,7,19s1-0.2,1.4-0.6l3.6-3.6l3.6,3.6   C16,18.8,16.5,19,17,19s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L14.8,12z"
                id="exit"
              />
            </svg>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};
