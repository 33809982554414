import React, {cloneElement} from "react";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ICON_SIZE_SMALL} from "../../utils";
import {colors} from "../../colors";

export enum TYPOGRAPHY_BUTTON_TYPE {
  Primary = "primary",
  Outline = "outline",
  TextOnly = "text-only",
  IconLeading = "icon-leading",
  IconTrailing = "icon-trailing",
  IconOnly = "icon-only"
}

export enum TYPOGRAPHY_BUTTON_TEXT {
  Large = "large",
  Regular = "regular",
  Small = "small"
}

const typographyButtonHashMap = (disabled, hovering) => {
  return new Map([
    [
      TYPOGRAPHY_BUTTON_TYPE.Primary,
      { 
        backgroundColor: disabled ? colors.Neutral.grey30 : hovering ? colors.Neutral.ink70 : colors.Neutral.ink90, 
        color: colors.Neutral.white00, 
        cursor: disabled ? "unset" : "pointer",
        border: "none" 
      },
    ],
    [
      TYPOGRAPHY_BUTTON_TYPE.Outline,
      { 
        backgroundColor: "transparent", 
        color: disabled ? colors.Neutral.grey20 : colors.Neutral.ink90, 
        cursor: disabled ? "unset" : "pointer",
        border: disabled ? colors.Neutral.grey30 : colors.Neutral.ink90 
      },
    ],
    [
      TYPOGRAPHY_BUTTON_TYPE.TextOnly,
      { 
        backgroundColor: "transparent", 
        color: disabled ? colors.Neutral.grey20 : colors.Neutral.ink90, 
        cursor: disabled ? "unset" : "pointer",
        border: "none" 
      },
    ],
    [
      TYPOGRAPHY_BUTTON_TYPE.IconLeading,
      { 
        backgroundColor: disabled ? colors.Neutral.grey20 : hovering ? colors.Neutral.ink70 : colors.Neutral.ink90, 
        color: colors.Neutral.white00, 
        cursor: disabled ? "unset" : "pointer",
        border: "none",
        order: -1
      },
    ],
    [
      TYPOGRAPHY_BUTTON_TYPE.IconTrailing,
      { 
        backgroundColor: disabled ? colors.Neutral.grey20 : hovering ? colors.Neutral.ink70 : colors.Neutral.ink90, 
        color: colors.Neutral.white00, 
        cursor: disabled ? "unset" : "pointer",
        border: "none",
        order: 1
      },
    ],
    [
      TYPOGRAPHY_BUTTON_TYPE.IconOnly,
      { 
        backgroundColor: disabled ? colors.Neutral.grey20 : hovering ? colors.Neutral.ink70 : colors.Neutral.ink90, 
        color: colors.Neutral.white00, 
        cursor: disabled ? "unset" : "pointer",
        border: "none",
        order: -1,
        textDisplay: "none"
      },
    ],
  ]);
}

const typographyButtonTextHashMap = new Map([
  [
    TYPOGRAPHY_BUTTON_TEXT.Large,
    { lineHeight: 40, fontSize: "1.25em", letterSpacing: "auto" },
  ],
  [
    TYPOGRAPHY_BUTTON_TEXT.Regular,
    { lineHeight: 40, fontSize: "1em", letterSpacing: "auto" },
  ],
  [
    TYPOGRAPHY_BUTTON_TEXT.Small,
    { lineHeight: 40, fontSize: "0.75em", letterSpacing: "auto" },
  ],
]);

const TypographyButton = ({
                           component = TYPOGRAPHY_BUTTON_TYPE.Primary,
                           buttonText = TYPOGRAPHY_BUTTON_TEXT.Regular,
                           icon = null,
                           buttonStyle = {},
                           textStyle = {},
                           size = "small",
                           children,
                           disabled = false,
                           hovering = false,
                           // Any additional props will be passed to the
                           // underlying material-ui component.
                           ...rest
                         }) => {
  const { typography } = useTheme();

  return (
    <button
      disabled={disabled}
      style={{
        padding: component === TYPOGRAPHY_BUTTON_TYPE.IconOnly ? "10px" : "10px 24px",
        borderRadius: "4px",
        background: `${typographyButtonHashMap(disabled, hovering).get(component).backgroundColor}`,
        color: `${typographyButtonHashMap(disabled, hovering).get(component).color}`,
        cursor: `${typographyButtonHashMap(disabled, hovering).get(component).cursor}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: 
          `${typographyButtonHashMap(disabled, hovering).get(component).border}` !== "none" ? 
            `2px solid ${typographyButtonHashMap(disabled, hovering).get(component).border}` : 
            "transparent",
        ...buttonStyle
      }}
      {...rest}
    >
      {icon && cloneElement(icon, {
        ...(size === "small" && ({
          style: {
            ...ICON_SIZE_SMALL,
            order: `${typographyButtonHashMap(disabled, hovering).get(component).order}`,
            margin: "0 0.25em"
          }
        }))
      })}
      <Typography
        component='span'
        style={{
          fontSize: `${typographyButtonTextHashMap.get(buttonText).fontSize}`,
          fontFamily: typography.fontFamily,
          letterSpacing: typographyButtonTextHashMap.get(buttonText).letterSpacing,
          display:  typographyButtonHashMap(disabled, hovering).get(component).textDisplay,
          fontWeight: 600,
          ...textStyle
        }}
      >
          {children}
      </Typography>
    </button>
  );
};

export default TypographyButton;
