import { createContext } from "react";

const InviteCodeContext = createContext({
  // These are default values only; they are NOT actually used.
  isInvited: false,
  setIsInvited: (state) => {},
});

export const InviteCodeContextProvider = InviteCodeContext.Provider;

export default InviteCodeContext;
