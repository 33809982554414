import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import {PikchaButton} from "./PikchaButton";

export default ({ onClick, showLabel = true, style = {}, ...rest }) => {
  const [isHovering, setIsHovering] = useState(false);

  const { spacing, palette, shape, typography } = useTheme();

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  return (
    <PikchaButton
      onClick={onClick}
      action="octonary"
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      className="flex-center"
      style={{
        width: showLabel === false ? undefined : 71,
        padding: showLabel === false ? undefined : "0.3em 0.5em 0.3em 0.5em",
        backgroundColor: isHovering ? palette.grey.light : "white",
        fontSize: typography.smaller,
        fontWeight: 500,
        borderRadius: showLabel === false ? 50 : 6,
        border: showLabel ? `2px solid ${palette.grey.mediumLight}` : undefined,
        height: showLabel === false ? undefined : 32,
        ...style,
      }}
      {...rest}
    >
      <ArrowBackIosRoundedIcon
        fontSize="inherit"
        style={{
          color: isHovering ? palette.text.primary : palette.grey.medium,
        }}
      />
      &nbsp;
      {showLabel && (
        <span
          style={{
            color: isHovering ? palette.text.primary : palette.grey.medium,
          }}
        >
          back
        </span>
      )}
    </PikchaButton>
  );
};
