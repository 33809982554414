import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { setAlert } from "../../../actions/alert";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { getNearestAspectRatio } from "../../../helpers/imageManipulation/imageManipulationHelpers";
import ConfirmAlert from "../../../components/ConfirmAlert/ConfirmAlert";
import { MINIMUM_MEGA_PIXELS } from "../../../helpers/constants";
import {
  PikchaModal,
  PikchaModalHeader
} from "../../../components/baseComponents";
import { removeModalOverlay } from "../../../helpers/utils";
import UploadModalContent from "./UploadModalContent";
import UploadFlowModalStep1 from "./UploadFlowModalStep1";
import UploadFlowModalStep2 from "./UploadFlowModalStep2";
import UploadFlowModalStep3 from "./UploadFlowModalStep3";
import {
  getAspectRatio,
  getCropperSrc,
  getOriginImgData,
  getOriginSrc,
  getSelectedRatio
} from "../../../actions/cropper";
import { handleCropperState } from "../../../actions/cropper";

const UploadFlowModal = ({
  schematicDiagram,
  modelStep = 1,
  selectingRatio = 1,
  parentAspectRatio = "4,5",
  getSelectedRatio,
  setAlert,
  handleClickClose,
  getCropperSrc,
  getOriginSrc,
  getOriginImgData,
  getAspectRatio,
  open,
  releasedForms,
  cropper,
  croppedImgSrc,
  handleCropperState,
  getBackToUploadStep1 = () => {
  }
}) => {

  const useStyles = makeStyles(({ _ }) => ({
    FooterActionProps: {
      justifyContent: "start !important"
    },
  }));

  const cropperRef = useRef(null);
  const [uploadStep, setUploadStep] = useState(modelStep);
  const [confirmExitAlertState, setConfirmExitAlertState] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(croppedImgSrc);
  const [selectedRatio, setSelectedRatio] = useState(selectingRatio);
  const [aspectRatio, setAspectRatio] = useState(parentAspectRatio);
  const [imageFileLoading, setImageFileLoading] = useState(false);
  const [rawImageFile, setRawImageFile] = useState();
  const [handlingPublish, setHandlingPublish] = useState(false);
  const [coordinates, setCroppedCoordinates] = useState({
    Left: 0,
    Top: 0,
    Height: 0,
    Width: 0,
    aspRatio: "",
  });
  const [imageInfoPlaceholder, setImageInfoPlaceholder] = useState({});
  const { palette, typography } = useTheme();
  const history = useHistory();
  const hiddenInputRef = useRef(null);
  const handleModalClose = () => {
    if (uploadStep < 3) {
      handleClickClose();
      removeModalOverlay();
    } else {
      setConfirmExitAlertState(true);
    }
  }

  const redirectModalClose = () => {
    handleClickClose();
    removeModalOverlay();
  }
  const handleConfirmExitAlert = () => {
    setConfirmExitAlertState(false);
  }
  const handleCloseModalAndExitAlert = (_, cancelExit = false) => {
    if (!cancelExit) {
      if (!window.location.href.includes("/upload") || cropper.uploadStep === 1 && !cropper.src) {
        setUploadStep(1);
      } else {
        setUploadedImage(cropper.orgImgData);
        setAspectRatio(cropper.selectedRatio);
      }
      handleClickClose();
      setConfirmExitAlertState(false);
      removeModalOverlay();
    } else {
      setConfirmExitAlertState(false);
    }
  }

  const croppedCoordinatesHandler = useCallback(
    (top, left, width, height) => {
      setCroppedCoordinates({
        ...coordinates,
        Top: parseInt(top),
        Left: parseInt(left),
        Width: parseFloat(width).toFixed(5),
        Height: parseFloat(height).toFixed(5),
      });
    },
    [setCroppedCoordinates]
  );
  const onUploadFileChange = (e, onDone) => {
    e.preventDefault();
    let image;

    if (e.dataTransfer) {
      image = e.dataTransfer.files;
      if (!e.target.files) e.target.files = e.dataTransfer.files
    } else if (e.target) {
      image = e.target.files;
    }
    const fileReader = new FileReader();
    if (
      e.target &&
      e.target.files &&
      e.target.files.length > 0 &&
      (e.target.files[0].type.split("/")[1] === "jpeg" ||
        e.target.files[0].type.split("/")[1] === "jpg" ||
        e.target.files[0].type.split("/")[1] === "png")
    ) {
      fileReader.onload = () => {
        const uploadedImage = new Image();

        uploadedImage.onload = function () {
          let mpx = (uploadedImage.width * uploadedImage.height) / 1000000;

          if (mpx < MINIMUM_MEGA_PIXELS) {
            setImageFileLoading(false);
            setAlert(
              `Image should be at least ${MINIMUM_MEGA_PIXELS}Mpx`,
              "error"
            );
            return false;
          }
          if (uploadedImage && uploadedImage.width) {
            const nearestAspectRatio =
              getNearestAspectRatio(uploadedImage.width, uploadedImage.height);

            updateAspectRatio(nearestAspectRatio);
          }
          onDone(uploadedImage);
          setRawImageFile(image[0]);
          getOriginSrc(image[0]);
          if (uploadStep === 2) {
            setUploadStep(uploadStep + 1);
          }
          setUploadedImage(fileReader.result);
        };
        uploadedImage.src = fileReader.result;
        setImageFileLoading(true);
      };
      fileReader.readAsDataURL(image[0]);
    } else {
      setAlert("Unsupported file type!");
      return false;
    }
  };
  const onFileChange = (event) => {
    onUploadFileChange(event, (i) => {
      if (!i) {
        onUploadFileChange();
        onChangeImage();
      } else {

      }
    });
  };
  const onChangeImage = () => {
    hiddenInputRef.current.click();
  };
  const getMpx = () => {
    const mpx = (coordinates.Width * coordinates.Height) / 1000000;
    // return mpx.toPrecision(2);
    return mpx;
  };
  const currentStep = () => {
    switch (uploadStep) {
      case 1:
        return (
          <UploadFlowModalStep1 
            onHandleClose={redirectModalClose} 
            setUploadStep={setUploadStep}
            uploadStep={uploadStep}
            setAlert={setAlert}
          />
        )
      case 2:
        return (
          <UploadFlowModalStep2
            onChangeImage={() => {
              onChangeImage()
            }}
            onFileChange={onFileChange}
          />
        )
      case 3:
        return (
          <UploadFlowModalStep3
            hiddenInputRef={hiddenInputRef}
            handleRatioChange={setAspectRatio}
            cropperRef={cropperRef}
            coordinates={coordinates}
            uploadedImage={uploadedImage}
            setUploadStep={setUploadStep}
            handleClickClose={() => {
              handleClickClose();
              removeModalOverlay();
            }}
            rawImageFile={rawImageFile}
            step1SelectedRatio={selectedRatio}
            parentAspectRatio={aspectRatio}
            croppedCoordinatesHandler={croppedCoordinatesHandler}
            imageFileLoading={imageFileLoading}
            handlePublish={handlePublish}
            handleChangeSelectedRatio={(value) => {
              setSelectedRatio(value)
            }}
            updateAspectRatio={updateAspectRatio}
            onChangeImage={() => {
              onChangeImage()
            }}
            mpx={getMpx()}
            setImageInfoPlaceholder={setImageInfoPlaceholder}
            releasedForms={releasedForms}
          />
        )
    }
  }

  const updateAspectRatio = useCallback((aspectRatio) => {
    setAspectRatio(aspectRatio);
  },
    [setAspectRatio]);

  const handlePublish = () => {
    const imageElement = (cropperRef || {}).current;
    const cropper = (imageElement || {}).cropper;
    if (cropper.getCroppedCanvas() && cropper.getCroppedCanvas().toDataURL('image/jpeg')) {
      // make sure the app could get src data, if not then give user an alert to try again.
      let mpx = (coordinates.Width * coordinates.Height) / 1000000;
      if (mpx < MINIMUM_MEGA_PIXELS) {
        setAlert(`Your image should be at least ${MINIMUM_MEGA_PIXELS}MP`, "error");
        return false;
      }
      setHandlingPublish(true);
      handleCropperState(imageInfoPlaceholder);
      getCropperSrc(cropper.getCroppedCanvas().toDataURL('image/jpeg'));
      getOriginImgData(uploadedImage);
      getSelectedRatio(aspectRatio);
      getAspectRatio(aspectRatio);
      if (!window.location.href.includes("/upload")) {
        setUploadedImage("");
        setUploadStep(1);
      }
      getBackToUploadStep1();
      handleClickClose();
      removeModalOverlay();
      history.push("/upload");
    } else {
      setAlert("Still processing image, please try again.", "error");
    }
  }

  useEffect(() => {
    if (croppedImgSrc && !handlingPublish) {
      setUploadedImage(croppedImgSrc);
    }
  }, [croppedImgSrc])

  useEffect(() => {
    if (modelStep) {
      setUploadStep(modelStep);
    }
  }, [modelStep])

  useEffect(() => {
    if (parentAspectRatio) {
      setAspectRatio(parentAspectRatio);
    }
  }, [parentAspectRatio])

  return (
    <>
      <ConfirmAlert
        handleCloseModalAndExitAlert={handleCloseModalAndExitAlert}
        open={confirmExitAlertState}
        onClose={handleConfirmExitAlert}
      />
      <PikchaModal
        className="upload-modal"
        hasCustomHeader
        fullWidth
        disableBackdropClick
        onClose={handleModalClose}
        open={open}
        minHeight="initial"
        paperStyle={{
          maxWidth: '1032px',
          maxHeight: "806px",
          height: '95%',
          backgroundColor: '#fff'
        }}
      >
        <PikchaModalHeader
          reduceVerticalPadding
          onClose={handleModalClose}
          headerStyle={uploadStep === 3 && {
            width:"30%",
            right: 0
          }}
        >
        </PikchaModalHeader>
        <UploadModalContent className="flex-column" uploadStep={uploadStep}>
          {currentStep()}
        </UploadModalContent>
        <input
          type="file"
          onChange={(e) => {
            onFileChange(e)
          }}
          ref={hiddenInputRef}
          style={{
            display: "none",
          }}
        />
      </PikchaModal>
    </>
  )
}

const mapStateToProps = (state) => ({
  cropper: state.cropperReducer
});

export default connect(mapStateToProps, {
  setAlert,
  getCropperSrc,
  getOriginSrc,
  getOriginImgData,
  getAspectRatio,
  handleCropperState,
  getSelectedRatio
})(UploadFlowModal);