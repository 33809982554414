import React from "react";
import { connect } from "react-redux";

import { useTheme } from "@material-ui/core/styles";

import { expiredShoppingCartItems } from "../../../../selectors/shoppingCart";

const ExpiredItemsGrid = ({ expiredShoppingCartItems, style }) => {
  const { palette, typography } = useTheme();

  let coverPhoto1;
  if (expiredShoppingCartItems.length >= 1) {
    coverPhoto1 = expiredShoppingCartItems[0];
  }
  let coverPhoto2;
  if (expiredShoppingCartItems.length >= 2) {
    coverPhoto2 = expiredShoppingCartItems[1];
  }
  let coverPhoto3;
  if (expiredShoppingCartItems.length >= 3) {
    coverPhoto3 = expiredShoppingCartItems[2];
  }
  let coverPhoto4;
  if (expiredShoppingCartItems.length === 4) {
    coverPhoto4 = expiredShoppingCartItems[3];
  }

  let remainingItems = expiredShoppingCartItems.length - 3;

  const showRemainingItems = expiredShoppingCartItems.length > 4;

  return (
    <div
      style={{
        borderRadius: 6,
        width: 260,
        height: 260,
        backgroundColor: palette.grey.light,
        display: "flex",

        ...(style || {}),
      }}
    >
      <div
        style={{
          width: "50%",
          marginLeft: 2,
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            borderRadius: "6px 0px 0px 0px",
            height: "calc(50% - 1px)",
            backgroundColor: palette.common.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: coverPhoto1
              ? `url(${coverPhoto1.imageRender})`
              : undefined,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div
          style={{
            borderRadius: "0px 0px 0px 6px",
            marginTop: 1,
            height: "50%",
            backgroundColor: palette.common.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: coverPhoto3
              ? `url(${coverPhoto3.imageRender})`
              : undefined,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      <div
        style={{
          width: "50%",
          marginLeft: 1,
          marginRight: 2,
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            borderRadius: "0px 6px 0px 0px",
            height: "calc(50% - 1px)",
            backgroundColor: palette.common.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: coverPhoto2
              ? `url(${coverPhoto2.imageRender})`
              : undefined,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div
          style={{
            borderRadius: "0px 0px 6px 0px",
            marginTop: 1,
            height: "50%",
            backgroundColor: palette.common.white,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage:
              !showRemainingItems && coverPhoto4
                ? `url(${coverPhoto4.imageRender})`
                : undefined,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {showRemainingItems && (
            <span
              style={{
                fontWeight: typography.fontWeightMedium,
                fontSize: typography.larger,
                color: palette.text.disabled,
              }}
            >
              +{remainingItems}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  expiredShoppingCartItems: expiredShoppingCartItems(state),
});

export default connect(mapStateToProps, {})(ExpiredItemsGrid);
