import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {connect} from "react-redux";

import numeral from "numeral";
import clsx from "clsx";

import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import {useTheme} from "@material-ui/core/styles";
import CropPortraitIcon from "@material-ui/icons/CropPortrait";
import CropLandscapeIcon from "@material-ui/icons/CropLandscape";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import * as Vibrant from 'node-vibrant'
import {
  PikchaLabel,
  PikchaToggleButton,
  PikchaToggleButtonGroup
} from '../../../components/baseComponents';
// import {handleCropperData} from "../../../actions/cropper";
import AuthorizeService from "../../../auth/AuthorizeService";
import {usePalette} from 'color-thief-react'

import {ASPECT_RATIOS} from "../../../helpers/imageManipulation/imageManipulationConstants";
import {ICON_SIZE_DEFAULT,} from "../../../helpers/constants";

import {setAlert} from "../../../actions/alert";

import SignatureDisplayPreview from "./SignatureDisplayPreview";
import CustomColorPicker from "./CustomColorPicker";

import isTablet from "../../../components/Responsive/isTablet";
import EditSignatureBlock from "./EditSignatureBlock";
import {mapAspectRatioToRadioButtonKey} from "../../../helpers/imageManipulation/imageManipulationHelpers";
import {useStyles} from "./Step1.styles";
import ImageCropper from "../../../components/ImageCropper/ImageCropper";

import "cropperjs/dist/cropper.css";

const ImageContainer = ({
                          onUploadFileChange,
                          shouldShowUploadWindow,
                          croppedCoordinatesHandler,
                          onImageUpload,
                          account,
                          setAlert,
                          updateAspectRatio,
                          rawImageUploaded,
                          signatureUpdated,
                          rawImageFile,
                          uploadedImage,
                          imageFileLoading,
                          mpx,
                          selectedSignatureColor,
                          signatureFont,
                          onColorChange,
                          onFontChange,
                          currentToggle,
                          handleToggleChange,
                          handleUpdateSignature,
                          sendSignatureCall,
                          signatureData,
                          updateUserSignature,
                          step1SelectedRatio,
                          handleChangeSelectedRatio,
                          handleSignaturePromotion,
                          getExistingSvgSignature,
                          showSigPromo,

                          firstRendering,
                          svgSignature,
                          setSvgSignature,

                          user,

                          cropper,
                          handleOmitSignature,

                          parentAspectRatio,
                          ...rest
                        }) => {
  const isOnTablet = isTablet();

  const [aspectRatio, setAspectRatio] = useState(step1SelectedRatio);
  const [selectedRatio, setSelectedRatio] = useState(ASPECT_RATIOS[0]);
  const [VibrantColors, setVibrantColors] = useState([]);
  const [ThiefColors, setThiefColors] = useState([]);

  const [croppedValues, setCroppedValues] = useState({
    topStart: 0,
    leftStart: 0,
    width: 0,
    height: 0,
  });

  const [isCropperActive, setIsCropperActive] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState({
    signature: null,
    isBlack: true,
  });
  const [signatureSize, setSignatureSize] = useState({
    width: 0,
    height: 0,
  });
  const [chooseColor, setChooseColor] = useState(false);
  const [signatureColor, setSignatureColor] = useState(selectedSignatureColor || "#000000");
  const [chooseColorHover, setChooseColorHover] = useState(false);

  const [signaturePreviewImage, setSignaturePreviewImage] = useState("");

  const [uploadLimit, setUploadLimit] = useState(0);

  const {palette, typography, shape} = useTheme();

  const cropperRef = useRef(null);
  const signaturePreviewRef = useRef(null);
  const hiddenInputRef = useRef(null);

  useEffect(() => {
    if (signatureData || svgSignature) {
      handleOmitSignature(false);
    }
  }, [signatureData, svgSignature]);
  useEffect(() => {
    if (uploadLimit >= 20) {
      return;
    }

    if (shouldShowUploadWindow) {
      setIsCropperActive(false);

      onUploadFileChange();

      // hiddenInputRef.current.click();
    } else {
      setIsCropperActive(true);
    }
  }, [shouldShowUploadWindow]);

  // TODO: Delete this useEffect block if the app successfully runs without it.
  useEffect(() => {
    const signatureSizes = async () => {
      const getImage = async function (url) {
        return new Promise((resolve, reject) => {
          axios
            .get(url, {
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
            })
            .then((response) => {
              if (response && response.data) {
                setSvgSignature(response.data);
                /*
                let img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = URL.createObjectURL(response.data);
                */
              }
            });
        });
      };

      if (account.user && account.user.sign) {
        let img = await getImage(account.user.sign);
        let width = img.width;
        let height = img.height;
        setSignatureSize({
          width,
          height,
        });
      }
    };
    if (sendSignatureCall && firstRendering) {
      signatureSizes();
    }
  }, [account.user.sign, sendSignatureCall]);

  useEffect(() => {
    const verifyUploadLimit = async () => {
      const token = await AuthorizeService.getAccessToken();
      const artistId = account.user.id;
      axios
        .get(`/api/Image/UploadLimitValidation/${artistId}`, {
          artistId,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUploadLimit(response.data.uploadlimit);
        });
    };
    if (firstRendering) {
      verifyUploadLimit();
    }
  }, []);

  const logCroppedData = ({detail}) => {
    setCroppedValues({
      topStart: detail.y,
      leftStart: detail.y,
      width: detail.y,
      height: detail.y,
    });

    const topStart = detail.y; //cropper start point right
    const leftStart = detail.x; //cropper start point left
    const width = detail.width; //cropper width
    const height = detail.height; //cropper Height
    croppedCoordinatesHandler(topStart, leftStart, width, height);
  };
  const addAspectRatio = ({target: {value}}) => {
    const id = parseInt(value);
    let selRatio = null;
    setAspectRatio(id);
    handleChangeSelectedRatio(id);
    ASPECT_RATIOS.forEach((ratio) => {
      if (ratio.id === id) {
        selRatio = ratio;

        setSelectedRatio(ratio);
      }
    });
    if (selRatio && selRatio.value) {
      updateAspectRatio(selRatio.value);
    }
  };

  useEffect(() => {
    ASPECT_RATIOS.forEach((ratio) => {
      if (ratio.id === step1SelectedRatio) {
        setSelectedRatio(ratio);
      }
    });
  }, [step1SelectedRatio]);

  useEffect(() => {
    const detectedAspectRatio =
      ASPECT_RATIOS[mapAspectRatioToRadioButtonKey(parentAspectRatio)];

    setAspectRatio(detectedAspectRatio.id);
    setSelectedRatio(detectedAspectRatio);
  }, [step1SelectedRatio, aspectRatio, parentAspectRatio]);

  const getAspectRatios = (orientation) => {
    let ratios = [];
    if (orientation === "landscape") {
      ASPECT_RATIOS.forEach((item, index) => {
        if (item.orientation === "landscape") {
          ratios.push(
            <FormControlLabel
              key={index}
              value={item.id}
              control={
                <Radio
                  className={classes.root}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)}/>
                  }
                  icon={<span className={classes.icon}/>}
                />
              }
              label={item.label}
            />
          );
        }
      });
    } else if (orientation === "portrait") {
      ASPECT_RATIOS.forEach((item, index) => {
        if (item.orientation === "portrait") {
          ratios.push(
            <FormControlLabel
              key={index}
              value={item.id}
              control={
                <Radio
                  className={classes.root}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)}/>
                  }
                  icon={<span className={classes.icon}/>}
                />
              }
              label={item.label}
            />
          );
        }
      });
    } else {
      ASPECT_RATIOS.forEach((item, index) => {
        if (item.orientation === "square") {
          ratios.push(
            <FormControlLabel
              key={index}
              value={item.id}
              control={
                <Radio
                  className={classes.root}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)}/>
                  }
                  icon={<span className={classes.icon}/>}
                />
              }
              label={item.label}
            />
          );
        }
      });
    }

    return ratios;
  };
  const [isDragging, setIsDragging] = useState(false)
  const onChangeImage = () => {
    setIsCropperActive(false);

    hiddenInputRef.current.click();
  };

  const onChooseColor = () => {
    setChooseColor(true);
  };
  const onCancelChoosingColor = () => {
    setChooseColor(false);
  };
  const onColorChanged = (color) => {
    setSignatureColor(color.hex);
  };
  const onColorSelected = (color, event) => {
    setChooseColor(false);
    setSignatureColor(signatureColor);
    onColorChange(signatureColor);
  };

  const defaultColorSelected = (color) => {
    setChooseColor(false);
    setSignatureColor(color);
    onColorChange(color);
  }

  const onHoverChooseColor = () => {
    setChooseColorHover(true);
  };
  const onHoverLeaveChooseColor = () => {
    setChooseColorHover(false);
  };

  const onFileChange = (event) => {
    setIsCropperActive(false);
    onUploadFileChange(event, (i) => {
      if (!i) {
        onUploadFileChange();
        onChangeImage();
      } else {
        setIsCropperActive(true);

        // addAspectRatio({
        //   target: {
        //     value: i.width < i.height ? 4 : 1,
        //   },
        // });
      }
    });
  };

  const classes = useStyles();

  let cropBox;
  if (cropperRef.current && cropperRef.current.cropper) {
    cropBox = cropperRef.current.cropper.cropBoxData;
  }
  let croppedSignatureWidth;
  let croppedSignatureHeight;
  let croppedSignatureTop;
  let croppedSignatureLeft;
  let cropperSignatureTextSize;
  if (cropBox) {
    const squareSigPx = (cropBox.width * cropBox.height * 12) / 10000;
    const imageRatio = cropBox.width / cropBox.height;
    let signatureRatio = signatureSize.width / signatureSize.height;
    if (isNaN(signatureRatio)) {
      signatureRatio = 2;
    }
    croppedSignatureHeight = Math.sqrt(squareSigPx / signatureRatio);
    croppedSignatureWidth = squareSigPx / croppedSignatureHeight;

    cropperSignatureTextSize = croppedSignatureHeight * 0.25;
    if (!isNaN(croppedSignatureHeight) && !isNaN(croppedSignatureWidth) && !isNaN(cropperSignatureTextSize)) {
      croppedSignatureTop =
        cropBox.top +
        cropBox.height -
        croppedSignatureHeight -
        cropperSignatureTextSize -
        Math.min(cropBox.height * 0.015, cropBox.width * 0.015);
      croppedSignatureLeft = currentToggle === "right" ?
        cropBox.left +
        cropBox.width -
        croppedSignatureWidth -
        Math.min(cropBox.height * 0.015, cropBox.width * 0.015) + 4 :
        cropBox.left + Math.min(cropBox.height * 0.015, cropBox.width * 0.015) + 4
    } else {
      croppedSignatureTop =
        cropBox.top +
        cropBox.height -
        Math.min(cropBox.height * 0.015, cropBox.width * 0.015) - 15;
      croppedSignatureLeft = currentToggle === "right" ?
        cropBox.left +
        cropBox.width -
        Math.min(cropBox.height * 0.015, cropBox.width * 0.015) - 45 :
        cropBox.left + Math.min(cropBox.height * 0.015, cropBox.width * 0.015)
    }
  }
  const signatureType = selectedSignature.isBlack ? "orgSig" : "invSig";

  useEffect(() => {
    const hexList = [];
    if (uploadedImage) {
      Vibrant.from(uploadedImage).getPalette((err, palette) => {
        const length = Object.keys(palette).length;
        for (let i = 0; i < length; i++) {
          const key = Object.keys(palette)[i];

          if (!palette[key]) {
            break;
          }
          
          const hexValue = palette[key].hex;
          hexList.push(hexValue);
          if (i === 0) {
            setSignatureColor(hexValue);
            defaultColorSelected(hexValue);
          }
        }
      });
    }
    setVibrantColors(hexList);
  }, [uploadedImage]);

  const {
    data,
    loading,
    error
  } = usePalette(uploadedImage, (VibrantColors.length || 6), "hex");

  useEffect(() => {
    setThiefColors(data);
  }, [data, uploadedImage]);

  useEffect(() => {
    if (rawImageFile && showSigPromo && !signatureData && !svgSignature) {
      handleSignaturePromotion()
    }
  }, [rawImageFile]);

  const handleBackgroundPreview = () => {
    if (cropperRef && cropperRef.current) {
      const base64Preview = cropperRef.current.getCroppedCanvas({
        maxWidth: 2000,
        maxHeight: 1600
      }).toDataURL();
      setSignaturePreviewImage(base64Preview);
    }
  }

  return (
    <>
      {uploadLimit >= 20 && (
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            color: palette.text.secondary,
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.smallest,
          }}
        >
          <p>We're currently limiting artists to 20 uploads per week</p>
          <p
            style={{
              marginTop: "1em",
            }}
          >
            In the meantime, checkout our selling tips{" "}
            <a href="/guides/artist/advertising">here</a>
          </p>
        </div>
      )}
      <div
        style={{
          display: uploadLimit >= 20 ? "none" : "flex",
          flex: "1 1 auto",
          flexDirection: "column",
        }}
      >
        <div className="Step1-imageMetaSection">
          <div
            style={{
              width: "100%",
              display: "block",
            }}
          >
            <Grid container>
              <Grid item xs={4}>
                {/* <PikchaLabel>
                  Cropped Size
                  <span
                    style={{
                      color: parseInt(numeral(mpx).format("0")) >= 15 ? "rgb(0, 230, 118)" : "red"
                    }}
                  >
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{numeral(mpx).format("0.0")}mp
                  </span>
                </PikchaLabel> */}

                <PikchaLabel>
                  Cropped Size
                  <span
                    style={{
                      color: Math.floor(mpx)>= 15 ? "rgb(0, 230, 118)" : "red"
                    }}
                  >
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Math.floor(mpx)}mp
                  </span>
                </PikchaLabel>
                
                <Grid
                  container
                  style={{
                    marginTop: ".125em",
                  }}
                >
                  <div
                    style={{
                      marginRight: "1em",
                      display: "block",
                    }}
                  >
                    <CropPortraitIcon
                      style={{
                        ...ICON_SIZE_DEFAULT,
                        display: "block",
                        color: palette.text.primary,
                      }}
                    />
                    <CropLandscapeIcon
                      style={{
                        ...ICON_SIZE_DEFAULT,
                        display: "block",
                        color: palette.text.primary,
                      }}
                    />
                    <CropSquareIcon
                      style={{
                        ...ICON_SIZE_DEFAULT,
                        display: "block",
                        color: palette.text.primary,
                      }}
                    />
                  </div>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "block",
                      }}
                    >
                      <FormControl
                        size="small"
                        component="div"
                        style={{
                          minWidth: 144,
                          display: "block",
                        }}
                      >
                        <RadioGroup
                          aria-label="Aspect Ratio"
                          name="Aspect Ratio"
                          value={aspectRatio}
                          style={{
                            display: "block",
                          }}
                          onChange={addAspectRatio}
                        >
                          {getAspectRatios("portrait")}
                        </RadioGroup>
                      </FormControl>
                      <FormControl
                        size="small"
                        component="div"
                        style={{
                          minWidth: 144,
                          display: "block",
                        }}
                      >
                        <RadioGroup
                          aria-label="Aspect Ratio"
                          name="Aspect Ratio"
                          value={aspectRatio}
                          style={{
                            display: "block",
                          }}
                          onChange={addAspectRatio}
                        >
                          {getAspectRatios("landscape")}
                        </RadioGroup>
                      </FormControl>
                      <FormControl
                        size="small"
                        style={{
                          minWidth: 144,
                          display: "block",
                        }}
                        component="div"
                      >
                        <RadioGroup
                          aria-label="Aspect Ratio"
                          name="Aspect Ratio"
                          style={{
                            display: "block",
                          }}
                          value={aspectRatio}
                          onChange={addAspectRatio}
                        >
                          {getAspectRatios("square")}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <input
                  type="file"
                  onChange={onFileChange}
                  ref={hiddenInputRef}
                  style={{
                    display: "none",
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <PikchaLabel>Signature Preview</PikchaLabel>
                <div
                  style={{
                    display: "flex",
                    marginTop: ".125em",
                    marginLeft: ".125em",
                  }}
                >
                  <div
                    ref={signaturePreviewRef}
                    style={{
                      backgroundImage: `url("${signaturePreviewImage}")`,
                      backgroundColor: palette.grey.light,
                      borderRadius: 6,
                      marginRight: "1em",
                      padding: "0.5em",
                      backgroundPosition: `bottom ${currentToggle}`
                    }}
                  >
                    <SignatureDisplayPreview
                      {...rest}
                      signatureData={signatureData}
                      // userSignatureSVG={userSignatureSVG}
                      sendSignatureCall={sendSignatureCall}
                      color={selectedSignatureColor}
                      height={50}
                      //alignInnerRight
                      textSize={12}
                      signature={svgSignature}
                      signatureType={signatureType}
                      signatureFont={signatureFont}
                      style={{
                        width: 150
                      }}

                      alignInnerPosition={currentToggle}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "20px"
                        }}
                      >
                        <PikchaToggleButtonGroup
                          style={{
                            display: "flex",
                            width: "120px",
                            border: "2px solid rgb(238, 238, 238)",
                          }}
                          value={currentToggle}
                        >
                          <PikchaToggleButton
                            style={{
                              color: palette.text.disabled,
                              width: 60,
                              height: 32,
                              border: 0
                            }}
                            selectedStyle={{
                              color: `${palette.text.primary} !important`,
                            }}
                            value="left"
                            aria-label="Left"
                            onClick={() => {
                              handleToggleChange("left");
                            }}
                          >
                            Left
                          </PikchaToggleButton>
                          <PikchaToggleButton
                            style={{
                              color: palette.text.disabled,
                              width: 60,
                              height: 32,
                              border: 0
                            }}
                            selectedStyle={{
                              color: `${palette.text.primary} !important`,
                            }}
                            value="right"
                            aria-label="Right"
                            onClick={() => {
                              handleToggleChange("right");
                            }}
                          >
                            Right
                          </PikchaToggleButton>
                        </PikchaToggleButtonGroup>
                      </div>
                      <div
                        style={{
                          position: "relative"
                        }}
                      >
                        <div
                          onMouseEnter={onHoverChooseColor}
                          onMouseLeave={onHoverLeaveChooseColor}
                          onClick={onChooseColor}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            border: chooseColor
                              ? `2px solid ${palette.primary.main}`
                              : chooseColorHover
                                ? `2px solid ${palette.common.black}`
                                : `2px solid ${palette.grey.light}`,
                            borderRadius: shape.borderRadius,
                            background: "white",
                            height: 32
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: signatureColor || "#000000",
                                width: 16,
                                height: 16,
                                borderRadius: shape.borderRadius,
                                marginLeft: "0.5em",
                                marginRight: "0.5em",
                                boxShadow: "0px 1px 5px 0.5px rgba(0, 0, 0, 0.15)"
                              }}
                            />
                          </div>
                        </div>
                        {chooseColor && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: !isOnTablet ? "50%" : "-180px",
                              transform: "translateY(-1%) translateX(-10%)",
                              zIndex: 100
                            }}
                          >
                            <CustomColorPicker
                              disableAlpha
                              onCancelChoosingColor={onCancelChoosingColor}
                              onChange={onColorChanged}
                              onApply={onColorSelected}
                              color={signatureColor}
                              vibrantColors={VibrantColors}
                              uploadedImage={uploadedImage}
                              thiefColors={ThiefColors}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {isOnTablet && (<div className="flex"><EditSignatureBlock
                      isOnTablet={isOnTablet}
                      onChangeImage={onChangeImage}
                      rawImageFile={rawImageFile}
                      handleUpdateSignature={handleUpdateSignature}
                    /></div>)}
                  </div>
                </div>
              </Grid>
              {!isOnTablet && (
                <Grid item xs={3}>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <EditSignatureBlock
                      isOnTablet={isOnTablet}
                      onChangeImage={onChangeImage}
                      rawImageFile={rawImageFile}
                      handleUpdateSignature={handleUpdateSignature}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
        <ImageCropper
          ref={{hiddenInputRef, cropperRef}}

          rawImageFile={rawImageFile}
          isDragging={isDragging}
          cropBox={cropBox}
          typography={typography}
          palette={palette}
          signatureData={signatureData}
          croppedSignatureTop={croppedSignatureTop}
          croppedSignatureLeft={croppedSignatureLeft}
          onFileChange={onFileChange}
          uploadedImage={uploadedImage}
          setIsDragging={setIsDragging}
          imageFileLoading={imageFileLoading}
          onChangeImage={onChangeImage}
          selectedRatio={selectedRatio}
          logCroppedData={logCroppedData}
          onChangeImage={onChangeImage}
          handleBackgroundPreview={handleBackgroundPreview}
          sendSignatureCall={sendSignatureCall}
          color={selectedSignatureColor}
          width={croppedSignatureWidth}
          height={croppedSignatureHeight}
          textSize={cropperSignatureTextSize}
          signature={svgSignature}
          signatureType={signatureType}
          alignInnerPosition={currentToggle}
          signatureFont={signatureFont}
          signatureMaxWidth={croppedSignatureWidth}
          signatureMaxHeight={croppedSignatureHeight}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  // cropper: state.cropperReducer
});

export default connect(mapStateToProps, {
  // handleCropperData,
  setAlert
})(ImageContainer);
