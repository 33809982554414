import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { PikchaRadioButton } from "./PikchaRadioButton";

export const PikchaFormControlLabel = withStyles((theme) => ({
  root: {},
}))(({ value, ...rest }) => (
  <FormControlLabel control={<PikchaRadioButton value={value} />} {...rest} />
));
