import React, { useRef } from "react";
import { connect } from "react-redux";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  queuedShoppingCartItems,
  readyShoppingCartItems,
  shoppingCartItems,
} from "../../../selectors/shoppingCart";
import ExpiredItemBoxDesktop from "./ExpiredItemBoxDesktop";
import isMobile from "../../Responsive/isMobile";
import { makeStyles } from "@material-ui/styles";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";

const useStyles = ({ isOnMobile }) => makeStyles(({ palette }) => ({
  timeOffContainer: {
    borderRadius: 6,
    boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.15)",
    position: "inherit",
    padding: "0.5em",
    margin: isOnMobile ? "1em 1em" : "1em"
  },
  expiredItem: {
    margin: "1em 1em 0.5em 1em",
    alignItems: "center"
  },
  expiredItemBox: {
    borderRadius: 6,
    margin: "1em",
    animation: "fadeIn 0.6s",
    width: isOnMobile ? 275 : 345,
    backgroundColor: palette.common.white,
  }
}));

const ExpiredItems = ({
  counter,
  updateTimer,
  deleteItem,
  addBackToCart,
  queuedShoppingCartItems,
}) => {
  const isOnMobile = isMobile();
  const sliderRef = useRef(null);
  const classes = useStyles({ isOnMobile })();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2.2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2.2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.6
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className={classes.timeOffContainer}>
      <div className={classes.expiredItem}>
        <TypographyBody
          size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <TimerOffIcon fontSize={'small'} /> &nbsp; Expired Items
        </TypographyBody>
        <TypographyBody
          style={{ margin: isOnMobile ? "12px 0px 0px 0px" : "12px 0px 0px auto" }}
        >
          Please add any expired items to your cart before you proceed to checkout
        </TypographyBody>
      </div>
      <Carousel
        responsive={responsive}
        ref={sliderRef}
        containerClass="carousel-container-with-scrollbar"
        partialVisible
        arrows={false}
      >
        {queuedShoppingCartItems.map(
          ({ shoppingCartItem, timer }, index) => (
            <div className={classes.expiredItemBox}>
              <ExpiredItemBoxDesktop
                key={index}
                shoppingCartItem={shoppingCartItem}
                shoppingCartItemTimer={timer}
                counter={counter}
                updateTimer={updateTimer}
                deleteItem={deleteItem}
                addBackToCart={addBackToCart}
                isOnMobile={isOnMobile}
                queued
              />
            </div>
          ))
        }
      </Carousel>
    </div>
  );
};

const mapStateToProps = (state) => {
  return ({
    state,
    account: state.accountReducer,
    shippingCart: state.shoppingCartReducer,
    shoppingCartItems: shoppingCartItems(state),
    readyShoppingCartItems: readyShoppingCartItems(state),
    queuedShoppingCartItems: queuedShoppingCartItems(state),
  });
};
export default connect(mapStateToProps, {})(ExpiredItems);
