import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { removeModal } from "../../../actions/modal";
import { PikchaModal } from "../../../components/baseComponents";
import {
  createCollection,
  updateCollection,
  deleteCollection,
  onImageSelectedForCreateAlbum,
  onImageDeselectedForCreateAlbum,
  resetSelectedImages,
  onSelectedImages,
} from "../../../actions/albums";
import { setAlert } from "../../../actions/alert";
import {
  isImageSelectedForCreateAlbum,
  selectedImages,
} from "../../../selectors/albums";
import isMobile from "../../../components/Responsive/isMobile";
import SelectionMasonry from "../../gallery/SelectionMasonry/SelectionMasonry.jsx";
import { getAddToAlbumPhotos, resetAddToAlbumPhotos } from "../../../actions/addToAlbumImages";
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographyDescriptionTextArea,
  TypographyTextInput,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  colors
} from "@pikcha/typography";
import Close from "@material-ui/icons/Close"
import UploadIconDefaultImage from "../../../assets/images/Collection/UploadIconDefaultImage.png"
import BannerIconDefaultImage from "../../../assets/images/Collection/BannerIconDefaultImage.png"
import { useHistory } from "react-router-dom"
import isTablet from "../../../components/Responsive/isTablet";

const CreateCollection = ({
  albumItem,
  addToAlbum,
  account,
  albums,
  selectedImages,
  getAddToAlbumPhotos,
  resetAddToAlbumPhotos,
  onImageSelectedForCreateAlbum,
  onImageDeselectedForCreateAlbum,
  resetSelectedImages,
  createCollection,
  updateCollection,
  deleteCollection,
  onSelectedImages,
  setAlert,
  removeModal,
  onCloseAlbumModal
}) => {
  const isEditingAlbum = !!albumItem;
  const history = useHistory()
  const artistId = account.user.id;
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const [albumTitle, setAlbumTitle] = useState("");
  const [albumDescription, setAlbumDescription] = useState("");
  // const [coverImages, setCoverImages] = useState([]);
  const [iconImage, setIconImage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [iconError, setIconError] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const [editingCollectionId, setEditingCollectionId] = useState("");

  let isCreateAlbumDisabled = true;
  if (albumTitle.length > 0) {
    if (selectedImages.length >= 3) {
      if (iconImage && bannerImage) {
        isCreateAlbumDisabled = false;
      }
    }
  }

  const onGetPhotos = (count, start) => {
    getAddToAlbumPhotos(artistId, count, start);
  };
  const onResetGallery = () => {
    resetAddToAlbumPhotos();
  };
  const isImageSelected = (photo) => {
    if (photo && photo.id)
      return isImageSelectedForCreateAlbum(albums, photo);
  };
  const onImageSelected = (id, photo) => {
    onImageSelectedForCreateAlbum(photo);
  };
  const onImageDeselected = (id, photo) => {
    onImageDeselectedForCreateAlbum(photo);
  };

  const handleAlbumTitle = ({ target: { value } }) => {
    setAlbumTitle(value);
  };
  const onAlbumDescriptionChange = (value) => {
    setAlbumDescription(value);
  };

  const hiddenInputRef = useRef(null);
  const hiddenInputRef1 = useRef(null);

  const fileInputRef = () => {
    hiddenInputRef.current.click();
  };

  const fileInputRef1 = () => {
    hiddenInputRef1.current.click();
  };

  const onCloseModal = () => {
    removeModal();
    resetSelectedImages();
    setAlbumTitle("");
    setAlbumDescription("");
  };

  const onCreateAlbum = () => {
    if(iconError){
      setAlert("icon file is not correct, please re-upload a new one", "error")
      return
    }
    if(bannerError){
      setAlert("banner file is not correct, please re-upload a new one", "error")
      return
    }
    createCollection({
      title: albumTitle,
      description: albumDescription,
      icon: iconImage,
      banner: bannerImage,
      onDone: () => {
        onCloseModal();
        setAlert("Collection created", "success");
        window.location.reload();
      },
      onError: (err) => {
        console.log(err.response);
        setAlert("The Image already added in collection7 collection", "error");
      },
    });
  };
  
  const onSaveChanges = () => {
    if(iconError){
      setAlert("icon file is not correct, please re-upload a new one", "error")
      return
    }
    if(bannerError){
      setAlert("banner file is not correct, please re-upload a new one", "error")
      return
    }
    const { id: collectionId } = albumItem;
    updateCollection({
      collectionId,
      title: albumTitle,
      description: albumDescription,
      icon: iconImage,
      banner: bannerImage,
      onDone: () => {
        onCloseModal();
        setAlert("Collection updated", "success");
        window.location.reload();
      },
      onError: () => { },
    });
  };

  const onIconFileInputChanged = (event) => {
    setIconError(false);
    const selectedFile = event.target.files[0];
    const ALLOWED_IMAGE_TYPES = ["image/png", "image/jpeg"]
    if(selectedFile.size > 4194304) {
      setAlert("icon file is larger than 4M, please re-upload a new one", "error");
      return
    }
    if (selectedFile && ALLOWED_IMAGE_TYPES.includes(selectedFile.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setIconImage(reader.result)
      };
      reader.readAsDataURL(selectedFile)
    }
    else {
      setIconError(true)
    }
  }

  const onBannerFileInputChanged = (event) => {
    setBannerError(false);
    const selectedFile = event.target.files[0];
    const ALLOWED_IMAGE_TYPES = ["image/png", "image/jpeg"];
    if(selectedFile.size > 6291456) {
      setAlert("banner file is larger than 6M, please re-upload a new one", "error");
      return
    }
    if (selectedFile && ALLOWED_IMAGE_TYPES.includes(selectedFile.type)) {
      let reader = new FileReader();
      reader.onload = () => {
        if(reader.readyState === 2){
          const img = new Image();
          img.src = reader.result;
          img.onload = function () {
            if(this.height < 1152 || this.width < 2048) {
              setAlert("banner file resolution is too small, please re-upload a new one", "error")
              setBannerError(true);
              return
            }
          }
        }
      }
      reader.onloadend = () => {
        setBannerImage(reader.result)
      };
      reader.readAsDataURL(selectedFile)
    }
    else {
      setBannerError(true)
    }
  }

  const onDeleteAlbum = () => {
    const { id: collectionId } = albumItem;
    const profileRedirect = albumItem?.pikchaUser?.userHandle
    deleteCollection({
      collectionId,
      onDone: () => {
        onCloseModal();
        setAlert("Collection deleted", "info");
        history.push(`/${profileRedirect}`);
        window.location.reload();
      },
      onError: () => { },
    });
  };

  useEffect(() => {
    if (albumItem) {
      const {
        title,
        description,
        images,
        id,
        icon,
        banner
      } = albumItem;

      setAlbumTitle(title);
      setAlbumDescription(description);
      onSelectedImages(images);
      setEditingCollectionId(id);
      setIconImage(icon);
      setBannerImage(banner);
    }
  }, [albumItem]);

  const bannerPreview = useCallback(
    () => {
      return (
        <div>
          <div
            style={{
              position: 'relative',
              width: 100,
              height: 35,
              background: `url(${bannerImage}) center center / cover no-repeat`,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div 
              style={{
                maxWidth: "100%",
                width: "100%",
                position: 'absolute',
                bottom: '-15px',
                left: 7
              }}
            >
              <div 
                style={{
                  border: '1px solid #FFF',
                  borderRadius: 2,
                  filter: 'drop-shadow(0px 2.47px 2.47px rgba(0, 0, 0, 0.1))',
                  width: 14,
                  height: 14,
                  backgroundColor: `${colors.Neutral.grey30}`,
                }}
              />
              <div
                style={{
                  width: 30,
                  height: 3,
                  borderRadius: 2,
                  backgroundColor: colors.Neutral.grey40,
                  margin: "2 0"
                }}
              />
              <div
                style={{
                  width: 15,
                  height: 3,
                  borderRadius: 2,
                  backgroundColor: colors.Neutral.grey40
                }}
              />
            </div>

          </div>
        </div>
      )   
    },
    [bannerImage],
  )

  return (
    <>
      <PikchaModal
        fullWidth
        hasCustomHeader
        onClose={() => { onCloseAlbumModal() }}
        open={true}
        minHeight="initial"
        paperStyle={{
          maxWidth: 1114,
          maxHeight: "900px",
          height: "95%",
        }}
        headerStyle={{
          marginLeft: '1.5em',
          marginTop: '2.5em'
        }}
      >
        <div
          style={{
            padding: "2em 2.5em 2.5em 2.5em"
          }}
        >
          <div
            style={{
              display: 'flex',
              paddingBottom: '2.5em',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <TypographySubtitle
              component={
                isOnMobile ? TYPOGRAPHY_SUBTITLE_COMPONENT.iPad_H3_Bold :
                  isOnTablet ? TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H3_Bold :
                    TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold
              }
            >
              {isEditingAlbum ? "Edit Your Collection" : "Create a new collection"}
            </TypographySubtitle>
            {isEditingAlbum ?
              (
                <TypographyBody
                  onClick={() => onDeleteAlbum()}
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                  style={{
                    color: ' #ED0000',
                    cursor: 'pointer'
                  }}
                >
                  Delete Collection
                </TypographyBody>
              ) :
              (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => onCloseAlbumModal()}
                >
                  <Close />
                </div>
              )
            }

          </div>
          <div
            style={{
              ...(isOnMobile
                ? {}
                : {
                  display: "flex",
                  width: '100%'
                }),
            }}
          >
            <div
              style={{
                ...(isOnMobile
                  ? {
                    width: "100%",
                  }
                  : {
                    minWidth: '50%',
                    marginTop: '1.50em',
                    marginRight: "2em",
                  }),
              }}
            >
              <TypographyTextInput
                required={true}
                value={albumTitle}
                type="text"
                label="Collection title"
                onChange={handleAlbumTitle}
              />
              <TypographyDescriptionTextArea
                label="Description"
                rows={11}
                maxChars={400}
                value={albumDescription}
                onChange={onAlbumDescriptionChange}
                style={{
                  marginTop: "1em",
                  maxHeight: '144px'
                }}
              />
              <div style={{ marginBottom: '1.5em', marginTop: '3em' }}>
                <TypographyBody style={{ marginBottom: '1em' }} size={TYPOGRAPHY_BODY_SIZE.Small_Bold}>
                  Collection Picture
                  <span style={{
                    color: 'red',
                    fontWeight: '600',
                  }}>
                    *
                  </span>
                </TypographyBody>
                <div style={{ display: 'flex' }}>
                  <div>
                    {iconError && <p>File Not Supported</p>}
                    <>
                      {iconImage &&
                        (
                          <div 
                            style={{
                              border: '1px solid #FFF',
                              borderRadius: '11px',
                              filter: 'drop-shadow(0px 2.47px 2.47px rgba(0, 0, 0, 0.1))',
                              width: '80px',
                              height: '80px',
                              background: `url(${iconImage}) center center / cover no-repeat`,
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)"
                            }}
                          />
                        )}
                      {!iconImage &&
                        (
                          <img style={{ height: '80px', width: '80px' }} src={UploadIconDefaultImage} alt="icon" />
                        )
                      }
                    </>
                  </div>
                  <div>
                    <TypographyBody style={{ marginLeft: '38px' }} size={TYPOGRAPHY_BODY_SIZE.Small_Regular}>
                      Recommended picture size is <br />
                      98 x 98 pixels and less than 4MB. <br />
                      PNG and JPEG file types accepted.
                    </TypographyBody>
                    {iconImage &&
                      (
                        <TypographyButton
                          onClick={fileInputRef}
                          buttonStyle={{ paddingLeft: '36px' }}
                          component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
                        >
                          Change
                        </TypographyButton>
                      )}
                    {!iconImage &&
                      (
                        <TypographyButton
                          onClick={fileInputRef}
                          buttonStyle={{ paddingLeft: '36px' }}
                          component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
                        >
                          Upload picture
                        </TypographyButton>
                      )}
                    <input
                      ref={hiddenInputRef}
                      className="full-width"
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      type="file"
                      onChange={onIconFileInputChanged}
                    />
                  </div>
                </div>
              </div>
              <div>
                <TypographyBody style={{ marginBottom: '1em' }} size={TYPOGRAPHY_BODY_SIZE.Small_Bold}>
                  Collection Banner
                  <span style={{
                    color: 'red',
                    fontWeight: '600',
                  }}>
                    *
                  </span>
                </TypographyBody>
                <div style={{ display: 'flex' }}>
                  <div>
                    <>
                      {bannerImage ?
                        (
                          bannerPreview()
                        ) :
                        (
                          <img style={{ height: '80px', width: '80px' }} src={BannerIconDefaultImage} alt="banner" />
                        )
                      }
                    </>
                  </div>
                  <div>
                    <TypographyBody style={{ marginLeft: '16px' }} size={TYPOGRAPHY_BODY_SIZE.Small_Regular}>
                      For the best results on all devices, <br /> use an image that’s at least 2048 <br /> x 1152 pixels and 6MB or less.
                    </TypographyBody>
                    {bannerImage &&
                      (
                        <TypographyButton
                          onClick={fileInputRef1}
                          buttonStyle={{ paddingLeft: '36px' }}
                          component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
                        >
                          Change
                        </TypographyButton>
                      )}
                    {!bannerImage &&
                      (
                        <TypographyButton
                          onClick={fileInputRef1}
                          buttonStyle={{ paddingLeft: '16px' }}
                          component={TYPOGRAPHY_BUTTON_TYPE.TextOnly} >
                          Upload Banner
                        </TypographyButton>
                      )}
                    <input
                      ref={hiddenInputRef1}
                      className="full-width"
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      type="file"
                      onChange={onBannerFileInputChanged}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="full-width" style={{marginTop: isOnMobile && "2em"}}>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              >
                Choose images for your collection (Minimum 3)
                <span style={{
                  color: 'red',
                  fontWeight: '600',
                }}>
                  *
                </span>
              </TypographyBody>
              <SelectionMasonry
                gallery={addToAlbum}
                getPhotos={onGetPhotos}
                resetGallery={onResetGallery}
                isImageSelected={isImageSelected}
                onImageSelected={onImageSelected}
                onImageDeselected={onImageDeselected}
                editingCollectionId={editingCollectionId}
                style={{
                  padding: '1.5em',
                  border: '1px solid #DBE0E4',
                  borderRadius: '4px',
                }}
              />
            </div>
          </div>
          <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: !isOnMobile && 'end',
            marginTop: '2.5em'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: isOnMobile && 'column-reverse',
              width: isOnMobile && '100%'
            }}
            >
              <TypographyButton
                buttonStyle={{
                  marginRight: !isOnMobile && '0.5em',
                  marginTop: isOnMobile && '1em'
                }}
                component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                onClick={() => onCloseAlbumModal()}
              >
                Cancel
              </TypographyButton>
              {isEditingAlbum ?
                (
                  <TypographyButton
                    component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                    onClick={() => onSaveChanges()}
                    disabled={selectedImages.length < 3}
                  >
                    Save Changes
                  </TypographyButton>
                ) :
                (
                  <TypographyButton
                    disabled={isCreateAlbumDisabled}
                    component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                    onClick={() => onCreateAlbum()}
                  >
                    Create Collection
                  </TypographyButton>
                )}
            </div>
          </div>
        </div>
      </PikchaModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  addToAlbum: state.addToAlbumImageReducer,
  albums: state.albumsReducer,
  selectedImages: selectedImages(state),
});

export default connect(mapStateToProps, {
  getAddToAlbumPhotos,
  resetAddToAlbumPhotos,
  onImageSelectedForCreateAlbum,
  onImageDeselectedForCreateAlbum,
  resetSelectedImages,
  createCollection,
  updateCollection,
  deleteCollection,
  onSelectedImages,
  setAlert,
  removeModal,
})(CreateCollection);
