import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import { useTheme } from "@material-ui/core/styles";
import ScreenRotationIcon from "@material-ui/icons/ScreenRotation";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';

import CropSquareIcon from "@material-ui/icons/CropSquare";
import CropPortraitIcon from "@material-ui/icons/CropPortrait";
import CropLandscapeIcon from "@material-ui/icons/CropLandscape";
import CheckIcon from "@material-ui/icons/Check";

import {
  ICON_SIZE_DEFAULT,
  ICON_SIZE_SMALL,
} from "../../helpers/constants";

const Orientation = ({ onClick, selectedOrientation, orientation }) => {
  const [isHovering, setIsHovering] = useState(false);

  const { palette } = useTheme();

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      style={{
        ...{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          padding: "0.5em",
        },
        ...(isHovering
          ? {
              backgroundColor: palette.grey.lighter,
            }
          : {}),
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      onClick={() => {
        setIsHovering(false);
        onClick(orientation);
      }}
    >
      {orientation === "square" && (
        <>
          <CropSquareIcon
            style={{
              ...ICON_SIZE_DEFAULT,
            }}
          />
          <div
            style={{
              marginLeft: "1em",
            }}
          >
            Square
          </div>
        </>
      )}
      {orientation === "portrait" && (
        <>
          <CropPortraitIcon
            style={{
              ...ICON_SIZE_DEFAULT,
            }}
          />
          <div
            style={{
              marginLeft: "1em",
            }}
          >
            Portrait
          </div>
        </>
      )}
      {orientation === "landscape" && (
        <>
          <CropLandscapeIcon
            style={{
              ...ICON_SIZE_DEFAULT,
            }}
          />
          <div
            style={{
              marginLeft: "1em",
            }}
          >
            Landscape
          </div>
        </>
      )}
      {selectedOrientation === orientation && (
        <CheckIcon
          style={{
            ...ICON_SIZE_DEFAULT,
            marginLeft: "auto",
          }}
        />
      )}
    </div>
  );
};

export default ({ style, handleOrientationChange, imageOrientation }) => {
  const { palette, typography } = useTheme();

  const [open, setOpen] = useState(false);
  const [selectedOrientation, setSelectedOrientation] = useState(
    imageOrientation
  );

  const [isHovering, setIsHovering] = useState(false);

  const dropdown = useRef(null);
  const control = useRef(null);
  useEffect(() => {
    setSelectedOrientation(imageOrientation);
  }, [imageOrientation]);
  useEffect(() => {
    const handleClick = (e) => {
      if (
        dropdown &&
        dropdown.current &&
        !dropdown.current.contains(e.target) &&
        !control.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => document.removeEventListener("mousedown", handleClick);
  });

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  const onToggleDropdown = () => {
    setOpen(!open);
  };

  const onSelectOrientation = (orientationToSelect) => {
    let newOrientation;
    if (selectedOrientation !== orientationToSelect) {
      newOrientation = orientationToSelect;
    } else {
      newOrientation = null;
    }
    setSelectedOrientation(newOrientation);
    handleOrientationChange(newOrientation);

    setOpen(false);
  };

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          position: "relative",
          backgroundColor: palette.common.white,
          padding: "0.25em",
          ...style,
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
        onClick={onToggleDropdown}
        ref={control}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightMedium,

            ...(isHovering
              ? {
                  color: palette.text.secondary,
                }
              : {
                  color: palette.text.disabled,
                }),
          }}
        >
          <ScreenRotationIcon
            style={{
              ...ICON_SIZE_SMALL,
              marginLeft: "0.25em",
            }}
          />
          <div
            style={{
              marginLeft: "0.5em",
            }}
          >
            {!selectedOrientation && <span>Orientation</span>}

            {selectedOrientation === "square" && (
              <CropSquareIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}

            {selectedOrientation === "portrait" && (
              <CropPortraitIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}

            {selectedOrientation === "landscape" && (
              <CropLandscapeIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}
          </div>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {!open && (
              <ArrowDropDownRoundedIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}
            {open && (
              <ArrowDropUpRoundedIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}
          </div>
        </div>
        {open && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              width: "100%",
              marginLeft: "-0.25em",
              marginTop: "0.25em",
              borderRadius: 6,
              // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              boxShadow:
                "4px 0px 4px rgba(0, 0, 0, 0.25), -2px 3px 4px 2px rgba(0, 0, 0, 0.25)",
              backgroundColor: palette.common.white,
            }}
            ref={dropdown}
          >
            <Orientation
              onClick={onSelectOrientation}
              orientation="square"
              selectedOrientation={selectedOrientation}
            />
            <Orientation
              onClick={onSelectOrientation}
              orientation="portrait"
              selectedOrientation={selectedOrientation}
            />
            <Orientation
              onClick={onSelectOrientation}
              orientation="landscape"
              selectedOrientation={selectedOrientation}
            />
          </div>
        )}
      </div>
    </>
  );
};
