export const ASPECT_RATIOS = [
  {
    id: 2,
    label: "3:2",
    cropperVal: 3 / 2,
    orientation: "landscape",
    value: "3,2",
  },
  {
    label: "4:3",
    id: 6,
    cropperVal: 4 / 3,
    orientation: "landscape",
    value: "4,3",
  },
  {
    id: 1,
    label: "5:4",
    cropperVal: 5 / 4,
    orientation: "landscape",
    value: "5,4",
  },
  {
    label: "2:3",
    id: 5,
    cropperVal: 2 / 3,
    orientation: "portrait",
    value: "2,3",
  },
  {
    id: 3,
    label: "3:4",
    cropperVal: 3 / 4,
    orientation: "portrait",
    value: "3,4",
  },
  {
    id: 4,
    label: "4:5",
    cropperVal: 4 / 5,
    orientation: "portrait",
    value: "4,5",
  },
  {
    label: "1:1",
    id: 7,
    cropperVal: 1,
    orientation: "square",
    value: "1,1",
  },
  {
    id: 8,
    label: "1:2",
    cropperVal: 1 / 2,
    orientation: "portrait",
    value: "1,2",
  },
  {
    id: 9,
    label: "1:3",
    cropperVal: 1 / 3,
    orientation: "portrait",
    value: "1,3",
  },
  {
    id: 10,
    label: "2:1",
    cropperVal: 2 / 1,
    orientation: "landscape",
    value: "2,1",
  },
  {
    id: 11,
    label: "3:1",
    cropperVal: 3 / 1,
    orientation: "landscape",
    value: "3,1",
  },
];
