import React, { useState, useEffect } from "react";
import {useTheme} from "@material-ui/core";

import {PikchaButton} from "../../../components/baseComponents";
import isMobilePortrait from "../../../components/Responsive/isMobilePortrait";

export const ProductItemPurchaseButton = ({
                                            isMobile,
                                            mobileComponent,
                                            image,
                                            isArtistDeleted,
                                            onClick,
                                          }) => {
  const { palette } = useTheme();
  const isOnMobilePortrait = isMobilePortrait();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const now = new Date();
    var countDownDate = new Date(image.publishAtLocal).getTime();
    var distance = countDownDate - now;

    if(distance > 0){
      setDisabled(true);
    }

  }, [image])
  

  return (
    <div
      style={{
        marginTop: !isMobile ? undefined : "1em",
        marginBottom: !isMobile ? undefined : "1em",
      }}
    >
      {image.totSold >= parseInt(image.totalEditions) && (
        <PikchaButton fullWidth disabled>
          Purchase Artwork
        </PikchaButton>
      )}
      {image.totSold >= parseInt(image.totalEditions) && isMobile && (
        <div style={{marginTop: "0.5em"}}>
          <div
            className={`mb-${!isOnMobilePortrait ? "1" : "half"}-em`}
            style={{display: "flex"}}
          >
            <MetaText>
              {isMobile}{image.totalEditions}/{image.totalEditions} Sold Out
            </MetaText>
            <div
              style={{
                marginLeft: "0.5em",
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: palette.error.main,
              }}
            />
          </div>
          {mobileComponent}
        </div>
      )}
      {image.totSold < parseInt(image.totalEditions) && (
        <PikchaButton
          color="black"
          disabled={isArtistDeleted || disabled}
          onClick={onClick}
          fullWidth
        >
          Purchase Artwork
        </PikchaButton>
      )}
    </div>
  );
};

const MetaText = ({children}) => {
  const {palette, typography} = useTheme();

  return (
    <p
      style={{
        color: palette.text.secondary,
        fontSize: typography.tiny,
        fontWeight: typography.fontWeightMedium,
      }}
    >
      {children}
    </p>
  );
};
