import {PREFERRED_CURRENCY_CHANGE, PREFERRED_UNIT_CHANGE, SET_FX_RATE} from "../actions/types";

const initialState = {
  currencyOptions: {label: "USD", value: "USD", symbol: '$', codeSymbol: 'US$', locale: 'en-US'},
  fxRate: "1",
  preferredUnit: {label: "in", value: "in", ariaLabel: 'in'}
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case PREFERRED_CURRENCY_CHANGE:
      return {
        ...state,
        currencyOptions: payload
      };
    case PREFERRED_UNIT_CHANGE:
      return {
        ...state,
        preferredUnit: payload
      };
    case SET_FX_RATE:
      return {
        ...state,
        fxRate: payload
      };
    default:
      return state;
  }
};
