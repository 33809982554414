import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ size = 1 }) => {
  const { spacing } = useTheme();

  return (
    <div
      className="block full-width"
      style={{
        height: spacing(size),
      }}
    />
  );
};
