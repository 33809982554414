import { ONBOARDING_FLOW_INIT } from "../actions/types";

const initialState = {
  isOnboarding: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ONBOARDING_FLOW_INIT:
      return {
        ...state,
        isOnboarding: payload,
      };
    default:
      return state;
  }
};
