import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./Timer.css";

import { updateNotificationItem } from "../../actions/notification";

const Timer = ({
  start,
  stop,
  overrideTimerClass = "",
  reset,
  mins,
  secs,
  status,
  notification,
  updateNotificationItem,
  id,
  timers,
  queued,
  style,
  zero
}) => {

  const [timer, setTimer] = useState({
    min: mins ? mins : 0,
    seconds: secs ? secs : 0,
  });
  const [activeTimerClass, setActiveTimerClass] = useState(overrideTimerClass);
  const [timerClasses, setTimerClasses] = useState("timerHolder " + status);
  useEffect(() => {
    startTimers();
  }, [start, timers, timers.timers]);

  useEffect(() => {
    if (stop) {
      stopTimer();
    }
  }, [stop]);
  useEffect(() => {
    switch (activeTimerClass) {
      case "danger": {
        setTimerClasses("timerDanger timerHolder");
        break;
      }
      case "warning": {
        setTimerClasses("timerWarning timerHolder");
        break;
      }
      case "success": {
        setTimerClasses("timerActive timerHolder");
        break;
      }
      default: {
        setTimerClasses("timerActive timerHolder");
        break;
      }
    }
  }, [activeTimerClass]);

  useEffect(() => {
    if (reset) {
      resetTimer();
    }
  }, [reset]);

  const startTimers = () => {
    let index = timers.timers.findIndex((x) => x.id === id);
    // let index = timers.timers.length > 0 ? 0 : -1;

    if(zero){
      setTimer({ min: mins ? mins : 0, seconds: secs ? secs : 0 });
      setActiveTimerClass("danger");
    }
    else if (index > -1) {
      setTimer({
        min: timers.timers[index].time.minutes,
        seconds: timers.timers[index].time.seconds,
      });
      if (
        timers.timers[index].time.minutes <= 3 &&
        timers.timers[index].time.minutes >= 2
      ) {
        if (timers.timers[index].time.seconds <= 30) {
          setActiveTimerClass("warning");
        }
      }
      if (timers.timers[index].time.minutes <= 1) {
        if (timers.timers[index].time.seconds <= 30) {
          setActiveTimerClass("danger");
        }
      }
      if (
        (timers.timers[index].time.minutes === 3 &&
          timers.timers[index].time.seconds > 30) ||
        timers.timers[index].time.minutes > 3
      ) {
        setActiveTimerClass("active");
      }
    }
  };
  const stopTimer = () => {
    let item = notification.item;
    item.currentTime = timer;
    updateNotificationItem(item);
  };
  const resetTimer = () => {
    setTimer({ min: mins ? mins : 11, seconds: secs ? secs : 59 });
  };
  return (
    <div
      className={queued ? "timerQueued" : timerClasses}
      style={{
        marginTop: "3%",
        ...(style || {}),
      }}
    >
      <span className="pl-1">
        {timer.min < 10 ? 0 : null}
        {timer.min}
      </span>{" "}
      :{" "}
      <span className="pr-1">
        {timer.seconds < 10 ? 0 : null}
        {timer.seconds}
      </span>
    </div>
  );
};
const mapStateToProps = (state) => ({
  notification: state.notificationclose,
  timers: state.timerReducer,
});

export default connect(mapStateToProps, {
  updateNotificationItem,
})(Timer);
