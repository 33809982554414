import {withStyles} from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import React from "react";

export const PikchaReusableTabsGroup = withStyles(({palette, shape}) => ({
  root: {
    minHeight: 30,
    // transition: "border-color 0.15s ease-in-out",
    // border: "1px solid rgba(0, 0, 0, .2)",
    "& > button:first-child": {
      borderRadius: `4px 0 0 4px`,
    },
    "& > button:last-child": {
      borderRadius: `0 4px 4px 0`,
    },
    // "&:hover": {
    //   transition: "border-color 0.15s ease-in-out",
    //   border: "1px solid rgba(0, 0, 0, .2)",
    // },
  },
  flexContainer: {
    // height: 40,
    display: "flex",
    justifyContent: "space-between"
  }
}))(({children, ...rest}) => (
  <Tabs
    {...rest}
    variant="scrollable"
  >
    {children}
  </Tabs>
));
