import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import UserDetails from '../../components/UserDetails/UserDetails';

import {
  getRelatedImages,
  handleRelatedImagesLoadingAspectRatio,
  handleRelatedImagesPhotoLoading,
  handleRelatedImagesPortrait,
  resetRelatedImages,
} from '../../actions/relatedImages';
import MasonryGallery from '../gallery/MasonryGallery/MasonryGallery';
import { getNearestAspectRatio } from '../../helpers/imageManipulation/imageManipulationHelpers';

const RelatedImages = ({
  image,
  style = {},
  relatedImages,
  getRelatedImages,
  scrollToTop,
  resetRelatedImages,
  handleRelatedImagesPortrait,
  handleRelatedImagesPhotoLoading,
  handleRelatedImagesLoadingAspectRatio,
  onClose,
  ...rest
} = {}) => {
  React.useLayoutEffect(() => {
    // Mock the loading state
    const timer = setTimeout(() => {
      handleRelatedImagesPhotoLoading(false);
    }, 1250);

    return () => clearTimeout(timer);
  }, [handleRelatedImagesPhotoLoading, relatedImages.photoLoading]);

  const { palette, typography } = useTheme();

  const relatedImagesRef = useRef(null);

  let artistId;
  let artistNickName;
  let artistAvatar;
  let artistUserHandle;

  if (image && image.artist) {
    artistId = image.artist.id;
    artistNickName = image.artist.nickName;
    artistAvatar = image.artist.avatar;
    artistUserHandle = image.artist.userHandle;
  }
  let photoId;
  if (image) {
    photoId = image.id;
  }

  const getPhotos = (count, start) => {
    getRelatedImages(artistId, count, start);
  };

  const resetGallery = () => {
    resetRelatedImages();
  };

  const imageItemClicked = ({ currentTarget }) => {
    if (!currentTarget) {
      return;
    }

    const aspectRatio = getNearestAspectRatio(currentTarget.clientWidth, currentTarget.clientHeight);

    handleRelatedImagesLoadingAspectRatio(aspectRatio);
    handleRelatedImagesPhotoLoading(true);

    handleRelatedImagesPortrait(currentTarget.clientHeight > currentTarget.clientWidth);

    if (scrollToTop) {
      scrollToTop();
    }
  };

  if (!artistId) {
    return null;
  }

  return (
    <div
      style={{
        display: 'grid',
        marginTop: '4em',
        marginBottom: '4em',
        ...style,
      }}
    >
      <div
        style={{
          fontWeight: typography.fontWeightMedium,
          fontSize: typography.standard,
          color: palette.text.secondary,
          display: 'flex',
          marginBottom: '-3em',
        }}
      >
        <div
          style={{
            justifyContent: 'flex-start',
            paddingRight: '0.5em',
            marginTop: 2,
            fontWeight: typography.fontWeightSemiMedium,
            fontSize: typography.smallest,
            color: palette.text.secondary,
          }}
        >
          More from
        </div>
        <div
          style={{
            justifyContent: 'flex-start',
            paddingLeft: '0.5em',
          }}
        >
          <UserDetails
            id={artistId}
            userHandle={artistUserHandle}
            nickName={artistNickName}
            avatar={artistAvatar}
            {...rest}
          />
        </div>
      </div>

      <MasonryGallery
        noItemHeaders
        ref={relatedImagesRef}
        fromRelatedImages
        gallery={relatedImages}
        getPhotos={getPhotos}
        onClickGalleryImage={imageItemClicked}
        resetGallery={resetGallery}
        restrict={[photoId]}
        noContainerMargins
        noBackground

        onClose={onClose}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  relatedImages: state.relatedImagesReducer,
});

export default connect(mapStateToProps, {
  getRelatedImages,
  handleRelatedImagesPortrait,
  handleRelatedImagesPhotoLoading,
  handleRelatedImagesLoadingAspectRatio,
  resetRelatedImages,
})(RelatedImages);
