import React from "react";

import {
  PikchaFormControlLabel,
  PikchaFormSection,
  PikchaFormSectionLabel,
  PikchaRadioGroup,
} from "../baseComponents";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";
import { useStyles } from "./radioStyles";

export default ({
  disabled,
  _isAudGst,
  fields,
  handleFields,
  handleSelectedTaxCountry,
  handleIsGstOutsideAu,
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    if (e.target.value === "true") {
      handleFields({
        ...fields,
        _isAudGst: true,
      });
      handleIsGstOutsideAu(true);
    } else {
      handleFields({
        ...fields,
        _isAudGst: false,
        taxCountry: "",
        vatNumber: "",
      });
      handleSelectedTaxCountry("");
      handleIsGstOutsideAu(false);
    }
  };

  if (disabled) return null;

  return (
    <PikchaFormSection topMarginSize={3}>
      <PikchaFormSectionLabel>
        Are you registered for GST?
      </PikchaFormSectionLabel>

      <PikchaRadioGroup
        style={{
          paddingTop: "0.15em",
          paddingLeft: "0.5em",
          paddingBottom: "0.5em",
        }}
        aria-label="GST Tax Options"
        value={_isAudGst}
        onChange={handleChange}
        row
      >
        <PikchaFormControlLabel
          control={
            <Radio
              className={classes.root}
              value={true}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
            />
          }
          label="Yes"
        />
        <PikchaFormControlLabel
          control={
            <Radio
              value={false}
              className={classes.root}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
            />
          }
          label="No"
        />
      </PikchaRadioGroup>
    </PikchaFormSection>
  );
};
