import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AccountNavigation from "../containers/account/AccountNavigation/Navigation";
import MyDetails from "../containers/account/MyDetails/index";
import MyOrders from "../containers/account/MyOrders/MyOrders";
import Settings from "../containers/account/Settings/index";
import NotFoundPage from "../pages/404Page";
import MySales from "../containers/account/MySales/MySales";

export default ({ match }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "space-between",
    }}
  >
    <Route strict path="/account" component={AccountNavigation} />
    <Switch style={{ width: "100%" }}>
      <Route
        exact
        path="/account"
        component={() => <Redirect to="/account/details" />}
      />

      <Route path={`${match.path}/details`} component={MyDetails} />
      <Route exact path={`${match.path}/orderHistory`} component={MyOrders} />
      <Route exact path={`${match.path}/sales`} component={MySales} />
      <Route
        exact
        path={`${match.path}/sales/sales-history`}
        component={() => <MySales salesHistoryRoute={true} />}
      />
      <Route
        exact
        path={`${match.path}/sales/payment-history`}
        component={() => <MySales paymentHistoryRoute={true} />}
      />
      <Route exact path={`${match.path}/settings`} component={Settings} />
      <Route
        component={() => <Redirect to="/notFound" component={NotFoundPage} />}
      />
    </Switch>
  </div>
);
