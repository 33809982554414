import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { updateUserDetails } from "../../../actions/account";
import isMobile from "../../../components/Responsive/isMobile";
import './ShippingInfo.css';
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  colors,
  TypographyTextInput,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographyDropdown
} from "@pikcha/typography";
import { Accordion, AccordionSummary } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    marginTop: "2em",
    padding: 0,
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);

const StyledAutocomplete = withStyles(({ palette, typography }) => ({
  option: {
    fontFamily: typography.fontFamilyMontserrat,
    fontSize: typography.smallest,
    fontWeight: typography.fontWeightSemiMedium,
    color: palette.text.secondary,
  },
}))(Autocomplete);

const StyledTextField = withStyles(({ palette, typography }) => ({
  root: {
    fontFamily: typography.fontFamilyMontserrat,
    fontSize: typography.small,
    fontWeight: typography.fontWeightSemiMedium,
    color: palette.text.primary,
  },
}))(TextField);

const ShippingInformation = ({ account, updateUserDetails, countries, allShippingStates }) => {
  const isOnMobile = isMobile();

  const shouldOpenShippingInformationSection = () => {
    const location = window.location.href;
    if(location.endsWith("shipping-information")){
      return true;
    }
    else{
      return false;
    }
  }

  const setDefaultCountry = () => {
    const completeCountryInfo = countries.filter((item)=>{
      return item.country === account.user.shipCountry
    })
    return completeCountryInfo[0];
  }

  
  const setUSDefaultState = () => {
    const completeUSState = allShippingStates.filter((item)=>{
      return item.label === account.user.shipState
    })
    return completeUSState[0];
  }

  const [isEditing, setIsEditing] = useState(false);
  const [currentOpen, setCurrentOpen] = useState(shouldOpenShippingInformationSection());
  const [address1, setAddress1] = useState(account.user.shipAddr1);
  const [address2, setAddress2] = useState(account.user.shipAddr2);
  const [city, setCity] = useState(account.user.shipCity);
  const [postcode, setPostcode] = useState(account.user.shipPostal);
  const [stateTerritory, setStateTerritory] = useState(account.user.shipState);
  const [USState, setUSState] = useState(setUSDefaultState());
  const [country, setCountry] = useState(account.user.shipCountry);
  const [completeCountry, setCompleteCountry] = useState(setDefaultCountry());
  const [isCountryDirty, setIsCountryDirty] = useState(false);
  const [wrongStateInput, setwrongStateInput] = useState(false);

  useEffect(() => {
    const completeCountryInfo = countries.filter((item)=>{
      return item.country === account.user.shipCountry
    })
    setCompleteCountry(completeCountryInfo[0]);
  }, [countries, account])

  useEffect(() => {
    const completeUSState = allShippingStates.filter((item)=>{
      return item.label === account.user.shipState
    })
    setUSState(completeUSState[0]);
  }, [allShippingStates, account])

  useEffect(() => {
    const defaultStateValue = (allShippingStates || []).filter((item) => {
      return item.label.toLowerCase() === account.user.shipState.toLowerCase();
    })
    if(account.user.shipState && defaultStateValue.length === 0 && allShippingStates.length > 0){
      setwrongStateInput(true)
    }
  }, [allShippingStates, account])

  const { palette, typography } = useTheme();


  const classes = useStyles();

  const onAccordionExpansion = () => {
    if(currentOpen){
      setCurrentOpen("");      
    } else {
      setCurrentOpen("Shipping Information");      
    }
  };

  const onBeginEditing = () => {
    setIsEditing(true);
  };

  const onCancelEditing = () => {
    // reset everything back to their original values
    setAddress1(account.user.shipAddr1);
    setAddress2(account.user.shipAddr2);
    setCity(account.user.shipCity);
    setPostcode(account.user.shipPostal);
    setStateTerritory(account.user.shipState);
    setCountry(account.user.shipCountry);

    setCompleteCountry(setDefaultCountry());

    setIsEditing(false);
  };

  const onSaveEditing = () => {
    updateUserDetails({
      links: {
        FacebookUrl:
          account.user.links && account.user.links.FacebookUrl
            ? account.user.links.FacebookUrl
            : "",
        InstagramUrl:
          account.user.links && account.user.links.InstagramUrl
            ? account.user.links.InstagramUrl
            : "",
        TwitterUrl:
          account.user.links && account.user.links.TwitterUrl
            ? account.user.links.TwitterUrl
            : "",
      },
      bio: account.user.bio,
      fName: account.user.fName,
      lName: account.user.lName,
      email: account.user.email,
      phoneNumber: account.user.phoneNumber,
      dob: account.user.dob,
      userHandle: account.user.userHandle,
      preferredCurrency: account.user.preferredCurrency,
      nickName: account.user.nickName,
      nationality: account.user.nationality,
      shipAddr1: address1,
      shipAddr2: address2,
      shipCountry: country,
      shipState: stateTerritory,
      shipCity: city,
      shipPostal: postcode,
    });

    setIsEditing(false);
  };

  const onChange = ({ target }) => {
    let key = target.name;

    if (key === "address1") {
      setAddress1(target.value);
    }
    if (key === "address2") {
      setAddress2(target.value);
    }
    if (key === "city") {
      setCity(target.value);
    }
    if (key === "postcode") {
      setPostcode(target.value);
    }
    if (key === "stateTerritory") {
      setStateTerritory(target.value);
    }
  };

  const areFieldsDisabled = !isEditing;

  return (
    <Accordion
      className={classes.expansionContainer}
      expanded={currentOpen === "Shipping Information"}
      style={{ marginBottom: 16 }}
      onChange={onAccordionExpansion} 
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMore />}
      >
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
        >
          Shipping Information
        </TypographyBody>
      </StyledAccordionSummary>
      <ExpansionPanelDetails className={classes.expansionDetails}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <div className="shipping-countries">
                {(!account.user.shipCountry || completeCountry) && (
                  <TypographyDropdown
                    disabled={areFieldsDisabled}
                    options={countries}
                    value={completeCountry}
                    style={{
                      marginBottom: "1.75rem",
                      borderRadius: "6px"
                    }}
                    label="Shipping Country"
                    onChange={(newValue) => {
                      setCompleteCountry(newValue || {});
                      setCountry(newValue?.country);
                      setIsCountryDirty(true);
                      if(newValue?.country === "United States"){
                        const defaultStateValue = (allShippingStates || []).filter((item) => {
                          return item.label.toLowerCase() === stateTerritory.toLowerCase();
                        })
                        if(stateTerritory && defaultStateValue.length === 0 && allShippingStates.length > 0){
                          setStateTerritory("");
                        }
                      }
                    }}
                  />
                )}
              </div>
              {((account.user.shipCountry === "United States" && !account.user.shipState) || USState || isCountryDirty || wrongStateInput) && 
                (account.user.shipCountry === "United States" || country === "United States") && 
                !(isCountryDirty && country !== "United States") && (
                  <div className="shipping-state">
                    <TypographyDropdown
                      disabled={areFieldsDisabled}
                      options={allShippingStates}
                      value={USState}
                      label="Shipping State"
                      style={{
                        marginTop: "-1.75em",
                        marginBottom: "1.75rem",
                        borderRadius: "6px"
                      }}
                      onChange={(newValue) => {
                        setStateTerritory(newValue?.label);
                        setUSState(newValue || {})
                      }}
                    />
                </div>
              )}
              <TypographyTextInput
                disabled={areFieldsDisabled}
                label="Address 1"
                type="text"
                name="address1"
                value={address1}
                onChange={onChange}
              />
              <TypographyTextInput
                disabled={areFieldsDisabled}
                label="Address 2"
                type="text"
                name="address2"
                value={address2}
                onChange={onChange}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TypographyTextInput
                    disabled={areFieldsDisabled}
                    label="City / Suburb / Town"
                    type="text"
                    name="city"
                    value={city}
                    onChange={onChange}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "1em",
                    width: "100%",
                  }}
                >
                  <TypographyTextInput
                    disabled={areFieldsDisabled}
                    label="Postcode"
                    type="text"
                    name="postcode"
                    value={postcode}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <TypographyTextInput
                    disabled={ (account.user.shipCountry === "United States" || country === "United States") && !(isCountryDirty && country !== "United States") ? true :
                      areFieldsDisabled
                    }
                    label="State / Territory"
                    type="text"
                    name="stateTerritory"
                    value={stateTerritory}
                    onChange={onChange}
                  />
                </div>
                <div
                  style={{
                    marginLeft: "1em",
                    width: "100%",
                  }}
                >
                  <TypographyTextInput
                    disabled={true}
                    label="Country"
                    noHelperClass
                    classNameOuter="Dropdown-container"
                    style={{ marginTop: 0 }}
                    value={country}
                    placeholder={country || ""}
                  />
                </div>
              </div>
            </div>
            {!isOnMobile && (
              <div
                style={{
                  marginLeft: "2em",
                  width: "100%",
                }}
              />
            )}
          </div>
          <div>
            {!isEditing && (
              <div
                style={{
                  display: "flex",
                  justifyContent: 'flex-end'
                }}
              >
                <TypographyButton
                  onClick={onBeginEditing}
                  component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                >
                  Edit
                </TypographyButton>
              </div>
            )}
            {isEditing && (
              <div
                style={{
                  display: "flex",
                  justifyContent: 'flex-end'
                }}
              >
                <TypographyButton
                  onClick={onCancelEditing}
                  component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                >
                  Cancel
                </TypographyButton>
                <TypographyButton
                  onClick={onSaveEditing}
                  component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                  buttonStyle={{
                    marginLeft: "1em",
                  }}
                >
                  Save
                </TypographyButton>
              </div>
            )}
          </div>
        </div>
      </ExpansionPanelDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  updateUserDetails,
})(ShippingInformation);
