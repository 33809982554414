import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({
  children,
  className = "",
  smaller,
  larger,
  noMaxWidth,
  subParagraph,
  style = {},
  ...rest
}) => {
  const { typography, palette } = useTheme();

  return (
    <div
      className={`${className} ${subParagraph ? "pl-1-em" : ""}`}
      style={{
        paddingTop: "1.5em",
        color: palette.text.primary,
        // fontSize:
        //   !larger && !smaller
        //     ? typography.body.fontSize
        //     : larger
        //     ? typography.standardLarge
        //     : typography.smaller,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: typography.body.lineHeight,
        letterSpacing: typography.body.letterSpacing,
        maxWidth: !noMaxWidth ? typography.body.maxWidth : null,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
