import React from 'react';
import { Modal, useTheme } from '@material-ui/core';
import {PikchaLoader} from "../../../components/baseComponents";

export default function NextPaymentOverlay({message}) {
  const { palette, shape, typography } = useTheme();

  return (
    <Modal
      style={{
        zIndex: 9999999,
      }}
      open
      disabledBackdropClick
      BackdropProps={{ transitionDuration: 1000 }}
    >
      <div
        className="full-width full-height flex-center"
        style={{
          justifyContent: 'center',
        }}
      >
        <div
          className="flex-grow-1 flex-column"
          style={{
            fontWeight: typography.fontWeightBold,
            justifyContent: "space-evenly",
            alignItems: "center",
            background: palette.common.white,
            maxWidth: 600,
            height: 200,
            borderRadius: shape.borderRadius,
            margin:"1em",
          }}
        >
          <div className="flex">{message}</div>
          <div className="flex"><PikchaLoader/></div>
        </div>
      </div>
    </Modal>
  );

}
