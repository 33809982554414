import React from "react";
import { useTheme } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";

export default (props) => {
  const { palette } = useTheme();

  return (
    <ContentLoader
      viewBox="0 0 820 900"
      height="900"
      width="820"
      backgroundColor={palette.grey.lighter}
      foregroundColor={palette.grey.lighter}
      speed={3}
      {...props}
    >
      <circle cx="25" cy="20" r="12" />
      <rect x="41" y="8" width="228" height="24" />
      <rect x="10" y="40" width="260" height="140" />

      <circle cx="302" cy="20" r="12" />
      <rect x="320" y="8" width="229" height="24" />
      <rect x="290" y="40" width="260" height="280" />

      <circle cx="583" cy="20" r="12" />
      <rect x="600" y="8" width="219" height="24" />
      <rect x="570" y="40" width="260" height="140" />

      <circle cx="25" cy="212" r="12" />
      <rect x="41" y="200" width="228" height="24" />
      <rect x="10" y="235" width="260" height="280" />

      <circle cx="302" cy="350" r="12" />
      <rect x="320" y="338" width="229" height="24" />
      <rect x="290" y="370" width="260" height="240" />

      <circle cx="583" cy="210" r="12" />
      <rect x="600" y="198" width="219" height="24" />
      <rect x="570" y="230" width="260" height="280" />

      <circle cx="25" cy="542" r="12" />
      <rect x="41" y="530" width="228" height="24" />
      <rect x="10" y="565" width="260" height="280" />

      <circle cx="302" cy="640" r="12" />
      <rect x="320" y="628" width="229" height="24" />
      <rect x="290" y="660" width="260" height="240" />

      <circle cx="583" cy="540" r="12" />
      <rect x="600" y="528" width="219" height="24" />
      <rect x="570" y="560" width="260" height="280" />
    </ContentLoader>
  );
};
