import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";

import AuthorizeService from "../../../auth/AuthorizeService";

import { setAlert } from "../../../actions/alert";

import { IdentityUrl } from "../../../selectors/settings";

import {
  PikchaModal,
  PikchaButton,
  PikchaLoader,
  PikchaTextField,
} from "../../../components/baseComponents";

import { isPasswordValid } from "../../../helpers/utils";

const ChangePasswordModal = ({
  open,
  onClose,
  IdentityUrl,
  account,
  setAlert,
}) => {
  const { typography, palette } = useTheme();

  const history = useHistory();

  const [isChanging, setIsChanging] = useState();

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const onCancel = () => {
    onClose();
  };

  const handleCurrentPasswordChange = ({ target: { value } }) => {
    setCurrentPassword(value);
  };
  const handleNewPasswordChange = ({ target: { value } }) => {
    setNewPassword(value);
  };
  const handleConfirmPasswordChange = ({ target: { value } }) => {
    setConfirmPassword(value);
  };

  const onChangePassword = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setAlert("Please fill in all the fields", "error");
      return;
    }
    if (!isPasswordValid(newPassword)) {
      setAlert("New password doesn't meet requirements", "error");
      return;
    }
    if (newPassword !== confirmPassword) {
      setAlert("Please confirm your new password correctly", "error");
      return;
    }

    const doChange = async () => {
      const userId = account.user.id;
      const token = await AuthorizeService.getAccessToken();
      axios
        .post(
          `${IdentityUrl}/Account/ChangePassword`,
          {
            UserId: userId,
            CurrentPassword: currentPassword,
            NewPassword: newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          onClose();
          setAlert("Password changed", "success");
        })
        .catch((error) => {
          let errorMessage = "Couldn't change password";
          if (error.response) {
            if (error.response.status === 422) {
              if (error.response.data.length > 0) {
                if (error.response.data[0].code === "PasswordMismatch") {
                  errorMessage = "Incorrect password";
                }
              }
            }
          }
          setIsChanging(false);
          setAlert(errorMessage, "error");
        });
    };

    setIsChanging(true);
    doChange();
  };

  const onWantsToClose = () => {
    if (isChanging) {
      return;
    }
    onClose();
  };

  return (
    <PikchaModal
      fullWidth={false}
      onClose={onWantsToClose}
      hideCloseButton={isChanging}
      maxWidth="lg"
      open={open}
      PaperProps={{
        style: {
          overflow: "hidden",
        },
      }}
      minHeight={1}
    >
      <div
        style={{
          padding: "3em 5em",
        }}
      >
        {!isChanging && (
          <>
            <div>
              <p
                style={{
                  fontSize: typography.xLarge,
                  fontWeight: typography.fontWeightSemiMedium,
                  color: palette.text.primary,
                }}
              >
                Change Password
              </p>
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <PikchaTextField
                autoComplete="current-password"
                value={currentPassword}
                onChange={handleCurrentPasswordChange}
                label="Confirm Current Password"
                type="password"
              />

              <PikchaTextField
                style={{
                  marginTop: "2em",
                }}
                autoComplete="new-password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                label="New Password"
                subLabel="8+ Characters, 1 num"
                type="password"
              />
              <PikchaTextField
                autoComplete="confirm-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                label="Confirm New Password"
                type="password"
              />
              <div
                style={{
                  display: "flex",
                  marginTop: "1em",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <PikchaButton onClick={onChangePassword} action="primary">
                    Update
                  </PikchaButton>
                </div>
              </div>
            </div>
          </>
        )}
        {isChanging && (
          <div
            style={{
              padding: "5em 7em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PikchaLoader />
            <p
              style={{
                marginTop: "1em",
                color: palette.text.primary,
                fontSize: typography.smallest,
              }}
            >
              Changing Password
            </p>
          </div>
        )}
      </div>
    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  IdentityUrl: IdentityUrl(state),
});

export default connect(mapStateToProps, {
  setAlert,
})(ChangePasswordModal);
