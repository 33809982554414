import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import AuthorizeService from "../auth/AuthorizeService";
import {
  PikchaTitle,
  PikchaLoader,
  PikchaSpacer,
} from "../components/baseComponents";

const VerifyPayPal = ({ account, settings: { settings } }) => {
  const [generalError, setGeneralError] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);

  const [duplicatePayPalError, setDuplicatePayPalError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (settings.payoutUrl && account.user && !awaitingResponse) {
      // Set boolean flag to prevent API call from being executed more than once
      setAwaitingResponse(true);

      const getToken = async () => await AuthorizeService.getAccessToken();

      const { fName, lName } = account.user;
      const location = window.location.search;

      const code = location.split("code=")[1].split("&")[0];

      getToken()
        .then((token) => {
          axios
            .post(
              `api/payment/paypal/userInfo/`,
              {
                code: code,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              if (!res) {
                return setGeneralError(true);
              }

              const { data } = res;

              // The purpose of this guard block is to stop the flow if a
              // user already has a paypal account registered with a Pikcha
              // account.
              if (
                data &&
                data.error_message &&
                data.error_message ===
                  "account already created with the same paypal email address"
              ) {
                return setDuplicatePayPalError(true);
              }

              if (res.status === 200) {
                // TODO: pretty dodgy way of handling the redirect, but
                // appears necessary to avoid a janky loading state on
                // the profile page. Find a better way to do this if time
                // permits.
                setTimeout(() => history.push(`/account/details`), 4000);
              }
            })
            .catch((err) => {
              //console.log("error 4");

              console.log("verifypaypal, error 4", err);
              setGeneralError(true);
            });
        })
        .catch((err) => {
          //console.log("");

          setGeneralError(true);
          console.log("verifypaypal, error 5", err);
        });
    }
  }, [account, settings]);

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="pl-2-em pr-2-em margin-auto"
        style={{
          maxWidth: "45em",
        }}
      >
        {duplicatePayPalError && (
          <>
            <PikchaTitle className="justify-center pb-1-em" medium smaller>
              Payment Setup Failed
            </PikchaTitle>
            <div>
              An artist account is already using this Paypal account to receive
              payouts. Creating multiple artist accounts violates the rules set
              out in our User Agreement.
            </div>
            <div>For more information please contact our support team.</div>
          </>
        )}
        {!duplicatePayPalError && (
          <>
            {!generalError ? (
              <>
                <PikchaTitle className="justify-center pb-1-em" medium smaller>
                  Verifying PayPal
                </PikchaTitle>
                <PikchaSpacer />
                <PikchaLoader />
              </>
            ) : (
              <>
                <PikchaTitle className="justify-center pb-1-em" medium smaller>
                  Payment Setup Failed
                </PikchaTitle>
                <div>For more information please contact our support team.</div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  settings: state.settingsReducer,
});

export default connect(mapStateToProps, {})(VerifyPayPal);
