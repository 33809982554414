import React, { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  hideNotification,
  showNotification,
  updateNotificationItem,
  updateNotificationType,
} from "../../../../actions/notification";
import {
  addProductToShoppingCart,
  notifyQueueStatus,
  getShoppingCart,
} from "../../../../actions/shoppingCart";
import { expiredShoppingCartItems } from "../../../../selectors/shoppingCart";
import { activeTimer } from "../../../../selectors/timer";
import { useHistory } from "react-router-dom";
import CartItemNotificationModal from "./cartItemNotificationModal";

const CartItemNotification = ({
  image,
  notification,
  shoppingCart,
  account,
  expiredShoppingCartItems,
  onClose,
  addProductToShoppingCart,
  hideNotification,
  showNotification,
  updateNotificationItem,
  updateNotificationType,
  handleTimerClose,
  open
}) => {
  const [isAddToCartLoading, setIsAddToCartLoading] = useState(false);
  const location = useLocation();
  const history = useHistory()

  const isAnExpiryNotification = notification.item.isExpiredItem;
  if (notification.item.isExpiredItem) {
    image = {};
  }

  const addBackToCart = () => {
    let id;
    if (shoppingCart.shoppingCart && shoppingCart.shoppingCart.id) {
      id = shoppingCart.shoppingCart.id;
    } else {
      id = account.user.id;
    }
    setIsAddToCartLoading(true);
    expiredShoppingCartItems.forEach((item) => {
      addProductToShoppingCart(id, {
        previousShoppingItemId: item.id,
        ImageId: item.imageId,
        CustomizationIdentifier: item.customizationIdentifier,
        Wrap: item.wrap,
        ImageRender: item.imageRender,
        SelectedProductNumber: item.productNumber,
        BorderSize: item.border,
        FrameColor: item.frame
      })
        .then((resp) => {
          setIsAddToCartLoading(false);

          updateNotificationType("cartItemNotification");
          if (resp.items.length > 0) {
            updateNotificationItem({
              id: resp.items[resp.items.length - 1].id,
              currentTime: notification.currentTime,
              startTimer: false,
              image: resp.items[resp.items.length - 1],
            });
          }
          showNotification();
        })
        .catch(() => {
          setIsAddToCartLoading(false);
          hideNotification();
        });
    });
  };

  const viewQueue = () => {
    hideNotification();
    history.push("/checkout#shipping");
    updateNotificationType("timeReminder");
  };

  let isPurchaseEnable;

  if (!(location && location.pathname && location.pathname.includes('/print'))) {
    return null;
  }

  return (
    <>
      <CartItemNotificationModal
        open={open}
        image={image}
        isPurchaseEnable={isPurchaseEnable}
        onClose={onClose}
        isAnExpiryNotification={isAnExpiryNotification}
        isAddToCartLoading={isAddToCartLoading}
        addBackToCart={addBackToCart}
        viewQueue={viewQueue}
        shoppingItemId={notification?.item?.id}
        handleTimerClose={handleTimerClose}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  notification: state.notificationReducer,
  timers: state.timerReducer,
  account: state.accountReducer,
  shoppingCart: state.shoppingCartReducer,
  expiredShoppingCartItems: expiredShoppingCartItems(state),
  activeTimer: activeTimer(state),
});

export default connect(mapStateToProps, {
  addProductToShoppingCart,
  notifyQueueStatus,
  getShoppingCart,
  hideNotification,
  showNotification,
  updateNotificationItem,
  updateNotificationType,
})(CartItemNotification);
