import React, { useState } from "react";

import { useTheme } from "@material-ui/core/styles";
// import AddIcon from "@material-ui/icons/Add";
// import FilterIcon from "@material-ui/icons/FilterAlt";
import CloseIcon from "@material-ui/icons/Close";
import PhotoIcon from "@material-ui/icons/Photo";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { AlbumIcon } from '../../components/extraIcons/index';

import {
  ICON_SIZE_DEFAULT,
  ICON_SIZE_SMALL,
} from "../../helpers/constants";

import FilterIcon from "../../assets/images/FilterIcon.svg";

import MasonryGallerySearch
  from "../../containers/gallery/MasonryGallerySearch/MasonryGallerySearch";

import {
  PikchaButton,
  PikchaCheckbox,
  PikchaDropdown,
  PikchaFieldWrapper,
  PikchaReusableTabsGroup,
  PikchaReusableTab
} from "../../components/baseComponents";
import LocationPicker from "../../components/LocationPicker";
import ResponsiveFriendlyColors
  from "../../components/ImageColourPicker/ResponsiveFriendlyColors";
import OrientationPicker
  from "../../components/OrientationPicker/OrientationPicker";
import LocationIcon from "../../assets/images/location_on-black-24dp.svg";

import "./SearchMobile.css";

export default ({
  images,

  searchTerm,
  isSearchLoading,
  resultsType,
  resultsTypeChange,
  gallery,
  getPhotos,
  resetGallery,
  sortBy,
  onSortByChange,
  onClearFilters,
  onApplyFilters,
  imageTypes,
  imageType,
  onImageTypeChange,
  imageCategories,
  imageCategory,
  onImageCategoryChange,
  imageOrientation,
  handleOrientationChange,
  imageColour,
  coloursAvailable,
  handleColourChange,
  location,
  onLocationChange,

}) => {
  const [isAddingFilter, setIsAddingFilter] = useState();

  const { palette, typography } = useTheme();

  const { photos: galleryPhotos, loading } = gallery;
  const showBlankState =
    images.photos.length < 1 && !images.loading && !isSearchLoading;

  const addFilter = () => {
    setIsAddingFilter(true);
  };
  const onCancelFiltering = () => {
    setIsAddingFilter(false);
  };
  const applyFilters = () => {
    onApplyFilters();
    setIsAddingFilter(false);
  };

  return (
    <div
      style={{
        paddingTop: "4em",
        width: "100%",
      }}
    >
      <div
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2em",
          }}
        >
          <div
            style={{
              alignSelf: "start",
              color: palette.text.primary,
              fontSize: typography.large,
              fontWeight: typography.fontWeightBold,
              textTransform: "capitalize"
            }}
          >
            {searchTerm}
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "2em",
              marginLeft: "auto",
              marginRight: "auto",
              width: "100%"
            }}
          >
            <PikchaReusableTabsGroup
              value={resultsType}
              onChange={resultsTypeChange}
              aria-label="Results Type Selector"
              style={{
                width: "100%"
              }}
            >
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Pikcha"
                  label="Image"
                  aria-label="Pikcha"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Artist"
                  label="Artist"
                  aria-label="Artist"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                  }}
                  value="Album"
                  label="Album"
                  aria-label="Album"
                />
              </PikchaReusableTabsGroup>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "1em",
              alignItems: "center",
            }}
          >
            {!isAddingFilter && (
              <div
                style={{
                  width: "100%",
                  marginTop: "1.85em",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <PikchaButton
                  mainStyle={{
                    width: 125,
                    height: 36,
                  }}
                  action="octonary"
                  onClick={addFilter}
                  className="fontSize16"
                >
                  {/* <FilterIcon
                    style={{
                      marginRight: "0.5em",
                    }}
                  /> */}
                  <div
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: "1em",
                      backgroundImage: `url(${FilterIcon})`,
                      backgroundSize: "cover",
                    }}
                  />
                  Filter
                </PikchaButton>
              </div>
            )}
            {isAddingFilter && (
              <div
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  display: "flex",
                  marginTop: "1.85em",
                  justifyContent: "center"
                }}
              >
                <PikchaButton
                  mainStyle={{
                    width: 125,
                    height: 36,
                  }}
                  action="octonary"
                  onClick={applyFilters}
                  className="fontSize16"
                >
                  Apply
                </PikchaButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {isAddingFilter && (
        <div
          className="search-mobile-filters"
          id="search-filter"
          style={{
            backgroundColor: palette.action.disabled,
            zIndex: 10,
            padding: "0.5em",
            marginTop: "2em",
            marginBottom: "-2em",
          }}
        >
          <div
            className="search-mobile-filters-modal"
            style={{
              borderRadius: 6,
              backgroundColor: palette.common.white,
              paddingLeft: "1em",
              paddingRight: "1em",
              paddingBottom: "2em",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1em",
                  marginBottom: "1em",
                }}
              >
                <div
                  onClick={onClearFilters}
                  style={{
                    display: "flex",
                    marginRight: "auto",
                    maxHeight: 16,
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="buttonHoverUnderline"
                    style={{
                      fontWeight: 500,
                      fontSize: "0.75rem",
                      color: typography.secondary,
                    }}
                  >
                    Clear
                  </span>
                  <div
                    style={{
                      width: 16,
                      height: 16,
                      backgroundImage: `url(${FilterIcon})`,
                      backgroundSize: "cover",
                    }}
                  />
                </div>


                <CloseIcon
                  style={{
                    ...ICON_SIZE_DEFAULT,
                    color: palette.text.primary,
                  }}
                  onClick={onCancelFiltering}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
              <div
                style={{
                  width: 258,
                }}
              >
                <PikchaDropdown
                  label="Sort By"
                  onChange={onSortByChange}
                  options={[
                    { value: "RV", label: "Recommended" },
                    { value: "MV", label: "Most Viewed" },
                    { value: "MS", label: "Most Sales" },
                    { value: "PHL", label: "Price High to Low" },
                    { value: "PLH", label: "Price Low to High" },
                  ]}
                  value={sortBy}
                  style={{
                    width: "100%",
                    minWidth: 200,
                  }}
                />
              </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PikchaFieldWrapper
                  label="Orientation"
                  style={{
                    width: 258,
                  }}
                >
                  <OrientationPicker
                    onChange={handleOrientationChange}
                    value={imageOrientation}
                  />
                </PikchaFieldWrapper>
              </div>
              {coloursAvailable && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PikchaFieldWrapper
                    label="Colour"
                    style={{
                      width: 258,
                    }}
                  >
                    <ResponsiveFriendlyColors
                      value={imageColour}
                      onChange={handleColourChange}
                      colors={coloursAvailable.map((color) => color.hexValue)}
                    />
                  </PikchaFieldWrapper>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: "column",
                  alignItems: "center",
                  width: 258,
                  margin: "0 auto"
                }}
              >
              <div
                style={{
                  marginTop: "2em",
                  color: palette.grey.medium,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightMedium,
                  alignSelf: "flex-start"
                }}
              >
                Image type
              </div>
              <div
                style={{
                  marginTop: "0.5em",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {imageTypes.map((option, index) => {
                  const { name } = option;
                  const isChecked = imageType.some(
                    (checkedType) => checkedType === name
                  );
                  return (
                    <div
                      key={index}
                      style={{
                        marginRight: 0,
                        marginTop: "0.5em",
                        width: "47%"
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 11,
                          width: 152,
                        }}
                      >
                        <PikchaCheckbox
                          value={!!isChecked}
                          checked={!!isChecked}
                          onChange={() => {
                            onImageTypeChange(name);
                          }}
                          label={name}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  marginTop: "2em",
                  color: palette.grey.medium,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightMedium,
                  alignSelf: "flex-start"
                }}
              >
                Category
              </div>
              <div
                style={{
                  marginTop: "0.5em",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {imageCategories.map((option, index) => {
                  const { name } = option;
                  const isChecked = imageCategory.some(
                    (checkedType) => checkedType === name
                  );
                  return (
                    <div
                      key={index}
                      style={{
                        marginRight: 0,
                        marginTop: "0.5em",
                        width: "47%"
                      }}
                    >
                      <div
                        style={{
                          marginLeft: 11,
                          width: 152,
                        }}
                      >
                        <PikchaCheckbox
                          value={!!isChecked}
                          checked={!!isChecked}
                          onChange={() => {
                            onImageCategoryChange(name);
                          }}
                          label={name}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              </div>

              <div
                style={{
                  marginTop: "1em",
                }}
              >
                <div
                  style={{
                    width: 16,
                    height: 16,
                    backgroundImage: `url(${LocationIcon})`,
                    backgroundSize: "cover",
                    marginLeft: "3.4rem",
                  }}
                />
                <LocationPicker
                  label="Location"
                  type="text"
                  value={location}
                  onChange={({ target: { value } }) => onLocationChange(value)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showBlankState && (
        <div
          style={{
            marginTop: isAddingFilter ? "3em" : "1em",
            paddingLeft: "1em",
            paddingRight: "1em",
            textAlign: "start",
          }}
        >
          <p
            style={{
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightMedium,
              color: palette.text.primary,
            }}
          >
            We couldn’t find any results
          </p>
          <p
            style={{
              fontSize: typography.smallest,
              color: palette.text.secondary,
            }}
          >
            try searching for something else!
          </p>
        </div>
      )}
      {resultsType === "Pikcha" && (
        <MasonryGallerySearch
          images={images}
          isFollowing={false}
          gallery={gallery}
          getPhotos={getPhotos}
          resetGallery={resetGallery}
        />
      )}
      <div
        style={{
          paddingBottom: "4em",
        }}
      />
    </div>
  );
};
