import React from "react";
import { PikchaLoader } from "../../baseComponents";
import { useTheme } from "@material-ui/core";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";

export const Step4 = () => {
  const { palette, shape } = useTheme();
  return (
    <div
      className="flex-grow-1 flex-column"
      style={{
        justifyContent: "center",
        alignItems: "center",
        background: palette.common.white,
        maxWidth: 600,
        height: 200,
        borderRadius: shape.borderRadius,
        margin: "1em",
        padding: '1.5em',
        textAlign: 'center'
      }}
    >
      <div className="flex" style={{ height: 30 }}>
        <div style={{ transform: 'translateY(17px)' }}><PikchaLoader /></div>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
          style={{ marginLeft: '10px', marginBottom: '1em' }}
        >
          Completing your order
        </TypographyBody>
      </div>
      <TypographyBody>
        Hang tight, we’re completing your order now!
      </TypographyBody>
    </div>
  );
}
