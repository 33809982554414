import React, {useState} from "react";
import {connect} from "react-redux";
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import {Link} from "react-router-dom";
import Popover from '@material-ui/core/Popover';
import {useTheme} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP,
  ICON_SIZE_SMALL
} from "../../../helpers/constants";
import {List, ListItem, ListItemIcon} from "@material-ui/core";
import { accountNotificationMarkAllAsRead } from "../../../actions/accountNotifications";
import NotificationOptionsItem from "./NotificationOptionsItem";

const NotificationHistory = ({ accountNotificationMarkAllAsRead, onClose }) => {
//export default () => {
  const {palette, typography} = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [isHovering, setIsHovering] = useState(false);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  const markAllAsRead = () => {
    accountNotificationMarkAllAsRead();
  };

  return (
    <div
      id="notificationHeader"
      className="no-select pl-1-em"
      style={{
        width: DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP,
        height: 40,
        display: 'flex',
        position: 'absolute',
        alignItems: "center",
        justifyContent: 'space-between',
        top: 0,
        borderBottom:`1px solid ${palette.grey.light}`,
      }}>
      <div
        className="flex"
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
        style={{
          padding: "0.25em",
          textAlign: "center",
          fontSize: "0.875rem",
          fontWeight: typography.fontWeightBold,
          color: palette.text.secondary,
        }}
      >
        Notifications
      </div>
      <IconButton
        className="pr-1-em flex"
        onClick={handleClick}
        style={{
          padding: 2,
          borderRadius: 6,
          marginRight:4,
          height:32,
          width:32,
        }}
      >
        <MoreHorizRoundedIcon />
      </IconButton>

      <Popover
        PaperProps={{
          style: {
            width: DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP,
            maxWidth: DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP
          }
        }}
        style={{
          zIndex: 99999999,
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        container={document.getElementById("notificationHeader")}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >

        <List component="nav" aria-label="main mailbox folders">
          <NotificationOptionsItem
            onClick={markAllAsRead}
            icon={<CheckRoundedIcon />}
            text="Mark all as read"
          />
          <NotificationOptionsItem
            to="/account/settings"
            onClick={()=>{
              handleClose();
              onClose();
            }}
            icon={<SettingsRoundedIcon />}
            text="Notification settings"
          />
        </List>

      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  accountNotificationMarkAllAsRead,
})(NotificationHistory);
