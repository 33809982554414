import React, { useLayoutEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {setSignatureColor} from "../../components/SignatureCanvas/utils/getViewBoxCropData";
export default ({
                  style,
                  alignInnerPosition,
                  width,
                  height,
                  textSize,
                  signature,
                  signatureType,
                  signatureFont,
                  color,
                  sendSignatureCall,
                  signatureData,
                  signatureMaxWidth = 30,
                  signatureMaxHeight = 22.5
                }) => {

  const signaturePreviewWrapperRef = useRef(null);

  const { typography } = useTheme();

  useLayoutEffect(() => {
    if (signaturePreviewWrapperRef.current) {
      const signaturePreview = signaturePreviewWrapperRef.current.firstChild;

      if (signaturePreview && signaturePreview.style) {
        signaturePreview.style.maxWidth = signatureMaxWidth;
        signaturePreview.style.maxHeight = signatureMaxHeight;
        signaturePreview.style.height = "auto";
        signaturePreview.style.paddingBottom = "0.25em";

        const signaturePreviewStrokePath = signaturePreview.querySelectorAll("path");
        setSignatureColor(signaturePreviewStrokePath, color);
      }
    }
  }, [signatureData, color, signature, signatureMaxWidth, signatureMaxHeight]);

  return (
    <div style={style}>
      {signatureData ? (
        <div
          ref={signaturePreviewWrapperRef}
          dangerouslySetInnerHTML={{__html: signatureData}}
        />
      ) : signature ? (
        <div
          ref={signaturePreviewWrapperRef}
          dangerouslySetInnerHTML={{__html: signature}}
        />
      ) : null}
      <div
        style={{
          color: color,
          fontWeight: typography.fontWeightRegular,
          fontFamily: signatureFont,
          fontSize: textSize,
          float: alignInnerPosition ? alignInnerPosition : "right",
          userSelect: "none",
        }}
      >
        00/100
      </div>
    </div>
  );
};
