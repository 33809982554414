import React, { useEffect, useRef, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import {
  PikchaLoader
} from "../../components/baseComponents";
import CustomiseAuthModal
  from '../../containers/modals/CustomiseAuthModal/CustomiseAuthModal';
import isMobile from "../../components/Responsive/isMobile";
import isTablet from "../../components/Responsive/isTablet";
import isDesktop from "../../components/Responsive/isDesktop";
import { PIKCHA_CONFIG_CDN } from "../../helpers/constants";
import "../../assets/css/buttons.css";
import axios from "axios";
import { setAlert } from "../../actions/alert";
import { TypographySubtitle, TYPOGRAPHY_SUBTITLE_COMPONENT } from "@pikcha/typography";

const CustomiseSummary = ({
  summary,
  maxCanvasWidth,
  image,
  galleryCard,
  galleryCardImage,
  sideDrawerOpen,
  onCanvasLoading,
  onCanvasFinishedLoading,
  isPageLoading,
  settings: {settings},
  authModalOpen,
  authModalOnClose,
  authModalOnLogin,
  authModalOnJoin,
  authModalOnContinueAsGuest,
  modalImage,
  setClickAsGuest,
  setImageLoading,
  setAlert,
  setShotsGenerated,
  imageLoading
}) => {
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const isOnDesktop = isDesktop();
  const previewImageRef = useRef(null);
  const [wallViewState, setWallViewState] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { palette } = useTheme();
  const [galleryCardObj, setGalleryCardObj] = useState(undefined);
  const [imageRerender, setImageRerender] = useState(false);
  const [randomNumber, setRandomNumber] = useState("");
  const [createConfigShotsSent, setCreateConfigShotsSent] = useState(false);
  const [createConfigShotsPending, setCreateConfigShotsPending] = useState(false);

  let dimensionsWidth;
  let dimensionsHeight;
  if (isOnMobile) {
    dimensionsWidth = document.body.clientWidth;
    dimensionsHeight = dimensionsWidth;
  } else if (isOnTablet) {
    dimensionsWidth = document.body.clientWidth;
    dimensionsHeight = dimensionsWidth;
  } else {
    dimensionsWidth = document.body.clientWidth * 0.5;
    dimensionsHeight = dimensionsWidth;
  }
  if (dimensionsWidth > maxCanvasWidth) {
    dimensionsWidth = maxCanvasWidth;
    dimensionsHeight = dimensionsWidth;
  }
  const [dimensions, setDimensions] = useState({
    width: dimensionsWidth,
    height: dimensionsHeight,
  });
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("deviceorientation", handleResize, true);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("deviceorientation", handleResize);
    };
  }, []);
  useEffect(() => {
    if(galleryCardImage && galleryCardImage.black && galleryCardImage.white && summary && galleryCard){
      setGalleryCardObj({
        color: galleryCard,
        ...galleryCardImage
      })
    }
  }, [galleryCard, galleryCardImage]);

  const handleResize = () => {
    let windowWidth = document.body.clientWidth;
    let newWidth;
    let newHeight;
    if (isOnMobile) {
      newWidth = windowWidth;
      newHeight = newWidth;
    } else if (isOnTablet) {
      newWidth = windowWidth;
      newHeight = newWidth;
    } else  {
      newWidth = windowWidth * 0.5;
      newHeight = newWidth;
    }

    if (newWidth > maxCanvasWidth) {
      newWidth = maxCanvasWidth;
      newHeight = newWidth;
    }

    setDimensions({
      width: newWidth,
      height: newHeight,
    });

    initCanvas();
  };

  const initCanvas = () => {
    setIsImageLoading(false);
    setWallViewState(true);
    onCanvasFinishedLoading();
  };

  useEffect(() => {
    onCanvasLoading();
    setIsImageLoading(true);
    initCanvas();
  }, []);
  useEffect(() => {
    setTimeout(function () {
      handleResize();
    }, 250);
  }, [isImageLoading, sideDrawerOpen]);

  const getCanvasDimensions = () => {
    // let toExclude = sideDrawerOpen ? 60 : 20;
    // let res = {
    //   width: isOnMobile ? dimensions.width : dimensions.width - toExclude,
    //   height: dimensions.height
    // };

    return {
      width: dimensions.width,
      height: dimensions.height,
    };
  };
  /**
   * local= MockupTest/configtest/
   * Stag = Data/Test/
   * live = Data/Web/
   * 
   */

  console.log(image);

  const createConfigShots = () => {
    if(!image || !image.id){
      return;
    }
    setCreateConfigShotsPending(true);
    const dto = {
      ImageId: 
        process.env.NODE_ENV === "production"
        ? image.id
        : `${process.env.NODE_ENV === "production" ? image.aspRatioY : 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`,
      ArtistId: 
        process.env.NODE_ENV === "production"
        ? image.artistId
        : `${process.env.NODE_ENV === "production" ? image.aspRatioY : 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`,
      AspectRatio: `${process.env.NODE_ENV === "production" ? image.aspRatioY : 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`
    }
    axios.post(
      `${process.env.NODE_ENV === "production"
      ? ""
      : "https://pikcha.local:1900"
    }/v1/mockup/MockupRequest/CreateConfigShots?ImageId=${process.env.NODE_ENV === "production"
      ? image.id
      : `${process.env.NODE_ENV === "production" ? image.aspRatioY : 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`
    }&AspectRatio=${`${process.env.NODE_ENV === "production" ? image.aspRatioY : 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`}&ArtistId=${process.env.NODE_ENV === "production"? image.artistId : `${process.env.NODE_ENV === "production" ? image.aspRatioY : 1}x${process.env.NODE_ENV === "production" ? image.aspRatioX : 1}`}`,  
      dto,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      setCreateConfigShotsPending(false)
      var randomNumber = Date.now() + Math.floor(Math.random() * 1000);
      setRandomNumber(randomNumber);
      setImageRerender(!imageRerender);
      setCreateConfigShotsSent(true);
    }).catch((err) => {
      setCreateConfigShotsPending(false)
      console.log(err);
      setShotsGenerated(false);
      setCreateConfigShotsSent(true);
      setAlert("We're having trouble creating a render of the artwork.", "error");
    })
  }

  const showImagePreview = useCallback(
    () => {
      return (
        <img 
          src={process.env.NODE_ENV !== "production" ? 
            `${PIKCHA_CONFIG_CDN}/MockupTest/configtest/${summary?.assetNumber}.jpg?q=${randomNumber}` : 
            settings?.searchAPIUrl?.indexOf("develop") !== -1 ? 
              `${PIKCHA_CONFIG_CDN}/Data/Test/${image?.artistId || image?.artist?.id}/Images/ConfigShots/${image?.id}/${summary?.assetNumber}.jpg?q=${randomNumber}` :
              `${PIKCHA_CONFIG_CDN}/Data/Web/${image?.artistId || image?.artist?.id}/Images/ConfigShots/${image?.id}/${summary?.assetNumber}.jpg?q=${randomNumber}`
          }
          style={{
            width: getCanvasDimensions().width - (isOnMobile ? 8 : 0),
            height: (getCanvasDimensions().width - (isOnMobile ? 8 : 0)) * 2/3,
            marginTop: "2em",
            borderRadius: 6,
            display: isPageLoading || imageLoading ? "none" : undefined,
            backgroundColor: palette.grey.light,
            marginLeft: isOnMobile ? 4 : undefined,
          }}
          onLoad={() => {
            setImageLoading(false)
          }}
          onError={() => {
            if(summary?.selectedMaterial?.id === "Gloss" || !summary?.assetNumber){
              return
            }
            if(!createConfigShotsSent){
              if(createConfigShotsPending){
                return
              }
              createConfigShots();
            } else {
              setShotsGenerated(false);
            }
          }}
        />
      )},[
        imageLoading,
        imageRerender,
        isOnMobile,
        isPageLoading,
        getCanvasDimensions().width,
        process.env.NODE_ENV,
        PIKCHA_CONFIG_CDN,
        summary?.assetNumber,
        settings?.searchAPIUrl,
        image?.artist?.id,
        image?.artistId,
        image?.id,
        summary?.assetNumber,
        image?.aspRatioX,
        image?.aspRatioY,
        randomNumber,
        createConfigShotsSent,
        createConfigShotsPending
  ]);
  
  return (
    <div>
      <CustomiseAuthModal
        open={authModalOpen}
        onClose={authModalOnClose}
        onLogIn={authModalOnLogin}
        onJoin={authModalOnJoin}
        onContinueAsGuest={authModalOnContinueAsGuest}
        image={modalImage}
        setClickAsGuest={setClickAsGuest}
        previewImageRef={previewImageRef}
        summary={summary}
        setWallViewState={setWallViewState}
      />
      <div>
        <div
          style={{
            textAlign: isOnDesktop ? "left" : "center",
          }}
          id="canvasHolder"
          className={isOnDesktop && "text-left"}
        >
          {showImagePreview()}
          {imageLoading && (
            <div
              style={{
                width: getCanvasDimensions().width - (isOnMobile ? 8 : 0),
                height: (getCanvasDimensions().width - (isOnMobile ? 8 : 0)) * 2/3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                background: "linear-gradient(103.81deg, #F2F4F8 16.12%, #F2F3F3 50.35%, #F2F4F8 84.82%)",
                margin: "auto"
              }}
            >
              <div 
                className='flex'
                style={{
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <PikchaLoader />
                <TypographySubtitle 
                  component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
                  style={{
                    textAlign: "center"
                  }}
                >
                  We're generating your thumbnail
                </TypographySubtitle>
              </div>
            </div>
          )}
          {isPageLoading && (
            <div
              style={{
                marginTop: "2em",
                borderRadius: 6,
                backgroundColor: palette.grey.light,
                width: getCanvasDimensions().width,
                height: getCanvasDimensions().height,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settingsReducer,
});

export default connect(mapStateToProps, {setAlert})(CustomiseSummary)