import {
  ARTIST100_LOADED,
  ARTIST100_LOADING,
  ARTIST100_ERROR,
  ARTIST100_RESET,
  HANDLE_SAVED_IMAGE,
} from "../actions/types";
import {
  GALLERY_ITEMINPAGE_DEFAULT,
  GALLERY_PAGE_DEFAULT,
} from "../helpers/constants";

const initialState = {
  top100: [],
  count: GALLERY_ITEMINPAGE_DEFAULT,
  start: GALLERY_PAGE_DEFAULT,
  loading: true,
  error: null,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HANDLE_SAVED_IMAGE: {
      const { imageId, saved: isSaved } = payload;
      const top100 = state.top100.map((artist) => {
        artist.images = artist.images.map((image) => {
          const { id } = image;
          if (id === imageId) {
            image = {
              ...image,
              ...{
                isSaved,
              },
            };
          }
          return image;
        });
        return artist;
      });
      return {
        ...state,
        ...{ top100 },
      };
    }

    case ARTIST100_LOADED:
      return {
        ...state,
        top100: [...state.top100, ...payload],
        loading: false,
        start: state.start + state.count,
      };

    case ARTIST100_ERROR:
      !(payload || {}).data === "You have reached the end." &&
        console.error(payload);
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: !(payload || {}).data === "You have reached the end.",
      };
    case ARTIST100_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ARTIST100_RESET:
      return initialState;

    default:
      return state;
  }
};
