import React from 'react';

export default ({ palette, handleSideDrawer }) => (
  <div
    onClick={() => handleSideDrawer(false)}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: palette.action.disabled,
      zIndex: 10,
    }}
  />
);
