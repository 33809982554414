import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import reactCSS from "reactcss";
import merge from "lodash/merge";

import {
  ColorWrap,
  Saturation,
  Hue,
  Alpha,
  Checkboard,
} from "react-color/lib/components/common";
import ChromeFields from "react-color/lib/components/chrome/ChromeFields";
import ChromePointer from "react-color/lib/components/chrome/ChromePointer";
import ChromePointerCircle from "react-color/lib/components/chrome/ChromePointerCircle";

import { PikchaButton } from "../../../components/baseComponents";

export const Chrome = ({
  width,
  onChange,
  onApply,
  onCancelChoosingColor,
  disableAlpha,
  rgb,
  hsl,
  hsv,
  hex,
  renderers,
  styles: passedStyles = {},
  className = "",
  defaultView,
  vibrantColors = [],
  thiefColors = []
}) => {
  const dropdown = useRef(null);

  const [hoverColor, setHoverColor] = useState({
    value: false,
    item: ""
  })

  useEffect(() => {
    const handleClick = (e) => {
      if (
        dropdown &&
        dropdown.current &&
        !dropdown.current.contains(e.target)
      ) {
        onCancelChoosingColor();
      }
    };

    setTimeout(() => {
      document.addEventListener("mousedown", handleClick);
    }, 250);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const styles = reactCSS(
    merge(
      {
        default: {
          picker: {
            width,
            background: "#fff",
            borderRadius: "6px",
            boxShadow: "0 0 2px rgba(0,0,0,.3), 0 4px 8px rgba(0,0,0,.3)",
            boxSizing: "initial",
            fontFamily: "Menlo",
            padding: ".5em"
          },
          saturation: {
            width: "100%",
            paddingBottom: "55%",
            position: "relative",
            borderRadius: "2px 2px 0 0",
            overflow: "hidden",
          },
          Saturation: {
            radius: "2px 2px 0 0",
          },
          body: {
            padding: "0px 16px 12px",
          },
          controls: {
            display: "flex",
          },
          color: {
            width: "32px",
          },
          swatch: {
            marginTop: "6px",
            width: "100%",
            height: "36px",
            borderRadius: "9px",
            position: "relative",
            overflow: "hidden",
          },
          active: {
            absolute: "0px 0px 0px 0px",
            borderRadius: "8px",
            boxShadow: "inset 0 0 0 1px rgba(0,0,0,.1)",
            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`,
            zIndex: "2",
          },
          toggles: {
            flex: "1",
          },
          hue: {
            height: "10px",
            position: "relative",
            marginTop: "16px"
          },
          Hue: {
            radius: "2px",
          },
          alpha: {
            height: "10px",
            position: "relative",
          },
          Alpha: {
            radius: "2px",
          },
        },
        disableAlpha: {
          color: {
            width: "100%",
          },
          alpha: {
            display: "none",
          },
          hue: {
            marginBottom: "0px",
          },
          swatch: {
            width: "100%",
            height: "36px",
            marginTop: "0px",
            boxShadow: "0px 1px 5px 0.5px rgba(0, 0, 0, 0.15)"
          },
        },
      },
      passedStyles
    ),
    { disableAlpha }
  );

  return (
    <div
      ref={dropdown}
      style={styles.picker}
      className={`chrome-picker ${className}`}
    >
      <div
        style={{
          fontSize: "0.75rem",
          fontWeight: 500,
          marginLeft: 7.5
        }}
      >
        Suggestions
      </div>
      <div                        
        style={{
          display: "flex",
          margin: "3px 0",
          justifyContent:"center",
          height: 32,
          alignItems: "center",
          width: "100%"
        }}
      >
      {vibrantColors.map((item)=>{
        return (
          <div
            key={item}
            style={{
              height: (hoverColor.item === item) && hoverColor.value ? 28 : 24,
              backgroundColor: item,
              margin: "0 6.75",
              borderRadius: 4,
              cursor: "pointer",
              flex:1,
              width: (hoverColor.item === item) && hoverColor.value ? 28 : 24,
              boxShadow: (hoverColor.item === item) && hoverColor.value ? "0px 1px 3px 1px rgba(0, 0, 0, 0.15)" : null
            }}
            onMouseDown={()=>{
              onChange(item)
            }}
            onMouseUp={()=>{
              onApply();
            }}
            onMouseEnter={()=>{setHoverColor({
              value: true,
              item: item
            })}}
            onMouseLeave={()=>{setHoverColor({
              value: false,
              item: item
            })}}
          />
        )
      })}
      </div>
            <div
              style={{
                display: "flex",
                margin: "3px 0",
                justifyContent:"center",
                height: 32,
                alignItems: "center",
                width: "100%"
              }}
            >
              {(thiefColors || []).map((item, index) => (
                <div 
                  key={index} 
                  style={{ 
                    height: (hoverColor.item === index) && hoverColor.value ? 28 : 24,
                    backgroundColor: item,
                    margin: "0 6.75",
                    borderRadius: 4,
                    cursor: "pointer",
                    flex: 1,
                    width: (hoverColor.item === index) && hoverColor.value ? 28 : 24,
                    boxShadow: (hoverColor.item === index) && hoverColor.value ? "0px 1px 3px 1px rgba(0, 0, 0, 0.15)" : null
                  }}
                  onMouseDown={()=>{
                    onChange(item)
                  }}
                  onMouseUp={()=>{
                    onApply();
                  }}
                  onMouseEnter={()=>{setHoverColor({
                    value: true,
                    item: index
                  })}}
                  onMouseLeave={()=>{setHoverColor({
                    value: false,
                    item: index
                  })}}
                />
              ))}
            </div>
      <div 
        style={styles.saturation}
        onMouseUp={()=>{
          onApply();
        }}
      >
        <Saturation
          style={styles.Saturation}
          hsl={hsl}
          hsv={hsv}
          pointer={ChromePointerCircle}
          onChange={onChange}
        />
      </div>
      <div style={styles.hue}>
        <Hue
          style={styles.Hue}
          hsl={hsl}
          pointer={ChromePointer}
          onChange={onChange}
        />
      </div>
      <div style={styles.body}>
        <div style={styles.controls} className="flexbox-fix">
          <div style={styles.toggles}>
            <div style={styles.alpha}>
              <Alpha
                style={styles.Alpha}
                rgb={rgb}
                hsl={hsl}
                pointer={ChromePointer}
                renderers={renderers}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <form
          onSubmit={(e)=>{
            e.preventDefault();
            onApply();
          }}
        >
          <ChromeFields
            rgb={rgb}
            hsl={hsl}
            hex={hex}
            view={defaultView}
            onChange={onChange}
            disableAlpha={disableAlpha}
          />
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1em"
          }}
        >
          <div style={styles.color}>
            <div style={styles.swatch}>
              <div style={styles.active} />
                <Checkboard 
                  renderers={renderers}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Chrome.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableAlpha: PropTypes.bool,
  styles: PropTypes.object,
  defaultView: PropTypes.oneOf(["hex", "rgb", "hsl"]),
};

Chrome.defaultProps = {
  width: 225,
  disableAlpha: false,
  styles: {},
};

export default ColorWrap(Chrome);
