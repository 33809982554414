import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import ProductItemDesktop from "../containers/product/ProductItem/ProductItemDesktop";
import ProductItemMobile from "../containers/product/ProductItem/ProductItemMobile";
import { PikchaLoader } from "../components/baseComponents";
import Desktop from "../components/Responsive/Desktop";
import Tablet from "../components/Responsive/Tablet";
import Mobile from "../components/Responsive/Mobile";
import { addView } from "../actions/gallery";

import { getPhotoFriendlyUrl } from "../actions/friendlyUrlImage";

const titleCase = require("ap-style-title-case");

const ImageDetails = ({ getPhotoFriendlyUrl, gallery, addView }) => {
  const image = gallery.photos.length > 0 ? gallery.photos[0] : null;
  const loading = gallery.loading;

  useEffect(() => {
    getPhotoFriendlyUrl(window.location.pathname);
  }, []);

  useEffect(() => {
    if(image && image.id){
      addView((image || {}).id);
    }
  }, [image])

  if (!image) {
    return null;
  }

  let artistNickName =  image.artist.nickName ? image.artist.nickName : "Pikcha ";  
  let imageTitle = image.title? image.title : "";
  let metaDescription = "Collect this limited-edition print of "+ imageTitle +" by "+ artistNickName;

  if (typeof image.dateCreated !== 'undefined' && image.dateCreated && image.dateCreated != "00-00-0000") {
    metaDescription += " | created on "+image.dateCreated;
  };

  if (typeof image.location !== 'undefined' && image.location) {
    metaDescription += " | located in "+image.location;
  };

  return (
    <Fragment>
      <Helmet>
        <title>{titleCase(artistNickName)} | {titleCase(imageTitle)}</title>
        <meta name="description" content={`${metaDescription}`}></meta>
      </Helmet>
      <div
        style={{
          width: "100%",
          marginTop: "64px",
        }}
      >
        {image && (
          <div>
            <Desktop>
              <ProductItemDesktop image={image} />
            </Desktop>
            <Tablet>
              <ProductItemMobile image={image} />
            </Tablet>
            <Mobile>
              <ProductItemMobile image={image} />
            </Mobile>
          </div>
        )}
        {loading && (
          <div
            style={{
              marginTop: "128px",
              display: "flex",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <PikchaLoader />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  gallery: state.friendlyUrlImageReducer
});

export default connect(mapStateToProps, {
  getPhotoFriendlyUrl,
  addView
})(ImageDetails);
