import React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core";

export default ({ children, href, ...rest }) => {
  if (!href) {
    throw new Error("The href must be provided");
  }

  const { pathname } = useLocation();

  const { typography, palette } = useTheme();

  return (
    <a
      className="anchor-link"
      style={{
        color: "black",
        fontWeight: typography.fontWeightMedium,
        paddingTop: 8,
        paddingBottom: 8,
        // paddingLeft: 32,
        // backgroundImage: `url('data:image/svg+xml;utf-8,<svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23616161" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-.35 12.65l-2.79-2.79c-.32-.32-.1-.86.35-.86h5.59c.45 0 .67.54.35.85l-2.79 2.79c-.2.2-.52.2-.71.01z"></path></svg>')`,
        backgroundRepeat: "no-repeat",
      }}
      href={href}
      {...rest}
    >
      {children}
    </a>
  );
};
