import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  makeStyles
} from "@material-ui/core/styles";
import { colors } from "../../colors";

const useStyles = () => 
  makeStyles(({palette}) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: palette.common.white,
        '& + $track': {
          backgroundColor: colors.Neutral.ink90,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: colors.Neutral.ink90,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${palette.grey[400]}`,
      backgroundColor: palette.grey[50],
      opacity: 1,
    },
    checked: {},
    focusVisible: {},
  }))(Switch);

export default ({
  checked,
  handleChange,
  labelText,
  style,
  disabled,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Switch 
          size="small" 
          checked={checked} 
          onChange={handleChange} 
          disabled={disabled}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
        />
      }
      label={labelText}
      style={{
        ...style
      }}
      {...rest}
    />
  )
}
