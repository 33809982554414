import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import { withOrientationChange } from 'react-device-detect';
import { isMobile } from '../../../env_utils/device_screen';
import {useTheme} from "@material-ui/core/styles";
import {
  TypographySubtitle, 
  TYPOGRAPHY_SUBTITLE_COMPONENT, 
  TypographyBody, 
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton, 
} from "@pikcha/typography";

let Step2 = ({
  handleCurrentPage,
  setActiveStep,
  stepOneAccountName,
  setUserAccountName,
  user,
  userCountry, userCurrency, userLanguage, userMeasureUnit,
  userHandle,
  getUserOnboarded,
  setAlert
}) => {
  const {palette} = useTheme();
  const [imageTypes, setImageTypes] = useState([]);
  const [selectedTypeIDs, setSelectedTypeIDs] = useState([]);
  const selectedCategoryIDs = [];

  useEffect(() => {
    const retrieveData = () => {
      axios({
        method: "GET",
        url: `api/image/prerequisites`,
        data: null,
      }).then((cat) => {
        const types = [];

        cat.data.types.forEach((type) =>
          types.push({
            id: type.id,
            name: type.name,
          })
        );
        setImageTypes(types);
      });
    };
    retrieveData();
  }, []);

  const sendData = useCallback(async () => {
    const userPreferences = {
      userCountry,
      Currency: userCurrency,
      Language: userLanguage,
      Measurement: userMeasureUnit,
      TimezoneOffset: new Date().getTimezoneOffset().toString(),
      TagIDsMedium: selectedTypeIDs.toString(),
      TagIDsCategories: selectedCategoryIDs.toString(),
    };

    const dto = {
      id: user.id,
      fName: user.fName,
      lName: user.lName,
      bio: user.bio,
      email: user.email,
      shipAddr1: user.shipAddr1,
      shipAddr2: user.shipAddr2,
      shipCity: user.shipCity,
      shipPostal: user.shipPostal,
      shipState: user.shipState,
      shipCountry: user.shipCountry,
      phoneNumber: user.phoneNumber,
      links: user.links,
      nationality: user.nationality,
      preferredCurrency: user.preferredCurrency,
      nickName: stepOneAccountName,
      userPreferences: userPreferences,
      userHandle: "@" + userHandle,
    }
    await getUserOnboarded(dto);
  }, [selectedCategoryIDs, selectedTypeIDs, stepOneAccountName, user.bio, user.email, user.fName, user.id, user.lName, user.links, user.nationality, user.phoneNumber, user.preferredCurrency, user.shipAddr1, user.shipAddr2, user.shipCity, user.shipCountry, user.shipPostal, user.shipState, userCountry, userCurrency, userHandle, userLanguage, userMeasureUnit]);

  return (
    <div
      style={{
        ...(isMobile
          ? {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: 'space-between',
            textAlign: "center",
            paddingTop: "5em",
            paddingBottom: "1em",
            maxHeight: "100%"
          }
          : {
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'space-between',
            textAlign: "center",
            flexGrow: 1,
            paddingLeft: '3em',
            paddingRight: '3em',
            paddingTop: "5em",
            paddingBottom: "1em",
            maxHeight: "100%"
          }),
      }}
    >
      <div  className="full-width">
        <div 
          className="full-width mb-1-em" 
          style={{
            textAlign: "center"
          }}
        >
          <TypographySubtitle
            component={isMobile ? TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H3_Bold : TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
          >
            What are your interests
          </TypographySubtitle>
        </div>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
          style={{
            textAlign: "center"
          }}
        >
          Help us find the perfect artwork by letting us know what you’re interested in.
        </TypographyBody>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexWrap: 'wrap',
          margin: "0 auto",
          justifyContent: "center"
        }}
      >
        {imageTypes && imageTypes.map((item) => {
          return (
            <Chip
              variant="outlined"
              size="small"
              label={item.name}
              component="a"
              clickable
              onClickCapture={() => {
                if (selectedTypeIDs.includes(item.id)){
                  // to be removed
                  let newList = [];
                  selectedTypeIDs && selectedTypeIDs.map(x => {
                    if (x !== item.id){
                      newList.push(x);
                    }
                  })
                  setSelectedTypeIDs(newList);
                } else {
                  // to be added
                  setSelectedTypeIDs([
                    ...selectedTypeIDs, item.id,
                  ]);
                }
              }}
              style={{
                fontWeight: 500,
                margin: 5,
                color: selectedTypeIDs.includes(item.id) ? 'white' : palette.text.primary,
                backgroundColor: selectedTypeIDs.includes(item.id) ? 'black' : null,
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                border: `1px solid rgba(0,0,0,0.1)`,
                width: isMobile ? "45%" :"30%",
                height: 72,
                borderRadius: 4,
                textTransform: "capitalize"
              }}
            />
          );
        })}
      </div>
      <TypographyButton
        className="Modal-actionButton no-underline"
        onClick={() => {
          if(!selectedTypeIDs.toString()){
            setAlert("Please select your interested medium", "error");
            return
          }
          sendData().then(_ => {
            setUserAccountName(stepOneAccountName);
            setActiveStep(2);
            handleCurrentPage(3);
          });
        }}
        buttonStyle={{
          marginBottom: "1em",
          width: "100%"
        }}
      >
        Next
      </TypographyButton>
    </div>
  );
}

Step2 = withOrientationChange(Step2);

export default Step2;
