import {
  SHOPPING_CART_ADD,
  SHOPPING_CART_CURRENCY_CHANGE,
  SHOPPING_CART_ERROR,
  SHOPPING_CART_LOADED,
  SHOPPING_CART_LOADING,
  SHOPPING_CART_REMOVE,
  SHOPPING_CART_UPDATED,
} from '../actions/types';

const initialState = {
  loading: true,
  shoppingCart: {
    items: []
  },
  errors: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOPPING_CART_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SHOPPING_CART_LOADED:
      return {
        loading: false,
        ...state,
        shoppingCart: payload,
      };
    case SHOPPING_CART_UPDATED:
      return {
        ...state,
        loading: false,
        shoppingCart: payload,
      };
    case SHOPPING_CART_ADD:
      return {
        loading: false,
        ...state,
        shoppingCart: payload,
      };
    case SHOPPING_CART_ERROR:
      return {
        loading: false,
        ...state,
        errors: payload,
      };
    case SHOPPING_CART_REMOVE:
      return {
        loading: false,
        ...state,
        shoppingCart: payload,
      };
    case SHOPPING_CART_CURRENCY_CHANGE:
      return {
        loading: false,
        ...state,
        shoppingCart: payload,
      };
    default:
      return state;
  }
};
