import {
  REMOVE_TIMER,
  ADD_TIMER,
  TIMER_MESSAGE,
  CHANGE_TIMER,
  UPDATE_EXISTING_TIMER,
} from "./types";
import worker from "../helpers/timerWorker.js";
import WebWorker from "../helpers/workerSetup.js";
export const addTimer = (data, allTimers) => {
  return async (dispatch) => {
    try {
      let newTimerWorker = null;
      if (typeof Worker !== "undefined") {
        let index = allTimers.timers.findIndex((x) => x.id === data.id);
        newTimerWorker = new WebWorker(
          worker,
          data.time.minutes,
          data.time.seconds
        );
        newTimerWorker.onmessage = function (event) {
          const timer = {
            minutes: event.data.split(":")[0],
            seconds: event.data.split(":")[1],
          };
          if (timer.minutes >= 0 && timer.seconds > 0) {
            dispatch({
              type: CHANGE_TIMER,
              payload: {
                data: data.data,
                id: data.id,
                worker: newTimerWorker,
                time: timer,
              },
            });
          } else if (timer.minutes === 0 && timer.seconds <= 0) {
            dispatch({
              type: REMOVE_TIMER,
              payload: {
                data: data.data,
                id: data.id,
                worker: newTimerWorker,
                time: timer,
              },
            });
          } else if (timer.minutes >= 0 && timer.seconds >= 0) {
            dispatch({
              type: CHANGE_TIMER,
              payload: {
                data: data.data,
                id: data.id,
                worker: newTimerWorker,
                time: timer,
              },
            });
          }
        };
        if (index > -1) {
          dispatch({
            type: CHANGE_TIMER,
            payload: {
              data: data.data,
              id: data.id,
              worker: newTimerWorker,
              time: data.time,
            },
          });
        } else {
          dispatch({
            type: ADD_TIMER,
            payload: {
              data: data.data,
              id: data.id,
              worker: newTimerWorker,
              time: data.time,
            },
          });
        }
      }
    } catch (err) {
      console.log("addTimer ", err);
      dispatch({ type: TIMER_MESSAGE, payload: "Timer init Error" });
    }
  };
};
export const workerOnMessage = () => {};
export const updateTimer = (e, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_EXISTING_TIMER,
        payload: data,
      });
    } catch (err) {
      console.log("updateTimer ", err);
      dispatch({ type: TIMER_MESSAGE, payload: "Timer Error" });
    }
  };
};
export const removeTimer = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_TIMER,
        payload: data,
      });
    } catch (err) {
      dispatch({ type: TIMER_MESSAGE, payload: "Timer Error" });
    }
  };
};
