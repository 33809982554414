import React from 'react'
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
    const { palette } = useTheme();

    return (
        <svg 
            width={size}
            height={size}
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M21.1525 5.38983L21.1406 5.34375C22.1575 4.73414 22.9785 3.72919 23.3438 2.57812C22.3913 3.1447 21.2769 3.57455 20.1562 3.79688C19.2576 2.83941 18.0585 2.25 16.6406 2.25C13.923 2.25 11.6719 4.45017 11.6719 7.17188C11.6719 7.55916 11.7266 7.93828 11.8125 8.29688V8.34375C7.76217 8.11237 4.09491 6.0525 1.6875 3.09375C1.26502 3.82172 1.03125 4.86197 1.03125 5.76562C1.03125 7.17188 1.86628 9.05536 3.1875 9.9375V10.0312C2.39859 9.99942 1.59056 9.45595 0.9375 9.09375C0.9375 9.11527 0.966094 9.14114 0.966094 9.16266C0.966094 11.5509 2.71659 13.6107 4.96875 14.0625C4.57627 14.1663 4.02347 14.25 3.60938 14.25C3.29072 14.25 2.97263 14.1667 2.67188 14.1094C3.29845 16.0673 5.10938 17.3438 7.26562 17.3438V17.3906C5.58727 18.6918 3.45994 19.5938 1.17188 19.5938C0.774422 19.5938 0.28125 19.5469 0 19.5C2.17692 20.8985 4.76484 21.75 7.54688 21.75C16.6019 21.75 21.5625 14.2969 21.5625 7.78125V7.125C22.5256 6.42933 23.3412 5.48972 24 4.5C24 4.5 22.2719 5.31886 21.1525 5.38983ZM19.6875 7.78125C19.6875 9.18806 19.412 10.6654 18.8906 12.0534C18.3428 13.5122 17.5542 14.8285 16.5468 15.9657C14.967 17.7491 12.1232 19.875 7.54688 19.875C7.41239 19.875 7.27814 19.8727 7.14389 19.868C7.58573 19.6117 8.01038 19.3264 8.41444 19.0131L9.14062 18.4501V17.5312V17.3438V15.4688L8.36817 15.4602C7.52353 15.4508 6.84375 14.7635 6.84375 13.9188V12.3857L5.8298 12.1567C5.38111 12.0554 5.0625 11.6568 5.0625 11.1968V10.0312V9.89062L5.03906 8.93489L4.2052 8.37816C3.84511 8.0752 3.51877 7.6425 3.28627 7.17422C5.74669 8.9685 8.66878 10.0422 11.7056 10.2157L13.6875 10.3289V8.34375V8.29688V8.07539L13.6359 7.86C13.5768 7.61348 13.5469 7.38197 13.5469 7.17188C13.5469 6.3645 13.869 5.60213 14.4539 5.02514C15.0423 4.44469 15.819 4.125 16.6406 4.125C17.4714 4.125 18.1943 4.44633 18.7891 5.08008L19.2675 5.58975L19.3254 5.81334L19.3373 5.85942L19.6875 7.21317V7.78125Z" 
                fill={color || palette.grey.medium}
            />
        </svg>

    )
}
