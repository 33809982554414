import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core";

import {
  PikchaButton,
  PikchaSubTitle,
  PikchaLoader,
  PikchaTextField,
  PikchaTitle,
} from "../../baseComponents";
import { setAlert } from "../../../actions/alert";
import {
  EMAIL_INVALID_MESSAGE,
  EMAIL_VALIDATION_REGEX,
} from "../../../helpers/constants";

const ForgotPasswordBase = ({ setAlert }) => {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [disableTextField, setDisableTextField] = useState(false);

  const { typography } = useTheme();

  const handleLoading = (state) => {
    setLoading(state);
  };

  const handleEmail = ({ target: { value } }) => {
    setEmail(value);
  };
  const handleEmailValidation = () =>
    setEmailError(!EMAIL_VALIDATION_REGEX.test(email));

  const handleEnter = ({ key }) => {
    if (key && key === "Enter") {
      if (!EMAIL_VALIDATION_REGEX.test(email)) {
        return setEmailError(true);
      } else {
        handleSubmit();
      }
    }
  };

  const handleDisableTextField = (state) => {
    setDisableTextField(state);
  };

  const handleSubmit = () => {
    handleLoading(true);

    axios
      .post("/api/Account/forgotpassword", { email: email })
      .then((res) => {
        handleLoading(false);
        //console.log(res);
        if (res.status === 200) {
          if (res.data === "success") {
            handleDisableTextField(true);
            return setAlert("Reset password link sent", "success");
          }
          if (res.data === "email does not exist") {
            return setAlert("No user with this email exists", "error");
          }
        }
      })
      .catch((err) => {
        handleLoading(false);
        console.log("handleSubmit ", err);

        // Fall through to error message if successful response is not received.
        // TODO: test this edge-case?
        return setAlert(
          "Something went wrong. Please try again or contact support",
          "error"
        );
      });
  };

  return (
    <div
      style={{
        maxWidth: 320,
      }}
    >
      <div className="mb-10">
        <PikchaTitle>Forgotten your password?</PikchaTitle>
        <PikchaSubTitle>
          Enter your email and we'll send you a link to reset your password.
        </PikchaSubTitle>
      </div>

      {!loading ? (
        <>
          <PikchaTextField
            autoComplete="email"
            label="Email"
            type="email"
            value={email}
            onChange={handleEmail}
            onBlur={handleEmailValidation}
            onKeyPress={handleEnter}
            disabled={disableTextField}
            required
            error={!!emailError}
            helperText={emailError ? EMAIL_INVALID_MESSAGE : null}
          />
          <PikchaButton style={{ float: "right" }} onClick={handleSubmit}>
            Submit
          </PikchaButton>
        </>
      ) : (
        <PikchaLoader />
      )}

      <div
        className="AuthFormBase-footerText mt-10"
        style={{
          fontSize: typography.smallest,
        }}
      >
        <Link to="/auth/login">Back to login</Link>
      </div>
    </div>
  );
};

export default connect(null, {
  setAlert,
})(ForgotPasswordBase);
