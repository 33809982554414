import axios from "axios";
import AuthorizeService from "../auth/AuthorizeService";
import {searchAPIUrl} from "../selectors/settings";
import {setAlert} from "./alert";

import {
  ARTIST_PHOTOS_ERROR,
  ARTIST_PHOTOS_LOADED,
  ARTIST_PHOTOS_LOADING,
  ARTIST_PHOTOS_RESET,
  ARTIST_PROFILE_INFO_ERROR,
  ARTIST_PROFILE_INFO_LOADED,
  ARTIST_PROFILE_INFO_LOADING,
  BASE_64_SIGNATURE_STRING,
  FORCE_GALLERY_RERENDER,
  FORCE_SIGNATURE_COVER_RERENDER,
  PROFILE_ERROR,
  PROFILE_LOADED,
  PROFILE_LOADING,
  PROFILE_ONBOARDING_INIT,
  PROFILE_ONBOARDING_MODAL,
  PROFILE_RESET,
  PROFILE_UPDATED,
  RESET_UPDATED_FIELDS
} from "./types";

// Get Profile
export const getProfile = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setProfileLoading());
      const res = await axios.get(`api/profile/artist/${id}`);

      dispatch({
        type: PROFILE_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PROFILE_ERROR,
        payload: err && err.response ? err.response.data : err,
      });
    }
  };
};

export const getArtistProfileInfo = (id) => {
  return async (dispatch, getState) => {
    const searchApi = await searchAPIUrl(getState());
    try {
      if (searchApi) {
        dispatch(setArtistProfileInfoLoading());
        const token = await AuthorizeService.getAccessToken();
        const res = await axios.post(
          `${searchApi}/api/Search/ProfileInfo/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

        dispatch({
          type: ARTIST_PROFILE_INFO_LOADED,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: ARTIST_PROFILE_INFO_ERROR,
        payload: err && err.response ? err.response.data : err,
      });
    }
  };
};

// Get Artist Photos
export const getArtistPhotos = (artistId, count, start, sortBy = "") => {

  return async (dispatch, getState) => {
    try {
      dispatch(setArtistPhotosLoading());
      const searchApi = searchAPIUrl(getState());
      const token = await AuthorizeService.getAccessToken();
      const parameters = {
        Id: artistId,
        sortBy: sortBy,
        Page: start,
        ItemInPage: count,
      };

      const res = await axios.post(
        `${searchApi}/api/Search/ImagesByArtist`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: ARTIST_PHOTOS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ARTIST_PHOTOS_ERROR,
        payload: err.response,
      });
    }
  };
};

export const handleArtistPromotionModal = (state) => {
  return (dispatch) => {
    dispatch({type: PROFILE_ONBOARDING_MODAL, payload: state});
  };
};

// Get Artist Photos
export const getProfilePhotos = (artistId, count, start) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ARTIST_PHOTOS_LOADED,
        payload: [],
      });
      dispatch(setArtistPhotosLoading());

      const searchApi = searchAPIUrl(getState());
      const token = await AuthorizeService.getAccessToken();
      const parameters = {
        Id: artistId,
        Page: Math.ceil(Math.max(start, 1) / Math.max(count, 1)),
        ItemInPage: count,
      };

      const res = await axios.post(
        `${searchApi}/api/Search/ImagesByArtist`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: ARTIST_PHOTOS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      console.log("getProfilePhotos ", err);
      dispatch({
        type: ARTIST_PHOTOS_ERROR,
        payload: err.response,
      });
    }
  };
};

export const getProfilePhotosAnonymous = (artistId, count, start, sortBy = "") => {

  return async (dispatch, getState) => {
    try {
      dispatch(setArtistPhotosLoading());
      const searchApi = searchAPIUrl(getState());
      const parameters = {
        Id: artistId,
        sortBy: sortBy,
        Page: start,
        ItemInPage: count,
      };

      const res = await axios.post(
        `${searchApi}/api/Search/ImagesByArtist`,
        parameters
      );

      dispatch({
        type: ARTIST_PHOTOS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ARTIST_PHOTOS_ERROR,
        payload: err.response,
      });
    }
  };
};

export const updateCoverPicture = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setProfileLoading());
      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `api/profile/artist/${formData.id}/cover`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: PROFILE_UPDATED,
        payload: res.data,
      });
      dispatch(setAlert("Cover Picture Updated", "info"));
    } catch (err) {
      dispatch({type: PROFILE_ERROR, payload: err.response});
      dispatch(setAlert(err.response, "error"));
    }
  };
};

export const handleForceGalleryRerender = (state) => {
  return (dispatch) => {
    dispatch({
      type: FORCE_GALLERY_RERENDER,
      payload: state,
    });
  };
};

export const handleInitProfileOnBoarding = (state) => {
  return (dispatch) => {
    dispatch({
      type: PROFILE_ONBOARDING_INIT,
      payload: state,
    });
  };
};

export const handleBase64SignatureString = (string) => {
  return (dispatch) => {
    dispatch({
      type: BASE_64_SIGNATURE_STRING,
      payload: string,
    });
  };
};

export const handleForceSignatureCoverRerender = (state) => {
  return (dispatch) => {
    dispatch({
      type: FORCE_SIGNATURE_COVER_RERENDER,
      payload: state,
    });
  };
};

// Set Profile Loading
export const setProfileLoading = () => {
  return {type: PROFILE_LOADING};
};

// Set Artist Profile Info Loading
export const setArtistProfileInfoLoading = () => {
  return {type: ARTIST_PROFILE_INFO_LOADING};
};

// Set Profile Loading
export const setArtistPhotosLoading = () => {
  return {type: ARTIST_PHOTOS_LOADING};
};

// Reset Profile
export const resetProfile = () => {
  return (dispatch) =>
    dispatch({
      type: PROFILE_RESET,
    });
};

export const resetUpdatedFields = () => {
  return (dispatch) =>
    dispatch({
      type: RESET_UPDATED_FIELDS,
    });
};

// Reset Artist Photos
export const resetArtistPhotos = () => {
  return (dispatch) =>
    dispatch({
      type: ARTIST_PHOTOS_RESET,
    });
};
