import { ADD_ROUTE } from '../actions/types';

const initialState = {
  routeHistory: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_ROUTE:
      return {
        ...state,
        routeHistory: [...state.routeHistory, payload],
      };
    default:
      return state;
  }
};
