import React from "react";
import { connect } from "react-redux";
import { readyShoppingCartItems } from "../../selectors/shoppingCart";
import Payment from "../../components/checkoutComponents/Payment";

const Step3Mobile = ({
  discount,
  onDiscountChange,
  handleDiscountCode,
  handlePaymentSubmission,
  onCardFieldChange,
  cardProcessing,
  cardError,
  stripe,
  elements,
  pendingDiscountCode,
}) => {

  return (
    <>
      <div
        style={{
          position: "relative",
          animation: "fadeIn 0.6s",
          background: "white"
        }}
      >
        <div
          className="flex flex-column w-full"
        >
          <div className="mt-4">
            <Payment
              handlePaymentSubmission={handlePaymentSubmission}
              onCardFieldChange={onCardFieldChange}
              cardError={cardError}
              cardProcessing={cardProcessing}
              stripe={stripe}
              elements={elements}
              discount={discount}
              onDiscountChange={onDiscountChange}
              handleDiscountCode={handleDiscountCode}
              pendingDiscountCode={pendingDiscountCode}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  readyShoppingCartItems: readyShoppingCartItems(state),
});
export default connect(mapStateToProps, {})(Step3Mobile);
