import React, { useState } from "react";
import { connect } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import { refreshItems, getShoppingCart } from "../../actions/shoppingCart";
import { activeTimer } from "../../selectors/timer";
import { canTimeBeExtended, readyShoppingCartItems, id } from "../../selectors/shoppingCart";
import Timer from "../Timer/Timer";
import isMobile from "../../components/Responsive/isMobile";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography"
import { removeModalOverlay, } from "../../helpers/utils";
import RefreshAllItemsModal from "../../components/checkoutComponents/RefreshAllItemsModal";
import { TypographyButton, TYPOGRAPHY_BUTTON_TYPE } from "@pikcha/typography";

const Header = ({
  id,
  refreshItems,
  getShoppingCart,
  canTimeBeExtended,
  readyShoppingCartItems,
  activeTimer,
  showOrderSummaryheader,
}) => {
  const [refreshAllItemsModalState, setRefreshAllItemsModalState] = useState();
  const isOnMobile = isMobile();

  const refreshAllItems = () => {
    setRefreshAllItemsModalState(true);
  };

  const cancelRefreshAllItems = () => {
    setRefreshAllItemsModalState(false);
    removeModalOverlay();
  };

  const doRefreshAllItems = async () => {
    await refreshItems(id, "ALL");
    getShoppingCart(id);
    setRefreshAllItemsModalState(false);
    removeModalOverlay();
  };

  let timerId;
  if (activeTimer) {
    timerId = activeTimer.id;
  }
  const areThereReadyShoppingCartItems = readyShoppingCartItems.length > 0;

  return (
    <>
      {refreshAllItemsModalState && (
        <RefreshAllItemsModal
          open={refreshAllItemsModalState}
          cancelRefreshAllItems={cancelRefreshAllItems}
          doRefreshAllItems={doRefreshAllItems}
        />
      )}
      {
        !showOrderSummaryheader && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1.5em 1em",
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                Cart
              </TypographyBody>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <Timer
                style={{
                  height: 44,
                  marginTop: 0,
                  paddingTop: 11,
                  marginLeft: -2,
                  zIndex: 10,
                  borderRadius: "6px",
                  boxShadow: "rgba(0, 0, 0, 0.25) 0.5px 1px 4px"
                }}
                id={timerId}
                updateTimer={(e) => { }}
                status="active"
                zero={areThereReadyShoppingCartItems ? false : true}
              />
              <TypographyButton
                disabled={areThereReadyShoppingCartItems ? !canTimeBeExtended : true}
                onClick={(e) => refreshAllItems()}
                buttonStyle={{
                  borderRadius: "6px",
                  marginLeft: '10px',
                  backgroundColor: '#F2F4F8',
                  padding: "1em 1.5em"
                }}
                component={TYPOGRAPHY_BUTTON_TYPE.IconOnly}
                icon={<RefreshIcon style={{ width: '0.7em', color: "black" }} color="action" />}
              />                
            </div>
          </div>
        )}
      {!areThereReadyShoppingCartItems && showOrderSummaryheader && (
        <div
          style={{
            padding: "1em",
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            textAlign: "center"
          }}
        >
          <TypographyBody
            size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
          >
            Order Summary
          </TypographyBody>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  canTimeBeExtended: canTimeBeExtended(state),
  readyShoppingCartItems: readyShoppingCartItems(state),
  activeTimer: activeTimer(state),
  id: id(state)
});
export default connect(mapStateToProps, {
  refreshItems,
  getShoppingCart
})(Header);
