import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

export const PikchaInvisibleButton = withStyles(
  ({ shape, palette, typography }) => ({
    root: (props) => ({
      color: props.blackText ? palette.common.black : palette.grey.darkest,
      border: 0,
      fontSize: props.larger ? typography.large : typography.standard,
      borderRadius: shape.borderRadius,
      padding: props.small ? "4px 12px" : "6px 16px",
      boxShadow: "none",
      fontWeight: props.boldText
        ? typography.fontWeightBold
        : typography.fontWeightMedium,
      lineHeight: props.small ? 1.34 : 1.5,
      //marginRight: 4,
      textTransform: "none",
      textDecoration: "none",
      background: "transparent",
      "&:hover": {
        boxShadow: "none",
        background: 0,
      },
      ...(props.style || {}),
    }),
  })
)(({ children, blackText, larger, boldText, ...rest }) => (
  <Button {...rest}>{children}</Button>
));
