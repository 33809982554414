import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import reactCSS from "reactcss";
import merge from "lodash/merge";
import {Alpha, ColorWrap, Hue, Saturation,} from "react-color/lib/components/common";
import ChromePointer from "react-color/lib/components/chrome/ChromePointer";
import ChromePointerCircle from "react-color/lib/components/chrome/ChromePointerCircle";
import { 
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE 
} from "@pikcha/typography";
import { PikchaIconButton } from "../../../components/baseComponents";
import ColorizeRoundedIcon from '@material-ui/icons/ColorizeRounded';

export const Chrome = ({
  width,
  onChange,
  onApply,
  onCancelChoosingColor,
  disableAlpha,
  rgb,
  hsl,
  hsv,
  hex,
  renderers,
  styles: passedStyles = {},
  className = "",
  defaultView,
  vibrantColors = []
}) => {
  const dropdown = useRef(null);

  const [hoverColor, setHoverColor] = useState({
    value: false,
    item: ""
  });

  useEffect(() => {
    const handleClick = (e) => {
      if (
        dropdown &&
        dropdown.current &&
        !dropdown.current.contains(e.target)
      ) {
        onCancelChoosingColor();
      }
    };

    setTimeout(() => {
      document.addEventListener("mousedown", handleClick);
    }, 250);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const styles = reactCSS(
    merge(
      {
        default: {
          picker: {
            width,
            background: "#fff",
            boxSizing: "initial",
            fontFamily: "Menlo",
          },
          saturation: {
            width: "100%",
            paddingBottom: "55%",
            position: "relative",
            borderRadius: "2px 2px 0 0",
            overflow: "hidden",
          },
          Saturation: {
            radius: "2px 2px 0 0",
          },
          body: {
            padding: "0px 16px 12px",
          },
          controls: {
            display: "flex",
          },
          color: {
            width: "32px",
          },
          swatch: {
            marginTop: "6px",
            width: "100%",
            height: "36px",
            borderRadius: "18px",
            position: "relative",
            overflow: "hidden",
          },
          active: {
            absolute: "0px 0px 0px 0px",
            borderRadius: "18px",
            boxShadow: "inset 0 0 0 1px rgba(0,0,0,.1)",
            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`,
            zIndex: "2",
          },
          toggles: {
            flex: "1",
          },
          hue: {
            height: "10px",
            position: "relative",
            display: "flex",
            width: "100%",
            marginLeft: "auto"
          },
          Hue: {
            radius: "2px",
          },
          alpha: {
            height: "10px",
            position: "relative",
          },
          Alpha: {
            radius: "2px",
          },
        },
        disableAlpha: {
          color: {
            width: "100%",
          },
          alpha: {
            display: "none",
          },
          hue: {
            marginBottom: "0px",
          },
          swatch: {
            width: "100%",
            height: "36px",
            marginTop: "0px",
            boxShadow: "0px 1px 5px 0.5px rgba(0, 0, 0, 0.15)"
          },
        },
      },
      passedStyles
    ),
    { disableAlpha }
  );

  return (
    <div
      ref={dropdown}
      style={styles.picker}
      className={`chrome-picker ${className}`}
    >
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
        color="neutral/grey40"
      >
        Suggestions
      </TypographyBody>
      <div                        
        style={{
          display: "flex",
          margin: "3px 0",
          justifyContent:"center",
          height: 32,
          alignItems: "center",
          width: "100%"
        }}
      >
        <div
          style={{
            position: "relative",
            width: "14%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PikchaIconButton
            onClick={()=>{}}
            icon={<ColorizeRoundedIcon />}
            color="black"
            size="small"
            noPadding
            style={{
              cursor: "pointer",
              width: 24,
              height: 24,
              marginRight: 6
            }}
          />
          <input 
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              opacity: "0",
              cursor: "pointer",
              borderWidth: 1,
              borderColor: "black"
            }}
            type="color" 
            id="favcolor" 
            name="favcolor" 
            value="#ff0000" 
            onChange={(event)=>{
              const item = {
                hex: event.target.value
              }
              onChange(item);
              onApply(event.target.value);
            }}
          />
        </div>
        {vibrantColors.map((item)=>{
          return (
            <div
              key={item}
              style={{
                height: (hoverColor.item === item) && hoverColor.value ? 28 : 24,
                backgroundColor: item,
                margin: "0 3",
                borderRadius: 4,
                cursor: "pointer",
                flex:1,
                width: (hoverColor.item === item) && hoverColor.value ? 28 : 24,
                boxShadow: (hoverColor.item === item) && hoverColor.value ? "0px 1px 3px 1px rgba(0, 0, 0, 0.15)" : null
              }}
              onMouseDown={()=>{
                onChange(item)
              }}
              onMouseUp={()=>{
                onApply();
              }}
              onMouseEnter={()=>{setHoverColor({
                value: true,
                item: item
              })}}
              onMouseLeave={()=>{setHoverColor({
                value: false,
                item: item
              })}}
            />
          )
        })}
      </div>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
        color="neutral/grey40"
      >
        Spectrum
      </TypographyBody>
      <div 
        className="flex full-width"
        style={{
          alignItems: "center",
          marginBottom: "1em"
        }}
      >
        <div style={styles.hue} className="Hue">
          <Hue
            style={{...styles.Hue, position: "relative", width: "100%"}}
            hsl={hsl}
            pointer={ChromePointer}
            onChange={onChange}
          />
        </div>
      </div>
      <div 
        style={styles.saturation}
        onMouseUp={()=>{
          onApply();
        }}
      >
        <Saturation
          style={styles.Saturation}
          hsl={hsl}
          hsv={hsv}
          pointer={ChromePointerCircle}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

Chrome.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableAlpha: PropTypes.bool,
  styles: PropTypes.object,
  defaultView: PropTypes.oneOf(["hex", "rgb", "hsl"]),
};

Chrome.defaultProps = {
  width: 225,
  disableAlpha: false,
  styles: {},
};

export default ColorWrap(Chrome);
