import { SETTINGS_LOADED } from "../actions/types";

const initialState = {
  settings: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SETTINGS_LOADED:
      return {
        ...state,
        ...{
          settings: payload,
        },
      };

    default:
      return state;
  }
};
