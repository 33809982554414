import React from 'react';
import Desktop from '../../../components/Responsive/Desktop';
import Tablet from '../../../components/Responsive/Tablet';
import Mobile from '../../../components/Responsive/Mobile'
import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';

export default () => (
  <>
    <Desktop>
      <NavigationDesktop/>
    </Desktop>
    <Tablet>
      <NavigationDesktop/>
    </Tablet>
    <Mobile>
      <NavigationMobile/>
    </Mobile>
  </>
);