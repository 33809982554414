import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { readyShoppingCartItems } from "../../selectors/shoppingCart";
import visaIcon from "../../assets/images/visa_1.svg";
import masterIcon from "../../assets/images/mastercard_1.svg";
import ReactPixel from "react-facebook-pixel";
import { FB_PIXEL_EVENT_TYPES } from "../../hooks/useFbPixelEvent/event-constants";
import ReactGA from "react-ga4";
import { getSavedUtmDetails, isProductionSite } from "../../helpers/utils";
import isMobile from "../../components/Responsive/isMobile";
import {
  TypographyBody, 
  TypographySubtitle, 
  TYPOGRAPHY_SUBTITLE_COMPONENT, 
  TYPOGRAPHY_BODY_SIZE
} from "@pikcha/typography"

const Step6Checkout = ({
  paymentSuccessResponse,
  fullName,
  email,
  phoneNumber,
  shipAddress1,
  shipAddress2,
  shipCity,
  shipPostal,
  country,
  countryState,
  shipState,
  billToShippingAddress,
  billAddress1,
  billAddress2,
  billCity,
  billPostal,
  billState,
  billCountry,
  shippingEstTime,
  cardType,
  cardLast4digit,
  preferredCurrency
}) => {
  const isOnMobile = isMobile();
  const userPreferencesReducer = useSelector((state) => state.userPreferencesReducer);

  const { palette, typography } = useTheme();
  const orderNumber =
    paymentSuccessResponse && paymentSuccessResponse.orderNumber;
  const totalPrice = (
    paymentSuccessResponse &&
    paymentSuccessResponse.requestDetails &&
    paymentSuccessResponse.requestDetails.totalAmount
  ) || 0;
  const state = country === "United States" ? countryState : shipState;

  const addr1 = billToShippingAddress ? shipAddress1 : billAddress1;
  const addr2 = billToShippingAddress ? shipAddress2 : billAddress2;
  const city = billToShippingAddress ? shipCity : billCity;
  const postal = billToShippingAddress ? shipPostal : billPostal;
  const thestate = billToShippingAddress
    ? country === "United States"
      ? countryState
      : shipState
    : billState;
  const thecountry = billToShippingAddress ? country : billCountry;

  useEffect(
    () => {
      if (isProductionSite()) {
        ReactPixel.track(FB_PIXEL_EVENT_TYPES.Purchase, {
          value: totalPrice,
          currency: userPreferencesReducer.currencyOptions.label,
          // TODO: clarify that this param is being sent correctly in
          // production.
          content_ids: [(paymentSuccessResponse &&
            paymentSuccessResponse.orderItem &&
            paymentSuccessResponse.orderItem.id) || ""],
          ...getSavedUtmDetails(),
          /*[image.id]*/
        });
        ReactGA.event("purchase", {
          currency: userPreferencesReducer.currencyOptions.label,
          transaction_id: orderNumber,
          value: totalPrice,
          ...getSavedUtmDetails(),
        });
      }
    },
    [orderNumber, paymentSuccessResponse, totalPrice,
      userPreferencesReducer.currencyOptions.label],
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
  }, [/* condition for when checkout complete */]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag("event", "purchase", {
      transaction_id: `${paymentSuccessResponse.orderNumber}`,
      value: `${paymentSuccessResponse.requestDetails.totalAmount}`,
      shipping: `${paymentSuccessResponse.requestDetails.shipCost}`,
      currency: preferredCurrency,
      items: paymentSuccessResponse.orderItems
    });
  }, [paymentSuccessResponse.orderNumber, preferredCurrency])

  return (
    <>
      <div
        style={{
          position: "relative",
          borderRadius: 6,
          boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.15)",
          padding: "2.5em",
          margin: "1em",
          animation: "fadeIn 0.6s",
          background: "white",
          minHeight: 350,
        }}
      >
        <>
          <div
            style={{
              display: "flex",
            }}
          >
            <TypographySubtitle
              component={isOnMobile ? TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H3_Bold : TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
            >
              Order Confirmed
            </TypographySubtitle>
          </div>
          <TypographyBody
            style={{
              marginTop: "1.5em",
            }}
          >
            Thank you for your order {fullName}
          </TypographyBody>
          <TypographyBody
            style={{
              marginTop: "1.5em",
            }}
          >
            A confirmation email has been sent to {email}. You will receive an
            email with the tracking <br />
            information once your items have shipped.
          </TypographyBody>
          <TypographyBody
            size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Large_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
            style={{
              marginTop: "2em",
              marginBottom: "2em",
            }}
          >
            Order Information
            - <span>&#35;{paymentSuccessResponse.orderNumber}</span>
          </TypographyBody>
          <div
            style={{
              display: "flex",
              flexDirection: isOnMobile && 'column'
            }}
          >
            <div style={{ marginRight: "2em", width: "100%", maxWidth: "270" }}>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              >
                Contact
              </TypographyBody>
              <TypographyBody
                style={{
                  lineHeight: '28px',
                  marginTop: "1em",
                }}
              >
                {fullName} <br />
                {email}<br />
                {phoneNumber}
              </TypographyBody>
            </div>
            <div
              style={{
                marginTop: isOnMobile && '2em',
                width: "100%",
                maxWidth: "300",
              }}
            >
              <div style={{
                margin: isOnMobile && "1em 0 0" ,
                lineHeight: '28px',
              }}>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                >
                  Address
                </TypographyBody>
                <TypographyBody
                  style={{
                    lineHeight: '28px',
                    marginTop: "1em",
                  }}
                >
                  <p>{shipAddress1}</p>
                  {shipAddress2 && <p>{shipAddress2}</p>}
                  <p>
                    {shipCity}, {shipPostal}
                  </p>
                  <p>
                    {state}, {country}
                  </p>
                </TypographyBody>
              </div>
              {billToShippingAddress == true ?
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{
                    marginTop: "1em",
                    color: '#697077'
                  }}
                >
                  Delivery address same as billing address
                </TypographyBody>
                :
                <div
                  style={{
                    marginTop: "1em",
                  }}
                >
                  <p
                    style={{
                      color: palette.text.primary,
                      fontSize: typography.smallest,
                      fontWeight: typography.fontWeightMedium,
                    }}
                  >
                    Billing Information
                  </p>
                  <div
                    style={{
                      marginTop: "1em",
                      lineHeight: '28px',
                    }}
                  >
                    <p>{addr1}</p>
                    {addr2 && <p>{addr2}</p>}
                    <p>
                      {city}, {postal}
                    </p>
                    <p>
                      {thestate}, {thecountry}
                    </p>
                  </div>
                </div>
              }
            </div>
          </div>
          <div
            style={{
              marginTop: "2em",
              display: "flex",
              flexDirection: isOnMobile && 'column'
            }}
          >
            <div style={{ marginRight: "2em", width: "100%", maxWidth: "270" }}>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              >
                Shipping Details
              </TypographyBody>
              <TypographyBody
                style={{
                  marginTop: "1em"
                }}
              >
                Expected To Ship in {shippingEstTime} Business Day(s)
              </TypographyBody>
            </div>
            <div
              style={{
                margin: isOnMobile && '1em 0 0',
                width: "100%",
                maxWidth: "300",
              }}
            >
              <>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                >
                  Payment
                </TypographyBody>
                <div
                  style={{
                    marginTop: "1em",
                    lineHeight: '28px',
                  }}
                >
                  {cardType === "visa" || cardType === "mastercard" ? (
                    <>
                      {cardType === "visa" && (
                        <TypographyBody>
                          Credit Card
                          <img
                            style={{
                              marginLeft: 6,
                            }}
                            src={visaIcon}
                            width={20}
                          />
                        </TypographyBody>
                      )}
                      {cardType == "mastercard" && (
                        <TypographyBody>
                          Credit Card
                          <img
                            style={{
                              marginLeft: 6,
                            }}
                            src={masterIcon}
                            width={20}
                          />
                        </TypographyBody>
                      )}
                    </>
                  ) : (
                    <TypographyBody>Credit Card {cardType}</TypographyBody>
                  )}
                  <TypographyBody>**** &nbsp; {cardLast4digit}</TypographyBody>
                </div>
              </>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  readyShoppingCartItems: readyShoppingCartItems(state),
});
export default connect(mapStateToProps, {})(Step6Checkout);
