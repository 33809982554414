import {
  SHOW_AGE_RESTRICTION,
  CANCEL_AGE_RESTRICTION,
  PASS_AGE_RESTRICTION,
} from "../actions/types";

const initialState = {
  open: false,
  passed: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_AGE_RESTRICTION:
      return {
        ...state,
        open: true,
      };
    case CANCEL_AGE_RESTRICTION:
      return {
        ...state,
        open: false,
        passed: false,
      };
    case PASS_AGE_RESTRICTION:
      return {
        ...state,
        passed: true,
        open: false,
      };
    default:
      return state;
  }
};
