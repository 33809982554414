import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Paypal_icon from "../../../assets/images/Paypal.svg";
import isMobile from "../../../components/Responsive/isMobile";
import { makeStyles, useTheme, withStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { updatePaymentDetails } from "../../../actions/account";
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  colors
} from "@pikcha/typography";
import {
  PikchaToggleButton,
  PikchaToggleButtonGroup,
} from "../../../components/baseComponents";
import { COUNTRIES } from "../../../config/countries";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";
import PaymentMethod from "./PaymentMethod";
import { Accordion, AccordionSummary } from "@material-ui/core";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    padding: 0,
  },
  contentMargin: {
    backgroundColor: 'white',
    border: '0',
  },
  accordionRoot: {
    boxShadow: 'none',
    backgroundColor: colors.Neutral.grey20,
    padding: '0 !important'
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);

const PaymentInformation = ({ account, country }) => {
  const isOnMobile = isMobile();
  const shouldOpenPaymentInformationSection = () => {
    const location = window.location.href;
    if (location.endsWith("payment-information")) {
      return "Payment Information";
    }
    else {
      return "";
    }
  }

  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const [hasPaypal, setHasPaypal] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("Paypal");
  const [paymentInfo, setPaymentInfo] = useState("");
  const [currentOpen, setCurrentOpen] = useState(shouldOpenPaymentInformationSection());

  const classes = useStyles();
  const { palette, typography } = useTheme();

  const onPersonalDetailsExpansion = () => {
    if(currentOpen === "Payment Information"){
      setCurrentOpen("")
    } else {
      setCurrentOpen("Payment Information")
    }
  }

  const selectPaymentInfo = () => {
    let primaryIndex = 0;
    paymentInfo.accounts.map((account, index) => {
      if (account.primary === "true") {
        primaryIndex = index
      }
    })
    return (
      <>
        {paymentInfo.accounts[primaryIndex].type === "bank-transfer" && (
          <div className="flex">
            <div className="mr-2-em">
              <TypographyBody
                style={{ marginBottom: '0.5em' }}
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
              >
                Payment Method
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                style={{ marginBottom: '0.5em' }}>
                Currency
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                style={{ marginBottom: '0.5em' }}>
                Account #
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                style={{ marginBottom: '0.5em' }}>
                Routing Number
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                style={{ marginBottom: '0.5em' }}>
                Bank Name
              </TypographyBody>
            </div>
            <div
              style={{
                fontSize: typography.small,
                fontWeight: typography.fontWeightMedium,
                color: palette.text.secondary
              }}
            >
              <div>
                <TypographyBody
                  style={{ marginBottom: '0.5em' }}
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                >
                  {paymentInfo.accounts[primaryIndex].type}
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                  style={{ marginBottom: '0.5em' }}>
                  {paymentInfo.accounts[primaryIndex].currency}
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                  style={{ marginBottom: '0.5em' }}>
                  {paymentInfo.accounts[primaryIndex].accountNum}
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                  style={{ marginBottom: '0.5em' }}>
                  {paymentInfo.accounts[primaryIndex].branchId}
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                  style={{ marginBottom: '0.5em' }}>
                  {paymentInfo.accounts[primaryIndex].bankName}
                </TypographyBody>
              </div>
            </div>
          </div>
        )}
        {paymentInfo.accounts[primaryIndex].type === "paypal" && (
          <div className="flex flex-column">
            <div className="flex">
              <div
                className="mr-3-em"
                style={{
                  fontSize: typography.small,
                  fontWeight: typography.fontWeightMedium,
                  color: palette.text.primary
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{ marginBottom: '0.5em' }}>
                  Payment Method
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{ marginBottom: '0.5em' }}>
                  Currency
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{ marginBottom: '0.5em' }}>
                  Email
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  style={{ marginBottom: '0.5em' }}>
                  Verification
                </TypographyBody>
              </div>
              <div
                style={{
                  fontSize: typography.small,
                  fontWeight: typography.fontWeightMedium,
                  color: palette.text.secondary
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                  style={{ marginBottom: '0.5em' }}>
                  Paypal
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                  style={{ marginBottom: '0.5em' }}>
                  $USD
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                  style={{ marginBottom: '0.5em' }}>
                  {account.user.paypalEmail}
                </TypographyBody>
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                  style={{ marginBottom: '0.5em' }}>
                  {account.user.paypalVerification ? "Verified" : "Unverified"}
                </TypographyBody>
              </div>
            </div>
            {!account.user.paypalVerification && (
              <div className="flex flex-column">
                <div
                  style={{
                    fontSize: typography.smallest,
                    fontWeight: typography.fontweightMedium,
                    color: palette.text.secondary
                  }}
                >
                  Your Paypal account must be verified before we can pay you!
                </div>
                <div
                  id="paypal-button"
                  style={{
                    width: 280,
                    marginTop: "1em",
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  const cancelEditPayment = () => {
    setIsEditingPayment(false)
  }
  const editPaymentMethod = () => {
    setIsEditingPayment(true);
  }

  useEffect(() => {
    COUNTRIES.map((item) => {
      if (item.label === country) {
        setHasPaypal(item.PaypalEnabled);
      }
    })
  }, [country, COUNTRIES]);

  useEffect(() => {
    if (!paymentInfo) {
      if (account.user.paymentMethod.toLowerCase() === "paypal") {
        const list = [{
          primary: "true",
          type: "paypal"
        }]
        setPaymentInfo({
          accounts: list
        })
      } else if (account.user.paymentMethod.toLowerCase() === "bank-transfer") {
        const list = [{
          primary: "true",
          type: "bank-transfer",
          currency: account.user.paymentCurrency,
          accountNum: account.user.accountNumber,
          branchId: account.user.branchId,
          bankName: account.user.bankName,
          recipientFees: account.user.artistFee
        }]
        setPaymentInfo({
          accounts: list
        })
      }
    }
  }, [account])

  return (
    <>
      <Accordion 
        expanded={currentOpen === "Payment Information"}
        className={classes.accordionRoot} 
        style={{ marginBottom: 16 }}
        onChange={onPersonalDetailsExpansion} 
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            Payment Information
          </TypographyBody>
        </StyledAccordionSummary>
        {paymentInfo && paymentInfo.accounts.length > 0 && (
          <div
            className={classes.contentMargin}
            style={{ padding: '2em 1em 0 1em' }}
          >
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              style={{ marginBottom: '1.5em' }}
            >
              Your Payment Method
            </TypographyBody>
            {selectPaymentInfo()}
          </div>
        )}
        {account.user.paymentMethod && !isEditingPayment && (
          <div
            style={{
              paddingBottom: '2em',
              paddingRight: '1em',
              display: 'flex',
              width: '100%',
              justifyContent: 'end'
            }}
            className={classes.contentMargin}
          >
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              onClick={editPaymentMethod}
            >
              Edit Payment Method
            </TypographyButton>
          </div>
        )}
        {isEditingPayment && (
          <div
            className={classes.contentMargin}
            style={{ display: 'flex', width: '100%', justifyContent: 'end' }}
          >
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              onClick={cancelEditPayment}
            >
              Cancel
            </TypographyButton>
          </div>
        )}
        {(isEditingPayment || !account.user.paymentMethod) && (
          <div
            className={classes.contentMargin}
            style={{ paddingTop: '2em' }}
          >
            <div
              className={classes.contentMargin}
              style={{
                fontWeight: typography.fontWeightMedium,
                color: palette.text.primary,
                paddingLeft: '1em'
              }}
            >
              Change Your Payment Method
            </div>
            <div
              style={{ marginLeft: '1em' }}
            >
              <PikchaToggleButtonGroup
                className={classes.contentMargin}
                style={{
                  display: "flex",
                  alignSelf: "center",
                  width: isOnMobile ? "100%" : 360,
                  maxHeight: 32,
                  border: "2px solid rgb(238, 238, 238)",
                  marginTop: "2em"
                }}
                value={paymentMethod}
              >
                <PikchaToggleButton
                  style={{
                    color: palette.text.disabled,
                    border: 0,
                    width: isOnMobile ? "50%" : 180
                  }}
                  selectedStyle={{
                    color: `${palette.text.primary} !important`,
                    boxShadow: "none",
                  }}
                  value={"Paypal"}
                  aria-label="Paypal"
                  onClick={() => {
                    if (paymentMethod !== "Paypal") {
                      setPaymentMethod("Paypal")
                    }
                  }}
                >
                  <div
                    style={{
                      width: 16,
                      height: 16,
                      backgroundImage: `url(${Paypal_icon})`,
                      backgroundSize: "cover",
                      marginRight: "0.5em",
                    }}
                  />
                  Paypal
                </PikchaToggleButton>
                <PikchaToggleButton
                  style={{
                    color: palette.text.disabled,
                    border: 0,
                    width: isOnMobile ? "50%" : 180
                  }}
                  selectedStyle={{
                    color: `${palette.text.primary} !important`,
                    boxShadow: "none",
                  }}
                  value={"BankTransfer"}
                  aria-label="Bank Transfer"
                  onClick={() => {
                    if (paymentMethod !== "BankTransfer") {
                      setPaymentMethod("BankTransfer")
                    }
                  }}
                >
                  <AccountBalanceRoundedIcon
                    style={{
                      height: 16,
                      width: 16,
                      marginRight: isOnMobile ? "0.2em" : "0.5em",
                    }}
                  />
                  Bank Transfer
                </PikchaToggleButton>
              </PikchaToggleButtonGroup>
            </div>
            <div
              className={classes.contentMargin}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginLeft: "1em"
              }}
            >
              <PaymentMethod
                hasPaypal={hasPaypal}
                paymentMethod={paymentMethod}
                paymentInfo={paymentInfo}
                setPaymentInfo={setPaymentInfo}
                setIsEditingPayment={setIsEditingPayment}
              />
            </div>
          </div>
        )}
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  updatePaymentDetails,
})(PaymentInformation);
