import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";

import { accountNotificationRead, accountNotificationDelete } from "../../../actions/accountNotifications";

import { getDynamicTimeStamp } from "../../../helpers/utils";
import IconButton from "@material-ui/core/IconButton";
import { MoreVertRounded } from "@material-ui/icons";
import Popover from "@material-ui/core/Popover";
import {
  DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP,
  ICON_SIZE_SMALL,
} from "../../../helpers/constants";
import {
  List,
  ListItem,
  ListItemIcon, MenuList,
} from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import NotificationOptionsItem from "./NotificationOptionsItem";
import {EMAIL_NOTIFICATION_TYPE, PAYPAL_NOTIFICATION_TYPE} from "../../../helpers/constants";

const AccountNotification = ({ notification, accountNotificationRead, accountNotificationDelete, onClose }) => {
  const {
    Url,
    PrimaryIcon,
    SecondaryIcon,
    Message,
    CreatedAt,
    IsRead,
    IsPrimaryIconAnImage,
    NotificationType
  } = notification;
  const history = useHistory();
  const { palette, typography } = useTheme();

  const [isHovering, setIsHovering] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsHovering(false);
  };

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };
  const readNotification=()=>{
    const { NotificationId } = notification;
    accountNotificationRead(NotificationId);
  };
  const deleteNotification=()=>{
    const { NotificationId } = notification;
    accountNotificationDelete(NotificationId);
  };
  const open = Boolean(anchorEl);
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const el = e.target.tagName.toLowerCase();

    // If the "More" icon in the top right is clicked, do NOT do anything.
    if (el === "button" || el === "path" || el === "svg") {
      return;
    }

    const { NotificationId } = notification;
    accountNotificationRead(NotificationId);

    if (Url) {
      history.push(Url, {});
//      window.location.href = Url;
    }

    onClose();
  };

  return (
    <div
      onClick={onClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      className="relative"
      style={{
        position: "relative",
        cursor: "pointer",
        padding: "1em",
        display: "flex",
        alignItems: "center",
        ...(isHovering
          ? {
            backgroundColor: palette.grey.lightest,
          }
          : {}),
      }}
    >
      {(isHovering && !(NotificationType == EMAIL_NOTIFICATION_TYPE || NotificationType == PAYPAL_NOTIFICATION_TYPE) || open) && (
        <IconButton
          onClick={handleClick}
          style={{
            zIndex: 999999999999999,
            position: "absolute",
            top: 4,
            right: 4,
            padding: 2,
            borderRadius: 6,
            height: 32,
            width: 32,
          }}
        >
          <MoreVertRounded />
        </IconButton>
      )}


      {open && (
        <Popover
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          PaperProps={{
            style: {
              width: DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP,
              maxWidth: DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP,
            },
          }}
          style={{
            zIndex: 99999999,
          }}
          container={document.querySelector(".NotificationsDropdown-scrollContainer")}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuList component="nav" aria-label="main mailbox folders">

            <NotificationOptionsItem
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
                readNotification();
                onClose();
              }}
              icon={<CheckRoundedIcon />}
              text="Mark as read"
            />

            <NotificationOptionsItem
               onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
                deleteNotification();
                onClose();
              }}
              icon={<CloseRoundedIcon />}
              text="Delete notification"
            />
          </MenuList>
        </Popover>
      )}

      <div
        style={{
          minWidth: 50,
          minHeight: 50,
          alignContent: "center",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${PrimaryIcon})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            ...(IsPrimaryIconAnImage ?
              {
                width: 50,
                height: 50,
              }
              :
              {
                width: 30,
                height: 30,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "0.5em",
              }
            ),
          }}
        />
      </div>

      <div
        style={{
          marginLeft: "1em",
          marginRight: ".5em"
        }}
      >
        <p
          style={{
            fontSize: "0.75rem",
            fontWeight: 500,
            color: typography.disabled,
          }}
        >
          {Message}
        </p>
        <p
          style={{
            marginTop: "0.5em",
            marginLeft: "auto",
            color: palette.purple.medium,
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
          }}
        >
          {getDynamicTimeStamp(CreatedAt)}

          {SecondaryIcon ? (
            <span>
              <img
                style={{
                  width: "18px",
                  height: "18px",
                  borderRadius: 18,
                  objectFit: "cover",
                  position: "relative",
                  top: 7,
                  left: 10,
                }}
                src={SecondaryIcon}
              />
            </span>
          ) : null}
        </p>
      </div>

      {!IsRead && (
        <div
          style={{
            minWidth: 6,
            minHeight: 6,
            width: 6,
            height: 6,
            borderRadius: "50%",
            backgroundColor: palette.purple.medium,
            position: "absolute",
            right: 18,
            bottom: "1.25em",
          }}
        />
      )}

    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  accountNotificationRead,
  accountNotificationDelete,
})(AccountNotification);
