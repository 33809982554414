import React from "react";
import { connect } from "react-redux";
import CartItemNotification from "./themes/cartItemNotification";
import TimeReminderNotification from "./themes/timeReminderNotification";
import "./Notification.css";

const Notification = ({
  showTimeReminder,
  handleTimerClose,
  notification,
  handleClose
}) => {

  const onClose = () => {
    handleClose();
  };

  const updateTimer = (t) => {
    let newVal = notification.item;
    newVal.currentTime = t;
  };

  return (
    <React.Fragment>
      {notification.type === "cartItemNotification" && (
        <CartItemNotification
          open={notification.type === "cartItemNotification" ? true : false}
          image={notification.item.image}
          startTimer={notification.item.startTimer}
          hideCloseBtn={false}
          onClose={onClose}
          handleTimerClose={handleTimerClose}
        />
      )}
      {notification.type === "timeReminder" && showTimeReminder && (
        <TimeReminderNotification
          startTimer={notification.item.startTimer}
          currentTimer={notification.item.currentTime}
          timerUpdated={(e) => updateTimer(e)}
          hideCloseBtn={true}
          handleClose={handleTimerClose}
          id={notification.item.id}
        />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  notification: state.notificationReducer,
});

export default connect(mapStateToProps)(Notification);
