import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { FacebookLoginButton as FacebookButton } from "react-social-login-buttons";
import { socialAuthButtonBaseStyles } from "./styles";
import FacebookIconAlt from "../../extraIcons/FacebookIconAlt";

export default ({ children, ...rest }) => {
  const { palette } = useTheme();

  return (
    <FacebookButton
      className="SocialAuthButton"
      style={{
        ...socialAuthButtonBaseStyles,
        background: "transparent",
      }}
      activeStyle={{
        ...socialAuthButtonBaseStyles,
        background: palette.action.hover,
      }}
      icon={(props) => <FacebookIconAlt iconSize={24} {...props} />}
      align="center"
      {...rest}
    >
      {children}
    </FacebookButton>
  );
};
