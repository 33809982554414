import React from 'react';
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
    const { palette } = useTheme();
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path 
                    d="M9.66404 9.50891C9.66404 8.3768 8.89383 8.3768 8.89383 8.3768H8.48602H5.7251V10.806H8.69741C9.21105 10.8058 9.66404 10.6415 9.66404 9.50891Z" 
                    fill={color || palette.grey.medium}
                />
                <path 
                    d="M8.89408 12.4839H5.7251V15.3934H8.74775C9.20246 15.3804 10.0417 15.238 10.0417 13.9792C10.0417 12.4687 8.89408 12.4839 8.89408 12.4839Z" 
                    fill={color || palette.grey.medium}
                />
                <path 
                    d="M17.0066 10.8058C15.3251 10.8058 15.0906 12.4839 15.0906 12.4839H18.6666C18.6669 12.4839 18.6882 10.8058 17.0066 10.8058Z" 
                    fill={color || palette.grey.medium}
                />
                <path 
                    d="M12.0059 0C5.37842 0 0.00585938 5.37281 0.00585938 12C0.00585938 18.6272 5.37842 24 12.0059 24C18.6333 24 24.0059 18.6272 24.0059 12C24.0059 5.37281 18.6333 0 12.0059 0ZM14.6222 7.08288H19.1138V8.42345H14.6222V7.08288ZM12.352 14.1142C12.352 17.4369 8.89408 17.3276 8.89408 17.3276H5.7251H5.63205H3.2291V6.44158H5.63205H5.7251H8.89384C10.6155 6.44158 11.9744 7.392 11.9744 9.33999C11.9744 11.2882 10.3132 11.4117 10.3132 11.4117C12.503 11.4117 12.352 14.1142 12.352 14.1142ZM17.0281 15.8312C18.8376 15.8312 18.7735 14.66 18.7735 14.66H20.6893C20.6893 17.7681 16.9642 17.5552 16.9642 17.5552C12.494 17.5552 12.7815 13.3936 12.7815 13.3936C12.7815 13.3936 12.778 9.21134 16.9637 9.21134C21.3702 9.21134 20.7532 13.9365 20.7532 13.9365H15.1122C15.1122 15.9593 17.0281 15.8312 17.0281 15.8312Z" 
                    fill={color || palette.grey.medium}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white" transform="translate(0.00585938)"/>
                </clipPath>
            </defs>
            </svg>
    )
}
