import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    const redirectUrl = pathname.replace("/inter", "");

 console.log(redirectUrl);
	    history.push(redirectUrl);
  }, []);

  return null;
};
