import React, {useLayoutEffect, useRef} from "react";
import {connect} from "react-redux";
import {useTheme} from "@material-ui/core/styles";
import {PikchaLoader} from "../../../components/baseComponents";
import {setSignatureColor} from '../../../components/SignatureCanvas/utils/getViewBoxCropData'

const SignatureDisplay = ({
                            style,
                            alignInnerPosition,
                            textSize,
                            signature,
                            signatureFont,
                            color,
                            signatureData,
                            omitSignature
                          }) => {
  const signaturePreviewWrapperRef = useRef(null);

  const {typography} = useTheme();
  
  // It's important we utilise the `useLayoutEffect` hook instead of
  // `useEffect` to hide the resizing of the signature preview.
  useLayoutEffect(() => {
    if (signaturePreviewWrapperRef.current) {
      const signaturePreview = signaturePreviewWrapperRef.current.firstChild;

      if (signaturePreview && signaturePreview.style) {
        signaturePreview.style.maxWidth = 80;
        signaturePreview.style.maxHeight = 60;
        signaturePreview.style.height = "auto";
        signaturePreview.style.paddingBottom = "0.25em";

        const signaturePreviewStrokePath = signaturePreview.querySelectorAll("path");
        setSignatureColor(signaturePreviewStrokePath, color);
      }
    }
  }, [signatureData, color, signature]);

  return (
    <div
      style={{
        ...style,
        height: 72,
        position: "relative"
      }}
    >
      <div
        style={{
          color: color,
          fontWeight: typography.fontWeightRegular,
          fontFamily: signatureFont,
          fontSize: textSize,
          // float: alignInnerPosition ? alignInnerPosition : "right",
          userSelect: "none",
          position: "absolute",
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          maxWidth: 80,
          alignItems: alignInnerPosition === "right" ? "flex-end" : "flex-start",
          ...(alignInnerPosition === "right" ? {
            right: 0
          } : {
            left: 0
          })
        }}
      >
        <>
          {(signatureData ? (
            <div
              ref={signaturePreviewWrapperRef}
              dangerouslySetInnerHTML={{__html: signatureData}}
            />
          ) : signature ? (
            <div
              ref={signaturePreviewWrapperRef}
              dangerouslySetInnerHTML={{__html: signature}}
            />
          ) : omitSignature ? null : (
            <PikchaLoader/>
          ))}
        </>
        00/100
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps)(SignatureDisplay);
