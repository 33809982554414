import {
  ACCOUNT_NOTIFICATIONS_DELETE,
  ACCOUNT_NOTIFICATIONS_ERROR,
  ACCOUNT_NOTIFICATIONS_LOADED,
  ACCOUNT_NOTIFICATIONS_LOADING,
  ACCOUNT_NOTIFICATIONS_MARK_ALL_AS_READ,
  ACCOUNT_NOTIFICATIONS_READ,
} from "../actions/types";

import {
  EMAIL_NOTIFICATION_TYPE,
  PAYPAL_NOTIFICATION_TYPE,
} from "../helpers/constants";

const initialState = {
  notifications: [],
  count: 5,
  start: 0,
  loading: true,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ACCOUNT_NOTIFICATIONS_LOADED:
      console.log(payload, ACCOUNT_NOTIFICATIONS_LOADED);
      return {
        ...state,
        notifications: [...state.notifications, ...payload],
        loading: false,
        hasMore: payload.length >= state.count,
        start: state.start + state.count,
      };
    case ACCOUNT_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ACCOUNT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        hasMore: false,
      };

    case ACCOUNT_NOTIFICATIONS_READ: {
      const notifications = state.notifications.map((notification) => {
        const notificationId = notification.NotificationId;

        if (notificationId === payload) {
          return {
            ...notification,
            IsRead: true,
          };
        }

        return {
          ...notification,
        };
      });

      return {
        ...state,
        notifications,
      };
    }

    case ACCOUNT_NOTIFICATIONS_DELETE: {
      const notifications =
        state.notifications.filter(({ NotificationId }) => NotificationId !== payload);

      return {
        ...state,
        notifications,
      };
    }

    case ACCOUNT_NOTIFICATIONS_MARK_ALL_AS_READ: {
      const notifications = state.notifications.map((notification) => {
        const { NotificationType } = notification;

        if (NotificationType === EMAIL_NOTIFICATION_TYPE || NotificationType === PAYPAL_NOTIFICATION_TYPE) {
          return {
            ...notification,
          };
        }

        return {
          ...{
            ...notification,
            IsRead: true,
          },
        };
      });

      return {
        ...state,
        notifications,
      };
    }

    default:
      return state;
  }
};
