import React from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

import {
  PikchaButton,
} from "../../../components/baseComponents";

const DeleteAccountModalStep1 = ({ onCancel, onStep2, isOnMobile}) => {
  const { typography, palette } = useTheme();
  return (
  <>
    <div>
      <p
        style={{
          fontSize: isOnMobile? typography.larger : typography.xLarge,
          color: palette.text.primary,
        }}
      >
        Delete Your Account
      </p>
      <p
        style={{
          marginTop: "1em",
          fontSize: typography.small,
          color: palette.text.primary,
        }}
      >
        We're sad to see you go.
      </p>
      <p
        style={{
          marginTop: "3em",
          fontSize: typography.small,
          color: palette.text.primary,
        }}
      >
        <strong>
          If you permenantly delete your account, you will not be able
          to retrieve it.
        </strong>
      </p>
      <p
        style={{
          fontSize: typography.small,
          color: palette.text.primary,
        }}
      >
        Are you sure that you want to continue to deleting your account
      </p>
    </div>
    <div  
        style={{
          marginTop: "4em",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <PikchaButton onClick={onCancel} action="secondary"
          style={{marginRight:"1em",}}
        >
          Cancel
        </PikchaButton>
        <PikchaButton action="primary" onClick={onStep2}>
          Continue
        </PikchaButton>
    </div>
  </>
  );
};

export default connect(null, {})(DeleteAccountModalStep1);
