import {
  FOLLOWING_LOADING,
  FOLLOWING_LOADED,
  FOLLOWING_ERROR,
  FOLLOWING_OPTION,
  FOLLOWING_SEARCH_BEGIN,
  ARTIST_FOLLOWED,
  ARTIST_UNFOLLOWED,
  ARTIST_NOTIFICATION_PREFERENCE_CHANGED,
  ARTIST_NOTIFICATION_UPDATING,
  ARTIST_FOLLOWING_UPDATING,
  ARTIST_FOLLOWERS_UPDATING,
  ARTIST_FOLLOWING_NO_UPDATING
} from "../actions/types";

import { followingOptions } from "../selectors/following";

const initialState = {
  following: [],
  count: 10,
  start: 1,
  loading: true,
  hasMore: true,
  noOfFollowing: 0,
  noOfNotifications: 0,
  noOfFollowers: 0,
  followers: [],
  notification: [],
  option: followingOptions.following,
  searchQuery: "",
  userList: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FOLLOWING_OPTION:
      return {
        ...state,
        following: [],
        notification: [],
        followers: [],
        start: 1,
        option: payload,
        hasMore: true,
      };
    case FOLLOWING_SEARCH_BEGIN:
      return {
        ...state,
        following: [],
        notification: [],
        followers: [],
        start: 1,
        hasMore: true,
      };
    case FOLLOWING_LOADED: {
      let following = payload.data.followingArtists || [];
      if (payload.option === followingOptions.following) {
        following = following.map((item) => ({
          ...item,
          isFollowing: true,
        }));
      }
      let hasMore = following.length >= state.count;
      let start = state.start + following.length;
      if (!payload.fromScratch) {
        following = [...state.following, ...following];
      }
      return {
        ...state,
        following,
        start,
        noOfFollowing: payload.data.noOfFollowing,
        noOfNotifications: payload.data.noOfNotifications,
        noOfFollowers: payload.data.noOfFollowers,
        option: payload.option,
        searchQuery: payload.searchQuery,
        loading: false,
        hasMore,
      };
    }
    case FOLLOWING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FOLLOWING_ERROR:
      return {
        ...state,
        option: payload.option,
        searchQuery: payload.searchQuery,
        loading: false,
        hasMore: false,
      };

    case ARTIST_FOLLOWED: {
      const followedArtistId = payload.artistId;
      let following = state.following.map((item) => {
        const { id } = item;
        let isFollowing = item.isFollowing;
        if (id === followedArtistId) {
          isFollowing = true;
        }
        return {
          ...item,
          isFollowing,
        };
      });
      return {
        ...state,
        following,
      };
    }

    case ARTIST_UNFOLLOWED: {
      const unfollowedArtistId = payload.artistId;
      let following = state.following.map((item) => {
        const { artistId } = item;
        let isFollowing = item.isFollowing;
        if (artistId === unfollowedArtistId) {
          isFollowing = false;
        }
        return {
          ...item,
          isFollowing,
        };
      });
      return {
        ...state,
        following,
      };
    }

    case ARTIST_NOTIFICATION_PREFERENCE_CHANGED: {
      const followedArtistId = payload.artistId;
      let following = state.following.map((item) => {
        const { artistId } = item;
        let isNotifiedApp = item.isNotifiedApp;
        let isNotifiedEmail = item.isNotifiedEmail;
        if (artistId === followedArtistId) {
          isNotifiedApp = payload.inApp;
          isNotifiedEmail = payload.email;
        }
        return {
          ...item,
          isNotifiedApp,
          isNotifiedEmail,
        };
      });
      return {
        ...state,
        following,
      };
    }

    case ARTIST_FOLLOWING_UPDATING: {
      return {
        ...state,
        following: [...state.following, ...payload.userList],
        noOfFollowing: payload.noOfFollowing,
        noOfNotifications: payload.noOfNotifications,
        noOfFollowers : payload.noOfFollowers,
        start: state.start + 1
      }
    }

    case ARTIST_NOTIFICATION_UPDATING: {
      return {
        ...state,
        notification: [...state.notification, ...payload.userList],
        noOfFollowing: payload.noOfFollowing,
        noOfNotifications: payload.noOfNotifications,
        noOfFollowers : payload.noOfFollowers,
        start: state.start + 1
      }
    }

    case ARTIST_FOLLOWERS_UPDATING: {
      return {
        ...state,
        following: [...state.following, ...payload.userList],
        noOfFollowing: payload.noOfFollowing,
        noOfNotifications: payload.noOfNotifications,
        noOfFollowers : payload.noOfFollowers,
        start: state.start + 1
      }
    }

    case ARTIST_FOLLOWING_NO_UPDATING: {
      return {
        ...state,
        noOfFollowing: payload
      }
    }

    default:
      return state;
  }
};
