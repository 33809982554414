import React, {useContext, useEffect, useRef, useState,} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";

import {isUserLoading, isUserLoggedIn} from "../../selectors/account";
import {inAppNotificationUrl} from "../../selectors/settings";

import {getAccountNotifications} from "../../actions/accountNotifications";
import {initOnboardingFlow} from "../../actions/onboarding";
import {
  closeDropdown,
  openCart,
  openMenu,
  openProfile,
} from "../../actions/nav";
import {handleSideDrawer} from "../../actions/sideDrawer";
import {createPromoteModal, createUploadImageModal,} from "../../actions/modal";
import {
  handleBase64SignatureString,
  handleForceGalleryRerender, handleForceSignatureCoverRerender,
  resetArtistPhotos,
} from "../../actions/profile";
import {getShoppingCart} from "../../actions/shoppingCart";
import {getPhotos} from "../../actions/gallery";
import {
  hideNotification,
  showNotification,
  updateNotificationType,
} from "../../actions/notification";

import OnboardingModal
  from "../../containers/modals/OnboardingModal/OnboardingModal";
import UploadImageModal
  from "../../containers/modals/UploadModal/UploadImageModal";

import Dropdown from "../../components/dropdowns/DropdownBase/DropdownBase";

import Desktop from "../../components/Responsive/Desktop";
import Tablet from "../../components/Responsive/Tablet";
import Mobile from "../../components/Responsive/Mobile";

import {getValueIfKeyExists,} from "../../helpers/utils";

import InviteCodeContext from "../../contexts/InviteCodeContext";

import NavBarDesktop from "./NavBarDesktop";
import NavBarMobile from "./NavBarMobile";

import Notification from "./Notification/Notification";
import {handleUploadStep} from "../../actions/cropper";

import "./NavBar.css";
import {
  removeSignature,
  updateUserSignature,
  uploadImage
} from "../../actions/account";
import {setAlert} from "../../actions/alert";
import {handleSignatureUpdateSuccess} from "../../actions/signature";
import BecomeArtistModal
  from "../../containers/modals/BecomeArtistModal/BecomeArtistModal";
import UploadFlowModal from "../../containers/modals/UploadFlowModal/UploadFlowModal";

const NavBar = ({
                  auth,
                  resetArtistPhotos,
                  account,
                  handleUploadStep,
                  openProfile,
                  shoppingCart,
                  openMenu,
                  notification,
                  getShoppingCart,
                  hideNotification,
                  showNotification,
                  updateNotificationType,
                  handleSideDrawer,
                  initOnboardingFlow,
                  onboarding,
                  loggedIn,
                  isLoading,
                  sideDrawer: {payload},

                  getPhotos,
                  handleForceGalleryRerender,
                  setAlert,
                  uploadImage,
                  inAppNotificationUrl,
                  getAccountNotifications,
                  cropper,

...rest
                }) => {
  const menuIconRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [userAccountName, setUserAccountName] = useState(account && account.user && account.user.nickName || "");

  // TODO: Find a better way to organise modals.
  const [onboardingModalState, setOnboardingModalState] = useState(false);
  const [becomeArtistModalState, setBecomeArtistModalState] = useState(false);
  const [uploadImageModalState, setUploadImageModalState] = useState(false);
  const [showTimeReminder, setShowTimeReminder] = useState(true);
  const [timer, setTimer] = useState({min: 12, seconds: 0});
  const [UploadInforFrame, setUploadInforFrame] = useState();

  const [activeStep, setActiveStep] = useState(1);

  const navBarRef = useRef(null);

  const url = window.location.href;
  //useEffect(() => {
  //  if (menuIconRef.current) {
  //    menuIconRef.current.style.display = pathname.includes("/auth")
  //      ? "none"
  //      : "auto";
  //  }
  //}, []);

  const location = useLocation();
  const {pathname} = location;

  useEffect(() => {
    if (inAppNotificationUrl) {
      getAccountNotifications();
    }
  }, [inAppNotificationUrl]);

  const handleClickCloseOnboardingModal = () => {
    // setOnboardingBooleanFlag(account.user.email, false);
    setOnboardingModalState(false);
  };

  const handleClickOpenArtistModal = () => {
    setBecomeArtistModalState(true);
  };
  const handleClickCloseArtistModal = () => {
    setBecomeArtistModalState(false);
  };
  const handleClickOpenImageModal = () => {
    setUploadImageModalState(true);
  };
  const handleClickCloseImageModal = () => {
    setUploadImageModalState(false);
    //resetArtistPhotos();
  };

  const onNotificationClose = () => {
    hideNotification();
    if (!notification.item.isExpiredItem) {
      updateNotificationType("timeReminder");
      setTimeout(function () {
        showNotification();
      }, 16);
    }
  };

  useEffect(() => {
    if((account.user || {}).roles && (account.user || {}).roles.includes("Artist")){
      var img = require("../../assets/images/upload-infor-frame.png");
      setUploadInforFrame(img.default);
    }
  }, [(account.user || {}).roles])

  useEffect(() => {
    if(location.pathname === "/checkout" ||
        location.pathname.includes('/print')
      /*location.pathname === "/customise"*/
    ){
      setShowTimeReminder(true);
    };
  }, [location])

  useEffect(() => {
    if (account && account.user && account.user.id && auth.isAuthenticated) {
      getShoppingCart(account.user.id);
    }
  }, [account.user, auth.isAuthenticated]);

  useEffect(() => {
    // Only displays the onboarding modals when a user first joins.
    if (
      auth.isAuthenticated &&
      account &&
      account.user &&
      !account.user.nickName
    ) {
      setOnboardingModalState(userAccountName === "" ? true : activeStep === 2);
    }
  }, [loggedIn, activeStep, location, account.user, userAccountName, auth]);

  const handleUploadClick = () => {
    account.user.roles.includes("Artist")
      ? handleClickOpenImageModal()
      : handleClickOpenArtistModal();
  };

  useEffect(() => {
    if (shoppingCart.shoppingCart.items) {
      const validItems = shoppingCart.shoppingCart.items.filter(item => item.isExpired === false);
      if (
        !url.endsWith("checkout") &&                            //do not show timer at checkout page
        !notification.item.isExpiredItem &&                     //remove the timer when item expired
        validItems.length > 0 &&                                //show the timer if there are items remained when user refreshed and vanish the timer if shoppingCart is empty
        notification.type !== "cartItemNotification"            //do not cover the checkout overlay if it shows up
      ) {
        updateNotificationType("timeReminder");
        setTimeout(function () {
          showNotification();
        }, 16);
      }
    }
  }, [url, shoppingCart.shoppingCart.items])

  const isLoadingNavbar =
    isLoading || window.location.pathname.startsWith("/authentication");
  // if (!betaInviteCode) {
  //   return null;
  // }
  return (
    <>
      <Desktop>
        <NavBarDesktop
          navBarRef={navBarRef}
          menuIconRef={menuIconRef}
          pathname={pathname}
          handleSideDrawer={handleSideDrawer}
          payload={payload}
          isLoading={isLoadingNavbar}
          loggedIn={loggedIn}
          handleUploadClick={handleUploadClick}
          cropper={cropper}
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          open={open}
        />
      </Desktop>
      <Tablet>
        <NavBarDesktop
          navBarRef={navBarRef}
          menuIconRef={menuIconRef}
          pathname={pathname}
          handleSideDrawer={handleSideDrawer}
          payload={payload}
          isLoading={isLoadingNavbar}
          loggedIn={loggedIn}
          handleUploadClick={handleUploadClick}
          cropper={cropper}
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          open={open}
        />
      </Tablet>
      <Mobile>
        <NavBarMobile
          navBarRef={navBarRef}
          menuIconRef={menuIconRef}
          pathname={pathname}
          handleSideDrawer={handleSideDrawer}
          payload={payload}
          isLoading={isLoadingNavbar}
          loggedIn={loggedIn}
          handleUploadClick={handleUploadClick}

          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          open={open}
        />
      </Mobile>
      <Dropdown/>
      <CssBaseline/>
      <OnboardingModal
        activeStep={activeStep}
        setActiveStep={setActiveStep}

        initOnboardingFlow={initOnboardingFlow}
        onboarding={onboarding}
        email={
          account && account.user && account.user.email
            ? account.user.email
            : undefined
        }
        open={onboardingModalState}
        handleClickClose={handleClickCloseOnboardingModal}

        userAccountName={userAccountName}
        setUserAccountName={setUserAccountName}
      />
      <BecomeArtistModal
        initOnboardingFlow={initOnboardingFlow}
        open={becomeArtistModalState}
        handleClickClose={handleClickCloseArtistModal}
      />
      <UploadFlowModal
        setAlert={setAlert}
        schematicDiagram={UploadInforFrame}
        account={account}
        modelStep={window.location.href.includes("/upload") && cropper.uploadStep === 1 && cropper.src ? 3 : window.location.href.includes("/upload") && cropper.uploadStep === 2 ? 1 : undefined}
        selectingRatio={window.location.href.includes("/upload") ? cropper.selectedRatio : undefined}
        parentAspectRatio={window.location.href.includes("/upload") ? cropper.selectedRatio : undefined}
        open={uploadImageModalState}
        croppedImgSrc={window.location.href.includes("/upload") ? cropper.orgImgData : undefined}
        handleClickClose={handleClickCloseImageModal}
        getBackToUploadStep1={()=>{
          handleUploadStep(1)
        }}
        {...rest}
      />
      {notification.show && (
        <div className="notificationHolder">
          <Notification 
            handleClose={onNotificationClose}
            handleTimerClose={()=>setShowTimeReminder(false)}
            showTimeReminder={showTimeReminder}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  nav: state.navReducer,
  account: state.accountReducer,
  cropper: state.cropperReducer,
  signatureReducer: state.signatureReducer,
  sideDrawer: state.sideDrawerReducer,
  shoppingCart: state.shoppingCartReducer,
  onboarding: state.onboardingReducer,
  notification: state.notificationReducer,
  loggedIn: isUserLoggedIn(state),
  isLoading: isUserLoading(state),
  inAppNotificationUrl: inAppNotificationUrl(state),
});

export default connect(mapStateToProps, {
  getPhotos,
  handleForceGalleryRerender,
  uploadImage,
  setAlert,
  handleUploadStep,
  openProfile,
  openMenu,
  openCart,
  closeDropdown,
  createUploadImageModal,
  createPromoteModal,
  getShoppingCart,
  resetArtistPhotos,
  updateNotificationType,
  initOnboardingFlow,
  hideNotification,
  showNotification,
  handleSideDrawer,
  getAccountNotifications,

  handleSignatureUpdateSuccess,
  handleForceSignatureCoverRerender,
  handleBase64SignatureString,
  updateUserSignature,
  removeSignature
})(NavBar);
