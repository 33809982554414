import { withStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";

export const PikchaVerticalTab = withStyles(
  ({ typography, shape, palette }) => ({
    root: {
      borderRadius: shape.borderRadius,
      fontWeight: typography.fontWeightMedium,
      color: "rgba(97, 97, 97, 0.38)",
      textTransform: "none",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&$selected": {
        color: palette.grey.dark,
        backgroundColor: palette.grey.light,
      },
      "&:hover": {
        color: palette.grey.dark,
        backgroundColor: palette.grey.light,
      },
    },
    selected: {},
  })
)(Tab);
