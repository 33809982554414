import React, { useState, useEffect, useCallback } from 'react';
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  colors
} from "@pikcha/typography";
import { connect } from "react-redux";
import {setAlert} from "../../actions/alert";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { PikchaReusableTab, PikchaReusableTabsGroup } from '../../components/baseComponents';
import { PIKCHA_CDN } from "../../helpers/constants";
import { getSavedUtmDetails, isProductionSite } from "../../helpers/utils";
import { FB_PIXEL_EVENT_TYPES } from "../../hooks/useFbPixelEvent/event-constants";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

const Step7 = ({
  setAlert,
  imageResp,
  heroShotsDetails,
  setSelectedHeroShots
}) => {
  const imageCopyLink = `https://www.pikcha.com/${imageResp.friendlyUrl}`;

  const [thumbNailMaterial, setThumbNailMaterial] = useState("Paper")

  useEffect(() => {
    if (isProductionSite()) {
      ReactPixel.trackCustom(
        FB_PIXEL_EVENT_TYPES.SuccessfulUpload,
        {...getSavedUtmDetails()}
      );
      ReactGA.event(
        "upload_art",
        {...getSavedUtmDetails()},
      );
    }
  }, []);

  const copyLink = () => {
    navigator.clipboard.writeText(imageCopyLink).then(
      () => {
        setAlert("URL has been copied", "info");
      },
      (_) => {
        setAlert("Couldn't copy image link", "error");
      },
    );
  };

  const mappingHeroShots = (item) => {
    const findKey = (data, value, compare = (a, b) => a === b) => {
      return Object.keys(data).find(k => compare(data[k], value))
    }

    let render = "";
    let key = "";

    key = findKey(heroShotsDetails.paperRender, item);
    render = "paperRender";

    if(!key){
      key = findKey(heroShotsDetails.canvasRender, item);
      render = "canvasRender";
    }

    key = key.replace("96px", "");
    setSelectedHeroShots(heroShotsDetails[render][key]);
  }

  const renderThumbnails = useCallback(
    () => {
      if(thumbNailMaterial === "Paper" && heroShotsDetails.paperRender){
        const paperKeyList = Object.keys(heroShotsDetails.paperRender).filter((item) => {
          return item.indexOf("Back") === -1 && item.indexOf("96") !== -1 
        })
        const paperValueList = [];
        paperKeyList.map((item) => {
          paperValueList.push(heroShotsDetails.paperRender[item])
        })
        return (
          <div 
            className="full-width flex mt-1-em"
            style={{
              justifyContent: "space-between",
            }}
          >
            {paperValueList.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  mappingHeroShots(item);
                }}
                style={{
                  width: "23%",
                  paddingBottom: "23%",
                  backgroundColor: colors.Neutral.grey20,
                  backgroundImage: `url(${PIKCHA_CDN}/${item})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: 5,
                  cursor: "pointer"
                }}
              />
            ))}
          </div>
        )
      }

      if(thumbNailMaterial === "Canvas" && heroShotsDetails.canvasRender){
        const canvasKeyList = Object.keys(heroShotsDetails.canvasRender).filter((item) => {
          return item.indexOf("Back") === -1 && item.indexOf("96") !== -1 
        })
        const canvasValueList = [];
        canvasKeyList.map((item) => {
          canvasValueList.push(heroShotsDetails.canvasRender[item])
        })
        return (
          <div 
            className="full-width flex mt-1-em"
            style={{
              justifyContent: "space-between",
            }}
          >
            {canvasValueList.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  mappingHeroShots(item);
                }}
                style={{
                  width: "23%",
                  paddingBottom: "23%",
                  backgroundColor: colors.Neutral.grey20,
                  backgroundImage: `url(${PIKCHA_CDN}/${item})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: 5,
                  cursor: "pointer"
                }}
              />
            ))}
          </div>
        )
      }
    },
    [heroShotsDetails, thumbNailMaterial],
  )

  return (
    <>
      <div 
        className="flex"
        style={{
          alignItems: "center",
          marginBottom: '1rem',
        }}
      >
        <CheckCircleOutlineIcon 
          style={{
            color: "green",
            marginRight: "0.5em"
          }}
        />
        <TypographySubtitle
          component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
        >
          Artwork Published
        </TypographySubtitle>
      </div>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
        style={{
          marginBottom: "1em"
        }}
      >
        Your artwork has been published successfully.
      </TypographyBody>
      <FacebookShareButton
        className="full-width flex"
        style={{
          paddingTop: "0.5em",
          paddingBottom: "0.5em",
          paddingLeft: "33%",
          backgroundColor: colors.Neutral.grey20,
          marginBottom: "0.5em",
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "start"
        }}
        quote={
          `Check out this artwork listing on Pikcha.com.` +
          " There are only 100 prints available! #pikcha #pikcha100"
        }
        url={`https://www.pikcha.com/${imageResp.friendlyUrl}`}
      >
        <FacebookIcon size={24}/>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
          style={{
            marginLeft: "2em"
          }}
        >
          Share on Facebook
        </TypographyBody>
      </FacebookShareButton>
      <TwitterShareButton
        className="full-width flex"
        style={{
          paddingTop: "0.5em",
          paddingBottom: "0.5em",
          paddingLeft: "33%",
          backgroundColor: colors.Neutral.grey20,
          marginBottom: "0.5em",
          cursor: "pointer",
          justifyContent: "start",
          alignItems: "center"
        }}
        quote={
          `Check out this artwork listing on Pikcha.com.` +
          " There are only 100 prints available! #pikcha #pikcha100"
        }
        url={`https://www.pikcha.com/${imageResp.friendlyUrl}`}
      >
        <TwitterIcon size={24}/>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
          style={{
            marginLeft: "2em"
          }}
        >
          Share on Twitter
        </TypographyBody>
      </TwitterShareButton>
      <LinkedinShareButton
        className="full-width flex"
        style={{
          paddingTop: "0.5em",
          paddingBottom: "0.5em",
          paddingLeft: "33%",
          backgroundColor: colors.Neutral.grey20,
          marginBottom: "0.5em",
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "start"
        }}
        quote={
          `Check out this artwork listing on Pikcha.com.` +
          " There are only 100 prints available! #pikcha #pikcha100"
        }
        url={`https://www.pikcha.com/${imageResp.friendlyUrl}`}
      >
        <LinkedinIcon size={24}/>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
          style={{
            marginLeft: "2em"
          }}
        >
          Share on LinkedIn
        </TypographyBody>
      </LinkedinShareButton>
      <TelegramShareButton
        className="full-width flex"
        style={{
          paddingTop: "0.5em",
          paddingBottom: "0.5em",
          paddingLeft: "33%",
          backgroundColor: colors.Neutral.grey20,
          marginBottom: "2em",
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "start"
        }}
        quote={
          `Check out this artwork listing on Pikcha.com.` +
          " There are only 100 prints available! #pikcha #pikcha100"
        }
        url={`https://www.pikcha.com/${imageResp.friendlyUrl}`}
      >
        <TelegramIcon size={24}/>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
          style={{
            marginLeft: "2em"
          }}
        >
          Share on Telegram
        </TypographyBody>
      </TelegramShareButton>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
      >
        Copy link
      </TypographyBody>
      <div
        className="full-width flex"
        style={{
          border: `1px solid ${colors.Neutral.grey30}`,
          padding: "0.5em",
          justifyContent: "space-between"
        }}
      >
        <TypographyBody
          style={{
            maxWidth: "80%",
            wordBreak: "break-all"
          }}
        >
          {imageCopyLink}
        </TypographyBody>
        <FileCopyIcon 
          onClick={copyLink}
          style={{
            cursor: "pointer"
          }}
        />
      </div>
      {heroShotsDetails.paperRender && (
        <div className="full-width">
          <PikchaReusableTabsGroup
            value={thumbNailMaterial}
            style={{
              width: "fit-content"
            }}
          >
            <PikchaReusableTab
              onClick={() => {
                setThumbNailMaterial("Paper")
              }}
              style={{
                width: 50,
                marginRight: "1em"
              }}
              value="Paper"
              label={
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                >
                  Paper
                </TypographyBody>
              }
            />
            <PikchaReusableTab
              onClick={() => {
                setThumbNailMaterial("Canvas")
              }}
              style={{
                width: 50
              }}
              value="Canvas"
              label={
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                >
                  Canvas
                </TypographyBody>
              }
            />
          </PikchaReusableTabsGroup>
          {renderThumbnails()}
        </div>
      )}
      <div
        className="full-width mt-1-em"
        style={{
          backgroundColor: colors.Neutral.grey20,
          padding: "2em 1em",
          borderRadius: 4,
        }}
      >
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          style={{
            marginBottom: "1em"
          }}
        >
          Pikcha Tip
        </TypographyBody>
        <TypographyBody>
          Sharing your new artwork on your social accounts increases the chances of making a sale dramatically.
          <br /><br />
          Read more about it on our blog.
        </TypographyBody>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  setAlert
})(Step7);