import { withStyles } from "@material-ui/core/styles";
import { ToggleButtonGroup } from "@material-ui/lab";
import React from "react";

export const PikchaToggleButtonGroup = withStyles(({ palette, shape }) => ({
  root: {
    // transition: "border-color 0.15s ease-in-out",
    // border: "1px solid rgba(0, 0, 0, .2)",
    "& > button:first-child": {
      borderRadius: `4px 0 0 4px`,
    },
    "& > button:last-child": {
      borderRadius: `0 4px 4px 0`,
    },
    // "&:hover": {
    //   transition: "border-color 0.15s ease-in-out",
    //   border: "1px solid rgba(0, 0, 0, .2)",
    // },
  },
}))(({ children, ...rest }) => (
  <ToggleButtonGroup exclusive {...rest}>
    {children}
  </ToggleButtonGroup>
));
