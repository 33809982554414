import axios from "axios";
import AuthorizeService from "../auth/AuthorizeService";
import { selectedImages } from "../selectors/albums";
import { searchAPIUrl } from "../selectors/settings";
import {
  ALBUMS_ERROR,
  ALBUMS_LOADED,
  ALBUMS_LOADING,
  ALBUMS_RESET,
  ALBUMS_IMAGE_SELECTED,
  ALBUMS_IMAGE_DESELECTED,
  ALBUMS_IMAGE_REARRANGE,
  ALBUMS_SELECTED_IMAGES_RESET,
  ALBUMS_IMAGES_SELECTED,
} from "./types";

export const createCollection = ({ title, description, icon, banner, onDone, onError }) => {
  return async (dispatch, getState) => {
    try {
      const theSelectedImages = selectedImages(getState()).reduce((accumulated, image) => {
        return [...accumulated, image.id];
      }, []);

      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      await axios.post(
        `api/image/${user.sub}/createCollection`,
        {
          artistId: user.sub,
          title,
          description,
          icon,
          banner,
          images: theSelectedImages,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      onDone();
    } catch (err) {
      console.log("createCollection ", err);
      onError(err);
    }
  };
};

export const updateCollection = ({ collectionId, title, description, icon, banner, onDone, onError }) => {

  return async (dispatch, getState) => {
    try {
      const theSelectedImages = selectedImages(getState()).reduce((accumulated, image) => {
        return [...accumulated, image.id];
      }, []);

      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      await axios.put(
        `api/image/${user.sub}/updateCollection`,
        {
          artistId: user.sub,
          collectionId,
          title,
          description,
          icon,
          banner,
          images: theSelectedImages,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      onDone();
    } catch (err) {
      console.log("updateCollection ", err);
      onError();
    }
  };
};

export const deleteCollection = ({ collectionId, onDone, onError }) => {
  return async (dispatch, getState) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      await axios.delete(
        `api/image/${user.sub}/collection/${collectionId}/DeleteCollection`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      onDone();
    } catch (err) {
      //console.log(err);
      onError();
    }
  };
};

//please impletement lazy loading for albums
export const getAlbums = (artistId) => {
  return async (dispatch, getState) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      dispatch(setLoading());

      const res = await axios.get(
        `api/Image/${artistId}/GetArtistCollections`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: ALBUMS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ALBUMS_ERROR,
        payload: err.response,
      });
    }
  };
};

export const setLoading = () => {
  return { type: ALBUMS_LOADING };
};

export const resetAlbums = () => {
  return (dispatch) =>
    dispatch({
      type: ALBUMS_RESET,
    });
};

export const onImageSelectedForCreateAlbum = (imageId) => {
  return {
    type: ALBUMS_IMAGE_SELECTED,
    payload: imageId,
  };
};
export const onImageDeselectedForCreateAlbum = (imageId) => {
  return {
    type: ALBUMS_IMAGE_DESELECTED,
    payload: imageId,
  };
};
export const onImageRearrangedForCreateAlbum = (
  removedIndex,
  addedIndex,
  imageId
) => {
  return {
    type: ALBUMS_IMAGE_REARRANGE,
    payload: { removedIndex, addedIndex, imageId },
  };
};

export const resetSelectedImages = () => {
  return { type: ALBUMS_SELECTED_IMAGES_RESET };
};
export const onSelectedImages = (imageIds) => {
  return {
    type: ALBUMS_IMAGES_SELECTED,
    payload: imageIds,
  };
};
