import uuid from "uuid";

import { SET_ALERT, REMOVE_ALERT } from "./types";

// Set Alert
export const setAlert = (msg, type, timeout = 6500) => {
  return (dispatch, getState) => {
    const { alertReducer: preexistingAlerts } = getState();

    // remove preexisting with same message
    let removedPreexistingAlert = false;
    preexistingAlerts.forEach((alert) => {
      const { msg: msgLhs, id } = alert;
      if (msgLhs === msg) {
        removedPreexistingAlert = true;
        dispatch({ type: REMOVE_ALERT, payload: id });
      }
    });

    const id = uuid.v4();
    const payloadDTO = { msg: msg, type: type, id: id };

    if (removedPreexistingAlert) {
      setTimeout(() => {
        dispatch({
          type: SET_ALERT,
          payload: payloadDTO,
        });
        setTimeout(
          () => dispatch({ type: REMOVE_ALERT, payload: id }),
          timeout
        );
      }, 300);
    } else {
      dispatch({
        type: SET_ALERT,
        payload: payloadDTO,
      });
      setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
    }
  };
};
