import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { getUserOnboarded } from "../../../actions/account";
import { PikchaModal, PikchaModalHeader } from "../../../components/baseComponents";
import "./OnboardingModal.css";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { DEFAULT_PREFILL } from "../../../config/currency_measurement";
import { withOrientationChange } from "react-device-detect";
import isMobile from "../../../components/Responsive/isMobile";
import {
  colors
} from "@pikcha/typography";
import {CURRENCY_MEASUREMENT} from "../../../config/currency_measurement";
import useGeoLocation from "react-ipgeolocation";

let OnboardingModal = ({
  email,
  account,
  handleClickClose,
  open,
  initOnboardingFlow,
  setAlert,
  userAccountName,
  setUserAccountName,
  setActiveStep,
  isLandscape,
  isPortrait,
  getUserOnboarded,
  ...rest
}) => {
  const [showInitialStep, setShowInitialStep] = useState(true);
  const [currency, setCurrency] = useState("US");
  const [currentPage, setCurrentPage] = useState(0);
  const [userCountry, setUserCountry] = useState(DEFAULT_PREFILL.country);
  const [userCurrency, setUserCurrency] = useState(DEFAULT_PREFILL.currency_code);
  const [userLanguage, setUserLanguage] = useState(DEFAULT_PREFILL.language_long);
  const [userMeasureUnit, setUserMeasureUnit] = useState(DEFAULT_PREFILL.measure_unit);
  const [stepOneAccountName, setStepOneAccountName] = useState(userAccountName);
  const [userHandle, setUserHandle] = useState("loading user default value");
  const scrollRef = useRef(null);
  const [scrollToTop, setScrollToTop] = useState(true);
  const [currencyLabels, setCurrencyLabels] = useState([]);
  const [languageLabels, setLanguageLabels] = useState([]);
  const location = useGeoLocation().country;

  const isOnMobile = isMobile();

  useEffect(() => {
    const maybeUserHandle = () => {
      if (account.user && account.user.userHandle && account.user.userHandle !== "") {
        if (account.user.userHandle.includes("@")) {
          return account.user.userHandle.replace("@", "");
        }
        return account.user.userHandle;
      }
      if (account.user && account.user.fName) {
        return account.user.fName.toLowerCase() + "." + account.user.lName.toLowerCase();
      }
      return "";
    };
    setUserHandle(maybeUserHandle(),
    );
  }, [account.user]);

  useEffect(() => {
    let tempCurrencyLabels = [];
    let tempLanguageLabels = [];
    CURRENCY_MEASUREMENT.forEach(item => {
      tempCurrencyLabels.push({ label: item.currency_code });
      item.language_long && tempLanguageLabels.push({ label: item.language_long });
      if (item.country_code === location) {
        setUserCountry(item.country);
        setUserCurrency(item.currency_code);
        setUserLanguage(item.language_long);
        setUserMeasureUnit(item.measure_unit);
      }
    });
    setCurrencyLabels(tempCurrencyLabels);
    setLanguageLabels(tempLanguageLabels);
  }, [location]);

  const handleInitialStep = (state) => {
    setShowInitialStep(state);
  };

  const handleCurrency = ({ countryCode }) => {
    setCurrency(countryCode);
  };

  const handleCurrentPage = (option) => {
    setCurrentPage(option);
    setScrollToTop(!scrollToTop);
  };

  const renderHeadPaginations = useCallback(() => {
    const paginations = [0,1,2,3,4]
    return (
      <div 
        className="flex full-width"
        style={{
          justifyContent: "center"
        }}
      >
        {paginations.map((pagination) => 
          <div
            style={{
              width: 8,
              height: 8,
              backgroundColor: pagination === currentPage ? colors.Primary.primary50 : colors.Neutral.ink90,
              borderRadius: 4,
              margin: "0 1em"
            }}
          />
        )}
      </div>
    )
  }, [currentPage])

  return (
    <PikchaModal
      fullWidth={false}
      aria-label="Onboarding Modal"
      hasCustomHeader
      hideCloseButton
      minHeight={440}
      disableBackdropClick
      scrollToTop={scrollToTop}
      disableEscapeKeyDown
      onClose={handleClickClose}
      open={open}
      paperStyle={{
        height: isOnMobile ? "93%" : "45%",
        width: isOnMobile ? "100%" : "50%",
        minWidth: isOnMobile ? undefined : 550,
        minHeight: isOnMobile ? undefined : 500,
        maxWidth: isOnMobile ? undefined : 550,
        maxHeight: isOnMobile ? undefined : 600,
        borderRadius: isOnMobile ? "6px 6px 0 0" : "6px"
      }}
    >
      <PikchaModalHeader>
        {renderHeadPaginations()}
      </PikchaModalHeader>
      <div 
        ref={scrollRef} 
        className="flex-grow-1 flex-stretch"
        style={{
          padding:"8 24"
        }}
      >
        {showInitialStep ? (
          <Step0
            token={account?.token}
            user={account?.user}
            setAlert={setAlert}
            setUserAccountName={setStepOneAccountName}
            handleInitialStep={handleInitialStep}
            handleCurrentPage={handleCurrentPage}
            userHandle={userHandle}
            setUserHandle={setUserHandle}
          />
        ) : (
          <>
            {(currentPage === 1) && (
              <Step1
                currency={currency}
                handleCurrency={handleCurrency}
                user={account.user}
                setAlert={setAlert}
                userAccountName={userAccountName}
                setUserAccountName={setStepOneAccountName}
                handleCurrentPage={handleCurrentPage}
                setActiveStep={setActiveStep}
                userCountry={userCountry}
                setUserCountry={setUserCountry}
                userCurrency={userCurrency}
                setUserCurrency={setUserCurrency}
                userLanguage={userLanguage}
                setUserLanguage={setUserLanguage}
                userMeasureUnit={userMeasureUnit}
                setUserMeasureUnit={setUserMeasureUnit}
                isPortrait={isPortrait}
                currencyLabels={currencyLabels}
                languageLabels={languageLabels}
                {...rest}
              />
            )}
            {(currentPage === 2) && (
              <Step2
                userAccountName={userAccountName}
                userHandle={userHandle}
                handleCurrentPage={handleCurrentPage}
                setActiveStep={setActiveStep}
                accountName={stepOneAccountName}
                setUserAccountName={setUserAccountName}
                user={account.user}
                userCountry={userCountry}
                userCurrency={userCurrency}
                userLanguage={userLanguage}
                userMeasureUnit={userMeasureUnit}
                getUserOnboarded={getUserOnboarded}
                setAlert={setAlert}
                {...rest}
              />
            )}
            {(currentPage === 3) && (
              <Step3
                handleCurrentPage={handleCurrentPage}
                handleClickClose={handleClickClose}
                setAlert={setAlert}
              />
            )}
            {(currentPage === 4) && (
              <Step4
                handleClickClose={handleClickClose}
              />
            )}
          </>
        )}
      </div>
    </PikchaModal>
  );
};

OnboardingModal = withOrientationChange(OnboardingModal);

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  profile: state.profileReducer,
});

export default connect(mapStateToProps, {
  setAlert,
  getUserOnboarded
})(OnboardingModal);
