import React, { useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isTheUserAnArtist } from "../../../selectors/account";
import isMobile from "../../../components/Responsive/isMobile";
import PortraitIcon from "@material-ui/icons/AccountBox";
import LocalAtmIcon from "../../../assets/images/AccountNav/PaymentsIcon.svg";
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded';
import { useLocation } from 'react-router-dom'

const NavigationMobile = ({ isTheUserAnArtist, history }) => {

  const PaymentImage = () => {
    return (
      <img style={{ width: '27px', height: '27px', marginRight: '0.10em' }} src={LocalAtmIcon} alt="Payment Icon" />
    )
  }

  const data = isTheUserAnArtist ? [
    {
      id: 1,
      link: "/account/details",
      text: 'Account Details',
      icon: <PortraitIcon />
    },
    {
      id: 2,
      link: "/account/orderHistory",
      text: 'Order history',
      icon: <ShoppingBasketRoundedIcon />
    },
    {
      id: 3,
      link: "/account/sales",
      text: 'Sales and Payments',
      icon: <PaymentImage />
    },
    {
      id: 4,
      link: "/account/settings",
      text: 'Settings',
      icon: <SettingsRoundedIcon />
    }
  ] : [
    {
      id: 1,
      link: "/account/details",
      text: 'Account Details',
      icon: <PortraitIcon />
    },
    {
      id: 2,
      link: "/account/orderHistory",
      text: 'Order history',
      icon: <ShoppingBasketRoundedIcon />
    },
    {
      id: 3,
      link: "/account/settings",
      text: 'Settings',
      icon: <SettingsRoundedIcon />
    }
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const isOnMobile = isMobile();

  const handleCallToRouter = (event) => {
    history.push(event.link);
    setSelectedOption(event);
  };

  const location = useLocation();
  const currentLocation = location.pathname

  const placeHolderValue = () => {
    if(isTheUserAnArtist) {
      switch (currentLocation) {
        case '/account/details':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[0].icon}
              <span style={{ marginLeft: 16 }}>{data[0].text}</span>
            </div>
          )
        case '/account/orderHistory':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[1].icon}
              <span style={{ marginLeft: 16 }}>{data[1].text}</span>
            </div>
          )
        case '/account/sales':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[2].icon}
              <span style={{ marginLeft: 16 }}>{data[2].text}</span>
            </div>
          )
        case '/account/settings':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[3].icon}
              <span style={{ marginLeft: 16 }}>{data[3].text}</span>
            </div>
          )
      }
    } else {
      switch (currentLocation) {
        case '/account/details':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[0].icon}
              <span style={{ marginLeft: 16 }}>{data[0].text}</span>
            </div>
          )
        case '/account/orderHistory':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[1].icon}
              <span style={{ marginLeft: 16 }}>{data[1].text}</span>
            </div>
          )
        case '/account/settings':
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data[2].icon}
              <span style={{ marginLeft: 16 }}>{data[2].text}</span>
            </div>
          )
      }
    }
  }
  return (
    <div
      style={{
        paddingLeft: isOnMobile && "2em",
        paddingRight: isOnMobile && "2em",
        paddingTop: "6em"
      }}
    >
      <Select
        placeholder={placeHolderValue()}
        value={selectedOption}
        options={data}
        onChange={handleCallToRouter}
        components={{
          IndicatorSeparator: null,
        }}
        styles={{
          container: (provided, { isFocused, isDisabled }) => ({
            ...provided,
            width: '100%',
            height: '40px',
            padding: 0,
            fontSize: '16px',
            fontWeight: '400',
            background: isDisabled
              ? '#F2F4F8'
              : '#fff',
            borderRadius: isDisabled ? '4px' : '4px 4px 0px 0px',
            borderColor: isFocused ? '#DBE0E4' : null,
            color: isDisabled ? '#A2A9B0' : '#343A3F',
          }),
          control: (provided, { isFocused, isDisabled }) => ({
            ...provided,
            minHeight: '40px !important',
            padding: 0,
            border: '1px solid #DBE0E4',
            borderColor: isFocused
              ? '#DBE0E4'
              : isDisabled
                ? '#DBE0E4'
                : "#DBE0E4",
            boxShadow: "none",
            cursor: "pointer",
            backgroundColor: "transparent",
            "&:hover": {
              borderColor: isDisabled
                ? '#DBE0E4'
                : isFocused
                  ? '#DBE0E4'
                  : 'black',
            },
            borderRadius: isDisabled ?
              ' 4px' : isFocused ? '4px 4px 0px 0px' : '4px'
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            cursor: "default",
            marginRight: '0.5em'
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: 0,
          }),
          input: (provided) => ({
            ...provided,
            marginLeft: 14,
          }),
          menuList: () => ({
            cursor: "default",
            width: '100%',
            borderRadius: '4px 4px 0px 0px',
            maxHeight: "19rem",
            paddingBottom: 4,
            boxSizing: "border-box",
            borderWidth: '1',
            marginRight: '1em',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
            alignContent: 'space-between',
          }),
          menu: (provided, { isFocused, isDisabled }) => ({
            ...provided,
            maxWidth: '100%',
            minWidth: '240px',
            marginTop: '0.75px',
            borderRadius: isFocused ? '0px 0px 4px 4px' : isDisabled ? '0px 0px 0px 0px' : '',
            paddingBottom: '0.5em',
            zIndex: 999,
            borderWidth: '1 !important',
          }),
          placeholder: (provided, { isDisabled }) => ({
            ...provided,
            color: isDisabled && '#A2A9B0',
            fontSize: '16px !important',
          }),
          singleValue: (provided, { isDisabled }) => ({
            ...provided,
            marginLeft: 16,
            userSelect: isDisabled ? "none" : undefined,
            color: isDisabled && '#A2A9B0',
            textTransform: "capitalize",
          }),
          option: (provided, { isFocused, isSelected }) => ({
            ...provided,

            cursor: "pointer",
            fontSize: '16px',
            textTransform: "capitalize",
            margin: '0 !important',
            color: '#343A3F',
            marginLeft: '0em !important',
            backgroundColor: isFocused ? '#FFFFFF' : null,
            "&:hover": {
              backgroundColor: isFocused ? '#F2F4F8' : '#fff',
              fontWeight: isFocused
                ? '600'
                : '400',
            },
            ":active": {
              ...provided[":active"],
              color: isSelected ? '#343A3F' : null,
              backgroundColor: isSelected ? 'grey' : null,

            },
          }),
        }}
        getOptionLabel={e => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{e.icon}</span>
            <span style={{ marginLeft: 16 }}>{e.text}</span>
          </div>
        )}
      />
    </div>
  )
}
const mapStateToProps = (state) => ({
  isTheUserAnArtist: isTheUserAnArtist(state),
});

export default connect(mapStateToProps, {})(withRouter(NavigationMobile));