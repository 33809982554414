import React from "react";
import { useTheme } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ICON_SIZE_DEFAULT } from "../../helpers/constants";
import { PikchaModal } from "../baseComponents";
import { TypographyButton, TYPOGRAPHY_BUTTON_TYPE } from "@pikcha/typography";

export default ({ open, cancelRefreshAllItems, doRefreshAllItems }) => {
  const { palette, typography } = useTheme();

  return (
    <PikchaModal
      open={open}
      onClose={cancelRefreshAllItems}
      paperStyle={{
        overflow: "hidden",
        minHeight: 0,
        height: 280,
        maxHeight: 280,
        maxWidth: 440,
      }}
    >
      <div
        style={{
          marginLeft: "1em",
          marginRight: "1em",
          marginTop: "4em",
          marginBottom: "2em",
          textAlign: "center",
        }}
      >
        <RefreshIcon
          style={{
            ...ICON_SIZE_DEFAULT,
            color: palette.purple.medium,
          }}
        />
        <p
          style={{
            marginTop: "1em",
            fontSize: typography.small,
            fontWeight: typography.fontWeightSemiMedium,
            color: palette.text.primary,
          }}
        >
          Are you sure you want to refresh your checkout time?
        </p>
        <br />
        <p
          style={{
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightSemiMedium,
            color: palette.text.secondary,
          }}
        >
          We only allow you to do this once
        </p>
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "2em",
          marginRight: "2em",
          justifyContent: "center"
        }}
      >
        <TypographyButton
          component={TYPOGRAPHY_BUTTON_TYPE.Outline}
          onClick={(e) => cancelRefreshAllItems()}
        >
          Cancel
        </TypographyButton>
        <TypographyButton
          onClick={(e) => doRefreshAllItems()}
          buttonStyle={{
            marginLeft: "1em",
          }}
        >
          Refresh Time
        </TypographyButton>
      </div>
    </PikchaModal>
  );
};
