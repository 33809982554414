import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import ViewingAlbum from "../containers/modals/ViewingAlbum/ViewingAlbum";
import { PikchaLoader } from "../components/baseComponents";
import Desktop from "../components/Responsive/Desktop";
import Tablet from "../components/Responsive/Tablet";
import Mobile from "../components/Responsive/Mobile";

import { getAlbumFriendlyUrl } from "../actions/friendlyUrlImage";

const AlbumDetails = ({ getAlbumFriendlyUrl, album, account }) => {
  const image = album.albums.length > 0 ? album.albums[0] : null;
  const loading = album.loading;

  const [creatable, setCreatable] = useState(false);

  useEffect(() => {
    getAlbumFriendlyUrl(window.location.pathname);
  }, []);

  useEffect(() => {
      if(album.albums[0] && album.albums[0].pikchaUser && account.user){
        if(album.albums[0].pikchaUser.id === account.user.id){
            setCreatable(true)
        } else {
            setCreatable(false)
        }
      }
  }, [album.albums[0], account])

  return (
    <Fragment>
      <Helmet>
        <title>Pikcha Album</title>
      </Helmet>
      <div
        style={{
          width: "100%",
          marginTop: "64px",
          display: "flex",
          justifyContent: "center"
        }}
      >
        {image && (
          <>
            <Desktop>
              <ViewingAlbum
                open={true}
                onClose={()=>{}}
                albumItem={image}
                creatable={creatable}
                onEditAlbumFromViewing={()=>{}}
              />
            </Desktop>
            <Tablet>
              <ViewingAlbum                 
                open={true}
                onClose={()=>{}}
                albumItem={image}
                creatable={creatable}
                onEditAlbumFromViewing={()=>{}} 
              />
            </Tablet>
            <Mobile>
              <ViewingAlbum                 
                open={true}
                onClose={()=>{}}
                albumItem={image}
                creatable={creatable}
                onEditAlbumFromViewing={()=>{}} 
              />
            </Mobile>
          </>
        )}
        {loading && (
          <div
            style={{
              marginTop: "128px",
              display: "flex",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <PikchaLoader />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  album: state.friendlyUrlAlbumReducer
});

export default connect(mapStateToProps, {
    getAlbumFriendlyUrl,
})(AlbumDetails);
