import React, { useState } from "react";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PikchaCloseButton from "../../../components/baseComponents/buttons/PikchaCloseButton";
import "./ExpiredItems.css";
import { PikchaButton, PikchaLoader, } from "../../baseComponents";
import { IntlCurrencyFormatter } from "../../NumberFormatters/IntlCurrencyFormatter";
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE
} from "@pikcha/typography"
import CartItems from "../CartItems";

export default ({
  shoppingCartItem,
  deleteItem,
  addBackToCart
}) => {
  const [isAddToCartLoading, setIsAddToCartLoading] = useState(false);
  const {
    id: shoppingItemId,
    imageTitle,
    sellerNickName,
    sellerUserHandle,
    type,
    border,
    frame,
    wrap,
    material,
    size,
    totalPricePresentment,
    isExpired,
    galleryCardColor,
    imageRender
  } = shoppingCartItem;

  const addToCart = () => {
    setIsAddToCartLoading(true);
    addBackToCart(shoppingCartItem, () => {
      setIsAddToCartLoading(false);
    });
  };

  let expectedProductNumber = shoppingCartItem.productNumber;

  const [displayDeleteModal, setDisplayDeleteModal] = useState();

  const onWantsToDelete = () => {
    setDisplayDeleteModal(true);
  };
  const onCancelDelete = () => {
    setDisplayDeleteModal(false);
  };
  const doDeleteItem = () => {
    deleteItem(shoppingItemId);
    setDisplayDeleteModal(false);
  };

  return (
    <>
      {displayDeleteModal && (
        <div style={{ padding: "0.5em", }}>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Medium}
            color="neutral/grey50"
            style={{
              marginTop: "2em",
              textAlign: "center",
            }}
          >
            Are you sure that you want to remove this item?
          </TypographyBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "2em 1em 1em"
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                maxWidth: 250,
              }}
            >
              <TypographyButton
                component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                onClick={(e) => onCancelDelete()}
              >
                Cancel
              </TypographyButton>
              <TypographyButton
                component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                onClick={(e) => doDeleteItem()}
                buttonStyle={{ marginLeft: "1em" }}
              >
                Remove
              </TypographyButton>
            </div>
          </div>
        </div>
      )}
      {!displayDeleteModal && (
        <div
          className="noselect"
          style={{
            borderRadius: 6,
            padding: "0.5em",
            marginBottom: "0.5em",
            cursor: "pointer"
          }}
        >
          <CartItems
            imageRender={imageRender}
            imageTitle={imageTitle}
            userHandle={sellerUserHandle}
            nickName={sellerNickName}
            type={type}
            material={material}
            size={size}
            border={border}
            frame={frame}
            wrap={wrap}
            galleryCardColor={galleryCardColor}
            productNumber={expectedProductNumber}
            totalEditions={shoppingCartItem.totalEditions}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: '1em'
            }}
          >
            {(isExpired && isAddToCartLoading)
              ? (
                <div
                  style={{
                    position: "absolute",
                    left: "50%",
                    paddingTop: 20,
                  }}
                >
                  <PikchaLoader />
                </div>
              ) : (
                <>
                  {isExpired && !isAddToCartLoading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <PikchaButton
                        fullWidth
                        action="octonary"
                        onClick={(e) => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behaviour: "smooth"
                          });
                          addToCart();
                        }}
                      >
                        <ShoppingCartIcon
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: "0.5em",
                            marginTop: "0.15em",
                          }}
                        />
                        Add
                      </PikchaButton>

                      <PikchaButton
                        action="octonary"
                        style={{ marginLeft: "1em" }}
                      >
                        <PikchaCloseButton
                          onClick={onWantsToDelete}
                          smallVariant
                          style={{
                            width: 20,
                            height: 20,
                            padding: '5px 8px 8px 16px'
                          }}
                        />
                      </PikchaButton>
                    </div>
                  )}
                </>
              )
            }
            <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                style={{
                  backgroundColor: '#F2F4F8',
                  padding: '0.4em 1em',
                  marginLeft: '1em',
                  borderRadius: '4px'
                }}
              >
              <IntlCurrencyFormatter value={+totalPricePresentment} />
            </TypographyBody>
          </div>
        </div>
      )}
    </>
  );
};
