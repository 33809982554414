import React, { useState } from 'react'
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlined from '@material-ui/icons/KeyboardArrowUpOutlined'

const ReadMore = ({ children, limit }) => {
  const [isReadMoreShown, setReadMoreShown] = useState(false)
  const toggleBtn = () => {
    setReadMoreShown(prevState => !prevState)
  }
  
  return (
    <div style={{
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      // alignItems: 'center'
    }}
      className='read-more-read-less'
    >
      <div>
        {isReadMoreShown ? children : children?.substr(0, limit)}
      </div>
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }}
      >
        {children.length > 100 &&
          (
            isReadMoreShown ?
              <KeyboardArrowUpOutlined
                style={{ cursor: 'pointer' }}
                onClick={toggleBtn}
              /> :
              <KeyboardArrowDownOutlined
                style={{ cursor: 'pointer' }}
                onClick={toggleBtn}
              />
          )
        }
      </div>
    </div>
  )
}

export default ReadMore