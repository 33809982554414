import React, { useState } from "react";
import Select from "react-select";
import { useTheme } from "@material-ui/core/styles";
import TypographyDropdownMenuList from "./TypographyDropdownMenuList";
import "./PikchaDropdown.css";
import TypographyLabelWrapper from "./TypographyLabelWrapper";
export default ({
  label,
  className = "",
  style = {},
  fullWidth,
  compactListSize,
  noTopMargin,
  noHelperClass,
  required,
  classNameOuter,
  disabled,
  placeholder = "",
  isMulti = false,
  closeMenuOnSelect = true,
  valueColourDisabled,
  FieldLabelProps = {},
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { palette } = useTheme();
  return (
    <div
      className={`${fullWidth ? "full-width" : ""} ${className}`}
      style={style}
      onFocusCapture={() => setIsFocused(true)}
      onBlurCapture={() => setIsFocused(false)}
    >
      <TypographyLabelWrapper
        className={className}
        required={required}
        disabled={disabled}
        {...FieldLabelProps}
        style={{
          color: '#343A3F !important',
        }}
        labelStyle={{ fontWeight: 'bold' }}
        label={label}
        noHelperClass={noHelperClass || noTopMargin}
        noFieldSet
      >
        <Select
          isMulti={isMulti}
          closeMenuOnSelect={closeMenuOnSelect}
          className="Dropdown-innerContainer"
          isDisabled={disabled ? disabled : false}
          components={{
            IndicatorSeparator: null,
            MenuList: (props) => <TypographyDropdownMenuList {...props} />,
          }}
          aria-label="Select"
          placeholder={placeholder}
          noOptionsMessage={() => ""}
          styles={{
            container: (provided, { isFocused, isDisabled }) => ({
              ...provided,
              width: '100%',
              height: '40px',
              padding: 0,
              fontSize: '16px',
              fontWeight: '400',
              background: isDisabled
                ? '#F2F4F8'
                : '#fff',
              borderRadius: isDisabled ? '4px' : '4px 4px 0px 0px',
              borderColor: isFocused ? '#DBE0E4' : null,
              color: isDisabled ? '#A2A9B0' : '#343A3F',
              marginTop:'0.25em',
            }),
            control: (provided, { isFocused, isDisabled }) => ({
              ...provided,
              minHeight: '40px !important',
              padding: 0,
              border: '1px solid #DBE0E4',
              borderColor: isFocused
                ? '#DBE0E4'
                : isDisabled
                  ? '#DBE0E4'
                  : "#DBE0E4",
              boxShadow: "none",
              cursor: "pointer",
              backgroundColor: "transparent",
              "&:hover": {
                borderColor: isDisabled
                  ? '#DBE0E4'
                  : isFocused
                    ? '#DBE0E4'
                    : palette.text.primary,
              },
              borderRadius: isDisabled ?
                ' 4px' : isFocused ? '4px 4px 0px 0px' : '4px'
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              cursor: "default",
              marginRight: '0.5em'
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: 0,
            }),
            input: (provided) => ({
              ...provided,
              marginLeft: 14,
            }),
            menuList: () => ({
              cursor: "default",
              width: '100%',
              borderRadius: '4px 4px 0px 0px',
              maxHeight: !compactListSize ? "19rem" : "12rem",
              paddingBottom: 4,
              boxSizing: "border-box",
              borderWidth: '1',
              paddingRight: '1em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-between',
              alignContent: 'space-between',
            }),
            menu: (provided, { isFocused, isDisabled }) => ({
              ...provided,
              maxWidth: '100%',
              marginTop: '0.75px',
              marginLeft: '1px',
              borderRadius: isFocused ? '0px 0px 4px 4px' : isDisabled ? '0px 0px 0px 0px' : '',
              paddingBottom: '0.5em',
              zIndex: 999,
              borderWidth: '1 !important',
            }),
            placeholder: (provided, { isDisabled }) => ({
              ...provided,
              paddingLeft: 14,
              color: isDisabled && '#A2A9B0',
              fontSize: '16px !important',
            }),
            singleValue: (provided, { isDisabled }) => ({
              ...provided,
              paddingLeft:"12px",
              userSelect: isDisabled ? "none" : undefined,
              color: isDisabled && '#A2A9B0',
              textTransform: "capitalize",
            }),
            option: (provided, { isFocused, isSelected }) => ({
              ...provided,
              cursor: "pointer",
              fontSize: '16px',
              paddingLeft: 14,
              textTransform: "capitalize",
              margin: '0 !important',
              color: '#343A3F',
              paddingRight: '0',
              backgroundColor: isFocused ? '#FFFFFF' : null,
              borderBottom: '1px solid #DBE0E4',
              ":last-child": {
                borderBottom: 'none',
              },
              "&:hover": {
                fontWeight: isFocused
                  ? '600'
                  : '400',
                backgroundColor: isFocused ? '#F2F4F8' : '#fff'
              },
              ":active": {
                ...provided[":active"],
                color: isSelected ? '#343A3F' : null,
                backgroundColor: isSelected ?'#F2F4F8' : null,
              },
            }),
          }}
          {...rest}
        />
      </TypographyLabelWrapper>
    </div>
  );
};
