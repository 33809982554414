import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

export const LoginNavButton = withStyles((theme) => ({
  root: {
    color: 'rgba(14,19,24,.7) !important',
    fontWeight: 600,
    boxShadow: 'none',
    textTransform: 'none',
    textDecoration: 'none',
    fontSize: 15,
    marginRight: 4,
    padding: '6px 12px',
    lineHeight: 1.34,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    backgroundImage: 'none !Important',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
}))(Button);
