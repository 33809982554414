import { combineReducers } from 'redux';
import navReducer from './navReducer';
import albumsReducer from './albumsReducer';
import galleryReducer from './galleryReducer';
import gallerySearchReducer from './gallerySearchReducer';
import galleryFollowingReducer from './galleryFollowingReducer';
import accountReducer from './accountReducer';
import authReducer from './authReducer';
import routesReducer from './routesReducer';
import alertReducer from './alertReducer';
import notificationReducer from './notificationReducer';
import timerReducer from './timerReducer';
import modalReducer from './modalReducer';
import savedImagesReducer from './gallerySavedReducer';
import pikcha100Reducer from './pikcha100Reducer';
import artist100Reducer from './artist100Reducer';
import sideBarReducer from './sideDrawerReducer';
import sideDrawerReducer from './sideDrawerReducer';
import profileReducer from './profileReducer';
import relatedImagesReducer from './relatedImagesReducer';
import relatedAlbumsReducer from './relatedAlbumsReducer';
import searchReducer from './searchReducer';
import searchArtistsReducer from './searchArtistsReducer';
import searchAlbumsReducer from './searchAlbumsReducer';
import settingsReducer from './settingsReducer';
import accountNotificationsReducer from './accountNotificationsReducer';
import shoppingCartReducer from './shoppingCartReducer';
import inviteCodeReducer from './inviteCodeReducer';
import signatureReducer from './signatureReducer';
import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import authModalReducer from './authModalReducer';
import artistRegistrationFormReducer from './artistRegistrationFormReducer';
import onboardingReducer from './onboardingReducer';
import ageRestrictionReducer from './ageRestrictionReducer';
import carouselReducer from './carouselReducer';
import myCollectionReducer from './myCollectionReducer';
import followingReducer from './followingReducer';
import friendlyUrlImageReducer from './friendlyUrlImageReducer';
import friendlyUrlAlbumReducer from './friendlyUrlAlbumReducer';
import addToAlbumImageReducer from './addToAlbumImageReducer';
import cropperReducer from './cropperReducer';
import locationReducer from './locationReducer';
import userPreferencesReducer from "./userPreferencesReducer";

export default combineReducers({
  albumsReducer,
  galleryReducer,
  gallerySearchReducer,
  galleryFollowingReducer,
  accountReducer,
  navReducer,
  authReducer,
  alertReducer,
  modalReducer,
  pikcha100Reducer,
  artist100Reducer,
  timerReducer,
  sideBarReducer,
  profileReducer,
  relatedImagesReducer,
  relatedAlbumsReducer,
  searchReducer,
  accountNotificationsReducer,
  searchArtistsReducer,
  searchAlbumsReducer,
  settingsReducer,
  shoppingCartReducer,
  notificationReducer,
  inviteCodeReducer,
  signatureReducer,
  loginReducer,
  registerReducer,
  authModalReducer,
  savedImagesReducer,
  sideDrawerReducer,
  artistRegistrationFormReducer,
  onboardingReducer,
  ageRestrictionReducer,
  carouselReducer,
  myCollectionReducer,
  followingReducer,
  friendlyUrlImageReducer,
  friendlyUrlAlbumReducer,
  addToAlbumImageReducer,
  routesReducer,
  cropperReducer,
  locationReducer,
  userPreferencesReducer
});
