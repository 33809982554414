import React from "react";
import PhoneInput from "react-phone-input-2";
import { useTheme } from "@material-ui/core/styles";
import { PikchaTextField } from "../baseComponents";
import isMobile from "../Responsive/isMobile";
import "react-phone-input-2/lib/style.css";

export default ({
  fullName,
  onFullNameChanged,
  email,
  onEmailChanged,
  phoneNumber,
  onPhoneNumberChanged,
  InvalidEmail
}) => {
  const { palette, typography } = useTheme();
  const isOnMobile = isMobile();

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <PikchaTextField
          required
          isFullWidth
          label="Full Name"
          type="text"
          name="fName"
          className={!fullName ? "invalid" : ""}
          onChange={onFullNameChanged}
          value={fullName}
          pattern=".{0,225}"
          title="Max 225 characters"
        />
      </div>
      <div
        style={{
          ...(isOnMobile
            ? {}
            : {
                display: "flex",
              }),
          marginTop: "2em",
        }}
      >
        <div 
          style={{
            width: "100%"
          }}
        >
          <PikchaTextField
            required
            isFullWidth
            label="Email"
            className={!email ? "invalid" : ""}
            type="text"
            name="email"
            value={email}
            onChange={onEmailChanged}
            pattern=".{0,225}"
            title="Max 225 characters"
          />
          {InvalidEmail && (
            <div
              style={{
                color: palette.error.main,
                fontWeight: typography.fontWeightMedium,
                fontFamily: typography.fontFamilyMontserrat,
                fontSize: typography.smallest
              }}
            >
              Please input correct email address!
            </div>
          )}
        </div>
        <div
          style={{
            ...(isOnMobile
              ? {
                  marginTop: "1.5em",
                  width: "100%",
                  marginBottom: "1em",
                }
              : {
                  marginLeft: "1em",
                  width: "100%",
                }),
          }}
        >
          <p
            style={{
              marginTop: "-1.3em",
              fontFamily: typography.fontFamilyMontserrat,
              fontWeight: typography.fontWeightMedium,
              fontSize: typography.smallest,
              color: palette.grey.mediumDark,
            }}
          >
            Phone Number
            <span
              style={{
                color: palette.error.main,
                fontWeight: typography.fontWeightMedium,
                fontFamily: typography.fontFamilyMontserrat,
              }}
            >
              *
            </span>
          </p>
          <PhoneInput
            preferredCountries = {['au','uk','us','ca','nz','de', 'fr']}  // AUS, UK, USA, CAD, NZ, GER, FRA
            preserveOrder={['preferredCountries']}
            defaultMask = {['... ... ... ... ..']}
            alwaysDefaultMask ="true"
            placeholder=""
            country="us"
            value={phoneNumber}
            onChange={onPhoneNumberChanged}
            enableSearch="true"
                dropdownStyle ={{
                  ...(isOnMobile
                    ? {
                        width: "275px"
                      }
                    : {
                        width: "209px"
                      }),
                }}
                searchStyle={{
                  width: "85%",
                }}
            inputStyle={{
              width: "100%",
              borderWidth: 2,
              fontSize: typography.small,
              fontWeight: typography.fontWeightSemiMedium,
              borderRadius: "6px",
            }}
            buttonStyle={{
              borderWidth: 2,
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
            }}
          />
        </div>
      </div>
    </div>
  );
};
