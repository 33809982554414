import React, { useState } from "react";
import { connect } from "react-redux";

import { useTheme } from "@material-ui/core/styles";

import { searchAPIUrl } from "../../selectors/settings";

import { PikchaTextField } from "../baseComponents/PikchaTextField";

import LocationAutocomplete from "./LocationAutocomplete";

import "./LocationPicker.css";

const LocationPicker = ({
  searchAPIUrl,
  location,
  onChange,
  label,
  disabled,
  startAdornment,
  value,
  ...rest
                        }) => {
  const [address, setAddress] = useState(location);

  const { palette } = useTheme();

  const handleChange = (newAddress) => {
    onChange({ target: { value: newAddress } });
    setAddress(newAddress);
  };

  const handleSelect = (newAddress) => {
    setAddress(newAddress);
    onChange({ target: { value: newAddress } });
  };

  const handleError = (status, clearSuggestions) => {
    console.log("Error from Google Maps API", status);
    clearSuggestions();
  };

  return (
    <LocationAutocomplete
      searchAPIUrl={searchAPIUrl}
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      shouldFetchSuggestions={!!(address && address.length > 0)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const otherInputProps = getInputProps({
          className: "pikcha-location-search-input",
        });
        if (disabled) {
          otherInputProps.disabled = true;
        }
        return (
          <div
            style={{
              position: "relative",
            }}
          >
            <PikchaTextField
              disabled={disabled}
              label={label}
              value={address}
              InputProps={otherInputProps}
              height={40}
              startAdornment={startAdornment}
              placeholder="location"
              {...rest}
            />
            <div
              style={{
                display: suggestions.length > 0 ? "block" : "none",
                position: "absolute",
                top: 40,
                zIndex: 100,
                width: "100%",
                borderColor: palette.primary.main,
                backgroundColor: palette.common.white,
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: 6,
              }}
            >
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "pikcha-suggestion-item--active"
                  : "pikcha-suggestion-item";

                const style = suggestion.active
                  ? {
                      backgroundColor: palette.grey.light,
                      color: palette.text.primary,
                      cursor: "pointer",
                      borderRadius: 6,
                    }
                  : {
                      color: palette.text.primary,
                      cursor: "pointer",
                      borderRadius: 6,
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.title}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </LocationAutocomplete>
  );
};

const mapStateToProps = (state) => ({
  searchAPIUrl: searchAPIUrl(state),
});

export default connect(mapStateToProps, {})(LocationPicker);
