import React, {useEffect, useState} from "react";
import {connect} from "react-redux"
import {Link, useHistory} from "react-router-dom";
import Img from "react-image";


import {useTheme} from "@material-ui/core/styles";
import PublicIcon from "@material-ui/icons/Public";

import {removeModal} from "../../actions/modal";
import {ICON_SIZE_TINY} from "../../helpers/constants";
import {PikchaArtistName} from "../baseComponents";
import {PikchaLink} from "../baseComponents/PikchaLink";

const UserDetails = ({
                       id,
                       userHandle,
                       nickName,
                       avatar,
                       nationality,
                       nameNeedsToBeWrapped,
                       nameMaxWidth,
                       isMobileMasonry,
                       smallerVariant,
                       largerVariant,
                       style = {},

                       removeModal,
                       onClose = () => {
                       }
                     } = {}) => {
  const history = useHistory();
  const {palette, typography} = useTheme();

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (!avatar) {
      setImageLoaded(false);
    }
  }, [avatar]);

  const onImageLoaded = () => {
    setImageLoaded(true);
  };
  return (
    <PikchaLink
      to={`/${userHandle}`}
      onClick={() => {
        removeModal();
        onClose();
      }}
    >
      <div
        className="Artist-profileLink no-underline"
        style={{
          ...style,
          cursor: "pointer"
        }}
      >
        <div className="flex-center full-width">
          <div className="flex-center">
            {!imageLoaded && (
              <div
                style={{
                  borderRadius: "50%",
                  width:
                    isMobileMasonry || smallerVariant
                      ? 20
                      : largerVariant
                      ? 40
                      : 20,
                  minWidth:
                    isMobileMasonry || smallerVariant
                      ? 20
                      : largerVariant
                      ? 40
                      : 20,
                  minHeight:
                    isMobileMasonry || smallerVariant
                      ? 20
                      : largerVariant
                      ? 40
                      : 20,
                  height:
                    isMobileMasonry || smallerVariant
                      ? 20
                      : largerVariant
                      ? 40
                      : 20,
                  backgroundColor: palette.grey.light,
                }}
              />
            )}
            <Img
              src={avatar}
              onLoad={onImageLoaded}
              style={{
                display: !imageLoaded ? "none" : "block",
                borderRadius: "50px",
                width: nationality ? 40 :
                  isMobileMasonry || smallerVariant
                    ? 20
                    : largerVariant
                    ? 40
                    : 20,
                opacity: "0.85",
              }}
            />
          </div>
          <div
            style={{
              paddingLeft: "0.5em",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            className="flex-center pl-half-em"
          >
            <PikchaArtistName
              style={{
                whiteSpace: "nowrap",
                maxWidth: nameNeedsToBeWrapped ? nameMaxWidth : !isMobileMasonry ? undefined : 104,
                color: palette.text.primary,
                fontSize:
                // TODO: Probably refactor this to be cleaner
                  isMobileMasonry || smallerVariant
                    ? typography.smallest
                    : !nationality
                    ? typography.smallest
                    : typography.small
              }}
            >
              {nickName}
            </PikchaArtistName>
            {nationality && (
              <div className="flex-center full-width">
                <div
                  className="flex-center"
                  style={{
                    color: palette.text.disabled,
                    fontWeight: typography.fontWeightMedium,
                    fontSize: typography.smallest,
                  }}
                >
                  <PublicIcon
                    style={{
                      ...ICON_SIZE_TINY,
                      color: palette.text.disabled,
                    }}
                  />
                  &nbsp;{nationality}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PikchaLink>
  );
};

export default connect(null, {removeModal})(UserDetails);
