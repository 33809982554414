import React, {useEffect, useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {setLocalPreferences} from "../../helpers/localUserPref";
import {setProfilePreferences} from "../../actions/changeCurrency";
import {PREFERRED_UNIT_CHANGE} from "../../actions/types";
import {makeStyles} from "@material-ui/core";
import {PikchaReusableTabsGroup} from "../baseComponents";
import {StraightenRounded} from "@material-ui/icons";
import PikchaReusableTab from "../baseComponents/tabs/PikchaReusableTab";

import './UnitPicker.css'

export const UnitPicker = ({customStyles}) => {
  const {palette, typography} = useTheme();

  const useStylesTabs = makeStyles({
    flexContainer: {
      width: "3.8em",
      height: "1.2rem",
      minHeight: "1.2rem",
      justifyContent: 'left',
      color: (customStyles && customStyles.color) ? customStyles.color : palette.common.black,
    }
  });

  const useStylesTab = makeStyles({
    labelIcon: {
      minHeight: "auto",
      height: "1.05rem",
      color: (customStyles && customStyles.color) ? customStyles.color : palette.common.black,
    }
  })

  const tabsClasses = useStylesTabs();
  const tabClasses = useStylesTab();

  // @ts-ignore
  const userPreferencesReducer = useSelector(state => state.userPreferencesReducer);
  const accountReducer = useSelector((state: any) => state.accountReducer);
  const [selectedUnit, setSelectedUnit] = useState(userPreferencesReducer.preferredUnit.value);
  const dispatch = useDispatch();

  const changeHandler = (e, newValue) => {
    if (newValue !== userPreferencesReducer.preferredUnit.value) {
      setLocalPreferences({
        currencyCode: userPreferencesReducer.currencyOptions.value,
        locale: userPreferencesReducer.currencyOptions.locale,
        fxRate: userPreferencesReducer.fxRate,
        preferredUnit: newValue
      });

      dispatch({
        type: PREFERRED_UNIT_CHANGE,
        payload: {label: newValue, value: newValue, ariaLabel: newValue}
      });

      if (accountReducer.user) {
        setProfilePreferences({
          currencyCode: userPreferencesReducer.currencyOptions.value,
          measurement: newValue
        });
      }
    }
  };

  useEffect(() => {
    setSelectedUnit(userPreferencesReducer.preferredUnit.value)
  }, [userPreferencesReducer.preferredUnit.value])

  return (
    <div className={'unit-picker-container'}>
      <div className={'unit-picker-icon'} style={{
        fontWeight: typography.fontWeightMedium,
        color: palette.common.black
      }}>
        <StraightenRounded style={{
          width: (customStyles && customStyles.width) ? customStyles.width : '24px',
          color: (customStyles && customStyles.color) ? customStyles.color : palette.common.black
        }} />
      </div>
      <div className={'unit-picker-tabs-container'}>
        <PikchaReusableTabsGroup
          className={tabsClasses.flexContainer} value={selectedUnit}
          onChange={changeHandler} variant={'fullWidth'} aria-label="Toggle Units" scrollButtons="off">
          <PikchaReusableTab className={tabClasses.labelIcon} style={{width: '1.7rem'}} label={'in'} value={"in"}
                             aria-label={"in"} indicatorColor={'red'} />
          <PikchaReusableTab className={tabClasses.labelIcon} style={{width: '2rem'}} label={'cm'} value={"cm"}
                             aria-label={"cm"} />
        </PikchaReusableTabsGroup>
      </div>
    </div>
  );
};
