import React from "react";
import { useTheme } from "@material-ui/core";

export default ({
  children,
  style,
  serif,
  extraLarge,
  larger,
  smaller,
  bold,
  medium,
  lineHeightReduced,
  tiny,
  ...rest
}) => {
  const { palette, typography } = useTheme();

  return (
    <h1
      style={{
        fontFamily: serif
          ? typography.fontFamilySerif
          : typography.fontFamilyMontserrat,
        fontWeight: medium
          ? typography.fontWeightMedium
          : bold
          ? typography.fontWeightBold
          : null,
        lineHeight: lineHeightReduced
          ? typography.h1.lineHeightReduced
          : typography.h1.lineHeight,
        letterSpacing: typography.h1.letterSpacing,
        color: palette.secondary,
        fontSize:
          extraLarge || larger
            ? typography.h1.extraLarge
            : smaller
            ? typography.h1.smaller
            : tiny
            ? typography.h1.tiny
            : typography.h1.fontSize,
        ...style,
      }}
      {...rest}
    >
      {children}
    </h1>
  );
};
