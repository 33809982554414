import {
  GALLERY_SAVED_ERROR,
  GALLERY_SAVED_LOADED,
  GALLERY_SAVED_LOADING,
  GALLERY_SAVED_RESET,
  HANDLE_SAVED_IMAGE,
} from "../actions/types";
import {
  GALLERY_PAGE_DEFAULT,
  GALLERY_ITEMINPAGE_DEFAULT,
} from "../helpers/constants";

const initialState = {
  page: GALLERY_PAGE_DEFAULT,
  itemInPage: GALLERY_ITEMINPAGE_DEFAULT,
  photos: [],
  loading: true,
  error: null,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GALLERY_SAVED_LOADED: {
      return {
        ...state,
        photos: [...state.photos, ...payload],
        loading: false,
        page: state.page + 1,
      };

      // const photos = [...state.photos, ...payload];
      // return {
      //   ...state,
      //   photos: photos,
      //   loading: false,
      //   start: state.start + state.count,
      // };
    }
    case HANDLE_SAVED_IMAGE: {
      const { imageId, saved: isSaved } = payload;
      const photos = state.photos.map((photo) => {
        const { id } = photo;
        if (id === imageId) {
          return {
            ...photo,
            ...{
              isSaved,
            },
          };
        }
        return photo;
      });
      return {
        ...state,
        ...{ photos },
      };
    }
    case GALLERY_SAVED_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GALLERY_SAVED_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: false,
      };
    case GALLERY_SAVED_RESET:
      return {
        ...state,
        page: GALLERY_PAGE_DEFAULT,
        itemInPage: GALLERY_ITEMINPAGE_DEFAULT,
        photos: [],
        loading: true,
        error: null,
        hasMore: true,
      };
    default:
      return state;
  }
};
