import axios from "axios";
import {createSelector} from "reselect";

import {
  GALLERY_CACHED_STATE,
  GALLERY_CHANGE_IMAGE_TYPE,
  GALLERY_ERROR,
  GALLERY_FILTERED_LOADED,
  GALLERY_FILTERED_LOADED_ERROR,
  GALLERY_LOADED,
  GALLERY_LOADING,
  GALLERY_RESET
} from "./types";

import AuthorizeService from "../auth/AuthorizeService";
import {GALLERY_ITEMINPAGE_DEFAULT, GALLERY_PAGE_DEFAULT,} from "../helpers/constants";

// Get Photo from Qr Code url
export const getPhotoQRCode = (qrUrl) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading());
      const res = await axios.get(`api/image/qrcode${qrUrl}`);
      dispatch({
        type: GALLERY_LOADED,
        payload: [res.data],
      });
    } catch (err) {
      dispatch({
        type: GALLERY_ERROR,
        payload: err.response,
      });
    }
  };
};

export const setCachedState = (state) => {
  return (dispatch) => {
    dispatch({type: GALLERY_CACHED_STATE, payload: state});
  };
};

const gallerySelector = (state, props) => state.galleryReducer;

export const photosSelector = createSelector(gallerySelector, (photos) => {
  return photos;
});

// Get Photos in the trending page
export const getPhotos = (
  itemInPage = GALLERY_ITEMINPAGE_DEFAULT,
  page = GALLERY_PAGE_DEFAULT,
  imageType = ""
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading());
      const token = await AuthorizeService.getAccessToken();
      const parameters = {
        page: page,
        itemInPage: itemInPage,
        imageType: imageType
      };

      const res = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? ""
            : "https://pikcha.local:1900"
        }/v1/search/api/Search/trending`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: GALLERY_LOADED,
        payload: res.data,
      });

    } catch (err) {
      dispatch({
        type: GALLERY_ERROR,
        payload: err.response,
      });
    }
  };
};

export const getFilteredPhotos = (
  itemInPage = GALLERY_ITEMINPAGE_DEFAULT,
  page = GALLERY_PAGE_DEFAULT,
  imageType = ""
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading());
      const token = await AuthorizeService.getAccessToken();
      const parameters = {
        page: page,
        itemInPage: itemInPage,
        imageType: imageType
      };

      const res = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? ""
            : // : "http://localhost:1900"
            "https://pikcha.local:1900"
        }/v1/search/api/Search/trending`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: GALLERY_FILTERED_LOADED,
        payload: res.data,
      });

    } catch (err) {
      dispatch({
        type: GALLERY_ERROR,
        payload: err.response,
      });
      dispatch({
        type: GALLERY_FILTERED_LOADED_ERROR,
        payload: []
      });
    }
  };
};

// Add Photo View
export const addView = (imageId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`api/image/${imageId}/view`);
      //console.log("View added", res);
    } catch (err) {
      console.log("View add error ", err);
    }
  };
};

// Set Loading
export const setLoading = () => {
  return (dispatch) => {
    dispatch({
      type: GALLERY_LOADING,
    });
  };
};

// Reset Gallery
export const resetGallery = () => {
  return (dispatch) =>
    dispatch({
      type: GALLERY_RESET,
    });
};

export const changeImageType = (imageType) => {
  return (dispatch) => {
    dispatch({
      type: GALLERY_CHANGE_IMAGE_TYPE,
      payload: imageType
    });
  };
};