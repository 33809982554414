import React, {useEffect, useState} from "react";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import AttachmentIcon from "@material-ui/icons/Attachment";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useTheme, withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import isMobile from "../../../components/Responsive/isMobile";
import isDesktop from "../../../components/Responsive/isDesktop";
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded';
import ZoomInRoundedIcon from "@material-ui/icons/ZoomInRounded";

import {handleRelatedImagesPortrait} from "../../../actions/relatedImages"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import {setAlert} from "../../../actions/alert";
import { downloadImage } from "../../../helpers/utils";
import {PikchaButton, PikchaLoader, PikchaTextField} from "../../../components/baseComponents";
import {FinalPreviewImage} from "./FinalPreviewImage";

import "./socialButtonStyles.css";
import {ICON_SIZE_DEFAULT, ICON_SIZE_SMALL} from "../../../helpers/constants";

const StyledLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey.light,
  },
}))(LinearProgress);

const Step3 = ({
                          handleRelatedImagesPortrait,

                          imageDetails,
                          isLoading,
                          previewImage,
                          thumbnailImage,
                          progress,
                          setAlert,
                        }) => {
  const {
    friendlyUrl,
    title,
    tags,
    framenail,
    aspRatioX,
    aspRatioY,
    descriptionCard,
    inverseDescriptionCard
  } = imageDetails;

  const [FinishLoadingImg1, setFinishLoadingImg1] = useState(false);
  const [FinishLoadingImg2, setFinishLoadingImg2] = useState(false);
  const isOnMobile = isMobile();
  const isOnDesktop = isDesktop();

  useEffect(() => {
    let timer1;
    let timer2;
    if (!FinishLoadingImg1) {
      timer1 = setInterval(function () {
        let _img1 = document.getElementById('sample-card-1');
        
        if (_img1) {
          let imgSrc1 = (_img1 || {}).getAttribute("src");
          _img1.setAttribute("src", imgSrc1)
        }
      }, 3000)
    }
    if (!FinishLoadingImg2) {
      timer2 = setInterval(function () {
        let _img2 = document.getElementById('sample-card-2');

        if (_img2) {
          let imgSrc2 = (_img2 || {}).getAttribute("src");
          _img2.setAttribute("src", imgSrc2);
        }
      }, 3000)
    }
    return () => {
      clearInterval(timer1);
      clearInterval(timer2);
    };
  }, [FinishLoadingImg1, FinishLoadingImg2]);

  const handleImg1Loaded = () => {
    setFinishLoadingImg1(true);
  }

  const handleImg2Loaded = () => {
    setFinishLoadingImg2(true);
  }

  // const downloadImage = (type) => {
  //   if (type === "preview") {
  //     let a = document.createElement("a");
  //     a.href = previewImage;
  //     a.download = previewImage;
  //     a.setAttribute("download", previewImage);
  //     a.click();
  //   } else {
  //     const imageType =
  //       type === "preview"
  //         ? previewImage.split(";")[0].split("/")[1]
  //         : thumbnailImage.split(";")[0].split("/")[1];
  //     const filename = imageDetails.title.replace(/ /g, "") + "." + imageType;

  //     const byteString = atob(
  //       type === "preview"
  //         ? previewImage.split(",")[1]
  //         : thumbnailImage.split(",")[1]
  //     );

  //     // separate out the mime component
  //     const mimeString =
  //       type === "preview"
  //         ? previewImage.split(",")[0].split(":")[1].split(";")[0]
  //         : thumbnailImage.split(",")[0].split(":")[1].split(";")[0];

  //     // write the bytes of the string to an ArrayBuffer
  //     const ab = new ArrayBuffer(byteString.length);

  //     // create a view into the buffer
  //     const ia = new Uint8Array(ab);

  //     // set the bytes of the buffer to the correct values
  //     for (let i = 0; i < byteString.length; i++) {
  //       ia[i] = byteString.charCodeAt(i);
  //     }

  //     // write the ArrayBuffer to a blob, and you're done
  //     const blob = new Blob([ab], {type: mimeString});

  //     let uriContent = URL.createObjectURL(blob);
  //     let a = document.createElement("a");
  //     a.href = uriContent;
  //     a.download = filename;
  //     a.click();
  //   }
  // };


  const downloadPreview = () => {
    downloadImage(imageDetails.framenail, imageDetails, true);
  };
  
  const imageCopyLink = `${window.location.origin}/${friendlyUrl}`;
  
  const copyLink = () => {
    navigator.clipboard.writeText(imageCopyLink).then(
      () => {
        setAlert("URL has been copied", "info");
      },
      (err) => {
        setAlert("Couldn't copy image link", "error");
      }
    );
  };
  const socialButtons = () => (
    <div
      className="full-width flex mt-1-em mb-3-em"
      style={{flexWrap: "wrap", maxWidth: 516,}}
    >
      <div className="social-sharing-button-box">
        <FacebookShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          quote={
            `Check out my new artwork listing, ${title}, on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
          url={`${window.location.origin}/${friendlyUrl}`}
        >
          <span
            className="Facebook-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <FacebookIcon size={46}/>
          </span>
        </FacebookShareButton>
        <span
          style={{

            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Facebook</span>
      </div>

      <div className="social-sharing-button-box">
        <FacebookMessengerShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/${friendlyUrl}`}
          appId="536438577204283"
          redirectUri={`${window.location.origin}/${friendlyUrl}`}
        >
          <span
            className="Messenger-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <FacebookMessengerIcon size={46}/>
          </span>
        </FacebookMessengerShareButton>
        <span
          style={{

            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Messenger</span>
      </div>

      <div className="social-sharing-button-box">
        <LinkedinShareButton
          url={`${window.location.origin}/${friendlyUrl}`}
          title="Check out my latest image on Pikcha"
          summary={`Check out my new artwork listing, ${title}, on Pikcha.com. There are only 100 prints available! #pikcha #pikcha100`}
          source="https://www.pikcha.com"
          style={{
            marginRight: "0px",
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
        >
          <span
            className="LinkedIn-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <LinkedinIcon size={46}/>
          </span>
        </LinkedinShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >LinkedIn</span>
      </div>

      <div className="social-sharing-button-box">

        <TwitterShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/${friendlyUrl}`}
          title={
            `Check out my new artwork listing, ${title}, on Pikcha.com.` +
            " There are only 100 prints available!"
          }
          hashtags={["pikcha", "pikcha100"]}
        >
          <span
            className="Twitter-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TwitterIcon size={46}/>
          </span>
        </TwitterShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Twitter</span>
      </div>

      <div className="social-sharing-button-box">

        <RedditShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/${friendlyUrl}`}
          title={
            `Check out my new artwork listing, ${title}, on Pikcha.com.` +
            " There are only 100 prints available!"
          }
          hashtags={["pikcha", "pikcha100"]}
        >
          <span
            className="Reddit-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px",
            }}
          >
            <RedditIcon size={46}/>
          </span>
        </RedditShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Reddit</span>
      </div>

      <div className="social-sharing-button-box">
        <EmailShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${friendlyUrl}`}
          body={
            `Check out my new artwork listing, ${title}, on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
          subject={`Check out my new artwork listing, ${title}, on Pikcha.com.`}
        >
          <span
            className="Email-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <EmailIcon size={46}/>
          </span>
        </EmailShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Mail</span>
      </div>

      <div className="social-sharing-button-box">
        <PinterestShareButton
          url={`${window.location.origin}/${friendlyUrl}`}
          //media={`${window.location.origin}/${friendlyUrl}`}
          media={framenail}
          description={`Check out my new artwork listing, ${title}, on Pikcha.com. There are only 100 prints available! #pikcha #pikcha100`}
          style={{
            marginRight: "0px",
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
        >
          <span
            className="Pinterest-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <PinterestIcon size={46}/>
          </span>
        </PinterestShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Pinterest</span>
      </div>

      <div className="social-sharing-button-box">
        <TumblrShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${friendlyUrl}`}
          caption={
            `Check out my new artwork listing, ${title}, on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }

          title={`${title}`}
          tags={tags}
        >
          <span
            className="Tumblr-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TumblrIcon size={46}/>
          </span>
        </TumblrShareButton>
        <span
          style={{

            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Tumblr</span>
      </div>

      <div className="social-sharing-button-box">
        <WhatsappShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${friendlyUrl}`}
          title={
            `Check out my new artwork listing, ${title}, on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
        >
          <span
            className="Whatsapp-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <WhatsappIcon size={46}/>
          </span>
        </WhatsappShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Whatsapp</span>
      </div>

      <div className="social-sharing-button-box">
        <TelegramShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${friendlyUrl}`}
          title={
            `Check out my new artwork listing, ${title}, on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
        >
          <span
            className="Telegram-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TelegramIcon size={46}/>
          </span>
        </TelegramShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Telegram</span>
      </div>

      <div className="social-sharing-button-box">
        <PikchaButton
          style={{
            marginRight: "0px",
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          onClick={() => 
            //downloadImage("preview")
            downloadPreview()
          }
        >
          <SaveAltRoundedIcon/>
        </PikchaButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Download</span>
      </div>
    </div>
  );

  const {palette, typography} = useTheme();

  return (
    <div className="pl-1-em pr-1-em pt-2-em pb-2-em">
      <div className="flex-stretch flex-grow-1 flex-column">
        {isLoading ? (
          <div className="flex-stretch flex-grow-1 flex-column margin-auto">
            <div
              style={{
                textAlign: "center",
                marginTop: "100px",
                fontWeight: typography.fontWeightMedium,
              }}
            >
              Image Uploading
            </div>
            <StyledLinearProgress
              style={{
                marginTop: "2em",
                width: "768px",
              }}
              variant="determinate"
              value={progress}
            />
            <div
              style={{
                marginTop: "64px",
                textAlign: "center",
              }}
            >
              Only share watermarked versions of your image to protect the IP!
            </div>
          </div>
        ) : (
          <div className="flex-stretch flex-grow-1 flex-column margin-auto">
            <div style={{...isOnDesktop && {display: "flex"}}}>
              <div
                className="pl-1-em pr-1-em"
                style={{
                  ...isOnDesktop && {
                    flexGrow: 1,
                    order: 2,
                    textAlign: "right",
                    maxWidth: "55%",
                  }
                }}
              >
                <div
                  style={{
                    color: palette.action.disabled,
                    fontSize: 10,
                    fontWeight: typography.fontWeightMedium,
                    paddingTop: 2,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span> Preview Image</span>
                </div>

                <div className="mb-1-em flex-stretch flex-grow-1">
                  {previewImage && (
                    <div
                      className="flex-grow-1 ProductItem-imageFrame p-1-em"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FinalPreviewImage
                        style={{
                          width: "50%",
                          display: "block",
                          height: "auto",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        alt="Uploaded Image"
                        image={{watermark: previewImage, aspRatioX, aspRatioY}}
                        handleIsPortrait={handleRelatedImagesPortrait}
                        needToHandleClick={false} 
                      />
                    </div>
                  )}
                </div>

                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <ZoomInRoundedIcon
                      style={{
                        ...ICON_SIZE_SMALL,
                        color: palette.grey.mediumDark,
                      }}
                    />
                    &nbsp;
                    <span
                      style={{
                        color: palette.action.disabled,
                        fontSize: 10,
                        fontWeight: typography.fontWeightMedium,
                        paddingTop: 2,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Hover Magnifier
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="pl-1-em pr-1-em"
                style={{
                  ...isOnDesktop && {
                    flexGrow: 1,
                    order: 1,
                    maxWidth: "45%",
                  }
                }}
              >
                <div className="full-width flex mt-1-em mb-1-em">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ReplyRoundedIcon
                      style={{
                        ...ICON_SIZE_DEFAULT,
                        color: palette.grey.mediumDark,
                        transform: "matrix(-1, 0, 0, 1, 0, 0)",
                      }}
                    />
                    &nbsp;
                    <span
                      style={{
                        flexGrow: 1,
                        color: "grey",
                        fontSize: typography.small,
                        fontWeight: typography.fontWeightMedium,
                        fontFamily: typography.fontFamilyMontserrat,
                      }}
                    >
                      Share To
                      </span>
                  </div>
                </div>

                {socialButtons()}

                <div style={{display: "flex",width: "100%", maxWidth:516}}>
                  <div style={{minWidth:"200px", width: "100%", paddingTop: "0.75em", paddingRight:"0.5em",}}>
                    <PikchaTextField
                        disabled={true}
                        type="text"
                        name="nickName"
                        value={imageCopyLink}
                      />
                  </div>
                  <div className="copy-link-button-box">
                    <PikchaButton
                      noPadding
                      style={{
                        minWidth: 40,
                        marginBottom: "0.5em",
                        padding: 3,
                        marginRight: 0,
                      }}
                      onClick={() => copyLink()}
                    >
                        <AttachmentIcon/>
                    </PikchaButton>
                    <span
                        style={{
                          color:"grey",
                          fontSize: typography.tiny,
                          fontWeight: typography.fontWeightSemiMedium,
                          fontFamily: typography.fontFamilyMontserrat,
                        }}
                      >
                        Copy
                    </span> 
                  </div>
                </div>   

                <p
                  style={{
                    marginTop: "5em",
                    marginBottom: "2em",
                    color: "grey",
                    fontSize: typography.small,
                    fontWeight: typography.fontWeightMedium,
                    fontFamily: typography.fontFamilyMontserrat,
                  }}
                >
                  Your Sample Gallery Cards
                </p>

                {(!FinishLoadingImg1 || !FinishLoadingImg2) && (
                  <div
                    style={{
                      marginTop: "50px"
                    }}
                  >
                    <PikchaLoader/>
                  </div>
                )}

                <div className="flex">
                  <div
                    style={{
                      marginRight: "2em", ...!isOnMobile && {
                        display: "flex",
                        flex: "1 1",
                        maxWidth: "250px",
                        alignItems: "flex-start",
                      }
                    }}
                  >
                    <img
                      id="sample-card-1"
                      style={{
                        marginTop: "1em",
                        boxShadow: `rgba(0, 0, 0, 0.25) 4px 4px 22px`,
                        display: !FinishLoadingImg1 ? "none" : true,
                        maxWidth: isOnDesktop ? "100%" : 196,
                        height: "auto",
                      }}
                      src={descriptionCard}
                      onLoad={handleImg1Loaded}
                    />
                  </div>
                  <div
                    style={{
                      ...!isOnMobile && {
                        display: "flex",
                        flex: "1 1",
                        maxWidth: "250px",
                        alignItems: "flex-start",
                      }
                    }}
                  >
                    <img
                      id="sample-card-2"
                      style={{
                        marginTop: "1em",
                        boxShadow: `rgba(0, 0, 0, 0.25) 4px 4px 22px`,
                        display: !FinishLoadingImg2 ? "none" : true,
                        maxWidth: isOnDesktop ? "100%" : 196,
                        height: "auto"
                      }}
                      src={inverseDescriptionCard}
                      onLoad={handleImg2Loaded}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step3;
