import React from 'react'
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  colors
} from "@pikcha/typography";
import SignaturePreview from '../../containers/modals/UploadFlowModal/SignaturePreview';

export default ({
  ...rest
}) => {
  return (
    <>
      <div
        className="mb-1-em"
        style={{
          width: "100%",
          border: `1px solid ${colors.Neutral.grey30}`,
          borderRadius: "4px",
          padding: "1em"
        }}
      >
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
        >
          Signature and Edition number placement
        </TypographyBody>
        <SignaturePreview
          {...rest}
        />
      </div>
    </>
  )
}
