import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({
  style,
  innerStyle,
  onClick,
  disabled,
  selectedColor,
  color,
  noHovering,
}) => {
  const { palette } = useTheme();

  const [isHovering, setIsHovering] = useState(false);

  const handleHover = () => {
    if (!noHovering) {
      setIsHovering(true);
    }
  };
  const handleHoverLeave = () => {
    if (!noHovering) {
      setIsHovering(false);
    }
  };

  return (
    <div
      style={{
        ...{
          width: "32px",
          height: "32px",
          cursor: disabled ? "default" : "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px",
        },
        ...(!disabled && (selectedColor === color || isHovering)
          ? {
              backgroundColor: palette.grey.lighter,
            }
          : {}),
        ...style,
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      onClick={() => {
        if (!disabled) {
          setIsHovering(false);
          onClick(color);
        }
      }}
    >
      <div
        style={{
          ...{
            width: "60%",
            height: "60%",
            borderRadius: "4px",
            backgroundColor: color,
          },
          ...(innerStyle || {}),
          ...(color === "#ffffff" ||
          color === "#FFFFFF" ||
          color === "#f5f5dc" ||
          color === "#F5F5DC"
            ? {
                boxShadow: `0 0 2px ${palette.grey.medium}`,
              }
            : {}),
        }}
      />
    </div>
  );
};
