import React, { useState } from 'react';

const ReadMore = ({
  children,
  more = 'Read more',
  less = 'Less',
  characters = 150,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleLines = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  if (!children) {
    return null;
  }

  return (
    <>
      {(children && children.length < characters) || expanded
        ? children
        : children.substring(0, characters)}
      {children && children.length > characters && !expanded && (
        <>
          <br/>
          <span>
            <a
              href="#"
              onClick={toggleLines}
            >
              {more}
            </a>
          </span>
        </>
      )}
      {children && children.length > characters && expanded && (
        <>
          <br/>
          <span>
            <a
              href="#"
              onClick={toggleLines}
            >
              {less}
            </a>
          </span>
        </>
      )}
    </>
  );
};

export default ReadMore;
