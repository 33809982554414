import {
  SEARCH_ARTISTS_LOADED,
  SEARCH_ALBUMS_LOADED,
  SEARCH_ARTISTS_LOADING,
  SEARCH_ALBUMS_LOADING,
  SEARCH_ERROR,
  SEARCH_LOADED,
  SEARCH_LOADING,
} from '../actions/types';

const initialState = {
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_ARTISTS_LOADING:
    case SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_ARTISTS_LOADED:
    case SEARCH_ALBUMS_LOADED:
    case SEARCH_ALBUMS_LOADING:
    case SEARCH_LOADED:
    case SEARCH_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};
