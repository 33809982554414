import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Zoomable from "react-instagram-zoom";
import { connect } from "react-redux";
import Img from "react-image";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import { useTheme } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';
import { removeModal } from "../../../actions/modal";
import { handleSavedImage } from "../../../actions/account";
import { handleAuthModal } from "../../../actions/authModal";
import { setAlert } from "../../../actions/alert";
import isTablet from "../../../components/Responsive/isTablet";
import isMobile from "../../../components/Responsive/isMobile";
import { isUserLoggedIn, userId } from "../../../selectors/account";
import EditButton from '../../../components/EditButton/EditButton';
import EditPhotoModalMobile
  from '../../../containers/modals/EditPhotoModal/EditPhotoModalMobile';
import {
  getCreatedAt, getCustomisePath,
  removeModalOverlay,
} from "../../../helpers/utils";
import {
  PikchaIcon,
  PikchaImageUploadDate,
  PikchaLikeButton,
  PikchaTextBlock,
} from "../../../components/baseComponents";
import RelatedImages from "../../RelatedImages/RelatedImages";
import { ProductItemPurchaseButton } from "./ProductItemPurchaseButton";
import "./ProductItem.css";
import { ProductItemShareButton } from "./ProductItemShareButton";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  colors,
  CountDownTimer
} from "@pikcha/typography";
import Avatar from '@material-ui/core/Avatar';
import { Link } from "react-router-dom";
import PriceRange from "./PriceRange";

const bodyScrollLock = require('body-scroll-lock');

const titleCase = require("ap-style-title-case");

const ProductItem = ({
  onClose,
  image,
  handleSavedImage,
  setAlert,
  loggedIn,
  handleAuthModal,
  removeModal,
  userId
}) => {
  let artistStatus;
  if (image) {
    if (image.artist) {
      artistStatus = image.artist.userStatus;
    }
  }

  let isArtistDeleted = artistStatus === "Inactive";

  const history = useHistory();

  const isOnTablet = isTablet();
  const isOnMobile = isMobile();

  const [isSharingModalOpen, setIsSharingModalOpen] = useState();
  const [EditModalShow, setEditModalShow] = useState(false);
  const { palette, typography } = useTheme();
  const [isPublished, setIsPublished] = useState(true)

  const getIsPublished = (data) => {
    setIsPublished(data)
  }

  const scrollRef = useRef(null);
  const scrollOnTop = () => {
    if (document.getElementsByClassName("modalScroll")[0] != undefined) {
      document.getElementsByClassName("modalScroll")[0].scrollTo(0, 0);
    }
  };

  const { isSaved } = image;
  const saved = !!isSaved;

  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;
  const targetElement = document.getElementById("Modal-scrollContainer");

  const _handleSavedImage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!loggedIn) {
      handleAuthModal(true, "saved-images");
      return;
    }

    const shouldSave = !saved;

    handleSavedImage(image.id);

    if (shouldSave) {
      setAlert("Image Liked", "info");
    } else {
      setAlert("Image removed from Liked", "info");
    }
  };

  const lockScrolling = (e) => {
    if (targetElement) {
      enableBodyScroll(targetElement);
      targetElement.classList.remove("ps")
    }
    ;
  };

  const unlockScrolling = (e) => {
    if (targetElement) {
      disableBodyScroll(targetElement);
      targetElement.classList.add("ps");
    }
    ;
  };

  const onPrintAndBuyClicked = () => {
    removeModal({
      moveForward: true,
    });
    removeModalOverlay();
    history.push(getCustomisePath(image), { image: image });
  };

  const onOpenSharingModal = () => {
    setIsSharingModalOpen(true);
  };
  const onCloseSharingModal = () => {
    setIsSharingModalOpen(false);
  };

  const _handleEditImage = (e) => {
    onClose();

    e.preventDefault();
    e.stopPropagation();

    const imgUrl = image.friendlyUrl.split("/")[1];

    history.push({
      pathname: `/edit/${imgUrl}`,
      state: { imageDetails: image }
    });
  }

  return (
    <div
      ref={scrollRef}
    >
      <div
        style={{
          padding: "0 1em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {EditModalShow && (
          <EditPhotoModalMobile
            imageDetails={image}
            onViewAllArtworkPrices={() => {
            }}
            onCloseModal={() => {
              setEditModalShow(false)
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
        </div>
        <div
          id="zoomable"
          className="flex-column justify-center"
          style={{
            marginTop: "0.7em",
            minHeight: 250,
          }}
        >
          <Zoomable
            onTouchStart={lockScrolling}
            onTouchEnd={unlockScrolling}
            zIndex={99999999999999999999}
            // onReleaseAnimationEnd={setNewZIndex}
            style={{
              display: isOnTablet || isOnMobile ? "flex" : undefined,
              justifyContent: isOnTablet || isOnMobile ? "center" : undefined,
            }}
          >
            <Img
              src={image && image.watermark ? image.watermark : image.thumbnail}
              style={{
                width: isOnTablet || isOnMobile ? undefined : "100%",
                // maxWidth: "100%",
                objectFit: "contain",
                height: "intrinsic",
                maxHeight: 700,
                boxShadow: `0px 0px 4px 2px rgba(0, 0, 0, 0.15)`,
                maxWidth: "100%"
                // padding: 15
              }}
              alt="Product"
            />
          </Zoomable>
        </div>
        <div
          style={{
            marginTop: "1em",
          }}
        >
          <div className="flex">
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              {image && image.title ? titleCase(image.title) : ""}
            </TypographyBody>
            <div
              style={{
                justifyContent: "flex-end"
              }}
              className="flex flex-grow-1"
            >
              {userId === image.artist.id && (
                <div
                  style={{
                    marginRight: "0.5em"
                  }}
                >
                  <EditButton
                    action="octonary"
                    onClick={(e) => {
                      _handleEditImage(e)
                    }}
                  />
                </div>
              )}
              <ProductItemShareButton
                image={image}
                isSharingModalOpen={isSharingModalOpen}
                onCloseSharingModal={onCloseSharingModal}
                onOpenSharingModal={onOpenSharingModal}
              />
              <PikchaLikeButton
                style={{
                  marginLeft: "0.5em"
                }}
                saved={saved}
                onClick={_handleSavedImage}
              />
            </div>
          </div>
          {image.totSold < image.totalEditions && (
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              color="neutral/grey50"
            >
              Edition of {image.totalEditions}
            </TypographyBody>
          )}
        </div>
        {image.dateCreated && image.dateCreated !== "00-00-0000" && (
          <PikchaImageUploadDate
            style={{
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightMedium,
              color: palette.text.secondary,
            }}
          >
            Created&nbsp;·&nbsp;{getCreatedAt(image.dateCreated)}
          </PikchaImageUploadDate>
        )}

        {image && image.location && image.location.length > 0 && (
          <div
            larger
            lightGrey
            medium
            className="flex-center ProductItem-locationMeta"
            style={{
              marginTop: ".25em",
              marginLeft: "-4px"
            }}
          >
            <PikchaIcon
              size="large"
              noMarginAuto
              icon={<LocationOnRoundedIcon />}
              style={{
                width: "16px",
                height: "16px",
                fontSize: 16,
              }}
            />
            <strong
              style={{
                marginLeft: "4px",
                fontSize: typography.smallest,
                maxWidth: "80%",
                fontWeight: typography.fontWeightMedium,
                color: palette.text.secondary,
                paddingTop: "2px"
              }}
              className="ProductItem-locationText"
            >
              {image.location}
            </strong>
          </div>
        )}
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
          style={{
            marginTop: "1em"
          }}
        >
          <span
            style={{
              paddingTop: "2em",
              paddingBottom: "1em",
            }}
            noMaxWidth
          >
            {image ? image.caption : ""}
          </span>
        </TypographyBody>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
          color="neutral/grey50"
          style={{
            marginTop: "1em"
          }}
        >
          Artist
        </TypographyBody>
        <div
          className="flex mb-1-em"
          style={{
            alignItems: "center"
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={image.artist.avatar}
            style={{
              marginRight: "1em",
              width: 16,
              height: 16,
              borderRadius: 8,
              border: "1px solid #E0E0E0",
              backgroundColor: colors.Neutral.white00
            }}
          />
          <Link
            to={image.artist.userHandle}
            onClick={onClose}
          >
            <TypographyBody
              style={{
                textDecoration: "underline"
              }}
            >
              {image.artist.nickName}
            </TypographyBody>
          </Link>
        </div>
        <div
          className="flex-column flex-end"
          style={{ marginTop: "auto" }}
        >
          <div
            className="flex"
            style={{
              borderRadius: 10,
              border: `1px solid ${colors.Neutral.grey30}`,
              width: "100%",
              alignItems: "center",
              marginBottom: '1.5em'
            }}
          >
            <PriceRange minPrice={image.minimumPrice} maxPrice={image.maximumPrice} />
            {!isPublished &&
              <div
                className="ml-1-em mr-1-em"
                style={{
                  height: 50,
                  width: 1,
                  background: colors.Neutral.grey30
                }}
              />
            }

            <CountDownTimer
              countingDownTime={image.publishAtLocal}
              getIsPublished={getIsPublished}
            />
          </div>
          <ProductItemPurchaseButton
            image={image}
            isArtistDeleted={isArtistDeleted}
            onClick={onPrintAndBuyClicked}
          />
        </div>
        {image && image.tags && image.tags.length > 0 && (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                marginBottom: "1em",
                marginTop: "2em",
              }}
            >
              {image.tags.map((each) => (
                <span>
                  <Chip
                    variant="outlined"
                    size="small"
                    label={each}
                    component="a"
                    onClick={() => {
                      removeModal();
                      history.push(`/search/${each}`);
                    }}
                    clickable
                    style={{
                      fontWeight: 500,
                      margin: 2,
                      color: palette.text.primary,
                      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                      border: `1px solid rgba(0,0,0,0.1)`,
                    }}
                  />
                </span>
              ))}
            </div>
          </div>
        )}

        <RelatedImages
          onClose={() => {
            removeModal({
              moveForward: true,
            });
            removeModalOverlay();
          }}
          image={image}
          scrollToTop={scrollOnTop}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: isUserLoggedIn(state),
  userId: userId(state)
});

export default connect(mapStateToProps, {
  setAlert,
  handleSavedImage,
  handleAuthModal,
  removeModal,
})(ProductItem);
