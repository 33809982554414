import React from "react";

import { useTheme } from "@material-ui/core/styles";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';

import MasonryGallerySearch
  from "../../containers/gallery/MasonryGallerySearch/MasonryGallerySearch";

import {
  PikchaReusableDropdown,
  PikchaReusableTabsGroup,
  PikchaReusableTab
} from "../../components/baseComponents";
import LocationReusablePicker from "../../components/LocationReusablePicker";

import isDesktopHD from "../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isTablet from "../../components/Responsive/isTablet";

import ColorDropdown from "./ColorDropdown";
import OrientationDropdown from "./OrientationDropdown";
import ImageTypeDropdown from "./ImageTypeDropdown";
import CategoryDropdown from "./CategoryDropdown";

export default ({
  searchTerm,
  isSearchLoading,
  resultsType,
  resultsTypeChange,
  gallery,
  getPhotos,
  resetGallery,
  sortBy,
  onSortByChange,
  onApplyFilters,
  imageTypes,
  imageType,
  onImageTypeChange,
  onClearFilters,
  imageCategories,
  imageCategory,
  onImageCategoryChange,
  imageOrientation,
  handleOrientationChange,
  imageColour,
  coloursAvailable,
  handleColourChange,
  location,
  onLocationChange,

  images,
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  //const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();

  const { palette, typography, shape } = useTheme();

  const { photos: galleryPhotos, loading } = gallery;

  const showBlankState =
    images.photos.length < 1 && !images.loading && !isSearchLoading;
  const getImages = () => {
    getPhotos(gallery.count, gallery.start);
  };

  return (
    <div
      style={{
        paddingTop: "4em",
        width: "100%",
      }}
    >
      <div
        style={{
          paddingLeft: isOnDesktopHD
            ? "4em"
            : isOnDesktopSD
              ? "2em"
              : isOnTablet
                ? "1em"
                : "0.5em",
          paddingRight: isOnDesktopHD
            ? "4em"
            : isOnDesktopSD
              ? "2em"
              : isOnTablet
                ? "1em"
                : "0.5em",
        }}
      >
        <div
          style={{
            marginTop: "4em",
            marginBottom: "2em"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                color: palette.text.primary,
                fontSize: typography.large,
                fontWeight: typography.fontWeightBold,
                textTransform: "capitalize"
              }}
            >
              {searchTerm}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end"
          }}
        >
          <div>
            <PikchaReusableTabsGroup
              value={resultsType}
              onChange={resultsTypeChange}
              aria-label="Results Type Selector"
            >
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Pikcha"
                  label="Image"
                  aria-label="Pikcha"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Artist"
                  label="Artist"
                  aria-label="Artist"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                  }}
                  value="Album"
                  label="Album"
                  aria-label="Album"
                />
              </PikchaReusableTabsGroup>
            </div>
          <div
            style={{
              width: 232,
              marginLeft: "auto",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            <PikchaReusableDropdown
              onChange={(sortBy)=>{onSortByChange(sortBy)}}
              style={{
                width: 152,
                fontWeight: 600,
                fontSize: ".93rem"
              }}
              itemStyle={{
                width: 180,
                fontWeight: 600,
                fontSize: ".93rem"
              }}
              options={[
                { value: "RV", label: "Recommended" },
                { value: "MV", label: "Most Viewed" },
                { value: "MS", label: "Most Sales" },
                { value: "PHL", label: "Price High to Low" },
                { value: "PLH", label: "Price Low to High" },
              ]}
              value={sortBy}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            marginTop: "2em"
          }}
        >
        <div
          style={{
            display: "flex",
            width: "100%",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            borderRadius: shape.borderRadius
          }}
        >
          <ColorDropdown
            style={{
              width: "100%",
              borderRadius: "6px 0 0 6px",
            }}
            imageColour={imageColour}
            coloursAvailable={coloursAvailable}
            handleColourChange={handleColourChange}
          />
          <OrientationDropdown
            style={{
              marginLeft: 2,
              width: "100%",
            }}
            handleOrientationChange={handleOrientationChange}
            imageOrientation={imageOrientation}
          />
          <ImageTypeDropdown
            style={{
              marginLeft: 2,
              width: "100%",
            }}
            imageTypes={imageTypes}
            imageType={imageType}
            onImageTypeChange={onImageTypeChange}
          />
          <CategoryDropdown
            style={{
              marginLeft: 2,
              width: "100%",
            }}
            imageCategories={imageCategories}
            imageCategory={imageCategory}
            onImageCategoryChange={onImageCategoryChange}
          />
          <div
            style={{
              width: "100%",
              marginLeft: 2,
              backgroundColor: palette.common.white,
              borderRadius: "0 6px 6px 0"
            }}
          >
            <LocationReusablePicker
              type="text"
              value={location}
              height={32}
              onChange={({ target: { value } }) => onLocationChange(value)}
              style={{
                marginBottom: 0,
              }}
            />
          </div>
        </div>
        <div
          onClick={onClearFilters}
          style={{
            display: "flex",
            maxHeight: 25,
            cursor: "pointer",
            marginTop: "1em",
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightMedium,
            color: (
              !imageColour && 
              !imageOrientation && 
              imageType.length === 0 && 
              imageCategory.length === 0 && 
              !location
              ) ? 
              palette.grey.lighter : 
              palette.text.primary,
            alignItems: "center"
          }}
        >
          Clear
          <FilterListRoundedIcon 
            style={{
              width: 16,
              height: 16
            }}
          />
        </div>
        </div>
      </div>
      {showBlankState && (
        <div
          style={{
            paddingLeft: isOnDesktopHD
              ? "4em"
              : isOnDesktopSD
                ? "2em"
                : isOnTablet
                  ? "1em"
                  : "0.5em",
            paddingRight: isOnDesktopHD
              ? "4em"
              : isOnDesktopSD
                ? "2em"
                : isOnTablet
                  ? "1em"
                  : "0.5em",
            textAlign: "start",
          }}
        >
          <p
            style={{
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightMedium,
              color: palette.text.primary,
            }}
          >
            We couldn’t find any results
          </p>
          <p
            style={{
              fontSize: typography.smallest,
              color: palette.text.secondary,
            }}
          >
            try searching for something else!
          </p>
        </div>
      )}
      {resultsType === "Pikcha" && (
        <MasonryGallerySearch
          images={images}
          isFollowing={false}
          gallery={gallery}
          getPhotos={getImages}
          resetGallery={resetGallery}
        />
      )}
      <div
        style={{
          paddingBottom: "4em",
        }}
      />
    </div>
  );
};
