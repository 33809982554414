import axios from "axios";

import {
  GALLERY_FOLLOWING_ERROR,
  GALLERY_FOLLOWING_LOADED,
  GALLERY_FOLLOWING_LOADING,
  GALLERY_FOLLOWING_RESET,
} from "./types";
import AuthorizeService from "../auth/AuthorizeService";

export const getFollowingPhotos = (pageNo,itemCount) => {
  return async (dispatch) => {
    try {
      dispatch(setFollowingLoading());
      // const token = await AuthorizeService.getAccessToken();
      // const { sub } = await AuthorizeService.getUser();
      // const res = await axios.get(`api/image/myFollowingImages/${sub}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const token = await AuthorizeService.getAccessToken();
      const parameters = {
        page: pageNo,
        itemInPage: itemCount,
      };
      const res = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? ""
            // : "http://localhost:1900"
            : "https://pikcha.local:1900"
        }/v1/search/api/PikchaPersonalized/MyFollowingImages`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: GALLERY_FOLLOWING_LOADED,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GALLERY_FOLLOWING_ERROR,
        payload: error.response,
      });
    }
  };
};

export const resetFollowingGallery = () => {
  return (dispatch) =>
    dispatch({
      type: GALLERY_FOLLOWING_RESET,
    });
};

export const setFollowingLoading = () => {
  return { type: GALLERY_FOLLOWING_LOADING };
};
