import React from 'react';
import { useTheme } from "@material-ui/core/styles";
import { PikchaButton } from '../components/buttons';
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import { ICON_SIZE_DEFAULT, ICON_SIZE_LARGE } from "../helpers/constants";
import pic404 from '../assets/images/404pic.svg';
import isMobile from "../components/Responsive/isMobile";
import isTablet from "../components/Responsive/isTablet";
import isDesktopSD from "../components/Responsive/isDesktopSD";
import isDesktopHD from "../components/Responsive/isDesktopHD";

export default () => {
  const { palette, typography, shape, spacing } = useTheme();
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  return (
    <div style={{marginTop: "56px", width: "100%", position:"relative"}}>
      <div 
        style={{
          marginTop: isOnDesktopHD ? "128px" : null, 
          marginLeft: isOnDesktopHD ? "128px" : null,
          padding: isOnDesktopHD ? null : "20px",
          textAlign: isOnDesktopHD ? null : "center"
        }}
      >
        <div style={{fontSize:"2em", color: typography.primary, fontWeight:"500"}}><strong>404 - Page Not Found</strong></div>
        <div style={{marginTop: "30px", marginBottom: "30px"}}>
          <div style={{fontSize:"1em", color: typography.secondary, fontWeight:"500"}}>We could not found the page you were looking for</div>
          <div style={{fontSize:"1em", color: typography.secondary, fontWeight:"500"}}>Try search for something else, or go home</div>
        </div>
        <div style={{textAlign: isOnDesktopHD || isOnTablet ? null : "center"}}>
        <PikchaButton 
          style = {{
            width: "240px",
          }}
        >
          <Link
            to="/"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <HomeIcon
              style={{
                ...ICON_SIZE_DEFAULT,
                marginRight: "0.5em",
              }}
            />
            Go Home
          </Link>
        </PikchaButton>
        </div>
      </div>
      <div style={{
          position: isOnDesktopHD ? "absolute" : null,
          bottom: "20px",
          right: isOnDesktopHD ? "140px" : null,
          width: isOnDesktopHD ? "60%" : null,
          height: "60%",
          marginTop: isOnDesktopHD ? null: "20px"        
      }}>
        <img 
          src={pic404} 
          style={{
            width: "100%",
            height: "100%"
          }}
        />
      </div>
    </div>
  )
}
