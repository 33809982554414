import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import { Link, useHistory } from "react-router-dom";

import {
  handleRegisterFirstName,
  handleRegisterLastName,
  handleRegisterLoadingStatus,
  handleRegisterPassword,
  handleRegisterUsername,
} from "../../../actions/register";
import { authenticate } from "../../../actions/auth";
import { setAlert } from "../../../actions/alert";
import {
  PikchaBackButton,
  PikchaCheckbox,
  PikchaButton,
  PikchaSecondaryButton,
  PikchaTextField,
  PikchaLoader,
} from "../../baseComponents";
import GoogleAuthButton from "../socialAuthButtons/GoogleAuthButton";
import FacebookAuthButton from "../socialAuthButtons/FacebookAuthButton";
import { socialAuthButtonBaseStyles } from "../socialAuthButtons/styles";
import {
  getCookie,
  isEmailValid,
  isPasswordValid,
  removeModalOverlay,
} from "../../../helpers/utils";
import {
  AUTH_CREATING_ACCOUNT_MESSAGE,
  PASSWORD_ERROR_MESSAGE,
  PASSWORD_REQUIREMENTS_LABEL,
} from "../../../helpers/authMessages/constants";
import authService from "../../../auth/AuthorizeService";

import "./RegistrationFormBase.css";
import { EMAIL_INVALID_MESSAGE } from "../../../helpers/constants";

const RegistrationFormBase = ({
  handleRegisterFirstName,
  handleRegisterLastName,
  handleRegisterUsername,
  handleRegisterPassword,
  handleRegisterLoadingStatus,
  register: { username, password, loading, firstName, lastName },
  authenticate,
  setAlert,
  // Only required when integrating within the auth modal.
  handleClickClose,
  // Conditionally renders some elements based on whether the form is in a
  // page or a modal.
  inModal,
}) => {
  const [emailFormVisible, setEmailFormVisible] = useState(false);
  const [policiesAgreement, setPoliciesAgreement] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const policiesTextRef = useRef(null);
  const checkboxRef = useRef(null);

  const { typography, palette, shape } = useTheme();
  const history = useHistory();

  const handleEmailFormVisibility = (state) => {
    setEmailFormVisible(state);
  };

  const handleEmailValidation = () => {
    setEmailError(!isEmailValid(username));
  };

  const handlePasswordValidation = () => {
    setPasswordError(!isPasswordValid(password));
  };

  const verifyFacebookUser = (data) => {
    const csrfToken = getCookie("XSRF-TOKEN");

    if (data && !data.authResponse) {
      // TODO: Handle errors with null authResponse object.
      return;
    }

    const access_token = data.authResponse.accessToken;

    handleRegisterLoadingStatus(true);

    axios
      .post(
        `api/account/fbauth`,
        { accessToken: access_token },
        {
          headers: {
            "X-XSRF-TOKEN": csrfToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const { status } = res;
        //console.log(res);
        if (status === 200) {
          //handleRegisterLoadingStatus(false);
          const state = window.location.href;
          authService
            .signIn(state)
            .then(() => {
              const ensureAuthenticated = async () => {
                await authenticate();
              };
              ensureAuthenticated()
                .then(() => {
                  if (handleClickClose) {
                    handleClickClose();
                    removeModalOverlay();
                  }
                  // TODO: Handle redirect if necessary from the cart
                  history.replace("/app");
                })
                .catch((error) => {
                  handleRegisterLoadingStatus(false);
                  console.log(`handleRegisterLoadingStatus : ${error}`);
                });
              handleRegisterLoadingStatus(false);
            })
            .catch((err) => {
              console.log("handleRegisterLoadingStatus 2 ", err);
              handleRegisterLoadingStatus(false);
            });
        }
      })
      .catch((err) => {
        handleRegisterLoadingStatus(false);
        setAlert("Please check your credentials", "error");
      });
  };

  const verifyGoogleUser = (data) => {
    const csrfToken = getCookie("XSRF-TOKEN");

    if (data && !data.wc) {
      // TODO: Handle errors with null authResponse object.
      return;
    }

    const access_token = data.wc.access_token;
    const id_token = data.wc.id_token;
    handleRegisterLoadingStatus(true);

    axios
      .post(
        `api/account/googleauth`,
        { accessToken: access_token, idToken: id_token },
        {
          headers: {
            "X-XSRF-TOKEN": csrfToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const { status } = res;
        //console.log(res);
        if (status === 200) {
          //handleRegisterLoadingStatus(false);
          const state = window.location.href;
          authService
            .signIn(state)
            .then(() => {
              const ensureAuthenticated = async () => {
                await authenticate();
              };
              ensureAuthenticated()
                .then(() => {
                  if (handleClickClose) {
                    handleClickClose();
                    removeModalOverlay();
                  }
                  // TODO: Handle redirect if necessary from the cart
                  history.replace("/app");
                })
                .catch((error) => {
                  handleRegisterLoadingStatus(false);
                  console.log(`handleRegisterLoadingStatus : ${error}`);
                });
              handleRegisterLoadingStatus(false);
            })
            .catch((err) => console.log("handleRegisterLoadingStatus 2", err));
        }
      })
      .catch((err) => {
        handleRegisterLoadingStatus(false);
        setAlert("Please check your credentials", "error");
      });
  };

  const handleEnter = ({ key }) => {
    if (key && key === "Enter") {
      handleEmailValidation();
      handlePasswordValidation();
      handleRegister();
    }
  };

  const handlePoliciesAgreement = ({ target: { checked } }) => {
    setPoliciesAgreement(checked);
  };

  const highlightError = () => {
    checkboxRef.current.classList.add("error-effect");
    policiesTextRef.current.classList.add("error-effect");

    setTimeout(() => {
      if (!policiesTextRef.current || !checkboxRef.current) {
        return;
      }
      policiesTextRef.current.classList.remove("error-effect");
      checkboxRef.current.classList.remove("error-effect");
    }, 5000);
  };

  const handleFacebook = (e) => {
    if (!policiesAgreement) {
      highlightError();
      return setAlert(
        "You must agree to Pikcha's user and privacy policy" +
          " before registering",
        "error"
      );
    }

    window.FB.getLoginStatus((response) => {
      if (response.status === "not_authorized") {
        return setAlert(
          "An error occurred connecting to the authentication service.",
          "error"
        );
      }

      if (response.status === "connected") {
        //console.log("already connected");
        verifyFacebookUser(response);
      } else {
        window.FB.login(
          (response) => {
            verifyFacebookUser(response);
          },
          { scope: "public_profile,email" }
        );
      }
    });
  };

  const handleGoogle = () => {
    if (!policiesAgreement) {
      highlightError();
      return setAlert(
        "You must agree to Pikcha's user and privacy policy" +
          " before registering",
        "error"
      );
    }

    Promise.resolve(window.gapi.auth2.getAuthInstance().signIn()).then(() => {
      var user = window.gapi.auth2.getAuthInstance().currentUser.get();
      //console.log(user);
      var access_token = user.wc.access_token;
      //call api
      verifyGoogleUser(user);
    });
  };
  const handleEmail = () => {
    if (!policiesAgreement) {
      highlightError();
      return setAlert(
        "You must agree to Pikcha's user and privacy policy" +
          " before registering",
        "error"
      );
    }

    setEmailFormVisible(true);
  };

  const handleEmailChange = (e) => {
    handleRegisterUsername(e);
    if (emailError) {
      handleEmailValidation();
    }
  };
  const handlePasswordChange = (e) => {
    handleRegisterPassword(e);
    if (passwordError) {
      handlePasswordValidation();
    }
  };

  const handleRegister = () => {
    if (
      !isEmailValid(username) ||
      !isPasswordValid(password) ||
      firstName.length === 0 ||
      lastName.length === 0
    ) {
      return setAlert("Please ensure the form is completed correctly");
    }

    handleRegisterLoadingStatus(true);

    const csrfToken = getCookie("XSRF-TOKEN");
    const dto = new FormData();
    dto.append("FName", firstName);
    dto.append("LName", lastName);
    dto.append("Email", username);
    dto.append("Password", password);

    axios
      .post(`api/account/register`, dto, {
        headers: {
          "X-XSRF-TOKEN": csrfToken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const { status } = res;

        if (status === 200) {
          const state = window.location.href;

          authService
            .signIn(state)
            .then(() => {
              const ensureAuthenticated = async () => {
                await authenticate();

                history.replace("/app");
              };

              ensureAuthenticated()
                .then(() => {
                  if (handleClickClose) {
                    handleClickClose();
                    removeModalOverlay();
                  }

                  // TODO: Handle redirect if necessary from the cart
                  //history.replace("/app");
                })
                .catch((error) => {
                  console.log(`handleRegisterLoadingStatus : ${error}`);
                });
            })
            .catch((err) => console.log("handleRegisterLoadingStatus 2", err));
        }
      })
      .catch((err) => {
        console.log("handleRegisterLoadingStatus ", err);
        handleRegisterLoadingStatus(false);
        setAlert("Please check your credentials", "error");
      });
  };

  return (
    <div className="flex-center justify-center RegisterFormBase-wrapper">
      <Helmet>
        <title>Pikcha — Join the Community</title>
      </Helmet>

      {!loading ? (
        !emailFormVisible ? (
          <div>
            <PikchaCheckbox
              className="pb-2-em pt-1-em pl-1-em"
              ref={checkboxRef}
              value={policiesAgreement}
              checked={policiesAgreement}
              onChange={handlePoliciesAgreement}
              label={
                <span
                  ref={policiesTextRef}
                  style={{
                    fontSize: typography.standard,
                    //fontWeight: typography.fontWeightMedium,
                    color: palette.text.secondary,
                    //whiteSpace: "nowrap"
                  }}
                >
                  I agree to the&nbsp;
                  <a href="/policies" className="no-underline" target="_blank">
                    User Policy
                  </a>
                  &nbsp;and&nbsp;
                  <a href="/policies" className="no-underline" target="_blank">
                    Privacy&nbsp;Policy
                  </a>
                </span>
              }
            />

            <div className="RegisterFormBase-buttonsContainer">
              <GoogleAuthButton onClick={handleGoogle}>
                Sign up with Google
              </GoogleAuthButton>
              <FacebookAuthButton onClick={handleFacebook}>
                Sign up with Facebook
              </FacebookAuthButton>
              <PikchaSecondaryButton
                onClick={handleEmail}
                style={{
                  ...socialAuthButtonBaseStyles,
                  textAlign: "left",
                }}
                fullWidth
                className="AuthButton-email"
              >
                <span style={{ fontSize: 26 }} className="flex-center">
                  <MailOutlineRoundedIcon fontSize="inherit" />
                </span>
                <div className="AuthButton-textHelper">Sign up with Email</div>
              </PikchaSecondaryButton>
            </div>

            <div className="AuthFormBase-footerText pt-1-em">
              <div
                style={{
                  paddingLeft: "0.5em",
                  fontSize: typography.smaller,
                  color: palette.grey.darkest,
                }}
              >
                <br />
                {!inModal && (
                  <span
                    style={{
                      color: palette.text.secondary,
                      fontSize: typography.small,
                    }}
                  >
                    Have an account?
                    <br />
                    <Link to="/auth/login">Log&nbsp;in</Link>
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {!inModal && (
              <div className="pb-8">
                <PikchaBackButton
                  onClick={() => handleEmailFormVisibility(false)}
                />
              </div>
            )}
            <form className="AuthFormBase-form">
              <PikchaTextField
                value={firstName}
                onChange={handleRegisterFirstName}
                label="First name"
                type="text"
                onKeyPress={handleEnter}
                autoFocus
                required
              />
              <PikchaTextField
                value={lastName}
                onChange={handleRegisterLastName}
                label="Last name"
                type="text"
                onKeyPress={handleEnter}
                required
              />
              <PikchaTextField
                autoComplete="username"
                value={username}
                onChange={handleEmailChange}
                onBlur={handleEmailValidation}
                onKeyPress={handleEnter}
                label="Email"
                type="email"
                required
                error={!!emailError}
                helperText={emailError ? EMAIL_INVALID_MESSAGE : null}
              />
              <PikchaTextField
                autoComplete="new-password"
                value={password}
                onChange={handlePasswordChange}
                onBlur={handlePasswordValidation}
                onKeyPress={handleEnter}
                label="Password"
                subLabel={PASSWORD_REQUIREMENTS_LABEL}
                type="password"
                required
                error={!!passwordError}
                helperText={passwordError ? PASSWORD_ERROR_MESSAGE : null}
              />
              <PikchaButton
                style={{
                  float: "right",
                }}
                onClick={handleRegister}
              >
                Register
              </PikchaButton>
            </form>
          </div>
        )
      ) : (
        <Fragment>
          <br />
          <PikchaLoader message={AUTH_CREATING_ACCOUNT_MESSAGE} />
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  register: state.registerReducer,
});

export default connect(mapStateToProps, {
  handleRegisterFirstName,
  handleRegisterLastName,
  handleRegisterUsername,
  handleRegisterPassword,
  handleRegisterLoadingStatus,
  authenticate,
  setAlert,
})(RegistrationFormBase);
