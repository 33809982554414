import React from "react";
import { useTheme } from "@material-ui/core";

import isMobile from "../Responsive/isMobile";

export default ({ adjustTopMargin }) => {
  const { typography, palette } = useTheme();

  const isOnMobile = isMobile();

  return (
    <div
      style={{
        alignSelf: "center",
        width: "100%",
        ...(!isOnMobile && {
          display: "flex",
        }),
        ...(isOnMobile && {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "4em",
          marginBottom: "4em",
        }),
      }}
    >
      <div className="beta-invite-code-logo" />
      <div
        style={{
          fontSize: typography.xLarge,
          color: palette.text.secondary,
          fontWeight: typography.fontWeightMedium,
          marginLeft: isOnMobile ? undefined : "1em",
          marginTop: isOnMobile ? "1em" : !adjustTopMargin ? "2.5em" : "2em",
        }}
      >
        <p>100&nbsp;Prints</p>
        <p>Every&nbsp;Image</p>
        <p>No&nbsp;Exception</p>
      </div>
    </div>
  );
};
