import axios from "axios";

import {
  GALLERY_SAVED_ERROR,
  GALLERY_SAVED_LOADED,
  GALLERY_SAVED_LOADING,
  GALLERY_SAVED_RESET,
} from "./types";

import AuthorizeService from "../auth/AuthorizeService";

export const getSavedImages = (
  start, 
  count, 
  sortBy,
  imageType = []
  ) => {
  return async (dispatch) => {
    try {
      setGallerySavedLoading();
      // const token = await AuthorizeService.getAccessToken();
      // const user = await AuthorizeService.getUser();

      // const res = await axios.get(`api/image/${user.sub}/MySavedImages`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      // console.log(res);
      const token = await AuthorizeService.getAccessToken();
      const parameters = {
        page: start,
        itemInPage: count,
        ImageType: imageType,
        SortBy: sortBy, //MS,MV,PHL,PLH,RV
      };
      const res = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? ""
            // : "http://localhost:1900"
            : "https://pikcha.local:1900"
        }/v1/search/api/PikchaPersonalized/MySavedImages`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: GALLERY_SAVED_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GALLERY_SAVED_ERROR,
        payload: [],
      });
      console.log("getSavedImages ", err);
    }
  };
};

export const setGallerySavedLoading = () => {
  return (dispatch) => {
    dispatch({
      GALLERY_SAVED_LOADING,
    });
  };
};

export const resetSavedGallery = () => {
  return (dispatch) => {
    dispatch({
      type: GALLERY_SAVED_RESET,
    });
  };
};
