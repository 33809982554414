import React from "react";

import {PikchaIconButton} from "../../../components/baseComponents";
import FullscreenModal
  from "../../modals/FullscreenModal/FullscreenModal";
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';

export const ProductItemFullscreenButton = ({
                                         image,
                                         isFullscreenModalOpen,
                                         onCloseFullscreenModal,
                                         onOpenFullscreenModal
                                       }) => {

  return (
    <>
      <div
        style={{
          alignSelf: "flex-end",
        }}
      >
        <PikchaIconButton
          onClick={onOpenFullscreenModal}
          size="small"
          icon={<FullscreenRoundedIcon alt="Fullscreen Icon" />}
        />
      </div>
      <FullscreenModal
        image={image}
        open={isFullscreenModalOpen}
        onClose={onCloseFullscreenModal}
      />
    </>
  );
}
