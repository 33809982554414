export const BasketNotificationUrl = (state) => {
  let settings;
  if (state) {
    settings = state.settingsReducer;
  }

  let settingsValues = {};
  if (settings) {
    settingsValues = settings.settings;
  }

  const BasketNotificationUrl = settingsValues.basketNotificationUrl;

  return BasketNotificationUrl;
};

export const IdentityUrl = (state) => {
  let settings;
  if (state) {
    settings = state.settingsReducer;
  }

  let settingsValues = {};
  if (settings) {
    settingsValues = settings.settings;
  }

  const IdentityUrl = settingsValues.identityUrl;

  return IdentityUrl;
};

export const searchAPIUrl = (state) => {
  let settings;
  if (state) {
    settings = state.settingsReducer;
  }

  let settingsValues = {};
  if (settings) {
    settingsValues = settings.settings;
  }

  const { searchAPIUrl } = settingsValues;

  return searchAPIUrl;
};

export const competitionManagerAPIUrl = (state) => {
  let settings;
  if (state) {
    settings = state.settingsReducer;
  }

  let settingsValues = {};
  if (settings) {
    settingsValues = settings.settings;
  }

  const { competitionManagerAPIUrl } = settingsValues;

  return competitionManagerAPIUrl;
};

export const inAppNotificationUrl = (state) => {
  let settings;
  if (state) {
    settings = state.settingsReducer;
  }

  let settingsValues = {};
  if (settings) {
    settingsValues = settings.settings;
  }

  const { inAppNotificationUrl } = settingsValues;

  return inAppNotificationUrl;
};

export const payoutUrl = (state) => {
  let settings;
  if (state) {
    settings = state.settingsReducer;
  }

  let settingsValues = {};
  if (settings) {
    settingsValues = settings.settings;
  }

  const { payoutUrl } = settingsValues;

  return payoutUrl;
};
