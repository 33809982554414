import {
  RELATED_ALBUMS_LOADED,
  RELATED_ALBUMS_LOADING,
  RELATED_ALBUMS_ERROR,
  RELATED_ALBUMS_RESET,
} from "../actions/types";

import {
  GALLERY_ITEMINPAGE_DEFAULT,
  GALLERY_PAGE_DEFAULT,
} from "../helpers/constants";

const initialState = {
  albums:[],
  count: GALLERY_ITEMINPAGE_DEFAULT,
  start: GALLERY_PAGE_DEFAULT,
  error: null,
  hasMore: true,
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RELATED_ALBUMS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RELATED_ALBUMS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case RELATED_ALBUMS_LOADED:
      const _albumResult = [...payload];
      //console.log(_albumResult);
      return {
        ...state,
        albums: _albumResult,
        loading: false,
        start: state.start,
        hasMore: false,
      };

    case RELATED_ALBUMS_RESET:
      return {
        ...state,
        albums:[],
        count: GALLERY_ITEMINPAGE_DEFAULT,
        start: GALLERY_PAGE_DEFAULT,
        error: null,
        hasMore: true,
        loading: true,
      };

    default:
      return state;
  }
};
