import React from "react";
import { useLocation } from "react-router-dom";

import { useRouteWithoutSideDrawer } from "../../hooks/useRouteWithFooter";

export default ({ children }) => {
  const { pathname } = useLocation();

  const isRouteWithoutSideDrawer = useRouteWithoutSideDrawer();

  let className = "mainView";

  if (!isRouteWithoutSideDrawer) {
    className = `${className} flex`;
  }

  if (pathname === "/checkout") {
    className = `${className} flex-column no-min-height`;
  }

  return (
    <div className={className}>
      {children}
    </div>
  );
};
