import {
  GALLERY_CACHED_STATE,
  GALLERY_CHANGE_IMAGE_TYPE,
  GALLERY_ERROR,
  GALLERY_FILTERED_LOADED,
  GALLERY_FILTERED_LOADED_ERROR,
  GALLERY_LOADED,
  GALLERY_LOADING,
  GALLERY_RESET,
  GET_USER_COLLECTION_MORE,
  GET_USER_COLLECTION_MORE_FAILED,
  SEARCH_ERROR,
  SEARCH_LOADED,
  SEARCH_LOADING
} from "../actions/types";
import {GALLERY_ITEMINPAGE_DEFAULT, GALLERY_PAGE_DEFAULT,} from "../helpers/constants";

const initialState = {
  photos: [],
  count: GALLERY_ITEMINPAGE_DEFAULT,
  start: GALLERY_PAGE_DEFAULT,
  getCached: false,
  loading: true,
  error: null,
  hasMore: true,
  imageType: {
    typeName: "",
    imageTypeId : ""
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GALLERY_CHANGE_IMAGE_TYPE:
      return {
        ...state,
        imageType: payload,
      };
    case GALLERY_CACHED_STATE:
      return {
        ...state,
        getCached: payload,
      };
    case GALLERY_FILTERED_LOADED:
      return {
        ...state,
        photos: [...payload],
        loading: false,
        hasMore: true,
        start: GALLERY_PAGE_DEFAULT + 1,
      };
      case GALLERY_FILTERED_LOADED_ERROR:
        return {
          ...state,
          photos: [...payload],
          loading: false,
          hasMore: false,
          start: GALLERY_PAGE_DEFAULT + 1,
        };
    case GALLERY_LOADED:
      return {
        ...state,
        photos: [...state.photos, ...payload],
        loading: false,
        hasMore: true,
        start: state.start + 1,
      };
    case GALLERY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: false,
      };
    case GALLERY_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_COLLECTION_MORE: {
      const photos = [...state.photos, ...payload];
      return {
        ...state,
        photos: photos,
        loading: false,
        start: state.start + state.count,
        hasMore: payload.length >= state.count,
      };
    }

    case GET_USER_COLLECTION_MORE_FAILED: {
      return {
        ...state,
        loading: false,
        hasMore: false,
      };
    }

    case SEARCH_LOADED:
      return {
        ...state,
        photos: [...state.photos, ...payload],
        loading: false,
        start: state.start + state.count,
        hasMore: payload.length > 0,
      };
    case SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: false,
      };

    case GALLERY_RESET:
      return initialState;

    default:
      return state;
  }
};
