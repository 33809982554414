import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import {
  makeStyles
} from "@material-ui/core/styles";

const useStyles = ({ disabled, error }) =>
  makeStyles(() => ({
    textField: {
      marginBottom: "1.75rem",
      "& > div > textarea": {
        resize: "vertical"
      },
      "& #outlined-error": {
        borderWidth: '1px',
        borderColor: error && !disabled ? '#ED0000 !important' : '',
        backgroundColor: error && !disabled && '#FFE7E6 !important'
      }
    },
    textFieldLabel: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      fontWeight: 'bold' as 'bold',
      textOverflow: "ellipsis",
      fontSize: '0.8em',
      userSelect: "none",
      color: '#343A3F!important',
      marginBottom: ".25rem",
      transform: "translateY(-1.1rem) !important",
    },
    textFieldInput: {
      height: '40px',
      backgroundColor: disabled ? '#F2F4F8' : '#fff',
      borderRadius: '4px',
      fontSize: '16px',
      fontWeight: 'normal' as 'normal',
      fontFamily: 'Montserrat',
      "& > fieldset": {
        borderWidth: 1,
        borderColor: error && !disabled ? '#ED0000 !important' : '#DBE0E4 !important'
      },
      "& #outlined-error": {
        borderColor: error && !disabled ? '#ED0000 !important' : '',
        backgroundColor: error && !disabled && '#FFE7E6 !important',
        borderRadius: '4px'
      }
    }
  }))(TextField);

export default ({
  style = {},
  isFullWidth = null,
  label = '',
  children,
  disabled = false,
  error,
  InputProps = null,
  endIndicator,
  startAdornment,
  required,
  helperText,
  ...rest
  // Any additional props will be passed to the
  // underlying material-ui component.
}) => {
  InputProps = {
    ...(InputProps || {}),
    ...{
      notched: false,
    },
    ...(startAdornment || {})
  };
  const classes = useStyles({ disabled, error });
  return (
    <>
      <div
        className={isFullWidth ? "full-width" : ""}
        style={{
          position: "relative",
          ...style,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TextField
            style={{
              ...style,
            }}
            fullWidth={isFullWidth === undefined || isFullWidth === true}
            id={error ? "outlined-error" : ''}
            disabled={disabled}
            error={error}
            variant="outlined"
            type="text"
            className={classes.textField}
            label={
              <Fragment>
                {label}
                {required && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: 600,
                      fontFamily: 'Montserrat, sans-serif'
                    }}
                  >
                    *
                  </span>
                )}
              </Fragment>
            }
            helperText={
              <span
                style={{
                  color: "red",
                  fontWeight: 600,
                  fontFamily: 'Montserrat, sans-serif'
                }}
              >
                {helperText}
              </span>
            }
            InputLabelProps={{
              className: classes.textFieldLabel,
              shrink: true,
              disableAnimation: true,
            }}
            size="small"
            InputProps={{
              className: classes.textFieldInput,
              ...InputProps,
              endAdornment: endIndicator ? endIndicator : undefined
            }}
            {...rest}
          />
        </div>
      </div>
    </>
  )
}