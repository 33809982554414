import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { ICON_SIZE_DEFAULT } from "../../helpers/constants";
import { addView } from "../../actions/gallery";
import { toggleSafeSearch } from "../../actions/ageRestriction";
import { showProductModal } from "../../actions/modal";
import { isSafeSearchEnabled, userId, userFollowing, isUserLoggedIn } from "../../selectors/account";
import { handleAuthModal } from "../../actions/authModal";
import UserDetails from "../../components/UserDetails/UserDetails";
import { PikchaButton } from "../../components/baseComponents";
import { followArtist, unfollowArtist } from "../../actions/account";
import isTablet from "../../components/Responsive/isTablet";

const ArtistRow = ({ row, loggedIn, addView, showProductModal, safeSearch, toggleSafeSearch, followArtist, unfollowArtist, userId, userFollowing, handleAuthModal }) => {
  const {
    nationality,
    id,
    nickName,
    friendlyUrl,
    userHandle,
    fName,
    lName,
    images,
    avatar,
    placeholder,
    artistStats
  } = row;

  const { palette, typography } = useTheme();

  const [hoverState, setHoverState] = useState(null);
  const [isCardHovering, setIsCardHovering] = useState(false);

  const [firstImageLoaded, setFirstImageLoaded] = useState(false);
  const [secondImageLoaded, setSecondImageLoaded] = useState(false);
  const [thirdImageLoaded, setThirdImageLoaded] = useState(false);
  const isOnTablet = isTablet();

  const tile = useRef(null);
  const [itemWidth, setItemWidth] = useState(0);
  
  let safeSearchFilter1 = (images && images.length > 0) ? (images[0].isNudity? true :false):false;  
  let safeSearchFilter2 = (images && images.length > 1) ? (images[1].isNudity? true :false):false;
  let safeSearchFilter3 = (images && images.length > 2) ? (images[2].isNudity? true :false):false;


  const handleResize = () => {
    const { width } = tile.current ? window.getComputedStyle(tile.current) : {};

    const widthValue = (parseFloat(width)-64)*0.3;

    setItemWidth(widthValue);
  };
  useEffect(() => {
    handleResize();
  }, [tile.current, hoverState]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (placeholder || !images[0]) {
      setFirstImageLoaded(false);
    }
    if (placeholder || !images[1]) {
      setSecondImageLoaded(false);
    }
    if (placeholder || !images[2]) {
      setThirdImageLoaded(false);
    }
  }, [placeholder, images]);

  const handleClickOpen = (image) => {
    if (!image) {
      return;
    }

    const { isNudity } = image;
    let safeSearchFilter = isNudity && safeSearch;

    if (!safeSearchFilter) {
      addView(image.id);
      showProductModal({
        ...image,
        artist: {
          id: id,
          nickName: nickName,
          fName: fName,
          lName: lName,
          avatar: avatar,
          nationality:nationality,
          friendlyUrl: friendlyUrl
        },
      });
    } else {
      toggleSafeSearch();
    }
  };
  const handleMouseOver = (id) => {
    setHoverState(id);
  };
  const handleMouseLeave = (id) => {
    setHoverState(undefined);
  };

  const handleCardHover = () => {
    setIsCardHovering(true);
  };
  const handleCardHoverLeave = () => {
    setIsCardHovering(false);
  };

  const followingListContains = (artistId) => {
    let following = false;
    (userFollowing || []).map((item)=>{
      if(item.id === artistId){
        following = true
      }
    })
    return following;
  }

  return (
    <div
      style={{
        width: "100%"
      }}
      ref={tile}
    >
      <div
        style={{
          marginTop  : "5px",
          ...(isCardHovering
            ? {
              border : `1px solid ${palette.grey.light}`,
              borderRadius: 6,
              backgroundColor: "#FFFFFF",
              cursor:"pointer",
              boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25), -4px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.25)",
            }
            : {
              border: `1px solid ${palette.grey.light}`,
              borderRadius: 6,
              backgroundColor: "#FFFFFF",
            }),
        }}
        onMouseEnter={handleCardHover}
        onMouseLeave={handleCardHoverLeave}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1em 2em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "4em",
            }}
          >
            <div className="artist-info">
              {placeholder && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: 24,
                      minWidth: 24,
                      height: 24,
                      borderRadius: "50%",
                      backgroundColor: palette.grey.light,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "0.5em",
                      borderRadius: 6,
                      width: "100%",
                      minWidth: 128,
                      backgroundColor: palette.grey.light,
                    }}
                  />
                </div>
              )}
              {!placeholder && (
                <UserDetails
                  id={id}
                  nickName={nickName}
                  userHandle={userHandle}
                  avatar={avatar}
                  fName={fName}
                  lName={lName}
                  nationality={nationality || null}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "0.5em",
              justifyContent: isOnTablet ? "space-evenly" : "space-between"
            }}
          >
            <div
              style={{
                cursor: "pointer",
                overflow: "hidden",
                width: "30%",
                maxWidth: 150,
                maxHeight: 150
              }}
              onMouseEnter={() => handleMouseOver(1)}
              onMouseLeave={() => handleMouseLeave(1)}
            >
              {placeholder && (
                <div
                  style={{
                    backgroundColor: palette.grey.light,
                    height: itemWidth,
                    width: "100%"
                  }}
                />
              )}
              {!placeholder && (
                <>
                  {safeSearch && safeSearchFilter1 && firstImageLoaded &&(
                    <div
                      onClick={(e) => handleClickOpen(images[0])}
                      style={{
                        position: "absolute",
                        height: "150px",
                        maxWidth: "150px",
                        zIndex: 5,
                        backgroundColor: "rgba(255, 255, 255, .8)",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: palette.text.primary,
                      }}
                    >
                      <VisibilityRoundedIcon
                        style={{
                          ...ICON_SIZE_DEFAULT,
                        }}
                      />
                    </div>
                  )}
                  {hoverState === 1 && (
                    <div
                      onClick={(e) => handleClickOpen(images[0])}
                      style={{
                        position: "absolute",
                        backgroundColor: palette.text.disabled,
                        height: itemWidth,
                        width: itemWidth,
                        zIndex: 200,
                      }}
                    />
                  )}
                  {!firstImageLoaded && (
                    <div
                      style={{
                        width: "100%",
                        height: itemWidth,
                        backgroundColor: palette.grey.light,
                      }}
                    />
                  )}
                  <img
                    src={images[0] ? images[0].thumbnail : undefined}
                    width={150}
                    height={150}
                    style={{
                      display: firstImageLoaded ? "block" : "none",
                      width: "100%",
                      height: itemWidth,
                      objectFit: "cover",
                      backgroundColor: palette.grey.light,
                      ...(safeSearch && safeSearchFilter1
                        ? {
                          opacity: ".7",
                          filter: "blur(12px)",
                        }
                        : {}),
                    }}
                    onClick={(e) => handleClickOpen(images[0])}
                    onLoad={() => setFirstImageLoaded(true)}
                  />
                </>
              )}
            </div>
            <div
              style={{
                marginLeft: "1em",
                marginRight: "1em",
                cursor: "pointer",
                overflow: "hidden",
                width: "30%",
                maxWidth: 150,
                maxHeight: 150
              }}
              onMouseEnter={() => handleMouseOver(2)}
              onMouseLeave={() => handleMouseLeave(2)}
            >
              {placeholder && (
                <div
                  style={{
                    backgroundColor: palette.grey.light,
                    height: itemWidth,
                    width: "100%",
                  }}
                />
              )}
              {!placeholder && (
                <>
                  {safeSearch && safeSearchFilter2 && secondImageLoaded && (
                    <div
                      onClick={(e) => handleClickOpen(images[1])}
                      style={{
                        position: "absolute",
                        height: "150px",
                        maxWidth: "150px",
                        zIndex: 5,
                        backgroundColor: "rgba(255, 255, 255, .8)",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: palette.text.primary,
                      }}
                    >
                      <VisibilityRoundedIcon
                        style={{
                          ...ICON_SIZE_DEFAULT,
                        }}
                      />
                    </div>
                  )}
                  {hoverState === 2 && (
                    <div
                      onClick={(e) => handleClickOpen(images[1])}
                      style={{
                        position: "absolute",
                        backgroundColor: palette.text.disabled,
                        height: itemWidth,
                        width: itemWidth,
                        zIndex: 200,
                      }}
                    />
                  )}
                  {!secondImageLoaded && (
                    <div
                      style={{
                        width: "100%",
                        height: itemWidth,
                        backgroundColor: palette.grey.light,
                      }}
                    />
                  )}
                  <img
                    src={images[1] ? images[1].thumbnail : undefined}
                    width={150}
                    height={150}
                    style={{
                      display: secondImageLoaded ? "block" : "none",
                      width: "100%",
                      height: itemWidth,
                      objectFit: "cover",
                      backgroundColor: palette.grey.light,
                      ...(safeSearch && safeSearchFilter2
                        ? {
                          opacity: ".7",
                          filter: "blur(12px)",
                        }
                        : {}),
                    }}
                    onClick={(e) => handleClickOpen(images[1])}
                    onLoad={() => setSecondImageLoaded(true)}
                  />
                </>
              )}
            </div>
            <div
              style={{
                cursor: "pointer",
                overflow: "hidden",
                width: "30%"
              }}
              onMouseEnter={() => handleMouseOver(3)}
              onMouseLeave={() => handleMouseLeave(3)}
            >
              {placeholder && (
                <div
                  style={{
                    backgroundColor: palette.grey.light,
                    height: itemWidth,
                    width: "100%"
                  }}
                />
              )}
              {!placeholder && (
                <>
                  {safeSearch && safeSearchFilter3 && thirdImageLoaded && (
                    <div
                      onClick={(e) => handleClickOpen(images[2])}
                      style={{
                        position: "absolute",
                        height: "150px",
                        maxWidth: "150px",
                        zIndex: 5,
                        backgroundColor: "rgba(255, 255, 255, .8)",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: palette.text.primary,
                      }}
                    >
                      <VisibilityRoundedIcon
                        style={{
                          ...ICON_SIZE_DEFAULT,
                        }}
                      />
                    </div>
                  )}
                  {hoverState === 3 && (
                    <div
                      onClick={(e) => handleClickOpen(images[2])}
                      style={{
                        position: "absolute",
                        backgroundColor: palette.text.disabled,
                        height: itemWidth,
                        width:itemWidth,
                        zIndex: 200,
                      }}
                    />
                  )}
                  {!thirdImageLoaded && (
                    <div
                      style={{
                        width: "100%",
                        height: itemWidth,
                        backgroundColor: palette.grey.light,
                      }}
                    />
                  )}
                  <img
                    src={images[2] ? images[2].thumbnail : undefined}
                    width={150}
                    height={150}
                    style={{
                      display: thirdImageLoaded ? "block" : "none",
                      width: "100%",
                      height: itemWidth,
                      objectFit: "cover",
                      backgroundColor: palette.grey.light,
                      ...(safeSearch && safeSearchFilter3
                        ? {
                          opacity: ".7",
                          filter: "blur(12px)",
                        }
                        : {}),
                    }}
                    onClick={(e) => handleClickOpen(images[2])}
                    onLoad={() => setThirdImageLoaded(true)}
                  />
                </>
              )}
            </div>
          </div>
          {userId !== (artistStats || {}).id && (
            <div
              style={{
                margin: "1em 0 0 0",
                width: "100%"
              }}
            >
              <PikchaButton
                action="octonary"
                style={{
                  width: "100%",
                  fontWeight: typography.fontWeightBold
                }}
                onClick={()=>{
                  if(!loggedIn){
                    handleAuthModal(true, "following");
                  } else {
                    if(!followingListContains(artistStats.id)){
                      followArtist(userId, artistStats.id, `${fName} ${lName}`)
                    } else {
                      unfollowArtist(userId, artistStats.id, `${fName} ${lName}`)
                    }
                  }
                }}
              >
                {followingListContains((artistStats || {}).id) ? "Following" : "Follow"}
              </PikchaButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: isUserLoggedIn(state),
  safeSearch: isSafeSearchEnabled(state),
  userId: userId(state),
  userFollowing: userFollowing(state)
});

export default connect(mapStateToProps, {
  addView,
  showProductModal,
  toggleSafeSearch,
  followArtist,
  unfollowArtist,
  handleAuthModal
})(ArtistRow);
