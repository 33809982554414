import { TypographyBody, TypographyButton, TYPOGRAPHY_BODY_SIZE, TYPOGRAPHY_BUTTON_TYPE } from "@pikcha/typography";
import React from "react";
import { PikchaModal } from "../../../components/baseComponents";

const DeleteModal = ({ open, onClose,onDeleteAlbum }) => {
  return (
    <div>
      <PikchaModal
        hasCustomHeader={true}
        fullWidth={false}
        onClose={onClose}
        maxWidth="460px"
        open={open}
        PaperProps={{
          style: {
            overflow: "hidden",
            padding: "1.5em"
          },
        }}
        minHeight={1}
      >
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: 'center'
          }}
        >
          <TypographyBody
            style={{ marginBottom: '1em' }}
            size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
          >
            Delete your collection?
          </TypographyBody>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
            style={{
              textAlign: 'center',
              marginBottom: '1.5em'
            }}
          >
            Are you sure you want to remove this collection?
          </TypographyBody>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <TypographyButton
              buttonStyle={{ width: '48%' }}
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
              onClick={onClose}
            >
              No, go back
            </TypographyButton>
            <TypographyButton
              buttonStyle={{ width: '48%' }}
              component={TYPOGRAPHY_BUTTON_TYPE.Primary}
              onClick={onDeleteAlbum}
            >
              Yes,delete
            </TypographyButton>
          </div>
        </div>
      </PikchaModal>
    </div>
  )
}

export default DeleteModal
