import React from "react";
import { useTheme, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { ICON_SIZE_DEFAULT, ICON_SIZE_SMALL, ICON_SIZE_EXTRA_LARGE } from "../../../helpers/constants";

const StyledIconButton = withStyles({
  root: {
    width: 32,
    height: 32,
    marginRight: 8,
    marginTop: 4,
    padding: 8,
    borderRadius: 6,
  },
})(IconButton);

export default ({ onClick, lightVariant, smallVariant, largeVariant, ...rest }) => {
  const { palette } = useTheme();

  return (
    <StyledIconButton
      style={{
        marginRight: "0.1em",
      }}
      onClick={onClick}
      {...rest}
    >
      <CloseRoundedIcon
        style={smallVariant ? ICON_SIZE_SMALL : largeVariant?  ICON_SIZE_EXTRA_LARGE : ICON_SIZE_DEFAULT}
        htmlColor={lightVariant ? palette.grey.lightest : palette.grey.dark}
      />
    </StyledIconButton>
  );
};
