import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TYPOGRAPHY_BODY_SIZE,
  TypographyBody,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TEXT,
} from "@pikcha/typography";
import "./cropper.css";
import {
  PikchaCheckbox,
} from "../../../components/baseComponents";

export default ({
  onHandleClose, 
  setUploadStep,
  uploadStep,
  setAlert
}) => {
  const [policiesAgreement, setPoliciesAgreement] = useState(false);

  const handlePoliciesAgreement = ({ target: { checked } }) => {
    setPoliciesAgreement(checked);
  };

  const handleClick = () => {
    if (policiesAgreement) {
      setUploadStep(uploadStep + 1)
    } else {
      setAlert("You must agree to Pikcha's terms", "error")
    }
  }

  return (
    <div
      style={{
        display: "flex",
        padding: "0 40",
        justifyContent: "center",
        margin: "auto 0"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <div>
          <div>
            <TypographySubtitle
              component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
            >
              Uploading Rules
            </TypographySubtitle>
            <ul
              style={{
                listStyle: "disc",
                marginTop: '2em',
                marginLeft: 20,
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                <li>Have not previously sold or licensed this image</li>
                <li>Have not shared the raw image file or a variation above 2mp, without watermarking</li>
                <li>Any depicted or owners of depicted property gave you the permisson to publish</li>
                <li>Upload content that you own the copyright to and that you have created yourself</li>
              </TypographyBody>
            </ul>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: '3em'
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{
                background: '#F2F4F8',
                textAlign: 'center',
                padding: '2em'
              }}
            >
              Your images must meet a minimum requirement of 15MP, so that it can be printed at high quality in a variation of sizes.
            </TypographyBody>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1em"
            }}
          >
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{
                background: '#F2F4F8',
                textAlign: 'center',
                padding: '2em'
              }}
            >
              You grant Pikcha an exclusive sublicence to sell content that you upload to the platform, as defined in the user agreement
            </TypographyBody>
          </div>
        </div>
        <div style={{ marginTop: '2.75em', marginBottom: '1.5em' }}>
          <TypographyBody
            style={{ display: 'flex', flexDirection: 'row' }}
            size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
          >
            For more information, please read our&nbsp;
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              <Link style={{color:'#343A3F'}} to="/policies/user-agreement" onClick={onHandleClose}>user agreement</Link>
            </TypographyBody>
            &nbsp;or view our&nbsp;
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              <Link style={{color:'#343A3F'}} to="/guides" onClick={onHandleClose}>uploading guide</Link>
            </TypographyBody>
            &nbsp;for more help
          </TypographyBody>
        </div>
        <div>
          <PikchaCheckbox
            style={{
              paddingLeft: '10px',
              paddingBottom: '1.75em'
            }}
            value={policiesAgreement}
            checked={policiesAgreement}
            onChange={handlePoliciesAgreement}
            label={
              <TypographyBody style={{ marginTop: '-3px' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>I understand and agree</TypographyBody>
            }
          />
          <TypographyButton
            buttonText={TYPOGRAPHY_BUTTON_TEXT.Regular}
            action="primary"
            onClick={() => {
              handleClick()
            }}
          >
            Agree & Continue
          </TypographyButton>
        </div>
      </div>
    </div >
  )
}
