import React from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import { useTheme } from "@material-ui/core/styles";

import ArtistRow from "../../containers/search/ArtistRow";

import {
  PikchaDropdown,
  PikchaReusableDropdown,
  PikchaToggleButton,
  PikchaToggleButtonGroup,
  PikchaReusableTabsGroup,
  PikchaReusableTab
} from "../../components/baseComponents";
import FilterIcon from "../../assets/images/FilterIcon.svg";
import PublicRoundedIcon from "@material-ui/icons/PublicRounded";

import isDesktopHD from "../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isDesktop from "../../components/Responsive/isDesktop";
import isTablet from "../../components/Responsive/isTablet";

export default ({
  toggleSafeSearch,
  searchTerm,
  isSearchLoading,
  resultsType,
  resultsTypeChange,
  sortBy,
  onSortByChange,
  onClearFilters,
  onApplyFilters,
  countries,
  onCountryChange,
  country,
  artists,
  getArtists,
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();

  const { palette, typography } = useTheme();

  let artistRows = artists.artists;
  //console.log(artistRows);
  if (isSearchLoading) {
    for (let i = 0; i < 8; i++) {
      artistRows = [
        ...artistRows,
        ...[
          {
            placeholder: true,
          },
        ],
      ];
    }
  }

  const showBlankState = !isSearchLoading && artistRows.length < 1;

  return (
    <div
      style={{
        paddingTop: "4em",
        width: "100%",
      }}
    >
      <div
        style={{
          paddingLeft: isOnDesktopHD
            ? "4em"
            : isOnDesktopSD
              ? "2em"
              : isOnTablet
                ? "1em"
                : "0.5em",
          paddingRight: isOnDesktopHD
            ? "4em"
            : isOnDesktopSD
              ? "2em"
              : isOnTablet
                ? "1em"
                : "0.5em",
        }}
      >
        <div
          style={{
            marginTop: "4em",
            marginBottom: "2em"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                color: palette.text.primary,
                fontSize: typography.large,
                fontWeight: typography.fontWeightBold,
                textTransform: "capitalize"
              }}
            >
              {searchTerm}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end"
          }}
        >
            <div>
              <PikchaReusableTabsGroup
                value={resultsType}
                onChange={resultsTypeChange}
                aria-label="Results Type Selector"
              >
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Pikcha"
                  label="Image"
                  aria-label="Pikcha"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Artist"
                  label="Artist"
                  aria-label="Artist"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12
                  }}
                  value="Album"
                  label="Album"
                  aria-label="Album"
                />
              </PikchaReusableTabsGroup>
            </div>
            <div
            style={{
              width: 232,
              marginLeft: "auto",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            <PikchaReusableDropdown
              onChange={(sortBy)=>{onSortByChange(sortBy)}}
              style={{
                width: 152,
                fontWeight: 600,
                fontSize: ".93rem"
              }}
              itemStyle={{
                width: 180,
                fontWeight: 600,
                fontSize: ".93rem"
              }}
              options={[
                { value: "RV", label: "Recommended" },
                { value: "MV", label: "Most Viewed" },
                { value: "MF", label: "Most Followed" },
                { value: "MS", label: "Most Sales" },
                { value: "PHL", label: "Price High to Low" },
                { value: "PLH", label: "Price Low to High" },
              ]}
              value={sortBy}
            />
          </div>
        </div>
      </div>
      {resultsType === "Artist" && (
        <div
          style={{
            marginTop: "4em",
            textAlign: "center",
          }}
        >
          <InfiniteScroll
            dataLength={artists.artists.length}
            next={() => {
              if(artists.start > 1){
                getArtists(artists.count, artists.start)
              }
            }}
            hasMore={artists.hasMore}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              marginLeft: isOnDesktopHD
                ? "4em"
                : isOnDesktopSD
                  ? "2em"
                  : isOnTablet
                    ? "1em"
                    : "0.5em",
              marginRight: isOnDesktopHD
                ? "4em"
                : isOnDesktopSD
                  ? "2em"
                  : isOnTablet
                    ? "1em"
                    : "0.5em",
            }}
          >
            {showBlankState && (
              <div
                style={{
                  textAlign: "start",
                }}
              >
                <p
                  style={{
                    fontSize: typography.smallest,
                    fontWeight: typography.fontWeightMedium,
                    color: palette.text.primary,
                  }}
                >
                  We couldn’t find any results
                </p>
                <p
                  style={{
                    fontSize: typography.smallest,
                    color: palette.text.secondary,
                  }}
                >
                  try searching for something else!
                </p>
              </div>
            )}
            {artistRows.map((row, index) => (
              <ArtistRow key={index + 1} rank={index + 1} row={row} toggleSafeSearch={toggleSafeSearch} />
            ))}
          </InfiniteScroll>
        </div>
      )}
      <div
        style={{
          paddingBottom: "4em",
        }}
      />
    </div>
  );
};
