import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import LoginFormBase from "../components/authComponents/LoginFormBase/LoginFormBase";
import RegisterFormBase from "../components/authComponents/RegistrationFormBase/RegistrationFormBase";
import ForgotPasswordBase from "../components/authComponents/ForgotPassword/ForgotPasswordBase";
import ResetPasswordConfirmation from "../components/authComponents/ForgotPassword/ResetPasswordConfirmation";

const AuthRoutes = ({ match, account: { user } }) => {
  if (user) {
    return <Redirect to="/app" />;
  }

  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}/login`}
        component={LoginFormBase}
      />
      <Route
        exact={true}
        path={`${match.path}/register`}
        component={RegisterFormBase}
      />
      <Route
        exact={true}
        path={`${match.path}/reset`}
        component={ForgotPasswordBase}
      />
      <Route
        exact={true}
        path={`${match.path}/reset-confirm`}
        component={ResetPasswordConfirmation}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps)(AuthRoutes);
