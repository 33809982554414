import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { getUploadedAt } from "../../../helpers/utils";
import { ICON_SIZE_DEFAULT } from "../../../helpers/constants";
import isMobile from "../../../components/Responsive/isMobile";
import { TypographyBody, TypographyButton, TYPOGRAPHY_BODY_SIZE, TYPOGRAPHY_BUTTON_TYPE } from "@pikcha/typography";
import isTablet from "../../../components/Responsive/isTablet";

export default ({ item }) => {
  const [informationOpen, setInformationOpen] = useState(false);
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();

  const onInformationExpandClicked = (e) => {
    e.preventDefault();
    setInformationOpen(!informationOpen);
  };

  return (
    <div style={{ margin: isOnMobile ? '1em' : '2em' }}>
      {!isOnMobile && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: '2.5em' }}>
            <img
              src={item.thumbnail}
              style={{
                width: "80px",
                height: "80px",
                objectFit: "cover",
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ marginRight: '1em', display: 'flex', flexDirection: 'column', justifyContent: 'end', textAlign: 'left' }}>
              {item?.imageProduct[0]?.prodNum ? (
                <TypographyBody
                  style={{ width: '150px' }}
                  size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
                >
                  {item.title} #{item?.imageProduct[0]?.prodNum > 9 ? item?.imageProduct[0]?.prodNum : `0${item?.imageProduct[0]?.prodNum}`}
                </TypographyBody>
              ) : (
                <TypographyBody
                  style={{ width: '150px' }}
                  size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
                >
                  {item.title}
                </TypographyBody>
              )}
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
              >
                Earnings
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
              >
                Editions
              </TypographyBody>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', textAlign: 'right' }}>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                ${parseInt(item.imageEarning).toFixed(2) === "NaN" ? 0.00 : parseInt(item.imageEarning).toFixed(2)}
                {/**????? currency symbol */}
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                {item.numberOfSales}/{item.totalEditions} Sold ({100 - item.numberOfSales} remaining)
              </TypographyBody>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'end', flex: 1, justifyContent: 'end' }}>
            <TypographyButton
              onClick={(e) => onInformationExpandClicked(e)}
              buttonStyle={{
                backgroundColor: '#fff',
                color: '#121619',
                border: '1px solid #121619',
                borderRadius: '4px'
              }}
              component={TYPOGRAPHY_BUTTON_TYPE.IconTrailing}
              icon={
                informationOpen ?
                  <ExpandLessIcon style={{
                    ...ICON_SIZE_DEFAULT,
                    marginLeft: "0.5em",
                  }} /> :
                  <ExpandMoreIcon
                    style={{
                      ...ICON_SIZE_DEFAULT,
                      marginLeft: "0.5em",
                    }}
                  />}
            >
              View sales history
            </TypographyButton>
          </div>
        </div>
      )}
      {isOnMobile && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={item.thumbnail}
            style={{
              display: 'flex',
              alignSelf: 'center',
              maxWidth: "100%",
              maxHeight: "10%",
              objectFit: "cover",
            }}
          />
          <div style={{ display: 'flex', marginTop: '1em' }}>
            <TypographyBody
              style={{ marginBottom: '0.25em' }}
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              {item.title}
            </TypographyBody>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TypographyBody
                style={{ marginBottom: '0.25em' }}
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              >
                Earnings
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              >
                Editions
              </TypographyBody>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
              <TypographyBody
                style={{ marginBottom: '0.25em' }}
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              >
                $AUD 956.00
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              >
                {item.numberOfSales}/{item.totalEditions} Sold ({100 - item.numberOfSales} remaining)
              </TypographyBody>
            </div>
          </div>
          <div >
            <TypographyButton
              onClick={(e) => onInformationExpandClicked(e)}
              buttonStyle={{
                backgroundColor: '#fff',
                color: '#121619',
                border: '1px solid #121619',
                borderRadius: '4px',
                width: '100%',
                marginBottom: '1em',
                marginTop: '1.5em',
              }}
              component={TYPOGRAPHY_BUTTON_TYPE.IconTrailing}
              icon={
                informationOpen ?
                  <ExpandLessIcon style={{
                    ...ICON_SIZE_DEFAULT,
                    marginLeft: "0.5em",
                  }} /> :
                  <ExpandMoreIcon
                    style={{
                      ...ICON_SIZE_DEFAULT,
                      marginLeft: "0.5em",
                    }}
                  />}
            >
              View sales history
            </TypographyButton>
          </div>
        </div>
      )}
      {informationOpen &&
        item.imageProduct.map((product, index) => {
          const { type, border } = product;
          let showBorder = type !== "Stretched Canvas";
          if (type === "Rolled Paper" || type === "Framed Canvas") {
            if (border === "No Border " || border === "No Border") {
              showBorder = false;
            }
          }
          const showFrame = type === "Framed Canvas" || type === "Framed Paper";
          const showWrap = type === "Stretched Canvas";

          const capitalizeFirstLetter = (string) => {
            return string && string.length > 0
              ? string[0].toUpperCase() + string.slice(1)
              : "";
          };
          return (
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: isOnMobile ? "column" : "row",
                  margin: isOnMobile ? '0' : '2em'
                }}
              >
                <div style={{
                  minWidth: isOnMobile ? '150px' : '100px',
                  height: isOnMobile ? '150px' : '100px',
                  backgroundColor: '#F2F4F8',
                  borderRadius: '4px',
                }}>
                  <div
                    key={index}
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      width: "100%",
                      height: "100%",
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center center',
                      backgroundImage: `url(${product.imageRender})`,
                    }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: isOnMobile ? '0' : '2em', marginTop: isOnMobile && '1em' }}>
                  <TypographyBody
                    size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
                  >
                    {product.prodNum} / {item.totalEditions}
                  </TypographyBody>
                  <TypographyBody
                    size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
                  >
                    ${product.setPrice}
                  </TypographyBody>
                  <TypographyBody
                    size={isOnTablet ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
                  >
                    {getUploadedAt(product.createdAt)}
                  </TypographyBody>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: isOnMobile ? '0' : '2em', marginTop: isOnMobile && '1em', marginBottom: isOnMobile && '1em' }}>
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                  >
                    Frame Details
                  </TypographyBody>
                  <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
                    {product.type},&nbsp;{product.material},&nbsp;{product.size},
                  </TypographyBody>
                  <div style={{ display: 'flex' }}>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                    >
                      {showBorder &&
                        <>
                          {product.border},&nbsp;
                        </>
                      }
                      {showFrame &&
                        <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                        >
                          {product.frame},&nbsp;
                        </TypographyBody>
                      }
                    </TypographyBody>
                    {showWrap && (
                      <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
                        {capitalizeFirstLetter(product.wrap)} wrap,&nbsp;
                      </TypographyBody>
                    )}
                    <TypographyBody size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
                      {capitalizeFirstLetter(product.galleryCardColor)} gallery card
                    </TypographyBody>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
