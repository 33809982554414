import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import { useTheme } from "@material-ui/core/styles";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';

import {
  ICON_SIZE_DEFAULT,
  ICON_SIZE_SMALL,
} from "../../helpers/constants";

import ResponsiveFriendlyColors
  from "../../components/ImageColourPicker/ResponsiveFriendlyColors";

export default ({
  style,

  imageColour,
  coloursAvailable,
  handleColourChange,
}) => {
  const { palette, typography } = useTheme();

  const [open, setOpen] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const dropdown = useRef(null);
  const control = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      if (
        dropdown &&
        dropdown.current &&
        !dropdown.current.contains(e.target) &&
        !control.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => document.removeEventListener("mousedown", handleClick);
  });

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  const onToggleDropdown = () => {
    setOpen(!open);
  };

  const onChange = (color) => {
    handleColourChange(color);
    setOpen(false);
  };

  const imageColourValue =
    typeof imageColour === "object" ? imageColour.hexValue : imageColour;

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          backgroundColor: palette.common.white,
          padding: "0.25em",
          position: "relative",
          ...style,
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
        onClick={onToggleDropdown}
        ref={control}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightMedium,
            ...(isHovering
              ? {
                  color: palette.text.secondary,
                }
              : {
                  color: palette.text.disabled,
                }),
          }}
        >
          <ColorLensIcon
            style={{
              ...ICON_SIZE_SMALL,
              marginLeft: "0.25em",
            }}
          />
          <div
            style={{
              marginLeft: "0.5em",
            }}
          >
            {!imageColourValue && <span>Colour</span>}
            {imageColourValue && (
              <div
                style={{
                  width: 19,
                  height: 19,
                  backgroundColor: imageColourValue,
                  borderRadius: 6,
                }}
              />
            )}
          </div>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {!open && (
              <ArrowDropDownRoundedIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}
            {open && (
              <ArrowDropUpRoundedIcon
                style={{
                  ...ICON_SIZE_DEFAULT,
                }}
              />
            )}
          </div>
        </div>
        {open && coloursAvailable && (
        <div
          style={{
            position: "absolute",
            zIndex: 10,
            marginTop: "0.25em",
            borderRadius: 6,
            // border: `2px solid ${palette.grey.mediumLight}`,
            boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.25), -2px 3px 4px 2px rgba(0, 0, 0, 0.25)"
          }}
          ref={dropdown}
        >
          <ResponsiveFriendlyColors
            value={imageColourValue}
            onChange={onChange}
            colors={coloursAvailable.map((color) => color.hexValue)}
          />
        </div>
      )}
      </div>
    </>
  );
};
