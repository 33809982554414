import React from 'react';
import { isMobile } from "../../../env_utils/device_screen";
import {
  TypographySubtitle, 
  TypographyTitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT, 
  TYPOGRAPHY_TITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton
} from "@pikcha/typography";

export default function Step4({
  handleClickClose
}) {
  return (
    <div
      className="full-width flex flex-column flex-center justify-center align-center"
      style={{
        paddingLeft: isMobile ? "0" : "3em",
        paddingRight: isMobile ? "0" : "3em",
        paddingTop: "3em",
        paddingBottom: "3em",
        maxHeight: "100%"
      }}
    >
      {!isMobile && (
        <TypographySubtitle
          component={ TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
          style={{
            textAlign: "center",
            marginTop: "0"
          }}
        >
          Enjoy $50 USD<br/>Off Your First Purchase
        </TypographySubtitle>
      )}
      {isMobile && (
        <TypographyTitle
          component={ TYPOGRAPHY_TITLE_COMPONENT.Mobile_H2_Bold}
          style={{
            textAlign: "center",
            marginTop: "2em"
          }}
        >
          Enjoy $50 USD<br/>Off Your First Purchase
        </TypographyTitle>
      )}
      <TypographyBody
        style={{
          textAlign: "center",
          margin: "3em 0"
        }}
        size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
      >
        To help you begin your beautiful collection of art, check 
        your emails for a discount code granting you $50 off of 
        your first Pikcha purchase. 
      </TypographyBody>
      <TypographyButton
        buttonStyle={{
          marginTop: '20px',
          marginBottom: '1em',
          width: '100%',
        }}
        onClick={() => {
          handleClickClose();
        }}
      >
        Begin Exploring
      </TypographyButton>
    </div>
  )
}
