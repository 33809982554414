export const COUNTRIES = [
  { label: "Albania", value: "AL", isGst: false, localeCode: "en-AL", PaypalEnabled: true},
  { label: "Algeria", value: "DZ", isGst: false, localeCode: "ar-DZ", PaypalEnabled: true},
  { label: "Andorra", value: "AD", isGst: false, localeCode: "en-AO", PaypalEnabled: true},
  { label: "Anguilla", value: "AI", isGst: false, localeCode: "en-AI", PaypalEnabled: true},
  { label: "Angola", value: "AO", isGst: false, localeCode: "en-AI", PaypalEnabled: true},
  { label: "Antigua & Barbuda", value: "AG", isGst: false, localeCode: "en-AG", PaypalEnabled: true},
  { label: "Argentina", value: "AR", isGst: false, localeCode: "es-AR", PaypalEnabled: true},
  { label: "Armenia", value: "AM", isGst: false, localeCode: "en-AM", PaypalEnabled: true},
  { label: "Aruba", value: "AW", isGst: false, localeCode: "en-AW", PaypalEnabled: true},
  { label: "Australia", value: "AU", isGst: false, localeCode: "en-AU", PaypalEnabled: true},
  { label: "Austria", value: "AT", isGst: true, localeCode: "de-AT", PaypalEnabled: true},
  { label: "Azerbaijan", value: "AZ", isGst: false, localeCode: "en-AZ", PaypalEnabled: true},
  { label: "Bahamas", value: "BS", isGst: false, localeCode: "en-BS", PaypalEnabled: true},
  { label: "Bahrain", value: "BH", isGst: false, localeCode: "ar-BH", PaypalEnabled: true},
  { label: "Bangladesh", value: "BD", isGst: false, localeCode: "en-US", PaypalEnabled: true},
  { label: "Barbados", value: "BB", isGst: false, localeCode: "en-BB", PaypalEnabled: false},
  { label: "Belgium", value: "BE", isGst: true, localeCode: "en-BE", PaypalEnabled: true},
  { label: "Belize", value: "BZ", isGst: false, localeCode: "en-BZ", PaypalEnabled: true},
  { label: "Benin", value: "BJ", isGst: false, localeCode: "fr-BJ", PaypalEnabled: true},
  { label: "Bermuda", value: "BM", isGst: false, localeCode: "en-BM", PaypalEnabled: true},
  { label: "Bhutan", value: "BT", isGst: false, localeCode: "en-BT", PaypalEnabled: true},
  { label: "Bolivia", value: "BO", isGst: false, localeCode: "es-BO", PaypalEnabled: true},
  { label: "Bosnia & Herzegovnia", value: "BA", isGst: false, localeCode: "en-BA", PaypalEnabled: true},
  { label: "Botswana", value: "BW", isGst: false, localeCode: "en-BW", PaypalEnabled: true},
  { label: "Brazil", value: "BR", isGst: false, localeCode: "pt-BR", PaypalEnabled: true},
  { label: "Brunei", value: "BN", isGst: false, localeCode: "en-BN", PaypalEnabled: true},
  { label: "Bulgaria", value: "BG", isGst: true, localeCode: "en-BG", PaypalEnabled: true},
  { label: "Burkina Faso", value: "BF", isGst: false, localeCode: "fr-BS", PaypalEnabled: true},
  { label: "Burundi", value: "BI", isGst: false, localeCode: "fr-BI", PaypalEnabled: true},
  { label: "Cambodia", value: "KH", isGst: false, localeCode: "en-KH", PaypalEnabled: true},
  { label: "Canada", value: "CA", isGst: false, localeCode: "en-CA", PaypalEnabled: true},
  { label: "Cayman Islands", value: "KY", isGst: false, localeCode: "en-KY", PaypalEnabled: true},
  { label: "Central African Republic", value: "CF", isGst: false, localeCode: "en-US", PaypalEnabled: false},
  { label: "Chad", value: "TD", isGst: false, localeCode: "fr-TD", PaypalEnabled: true},
  { label: "Chile", value: "CL", isGst: false, localeCode: "es-CL", PaypalEnabled: true},
  { label: "China", value: "CN", isGst: false, localeCode: "zh-CN", PaypalEnabled: true},
  { label: "Christmas Island", value: "CX", isGst: false, localeCode: "en-AU", PaypalEnabled: true},
  { label: "Colombia", value: "CO", isGst: false, localeCode: "es-CO", PaypalEnabled: true},
  { label: "Comoros", value: "KM", isGst: false, localeCode: "fr-KM", PaypalEnabled: true},
  { label: "Congo (Brazzaville)", value: "CG", isGst: false, localeCode: "fr-CG", PaypalEnabled: true},
  { label: "Cook Islands", value: "CK", isGst: false, localeCode: "en-CK", PaypalEnabled: false},
  { label: "Costa Rica", value: "CR", isGst: false, localeCode: "es-CR", PaypalEnabled: true},
  { label: "Croatia", value: "HR", isGst: true, localeCode: "en-HR", PaypalEnabled: false},
  { label: "Curacao", value: "CW", isGst: false, localeCode: "en-HR", PaypalEnabled: true},
  { label: "Cyprus", value: "CY", isGst: true, localeCode: "en-CY", PaypalEnabled: true},
  { label: "Czech Republic", value: "CZ", isGst: true, localeCode: "en-CZ", PaypalEnabled: true},
  { label: "Denmark", value: "DK", isGst: true, localeCode: "da-DK", PaypalEnabled: true},
  { label: "Djibouti", value: "DJ", isGst: false, localeCode: "fr-DJ", PaypalEnabled: true},
  { label: "Dominica", value: "DM", isGst: false, localeCode: "en-DM", PaypalEnabled: true},
  { label: "Dominican Republic", value: "DO", isGst: false, localeCode: "es-DO", PaypalEnabled: true},
  { label: "Ecuador", value: "EC", isGst: false, localeCode: "es-EC", PaypalEnabled: true},
  { label: "Egypt", value: "EG", isGst: false, localeCode: "ar-EG", PaypalEnabled: true},
  { label: "El Salvador", value: "SV", isGst: false, localeCode: "es-SV", PaypalEnabled: true},
  { label: "Equatorial Guinea", value: "GQ", isGst: false, localeCode: "en-US", PaypalEnabled: false},
  { label: "Estonia", value: "EE", isGst: true, localeCode: "en-ET", PaypalEnabled: true},
  { label: "Falkland Islands (Malvinas)", value: "FK", isGst: false, localeCode: "en-FK", PaypalEnabled: true},
  { label: "Faroe Islands", value: "FO", isGst: false, localeCode: "da-FO", PaypalEnabled: true},
  { label: "Fiji", value: "FJ", isGst: false, localeCode: "en-FJ", PaypalEnabled: true},
  { label: "Finland", value: "FI", isGst: true, localeCode: "fi-FI", PaypalEnabled: true},
  { label: "France", value: "FR", isGst: true, localeCode: "fr-FR", PaypalEnabled: true},
  { label: "French Guiana", value: "GF", isGst: false, localeCode: "en-GF", PaypalEnabled: true},
  { label: "French Polynesia", value: "PF", isGst: false, localeCode: "en-PF", PaypalEnabled: true},
  { label: "French Southern Territories", value: "TF", isGst: false, localeCode: "en-US", PaypalEnabled: true},
  { label: "Gabon", value: "GA", isGst: false, localeCode: "fr-GA", PaypalEnabled: false},
  { label: "Gambia", value: "GM", isGst: false, localeCode: "en-GM", PaypalEnabled: true},
  { label: "Georgia", value: "GE", isGst: false, localeCode: "en-GE", PaypalEnabled: true},
  { label: "Germany", value: "DE", isGst: true, localeCode: "de-DE", PaypalEnabled: true},
  { label: "Ghana", value: "GH", isGst: true, localeCode: "de-DE", PaypalEnabled: false},	
  { label: "Gibraltar", value: "GI", isGst: false, localeCode: "en-GI", PaypalEnabled: true},
  { label: "Greece", value: "GR", isGst: true, localeCode: "el-GR", PaypalEnabled: true},
  { label: "Greenland", value: "GL", isGst: false, localeCode: "da-GL", PaypalEnabled: true},
  { label: "Grenada", value: "GD", isGst: false, localeCode: "en-GD", PaypalEnabled: true},
  { label: "Guadeloupe", value: "GP", isGst: false, localeCode: "en-GP", PaypalEnabled: true},
  { label: "Guam", value: "GU", isGst: false, localeCode: "en-US", PaypalEnabled: false},
  { label: "Guatemala", value: "GT", isGst: false, localeCode: "en-US", PaypalEnabled: true},
  { label: "Guernsey", value: "GG", isGst: false, localeCode: "en-US", PaypalEnabled: false},
  { label: "Guinea", value: "GN", isGst: false, localeCode: "fr-GN", PaypalEnabled: true},
  { label: "Guinea-Bissau", value: "GW", isGst: false, localeCode: "en-GW", PaypalEnabled: true},
  { label: "Guyana", value: "GY", isGst: false, localeCode: "en-GY", PaypalEnabled: true},
  { label: "Haiti", value: "HT", isGst: false, localeCode: "en-GY", PaypalEnabled: false},
  { label: "Honduras", value: "HN", isGst: false, localeCode: "es-HN", PaypalEnabled: true},
  { label: "Hong Kong", value: "HK", isGst: false, localeCode: "en-HK", PaypalEnabled: true},
  { label: "Hungary", value: "HU", isGst: true, localeCode: "hu-HU", PaypalEnabled: true},
  { label: "Iceland", value: "IS", isGst: false, localeCode: "en-IS", PaypalEnabled: true},
  { label: "India", value: "IN", isGst: false, localeCode: "en-IN", PaypalEnabled: true},
  { label: "Indonesia", value: "ID", isGst: false, localeCode: "id-ID", PaypalEnabled: true},
  { label: "Ireland", value: "IE", isGst: true, localeCode: "en-IE", PaypalEnabled: true},
  { label: "Isle of Man", value: "IM", isGst: false, localeCode: "en-US", PaypalEnabled: false},
  { label: "Israel", value: "IL", isGst: false, localeCode: "en-IL", PaypalEnabled: true},
  { label: "Italy", value: "IT", isGst: true, localeCode: "en-IT", PaypalEnabled: true},
  { label: "Jamaica", value: "JM", isGst: false, localeCode: "es-JM", PaypalEnabled: true},
  { label: "Japan", value: "JP", isGst: false, localeCode: "ja-JP", PaypalEnabled: true},
  { label: "Jersey", value: "JE", isGst: false, localeCode: "en-US", PaypalEnabled: true},
  { label: "Jordan", value: "JO", isGst: false, localeCode: "ar-JO", PaypalEnabled: true},
  { label: "Kenya", value: "KE", isGst: false, localeCode: "en-KE", PaypalEnabled: true},
  { label: "Kazakhstan", value: "KZ", isGst: false, localeCode: "en-KZ", PaypalEnabled: true},
  { label: "Kiribati", value: "KI", isGst: false, localeCode: "en-KI", PaypalEnabled: true},
  { label: "Kosovo", value: "XK", isGst: false, localeCode: "ar-KW", PaypalEnabled: false},
  { label: "Kuwait", value: "KW", isGst: false, localeCode: "ar-KW", PaypalEnabled: true},
  { label: "Kyrgyzstan", value: "KG", isGst: false, localeCode: "en-KG", PaypalEnabled: true},
  { label: "Laos", value: "LA", isGst: false, localeCode: "en-LA", PaypalEnabled: true},
  { label: "Latvia", value: "LV", isGst: true, localeCode: "en-LV", PaypalEnabled: true},
  { label: "Lesotho", value: "LS", isGst: false, localeCode: "en-LS", PaypalEnabled: true},
  { label: "Liechtenstein", value: "LI", isGst: false, localeCode: "en-LI", PaypalEnabled: true},
  { label: "Lithuania", value: "LT", isGst: true, localeCode: "en-LT", PaypalEnabled: true},
  { label: "Luxembourg", value: "LU", isGst: true, localeCode: "en-LU", PaypalEnabled: true},
  { label: "Macau", value: "MO", isGst: false, localeCode: "en-US", PaypalEnabled: false},
  { label: "Macedonia", value: "MK", isGst: false, localeCode: "en-MK", PaypalEnabled: true},
  { label: "Madagascar", value: "MG", isGst: false, localeCode: "en-MG", PaypalEnabled: true},
  { label: "Malawi", value: "MW", isGst: false, localeCode: "en-MW", PaypalEnabled: true},
  { label: "Malaysia", value: "MY", isGst: false, localeCode: "en-MY", PaypalEnabled: true},
  { label: "Maldives", value: "MV", isGst: false, localeCode: "en-MV", PaypalEnabled: true},
  { label: "Mali", value: "ML", isGst: false, localeCode: "en-ML", PaypalEnabled: true},
  { label: "Malta", value: "MT", isGst: true, localeCode: "en-MT", PaypalEnabled: true},
  { label: "Marshall Islands", value: "MH", isGst: false, localeCode: "en-MH", PaypalEnabled: true},
  { label: "Martinique", value: "MQ", isGst: false, localeCode: "en-MQ", PaypalEnabled: true},
  { label: "Mauritania", value: "MR", isGst: false, localeCode: "en-MR", PaypalEnabled: true},
  { label: "Mauritius", value: "MU", isGst: false, localeCode: "en-MU", PaypalEnabled: true},
  { label: "Mayotte", value: "YT", isGst: false, localeCode: "en-YT", PaypalEnabled: true},
  { label: "Mexico", value: "MX", isGst: false, localeCode: "es-MX", PaypalEnabled: true},
  { label: "Micronesia", value: "FM", isGst: false, localeCode: "en-FM", PaypalEnabled: true},
  { label: "Moldova", value: "MD", isGst: false, localeCode: "en-MD", PaypalEnabled: false},
  { label: "Monaco", value: "MC", isGst: false, localeCode: "fr-MC", PaypalEnabled: false},
  { label: "Mongolia", value: "MN", isGst: false, localeCode: "en-MN", PaypalEnabled: false},
  { label: "Montenegro", value: "ME", isGst: false, localeCode: "en-ME", PaypalEnabled: true},
  { label: "Montserrat", value: "MS", isGst: false, localeCode: "en-MS", PaypalEnabled: true},
  { label: "Morocco", value: "MA", isGst: false, localeCode: "ar-MA", PaypalEnabled: true},
  { label: "Mozambique", value: "MZ", isGst: false, localeCode: "en-MZ", PaypalEnabled: true},
  { label: "Namibia", value: "NA", isGst: false, localeCode: "en-NA", PaypalEnabled: true},
  { label: "Nepal", value: "NP", isGst: false, localeCode: "en-NP", PaypalEnabled: true},
  { label: "Netherlands", value: "NL", isGst: true, localeCode: "nl-NL", PaypalEnabled: true},
  { label: "New Caledonia", value: "NC", isGst: false, localeCode: "en-NC", PaypalEnabled: true},
  { label: "New Zealand", value: "NZ", isGst: true, localeCode: "en-NZ", PaypalEnabled: true},
  { label: "Nicaragua", value: "NI", isGst: false, localeCode: "es-NI", PaypalEnabled: true},
  { label: "Niger", value: "NE", isGst: false, localeCode: "fr-NE", PaypalEnabled: true},
  { label: "Nigeria", value: "NG", isGst: false, localeCode: "en-NG", PaypalEnabled: true},
  { label: "Niue", value: "NU", isGst: false, localeCode: "en-NU", PaypalEnabled: true},
  { label: "Norfolk Island", value: "NF", isGst: false, localeCode: "en-NF", PaypalEnabled: true},
  { label: "Norway", value: "NO", isGst: false, localeCode: "no-NO", PaypalEnabled: true},
  { label: "Oman", value: "OM", isGst: false, localeCode: "ar-OM", PaypalEnabled: true},
  { label: "Palau", value: "PW", isGst: false, localeCode: "en-PW", PaypalEnabled: true},
  { label: "Panama", value: "PA", isGst: false, localeCode: "es-PA", PaypalEnabled: true},
  { label: "Papua new Guinea", value: "PG", isGst: false, localeCode: "en-PG", PaypalEnabled: true},
  { label: "Peru", value: "PE", isGst: false, localeCode: "es-PE", PaypalEnabled: true},
  { label: "Philippines", value: "PH", isGst: false, localeCode: "en-PH", PaypalEnabled: true},
  { label: "Pitcairn", value: "PN", isGst: false, localeCode: "en-PN", PaypalEnabled: true},
  { label: "Poland", value: "PL", isGst: true, localeCode: "pl-PL", PaypalEnabled: true},
  { label: "Portugal", value: "PT", isGst: true, localeCode: "pt-PT", PaypalEnabled: true},
  { label: "Puerto Rico", value: "PT", isGst: false, localeCode: "pt-PT", PaypalEnabled: false},
  { label: "Qatar", value: "QA", isGst: false, localeCode: "en-QA", PaypalEnabled: true},
  { label: "Reunion", value: "RE", isGst: false, localeCode: "en-RE", PaypalEnabled: true},
  { label: "Romania", value: "RO", isGst: false, localeCode: "en-RO", PaypalEnabled: true},
  { label: "Russia", value: "RU", isGst: true, localeCode: "ru-RU", PaypalEnabled: true},
  { label: "Rwanda", value: "RW", isGst: false, localeCode: "fr-RW", PaypalEnabled: true},
  { label: "Samoa", value: "WS", isGst: false, localeCode: "en-WS", PaypalEnabled: true},
  { label: "San Marino", value: "SM", isGst: false, localeCode: "en-SM", PaypalEnabled: true},
  { label: "Sao Tome & Principe", value: "ST", isGst: false, localeCode: "en-ST", PaypalEnabled: true},
  { label: "Saudi Arabia", value: "SA", isGst: false, localeCode: "ar-SA", PaypalEnabled: true},
  { label: "Senegal", value: "SN", isGst: false, localeCode: "fr-SN", PaypalEnabled: true},
  { label: "Serbia", value: "RS", isGst: false, localeCode: "en-RS", PaypalEnabled: true},
  { label: "Seychelles", value: "SC", isGst: false, localeCode: "fr-SC", PaypalEnabled: true},
  { label: "Sierra Leone", value: "SL", isGst: false, localeCode: "en-SL", PaypalEnabled: true},
  { label: "Singapore", value: "SG", isGst: false, localeCode: "en-SG", PaypalEnabled: true},
  { label: "Slovakia", value: "SK", isGst: true, localeCode: "sk-SK", PaypalEnabled: true},
  { label: "Slovenia", value: "SI", isGst: true, localeCode: "en-SI", PaypalEnabled: true},
  { label: "Solomon Islands", value: "SB", isGst: false, localeCode: "en-SB", PaypalEnabled: true},
  { label: "South Africa", value: "ZA", isGst: true, localeCode: "en-ZA", PaypalEnabled: true},
  { label: "South Korea", value: "KR", isGst: false, localeCode: "kr-KR", PaypalEnabled: false},
  { label: "Spain", value: "ES", isGst: true, localeCode: "es-ES", PaypalEnabled: true},
  { label: "Sri Lanka", value: "LK", isGst: false, localeCode: "en-LK", PaypalEnabled: true},
  { label: "St Barthelemy", value: "BL", isGst: false, localeCode: "en-US", PaypalEnabled: false},
  { label: "St Helena", value: "SH", isGst: false, localeCode: "en-SH", PaypalEnabled: true},
  { label: "St Kitts & Nevis", value: "KN", isGst: false, localeCode: "en-KN", PaypalEnabled: true},
  { label: "St Lucia", value: "LC", isGst: false, localeCode: "en-LC", PaypalEnabled: true},
  { label: "St Pierre & Miquelon", value: "PM", isGst: false, localeCode: "en-PM", PaypalEnabled: true},
  { label: "St Vincent & the Grenadines", value: "VC", isGst: false, localeCode: "en-VC", PaypalEnabled: true},
  { label: "Suriname", value: "SR", isGst: false, localeCode: "en-SR", PaypalEnabled: true},
  { label: "Svalbard", value: "SJ", isGst: false, localeCode: "en-SJ", PaypalEnabled: true},
  { label: "Swaziland", value: "SZ", isGst: false, localeCode: "en-SZ", PaypalEnabled: true},
  { label: "Sweden", value: "SE", isGst: true, localeCode: "sv-SE", PaypalEnabled: true},
  { label: "Switzerland", value: "CH", isGst: false, localeCode: "de-CH", PaypalEnabled: true},
  { label: "Taiwan", value: "TW", isGst: false, localeCode: "zh-TW", PaypalEnabled: true},
  { label: "Tajikistan", value: "TJ", isGst: false, localeCode: "en-TJ", PaypalEnabled: true},
  { label: "Tanzinia", value: "TZ", isGst: false, localeCode: "en-TZ", PaypalEnabled: true},
  { label: "Thailand", value: "TH", isGst: false, localeCode: "th-TH", PaypalEnabled: true},
  { label: "Timor-Leste", value: "TL", isGst: false, localeCode: "th-TH", PaypalEnabled: false},
  { label: "Togo", value: "TG", isGst: false, localeCode: "fr-TG", PaypalEnabled: true},
  { label: "Tonga", value: "TO", isGst: false, localeCode: "en-TO", PaypalEnabled: true},
  { label: "Trinidad & Tobago", value: "TT", isGst: false, localeCode: "en-TT", PaypalEnabled: true},
  { label: "Tunisia", value: "TN", isGst: false, localeCode: "ar-TN", PaypalEnabled: true},
  { label: "Turkmenistan", value: "TM", isGst: false, localeCode: "en-TM", PaypalEnabled: true},
  { label: "Turkey", value: "TR", isGst: false, localeCode: "en-TC", PaypalEnabled: false},
  { label: "Turks & Caicos Island", value: "TC", isGst: false, localeCode: "en-TC", PaypalEnabled: true},
  { label: "Tuvalu", value: "TV", isGst: false, localeCode: "en-TV", PaypalEnabled: true},
  { label: "Uganada", value: "UG", isGst: false, localeCode: "en-UG", PaypalEnabled: true},
  { label: "Ukraine", value: "UA", isGst: false, localeCode: "en-UA", PaypalEnabled: true},
  { label: "United Arab Emirates", value: "AE", isGst: false, localeCode: "en-AE", PaypalEnabled: true},
  { label: "United Kingdom", value: "GB", isGst: true, localeCode: "en-GB", PaypalEnabled: true},
  { label: "United States", value: "US", isGst: false, localeCode: "en-US", PaypalEnabled: true},
  { label: "Uruguay", value: "UY", isGst: false, localeCode: "es-UY", PaypalEnabled: true},
  { label: "Uzbekistan", value: "UZ", isGst: false, localeCode: "es-UY", PaypalEnabled: false},
  { label: "Vaitcan City", value: "VA", isGst: false, localeCode: "en-VA", PaypalEnabled: true},
  { label: "Vanuatu", value: "VU", isGst: false, localeCode: "en-VU", PaypalEnabled: true},
  { label: "Vietnam", value: "VN", isGst: false, localeCode: "en-VN", PaypalEnabled: true},
  { label: "Virgin Islands (British)", value: "VG", isGst: false, localeCode: "en-VG", PaypalEnabled: false},
  { label: "Virgin Islands (US)", value: "VI", isGst: false, localeCode: "en-VG", PaypalEnabled: false},
  { label: "Wallis & Futuna", value: "WF", isGst: false, localeCode: "en-WF", PaypalEnabled: true},
  { label: "Zambia", value: "ZM", isGst: false, localeCode: "en-ZM", PaypalEnabled: true},
];
