import * as rdd from 'react-device-detect';

export const isMobile = rdd.isMobile;
export const isMobile_Chrome = rdd.isMobile && rdd.isChrome;
export const isMobile_Safari = rdd.isMobileSafari;

export const isComputer_Chrome = rdd.isChrome && !rdd.isMobile;
export const isComputer_Safari = rdd.isSafari && !rdd.isMobile;

export const getWindowDynamicWidth = isPortrait => {
  const windowStaticWidth = window.screen.width;
  const windowStaticHeight = window.screen.height;
  if (!rdd.isMobile) {
    return windowStaticWidth;
  } else {
    return isPortrait
      ? (windowStaticWidth < windowStaticHeight ? windowStaticWidth : windowStaticHeight)
      : (windowStaticWidth > windowStaticHeight ? windowStaticWidth : windowStaticHeight)
  }
}

export const getWindowDynamicHeight = isPortrait => {
  const windowStaticWidth = window.screen.width;
  const windowStaticHeight = window.screen.height;
  if (!rdd.isMobile) {
    return windowStaticHeight;
  } else {
    return isPortrait
      ? (windowStaticWidth > windowStaticHeight ? windowStaticWidth : windowStaticHeight)
      : (windowStaticWidth < windowStaticHeight ? windowStaticWidth : windowStaticHeight)
  }
}

// test out puts
//
//

// console.log(rdd);
// console.log('isMobile_Chrome =', isMobile_Chrome);
// console.log('isMobile_Safari =', isMobile_Safari);
// console.log('isComputer_Chrome =', isComputer_Chrome);
// console.log('isComputer_Safari =', isComputer_Safari);
//
// console.log('getWindowDynamicWidth', getWindowDynamicWidth(false));
// console.log('getWindowDynamicHeight', getWindowDynamicHeight(false));

// ###########################################################################################################

// Following is a typical example to use responsive rotation
//
//

// import { withOrientationChange } from 'react-device-detect'
// import { getWindowDynamicWidth, getWindowDynamicHeight } from 'PikchaWebApp/ClientApp/packages/web-app/src/env_utils/device_screen.ts';

// useEffect(() => {
//   console.log('Rotation Changed to: ', isPortrait ? 'portrait' : 'landscape');
//   console.log('changed width', getWindowDynamicWidth(isPortrait));
//   console.log('changed height', getWindowDynamicHeight(isPortrait));
//
// }, [isPortrait]);

// let App = props => {
//   const { isLandscape, isPortrait } = props
//
//   if (isLandscape) {
//     return  <div>The device is in Landscape mode</div>
//   }
//
//   if (isPortrait) {
//     return  <div>The device is in Portrait mode</div>
//   }
// }
//
// App = withOrientationChange(App)
//
// export { App }






