import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import PortraitIcon from "@material-ui/icons/AccountBox";
import LocalAtmIcon from "../../../assets/images/AccountNav/PaymentsIcon.svg";
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded';
import { isTheUserAnArtist } from "../../../selectors/account";
import isMobile from "../../../components/Responsive/isMobile";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";

const useStyles = makeStyles(({ typography }) => ({
  indicator: {
    background: "none"
  },
  tabs: {
    "& .MuiTab-wrapper": {
      flexDirection: "row !important",
      padding: '0em 1.5em',
    },
    "& .MuiTab-labelIcon": {
      minHeight: '44px',
      paddingRight: '0.5em'
    },
    "& button[aria-selected='true']": {
      border: "0"
    },
    "& .MuiSvgIcon-root": {
      width: "18px",
      width: "18px",
    }
  },
  tab: {
    textTransform: "capitalize",
    fontWeight: typography.fontWeightMedium,
    maxWidth: '100%'
  },
  tabSelected: {
    color: '#343A3F',
    backgroundColor: "#F2F4F8",
    borderRadius: '84px',
  },
}));

const PaymentImage = () => {
  return (
    <img style={{ width: '18px', height: '18px', marginRight: '0.5em' }} src={LocalAtmIcon} alt="LocalAtmIcon" />
  )
}

const Navigation = ({ isTheUserAnArtist, history }) => {
  const isOnMobile = isMobile();

  const classes = useStyles();

  const handleCallToRouter = (_, value) => {
    history.push(value);
  };

  return (
    <div
      className="nonPrintable"
      style={{
        paddingLeft: isOnMobile ? 0 : "4em",
        paddingRight: isOnMobile ? 0 : "4em",
        paddingTop: "6em",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Tabs
        TabIndicatorProps={{ style: { background: "none" } }}
        className={classes.tabs}
        variant="scrollable"
        scrollButtons="on"
        value={history.location.pathname}
        onChange={handleCallToRouter}
      >
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabSelected,
          }}
          label={
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              Account details
            </TypographyBody>}
          icon={<PortraitIcon />}
          value="/account/details"
        />
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabSelected,
          }}
          label={
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              Order History
            </TypographyBody>
          }
          icon={<ShoppingBasketRoundedIcon />}
          value="/account/orderHistory"
        />
        {isTheUserAnArtist && (
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
            }}
            label={
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
              >
                Sales And Payments
              </TypographyBody>
            }
            icon={<PaymentImage />}
            className="Onboarding-1"
            value="/account/sales"
          />
        )}
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabSelected,
          }}
          label={
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              Settings
            </TypographyBody>
          }
          icon={<SettingsRoundedIcon />}
          value="/account/settings"
        />
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTheUserAnArtist: isTheUserAnArtist(state),
});

export default connect(mapStateToProps, {})(withRouter(Navigation));
