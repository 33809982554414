import React from "react";
import { useTheme } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";

export default ({ containerStyle, ...otherProps }) => {
  const { palette } = useTheme();

  return (
    <div>
    <div
      className="text-left"
      style={{
        marginTop: "3em",
        marginLeft: "6em",
        marginRight: "6em",
        borderRadius: 6,
        boxShadow:
          "-2px 2px 4px rgba(0, 0, 0, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.25)",
        backgroundColor: palette.common.white,
        display: "flex",
        justifyContent: "center",
        padding: "1em",
        height: 707,
        width: "auto",
      }}
    />
      <div style={containerStyle}>
        <ContentLoader
          width={768}
          height={600}
          viewBox="0 0 768 600"
          backgroundColor={palette.grey.lighter}
          foregroundColor={palette.grey.lighter}
          {...otherProps}
        >
          {/* <rect x="64" y="0" rx="6" ry="6" width="640" height="361" />

        <rect x="156" y="392" rx="6" ry="6" width="142" height="46" />
        <rect x="500" y="412" rx="6" ry="6" width="112" height="23" /> */}
          <rect x="156" y="30" rx="6" ry="6" width="456" height="422" />
          <rect x="156" y="472" rx="6" ry="6" width="456" height="46" />
        </ContentLoader>
      </div>
    </div>
  );
};
