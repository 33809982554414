import React, { useRef, useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import SignatureDisplayCropper from "../../containers/modals/UploadFlowModal/SignatureDisplayCropper";
import { connect } from "react-redux";
import isDesktopHD from "../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isTablet from "../../components/Responsive/isTablet";
import isMobile from "../../components/Responsive/isMobile";
import { setAlert } from "../../actions/alert";
import * as Vibrant from "node-vibrant";
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  colors,
} from "@pikcha/typography";

const ImagePreview = ({
  signature,
  currentToggle,
  rawThumbnail,
  imageSignature,
  editionNum,
  miTotSold,
  setVibrantColors,
  selectedSignatureColor,
  submitPending,
  setShowDeleteConfirmation,
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  const [signatureSize, setSignatureSize] = useState({
    width: 0,
    height: 0,
  });

  const { palette } = useTheme();
  const imageWrapper = useRef(null);
  const cropperRef = useRef(null);
  const signatureRef = useRef(null);

  const [imageWrapperWidth, setImageWrapperWidth] = useState(
    imageWrapper.current ? window.getComputedStyle(imageWrapper.current) : 119.2
  );
  const [calculateSigPosition, setCalculateSigPosition] = useState(false);
  const [croppedSignatureTop, setCroppedSignatureTop] = useState();
  const [croppedSignatureLeft, setCroppedSignatureLeft] = useState();
  const [cropperSignatureTextSize, setCropperSignatureTextSize] = useState();

  const handleResize = () => {
    const { width } = imageWrapper.current
      ? window.getComputedStyle(imageWrapper.current)
      : {};
    const widthValue = isOnDesktopHD
      ? parseFloat(width) - 128
      : isOnDesktopSD
      ? parseFloat(width) - 64
      : isOnTablet
      ? parseFloat(width) - 32
      : parseFloat(width) - 16;
    setImageWrapperWidth(widthValue);
  };

  const getSignaturePosition = () => {
    if (calculateSigPosition) {
      let cropBox;
      if (cropperRef.current) {
        cropBox = cropperRef.current;
      }
      let signatureBox;
      if (signatureRef.current) {
        signatureBox = signatureRef.current;
      }
      let imageWrapperBox;
      if (imageWrapper.current) {
        imageWrapperBox = imageWrapper.current;
      }
      // let croppedSignatureWidth;
      let croppedSignatureHeight;
      if (cropBox && signatureBox && imageWrapperBox) {
        const squareSigPx =
          (cropBox.clientWidth * cropBox.clientHeight * 12) / 10000;
        let signatureRatio = signatureSize.width / signatureSize.height;
        if (isNaN(signatureRatio)) {
          signatureRatio = 2;
        }
        croppedSignatureHeight = Math.sqrt(squareSigPx / signatureRatio);

        setCropperSignatureTextSize(croppedSignatureHeight * 0.25);
        if (!isNaN(croppedSignatureHeight) && signatureSize.width > 0) {
          setCroppedSignatureTop(
            cropBox.offsetHeight +
              (imageWrapperBox.offsetHeight - cropBox.offsetHeight) / 2 -
              signatureSize.height -
              croppedSignatureHeight * 0.25 -
              Math.min(
                cropBox.clientHeight * 0.015,
                cropBox.clientWidth * 0.015
              ) -
              (imageWrapperWidth * 0.07) / 3
          );
          setCroppedSignatureLeft(
            currentToggle === "right"
              ? cropBox.offsetWidth +
                  (imageWrapperBox.offsetWidth - cropBox.offsetWidth) / 2 -
                  signatureSize.width -
                  (imageWrapperWidth * 0.07) / 3 -
                  8
              : (imageWrapperBox.offsetWidth - cropBox.offsetWidth) / 2 +
                  (imageWrapperWidth * 0.07) / 3 +
                  8
          );
        } else {
          setCroppedSignatureTop(
            cropBox.offsetHeight +
              (imageWrapperBox.offsetHeight - cropBox.offsetHeight) / 2 -
              (imageWrapperWidth * 0.07) / 3 -
              croppedSignatureHeight * 0.25
          );
          setCroppedSignatureLeft(
            currentToggle === "right"
              ? cropBox.offsetWidth +
                  (imageWrapperBox.offsetWidth - cropBox.offsetWidth) / 2 -
                  (imageWrapperWidth * 0.07) / 3 -
                  croppedSignatureHeight
              : (imageWrapperBox.offsetWidth - cropBox.offsetWidth) / 2 +
                  (imageWrapperWidth * 0.07) / 3
          );
        }
      }
    }
  };

  useEffect(() => {
    getSignaturePosition();
  }, [calculateSigPosition, currentToggle, cropperRef, signatureSize]);

  useEffect(() => {
    if (calculateSigPosition) {
      window.addEventListener("resize", getSignaturePosition);
      return () => window.removeEventListener("resize", getSignaturePosition);
    }
  }, [calculateSigPosition, signatureSize, currentToggle]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, [imageWrapper.current]);

  useEffect(() => {
    const hexList = [];
    if (rawThumbnail) {
      Vibrant.from(rawThumbnail).getPalette((err, palette) => {
        const length = Object.keys(palette).length;
        for (let i = 0; i < length; i++) {
          const key = Object.keys(palette)[i];

          if (!palette[key]) {
            break;
          }

          const hexValue = palette[key].hex;
          hexList.push(hexValue);
        }
      });
    }
    setVibrantColors(hexList);
  }, [rawThumbnail]);

  return (
    <>
      {/* <div className="full-width mr-1-em mt-4-em" style={{ maxWidth: 1000 }}> */}
      <div
        className={isOnMobile ? "full-width" : "full-width mr-1-em mt-4-em"}
        style={{ maxWidth: 1000 }}
      >
        <div
          className="ImagePreview-container ProductItem-imageFrame full-height full-width flex-center justify-center relative p-4-em"
          style={{
            backgroundColor: palette.grey.lightest,
            height: "100%",
            maxHeight: 1000,
            borderRadius: 6,
            border: "0px solid rgba(12, 18, 28, .12)",
            position: "relative",
          }}
          ref={imageWrapper}
        >
          {!isOnMobile && (
            <div
              className="absolute"
              style={{
                bottom: 8,
                zIndex: 10,
                left: 8,
              }}
            >
              <div className="flex pt-2-em">
                <TypographyButton
                  buttonStyle={{ backgroundColor: colors.Neutral.white00 }}
                  onClick={() => {
                    setShowDeleteConfirmation(true);
                  }}
                  disabled={submitPending}
                  component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                >
                  Delete image
                </TypographyButton>
              </div>
            </div>
          )}

          {rawThumbnail && (
            <>
              <img
                className="block img-preview"
                ref={cropperRef}
                style={{
                  width: "auto",
                  height: "auto",
                  objectFit: "contain",
                  maxHeight: "100%",
                  border: `${(imageWrapperWidth * 0.07) / 3}px solid #fff`,
                  maxWidth: "100%",
                  boxShadow: "0px 0px 4px 2px #999999",
                }}
                src={rawThumbnail}
                alt="Image Preview"
                onLoad={() => {
                  setCalculateSigPosition(true);
                }}
              />
              <div ref={signatureRef}>
                {calculateSigPosition &&
                  croppedSignatureTop &&
                  croppedSignatureLeft && (
                    <SignatureDisplayCropper
                      textSize={cropperSignatureTextSize}
                      alignInnerPosition={currentToggle}
                      setSignatureSize={setSignatureSize}
                      signatureRef={signatureRef}
                      signatureData={imageSignature}
                      signatureFont={signature.signatureFont}
                      style={{
                        position: "absolute",
                        top: croppedSignatureTop,
                        left: croppedSignatureLeft,
                      }}
                      color={selectedSignatureColor}
                      editionNumber={editionNum}
                    />
                  )}
              </div>
            </>
          )}
        </div>
        {isOnMobile && (
          <div
            style={{ width: "100%", paddingBottom: "1rem", paddingTop: "1rem" }}
          >
            <TypographyButton
              buttonStyle={{
                backgroundColor: colors.Neutral.white00,
                width: "100%",
              }}
              onClick={() => {
                setShowDeleteConfirmation(true);
              }}
              disabled={submitPending || miTotSold > 0}
              component={TYPOGRAPHY_BUTTON_TYPE.Outline}
            >
              Delete image
            </TypographyButton>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    signature: state.signatureReducer,
  };
};

export default connect(mapStateToProps, {
  setAlert,
})(ImagePreview);
