import React, { useEffect } from "react";
import axios from "axios";
import DirectionsWalkRoundedIcon from "@material-ui/icons/DirectionsWalkRounded";
import Card from "@material-ui/core/Card";

import { PikchaButton } from "../../components/baseComponents";
import { ONBOARDING_PORTAL_ID } from "../../helpers/constants";
import AuthorizeService from "../../auth/AuthorizeService";

export default ({
  id,
  handleDestroyComponent,
  handleRun,
  handleInitProfileOnBoarding,
  handleLastStepClick
}) => {
  useEffect(() => {
    const overlayNode = document.createElement("div");
    const rootOverlayNode = document.getElementById(ONBOARDING_PORTAL_ID);

    overlayNode.className = "Onboarding-overlay";
    overlayNode.style.visibility = "visible";
    if((rootOverlayNode.childNodes[0] || {}).className!=="Onboarding-overlay"){
      rootOverlayNode.appendChild(overlayNode);
    }
  }, []);

  const handleClickYes = () => {
    handleDestroyComponent(true);
    handleRun(true);
    handleLastStepClick(true);

    const overlayEl = document.querySelector(".Onboarding-overlay");
    if (overlayEl) {
      const parentNode = overlayEl.parentNode;
      parentNode.removeChild(overlayEl);
    }
  };

  const handleClickNo = () => {
    /*
    const getToken = async () => await AuthorizeService.getAccessToken();

    const parameter = {
      isUserPromoted: true,
    };
    getToken().then((token) => {
      axios
        .post(
          "/api/profile/IsUserPromoted",
          parameter,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((_) => {
          handleDestroyComponent(true);
        })
        .catch((err) => {
          console.log("Lastestep, handleClickNo ", err);
          handleDestroyComponent(true);
        });
    });
    */
    handleDestroyComponent(true);
    handleInitProfileOnBoarding(false);
    handleLastStepClick(false);
  };

  return (
    <>
      <Card
        style={{
          height: 160,
          alignItems: "center",
          padding: "2em",
          justifyContent: "center",
          width: 350,
          userSelect: "none",
          filter: "drop-shadow(rgba(0, 0, 0, 0.4) -8px 8px 8px)",
          maxWidth: "100%",
          borderRadius: 20,
          opacity: 1,
          position: "fixed",
          transition: "opacity 0.3s ease 0s, transform 0.2s ease 0s",
          visibility: "visible",
          zIndex: 9999999,
          left: "24%",
          top: "10%",
          transform: "translate(-30%, -30%)",
        }}
        className="flex-column"
      >
        <div style={{ width: "100%" }} className="flex">
          <div
            className="flex-center justify-content"
            style={{
              flex: "1 1",
            }}
          >
            <DirectionsWalkRoundedIcon />
          </div>

          <div
            className="flex-center justify-content"
            style={{
              flex: "3 1",
            }}
          >
            Take the artist tour again?
          </div>
        </div>

        <div
          className="flex-grow-1 flex-center"
          style={{
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              paddingRight: "0.5em",
            }}
            className="flex-grow-1"
          >
            <PikchaButton onClick={handleClickYes} fullWidth action="octonary">
              Yes
            </PikchaButton>
          </div>

          <div
            style={{
              paddingLeft: "0.5em",
            }}
            className="flex-grow-1"
          >
            <PikchaButton onClick={handleClickNo} fullWidth action="octonary">
              No
            </PikchaButton>
          </div>
        </div>
      </Card>
    </>
  );
};
