import React, { useState, useEffect } from 'react';
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { connect } from "react-redux";
import { setAlert } from '../../../actions/alert';
import NextPaymentOverlay from "./NextPaymentOverlay";
import { Link } from "react-router-dom";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  colors,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT
} from "@pikcha/typography";
import { IntlCurrencyFormatter } from "../../../components/NumberFormatters/IntlCurrencyFormatter";
import isMobile from "../../../components/Responsive/isMobile";

interface IHistoryData {
  totalCancelledArtistMargin: string;
  totalPayableArtistMargin: string;
  totalPendingArtistMargin: string;
  imageSaleHistory: Array<object>
}

interface IPaymentHistory {
  AmountDue: string
  PendingAmount: string
  PaymentDate: string
  TotalEarnings: string
  TotalToBePaid: string
}

interface INextPayment {
  historyData: IHistoryData;
  user: object,
  paymentHistoryData: IPaymentHistory,
  settings: object
  setAlert: (a: string, b: string) => void
}

interface IGenetationQuote {
  batchId: string,
  sourceAmount: string | number,
  exchangeRate: string | number,
  fees: string | number,
  fxRate: string | number,
  sourceCurrency: string,
  targetCurrency: string,
  targetAmount: string | number
}

const NextPayment = ({
  historyData,
  user,
  paymentHistoryData,
  // @ts-ignore
  settings: { settings },
  setAlert
}: INextPayment) => {
  const { typography } = useTheme();
  const isOnMobile = isMobile();
  const [genetationQuote, setGenetationQuote] = useState<IGenetationQuote>({
    batchId: "",
    sourceAmount: "-",
    exchangeRate: "-",
    fees: "-",
    fxRate: "-",
    sourceCurrency: "",
    targetCurrency: "",
    targetAmount: "-"
  });
  const [showOverlay, setShowOverlay] = useState(false);
  const [message, setMessage] = useState("");
  const [paidSuccessful, setPaidSuccessful] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const handleGenerateQuote = () => {
    setMessage("Fetching Currency rates and generating a quote");
    setShowOverlay(true);
    axios
      .get(
        `${
        // @ts-ignore
        settings.payoutUrl ||
        "https://pikcha.local:1900/v1/payout"
        // @ts-ignore
        }/GenerateQuoteBankTransfer?artistId=${user.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        const { batchId, sourceAmount, exchangeRate, fees, fxRate, sourceCurrency, targetCurrency, targetAmount } = res.data;
        if (batchId) {
          setGenetationQuote({
            batchId: batchId,
            sourceAmount: sourceAmount,
            exchangeRate: exchangeRate,
            fees: fees,
            fxRate: fxRate,
            sourceCurrency: sourceCurrency,
            targetCurrency: targetCurrency,
            targetAmount: targetAmount
          });
          setAlert("Your payment is ready to cash out", "success");
        } else {
          setGenetationQuote({
            batchId: "",
            sourceAmount: "-",
            exchangeRate: "-",
            fees: "-",
            fxRate: "-",
            sourceCurrency: "",
            targetCurrency: "",
            targetAmount: "-"
          });
          setAlert("No Bank transfer payments were found.", "error");
        }
        setShowOverlay(false);
      }).catch((err) => {
        console.log(err);
        setShowOverlay(false);
        setAlert("Get Generation Quote failed, please retry later", "error");
      })
  }

  // @ts-ignore
  useEffect(async () => {
    // @ts-ignore
    if (user.paymentMethod.toLowerCase() === "paypal") {
      setLoadingSpinner(true);
      await axios
        .get(
          `${
          // @ts-ignore
          settings.payoutUrl ||
          "https://pikcha.local:1900/v1/payout"
          // @ts-ignore
          }/GenerateQuoteBankTransfer?artistId=${user.id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          const { batchId, sourceAmount, exchangeRate, fees, fxRate, sourceCurrency, targetCurrency, targetAmount } = res.data;
          if (batchId) {
            setGenetationQuote({
              batchId: batchId,
              sourceAmount: sourceAmount,
              exchangeRate: exchangeRate,
              fees: fees,
              fxRate: fxRate,
              sourceCurrency: sourceCurrency,
              targetCurrency: targetCurrency,
              targetAmount: targetAmount
            });
            setAlert("Your payment is ready to cash out", "success");
          } else {
            setGenetationQuote({
              batchId: "",
              sourceAmount: "-",
              exchangeRate: "-",
              fees: "-",
              fxRate: "-",
              sourceCurrency: "",
              targetCurrency: "",
              targetAmount: "-"
            });
            setAlert("No Bank transfer payments were found.", "error");
          }
        }).catch((err) => {
          console.log(err);
          setAlert("Get Generation Quote failed, please retry later", "error");
        })
      setLoadingSpinner(false);
    }
    // @ts-ignore
  }, [user.paymentMethod])

  const handleProcessBatch = () => {
    setMessage("Processing Payment");
    setShowOverlay(true);
    axios.get(
      `${
      // @ts-ignore
      settings.payoutUrl ||
      "https://pikcha.local:1900/v1/payout"
      // @ts-ignore
      }/ProcessBatchForBankTransfer?batchId=${genetationQuote.batchId}&artistId=${user.id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      setPaidSuccessful(true);
      setShowOverlay(false);
      setAlert("Your payment is proceeding. You will receive an email for confirmation", "success");
    }).catch(() => {
      setShowOverlay(false);
    })
  }

  return (
    <div
      style={{
        border: '1px solid #DBE0E4',
        borderRadius: 4,
        marginBottom: "4em",
        padding: "2em 1em 1.5em 1em",
        position: "relative",
        minHeight: !isOnMobile && '601px'
      }}
    >
      {showOverlay && (
        /*// @ts-ignore*/
        <NextPaymentOverlay message={message} />
      )}
      <TypographySubtitle
        style={{ marginBottom: '1.5em' }}
        component={isOnMobile ? TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Bold : TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
      >
        Next Payment
      </TypographySubtitle>
      <div>
        <TypographyBody
          size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
          style={{ marginBottom: '0.5em' }}
        >
          Your Earnings
        </TypographyBody>
        <div
          className="full-width"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between'
          }}
        >
          <div>
            <TypographyBody
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{ marginBottom: '0.5em' }}
            >
              Earnings to be paid
            </TypographyBody>
            <TypographyBody
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{ marginBottom: '0.5em' }}
            >
              Earnings from pending sales
            </TypographyBody>
            <TypographyBody
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{ marginBottom: '0.5em' }}
            >
              Earnings from cancelled sales
            </TypographyBody>
          </div>
          <div style={{ textAlign: 'right' }}>
            <TypographyBody
              style={{ marginBottom: '0.5em' }}
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
            >
              $USD {parseInt(paymentHistoryData?.AmountDue).toFixed(2) === "NaN" ? 0 : parseInt(paymentHistoryData?.AmountDue).toFixed(2)}
            </TypographyBody>
            <TypographyBody
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{ marginBottom: '0.5em' }}
            >
              $USD {parseInt(paymentHistoryData?.PendingAmount).toFixed(2) === "NaN" ? 0 : parseInt(paymentHistoryData?.PendingAmount).toFixed(2)}
            </TypographyBody>
            <TypographyBody
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{ marginBottom: '0.5em' }}
            >
              $USD {historyData?.totalCancelledArtistMargin}
            </TypographyBody>
          </div>
        </div>
      </div>
      <div className='mt-2-em'>
        <TypographyBody
          style={{ marginBottom: '0.5em' }}
          size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
        >
          Payment of earnings
        </TypographyBody>
        {genetationQuote.sourceCurrency && genetationQuote.targetCurrency && genetationQuote.exchangeRate && (
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
            style={{ marginBottom: '1em' }}
          >
            Currency conversion ({genetationQuote.sourceCurrency}-{genetationQuote.targetCurrency}) = {genetationQuote.exchangeRate}
          </TypographyBody>
        )}
        <div
          className="full-width"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between'
          }}
        >
          <div>
            <TypographyBody
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{ marginBottom: '0.5em' }}
            >
              Earnings to be paid
            </TypographyBody>
            {/*// @ts-ignore*/}
            {user.paymentMethod.toLowerCase() === "bank-transfer" && (
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                Less payment fees
              </TypographyBody>
            )}
            {/*// @ts-ignore*/}
            {user.paymentMethod.toLowerCase() === "bank-transfer" && (
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                Less conversion costs
              </TypographyBody>
            )}
            <TypographyBody
              size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
              style={{ marginBottom: '1.5em' }}
            >
              Net payment to be received
            </TypographyBody>
          </div>
          <div>
            {!loadingSpinner && (
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                {genetationQuote.sourceAmount === "-" ?
                  genetationQuote.sourceAmount : (
                    <>
                      {/* @ts-ignore*/}
                      <IntlCurrencyFormatter targetCurrency={genetationQuote.sourceCurrency} />{genetationQuote.sourceCurrency} {genetationQuote.sourceAmount.toFixed(2)}
                    </>
                  )}
              </TypographyBody>
            )}
            {/*// @ts-ignore*/}
            {user.paymentMethod.toLowerCase() === "bank-transfer" && (
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                {
                  genetationQuote.fees === "-" ?
                    genetationQuote.fees : (
                      <>
                        {/*// @ts-ignore*/}
                        <IntlCurrencyFormatter targetCurrency={genetationQuote.sourceCurrency} />{genetationQuote.sourceCurrency} {genetationQuote.fees.toFixed(2)}
                      </>
                    )}
              </TypographyBody>
            )}
            {/*// @ts-ignore*/}
            {user.paymentMethod.toLowerCase() === "bank-transfer" && (
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '0.5em' }}
              >
                {genetationQuote.fxRate === "-" ?
                  genetationQuote.fxRate : (
                    <>
                      {/*// @ts-ignore*/}
                      <IntlCurrencyFormatter targetCurrency={genetationQuote.sourceCurrency} />{genetationQuote.sourceCurrency} {genetationQuote.fxRate.toFixed(2)}
                    </>
                  )}
              </TypographyBody>
            )}
            {!loadingSpinner && (
              <TypographyBody
                size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Regular : TYPOGRAPHY_BODY_SIZE.Default_Regular}
                style={{ marginBottom: '1.5em' }}
              >
                {genetationQuote.fxRate === "-" ?
                  genetationQuote.fxRate : (
                    <>
                      {/* @ts-ignore*/}
                      <IntlCurrencyFormatter targetCurrency={genetationQuote.targetCurrency} />{genetationQuote.targetCurrency} {genetationQuote.targetAmount.toFixed(2)}
                    </>
                  )}
              </TypographyBody>
            )}
          </div>
        </div>
        {/**@ts-ignore */}
        {user.paymentMethod.toLowerCase() === "bank-transfer" && (
          <div
            className="flex mt-1-em full-width"
            style={{
              flexDirection: isOnMobile ? "column" : "row"
            }}
          >
            <TypographyButton
              component={!genetationQuote.batchId ? TYPOGRAPHY_BUTTON_TYPE.Outline : TYPOGRAPHY_BUTTON_TYPE.Primary}
              type="button"
              buttonStyle={{
                width: isOnMobile ? "100%" : "50%",
                marginRight: "0.5em",
                marginBottom: isOnMobile ? "0.5em" : "0"
              }}
              textStyle={{
                fontWeight: typography.fontWeightMedium,
                color: !genetationQuote.batchId ? colors.Neutral.ink90 : colors.Neutral.grey50
              }}
              onClick={handleGenerateQuote}
              disabled={genetationQuote.batchId ? true : false}
            >
              Generate Quote
            </TypographyButton>
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Primary}
              onClick={handleProcessBatch}
              disabled={!genetationQuote.batchId || paidSuccessful ? true : false}
              type="button"
              buttonStyle={{
                width: isOnMobile ? "100%" : "50%",
              }}
              textStyle={{
                color: !genetationQuote.batchId || paidSuccessful ? colors.Neutral.grey50 : colors.Neutral.white00,
                fontWeight: typography.fontWeightMedium
              }}
            >
              Get Paid Now
            </TypographyButton>
          </div>
        )}
        {/**@ts-ignore */}
        {user.paymentMethod.toLowerCase() === "paypal" && (
          <div className="flex mt-1-em">
            <TypographyButton
              component={TYPOGRAPHY_BUTTON_TYPE.Primary}
              onClick={handleProcessBatch}
              type="button"
              disabled={!genetationQuote.batchId || paidSuccessful ? true : false}
              buttonStyle={{
                width: "100%"
              }}
              textStyle={{
                fontWeight: typography.fontWeightMedium
              }}
            >
              Cash Out Earnings
            </TypographyButton>
          </div>
        )}
      </div>
      {/*// @ts-ignore*/}
      {!user.paymentMethod && (
        <div className="mt-2-em">
          <Link
            to="/account/details#payment-information"
          >
            <TypographyButton
              buttonStyle={{
                width: "100%"
              }}
            >
              Add Your Payment Details
            </TypographyButton>
          </Link>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  settings: state.settingsReducer,
});

export default connect(mapStateToProps, {
  setAlert,
})(NextPayment)
