import React from "react";
import { Link } from "react-router-dom";

import { PikchaButton } from "../../components/baseComponents";
import { getCustomisePath, removeModalOverlay } from "../../helpers/utils";
import {PikchaLink} from "../../components/baseComponents/PikchaLink";

export default ({ image, imageNumber }) => (
  <div className="">
    <PikchaLink
      className="no-underline"
      to={{
        pathname: getCustomisePath(image),
        state: { image: image },
      }}
    >
      <PikchaButton fullWidth onClick={removeModalOverlay}>
        Print and buy #{imageNumber}
      </PikchaButton>
    </PikchaLink>
  </div>
);
