import React, { useRef, useEffect, useState } from "react";

import { useTheme } from "@material-ui/core/styles";

export default () => {
  const { palette, typography } = useTheme();

  const tile = useRef(null);
  const [itemWidth, setItemWidth] = useState(0);

  const handleResize = () => {
    const { width } = tile.current ? window.getComputedStyle(tile.current) : {};

    const widthValue = parseFloat(width);

    setItemWidth(widthValue);
  };
  useEffect(() => {
    handleResize();
  }, [tile.current]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div ref={tile}>
      <div
        style={{
          borderRadius: 6,
          width: "100%",
          height: itemWidth,
          backgroundColor: palette.grey.light,
          display: "flex",
        }}
      >
        <div
          style={{
            borderRadius: "6px 0 0 6px",
            width: "calc(50% - 1px)",
            backgroundColor: palette.grey.lightest,
          }}
        />
        <div
          style={{
            width: "50%",
            marginLeft: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              borderRadius: "0px 6px 0px 0px",
              height: "calc(50% - 1px)",
              backgroundColor: palette.grey.lightest,
            }}
          />
          <div
            style={{
              borderRadius: "0px 0px 6px 0px",
              marginTop: 1,
              height: "50%",
              backgroundColor: palette.grey.lightest,
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "0.25em",
        }}
      >
        <div
          style={{
            borderRadius: 6,
            width: "75%",
            height: 28,
            backgroundColor: palette.grey.lightest,
          }}
        />
        <div
          style={{
            marginLeft: "auto",
            borderRadius: 6,
            width: 28,
            height: 28,
            backgroundColor: palette.grey.lightest,
          }}
        />
      </div>
    </div>
  );
};
