import {
  COLLECTION_ERROR,
  COLLECTION_LOADED,
  COLLECTION_LOADING,
  COLLECTION_RESET,
  GET_USER_COLLECTION_MORE,
} from '../actions/types';

const initialState = {
  collections: [],
  count: 5,
  start: 1,
  loading: true,
  error: null,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COLLECTION_LOADED:
      return {
        ...state,
        collections: [...state.collections, ...payload],
        loading: false,
        start: state.start + state.count,
      };
    case GET_USER_COLLECTION_MORE:
      const collections = [...state.collections, ...payload];
      return {
        ...state,
        collections,
        loading: false,
        start: state.start + state.count,
        hasMore: payload.length >= state.count,
      };
    case COLLECTION_ERROR:
      payload.data === 'You have reached the end.' && console.error(payload);
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: !payload.data === 'You have reached the end.',
      };
    case COLLECTION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case COLLECTION_RESET:
      return initialState;

    default:
      return state;
  }
};
