import React from "react";
import {connect} from "react-redux";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import {useTheme} from "@material-ui/core/styles";

import {FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, DiscordIcon} from "../../components/extraIcons";
import PinterestIcon from "../../assets/images/PinterestIcon.svg";
import {PikchaSpacer} from "../../components/baseComponents";

import whiteLogo from "../../assets/images/White_Pikcha_SVG.svg";
import visaIcon from "../../assets/images/visa_1.svg";
import masterIcon from "../../assets/images/mastercard_1.svg";
import amexIcon from "../../assets/images/amex_1.svg";
import unionPayIcon from "../../assets/images/unionpay_1.svg";
import dinersIcon from "../../assets/images/diners_1.svg";
import discoverIcon from "../../assets/images/discover_1.svg";
import jcbIcon from "../../assets/images/jcb_1.svg";
import paypalIcon from "../../assets/images/paypal.jpg";
import {toggleSafeSearch} from "../../actions/ageRestriction";
import {isSafeSearchEnabled} from "../../selectors/account";
import {PikchaLink} from "../../components/baseComponents/PikchaLink";
import {makeStyles} from "@material-ui/core";
import CurrencyPicker from "../../components/CurrencyPicker/CurrencyPicker";
import {UnitPicker} from "../../components/UnitPicker/UnitPicker";
import {PikchaToggleSwitch} from "../../components/baseComponents/PikchaToggleSwitch/PikchaToggleSwitch";

const PageFooterMobile = ({toggleSafeSearch, safeSearch}) => {
  const {palette, typography} = useTheme();

  const useStyles = makeStyles((theme) => ({
    toggle: {
      '& .MuiSwitch-track': {
        backgroundColor: palette.grey["600"]
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: palette.common.black,
      },
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          '& + .MuiSwitch-track': {
            backgroundColor: palette.grey.light,
          },
        }
      },
    }
  }))

  const customClasses = useStyles();

  const _handleSafeSearch = () => {
    toggleSafeSearch();
  };

  return (
    <footer
      className="full-width flex-column relative"
      style={{
        backgroundColor: "#191919",
        marginTop: "8em",
      }}
    >
      <PikchaSpacer size={8} />
      <div
        style={{
          padding: "0 0 1em 2em",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div>
          <PikchaLink to="/">
            <img style={{height: 35,}} src={whiteLogo} alt="Pikcha Logo" />
          </PikchaLink>
        </div>
        <div
          style={{
            fontWeight: typography.fontWeightMedium,
            color: palette.grey.light,
            fontSize: typography.smallest,
            marginTop: "1em",
          }}
        >
          &nbsp; &nbsp; <span style={{fontSize: 15,}}>&#9679;</span>  &nbsp; &nbsp;  Signed. Rare. Original
        </div>
      </div>
      <PikchaSpacer />
      <div
        className="full-width pl-2-em pr-2-em"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginBottom: "1em",
        }}
      >

        <div>
          <div
            style={{
              color: palette.grey.mediumDark,
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightBold,
              marginBottom: "0.75em",
            }}
          >
            Policies
          </div>
          <ul>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/policies/privacy"
                >
                  Privacy
                </PikchaLink>
              </span>
            </li>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/policies/user-agreement"
                >
                  User Agreement
                </PikchaLink>
              </span>
            </li>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/policies/terms-of-use"
                >
                  Terms of Use
                </PikchaLink>
              </span>
            </li>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/policies/ip"
                >
                  IP Policy
                </PikchaLink>
              </span>
            </li>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/policies/privacy"
                >
                  Cookie Policy
                </PikchaLink>
              </span>
            </li>
          </ul>
        </div>
        <div>
          <div
            style={{
              color: palette.grey.mediumDark,
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightBold,
              marginBottom: "0.75em",
            }}
          >
            Resources
          </div>
          <ul>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/about"
                >
                  About Us
                </PikchaLink>
              </span>
            </li>
      
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/guides/customer/social-initiatives#sustainability"
                >
                  Sustainability
                </PikchaLink>
              </span>
            </li>
          </ul>
        </div>

      </div>
      <div
        className="full-width pl-2-em pr-2-em"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginBottom: "1em",
        }}
      >

        <div>
          <div
            style={{
              color: palette.grey.mediumDark,
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightBold,
              marginBottom: "0.75em",
            }}
          >
            Help
          </div>
          <ul>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/contact"
                >
                  Contact
                </PikchaLink>
              </span>
            </li>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/contact"
                >
                  FAQs
                </PikchaLink>
              </span>
            </li>
          
          </ul>
        </div>
        <div>
          <div
            style={{
              color: palette.grey.mediumDark,
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightBold,
              marginBottom: "0.75em",
            }}
          >
            Discover
          </div>
          <ul>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <a
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  href="https://blog.pikcha.com/"
                  target="_blank"
                >
                  Blog
                </a>
              </span>
            </li>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/pikcha100"
                >
                  Pikcha 100
                </PikchaLink>
              </span>
            </li>
            <li>
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <PikchaLink
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                  to="/artist100"
                >
                  Artist 100
                </PikchaLink>
              </span>
            </li>
          </ul>
        </div>

      </div>
      <PikchaSpacer />

      <div
        className="full-width pl-2-em pr-2-em"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginBottom: "1em",
        }}
      >
        <div>
          <div
            style={{
              color: palette.grey.mediumDark,
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightBold,
              paddingBottom: "1em",
            }}
          >
            Socials
          </div>
          <ul>
            <li
              style={{
                paddingBottom: "0.5em",
              }}
            >
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <a
                  className="flex-center"
                  href="https://www.facebook.com/Pikcha-275041869853091/"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                >
                  <FacebookIcon />
                  &nbsp;Facebook
                </a>
              </span>
            </li>
            <li
              style={{
                paddingBottom: "0.5em",
              }}
            >
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <a
                  className="flex-center"
                  href="https://www.instagram.com/pikcha_official/?hl=en"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                >
                  <InstagramIcon />
                  &nbsp;Instagram
                </a>
              </span>
            </li>
            <li
              style={{
                paddingBottom: "0.5em",
              }}
            >
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <a
                  className="flex-center"
                  href="https://www.linkedin.com/company/pikcha-it/"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                >
                  <LinkedInIcon />
                  &nbsp;LinkedIn
                </a>
              </span>
            </li>
            <li
              style={{
                paddingBottom: "0.5em",
              }}
            >
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <a
                  className="flex-center"
                  href="https://discord.gg/B9QPCzfEnv"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                >
                  <DiscordIcon />
                  &nbsp;Discord
                </a>
              </span>
            </li>
            <li
              style={{
                paddingBottom: "0.5em",
              }}
            >
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <a
                  className="flex-center"
                  href="https://twitter.com/pikcha_official"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                >
                  <TwitterIcon />
                  &nbsp;Twitter
                </a>
              </span>
            </li>
            <li
              style={{
                paddingBottom: "0.5em",
              }}
            >
              <span
                className="underlined-link"
                style={{
                  color: palette.grey.mediumDark,
                  fontSize: typography.smallest,
                  fontWeight: typography.fontWeightStandard,
                }}
              >
                <a
                  className="flex-center"
                  href="https://www.pinterest.com.au/Pikcha_official/"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: palette.grey.light,
                  }}
                >
                  <img style={{height: 16,}} src={PinterestIcon} alt="Pinterest Icon" />
                  &nbsp;Pinterest
                </a>
              </span>
            </li>
          </ul>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateRows: 'auto auto',
              alignItems: "center",
            }}>
            <div style={{
              width: '100%',
              color: palette.grey.mediumDark,
              fontSize: typography.smallest,
              fontWeight: typography.fontWeightBold,
              paddingBottom: "1em"
            }}>
              User Preferences
            </div>
            <div>
              <ul>
                <li style={{paddingBottom: "0.2em"}}>
                  <CurrencyPicker customStyles={{color: palette.grey.light}} />
                </li>
                <li style={{paddingBottom: "0.2em"}}>
                  <UnitPicker customStyles={{color: palette.grey.light}} />
                </li>
                <li style={{paddingBottom: "0.2em"}}>
                  <div className={'pf-pikcha-safeSearch-wrapper'}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: '0 1em 0 0'}}>
                      <VisibilityRoundedIcon style={{width: '24px', height: '24px', color: palette.grey.light}} />
                    </div>
                    <div className={'pf-pikcha-safeSearch-container'}>
                      <PikchaToggleSwitch
                        checked={safeSearch}
                        className={customClasses.toggle}
                        onClick={() => {
                          _handleSafeSearch();
                        }} />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <PikchaSpacer size={6} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={paypalIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={visaIcon}
            width={26} />
          <img
            style={{
              marginLeft: 4,
            }}
            src={masterIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={amexIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={unionPayIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={dinersIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={discoverIcon}
            width={26}
          />
          <img
            style={{
              marginLeft: 4,
            }}
            src={jcbIcon}
            width={26}
          />
        </div>
      </div>

      <PikchaSpacer size={2} />

      <div
        className="flex-center justify-center"
        style={{
          fontWeight: typography.fontWeightMedium,
          color: palette.grey.medium,
          fontSize: typography.smallest,
        }}
      >
        <sup
          style={{
            verticalAlign: "super",
          }}
        >
          ©
        </sup>
        &nbsp;Pikcha. All Rights Reserved.
      </div>
      <PikchaSpacer size={2} />
    </footer>
  );
};

const mapStateToProps = (state) => ({
  safeSearch: isSafeSearchEnabled(state),
});


export default connect(mapStateToProps, {
  toggleSafeSearch,
})(PageFooterMobile);
