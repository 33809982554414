import React, { Fragment } from "react";
import { connect } from "react-redux";

import ProfileDropdown from "../ProfileDropdown";
import NotificationsDropdown from "../NotificationsDropdown/NotificationsDropdown";
import CartDropdown from "../CartDropdown";
import FollowingDropdown from "../FollowingDropdown";

import "./DropdownBase.css";

const DropdownBase = ({ nav }) => (
  <Fragment>
    {nav.dropDown === "Profile" && <ProfileDropdown />}
    {nav.dropDown === "Menu" && <NotificationsDropdown />}
    {nav.dropDown === "Cart" && <CartDropdown />}
    {nav.dropDown === "Following" && <FollowingDropdown />}
  </Fragment>
);

const mapStateToProps = (state) => ({
  nav: state.navReducer,
});

export default connect(mapStateToProps)(DropdownBase);
