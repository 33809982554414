import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";

import { setAlert } from "../../../actions/alert";
import {
  openAuthentication,
  openRegistration,
} from "../../../actions/authModal";

import { PikchaButton } from "../../../components/baseComponents";

// import AuthPreviewDecor from "../../../assets/images/auth-preview-decor.png";
import AuthPreviewDecor from "../../../assets/images/HowItWorks_step3.jpg";

const AuthModalFormContainer = ({
  origin,
  handleClickClose,
  openAuthentication,
  openRegistration,
}) => {
  const { palette, typography } = useTheme();

  const history = useHistory();
  const location = useLocation();

  const onJoin = () => {
    openRegistration(history, location);
  };

  const onLogin = () => {
    openAuthentication(history, location);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          // width: "100%",
          padding: "2em",
          maxWidth: 440,
        }}
      >
        <div
          style={{
            marginBottom: "2em",
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.large,
            color: palette.text.primary,
          }}
        >
          {origin === "customisation" || origin === "cart"
            ? "Sign in to Checkout"
            : origin === "following"
              ? "Sign in to follow artists"
              : origin === "safe-search"
                ? "Sign in to remove safe search"
                : origin === "notifications"
                  ? "Sign in to receive notifications"
                  : "Sign in to save images"}
        </div>
        <div>
          <div
            style={{
              fontWeight: typography.fontWeightMedium,
              fontSize: typography.standard,
              color: palette.text.secondary,
              marginBottom: "1em",
            }}
          >
            {origin !== "cart" && origin !== "customisation"
              ? "New Users"
              : "New Customers"}
          </div>
          <PikchaButton
            fullWidth
            onClick={onJoin}>
            Create An Account
            </PikchaButton>
        </div>
        <div
          style={{
            marginTop: "2em",
          }}
        >
          <div
            style={{
              fontWeight: typography.fontWeightMedium,
              fontSize: typography.standard,
              color: palette.text.secondary,
              width: "max-content",
              marginBottom: "1em",
            }}
          >
            {origin !== "cart" && origin !== "customisation"
              ? "Returning Users"
              : "Returning Customers"}
          </div>
          <PikchaButton
            action="octonary"
            fullWidth
            onClick={onLogin}
            mainStyle={{
              height: 44,
              fontSize: 16,
              fontWeight: 700
            }}
          >
            Log In
            </PikchaButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: 400,
          height: 400,
          backgroundImage: `url(${AuthPreviewDecor})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  setAlert,
  openAuthentication,
  openRegistration,
})(AuthModalFormContainer);
