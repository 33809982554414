import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core";

import { setAlert } from "../../../actions/alert";
import { useQueryParams } from "../../../hooks/useQueryParams";
import {
  PikchaButton,
  PikchaSubTitle,
  PikchaTextField,
  PikchaTitle,
} from "../../baseComponents";
import {
  PASSWORD_CONFIRMATION_ERROR_MESSAGE,
  PASSWORD_ERROR_MESSAGE,
  PASSWORD_REQUIREMENTS_LABEL,
} from "../../../helpers/authMessages/constants";
import { isPasswordValid } from "../../../helpers/utils";

const ResetPasswordConfirmation = ({ setAlert }) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(
    false
  );

  const [disableTextFields, setDisableTextFields] = useState(false);

  const { typography } = useTheme();

  const { cleanedParamA: userId, cleanedParamB: token } = useQueryParams([
    "?userId=",
    "token=",
  ]);

  const handlePassword = ({ target: { value } }) => {
    setPassword(value);
    if (passwordError) {
      handlePasswordValidation();
    }
  };
  const handlePasswordValidation = () => {
    setPasswordError(!isPasswordValid(password));
  };

  const handlePasswordConfirmation = ({ target: { value } }) => {
    setPasswordConfirmation(value);
    if (passwordConfirmationError) {
      handlePasswordConfirmationValidation();
    }
  };
  const handlePasswordConfirmationValidation = () => {
    setPasswordConfirmationError(password !== passwordConfirmation);
  };

  const handleEnter = ({ key }) => {
    if (key && key === "Enter") {
      handlePasswordValidation();
      handlePasswordConfirmationValidation();
      handleSubmit();
    }
  };

  const handleDisabledTextFields = (state) => {
    setDisableTextFields(state);
  };

  const handleSubmit = () => {
    if (disableTextFields) {
      return;
    }

    if (!isPasswordValid(password)) {
      return setPasswordError(true);
    }

    if (password !== passwordConfirmation) {
      return setPasswordConfirmationError(true);
    }

    axios
      .post("/api/Account/forgotpasswordstep2", {
        userId: userId,
        token: token,
        newPassword: password,
      })
      .then((res) => {
        if (res.status === 200) {
          handleDisabledTextFields(true);
          setAlert("Your password has been changed", "success");
        }
      })
      .catch((err) => {
        setAlert(
          "An error occurred. Please try again or contact support",
          "danger"
        );
        console.log("handleSubmit ", err);
      });
  };

  return (
    <div
      style={{
        maxWidth: 320,
      }}
    >
      <div className="mb-10">
        <PikchaTitle>Password Reset</PikchaTitle>
        <PikchaSubTitle>
          Protect your account with a new password.
        </PikchaSubTitle>
      </div>
      <PikchaTextField
        label="New Password"
        type="password"
        value={password}
        onChange={handlePassword}
        onBlur={handlePasswordValidation}
        onKeyPress={handleEnter}
        disabled={disableTextFields}
        subLabel={PASSWORD_REQUIREMENTS_LABEL}
        error={!!passwordError}
        helperText={passwordError ? PASSWORD_ERROR_MESSAGE : null}
        required
      />
      <PikchaTextField
        label="Confirm Password"
        type="password"
        value={passwordConfirmation}
        onChange={handlePasswordConfirmation}
        onBlur={handlePasswordConfirmationValidation}
        onKeyPress={handleEnter}
        disabled={disableTextFields}
        error={!!passwordConfirmationError}
        helperText={
          passwordConfirmationError ? PASSWORD_CONFIRMATION_ERROR_MESSAGE : null
        }
        required
      />
      <PikchaButton style={{ float: "right" }} onClick={handleSubmit}>
        Submit
      </PikchaButton>
      <div
        className="AuthFormBase-footerText mt-10"
        style={{
          fontSize: typography.smallest,
        }}
      >
        <Link to="/auth/login">Back to login</Link>
      </div>
    </div>
  );
};

export default connect(null, { setAlert })(ResetPasswordConfirmation);
