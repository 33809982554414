import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLocation} from "react-router-dom";
import {setAlert} from '../../actions/alert';
import {addProductToShoppingCart, notifyQueueStatus,} from '../../actions/shoppingCart';
import {
  hideNotification,
  showNotification,
  updateNotificationItem,
  updateNotificationType,
} from '../../actions/notification';
import {handleAuthModal, openAuthentication, openRegistration,} from '../../actions/authModal';
import {isUserLoggedIn} from '../../selectors/account';
import {
  ARTIST_PROFILE_INFO_LOADING,
} from "../../actions/types";
import {
  getArtistProfileInfo,
  getProfile,
  resetArtistPhotos,
  resetProfile,
  resetUpdatedFields,
} from "../../actions/profile";
import {searchAPIUrl} from "../../selectors/settings";
import { PIKCHA_GUEST_CART, reservationStatusCode, PIKCHA_CDN } from '../../helpers/constants';
import Desktop from '../../components/Responsive/Desktop';
import Tablet from '../../components/Responsive/Tablet';
import Mobile from '../../components/Responsive/Mobile';
import CustomiseDesktop from './CustomiseDesktop';
import CustomiseMobile from './CustomiseMobile';
import ReservationsConnection from './ReservationsConnection';
import {changeCurrency} from "../../actions/changeCurrency";
import {SHOPPING_CART_CURRENCY_CHANGE} from "../../actions/types";
import { PIKCHA_CONFIG_CDN } from "../../helpers/constants";

const Customise = ({
                     account,
                     getArtistProfileInfo,
                     searchAPIUrl,
                     location,
                     shoppingCart,
                     showNotification,
                     notification,
                     addProductToShoppingCart,
                     updateNotificationType,
                     updateNotificationItem,
                     setAlert,
                     sideDrawer,
                     isUserLoggedIn,
                     profile,
                     openAuthentication,
                     openRegistration,
                     settings: {settings},
                   }) => {
  const {pathname} = useLocation();
  const history = useHistory();
  const [startCrop, setStartCrop] = useState(false);
  const [imageRenderer, setImageRenderer] = useState(null);
  const [summary, setSummary] = useState({
    selectedMaterial: null,
    allMaterials: [],
    selectedSize: null,
    allSizes: [],
    selectedBorder: null,
    allBorders: [],
    selectedFrame: null,
    allFrames: [],
    imageRender: null,
  });
  const [jondoConfigMaterials, setJondoConfigMaterials] = useState();
  const [fields, setFields] = useState({
    assetNumber: "",
    selectedCanvas: jondoConfigMaterials ? jondoConfigMaterials[0] : undefined,
    selectedMaterial: null,
    allMaterials: jondoConfigMaterials,
    selectedSize: null,
    allSizes: [],
    selectedBorder: null,
    allBorders: [],
    selectedFrame: null,
    allFrames: [],
  });
  const [galleryImages, setGalleryImages] = useState([]);
  const [feature, setFeature] = useState([]);
  const [isCanvasLoading, setIsCanvasLoading] = useState(false);
  const [galleryCard, setGalleryCard] = useState('white');
  const [productPrices, setProductPrices] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [clickAsGuest, setClickAsGuest] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [shotsGenerated, setShotsGenerated] = useState(true);
  // start
  // Need to review
  // Note: when the auth flow will be implemented in the popup, we will not
  // need to keep the image iamge in the localstorage.
  if (location.state == null) {
    location.state = JSON.parse(localStorage.getItem('replica'));
    //console.log(location.state);
  }
  if (location.state) {
    localStorage.removeItem('replica');
    localStorage.setItem('replica', JSON.stringify(location.state));
  }
  // end
  const expectedProductNumber = location?.state?.image?.totSold;
  const [descriptionCard, setDescriptionCard] = useState('');
  const [inverseDescriptionCard, setInverseDescriptionCard] = useState('');
  const [isAlreadySigned, setIsAlreadySigned] = useState();
  const [isSigned, setIsSigned] = useState();
  const [signatureAlignment, setSignatureAlignment] = useState('');
  const [signatureSample, setSignatureSample] = useState('');
  const [authModalOpen, setAuthModalOpen] = useState();
  const [continueAsGuest, setContinueAsGuest] = useState();
  const [loginAfterCart, setLoginAfterCart] = useState();
  const [registerAfterCart, setRegisterAfterCart] = useState();
  const [imageThumbnail, setImageThumbnail] = useState("")

  useEffect(() => {
    const existingUrl = location?.state?.image.friendlyUrl;
    const shortId = location.pathname.split('/')[2];
    console.log(existingUrl?.indexOf(shortId));
    if(!location.state?.image || existingUrl?.indexOf(shortId) === -1){
      console.log(existingUrl);
      console.log(shortId);
      axios.get(`api/image/friendly/artwork/${shortId}`).then((resp) => {
        console.log(resp.data);
        location.state = {
          image: {
            artistId: resp.data.artist.id,
            ...resp.data
          }
        }
      })
    }
  }, [location.state?.image]);

  useEffect(() => {
    let isOwnProfile = false;
    if (account.user && account.user.friendlyUrl) {
      isOwnProfile = window.location.href.includes(account.user.friendlyUrl);
    }
  }, [Customise, account]);

  useEffect(async () => {
    if(!location.state || !location.state?.image || !location.state?.image?.artist?.id) {
      return;
    }

    getArtistProfileInfo(location.state?.image?.artist?.id);
    return () => {
      resetArtistPhotos();
      resetUpdatedFields();
      resetProfile();
    };
  }, [pathname, searchAPIUrl, location.state?.image?.artist?.id]);

  const onEditionSelected = (reservation) => {
    const {productNumber: selectedProductNumber} = reservation;
    const newReservations = reservations.map((thisReservation) => {
      const {productNumber, statusCode} = thisReservation;

      let newStatusCode = statusCode;

      if (productNumber === selectedProductNumber) {
        if (statusCode !== reservationStatusCode.selectedByUser) {
          newStatusCode = reservationStatusCode.selectedByUser;
        } else {
          newStatusCode = reservationStatusCode.availableForPurchase;
        }
      } else {
        if (newStatusCode === reservationStatusCode.selectedByUser) {
          newStatusCode = reservationStatusCode.availableForPurchase;
        }
      }

      return {
        ...thisReservation,
        statusCode: newStatusCode,
      };
    });
    setReservations(newReservations);
  };

  const onReservationsUpdated = (items) => {
    let editionCap = location.state?.image?.totalEditions ? location.state?.image?.totalEditions : 100;
    setReservations((reservations) => {
      if (reservations.length < 1) {
        let defaultReservations = [];
        for (let i = 0; i < editionCap; i++) {
          const productNumber = i + 1;
          defaultReservations = [
            ...defaultReservations,
            ...[
              {
                productNumber,
                statusCode: reservationStatusCode.soldOut,
              },
            ],
          ];
        }

        let pre_reserved_item;
        defaultReservations.reduce((accumulator, item) => {
          const {statusCode} = item;

          let newStatusCode = statusCode;
          if (
            !pre_reserved_item &&
            statusCode === reservationStatusCode.availableForPurchase
          ) {
            newStatusCode = reservationStatusCode.selectedByUser;
            pre_reserved_item = item;
          }

          return [
            ...accumulator,
            ...[
              {
                ...item,
                statusCode: newStatusCode,
              },
            ],
          ];
        }, []);

        return defaultReservations;
      }
      return reservations;
    });
    setReservations((reservations) => {
      let newReservations = reservations.map((reservation) => {
        const {productNumber, statusCode} = reservation;

        let newStatusCode = statusCode;
        const matchingItem = items.find(
          ({productNumber: productNumberRhs}) =>
            productNumber === productNumberRhs,
        );
        if (matchingItem) {
          const {statusCode: matchingStatusCode} = matchingItem;

          if (
            matchingStatusCode === reservationStatusCode.availableForPurchase &&
            newStatusCode === reservationStatusCode.selectedByUser
          ) {
            newStatusCode = reservationStatusCode.selectedByUser;
          } else {
            newStatusCode = matchingStatusCode;
          }
        }

        return {
          ...reservation,
          statusCode: newStatusCode,
        };
      });

      let pre_reserved_item = newReservations.find(
        ({statusCode}) => statusCode === reservationStatusCode.selectedByUser,
      );
      newReservations = newReservations.reduce((accumulator, item) => {
        const {statusCode} = item;

        let newStatusCode = statusCode;
        if (
          !pre_reserved_item &&
          statusCode === reservationStatusCode.availableForPurchase
        ) {
          newStatusCode = reservationStatusCode.selectedByUser;
          pre_reserved_item = item;
        }

        return [
          ...accumulator,
          ...[
            {
              ...item,
              statusCode: newStatusCode,
            },
          ],
        ];
      }, []);

      return newReservations;
    });
  };
  const imageRenderChanged = (e) => {
    setImageRenderer(e);
  };

  useEffect(() => {
    if(location.state && location.state?.image?.artistId){
    setImageThumbnail(process.env.NODE_ENV !== "production" ? 
      `${PIKCHA_CONFIG_CDN}/MockupTest/configtest/${summary.assetNumber}.jpg` : 
      settings?.searchAPIUrl?.indexOf("develop") !== -1 ? 
        `${PIKCHA_CONFIG_CDN}/Data/Test/${location.state?.image?.artistId}/Images/ConfigShots/${location.state?.image?.id}/${summary?.assetNumber}.jpg` :
        `${PIKCHA_CONFIG_CDN}/Data/Web/${location.state?.image?.artistId}/Images/ConfigShots/${location.state?.image?.id}/${summary?.assetNumber}.jpg`
    )
    }
  }, [summary.assetNumber, location.state?.image?.artistId, settings?.searchAPIUrl])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const postInitialization = () => {
    let isAddToCartFromAuth = false;
    if (location.state?.summary) {
      isAddToCartFromAuth = true;
      setSummary(location.state?.summary);
    }
    if (location.state?.fields) {
      isAddToCartFromAuth = true;
      setFields(location.state?.fields);
    }
    // Need to review
    // start
    // added this code to solve the issue regaridng the transition form guest
    // to loggedin user   once we got redirected from auth, this part is
    // populating the cart
    if (isUserLoggedIn) {
      var needtoAddToCart = JSON.parse(localStorage.getItem('needtoAddToCart'));
      var cartInfo = JSON.parse(localStorage.getItem('cartInfo'));
      var additionalProductInfo = JSON.parse(localStorage.getItem(
        'additionalProductInfo'));

      if (needtoAddToCart) {
        {
          const SelectedProductNumber = cartInfo.SelectedProductNumber;
          addProductToShoppingCart(account.user.id, cartInfo).then((resp) => {
            updateNotificationType('cartItemNotification');
            if (resp.items.length > 0) {
              updateNotificationItem({
                id: resp.items[resp.items.length - 1].id,
                currentTime: notification.currentTime,
                startTimer: false,
                image: {
                  ...location.state?.image,
                  imageTitle: location.state?.image?.title,
                  imageRender: cartInfo.ImageRender,
                  thumbnail: imageThumbnail,
                  type: additionalProductInfo.type,
                  material: additionalProductInfo.material,
                  size: summary.selectedFrame.id !== "NA" ? summary.selectedSize.finalSizeInCm : summary.selectedSize.sizeInCm,
                  frame: additionalProductInfo.frame,
                  border: additionalProductInfo.border,
                  wrap: additionalProductInfo.wrap,
                  totalPrice: additionalProductInfo.totalPrice,
                  expectedProductNumber: SelectedProductNumber,
                  productNumber: SelectedProductNumber,
                  galleryCardColor: additionalProductInfo.galleryCardColor,
                },
              });
              setImageRenderer(null);
            }
            showNotification();
            setStartCrop(false);
            localStorage.setItem('needtoAddToCart', JSON.stringify(false));
            localStorage.setItem('additionalProductInfo', JSON.stringify(null));
            localStorage.setItem('cartInfo', JSON.stringify(null));
          });
        }
      }
    }
  };

  useEffect(() => {
    if (continueAsGuest) {
      setTimeout(() => {
        addToCart();
      }, 1000);
    }
  }, [continueAsGuest]);

  const updateSummary = (e) => {
    setSummary(e);
  };
  const featureChanged = (e) => {
    setFeature(e);
    let galleryImages = [];
    const canvasRender = e.canvasRender;
    const paperRender = e.paperRender;
    galleryImages.push({
      src: `${PIKCHA_CDN}/${canvasRender.BlackFrame600px}`,
      width: 1,
      height: 1,
      alt: "canvasRender.BlackFrame600px",
      material: "Canvas"
    });
    galleryImages.push({
      src: `${PIKCHA_CDN}/${canvasRender.DarkwoodFrame600px}`,
      width: 1,
      height: 1,
      alt: "canvasRender.DarkwoodFrame600px",
      material: "Canvas"
    })
    galleryImages.push({
      src: `${PIKCHA_CDN}/${canvasRender.NoFrame600px}`,
      width: 1,
      height: 1,
      alt: "canvasRender.NoFrame600px",
      material: "Canvas"
    })
    galleryImages.push({
      src: `${PIKCHA_CDN}/${canvasRender.WhiteFrame600px}`,
      width: 1,
      height: 1,
      alt: "canvasRender.WhiteFrame600px",
      material: "Canvas"
    })
    galleryImages.push({
      src: `${PIKCHA_CDN}/${paperRender.BlackFrame600px}`,
      width: 1,
      height: 1,
      alt: "paperRender.BlackFrame600px",
      material: "Paper"
    })
    galleryImages.push({
      src: `${PIKCHA_CDN}/${paperRender.DarkwoodFrame600px}`,
      width: 1,
      height: 1,
      alt: "paperRender.DarkwoodFrame600px",
      material: "Paper"
    })
    galleryImages.push({
      src: `${PIKCHA_CDN}/${paperRender.LightwoodFrame600px}`,
      width: 1,
      height: 1,
      alt: "paperRender.NoFrame600px",
      material: "Paper"
    })
    galleryImages.push({
      src: `${PIKCHA_CDN}/${paperRender.WhiteFrame600px}`,
      width: 1,
      height: 1,
      alt: "paperRender.WhiteFrame600px",
      material: "Paper"
    })
    setGalleryImages(galleryImages);
  };
  const onGalleryCardUpdate = (e) => {
    setGalleryCard(e);
  };

  const addToCart = async (e) => {
    const selectedReservation = reservations.find(
      ({productNumber, statusCode}) =>
        statusCode === reservationStatusCode.selectedByUser,
    );
    if (!selectedReservation) {
      setAlert('Select an edition', 'error');
      return;
    }

  
    if (!continueAsGuest) {
      if (!isUserLoggedIn) {
      
        setAuthModalOpen(true);
        return;
      }
    }

    setStartCrop(true);
    if (!summary.selectedMaterial && startCrop) {
      setAlert('Choose All options First', 'error');
      return;
    } else if (!summary.selectedSize) {
      setAlert('Please Select Size', 'error');
      return;
    } else if (!summary.selectedBorder) {
      setAlert('Please Select Border', 'error');
      return;
    } else if (!summary.selectedFrame) {
      setAlert('Please Select Frame', 'error');
      return;
    }
    hideNotification();

    let id;
    if (
      shoppingCart &&
      shoppingCart.shoppingCart &&
      shoppingCart.shoppingCart.id &&
      shoppingCart.shoppingCart.id !== 'undefined'
    ) {
      id = shoppingCart.shoppingCart.id;
    } else if (account && account.user && account.user.id) {
      id = account.user.id;
    } else {
      id = 'guest';
    }

    let Wrap = summary.wrap;

    if (summary.selectedFrame.id === 'Black Floater Frame') {
      Wrap = 'Framed';
    }

    const SelectedProductNumber = selectedReservation.productNumber;
    addProductToShoppingCart(id, {
      ImageId: location.state?.image?.id,
      GalleryCardColor: galleryCard,
      CustomizationIdentifier: summary.selectedBorder.code,
      Wrap,
      ImageRender: imageThumbnail,
      SelectedProductNumber,
      BorderSize: summary.selectedBorder.borderSize,
      FrameColor: summary.selectedFrame.id
    }).then((resp) => {
      if(!clickAsGuest){
        updateNotificationType('cartItemNotification');
      }
      if (resp?.items?.length > 0) {
        updateNotificationItem({
          id: resp.items[resp.items.length - 1].id,
          currentTime: notification.currentTime,
          startTimer: false,
          image: {
            ...location.state?.image,
            imageTitle: location.state?.image?.title,
            imageRender: imageThumbnail,
            thumbnail: imageThumbnail,
            type: summary.selectedCanvas.id,
            material: summary.selectedMaterial.id,
            size: summary.selectedFrame.id !== "NA" ? summary.selectedSize.finalSizeInCm : summary.selectedSize.sizeInCm,
            frame: summary.selectedFrame.id,
            border: summary.selectedBorder.id,
            wrap: summary.wrap,
            totalPrice: summary?.selectedSize?.price,
            expectedProductNumber: SelectedProductNumber,
            productNumber: SelectedProductNumber,
            galleryCardColor: resp.items[resp.items.length - 1].galleryCardColor,
          },
        });
        setImageRenderer(null);
      }
      showNotification();
      setStartCrop(false);

      if (id === 'guest') {
        localStorage.setItem(PIKCHA_GUEST_CART, resp.id);
      }

      localStorage.setItem('needtoAddToCart', JSON.stringify(false));
    });
  };

  const getProductPrice = () => {
    let resultPrice = 0;
    if (summary.selectedFrame) {
      productPrices.forEach((price) => {
        if (price.code ===summary.selectedBorder.code) {
          resultPrice = (price.pricePresentment).toFixed(2);
        }
      });
    }
    return resultPrice;
  };

  useEffect(() => {
    if (!imageRenderer) {
      setStartCrop(false);
      return;
    }

    let id;
    if (
      shoppingCart &&
      shoppingCart.shoppingCart &&
      shoppingCart.shoppingCart.id &&
      shoppingCart.shoppingCart.id !== 'undefined'
    ) {
      id = shoppingCart.shoppingCart.id;
    } else if (account && account.user && account.user.id) {
      id = account.user.id;
    } else {
      id = 'guest';
    }

    let Wrap = summary.wrap;

    if (summary.selectedFrame.id === 'Black Floater Frame') {
      Wrap = 'Framed';
    }
    const selectedReservation = reservations.find(
      ({productNumber, statusCode}) =>
        statusCode === reservationStatusCode.selectedByUser,
    );
    if (!selectedReservation) {
      setAlert('Select an edition', 'error');
      return;
    }

    // Need to review
    if (isUserLoggedIn || (!loginAfterCart && !registerAfterCart && continueAsGuest)) {
      const SelectedProductNumber = selectedReservation.productNumber;
      addProductToShoppingCart(id, {
        ImageId: location.state?.image?.id,
        GalleryCardColor: galleryCard,
        CustomizationIdentifier: summary.selectedBorder.code,
        Wrap,
        ImageRender: imageThumbnail,
        SelectedProductNumber,
      }).then((resp) => {
        if(!clickAsGuest){
          updateNotificationType('cartItemNotification');
        }
        if (resp?.items?.length > 0) {
          updateNotificationItem({
            id: resp.items[resp.items.length - 1].id,
            currentTime: notification.currentTime,
            startTimer: false,
            image: {
              ...location.state?.image,
              imageTitle: location.state?.image?.title,
              imageRender: imageThumbnail,
              thumbnail: imageThumbnail,
              type: summary.selectedCanvas.id,
              material: summary.selectedMaterial.id,
              size: summary.selectedFrame.id !== "NA" ? summary.selectedSize.finalSizeInCm : summary.selectedSize.sizeInCm,
              frame: summary.selectedFrame.id,
              border: summary.selectedBorder.id,
              wrap: summary.wrap,
              totalPrice: summary?.selectedSize?.price,
              expectedProductNumber: SelectedProductNumber,
              productNumber: SelectedProductNumber,
              galleryCardColor: resp.items[resp.items.length - 1].galleryCardColor,
            },
          });
          setImageRenderer(null);
        }
        showNotification();
        setStartCrop(false);

        if (id === 'guest') {
          localStorage.setItem(PIKCHA_GUEST_CART, resp.id);
        }

        localStorage.setItem('needtoAddToCart', JSON.stringify(false));
      });
    }

      // Need to review
      // setting important values to local storage, so that once we redirect
    // back, we can populate the cart
    else {
      const SelectedProductNumber = selectedReservation.productNumber;
      var cartInfo = {
        ImageId: location.state?.image?.id,
        GalleryCardColor: galleryCard,
        CustomizationIdentifier: summary.selectedBorder.code,
        Wrap,
        ImageRender: imageThumbnail,
        SelectedProductNumber,
      };

      var additionalProductInfo = {
        type: summary.selectedCanvas.id,
        material: summary.selectedMaterial.id,
        size: summary.selectedSize.id,
        frame: summary.selectedFrame.id,
        border: summary.selectedBorder.id,
        wrap: summary.wrap,
        totalPrice: summary?.selectedSize?.price,
        galleryCardColor: galleryCard,
      };

      localStorage.setItem('needtoAddToCart', JSON.stringify(true));
      localStorage.setItem(
        'additionalProductInfo',
        JSON.stringify(additionalProductInfo),
      );
      localStorage.setItem('cartInfo', JSON.stringify(cartInfo));
    }

    if (loginAfterCart) {
      openAuthentication(history, location);
    }
    if (registerAfterCart) {
      openRegistration(history, location);
    }
  }, [imageRenderer]);

  const preferredCurrency = useSelector(state => state.userPreferencesReducer.currencyOptions.value);
  const shoppingCartId = useSelector(state => state.shoppingCartReducer.shoppingCart?.id);
  const dispatch = useDispatch();

  const performChangeCurrency = async () => {
    return await changeCurrency({currencyCode: preferredCurrency, cartId: shoppingCartId});
  };

  useEffect(() => {
    if (shoppingCartId) {
      performChangeCurrency().then((resp) => {
        if (resp.data) {
          dispatch({
            type: SHOPPING_CART_CURRENCY_CHANGE,
            payload: resp.data,
          });
        }
      });
    }
  }, [shoppingCartId]);


  useEffect(async () => {
    const imageId = location.state?.image?.id;
    const res = await axios.get(`api/Image/${imageId}/SampleDescriptionCards`);
    setDescriptionCard(res.data.descriptionCard);
    setInverseDescriptionCard(res.data.inverseDescriptionCard);
    setIsAlreadySigned(res.data.isAlreadySigned);
    setIsSigned(res.data.isSigned);
    setSignatureAlignment(res.data.signatureAlignment);
    setSignatureSample(res.data.signatureSample);
  }, [location]);

  useEffect(() => {
    if(
        !location.state?.image?.aspRatioX ||
        !location.state?.image?.aspRatioY ||
        !location.state?.image?.id
      ){
          return;
    }
    let dto = {
      printercode: 'JONDO',
      AspectRatioX: location.state?.image?.aspRatioX,
      AspectRatioY: location.state?.image?.aspRatioY,
      imageId: location.state?.image?.id,
    };
    let url =
      `api/Printer/${dto.printercode}/newTemplates?` +
        'aspectRatioX=' +
        dto.AspectRatioX +
        '&aspectRatioY=' +
        dto.AspectRatioY +
        '&imageId=' +
        dto.imageId;

    axios
      .get(url, {
        data: dto,
      })
      .then((resp) => {
        const image = location.state?.image;
        const productList = image?.productList;
        const filteredProductList = [];

        // Here we filter through the product list to only include the
        // relevant product line/s in the array (i.e. paper and/or canvas).
        resp.data[0].medium.map(el1 => {
          productList.map(el2 => {
            if(!el1.id){
              return
            }
            const arr2 = el1.id.toLowerCase();
            const productListId = el2.productType.type.toLowerCase();
            if (arr2.match(productListId)) {
              filteredProductList.push(el1);
            }
          });
        });
        setFields({
          selectedCanvas: filteredProductList[0],
          selectedMaterial: null,
          allMaterials: resp.data[0].medium,
          selectedSize: null,
          allSizes: [],
          selectedBorder: null,
          allBorders: [],
          selectedFrame: null,
          allFrames: [],
        });

        setJondoConfigMaterials(resp.data[0].medium);
      });
  }, [setJondoConfigMaterials, location.state?.image?.aspRatioX, location.state?.image?.aspRatioY, location.state?.image?.id]);

  const isPageLoading =
    !jondoConfigMaterials || jondoConfigMaterials.length < 1 || isCanvasLoading;

  const onCanvasLoading = () => {
    setIsCanvasLoading(true);
  };
  const onCanvasFinishedLoading = () => {
    setTimeout(() => {
      setIsCanvasLoading(false);
    }, 200);
  };
  const giveProductPrices = (prices) => {
    setProductPrices(prices);
  };

  const authModalOnClose = () => {
    setAuthModalOpen(false);
  };
  const authModalOnLogin = () => {
    setLoginAfterCart(true);
    setContinueAsGuest(true);
    setAuthModalOpen(false);
  };
  const authModalOnJoin = () => {
    setRegisterAfterCart(true);
    setContinueAsGuest(true);
    setAuthModalOpen(false);
  };
  const authModalOnContinueAsGuest = () => {
    setContinueAsGuest(true);
    setAuthModalOpen(false);
  };

  return (
    <React.Fragment>
      <ReservationsConnection
        imageId={location.state?.image?.id}
        onReservationsUpdated={onReservationsUpdated}
      />
      <Desktop>
        <CustomiseDesktop
          setShotsGenerated={setShotsGenerated}
          shotsGenerated={shotsGenerated}
          artist={profile.artistProfileInfo}
          setImageLoading={setImageLoading}
          imageLoading={imageLoading}
          sideDrawer={sideDrawer}
          startCrop={startCrop}
          summary={summary}
          fields={fields}
          setFields={setFields}
          postInitialization={postInitialization}
          location={location}
          imageRenderChanged={imageRenderChanged}
          jondoConfigMaterials={jondoConfigMaterials}
          addToCart={addToCart}
          featureChanged={featureChanged}
          updateSummary={updateSummary}
          feature={feature}
          galleryImages={galleryImages}
          onCanvasLoading={onCanvasLoading}
          onCanvasFinishedLoading={onCanvasFinishedLoading}
          isPageLoading={isPageLoading}
          updateGalleryCard={onGalleryCardUpdate}
          giveProductPrices={giveProductPrices}
          expectedProductNumber={expectedProductNumber}
          reservations={reservations}
          onEditionSelected={onEditionSelected}
          descriptionCard={descriptionCard}
          inverseDescriptionCard={inverseDescriptionCard}
          isAlreadySigned={isAlreadySigned}
          isSigned={isSigned}
          signatureAlignment={signatureAlignment}
          signatureSample={signatureSample}
          authModalOpen={authModalOpen}
          authModalOnClose={authModalOnClose}
          authModalOnLogin={authModalOnLogin}
          authModalOnJoin={authModalOnJoin}
          authModalOnContinueAsGuest={authModalOnContinueAsGuest}
          modalImage={{
            ...location.state?.image,
            imageTitle: location.state?.image?.title,
            imageRender: imageThumbnail,
            type: (summary.selectedCanvas || {}).id,
            material: (summary.selectedMaterial || {}).id,
            size: (summary.selectedSize || {}).id,
            frame: (summary.selectedFrame || {}).id,
            border: (summary.selectedBorder || {}).id,
            wrap: summary.wrap,
            totalPrice: summary?.selectedSize?.price,
            productNumber: (reservations.find(
              ({statusCode}) =>
                statusCode === reservationStatusCode.selectedByUser,
              ) || {}).productNumber
          }}
          setClickAsGuest={setClickAsGuest}
        />
      </Desktop>
      <Tablet>
        <CustomiseMobile
          setShotsGenerated={setShotsGenerated}
          shotsGenerated={shotsGenerated}
          artist={profile.artistProfileInfo}
          sideDrawer={sideDrawer}
          setImageLoading={setImageLoading}
          imageLoading={imageLoading}
          startCrop={startCrop}
          summary={summary}
          location={location}
          fields={fields}
          setFields={setFields}
          updateGalleryCard={onGalleryCardUpdate}
          postInitialization={postInitialization}
          imageRenderChanged={imageRenderChanged}
          jondoConfigMaterials={jondoConfigMaterials}
          addToCart={addToCart}
          featureChanged={featureChanged}
          updateSummary={updateSummary}
          feature={feature}
          galleryImages={galleryImages}
          onCanvasLoading={onCanvasLoading}
          onCanvasFinishedLoading={onCanvasFinishedLoading}
          isPageLoading={isPageLoading}
          giveProductPrices={giveProductPrices}
          expectedProductNumber={expectedProductNumber}
          reservations={reservations}
          onEditionSelected={onEditionSelected}
          descriptionCard={descriptionCard}
          inverseDescriptionCard={inverseDescriptionCard}
          isAlreadySigned={isAlreadySigned}
          isSigned={isSigned}
          signatureAlignment={signatureAlignment}
          signatureSample={signatureSample}
          authModalOpen={authModalOpen}
          authModalOnClose={authModalOnClose}
          authModalOnLogin={authModalOnLogin}
          authModalOnJoin={authModalOnJoin}
          authModalOnContinueAsGuest={authModalOnContinueAsGuest}
          modalImage={{
            ...location.state?.image,
            imageTitle: location.state?.image?.title,
            imageRender: imageThumbnail,
            type: (summary.selectedCanvas || {}).id,
            material: (summary.selectedMaterial || {}).id,
            size: (summary.selectedSize || {}).id,
            frame: (summary.selectedFrame || {}).id,
            border: (summary.selectedBorder || {}).id,
            wrap: summary.wrap,
            totalPrice: summary?.selectedSize?.price,
            productNumber: (reservations.find(
              ({statusCode}) =>
                statusCode === reservationStatusCode.selectedByUser,
              ) || {}).productNumber
          }}
          setClickAsGuest={setClickAsGuest}
        />
      </Tablet>
      <Mobile>
        <CustomiseMobile
          setShotsGenerated={setShotsGenerated}
          shotsGenerated={shotsGenerated}
          artist={profile.artistProfileInfo}
          sideDrawer={sideDrawer}
          setImageLoading={setImageLoading}
          imageLoading={imageLoading}
          startCrop={startCrop}
          summary={summary}
          location={location}
          fields={fields}
          setFields={setFields}
          postInitialization={postInitialization}
          imageRenderChanged={imageRenderChanged}
          jondoConfigMaterials={jondoConfigMaterials}
          addToCart={addToCart}
          featureChanged={featureChanged}
          updateSummary={updateSummary}
          feature={feature}
          updateGalleryCard={onGalleryCardUpdate}
          galleryImages={galleryImages}
          onCanvasLoading={onCanvasLoading}
          onCanvasFinishedLoading={onCanvasFinishedLoading}
          isPageLoading={isPageLoading}
          giveProductPrices={giveProductPrices}
          expectedProductNumber={expectedProductNumber}
          reservations={reservations}
          onEditionSelected={onEditionSelected}
          descriptionCard={descriptionCard}
          inverseDescriptionCard={inverseDescriptionCard}
          isAlreadySigned={isAlreadySigned}
          isSigned={isSigned}
          signatureAlignment={signatureAlignment}
          signatureSample={signatureSample}
          authModalOpen={authModalOpen}
          authModalOnClose={authModalOnClose}
          authModalOnLogin={authModalOnLogin}
          authModalOnJoin={authModalOnJoin}
          authModalOnContinueAsGuest={authModalOnContinueAsGuest}
          modalImage={{
            ...location.state?.image,
            imageTitle: location.state?.image?.title,
            imageRender: imageThumbnail,
            type: (summary.selectedCanvas || {}).id,
            material: (summary.selectedMaterial || {}).id,
            size: (summary.selectedSize || {}).id,
            frame: (summary.selectedFrame || {}).id,
            border: (summary.selectedBorder || {}).id,
            wrap: summary.wrap,
            totalPrice: summary?.selectedSize?.price,
            productNumber: (reservations.find(
              ({statusCode}) =>
                statusCode === reservationStatusCode.selectedByUser,
              ) || {}).productNumber
          }}
          setClickAsGuest={setClickAsGuest}
        />
      </Mobile>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  nav: state.navReducer,
  account: state.accountReducer,
  shoppingCart: state.shoppingCartReducer,
  notification: state.notificationReducer,
  sideDrawer: state.sideDrawerReducer,
  isUserLoggedIn: isUserLoggedIn(state),
  searchAPIUrl: searchAPIUrl(state),
  profile: state.profileReducer,
  settings: state.settingsReducer
});

export const setArtistProfileInfoLoading = () => {
  return {type: ARTIST_PROFILE_INFO_LOADING};
};  

export default connect(mapStateToProps, {
  setAlert,
  showNotification,
  getProfile,
  getArtistProfileInfo,
  addProductToShoppingCart,
  updateNotificationItem,
  updateNotificationType,
  handleAuthModal,
  notifyQueueStatus,
  openAuthentication,
  openRegistration,
})(Customise);
