import React, {useEffect, useState} from "react";
import {useTheme} from "@material-ui/core/styles";
import PikchaDropdownBorderless from "../baseComponents/PikchaDropdown/PikchaDropdownBorderless";
import {useDispatch, useSelector} from "react-redux";
import {PREFERRED_CURRENCY_CHANGE, SET_FX_RATE, SHOPPING_CART_CURRENCY_CHANGE} from "../../actions/types";
import {setLocalPreferences} from "../../helpers/localUserPref";
import {options} from "../../config/currencyPickerOptions";
import {changeCurrency, getFxRate, setProfilePreferences} from "../../actions/changeCurrency";

import './CurrencyPicker.css'

const CurrencyPicker = ({customStyles}) => {
  const {palette, typography} = useTheme();

  // @ts-ignore
  const userPreferencesReducer = useSelector(state => state.userPreferencesReducer);
  const shoppingCart = useSelector((state: any) => state.shoppingCartReducer.shoppingCart);
  const accountReducer = useSelector((state: any) => state.accountReducer);
  const dispatch = useDispatch();

  const stripSymbolFromLabel = (option) => {
    let stripedLabel = option.label.split(' ').pop()
    return {...option, label: stripedLabel}
  }

  const [selectedCurrency, setSelectedCurrency] = useState(stripSymbolFromLabel(userPreferencesReducer.currencyOptions));

  const changeHandler = (selectedOption) => {
    if (selectedOption.value !== userPreferencesReducer.currencyOptions.value) {
      if (shoppingCart.id) {
        changeCurrency({currencyCode: selectedOption.value, cartId: shoppingCart.id})
          .then((responseData: any) => {
            if (responseData.data) {
              dispatch({
                type: SHOPPING_CART_CURRENCY_CHANGE,
                payload: responseData.data
              });
            }
          });
      }

      dispatch({
        type: PREFERRED_CURRENCY_CHANGE,
        payload: selectedOption
      });

      getFxRate(selectedOption.value).then((resp: { data: number }) => {
        if (resp.data) {
          setLocalPreferences({
            currencyCode: selectedOption.value,
            locale: selectedOption.locale,
            fxRate: resp.data.toString(),
            preferredUnit: userPreferencesReducer.preferredUnit.value
          });

          dispatch({
            type: SET_FX_RATE,
            payload: resp.data
          });
        }
      });

      if (accountReducer.user) {
        setProfilePreferences({
          currencyCode: selectedOption.value,
          measurement: userPreferencesReducer.preferredUnit.value
        });
      }
    }
  };

  useEffect(() => {
    setSelectedCurrency(stripSymbolFromLabel(userPreferencesReducer.currencyOptions));
  }, [userPreferencesReducer.currencyOptions.value])

  return (
    <div className={'currency-picker-container'}>
      <div className={'currency-picker-icon'} style={{fontWeight: typography.fontWeightBold}}>
        <div style={{
          width: '24px',
          maxWidth: '24px',
          textAlign: "center",
          color: (customStyles && customStyles.color) ? customStyles.color : palette.common.black
        }}>{selectedCurrency.symbol}</div>
      </div>
      <div className={'currency-picker-dropdown-container'}>
        {/*@ts-ignore*/}
        <PikchaDropdownBorderless
          label={''}
          value={selectedCurrency}
          options={options}
          onChange={changeHandler}
          style={customStyles}
        />
      </div>
    </div>
  );
};

export default CurrencyPicker;
