import React from 'react'
import ArrowLeft from '@material-ui/icons/ArrowBackIosOutlined'
import DownloadIcon from "../../../../components/extraIcons/DownloadIcon";
import { currencyFormat, IntlCurrencyFormatter } from "../../../../components/NumberFormatters/IntlCurrencyFormatter";
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TYPOGRAPHY_BODY_SIZE,
  TypographyBody
} from "@pikcha/typography";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-grid-system";
import withStyles from "@material-ui/core/styles/withStyles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";

export const StyledTableContainer = withStyles(() => ({
  root: {
    maxHeight: "60vh",
    marginBottom: "8em",
    border: 'none',
    boxShadow: 'none',
  },
}))(TableContainer);

export const StyledTableCell = withStyles(() => ({
  root: {
    minWidth: 225,
    fontFamily: "montserrat",
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#F2F4F8',
    height: '44px',
    padding: '0',
    marginTop: '1em'
  },
}))(TableCell);

const PaymentHistory = ({ 
  goBackFromPaymentHistory, 
  paymentHistoryData, 
  viewSalesOfPayment,
  downloadPaymentHistory
}) => {
  return (
    <div
      style={{
        margin: '0 0.5em'
      }}
    >
      <TypographyButton
        buttonStyle={{ padding: '16px 8x', marginBottom: '1.75em' }}
        onClick={(e) => goBackFromPaymentHistory()}
        component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
        icon={<ArrowLeft />}
      >
        Back
      </TypographyButton>
      <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'column' }}>
        <TypographySubtitle
          component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
          style={{ marginBottom: '1em' }}
        >
          Payment history
        </TypographySubtitle>
        <TypographyButton
          onClick={downloadPaymentHistory}
          buttonStyle={{
            color: '#000', marginBottom: '2.25em'
          }}
          textStyle={{ paddingLeft: "0.5em" }}
          component={TYPOGRAPHY_BUTTON_TYPE.Outline}
          icon={
            <DownloadIcon
              color="#000"
              iconSize={16}
            />
          }
        >
          Download
        </TypographyButton>
      </div>
      <div
        style={{
          display: 'flex',
          maxWidth: '100%',
          justifyContent: 'space-between',
          border: '1px solid #DBE0E4',
          borderRadius: '4px',
          padding: '1.5em',
          marginBottom: '1.5em'
        }}
      >
        <div>
          <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
            Payment at Next Payment Cycle
          </TypographyBody>
          <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
            Total sales to be paid
          </TypographyBody>
          <Link
            style={{
              fontSize: '12px',
              fontWeight: 700,
              color: "#4B01D1",
            }}
            to="/guides/artist/getting-paid"
          >
            When will I get paid?
          </Link>
        </div>
        <div>
          <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
            <IntlCurrencyFormatter targetCurrency="USD" />
            {paymentHistoryData?.AmountDue}
          </TypographyBody>
          <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
            <IntlCurrencyFormatter targetCurrency="USD" />
            {paymentHistoryData?.TotalToBePaid}
          </TypographyBody>
        </div>
      </div>
      {paymentHistoryData?.payoutBase?.payoutbase?.length === 0 && (
          <Container fluid>
            <Row className="text-center">
              <Col md={12} className="mt-4">
                <span className="fontSize16">
                  You don’t have any sales yet
                </span>
              </Col>
            </Row>
          </Container>
        )}
      <div>
        {paymentHistoryData?.payoutBase?.payoutbase?.map((item) => {
          return (
            <>
              <div style={{
                border: '1px solid #DBE0E4',
                borderRadius: '4px',
                padding: '1em',
                marginBottom:'1em'
              }}
              >
                <TypographySubtitle
                  style={{
                    marginBottom: '1em',
                  }}
                  component={TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Bold}
                >
                  {item.PaymentDate ? moment(item.PaymentDate, "YYYY-MM-DD").format(
                    "D-M-YYYY"
                  ) : "Not Available"}
                </TypographySubtitle>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}>
                  <div>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    >
                      Payment Method
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      style={{ marginBottom: '0.75em' }}
                    >
                      Amount (USD)
                    </TypographyBody >
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    >
                      Payout Currency
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      style={{ marginBottom: '0.75em' }}
                    >
                      FX Rate
                    </TypographyBody>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}>
                      Transfer Fee
                    </TypographyBody>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}>
                      &#36;payout
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      style={{ marginBottom: '0.75em' }}
                    >
                      Payment Status
                    </TypographyBody>
                  </div>
                  <div>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                      style={{ marginBottom: '0.75em' }}
                    >
                      {item.PayoutMethod}
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                      style={{ marginBottom: '0.75em' }}
                    >
                      <IntlCurrencyFormatter targetCurrency="USD" />
                      {item.SourceAmount}
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                      style={{ marginBottom: '0.75em' }}
                    >
                      {item.TargetCurrency ? (
                        item.TargetCurrency
                      ) : (
                        'Not Valid'
                      )
                      }
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                      style={{ marginBottom: '0.75em' }}
                    >
                      {item.FxRate}
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                      style={{ marginBottom: '0.75em' }}
                    >
                      {item.TransferFees}
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                      style={{ marginBottom: '0.75em' }}
                    >
                      <IntlCurrencyFormatter value={+(item.TargetAmount)} localConversion={true}
                        targetCurrency={item.TargetCurrency}
                        displayFormat={currencyFormat.code} />
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                      style={{ marginBottom: '0.75em' }}
                    >
                      {item.PayoutStatus}
                    </TypographyBody>
                  </div>
                </div>
                <div style={{display:'flex',justifyContent:'end',margin:'2em 0'}}>
                <TypographyButton
                  buttonStyle={{ height: '32px'}}
                  component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                  onClick={() => viewSalesOfPayment(item)}
                >
                  View Sales
                </TypographyButton>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  )
}

export default PaymentHistory