import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import { useTheme } from "@material-ui/core/styles";

import { PikchaButton } from "..";

export default ({
  children,
  //footerSecondaryAction,
  //footerPrimaryAction,
  FooterActionProps,
  //footerSecondaryText = "Cancel",
  //footerPrimaryText = "Save"
  dialogActionStyle = {},
  customStyles = {},
}) => {
  if (
    (FooterActionProps &&
      FooterActionProps.secondary &&
      !FooterActionProps.secondary.action) ||
    (FooterActionProps &&
      FooterActionProps.primary &&
      !FooterActionProps.primary.action)
  ) {
    throw new Error(
      "If a `secondary` or `primary` config is supplied, it" +
        " must include an `action` callback.",
    );
  }

  const { palette, spacing } = useTheme();

  if (!FooterActionProps) {
    return (
      <DialogActions
        className="full-width"
        style={{
          background: palette.grey.light,
          zIndex: 1,
          ...dialogActionStyle
        }}
      >
        <div
          style={{ zIndex: 1, ...customStyles }}
          className={`ModalFooter-container ${
            FooterActionProps && FooterActionProps.fullWidthButtons
              ? "full-width"
              : "half-width flex-end"
          }`}
        >
          {children}
        </div>
      </DialogActions>
    );
  }

  return (
    <DialogActions
      className="absolute-bottom-0 full-width"
      style={{
        background: palette.grey.light,
        zIndex: FooterActionProps.zIndex ? FooterActionProps.zIndex : 8,
        ...customStyles
      }}
    >
      <div
        className={`ModalFooter-container ${
          FooterActionProps && FooterActionProps.fullWidthButtons
            ? "full-width"
            : "half-width flex-end"
        }`}
      >
        {FooterActionProps && FooterActionProps.secondary && (
          <PikchaButton
            className={
              FooterActionProps.fullWidthButtons ? "full-width" : "half-width"
            }
            action={
              FooterActionProps.secondary &&
              FooterActionProps.secondary.buttonType
                ? FooterActionProps.secondary.buttonType
                : "secondary"
            }
            onClick={FooterActionProps.secondary.action}
            style={{
              marginRight: spacing(1),
            }}
          >
            {FooterActionProps.secondary && FooterActionProps.secondary.text
              ? FooterActionProps.secondary.text
              : "Cancel"}
          </PikchaButton>
        )}
        {FooterActionProps && FooterActionProps.primary && (
          <PikchaButton
            className={
              FooterActionProps.fullWidthButtons ? "full-width" : "half-width"
            }
            action={
              FooterActionProps && FooterActionProps.primary.buttonType
                ? FooterActionProps.primary.buttonType
                : "primary"
            }
            disabled={
              FooterActionProps.primary.disabled
                ? FooterActionProps.primary.disabled
                : null
            }
            onClick={FooterActionProps.primary.action}
            color={FooterActionProps.primary.color ? FooterActionProps.primary.color : undefined}
          >
            {FooterActionProps.primary && FooterActionProps.primary.text
              ? FooterActionProps.primary.text
              : "Save"}
          </PikchaButton>
        )}
      </div>
    </DialogActions>
  );
};
