import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "@material-ui/core/styles";

import {
  PikchaLoader,
  PikchaMasonryTitle,
} from "../../../components/baseComponents";
import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../../components/Responsive/isDesktopSD";
import isDesktop from "../../../components/Responsive/isDesktop";
import isTablet from "../../../components/Responsive/isTablet";
import isMobile from "../../../components/Responsive/isMobile";
import MasonryColumn from "../MasonryColumn";

import "./MasonryGallery.css";
import {
  GALLERY_ITEMINPAGE_DEFAULT,
  GALLERY_PAGE_DEFAULT
} from "../../../helpers/constants";

export default ({
  fromRelatedImages,
  images,
  isFollowing,
  onMasonryModalClosed,
  title,
  gallery,
  getPhotos,
  resetGallery,
  onClickGalleryImage,
  fromProfile,
  noContainerMargins,
  restrict = [],
  noBackground,
  noItemHeaders,
  imageType = "",

  onClose
} = {}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  const columns = isOnDesktop ? 3 : isOnTablet ? 2 : 1;
  const galleryWrapper = useRef(null);
  const [galleryWrapperWidth, setGalleryWrapperWidth] = useState(galleryWrapper.current ? window.getComputedStyle(galleryWrapper.current) : 119.2);
  const shouldShowFollowing = isFollowing || false;

  useEffect(() => {
    if (gallery.photos) {
      if (gallery.photos.length <= 0) {
        if (fromRelatedImages) {
          getPhotos(GALLERY_ITEMINPAGE_DEFAULT, GALLERY_PAGE_DEFAULT);
          return () => resetGallery();
        }
      }
    }
  }, []);

  const { palette, typography } = useTheme();

  const clickImage = (e) => {
    if (onClickGalleryImage) {
      onClickGalleryImage(e);
    }
  };

  const separatePhotos = () => {
    let photos = [];

    if (images && images.photos) {
      photos = images.photos;
    } else if (restrict || fromProfile) {
      photos = gallery.photos;
      //photos = galleryPhotos;
    }

    photos = photos.reduce((accumulator, item) => {
      const { id } = item;
      if (restrict.some((restrictedItem) => restrictedItem === id)) {
        return accumulator;
      }
      return [...accumulator, ...[item]];
    }, []);

    const res = [...Array(columns).keys()].map((c) =>
      photos.filter((_, i) => i % columns === c)
    );

    const columnsToMap = [...Array(columns)];
    return columnsToMap.map((column, index) => {
      const isFirstColumn = index === 0;
      const isLastColumn = index + 1 === columnsToMap.length;
      return (
        <MasonryColumn
          view={'MasonryGallery'}
          onClose={onClose}
          imageClicked={clickImage}
          key={index + 1}
          columnNo={index + 1}
          noItemHeaders={noItemHeaders}
          onMasonryModalClosed={onMasonryModalClosed}
          photos={res[index]}
          galleryWrapperWidth={galleryWrapperWidth}
          style={{
            marginLeft: !isFirstColumn
              ? isOnDesktopHD
                ? "1em"
                : isOnDesktopSD
                  ? "1.1em"
                  : isOnTablet
                    ? "1.1em"
                    : isOnMobile
                      ? 0
                      : "0.5em"
              : 0,
            marginRight: !isLastColumn
              ? isOnDesktopHD
                ? "1em"
                : isOnDesktopSD
                  ? "1.1em"
                  : isOnTablet
                    ? "1.1em"
                    : isOnMobile
                      ? 0
                      : "0.5em"
              : 0,
          }}
        />
      );
    });
  };

  const handleResize = () => {
    const { width } = galleryWrapper.current ? window.getComputedStyle(galleryWrapper.current) : {};
    const widthValue = isOnDesktopHD ? parseFloat(width) - 128 : isOnDesktopSD ? parseFloat(width) - 64 : isOnTablet ? parseFloat(width) - 32 : parseFloat(width) - 16;
    setGalleryWrapperWidth(widthValue);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize()
  }, [galleryWrapper]);

  return (
    <div>
      {title !== "undefined" && (
        <PikchaMasonryTitle>{title}</PikchaMasonryTitle>
      )}
      <InfiniteScroll
        scrollableTarget={
          fromRelatedImages ? "Modal-scrollContainer" : undefined
        }
        dataLength={gallery.photos.length}
        style={{
          overflow: "hidden",
        }}
        next={() => {
          if (gallery.start > 1) {
            getPhotos(gallery.count, gallery.start, imageType)
          }
        }}
        hasMore={gallery.hasMore}
        // hasMore={false}
        loader={<PikchaLoader
          marginTop={typography.xLarge}
          marginBottom={isOnMobile ? "500px" : "800px"}
        />}
        endMessage={
          <p
            style={{
              textAlign: "center",
              marginTop: "5em",
              fontSize: typography.small,
              fontWeight: typography.fontWeightSemiMedium,
              color: palette.text.secondary,
            }}
          >
            You've seen all the images
          </p>
        }
      >
        <div
          className={`${!fromProfile ? "masonry" : "Profile-masonry"} ${isFollowing ? "pb-2-em" : ""}`}
          ref={galleryWrapper}
          style={{
            marginTop: "2em",
            paddingTop: shouldShowFollowing ? "1.5em" : null,
            background: noBackground ? null : palette.background.default,
          }}
        >
          {separatePhotos()}
        </div>
      </InfiniteScroll>
    </div>
  );
};
