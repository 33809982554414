import React from 'react';
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
    const { palette } = useTheme();
    return (
        <svg 
            width={size}
            height={size}
            viewBox="0 0 25 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M19.5059 4.5V0H15.0059L12.7949 4.1055C12.6644 4.3485 12.4109 4.5 12.1349 4.5H4.50586V10.5H8.09686C8.66386 10.5 9.02686 11.106 8.75686 11.6055L4.50586 19.5V24H9.00586L11.2169 19.8945C11.3474 19.6515 11.6009 19.5 11.8769 19.5H19.5059V13.5H15.9149C15.3479 13.5 14.9849 12.894 15.2549 12.3945L19.5059 4.5Z" 
                fill={color || palette.grey.medium}
            />
        </svg>
    )
}
