import React from 'react'
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographySelector,
  colors
} from "@pikcha/typography";

export default ({
  edition,
  handleImageDetails
}) => {
  return (
    <>
      <div
        className="mb-1-em"
        style={{
          width: "100%",
          border: `1px solid ${colors.Neutral.grey30}`,
          borderRadius: "4px",
          padding: "1em"
        }}
      >
        <TypographySelector
          selectorType="radio"
          labelText={
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
            >
              Number of Editions
            </TypographyBody>
          }
          options={[
            {
              label: "5",
              value: "5"
            },
            {
              label: "10",
              value: "10"
            },
            {
              label: "20",
              value: "20"
            },
            {
              label: "50",
              value: "50"
            },
            {
              label: "100",
              value: "100"
            }
          ]}
          value={edition.toString()}
          handleChange={(e)=>handleImageDetails("totalEditions", e.target.value)}
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1em",
            marginLeft: "0.5em",
            maxWidth: 200,
            flexWrap: "wrap"
          }}
          optionStyle={{
            paddingRight: "2em",
            paddingBottom: "1em",
            maxWidth: 50
          }}
        />
      </div>
    </>
  )
}
