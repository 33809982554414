import React, {Fragment, useState, useEffect} from "react";
import {makeStyles, useTheme, withStyles} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(({typography}) => ({
  contained: {
    fontSize: typography.small,
    fontWeight: typography.fontWeightSemiMedium,
    fontFamily: typography.fontFamilyMontserrat,
    marginLeft: 0,
    userSelect: "none",
  },
}));


export const PikchaTextField = withStyles(({palette, shape, typography}) => ({
  textFieldInput: (props) => ({
    height: props.height ? props.height : null,
    background:
      props && props.disabled
        ? palette.action.disabledBackground
        : palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: typography.small,
    fontWeight: typography.fontWeightSemiMedium,
    fontFamily: typography.fontFamilyMontserrat,
    "& > fieldset": {
      borderWidth: 2
    },
  }),
  textField: (props) => ({
    borderRadius: shape.borderRadius,
    marginBottom:
      props && (props.charCount || props.footerText || props.reduceBottomMargin)
        ? "0.25rem"
        : "1.75rem",
    "& > div > textarea": {
      resize: "vertical"
    }
  }),
  textFieldLabel: (props) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    fontSize: typography.smallest,
    fontFamily: typography.fontFamilyMontserrat,
    lineHeight: "1rem",
    userSelect: "none",
    color:
      props && props.disabled
        ? palette.action.disabled
        : palette.grey.mediumDark,
    marginBottom: ".25rem",
    fontWeight: typography.fontWeightMedium,
    transform: "translateY(-1.1rem) !important",
  }),
}))(
({
  classes: {textFieldLabel, textField, textFieldInput},
  style = {},
  isFullWidth,
  charCount,
  label,
  subLabel,
  required,
  reduceBottomMargin,
  disabled,
  footerText,
  helperText,
  InputProps,
  startAdornment,
  variant="outlined",
  onFinishEdit,
  endIndicator,
  ...rest
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [previousIsFocused, setPreviousIsFocused] = useState(false);
    const {palette, typography} = useTheme();
    const classes = useStyles();

    InputProps = {
      ...(InputProps || {}),
      ...{
        notched: false,
      },
      ...(startAdornment || {})
    };
    InputProps.className = `${InputProps.className || ""} ${textFieldInput}`;

    useEffect(() => {
      if (!isFocused && previousIsFocused){
        onFinishEdit && onFinishEdit();
        setPreviousIsFocused(isFocused);
      }
    }, [onFinishEdit, isFocused, previousIsFocused])

    return (
      <div
        className={isFullWidth ? "full-width" : ""}
        style={{
          position: "relative",
          ...style,
        }}
        onFocusCapture={() => {
          setPreviousIsFocused(isFocused);
          setIsFocused(true);
        }}
        onBlurCapture={() => {
          setPreviousIsFocused(isFocused);
          setIsFocused(false);
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TextField
            style={{
              ...style,
            }}
            fullWidth={isFullWidth === undefined || isFullWidth === true}
            disabled={disabled}
            variant={variant}
            type="text"
            rows="2"
            className={textField}
            helperText={helperText}
            FormHelperTextProps={{
              className: classes.contained,
            }}
            label={
              <Fragment>
                {label}
                {required && (
                  <span
                    style={{
                      color: palette.error.main,
                      fontWeight: typography.fontWeightMedium,
                      fontFamily: typography.fontFamilyMontserrat,
                    }}
                  >
                  *
                </span>
                )}
                {subLabel && (
                  <Fragment>
                    &nbsp;
                    <span
                      style={{
                        fontFamily: typography.fontFamilyMontserrat,
                        fontWeight: typography.fontWeightRegular,
                      }}
                    >
                    ({subLabel})
                  </span>
                  </Fragment>
                )}
              </Fragment>
            }
            InputLabelProps={{
              className: textFieldLabel,
              shrink: true,
              disableAnimation: true,
            }}
            size="small"
            InputProps={{
              ...InputProps,
              endAdornment: endIndicator ? endIndicator : undefined}}
            {...rest}
          />
        </div>
        {(charCount !== undefined || footerText !== undefined) && (
          <div
            style={{
              color: charCount < 1
                ? palette.error.main
                : isFocused
                  ? palette.secondary.main
                  : palette.grey.darkest,
              fontSize: typography.tiny,
              fontWeight: typography.fontWeightMedium,
              fontFamily: typography.fontFamilyMontserrat,
              position: "absolute",
              userSelect: "none",
              cursor: "default",
              bottom: charCount !== 0 ? undefined : 8,
              right: 2,
            }}
          >
            {charCount || charCount === 0 ? charCount : footerText}
          </div>
        )}
      </div>
    );
  }
);
