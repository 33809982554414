import React, { useState, } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Img from "react-image";
import PublicRoundedIcon from "@material-ui/icons/PublicRounded";
import { useHistory } from "react-router-dom";
import { PROFILE_LEFT_COLUMN_WIDTH } from "../../helpers/profile/constants";
import { getValueIfKeyExists } from "../../helpers/utils";

import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TYPOGRAPHY_BUTTON_TYPE,
  TYPOGRAPHY_BUTTON_TEXT,
  TypographyButton,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE
} from "@pikcha/typography";
import "../../containers/profile/ProfileUserDetails.css";

const Profile = ({
  account,
  artist,
  profile,
  isOwnProfile
}) => {
  const history = useHistory();
  const [profileImageLoaded, setProfileImageLoaded] = useState(false);
  const { shape, palette, typography } = useTheme();

  const onProfileImageLoaded = () => {
    setProfileImageLoaded(true);
  };
  const {
    bio,
    nationality,
    userStatus,
  } = profile || {};
  const { user } = account;

  const isProfileDeleted = userStatus === "Inactive";
  const isUserAnArtist =
    getValueIfKeyExists(account, "user.roles") &&
    account.user.roles.includes("Artist");
  const isBecomeAnArtist = !isUserAnArtist && isOwnProfile;

  return (
    <>
      <div
        className="ProfileUserDetails-wrapper"
        style={{
          borderRadius: shape.borderRadius,
          marginBottom: "3em",
          marginTop: "3em",
        }}
      >
        <Box
          py={3}
          className="mod-profile"
          style={{
            borderRadius: shape.borderRadius,
            backgroundColor: "transparent"
          }}
        >
          {isProfileDeleted && !isBecomeAnArtist
            && (
              <div
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundImage: "cover !important",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  background: palette.grey.medium,
                  backgroundSize: "cover",
                  borderRadius: shape.borderRadius,
                  backgroundPosition: "center center",
                }}
              >
                <div
                  style={{
                    marginLeft: "1.5em",
                    fontSize: typography.small,
                    fontWeight: typography.fontWeightSemiMedium,
                    color: palette.text.disabled,
                  }}
                >
                  This account is inactive.
                </div>
              </div>
            )}
          <>
            <div
              style={{
                display: "flex",
                margin: '1em 1.5em',
                justifyContent: "center"
              }}
            >
              <div
                className="user-avatar"
                style={{
                  display: "flex",
                  width: '100%'
                }}
              >
                <div
                  className="Onboarding-1"
                  style={{
                    height: PROFILE_LEFT_COLUMN_WIDTH,
                    width: PROFILE_LEFT_COLUMN_WIDTH,
                  }}
                >
                  {!profileImageLoaded && (
                    <div
                      style={{
                        borderRadius: "100%",
                        width: PROFILE_LEFT_COLUMN_WIDTH,
                        height: PROFILE_LEFT_COLUMN_WIDTH,
                        backgroundColor: palette.grey.light,
                      }}
                    />
                  )}
                  <Img
                    style={{
                      display: profileImageLoaded ? "block" : "none",
                      border: 0,
                      height: PROFILE_LEFT_COLUMN_WIDTH,
                      width: PROFILE_LEFT_COLUMN_WIDTH
                    }}
                    src={artist?.avatarMedium}
                    alt="User Profile Picture"
                    onLoad={onProfileImageLoaded}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "1.5em",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "0 10",
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div>
                      <TypographySubtitle
                        component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
                      >
                        {artist?.nickName}
                      </TypographySubtitle>
                      <TypographyBody
                        style={{
                          color: '#4D5358',
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '0.5em'
                        }}
                        size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
                      >
                        <PublicRoundedIcon
                          color="inherit"
                          style={{
                            height: 16,
                            width: 16,
                            color: '#4D5358'
                          }}
                        />
                        &nbsp;{artist?.nationality}
                        <span className="text-center">
                          {isOwnProfile ?
                            (account.updatedNationality ?
                              account.updatedNationality
                              :
                              nationality) : nationality
                          }
                        </span>
                      </TypographyBody>
                      <TypographyBody
                        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                      >
                        {isOwnProfile && (
                          <>
                            {user.roles.includes("Artist") && (
                              account.updatedBio ? account.updatedBio : bio
                            )}
                          </>
                        )}
                        {!isOwnProfile && (
                          artist?.bio
                        )}
                      </TypographyBody>
                    </div>
                    <div>
                      <TypographyButton
                        buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
                        component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                        onClick={() => {
                          history.push(`/${artist?.userHandle}`)
                        }}
                        buttonStyle={{
                          width: '100%',
                          minWidth: '140px'
                        }}
                        textStyle={{
                          fontWeight: 600
                        }}
                      >
                        View Profile
                      </TypographyButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Box>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {

})(Profile);
