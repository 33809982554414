import {
  HANDLE_CROPPER_STATE, 
  HANDLE_CROPPER_RESET, 
  GET_CROPPER_SRC, 
  GET_ORG_SRC, 
  GET_ASPECTRATIO, 
  GET_SELECTED_RATIO, 
  GET_ORG_IMG_DATA, 
  HANDLE_UPLOAD,
  GET_RELEASED_FORMS
} from "./types";

export const handleCropperState = (data) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_CROPPER_STATE,
      payload: data,
    })
  }
}

export const getCropperSrc = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_CROPPER_SRC,
      payload: data,
    })
  }
}

export const getOriginSrc = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_ORG_SRC,
      payload: data,
    })
  }
}

export const getSelectedRatio = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_SELECTED_RATIO,
      payload: payload,
    })
  }
}

export const getReleasefForms = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_RELEASED_FORMS,
      payload: payload,
    })
  }
}

export const getAspectRatio = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_ASPECTRATIO,
      payload: data,
    })
  }
}

export const handleCropperReset = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_CROPPER_RESET,
      payload: state,
    })
  }
}

export const getOriginImgData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_ORG_IMG_DATA,
      payload: payload,
    })
  }
}

export const handleUploadStep = (payload) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_UPLOAD,
      payload: payload
    })
  }
}