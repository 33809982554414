import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import isDesktop from "../../components/Responsive/isDesktop";
import isMobile from "../../components/Responsive/isMobile";
import { colors } from "@pikcha/typography";
import Carousel, { Modal, ModalGateway } from "react-images";

const FeaturePhoto = ({
  style,
  photo,
  index,
  photoSize,
  onImageSelected,
}) => {
  const { palette } = useTheme();
  const [hoverState, setHoverState] = useState({
    item: "",
    value: false
  });

  const handleMouseOver = (item) => {
    setHoverState({
      item: item,
      value: true
    });
  };
  const handleMouseLeave = (item) => {
    setHoverState({
      item: item,
      value: false
    });
  };

  const onClick = () => {
    onImageSelected(index);
  };

  return (
    <>
      <div
        style={{
          cursor: photo.src && "pointer",
          position: "relative",
          borderRadius: 6,
          width: "23%",
          paddingBottom: "23%",
          backgroundColor: colors.Neutral.grey20,
          backgroundImage: photo.src && `url(${photo.src})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: 5,
          ...(style || {}),
        }}
        onMouseEnter={() => {handleMouseOver(photo.src)}}
        onMouseLeave={() => {handleMouseLeave(photo.src)}}
        onClick={() => {
          if(photo.src){
            onClick()
          }
        }}
      >
        {/*
        {hoverState.value && photo.src === hoverState.item && (
          <div
            style={{
              position: "absolute",
              width: photoSize,
              height: photoSize,
              zIndex: 10,
              backgroundColor: palette.text.disabled,
              borderRadius: 6,
            }}
          />
        )}
        */}
      </div>
    </>
  );
};

export default ({ galleryImages, photoLimit, photoSize, style, selectedMaterial }) => {
  let photos = [1,2,3,4];
  photos = galleryImages.filter((img) => {
    return img.material === selectedMaterial
  }).reduce((accumulator, item) => {
    if (accumulator.length < photoLimit) {
      return [...accumulator, ...[item]];
    }
    return accumulator;
  }, []);
 
  if(photos.length === 0) {
    photos = [1,2,3,4];
  }

  const lastIndex = photos.length - 1;
  const remainingPhotosLength = galleryImages.length - photos.length;

  const isOnDesktop = isDesktop();
  const isOnMobile = isMobile();

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-between",
        ...(style || {}),
      }}
    >
      {photos.map((photo, index) => (
        <FeaturePhoto
          key={index}
          index={index}
          photo={photo}
          isLastPhoto={index === lastIndex}
          remainingPhotosLength={remainingPhotosLength}
          photoSize={photoSize}
          onImageSelected={openLightbox}
          style={{
            marginBottom: "0.25em",
          }}
        />
      ))}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
              styles={{
                navigationPrev: (base, props) => ({
                  ...base,
                  ...(isOnMobile
                    ? {
                        top: "30%",
                      }
                    : {}),
                  borderRadius: "25%",
                  "& *:focus": {
                    outline: "none",
                  },
                }),
                navigationNext: (base, props) => ({
                  ...base,
                  ...(isOnMobile
                    ? {
                        top: "30%",
                      }
                    : {}),
                  borderRadius: "25%",
                  "& *:focus": {
                    outline: "none",
                  },
                }),
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};
