export default class WebWorker {
  constructor(worker, minutes, seconds) {
    const code = worker.toString();
    const blob = new Blob(["(" + code + ")()"]);
    let url = URL.createObjectURL(blob);
    //url += "#minutes=" + minutes + "&seconds=" + seconds;
    let work = new Worker(url);
    work.postMessage({ args: { minutes: minutes, seconds: seconds } });
    return work;
  }
}
