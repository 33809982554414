import React, {useState} from 'react';
import { isMobile } from "../../../env_utils/device_screen";
import {
  TypographySubtitle, 
  TYPOGRAPHY_SUBTITLE_COMPONENT, 
  TYPOGRAPHY_BODY_SIZE,
  TypographyBody, 
  TypographyButton,
  colors
} from "@pikcha/typography";
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import binoculars from "../../../assets/images/binoculars.png"
import brush from "../../../assets/images/outline_brush_black_24dp.png"
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  img: {
    width: "50%",
    height: "50%"
  },
}));

const Step3 = ({
  handleCurrentPage,
  handleClickClose,
  setAlert
}) => {
  const [role, setRole] = useState("");
  const handleClick = (value) => {
    setRole(value)
  }
  const history = useHistory();
  const classes = useStyles();
  return (
    <div
      className="full-width flex flex-column flex-center space-between"
      style={{
        paddingLeft: isMobile ? "0" : "2em",
        paddingRight: isMobile ? "0" : "2em",
        alignItems: "center",
        paddingTop: "5em",
        paddingBottom: "1em",
        maxHeight: "100%"
      }}
    >
      <div>
        <TypographySubtitle
          component={isMobile ? TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H3_Bold : TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
          style={{
            marginBottom: "1em",
            textAlign: "center"
          }}
        >
          Select user type
        </TypographySubtitle>
        <TypographyBody
          style={{
            textAlign: "center"
          }}
          size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
        >
          What do you want to do on Pikcha? You can always 
          change this in your account settings. 
        </TypographyBody>
      </div>
      <div 
        className="flex full-width"
        style={{
          flexDirection: "column"
        }}
      >
        <div 
          className="full-width flex"
          style={{
            padding: "1em",
            backgroundColor: role === "Collector" ? colors.Neutral.ink90 : colors.Neutral.white00,
            marginBottom: "0.25em",
            borderRadius: 4,
            border: "1px solid rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            minHeight: isMobile ? null : 105
          }}
          onClick={()=>{
            handleClick("Collector");
          }}
        >
          <Avatar 
            alt="Remy Sharp" 
            src={binoculars}
            style={{
              marginRight: "1em",
              width: 56,
              height: 56,
              borderRadius: 28,
              border: "1px solid #E0E0E0",
              backgroundColor: colors.Neutral.white00
            }} 
            classes={{
              img: classes.img
            }}
          />
          <div 
            className="flex"
            style={{
              justifyContent: "start",
              flexDirection: "column"
            }}
          >
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              color={role === "Collector" ? "neutral/white00" : "neutral/ink90"}
              style={{
                lineHeight: null
              }}
            >
              Collector
            </TypographyBody>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              color={role === "Collector" ? "neutral/white00" : "neutral/ink90"}
            >
              You want to browse and purchase from the Pikcha collection.
            </TypographyBody>
          </div>
        </div>
        <div
          className="full-width flex"
          style={{
            padding: "1em",
            backgroundColor: role === "Artist" ? colors.Neutral.ink90 : colors.Neutral.white00,
            borderRadius: 4,
            border: "1px solid rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            alignItems: "center",
            minHeight: isMobile ? null : 105
          }}
          onClick={()=>{
            handleClick("Artist");
          }}
        >
          <Avatar 
            alt="Remy Sharp" 
            src={brush}
            style={{
              marginRight: "1em",
              width: 56,
              height: 56,
              borderRadius: 28,
              border: "1px solid #E0E0E0",
              backgroundColor: colors.Neutral.white00
            }} 
            classes={{
              img: classes.img
            }}
          />
          <div 
            className="flex"
            style={{
              justifyContent: "start",
              flexDirection: "column"
            }}
          >
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              color={role === "Artist" ? "neutral/white00" : "neutral/ink90"}
              style={{
                lineHeight: null
              }}
            >
              Artist
            </TypographyBody>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              color={role === "Artist" ? "neutral/white00" : "neutral/ink90"}
            >
              You want to upload and sell artwork on Pikcha.
            </TypographyBody>
          </div>
        </div>
      </div>
      <TypographyButton
        buttonStyle={{
          marginBottom: '1em',
          width: '100%',
        }}
        onClick={() => {
          if(!role){
            setAlert("Please select your role", "error");
            return
          }
          if(role === "Collector"){
            handleCurrentPage(4)
          } else {
            history.push("/sell-your-art");
            handleClickClose();
          }
        }}
      >
        Next
      </TypographyButton>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
})(Step3);