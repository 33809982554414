import React, {useState, useEffect, useRef, useCallback} from 'react';
import Cropper from "react-cropper";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import CropPortraitIcon from "@material-ui/icons/CropPortrait";
import CropLandscapeIcon from "@material-ui/icons/CropLandscape";
import CropSquareIcon from "@material-ui/icons/CropSquare";
import {ICON_SIZE_DEFAULT,} from "../../../helpers/constants";
import {useTheme} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import SwitchCameraRoundedIcon from "@material-ui/icons/SwitchCameraRounded";
import isTablet from "../../../components/Responsive/isTablet";
import {ASPECT_RATIOS} from "../../../helpers/imageManipulation/imageManipulationConstants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";
import {useStyles} from "./Step3.styles";
import {mapAspectRatioToRadioButtonKey} from "../../../helpers/imageManipulation/imageManipulationHelpers";
import "./cropper.css";
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import {
  TypographyBody,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TYPOGRAPHY_BUTTON_TEXT,
  TYPOGRAPHY_BODY_SIZE,
  colors
} from "@pikcha/typography"
import {
  PikchaLabel,
  PikchaButton
} from '../../../components/baseComponents';
import { setAlert } from '../../../actions/alert';
import { getReleasefForms } from "../../../actions/cropper";

const UploadFlowModalStep3 = ({
  mpx,
  handleRatioChange,
  onChangeImage,
  step1SelectedRatio,
  handleChangeSelectedRatio,
  updateAspectRatio,
  parentAspectRatio,
  setAlert,
  croppedCoordinatesHandler,
  cropperRef,
  uploadedImage,
  handleClickClose,
  handlePublish,
  getReleasefForms,
  setImageInfoPlaceholder,
  releasedForms = []
}) => {
  const classes = useStyles();
  const isOnTablet = isTablet();
  const {palette} = useTheme();
  const [aspectRatio, setAspectRatio] = useState(step1SelectedRatio);
  const [selectedRatio, setSelectedRatio] = useState(ASPECT_RATIOS[mapAspectRatioToRadioButtonKey(step1SelectedRatio)]);
  const [initialCropper, setInitialCropper] = useState(false);
  const [imageChanges, setImageChanges] = useState(false);
  const [uploadedForms, setUploadedForms] = useState(releasedForms);
  const hiddenInputRef = useRef(null);

  const removeReleaseForm = (form) => {
    const formList = uploadedForms.filter((item)=>{
      return item.name !== form.name
    })
    setUploadedForms(formList);
    getReleasefForms(formList);
  }

  const fileUpload = async (e) => {
    const files = e.target.files;
    // Convert `files`, which is an Array-like object, to an actual array,
    // so we can call non-mutative higher-order functions on it (i.e. map,
    // filter, forEach).
    const _files = Array.from(files);

    let blockProgress = false;

    _files.forEach(({ size, type }) => {
      const fileSizeInMb = size / 1000000;
      const isValidFileType = ["pdf", "doc",
        "docx"].filter(x => type.includes(x)).length !== 0;

      if (fileSizeInMb > 10) {
        setAlert("Please ensure that your file is less than 10 MB", "error");
        blockProgress = true;
      }
      if (!isValidFileType) {
        setAlert("Please ensure that a supported file type is used", "error");
        blockProgress = true;
      }
    });
    if (blockProgress) {
      return;
    }

    if (uploadedForms.length > 10) {
      setAlert("A maximum of 10 files can be uploaded", "error");
      return;
    }
    
    const insertedFiles = [];
    let count = 0;

    _files.map((file)=>{
      uploadedForms.map((item)=>{
        if(item.name === file.name){
          return
        }
        count ++
      })
      if(count === uploadedForms.length){
        insertedFiles.push(file)
      }
      count = 0;
    })

    if (files.length > 0) {
      // setIsFileUploaded(true);
      const FormsList = [...uploadedForms, ...insertedFiles]; 
      setUploadedForms(FormsList);
      getReleasefForms(FormsList)
    }
  };

  const showReleasedFormList = useCallback(
    () => (
      <>
        {uploadedForms.map((form)=>(
          <div className="flex space-between">
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{maxWidth: "85%"}}
            >
              {form.name}
            </TypographyBody>
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{
                cursor: "pointer"
              }}
              onClick={() => {
                removeReleaseForm(form)
              }}
            >
              x
            </TypographyBody>
          </div>
        ))}
      </>
    ),[uploadedForms])

  const onChangeForm = () => {
    hiddenInputRef.current.click();
  };

  const handleCropEnd = () => {
    if (cropperRef.current) {
      setImageInfoPlaceholder(cropperRef.current.cropper.getData(true));
    }
  }

  const handleResize = () => {
    setAlert("Please do not change the window size while uploading", "error");
    handleClickClose();
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if((((cropperRef || {}).current||{}).cropper || {}).canvas && uploadedImage){
      setInitialCropper(true);
      setImageChanges(!imageChanges);
    } else {
      setInitialCropper(false);
    }
  }, [uploadedImage, (((cropperRef || {}).current||{}).cropper || {}).canvas, aspectRatio]);

  useEffect(() => {
    if(initialCropper){
      setImageInfoPlaceholder(cropperRef.current.cropper.getData(true));
    }
  }, [initialCropper, imageChanges])

  useEffect(() => {
    const detectedAspectRatio =
      ASPECT_RATIOS[mapAspectRatioToRadioButtonKey(parentAspectRatio)];
    setAspectRatio(detectedAspectRatio.id);
    setSelectedRatio(detectedAspectRatio);
  }, [step1SelectedRatio, aspectRatio, parentAspectRatio]);

  useEffect(() => {
    handleRatioChange(parentAspectRatio);
  }, [aspectRatio])

  const addAspectRatio = ({target: {value}}) => {
    const id = parseInt(value);
    let selRatio = null;
    setAspectRatio(id);
    handleChangeSelectedRatio(id);
    ASPECT_RATIOS.forEach((ratio) => {
      if (ratio.id === id) {
        selRatio = ratio;

        setSelectedRatio(ratio);
      }
    });
    if (selRatio && selRatio.value) {
      updateAspectRatio(selRatio.value);
    }
  };
  const getAspectRatios = (orientation) => {
    let ratios = [];
    if (orientation === "landscape") {
      ASPECT_RATIOS.forEach((item, index) => {
        if (item.orientation === "landscape") {
          ratios.push(
            <FormControlLabel
              key={index}
              value={item.id}
              control={
                <Radio
                  className={classes.root}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)}/>
                  }
                  icon={<span className={classes.icon}/>}
                />
              }
              label={item.label}
            />
          );
        }
      });
    } else if (orientation === "portrait") {
      ASPECT_RATIOS.forEach((item, index) => {
        if (item.orientation === "portrait") {
          ratios.push(
            <FormControlLabel
              key={index}
              value={item.id}
              control={
                <Radio
                  className={classes.root}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)}/>
                  }
                  icon={<span className={classes.icon}/>}
                />
              }
              label={item.label}
            />
          );
        }
      });
    } else {
      ASPECT_RATIOS.forEach((item, index) => {
        if (item.orientation === "square") {
          ratios.push(
            <FormControlLabel
              key={index}
              value={item.id}
              control={
                <Radio
                  className={classes.root}
                  disableRipple
                  color="default"
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)}/>
                  }
                  icon={<span className={classes.icon}/>}
                />
              }
              label={item.label}
            />
          );
        }
      });
    }

    return ratios;
  };
  const logCroppedData = ({detail}) => {
    const topStart = detail.y; //cropper start point right
    const leftStart = detail.x; //cropper start point left
    const width = detail.width; //cropper width
    const height = detail.height; //cropper Height
    croppedCoordinatesHandler(topStart, leftStart, width, height);
  };
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        backgroundColor: "rgb(251, 251, 251)",
        padding: "0.5em"
      }}
    >
      <Cropper
        responsive
        restore
        autoCrop
        autoCropArea={0.9}
        background={false}
        viewMode={1}
        ref={cropperRef}
        style={{
          cursor: "pointer",
          maxHeight: "100%",
          height: "100%",
          width: "100%",
          borderRadius: 0,
          position: "relative",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          flex: "1 1 0",
        }}
        src={uploadedImage}
        aspectRatio={selectedRatio.cropperVal}
        crop={logCroppedData}
        cropend={handleCropEnd}
        zoomable={false}
      />
      <div
        style={{
          padding: "64 15 15",
          display: "flex",
          flexDirection: "column",
          width: 300,
          backgroundColor: palette.background.paper
        }}
      >
        <PikchaButton
          className="half-width mr-half-em"
          onClick={onChangeImage}
          action="octonary"
          style={{
            marginTop: "16px",
            height: "36px",
            width: "100%",
          }}
        >
          <SwitchCameraRoundedIcon
            fontSize="small"
            style={{
              marginRight: !isOnTablet ? 20 : 4
            }}
          /> 
            {!isOnTablet ? "Change Image" : "Image"}
        </PikchaButton>
        <div
          style={{
            borderRadius: 8,
            padding: 5,
            backgroundColor: "white",
            border: `1px solid ${colors.Neutral.grey30}`,
            marginTop: "0.5em"
          }}
        >
          <PikchaLabel style={{color: palette.text.primary, marginBottom: "1em"}}>
            Cropped Size
            <span
              style={{
                marginLeft: "auto",
                color: Math.floor(mpx)>= 15 ? "rgb(0, 230, 118)" : "red"
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{Math.floor(mpx)}mp
            </span>
          </PikchaLabel>
          <Grid
            container
            style={{
              marginTop: ".125em",
            }}
          >
            <div
              style={{
                display: "block",
              }}
            >
              <div 
                className="flex"
                style={{
                  width: "100%",
                  borderBottomColor: `${colors.Neutral.grey30}`,
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                  paddingBottom: "1em"
                }}
              >
                <CropPortraitIcon
                  style={{
                    ...ICON_SIZE_DEFAULT,
                    display: "block",
                    color: palette.text.primary,
                    marginRight: "1em"
                  }}
                />
                <FormControl
                  size="small"
                  component="div"
                  style={{
                    minWidth: 144,
                    width: "65%",
                    display: "block",
                  }}
                >
                  <RadioGroup
                    aria-label="Aspect Ratio"
                    name="Aspect Ratio"
                    value={aspectRatio}
                    style={{
                      display: "block",
                    }}
                    onChange={addAspectRatio}
                  >
                    {getAspectRatios("portrait")}
                  </RadioGroup>
                </FormControl>
              </div>
              <div 
                className="flex"
                style={{
                  width: "100%",
                  borderBottomColor: `${colors.Neutral.grey30}`,
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                  padding: "1em 0"
                }}
              >
                <CropLandscapeIcon
                  style={{
                    ...ICON_SIZE_DEFAULT,
                    display: "block",
                    color: palette.text.primary,
                    marginRight: "1em"
                  }}
                />
                <FormControl
                  size="small"
                  component="div"
                  style={{
                    minWidth: 144,
                    width: "65%",
                    display: "block",
                  }}
                >
                  <RadioGroup
                    aria-label="Aspect Ratio"
                    name="Aspect Ratio"
                    value={aspectRatio}
                    style={{
                      display: "block",
                    }}
                    onChange={addAspectRatio}
                  >
                    {getAspectRatios("landscape")}
                  </RadioGroup>
                </FormControl>
              </div>
              <div 
                className="flex"
                style={{
                  width: "100%",
                  padding: "1em 0"
                }}
              >
                <CropSquareIcon
                  style={{
                    ...ICON_SIZE_DEFAULT,
                    display: "block",
                    color: palette.text.primary,
                    marginRight: "1em"
                  }}
                />
                <FormControl
                  size="small"
                  style={{
                    minWidth: 144,
                    width: "65%",
                    display: "block",
                  }}
                  component="div"
                >
                  <RadioGroup
                    aria-label="Aspect Ratio"
                    name="Aspect Ratio"
                    style={{
                      display: "block",
                    }}
                    value={aspectRatio}
                    onChange={addAspectRatio}
                  >
                    {getAspectRatios("square")}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </Grid>
        </div>
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
          style={{
            marginTop: "2em"
          }}
        >
          Upload release form(s) for this image.
        </TypographyBody>
        <div
          style={{
            border: "2px dashed grey",
            borderRadius: 6,
            height: 150,
            width: "100%"
          }}
          className="mt-1-em mb-1-em justify-center flex-column flex-center"
          onDrop={(e) => {
            // fileUpload(e)
            // onFileChange(e);
            e.preventDefault();
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragLeave={(e) => {
            e.preventDefault();
          }}
          onDragEnd={(e) => {
            e.preventDefault();
          }}
        >
          <TypographyButton
            onClick={onChangeForm}
            className="half-width pl-4-em pr-4-em"
            component={TYPOGRAPHY_BUTTON_TYPE.IconLeading}
            buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
            icon={<PublishRoundedIcon />}
            buttonStyle={{
              width: "auto",
            }}
          >
            Select File or Drop
          </TypographyButton>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
            style={{
              marginTop: "1em"
            }}
          >
            PDF and docx accepted
          </TypographyBody>
          <input
            ref={hiddenInputRef}
            className="full-width"
            accept=".pdf,.docx,.doc,.pptx"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              opacity: 0,
              cursor: "pointer",
            }}
            type="file"
            id="upload_imgs"
            name="upload_imgs[]"
            multiple
            onChange={fileUpload}
          />
        </div>
        {showReleasedFormList()}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "auto",
            width: "100%"
          }}
        >
          <PikchaButton
            onClick={handlePublish}
            action="primary"
            color="black"
            style={{
              width: "100%"
            }}
          >
            Next
          </PikchaButton>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {

}

export default connect(mapStateToProps, {
  setAlert,
  getReleasefForms
})(UploadFlowModalStep3);