import axios from "axios";
import AuthorizeService from "../auth/AuthorizeService";

import {
  RELATED_IMAGES_ERROR,
  RELATED_IMAGES_LOADED,
  RELATED_IMAGES_LOADING,
  RELATED_IMAGES_PHOTO_LOADING,
  RELATED_IMAGES_PHOTO_LOADING_ASPECT_RATIO,
  RELATED_IMAGES_PORTRAIT,
  RELATED_IMAGES_RESET
} from "./types";
import {GALLERY_ITEMINPAGE_DEFAULT, GALLERY_PAGE_DEFAULT} from "../helpers/constants";

export const getRelatedImages = (
  artistId,
  itemInPage = GALLERY_ITEMINPAGE_DEFAULT,
  page = GALLERY_PAGE_DEFAULT
) => {
  return async (dispatch) => {
    try {
      dispatch(setRelatedImagesLoading());

      const token = await AuthorizeService.getAccessToken();

      const parameters = {
        Id: artistId,
        page: page,
        itemInPage: itemInPage
        // Page: Math.ceil(Math.max(start, 1) / Math.max(count, 1)),
        // ItemInPage: count,
      };
      const res = await axios.post(
        //`${searchApi}/api/Search/ImagesByArtist`,
        `${
          process.env.NODE_ENV === "production"
            ? ""
            : "https://pikcha.local:1900"
        }/v1/search/api/Search/ImagesByArtist`,
        parameters,
        token
          ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
          : null
      );

      dispatch({
        type: RELATED_IMAGES_LOADED,
        payload: res.data,
      });
    } catch (err) {

      console.log("getRelatedImages ", err);

      dispatch({
        type: RELATED_IMAGES_ERROR,
        payload: err.response,
      });
    }
  };
};

export const setRelatedImagesLoading = () => {
  return {type: RELATED_IMAGES_LOADING};
};

export const handleRelatedImagesLoadingAspectRatio = (aspectRatio) => {
  return (dispatch) =>
    dispatch({
      type: RELATED_IMAGES_PHOTO_LOADING_ASPECT_RATIO,
      payload: aspectRatio
    });
};

export const resetRelatedImages = () => {
  return (dispatch) =>
    dispatch({
      type: RELATED_IMAGES_RESET,
    });
};

export const handleRelatedImagesPortrait = (isPortrait) => {
  return (dispatch) =>
    dispatch({
      type: RELATED_IMAGES_PORTRAIT,
      payload: isPortrait
    });
};

export const handleRelatedImagesPhotoLoading = (state) => {
  return (dispatch) =>
    dispatch({
      type: RELATED_IMAGES_PHOTO_LOADING,
      payload: state
    });
};

