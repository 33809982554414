import React, { useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";

const createBreadcrumbText = (pathname) => {
  if (pathname.endsWith("/")) {
    pathname = pathname.substring(0, pathname.length - 1);
  }
  return pathname
    .split("/")
    .filter((route) => route !== "")
    .map((route) => route.replace(/-/g, " "));
};

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    fontSize: typography.smaller,
    color: "white",
    fontWeight: typography.fontWeightMedium,
    "&:hover": {
      color: "white",
    },
  },
}));

export default () => {
  const classes = useStyles();

  const { pathname } = useLocation();

  const createBreadcrumbLink = useCallback((index, pathname) => {
    const cleanedPaths = pathname.split("/").filter((route) => route !== "");

    let newPath = "";

    for (let i = 0; i < cleanedPaths.length; i++) {
      if (i > index) {
        break;
      }

      newPath = newPath + "/" + cleanedPaths[i];
    }

    if (newPath !== "") {
      return newPath;
    }

    if (newPath === pathname) {
      return undefined;
    }
  }, []);

  return (
    <Breadcrumbs
      aria-label="Breadcrumbs"
      className={`${classes.root} pt-half-em `}
    >
      {createBreadcrumbText(pathname).map((route, index) => (
        <Typography key={index} className={classes.root}>
          {/* TODO: Find a more efficient way to implement this */}
          {createBreadcrumbLink(index, pathname) !== pathname ? (
            <Link
              className={`${classes.root} underlined-link`}
              to={createBreadcrumbLink(index, pathname)}
              style={{color: "white"}}
            >
              {route}
            </Link>
          ) : (
            route
          )}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};
