import React , { useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import {
  PikchaButton,
  PikchaToggleButtonGroup,
  PikchaToggleButton,
} from "../../../components/baseComponents";


const DeleteAccountModalStep3 = ({ 
    onCancel, 
    onStep4, 
    isOnMobile,
    handleRemoveAll,
    handleRemoveProfileImage,
    handleRemoveSocialMediaLinks,
    handleRemoveBiography,
    handleRemoveNationality ,
    RemoveAll,
    RemoveProfileImage,
    RemoveSocialMediaLinks,
    RemoveBiography,
    RemoveNationality,
  }) => {

  const { typography, palette } = useTheme();
  return (
          <>
            <div
              style={{
                maxWidth:"540px",
              }}
            >
              <p
                style={{
                  fontSize: isOnMobile? typography.larger : typography.xLarge,
                  color: palette.text.primary,
                }}
              >
                Select which information you want to remove
              </p>
            
              <div
                style={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    marginTop: "2em",
                    marginBottom: "1em",
                }}
              >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                  <div
                      style={{
                          display: "grid",
                          gridTemplateColumns: "8fr 3fr",
                          gridTemplateRows: "1fr",
                          gap: "16px 8px",
                          minWidth: "100%",
                          gridTemplateAreas: "1fr",
                          alignItems: "center",
                      }}
                  >
                    <span style={{ fontWeight: 600, fontSize: "small" }}>Remove all</span>
                    <div>
                        <PikchaToggleButtonGroup
                            value={RemoveAll}
                            onChange={(_, value) => {
                                if(value===null) return;
                                handleRemoveAll(value);
                            }}
                            aria-label="Viewing"
                        >
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}

                                value={true}
                                aria-label="Setting"
                            >
                                Yes
                            </PikchaToggleButton>
                            <PikchaToggleButton
                                style={{
                                    color: palette.text.disabled,
                                    width: isOnMobile? 70: 100,
                                    maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={false}
                                aria-label="Detail"
                            >
                                No
                            </PikchaToggleButton>
                        </PikchaToggleButtonGroup>
                    </div>
                  </div>
                </div>
              </div>

              <Divider light />

              <div
                style={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    marginTop: "2em",
                    marginBottom: "5em",
                }}
              >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                  <div
                      style={{
                          display: "grid",
                          gridTemplateColumns: "8fr 3fr",
                          gridTemplateRows: "1fr 1fr 1fr",
                          gap: "16px 8px",
                          minWidth: "100%",
                          gridTemplateAreas: "1fr 1fr 1fr",
                          alignItems: "center",
                      }}
                  >
                    <span style={{ fontWeight: 600, fontSize: "small" }}>Profile Image</span>
                    <div>
                        <PikchaToggleButtonGroup
                            value={RemoveProfileImage}
                            onChange={(_, value) => {
                                if(value===null) return;
                                handleRemoveProfileImage(value);
                            }}
                            aria-label="Viewing"
                        >
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={true}
                                aria-label="Setting"
                            >
                                Remove
                            </PikchaToggleButton>
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={false}
                                aria-label="Detail"
                            >
                                Keep
                            </PikchaToggleButton>
                        </PikchaToggleButtonGroup>
                    </div>

                    <span style={{ fontWeight: 600, fontSize: "small" }}>Social Media Links</span>
                    <div>
                        <PikchaToggleButtonGroup
                            value={RemoveSocialMediaLinks}
                            onChange={(_, value) => {
                                if(value===null) return;
                                handleRemoveSocialMediaLinks(value);
                            }}
                            aria-label="Viewing"
                        >
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={true}
                                aria-label="Setting"
                            >
                                Remove
                            </PikchaToggleButton>
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={false}
                                aria-label="Detail"
                            >
                                Keep
                            </PikchaToggleButton>
                        </PikchaToggleButtonGroup>
                    </div>

                    <span style={{ fontWeight: 600, fontSize: "small" }}>Biography</span>
                    <div>
                        <PikchaToggleButtonGroup
                            value={RemoveBiography}
                            onChange={(_, value) => {
                                if(value===null) return;
                                handleRemoveBiography(value);
                            }}
                            aria-label="Viewing"
                        >
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={true}
                                aria-label="Setting"
                            >
                                Remove
                            </PikchaToggleButton>
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={false}
                                aria-label="Detail"
                            >
                                Keep
                            </PikchaToggleButton>
                        </PikchaToggleButtonGroup>
                    </div>

                    <span style={{ fontWeight: 600, fontSize: "small" }}>Nationality</span>
                    <div>
                        <PikchaToggleButtonGroup
                            value={RemoveNationality}
                            onChange={(_, value) => {
                                if(value===null) return;
                                handleRemoveNationality(value);
                            }}
                            aria-label="Viewing"
                        >
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={true}
                                aria-label="Setting"
                            >
                                Remove
                            </PikchaToggleButton>
                            <PikchaToggleButton
                                style={{
                                  color: palette.text.disabled,
                                  width: isOnMobile? 70: 100,
                                  maxHeight: 32,
                                }}
                                selectedStyle={{
                                  color: `${palette.text.primary} !important`,
                                }}
                                value={false}
                                aria-label="Detail"
                            >
                                Keep
                            </PikchaToggleButton>
                        </PikchaToggleButtonGroup>
                    </div>


                  </div>
                </div>
              </div>
              
              <div  
                style={{
                  marginTop: "3em",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <PikchaButton action="secondary"
                  style={{marginRight:"1em",}}
                  onClick={onCancel}
                >
                  Cancel
                </PikchaButton>
                <PikchaButton action="primary" onClick={onStep4}>
                  {isOnMobile? (<>Continue</>): (<>Continue To Delete</>)}
                </PikchaButton>
              </div>
            </div>
        </>
        );
      };

export default connect(null, {})(DeleteAccountModalStep3);