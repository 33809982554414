import React, {useState, lazy, Suspense} from "react";
import {connect} from "react-redux";

import InfiniteScroll from "react-infinite-scroll-component";

import {useTheme} from "@material-ui/core/styles";

import {getArtist100} from "../../actions/artist100";
import {
  followArtist,
  setFromFollowingSection,
  unfollowArtist,
} from "../../actions/account";
import {handleAuthModal} from "../../actions/authModal";

import isMobile from "../../components/Responsive/isMobile";
import ContentLoader from "react-content-loader";

// import FollowingCard from "../../components/FollowingCard/FollowingCard";

const FollowingCard = lazy(() => import("../../components/FollowingCard/FollowingCard"));

export const FollowingCardLoader = (props) => {
  const { spacing, palette } = useTheme();
  
  return (
    <div
      style={{
        marginLeft: spacing(2),
      }}
    >
      <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        // viewBox="0 0 696 414"
        backgroundColor={palette.grey.lighter}
        foregroundColor={palette.grey.lighter}
        {...props}
      >
      </ContentLoader>
    </div>
  );
}

const FollowingSuggestions = ({
                                artist100,
                                auth,
                                account,
                                getArtist100,
                                followArtist,
                                unfollowArtist,
                                handleAuthModal,
                                setFromFollowingSection,
                              }) => {
  let artistRows = artist100.top100;
  const areArtistSuggestionsLoading =
    artist100.top100.length === 0 && artist100.loading;
  if (areArtistSuggestionsLoading) {
    for (let i = 0; i < 8; i++) {
      artistRows = [
        ...artistRows,
        ...[
          {
            placeholder: true,
          },
        ],
      ];
    }
  }

  const {palette, typography} = useTheme();
  const [isCardHovering, setIsCardHovering] = useState(false);

  const isOnMobile = isMobile();

  return (
    <InfiniteScroll
      dataLength={artistRows.length}
      next={() =>
        getArtist100(artist100.count, artist100.start, "RV", "asc")
      }
      hasMore={artist100.hasMore}
      style={{
        height: "auto",
        gridTemplateColumns: !isOnMobile ? "1fr 1fr" : undefined,
        overflow: "auto",
        display: isOnMobile ? null : "grid",
        justifyContent: "center",
        gridGap: "1.5em",
        padding: "1em",
      }}
    >
      {artistRows.map(
        (
          {id, avatar, fName, lName, nickName, nationality, images, placeholder, userHandle},
          index
        ) => {
          if (placeholder) {
            return (
              // static placeholder
              <div
                style={{
                  maxWidth: 700,
                  flex: "1 1",
                  border: `1px solid ${palette.grey.light}`,
                  borderRadius: 6,
                  backgroundColor: "#FFFFFF",
                  padding: "1em",
                  marginTop: isOnMobile ? "1em" : "0.5em",

                  // width: "100%",
                  // maxWidth: 336,
                  // marginBottom: "4em",
                  // marginRight: isOnMobile ? null : "1em",
                  ...(isOnMobile ? {
                    width: "100%",
                  } : {
                    width: "433px",
                  }),
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: 48,
                      height: 48,
                      borderRadius: "50%",
                      backgroundColor: palette.grey.light,
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "0.5em",
                    }}
                  >
                    <div
                      style={{
                        width: 100,
                        height: 24,
                        borderRadius: 6,
                        backgroundColor: palette.grey.light,
                      }}
                    />
                    <div
                      style={{
                        marginTop: "0.5em",
                        width: 64,
                        height: 12,
                        borderRadius: 6,
                        backgroundColor: palette.grey.light,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "auto",
                      width: 128,
                      height: 48,
                      borderRadius: 6,
                      backgroundColor: palette.grey.light,
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "0.5em",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      width: "128px",
                      height: "128px",
                      //borderRadius: 6,
                      backgroundColor: palette.grey.light,
                      ...(isOnMobile ? {
                        width: "100px",
                        height: "100px",
                        marginRight: ".25em",
                        marginLeft: ".25em"
                      } : {}),
                    }}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      width: "128px",
                      height: "128px",
                      //borderRadius: 6,
                      backgroundColor: palette.grey.light,
                      ...(isOnMobile ? {
                        width: "100px",
                        height: "100px",
                        marginRight: ".25em",
                        marginLeft: ".25em"
                      } : {}),
                    }}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      width: "128px",
                      height: "128px",
                      //borderRadius: 6,
                      backgroundColor: palette.grey.light,
                      ...(isOnMobile ? {
                        width: "100px",
                        height: "100px",
                        marginRight: ".25em",
                        marginLeft: ".25em"
                      } : {}),
                    }}
                  />
                </div>
              </div>
            );
          }
          return (
            <Suspense fallback={<FollowingCardLoader/>}>
              <FollowingCard
                key={id}
                id={id}
                userHandle={userHandle}
                auth={auth}
                avatar={avatar}
                fName={fName}
                lName={lName}
                nickName={nickName}
                nationality={nationality}
                images={images}
                index={index}
                account={account}
                followArtist={followArtist}
                unfollowArtist={unfollowArtist}
                handleAuthModal={handleAuthModal}
                setFromFollowingSection={setFromFollowingSection}
              />
            </Suspense>
          );
        }
        )}
        </InfiniteScroll>
        );
      };

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  auth: state.authReducer,
  artist100: state.artist100Reducer,
});

export default connect(mapStateToProps, {
  getArtist100,
  followArtist,
  unfollowArtist,
  handleAuthModal,
  setFromFollowingSection,
})(FollowingSuggestions);
