import React from 'react';
import CreationTile from './CreationTile';
import PlaceholderTile from './PlaceholderTile';
import AlbumTile from './AlbumTile';
import isMobile from '../../../components/Responsive/isMobile';

export default (props) => {
  const { albumItem } = props;
  const isOnMobile = isMobile()

  const { creationTile, placeholderTile } = albumItem;

  if (creationTile) {
    return <CreationTile {...props} />;
  } if (placeholderTile) {
    return <PlaceholderTile {...props} />;
  }
  return (
    <div style={{ marginRight: isOnMobile && '3em' }}>
      <AlbumTile {...props} />
    </div>
  )
};
