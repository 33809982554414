import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ zIndex, palette, transitions }) => ({
  root: {
    width: '100%',
    height: 'fit-content',
    background: palette.common.white,
    backgroundColor: palette.common.white,
    zIndex: zIndex.drawer,
  },
  rootGrow: {
    flexDirection: 'column',
    display: 'flex',
    flexGrow: 1,
  },
  paper: {
    background: palette.common.white,
    backgroundColor: palette.common.white,
    width: '100%',
    boxShadow: 'none',
    height: '100%',
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  expand: (expanded) => ({
    transform: `rotate(${!expanded ? '180' : '0'} deg)`,
    paddingLeft: '.7em',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }),
  drawerOpen: (drawerWidth) => ({
    overflowY: 'hidden',
    background: palette.common.white,
    width: drawerWidth,
    zIndex: zIndex.drawer,
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    //boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 5%)',
  }),
  drawerClose: (drawerWidth) => ({
    zIndex: zIndex.drawer,
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    background: palette.common.white,
    width: drawerWidth - 4,
  }),
}));
