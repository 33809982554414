export const FB_PIXEL_EVENT_TYPES = {
  AddPaymentInfo: 'AddPaymentInfo',
  AddToCart: 'AddToCart',
  AddToWishlist: 'AddToWishlist',
  CompleteRegistration: 'CompleteRegistration',
  Contact: 'Contact',
  CustomizeProduct: 'CustomizeProduct',
  Donate: 'Donate',
  FindLocation: 'FindLocation',
  InitiateCheckout: 'InitiateCheckout',
  Lead: 'Lead',
  PageView: 'PageView',
  Purchase: 'Purchase',
  Schedule: 'Schedule',
  Search: 'Search',
  StartTrial: 'StartTrial',
  SubmitApplication: 'SubmitApplication',
  Subscribe: 'Subscribe',
  ViewContent: 'ViewContent',
  ArtistPromotion: 'ArtistPromotion',
  SuccessfulUpload: 'SuccessfulUpload',
};
