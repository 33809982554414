import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import {useTheme} from "@material-ui/core/styles";

import {PikchaModal} from "./PikchaModal";
import {PikchaButton, PikchaModalHeader,} from "../index";
import ConfirmAlert from "../../ConfirmAlert/ConfirmAlert";
import ImageUploadStepsCustom
  from "../../ImageUploadSteps/ImageUploadStepsCustom";
import isMobile from "../../Responsive/isMobile";
import isMobileLandscape from "../../Responsive/isMobileLandscape";
import isMobilePortrait from "../../Responsive/isMobilePortrait";

export default ({
                  hideHeaderOnMobile = false,
                  nextText="Next",
                  open,
                  title,
                  handlePrevStep,
                  handleNextStep,
                  handleModalClose,
                  getSteps,
                  step,
                  currentStep,
                  maxWidth = "lg",
                  height = 704,
                  handleInitialStep = undefined,
                  handleSecondStep = undefined,
                  handleThirdStep = undefined,
                  handleFinalStep = undefined,

                  handleCloseModalAndExitAlert = undefined,

                  confirmExitAlertState = undefined,
                  handleConfirmExitAlert = undefined,
                }) => {
  if (
    handleCloseModalAndExitAlert === undefined ||
    confirmExitAlertState === undefined ||
    handleConfirmExitAlert === undefined
  ) {
    throw new Error(
      "An exit alert state must be provided with an exit alert handler",
    );
  }

  const isOnMobile = isMobile();

  const isOnMobilePortrait = isMobilePortrait();
  const isOnMobileLandscape = isMobileLandscape();

  const {palette, typography} = useTheme();

  const steps = getSteps();

  return (
    <>
      {confirmExitAlertState && (
        <ConfirmAlert
          handleCloseModalAndExitAlert={handleCloseModalAndExitAlert}
          open={confirmExitAlertState}
          onClose={handleConfirmExitAlert}
        />
      )}
      <PikchaModal
        hasCustomHeader={!hideHeaderOnMobile}
        open={open}
        maxWidth={!isOnMobile ? maxWidth : "100vW"}
        height={!isOnMobile ? height : "100vH"}
        onClose={handleModalClose}
        disableEscapeKeyDown
        disableBackdropClick
        paperStyle={{
          ...((isOnMobilePortrait || isOnMobileLandscape) && ({
            maxWidth: "100vW",
            margin: 0,
            borderRadius: 0,
            maxHeight: "100vH",
            height: "100vH",
            width: "100vW"
          }))
        }}
      >
        <PikchaModalHeader
          reduceVerticalPadding
          onClose={handleModalClose}
          darkHeader
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            Become An Artist
          </div>
          <div className="flex" style={{marginRight: "auto", marginLeft: "auto"}}>
            <div
              style={{
                fontSize: typography.smallest,
                color: palette.primary.medium,
                marginRight: "0.75em"
              }}
            >
              Artist Details
            </div>
            <div
              style={{
                fontSize: typography.smallest,
                color: step >= 3 ? palette.primary.medium : palette.common.white,
                marginRight: "0.75em",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div 
                style={{
                  width: 40, 
                  height: 4, 
                  borderRadius: 4,
                  backgroundColor: step >= 2 ? palette.primary.medium : palette.common.white,
                  marginRight: "0.25em"
                }}
              />
              Contact Information
            </div>
            <div
              style={{
                fontSize: typography.smallest,
                color: palette.common.white,
                display: "flex",
                alignItems: "center"
              }}
            >
              <div 
                style={{
                  width: 40, 
                  height: 4, 
                  borderRadius: 4,
                  backgroundColor: step >= 3 ? palette.primary.medium : palette.common.white,
                  marginRight: "0.25em"
                }}
              />
              Tax Information
            </div>
          </div>
        </PikchaModalHeader>
        <div className="flex-stretch full-width flex-grow-1">
          <div className={`flex-grow-1 pl-1-em pr-1-em ${!isOnMobilePortrait && !isOnMobileLandscape
            ? "pb-3-em pt-5-em"
            : ""} flex-column`}>
            {currentStep()}
          </div>
        </div>
        <DialogActions
          className="full-width"
          style={{
            background: palette.grey.light,
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <div className="ModalFooter-container">
            {step !== 1 && (
              <PikchaButton
                action="secondary"
                className="half-width mr-half-em"
                onClick={handlePrevStep}
              >
                Back
              </PikchaButton>
            )}
            <PikchaButton
              onClick={
                step === 2 && handleInitialStep
                  ? handleInitialStep
                    : step === 3 && handleFinalStep
                      ? handleFinalStep
                      : handleNextStep
              }
              color="black"
            >
              {step === 3 ? "Submit" : nextText}
            </PikchaButton>
          </div>
        </DialogActions>
      </PikchaModal>
    </>
  );
};
