import axios from "axios";

import AuthorizeService from "../auth/AuthorizeService";

import { isSafeSearchEnabled, isUserLoggedIn } from "../selectors/account";
import { hasPassedAgeRestriction } from "../selectors/ageRestriction";

import {
  SHOW_AGE_RESTRICTION,
  CANCEL_AGE_RESTRICTION,
  PASS_AGE_RESTRICTION,
  HANDLE_SAFE_SEARCH,
} from "./types";

import { setAlert } from "./alert";
import { handleAuthModal } from "./authModal";

export const showAgeRestriction = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_AGE_RESTRICTION,
    });
  };
};

export const cancelAgeRestriction = () => {
  return (dispatch) => {
    dispatch({
      type: CANCEL_AGE_RESTRICTION,
    });
  };
};

export const passAgeRestriction = ({ isOver18 }) => {
  return (dispatch) => {
    if (isOver18) {
      dispatch({
        type: PASS_AGE_RESTRICTION,
      });

      dispatch(toggleSafeSearch());
    } else {
      dispatch(setAlert("You must verify your age", "error"));
    }
  };
};

export const toggleSafeSearch = () => {
  return async (dispatch, getState) => {
    try {
      const userLoggedIn = isUserLoggedIn(getState());
      if (!userLoggedIn) {
        dispatch(handleAuthModal(true, "safe-search"));
        return;
      }

      const safeSearchState = isSafeSearchEnabled(getState());
      const newSafeSearchState = !safeSearchState;

      const passedAgeRestriction = hasPassedAgeRestriction(getState());
      if (!passedAgeRestriction && !newSafeSearchState) {
        dispatch(showAgeRestriction());
        return;
      }

      if (passedAgeRestriction && newSafeSearchState) {
        dispatch(cancelAgeRestriction());
      }

      const user = await AuthorizeService.getUser();
      const token = await AuthorizeService.getAccessToken();

      const res = await axios.post(
        `api/Search/SafeSearch/${user.sub}?safeSearch=${newSafeSearchState}`,
        {
          params: {
            userId: user.sub,
            safeSearch: newSafeSearchState,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: HANDLE_SAFE_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      console.log("toggleSafeSearch", err);
    }
  };
};
