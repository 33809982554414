import {
  HANDLE_LOGIN_LOADING_STATUS,
  HANDLE_LOGIN_PASSWORD,
  HANDLE_LOGIN_USERNAME,
} from "./types";

export const handleLoginUsername = ({ target: { value } }) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_LOGIN_USERNAME,
      payload: value,
    });
  };
};

export const handleLoginPassword = ({ target: { value } }) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_LOGIN_PASSWORD,
      payload: value,
    });
  };
};

export const handleLoginLoadingStatus = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_LOGIN_LOADING_STATUS,
      payload: state,
    });
  };
};
