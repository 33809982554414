import React from "react";
import { connect } from "react-redux";
import PerfectScrollBar from "react-perfect-scrollbar";
import { queuedShoppingCartItems, readyShoppingCartItems, shoppingCartItems, } from "../../../selectors/shoppingCart";
import HeaderDesktop from "../HeaderDesktop";
import TotalsMobile from "../TotalsMobile";
import ShoppingCartItemDesktop from "../../../containers/checkout/ShoppingCartItemDesktop";
import { PikchaLoader } from "../../baseComponents";
import { PikchaLink } from "../../baseComponents/PikchaLink";
import { IntlCurrencyFormatter, currencyFormat } from "../../NumberFormatters/IntlCurrencyFormatter";
import { TypographyButton, TYPOGRAPHY_BUTTON_TYPE, TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";
import CartItems from "../CartItems"

const CartDetails = ({
  deleteItem,
  readyShoppingCartItems,
  shoppingCart,
  account,
  paymentSuccessResponse
}) => {
  const areThereReadyShoppingCartItems = readyShoppingCartItems.length > 0;
  const showOrderSummary = !areThereReadyShoppingCartItems &&
    paymentSuccessResponse &&
    paymentSuccessResponse.orderItems;

  return (
    <>
      <div
        style={{
          borderRadius: 6,
          boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.15)",
          animation: "fadeIn 0.6s",
          background: "white",
          minHeight: 350,
          margin: '1em',
          position: "sticky",
          top: "4em"
        }}
      >
        <HeaderDesktop
          showOrderSummaryheader={showOrderSummary}
        />
        {((!account.user && !areThereReadyShoppingCartItems && !account.loadingUser && !showOrderSummary) ||  //deal with the no login condition, shows empty page if user not login so get nothing from backend by no id
          (account.user == null && !areThereReadyShoppingCartItems && !account.loadingUser && !showOrderSummary) || // guest User
          (!areThereReadyShoppingCartItems && !showOrderSummary)) &&

          ( //show empty page if no ready shopping cart items and loading
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: ".5em 1em 1em .5em",
                  margin: "0.5em",
                }}
              >
                <div style={{
                  textAlign: 'center',
                  marginTop: "1em",
                  marginBottom: "1em",
                }}>
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    style={{ marginBottom: '1em' }}
                  >
                    Your cart is empty
                  </TypographyBody>
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                    style={{ lineHeight: '24px' }}
                  >
                    Looks like you haven’t added anything to your cart yet!
                  </TypographyBody>
                </div>
                <TypographyButton
                  component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                >
                  <PikchaLink
                    to="/"
                    style={{
                      color: 'white'
                    }}
                  >
                    Explore artwork
                  </PikchaLink>
                </TypographyButton>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    justifyContent: "space-between",
                    minWidth: "100%",
                    padding: "1.5em",
                  }}
                >
                  <TotalsMobile areThereReadyShoppingCartItems={areThereReadyShoppingCartItems} basketId={(account.user || {}).id} />
                </div>
              </div>
            </>
          )}

        {account.user && !account.loadingUser && shoppingCart.loading && !areThereReadyShoppingCartItems && (
          <PikchaLoader />
        )}
        {(areThereReadyShoppingCartItems || showOrderSummary) && (
          <>
            <PerfectScrollBar
              options={{
                suppressScrollX: true,
                handlers: ["click-rail", "drag-thumb", "keyboard", "wheel", "touch"],
                wheelPropagation: false
              }}
              style={{
                maxHeight: "60vh",
                height: "auto"
              }}
            >
              <div
                style={{
                  margin: "0.5em",
                }}
              >
                {readyShoppingCartItems.map(
                  ({ shoppingCartItem, timer }, index) => (
                    <>
                      <ShoppingCartItemDesktop
                        key={index}
                        shoppingCartItem={shoppingCartItem}
                        deleteItem={deleteItem}
                      />
                    </>
                  )
                )}
              </div>
            </PerfectScrollBar>
            {!showOrderSummary &&
              <div
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    justifyContent: "space-between",
                    minWidth: "100%",
                    padding: "1.5em",
                  }}
                >
                  <TotalsMobile areThereReadyShoppingCartItems={areThereReadyShoppingCartItems} basketId={(account.user || {}).id} />
                </div>
              </div>
            }
          </>
        )}

        {/* Conditional rendering for Order Summary */}
        <div style={{ width: "100%" }}>
          {showOrderSummary &&
            paymentSuccessResponse.orderItems.map(
              (shoppingCartItem, index) => {
                const props = {
                  imageRender: shoppingCartItem?.imageRender,
                  imageTitle: shoppingCartItem?.imageTitle,
                  userHandle: shoppingCartItem?.seller?.userHandle,
                  nickName: shoppingCartItem?.seller?.nickName,
                  type: shoppingCartItem?.type,
                  material: shoppingCartItem?.material,
                  size: shoppingCartItem?.size,
                  border: shoppingCartItem?.border,
                  frame: shoppingCartItem?.frame,
                  wrap: shoppingCartItem?.wrap,
                  galleryCardColor: shoppingCartItem?.galleryCardColor,
                  productNumber: shoppingCartItem?.productNumber,
                  totalEditions: shoppingCartItem?.totalEditions,
                  totalPrice: shoppingCartItem?.finPrice
                }
                return (
                  <div
                    key={index}
                    style={{
                      borderRadius: 6,
                      padding: "0.5em 1em",
                    }}
                  >
                    <CartItems {...props} />
                  </div>
                )
              })}
          {showOrderSummary &&
            (<div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  justifyContent: "space-between",
                  minWidth: "100%",
                  padding: "0.5em 1em 1.5em",
                }}
              >
                <div style={{
                  borderTop: '2px solid #DBE0E4',
                  lineHeight: '24px'
                }}>
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    color="neutral/grey50"
                    style={{
                      display: "flex",
                      paddingTop: '1em'
                    }}
                  >
                    Subtotal
                    <p
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      <IntlCurrencyFormatter value={+(paymentSuccessResponse.requestDetails.subTotal
                        ? paymentSuccessResponse.requestDetails.subTotal
                        : 0)}
                      />
                    </p>
                  </TypographyBody>
                  {paymentSuccessResponse?.requestDetails?.discount ?
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      color="neutral/grey50"
                      style={{
                        display: "flex",
                        paddingTop: '1em'
                      }}
                    >
                      Discount
                      <p
                        style={{
                          marginLeft: "auto",
                        }}>
                        <IntlCurrencyFormatter value={+(paymentSuccessResponse.requestDetails
                          ? paymentSuccessResponse.requestDetails.discount
                          : 0)} />
                      </p>
                    </TypographyBody>
                    : ''}
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    color="neutral/grey50"
                    style={{
                      display: "flex",
                      paddingTop: '1em'
                    }}
                  >
                    Shipping
                    <p
                      style={{
                        marginLeft: "auto",
                      }}>
                      <IntlCurrencyFormatter value={+(paymentSuccessResponse.requestDetails
                        ? paymentSuccessResponse.requestDetails.shipCost
                        : 0)} />
                    </p>
                  </TypographyBody>
                  <TypographyBody
                    size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    style={{
                      display: "flex",
                      paddingTop: '1em'
                    }}
                  >
                    Total
                    <p
                      style={{
                        marginLeft: "auto",
                      }}
                    >
                      <IntlCurrencyFormatter value={+(paymentSuccessResponse.requestDetails
                        ? paymentSuccessResponse.requestDetails.totalAmount
                        : 0)} displayFormat={currencyFormat.code} />
                    </p>
                  </TypographyBody>
                </div>
              </div>
            </div>
            )
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  state,
  account: state.accountReducer,
  shoppingCart: state.shoppingCartReducer,
  shoppingCartItems: shoppingCartItems(state),
  readyShoppingCartItems: readyShoppingCartItems(state),
  queuedShoppingCartItems: queuedShoppingCartItems(state),
});
export default connect(mapStateToProps, {})(CartDetails);
