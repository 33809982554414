import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { hideNotification } from "../../../../actions/notification";

import { activeTimer } from "../../../../selectors/timer";

import { ICON_SIZE_LARGE } from "../../../../helpers/constants";

import Timer from "../../../../components/Timer/Timer";

import isMobile from "../../../../components/Responsive/isMobile";
import TimerIcon from '@material-ui/icons/Timer';
import IconButton from "@material-ui/core/IconButton";
import {PikchaLink} from "../../../../components/baseComponents/PikchaLink";
import {PikchaCloseButton} from "../../../../components/baseComponents";

const placeHolder = require("../../../../assets/images/placeholder.png");

const TimeReminderNotification = ({
  currentTimer,
  activeTimer,
  handleClose,
  startTimer,
  label,
  id,
  hideNotification,
  timerUpdated,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const isOnMobile = isMobile();

  const theme = useTheme();
  const closeNotification = () => {
    hideNotification();
  };
  const updateTimerVal = (e) => {
    timerUpdated(e);
  };

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.includes("/checkout")) {
      closeNotification();
    }
  }, [pathname]);

  let timerId;
  if (activeTimer) {
    timerId = activeTimer.id;
  }

  const { typography, palette } = theme;

  const StyledIconButton = withStyles({
    root: {
      width: 32,
      height: 32,
      marginRight: 8,
      padding: 8,
      borderRadius: 6,
    },
  })(IconButton);
  return (
    <div>
      <PikchaLink
        to="/checkout"
        onClick={(e) => closeNotification()}
        className="timeNotificationLink text-decoration-none"
        style={{
          animation: "fadeIn",
          animationDuration: ".5s",
          maxWidth: "220px",
          minWidth: "220px",
          minHeight: "80px",
          maxHeight: "80px",
          position: "fixed",
          padding: "0.5em",
          top: "3.5em",
          right: isOnMobile ? "5%" : "7%",
          border: "1px solid #DADADA",
          borderRadius: "6px",
          background: "white",
          display: "flex",
          boxShadow: isHovering
            ? "1px 1px 4px rgba(0, 0, 0, 0.25), 1px 1px 8px rgba(0, 0, 0, 0.25)"
            : "1px 1px 4px rgba(0, 0, 0, 0.25), 1px 1px 4px rgba(0, 0, 0, 0.25)",
          flexDirection: "row",
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleHoverLeave}
      >
        {isOnMobile&&(
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              position: "absolute",
              top: 0,
              right: 0,
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <PikchaCloseButton
              smallVariant
              onClick={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                handleClose();
              }}
              style={{
                zIndex: 9999,
              }}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display:"flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <TimerIcon fontSize={"small"}/>
            <div
              style={{
                fontSize: typography.tiny,
                fontWeight: typography.fontWeightMedium,
                color: palette.text.primary,
                margin: "0 3px"
              }}
            >
              {label && <p>{label}</p>}
              {!label && <p>TIME TO CHECKOUT</p>}
            </div>
          </div>
          <div>
            <Timer
              updateTimer={(e) => updateTimerVal(e)}
              start={startTimer}
              id={timerId}
              status={"active"}
            />
          </div>
        </div>
        <div
          style={{
            alignSelf: "flex-end",
            marginLeft: "auto",
          }}
        >
          <StyledIconButton>
            <ChevronRightIcon
              style={{
                ...ICON_SIZE_LARGE,
                color: palette.text.primary,
              }}
            />
          </StyledIconButton>
        </div>
      </PikchaLink>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeTimer: activeTimer(state),
});

export default connect(mapStateToProps, {
  hideNotification,
})(TimeReminderNotification);
