import React, { useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

import { toggleSafeSearch } from "../../actions/ageRestriction";

import { isSafeSearchEnabled } from "../../selectors/account";

import Desktop from "../../components/Responsive/Desktop";
import Tablet from "../../components/Responsive/Tablet";
import Mobile from "../../components/Responsive/Mobile";
import isMobile from "../../components/Responsive/isMobile";

import ContentLoader from "react-content-loader";

import PikchaRowDesktop from "./PikchaRowDesktop";
import PikchaRowMobile from "./PikchaRowMobile";

export const PikchaRowLoader = (props) => {
  const { spacing, palette } = useTheme();
  return (
    <div
      style={{
        marginLeft: spacing(2),
      }}
    >
      <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        // viewBox="0 0 696 414"
        backgroundColor={palette.grey.lighter}
        foregroundColor={palette.grey.lighter}
        {...props}
      >
      </ContentLoader>
    </div>
  );
}

const PikchaRow = ({
  row,
  rank,
  searchBy,
  style,
  safeSearch,
  toggleSafeSearch,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const { palette } = useTheme();
  const isOnMobile = isMobile();

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      style={{
        ...style,
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey.lighter}`,
        borderRadius: "6px",
        marginBottom: "2em",
        marginLeft: isOnMobile ? 0 : "1em",
        marginRight: isOnMobile ? 0 : "1em",

        ...(isHovering && {
          boxShadow:
            "4px 4px 4px rgba(0, 0, 0, 0.25), -4px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.25)",
        }),
      }}
    >
      <Desktop>
        <PikchaRowDesktop
          row={row}
          rank={rank}
          searchBy={searchBy}
          safeSearch={safeSearch}
          toggleSafeSearch={toggleSafeSearch}
        />
      </Desktop>
      <Tablet>
        <PikchaRowDesktop
          row={row}
          rank={rank}
          searchBy={searchBy}
          safeSearch={safeSearch}
          toggleSafeSearch={toggleSafeSearch}
        />
      </Tablet>
      <Mobile>
        <PikchaRowMobile
          row={row}
          rank={rank}
          searchBy={searchBy}
          safeSearch={safeSearch}
          toggleSafeSearch={toggleSafeSearch}
        />
      </Mobile>
    </div>
  );
};

const mapStateToProps = (state) => ({
  safeSearch: isSafeSearchEnabled(state),
});

export default connect(mapStateToProps, {
  toggleSafeSearch,
})(PikchaRow);
