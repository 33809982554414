import React, { useEffect } from 'react';
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  colors
} from "@pikcha/typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const StyledLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 4
  },
  barColorPrimary: {
    backgroundColor: colors.Primary.primary40,
  },
  colorPrimary: {
    backgroundColor: colors.Neutral.grey30
  },
}))(LinearProgress);

export default ({
  uploadProgress,
  uploadSuccessfully,
  handleNextStep
}) => {
  
  useEffect(() => {
    uploadSuccessfully && handleNextStep()
  }, [uploadSuccessfully])

  return (
    <>
      <TypographySubtitle
        component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
        style={{
          maxWidth: "80%",
          margin: "0 auto",
          textAlign: "center"
        }}
      >
        We’re Publishing Your 
        Artwork to Pikcha
      </TypographySubtitle>
      <StyledLinearProgress
        style={{
          width: "90%",
          margin: "1em auto"
        }}
        variant="determinate"
        value={uploadProgress}
      />
    </>
  )
}
