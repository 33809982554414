export const colors = {
  Primary: {
    primary90: "#190078",
    primary80: "#280096",
    primary70: "#3700B3",
    primary60: "#4B01D1",
    primary50: "#6100FF",
    primary40: "#7F22FD",
    primary30: "#9E54FC",
    primary20: "#BB86FC",
    primary10: "#D7B7FD",
    primary00: "#F2E7FE"
  },
  Neutral: {
    ink90: "#000000",
    ink80: "#121619",
    ink70: "#343A3F",
    ink60: "#4D5358",
    ink50: "#697077",
    grey50: "#878D96",
    grey40: "#A2A9B0",
    grey30: "#DBE0E4",
    grey20: "#F2F4F8",
    white00: "#FFFFFF"
  },
  System: {
    green50: "#00C738",
    green40: "#49D15B",
    green30: "#9EE4A2",
    green20: "#C5EEC7",
    green10: "#E7F9E8",
    red50: "#ED0000",
    red40: "#FF2B12",
    red30: "#FF7B5D",
    red20: "#FFC8B9",
    red10: "#FFE7E6",
    yellow50: "#F3D125",
    yellow40: "#F5E64D",
    yellow30: "#F9F198",
    yellow20: "#FCF7C1",
    yellow10: "#FEFCE6",
  },
  Secondary: {
    secondary90: "#008350",
    secondary80: "#00A271",
    secondary70: "#00B380",
    secondary60: "#00C692",
    secondary50: "#00D6A2",
    secondary40: "#00E0B1",
    secondary30: "#00ECC1",
    secondary20: "#00F5D4",
    secondary10: "#98F9E5",
    secondary00: "#D7FDF6",
  }
}