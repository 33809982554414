import {
  GALLERY_SEARCH_LOADED,
  GALLERY_SEARCH_LOADING,
  GALLERY_SEARCH_ERROR,
  GALLERY_SEARCH_RESET,
  GET_SAVED_IMAGES,
  HANDLE_SAVED_IMAGE,
  GET_USER_COLLECTION_MORE,
  GET_USER_COLLECTION_MORE_FAILED,
  SEARCH_LOADED,
  SEARCH_LOADING,
  SEARCH_ERROR,
  SEARCH_RESET,
} from '../actions/types';

const initialState = {
  photos: [],
  count: 10,
  // count: 15,
  start: 1,
  loading: true,
  error: null,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GALLERY_SEARCH_RESET:
      return initialState;
    // return state;
    case SEARCH_RESET:
      return initialState;
    // return {
    //  ...state,
    //  initialState
    // }
    case GALLERY_SEARCH_LOADED:
      return {
        // ...state,
        photos: payload,
        // photos: [...state.photos, ...payload],
        loading: false,
        start: state.start + 1,
      };
    case GALLERY_SEARCH_ERROR:
      // payload &&
      //   payload.data &&
      //   payload.data === "You have reached the end." &&
      //   console.error(payload);
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: false,
        // payload &&
        // payload.data &&
        // payload.data !== "You have reached the end.",
      };
    case GALLERY_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case HANDLE_SAVED_IMAGE: {
      const { imageId, saved: isSaved } = payload;
      const photos = state.photos.map((photo) => {
        const { id } = photo;
        if (id === imageId) {
          return {
            ...photo,
            ...{
              isSaved,
            },
          };
        }
        return photo;
      });
      return {
        ...state,
        ...{ photos },
      };
    }
    case GET_SAVED_IMAGES: {
      const photos = [...state.photos, ...payload];
      return {
        ...state,
        photos,
        loading: false,
        start: state.start + state.count,
      };
    }

    case GET_USER_COLLECTION_MORE: {
      const photos = [...state.photos, ...payload];
      return {
        ...state,
        photos,
        loading: false,
        start: state.start + state.count,
        hasMore: payload.length >= state.count,
      };
    }

    case GET_USER_COLLECTION_MORE_FAILED: {
      return {
        ...state,
        loading: false,
        hasMore: false,
      };
    }

    case SEARCH_LOADED:
      return {
        ...state,
        photos: [...state.photos, ...payload],
        loading: false,
        start: state.start + state.count,
        hasMore: payload.length > 0,
      };
    case SEARCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: false,
      };
    default:
      return state;
  }
};
