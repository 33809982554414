import React, {useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import {useTheme} from "@material-ui/core/styles";

import {MAXIMUM_PRICE} from "../../helpers/constants";
import PikchaFieldWrapper from "../baseComponents/PikchaFieldWrapper";

export default ({
  required,
  style,
  onValueChange,
  value,
  disabled,
  ...rest
}) => {
  const [priceValue, setPriceValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);

  const { palette, typography } = useTheme();

  const onPriceValueChanged = (values) => {
    setPriceValue(values.value);
    if (onValueChange) {
      onValueChange(values);
    }
  };

  useEffect(() => {
    if(value){
      setPriceValue(value);
    }
  }, [value]);

  return (
    <PikchaFieldWrapper
      label="Earnings $ per sale"
      subLabel="USD"
      required
      disabled={disabled}
      classNameOuter="NumberFormat-container"
      innerClassName={`NumberFormat-wrapper ${disabled && "disabled"}`}
      style={{
        backgroundColor: palette.common.white,
        color: isFocused
          ? palette.primary.main
          : disabled
          ? palette.action.disabled
          : palette.grey.mediumDark,
        ...(style || {}),
      }}
      labelStyle={{
        color: isFocused
          ? palette.primary.main
          : disabled
          ? palette.action.disabled
          : palette.grey.mediumDark,
      }}
      legend={
        <div
          style={{
            paddingTop: "6.4px",
          }}
        >
          <span
            style={{
              fontWeight: typography.fontWeightMedium,
              fontSize: typography.small,
            }}
          >
            $
          </span>
          {(priceValue || "").length < 1 && (
            <span
              style={{
                paddingLeft: "6px",
                color: palette.text.secondary,
                fontSize: typography.small,
                fontWeight: typography.fontWeightSemiMedium,
              }}
            >
              10 min
            </span>
          )}
        </div>
      }
    >
      <NumberFormat
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="MuiInputBase-input"
        thousandSeparator
        isNumericString
        allowNegative={false}
        decimalSeparator={false}
        onValueChange={onPriceValueChanged}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return formattedValue === "" || floatValue < MAXIMUM_PRICE;
        }}
        style={{
          paddingLeft: "21px",
          paddingBottom: "0.7em",
          paddingTop: "0.85rem",
          width: "100%"
        }}
        disabled={disabled}
        value={priceValue}
        {...rest}
      />
    </PikchaFieldWrapper>
  );
};
