import {
    FRIENDLY_URL_ALBUM_LOADED,
    FRIENDLY_URL_ALBUM_ERROR,
  } from "../actions/types";
  
  const initialState = {
    albums: [],
    // count: 15,
    start: 1,
    loading: true,
    error: null,
    hasMore: true,
  };
  
  export default (state = initialState, { type, payload }) => {
   switch (type) {
      case FRIENDLY_URL_ALBUM_LOADED:
        return {
          ...state,
          albums: payload,
          loading: false,
          start: state.start + 1,
        };
      case FRIENDLY_URL_ALBUM_ERROR:
        return {
          ...state,
          error: true,
          loading: false,
          hasMore: false
        };
      default:
        return state;
    }
  };
  