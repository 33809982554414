export const hasPassedAgeRestriction = (state) => {
  let ageRestriction;
  if (state) {
    ageRestriction = state.ageRestrictionReducer;
  }

  let hasPassedAgeRestriction;
  if (ageRestriction) {
    hasPassedAgeRestriction = ageRestriction.passed;
  }

  return hasPassedAgeRestriction;
};

export const showAgeRestriction = (state) => {
  let ageRestriction;
  if (state) {
    ageRestriction = state.ageRestrictionReducer;
  }

  let shouldShowAgeRestriction;
  if (ageRestriction) {
    shouldShowAgeRestriction = ageRestriction.open;
  }

  return shouldShowAgeRestriction;
};
