import React, {
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import Desktop from "../../components/Responsive/Desktop";
import Tablet from "../../components/Responsive/Tablet";
import Mobile from "../../components/Responsive/Mobile";
import Step3Desktop from "./Step3Desktop";
import Step3Mobile from "./Step3Mobile";
import { getStripeKey } from "../../helpers/utils";

const stripeKey = getStripeKey();
const stripePromise = loadStripe(stripeKey);

export default (props) => {
  const { paymentSuccess } = props;
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    axios({
      method: "GET",
      url: "api/payment/clientinfo/Stripe",
    }).then((res) => {
      setApiKey(res.data.client_id);
    });
  }, []);

  return (
    <>
      {apiKey && !paymentSuccess && (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <>
                <Desktop>
                  <Step3Desktop
                    stripe={stripe}
                    elements={elements}
                    {...props}
                  />
                </Desktop>
                <Tablet>
                  <Step3Desktop
                    stripe={stripe}
                    elements={elements}
                    {...props}
                  />
                </Tablet>
                <Mobile>
                  <Step3Mobile stripe={stripe} elements={elements} {...props} />
                </Mobile>
              </>
            )}
          </ElementsConsumer>
        </Elements>
      )}
    </>
  );
};
