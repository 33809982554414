export const TYPED_SIGNATURE_PLACEHOLDER = 'Type your signature';

export const DEFAULT_TYPED_SIGNATURE_FONT = 'Kalam';

export const SIGNATURE_CONTAINER_MOBILE_WIDTH = 496;
export const SIGNATURE_CONTAINER_TABLET_WIDTH = 648;
export const SIGNATURE_CONTAINER_MOBILE_HEIGHT = 205;
export const SIGNATURE_CONTAINER_WIDTH = 696;
export const SIGNATURE_CONTAINER_HEIGHT = 334;

export const BRUSH_STROKE_THIN = 'thin';
export const BRUSH_STROKE_MEDIUM = 'medium';
