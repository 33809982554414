import axios from "axios";
import AuthorizeService from "../auth/AuthorizeService";

import {
  ARTIST100_LOADED,
  ARTIST100_LOADING,
  ARTIST100_ERROR,
  ARTIST100_RESET,
} from "./types";

import { searchAPIUrl } from "../selectors/settings";

// Get Artist 100
export const getArtist100 = (count, start, sort, order) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading());

      const token = await AuthorizeService.getAccessToken();
      const searchApi = searchAPIUrl(getState());
      
      const res = await axios.post(
        //`${searchApi}/api/Search/Artists`,
        `${
          process.env.NODE_ENV === "production"
            ? ""
            : "https://pikcha.local:1900"
        }/v1/search/api/Pikcha100/Artists`,
        
        {
          sortBy: sort,
          page: Math.ceil(Math.max(start, 1) / Math.max(count, 1)),
          itemInPage: count,
          order,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch({
        type: ARTIST100_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ARTIST100_ERROR,
        payload: err.response,
      });
    }
  };
};

// Set Loading
export const setLoading = () => {
  return { type: ARTIST100_LOADING };
};

// Reset Artist 100
export const resetArtist100 = () => {
  return (dispatch) =>
    dispatch({
      type: ARTIST100_RESET,
    });
};
