import {CAROUSEL_ERROR, CAROUSEL_LOADED, CAROUSEL_LOADING, HANDLE_SAVED_IMAGE,} from "../actions/types";

const initialState = {
  photos: [],
  loading: false,
  error: null,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case CAROUSEL_LOADED:
      return {
        ...state,
        photos: [...state.photos, ...payload],
        loading: false
      };
    case CAROUSEL_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case CAROUSEL_LOADING:
      return {
        ...state,
        loading: true
      };
    case HANDLE_SAVED_IMAGE: {
      const {imageId, saved: isSaved} = payload;
      const photos = state.photos.map((photo) => {
        const {id} = photo;
        if (id === imageId) {
          return {
            ...photo,
            ...{
              isSaved,
            },
          };
        }
        return photo;
      });
      return {
        ...state,
        ...{photos},
      };
    }
    default:
      return state;
  }
};
