import React, {useEffect} from "react";
import ReactDOM from 'react-dom';
import {connect} from "react-redux";
import Popover from "@material-ui/core/Popover";
import {useTheme} from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";

import {handleAuthModal} from "../../../actions/authModal";

import {isUserLoggedIn} from "../../../selectors/account";
import {
  accountNotifications,
  accountNotificationsHasMore,
  hasAccountNotifications,
} from "../../../selectors/accountNotifications";

import {getAccountNotifications} from "../../../actions/accountNotifications";

import PikchaLoader
  from "../../../components/baseComponents/loaders/PikchaLoader";

import isMobile from "../../Responsive/isMobile";

import AccountNotification from "./AccountNotification";
import NotificationHistory from "./NotificationHistory";
import {PikchaSpacer} from "../../baseComponents";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP,
  DROPDOWN_NOTIFICATIONS_WIDTH_MOBILE
} from "../../../helpers/constants";

const NotificationsDropdown = ({
                                 open,
                                 anchorEl,
                                 onClose,

                                 hasAccountNotifications,
                                 accountNotifications,
                                 accountNotificationsHasMore,

                                 getAccountNotifications,
                                 
                               }) => {
  const isOnMobile = isMobile();

  const {palette, typography} = useTheme();

  useEffect(() => {
    if(!isOnMobile){
    let body = document.getElementsByTagName('body')[0]; 
    let header = document.getElementsByTagName('header')[0];
    if(open) {
      ReactDOM.findDOMNode(body).style = 'height: 100vh;overflow-y: hidden;padding-right: 16px;' ;
      ReactDOM.findDOMNode(header).style = 'padding-right: 16px;' ;
    }      
    else{
      ReactDOM.findDOMNode(body).style = 'height: 100%;overflow-y: scroll;padding-right: 0px;'  ;
      ReactDOM.findDOMNode(header).style = 'padding-right: 0px;' ;
    }  
  }  
  }, [open]);

  return (
    <Popover
      PaperProps={{
        style: {
          width: !isOnMobile 
            ? DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP 
            : DROPDOWN_NOTIFICATIONS_WIDTH_MOBILE,
          maxWidth: !isOnMobile 
            ? DROPDOWN_NOTIFICATIONS_WIDTH_DESKTOP 
            : DROPDOWN_NOTIFICATIONS_WIDTH_MOBILE,
            backgroundColor: palette.common.white,
        },
      }}
      style={{
        zIndex: 9999999,
        marginTop: 15,
        
      }}
      open={open}
      container={document.getElementById("root")}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
        
      }}
    >
      <PikchaSpacer size={5} />
      {!hasAccountNotifications && (
        <div
          style={{
            padding: "4%",
            fontSize: typography.small,
            textAlign: "center",
          }}
        >
          You don't have any new notifications
        </div>
      )}
      <PerfectScrollbar
        id="Dropdown-scrollContainer"
        className="flex-column flex-grow-1 flex-stretch modalScroll"
        style={{
          height: 350,
        }}
        options={{
          minScrollbarLength: 0,
          suppressScrollX: true,
          suppressScrollY: accountNotifications.length>4 ? false : true
        }}
      >
        <InfiniteScroll
          className="NotificationsDropdown-scrollContainer"
          dataLength={accountNotifications.length}
          scrollableTarget="Dropdown-scrollContainer"
          style={{
            overflow: "hidden",
          }}
          next={() => {
            getAccountNotifications();
          }}
          //          scrollableTarget="Modal-scrollContainer"
          hasMore={accountNotificationsHasMore}
          loader={<PikchaLoader />}
        >
          {accountNotifications.map((notification, index) => (
            // <NotificationItemBase
            //   key={notification.NotificationId}
            //   route={notification.Url}
            //  
            // />
            <AccountNotification notification={notification} key={index} onClose={onClose}/>
          ))}
        </InfiniteScroll>
      </PerfectScrollbar>
      <div
        style={{
          width: "100%",
          height: 1,
          backgroundColor: palette.grey.medium,
        }}
      />
      <NotificationHistory onClose={onClose}/>
    </Popover>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  account: state.accountReducer,
  loggedIn: isUserLoggedIn(state),
  hasAccountNotifications: hasAccountNotifications(state),
  accountNotifications: accountNotifications(state),
  accountNotificationsHasMore: accountNotificationsHasMore(state),
});

export default connect(mapStateToProps, {
  handleAuthModal,
  getAccountNotifications,
})(NotificationsDropdown);
