import React from 'react'
import { IntlCurrencyFormatter } from "../../../../components/NumberFormatters/IntlCurrencyFormatter";
import DownloadIcon from "../../../../components/extraIcons/DownloadIcon";
import { Col, Container, Row } from "react-grid-system";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT
} from "@pikcha/typography";
import ArrowLeft from '@material-ui/icons/ArrowBackIosOutlined'
import isMobile from '../../../../components/Responsive/isMobile';

const SalesHistoryDesktop = ({ 
  historyData, 
  downloadHistory, 
  goBackFromSalesHistory 
}) => {
  const isOnMobile = isMobile()

  return (
    <>
      <TypographyButton
        buttonStyle={{ padding: '16px 8x', marginBottom: isOnMobile ? '1.75em' : '2em' }}
        onClick={(e) => goBackFromSalesHistory()}
        component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
        icon={<ArrowLeft />}
      >
        Back
      </TypographyButton>
      <div style={{ margin: '0 1.5em' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
          >
            Sales history
          </TypographySubtitle>
          <TypographyButton
            onClick={downloadHistory}
            component={TYPOGRAPHY_BUTTON_TYPE.Outline}
            buttonStyle={{ margin: '1.5em 0' }}
            textStyle={{ paddingLeft: '0.5em', marginBottom: '-3px' }}
            icon={
              <DownloadIcon
                color="#000"
                iconSize={16}
              />
            }
          >
            Download
          </TypographyButton>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            border: '1px solid #DBE0E4',
            borderRadius: '4px',
            padding: '2em',
            marginBottom: '1.5em'
          }}
        >
          <div>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
              Sales to be paid
            </TypographyBody>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
              Pending sales
            </TypographyBody>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Bold}>
              Cancelled orders
            </TypographyBody>
          </div>
          <div>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              <IntlCurrencyFormatter targetCurrency="USD" />
              {historyData?.totalPayableArtistMargin}
            </TypographyBody>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              <IntlCurrencyFormatter targetCurrency="USD" />
              {+historyData?.totalPendingArtistMargin}
            </TypographyBody>
            <TypographyBody style={{ marginBottom: '0.5em' }} size={TYPOGRAPHY_BODY_SIZE.Default_Regular}>
              <IntlCurrencyFormatter targetCurrency="USD" />
              {historyData?.totalCancelledArtistMargin}
            </TypographyBody>
          </div>
        </div>
        {historyData?.imageSaleHistory?.length === 0 && (
          <Container fluid>
            <Row className="text-center">
              <Col md={12} className="mt-4">
                <span className="fontSize16">
                  You don’t have any sales yet
                </span>
              </Col>
            </Row>
          </Container>
        )}
        <div>
          {historyData?.imageSaleHistory?.map((item) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid #DBE0E4',
                  padding: '1.5em',
                  marginBottom: '1em',
                }
                }>
                <TypographySubtitle style={{ marginBottom: '1.5em' }} component={TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Bold}>
                  {item.orderNumber} &nbsp; - &nbsp; {item.title}
                </TypographySubtitle>
                <span style={{
                  minWidth: '200px',
                  height: '200px',
                  backgroundColor: '#F2F4F8',
                  borderRadius: '4px',
                }}>
                  <div
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      width: "100%",
                      height: "100%",
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      backgroundImage: `url(${item.imageRender})`,
                    }} />
                </span>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '1em 0' }}>
                  <span>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      style={{ marginBottom: '0.75em' }}
                    >
                      ORDER DATE
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      style={{ marginBottom: '0.75em' }}
                    >
                      ORDER STATUS
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      style={{ marginBottom: '0.75em' }}
                    >
                      ORDER QUANTITY
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      style={{ marginBottom: '0.75em' }}
                    >
                      RETAIL PRICE
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                      style={{ marginBottom: '0.75em' }}
                    >
                      MANUFACTURING FEE
                    </TypographyBody>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    >
                      ARTIST MARGIN
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                    >
                      PAYMENT STATUS
                    </TypographyBody>
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                    >
                      {item.orderDate}
                    </TypographyBody>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                    >
                      {item.shipDate}
                    </TypographyBody>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                    >
                      {item.quantity}
                    </TypographyBody>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                    >
                      <IntlCurrencyFormatter targetCurrency="USD" />
                      {(item.retailPrice)}
                    </TypographyBody>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                    >
                      <IntlCurrencyFormatter targetCurrency="USD" />
                      {(item.pikchaFee)}
                    </TypographyBody>
                    <TypographyBody
                      style={{ marginBottom: '0.75em' }}
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                    >
                      <IntlCurrencyFormatter targetCurrency="USD" />
                      {(item.artistMargin)}
                    </TypographyBody>
                    <TypographyBody
                      size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                    >
                      {item.paymentStatus}
                    </TypographyBody>
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default SalesHistoryDesktop