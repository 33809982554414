import React, {useCallback, useEffect, useState} from "react";

import {
  makeStyles,
  TextareaAutosize,
  useTheme,
  withStyles
} from "@material-ui/core";
import {PikchaLabel} from "../baseComponents";

const useStyles = makeStyles(({typography}) => ({
  contained: {
    fontSize: typography.small,
    fontWeight: typography.fontWeightSemiMedium,
    fontFamily: typography.fontFamilyMontserrat,
    marginLeft: 0,
    userSelect: "none",
  },
}));

const PikchaTextArea = withStyles(({palette, shape, typography}) => ({
  textField: (props) => ({
    height: props.height ? props.height : null,
    width: "100%",
    background:
      props && props.disabled
        ? palette.action.disabledBackground
        : palette.common.white,
    fontSize: typography.small,
    fontWeight: typography.fontWeightSemiMedium,
    fontFamily: typography.fontFamilyMontserrat,

    padding: "18.5px 14px",
    paddingBottom: "10.5px",
    paddingTop: "10.5px",

    borderRadius: shape.borderRadius,
    resize: "none",
    marginBottom:
      props && (props.charCount || props.footerText || props.reduceBottomMargin)
        ? "0.25rem"
        : "1.75rem",
  }),
  textFieldLabel: (props) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    fontSize: typography.smallest,
    fontFamily: typography.fontFamilyMontserrat,
    lineHeight: "1rem",
    userSelect: "none",
    color:
      props && props.disabled
        ? palette.action.disabled
        : palette.grey.mediumDark,
    marginBottom: ".25rem",
    fontWeight: typography.fontWeightMedium,
  }),
}))(
  ({
     classes: {textFieldLabel, textField, textFieldInput},
     style = {},
     isFullWidth,
     charCount,
     label,
     subLabel,
     required,
     reduceBottomMargin,
     disabled,
     footerText,
     helperText,
     InputProps,
     startAdornment,
     onFinishEdit,
     variant = "outlined",
     ...rest
   }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [previousIsFocused, setPreviousIsFocused] = useState(false);

    const {palette, typography} = useTheme();
    const classes = useStyles();

    InputProps = {
      ...(InputProps || {}),
      ...{
        notched: false,
      },
      ...(startAdornment || {})
    };
    InputProps.className = `${InputProps.className || ""} ${textFieldInput}`;

    useEffect(() => {
      if (!isFocused && previousIsFocused){
        onFinishEdit && onFinishEdit();
        setPreviousIsFocused(isFocused);
      }
    }, [onFinishEdit, isFocused, previousIsFocused])

    return (
      <div
        className={isFullWidth ? "full-width" : ""}
        style={{
          position: "relative",
          ...style,
        }}
        onFocusCapture={() => {
          setPreviousIsFocused(isFocused);
          setIsFocused(true);
        }}
        onBlurCapture={() => {
          setPreviousIsFocused(isFocused);
          setIsFocused(false);
        }}
      >
        <TextareaAutosize
          style={style}
          fullWidth
          disabled={disabled}
          variant={variant}
          type="text"
          rows="2"
          className={textField}
          helperText={helperText}
          FormHelperTextProps={{
            className: classes.contained,
          }}
          InputLabelProps={{
            className: textFieldLabel,
            shrink: true,
            disableAnimation: true,
          }}
          size="small"
          InputProps={InputProps}
          {...rest}
        />
        {(charCount !== undefined || footerText !== undefined) && (
          <div
            style={{
              color: charCount < 1
                ? palette.error.main
                : isFocused
                  ? palette.secondary.main
                  : palette.grey.darkest,
              fontSize: typography.tiny,
              fontWeight: typography.fontWeightMedium,
              fontFamily: typography.fontFamilyMontserrat,
              position: "absolute",
              userSelect: "none",
              cursor: "default",
              bottom: charCount !== 0 ? undefined : 8,
              right: 2,
            }}
          >
            {charCount || charCount === 0 ? charCount : footerText}
          </div>
        )}
      </div>
    );
  }
);

export default ({
                  label = "Description",
                  rows = 2,
                  value,
                  onChange,
                  handleImageDetails,
                  maxChars,
                  required,
                  style,
                  onFinishEdit,
                }) => {
  if (!onChange && !handleImageDetails) {
    throw new Error(
      "Either an `onChange` or `handleImageDetails` callback" +
      " must be passed as a prop"
    );
  }

  const {palette, typography} = useTheme();

  const handleChange = (e) => {
    const {target: {value}} = e;
    const remainingChars = maxChars - value.length;
    if (remainingChars === -1) {
      e.preventDefault();
      e.stopPropagation();

      return;
    }
    if (handleImageDetails) {
      handleImageDetails("caption", value);
    } else {
      onChange(value);
    }
  }

  const handlePaste = useCallback((e) => {
    const stringLength = e.clipboardData.getData("text").length;

    if (value.length + stringLength > maxChars) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [value]);

  return (
    <>
      <PikchaLabel>
        {label}
        {required && (
          <span
            style={{
              color: palette.error.main,
              fontWeight: typography.fontWeightMedium,
              fontFamily: typography.fontFamilyMontserrat,
            }}
          >
                  *
                </span>
        )}
      </PikchaLabel>
      <PikchaTextArea
        required={required}
        style={style}
        rows={rows}
        label={label}
        onPaste={handlePaste}
        type="text"
        multiline
        value={value}
        onChange={handleChange}
        charCount={maxChars ? maxChars - value.length : undefined}
        onFinishEdit={onFinishEdit}
      />
    </>
  );
};
