import React, { useCallback } from "react";

import { useTheme } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";

const TICK_SIZE = 16;

export default ({
  isOnMobile,
  activeStep,
  steps,
  uploadProgress,
  style,
  darkLabel,
}) => {
  const { palette, typography } = useTheme();

  const getBubbleSize = useCallback(() => !isOnMobile ? 16 : 12, [isOnMobile]);
  const getFontSize = useCallback(() => !isOnMobile
    ? typography.verysmall
    : typography.smallest, [isOnMobile]);

  const isFinished = activeStep + 1 >= steps.length && uploadProgress > 99;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          maxWidth: 500,
        }}
      >
        {steps.map((label, index) => {
          const hasStepBeenPassed = activeStep >= index;
          const isNextStepPassedOrActive = activeStep >= index + 1;
          const isThisTheLastStep = index + 1 === steps.length;
          if (isFinished) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: !isThisTheLastStep ? "100%" : undefined,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: getBubbleSize(),
                      height: getBubbleSize(),
                      borderRadius: "50%",
                      backgroundColor: palette.primary.main,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        color: palette.common.white,
                      }}
                    >
                      <DoneIcon
                        color="inherit"
                        style={{
                          height: TICK_SIZE,
                          width: TICK_SIZE,
                        }}
                      />
                    </div>
                  </div>
                </div>
                {!isThisTheLastStep && (
                  <div
                    style={{
                      marginLeft: "1em",
                      marginRight: "1em",
                      marginTop: "0.5em",
                      width: "150px",
                      height: "3px",
                      backgroundColor: palette.primary.main,
                      width: "100%",
                    }}
                  />
                )}
              </div>
            );
          } else if (hasStepBeenPassed) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: !isThisTheLastStep ? "100%" : undefined,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: getBubbleSize(),
                      height: getBubbleSize(),
                      borderRadius: "50%",
                      backgroundColor: palette.primary.main,
                    }}
                  />
                </div>
                {!isThisTheLastStep && (
                  <div
                    style={{
                      marginLeft: "1em",
                      marginRight: "1em",
                      marginTop: "0.5em",
                      width: "150px",
                      height: "3px",
                      backgroundColor: isNextStepPassedOrActive
                        ? palette.primary.main
                        : palette.grey.mediumLight,
                      width: "100%",
                    }}
                  />
                )}
              </div>
            );
          } else {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: !isThisTheLastStep ? "100%" : undefined,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: getBubbleSize(),
                      height: getBubbleSize(),
                      borderRadius: "50%",
                      backgroundColor: palette.grey.mediumLight,
                    }}
                  />
                </div>
                {!isThisTheLastStep && (
                  <div
                    style={{
                      marginLeft: "1em",
                      marginRight: "1em",
                      marginTop: "0.5em",
                      width: "150px",
                      height: "3px",
                      backgroundColor: palette.grey.mediumLight,
                      width: "100%",
                    }}
                  />
                )}
              </div>
            );
          }
        })}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          maxWidth: 500,
        }}
      >
        {steps.map((label, index) => {
          const hasStepBeenPassed = activeStep >= index;
          const isNextStepPassedOrActive = activeStep >= index + 1;
          const isThisTheLastStep = index + 1 === steps.length;
          if (isFinished) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: !isThisTheLastStep ? "100%" : undefined,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingTop: 2,
                      color: darkLabel
                        ? hasStepBeenPassed
                          ? palette.primary.main
                          : palette.text.secondary
                        : palette.common.white,
                      fontWeight: typography.fontWeightMedium,
                      fontSize: getFontSize(),
                      width: "max-content",
                    }}
                  >
                    {label}
                  </div>
                </div>
                {!isThisTheLastStep && (
                  <div
                    style={{
                      marginTop: "0.5em",
                      width: "150px",
                      height: "3px",
                      width: "100%",
                    }}
                  />
                )}
              </div>
            );
          } else if (hasStepBeenPassed) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: !isThisTheLastStep ? "100%" : undefined,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingTop: 2,
                      color: darkLabel
                        ? hasStepBeenPassed
                          ? palette.primary.main
                          : palette.text.secondary
                        : palette.common.white,
                      fontWeight: typography.fontWeightMedium,
                      fontSize: getFontSize(),
                      width: "max-content",
                    }}
                  >
                    {label}
                  </div>
                </div>
                {!isThisTheLastStep && (
                  <div
                    style={{
                      marginTop: "0.5em",
                      width: "150px",
                      height: "3px",
                      width: "100%",
                    }}
                  />
                )}
              </div>
            );
          } else {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: !isThisTheLastStep ? "100%" : undefined,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      paddingTop: 2,
                      color: darkLabel
                        ? hasStepBeenPassed
                          ? palette.primary.main
                          : palette.text.secondary
                        : palette.common.white,
                      fontWeight: typography.fontWeightMedium,
                      fontSize: getFontSize(),
                      width: "max-content",
                    }}
                  >
                    {label}
                  </div>
                </div>
                {!isThisTheLastStep && (
                  <div
                    style={{
                      marginTop: "0.5em",
                      width: "150px",
                      height: "3px",
                      width: "100%",
                    }}
                  />
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
