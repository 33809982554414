import React from "react";
import { Helmet } from "react-helmet";

import Desktop from "../../../components/Responsive/Desktop";
import Tablet from "../../../components/Responsive/Tablet";
import Mobile from "../../../components/Responsive/Mobile";

import ProductItemDesktop from "../../product/ProductItem/ProductItemDesktop";
import ProductItemMobile from "../../product/ProductItem/ProductItemMobile";

import "./MasonryModal.css";

const titleCase = require("ap-style-title-case");

export default ({ image, onClose}) => {
  if (!image) {
    return null;
  }
  let artistNickName =  image.artist.nickName ? image.artist.nickName : "Pikcha ";  // need to check why artist nickname is null sometimes.
  let imageTitle = image.title? image.title : "";
  let metaDescription = "Collect this limited-edition print of "+ imageTitle +" by "+ artistNickName;

  if (typeof image.dateCreated !== 'undefined' && image.dateCreated) {
    metaDescription += " | created on "+image.dateCreated;
  };

  if (typeof image.location !== 'undefined' && image.location) {
    metaDescription += " | located in "+image.location;
  };
 
  return (
    <>
      <Helmet>
        <title>{titleCase(artistNickName)} | {titleCase(imageTitle)}</title>
        <meta name="description" content={`${metaDescription}`}></meta>
      </Helmet>
      <Desktop>
        <ProductItemDesktop image={image} modal onClose={onClose} />
      </Desktop>
      <Tablet>
        <ProductItemMobile image={image} modal onClose={onClose}/>
      </Tablet>
      <Mobile>
        <ProductItemMobile image={image} modal onClose={onClose}/>
      </Mobile>
    </>
  );
};
