import React, { useState } from "react";
import { connect } from "react-redux";
import DeleteAccountModal from "../../modals/DeleteAccountModal/DeleteAccountModal";
import {
  TypographyBody, TYPOGRAPHY_BODY_SIZE, colors,
  TypographyButton, TYPOGRAPHY_BUTTON_TYPE
} from "@pikcha/typography";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    padding: 0,
  },
  contentMargin: {
    backgroundColor: 'white',
    border: '0',
  },
  accordionRoot: {
    boxShadow: 'none',
    backgroundColor: colors.Neutral.grey20,
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);

const GeneralSettings = ({ account, expandedPanel, handleAccordionChange }) => {
  let emailConfirmed = false;
  if (account && account.user) {
    emailConfirmed = account.user.emailConfirmed;
  }

  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  const handleDeleteAccountModal = (state) => setDeleteAccountModal(state);
  const classes = useStyles();

  return (
    <>
      <Accordion 
        expanded={expandedPanel === 'panel4'} 
        onChange={handleAccordionChange('panel4')} 
        className={classes.accordionRoot} 
        style={{ margin: 0 }} 
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          classes={{ expanded: classes.expandedSummary }}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            General Settings
          </TypographyBody>
        </StyledAccordionSummary>
        <AccordionDetails
          className={classes.contentMargin}>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              padding: '0px 16px 40px !important'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '3em',
                marginBottom: '2em'
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                Delete your account
              </TypographyBody>
              <TypographyButton
                component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                onClick={handleDeleteAccountModal}
              >
                Delete Your Account
              </TypographyButton>
            </div>
          </div>
          <DeleteAccountModal
            open={deleteAccountModal}
            onClose={() => handleDeleteAccountModal(false)}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {})(GeneralSettings);
