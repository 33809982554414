import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import {
  useHistory,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import MasonryGallerySaved
  from "../containers/gallery/MasonryGallerySaved/MasonryGallerySaved";
import {
  PikchaButton,
  PikchaLoader,
} from "../components/baseComponents";
import isDesktopHD from "../components/Responsive/isDesktopHD";
import isDesktopSD from "../components/Responsive/isDesktopSD";
import isTablet from "../components/Responsive/isTablet";
import isMobile from "../components/Responsive/isMobile";
import {
  getSavedImages,
  resetSavedGallery,
} from "../actions/gallerySaved";
import { getPhotos } from "../actions/gallery";
import {
  getFollowingPhotos,
  resetFollowingGallery,
} from "../actions/galleryFollowing";
import {
  openAuthentication,
  openRegistration,
} from "../actions/authModal";
import {
  GALLERY_PAGE_DEFAULT,
  GALLERY_ITEMINPAGE_DEFAULT,
} from "../helpers/constants";
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyDropdown,
  colors,
  TypographySelector
} from "@pikcha/typography";
import { ExpandIcon } from "../components/extraIcons";
import { setAlert } from "../actions/alert";

const Saved = ({
  account,
  gallery,
  auth,
  resetSavedGallery,
  getSavedImages,
  openAuthentication,
  openRegistration,
  setAlert
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();

  const { palette, typography } = useTheme();
  const [sortBy, setSortBy] = useState("RV");
  const [imageType, setImageType] = useState([
    "photography",
    "illustration",
    "digital art",
    "collage",
    "painting"
  ])
  const [allSelected, setAllSelected] = useState(true);
  const [photoGraphy, setPhotoGraphy] = useState(true);
  const [illustration, setIllustration] = useState(true);
  const [digitalArt, setDigitalArt] = useState(true);
  const [collage, setCollage] = useState(true);
  const [painting, setPainting] = useState(true);
  const [mediumValue, setMediumValue] = useState("All");
  const [showMediumDropdown, setShowMediumDropdown] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const onJoin = () => {
    openRegistration(history, location);
  };

  const onLogin = () => {
    openAuthentication(history, location);
  };

  const isUserLoading =
    (auth.isAuthenticated && account.loadingUser) || auth.loading;
  const isGalleryLoading = gallery.loading;
  const hasUserLoggedIn = !!account.user;
  const hasSavedPhotos = gallery.photos && gallery.photos.length > 0;


  useEffect(() => {
    if(imageType.length === 0){
      resetSavedGallery();
      return
    }
    resetSavedGallery();
    getSavedImages(GALLERY_PAGE_DEFAULT, GALLERY_ITEMINPAGE_DEFAULT, sortBy, imageType);
  }, [sortBy, imageType.length]);

  useEffect(() => {
    if(imageType.length === 0){
      setAlert("Please select at lease one image type", "error")
    }
  }, [imageType.length])
  

  const MediumDropdown = () => {
    return (
      <div 
        style={{
          marginTop: "auto",
          width: isOnMobile ? "80%" : "48%",
          position: "relative"
        }}
      >
        <div
          onClick={() => {setShowMediumDropdown(!showMediumDropdown)}}
          style={{
            border: `1px solid ${colors.Neutral.grey30}`,
            borderRadius: 4,
            width: "100%",
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1em",
            cursor: "pointer"
          }}
        >
          {mediumValue}
          <ExpandIcon />
        </div>
        {showMediumDropdown && (
          <div 
            style={{
              position: "absolute",
              left: 0,
              top: 40,
              zIndex: 9,
              backgroundColor: "#FFFFFF",
              border: `1px solid ${colors.Neutral.grey30}`,
              borderRadius: 4,
              width: "100%"
            }}
          >
            <div 
              style={{ 
                width: "100%", 
                height: 40, 
                padding: "1em",
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() => {
                if(!allSelected) {
                  setMediumValue("All");
                  setImageType([
                    "photography",
                    "illustration",
                    "digital art",
                    "collage",
                    "painting"
                  ])
                  setAllSelected(true)
                  setPhotoGraphy(true)
                  setIllustration(true)
                  setDigitalArt(true)
                  setCollage(true)
                  setPainting(true)
                } else {
                  setMediumValue("0 selected");
                  setAllSelected(false)
                  setPhotoGraphy(false)
                  setIllustration(false)
                  setDigitalArt(false)
                  setCollage(false)
                  setPainting(false)
                  setImageType([])
                }
              }}
            >
              <TypographySelector 
                selectorType="checkbox"
                checked={allSelected} 
              />
              All
            </div>
            <div 
              style={{ 
                width: "100%", 
                height: 40,
                padding: "1em",
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() => {
                if(!photoGraphy) {
                  setMediumValue(`${imageType.length + 1} selected`);
                  let list = imageType;
                  list.push("photography");
                  setImageType(list);
                  setPhotoGraphy(true)
                } else {
                  setAllSelected(false)
                  setMediumValue(`${imageType.length - 1} selected`);
                  let list = imageType;
                  let index = imageType.indexOf("photography");
                  if (index > -1) {
                    list.splice(index, 1);
                  }
                  setImageType(list)
                  setPhotoGraphy(false)
                }
              }}
            >
              <TypographySelector 
                selectorType="checkbox"
                checked={photoGraphy} 
              />
              photography
            </div>
            <div 
              style={{ 
                width: "100%", 
                height: 40,
                padding: "1em",
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() => {
                if(!illustration) {
                  setMediumValue(`${imageType.length + 1} selected`);
                  let list = imageType;
                  list.push("illustration");
                  setImageType(list);
                  setIllustration(true)
                } else {
                  setAllSelected(false)
                  setMediumValue(`${imageType.length - 1} selected`);
                  let list = imageType;
                  let index = imageType.indexOf("illustration");
                  if (index > -1) {
                    list.splice(index, 1);
                  }
                  setImageType(list)
                  setIllustration(false)
                }
              }}
            >
              <TypographySelector 
                selectorType="checkbox" 
                checked={illustration}
              />
              illustration
            </div>
            <div 
              style={{ 
                width: "100%", 
                height: 40,
                padding: "1em",
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() => {
                if(!digitalArt) {
                  setMediumValue(`${imageType.length + 1} selected`);
                  let list = imageType;
                  list.push("digitalArt");
                  setImageType(list);
                  setDigitalArt(true)
                } else {
                  setAllSelected(false)
                  setMediumValue(`${imageType.length - 1} selected`);
                  let list = imageType;
                  let index = imageType.indexOf("digital art");
                  if (index > -1) {
                    list.splice(index, 1);
                  }
                  setImageType(list)
                  setDigitalArt(false)
                }
              }}
            >
              <TypographySelector 
                selectorType="checkbox" 
                checked={digitalArt}
              />
              digital art
            </div>
            <div 
              style={{ 
                width: "100%", 
                height: 40,
                padding: "1em",
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() => {
                if(!collage) {
                  setMediumValue(`${imageType.length + 1} selected`);
                  let list = imageType;
                  list.push("collage");
                  setImageType(list);
                  setCollage(true)
                } else {
                  setAllSelected(false)
                  setMediumValue(`${imageType.length - 1} selected`);
                  let list = imageType;
                  let index = imageType.indexOf("collage");
                  if (index > -1) {
                    list.splice(index, 1);
                  }
                  setImageType(list)
                  setCollage(false)
                }
              }}
            >
              <TypographySelector 
                selectorType="checkbox" 
                checked={collage}
              />
              collage
            </div>
            <div 
              style={{ 
                width: "100%", 
                height: 40,
                padding: "1em",
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={() => {
                if(!painting) {
                  setMediumValue(`${imageType.length + 1} selected`);
                  let list = imageType;
                  list.push("painting");
                  setImageType(list);
                  setPainting(true)
                } else {
                  setAllSelected(false)
                  setMediumValue(`${imageType.length - 1} selected`);
                  let list = imageType;
                  let index = imageType.indexOf("painting");
                  if (index > -1) {
                    list.splice(index, 1);
                  }
                  setImageType(list)
                  setPainting(false)
                }
              }}
            >
              <TypographySelector 
                selectorType="checkbox" 
                checked={painting}
              />
              painting
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>Pikcha — Saved Images</title>
      </Helmet>
      <div className="Page-innerContainer pt-4-em main-width-restrict">
        <div
          style={{
            display: "flex",
            flexDirection: isOnMobile ? "column" :"row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "4em",
            marginLeft: isOnDesktopHD
              ? "4em"
              : isOnDesktopSD
                ? "2em"
                : isOnTablet
                  ? "1em"
                  : "0.5em",
            marginRight: isOnDesktopHD
              ? "4em"
              : isOnDesktopSD
                ? "2em"
                : isOnTablet
                  ? "1em"
                  : "0.5em",
            // backgroundColor: palette.grey.light,
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
            borderRadius: "12px 12px 0px 0px",
          }}
        >
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
          >
            Liked artwork
          </TypographySubtitle>
          <div 
            className="flex" 
            style={{
              width: isOnMobile ? "100%" : isOnTablet ? "50%" : "40%", 
              alignItems: isOnMobile ? "center" : "space-between", 
              justifyContent: "space-between",
              flexDirection: isOnMobile ? "column" : "row"
            }}
          >
            <TypographyDropdown
              style={{ width: isOnMobile ? "80%" : "48%", marginBottom: isOnMobile && "1em" }}
              label="Sort by"
              name="Sort by"
              placeholder="Most recent"
              options={[
                { value: "RV", label: "Most recent" },
                { value: "MV", label: "Most views" },
                { value: "MS", label: "Most sales" },
                { value: "PHL", label: "Price High to Low" },
                { value: "PLH", label: "Price Low to High" },
              ]}
              onChange={(val) => {
                setSortBy(val.value)
              }}
            />
            {MediumDropdown()}
          </div>
        </div>
        {hasUserLoggedIn && (
          <MasonryGallerySaved
            inSavedImagePage
            gallery={gallery}
            getPhotos={() => {
              if(gallery.page > 1){
                getSavedImages(gallery.page, gallery.itemInPage, sortBy, imageType)
              }
            }}
            resetGallery={() => resetSavedGallery()}
          />
        )}
        {hasUserLoggedIn && !isGalleryLoading && !hasSavedPhotos && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "48px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                ...{ color: palette.text.primary },
                ...typography.body,
              }}
            >
              <p>You don't have any saved images yet.</p>
              <p>Save images to view or print later!</p>
            </div>
          </div>
        )}
        {!isUserLoading && !hasUserLoggedIn && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "48px",
              textAlign: "start",
            }}
          >
            <div
              style={{
                fontWeight: typography.fontWeightMedium,
                fontSize: typography.standard,
                color: palette.text.secondary,
              }}
            >
              <p>New Users</p>
              <PikchaButton
                onClick={onJoin}
                mainStyle={{
                  marginTop: "1em",
                  width: 200,
                }}
              >
                Join
              </PikchaButton>
            </div>
            <div
              style={{
                marginTop: "2em",
              }}
            >
              <div
                style={{
                  fontWeight: typography.fontWeightMedium,
                  fontSize: typography.standard,
                  color: palette.text.secondary,
                  width: "max-content",
                  marginBottom: "1em",
                }}
              >
                Returning Users
              </div>
              <PikchaButton
                action="octonary"
                onClick={onLogin}
                mainStyle={{
                  width: 200,
                  height: 44
                }}
              >
                Log In
              </PikchaButton>
            </div>
          </div>
        )}
        {isUserLoading && (
          <div
            className="flex-center"
            style={{
              marginTop: "64px",
              width: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: "3 1 0",
            }}
          >
            <div className="flex-center flex-grow-1">
              <PikchaLoader />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  gallery: state.savedImagesReducer,
  account: state.accountReducer,
  auth: state.authReducer,
});

export default connect(mapStateToProps, {
  getPhotos,
  getFollowingPhotos,
  resetFollowingGallery,
  resetSavedGallery,
  getSavedImages,
  openAuthentication,
  openRegistration,
  setAlert
})(Saved);
