// @ts-nocheck

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import defaultTheme from "@pikcha/web-app/src/themes/defaultTheme";
import App from "@pikcha/web-app/src/App";
import { InviteCodeContextProvider } from "@pikcha/web-app/src/contexts/InviteCodeContext";
import store from "@pikcha/web-app/src/store";
import { PIKCHA_INVITE_KEY } from "@pikcha/web-app/src/helpers/constants";

import "./assets/sass/_main.scss";

import "./assets/css/main.css";
import "./assets/css/material-ui-overrides.css";
import "./assets/css/images.css";
import "./assets/css/alignment.css";
import "./assets/css/flexbox.css";
import "./assets/css/spacing.css";
import "./assets/css/sizing.css";
import "@pikcha/web-app/src/assets/css/buttons.css";
import "./assets/css/typography.css";
import "./assets/css/misc.css";
import "./assets/css/css-grid.css";

// @ts-ignore
let applyTheme = createMuiTheme(defaultTheme);

const existingCode =
  parseInt(localStorage.getItem(PIKCHA_INVITE_KEY)) > 6500 &&
  parseInt(localStorage.getItem(PIKCHA_INVITE_KEY)) < 6900;

const Index = () => {
  const [betaInviteCode, setBetaInviteCode] = useState(existingCode);

  const handleBetaInviteCode = (state) => setBetaInviteCode(state);
  const betaInviteCodeContext = {
    betaInviteCode,
    handleBetaInviteCode,
  };

  return (
    <MuiThemeProvider theme={applyTheme}>
      <Provider store={store}>
        <InviteCodeContextProvider
          // @ts-ignore
          value={betaInviteCodeContext}
        >
          <App />
        </InviteCodeContextProvider>
      </Provider>
    </MuiThemeProvider>
  );
};

// https://reactjs.org/blog/2020/10/20/react-v17.html
// In React 17, event delegation occurs to the root node instead
// of the page document.
const rootNode = document.getElementById("root");
ReactDOM.render(<Index />, rootNode);
