import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export enum currencyFormat {
  symbol = 'symbol',
  narrowSymbol = 'narrowSymbol',
  code = 'code',
  name = 'name'
}

export const IntlCurrencyFormatter = ({
                                        value,
                                        localConversion,
                                        targetCurrency,
                                        displayFormat = currencyFormat.narrowSymbol
                                      }: { value: number, localConversion?: boolean, targetCurrency?: string, displayFormat?: string }) => {
  // @ts-ignore
  const userPreferencesReducer = useSelector(state => state.userPreferencesReducer);
  const locale = userPreferencesReducer.currencyOptions.locale;
  const [formattedValue, setFormattedValue] = useState("");

  const formattedCurrency = () => {
    if (localConversion) {
      value *= userPreferencesReducer.fxRate;
    }

    if(value){
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currencyDisplay: displayFormat,
        currency: targetCurrency ? targetCurrency : userPreferencesReducer.currencyOptions.value,
        maximumFractionDigits: 2
      }).format(value);
    } else {
      let currency = (new Intl.NumberFormat(locale, {
        style: 'currency',
        currencyDisplay: displayFormat,
        currency: targetCurrency ? targetCurrency : userPreferencesReducer.currencyOptions.value,
        maximumFractionDigits: 2
      }).format(value)).replace("NaN","");
      return currency;
    }
  };

  useEffect(() => {
    setFormattedValue(formattedCurrency());
  }, [value, userPreferencesReducer.fxRate]);

  return (
    <span>{formattedValue}</span>
  );
};
