import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../../components/Responsive/isDesktopSD";
import isTablet from "../../../components/Responsive/isTablet";
import isMobile from "../../../components/Responsive/isMobile";
import SelectionMasonryColumn from "./SelectionMasonryColumn.jsx";

export default ({
  gallery,
  getPhotos,
  resetGallery,
  isImageSelected,
  onImageSelected,
  onImageDeselected,
  style,
  editingCollectionId
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  const columns = isOnMobile ? 2 : 3;

  useEffect(() => {
    getNextPhotos()
    return () => resetGallery();
  }, []);

  const getNextPhotos = () => {
    getPhotos(gallery.count, gallery.start)
  }
  const separatePhotos = () => {
    const { photos: galleryPhotos, loading } = gallery;

    let photos = galleryPhotos;
    if (loading) {
      for (let i = 0; i < 9; i++) {
        photos = [
          ...photos,
          ...[
            {
              placeholder: true,
            },
          ],
        ];
      }
    }
    const res = [...Array(columns).keys()].map((c) =>
      photos.filter((_, i) => i % columns === c)
    );

    const columnsToMap = [...Array(columns)];
    return columnsToMap.map((column, index) => {
      const isFirstColumn = index === 0;
      const isLastColumn = index + 1 === columnsToMap.length;
      return (
        <SelectionMasonryColumn
          key={index}
          photos={res[index]}
          isImageSelected={isImageSelected}
          onImageSelected={onImageSelected}
          onImageDeselected={onImageDeselected}
          editingCollectionId={editingCollectionId}
          style={{
            flexGrow: 1,
            flexShrink: 2,
            flexBasis: "0%",
            height: "100%",
            display: "flex",
            flexDirection: "column",

            marginLeft: !isFirstColumn
              ? isOnDesktopHD
                ? "0.25em"
                : isOnDesktopSD
                  ? "0.25em"
                  : isOnTablet
                    ? "0.25em"
                    : "0.5em"
              : 0,
            marginRight: !isLastColumn
              ? isOnDesktopHD
                ? "0.25em"
                : isOnDesktopSD
                  ? "0.25em"
                  : isOnTablet
                    ? "0.25em"
                    : "0.5em"
              : 0,
          }}
        />
      );
    });
  };

  return (
    <InfiniteScroll
      dataLength={gallery.photos.length}
      next={() => getNextPhotos()}
      hasMore={gallery.hasMore}
      height={600}
      scrollableTarget="Modal-scrollContainer"
      style={{
        display: "flex",
        flexDirection: "row",
        // overflow: "hidden",
        ...(style || {}),
      }}
    >
      {separatePhotos()}
    </InfiniteScroll>
  );
};
