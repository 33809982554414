import React from "react";
import ContentLoader from "react-content-loader";
import { useTheme } from "@material-ui/core/styles";

export default (props) => {
  const { palette } = useTheme();

  return (
    <div className="flex-center justify-center pt-6-em">
      <ContentLoader
        speed={2}
        width={1200}
        height={600}
        viewBox="0 0 1200 600"
        backgroundColor={palette.grey.lighter}
        foregroundColor={palette.grey.lighter}
        {...props}
      >
        <rect x="477" y="33" rx="2" ry="2" width="248" height="50" />
        <rect x="327" y="229" rx="2" ry="2" width="560" height="363" />
        <rect x="306" y="147" rx="0" ry="0" width="132" height="29" />
        <rect x="458" y="147" rx="0" ry="0" width="132" height="29" />
        <rect x="622" y="147" rx="0" ry="0" width="132" height="29" />
        <rect x="779" y="147" rx="0" ry="0" width="132" height="29" />
      </ContentLoader>
    </div>
  );
};
