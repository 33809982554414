import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ children, style, ...rest }) => {
  const { palette, typography } = useTheme();

  return (
    <div
      className="pr-1-em"
      style={{
        ...{
          color: palette.common.white,
          fontWeight: typography.fontWeightMedium,
          fontSize: typography.small,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
