import React from "react";
import { useTheme } from "@material-ui/core/styles";

import Desktop from "../../components/Responsive/Desktop";
import isDesktop from "../../components/Responsive/isDesktop";
import isTablet from "../../components/Responsive/isTablet";
import Tablet from "../../components/Responsive/Tablet";
import Mobile from "../../components/Responsive/Mobile";

import ArtistRowDesktop from "./ArtistRowDesktop";
import ArtistRowMobile from "./ArtistRowMobile";

const ArtistRow = ({ row, rank, style }) => {
  const { palette } = useTheme();
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  return (
    <div
      style={{
        ...style,
        paddingBottom: "1em",
        paddingRight: isOnDesktop && rank%2 === 0 ? 0 : "0.5em",
        paddingLeft: isOnDesktop && rank%2 === 1 ? 0 : "0.5em",
        width: isOnDesktop ? "50%" : "100%",
      }}
    >
      <Desktop>
        <ArtistRowDesktop row={row} />
      </Desktop>
      <Tablet>
        <ArtistRowDesktop row={row} />
      </Tablet>
      <Mobile>
        <ArtistRowMobile row={row} />
      </Mobile>
    </div>
  );
};

export default ArtistRow;
