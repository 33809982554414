import React from "react";
import { connect } from "react-redux";
import { readyShoppingCartItems } from "../../selectors/shoppingCart";
import Payment from "../../components/checkoutComponents/Payment";

const Step3Mobile = ({
  handlePaymentSubmission,
  onCardFieldChange,
  cardProcessing,
  cardError,
  stripe,
  elements,
}) => {
  return (
    <>
      <div
        style={{
          position: "relative",
          animation: "fadeIn 0.6s",
          background: "white",
          minHeight: 350,
          marginTop: '2em'
        }}
      >
        <Payment
          handlePaymentSubmission={handlePaymentSubmission}
          onCardFieldChange={onCardFieldChange}
          cardError={cardError}
          cardProcessing={cardProcessing}
          stripe={stripe}
          elements={elements}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  readyShoppingCartItems: readyShoppingCartItems(state),
});
export default connect(mapStateToProps, {})(Step3Mobile);
