import React from "react";
import LikeIcon from '../../../components/extraIcons/LikeIcon';
import UnLikeIcon from '../../../components/extraIcons/UnLikeIcon';

import {PikchaIconButton} from "./PikchaIconButton";

export const PikchaLikeButton = ({
                                   saved,
                                   ...rest
                                 }) => {
  return (
    <PikchaIconButton
      size="small"
      icon={!saved ? <UnLikeIcon /> :
        <LikeIcon />}
      {...rest}
    />
  );
}