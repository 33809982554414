import React, { lazy, Suspense } from "react";

import SalesOverTimeLoader from "./SalesOverTImeLoader";
const SalesOverTime = lazy(() => import("./SalesOverTime"));

export default (props) => {
  return (
    <Suspense fallback={<SalesOverTimeLoader/>}>
      <SalesOverTime {...props} />
    </Suspense>
  );
}