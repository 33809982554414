import {
  PROFILE_LOADED,
  PROFILE_LOADING,
  PROFILE_ERROR,
  PROFILE_RESET,
  ARTIST_PHOTOS_LOADED,
  ARTIST_PHOTOS_LOADING,
  ARTIST_PHOTOS_ERROR,
  ARTIST_PHOTOS_RESET,
  PROFILE_UPDATED,
  HANDLE_SAVED_IMAGE,
  PROFILE_ONBOARDING_MODAL,
  FORCE_SIGNATURE_COVER_RERENDER,
  BASE_64_SIGNATURE_STRING,
  FORCE_GALLERY_RERENDER,
  PROFILE_ONBOARDING_INIT,
  ARTIST_PROFILE_INFO_LOADED,
  ARTIST_PROFILE_INFO_LOADING,
  ARTIST_PROFILE_INFO_ERROR,
} from "../actions/types";
import {
  GALLERY_ITEMINPAGE_DEFAULT,
  GALLERY_PAGE_DEFAULT,
} from "../helpers/constants";

const initialState = {
  artist: null,
  artistProfileInfo: null,
  photos: [],
  start: GALLERY_PAGE_DEFAULT,
  count: GALLERY_ITEMINPAGE_DEFAULT,
  profileLoading: true,
  photosLoading: true,
  profileError: null,
  photosError: null,
  hasMore: true,
  showOnboardingModal: false,
  forceSignatureCoverRerender: false,
  forceGalleryRerender: false,
  base64SignatureString: "",
  initOnBoarding: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case PROFILE_UPDATED:
    case PROFILE_LOADED:
      return {
        ...state,
        artist: payload,
        profileLoading: false,
      };
    case PROFILE_LOADING:
      return {
        ...state,
        profileLoading: true,
      };
    case ARTIST_PROFILE_INFO_LOADING:
      return {
        ...state,
        profileLoading: true,
      };
    case ARTIST_PROFILE_INFO_LOADED:
      return {
        ...state,
        artistProfileInfo: payload,
        profileLoading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        profileError: payload,
        profileLoading: false,
      };
    case ARTIST_PROFILE_INFO_ERROR:
      return {
        ...state,
        profileError: payload,
        profileLoading: false,
      };   
    case PROFILE_ONBOARDING_MODAL:
      return {
        ...state,
        showOnboardingModal: payload,
      };
    case PROFILE_RESET:
      return {
        ...state,
        artist: null,
        artistProfileInfo: null,
        profileLoading: true,
        profileError: null,
      };

    case HANDLE_SAVED_IMAGE: {
      const {imageId, saved: isSaved} = payload;
      const photos = state.photos.map((photo) => {
        const {id} = photo;
        if (id === imageId) {
          return {
            ...photo,
            ...{
              isSaved,
            },
          };
        }
        return photo;
      });
      return {
        ...state,
        ...{photos},
      };
    }
    case PROFILE_ONBOARDING_INIT:
      return {
        ...state,
        initOnBoarding: payload,
      };
    case BASE_64_SIGNATURE_STRING:
      return {
        ...state,
        base64SignatureString: payload,
      };
    case FORCE_SIGNATURE_COVER_RERENDER:
      return {
        ...state,
        forceSignatureCoverRerender: payload,
      };
    case ARTIST_PHOTOS_LOADED:
      payload.forEach((d) => {
        const randomised = Math.random() * (30 - 15) + 15;
        d.height = randomised;
      });

      return {
        ...state,
        photos: [...state.photos, ...payload],
        photosLoading: false,
        start: state.start + 1,
        hasMore: payload.length >= state.count,
      };
    case FORCE_GALLERY_RERENDER:
      return {
        ...state,
        forceGalleryRerender: payload,
      };
    case ARTIST_PHOTOS_LOADING:
      return {
        ...state,
        photosLoading: true,
      };
    case ARTIST_PHOTOS_ERROR:
      // !payload.data === "You have reached the end." && console.error(payload);
      return {
        ...state,
        photosLoading: false,
        hasMore: false,
        photosError: payload,
        // hasMore:
        //   (payload &&
        //     payload.data &&
        //     payload.data !== "You have reached the end.") ||
        //   false,
      };
    case ARTIST_PHOTOS_RESET:
      return {
        ...state,
        photos: [],
        start: GALLERY_PAGE_DEFAULT,
        count: GALLERY_ITEMINPAGE_DEFAULT,
        photosLoading: true,
        photosError: null,
        hasMore: true,
      };

    default:
      return state;
  }
};
