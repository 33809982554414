import axios from "axios";
import AuthorizeService from "../auth/AuthorizeService";
import {PIKCHA100_ERROR, PIKCHA100_LOADED, PIKCHA100_LOADING, PIKCHA100_RESET,} from "./types";

// Get Pikcha100
export const getPikcha100 = (count, start, sort, order) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading());

      const token = await AuthorizeService.getAccessToken();

      const res = await axios.post(`${process.env.NODE_ENV === "production" ? "" : "https://pikcha.local:1900"}/v1/search/api/Pikcha100/Images`,
        {
          sortBy: sort,
          page: Math.ceil(Math.max(start, 1) / Math.max(count, 1)),
          itemInPage: count,
          order,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: PIKCHA100_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PIKCHA100_ERROR,
        payload: err.response,
      });
    }
  };
};

// Set Loading
export const setLoading = () => {
  return {type: PIKCHA100_LOADING};
};

// Reset Pikcha Top100
export const resetPikcha100 = () => {
  return (dispatch) =>
    dispatch({
      type: PIKCHA100_RESET,
    });
};
