import React from "react";
import { PikchaTextField } from "../baseComponents";
import { makeStyles } from "@material-ui/styles";
import isMobile from "../../components/Responsive/isMobile";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography"

const useStyles = ({ isOnMobile }) => makeStyles({
  textBox: {
    display: 'flex',
    flexDirection: isOnMobile && 'column'
  },
  rightTextBox: {
    marginLeft: !isOnMobile && "1em",
    width: '100%',
    marginTop: isOnMobile && '2em'
  },
  stateBox: {
    display: "flex",
    marginTop: "1.5em",
  },
  topMargin: {
    marginTop: '2em'
  }
})

export default ({
  tittle,
  address1,
  onAddress1Changed,
  address2,
  onAddress2Changed,
  city,
  onCityChanged,
  postal,
  onPostalChanged,
  state,
  onStateChanged,
  countryState,
  country,
  onCountryChanged
}) => {
  const isOnMobile = isMobile();
  const classes = useStyles({ isOnMobile })();

  return (
    <>
      <TypographyBody
        size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Default_Bold : TYPOGRAPHY_BODY_SIZE.Large_Bold}
        style={{ marginBottom: '3em' }}
      >
        {tittle}
      </TypographyBody>
      <div className={classes.textBox}>
        <PikchaTextField
          required
          label="Address"
          className={!address1 ? "invalid" : ""}
          type="text"
          name="addr1"
          value={address1}
          onChange={onAddress1Changed}
          pattern=".{0,225}"
          title="Max 225 characters"
          style={{ width: '100%', fontSize: '12px' }}
          classes={{ typography: '12px' }}
        />
        <div className={classes.rightTextBox}>
          <PikchaTextField
            label="Apartment, Unit, Suite"
            type="text"
            name="addr2"
            value={address2}
            onChange={onAddress2Changed}
          />
        </div>
      </div>
      <div className={classes.textBox}>
        <PikchaTextField
          required
          isFullWidth
          label="Suburb"
          className={!city ? "invalid" : ""}
          type="text"
          name="shipCity"
          value={city}
          onChange={onCityChanged}
          pattern=".{0,225}"
          title="Max 225 characters"
        />
        <div className={classes.rightTextBox}>
          <PikchaTextField
            required
            isFullWidth
            label="Postcode"
            className={!postal ? "invalid" : ""}
            type="text"
            name="shipPostal"
            value={postal}
            onChange={onPostalChanged}
            title="Enter valid post code"
          />
        </div>
      </div>
      <div className={`${classes.textBox} ${classes.topMargin}`} >
        <PikchaTextField
          required
          isFullWidth
          label="State"
          className={!state ? "invalid" : ""}
          type="text"
          name="shipState"
          value={country === "United States" ? countryState : state}
          disabled={country === "United States" && tittle == 'Delivery address'}
          onChange={onStateChanged}
          pattern=".{0,225}"
          title="Max 225 characters"
        />
        <div className={classes.rightTextBox}>
          <PikchaTextField
            required
            isFullWidth
            label="Country"
            disabled={tittle == 'Delivery address'}
            value={country}
            placeholder={country || ""}
            onChange={onCountryChanged}
          />
        </div>
      </div>
    </>
  );
};
