export const DEFAULT_PREFILL = {
  "country":"Australia-default",
  "country_code":"AU-default",
  "currency_code":"AUD-default",
  "measure_unit":"cm-default",
  "language_long":"English-Australia-default",
  "language_code_639_1":"en-default"
};

  export const LANGUAGE_RANGE = {
  en: 'English',
  zh: 'Mandarin',
  fr: 'French',
  es: 'Spanish',
  ru: 'Russian',
  ja: 'Japanese',
  de: 'German',
  nl: 'Dutch',
};

export const CURRENCY_MEASUREMENT = [
  {"country":"Albania","country_code":"AL","currency_code":"ALL","measure_unit":"cm","language_long":"Albanian - Albania","language_code_639_1":"sq"},
  {"country":"Algeria","country_code":"DZ","currency_code":"DZD","measure_unit":"cm","language_long":"Arabic - Algeria","language_code_639_1":"ar"},
  {"country":"Andorra","country_code":"AS","currency_code":"USD","measure_unit":"cm"},
  {"country":"Angola","country_code":"AO","currency_code":"AOA","measure_unit":"cm","language_code_639_1":"kg"},
  {"country":"Anguilla","country_code":"AI","currency_code":"XCD","measure_unit":"cm"},
  {"country":"Antigua & Barbuda","country_code":"AG","currency_code":"XCD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Argentina","country_code":"AR","currency_code":"ARS","measure_unit":"cm","language_long":"Spanish - Argentina","language_code_639_1":"es"},
  {"country":"Armenia","country_code":"AM","currency_code":"AMD","measure_unit":"cm","language_long":"Armenian - Armenia","language_code_639_1":"hy"},
  {"country":"Aruba","country_code":"AW","currency_code":"AWG","measure_unit":"cm","language_code_639_1":"nl"},
  {"country":"Australia","country_code":"AU","currency_code":"AUD","measure_unit":"cm","language_long":"English - Australia","language_code_639_1":"en"},
  {"country":"Austria","country_code":"AT","currency_code":"EUR","measure_unit":"cm","language_long":"German - Austria","language_code_639_1":"de"},
  {"country":"Azerbaijan","country_code":"AZ","currency_code":"AZN","measure_unit":"cm","language_long":"Azeri (Cyrillic) - Azerbaijan","language_code_639_1":"az"},
  {"country":"Bahamas","country_code":"BS","currency_code":"BSD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Bahrain","country_code":"BH","currency_code":"BHD","measure_unit":"cm","language_long":"Arabic - Bahrain","language_code_639_1":"ar"},
  {"country":"Bangladesh","country_code":"BD","currency_code":"BDT","measure_unit":"cm","language_code_639_1":"bn"},
  {"country":"Barbados","country_code":"BB","currency_code":"BBD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Belgium","country_code":"BE","currency_code":"EUR","measure_unit":"cm","language_long":"Dutch - Belgium","language_code_639_1":"nl"},
  {"country":"Belize","country_code":"BZ","currency_code":"BZD","measure_unit":"cm","language_long":"English - Belize","language_code_639_1":"en"},
  {"country":"Benin","country_code":"BJ","currency_code":"XOF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Bermuda","country_code":"BM","currency_code":"BMD","measure_unit":"cm"},
  {"country":"Bhutan","country_code":"BT","currency_code":"BTN","measure_unit":"cm","language_code_639_1":"dz"},
  {"country":"Bolivia","country_code":"BO","currency_code":"BOB","measure_unit":"cm","language_long":"Spanish - Bolivia","language_code_639_1":"ay"},
  {"country":"Bosnia & Herzegovnia","country_code":"BA","currency_code":"BAM","measure_unit":"cm","language_code_639_1":"bs"},
  {"country":"Botswana","country_code":"BW","currency_code":"BWP","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Brazil","country_code":"BR","currency_code":"BRL","measure_unit":"cm","language_long":"Portuguese - Brazil","language_code_639_1":"pt"},
  {"country":"Brunei","country_code":"BN","currency_code":"BND","measure_unit":"cm","language_long":"Malay - Brunei","language_code_639_1":"ms"},
  {"country":"Bulgaria","country_code":"BG","currency_code":"BGN","measure_unit":"cm","language_long":"Bulgarian - Bulgaria","language_code_639_1":"bg"},
  {"country":"Burkina Faso","country_code":"BF","currency_code":"XOF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Burundi","country_code":"BI","currency_code":"BIF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Cambodia","country_code":"KH","currency_code":"KHR","measure_unit":"cm","language_code_639_1":"km"},
  {"country":"Canada","country_code":"CA","currency_code":"CAD","measure_unit":"cm","language_long":"English - Canada","language_code_639_1":"en"},
  {"country":"Cayman Islands","country_code":"KY","currency_code":"KYD","measure_unit":"cm"},
  {"country":"Central African Republic","country_code":"CF","currency_code":"XAF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Chad","country_code":"TD","currency_code":"XAF","measure_unit":"cm","language_code_639_1":"ar"},
  {"country":"Chile","country_code":"CL","currency_code":"CLF","measure_unit":"cm","language_long":"Spanish - Chile","language_code_639_1":"es"},
  {"country":"China","country_code":"CN","currency_code":"CNY","measure_unit":"cm","language_long":"Chinese - China","language_code_639_1":"zh"},
  {"country":"Christmas Island","country_code":"CX","currency_code":"AUD","measure_unit":"cm"},
  {"country":"Colombia","country_code":"CO","currency_code":"COP","measure_unit":"cm","language_long":"Spanish - Colombia","language_code_639_1":"es"},
  {"country":"Comoros","country_code":"KM","currency_code":"KMF","measure_unit":"cm","language_code_639_1":"ar"},
  {"country":"Congo (Brazzaville)","country_code":"CG","currency_code":"CDF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Cook Islands","country_code":"CK","currency_code":"NZD","measure_unit":"cm"},
  {"country":"Costa Rica","country_code":"CR","currency_code":"CRC","measure_unit":"cm","language_long":"Spanish - Costa Rica","language_code_639_1":"es"},
  {"country":"Croatia","country_code":"HR","currency_code":"HRK","measure_unit":"cm","language_long":"Croatian - Croatia","language_code_639_1":"hr"},
  {"country":"Curacao","country_code":"CW","currency_code":"ANG","measure_unit":"cm","language_code_639_1":"nl"},
  {"country":"Cyprus","country_code":"CY","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"el"},
  {"country":"Czech Republic","country_code":"CZ","currency_code":"CZK","measure_unit":"cm","language_long":"Czech - Czech Republic","language_code_639_1":"cs"},
  {"country":"Denmark","country_code":"DK","currency_code":"DKK","measure_unit":"cm","language_long":"Danish - Denmark","language_code_639_1":"da"},
  {"country":"Djibouti","country_code":"DJ","currency_code":"DJF","measure_unit":"cm","language_code_639_1":"aa"},
  {"country":"Dominica","country_code":"DM","currency_code":"DOP","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Dominican Republic","country_code":"DO","currency_code":"DOP","measure_unit":"cm","language_long":"Spanish - Dominican Republic","language_code_639_1":"es"},
  {"country":"Ecuador","country_code":"EC","currency_code":"USD","measure_unit":"cm","language_long":"Spanish - Ecuador","language_code_639_1":"es"},
  {"country":"Egypt","country_code":"EG","currency_code":"EGP","measure_unit":"cm","language_long":"Arabic - Egypt","language_code_639_1":"ar"},
  {"country":"El Salvador","country_code":"SV","currency_code":"SVC","measure_unit":"cm","language_long":"Spanish - El Salvador","language_code_639_1":"es"},
  {"country":"Equatorial Guinea","country_code":"GQ","currency_code":"XAF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Estonia","country_code":"EE","currency_code":"EUR","measure_unit":"cm","language_long":"Estonian - Estonia","language_code_639_1":"et"},
  {"country":"Falkland Islands (Malvinas)","country_code":"FK","currency_code":"FKP","measure_unit":"cm"},
  {"country":"Faroe Islands","country_code":"FO","currency_code":"DKK","measure_unit":"cm","language_long":"Faroese - Faroe Islands","language_code_639_1":"da"},
  {"country":"Fiji","country_code":"FJ","currency_code":"FJD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Finland","country_code":"FI","currency_code":"EUR","measure_unit":"cm","language_long":"Finnish - Finland","language_code_639_1":"fi"},
  {"country":"France","country_code":"FR","currency_code":"EUR","measure_unit":"cm","language_long":"French - France","language_code_639_1":"fr"},
  {"country":"French Guiana","country_code":"GF","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"French Polynesia","country_code":"PF","currency_code":"XPF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"French Southern Territories","country_code":"TF","currency_code":"EUR","measure_unit":"cm"},
  {"country":"Gabon","country_code":"GA","currency_code":"XAF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Gambia","country_code":"GM","currency_code":"GMD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Georgia","country_code":"GE","currency_code":"GEL","measure_unit":"cm","language_long":"Georgian - Georgia","language_code_639_1":"ka"},
  {"country":"Germany","country_code":"DE","currency_code":"EUR","measure_unit":"cm","language_long":"German - Germany","language_code_639_1":"de"},
  {"country":"Ghana","country_code":"GH","currency_code":"GHS","measure_unit":"cm","language_code_639_1":"ak"},
  {"country":"Gibraltar","country_code":"GI","currency_code":"GIP","measure_unit":"cm"},
  {"country":"Greece","country_code":"GR","currency_code":"EUR","measure_unit":"cm","language_long":"Greek - Greece","language_code_639_1":"el"},
  {"country":"Greenland","country_code":"GL","currency_code":"DKK","measure_unit":"cm"},
  {"country":"Grenada","country_code":"GD","currency_code":"XCD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Guadeloupe","country_code":"GP","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Guam","country_code":"GU","currency_code":"USD","measure_unit":"cm"},
  {"country":"Guatemala","country_code":"GT","currency_code":"GTQ","measure_unit":"cm","language_long":"Spanish - Guatemala","language_code_639_1":"es"},
  {"country":"Guernsey","country_code":"GG","currency_code":"GBP","measure_unit":"cm"},
  {"country":"Guinea","country_code":"GN","currency_code":"GNF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Guinea-Bissau","country_code":"GW","currency_code":"XOF","measure_unit":"cm","language_code_639_1":"pt"},
  {"country":"Guyana","country_code":"GY","currency_code":"GYD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Haiti","country_code":"HT","currency_code":"HTG","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Honduras","country_code":"HN","currency_code":"HNL","measure_unit":"cm","language_long":"Spanish - Honduras","language_code_639_1":"es"},
  {"country":"Hong Kong","country_code":"HK","currency_code":"HKD","measure_unit":"cm","language_long":"Chinese - Hong Kong SAR","language_code_639_1":"zh"},
  {"country":"Hungary","country_code":"HU","currency_code":"HUF","measure_unit":"cm","language_long":"Hungarian - Hungary","language_code_639_1":"hu"},
  {"country":"Iceland","country_code":"IS","currency_code":"ISK","measure_unit":"cm","language_long":"Icelandic - Iceland","language_code_639_1":"is"},
  {"country":"India","country_code":"IN","currency_code":"INR","measure_unit":"cm","language_long":"English - India","language_code_639_1":"bn"},
  {"country":"Indonesia","country_code":"ID","currency_code":"IDR","measure_unit":"cm","language_long":"Indonesian - Indonesia","language_code_639_1":"id"},
  {"country":"Ireland","country_code":"IE","currency_code":"EUR","measure_unit":"cm","language_long":"English - Ireland","language_code_639_1":"en"},
  {"country":"Isle of Man","country_code":"IM","currency_code":"GBP","measure_unit":"cm"},
  {"country":"Israel","country_code":"IL","currency_code":"ILS","measure_unit":"cm","language_long":"Hebrew - Israel","language_code_639_1":"ar"},
  {"country":"Italy","country_code":"IT","currency_code":"EUR","measure_unit":"cm","language_long":"Italian - Italy","language_code_639_1":"fr"},
  {"country":"Jamaica","country_code":"JM","currency_code":"JMD","measure_unit":"cm","language_long":"English - Jamaica","language_code_639_1":"en"},
  {"country":"Japan","country_code":"JP","currency_code":"JPY","measure_unit":"cm","language_long":"Japanese - Japan","language_code_639_1":"ja"},
  {"country":"Jersey","country_code":"JE","currency_code":"GBP","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Jordan","country_code":"JO","currency_code":"JOD","measure_unit":"cm","language_long":"Arabic - Jordan","language_code_639_1":"ar"},
  {"country":"Kenya","country_code":"KE","currency_code":"KES","measure_unit":"cm","language_long":"Swahili - Kenya","language_code_639_1":"en"},
  {"country":"Khazakhstan","country_code":"KZ","currency_code":"KZT","measure_unit":"cm","language_long":"Kazakh - Kazakhstan","language_code_639_1":"kk"},
  {"country":"Kiribati","country_code":"KI","currency_code":"AUD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Kosovo","country_code":"XK","measure_unit":"cm"},
  {"country":"Kuwait","country_code":"KW","currency_code":"KWD","measure_unit":"cm","language_long":"Arabic - Kuwait","language_code_639_1":"ar"},
  {"country":"Kyrgyzstan","country_code":"KG","currency_code":"KGS","measure_unit":"cm","language_code_639_1":"ky"},
  {"country":"Laos","country_code":"LA","currency_code":"LAK","measure_unit":"cm","language_code_639_1":"lo"},
  {"country":"Latvia","country_code":"LV","currency_code":"EUR","measure_unit":"cm","language_long":"Latvian - Latvia","language_code_639_1":"lv"},
  {"country":"Lesotho","country_code":"LS","currency_code":"LSL","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Lichtenstein","country_code":"LI","currency_code":"CHF","measure_unit":"cm","language_long":"German - Liechtenstein","language_code_639_1":"de"},
  {"country":"Lithuania","country_code":"LT","currency_code":"EUR","measure_unit":"cm","language_long":"Lithuanian - Lithuania","language_code_639_1":"lt"},
  {"country":"Luxembourg","country_code":"LU","currency_code":"EUR","measure_unit":"cm","language_long":"French - Luxembourg","language_code_639_1":"fr"},
  {"country":"Macau","country_code":"MO","currency_code":"MOP","measure_unit":"cm","language_long":"Chinese - Macau SAR","language_code_639_1":"zh"},
  {"country":"Macedonia","country_code":"MK","currency_code":"MKD","measure_unit":"cm","language_long":"Macedonian (FYROM)","language_code_639_1":"mk"},
  {"country":"Madagascar","country_code":"MG","currency_code":"MGA","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Malawi","country_code":"MW","currency_code":"MWK","measure_unit":"cm","language_code_639_1":"ny"},
  {"country":"Malaysia","country_code":"MY","currency_code":"MYR","measure_unit":"cm","language_long":"Malay - Malaysia","language_code_639_1":"en"},
  {"country":"Maldives","country_code":"MV","currency_code":"MVR","measure_unit":"cm","language_long":"Dhivehi - Maldives","language_code_639_1":"dv"},
  {"country":"Mali","country_code":"ML","currency_code":"XOF","measure_unit":"cm","language_code_639_1":"bm"},
  {"country":"Malta","country_code":"MT","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Marshall Islands","country_code":"MH","currency_code":"USD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Martinique","country_code":"MQ","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Mauritania","country_code":"MR","currency_code":"MRU","measure_unit":"cm","language_code_639_1":"ar"},
  {"country":"Mauritius","country_code":"MU","currency_code":"MUR","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Mayotte","country_code":"YT","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Mexico","country_code":"MX","currency_code":"MXN","measure_unit":"cm","language_long":"Spanish - Mexico","language_code_639_1":"es"},
  {"country":"Micronesia","country_code":"FM","currency_code":"USD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Moldova","country_code":"MD","currency_code":"MDL","measure_unit":"cm","language_code_639_1":"ro"},
  {"country":"Monaco","country_code":"MC","currency_code":"EUR","measure_unit":"cm","language_long":"French - Monaco","language_code_639_1":"fr"},
  {"country":"Mongolia","country_code":"MN","currency_code":"MNT","measure_unit":"cm","language_long":"Mongolian - Mongolia","language_code_639_1":"mn"},
  {"country":"Montenegro","country_code":"ME","currency_code":"EUR","measure_unit":"cm"},
  {"country":"Montserrat","country_code":"MS","currency_code":"XCD","measure_unit":"cm"},
  {"country":"Morocco","country_code":"MA","currency_code":"MAD","measure_unit":"cm","language_long":"Arabic - Morocco","language_code_639_1":"ar"},
  {"country":"Mozambique","country_code":"MZ","currency_code":"MZN","measure_unit":"cm","language_code_639_1":"pt"},
  {"country":"Namibia","country_code":"NA","currency_code":"NAD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Nepal","country_code":"NP","currency_code":"NPR","measure_unit":"cm","language_code_639_1":"ne"},
  {"country":"Netherlands","country_code":"NL","currency_code":"EUR","measure_unit":"cm","language_long":"Dutch - The Netherlands","language_code_639_1":"nl"},
  {"country":"New Caledonia","country_code":"NC","currency_code":"XPF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"New Zealand","country_code":"NZ","currency_code":"NZD","measure_unit":"cm","language_long":"English - New Zealand","language_code_639_1":"en"},
  {"country":"Nicaragua","country_code":"NI","currency_code":"NIO","measure_unit":"cm","language_long":"Spanish - Nicaragua","language_code_639_1":"es"},
  {"country":"Niger","country_code":"NE","currency_code":"NGN","measure_unit":"cm","language_code_639_1":"ar"},
  {"country":"Nigeria","country_code":"NG","currency_code":"NGN","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Niue","country_code":"NU","currency_code":"NZD","measure_unit":"cm"},
  {"country":"Norfolk Island","country_code":"NF","currency_code":"AUD","measure_unit":"cm"},
  {"country":"Norway","country_code":"NO","currency_code":"NOK","measure_unit":"cm","language_long":"Norwegian (Bokmål) - Norway","language_code_639_1":"nb"},
  {"country":"Oman","country_code":"OM","currency_code":"OMR","measure_unit":"cm","language_long":"Arabic - Oman","language_code_639_1":"ar"},
  {"country":"Palau","country_code":"PW","currency_code":"USD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Panama","country_code":"PA","currency_code":"PAB","measure_unit":"cm","language_long":"Spanish - Panama","language_code_639_1":"es"},
  {"country":"Papua new Guinea","country_code":"PG","currency_code":"PGK","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Peru","country_code":"PE","currency_code":"PEN","measure_unit":"cm","language_long":"Spanish - Peru","language_code_639_1":"ay"},
  {"country":"Philippines","country_code":"PH","currency_code":"PHP","measure_unit":"cm","language_long":"English - Philippines","language_code_639_1":"en"},
  {"country":"Pitcairn","country_code":"PN","currency_code":"NZD","measure_unit":"cm"},
  {"country":"Poland","country_code":"PL","currency_code":"PLN","measure_unit":"cm","language_long":"Polish - Poland","language_code_639_1":"pl"},
  {"country":"Portugal","country_code":"PT","currency_code":"EUR","measure_unit":"cm","language_long":"Portuguese - Portugal","language_code_639_1":"pt"},
  {"country":"Puerto Rico","country_code":"PT","currency_code":"EUR","measure_unit":"cm","language_long":"Portuguese - Portugal","language_code_639_1":"pt"},
  {"country":"Qatar","country_code":"QA","currency_code":"QAR","measure_unit":"cm","language_long":"Arabic - Qatar","language_code_639_1":"ar"},
  {"country":"Reunion","country_code":"RE","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Romania","country_code":"RO","currency_code":"RON","measure_unit":"cm","language_long":"Romanian - Romania","language_code_639_1":"ro"},
  {"country":"Russia","country_code":"RU","currency_code":"RUB","measure_unit":"cm","language_long":"Russian - Russia","language_code_639_1":"ru"},
  {"country":"Rwanda","country_code":"RW","currency_code":"RWF","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Samoa","country_code":"WS","currency_code":"USD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"San Marino","country_code":"SM","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"it"},
  {"country":"Sao Tome & Principe","country_code":"ST","currency_code":"STN","measure_unit":"cm","language_code_639_1":"pt"},
  {"country":"Saudi Arabia","country_code":"SA","currency_code":"SAR","measure_unit":"cm","language_long":"Arabic - Saudi Arabia","language_code_639_1":"ar"},
  {"country":"Senegal","country_code":"SN","currency_code":"XOF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Serbia","country_code":"RS","currency_code":"RSD","measure_unit":"cm","language_long":"Serbian (Cyrillic) - Serbia","language_code_639_1":"sr"},
  {"country":"Seychelles","country_code":"SC","currency_code":"SCR","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Sierra Leone","country_code":"SL","currency_code":"SLL","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Singapore","country_code":"SG","currency_code":"SGD","measure_unit":"cm","language_long":"Chinese - Singapore","language_code_639_1":"zh"},
  {"country":"Slovakia","country_code":"SK","currency_code":"EUR","measure_unit":"cm","language_long":"Slovak - Slovakia","language_code_639_1":"cs"},
  {"country":"Slovenia","country_code":"SI","currency_code":"EUR","measure_unit":"cm","language_long":"Slovenian - Slovenia","language_code_639_1":"it"},
  {"country":"Solomon Islands","country_code":"SB","currency_code":"SBD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"South Africa","country_code":"ZA","currency_code":"ZAR","measure_unit":"cm","language_long":"Afrikaans - South Africa","language_code_639_1":"af"},
  {"country":"South Korea","country_code":"KR","currency_code":"KPW","measure_unit":"cm","language_long":"Korean - Korea","language_code_639_1":"ko"},
  {"country":"Spain","country_code":"ES","currency_code":"EUR","measure_unit":"cm","language_long":"Basque - Basque","language_code_639_1":"es"},
  {"country":"Sri Lanka","country_code":"LK","currency_code":"LKR","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"St Barthelemy","country_code":"BL","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"St Helena","country_code":"SH","currency_code":"SHP","measure_unit":"cm"},
  {"country":"St Kitts & Nevis","country_code":"KN","currency_code":"XCD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"St Lucia","country_code":"LC","currency_code":"XCD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"St Pierre & Miquelon","country_code":"PM","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"St Vincent & the Grenadines","country_code":"VC","currency_code":"XCD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Suriname","country_code":"SR","currency_code":"SRD","measure_unit":"cm","language_code_639_1":"nl"},
  {"country":"Svalbard","country_code":"SJ","currency_code":"NOK","measure_unit":"cm"},
  {"country":"Swaziland","country_code":"SZ","currency_code":"SZL","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Sweden","country_code":"SE","currency_code":"SEK","measure_unit":"cm","language_long":"Swedish - Sweden","language_code_639_1":"sv"},
  {"country":"Switzerland","country_code":"CH","currency_code":"CHE","measure_unit":"cm","language_long":"French - Switzerland","language_code_639_1":"fr"},
  {"country":"Taiwan","country_code":"TW","currency_code":"TWD","measure_unit":"cm","language_long":"Chinese - Taiwan","language_code_639_1":"zh"},
  {"country":"Tajikistan","country_code":"TJ","currency_code":"TJS","measure_unit":"cm","language_code_639_1":"fa"},
  {"country":"Tanzinia","country_code":"TZ","currency_code":"TZS","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Thailand","country_code":"TH","currency_code":"THB","measure_unit":"cm","language_long":"Thai - Thailand","language_code_639_1":"th"},
  {"country":"Timor-Leste","country_code":"TL","currency_code":"USD","measure_unit":"cm","language_code_639_1":"pt"},
  {"country":"Togo","country_code":"TG","currency_code":"XOF","measure_unit":"cm","language_code_639_1":"ee"},
  {"country":"Tonga","country_code":"TO","currency_code":"TOP","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Trinidad & Tobago","country_code":"TT","currency_code":"TTD","measure_unit":"cm","language_long":"English - Trinidad and Tobago","language_code_639_1":"en"},
  {"country":"Tunisia","country_code":"TN","currency_code":"TND","measure_unit":"cm","language_long":"Arabic - Tunisia","language_code_639_1":"ar"},
  {"country":"Turkmenistan","country_code":"TM","currency_code":"TMT","measure_unit":"cm","language_code_639_1":"tk"},
  {"country":"Turkey","country_code":"TR","currency_code":"TRY","measure_unit":"cm","language_long":"Turkish - Turkey","language_code_639_1":"tr"},
  {"country":"Turks & Caicos Island","country_code":"TC","currency_code":"USD","measure_unit":"cm"},
  {"country":"Tuvalu","country_code":"TV","currency_code":"AUD","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Uganada","country_code":"UG","currency_code":"UGX","measure_unit":"cm","language_code_639_1":"en"},
  {"country":"Ukraine","country_code":"UA","currency_code":"UAH","measure_unit":"cm","language_long":"Ukrainian - Ukraine","language_code_639_1":"uk"},
  {"country":"United Arab Emirates","country_code":"AE","currency_code":"AED","measure_unit":"cm","language_long":"Arabic - United Arab Emirates","language_code_639_1":"ar"},
  {"country":"United Kingdom","country_code":"GB","currency_code":"GBP","measure_unit":"cm","language_long":"English - United Kingdom","language_code_639_1":"en"},
  {"country":"United States","country_code":"US","currency_code":"USD","measure_unit":"cm","language_long":"English - United States","language_code_639_1":"en"},
  {"country":"Uruguay","country_code":"UY","currency_code":"UYI","measure_unit":"cm","language_long":"Spanish - Uruguay","language_code_639_1":"es"},
  {"country":"Uzbekistan","country_code":"UZ","currency_code":"UZS","measure_unit":"cm","language_long":"Uzbek (Cyrillic) - Uzbekistan","language_code_639_1":"uz"},
  {"country":"Vaitcan City","country_code":"VA","currency_code":"EUR","measure_unit":"cm","language_code_639_1":"it"},
  {"country":"Vanuatu","country_code":"VU","currency_code":"VUV","measure_unit":"cm","language_code_639_1":"bi"},
  {"country":"Vietnam","country_code":"VN","currency_code":"VND","measure_unit":"cm","language_long":"Vietnamese - Vietnam","language_code_639_1":"vi"},
  {"country":"Virgin Islands (British)","country_code":"VG","currency_code":"USD","measure_unit":"cm"},
  {"country":"Virgin Islands (US)","country_code":"VI","currency_code":"USD","measure_unit":"cm"},
  {"country":"Wallis & Futuna","country_code":"WF","currency_code":"XPF","measure_unit":"cm","language_code_639_1":"fr"},
  {"country":"Zambia","country_code":"ZM","currency_code":"ZMW","measure_unit":"cm","language_code_639_1":"en"}
];
