import {
  HANDLE_REGISTER_FIRST_NAME,
  HANDLE_REGISTER_LAST_NAME,
  HANDLE_REGISTER_LOADING_STATUS,
  HANDLE_REGISTER_PASSWORD,
  HANDLE_REGISTER_USERNAME,
} from '../actions/types';

const initialState = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HANDLE_REGISTER_FIRST_NAME:
      return {
        ...state,
        firstName: payload,
      };
    case HANDLE_REGISTER_LAST_NAME:
      return {
        ...state,
        lastName: payload,
      };
    case HANDLE_REGISTER_USERNAME:
      return {
        ...state,
        username: payload,
      };
    case HANDLE_REGISTER_LOADING_STATUS:
      return {
        ...state,
        loading: payload,
      };
    case HANDLE_REGISTER_PASSWORD:
      return {
        ...state,
        password: payload,
      };
    default:
      return state;
  }
};
