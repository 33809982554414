import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles(({ palette, typography }) => ({
  root: ({ badgeContent }) => ({
    "& > .MuiBadge-anchorOriginTopRightRectangle": {
      // https://github.com/mui-org/material-ui/issues/18472
      // Necessary hack to hide the badge when the number is 0.
      display: badgeContent === null || badgeContent === 0 ? "none" : "flex",
      transform: "scale(1) translate(47%, -30%)",
      height: 18,
      padding: 6,
      backgroundColor: palette.error.main,
      width: 18,
      color: palette.common.white,
      minWidth: 0,
      fontSize: typography.smallest,
      fontWeight: typography.fontWeightBold,
    },
  }),
}));

export const PikchaBadge = ({ children, badgeContent, ...rest }) => {
  const classes = useStyles({ badgeContent });

  return (
    <Badge className={classes.root} badgeContent={badgeContent} {...rest}>
      {children}
    </Badge>
  );
};
