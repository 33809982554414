import React from "react";
import {PikchaIconButton} from "../../../components/baseComponents";
import PhotoSharingModal
  from "../../modals/PhotoSharingModal/PhotoSharingModal";
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded';

export const ProductItemShareButton = ({
                                         image,
                                         isSharingModalOpen,
                                         onCloseSharingModal,
                                         onOpenSharingModal
                                       }) => {
  return (
    <>
      <div
        style={{
          alignSelf: "flex-end",
        }}
      >
        <PikchaIconButton
          onClick={onOpenSharingModal}
          size="small"
          icon={<ReplyRoundedIcon alt="Share Icon" />   }
          className="rotate-item"
        />
      </div>
      <PhotoSharingModal
        image={image}
        open={isSharingModalOpen}
        onClose={onCloseSharingModal}
      />
    </>
  );
}
