import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({
  children,
  className = "",
  style = {},
  fontWeightStandard = null,
  ...rest
}) => {
  const { palette, typography } = useTheme();

  return (
    <label
      className={`${className} pt-half-em no-select flex-center`}
      style={{
        color: "rgb(117, 117, 117)",
        overflow: "hidden",
        fontSize: "0.75rem",
        maxWidth: "100%",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 600,
        lineHeight: "1rem",
        whiteSpace: "nowrap",
        marginBottom: "0.25rem",
        textOverflow: "ellipsis",
        transform: "translateY(-1.1rem) !important",
        ...style,
      }}
      {...rest}
    >
      {children}
    </label>
  );
};
