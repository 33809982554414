const SVG_ROOT_ID = "svg-root";

//const COLOR_BLACK = "#000";
const COLOR_WHITE = "#FFF";

const SVG_PATH = "path";
const SVG_CIRCLE = "circle";
const SVG_FILL = "fill";
const SVG_STROKE = "stroke";

const EXTRA_PADDING_OFFSET_DRAWN_SIG = 16;

const EXTRA_PADDING_OFFSET_TYPED_SIG = 4;

let state = {
  svgNodeExistsInDOM: false,
  isBackwardHorizontally: false,
  isBackwardVertically: false,

  thickness: 0
};

const pipe = (...fns) => (x) => fns.reduce((y, f) => f(y), x);

// This step is only needed when converting drawn signatures from the canvas
// to an SVG.
export const getViewBoxCropData = (canvasNode) =>
  pipe(getBase64String, getCropData)(canvasNode);

// Start the conversion process for typed signatures here.
export const getBase64String = (drawnSignaturePadNode) =>
  drawnSignaturePadNode.toDataURL("image/svg+xml");

export const setSignatureColor = (signaturePreviewStrokePath, color) => {
  if (signaturePreviewStrokePath.length > 0 && color) {
    for (let i = 0; i < signaturePreviewStrokePath.length; i++) {
      if(window.getComputedStyle(signaturePreviewStrokePath[i]).getPropertyValue("fill")!=="none"){
          signaturePreviewStrokePath[i].style.fill = color;
      } else {
          signaturePreviewStrokePath[i].style.stroke = color;
      }
    }
  }
}

export const setSvgColor = (svgString, color) => {
//  const svg = ``
  const svg = `${svgString}`;
  const FILL_ATTRIBUTE = "fill";
  const STROKE_ATTRIBUTE = "stroke"
  const svgNode = new DOMParser().parseFromString(svg,'text/html');
  const pathList = svgNode.getElementsByTagName("path");
  for(let i = 0; i < pathList.length; i++){
    const hasFillAttribute =
      (pathList[i].hasAttribute(FILL_ATTRIBUTE) && pathList[i].getAttribute("fill")!=="none") || (
      window
        .getComputedStyle(pathList[i])
        .getPropertyValue(FILL_ATTRIBUTE) !== "none" &&       
      window
        .getComputedStyle(pathList[i])
        .getPropertyValue(FILL_ATTRIBUTE)) || (
          pathList[i].style.fill && pathList[i].style.fill!=="none" 
        );
    
    const hasStrokeAttribute =
      (pathList[i].hasAttribute(STROKE_ATTRIBUTE) && pathList[i].getAttribute("stroke")!=="none") || (
      window
        .getComputedStyle(pathList[i])
        .getPropertyValue(STROKE_ATTRIBUTE) !== "none" &&       
      window
          .getComputedStyle(pathList[i])
          .getPropertyValue(FILL_ATTRIBUTE)) || (
            pathList[i].style.stroke && pathList[i].style.stroke!=="none" 
          );

    if(hasStrokeAttribute && !hasFillAttribute){
      pathList[i].removeAttribute("stroke");
      pathList[i].style.stroke=color;
    }
    if(hasFillAttribute && !hasStrokeAttribute) {
      pathList[i].removeAttribute("fill");
      pathList[i].style.fill=color;
    } 
    if(!hasFillAttribute && !hasStrokeAttribute){
      pathList[i].style.fill=color;
      pathList[i].style.stroke=color;
    }
    if(hasFillAttribute && hasStrokeAttribute){
      pathList[i].removeAttribute("stroke");
      pathList[i].removeAttribute("fill");
      pathList[i].style.fill=color;
      pathList[i].style.stroke=color;
    }
  }
  var s = new XMLSerializer();
  var str = s.serializeToString(svgNode.getElementsByTagName("svg")[0]);
  return str;
}

export const getCropData = (data, isSVGString, selectedSignatureColor, brushStroke) =>
  new Promise((resolve) => {
    if (brushStroke) {
      state = {
        ...state,
        thickness: brushStroke
      }
    }

    // Check if this is the first invocation; if so, append an empty div to
    // the DOM which we can attach our svg to and access the browser's svg
    // methods.
    if (!document.getElementById(SVG_ROOT_ID)) {
      const svgRoot = document.createElement("div");
      svgRoot.setAttribute("ID", SVG_ROOT_ID);

      document.body.appendChild(svgRoot);
    }
    if (isSVGString) {
      return resolve(data, selectedSignatureColor);
    }

    const xhr = new XMLHttpRequest();
    xhr.open("GET", data);

    xhr.onload = async () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        await resolve(xhr.response, selectedSignatureColor, brushStroke);
      }
    };
    xhr.send(null);
  })
    .then((svgXmlString, selectedSignatureColor, brushStroke) => {
      const domParser = new DOMParser();
      const svgNode = domParser.parseFromString(svgXmlString, "image/svg+xml");
      const svgRoot = document.getElementById(SVG_ROOT_ID);

      if (state.svgNodeExistsInDOM) {
        svgRoot.removeChild(svgRoot.firstChild);
      }

      svgRoot.appendChild(document.importNode(svgNode.documentElement, true));

      state = {
        ...state,
        svgNodeExistsInDOM: true,
      };

      const {x, y, width, height} = svgRoot.firstChild.getBBox();

      // Issue is `isBackwardHorizontally` is calculated incorrectly.
      //state = {
      //  ...state,
      //  isBackwardHorizontally: false,
      //  isBackwardVertically: false
      //};

      const {thickness, isBackwardVertically, isBackwardHorizontally} = state;

      // N.b. We add a small margin to the rendered SVG signature.
      if (isSVGString) {
        return {
          minX: x - EXTRA_PADDING_OFFSET_TYPED_SIG - thickness,
          minY: y - EXTRA_PADDING_OFFSET_TYPED_SIG - thickness,
          width: width + (EXTRA_PADDING_OFFSET_TYPED_SIG + thickness) * 2,
          height: height + (EXTRA_PADDING_OFFSET_TYPED_SIG + thickness) * 2,
        }
      }

      return {
        minX: isBackwardHorizontally
          ? x - width + EXTRA_PADDING_OFFSET_DRAWN_SIG + brushStroke
          : x - EXTRA_PADDING_OFFSET_DRAWN_SIG + brushStroke,
        minY: isBackwardVertically
          ? y - height + EXTRA_PADDING_OFFSET_DRAWN_SIG + brushStroke
          : y - EXTRA_PADDING_OFFSET_DRAWN_SIG + brushStroke,
        width: width + (EXTRA_PADDING_OFFSET_DRAWN_SIG + brushStroke) * 2,
        height: height + (EXTRA_PADDING_OFFSET_DRAWN_SIG + brushStroke) * 2,
      };
    })
    .then(({minX, minY, height, width}) => {
      const svg = document.getElementById(SVG_ROOT_ID).firstChild;
      if(svg){
        svg.setAttribute("viewBox", `${minX} ${minY} ${width} ${height}`);
        svg.setAttribute("width", width);
        svg.setAttribute("height", height);

        return getOrgSigAndInvSig(svg, selectedSignatureColor, brushStroke);
      }
    });

export const getOrgSigAndInvSig = (svg, selectedSignatureColor, brushStroke) => {
  const serializer = new XMLSerializer();

  let data = {
    orgSigSVG: serializer.serializeToString(svg),
    invSigSVG: undefined,
  };

  // Start the process to invert the svg color before serializing it to a
  // string.
  const innerPaths = svg.getElementsByTagName(SVG_PATH);
  // Cache the length property to avoid recalculating on every iteration.
  const pathsLength = innerPaths.length;

  for (let i = 0; i < pathsLength; i++) {
    if(window.getComputedStyle(innerPaths[i]).getPropertyValue("fill")!=="none") {
      innerPaths[i].style.fill = selectedSignatureColor;
    } else {
      innerPaths[i].style.fill = null;
    }
    // innerPaths[i].setAttribute(SVG_FILL, selectedSignatureColor);
    innerPaths[i].setAttribute(SVG_STROKE, selectedSignatureColor);
    innerPaths[i].style.stroke = selectedSignatureColor;
  }

  const innerCircles = svg.getElementsByTagName(SVG_CIRCLE);
  const circlesLength = innerCircles.length;

  // Non-contiguous canvas marks tend to be rendered as circle elements
  // instead of strokes, so we handle them here.
  if (innerCircles) {
    for (let i = 0; i < circlesLength; i++) {
      innerCircles[i].setAttribute(SVG_FILL, "none");
      // innerCircles[i].setAttribute(SVG_FILL, selectedSignatureColor);
      innerCircles[i].setAttribute(SVG_STROKE, selectedSignatureColor);
    }
  }

  data = {
    ...data,
    invSigSVG: serializer.serializeToString(svg),
  };
  const svgRoot = document.getElementById(SVG_ROOT_ID);
  const svgElement = svgRoot.firstChild;

  // Strip the cloned SVG from the dom.
  if (svgElement) {
    state = {
      ...state,
      svgNodeExistsInDOM: false,
    };

    svgRoot.removeChild(svgElement);
  }

  return data;
};
