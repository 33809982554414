import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
    const { palette } = useTheme();

    return (
        <svg 
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <path d="M17,7h-3c-0.55,0-1,0.45-1,1s0.45,1,1,1h3c1.65,0,3,1.35,3,3s-1.35,3-3,3h-3c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h3 c2.76,0,5-2.24,5-5S19.76,7,17,7z M8,12c0,0.55,0.45,1,1,1h6c0.55,0,1-0.45,1-1s-0.45-1-1-1H9C8.45,11,8,11.45,8,12z M10,15H7 c-1.65,0-3-1.35-3-3s1.35-3,3-3h3c0.55,0,1-0.45,1-1s-0.45-1-1-1H7c-2.76,0-5,2.24-5,5s2.24,5,5,5h3c0.55,0,1-0.45,1-1 C11,15.45,10.55,15,10,15z"
                fill={color || palette.grey.medium}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
