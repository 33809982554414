import React, { useRef, useEffect } from "react";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const PikchaSlider = withStyles(({ palette, shape }) => ({
  root: {
    padding: "12px 0",
    color: "black",
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "black",
    border: "1px solid white",
    marginTop: "-3px",
    // marginLeft: -10,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 10,
    borderRadius: shape.borderRadius,
  },
  rail: {
    height: 10,
    borderRadius: shape.borderRadius,
    backgroundColor: palette.grey.medium,
  },
}))((props) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    sliderRef.current.oncontextmenu = (e) => e.preventDefault();
  }, []);

  return <Slider ref={sliderRef} {...props} />;
});

export default PikchaSlider;
