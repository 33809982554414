import React, { useEffect, useState, useCallback } from 'react';
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  colors
} from "@pikcha/typography";
import axios from "axios";
import { 
  PikchaDropdown, 
  TagsPicker, 
  PikchaCheckbox, 
  PikchaReusableTabsGroup, 
  PikchaReusableTab, 
  PikchaLoader
} from '../../components/baseComponents';
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { makeStyles } from '@material-ui/styles';
import { PIKCHA_CDN } from "../../helpers/constants";

const useStyles = makeStyles(() => ({
  fieldMargin: {
    marginTop: '1.5em'
  },
  checkbox: {
    paddingTop: '0px',
    marginTop: '-3px'
  },
  btnStyle: {
    width: "100%",
    marginTop: '1.5em'
  }
}))

const Step5 = ({ 
  imageDetails, 
  setAlert, 
  handleImageDetails, 
  handleNextStep, 
  setImageDetails, 
  setProductTypeIds,
  setAllProductLines,
  heroShotsDetails,
  heroShotsPending,
  selectedHeroShots,
  setSelectedHeroShots
}) => {
  const classes = useStyles();

  const [tags, setTags] = useState(imageDetails.tags);
  const [thumbNailMaterial, setThumbNailMaterial] = useState("Paper")
  const [artworkType, setArtworkType] = useState({
    label: null,
    value: null,
  });
  const [allImageTypes, setAllImageTypes] = useState([]);
  const [selectedHeroShotThumb, setSelectedHeroShotThumb] = useState("");

  const handleArtworkChange = (label, value) =>
    setArtworkType({
      label: label,
      value: value,
    });

  useEffect(() => {
    axios({
      method: "GET",
      url: `api/image/prerequisites`,
      data: null,
    })
      .then((cat) => {
        const types = [];
        const productLines = [];

        cat.data.types.forEach((type) =>
          types.push({
            label: type.name,
            value: type,
          })
        );
        cat.data.productLines.forEach((product) => {
          productLines.push({ label: product.type, value: product });
        });
        setAllImageTypes(types);
        setArtworkType(cat.data.types[0].id);
        setAllProductLines(productLines);
        setProductTypeIds(
          [productLines[0].value.id, productLines[1].value.id]
        );
      });
  }, []);

  useEffect(() => {
    handleImageDetails('tags', tags)
  }, [tags]);

  useEffect(() => {
    if(!selectedHeroShots){
      return
    }

    const findKey = (data, value, compare = (a, b) => a === b) => {
      return Object.keys(data).find(k => compare(data[k], value))
    }

    let render = "";
    let key = "";

    key = findKey(heroShotsDetails.paperRender, selectedHeroShots);
    render = "paperRender";

    if(!key){
      key = findKey(heroShotsDetails.canvasRender, selectedHeroShots);
      render = "canvasRender";
    }
    key = key + "96px";
    setSelectedHeroShotThumb(heroShotsDetails[render][key]);
  }, [heroShotsDetails, selectedHeroShots]);

  const mappingHeroShots = (item) => {
    const findKey = (data, value, compare = (a, b) => a === b) => {
      return Object.keys(data).find(k => compare(data[k], value))
    }

    let render = "";
    let key = "";

    key = findKey(heroShotsDetails.paperRender, item);
    render = "paperRender";

    if(!key){
      key = findKey(heroShotsDetails.canvasRender, item);
      render = "canvasRender";
    }

    key = key.replace("96px", "");
    setSelectedHeroShots(heroShotsDetails[render][key]);
  }

  const handleTagAdd = (tag, value) => {
    if (value.length === 10) {
      setAlert("A maximum of 10 tags are allowed", "error");
      return;
    }
    if (tag.startsWith("#")) {
      tag = tag.substring(1);
    }
    setTags([...tags, tag.trim()]);
  };

  const handleTagDelete = (_, index) => {
    tags.splice(index, 1);
    setTags(tags);
  };

  const renderThumbnails = useCallback(
    () => {
      if(thumbNailMaterial === "Paper"){
        const paperKeyList = Object.keys(heroShotsDetails.paperRender || {}).filter((item) => {
          return item.indexOf("Back") === -1 && item.indexOf("96") !== -1 
        })
        const paperValueList = [];
        paperKeyList.map((item) => {
          paperValueList.push(heroShotsDetails.paperRender[item])
        })

        const paperValueHolder = [1, 2, 3, 4];
        return (
          <div
            style={{
              padding: "0 0.75em"
            }}
          >
            {!heroShotsPending ? (
              <div 
                className="full-width flex mt-1-em"
                style={{
                  justifyContent: "space-between",
                }}
              >
                {paperValueList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if(item === selectedHeroShotThumb){
                        return
                      }
                      setSelectedHeroShotThumb(item);
                      mappingHeroShots(item);
                    }}
                    style={{
                      cursor: "pointer",
                      width: "23%",
                      paddingBottom: "23%",
                      backgroundColor: colors.Neutral.grey20,
                      backgroundImage: `url(${PIKCHA_CDN}/${item})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      boxShadow: item === selectedHeroShotThumb && `0px 0px 4px 2px ${colors.Primary.primary90}`,
                      borderRadius: 5
                    }}
                  />
                ))}
              </div>
            ) : (
              <div 
                className="full-width 1111 flex mt-1-em"
                style={{
                  justifyContent: "space-between",
                }}
              >
                {paperValueHolder.map((_, index) => (
                  <div
                    key={index}
                    style={{
                      width: "23%",
                      paddingBottom: "23%",
                      backgroundColor: colors.Neutral.grey20,
                      borderRadius: 5,
                      position: "relative"
                    }}
                  >
                    <div 
                      className='absolute'
                      style={{
                        top: "50%",
                        left: "50%"
                      }}
                    >
                      <PikchaLoader />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )
      }

      if(thumbNailMaterial === "Canvas"){
        const canvasKeyList = Object.keys(heroShotsDetails.canvasRender || {}).filter((item) => {
          return item.indexOf("Back") === -1 && item.indexOf("96") !== -1 
        })
        const canvasValueList = [];
        canvasKeyList.map((item) => {
          canvasValueList.push(heroShotsDetails.canvasRender[item])
        })
        const canvasValueHolder = [1, 2, 3, 4];

        return (
          <div
            style={{
              padding: "0 0.75em"
            }}
          >
            {!heroShotsPending ? (
              <div 
                className="full-width flex mt-1-em"
                style={{
                  justifyContent: "space-between",
                }}
              >
                {canvasValueList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if(item === selectedHeroShotThumb){
                        return
                      }
                      setSelectedHeroShotThumb(item);
                      mappingHeroShots(item);
                    }}
                    style={{
                      cursor: "pointer",
                      width: "23%",
                      paddingBottom: "23%",
                      backgroundColor: colors.Neutral.grey20,
                      backgroundImage: `url(${PIKCHA_CDN}/${item})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      borderRadius: 5,
                      boxShadow: item === selectedHeroShotThumb && `0px 0px 8px 4px ${colors.Primary.primary90}`
                    }}
                  />
                ))}
              </div>
            ) : (
              <div 
                className="full-width flex mt-1-em"
                style={{
                  justifyContent: "space-between",
                }}
              >
                {canvasValueHolder.map((_, index) => (
                  <div
                    key={index}
                    style={{
                      width: "23%",
                      paddingBottom: "23%",
                      backgroundColor: colors.Neutral.grey20,
                      borderRadius: 5,
                      position: "relative"
                    }}
                  >
                    <div 
                      className='absolute'
                      style={{
                        top: "50%",
                        left: "50%"
                      }}
                    >
                      <PikchaLoader />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )
      }
    },
    [
      heroShotsDetails, 
      thumbNailMaterial, 
      heroShotsPending,
      selectedHeroShots,
      selectedHeroShotThumb
    ],
  )

  return (
    <>
      <TypographySubtitle
        component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
        style={{ marginBottom: '1rem' }}
      >
        Discoverability
      </TypographySubtitle>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
      >
        Tags and metadata help your artwork get found by collectors around the world.
      </TypographyBody>
      <div className={classes.fieldMargin}>
        <PikchaDropdown
          className={classes.fieldHeight}
          label={              
            <TypographyBody
              size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
              style={{
                lineHeight: undefined
              }}
            >
              Image Type
            </TypographyBody>
          }
          onChange={({ label, value }) => {
            handleArtworkChange(label, value);
            setImageDetails({
              ...imageDetails,
              TypeId: value.id,
            });
          }}
          options={allImageTypes}
          value={artworkType}
          placeholder="Please select"
        />
        <div className={classes.fieldMargin}>
          <TagsPicker
            value={tags}
            handleTagAdd={handleTagAdd}
            handleTagDelete={handleTagDelete}
            onMouseLeave={() => handleImageDetails('tags', tags)}
          />
        </div>
        <div className={classes.fieldMargin}>
          <PikchaCheckbox
            style={{
              marginLeft: 0,
            }}
            value={imageDetails.isNudity}
            checked={imageDetails.isNudity}
            onChange={() => {
              handleImageDetails("isNudity", !imageDetails.isNudity);
            }}
            label={
              <div
                className="flex"
                style={{
                  alignItems: "center",
                }}
              >
                <TypographyBody
                  className={classes.checkbox}
                  size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
                >
                  Image contains sensitive or mature content
                </TypographyBody>
              </div>
            }
          />
        </div>
        <div className="full-width">
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
            style={{
              textAlign: "center",
              marginTop: "2em"
            }}
          >
            Choose a thumbnail
          </TypographyBody>
          <PikchaReusableTabsGroup
            value={thumbNailMaterial}
            style={{
              width: "fit-content"
            }}
          >
            <PikchaReusableTab
              onClick={() => {
                setThumbNailMaterial("Paper")
              }}
              style={{
                width: 50,
                marginRight: "1em"
              }}
              value="Paper"
              label={
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                  color={thumbNailMaterial === "Paper" ? "neutral/ink70" : "neutral/grey40"}
                >
                  Paper
                </TypographyBody>
              }
            />
            <PikchaReusableTab
              onClick={() => {
                setThumbNailMaterial("Canvas")
              }}
              style={{
                width: 50
              }}
              value="Canvas"
              label={
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                  color={thumbNailMaterial === "Canvas" ? "neutral/ink70" : "neutral/grey40"}
                >
                  Canvas
                </TypographyBody>
              }
            />
          </PikchaReusableTabsGroup>
          {renderThumbnails()}
        </div>
      </div>
      <TypographyButton
        className={classes.btnStyle}
        onClick={() => handleNextStep()}
        disabled={heroShotsPending}
      >
        Next
      </TypographyButton>
    </>
  )
};

export default connect(null, {
  setAlert,
})(Step5);
