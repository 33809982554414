import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles(({ typography, palette }) => ({
  root: (props) => ({
    padding: `${props.addTopPadding ? ".5em" : "0"} 0 0 0`,
    fontSize: props.larger ? typography.small : typography.small,
    fontWeight: props.fontWeightMedium
      ? typography.fontWeightMedium
      : typography.fontWeightBold,
    color: props.lighter ? palette.text.secondary : palette.text.secondary,
  }),
}));

export default ({
  children,
  className,
  lighter,
  larger,
  fontWeightMedium,
  addTopPadding,
  ...rest
}) => {
  const { typography, palette } = useTheme();
  const { root } = useStyles({
    addTopPadding,
    larger,
    fontWeightMedium,
    lighter,
  });

  return (
    <FormLabel
      component="label"
      className={`${root} ${className ? className : ""} no-select`}
      style={{}}
      {...rest}
    >
      {children}
    </FormLabel>
  );
};
