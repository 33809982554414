import React, {Fragment} from "react";
//import { Redirect } from "react-router-dom";
import ProductItemDesktop from "../containers/product/ProductItem/ProductItemDesktop";
import MainSeller from "../containers/product/MainSeller";
import SellerList from "../containers/product/SellerList";
import {connect} from "react-redux";
import MasonryGallery from "../containers/gallery/MasonryGallery/MasonryGallery";
import {resetGallery,} from "../actions/gallery";
import {getPhotoFriendlyUrl} from "../actions/friendlyUrlImage";

const Product = ({gallery, getPhotoFriendlyUrl: getPhotos, resetGallery}) => {

  return (
    <Fragment>
      <ProductItemDesktop />
      <MainSeller />
      <SellerList />
      <MasonryGallery
        gallery={gallery}
        getPhotos={(count, start) => getPhotos(count, start)}
        resetGallery={() => resetGallery()}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  gallery: state.friendlyUrlImageReducer,
});

export default connect(mapStateToProps, {getPhotoFriendlyUrl, resetGallery})(Product);
