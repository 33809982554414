import {
  AUTH_FAILED,
  AUTH_LOADING,
  AUTH_SUCCESS,
  DEAUTHENTICATE,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  loading: true,
  failed: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        failed: false,
      };
    case AUTH_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        failed: true,
      };
    case DEAUTHENTICATE:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
        failed: false,
      };
    default:
      return state;
  }
};
