import React, { cloneElement, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTheme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  ICON_SIZE_DEFAULT,
  ICON_SIZE_EXTRA_LARGE,
  ICON_SIZE_LARGE,
} from "../../../helpers/constants";

// variant props: 'large' | 'extraLarge'
export const PikchaGuidesButton = withStyles(
  ({ palette, typography, spacing }) => ({
    root: (props) => ({
      color: "black",
      // border: `4px solid ${palette.grey.mediumDark}`,
      //fontSize:
      //  props.variant && props.variant === "large"
      //    ? typography.larger
      //    : props.variant && props.variant === "extraLarge"
      //    ? typography.xxxLarge
      //    : typography.standard,
      borderRadius: 20,
      //borderRadius: shape.borderRadius,
      padding:
        props.variant && props.variant === "large"
          ? `${spacing(!props.isOnMobileOrTablet ? 6 : 4)}px ${spacing(
              !props.isOnMobileOrTablet ? 4 : 3
            )}px`
          : props.variant && props.variant === "extraLarge"
          ? `${spacing(!props.isOnMobileOrTablet ? 10 : 9)}px ${spacing(
              !props.isOnMobileOrTablet ? 6 : 3
            )}px`
          : "6px 16px",
      boxShadow: "none",
      fontWeight: typography.fontWeightBold,
      lineHeight: props.small ? 1.34 : 1.5,
      marginRight: 4,
      textTransform: "none",
      textDecoration: "none",
      background: "white",
      boxShadow: "0px 0px 8px 4px rgba(0, 0, 0, 0.15)",
      "&:hover": {
        background: "white",
        color: palette.primary.main,
        boxShadow: "1px 1px 20px 1px rgba(0, 0, 0, 0.25)",
        //background: palette.primary.main,
        // border: `4px solid ${palette.primary.main}`,
        transition:
          "background-color 0.1s ease-in-out, border 0.1s ease-in-out",
      },
    }),
  })
)(
  ({
    children,
    className = "",
    isOnMobileOrTablet,
    icon,
    text,
    to,
    small,
    variant,
    ...rest
  }) => {
    const { typography } = useTheme();
    const { palette } = useTheme();

    return (
      <Fragment>
        {to ? (
          <Link
            to={to}
            className={`${className} justify-center flex flex-stretch flex-grow-1 no-underline`}
          >
            <Button className="flex-grow-1" {...rest}>
              {variant ? (
                <div
                  className="block"
                  style={{
                    fontSize:
                      !isOnMobileOrTablet && variant === "large"
                        ? typography.larger
                        : isOnMobileOrTablet && variant === "large"
                        ? typography.standard
                        : !isOnMobileOrTablet && variant === "extraLarge"
                        ? typography.large
                        : typography.larger,
                  }}
                >
                  <div>{text}</div>
                  <div className="pt-3-4-em flex-center justify-center">
                    {cloneElement(icon, {
                      style: {
                        ...(!isOnMobileOrTablet && variant === "large"
                          ? ICON_SIZE_LARGE
                          : isOnMobileOrTablet && variant === "large"
                          ? ICON_SIZE_DEFAULT
                          : !isOnMobileOrTablet && variant === "extraLarge"
                          ? ICON_SIZE_EXTRA_LARGE
                          : ICON_SIZE_LARGE),
                      },
                      "&:hover": {
                        color: palette.primary.main,
                      }
                    })}
                  </div>
                </div>
              ) : (
                <Fragment>{children}</Fragment>
              )}
            </Button>
          </Link>
        ) : (
          <Button className={`${className} flex flex-grow-1`} {...rest}>
            {children}
          </Button>
        )}
      </Fragment>
    );
  }
);
