import React, {Fragment, useLayoutEffect, useRef, useState} from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import {connect} from "react-redux";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import MoneyRoundedIcon from "@material-ui/icons/MoneyRounded";
import WhatshotRoundedIcon from "@material-ui/icons/WhatshotRounded";
//import Img from "react-image";
import {useTheme} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import IconButton from "@material-ui/core/IconButton";
import CollectionsBookmarkRoundedIcon
  from "@material-ui/icons/CollectionsBookmarkRounded";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useLocation} from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import {useStyles} from "./SideDrawer.styles";
import {
  MAX_FOLLOWERS_TO_DISPLAY,
  SIDE_DRAWER_COLLAPSED_WIDTH,
  SIDE_DRAWER_EXPANDED_WIDTH,
} from "../../helpers/constants";
import {FollowingMenuItem} from "../../components/FollowingMenuItem/FollowingMenuItem";
import {getProfile} from "../../actions/profile";
import {handleSideDrawer} from "../../actions/sideDrawer";
import {isUserLoading} from "../../selectors/account";
import {
  PikchaDrawerMenuItem,
  PikchaLabel,
  PikchaMenuList,
  PikchaSpacer,
} from "../../components/baseComponents";
import SideDrawerFooter from "../Footer/Footer";
import {getValueIfKeyExists} from "../../helpers/utils";
import FollowingListSkeletonLoader
  from "../../components/skeletonLoaders/FollowingListSkeletonLoader";

import isMobile from "../../components/Responsive/isMobile";
import isTablet from "../../components/Responsive/isTablet";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isDesktopHD from "../../components/Responsive/isDesktopHD";
import SideDrawerOnboardingGroup from "./SideDrawerOnboardingGroup";
import Overlay from "../../components/Overlay/Overlay";
import {useRouteWithFooter, useRouteWithoutSideDrawer} from "../../hooks/useRouteWithFooter";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./SideDrawer.css";
import LikeIcon from "../../components/extraIcons/LikeIcon"
import { colors } from "@pikcha/typography"

const SideDrawer = ({
                      auth,
                      account,
                      handleSideDrawer,
                      sideDrawer: {payload},
                      getProfile,
                      isUserLoading,
                    }) => {
  const {pathname} = useLocation();

  const isRouteWithFooter = useRouteWithFooter();
  const isRouteWithoutSideDrawer = useRouteWithoutSideDrawer();
  //const [isOnAuthPage] = useState(pathname.includes("auth"));
  const [expanded, setExpanded] = useState(false);
  const [forceScrollbarVisibility, setForceScrollbarVisibility] = useState(
    false
  );
  const collapseRef = useRef(null);
  const scrollbarRef = useRef(null);

  useLayoutEffect(() => {
    if (
      (scrollbarRef.current &&
        scrollbarRef.current.classList.contains("ps--active-y")) ||
      (scrollbarRef.current &&
        scrollbarRef.current.classList.contains("ps--scrolling-y"))
    ) {
      if (forceScrollbarVisibility) {
        scrollbarRef.current.classList.add("ps--active-y");
        scrollbarRef.current.classList.add("ps--scrolling-y");
      } else {
        scrollbarRef.current.classList.remove("ps--active-y");
        scrollbarRef.current.classList.remove("ps--scrolling-y");
      }
    }
  }, [forceScrollbarVisibility]);

  const drawerWidth = () =>
    payload ? SIDE_DRAWER_EXPANDED_WIDTH : SIDE_DRAWER_COLLAPSED_WIDTH;

  const classes = useStyles(drawerWidth(), expanded, pathname);
  const theme = useTheme();

  const handleExpandClick = () => {
    setForceScrollbarVisibility(!expanded);
    setExpanded(!expanded);
  };

  const {typography, palette} = theme;
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const isOnDesktopSD = isDesktopSD();
  const isOnDesktopHD = isDesktopHD();

  if ((isOnMobile || isOnTablet) && !payload) {
    return null;
  }

  if (isRouteWithoutSideDrawer && !payload) {
    return null;
  }
  const shouldShowOverlay =
    (isOnMobile || isOnTablet || isOnDesktopSD) && payload;
  
  return (
    <Fragment>
      {shouldShowOverlay && (
        <Overlay palette={palette} handleSideDrawer={handleSideDrawer}/>
      )}
      <Drawer
        ModalProps={{
          keepMounted: true
        }}
        elevation={0}
        PaperProps={{
          component: "aside",
        }}
        variant={isOnDesktopSD || isOnDesktopHD ? "permanent" : "temporary"}
        className={`SideDrawer-container nonPrintable ${clsx(classes.drawer, {
          [classes.drawerOpen]: payload,
          [classes.drawerClose]: !payload,
        })}`}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: payload,
            [classes.drawerClose]: !payload,
          }),
        }}
        onClose={() => handleSideDrawer(false)}
        open={payload}
        anchor="left"
      >
        <div className="full-width relative pt-4-em"/>
        {payload ? (
          <PerfectScrollbar
            containerRef={(ref) => (scrollbarRef.current = ref)}
            options={{
              wheelPropagation: false,
              suppressScrollX: true,
            }}
          >
            <div
              className="SideDrawer-innerContainer flex-column relative full-width full-height"
              style={{
                minHeight: 600,
              }}
            >
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <PikchaLabel className="pl-1-em pb-half-em" style={{color: "black"}}>
                    Galleries
                  </PikchaLabel>
                  <PikchaMenuList>
                    <PikchaDrawerMenuItem
                      to="/"
                      pathname={pathname}
                      icon={
                        <WhatshotRoundedIcon
                          fontSize="inherit"
                          color={
                            pathname === "/" ? palette.text.primary : palette.text.secondary
                          }
                        />
                      }
                    >
                      Home
                    </PikchaDrawerMenuItem>
                    <PikchaDrawerMenuItem
                      to="/following"
                      pathname={pathname}
                      icon={
                        <GroupRoundedIcon 
                          fontSize="inherit" 
                          color={
                            pathname === "/following" ? palette.text.primary : palette.text.secondary
                          }
                        />
                      }
                    >
                      Following
                    </PikchaDrawerMenuItem>
                    <PikchaDrawerMenuItem
                      to="/pikcha100"
                      pathname={pathname}
                      icon={
                        <MoneyRoundedIcon 
                          fontSize="inherit" 
                          color={
                            pathname === "/pikcha100" ? palette.text.primary : palette.text.secondary
                          }
                        />
                      }
                    >
                      Top 100
                    </PikchaDrawerMenuItem>
                    <PikchaDrawerMenuItem
                      to="/liked"
                      pathname={pathname}
                      icon={
                        <LikeIcon
                          fontSize="inherit"
                          fill={
                            pathname === "/liked" ? "black" : `${colors.Neutral.ink70}`
                          }
                        />
                      }
                    >
                      Liked
                    </PikchaDrawerMenuItem>
                  </PikchaMenuList>
                </Paper>
              </div>
              <PikchaSpacer/>
              <Divider light/>
              {isUserLoading && <FollowingListSkeletonLoader/>}
              {!isUserLoading &&
              auth &&
              auth.isAuthenticated &&
              getValueIfKeyExists(account, "user.following") && (
                // <div className={classes.rootGrow}>
                <Paper className={classes.paper} style={{height: "auto"}}>
                  {account.user.following.length === 0 && (
                    <div className="SideDrawer-group">
                      <PikchaLabel className="pl-1-em pb-half-em">
                        Artists
                      </PikchaLabel>
                      <div
                        className="UsersFollowing-none"
                        style={{
                          minWidth: 208,
                          color: palette.grey.medium,
                          fontSize: typography.smallest,
                          fontWeight: typography.fontWeightSemiMedium,
                          fontFamily: typography.fontFamilyMontserrat,
                        }}
                      >
                        <p className="pb-4">
                          You're not following any artists.
                        </p>
                        <p>
                          Follow Artists and get notified when they upload
                          so you don't miss a post!
                        </p>
                      </div>
                    </div>
                  )}
                  {account.user.following.length > 0 && (
                    <Fragment>
                      <PikchaLabel className="pl-1-em pb-half-em">
                        Artists
                      </PikchaLabel>
                      <MenuList className={classes.menuList}>
                        {account.user.following
                          .slice(0, MAX_FOLLOWERS_TO_DISPLAY)
                          .map(({fName, lName, nickName, avatar, id, userHandle}) => (
                            <FollowingMenuItem
                              key={id}
                              userHandle={userHandle}
                              id={id}
                              nickName={nickName}
                              fName={fName}
                              lName={lName}
                              avatar={avatar}
                              getProfile={getProfile}
                            />
                          ))}
                      </MenuList>
                      <Collapse
                        in={expanded}
                        timeout="auto"
                        ref={collapseRef}
                      >
                        <MenuList className={classes.menuList}>
                          {account.user.following
                            .slice(
                              MAX_FOLLOWERS_TO_DISPLAY,
                              account.user.following.length
                            )
                            .map(({fName, lName, nickName, avatar, id, userHandle}) => (
                              <FollowingMenuItem
                                key={id}
                                id={id}
                                userHandle={userHandle}
                                nickName={nickName}
                                fName={fName}
                                lName={lName}
                                avatar={avatar}
                                getProfile={getProfile}
                              />
                            ))}
                        </MenuList>
                      </Collapse>
                      {getValueIfKeyExists(account, "user.following") &&
                      account.user.following.length >
                      MAX_FOLLOWERS_TO_DISPLAY && (
                        <div className="flex-center">
                          <IconButton
                            className={classes.expand}
                            size="small"
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                          >
                            {expanded ? (
                              <ExpandLessRoundedIcon
                                fontSize="small"
                                color="action"
                              />
                            ) : (
                              <>
                                <ExpandMoreRoundedIcon
                                  fontSize="small"
                                  color="action"
                                />
                                &nbsp;
                                <PikchaLabel
                                  fontWeightStandard
                                  style={{
                                    paddingTop: 2,
                                  }}
                                  className="cursor-pointer"
                                >
                                  {`${
                                    account.user.following.length -
                                    MAX_FOLLOWERS_TO_DISPLAY
                                  }`}
                                  &nbsp;more
                                </PikchaLabel>
                              </>
                            )}
                          </IconButton>
                        </div>
                      )}
                    </Fragment>
                  )}
                </Paper>
                //{/* </div> */}
              )}
              {!isUserLoading &&
              auth &&
              auth.isAuthenticated &&
              !getValueIfKeyExists(account, "user.following") && (
                <SideDrawerOnboardingGroup/>
              )}
              {!isUserLoading && (!auth || !auth.isAuthenticated) && (
                <SideDrawerOnboardingGroup/>
              )}
              {/*<CSSTransition*/}
              {/*  in={payload}*/}
              {/*  classNames="SideDrawer-footerTransition"*/}
              {/*  timeout={300}*/}
              {/*  appear*/}
              {/*  unmountOnExit*/}
              {/*>*/}
              <SideDrawerFooter auth={auth} expanded={expanded}/>
              {/*</CSSTransition>*/}
            </div>
          </PerfectScrollbar>
        ) : (
          <PikchaMenuList className="flex-column">
            <PikchaDrawerMenuItem
              to="/"
              pathname={pathname}
              icon={<WhatshotRoundedIcon fontSize="inherit" color="action"/>}
            />
            <PikchaDrawerMenuItem
              to="/following"
              pathname={pathname}
              icon={<GroupRoundedIcon fontSize="inherit" color="action"/>}
            />
            <PikchaDrawerMenuItem
              to="/pikcha100"
              pathname={pathname}
              icon={<MoneyRoundedIcon fontSize="inherit" color="action"/>}
            />
            <PikchaDrawerMenuItem
              to="/liked"
              pathname={pathname}
              icon={
                <CollectionsBookmarkRoundedIcon
                  fontSize="inherit"
                  color="action"
                />
              }
            />
          </PikchaMenuList>
        )}
      </Drawer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authReducer,
  account: state.accountReducer,
  sideDrawer: state.sideDrawerReducer,
  isUserLoading: isUserLoading(state),
});

export default connect(mapStateToProps, {
  getProfile,
  handleSideDrawer,
})(SideDrawer);
