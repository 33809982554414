import React from "react";
import { useTheme } from "@material-ui/core";

export default ({ children, className = "", style = {}, ...rest }) => {
  const { palette, typography } = useTheme();

  return (
    <div
      className={`pl-1-em flex-center ${className}`}
      style={{
        color: palette.grey.medium,
        fontWeight: typography.fontWeightMedium,
        fontSize: typography.standard,
        display: "inline",
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
