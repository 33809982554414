/* eslint-disable */

export default (time) => {
  let timerStart = true;
  let mylet = null;
  let startTime = null;
  let sec = 0;
  let min = 13;
  self.addEventListener(
    "message",
    function (e) {
      var args = e.data.args;
      min = args.minutes;
      sec = args.seconds;
      startTime = args;
    },
    false
  );
  function myTimer() {
    if (startTime === null) {
      if (location) {
        min =
          location.href.split("#") && location.href.split("#")[1]
            ? location.href.split("#")[1].split("&")[0].split("=")[1]
            : min;
        sec =
          location.href.split("#") && location.href.split("#")[1]
            ? location.href.split("#")[1].split("&")[1].split("=")[1]
            : sec;
      }
      startTime = {
        minutes: parseInt(min),
        seconds: parseInt(sec),
      };
    }

    let currTimer = startTime;
    if (currTimer.seconds === 0 && currTimer.minutes > 0) {
      currTimer.minutes--;
      currTimer.seconds = 59;
    } else if (currTimer.seconds > 0 && currTimer.minutes > 0) {
      currTimer.seconds--;
    } else if (currTimer.seconds >= 0 && currTimer.minutes === 0) {
      currTimer.seconds--;
    } else if (currTimer.seconds <= 0 && currTimer.minutes === 0) {
      currTimer.seconds = 0;
      currTimer.minutes = 0;
      postMessage("0:0");
      this.close();
    }
    postMessage(currTimer.minutes + ":" + currTimer.seconds);
  }
  if (timerStart) {
    mylet = setInterval(function () {
      myTimer();
    }, 1000);
    timerStart = false;
  }
};
