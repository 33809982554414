export const getNearestAspectRatio = (width, height, side = 0) => {
  /**
   * Calculates the nearest normal aspect ratio.
   */
  const ratio = (width * 100) / (height * 100);

  const ratios = {
    "3,2": 1.5,
    "4,3": 1.33333333,
    "5,4": 1.25,
    "2,3": 0.66666667,
    "3,4": 0.75,
    "4,5": 0.8,
    "1,1": 1,
    "1,2": 0.5,
    "1,3": 0.33333333,
    "2,1": 2,
    "3,1": 3
  }
  let match;
  let key;

  for (key in ratios) {
    if (!match || (
      !side && Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])
    ) || (
      side < 0 &&
      ratios[key] <= ratio &&
      Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])
    ) || (
      side > 0 &&
      ratios[key] >= ratio &&
      Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])
    )) {
      match = key;
    }
  }

  return match;
}

export const mapAspectRatioToRadioButtonKey = (ratio) => {
  switch (ratio) {
    case "2,1":
      return 9;
    case "3,1":
      return 10;
    case "1,2":
      return 7;
    case "1,3":
      return 8;
    case "2,3":
      return 3;
    case "3,4":
      return 4;
    case "4,5":
      return 5;
    case "3,2":
      return 0;
    case "4,3":
      return 1;
    case "5,4":
      return 2;
    case "1,1":
      return 6;
    default:
      return 0;
  }
}
  
