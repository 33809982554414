import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

export const PikchaActionInvertedButton = withStyles({
  root: {
    color: '#6e37ff !important',
    boxShadow: 'none',
    textTransform: 'none',
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 600,
    marginRight: 4,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.34,
    backgroundColor: '#FAFAFA',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#FAFAFA',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);
