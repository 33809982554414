import React, { useEffect, useState } from "react";

import Desktop from "../../components/Responsive/Desktop";
import Tablet from "../../components/Responsive/Tablet";
import Mobile from "../../components/Responsive/Mobile";
import PageFooterDesktop from "./PageFooterDesktop";
import PageFooterMobile from "./PageFooterMobile";
import { useRouteWithFooter } from "../../hooks/useRouteWithFooter";

export default () => {
  const isRouteWithFooter = useRouteWithFooter();

  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDisplay(true);
    }, 5000);
  }, []);

  if (!isRouteWithFooter) {
    return null;
  }
  if (!display) {
    return null;
  }

  return (
    <React.Fragment>
      <Desktop>
        <PageFooterDesktop />
      </Desktop>
      <Tablet>
        <PageFooterDesktop />
      </Tablet>
      <Mobile>
        <PageFooterMobile />
      </Mobile>
    </React.Fragment>
  );
};
