import React, { cloneElement } from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const IconButtonWrapper = withStyles(({ typography, palette }) => ({
  root: (props) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: props.activeColor ? palette.common.white : null,
    padding: props.noPadding && !props.activeColor ? 0 : 3,
    fontSize:
      props.size && props.size === "medium"
        ? typography.standard
        : props.size && props.size === "large"
        ? typography.larger
        : props.size && props.size === "larger"
        ? typography.large
        : typography.smaller,
    color:
      props.color && props.activeColor
        ? palette.primary.main
        : props.color && props.color === "white"
        ? palette.common.white
        : null,
    transition: "transform .2s ease-in-out, padding .2s ease-in-out",
    "&:hover": {
      transform: props.hoverGrow ? "scale(1.2)" : null,
      backgroundColor: props.activeColor ? palette.common.white : "transparent",
    },
  }),
}))(
  ({
    noMarginAuto,
    size,
    noPadding,
    color,
    hoverGrow,
    activeColor,
    ...rest
  }) => <IconButton {...rest} />
);

export default ({
  icon,
  label,
  color,
  size,
  noMarginAuto,
  noPadding,
  hoverGrow,
  activeColor,
  ...rest
}) => {
  const { palette, typography } = useTheme();

  return (
    <div
      className={`${noMarginAuto ? "" : "margin-auto"}`}
      style={{
        display: label ? "block" : "flex",
      }}
    >
      <div>
        <IconButtonWrapper
          className={`${noMarginAuto ? "" : "margin-auto"}`}
          color={color}
          size={size}
          noPadding={noPadding}
          hoverGrow={hoverGrow}
          activeColor={activeColor}
          {...rest}
        >
          {cloneElement(icon, {
            fontSize: "inherit",
            color: color ? "inherit" : "action",
          })}
        </IconButtonWrapper>
        {label && (
          <div
            style={{
              fontSize: typography.tiny,
              color: palette.common.white,
            }}
          >
            {label}
          </div>
        )}
      </div>
    </div>
  );
};
