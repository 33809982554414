import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PeopleIcon from "@material-ui/icons/People";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";

import {
  getFollowingPhotos,
  resetFollowingGallery
} from "../../actions/galleryFollowing";
import { followArtist, unfollowArtist } from "../../actions/account";
import { handleAuthModal } from "../../actions/authModal";
import { getArtist100 } from "../../actions/artist100";

import { searchAPIUrl } from "../../selectors/settings";

import MasonryGallery
  from "../../containers/gallery/MasonryGallery/MasonryGallery";

import {
  PikchaLoader,
  PikchaSpacer,
  PikchaTextBlock
} from "../../components/baseComponents";

import isDesktop from "../../components/Responsive/isDesktop";
import isDesktopHD from "../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isTablet from "../../components/Responsive/isTablet";
import isMobile from "../../components/Responsive/isMobile";
import {
  GALLERY_PAGE_DEFAULT,
  GALLERY_ITEMINPAGE_DEFAULT,
} from "../../helpers/constants";
import MasonryGalleryFollowing
  from "../../containers/gallery/MasonryGalleryFollowing/MasonryGalleryFollowing";


import "./FollowingSection.css";
import FollowingSuggestions from "./FollowingSuggestions";

const FollowingSection = ({
  auth,
  galleryFollowing,
  searchAPIUrl,
  account,
  artist100,
  getArtist100,
  followArtist,
  unfollowArtist,
  getFollowingPhotos,
  resetFollowingGallery,
  handleAuthModal,

}) => {
  const [currentUser, setCurrentUser] = useState();
  const [
    originallyFollowingAnyArtists,
    setOriginallyFollowingAnyArtists,
  ] = useState(true);

  const { palette, typography } = useTheme();
  useEffect(() => {

    if (account.user && !currentUser) {
      setCurrentUser(account.user);
      const isFollowingAnyone =
        account.user &&
        !account.loadingUser &&
        account.user.following &&
        account.user.following.length > 0;
      setOriginallyFollowingAnyArtists(isFollowingAnyone);
    }
  }, [account.user]);

  const isUserLoading =
    !currentUser &&
    ((auth.isAuthenticated && account.loadingUser) || auth.loading);

  let isFollowingArtists = true;

  isFollowingArtists = !!(
    !account.fromFollowingSection &&
    originallyFollowingAnyArtists &&
    account.user &&
    account.user.following &&
    account.user.following.length > 0
  );

  const isGalleryLoading = galleryFollowing.loading;

  const areArtistSuggestionsLoading =
    artist100.top100.length === 0 && artist100.loading;

  const isOnDesktop = isDesktop();
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();


  useEffect(() => {
    //console.log("Need to reset following page");
    resetFollowingGallery();
    getFollowingPhotos(GALLERY_PAGE_DEFAULT, GALLERY_ITEMINPAGE_DEFAULT);
  }, []);


  return (
    <>
      <Helmet>
        <title>Following | Pikcha</title>
        <meta name="description" content="Create a personalised feed of your favourite Artists and receive notifications when they upload 
          new limited-edition images to Pikcha. Never miss an art drop from artists that you love!"></meta>
      </Helmet>
      <div className="flex-grow-1 Page-innerContainer pt-4-em main-width-restrict">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "4em",
            marginLeft: isOnDesktopHD
              ? "4em"
              : isOnDesktopSD
                ? "2em"
                : isOnTablet
                  ? "1em"
                  : "0.5em",
            marginRight: isOnDesktopHD
              ? "4em"
              : isOnDesktopSD
                ? "2em"
                : isOnTablet
                  ? "1em"
                  : "0.5em",
            // backgroundColor: palette.grey.light,
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
            borderRadius: "12px 12px 0px 0px",
          }}
        >
          <PeopleIcon
            style={{
              color: palette.grey.mediumDark,
              fontSize: typography.xLarge,
            }}
          />
          <div
            style={{
              ...{
                color: palette.grey.mediumDark,
                fontFamily: typography.fontFamilyMontserrat,
                fontWeight: typography.fontWeightMedium,
                paddingLeft: "12px",
                paddingRight: "32px",
                // marginTop: "-8px",
                maxWidth: 1080,
                fontSize: "1.25rem"
              },
              // ...typography.h2,
            }}
          >
            Following
          </div>
        </div>

        {isFollowingArtists && (
          // <MasonryGallery
          //   isFollowing
          //   gallery={galleryFollowing}
          //   getPhotos={() => getFollowingPhotos(galleryFollowing.page, galleryFollowing.itemInPage)} 
          //   resetGallery={() => resetFollowingGallery()}
          // />

          <MasonryGalleryFollowing
            isFollowing
            gallery={galleryFollowing}
            getPhotos={() => {
              if (galleryFollowing.page > 1) {
                getFollowingPhotos(galleryFollowing.page, galleryFollowing.itemInPage)
              }
            }
            }
            resetGallery={() => resetFollowingGallery()}
          />
        )}
        {!isUserLoading && !isFollowingArtists && (
          <div className="flex-column flex-stretch flex-grow-1">
            <div className="flex-grow-1 flex-center justify-center flex-column">
              <div className="flex-column text-center flex-grow-1">
                <PikchaTextBlock className="pb-2-em FollowingSection-description">
                  <Fragment>
                    <div
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: typography.fontWeightMedium,
                        color: palette.grey.medium,
                      }}
                    >
                      <div>You're not following any artists yet.</div>
                      <div
                        style={{
                          marginTop: "1em",
                        }}
                      >
                        To get your feed started, follow some of the<br />
                      artists currently featured in Artist 100!
                      {/* <span className="medium">
                          <a
                            className="no-underline"
                            href="/pikcha100?sort=asc&filter=algorithm&type=artist100"
                          >
                            Artist 100
                          </a>
                        </span>{" "}
                      below. */}
                      </div>
                    </div>
                  </Fragment>
                </PikchaTextBlock>
              </div>
              <PikchaSpacer />
              {!isFollowingArtists && (
                <FollowingSuggestions />
              )}
            </div>
          </div>
        )}
        {isUserLoading && (
          <div
            className="flex-center"
            style={{
              marginTop: "64px",
              width: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: "3 1 0",
            }}
          >
            <div className="flex-center flex-grow-1">
              <PikchaLoader />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  auth: state.authReducer,
  profile: state.profileReducer,
  artist100: state.artist100Reducer,
  galleryFollowing: state.galleryFollowingReducer,
  searchAPIUrl: searchAPIUrl(state),
});

export default connect(mapStateToProps, {
  getFollowingPhotos,
  followArtist,
  unfollowArtist,
  getArtist100,
  handleAuthModal,
  resetFollowingGallery
})(FollowingSection);
