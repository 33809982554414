import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

export const PikchaSecondaryModalButton = withStyles((theme) => ({
  root: {
    width: '100%',
    color: '#a6a6a6',
    boxShadow: 'none',
    textTransform: 'none',
    textDecoration: 'none',
    borderRadius: theme.shape.borderRadius,
    fontSize: 20,
    fontWeight: 600,
    padding: '8px 16px',
    border: '1px solid',
    lineHeight: 1.34,
    backgroundColor: 'transparent',
    backgroundImage: 'none !important',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
}))(Button);
