import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Paper } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import isMobile from "../../../../components/Responsive/isMobile";
import { currencyFormat, IntlCurrencyFormatter } from "../../../../components/NumberFormatters/IntlCurrencyFormatter";
import {
  TypographyButton,
  TYPOGRAPHY_BUTTON_TYPE,
} from "@pikcha/typography";
import DownloadIcon from "../../../../components/extraIcons/DownloadIcon";
import ArrowLeft from '@material-ui/icons/ArrowBackIosOutlined'

export const StyledTableContainer = withStyles(() => ({
  root: {
    maxHeight: "60vh",
    marginBottom: "8em",
    border: 'none',
    boxShadow: 'none',
  },
}))(TableContainer);

export const StyledTableCell = withStyles(() => ({
  root: {
    minWidth: 225,
    fontFamily: "montserrat",
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#F2F4F8',
    height: '44px',
    padding: '0',
    marginTop: '1em'
  },
}))(TableCell);

const PaymentHistoryBySale = ({
  viewingSalesOfPayment,
  getPaymentSalesQuantityTotal,
  getPaymentSalesRetailTotal,
  getPaymentSalesPikchaFeeTotal,
  getPaymentSalesArtistMarginTotal,
  getPaymentSalesPaymentStatusTotal,
  downloadHistory,
  goBackFromViewingSalesOfPayment
}) => {
  const { palette, typography } = useTheme();
  const isOnMobile = isMobile();

  return (
    <>
      <div>
        <div
          className="nonPrintable"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TypographyButton
            buttonStyle={{ padding: '16px 8x', marginBottom: isOnMobile ? '1.75em' : '2em' }}
            onClick={(e) => goBackFromViewingSalesOfPayment()}
            component={TYPOGRAPHY_BUTTON_TYPE.TextOnly}
            icon={<ArrowLeft />}
          >
            Back
          </TypographyButton>
          <TypographyButton
            onClick={downloadHistory}
            buttonStyle={{
              color: '#000', marginBottom: isOnMobile ? '2.25em' : '0'
            }}
            textStyle={{ paddingLeft: "0.5em" }}
            component={TYPOGRAPHY_BUTTON_TYPE.Outline}
            icon={
              <DownloadIcon
                color="#000"
                iconSize={16}
              />
            }
          >
            Download
          </TypographyButton>
        </div>
        <div>
          <div
            style={{
              marginTop: "1em",
              fontSize: typography.larger,
              fontWeight: typography.fontWeightBold,
              color: palette.purple.medium,
            }}
          >
            {/* {viewingSalesOfPayment.PaymentDate}  */}
            Payment
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "1em",
              marginBottom: "1em",
            }}
            className="nonPrintable"
          >
            <div
              style={{
                fontSize: typography.small,
                fontWeight: typography.fontWeightBold,
                color: palette.text.primary,
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              Sales Made
            </div>
          </div>
          <StyledTableContainer children={<table></table>} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Order Date
                  </StyledTableCell>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Ship Date
                  </StyledTableCell>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Order#
                    </StyledTableCell>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Image Title
                  </StyledTableCell>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Quantity
                    </StyledTableCell>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Retail Price
                  </StyledTableCell>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Pikcha Fee
                  </StyledTableCell>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Artist Margin
                  </StyledTableCell>
                  <StyledTableCell style={{ position: "sticky", top: 0, zIndex: 1, background: "white", paddingBottom: '1em' }} align={"center"}>
                    Payment Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(viewingSalesOfPayment.PayoutDetails || []).map((item,index) => {
                  return (
                    <TableRow>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                        {moment(item.OrderDate, "DD/MM/YYYY").format(
                          "D-M-YYYY"
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                        {moment(item.CreatedAt, "DD/MM/YYYY hh:mm:ss").format(
                          "D-M-YYYY"
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>{item.OrderNumber}</StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                        {item.ImageTitle}
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>{item.Qty}</StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                        <IntlCurrencyFormatter value={+(item.FinPrice)} localConversion={true}
                          displayFormat={currencyFormat.code} />
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align={"center"}>
                        <IntlCurrencyFormatter value={+(item.FinPrice - item.SetPrice)} localConversion={true}
                          displayFormat={currencyFormat.code} />
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align="center">
                        <IntlCurrencyFormatter value={+(item.SetPrice)} localConversion={true}
                          displayFormat={currencyFormat.code} />
                      </StyledTableCell>
                      <StyledTableCell style={{ backgroundColor: index % 2 === 0 ? '#F2F4F8' : 'white' }} align="center">
                        {item.PaymentStatus}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
                <TableRow style={{backgroundColor:'white'}}>
                  <StyledTableCell align={"center"}></StyledTableCell>
                  <StyledTableCell align={"center"}></StyledTableCell>
                  <StyledTableCell align={"center"}></StyledTableCell>
                  <StyledTableCell align={"center"}></StyledTableCell>
                  <StyledTableCell align={"center"}>
                    <strong>
                      <IntlCurrencyFormatter value={+(getPaymentSalesQuantityTotal())} localConversion={true}
                        displayFormat={currencyFormat.code} />
                    </strong>
                  </StyledTableCell>
                  <StyledTableCell align={"center"}>
                    <strong>
                      <IntlCurrencyFormatter value={+(getPaymentSalesRetailTotal())} localConversion={true}
                        displayFormat={currencyFormat.code} />
                    </strong>
                  </StyledTableCell>
                  <StyledTableCell align={"center"}>
                    <strong>
                      <IntlCurrencyFormatter value={+(getPaymentSalesPikchaFeeTotal())} localConversion={true}
                        displayFormat={currencyFormat.code} />
                    </strong>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <strong>
                      <IntlCurrencyFormatter value={+(getPaymentSalesArtistMarginTotal())} localConversion={true}
                        displayFormat={currencyFormat.code} />
                    </strong>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {getPaymentSalesPaymentStatusTotal()}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledTableContainer>
        </div>
      </div>
    </>
  )
}

export default PaymentHistoryBySale