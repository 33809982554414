import React, { forwardRef } from "react";
import { Col, Row } from "react-grid-system";
import { useTheme } from "@material-ui/core/styles";
import UploadIcon from "../../../components/extraIcons/UploadIcon";
import { 
  colors, 
  TypographyButton, 
  TYPOGRAPHY_BUTTON_TYPE, 
  TypographyBody, 
  TYPOGRAPHY_BODY_SIZE,
  TYPOGRAPHY_BUTTON_TEXT,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT
} from "@pikcha/typography";
import {
  PikchaSpacer,
  PikchaUnorderedList
} from "../../../components/baseComponents";

const UploadFlowModalStep2 = forwardRef(({
  onChangeImage,
  onFileChange
},
  ref
) => {
  const { palette, typography } = useTheme();
  return (
    <div
      className="flex-center justify-center"
      style={{
        width: "100%",
        maxWidth: 1920,
        flexDirection: "column",
        justifyContent: "space-between",
        flex: "3 1 0",
        padding: "0 2em"
      }}
    >
      <TypographySubtitle
        component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
        style={{
          alignSelf: "start",
        }}
      >
        Upload
      </TypographySubtitle>
      <TypographyBody
        style={{
          alignSelf: "start",
          marginTop: "1em",
          marginBottom: "2em"
        }}
      >
        Select a file from your computer or drag and drop into the area below to begin your upload.
      </TypographyBody>
      <div
        style={{
          border: "2px dashed grey",
          borderRadius: 6,
          width: "100%"
        }}
        className="mb-5-em full-height justify-center flex-column flex-center"
        onDrop={(e) => {
          e.preventDefault();
          onFileChange(e);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        onDragEnd={(e) => {
          e.preventDefault();
        }}
      >
        <div
          className="UploadImage-instructions"
          style={{
            color: palette.grey.dark,
          }}
        >
          <TypographyButton
            component={TYPOGRAPHY_BUTTON_TYPE.IconLeading}
            onClick={() => ref.current.click()}
            action="octonary"
            onClick={onChangeImage}
            buttonText={TYPOGRAPHY_BUTTON_TEXT.Small}
            icon={
              <UploadIcon
                color={colors.Neutral.white00}
                iconSize={16}
              />
            }
            textStyle = {{marginLeft:'0.5em'}}
          >
            Select File or Drop
          </TypographyButton>
        </div>
        <Row
          className="text-left Step1-uploadInstructions"
          style={{
            justifyContent: "center"
          }}
        >
          <Col md={4.5}>
            <PikchaUnorderedList
              style={{
                fontSize: typography.smallest,
                fontWeight: typography.fontWeightMedium,
                color: palette.text.secondary,
                textAlign: "center",
                lineHeight: null,
                letterSpacing: null
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              >
                JPG and PNG accepted
              </TypographyBody>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              >
                15 MP minimum
              </TypographyBody>
              <br />
            </PikchaUnorderedList>
          </Col>
        </Row>
        <PikchaSpacer />
      </div>
    </div>
  );
});

export default UploadFlowModalStep2;
