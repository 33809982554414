import React, {useEffect, useState} from "react";
import axios from "axios";
import {connect} from "react-redux";

import {useTheme} from "@material-ui/core/styles";

import {
  PikchaButton,
  PikchaLoader,
  PikchaModal,
  PikchaToggleButton,
  PikchaToggleButtonGroup,
  PriceField,
} from "../../components/baseComponents";
import {PikchaImageButton} from "../../components/baseComponents/buttons/PikchaImageButton";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {currencyFormat, IntlCurrencyFormatter} from "../../components/NumberFormatters/IntlCurrencyFormatter";

export const StyledTableContainer = withStyles(({typography, palette}) => ({
  root: {
    maxHeight: "467px"
  },
}))(TableContainer);

export const StyledTableCell = withStyles(({typography, palette}) => ({
  root: {
    fontFamily: "montserrat",
  },
}))(TableCell);

const ArtworkPricesModal = ({open = false, onClose, aspectRatio, setPrice}) => {
  const {typography, palette, shape} = useTheme();
  const [priceValue, setPriceValue] = useState(setPrice);
  const [artistTemplatesLoading, setArtistTemplatesLoading] = useState();
  const [artistTemplates, setArtistTemplates] = useState({});
  const [openOnceStatus, setOpenOnceStatus] = useState(true);
  const [currentTab, setCurrentTab] = useState("table");

  const [selectedWrap, setSelectedWrap] = useState("image");
  const [wrap, setWrap] = useState(["image", "black", "white"]);
  const [fields, setFields] = useState({
    selectedCanvas: undefined,
    selectedMaterial: null,
    allMaterials: [],
    selectedSize: null,
    allSizes: [],
    selectedBorder: null,
    allBorders: [],
    selectedFrame: null,
    allFrames: [],
  });
  const [priceList, setPriceList] = useState([]);

  const callingPriceEstimator = (setNewPrice) => {
    //  setArtistTemplatesLoading(true);
    const aspectRatioX = parseInt(aspectRatio.split(",")[0]);
    const aspectRatioY = parseInt(aspectRatio.split(",")[1]);
    axios
      .get("/api/Printer/Jondo/ArtistTemplates", {
        params: {
          printerCode: "Jondo",
          aspectRatioX,
          aspectRatioY,
          //SetPrice: priceValue || 0,
          SetPrice: setNewPrice || 0,
        },
      })
      .then((resp) => {
        setArtistTemplates(resp.data);

        setFields({
          selectedCanvas: resp.data[0],
          selectedMaterial: null,
          allMaterials: resp.data,
          selectedSize: null,
          allSizes: [],
          selectedBorder: null,
          allBorders: [],
          selectedFrame: null,
          allFrames: [],
        });

        changeMaterial(resp.data[0].material[0], resp.data[0]);
        //  setArtistTemplatesLoading(false);
      });
  };

  const callingPriceTable = (setNewPrice) => {
    const aspectRatioX = parseInt(aspectRatio.split(",")[0]);
    const aspectRatioY = parseInt(aspectRatio.split(",")[1]);
    axios
      .get("/api/Printer/Jondo/ProductLine", {
        params: {
          aspectRatioX,
          aspectRatioY,
          //SetPrice: priceValue || 0,
          SetPrice: setNewPrice || 0,
        },
      })
      .then((resp) => {
        setPriceList(resp.data);
      });
  };

  const changeMaterial = (mat, canvas) => {
    setFields((fields) => {
      let selectedSize = null;
      let biggest =
        mat.aspectRatios[0] && mat.aspectRatios[0].sizes[0]
          ? mat.aspectRatios[0].sizes[0].x * mat.aspectRatios[0].sizes[0].y
          : 0;
      mat.aspectRatios[0].sizes.forEach((size, index) => {
        if (size.x * size.y > biggest) {
          selectedSize = size;
        }
      });
      let selectedBorder = selectedSize
        ? selectedSize.borders[0]
        : mat.aspectRatios[0] &&
        mat.aspectRatios[0].sizes[0] &&
        mat.aspectRatios[0].sizes[0].borders[0]
          ? mat.aspectRatios[0].sizes[0].borders[0]
          : null;
      if (
        selectedSize &&
        selectedSize.borders &&
        selectedSize.borders.length > 0
      ) {
        const lastBorder =
          selectedSize.borders[selectedSize.borders.length - 1];
        selectedBorder = lastBorder;
      }
      let selectedFrame = selectedBorder
        ? selectedBorder.frames[0]
        : mat.aspectRatios[0] &&
        mat.aspectRatios[0].sizes[0] &&
        mat.aspectRatios[0].sizes[0].borders[0] &&
        mat.aspectRatios[0].sizes[0].borders[0].frames[0]
          ? mat.aspectRatios[0].sizes[0].borders[0].frames[0]
          : null;
      let allBorders = selectedSize
        ? selectedSize.borders
        : mat.aspectRatios[0] &&
        mat.aspectRatios[0].sizes[0] &&
        mat.aspectRatios[0].sizes[0].borders
          ? mat.aspectRatios[0] &&
          mat.aspectRatios[0].sizes[0] &&
          mat.aspectRatios[0].sizes[0].borders
          : [];
      let allFrames = selectedBorder
        ? selectedBorder.frames
        : mat.aspectRatios[0] &&
        mat.aspectRatios[0].sizes[0] &&
        mat.aspectRatios[0].sizes[0].borders[0].frames
          ? mat.aspectRatios[0] &&
          mat.aspectRatios[0].sizes[0] &&
          mat.aspectRatios[0].sizes[0].borders[0].frames
          : [];
      if (mat.type === "Rolled Paper" || mat.type === "Stretched Canvas") {
        allFrames = [];
      }
      if (mat.type === "Stretched Canvas" || mat.type === "Framed Canvas") {
        allBorders = [];
      }
      return {
        ...fields,
        selectedCanvas: canvas,
        wrap: mat.type === "Stretched Canvas" ? selectedWrap : null,
        selectedMaterial: mat,
        allSizes: mat.aspectRatios[0].sizes,
        selectedSize: selectedSize,
        selectedBorder: selectedBorder,
        selectedFrame: selectedFrame,
        allBorders: allBorders,
        allFrames: allFrames,
      };
    });
  };

  const changeCanvas = (canvas) => {
    changeMaterial(canvas.material[0], canvas);
  };

  const changeSize = async (size) => {
    let selectedBorder;
    if (size && size.borders && size.borders.length > 0) {
      const lastBorder = size.borders[size.borders.length - 1];
      selectedBorder = lastBorder;
    } else {
      selectedBorder = null;
    }

    setFields({
      ...fields,
      selectedSize: size,
      allBorders:
        fields.selectedCanvas.id === "Stretched Canvas" ||
        fields.selectedCanvas.id === "Framed Canvas"
          ? []
          : size.borders,
      selectedBorder: selectedBorder,
      selectedFrame: size.borders[0].frames[0],
      wrap:
        fields.selectedCanvas.id === "Stretched Canvas" ? selectedWrap : null,
      allFrames:
        fields.selectedCanvas.id === "Rolled Paper" ||
        fields.selectedCanvas.id === "Stretched Canvas"
          ? []
          : size.borders[0].frames,
    });
  };

  const changeBorder = (border) => {
    setFields({
      ...fields,
      selectedBorder: border,
      allFrames:
        fields.selectedCanvas.id === "Rolled Paper" ? [] : border.frames,
      wrap:
        fields.selectedCanvas.id === "Stretched Canvas" ? selectedWrap : null,
      selectedFrame:
        fields.selectedCanvas.id === "Rolled Paper"
          ? border.frames[0]
          : fields.selectedCanvas.id === "Framed Paper"
            ? fields.selectedFrame
            : null,
    });
  };

  const changeFrame = (frame) => {
    setFields({
      ...fields,
      wrap: selectedWrap,
      selectedFrame: frame,
    });
  };

  const onPriceChange = (values) => {
    //debugger
    setPriceValue(values.value);
    //call it here
    if (currentTab === "product") {
      callingPriceEstimator(values.value);
    } else if (currentTab === "table") {
      callingPriceTable(values.value);
    }
  };

  let finalPrice = 0;
  if (fields && fields.selectedFrame) {
    finalPrice = fields.selectedFrame.finalPrice;
  }

  if (open) {
    if (openOnceStatus) {
      //debugger
      setOpenOnceStatus(false);
    }
  }

  useEffect(() => {
    if (setPrice && typeof (setPrice) === "string") {
      setPriceValue(parseInt(setPrice));
      callingPriceEstimator(parseInt(setPrice));
      callingPriceTable(parseInt(setPrice));
    }
  }, [setPrice]);

  return (
    <PikchaModal
      fullWidth={false}
      onClose={onClose}
      maxWidth="lg"
      open={open}
      PaperProps={{
        style: {
          overflow: "hidden",
          width: 700,
          height: 660
        },
      }}
      minHeight={1}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "1.9em",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            maxHeight: 99
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <div
              style={{
                marginRight: "1em",
              }}
            >
              <div>
                <strong>Enter Your Earnings</strong>
              </div>
              <PriceField
                onValueChange={onPriceChange}
                value={priceValue}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: palette.grey.lighter,
            height: 1,
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        />
        {artistTemplatesLoading && (
          <div
            style={{
              marginTop: "2em",
              minWidth: 400,
              minHeight: 400,
            }}
          >
            <PikchaLoader />
          </div>
        )}
        {!artistTemplatesLoading && currentTab === "product" && (
          <div>
            <div
              style={{
                display: "flex",
                marginBottom: "3em"
              }}
            >
              <div>
                <strong>Final Retail Price</strong>
              </div>
              <div
                style={{
                  marginLeft: "1em",
                  fontWeight: typography.fontWeightBold,
                  color: palette.purple.medium,
                }}
              >
                <IntlCurrencyFormatter value={+finalPrice} localConversion={true} displayFormat={currencyFormat.code} />
              </div>
            </div>
            <div
              style={{
                maxWidth: 400,
                display: "flex",
                flexWrap: "wrap",
                width: "fit-content",
              }}
            >
              {fields.allMaterials.map((material, index) => (
                <div
                  style={{
                    alignItems: "center",
                    marginBottom: index === 0 ? "0.6em" : undefined,
                    marginRight: "0.8em",
                  }}
                  key={index}
                >
                  <PikchaImageButton
                    active={fields.selectedCanvas.id === material.id}
                    noHoverEffect
                    action={"secondary"}
                    image={material.imageUrl}
                    fullWidth
                    onClick={(e) => changeCanvas(material)}
                  >
                    <span
                      style={{
                        margin: "auto",
                        padding: "2%",
                        lineHeight: "1.1rem"
                      }}
                    >
                      {material.label}
                    </span>
                  </PikchaImageButton>
                </div>
              ))}
            </div>

            {fields.selectedCanvas && (
              <>
                <div
                  style={{
                    fontWeight: typography.fontWeightMedium,
                    fontSize: typography.small,
                    color: palette.text.secondary,
                  }}
                >
                  Material
                </div>
                <div
                  style={{
                    maxWidth: 400,
                    display: "flex",
                    flexWrap: "wrap",
                    width: "fit-content",
                  }}
                >
                  {fields.selectedCanvas &&
                  fields.selectedCanvas.material.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: "0.3em",
                        marginRight: "0.6em",
                      }}
                    >
                      <PikchaButton
                        active={
                          fields.selectedMaterial &&
                          fields.selectedMaterial.id === item.id
                        }
                        action={"secondary"}
                        noHoverEffect
                        onClick={(e) =>
                          changeMaterial(item, fields.selectedCanvas)
                        }
                        style={{
                          height: 40,
                        }}
                      >
                        {item.label}
                      </PikchaButton>
                    </div>
                  ))}
                </div>
              </>
            )}

            {fields.allSizes.length > 0 ? (
              <>
                <div
                  style={{
                    fontWeight: typography.fontWeightMedium,
                    fontSize: typography.small,
                    color: palette.text.secondary,
                  }}
                >
                  Size
                </div>
                <div
                  style={{
                    maxWidth: 400,
                    display: "flex",
                    flexWrap: "wrap",
                    width: "fit-content",
                  }}
                >
                  {fields.allSizes.length > 0 &&
                  fields.allSizes.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: "0.3em",
                        marginRight: "0.6em",
                      }}
                    >
                      <PikchaButton
                        action={"secondary"}
                        active={
                          item &&
                          !!fields.selectedSize &&
                          fields.selectedSize.id === item.id
                        }
                        noHoverEffect
                        onClick={(e) => changeSize(item)}
                        style={{
                          height: 40,
                        }}
                      >
                        {item.label}
                      </PikchaButton>
                    </div>
                  ))}
                </div>
              </>
            ) : null}

            {fields.selectedCanvas &&
            fields.selectedCanvas.id === "Stretched Canvas" && (
              <div
                style={{
                  marginTop: "0.8em",
                }}
              >
                <div
                  className="text-left"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: typography.fontWeightMedium,
                      fontSize: typography.small,
                      color: palette.text.secondary,
                    }}
                  >
                    Wrap
                  </div>
                </div>
                <div
                  style={{
                    maxWidth: 400,
                    display: "flex",
                    flexWrap: "wrap",
                    width: "fit-content",
                  }}
                >
                  {wrap.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          marginTop: "0.3em",
                          marginRight: "0.6em",
                        }}
                      >
                        <PikchaButton
                          action={"secondary"}
                          active={selectedWrap && selectedWrap === item}
                          noHoverEffect
                          onClick={(e) => {
                            setSelectedWrap(item);
                            setFields({
                              ...fields,
                              wrap: item,
                            });
                          }}
                          style={{
                            height: 40,
                          }}
                        >
                          {item}
                        </PikchaButton>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {fields.allBorders && fields.allBorders.length > 0 && (
              <div
                style={{
                  marginTop: "0.8em",
                }}
              >
                <div>
                  {fields.allBorders.length ? (
                    <div
                      style={{
                        fontWeight: typography.fontWeightMedium,
                        fontSize: typography.small,
                        color: palette.text.secondary,
                      }}
                    >
                      Border
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    maxWidth: 400,
                    display: "flex",
                    flexWrap: "wrap",
                    width: "fit-content",
                  }}
                >
                  {fields.allBorders &&
                  fields.allBorders.length > 0 &&
                  fields.allBorders.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: "0.3em",
                        marginRight: "0.6em",
                      }}
                    >
                      <PikchaButton
                        action={"secondary"}
                        active={
                          fields.selectedBorder &&
                          fields.selectedBorder.id === item.id
                        }
                        noHoverEffect
                        onClick={(e) => changeBorder(item)}
                        style={{
                          height: 40,
                        }}
                      >
                        {item.label}
                      </PikchaButton>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {fields.allFrames && fields.allFrames.length > 0 && (
              <div
                style={{
                  marginTop: "0.8em",
                }}
              >
                <div>
                  {fields.allFrames.length ? (
                    <div
                      style={{
                        fontWeight: typography.fontWeightMedium,
                        fontSize: typography.small,
                        color: palette.text.secondary,
                      }}
                    >
                      Frame
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    maxWidth: 400,
                    display: "flex",
                    flexWrap: "wrap",
                    width: "fit-content",
                  }}
                >
                  {fields.allFrames &&
                  fields.allFrames.length > 0 &&
                  fields.allFrames.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        marginTop: "0.3em",
                        marginRight: "0.6em",
                      }}
                    >
                      <PikchaButton
                        action={"secondary"}
                        active={
                          fields.selectedFrame &&
                          fields.selectedFrame.id === item.id
                        }
                        noHoverEffect
                        onClick={() => changeFrame(item)}
                        style={{
                          height: 40,
                        }}
                      >
                        {item.label}
                      </PikchaButton>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {!artistTemplatesLoading && currentTab === "table" && (
          <StyledTableContainer
            children={<table></table>}
            component={Paper}
          >
            <Table aria-label="simple table">
              <TableHead style={{height: 48}}>
                <TableRow style={{height: 48}}>
                  <StyledTableCell
                    align={"center"}
                    style={{
                      backgroundColor: palette.grey.light,
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      fontSize: typography.smallest,
                      maxWidth: 100,
                      width: 100,
                      lineHeight: "inherit",
                      fontWeight: typography.fontWeightMedium,
                      color: palette.text.primary
                    }}
                  >
                    Final Order
                  </StyledTableCell>
                  <StyledTableCell
                    align={"center"}
                    style={{
                      backgroundColor: palette.grey.light,
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      fontSize: typography.smallest,
                      maxWidth: 100,
                      width: 100,
                      lineHeight: "inherit",
                      fontWeight: typography.fontWeightMedium,
                      color: palette.text.primary
                    }}
                  >
                    Product Format
                  </StyledTableCell>
                  <StyledTableCell
                    align={"center"}
                    style={{
                      backgroundColor: palette.grey.light,
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      fontSize: typography.smallest,
                      maxWidth: 100,
                      width: 100,
                      lineHeight: "inherit",
                      fontWeight: typography.fontWeightMedium,
                      color: palette.text.primary
                    }}
                  >
                    Print Material
                  </StyledTableCell>
                  <StyledTableCell
                    align={"center"}
                    style={{
                      backgroundColor: palette.grey.light,
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      fontSize: typography.smallest,
                      maxWidth: 100,
                      width: 100,
                      lineHeight: "inherit",
                      fontWeight: typography.fontWeightMedium,
                      color: palette.text.primary
                    }}
                  >
                    Size
                  </StyledTableCell>
                  <StyledTableCell
                    align={"center"}
                    style={{
                      backgroundColor: palette.grey.light,
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      fontSize: typography.smallest,
                      maxWidth: 100,
                      width: 100,
                      lineHeight: "inherit",
                      fontWeight: typography.fontWeightMedium,
                      color: palette.text.primary
                    }}
                  >
                    Border
                  </StyledTableCell>
                  <StyledTableCell
                    align={"center"}
                    style={{
                      backgroundColor: palette.grey.light,
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      fontSize: typography.smallest,
                      maxWidth: 100,
                      width: 100,
                      lineHeight: "inherit",
                      fontWeight: typography.fontWeightMedium,
                      color: palette.text.primary
                    }}
                  >
                    Frame
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(priceList).map((item, index) => {
                  return (
                    <TableRow key={index} style={{height: 48}}>
                      <StyledTableCell
                        align={"center"}
                        style={{
                          fontSize: typography.tiny,
                          fontWeight: typography.fontWeightSemiMedium,
                          color: palette.text.primary,
                          maxWidth: 100,
                          width: 100
                        }}
                      >
                        <IntlCurrencyFormatter value={+item.price} localConversion={true}
                                               />
                      </StyledTableCell>
                      <StyledTableCell
                        align={"center"}
                        style={{
                          fontSize: typography.tiny,
                          fontWeight: typography.fontWeightSemiMedium,
                          color: palette.text.primary,
                          maxWidth: 100,
                          width: 100
                        }}
                      >
                        {item.type}
                      </StyledTableCell>
                      <StyledTableCell
                        align={"center"}
                        style={{
                          fontSize: typography.tiny,
                          fontWeight: typography.fontWeightSemiMedium,
                          color: palette.text.primary,
                          maxWidth: 100,
                          width: 100
                        }}
                      >
                        {item.material}
                      </StyledTableCell>
                      <StyledTableCell
                        align={"center"}
                        style={{
                          fontSize: typography.tiny,
                          fontWeight: typography.fontWeightSemiMedium,
                          color: palette.text.primary,
                          maxWidth: 100,
                          width: 100
                        }}
                      >
                        {item.size}
                      </StyledTableCell>
                      <StyledTableCell
                        align={"center"}
                        style={{
                          fontSize: typography.tiny,
                          fontWeight: typography.fontWeightSemiMedium,
                          color: palette.text.primary,
                          maxWidth: 100,
                          width: 100
                        }}
                      >
                        {item.border}
                      </StyledTableCell>
                      <StyledTableCell
                        align={"center"}
                        style={{
                          fontSize: typography.tiny,
                          fontWeight: typography.fontWeightSemiMedium,
                          color: palette.text.primary,
                          maxWidth: 100,
                          width: 100
                        }}
                      >
                        {item.frame}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter
                style={{
                  height: 12,
                  backgroundColor: palette.grey.light,
                  position: "sticky",
                  borderBottomRightRadius: shape.borderRadius,
                  borderBottomLeftRadius: shape.borderRadius
                }}
              >
                <TableRow
                  style={{
                    height: 12,
                    backgroundColor: palette.grey.light,
                    position: "sticky",
                    borderBottomRightRadius: shape.borderRadius,
                    borderBottomLeftRadius: shape.borderRadius
                  }}
                />
              </TableFooter>
            </Table>
          </StyledTableContainer>
        )}
      </div>
    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(ArtworkPricesModal);
