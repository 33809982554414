import axios from "axios";

import AuthorizeService from "../auth/AuthorizeService";

import {
  SEARCH_ERROR,
  SEARCH_LOADED,
  SEARCH_LOADING,
  SEARCH_RESET,
  SEARCH_ARTISTS_LOADING,
  SEARCH_ARTISTS_LOADED,
  SEARCH_ALBUMS_LOADING,
  SEARCH_ALBUMS_LOADED,
  RELATED_ALBUMS_LOADING,
  RELATED_ALBUMS_LOADED,
  RELATED_ALBUMS_RESET,
} from "./types";

import { searchAPIUrl } from "../selectors/settings";

export const search = ({
  searchTerm,
  sortBy,
  imageType = [],
  imageCategory = [],
  location = "",
  imageOrientation = "",
  imageColour = "",
  count = 15,
  start = 1,
} = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SEARCH_LOADING,
      });

      const searchApiUrl = searchAPIUrl(getState());

      const parameters = {
        SearchText: searchTerm,
        ImageType: imageType,
        Category: imageCategory,
        Colour: imageColour,
        Orientation: imageOrientation,
        Location: location,
        SortBy: sortBy, //MS,MV,PHL,PLH,RV
        Page: Math.ceil(Math.max(start, 1) / Math.max(count, 1)),
        ItemInPage: 15, //TODO change 5 to count
      };

      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `${searchApiUrl}/api/search/images`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return dispatch({
        type: SEARCH_LOADED,
        payload: res.data,
      });
    } catch (err) {
      return dispatch({
        type: SEARCH_ERROR,
        payload: err.response,
      });
    }
  };
};

export const searchArtists = ({
  searchTerm,
  sortBy,
  country,
  count = 15,
  start = 1,
} = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SEARCH_ARTISTS_LOADING,
      });

      const searchApiUrl = searchAPIUrl(getState());

      const parameters = {
        SearchText: searchTerm,
        Nationality: country,
        SortBy: sortBy, //MS,MV,PHL,PLH,RV
        Page: Math.ceil(Math.max(start, 1) / Math.max(count, 1)),
        ItemInPage: count,
      };

      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `${searchApiUrl}/api/search/artists`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return dispatch({
        type: SEARCH_ARTISTS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      return dispatch({
        type: SEARCH_ERROR,
        payload: err.response,
      });
    }
  };
};

export const searchAlbums = ({
  searchTerm,
  sortBy,
  count = 20,
  start = 1,
} = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SEARCH_ALBUMS_LOADING, 
      });
      
      const searchApiUrl = searchAPIUrl(getState());

      const parameters = {
        SearchText: searchTerm,
        SortBy: sortBy, //MS,MV,PHL,PLH,RV
        Page: Math.ceil(Math.max(start, 1) / Math.max(count, 1)),
        ItemInPage: count,
      };

      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `${searchApiUrl}/api/search/albums`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return dispatch({
        type: SEARCH_ALBUMS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      return dispatch({
        type: SEARCH_ERROR,
        payload: err.response,
      });
    }
  };
};

export const searchAlbumsByImage = (
  imageId,
 ) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: RELATED_ALBUMS_LOADING, 
      });
      
      let searchApiUrl = searchAPIUrl(getState());
      if(!searchApiUrl){
        searchApiUrl = `${
          process.env.NODE_ENV === "production"
            ? ""
            //: "http://localhost:1900"
            : "https://pikcha.local:1900"
        }/v1/search`;
      }

      const parameters = {
        ImageId: imageId,
      };

      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `${searchApiUrl}/api/search/albumsContain`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log(res.data);
      return dispatch({
        type: RELATED_ALBUMS_LOADED,
        payload: res.data,
      });
    } catch (err) {
      return dispatch({
        type: RELATED_ALBUMS_RESET,
        payload: err.response,
      });
    }
  };
};

export const resetSearch = () => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_RESET,
    });
  };
};
