import React from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import { useTheme } from "@material-ui/core/styles";

import {
  PikchaReusableDropdown,
  PikchaReusableTabsGroup,
  PikchaReusableTab
} from "../../components/baseComponents";
import FilterIcon from "../../assets/images/FilterIcon.svg";
import PublicRoundedIcon from "@material-ui/icons/PublicRounded";

import isDesktopHD from "../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../components/Responsive/isDesktopSD";
import isDesktop from "../../components/Responsive/isDesktop";
import isTablet from "../../components/Responsive/isTablet";
import AlbumGallery from "../../containers/gallery/AlbumGallery/AlbumGallery";
import isMobile from "../../components/Responsive/isMobile";

export default ({
  toggleSafeSearch,
  searchTerm,
  isSearchLoading,
  resultsType,
  resultsTypeChange,
  sortBy,
  onSortByChange,
  onClearFilters,
  onApplyFilters,

  albums,
  getAlbums,
  resetAlbums,
}) => {
  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();

  const { palette, typography } = useTheme();
  
  let albumRows = albums.albums;
  //console.log(isSearchLoading);
  if (isSearchLoading) {
    for (let i = 0; i < 8; i++) {
      albumRows = [
        ...albumRows,
        ...[
          {
            placeholder: true,
          },
        ],
      ];
    }
  }

  const onGetAlbums = (count, start) => {
    if (albums.loading) {
      return;
    }

    //getAlbums(userProfileId);
  };
  const onResetAlbums = () => {
    resetAlbums();
  };
  const onRefreshAlbums = () => {
    resetAlbums();
    //getAlbums(userProfileId);
  };


  const showBlankState = !isSearchLoading && albumRows.length < 1;

  return (
    <div
      style={{
        paddingTop: "4em",
        width: "100%",
      }}
    >
      <div
        style={{
          paddingLeft: isOnDesktopHD
            ? "4em"
            : isOnDesktopSD
              ? "2em"
              : isOnTablet
                ? "1em"
                : "1em",
          paddingRight: isOnDesktopHD
            ? "4em"
            : isOnDesktopSD
              ? "2em"
              : isOnTablet
                ? "1em"
                : "1em",
        }}
      >
        <div
          style={{
            marginTop: "4em",
            marginBottom: "2em"
          }}
        >
          <div
            style={{
              display: isOnMobile? "block":"flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                color: palette.text.primary,
                fontSize: typography.large,
                fontWeight: typography.fontWeightBold,
                textTransform: "capitalize"
              }}
            >
              {searchTerm}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            marginTop: isOnMobile? "1em": undefined,
          }}
        >
            <div
              style={{
                marginLeft: isOnMobile? undefined : null,
                marginTop: isOnMobile? "3em": undefined ,
              }}
            >
              <PikchaReusableTabsGroup
                value={resultsType}
                onChange={resultsTypeChange}
                aria-label="Results Type Selector"
              >
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Pikcha"
                  label="Image"
                  aria-label="Pikcha"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                    marginRight: "2em"
                  }}
                  value="Artist"
                  label="Artist"
                  aria-label="Artist"
                />
                <PikchaReusableTab
                  style={{
                    width: 60,
                    height: 12,
                  }}
                  value="Album"
                  label="Album"
                  aria-label="Album"
                />
              </PikchaReusableTabsGroup>
            </div>
            <div
            style={{
              width: 232,
              marginLeft: "auto",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            <PikchaReusableDropdown
              onChange={(sortBy)=>{onSortByChange(sortBy)}}
              style={{
                width: 152,
                fontWeight: 600,
                fontSize: ".93rem"
              }}
              itemStyle={{
                width: 200,
                fontWeight: 600,
                fontSize: ".93rem"
              }}
              options={[
                { value: "RV", label: "Recommended" },
                { value: "AVW", label: "Most Viewed" },
                { value: "APS", label: "Most Percentage Sold" },
                { value: "AVG", label: "Most Avarage Price" },
                { value: "ATI", label: "Most Images in Album" },
              ]}
              value={sortBy}
            />
          </div>
        </div>
        {resultsType === "Album" && (
          <div
            style={{
              marginTop: "4em",
              textAlign: "center",
            }}
          >
            <AlbumGallery
              albums={albums}
              getAlbums={getAlbums}
              resetAlbums={onResetAlbums}
              refreshAlbums={onRefreshAlbums}
              creatable={false}
              isInSearchPage={true}
              isInProfilePage={false}
              isInRelatedAlbumPage={false}
            />
          </div>
        )}
        <div
          style={{
            paddingBottom: "4em",
          }}
        />
      </div>
    </div>
  );
};
