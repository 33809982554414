import React from 'react';
import { useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Title = ({ children, ...rest }) => {
  const { typography } = useTheme();

  return (
    <Typography
      component="h1"
      style={{
        fontSize: 54,
        fontWeight: typography.fontWeightBold,
        fontFamily: typography.fontFamilySerif,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

export default Title;
