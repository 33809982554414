import {
  ALBUMS_ERROR,
  ALBUMS_IMAGE_DESELECTED,
  ALBUMS_IMAGE_REARRANGE,
  ALBUMS_IMAGE_SELECTED,
  ALBUMS_IMAGES_SELECTED,
  ALBUMS_LOADED,
  ALBUMS_LOADING,
  ALBUMS_RESET,
  ALBUMS_SELECTED_IMAGES_RESET,
} from '../actions/types';

const initialState = {
  albums: [],
  selectedImages: [],
  count: 15,
  start: 1,
  loading: false,
  error: null,
  hasMore: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ALBUMS_LOADED:
      return {
        ...state,
        albums: [...state.albums, ...payload],
        loading: false,
        start: state.start + 1,
        hasMore: payload.length >= state.count,
      };
    case ALBUMS_ERROR:
      // payload.data === "You have reached the end." && console.error(payload);
      return {
        ...state,
        error: payload,
        loading: false,
        hasMore: false,
        // hasMore: !payload.data === "You have reached the end.",
      };
    case ALBUMS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case ALBUMS_IMAGE_SELECTED: {
      const selectedImages = [...state.selectedImages, ...[payload]];
      return {
        ...state,
        ...{
          selectedImages,
        },
      };
    }
    case ALBUMS_IMAGE_DESELECTED: {
      const deselectedImageId = payload.id;
      const selectedImages = state.selectedImages.reduce(
        (accumulator, selectedImageId) => {
          if (selectedImageId.id === deselectedImageId) {
            return accumulator;
          }
          return [...accumulator, ...[selectedImageId]];
        },
        [],
      );
      return {
        ...state,
        ...{
          selectedImages,
        },
      };
    }
    case ALBUMS_IMAGE_REARRANGE: {
      const { removedIndex, addedIndex, imageId } = payload;

      let itemToAdd = imageId;
      const { selectedImages } = state;

      let theRemovingIndex = removedIndex;
      if (theRemovingIndex > selectedImages.length) {
        theRemovingIndex = selectedImages.length;
      }
      let theAddedIndex = addedIndex;
      if (theAddedIndex > selectedImages.length) {
        theAddedIndex = selectedImages.length;
      }

      if (theRemovingIndex !== null) {
        itemToAdd = selectedImages.splice(theRemovingIndex, 1)[0];
      }

      if (theAddedIndex !== null) {
        selectedImages.splice(theAddedIndex, 0, itemToAdd);
      }
      return {
        ...state,
        ...{
          selectedImages,
        },
      };
    }

    case ALBUMS_SELECTED_IMAGES_RESET:
      return {
        ...state,
        ...{
          selectedImages: [],
        },
      };

    case ALBUMS_IMAGES_SELECTED: {
      const selectedImages = payload;
      return {
        ...state,
        ...{
          selectedImages,
        },
      };
    }

    case ALBUMS_RESET:
      return initialState;

    default:
      return state;
  }
};
