import React, {useEffect, useState, useRef} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTheme} from "@material-ui/core/styles";

import {
  PikchaLoader,
  PikchaMasonryTitle,
} from "../../../components/baseComponents";
import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import isDesktopSD from "../../../components/Responsive/isDesktopSD";
import isDesktop from "../../../components/Responsive/isDesktop";
import isTablet from "../../../components/Responsive/isTablet";
import isMobile from "../../../components/Responsive/isMobile";
import MasonryColumn from "../MasonryColumn";

export default ({
                  images,
                  isFollowing,
                  //inSavedImagePage,
                  onMasonryModalClosed,
                  title,
                  gallery,
                  getPhotos,
                  resetGallery,
                  onClickGalleryImage,
                  fromProfile,
                  noContainerMargins,
                  restrict = [],
                  noBackground,
                  noItemHeaders,
                } = {}) => {

  const isOnDesktopHD = isDesktopHD();
  const isOnDesktopSD = isDesktopSD();
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();
  const columns = isOnDesktop ? 3 : isOnTablet ? 2 : 1;
  const galleryWrapper = useRef(null);
  const [galleryWrapperWidth, setGalleryWrapperWidth] = useState(galleryWrapper.current ? window.getComputedStyle(galleryWrapper.current) : 119.2);

  const shouldShowFollowing = isFollowing || false;
  //const showSavedImages = inSavedImagePage || false;

  const {palette, typography} = useTheme();

  const clickImage = () => {
    if (onClickGalleryImage) onClickGalleryImage();
  };
  const separatePhotos = () => {
    const {photos: galleryPhotos, loading} = gallery;

    let photos = [];

    if (images && images.photos) {
      photos = images.photos;
    } else if (restrict || fromProfile) {
      photos = gallery.photos;
    }


    photos = photos.reduce((accumulator, item) => {
      const {id} = item;
      if (restrict.some((restrictedItem) => restrictedItem === id)) {
        return accumulator;
      }
      return [...accumulator, ...[item]];
    }, []);

    const res = [...Array(columns).keys()].map((c) =>
      photos.filter((_, i) => i % columns === c)
    );

    const columnsToMap = [...Array(columns)];

    return columnsToMap.map((column, index) => {
      const isFirstColumn = index === 0;
      const isLastColumn = index + 1 === columnsToMap.length;
      return (
        <MasonryColumn
          imageClicked={clickImage}
          key={index + 1}
          noItemHeaders={noItemHeaders}
          onMasonryModalClosed={onMasonryModalClosed}
          photos={res[index]}
          galleryWrapperWidth={galleryWrapperWidth}
          style={{
            marginLeft: !isFirstColumn
              ? isOnDesktopHD
                ? "1.5em"
                : isOnDesktopSD
                  ? "1.1em"
                  : isOnTablet
                  ? "1.1em"
                  : isOnMobile 
                  ? 0
                  : "0.5em"
                : 0,
            marginRight: !isLastColumn
              ? isOnDesktopHD
                ? "1.5em"
                : isOnDesktopSD
                  ? "1.1em"
                  : isOnTablet
                  ? "1.1em"
                  : isOnMobile
                  ? 0
                  : "0.5em"
                : 0,
          }}
        />
      );
    });
  };

  const handleResize = () => {
    const { width } = galleryWrapper.current ? window.getComputedStyle(galleryWrapper.current) : {};
    const widthValue = isOnDesktopHD ? parseFloat(width)-128 : isOnDesktopSD ? parseFloat(width)-64 : isOnTablet ? parseFloat(width)-32 : parseFloat(width)-16;
    setGalleryWrapperWidth(widthValue);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize()
  }, [galleryWrapper]);

  return (
    <div>
      {title !== "undefined" && (
        <PikchaMasonryTitle>{title}</PikchaMasonryTitle>
      )}
      <InfiniteScroll
        dataLength={gallery.photos.length}
        style={{
          overflow: "hidden",
        }}
        next={() => {
          //if(gallery.start != null){
            //console.log("loading next batch, page no ",gallery.start);
            getPhotos(gallery.count, gallery.start)
          //}
          }
        }
        hasMore={
          gallery.hasMore
        }
        loader={<PikchaLoader marginTop={typography.xLarge} marginBottom= {isOnMobile?"500px":"800px"}/>}
        endMessage={
          gallery.photos.length !== 0 ? (
            <p
              style={{
                textAlign: "center",
                marginTop: "5em",
                fontSize: typography.small,
                fontWeight: typography.fontWeightSemiMedium,
                color: palette.text.secondary,
              }}
            >
              You've seen all the images
            </p>
          ) : null
        }
      >
        <div
          className={`${!fromProfile ? "masonry" : "Profile-masonry"} ${
            isFollowing ? "pb-2-em" : ""
          }`}
          ref={galleryWrapper}
          style={{
            marginTop: "2em",
            paddingTop: shouldShowFollowing ? "1.5em" : null,
            paddingLeft:
              noContainerMargins || fromProfile
                ? null
                : isOnDesktopHD
                ? "4em"
                : isOnDesktopSD
                  ? "2em"
                  : isOnTablet
                    ? "1em"
                    : "0.5em",
            paddingRight:
              noContainerMargins || fromProfile
                ? null
                : isOnDesktopHD
                ? "4em"
                : isOnDesktopSD
                  ? "2em"
                  : isOnTablet
                    ? "1em"
                    : "0.5em",
            background: noBackground ? null : palette.background.default,
          }}
        >
          {separatePhotos()}
        </div>
      </InfiniteScroll>
    </div>
  );
};
