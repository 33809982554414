import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import ExitToAppRounded from "@material-ui/icons/ExitToAppRounded";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";

import { PikchaNoUnderlineLink } from "../baseComponents";
import { closeDropdown, openFollowing } from "../../actions/nav";
import { ApplicationPaths } from "../../auth/ApiAuthorizationConstants";
import { getProfile } from "../../actions/profile";

const ProfileDropdown = ({ account, closeDropdown, getProfile }) => {
  const history = useHistory();

  const dropdown = useRef(null);
  useEffect(() => {
    const handleClick = (e) => {
      if (
        dropdown &&
        dropdown.current &&
        !dropdown.current.contains(e.target)
      ) {
        closeDropdown();
      }
    };

    setTimeout(() => {
      document.addEventListener("mousedown", handleClick);
    }, 250);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const { typography, shape } = useTheme();

  const handleProfileChange = () => {
    history.push(`/${account.user.userHandle}`);
  };

  return (
    <div
      className="Dropdown profile-dropdown no-select pointer"
      style={{
        zIndex: 99999999,
        borderRadius: shape.borderRadius,
      }}
      ref={dropdown}
    >
      <div
        className="NavBar-navLink"
      >
        <div
          onClick={() => {
            closeDropdown();
            handleProfileChange();
            window.scrollTo(0,0);
          }}
          className="DropdownItem firstInList"
        >
          <div
            className="ProfileDropdown-iconWrapper flex-center"
            style={{ fontSize: typography.large }}
          >
            <AccountCircleRoundedIcon fontSize="inherit" />
          </div>
          <div className="ProfileDropdown-textWrapper flex-center">Profile</div>
        </div>
      </div>
      <PikchaNoUnderlineLink to="/account" className="NavBar-navLink">
        <div onClick={() => closeDropdown()} className="DropdownItem">
          <div
            className="ProfileDropdown-iconWrapper flex-center"
            style={{ fontSize: typography.large }}
          >
            <SettingsRoundedIcon fontSize="inherit" />
          </div>
          <div className="ProfileDropdown-textWrapper flex-center">Account</div>
        </div>
      </PikchaNoUnderlineLink>
      <Divider />
      <PikchaNoUnderlineLink
        to={ApplicationPaths.LogOut}
        className="NavBar-navLink"
      >
        <div className="DropdownItem lastInList">
          <div
            className="ProfileDropdown-iconWrapper flex-center"
            style={{ fontSize: typography.large }}
          >
            <ExitToAppRounded fontSize="inherit" />
          </div>
          <div className="ProfileDropdown-textWrapper flex-center">Logout</div>
        </div>
      </PikchaNoUnderlineLink>
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  closeDropdown,
  openFollowing,
  getProfile,
})(ProfileDropdown);
