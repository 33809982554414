import React from 'react';
import { 
  TypographySubtitle, 
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  colors
} from "@pikcha/typography";
import {
  PikchaLoader
} from "../../components/baseComponents";
import { PIKCHA_CDN } from "../../helpers/constants";

export default ({
  heroShotsPending,
  selectedHeroShots
}) => {
  return (
    <div
      className="full-height full-width absolute"
      style={{
        borderRadius: 6,
        top: 0,
        left: 0,
        zIndex: 10,
        backgroundColor: colors.Neutral.grey20,
        backgroundImage: !heroShotsPending && `url(${PIKCHA_CDN}/${selectedHeroShots})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {heroShotsPending && (
        <>
          <TypographySubtitle
            component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
            style={{
              marginBottom: "2em"
            }}
          >
            Generating Thumbnail
          </TypographySubtitle>
          <PikchaLoader 
            size={{
              height: 90,
              width: 90
            }}
          />
        </>
      )}
    </div>
  )
}
