﻿import React from "react";
import {useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

// Defaults to serif font-family unless the prop `sansSerif` is passed.
const Text = ({large, customLineHeight, sansSerif = false, children, ...rest}) => {
  const {typography} = useTheme();

  return (
    <Typography
      component="p"
      style={{
        fontSize: !large ? 16 : 24,
        lineHeight:  customLineHeight? "140%" : !large ? "125%" : "140%",
        fontWeight: !large ? typography.fontWeightSemiMedium : typography.fontWeightMedium,
        fontFamily: sansSerif ? typography.fontFamily : typography.fontFamilySerif,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
}

export default Text;
