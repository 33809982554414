import {
  GET_USER_IP, GET_USER_LOCATION,
} from "./types";
import publicIp from "public-ip";

export const getUserIpaddress = () => {
  return async (dispatch) => {
    try {
      let ipAddress = await publicIp.v4();
      dispatch({
        type: GET_USER_IP,
        payload: ipAddress,
      });
    } catch (e) {
      console.log('Fetching location info error !', e);
    }
  };
};

export const updateUserCountry = (userCountry) => {
  return (dispatch) => {
    dispatch({
      type: GET_USER_LOCATION,
      payload: userCountry,
    });
  }
}
