import React, { lazy, Suspense } from "react";
import { SignatureCanvasLoader } from "../skeletonLoaders";

const SignatureCanvas = lazy(() => import("./SignatureCanvas"));

export default (props) => (
  <Suspense fallback={SignatureCanvasLoader}>
    <SignatureCanvas {...props} />
  </Suspense>
);
