import React, { lazy, Suspense, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { isGalleryLoading } from "../../selectors/gallery";
import { useTheme } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";
import { TypographyBody, TypographyButton, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography";
import PikchaLogoWhite from "../../assets/images/Masonry/PikchaLogoWhite.svg"
import isMobile from "../../components/Responsive/isMobile";
import isDesktop from "../../components/Responsive/isDesktop";
import AuthorizeService from "../../auth/AuthorizeService";
import { useHistory } from 'react-router-dom';
const MasonryItem = lazy(() => import("../../components/galleryComponents/MasonryItem/MasonryItem"));

export const MasonryColumnLoader = (props) => {
  const { spacing, palette } = useTheme();
  return (
    <div
      style={{
        marginLeft: spacing(2),
      }}
    >
      <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        // viewBox="0 0 696 414"
        backgroundColor={palette.grey.lighter}
        foregroundColor={palette.grey.lighter}
        {...props}
      >
      </ContentLoader>
    </div>
  );
};

const MasonryColumn = ({
  columnNo,
  photos,
  noItemHeaders,
  onMasonryModalClosed,
  style,
  imageClicked,
  view,
  galleryWrapperWidth,
  showName,
  onClose
}) => {
  const [images, setImages] = useState([])
  const [selected] = useState(false);
  const column = useRef(null);

  const [itemWidth, setItemWidth] = useState(0);

  const [isHovering, setIsHovering] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [getLoggedInInfo, setGetLoggedInInfo] = useState(false);

  async function fetchAccessToken() {
    const token = await AuthorizeService.getAccessToken();
    if (token) {
      setIsLoggedIn(true);
    }
    setGetLoggedInInfo(true);
  }

  const handleHover = () => {
    setIsHovering(true);
  };

  const handleHoverLeave = () => {
    setIsHovering(false);
  };

  const isImageClicked = (e) => {
    if (imageClicked) {
      imageClicked(e);
    }
  };

  const handleResize = () => {
    const { width } = column.current
      ? window.getComputedStyle(column.current)
      : {};

    const widthValue = parseFloat(width);
    setItemWidth(widthValue);
  };

  useLayoutEffect(() => {
    handleResize();
  }, [column.current, isHovering]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    fetchAccessToken();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (columnNo == 1 && (view === 'MasonryGallery' || view === 'MasonryGallerySearch') && !isLoggedIn && getLoggedInInfo) {
      let images = []
      photos.forEach((image, ind) => {
        if ((ind + 7) % 10 == 0) {
          images.push({ isSignUp: true })
        }
        images.push(image)
      })
      setImages(images)
    } else {
      setImages(photos)
    }
  }, [
    photos, 
    isLoggedIn,
    columnNo,
    view,
    getLoggedInInfo
  ])

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
      className={`masonry-col ${selected && "selected"}`}
      ref={column}
      style={style}
    >
      {images?.map((photo, index) => (
        <Suspense fallback={() => { }}>
          {
            photo.isSignUp ?
              <SignUpBox />
              :
              <MasonryItem
                key={index}
                noItemHeaders={noItemHeaders}
                imageClicked={isImageClicked}
                index={index}
                photo={photo}
                itemWidth={itemWidth}
                galleryWrapperWidth={galleryWrapperWidth}
                onMasonryModalClosed={onMasonryModalClosed}
                showName={showName}
                onClose={onClose}
              />
          }
        </Suspense>
      ))}
    </div>
  );
};

const SignUpBox = () => {

  const isOnMobile = isMobile();
  const isOnDesktop = isDesktop();
  const signUpRef = useRef(null)
  const history = useHistory();
  return (
    <div
      ref={signUpRef}
      style={{
        width: '95%',
        margin: '0.5em 0.5em 2em'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'black',
          padding: !isOnMobile ? '2em 1em 0em 2em' : '2em 1em 0em 1em',
          minHeight: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: !isOnMobile && '3em',
            marginBottom: !isOnMobile ? '3em' : "1em",
            alignSelf: 'start ',
            maxWidth: isOnDesktop ? "60%" : '15em'
          }}
        >
          <TypographyBody
            size={isOnMobile ? TYPOGRAPHY_BODY_SIZE.Small_Bold : TYPOGRAPHY_BODY_SIZE.Default_Bold}
            style={{
              color: "white",
              marginBottom: '2em'
            }}
          >
            Sign up  for $50 USD off your first order
          </TypographyBody>
          <TypographyButton
            onClick={() => history.push('/authentication/login')}
            buttonStyle={{
              border: '1px solid white',
              width: !isOnMobile && '100%'
            }}
          >
            Sign up
          </TypographyButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'flex-end',
            marginBottom: '1em',
          }}
        >
          <img
            src={PikchaLogoWhite}
            width={33}
          />
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = (state) => ({
  isGalleryLoading: isGalleryLoading(state),
});

export default connect(mapStateToProps)(MasonryColumn);
