import React from 'react';
import {useTheme} from "@material-ui/core";
import GestureIcon from '@material-ui/icons/Gesture';
import {UploadIcon} from '../../../components/extraIcons';
import isTablet from "../../../components/Responsive/isTablet";
import MauroSignatureSample from "../../../assets/images/Mauro_signature_sample.png";


export default () => {
  const isOnTablet = isTablet();
  const {palette, shape, typography} = useTheme();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      {/* <PikchaSpacer />
      <div
        style={{
          width: "100%",
          height: 80,
          backgroundColor: palette.grey.lightest,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <h1
          style={{
            fontSize: "1.25em",
            fontWeight: 700,
            color: typography.primary
          }}
        >
          Digitally sign your first image
        </h1>
      </div> */}
      <div
        style={{
          padding: "20px 50px",
          display: "flex",
          flexDirection: isOnTablet ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-around",
          // height: "-moz-calc(100% - (80px))",
          // height: "-webkit-calc(100% - (80px))",
          height: "calc(100% - (80px))"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isOnTablet ? "row" : "column",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: 'start',
              // marginRight: !isOnTablet ? 200 : 72
            }}
          >
            <div
              style={{
                color: palette.purple.medium,
                // textAlign: "center",
                fontSize: "1em",
                fontWeight: 600
              }}
            >
              <strong>How We Sign and Number Artwork</strong>
            </div>
            <p
              style={{
                maxWidth: "420px",
                margin: "20px 0",
                fontSize: "0.75em",
                fontWeight: 500,
                color: typography.disabled
              }}
            >
              Signing your art adds an additional layer of authencity and security for your collectors and enables you
              to price your images higher.
            </p>
            <p
              style={{
                maxWidth: "420px",
                margin: "20px 0",
                fontSize: "0.75em",
                fontWeight: 500,
                color: typography.disabled
              }}
            >
              When we create artworks, your signature is imposed with the edition number/100 in the corner of the
              artwork.
            </p>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <img 
                src={PaperSigInfo}
                alt="first-upload-img"
                style={{
                  width: 180,
                  marginRight: 20,
                  borderRadius:shape.borderRadius,
                }}
              />
              <img 
                src={CanvasSigInfo}
                alt="first-upload-img"
                style={{
                  width: 180,
                  borderRadius:shape.borderRadius,
                }}
              />
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "start",
              marginTop: isOnTablet ? 0 : "2em",
              marginLeft: isOnTablet ? "2em" : 0,
            }}
          >
            <div
              style={{
                color: palette.purple.medium,
                // textAlign: "start",
                fontSize: "1em",
                fontWeight: 600
              }}
            >
              <strong>Create Your Artistic Signature</strong>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                margin: "20px 0"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50%"
                }}
              >
                <GestureIcon />
                <div
                  style={{
                    maxWidth: 125,
                    fontSize: "0.75em",
                    fontWeight: 500,
                    color: typography.disabled,
                    textAlign: "center",
                  }}
                >
                  <strong>Draw it</strong> manually using our smooth signature pad.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 40px",
                  textDecoration: "underline"
                }}
              >
                OR
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  width: "50%"
                }}
              >
                <UploadIcon fill={"#000"} />
                <div
                  style={{
                    maxWidth: 125,
                    fontSize: "0.75em",
                    fontWeight: 500,
                    color: typography.disabled,
                    textAlign: "center",
                  }}
                >
                  <strong>Upload</strong> an <strong>SVG</strong> of your signature on file.
                </div>
              </div>
            </div>
            <p
              style={{
                maxWidth: "380px",
                margin: "20px 0",
                fontSize: "0.75em",
                fontWeight: 500,
                color: typography.disabled
              }}
            >
              If you have <strong>already signed</strong> or you <strong>don't want to sign</strong>, just select "No
              Signature".
            </p>
          </div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
        }}>
          <div
            style={{
              width: 500,
              height: 375,
              backgroundImage: `url(${MauroSignatureSample})`,
              backgroundSize: "cover",
              borderRadius: 10,
              // alignSelf: "flex-end",
            }}
          />
          <p style={{
            fontWeight: 600,
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.3)",
            alignSelf: "flex-end",
            textAlign: "end",
            marginTop: "1em"
          }}>
            Regatta De Blue<br />
            <em>
              by Mauro Risch<br />
              1/100
            </em>
          </p>
        </div>
      </div>
    </div>
  );
}
