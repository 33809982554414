import React, { useState, useEffect } from 'react';
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  colors
} from '../../index.ts';
//@ts-ignore
import isTablet from "../../../../web-app/src/components/Responsive/isTablet";
//@ts-ignore
import isDesktop from "../../../../web-app/src/components/Responsive/isDesktop"

export default ({
  countingDownTime,
  getIsPublished
}) => {
  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  const [days, setDays] = useState("-");
  const [hours, setHours] = useState("-");
  const [minutes, setMinutes] = useState("-");
  const [seconds, setSeconds] = useState("-");
  const [publishedAlready, setPublishedAlready] = useState(true);

  useEffect(() => {
    var countDownDate = new Date(countingDownTime).getTime();
    var timer = setInterval(function() {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      if(distance > 0){
        setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
        setPublishedAlready(false);
        getIsPublished(false)
        return;
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  }, [countingDownTime])

  return (
    <>
      {!publishedAlready && (
        <div
          style={{
            padding: "1em 2em",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
            color="neutral/grey50"
          >
            Artwork drops in
          </TypographyBody>
          <TypographySubtitle
            component={isOnDesktop ?
              TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold :
              isOnTablet ?
                TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold
                : TYPOGRAPHY_SUBTITLE_COMPONENT.Mobile_H4_Bold}
          >
            {days}d <span style={{ color: colors.Neutral.grey40 }}>:</span> {hours}h <span style={{ color: colors.Neutral.grey40 }}>:</span> {minutes}m <span style={{ color: colors.Neutral.grey40 }}>:</span> {seconds}s
          </TypographySubtitle>
        </div>
      )}
    </>
  )
}