import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";

import "./Alert.css";

const Alert = ({ alerts }) => {
  const alertsWithoutDuplicates = (alerts || []).reduce(
    (accumulated, alert) => {
      const { msg: msgLhs } = alert;
      if (!accumulated.some(({ msg: msgRhs }) => msgLhs === msgRhs)) {
        accumulated = [...accumulated, ...[alert]];
      }
      return accumulated;
    },
    []
  );

  const { spacing, palette, shape, typography } = useTheme();

  return (
    <Fragment>
      {alertsWithoutDuplicates.map((alert, index) => (
        <div
          key={index}
          className={"Alert " + alert.type}
          style={{
            alignSelf: "flex-end",
            height: "52px",
            width: "288px", 
            borderRadius: shape.borderRadius,
            justifyContent: "start",
            alignItems: "start",
            paddingTop: spacing(2),
            paddingBottom: spacing(2),
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.smallest,
            background:
              alert.type === "error"
                ? palette.error.main
                : alert.type === "info"
                ? palette.grey.darkest
                : alert.type === "success"
                ? palette.success.main
                : palette.error.main,
          }}
        >
          {alert.msg}
        </div>
      ))}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  alerts: state.alertReducer,
});

export default connect(mapStateToProps)(Alert);
