import axios from "axios";
import {getCookie} from "../helpers/utils";
import AuthorizeService from "../auth/AuthorizeService";

const csrfToken = getCookie("XSRF-TOKEN");

export const setProfilePreferences = async ({
                                              currencyCode,
                                              measurement
                                            }: { currencyCode?: string, measurement?: string }) => {
  const token = await AuthorizeService.getAccessToken();

  return await axios.post('/api/Profile/SetUserPreferences', {
    "currency": currencyCode || '',
    "measurement": measurement || ''
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-XSRF-TOKEN": csrfToken,
      "Content-Type": "application/json",
    },
  });
};

export const changeCurrency = async ({currencyCode, cartId}) => {
  const token = await AuthorizeService.getAccessToken();

  const apiParams = {"basketId": cartId, "currencyName": currencyCode};

  return await axios.post('/api/ShoppingCart/changeCurrency', apiParams, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-XSRF-TOKEN": csrfToken,
      "Content-Type": "application/json",
    },
  });
};

export const getFxRate = async (currencyCode) => {
  const token = await AuthorizeService.getAccessToken();

  return await axios.post('/api/ShoppingCart/getfxrate', currencyCode, {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-XSRF-TOKEN": csrfToken,
      "Content-Type": "application/json",
    },
  });
};
