export const isLoading = (state) => {
  let search;
  if (state) {
    search = state.searchReducer;
  }

  const { loading } = search;

  return loading;
};
