import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { openRegistration } from "../../actions/authModal";

import { PikchaButton, PikchaLabel } from "../../components/baseComponents";

const SideDrawerOnboardingGroup = ({ openRegistration }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      className="flex-column flex-grow-1"
      style={{
        zIndex: 100,
      }}
    >
      <PikchaLabel className="pl-1-em pb-half-em">Artists</PikchaLabel>
      <div 
        className="flex-center justify-center"
        style={{
          margin: "auto"
        }}
      >
        <PikchaButton
          action="octonary"
          middle
          onClick={() => openRegistration(history, location)}
        >
          Sign in
        </PikchaButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  openRegistration,
})(SideDrawerOnboardingGroup);
