import React from "react";
// import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core";
import {PikchaLink} from "./PikchaLink";

export default ({ children, className, accentColor, ...rest }) => {
  const { palette } = useTheme();

  return (
    <PikchaLink
      className={`no-underline ${className || ""}`}
      style={{
        color: accentColor ? null : palette.grey.darkest,
      }}
      {...rest}
    >
      {children}
    </PikchaLink>
  );
};
