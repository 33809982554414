import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { updatePaymentDetails } from "../../../actions/account";
import {
  PikchaFormSection,
} from "../../../components/baseComponents";
import {
  AusTaxOptions,
  GSTTaxOptions,
  VATTaxOptions,
  TaxOptions,
} from "../../../components/taxOptions";
import isMobile from "../../../components/Responsive/isMobile";
import { COUNTRIES } from "../../../config/countries";
import { TAX_EXEMPTION_VALUE_4 } from "../../../helpers/constants";
import {
  TypographyBody, 
  TYPOGRAPHY_BODY_SIZE, 
  colors,
  TypographyButton, 
  TypographyTextInput, 
  TYPOGRAPHY_BUTTON_TYPE, 
  TypographyDropdown
} from "@pikcha/typography";
import { Accordion, AccordionSummary } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    padding: 0,
  },
  contentMargin: {
    backgroundColor: 'white',
    border: '0',
    padding: '2em 0'
  },
  accordionRoot: {
    boxShadow: 'none',
    backgroundColor: colors.Neutral.grey20,
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);

const PaymentInformation = ({ account, updatePaymentDetails, country, setCountry }) => {
  const isOnMobile = isMobile();

  const shouldOpenPaymentInformationSection = () => {
    const location = window.location.href;
    if (location.endsWith("residential-tax-informatiom")) {
      return true;
    }
    else {
      return false;
    }
  }

  const [isEditing, setIsEditing] = useState(false);
  const [currentOpen, setCurrentOpen] = useState(shouldOpenPaymentInformationSection());
  const onAccordionExpansion = () => {
    if(currentOpen){
      setCurrentOpen("")
    } else {
      setCurrentOpen("Residential Address And Tax Information")
    }
  };
  const [address1, setAddress1] = useState(account.user.addr1);
  const [address2, setAddress2] = useState(account.user.addr2);
  const [stateList, setStateList] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [city, setCity] = useState(account.user.city);
  const [postcode, setPostcode] = useState(account.user.postal);
  const [stateTerritory, setStateTerritory] = useState(account.user.state);
  const [businessOption, setTaxOptions] = useState(account.user.businessOption);
  const [hasABN, setHasABN] = useState(
    account.user && account.user.businessNumber !== ""
      ? true
      : !account.user.isAudGst && account.user.country === "Australia"
        ? false
        : null
  );
  const [gstRegistered, setGstRegistered] = useState(
    country === "Australia" && hasABN && account.user.isAudGst
      ? true
      : country === "Australia" && !account.user.isAudGst
        ? false
        : null
  );
  const [businessNumber, setBusinessNumber] = useState(
    account.user.businessNumber || ""
  );

  const [selectedTaxCountry, setSelectedTaxCountry] = useState(
    account.user && account.user.taxCountry ? account.user.taxCountry : ""
  );
  const [gstCountryError, setGstCountryError] = useState(false);

  const [isGstOutsideAu, setIsGstOutsideAu] = useState(
    !!(
      account.user &&
      account.user.country !== "Australia" &&
      !account.user.isAusGst &&
      selectedTaxCountry &&
      account.user.businessNumber
    )
  );

  const [vatNumber, setVatNumber] = useState(
    account.user.country.toLowerCase() !== "australia"
      ? account.user.businessNumber
      : ""
  );
  const [gstVatError, setGstVatError] = useState(true);
  const { palette, typography } = useTheme();

  const classes = useStyles();

  const onBeginEditing = () => {
    setIsEditing(true);
  };

  const onCancelEditing = () => {
    // reset everything back to their original values
    setAddress1(account.user.addr1);
    setAddress2(account.user.addr2);
    setCity(account.user.city);
    setPostcode(account.user.postal);
    setStateTerritory(account.user.state);
    setCountry(account.user.country);
    setTaxOptions(account.user.businessOption);

    const hasAnAbn =
      account.user && account.user.businessNumber !== ""
        ? true
        : !account.user.isAudGst && account.user.country === "Australia"
          ? false
          : null;
    setHasABN(hasAnAbn);
    setGstRegistered(
      country === "Australia" && hasAnAbn && account.user.isAudGst
        ? true
        : country === "Australia" && !account.user.isAudGst
          ? false
          : null
    );

    setBusinessNumber(
      account.user.country.toLowerCase() === "australia"
        ? account.user.businessNumber
        : ""
    );

    setSelectedTaxCountry(
      account.user && account.user.taxCountry ? account.user.taxCountry : ""
    );
    setIsGstOutsideAu(
      !!(
        account.user &&
        account.user.country !== "Australia" &&
        !account.user.isAusGst &&
        selectedTaxCountry &&
        account.user.businessNumber
      )
    );

    setVatNumber(
      account.user.country.toLowerCase() !== "australia"
        ? account.user.businessNumber
        : ""
    );
    setGstVatError(true);

    setIsEditing(false);
  };

  const onSaveEditing = () => {
    let isAudGst = false;
    if (
      country === "Australia" &&
      hasABN &&
      businessOption === TAX_EXEMPTION_VALUE_4
    ) {
      isAudGst = gstRegistered;
    }

    updatePaymentDetails({
      addr1: address1,
      addr2: address2,
      city,
      postal: postcode,
      state: stateTerritory,
      country,
      businessNumber,
      businessOption: businessOption,
      _taxCountry: selectedTaxCountry,
      isAudGst,
    });
    setIsEditing(false);
  };

  const onChange = ({ target }) => {
    let key = target.name;

    if (key === "address1") {
      setAddress1(target.value);
    }
    if (key === "address2") {
      setAddress2(target.value);
    }
    if (key === "city") {
      setCity(target.value);
    }
    if (key === "postcode") {
      setPostcode(target.value);
    }
    if (key === "stateTerritory") {
      setStateTerritory(target.value);
    }
  };
  const onCountryChange = ({ label, value }) => {
    setCountry(label);
  };

  const handleAusTaxCategory = (option) => {
    setTaxOptions(option);
  };
  const handleAusTaxOptions = ({ gstRegistered }) => {
    setGstRegistered(gstRegistered);
  };
  const handleHasABN = (state) => {
    setHasABN(state);
  };

  const handleGstTaxOptions = ({ gstRegistered, taxCountry, vatNumber }) => {
    setGstRegistered(gstRegistered);
    handleSelectedTaxCountry(taxCountry);
    setVatNumber(vatNumber);
  };
  const handleSelectedTaxCountry = (state) => {
    setSelectedTaxCountry(state);
  };
  const handleIsGstOutsideAu = (state) => {
    setIsGstOutsideAu(state);
  };

  const changeGstCountry = (label, value) => {
    let countryCode = null;
    COUNTRIES.forEach((currCountry) => {
      if (currCountry.label === label) {
        countryCode = currCountry.value;
      }
    });
    handleSelectedTaxCountry({
      label: label,
      value: value,
    });
    setGstCountryError(false);
  };

  const isCountryGst = () => {
    let result = false;
    COUNTRIES.forEach((item) => {
      if (item.label === country && item.isGst) {
        result = true;
      }
    });
    return result;
  };

  const areFieldsDisabled = !isEditing;

  const getStates = async (val) => {
    setLoadingState(true)
    const res = await axios.get(`api/payment/${val}/subRegion`);
    const regions = Object.values(((res.data.country || {}).regions) || {});
    const regionsValue = [];
    regions.map((region) => {
      regionsValue.push({ label: region, value: region })
    })
    setStateList(regionsValue);
    setLoadingState(false);
  }

  useEffect(() => {
    getStates((COUNTRIES.find((item) => item.label === country) || {}).value);
  }, [country])

  return (
    <>
      <Accordion 
        expanded={currentOpen === "Residential Address And Tax Information"}
        className={classes.accordionRoot} 
        style={{ marginBottom: 16 }}
        onChange={onAccordionExpansion} 
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          style={{
            padding: isOnMobile && "0 16",
            maxHeight: isOnMobile && 70
          }}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            Residential Address And Tax Information
          </TypographyBody>
        </StyledAccordionSummary>
        <div className={classes.contentMargin}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingTop: '2em'
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isOnMobile ? "column" : "row",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
                >
                  Residential Address
                </TypographyBody>
                <TypographyDropdown
                  style={{ marginTop: '3em',marginBottom:'1.7em' }}
                  disabled={areFieldsDisabled}
                  label="Country"
                  compactListSize
                  noHelperClass
                  onChange={(val) => {
                    setStateList([]);
                    setStateTerritory("");
                    onCountryChange(val)
                  }}
                  options={COUNTRIES}
                  value={country}
                  placeholder={country || ""}
                />
                <TypographyTextInput
                  disabled={areFieldsDisabled}
                  label="Address 1"
                  type="text"
                  name="address1"
                  value={address1}
                  onChange={onChange}
                />
                <TypographyTextInput
                  disabled={areFieldsDisabled}
                  label="Address 2"
                  type="text"
                  name="address2"
                  value={address2}
                  onChange={onChange}
                />
                <TypographyTextInput
                  disabled={areFieldsDisabled}
                  label="City / Suburb / Town"
                  type="text"
                  name="city"
                  value={city}
                  onChange={onChange}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <TypographyDropdown
                      compactListSize
                      label={country === "Canada" ? "Province" : country === "United Kingdom" ? "County" : "State"}
                      noHelperClass
                      onChange={(value) => {
                        setStateTerritory(value.label);
                      }}
                      options={stateList}
                      value={stateList.find((state) => state.value === stateTerritory) || {}}
                      disabled={!areFieldsDisabled && country && !loadingState ? false : true}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: "1em",
                      width: "100%",
                    }}
                  >
                    <TypographyTextInput
                      disabled={areFieldsDisabled}
                      label="Postcode"
                      type="text"
                      name="postcode"
                      value={postcode}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginLeft: isOnMobile ? undefined : "2em",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontSize: typography.small,
                    fontWeight: typography.fontWeightMedium,
                    color: palette.text.primary,
                  }}
                >
                </div>
                <TaxOptions
                  disabled={areFieldsDisabled}
                  fields={{ businessOption }}
                  country={country}
                  handleAusTaxCategory={handleAusTaxCategory}
                />
                {country === "Australia" &&
                  businessOption === TAX_EXEMPTION_VALUE_4 && (
                    <Fragment>
                      <AusTaxOptions
                        disabled={areFieldsDisabled}
                        fields={{}}
                        handleFields={handleAusTaxOptions}
                        hasABN={hasABN}
                        handleHasABN={handleHasABN}
                      />
                      {hasABN && (
                        <PikchaFormSection topMarginSize={2}>
                          <TypographyTextInput
                            disabled={areFieldsDisabled}
                            label="My ABN:"
                            className={"payment__form-label"}
                            type={"text"}
                            name={"abn"}
                            pattern=".{1,225}"
                            value={businessNumber}
                            onChange={(e) => {
                              setBusinessNumber(e.currentTarget.value);
                            }}
                            required
                            title="Max 225 characters"
                          />
                        </PikchaFormSection>
                      )}
                    </Fragment>
                  )}
                {((country === "Australia" &&
                  hasABN &&
                  businessOption === TAX_EXEMPTION_VALUE_4) ||
                  (country !== "Australia" && isCountryGst())) && (
                    <Fragment>
                      <div>
                        <FormControl size="small" required component="div">
                          {country === "Australia" ? (
                            <GSTTaxOptions
                              disabled={areFieldsDisabled}
                              gstRegistered={gstRegistered}
                              handleFields={handleGstTaxOptions}
                              fields={{
                                gstRegistered,
                                taxCountry: selectedTaxCountry,
                                vatNumber,
                              }}
                              handleSelectedTaxCountry={
                                handleSelectedTaxCountry
                              }
                              handleIsGstOutsideAu={handleIsGstOutsideAu}
                            />
                          ) : (
                            <VATTaxOptions
                              disabled={areFieldsDisabled}
                              isGstOutsideAu={isGstOutsideAu}
                              fields={{
                                gstRegistered,
                                taxCountry: selectedTaxCountry,
                                vatNumber,
                              }}
                              handleFields={handleGstTaxOptions}
                              handleSelectedTaxCountry={
                                handleSelectedTaxCountry
                              }
                              handleIsGstOutsideAu={handleIsGstOutsideAu}
                            />
                          )}
                        </FormControl>
                      </div>
                      {country.toLowerCase() !== "australia" && isGstOutsideAu && (
                        <PikchaFormSection>
                          <TypographyDropdown
                            label="What is your tax country?"
                            noHelperClass
                            value={selectedTaxCountry}
                            style={{
                              marginBottom: "1.75em",
                            }}
                            onChange={({ label, value }) => {
                              changeGstCountry(label, value);
                            }}
                            options={COUNTRIES}
                            placeholder={selectedTaxCountry || ""}
                          />
                          {gstCountryError && (
                            <span className={"text-small text-danger"}>
                              Country is required!
                            </span>
                          )}
                          {selectedTaxCountry !== "" && (
                            <div>
                              <TypographyTextInput
                                disabled={areFieldsDisabled}
                                label="My GST/VAT number"
                                onChange={(e) => {
                                  setBusinessNumber(e.currentTarget.value);
                                }}
                                name="abn"
                                value={businessNumber}
                                required
                              />
                              {!gstVatError && (
                                <span className={"text-small text-danger"}>
                                  Please enter valid GST/VAT number!
                                </span>
                              )}
                            </div>
                          )}
                        </PikchaFormSection>
                      )}
                    </Fragment>
                  )}
                <div
                  style={{
                    marginBottom: "1em",
                  }}
                />
              </div>
            </div>
            <div>
              {!isEditing && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: 'flex-end'
                  }}
                >
                  <TypographyButton
                    component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                    onClick={onBeginEditing}
                    textStyle={{
                      fontWeight: 600
                    }}
                  >
                    Edit
                  </TypographyButton>
                </div>
              )}

              {isEditing && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <TypographyButton
                    component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                    onClick={onCancelEditing}
                  >
                    Cancel
                  </TypographyButton>
                  <TypographyButton
                    component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                    onClick={onSaveEditing}
                    buttonStyle={{
                      marginLeft: "1em",
                    }}
                  >
                    Save
                  </TypographyButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {
  updatePaymentDetails,
})(PaymentInformation);
