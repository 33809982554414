import {
  HANDLE_SAVED_IMAGE,
  MODAL_CREATED,
  MODAL_REMOVED,
} from "../actions/types";

import {modalTypes} from "../selectors/modal";

const initialState = {
  type: false,
  data: false,
  stack: 0,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case HANDLE_SAVED_IMAGE: {
      const {imageId, saved: isSaved} = payload;
      let newData = state.data;
      if (state.type === modalTypes.product) {
        const {id} = state.data || {};
        if (id === imageId) {
          newData = {
            ...newData,
            isSaved,
          };
        }
      }
      return {
        ...state,
        data: newData,
      };
    }

    case MODAL_CREATED: {
      let stack = state.stack;
      if (payload.type === modalTypes.product) {
        const {friendlyUrl} = payload.data || {};
        if (friendlyUrl) {
          stack += 1;
        }
      } else {
        stack = 0;
      }
      return {
        ...state,
        stack,
        type: payload.type,
        data: payload.data,
      };
    }

    case MODAL_REMOVED:
      return initialState;

    default:
      return state;
  }
};
