import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AUTH_VERIFYING_ACCOUNT_MESSAGE } from "../../../helpers/authMessages/constants";
import { FacebookAuthButton, GoogleAuthButton } from "../socialAuthButtons";
import {
  handleLoginLoadingStatus,
  handleLoginPassword,
  handleLoginUsername,
} from "../../../actions/login";
import { authenticate } from "../../../actions/auth";
import { setAlert } from "../../../actions/alert";
import {
  PikchaLoader,
  PikchaButton,
  PikchaTextField,
} from "../../baseComponents";
import { getCookie, removeModalOverlay } from "../../../helpers/utils";
import authService from "../../../auth/AuthorizeService";

import "./LoginFormBase.css";

const LoginFormBase = ({
  handleLoginUsername,
  handleLoginPassword,
  handleLoginLoadingStatus,
  login: { username, password, loading },
  authenticate,
  setAlert,
  // Only required when integrating within the auth modal.
  handleClickClose,
  // Conditionally renders some elements based on whether the form is in a
  // page or a modal.
  inModal,
}) => {
  const { typography } = useTheme();
  const history = useHistory();

  const verifyFacebookUser = (data) => {
    const csrfToken = getCookie("XSRF-TOKEN");

    if (data && !data.authResponse) {
      // TODO: Handle errors with null authResponse object.
      return;
    }

    const access_token = data.authResponse.accessToken;

    handleLoginLoadingStatus(true);

    axios
      .post(
        `api/account/fbauth`,
        { accessToken: access_token },
        {
          headers: {
            "X-XSRF-TOKEN": csrfToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          const state = window.location.href;
          authService
            .signIn(state)
            .then(() => {
              const ensureAuthenticated = async () => {
                await authenticate();
              };
              ensureAuthenticated()
                .then(() => {
                  if (handleClickClose) {
                    handleClickClose();
                    removeModalOverlay();
                  }
                  // TODO: Handle redirect if necessary from the cart
                  history.replace("/app");
                })
                .catch((error) => {
                  handleLoginLoadingStatus(false);
                  console.log(`handleLoginLoadingStatus : ${error}`);
                });
              handleLoginLoadingStatus(false);
            })
            .catch((err) => console.log("handleLoginLoadingStatus 2", err));
        }
      })
      .catch((err) => {
        handleLoginLoadingStatus(false);
        setAlert("Please check your credentials", "error");
      });
  };

  const verifyGoogleUser = (data) => {
    const csrfToken = getCookie("XSRF-TOKEN");

    if (data && !data.wc) {
      // TODO: Handle errors with null authResponse object.
      return;
    }

    const access_token = data.wc.access_token;
    const id_token = data.wc.id_token;
    handleLoginLoadingStatus(true);

    axios
      .post(
        `api/account/googleauth`,
        { accessToken: access_token, idToken: id_token },
        {
          headers: {
            "X-XSRF-TOKEN": csrfToken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          const state = window.location.href;
          authService
            .signIn(state)
            .then(() => {
              const ensureAuthenticated = async () => {
                await authenticate();
              };
              ensureAuthenticated()
                .then(() => {
                  if (handleClickClose) {
                    handleClickClose();
                    removeModalOverlay();
                  }
                  // TODO: Handle redirect if necessary from the cart
                  history.replace("/app");
                })
                .catch((error) => {
                  handleLoginLoadingStatus(false);
                  console.log(`ensureAuthenticated : ${error}`);
                });
              handleLoginLoadingStatus(false);
            })
            .catch((err) => console.log("ensureAuthenticated 2 ", err));
        }
      })
      .catch((err) => {
        handleLoginLoadingStatus(false);
        setAlert("Please check your credentials", "error");
      });
  };

  const handleFacebook = (e) => {
    window.FB.getLoginStatus((response) => {
      if (response.status === "not_authorized") {
        return setAlert(
          "An error occurred connecting to the authentication service.",
          "error"
        );
      }

      if (response.status === "connected") {
        //console.log("already connected");
        verifyFacebookUser(response);
      } else {
        window.FB.login(
          (response) => {
            verifyFacebookUser(response);
          },
          { scope: "public_profile,email" }
        );
      }
    });
  };

  const handleGoogle = () => {
    Promise.resolve(window.gapi.auth2.getAuthInstance().signIn()).then(() => {
      var user = window.gapi.auth2.getAuthInstance().currentUser.get();
      //console.log(user);
      var access_token = user.wc.access_token;
      //call api
      verifyGoogleUser(user);
    });
  };

  const handleLogin = () => {
    const csrfToken = getCookie("XSRF-TOKEN");

    const dto = new FormData();
    dto.append("Email", username);
    dto.append("Password", password);
    dto.append("RememberMe", "true");

    axios
      .post(`api/account/login`, dto, {
        headers: {
          "X-XSRF-TOKEN": csrfToken,
          "Content-Type": "application/json",
        },
      })
      .then(({ status }) => {
        if (status === 200) {
          const state = window.location.href;

          authService
            .signIn(state)
            .then(() => {
              const ensureAuthenticated = async () => {
                await authenticate();
              };

              ensureAuthenticated()
                .then(() => {
                  if (handleClickClose) {
                    handleClickClose();
                    removeModalOverlay();
                  } /*else {
             history.replace('/app');
             }*/
                  history.replace("/app");
                })
                .catch((error) => {
                  console.log(`loginformbase ensureAuthenticated : ${error}`);
                });
            })
            .catch((err) => console.log("login ensureAuthenticated 2 ", err));
        }
      })
      .catch((err) => {
        handleLoginLoadingStatus(false);
        setAlert("Please check your credentials", "error");
      });
  };

  return (
    <Fragment>
      <Helmet>
        <title>Pikcha — Log in</title>
      </Helmet>

      {!loading ? (
        <div className="flex-center LoginFormBase-wrapper">
          <GoogleAuthButton onClick={handleGoogle}>
            Log in with Google
          </GoogleAuthButton>
          <FacebookAuthButton onClick={handleFacebook}>
            Log in with Facebook
          </FacebookAuthButton>

          <hr className="LoginFormBase-hr" data-content="OR" />

          <form className="AuthFormBase-form">
            <PikchaTextField
              autoComplete="username"
              value={username}
              onChange={handleLoginUsername}
              label="Email"
              type="email"
              autoFocus
              required
              fullWidth
            />
            <PikchaTextField
              autoComplete="current-password"
              value={password}
              onChange={handleLoginPassword}
              label="Password"
              type="password"
              required
              fullWidth
            />
            <PikchaButton
              style={{
                float: "right",
              }}
              type="submit"
              onClick={() => {
                handleLoginLoadingStatus(true);
                handleLogin();
              }}
            >
              Log in
            </PikchaButton>
          </form>
          {!inModal && (
            <div
              className="AuthFormBase-footerText pt-5"
              style={{
                fontSize: typography.smallest,
              }}
            >
              <div className="pb-3">
                <Link to="/auth/reset">Forgot password?</Link>
              </div>
              <div>
                New to Pikcha?&nbsp;<Link to="/auth/register">Sign up</Link>
              </div>
            </div>
          )}
        </div>
      ) : (
        <PikchaLoader message={AUTH_VERIFYING_ACCOUNT_MESSAGE} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  login: state.loginReducer,
});

export default connect(mapStateToProps, {
  handleLoginUsername,
  handleLoginPassword,
  handleLoginLoadingStatus,
  authenticate,
  setAlert,
})(LoginFormBase);
