import {
  NOTIFICATION_HIDE,
  NOTIFICATION_ITEM,
  NOTIFICATION_MESSAGE,
  NOTIFICATION_SHOW,
  NOTIFICATION_TYPE,
} from "../actions/types";

const initialState = {
  type: "",
  show: false,
  message: "",
  item: {
    startTimer: false,
    currentTime: {
      min: 11,
      seconds: 59,
    },
    image: {
      artist: {
        id: 1,
        fName: "test fName",
        lName: "test lName",
        avatar: "",
      },
      imageRender:
        "uploads/7ec452d7-02af-4476-969f-a1cf5e57078a/Images/Customizable/5e90c712-afb0-4121-be30-9f391f875254.jpg",
      title: "Test Title",
      type: "Test Type",
      material: "Test Material",
      size: "Test Size",
      frame: "Test Frame",
      prodNum: 1,
      galleryCardColor :"test",
    },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NOTIFICATION_SHOW:
      return {
        ...state,
        show: payload,
      };
    case NOTIFICATION_HIDE:
      return {
        ...state,
        show: payload,
      };
    case NOTIFICATION_TYPE:
      return {
        ...state,
        type: payload,
      };
    case NOTIFICATION_MESSAGE:
      return {
        ...state,
        message: payload,
      };
    case NOTIFICATION_ITEM:
      return {
        ...state,
        item: payload,
      };
    default:
      return state;
  }
};
