import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import AuthorizeService from "../../../auth/AuthorizeService";
import { setAlert } from "../../../actions/alert";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core";
import {
  TypographyBody, TYPOGRAPHY_BODY_SIZE, colors,
  TypographyButton, TYPOGRAPHY_BUTTON_TYPE, TypographySelector
} from "@pikcha/typography";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";

const useStyles = makeStyles((_) => ({
  expansionContainer: {
    boxShadow: "none",
    backgroundColor: "transparent !important",
  },
  expansionDetails: {
    padding: 0,
  },
  contentMargin: {
    backgroundColor: 'white',
    border: '0',
  },
  accordionRoot: {
    boxShadow: 'none',
    backgroundColor: colors.Neutral.grey20,
  },
}));

const StyledAccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    backgroundColor: colors.Neutral.grey20,
    borderRadius: '4px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
      backgroundColor: colors.Neutral.grey20,
    }
  },
  expandIcon: {
    transform: 'none'
  }
})(AccordionSummary);

const PersonalDetails = ({ setAlert,expandedPanel,handleAccordionChange}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [weeklyNewsletter, setWeeklyNewsletter] = useState();
  const [pikchaEditorial, setPikchaEditorial] = useState();
  const [newProductsAndFeatures, setNewProductsAndFeatures] = useState();
  const [priceDropsAndDiscounts, setPriceDropsAndDiscounts] = useState();
 
  useEffect(() => {
    AuthorizeService.getAccessToken().then((token) =>
      axios
        .get("/api/EmailPreference/GetPreference", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setWeeklyNewsletter(response.data.weeklyNewsLetter);
          setPikchaEditorial(response.data.pikchaEditorial);
          setNewProductsAndFeatures(response.data.newProductsAndFeatures);
          setPriceDropsAndDiscounts(response.data.priceDropsAndDiscounts);
        })
    );
  }, []);

  const onBeginEditing = () => {
    setIsEditing(true);
  };

  const onSaveEditing = () => {
    AuthorizeService.getAccessToken().then((token) =>
      axios
        .post(
          "/api/EmailPreference/SetPreference",
          {
            weeklyNewsLetter: weeklyNewsletter,
            pikchaEditorial,
            newProductsAndFeatures,
            priceDropsAndDiscounts,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setAlert("User Updated", "success");
          setIsEditing(false);
        })
    );
  };
  const classes = useStyles();

  return (
    <>
      <Accordion 
        expanded={expandedPanel === 'panel3'} 
        onChange={handleAccordionChange('panel3')} 
        className={classes.accordionRoot} 
        style={{ margin: 0 }} 
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          classes={{ expanded: classes.expandedSummary }}
        >
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
          >
            Email Preferences
          </TypographyBody>
        </StyledAccordionSummary>
        <AccordionDetails className={classes.contentMargin}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "1em",
              paddingTop: '2em',
              marginBottom: '1em'
            }}
          >
            <TypographySelector
              labelStyle={{ fontSize: '1em !important' }}
              selectorType="checkbox"
              disabled={!isEditing}
              value={!!weeklyNewsletter}
              checked={!!weeklyNewsletter}
              handleChange={() => {
                setWeeklyNewsletter(!weeklyNewsletter);
              }}
              labelText="Weekly newsletter"
            />
            <div
              style={{
                marginTop: "1em",
              }}
            >
              <TypographySelector
                selectorType="checkbox"
                disabled={!isEditing}
                value={!!pikchaEditorial}
                checked={!!pikchaEditorial}
                handleChange={() => {
                  setPikchaEditorial(!pikchaEditorial);
                }}
                labelText="The Pikcha Editorial"
              />
            </div>
            <div
              style={{
                marginTop: "1em",
              }}
            >
              <TypographySelector
                selectorType="checkbox"
                disabled={!isEditing}
                value={!!newProductsAndFeatures}
                checked={!!newProductsAndFeatures}
                handleChange={() => {
                  setNewProductsAndFeatures(!newProductsAndFeatures);
                }}
                labelText="New products and features"
              />
            </div>
            <div
              style={{
                marginTop: "1em",
              }}
            >
              <TypographySelector
                selectorType="checkbox"
                disabled={!isEditing}
                value={!!priceDropsAndDiscounts}
                checked={!!priceDropsAndDiscounts}
                handleChange={() => {
                  setPriceDropsAndDiscounts(!priceDropsAndDiscounts);
                }}
                labelText="Price drops and discounts"
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "1em",
            }}
          />
          {!isEditing && (
            <div
              style={{
                display: "flex",
                width: '100%',
                justifyContent: 'end'
              }}
            >
              <TypographyButton
                component={TYPOGRAPHY_BUTTON_TYPE.Outline}
                onClick={onBeginEditing}
                buttonStyle={{
                  marginLeft: "1em",
                  maxHeight: '40px'
                }}
              >
                Edit
              </TypographyButton>
            </div>
          )}
          {isEditing && (
            <div
              style={{
                display: "flex",
                width: '100%',
                justifyContent: 'end',
              }}
            >
              <TypographyButton
                component={TYPOGRAPHY_BUTTON_TYPE.Primary}
                onClick={onSaveEditing}
                buttonStyle={{
                  marginLeft: "1em",
                  maxHeight: '40px',
                }}
              >
                Save
              </TypographyButton>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  setAlert,
})(PersonalDetails);
