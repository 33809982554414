import React from "react";
import { connect } from "react-redux";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import { useTheme } from "@material-ui/core/styles"
import { setAlert } from "../../../actions/alert";
import { PikchaModal } from "../../../components/baseComponents";
import CopyLink from "../../../assets/images/Collection/CopyLink.svg"
import FacebookIcon from "../../../assets/images/Collection/FacebookIcon.svg"
import TwitterIcon from "../../../assets/images/Collection/TwitterIcon.svg"
import LinkedinIcon from "../../../assets/images/Collection/LinkedinIcon.svg"
import TelegramIcon from "../../../assets/images/Collection/TelegramIcon.svg"
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE
} from "@pikcha/typography";

const CollectionSharingModal = ({ open, onClose, pageType, setAlert,collectionUrl }) => {
  const { typography, } = useTheme();
  const imageCopyLink = `${window.location.origin}/${collectionUrl}`;
  const copyLink = () => {
    navigator.clipboard.writeText(imageCopyLink).then(
      () => {
        setAlert("URL has been copied", "info");
      },
      (err) => {
        setAlert("Couldn't copy image link", "error");
      }
    );
  };

  const title = (pageType == "pikcha100") ?
    "A collection of the most influential and collectible artworks on Pikcha" :
    "A collection of the most influential and collectible artists on Pikcha";

  const socialSharingButtonsForPikcha100 = () => (
    <div
      className="full-width flex"
      style={{
        flexWrap: "wrap",
        maxWidth: 516
      }}>
      <div className="social-sharing-button-box">
        <FacebookShareButton
          style={{
            maxWidth: "24px",
            maxHeight: "24px",
            marginBottom: "0.75em",
          }}
          quote={title}
          url={`${window.location.origin}/${collectionUrl}`}
        >
          <span
            style={{
              maxHeight: "24px"
            }}
          >
            <img src={FacebookIcon} alt="" />
          </span>
        </FacebookShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Facebook</span>
      </div>
      <div className="social-sharing-button-box">
        <LinkedinShareButton
          url={`${window.location.origin}/${collectionUrl}`}
          title="Check out my latest image on Pikcha"
          summary={title}
          source="https://www.pikcha.com"
          style={{
            maxWidth: "24px",
            maxHeight: "24px",
            marginBottom: "0.75em",
          }}
        >
          <span
            style={{
              maxHeight: "24px"
            }}
          >
            <img src={LinkedinIcon} alt="" />
          </span>
        </LinkedinShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >LinkedIn</span>
      </div>
      <div className="social-sharing-button-box">
        <TwitterShareButton
          style={{
            maxWidth: "24px",
            maxHeight: "24px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/${collectionUrl}`}
          title={title}
          hashtags={["pikcha", "pikcha100"]}
        >
          <span
            style={{
              maxHeight: "24px",
            }}
          >
            <img src={TwitterIcon} alt="" />
          </span>
        </TwitterShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Twitter</span>
      </div>
      <div className="social-sharing-button-box">
        <TelegramShareButton
          style={{
            maxWidth: "24px",
            maxHeight: "24px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${collectionUrl}`}
          title={title}
        >
          <span
            style={{
              maxHeight: "24px",
            }}
          >
            <img src={TelegramIcon} alt="" />
          </span>
        </TelegramShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Telegram</span>
      </div>
    </div>
  );

  return (
    <PikchaModal
      fullWidth={false}
      onClose={onClose}
      maxWidth="lg"
      open={open}
      PaperProps={{
        style: {
          overflow: "hidden",
          maxWidth: "100%"
        },
      }}
      minHeight={1}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          margin: "1.5em",
        }}
      >
        <TypographySubtitle
        style={{marginBottom:'1em'}}
          component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H4_Bold}
        >
          Share your collection
        </TypographySubtitle>
        <div style={{
          display: "flex",
          width: "100%",
          maxWidth: 516,
          flexDirection: 'column'
        }}>
          <div style={{
            minWidth: "400px",
            width: "100%",
            paddingTop: "0.75em",
            paddingRight: "0.5em",
            marginBottom:'1em'
          }}>
            <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
            >
              Copy Link
            </TypographyBody>
            <div
              style={{
                display: 'flex',
                height: '40px',
                border: '1px solid #DBE0E4',
                borderRadius: '4px',
                alignItems: 'center',
                padding: '0.25em 1em',
                justifyContent: 'space-between'
              }}>
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
              >
                {imageCopyLink}
              </TypographyBody>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => copyLink()}
              >
                <img src={CopyLink} alt="Copy link Icon" />
              </div>
            </div>
          </div>
          {socialSharingButtonsForPikcha100()}
        </div>
      </div>
    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  setAlert,
})(CollectionSharingModal);