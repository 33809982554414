import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  root: {
    padding: 4,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 50,
    width: "1rem",
    height: "1rem",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#F5F8FA",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#EBF1F5",
    },
  },
  checkedIcon: {
    backgroundColor: palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: "1rem",
      height: "1rem",
      backgroundImage: `radial-gradient(${palette.common.white}, ${palette.common.white} 28%,transparent 32%)`,
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: palette.secondary.main,
    },
  },
  formControl: {
    padding: spacing(5),
    width: "100%",
  },
  label: {
    fontWeight: typography.fontWeightMedium,
    fontFamily: typography.fontFamilyMontserrat,
    fontSize: typography.standard,
    marginBottom: "0.5em",
  },
}));
