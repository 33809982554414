import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

const PikchaTab = withStyles(({ palette, typography }) => ({
  root: ({ sideDrawer: { payload } }) => ({
    fontSize: typography.large,
    fontWeight: typography.fontWeightMedium,
    background: "transparent",
    color: `${palette.grey.darkest} !important`,
    border: 0,
    padding: 0,
    textTransform: "capitalize",
    minWidth: 0,
    minHeight: 40,
  }),
  selected: {
    fontSize: typography.large,
    color: `${palette.secondary.main} !important`,
    fontWeight: typography.fontWeightBold,
    border: 0,
    padding: 0,
    background: "transparent",
    minWidth: 0,
    minHeight: 40,
  },
  wrapper: {
    flexDirection: "row",
    fontSize: typography.smaller,
  },
  labelIcon: {
    "& > *:first-child": {
      marginRight: ".25rem",
      marginBottom: 3,
    },
  },
}))(({ sideDrawer, ...rest }) => (
  <Tab
    style={{
      width: 150,
    }}
    {...rest}
  />
));

const mapStateToProps = (state) => ({
  sideDrawer: state.sideDrawerReducer,
});

export default connect(mapStateToProps)(PikchaTab);
