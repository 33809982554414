import React, {useEffect, useState} from "react";
import {SideBySideMagnifier,} from "react-image-magnifiers";
import "./ImageMagnifier.css";

export default ({image, heightConstrained, needToHandleClick, handleClick, onImageLoad}) => {
  const {aspRatioX, aspRatioY, title} = image;
  
  const isPortrait = aspRatioX <= aspRatioY;

  useEffect(() => {
    if(image.watermark){
      /*
      const wrapper = document.getElementsByClassName("ImageWrapper-main")[0];
      const image = wrapper.getElementsByTagName("img")[0];
      const imageWrapper = image.parentElement;
      imageWrapper.classList.add("image-wrapper");
      image.classList.add("img-style");
      if(document.getElementsByClassName("ImageWrapper-main").length > 1){
        const wrapper = document.getElementsByClassName("ImageWrapper-main")[1];
        const image = wrapper.getElementsByTagName("img")[0];
        const imageWrapper = image.parentElement;
        imageWrapper.classList.add("image-wrapper");
        image.classList.add("img-style");
      }
      */
      const wrapperList = document.getElementsByClassName("ImageWrapper-main");
      Array.from(wrapperList).forEach((item)=>{
        const image = item.getElementsByTagName("img")[0];
        const imageWrapper = image.parentElement;
        imageWrapper.classList.add("image-wrapper");
        image.classList.add("img-style");
      })
    }
  }, [image, isPortrait])
  
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <figure
      className="ImageWrapper-main"
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        width: imageLoaded? (isPortrait ? "auto" : "100%") : '0%',
        height: imageLoaded ? (isPortrait ? "100%": "auto") : '0%',
        cursor: "zoom-in",
        boxSizing: "border-box",
        overflow: "hidden",
        position: "relative",
        // transition: "all .8s",
        // backgroundColor: 'red',
        display: "flex"   
      }}
      onClick={() => {
        if (needToHandleClick) {
          handleClick()
        }
      }}
    >
      <SideBySideMagnifier
        imageSrc={image.watermark}
        onImageLoad={() => {
          setImageLoaded(true);
          onImageLoad && onImageLoad()
        }}
        imageAlt={title}
        largeImageSrc={image.watermark}
        alwaysInPlace={true}
        cursorStyle={"zoom-in"}
      />
    </figure>
  );
};