import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ color, size = 16 }) => {
  const { palette } = useTheme();

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0284 0H6.97162C3.12745 0 0 3.12745 0 6.97162V17.0284C0 20.8725 3.12745 24 6.97162 24H17.0284C20.8725 24 24 20.8725 24 17.0284V6.97162C24 3.12745 20.8725 0 17.0284 0ZM22.125 17.0284C22.125 19.8387 19.8387 22.125 17.0284 22.125H6.97162C4.16133 22.125 1.875 19.8387 1.875 17.0284V6.97162C1.875 4.16133 4.16133 1.875 6.97162 1.875H17.0284C19.8387 1.875 22.125 4.16133 22.125 6.97162V17.0284Z"
        fill={color || palette.grey.medium}
      />
      <path
        d="M12 5.53125C8.43309 5.53125 5.53125 8.43309 5.53125 12C5.53125 15.5669 8.43309 18.4688 12 18.4688C15.5669 18.4688 18.4688 15.5669 18.4688 12C18.4688 8.43309 15.5669 5.53125 12 5.53125ZM12 16.5938C9.46702 16.5938 7.40625 14.533 7.40625 12C7.40625 9.46702 9.46702 7.40625 12 7.40625C14.533 7.40625 16.5938 9.46702 16.5938 12C16.5938 14.533 14.533 16.5938 12 16.5938Z"
        fill={color || palette.grey.medium}
      />
      <path
        d="M18.5625 6.375C19.0803 6.375 19.5 5.95527 19.5 5.4375C19.5 4.91973 19.0803 4.5 18.5625 4.5C18.0447 4.5 17.625 4.91973 17.625 5.4375C17.625 5.95527 18.0447 6.375 18.5625 6.375Z"
        fill={color || palette.grey.medium}
      />
    </svg>
  );
};
