import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({
  children,
  light,
  lightGrey,
  larger,
  medium,
  style = {},
  ...rest
}) => {
  const { palette, typography } = useTheme();

  return (
    <div
      className="pr-1-em"
      style={{
        display: "flex",
        alignItems:"center",
        // color: light
        //   ? palette.common.white
        //   : lightGrey
        //   ? palette.grey.mediumDark
        //   : palette.grey.dark,
        // fontWeight: medium
        //   ? typography.fontWeightMedium
        //   : typography.fontWeightRegular,
        // fontSize: larger ? typography.small : typography.smallest,
        color: palette.secondary,
        fontWeight: 500,
        fontSize: "0.63rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: 1.25,
        userSelect: "none",
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
