import React from "react";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { colors } from "../../colors";
import { getColorKey } from "../../utils";

export enum TYPOGRAPHY_METADATA_KIND {
  Bold = "Bold",
  Subtle = "Subtle"
}

const typographyMetadataHashMap = new Map([
  [
    TYPOGRAPHY_METADATA_KIND.Bold,
    { lineHeight: "28px", fontSize: "1em", letterSpacing: "-0.8px" },
  ],
  [
    TYPOGRAPHY_METADATA_KIND.Subtle,
    { lineHeight: "auto", fontSize: "1em", letterSpacing: "-0.8px" },
  ],
]);

const TypographyButton = ({
                            kind = TYPOGRAPHY_METADATA_KIND.Bold,
                            color = "neutral/ink90",
                            children,
                            // Any additional props will be passed to the
                            // underlying material-ui component.
                            ...rest
                          }) => {
  const { typography } = useTheme();
  let colorKey1;
  let colorKey2;
  colorKey1 = getColorKey(color).colorKey1;
  colorKey2 = getColorKey(color).colorKey2;

  return (
    <Typography
      component="div"
      style={{
        ...(kind === TYPOGRAPHY_METADATA_KIND.Bold && {
          fontWeight: "bold",
          textTransform: "uppercase",
        }),
        fontSize: `${typographyMetadataHashMap.get(kind).fontSize}`,
        fontFamily: typography.fontFamily,
        letterSpacing: typographyMetadataHashMap.get(kind).letterSpacing,
        lineHeight: typographyMetadataHashMap.get(kind).lineHeight,
        color: colors[colorKey1][colorKey2]
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default TypographyButton;
