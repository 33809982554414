import React, {forwardRef} from "react";
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import {Col, Row} from "react-grid-system";

import {
  PikchaButton,
  PikchaSpacer,
  PikchaUnorderedList
} from "../baseComponents";

const ImageCropperPlaceholderBlock = forwardRef(({
                                                   palette,
                                                   typography,
                                                   onChangeImage
                                                 },
                                                 ref
) => {

  return (
    <div
      className="flex-center justify-center"
      style={{
        width: "100%",
        maxWidth: 1920,
        flexDirection: "column",
        justifyContent: "space-between",
        flex: "3 1 0",
      }}
    >
      <div
        style={{
          border: "2px dashed grey",
          borderRadius: 6,
          width: "80%"
        }}
        className="mt-2-em mb-2-em full-height justify-center flex-column flex-center"
      >
        <div
          className="UploadImage-instructions"
          style={{
            color: palette.grey.dark,
          }}
        >
          <PikchaButton
            onClick={() => ref.current.click()}
            action="octonary"
            className="half-width pl-4-em pr-4-em mr-half-em"
            onClick={onChangeImage}
            style={{
              height: 60,
              marginTop: 16,
              width: "auto",
            }}
          >
            <PublishRoundedIcon/>&nbsp;Select File or Drop
          </PikchaButton>
        </div>
        <Row
          className="text-left Step1-uploadInstructions"
          style={{
            justifyContent: "center"
          }}
        >
          <Col md={4.5}>
            <PikchaUnorderedList
              style={{
                fontSize: typography.smallest,
                fontWeight: typography.fontWeightMedium,
                color: palette.text.secondary,
                textAlign: "center",
                lineHeight: null,
                letterSpacing: null
              }}
            >
              <li
                style={{
                  color: palette.text.primary,
                  fontWeight: 700, textDecoration: "underline"
                }}
              >Uploading Rules
              </li>
              <br/>
              <li>15 MP minimum</li>
              <br></br>
              <li>No borders, text or watermarks</li>
              <br></br>
              <li>Only upload images to sell exclusively on Pikcha</li>
              <br></br>
              <li>Do not upload images that have been shared above 2MP or
                previously published
              </li>
            </PikchaUnorderedList>
          </Col>
        </Row>
        <div
          style={{
            fontSize: typography.smallest,
            fontWeight: typography.fontWeightMedium,
            color: palette.text.secondary,
            textAlign: "center",
            lineHeight: null,
            letterSpacing: null
          }}
        >
          <div>
            <br/>
            <div>
              <span style={{color: palette.text.primary}}>See the&nbsp;</span>
              <a className="medium" href="/guides/artist/uploading">
                Uploading Guide
              </a>
              <span style={{color: palette.text.primary}}>&nbsp;for more details and tips</span>
            </div>
          </div>
        </div>
        <PikchaSpacer/>
      </div>
    </div>
  );
});

export default ImageCropperPlaceholderBlock;
