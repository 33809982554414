import React, { useEffect, useRef, useState } from "react";
import { components } from "react-select";
import PerfectScrollBar from "react-perfect-scrollbar";

export default React.memo(({ children, ...rest }) => {
  const scrollbarRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    scrollbarRef.current.classList.add("ps--active-y");
    scrollbarRef.current.classList.add("ps--scrolling-y");
    scrollbarRef.current.classList.add("ScrollBar-positionAdjust");
  }, []);
  useEffect(() => {
    scrollbarRef.current.addEventListener("ps-scroll-down", (e) => {
      setScrollPosition(e.currentTarget.scrollTop);
    });
    //console.log(scrollPosition);
    scrollbarRef.current.scrollTo({ x: 0, y: scrollPosition });
  }, [scrollbarRef.current]);

  const { MenuList } = components;

  return (
    <PerfectScrollBar
      className="default-cursor"
      containerRef={(ref) => (scrollbarRef.current = ref)}
      options={{
        //minScrollbarLength: 100,
        //wheelSpeed: 0.1,
        suppressScrollX: true,
      }}
    >
      <MenuList {...rest}>{children}</MenuList>
    </PerfectScrollBar>
  );
});
