export const modalTypes = {
  product: 'product',
  createCollection: 'createCollection',
  editAlbum: 'editAlbum',
  viewAlbum: 'viewAlbum',
};

export const modalType = (state) => {
  let modal;
  if (state) {
    modal = state.modalReducer;
  }

  let modalType;
  if (modal) {
    modalType = modal.type;
  }

  return modalType;
};

export const modalData = (state) => {
  let modal;
  if (state) {
    modal = state.modalReducer;
  }

  let modalData;
  if (modal) {
    modalData = modal.data;
  }

  return modalData;
};

export const modalStack = (state) => {
  let modal;
  if (state) {
    modal = state.modalReducer;
  }

  let modalStack;
  if (modal) {
    modalStack = modal.stack;
  }

  return modalStack;
};
