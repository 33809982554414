import React, {useRef, useState} from "react";
import axios from "axios";
import {connect} from "react-redux";
import {useTheme} from "@material-ui/core/styles";

import {setAlert} from "../../actions/alert";
import {PikchaButton, PikchaTextField} from "../baseComponents";
import isMobile from "../Responsive/isMobile";
import LogoWithTitle from "../LogoWithTitle/LogoWithTitle";
import {existingInviteCode, PIKCHA_INVITE_KEY} from "../../helpers/constants";

import "./BetaInviteSection.css";
import {getEnvironment} from "../../helpers/utils";

const BetaInviteSection = ({inviteCodeContext, setAlert}) => {
  const [betaInviteCode, setBetaInviteCode] = useState(existingInviteCode);

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();

  const isOnMobile = isMobile();

  const inputRef = useRef(null);

  const {palette, typography} = useTheme();

  const handleFirstName = ({target: {value}}) => {
    setFirstName(value);
  };
  const handleLastName = ({target: {value}}) => {
    setLastName(value);
  };
  const handleEmail = ({target: {value}}) => {
    setEmail(value);
  };

  const handleNewInviteCode = ({target: {value}}) => {
    if (value === "") {
      setBetaInviteCode();
      return;
    }

    const pattern = /^[0-9]*$/;
    const valueIsANumber = pattern.test(value);

    if (!valueIsANumber) {
      setBetaInviteCode();
      return;
    }

    const parsedValue = parseInt(value);

    setBetaInviteCode(parsedValue);
    localStorage.setItem(PIKCHA_INVITE_KEY, `${parsedValue}`);
  };

  const handleSubmit = () => {
    betaInviteCode > 6500 &&
    betaInviteCode < 6900
      ? inviteCodeContext.handleBetaInviteCode(true)
      : setAlert("Invalid code. Please try again.", "error");
  };

  const handleKeyPress = ({key}) => {
    if (key === "Enter") {
      handleSubmit();
    }
  };

  const handleSendCode = () => {
    setIsEmailSent(true);
    
    axios.post("/api/InternalCommunication/invite", {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
    });
  };

  return (
    <div
      style={{
        minHeight: "100%",
        width: "100%",
        background: "#FFF",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: isOnMobile ? "column" : "row",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 300,
          }}
        >
          <div
            style={{
              marginTop: "2em",
            }}
          >
            <p
              style={{
                fontSize: typography.standard,
                color: palette.text.primary,
                fontWeight: typography.fontWeightMedium,
              }}
            >
              Beta Invite Code
            </p>
            <PikchaTextField
              style={{
                marginTop: "1em",
              }}
              inputRef={inputRef}
              value={betaInviteCode ? betaInviteCode.toString() : ""}
              type="text"
              label="Invite Code"
              reduceBottomMargin
              onChange={handleNewInviteCode}
              onKeyPress={handleKeyPress}
            />
            <PikchaButton
              small
              //type="submit"
              style={{
                marginTop: "1em",
                float: "right",
                marginRight: 0,
                width: 120,
              }}
              onClick={handleSubmit}
            >
              Submit
            </PikchaButton>
          </div>

          {isEmailSent && (
            <div
              style={{
                marginTop: "3em",
              }}
            >
              <p
                style={{
                  fontSize: typography.standard,
                  color: palette.text.primary,
                  fontWeight: typography.fontWeightMedium,
                }}
              >
                Want To Join?
              </p>
              <p
                style={{
                  marginTop: "1em",
                  fontSize: typography.smallest,
                  color: palette.text.secondary,
                  fontWeight: typography.fontWeightMedium,
                }}
              >
                Enter your details below and we will send you the beta invite
                code, granting you priority access!
              </p>
              <p
                style={{
                  marginTop: "1em",
                  fontSize: typography.standard,
                  fontWeight: typography.fontWeightRegular,
                  backgroundColor: palette.success.main,
                  color: palette.success.contrastText,
                  padding: "1em",
                  borderRadius: 6,
                }}
              >
                Our team will contact you shortly with the beta invite code!
              </p>
            </div>
          )}
          {!isEmailSent && (
            <div
              style={{
                marginTop: "3em",
              }}
            >
              <p
                style={{
                  fontSize: typography.standard,
                  color: palette.text.primary,
                  fontWeight: typography.fontWeightMedium,
                }}
              >
                Want To Join?
              </p>
              <p
                style={{
                  marginTop: "1em",
                  fontSize: typography.smallest,
                  color: palette.text.secondary,
                  fontWeight: typography.fontWeightMedium,
                }}
              >
                Enter your details below and we will send you the beta invite
                code, granting you priority access!
              </p>

              <PikchaTextField
                style={{
                  marginTop: "2em",
                }}
                value={firstName}
                type="text"
                label="First Name"
                reduceBottomMargin
                onChange={handleFirstName}
              />

              <PikchaTextField
                style={{
                  marginTop: "1em",
                }}
                value={lastName}
                type="text"
                label="Last Name"
                reduceBottomMargin
                onChange={handleLastName}
              />

              <PikchaTextField
                style={{
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
                value={email}
                type="text"
                label="Email Address"
                reduceBottomMargin
                onChange={handleEmail}
              />

              <PikchaButton
                small
                style={{
                  float: "right",
                  marginRight: 0,
                  width: 120,
                }}
                onClick={handleSendCode}
              >
                Send Code
              </PikchaButton>
            </div>
          )}

          {!isOnMobile && (
            <p
              style={{
                marginTop: "2em",
                fontSize: typography.small,
                color: palette.text.disabled,
              }}
            >
              &copy; Pikcha. All rights reserved.
            </p>
          )}
        </div>
      </div>

      <LogoWithTitle/>

      {isOnMobile && (
        <p
          style={{
            marginTop: "2em",
            fontSize: typography.small,
            color: palette.text.disabled,
            textAlign: "center",
          }}
        >
          &copy; Pikcha. All rights reserved.
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, {setAlert})(BetaInviteSection);
