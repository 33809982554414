import React from "react";
import { useTheme } from "@material-ui/core";

import "./PikchaLoader.css";

export default ({ size, color, message, linearVariant, marginTop=null, width="100%", marginBottom=null, ...rest }) => {
  const { palette } = useTheme();

  return (
    <div
      style={{
        display: "flex",
        width: width,
        justifyContent: "center",
      }}
    >
      <div 
        id="spinner" 
        style={{
          marginTop: marginTop, 
          marginBottom: marginBottom,
          width: size && size.width ? size.width : 24,
          height: size && size.height ? size.height : 24
        }}
      />
    </div>
  );
};
