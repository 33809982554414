import React from "react";
import { useTheme } from "@material-ui/core";
import ContentLoader from "react-content-loader";
import { PikchaLabel } from "../../components/baseComponents";

export default () => {
  const { palette } = useTheme();

  return (
    <div
      style={{
        paddingLeft: "1em",
        paddingRight: "1em",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <PikchaLabel>Artists</PikchaLabel>
      <ContentLoader
        speed={3}
        width={240}
        height={160}
        viewBox="0 0 240 160"
        backgroundColor={palette.grey.lighter}
        foregroundColor={palette.grey.lighter}
      >
        <circle cx="12" cy="20" r="12" />
        <rect x="29" y="15" rx="5" ry="5" width="148" height="14" />
        <circle cx="12" cy="50" r="12" />
        <rect x="29" y="45" rx="5" ry="5" width="148" height="14" />
        <circle cx="12" cy="80" r="12" />
        <rect x="29" y="75" rx="5" ry="5" width="148" height="14" />
        <circle cx="12" cy="110" r="12" />
        <rect x="29" y="105" rx="5" ry="5" width="148" height="14" />
      </ContentLoader>
    </div>
  );
};
