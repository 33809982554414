import React, { useState } from "react";
import isDesktopHD from "../../../components/Responsive/isDesktopHD";
import isMobile from "../../../components/Responsive/isMobile";
import Desktop from "../../../components/Responsive/Desktop";
import Tablet from "../../../components/Responsive/Tablet";
import Mobile from "../../../components/Responsive/Mobile";
import ResetPassword from "./Security";
import NotificationSettings from "./NotificationSettings";
import GeneralSettings from "./GeneralSettings";
import EmailPreferences from "./EmailPreferences";

export default () => {

  const [expandedPanel, setExpandedPanel] = useState('panel1');
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };
  const isOnDesktopHD = isDesktopHD();
  const isOnMobile = isMobile();

  const desktopHDStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 810,
    width: "100%",
  };

  return (
    <div
      style={{
        marginLeft: isOnMobile ? "1.5em" : "6em",
        marginRight: isOnMobile ? "1.5em" : "6em",
        marginTop: "1em",
        ...(isOnDesktopHD && desktopHDStyle),
      }}
    >
      <Desktop>
        <ResetPassword
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
        <div
          style={{
            paddingBottom: "1em",
          }}
        />
        <NotificationSettings
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
        <div
          style={{
            paddingBottom: "1em",
          }}
        />
        <EmailPreferences
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
        <div
          style={{
            paddingBottom: "1em",
          }}
        />
        <GeneralSettings
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
      </Desktop>
      <Tablet>
        <ResetPassword
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
        <div
          style={{
            paddingBottom: "2.5em",
          }}
        />
        <NotificationSettings
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
        <div
          style={{
            paddingBottom: "2.5em",
          }}
        />
        <EmailPreferences
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
        <div
          style={{
            paddingBottom: "2.5em",
          }}
        />
        <GeneralSettings
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange}
        />
      </Tablet>
      <Mobile>
        <ResetPassword
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange} />
        <div
          style={{
            paddingBottom: "0.5em",
          }}
        />
        <NotificationSettings
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange} />
        <div
          style={{
            paddingBottom: "0.5em",
          }}
        />
        <EmailPreferences
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange} />
        <div
          style={{
            paddingBottom: "0.5em",
          }}
        />
        <GeneralSettings
          expandedPanel={expandedPanel}
          handleAccordionChange={handleAccordionChange} />
      </Mobile>
    </div>
  );
};
