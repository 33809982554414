import React from "react";
import { useTheme } from "@material-ui/core/styles";
import isMobile from "../../Responsive/isMobile";
import isTablet from "../../Responsive/isTablet";
import isDesktopSD from "../../Responsive/isDesktopSD";

export default ({ children, style = {}, ...rest }) => {
  const { palette, typography } = useTheme();

  const isOnMobile = isMobile();
  const isOnTablet = isTablet();
  const isOnDesktopSD = isDesktopSD();

  return (
    <div
      className="block"
      // className="flex-center"
      style={{
        // whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: isOnDesktopSD ? 200 : isOnMobile ? undefined/*258*/ : isOnTablet ? undefined/*175*/ : 156,
        
        color: palette.grey.mediumDark,
        fontWeight: typography.fontWeightMedium,
        fontSize: typography.standard,
        letterSpacing: "-.35px",
        marginTop: 2,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
