import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import ProductItemDesktop from "../containers/product/ProductItem/ProductItemDesktop";
import { PikchaLoader } from "../components/baseComponents";

import { getPhotoQRCode } from "../actions/gallery";

const QRImageDetails = ({ getPhotoQRCode, gallery }) => {
  const image = gallery.photos.length > 0 ? gallery.photos[0] : null;
  const loading = gallery.loading;

  useEffect(() => {
    getPhotoQRCode(window.location.pathname);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Pikcha</title>
      </Helmet>
      <div
        style={{
          width: "100%",
          marginTop: "64px",
        }}
      >
        {image && <ProductItemDesktop image={image} />}
        {loading && (
          <div
            style={{
              marginTop: "128px",
              display: "flex",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <PikchaLoader />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  gallery: state.galleryReducer,
});

export default connect(mapStateToProps, {
  getPhotoQRCode,
})(QRImageDetails);
