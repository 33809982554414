import React from 'react';

import isMobile from '../../components/Responsive/isMobile';
import AuthRoutes from '../../routes/AuthRoutes';
import LogoWithTitle from '../../components/LogoWithTitle/LogoWithTitle';

export default ({ match }) => {
  const isOnMobile = isMobile();

  return (
    <div className={`${!isOnMobile ? 'pl-5-em' : ''} flex-grow-1 flex-stretch`}>
      <div
        className="flex-grow-1 flex-center"
        style={{
          flexDirection: !isOnMobile ? 'row' : 'column',
        }}
      >
        <div
          className="AuthPage-leftContainer flex-center justify-center"
          style={{
            paddingTop: !isOnMobile ? null : '6em',
          }}
        >
          <AuthRoutes match={match} />
        </div>
        <div
          className="AuthPage-rightContainer flex-center justify-center"
          style={{
            paddingLeft: !isOnMobile ? '20%' : null,
          }}
        >
          <LogoWithTitle adjustTopMargin />
        </div>
      </div>
    </div>
  );
};
