import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ noUnderline = true, style = {}, children, ...rest }) => {
  const { typography, palette, spacing } = useTheme();

  return (
    <div
      style={{
        fontSize: typography.standard,
        fontWeight: typography.fontWeightMedium,
        marginBottom: !noUnderline ? spacing(2) : null,
        //marginBottom: !noUnderline ? "2rem" : null,
        borderBottom: !noUnderline ? `2px solid ${palette.grey.light}` : null,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
