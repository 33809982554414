import axios from "axios";

import AuthorizeService from "../auth/AuthorizeService";

import { inAppNotificationUrl } from "../selectors/settings";
import {
  accountNotificationsCount,
  accountNotificationsStart,
} from "../selectors/accountNotifications";

import {
  ACCOUNT_NOTIFICATIONS_DELETE,
  ACCOUNT_NOTIFICATIONS_ERROR,
  ACCOUNT_NOTIFICATIONS_LOADED,
  ACCOUNT_NOTIFICATIONS_LOADING,
  ACCOUNT_NOTIFICATIONS_MARK_ALL_AS_READ,
  ACCOUNT_NOTIFICATIONS_READ,
} from "./types";

export const getAccountNotifications = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAccountNotificationsLoading());

      const skip = accountNotificationsStart(getState());
      const count = accountNotificationsCount(getState());

      const notificationUrl = inAppNotificationUrl(getState());

      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.get(`${notificationUrl}/NotificationHistory`, {
        params: {
          userId: user.sub,
          skip,
          count,
        },
      });
      dispatch({
        type: ACCOUNT_NOTIFICATIONS_LOADED,
        payload: res.data,
      });
    }
    catch (err) {
      dispatch({ type: ACCOUNT_NOTIFICATIONS_ERROR, payload: err.response });
    }
  };
};

export const accountNotificationRead = (notificationId) => {
  return async (dispatch, getState) => {
    try {
      const notificationUrl = inAppNotificationUrl(getState());

      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.post(
        `${notificationUrl}/IsNotificationRead`,
        {},
        {
          params: {
            userId: user.sub,
            notificationId,
          },
        },
      );

      dispatch({
        type: ACCOUNT_NOTIFICATIONS_READ,
        payload: notificationId,
      });
    }
    catch (err) {
      console.log("accountNotification, accountNotificationRead ", err);
    }
  };
};

export const accountNotificationDelete = (notificationId) => {
  return async (dispatch, getState) => {
    try {
      const notificationUrl = inAppNotificationUrl(getState());

      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.post(
        `${notificationUrl}/DeleteNotification`,
        {},
        {
          params: {
            userId: user.sub,
            notificationId,
          },
        },
      );
      dispatch({
        type: ACCOUNT_NOTIFICATIONS_DELETE,
        payload: notificationId,
      });
    }
    catch (err) {
      console.log("accountNotification, accountNotificationDelete", err);
    }
  };
};

export const accountNotificationMarkAllAsRead = () => {
  return async (dispatch, getState) => {
    try {
      const notificationUrl = inAppNotificationUrl(getState());
      const user = await AuthorizeService.getUser();
      const res = await axios.post(`${notificationUrl}/${user.sub}/MarkAllAsRead`);
      dispatch({
        type: ACCOUNT_NOTIFICATIONS_MARK_ALL_AS_READ,
      });
    }
    catch (err) {
      console.log("accountNotification, accountNotificationMarkAllAsRead", err);
      dispatch({ type: ACCOUNT_NOTIFICATIONS_ERROR, payload: err.response });
    }
  };
};

export const setAccountNotificationsLoading = () => {
  return { type: ACCOUNT_NOTIFICATIONS_LOADING };
};
