import React from "react";
import { connect } from "react-redux";
import Img from "react-image";
import { useHistory, useLocation } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import ShoppingCartRoundedIcon from "@material-ui/icons/ShoppingCartRounded";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import Tooltip from "@material-ui/core/Tooltip";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import { useTheme } from "@material-ui/core/styles";

import {
  handleAuthModal,
  openAuthentication,
  openRegistration,
} from "../../actions/authModal";
import { openMenu, openProfile } from "../../actions/nav";

import { hasUnreadAccountNotifications } from "../../selectors/accountNotifications";

import { ICON_SIZE_DEFAULT } from "../../helpers/constants";
import { getValueIfKeyExists } from "../../helpers/utils";

import SearchBar from "../../components/search/SearchBar";
import UploadIcon from "../../components/extraIcons/UploadIcon";
import { PikchaBadge, PikchaButton } from "../../components/baseComponents";
import NavigationSkeletonLoader
  from "../../components/skeletonLoaders/NavigationSkeletonLoader";

import Logo from "../../assets/images/logo-alt.svg";

import { useStyles } from "./NavBar.styles";
import NotificationsDropdown
  from "../../components/dropdowns/NotificationsDropdown/NotificationsDropdown";
import { PikchaLink } from "../../components/baseComponents/PikchaLink";
import { ScrollingBanner } from "../../components/ScrollingBanner/ScrollingBanner";
import Marquee from "react-fast-marquee";
const constructBannerNode = msgText => msgText;

const NavBarDesktop = ({
  navBarRef,
  menuIconRef,
  pathname,
  handleSideDrawer,
  payload,
  isLoading,
  loggedIn,
  handleUploadClick,

  hasUnreadAccountNotifications,
  account,
  shoppingCart,
  openMenu,
  openProfile,
  handleAuthModal,
  openAuthentication,
  openRegistration,
  cropper,
  handleClick,
  handleClose,
  anchorEl,
  open,
}) => {
  const classes = useStyles();
  const { palette, typography, zIndex: { drawer }, spacing } = useTheme();
  const history = useHistory();
  const location = useLocation();
  return (
    <>
      <AppBar
        id="navbar-header"
        className={`AppBar-top nonPrintable ${classes.root}`}
        ref={navBarRef}
      >
        <Toolbar disableGutters>
          <div className="navbar-top nonPrintable">
            <div
              ref={menuIconRef}
              style={{
                paddingLeft: "1.35em",
                paddingRight: "1em",
              }}
            >
              <IconButton
                onClick={() => handleSideDrawer(!payload)}
                className="NavButton"
              >
                <MenuRoundedIcon
                  style={ICON_SIZE_DEFAULT}
                  fontSize="inherit"
                  color="action"
                />
              </IconButton>
            </div>
            <div className="nav-logo">
              <div className="pl-half-em">
                <PikchaLink to="/">
                  <Img src={Logo} alt="" className="Pikcha-logo" />
                </PikchaLink>
              </div>
            </div>
            <SearchBar pathname={pathname} />
            {!isLoading && loggedIn && (
              <div className="auth-items">
                <div className="Onboarding-3">
                  {/*
                    <TypographyButton
                      size="small"
                      component="outline"
                      buttonText="small"
                      icon={<EditRoundedIcon/>}
                    >
                      121212
                    </TypographyButton>
                  */}
                  {/*
                    <TypographySelector 
                      selectorType="toggle"
                    />
                  */}
                  <Tooltip
                    title={
                      <span style={{ fontSize: "8px" }}>Upload an image</span>}
                    interactive
                    disableTouchListener
                    enterDelay={500}
                    leaveDelay={50}
                  >
                    <PikchaButton
                      style={{
                        minWidth: 120,
                        height: 32,
                      }}
                      className="Onboarding-3 flex-center justify-center"
                      onClick={handleUploadClick}
                      action="octonary"
                      disabled={window.location.href.includes("/upload") && cropper.uploadStep === 1
                        ? true
                        : false}
                    >
                      <span style={{ paddingRight: "0.25em" }}>
                        <UploadIcon
                          color={palette.text.primary}
                          iconSize={24}
                        />
                      </span>
                      <span
                        style={{
                          fontSize: typography.standard,
                          fontWeight: typography.fontWeightMedium,
                          color: palette.text.primary,
                        }}
                      >
                        Upload
                      </span>
                    </PikchaButton>
                  </Tooltip>
                </div>
                <div className="user-item">
                  <PikchaLink to="/checkout">
                    <IconButton
                      className="NavButton"
                      style={{
                        paddingTop: 2,
                      }}
                    >
                      <PikchaBadge
                        badgeContent={
                          getValueIfKeyExists(
                            shoppingCart,
                            "shoppingCart.totalItems",
                          ) || 0
                        }
                      >
                        <ShoppingCartRoundedIcon
                          style={{
                            color: "rgb(66, 66, 66)",
                            ...ICON_SIZE_DEFAULT,
                          }}
                        />
                      </PikchaBadge>
                    </IconButton>
                  </PikchaLink>
                </div>
                <div className="user-item">
                  <IconButton onClick={handleClick} className="NavButton">
                    {hasUnreadAccountNotifications && (
                      <div
                        style={{
                          marginLeft: "-0.45em",
                          alignSelf: "flex-start",
                          width: 12,
                          height: 12,
                          borderRadius: "50%",
                          backgroundColor: palette.error.main,
                          position: "absolute",
                          right: 1,
                        }}
                      />
                    )}
                    <NotificationsRoundedIcon
                      style={{
                        color: "rgb(66, 66, 66)",
                        ...ICON_SIZE_DEFAULT,
                      }}
                    />
                  </IconButton>
                  <NotificationsDropdown
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                  />
                </div>
                <div className="user-item">
                  <IconButton
                    className="NavButton"
                    style={{
                      paddingTop: 4.5,
                    }}
                  >
                    {getValueIfKeyExists(account, "user.avatar") ===
                      "Data/Test/Avatars/default-avatar.jpg" ||
                      getValueIfKeyExists(account, "user.avatar") === "" ? (
                      <PersonRoundedIcon
                        onMouseDown={() => openProfile()}
                        style={ICON_SIZE_DEFAULT}
                      />
                    ) : (
                      <Img
                        onMouseDown={() => openProfile()}
                        className="user-pic --opaque"
                        src={getValueIfKeyExists(account, "user.avatar")}
                        alt=""
                      />
                    )}
                  </IconButton>
                </div>
              </div>
            )}
            {!isLoading && !loggedIn && (
              <div className="auth-links">
                <div className="NavItem">
                  {pathname.includes("auth") ? (
                    <PikchaLink to="/auth">
                      <IconButton className="NavButton">
                        <PikchaBadge
                          badgeContent={
                            getValueIfKeyExists(
                              shoppingCart,
                              "shoppingCart.totalItems",
                            ) || 0
                          }
                        >
                          <ShoppingCartRoundedIcon
                            style={{
                              color: "rgb(66, 66, 66)",
                              ...ICON_SIZE_DEFAULT,
                            }}
                          />
                        </PikchaBadge>
                      </IconButton>
                    </PikchaLink>
                  ) : (
                    <PikchaLink to="/checkout">
                      <div className="NavItem">
                        <PikchaBadge
                          badgeContent={
                            getValueIfKeyExists(
                              shoppingCart,
                              "shoppingCart.totalItems",
                            ) || 0
                          }
                        >
                          <ShoppingCartRoundedIcon
                            style={{
                              ...ICON_SIZE_DEFAULT,
                              color: palette.grey.dark,
                            }}
                          />
                        </PikchaBadge>
                      </div>
                    </PikchaLink>
                  )}
                </div>
                <div className="NavItem">
                  <IconButton onClick={openMenu} className="NavButton">
                    <NotificationsRoundedIcon
                      style={{
                        color: "rgb(66, 66, 66)",
                        ...ICON_SIZE_DEFAULT,
                      }}
                    />
                    <NotificationsDropdown
                      open={open}
                      onClose={handleClose}
                      anchorEl={anchorEl}
                    />
                  </IconButton>
                </div>
                <div
                  className="NavItem"
                  onClick={() => openRegistration(history, location)}
                  style={{
                    width: "max-content",
                  }}
                >
                  <PikchaButton
                    style={{
                      marginLeft: "0.5em",
                      minWidth: 100,
                      height: 36,
                      fontSize: typography.small,
                      color: palette.text.primary,
                    }}
                    middle
                    action="octonary"
                  >
                    Sign in
                  </PikchaButton>
                </div>
              </div>
            )}
            {isLoading && (
              <div>
                <NavigationSkeletonLoader />
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>

      {/* {["/", "/pikcha100", "/artist100"].includes(pathname) && (
        <Marquee
          style={{
            position: "fixed",
            backgroundColor: "#000",
            top: spacing(7),
            height: 44,
            zIndex: drawer - 1,
            color: "#FFFFFF",
            textTransform: "uppercase",
          }}
          speed={25}
          gradient={false}
        >
          <span style={{ fontWeight: 600 }}> Sign-up to Pikcha </span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>
          <span>$50 USD off your first order</span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>


          <span style={{ fontWeight: 600 }}> Sign-up to Pikcha </span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>
          <span>$50 USD off your first order</span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>


          <span style={{ fontWeight: 600 }}> Sign-up to Pikcha </span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>
          <span>$50 USD off your first order</span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>


          <span style={{ fontWeight: 600 }}> Sign-up to Pikcha </span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>
          <span>$50 USD off your first order</span>
          <span style={{ fontWeight: 600, margin: "0 10px" }}> • </span>

        </Marquee>
      )} */}
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  shoppingCart: state.shoppingCartReducer,
  hasUnreadAccountNotifications: hasUnreadAccountNotifications(state),
});

export default connect(mapStateToProps, {
  openMenu,
  openProfile,
  handleAuthModal,
  openAuthentication,
  openRegistration,
})(NavBarDesktop);
