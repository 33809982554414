import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {useTheme} from "@material-ui/core/styles";

import {searchAlbumsByImage} from "../../actions/search";
import AlbumGallery from "../../containers/gallery/AlbumGallery/AlbumGallery";
import { AlbumIcon } from '../../components/extraIcons/index';
import { ICON_SIZE_DEFAULT } from "../../helpers/constants";
import UserDetails from "../../components/UserDetails/UserDetails";

const RelatedAlbums = ({
                         image,
                         albums,
                         resetAlbums,
                         style = {},
                        searchAlbumsByImage,
                       } = {}) => {
  const {palette, typography} = useTheme();

  let imageId;
  let imageTitle;
  if (image) {
    imageId = image.id;
    imageTitle = image.title;
  }

  
  let artistId;
  let artistNickName;
  let artistAvatar;
  let artistUserHandle;
  if (image && image.artist) {
    artistId = image.artist.id;
    artistNickName = image.artist.nickName;
    artistAvatar = image.artist.avatar;
    artistUserHandle = image.artist.userHandle;
  }

  useEffect(() => {
      searchAlbumsByImage(imageId);
  }, [image]);

  const onResetAlbums = () => {
//    resetAlbums();
  };
  const onRefreshAlbums = () => {
//    resetAlbums();
  };


  return (
    <>
      {(albums.albums.length>0) &&
      <div
        style={{
          display: "grid",
          marginTop: "7em",
          ...style,
        }}
      >
        <div
          style={{
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.standard,
            color: palette.text.secondary,
            display:"flex",
          }}
        >
          <div style={{
              justifyContent: "flex-start",
              paddingRight:"0.5em",
              marginTop:2,
              fontWeight: typography.fontWeightSemiMedium, 
              fontSize: typography.smallest,
              color: palette.text.secondary,
            }}
          >
            {imageTitle} is a part of the following {(albums.albums.length>1)? 'albums' : 'album'}
          </div>

          <div 
            style={{
              justifyContent: "flex-start",
              paddingLeft: "0.5em",
            }}
          >
            <UserDetails
              id={artistId}
              userHandle={artistUserHandle}
              nickName={artistNickName}
              avatar={artistAvatar}
            /> 
          </div>
        </div>
        <AlbumGallery
            albums={albums}
            resetAlbums={onResetAlbums}
            refreshAlbums={onRefreshAlbums}
            creatable={false}
            isInProfilePage={false}
            isInSearchPage={false}
            isInRelatedAlbumPage={true}
            setCustomMargin={"1em"}
          />
      </div>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  albums:state.relatedAlbumsReducer,
});

export default connect(mapStateToProps, {
  searchAlbumsByImage,
})(RelatedAlbums);
