export const getColorKey = (color) => {
  let colorKey1;
  let colorKey2;
  if(color){
    colorKey1 = (color.slice(0,1).toUpperCase() + color.slice(1).toLowerCase()).split("/")[0];
    colorKey2 = (color.slice(0,1).toUpperCase() + color.slice(1).toLowerCase()).split("/")[1];
  } else {
    colorKey1 = "Neutral";
    colorKey2 = "ink90";
  }
  const dto = {
    colorKey1: colorKey1,
    colorKey2: colorKey2
  }
  return dto;
}

export const ICON_SIZE_SMALL = {
  height: 16,
  width: 16,
};