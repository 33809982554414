import React from "react";
import { useTheme } from "@material-ui/core/styles";

export default ({ children, style, ...rest }) => {
  const { palette, typography } = useTheme();

  return (
    <div
      className="flex-center no-select"
      style={{
        color: palette.text.primary,
        fontFamily: typography.fontFamilyMontserrat,
        fontSize: typography.smallest,
        fontWeight: typography.fontWeightMedium,
        paddingTop: 2,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
