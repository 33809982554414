import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { showEditAlbumModal, removeModal } from "../../../actions/modal";
import MasonryGallery from "../../gallery/MasonryGallery/MasonryGallery";
import { PikchaImageMetaText } from "../../../components/baseComponents";
import isMobile from "../../../components/Responsive/isMobile";
import isDesktop from "../../../components/Responsive/isDesktop";
import ViewIcon from "../../../assets/images/visibility-black-24dp.svg";
import SoldIcon from "../../../assets/images/filter_frames-black-24dp.svg";
import MoneyIcon from "../../../assets/images/attach_money-black-24dp.svg";
import InsertPhotoIcon from "../../../assets/images/insert_photo-grey-24dp.svg";
import iconCollection from "../../../assets/images/Collection/iconCollection.jpg"
import bannerCollection from "../../../assets/images/Collection/bannerCollection.jpg"
import { currencyFormat, IntlCurrencyFormatter } from "../../../components/NumberFormatters/IntlCurrencyFormatter";
import { TypographyBody, TypographySubtitle, TYPOGRAPHY_BODY_SIZE, TYPOGRAPHY_SUBTITLE_COMPONENT } from "@pikcha/typography";
import MoreHorizRounded from "@material-ui/icons/MoreHorizRounded";
import Popover from '@material-ui/core/Popover';
import { deleteCollection, resetSelectedImages } from "../../../actions/albums";
import { useHistory } from "react-router-dom";
import { setAlert } from "../../../actions/alert";
import AlbumInfoModal from "./AlbumInfoModal";
import CollectionSharingModal from "./CollectionSharingModal";
import DeleteModal from "./DeleteModal";
import ReadMore from "./ReadMore";

const ViewingAlbum = ({ 
  albumItem, 
  creatable, 
  showEditAlbumModal, 
  deleteCollection, 
  setAlert,
  removeModal,
  resetSelectedImages,
  account
}) => {
  let collectionUrl;
  let images = [];
  let title;
  if (albumItem) {
    collectionUrl = albumItem.friendlyUrl;
    images = albumItem.images;
    title = albumItem.title;
  }
  let nickName;
  let avatar;
  let userHandle;
  if (albumItem) {
    const { pikchaUser: artist } = albumItem;
    nickName = artist?.nickName;
    avatar = artist?.avatar;
    userHandle = artist?.userHandle;
  }

  const isOnMobile = isMobile();
  const isOnDesktop = isDesktop();
  const history = useHistory()

  const onEditAlbumFromViewing = () => {
    showEditAlbumModal(albumItem);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [lowestPrice, setLowestPrice] = useState();
  const [highestPrice, setHighestPrice] = useState();

  useEffect(() => {
    let lowestPrice = images[0].setPrice;
    let highestPrice = images[0].setPrice;
    images.map((img) => {
      if(img.setPrice < lowestPrice){
        lowestPrice = img.setPrice
      }
      if(img.setPrice > highestPrice){
        highestPrice = img.setPrice
      }
    })
    setLowestPrice(lowestPrice);
    setHighestPrice(highestPrice);
  }, [images])

  const openSharingModal = () => {
    setShareModalOpen(true)
  }

  const openDeleteModal = () => {
    setDeleteModalOpen(true)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseModal = () => {
    removeModal();
    resetSelectedImages();
  };

  const onDeleteAlbum = () => {
    const { id: collectionId } = albumItem;
    const profileRedirect = albumItem.pikchaUser.userHandle
    deleteCollection({
      collectionId,
      onDone: () => {
        onCloseModal();
        setAlert("Collection deleted", "info");
        history.push(`/${profileRedirect}`);
      },
      onError: () => { },
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div
        style={{
          marginTop: "1em",
          marginBottom: "4em",
          width: "100%"
        }}
      >
        <div 
          style={{
            position: 'relative',
            width: '100%',
            height: isOnMobile ? 300 : 500,
            marginBottom: isOnMobile ? "1em" : '5em',
            background: `url(${albumItem.banner ? albumItem.banner : bannerCollection}) center center / cover no-repeat`,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div 
            style={{
              maxWidth: 1320,
              width: "100%",
              position: 'absolute',
              bottom: '-40px',
            }}
          >
            <div 
              style={{
                left: '70px',
                border: '1px solid #FFF',
                borderRadius: '11px',
                filter: 'drop-shadow(0px 2.47px 2.47px rgba(0, 0, 0, 0.1))',
                width: '100px',
                height: '100px',
                background: `url(${albumItem.icon ? albumItem.icon : iconCollection}) center center / cover no-repeat`,
                marginLeft: !isOnDesktop && "1em"
              }}
            />
          </div>
        </div>
        <div style={{ maxWidth: 1320, margin: "0 auto", padding: !isOnDesktop && "1em" }}>
          <div
            style={{
              marginTop: "1em",
              display: "flex"
            }}
          >
            <div>
              <TypographySubtitle
                component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
                style={{ marginBottom: '0.5em' }}
              >
                {title}
              </TypographySubtitle>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
                onClick={() => {
                  history.push(`/${userHandle}`);
                }}
              >
                <img
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    marginRight: '0.5em'
                  }}
                  src={avatar}
                  alt="User Picture"
                />
                <TypographyBody
                  size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
                >
                  {nickName}
                </TypographyBody>
              </div>
            </div>
            {/**
             * what if it's not own profile????????
             */}
            {albumItem?.pikchaUser?.id === account?.user?.id && (
              <div
                style={{
                  display: "flex",
                  marginLeft: "auto",
                }}
              >
                {creatable && (
                  <>
                    <div
                      onClick={handleClick}
                      style={{
                        cursor: 'pointer',
                        position: "relative",
                        margin: "16px",
                        width: "auto",
                        display: "inline-block"
                      }}>
                      <MoreHorizRounded
                        aria-describedby={id}
                      />
                      <Popover
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <div 
                          style={{
                            width: '130px',
                            padding: '1em 1.5em',
                            boxShadow: ' 0px 4px 16px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                          }}
                        >
                          <TypographyBody
                            style={{
                              cursor: 'pointer',
                              marginBottom: '1em'
                            }}
                            onClick={onEditAlbumFromViewing}
                          >
                            Edit
                          </TypographyBody>
                          <TypographyBody
                            style={{
                              cursor: 'pointer',
                              marginBottom: '1em'
                            }}
                            onClick={openSharingModal}
                          >
                            Share
                          </TypographyBody>
                          <TypographyBody
                            onClick={openDeleteModal}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            Delete
                          </TypographyBody>
                        </div>
                      </Popover>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: "1em",
              ...(isOnMobile
                ? {}
                : {
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: isOnMobile ? "column" : "row"
                }),
            }}
          >
            <div
              style={{
                width: isOnMobile ? "100%" : "45%"
              }}
            >
              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Regular}
              >
                <ReadMore
                  limit={160}
                >
                  {albumItem.description}
                </ReadMore>
              </TypographyBody>
            </div>
            <div
              style={{
                width: isOnMobile ? "100%" : "45%"
              }}
            >
              <AlbumInfoModal 
                artworkNum={albumItem?.images?.length}
                lowestPrice={lowestPrice}
                highestPrice={highestPrice}
                targetCurrency={account?.user?.preferredCurrency}
              />
            </div>
          </div>
          <MasonryGallery
            noItemHeaders
            noContainerMargins
            noBackground
            gallery={{
              loading: false,
              hasMore: false,
              photos: images,
              count: images.length,
              start: 1,
            }}
            getPhotos={() => {
            }}
            resetGallery={() => {
            }}
          />
          <CollectionSharingModal
            collectionUrl={collectionUrl}
            open={shareModalOpen}
            onClose={() => setShareModalOpen(false)}
          />
          <DeleteModal
            onDeleteAlbum={onDeleteAlbum}
            friendlyUrl={userHandle}
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer
});

export default connect(mapStateToProps, {
  showEditAlbumModal,
  deleteCollection,
  setAlert,
  removeModal,
  resetSelectedImages
})(ViewingAlbum);
