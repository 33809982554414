import React, { Fragment, useEffect, useState, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import PhotoRoundedIcon from "@material-ui/icons/PhotoRounded";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";

import InfiniteScroll from "react-infinite-scroll-component";

import { useTheme } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import Grid from "@material-ui/core/Grid/Grid";

import { getArtist100, resetArtist100 } from "../actions/artist100";
import { getPikcha100, resetPikcha100 } from "../actions/pikcha100";
import {
  ICON_SIZE_SMALL,
  GALLERY_ITEMINPAGE_DEFAULT,
  GALLERY_PAGE_DEFAULT,
} from "../helpers/constants";

import Pikcha100Sharing from "../containers/modals/Pikcha100Sharing/Pikcha100Sharing";
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded';

import {
  PikchaReusableDropdown,
  PikchaReusableTabsGroup,
  PikchaReusableTab,
  PikchaIconButton
} from "../components/baseComponents";

import isDesktop from "../components/Responsive/isDesktop";
import isTablet from "../components/Responsive/isTablet";
import isMobile from "../components/Responsive/isMobile";
import {PikchaRowLoader} from "../containers/pikcha100/PikchaRow";

const PikchaRow = lazy(() => import("../containers/pikcha100/PikchaRow"));
const ArtistRow = lazy(() => import("../containers/artist100/ArtistRow"));

const Pikcha100 = ({
  history,
  routePageType = "pikcha100",
  pikcha100,
  getPikcha100,
  resetPikcha100,
  artist100,
  getArtist100,
  resetArtist100,
} = {}) => {
  let pikcha100rows = pikcha100.top100;
  if (pikcha100.loading) {
    for (let i = 0; i < 9; i++) {
      pikcha100rows = [
        ...pikcha100rows,
        ...[
          {
            placeholder: true,
          },
        ],
      ];
    }
  }

  let artist100rows = artist100.top100;
  if (artist100.loading) {
    for (let i = 0; i < 9; i++) {
      artist100rows = [
        ...artist100rows,
        ...[
          {
            placeholder: true,
          },
        ],
      ];
    }
  }

  const { palette, shape, typography } = useTheme();

  const searchValue = "";
  const order = "asc";
  const [showShare, setShowShare] = useState(false);
  const [pageType, setPageType] = useState(routePageType);
  const [sortby, setSortBy] = useState({ value: "RV", label: "Relevance" });

  useEffect(() => {
    if(pikcha100.start <= 1 && pageType === "pikcha100"){
      getPikcha100(
        GALLERY_ITEMINPAGE_DEFAULT,
        GALLERY_PAGE_DEFAULT,
        "RV",
        "asc",
      );
      return
    }
    if(artist100.start <= 1 && pageType === "artist100"){
      getArtist100(
        GALLERY_ITEMINPAGE_DEFAULT,
        GALLERY_PAGE_DEFAULT,
        "RV",
        "asc",
      );
      return
    }
  }, [pageType])
  
  const changeUrl = (type, filter, order) => {
    history.push({
      pathname: "/pikcha100",
      search: `?type=${type}`,
    });
  };
  const onPageTypeChanged = (e, newValue) => {
    setPageType(newValue);
    history.push(`/${newValue}`);
  };
  const loadNext = () => {
    getPikcha100(
      pikcha100.count,
      pikcha100.start,
      sortby.value,
      order
    );
  };
  const loadNextArtists = () => {
    getArtist100(
      artist100.count,
      artist100.start,
      sortby.value,
      order
    );
  };
  const shareContent = () => {
    setShowShare(!showShare);
  };
  const onCloseSharing = () => {
    setShowShare(false);
  };

  useEffect(() => {
    let params = history.location.search.replace("?", "").split("&");
    let type = "pikcha100";
    params.forEach((param, index) => {
      if (param.split("=")[0] === "type") {
        type = param.split("=")[1];
      }
    });
  }, [history.location.search]);


  const isOnDesktop = isDesktop();
  const isOnTablet = isTablet();
  const isOnMobile = isMobile();

  const handleFilterChange = (sortby) => {
    setSortBy(sortby);
    if (pageType === "artist100") {
      resetArtist100();
      getArtist100(
        GALLERY_ITEMINPAGE_DEFAULT,
        GALLERY_PAGE_DEFAULT,
        sortby.value,
        order
      );
    } else if (pageType === "pikcha100") {
      resetPikcha100();
      getPikcha100(
        GALLERY_ITEMINPAGE_DEFAULT,
        GALLERY_PAGE_DEFAULT,
        sortby.value,
        order
      );
    }
  };

  return (
    <>
      <Fragment>
        <Helmet>
          <title>{pageType === "artist100" ? "The Artist 100" : "The Pikcha 100"} | Pikcha</title>
          {pageType === "artist100" ? (
            <meta name="description" content="View the greatest 100 artists featured on Pikcha Today."/>
          ) : (
            <meta name="description" content="View the greatest 100 artworks featured on Pikcha."/>
          )}
        </Helmet>

      <Grid
        container
        style={{
          borderRadius: shape.borderRadius,
          marginTop: "4.5em",
        }}
      >
        <Grid item xs={12}
          style={{
            // display: "flex",
            // justifyContent: "center",
            marginLeft: isOnDesktop ? "6em" : isOnTablet ? "4em" : "1em",
            marginRight: isOnDesktop ? "6em" : isOnTablet ? "4em" : "1em",
            width: "-webkit-fill-available"
          }}
        >
          <div
            style={{
              backgroundColor: "FFFFFF",
              borderRadius: 10,
              marginLeft: isOnDesktop ? "auto" : isOnTablet ? "auto" : 7,
              marginRight: isOnDesktop ? "auto" : isOnTablet ? "auto" : 7,
              maxWidth: 1320,
              paddingTop: "1.5em",
            }}
          >
            <div
              style={{
                display: "flex",
                marginLeft: isOnMobile ? 0 : 20,
                alignItems: "center"
              }}
            >
              <div
                style={{
                  paddingTop: 0,
                  fontFamily: typography.fontFamilyMontserrat,
                  fontSize: isOnMobile ? 16 : 56,
                  color: palette.text.primary,
                  marginRight: 25,
                  marginTop: isOnMobile && 17,
                  fontWeight: "bold"
                }}
              >
                {pageType === "pikcha100" ? "Pikcha 100" : "Artist 100"}
              </div>
              <div
                style={{
                  paddingTop: 0,
                  fontFamily: typography.fontFamilyMontserrat,
                  fontSize: 36,
                  color: palette.text.primary,
                  fontWeight: typography.fontWeightMedium,
                  width: 25
                }}
              >
                |
              </div>
              <div>
                <p
                  style={{
                    color: palette.text.primary,
                    fontFamily: typography.fontFamilyMontserrat,
                    fontSize: "0.875em",
                    fontWeight: typography.fontWeightMedium,
                    marginTop: 17
                  }}
                >
                  The best 100 {pageType === "artist100" ? "artists" : "images"}
                </p>
              </div>
              {!isOnMobile && (
              <Tooltip 
                style={{ zIndex: '999999'}}
                title={<span style={{ fontSize:"8px", }}>Share this page</span>} 
                interactive
                disableTouchListener
                enterDelay={1000} 
                leaveDelay={0}
                placement="bottom"
              >
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "1em"
                  }}
                >
                  <PikchaIconButton
                    onClick={() => shareContent()}
                    style={{
                      padding: 0,
                      width: 100,
                      minWidth: 46,
                      maxWidth: 100,
                      height: 39,
                    }}
                    className="rotate-item"
                    icon={<ReplyRoundedIcon alt="Share Icon" />}
                    size="medium"
                  />
                </div>
              </Tooltip>
              )}
            </div>
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    marginLeft: "0em", //isOnDesktop ? "0em" : isOnTablet ? "4em" : "1em",
                    marginRight: "0em", //isOnDesktop ? "0em" : isOnTablet ? "4em" : "1em",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: 1320,
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "1em",
                        justifyContent: isOnMobile ? "flex-start" : "center",
                      }}
                    >

                    <Grid justify={"center"}>
                      <Grid item className={"text-center"}>
                        <PikchaReusableTabsGroup
                          value={pageType}
                          onChange={onPageTypeChanged}
                          aria-label="Page Type Selector"
                          variant="fullWidth"
                          indicatorColor="primary"
                          textColor="primary"
                          style={{
                            marginLeft: isOnMobile ? 0 : 20
                          }}
                        >
                          <PikchaReusableTab
                            style={{
                              width: 60,
                              height: 12,
                              marginRight: isOnDesktop ? "2em" : "1em"
                            }}
                            value="pikcha100"
                            label="pikcha"
                            aria-label="Pikcha 100"
                          >
                            <PhotoRoundedIcon style={ICON_SIZE_SMALL} />
                            &nbsp; &nbsp; Pikcha
                          </PikchaReusableTab>
                          <PikchaReusableTab
                            style={{
                              width: 60,
                              height: 12,
                            }}
                            value="artist100"
                            label="artist"
                            aria-label="Artist 100"
                          >
                            <AccountCircleRoundedIcon style={ICON_SIZE_SMALL} />
                            &nbsp; &nbsp; Artist
                          </PikchaReusableTab>
                        </PikchaReusableTabsGroup>
                      </Grid>
                    </Grid>        
                    {!isOnMobile && (  
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          maxWidth: 1320,
                          width: "100%",
                          paddingLeft: "1em",
                          paddingRight: "1em",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            maxWidth: "300px",
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-end"
                          }}
                        >
                          <PikchaReusableDropdown
                            noHelperClass
                            classNameOuter="Dropdown-container"
                            onChange={(value) => {
                              handleFilterChange(value);
                            }}
                            style={{
                              width: 120,
                              fontWeight: 600,
                              fontSize: ".93rem"
                            }}
                            itemStyle={{
                              width: 150,
                              fontWeight: 600,
                              fontSize: ".93rem"
                            }}
                            options={[
                              { value: "RV", label: "Relevance" },
                              { value: "MV", label: "Views" },
                              { value: "MS", label: "Sales" },
                              { value: "AP", label: "Average Price" },
                            ]}
                            value={sortby}
                            placeholder={sortby || "Sort By"}
                          />
                        </div>
                      </div>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: ".75em",
                      }}
                      />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            style={{
              marginTop: "2em",
            }}
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{
                    maxWidth: 1320,
                    width: "100%",
                    marginLeft: isOnMobile ? "-0.5em" : "-1em",
                    marginRight: isOnMobile ? "-0.5em" : "-1em",
                  }}
                >
                  {pageType === "pikcha100" && (
                    <InfiniteScroll
                      dataLength={pikcha100.top100.length}
                      next={() => loadNext()}
                      hasMore={pikcha100.hasMore}
                    >
                      {pikcha100rows.map((row, index) => (
                        <Suspense
                          fallback={<PikchaRowLoader/>}>
                            <PikchaRow
                              key={index + 1}
                              rank={order === "desc" ? 100 - index : index + 1}
                              row={row}
                              searchBy={searchValue}
                              style={{
                                ...(index === 0 && {
                                  marginTop: "1em",
                                }),
                              }}
                            />
                            </Suspense>
                      ))}
                    </InfiniteScroll>
                  )}
                  {pageType === "artist100" && (
                    <InfiniteScroll
                      dataLength={artist100.top100.length}
                      next={() => {
                        if (artist100.start > 1) {
                          loadNextArtists();
                        }
                      }}
                      hasMore={artist100.hasMore}
                    >
                      {artist100rows.map((row, index) => (
                        <Suspense fallback={<PikchaRowLoader/>}>
                          <ArtistRow
                            key={index + 1}
                            rank={order === "desc" ? 100 - index : index + 1}
                            row={row}
                            searchBy={searchValue}
                            style={{
                              ...(index === 0 && {
                                marginTop: "1em",
                              }),
                            }}
                          />
                        </Suspense>
                      ))}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Pikcha100Sharing
        open={showShare}
        onClose={onCloseSharing}
        pageType={pageType}
      />
    </Fragment>
    </>
  );
};

const mapStateToProps = (state) => ({
  pikcha100: state.pikcha100Reducer,
  artist100: state.artist100Reducer,
});

export default connect(mapStateToProps, {
  getPikcha100,
  resetPikcha100,
  getArtist100,
  resetArtist100,
})(Pikcha100);
