// @ts-nocheck
import { PASSWORD_VALIDATION_REGEX, UTM_LOCAL_STORAGE_KEY } from "./constants";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { UtmExtractor } from "./UtmExtractor";
import axios from "axios";

const moment = require("moment-timezone");
const numeral = require("numeral");

export const getEnvironment = () => {
  const url = window.location.origin;
  const isStagingSite = url.includes("develop");

  return isStagingSite;
};

export const getStripeKey = () => {
  const isLiveMode = process.env.REACT_APP_STRIPE_LIVE_MODE;
  //
  // if (isLiveMode === "false") {
  //   return process.env.REACT_APP_STRIPE_LIVE_KEY;
  // }
  //
  // return process.env.REACT_APP_STRIPE_LIVE_KEY;

  return isLiveMode === "false"
    ? process.env.REACT_APP_STRIPE_TEST_KEY
    : process.env.REACT_APP_STRIPE_LIVE_KEY;
};

export const getBaseUrl = (isServiceUrl = false) => {
  const environment = process.env.NODE_ENV;
  const PRODUCTION_BASE_URL = "https://pikcha.com";
  const DEVELOPMENT_BASE_SERVICE_URL = "https://pikcha.local:1900";
  const DEVELOPMENT_BASE_URL = "https://127.0.0.1:5001";

  if (environment === "production") {
    return PRODUCTION_BASE_URL;
  }

  if (isServiceUrl) {
    return DEVELOPMENT_BASE_SERVICE_URL;
  }

  return DEVELOPMENT_BASE_URL;
};

/**
 * Helper function to ensure that the blurred modals overlay effect is
 * removed in some edge-cases.
 */
export const removeModalOverlay = () => {
  //document.body.style.overflowY = "auto";

  // const root = document.querySelector("#root");
  // if (root.classList.contains("--active-modal")) {
  //   root.classList.remove("--active-modal");
  // }

  enableBodyScroll(document.querySelector("#root"));
};

export const addModalOverlay = () => {
  //document.body.style.overflow = "hidden";

  // const root = document.querySelector("#root");
  // if (!root.classList.contains("--active-modal")) {
  //   root.classList.add("--active-modal");
  // }

  disableBodyScroll(document.querySelector("#root"));
};

export const lockScrollbar = () => {
  //const root = document.querySelector("#root");

  //root.classList.add("--active-modal");
};

export const setOnboardingBooleanFlag = (email, state) => {
  localStorage.setItem(`${email}-showOnboardingModal`, `${state}`);
};
export const downloadImage = (imageUrl, imageDetails, appendRandomNumber) => {
  let img = new Image();
  let queryString = "";

  if (appendRandomNumber) {
    queryString = `?q=${Date.now() + Math.floor(Math.random() * 1000)}`;
  }

  img.crossOrigin = "Anonymous";
  img.onload = function (result) {
    let canvas = document.createElement("canvas");
    canvas.width = result.currentTarget.width;
    canvas.height = result.currentTarget.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    let i_type = imageUrl.split(".")[imageUrl.split(".").length - 1];
    const dataURL = canvas.toDataURL("image/" + i_type);
    let filename = imageDetails.title.replace(/ /g, "") + "." + i_type;
    const byteString = atob(dataURL.split(",")[1]);
    // separate out the mime component
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    const ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });

    let uriContent = URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = uriContent;
    a.download = filename;
    a.click();
  };

  img.src = `${imageUrl}${queryString}`;
};

/**
 * Safely returns the value of a given key for an object.
 */
export const getValueIfKeyExists = (object, key) =>
  key
    .split(".")
    .reduce(
      (_object, _key) =>
        typeof _object == "undefined" || _object === null
          ? _object
          : _object[_key],
      object,
    );

/**
 * Efficient null-checking.
 */
export const keyExists = (object, key) =>
  key.split(".").every((property) => {
    if (
      typeof object !== "object" ||
      object === null ||
      !(property in object)
    ) {
      return false;
    }
    object = object[property];
    return true;
  });

/**
 * Takes a cookie name as a parameter and returns its value as a string, if
 * it exists.
 */
export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);

  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
};

export const getOverlayRects = (node) => {
  const rects = node.getBoundingClientRect();

  return {
    height: rects.height,
    width: rects.width,
  };
};

export const isEmailValid = (email) => email.includes("@") || email.length > 4;
export const isPasswordValid = (password) =>
  password.match(PASSWORD_VALIDATION_REGEX);

const getDay = (date) => {
  let day = date.getDate();
  return (day = day < 10 ? `0${day}` : day);
};

export const getDynamicTimeStamp = (uploadedAt) => {
  moment.tz.setDefault("Australia/Melbourne");

  const dateTime = new Date(uploadedAt);
  const dateTimeNow = moment(new Date());
  const duration = moment.duration(dateTimeNow.diff(dateTime));

  const isSameYear = duration.years() === 0;
  const isMoreThanSevenDaysAgo = duration.days() > 7;
  const isDaysAgo = duration.days() > 0;
  const isHoursAgo = duration.hours() > 0 && duration.hours() < 24;
  const isMinutesAgo = duration.minutes() > 0 && duration.minutes() < 60;
  const isSecondsAgo = duration.seconds() < 60;

  if (isSameYear) {
    if (duration.months() > 0) {
      return `${moment(dateTime).format("D MMM YY")}`;
    } else if (isMoreThanSevenDaysAgo) {
      return moment(dateTime).format("D MMM");
    } else {
      if (isDaysAgo) {
        return `${duration.days()}d ago`;
      } else if (!isDaysAgo && isHoursAgo) {
        return `${duration.hours()}h ago`;
      } else if (!isDaysAgo && !isHoursAgo && isMinutesAgo) {
        return `${duration.minutes()}m ago`;
      } else {
        return `${duration.seconds()}s ago`;
      }
    }
  } else {
    return `${moment(dateTime).format("D MMM YY")}`;
  }
};
export const getUploadedAt = (date) => {
  // if (!date) {
  //   return "";
  // }

  // const currentDateTime = new Date(date);

  // const day = getDay(currentDateTime);

  // let month = currentDateTime.getMonth();
  // month = month < 10 ? `0${month + 1}` : `${month + 1}`;

  // const stringifiedDate = currentDateTime.getFullYear() + month + day;

  // return moment(stringifiedDate, "YYYYMMDD").format("D MMMM YYYY");

  if (!date) {
    return "";
  }

  const currentDateTime = new Date(date);
  var fullMonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][currentDateTime.getMonth()];
  var purifiedDate =
    currentDateTime.getDate() < 10
      ? `0${currentDateTime.getDate()}`
      : `${currentDateTime.getDate()}`;
  var str =
    purifiedDate + " " + fullMonthNames + " " + currentDateTime.getFullYear();
  return str;
};

export const getCreatedAt = (date, type) => {
  if (date === "00-00-0000" || date === null) {
    return "";
  }
  const day = date.split("-")[0];
  const month = date.split("-")[1];
  const year = date.split("-")[2];
  if (!type) {
    if (day !== "00") {
      const monthNum = parseInt(month);
      var fullMonthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ][monthNum - 1];
      return `${day} ${fullMonthNames} ${year}`;
    } else {
      if (month !== "00") {
        const monthNum = parseInt(month);
        var fullMonthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ][monthNum - 1];
        return `${fullMonthNames} ${year}`;
      } else {
        return `${year}`;
      }
    }
  } else if (type === "uploading") {
    if (day !== "00") {
      const monthNum = parseInt(month);
      const dayNum = parseInt(day);
      var fullMonthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][monthNum - 1];

      var date = [
        "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
        "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
        "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31",
      ][dayNum - 1];

      return `${date} ${fullMonthNames} ${year}`;
    } else {
      if (month !== "00") {
        const monthNum = parseInt(month);
        var fullMonthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ][monthNum - 1];
        return `${fullMonthNames} ${year}`;
      } else {
        return `${year}`;
      }
    }
  }
};

export const getImageNumberAsString = (sold) => `${parseInt(sold)}`;

export const loadFbLoginApi = (appId) => {
  if (window.FB) {
    window.FB.init({
      appId: appId,
      cookie: true, // enable cookies to allow the server to access
      // the session
      xfbml: true,
      version: "v7.0",
    });

    // Load the SDK asynchronously
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
};

/**
 * Accepts birthday dates in format `yyyy-mm-dd` or `yyyy/mm/dd`.
 */
export const isUserOver18 = (birthDate) => {
  const birthdayCleaned = birthDate.replace(/-/g, "/");
  const birthday = new Date(birthdayCleaned);
  const currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";
  const userAge = ~~((Date.now(currentDate) - birthday) / 31557600000);

  return userAge >= 18;
};

export const loadGoogleLoginApi = (appId) => {
  if (window.gapi) {
    window.gapi.load("client", () => {
      //console.log("loaded client");

      window.gapi.client.init({
        client_id:
          "620356774616-6g2fcmks1pfm9bcef1mt1g0feqah3at3.apps.googleusercontent.com",
        scope: "email",
        cookiePolicy: "single_host_origin",
        fetch_basic_profile: false,
        //discoveryDocs:
        // ['https://www.googleapis.com/discovery/v1/apis/some-api'], scope:
        // 'https://www.googleapis.com/auth/some.scope',
      });
    });
  }
};

export const addDoubleQuotationToSize = (size) => {
  const sizeList = size.split("×");
  sizeList.forEach((_, index) => {
    sizeList[index] = `${sizeList[index]}"`;
  });
  const sizeUpdated = `${sizeList[0]}×${sizeList[1]}`;
  return sizeUpdated;
};

export const formatViews = (views) => {
  return numeral(views).format("0.[0]a");
};

export const getCustomisePath = ({friendlyUrl}) => `/${friendlyUrl}/print`

export const isProductionSite = () => window.location.origin.includes("pikcha") &&
  !window.location.origin.includes("develop");

export const USER_INPUT_REGEX =
  new RegExp("[\\\\\\/!@#$%^&*(),?\":{}[\\]|<=+>`~;]", "g");

export const getUtmData =() =>{
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if(params.length == 0){
    return null;
  }
  else{
    return params; 
  }
}

export const handleUtmData = () => {
  const toCamelCase = (string) => string.replace(
    /^([A-Z])|[\s-_](\w)/g,
    function (match, index) {
      if (+match === 0) {
        return "";
      }
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    },
  );

  const cacheUtmData = (data) => localStorage.setItem(
    UTM_LOCAL_STORAGE_KEY,
    JSON.stringify(data),
  );

  const mapUtmParamsToObjProps = (payload) => {
    const utmData = {};

    for (const [key, value] of Object.entries(payload)) {
      utmData[toCamelCase(key).replace("_", "")] = value;
    }
    utmData["createdAt"] = new Date();

    cacheUtmData(utmData);

    return utmData;
  };

  const utm = new UtmExtractor(window.location.href);
  const values = utm.get();

  return values.length === 0 ? null : mapUtmParamsToObjProps(values);
};

export const getSavedUtmDetails = () => {
  const utmDetails = JSON.parse(localStorage.getItem(UTM_LOCAL_STORAGE_KEY));
  console.log("getSavedUtmDetails", utmDetails);

  return utmDetails ? utmDetails : null;
};

export const sendCriticalException = (source, remark) => {
  if(process.env.NODE_ENV !== "production"){
    return
  }
  const occuringTime = new Date();
  const dto = {
    source: source,
    remark: remark,
    occuredAt: occuringTime
  }

  axios.post("/api/InternalCommunication/report", dto, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  }).then((response) => {
    console.log(response)
  }).catch((err) => {
    console.log(err)
  })
}
