import Typography from "@material-ui/core/Typography";
import React, { Fragment } from "react";

export const PikchaTabPanel = ({ children, value, index, ...rest }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...rest}
  >
    {value === index && <Fragment>{children}</Fragment>}
  </Typography>
);
