import React, {useState, useEffect} from 'react';
import Img from "react-image";
import { downloadImage } from "../../helpers/utils";
import {PikchaButton} from "../baseComponents";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";

export default ({
  imageDetails
}) => {
  
  const {
    framenail,
    whiteFrame,
    blackFrame,
    framenailPikchaBrand,
    whiteFramePikchaBrand,
    blackFramePikchaBrand
  } = imageDetails;
  
  const downloadPreview = (framenail) => {
    downloadImage(framenail, imageDetails, true);
  };

  const [frameList, setFrameList] = useState([]);

  useEffect(() => {
    const list = [    
      framenail,
      whiteFrame,
      blackFrame,
      framenailPikchaBrand,
      whiteFramePikchaBrand,
      blackFramePikchaBrand,
    ];
    setFrameList(list)
  }, [imageDetails]);

  const mapFrameList=(frameList)=>{
    return frameList.map((item)=>(
      <div
        className="flex flex-column"
        style={{
          width: "33%",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          marginBottom: 12,
          padding: "0 6"
        }}
      >
        <Img
          className="block"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain"
          }}
          alt="white frame"
          src={item}
        />
        <PikchaButton
          action="octonary"
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            marginRight: "0px",
            height: 32,
            marginTop: "0.75em",
            padding: "8 8",
            minWidth: 32,
            width: 32
          }}
          onClick={() =>
            downloadPreview(item)
          }
        >
          <SaveAltRoundedIcon
            style={{
              width: 12,
              height: 12,
              color: "black"
            }}
          />
        </PikchaButton>
      </div>
    ))
  }

  return (
    <div
      className="flex full-width"
      style={{
        flexWrap: "wrap"
      }}
    >
      {mapFrameList(frameList)}
    </div>
  )
}
