import {options, units} from "../config/currencyPickerOptions";

export const setLocalPreferences = ({
                                      currencyCode,
                                      locale,
                                      fxRate,
                                      preferredUnit
                                    }: { currencyCode: string, locale: string, fxRate: string, preferredUnit: string }) => {

  let userPref = {presentmentCurrency: currencyCode, locale: locale, fx: fxRate, unit: preferredUnit};
  localStorage.setItem("userPreferences", JSON.stringify(userPref));
};

const parseLocalUserPref = () => {
  const userPref = localStorage.getItem("userPreferences");
  if (userPref) {
    return JSON.parse(localStorage.getItem("userPreferences"));
  }
  return {presentmentCurrency: "USD", locale: 'en-US', fx: '1', unit: 'in'}
};

export const getOptionByValue = (optionsArray, optionValue) => {
  return optionsArray.filter(option => option.value == optionValue).pop();
};

export const getLocalCurrencyOption = () => {
  if (parseLocalUserPref().presentmentCurrency) {
    return getOptionByValue(options, parseLocalUserPref().presentmentCurrency);
  }
  return null;
};

export const getLocalFxRate = () => {
  return parseLocalUserPref().fx;
};

export const getLocalUnitPref = () => {
  if (parseLocalUserPref().unit) {
    return getOptionByValue(units, parseLocalUserPref().unit);
  }
  return null;
};
