import React, {
  useEffect,
  useState,
} from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { authenticate } from "../actions/auth";

import { IdentityUrl } from "../selectors/settings";

import authService, { AuthenticationResultStatus } from "./AuthorizeService";
import {
  ApplicationPaths,
  LoginActions,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";

// The main responsibility of this component is to handle the user's login
// process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
const Login = withRouter(
  ({ action, IdentityUrl, history, location, authenticate }) => {
    const [mainState, setMainState] = useState({
      message: undefined,
    });

    const redirectToRegister = () => {
      redirectToApiAuthorizationPath(
        `${IdentityUrl}${ApplicationPaths.IdentityRegisterPath}?${
          QueryParameterNames.ReturnUrl
        }=${encodeURI(
          window.location.protocol +
            "//" +
            window.location.host +
            ApplicationPaths.Login
        )}`
      );
    };

    const redirectToProfile = () => {
      redirectToApiAuthorizationPath(
        `${IdentityUrl}${ApplicationPaths.IdentityManagePath}`
      );
    };

    const redirectToApiAuthorizationPath = (apiAuthorizationPath) => {
      const redirectUrl = `${apiAuthorizationPath}`;
      //const redirectUrl = `${apiAuthorizationPath}`;
      // It's important that we do a replace here so that when the user hits the back arrow on the
      // browser he gets sent back to where it was on the app instead of to an endpoint on this
      // component.
      window.location.replace(redirectUrl);
    };

    const navigateToReturnUrl = (returnUrl) => {
      //const state = location.state;

      // It's important that we do a replace here so that we remove the callback uri with the
      // fragment containing the tokens from the browser history.
      //
      /*authenticate(() => {
        history.push(decodeURI(returnUrl), state);
      }); */

      // replace doesn't work...
      window.location.replace(returnUrl); // added by thananji - need
    };

    const getReturnUrl = (state) => {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(QueryParameterNames.ReturnUrl);

      // this won't allow us to push location objects
      /*if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        "Invalid return url. The return url needs to have the same origin as the current page."
      );
    }*/

      return (
        (state && state.returnUrl) || fromQuery || `${window.location.origin}/`
      );
    };

    const processLoginCallback = async (returnUrl) => {
      const isCustomisationPage = returnUrl === "/customise";
      const url = window.location.href;
      const result = await authService.completeSignIn(url);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeSignIn finishes
          // is when we are doing a redirect sign in flow.
          throw new Error("Should not redirect.");
        case AuthenticationResultStatus.Success:
          if (!isCustomisationPage) {
            await navigateToReturnUrl(getReturnUrl(result.state));
            //console.log(url);
            //console.log(window.location.href);
            //console.log(getReturnUrl(result.state));
          }
          break;
        case AuthenticationResultStatus.Fail:
          setMainState({ message: result.message });
          break;
        default:
          throw new Error(
            `Invalid authentication result status '${result.status}'.`
          );
      }
    };

    const login = async (returnUrl) => {
      const state = { returnUrl };
      const isCustomisationPage = returnUrl === "/customise";
      const result = await authService.signIn(state, isCustomisationPage);
      //console.log(result);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          return <div className="LoadingOverlay">{
            //console.log("here")
          }</div>;
        // break;
        case AuthenticationResultStatus.Success:
          await navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          setMainState({ message: result.message });
          break;
        default:
          throw new Error(`Invalid status result ${result.status}.`);
      }
    };

    useEffect(() => {
      const returnUrl = getReturnUrl();
      switch (action) {
        case LoginActions.Login:
          login(returnUrl);
          break;
        case LoginActions.LoginCallback:
          processLoginCallback(returnUrl);
          break;
        case LoginActions.LoginFailed:
          const params = new URLSearchParams(window.location.search);
          const error = params.get(QueryParameterNames.Message);
          setMainState({ message: error });
          break;
        case LoginActions.Profile:
          redirectToProfile();
          break;
        case LoginActions.Register:
          redirectToRegister();
          break;
        default:
          throw new Error(`Invalid action '${action}'`);
      }
    }, [action]);

    const { message } = mainState;

    if (!!message) {
      if (typeof message === "object") {
        //console.log(message);
        return null;
      }
      return <div>{message}</div>;
    } else {
      switch (action) {
        case LoginActions.Login:
          return <div>Processing login</div>;
        case LoginActions.LoginCallback:
          return <div>Processing login callback</div>;
        case LoginActions.Profile:
        case LoginActions.Register:
          return <div></div>;
        default:
          throw new Error(`Invalid action '${action}'`);
      }
    }
  }
);

const mapStateToProps = (state) => ({
  IdentityUrl: IdentityUrl(state),
});
export default connect(mapStateToProps, {
  authenticate,
})(Login);
