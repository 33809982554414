import { HANDLE_ARTIST_REGISTRATION_FORM } from "./types";

export const handleArtistRegistrationForm = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_ARTIST_REGISTRATION_FORM,
      payload: state,
    });
  };
};
