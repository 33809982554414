import {
  HANDLE_LOGIN_LOADING_STATUS,
  HANDLE_LOGIN_PASSWORD,
  HANDLE_LOGIN_USERNAME,
} from "../actions/types";

const initialState = {
  username: "",
  password: "",
  loading: false,
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case HANDLE_LOGIN_USERNAME:
      return {
        ...state,
        username: payload,
      };
    case HANDLE_LOGIN_PASSWORD:
      return {
        ...state,
        password: payload,
      };
    case HANDLE_LOGIN_LOADING_STATUS:
      return {
        ...state,
        loading: payload,
      };
    default:
      return state;
  }
};
