import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";

import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";

import { showAgeRestriction } from "../../../selectors/ageRestriction";

import {
  cancelAgeRestriction,
  passAgeRestriction,
} from "../../../actions/ageRestriction";

import {
  PikchaCheckbox,
  PikchaModal,
  PikchaButton,
} from "../../../components/baseComponents";
import isMobile from "../../../components/Responsive/isMobile";

import { ICON_SIZE_DEFAULT } from "../../../helpers/constants";
import { removeModalOverlay } from "../../../helpers/utils";

const AgeRestrictionModal = ({
  showAgeRestriction,
  cancelAgeRestriction,
  passAgeRestriction,
}) => {
  const [isOver18, setIsOver18] = useState(false);

  const isOnMobile = isMobile();

  const { typography, palette } = useTheme();

  const handleIsOver18Toggle = () => {
    setIsOver18(!isOver18);
  };

  const onRemoveSafeSearch = () => {
    passAgeRestriction({
      isOver18,
    });
    removeModalOverlay();
    setIsOver18(false);
  };

  return (
    <PikchaModal
      fullWidth={false}
      onClose={cancelAgeRestriction}
      maxWidth="lg"
      open={showAgeRestriction}
      PaperProps={{
        style: {
          overflow: "hidden",
        },
      }}
      minHeight={1}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "1.9em",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1em",
              marginBottom: "2em",
            }}
          >
            <VisibilityRoundedIcon
              style={{
                ...ICON_SIZE_DEFAULT,
              }}
              fontSize="inherit"
              color="inherit"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                maxWidth: 300,
                marginLeft: isOnMobile ? 0 : "1em",
                fontSize: typography.small,
                fontWeight: typography.fontWeightLight,
                color: palette.text.primary,
                textAlign: "center",
              }}
            >
              <p>Removing Safe Search may expose you to nudity and violence.</p>
              <p
                style={{
                  marginTop: "1em",
                }}
              >
                This content is not suitable for viewiers under the age
                of 18
              </p>

              <div
                style={{
                  marginTop: "4em",
                  marginLeft: "0.7em",
                }}
              >
                <PikchaCheckbox
                  label="I am over the age of 18+"
                  spanStyle={{
                    marginRight: "1em",
                    marginTop: "-0.4em",
                  }}
                  labelStyle={{
                    fontSize: typography.standard,
                    fontWeight: typography.fontWeightMedium,
                    color: palette.text.primary,
                  }}
                  value={isOver18}
                  checked={isOver18}
                  onChange={handleIsOver18Toggle}
                />
              </div>
              <div
                style={{
                  marginTop: "2em",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PikchaButton onClick={onRemoveSafeSearch}>
                  Remove SafeSearch
                </PikchaButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({
  showAgeRestriction: showAgeRestriction(state),
});

export default connect(mapStateToProps, {
  cancelAgeRestriction,
  passAgeRestriction,
})(AgeRestrictionModal);
