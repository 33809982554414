import { HANDLE_ARTIST_REGISTRATION_FORM } from "../actions/types";

const initialState = {
  payload: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HANDLE_ARTIST_REGISTRATION_FORM:
      return {
        ...state,
        payload,
      };
    default:
      return state;
  }
};
