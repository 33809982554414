import axios from "axios";

import {GALLERY_SEARCH_ERROR, GALLERY_SEARCH_LOADED, GALLERY_SEARCH_LOADING, GALLERY_SEARCH_RESET,} from "./types";

import AuthorizeService from "../auth/AuthorizeService";
import {createSelector} from "reselect";

// Get Photo from Friendly Url
export const getPhotoFriendlyUrlSearch = (friendlyUrl) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading());
      const res = await axios.get(`api/image/friendly${friendlyUrl}`);
      // window.location.reload();
      dispatch({
        type: GALLERY_SEARCH_LOADED,
        payload: [res.data],
      });
    } catch (err) {
      dispatch({
        type: GALLERY_SEARCH_ERROR,
        payload: err.response,
      });
    }
  };
};

// Get Photo from Qr Code url
export const getPhotoQRCodeSearch = (qrUrl) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading());
      const res = await axios.get(`api/image/qrcode${qrUrl}`);
      dispatch({
        type: GALLERY_SEARCH_LOADED,
        payload: [res.data],
      });
    } catch (err) {
      dispatch({
        type: GALLERY_SEARCH_ERROR,
        payload: err.response,
      });
    }
  };
};

const gallerySearchSelector = (state) => state.gallerySearchReducer;

export const photoSearchSelector = createSelector(
  gallerySearchSelector,
  (photos) => photos
);

// Get Photos
export const getPhotosSearch = (count, start) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading());
      const token = await AuthorizeService.getAccessToken();
      const parameters = {
        page: start,
        itemInPage: count,
      };
      // original axios

      const res = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? ""
            //: "http://localhost:1900"
            : "https://pikcha.local:1900"
        }/v1/search/api/Search/trending`,
        parameters,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: GALLERY_SEARCH_LOADED,
        payload: res.data,
      });

    } catch (err) {
      dispatch({
        type: GALLERY_SEARCH_ERROR,
        payload: err.response,
      });
    }
  };
};

// Add Photo View
export const addViewSearch = (imageId) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`api/image/${imageId}/view`);
      //console.log("View added", res);
    } catch (err) {
      console.log("View add error ", err);
    }
  };
};

// Set Loading
export const setLoading = () => {
  return (dispatch) =>
    dispatch({
      type: GALLERY_SEARCH_LOADING
    });
};


// Reset Gallery
export const resetGallerySearch = () => {
  return (dispatch) =>
    dispatch({
      type: GALLERY_SEARCH_RESET,
    });
};
