import React, { useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HelpIcon from "@material-ui/icons/Help";
import { currencyFormat, IntlCurrencyFormatter } from "../NumberFormatters/IntlCurrencyFormatter";
import axios from "axios";
import CancelIcon from '@material-ui/icons/Cancel';
import { setAlert } from "../../actions/alert";
import { getShoppingCart } from "../../actions/shoppingCart";
import { TypographyBody, TYPOGRAPHY_BODY_SIZE } from "@pikcha/typography"

const TotalsMobile = ({ areThereReadyShoppingCartItems, shoppingCart, basketId, setAlert, getShoppingCart }) => {
  const { palette, typography } = useTheme();

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const resetDiscount = async () => {
    axios.post(
      `${process.env.NODE_ENV === "production"
        ? ""
        : "https://pikcha.local:1900"
      }/v1/shopping/shoppingAggregator/ResetDiscount`, {
      basketId: basketId,
    }).then(() => {
      setAlert("discount reset applied", "success");
      getShoppingCart(basketId);
    }).catch((err) => {
      setAlert("discount reset failes, please retry later", "error");
      console.log(err);
    });
  }

  return (
    <div
      style={{
        borderTop: '2px solid #DBE0E4',
      }}
    >
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
        color="neutral/grey50"
        style={{
          display: "flex",
          paddingTop: '1em'
        }}
      >
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Subtotal&nbsp;
          <Tooltip
            open={tooltipIsOpen}
            onClose={() => setTooltipIsOpen(false)}
            onOpen={() => setTooltipIsOpen(true)}
            title={
              <div>
                <p>
                  The subtotal reflects the total price of your order, including
                  taxes, before any applicable discounts. It does not include
                  shipping costs and international transaction fees.
                </p>
              </div>
            }
            placement="top-start"
          >
            <div
              onMouseEnter={() => {
                setTooltipIsOpen(true);
              }}
              onMouseLeave={() => {
                setTooltipIsOpen(false);
              }}
            >
              {tooltipIsOpen ? (
                <HelpIcon
                  style={{
                    width: "16px",
                    height: "16px",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <HelpOutlineIcon
                  style={{
                    width: "16px",
                    height: "16px",
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          </Tooltip>
        </p>
        <p
          style={{
            marginLeft: "auto",
          }}
        >
          {areThereReadyShoppingCartItems ? (
            <IntlCurrencyFormatter value={+shoppingCart.shoppingCart?.subTotalPresentment}
            />) : (<span> AUD $0.00 </span>)}
        </p>
      </TypographyBody>
      {shoppingCart.shoppingCart?.discountValuePresentment ?
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
          color="neutral/grey50"
          style={{
            marginTop: "0.5em",
            display: "flex",
          }}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>Discount</span>
            {shoppingCart.shoppingCart?.discountValuePresentment !== 0 && (
              <CancelIcon
                style={{
                  height: 15,
                  width: 15,
                  marginLeft: 5,
                  cursor: "pointer"
                }}
                onClick={resetDiscount}
              />
            )}
          </p>
          <p
            style={{
              marginLeft: "auto",
            }}>
            {areThereReadyShoppingCartItems ? (
              <IntlCurrencyFormatter value={+shoppingCart.shoppingCart?.discountValuePresentment}
              />
            ) : (<span> - </span>)}
          </p>
        </TypographyBody>
        : ''}
      <div
        style={{
          color: palette.text.primary,
          fontSize: typography.smallest,
          fontWeight: typography.fontWeightMedium,
        }}
      >
        {shoppingCart.shoppingCart?.discountCode}
      </div>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
        color="neutral/grey50"
        style={{
          marginTop: "0.5em",
          display: "flex",
        }}
      >
        <p>Shipping</p>
        <p
          style={{
            marginLeft: "auto",
          }}>
          {areThereReadyShoppingCartItems ? (
            <IntlCurrencyFormatter value={+shoppingCart.shoppingCart?.shipCostPresentment}
            />
          ) : (<span> FREE </span>)}
        </p>
      </TypographyBody>
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
        style={{
          marginTop: "0.5em",
          display: "flex",
        }}
      >
        <p>Total</p>
        <p
          style={{
            marginLeft: "auto",
          }}
        >
          {areThereReadyShoppingCartItems ? (
            <IntlCurrencyFormatter value={+shoppingCart.shoppingCart?.totalAmountPresentment}
              displayFormat={currencyFormat.code} />
          ) : (<span> AUD $0.00 </span>)}
        </p>
      </TypographyBody>
    </div>
  );
};

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCartReducer,
});
export default connect(mapStateToProps, { setAlert, getShoppingCart })(TotalsMobile);
