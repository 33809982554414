import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import "./PikchaSideDrawerLink.css";
import {PikchaLink} from "../PikchaLink";

export default ({ path, text }) => {
  const { typography } = useTheme();

  return (
    <span
      className="SideDrawer-link"
      style={{
        fontSize: typography.smallest,
      }}
    >
      <PikchaLink to={path}>{text}</PikchaLink>
    </span>
  );
};
