import React from "react";
import {connect} from "react-redux";
import isDesktop from "../../../components/Responsive/isDesktop";

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import {useTheme} from "@material-ui/core/styles";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import AttachmentIcon from "@material-ui/icons/Attachment";

import {setAlert} from "../../../actions/alert";

import {downloadImage} from "../../../helpers/utils";

import {
  PikchaButton,
  PikchaModal,
  PikchaTextField
} from "../../../components/baseComponents";
import isMobilePortrait from "../../../components/Responsive/isMobilePortrait";
import ImageFrame from "../../../components/ImageFrame/ImageFrame";

const SharingModal = ({open, onClose, image, enableDownload, setAlert}) => {
  const isOnDesktop = isDesktop();
  const isOnMobilePortrait = isMobilePortrait();

  const {typography, palette} = useTheme();
  const {title, friendlyUrl, framenail, tags} = image;
  const imageCopyLink = `${window.location.origin}/${friendlyUrl}`;
  let artistFullName;
  if (image.artist) {
    artistFullName = `${image.artist.nickName}`;
  }

  const copyLink = () => {
    const friendlyLinkOriginAdjusted = `${window.location.origin}/${friendlyUrl}`;
    navigator.clipboard.writeText(friendlyLinkOriginAdjusted).then(
      () => {
        setAlert("URL has been copied", "info");
      },
      (err) => {
        setAlert("Couldn't copy image link", "error");
      }
    );
  };

  const downloadThumbnail = () => {
    downloadImage(image.thumbnail, image);
  };
  const downloadPreview = () => {
    downloadImage(image.framenail, image, true);
  };

  const socialButtons = () => (
    <div
      className="full-width flex mt-2-em mb-2-em"
      style={{
        flexWrap: "wrap",
        maxWidth: 516,
        marginLeft: isOnDesktop ? null : "1em"
      }}
    >
      <div className="social-sharing-button-box">
        <FacebookShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          quote={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
          url={`${window.location.origin}/${friendlyUrl}`}
        >
          <span
            className="Facebook-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <FacebookIcon size={46}/>
          </span>
        </FacebookShareButton>
        <span
          style={{

            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Facebook</span>
      </div>

      <div className="social-sharing-button-box">
        <FacebookMessengerShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/${friendlyUrl}`}
          appId="536438577204283"
          redirectUri={`${window.location.origin}/${friendlyUrl}`}
        >
          <span
            className="Messenger-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <FacebookMessengerIcon size={46}/>
          </span>
        </FacebookMessengerShareButton>
        <span
          style={{

            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Messenger</span>
      </div>

      <div className="social-sharing-button-box">
        <LinkedinShareButton
          url={`${window.location.origin}/${friendlyUrl}`}
          title="Check out my latest image on Pikcha"
          summary={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
          source="https://www.pikcha.com"
          style={{
            marginRight: "0px",
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
        >
          <span
            className="LinkedIn-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <LinkedinIcon size={46}/>
          </span>
        </LinkedinShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >LinkedIn</span>
      </div>

      <div className="social-sharing-button-box">

        <TwitterShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/${friendlyUrl}`}
          title={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
          hashtags={["pikcha", "pikcha100"]}
        >
          <span
            className="Twitter-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TwitterIcon size={46}/>
          </span>
        </TwitterShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Twitter</span>
      </div>

      <div className="social-sharing-button-box">

        <RedditShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/${friendlyUrl}`}
          title={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }

          hashtags={["pikcha", "pikcha100"]}
        >
          <span
            className="Reddit-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px",
            }}
          >
            <RedditIcon size={46}/>
          </span>
        </RedditShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Reddit</span>
      </div>

      <div className="social-sharing-button-box">
        <EmailShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${friendlyUrl}`}
          body={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
          subject={`Check out my new artwork listing, ${title}, on Pikcha.com.`}
        >
          <span
            className="Email-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <EmailIcon size={46}/>
          </span>
        </EmailShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Mail</span>
      </div>

      <div className="social-sharing-button-box">
        <PinterestShareButton
          url={`${window.location.origin}/${friendlyUrl}`}
          media={framenail}
          description={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
          style={{
            marginRight: "0px",
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
        >
          <span
            className="Pinterest-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <PinterestIcon size={46}/>
          </span>
        </PinterestShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Pinterest</span>
      </div>

      <div className="social-sharing-button-box">
        <TumblrShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${friendlyUrl}`}
          caption={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
          title={`${title}`}
          tags={tags}
        >
          <span
            className="Tumblr-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TumblrIcon size={46}/>
          </span>
        </TumblrShareButton>

        <span
          style={{

            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Tumblr</span>
      </div>

      <div className="social-sharing-button-box">
        <WhatsappShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${friendlyUrl}`}
          title={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
        >
          <span
            className="Whatsapp-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <WhatsappIcon size={46}/>
          </span>
        </WhatsappShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Whatsapp</span>
      </div>

      <div className="social-sharing-button-box">
        <TelegramShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/${friendlyUrl}`}
          title={
            `Check out this artwork listing, ${title}, by ${artistFullName} on Pikcha.com.` +
            " There are only 100 prints available! #pikcha #pikcha100"
          }
        >
          <span
            className="Telegram-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TelegramIcon size={46}/>
          </span>
        </TelegramShareButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Telegram</span>
      </div>

      <div className="social-sharing-button-box">
        <PikchaButton
          style={{
            marginRight: "0px",
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          onClick={() => downloadPreview()}
        >
          <SaveAltRoundedIcon/>
        </PikchaButton>
        <span
          style={{
            color: "grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Download</span>
      </div>
    </div>
  );


  return (
    <PikchaModal
      fullWidth={false}
      onClose={onClose}
      maxWidth="lg"
      open={open}
      PaperProps={{
        style: {
          overflow: "hidden",
          marginLeft: "1em",
          marginRight: "1em",
          position: "absolute",
          top: isOnDesktop ? 75 : isOnMobilePortrait ? 0 : 6,
        },
      }}
      minHeight={1}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "1.9em",
        }}
      >
        <p
          style={{
            color: "grey",
            fontSize: typography.small,
            fontWeight: typography.fontWeightMedium,
            fontFamily: typography.fontFamilyMontserrat,
            marginTop: "1em",
            marginBottom: "2em",
          }}
        >
          Share This Image
        </p>
        {/*<img*/}
        {/*  src={image.framenail}*/}
        {/*  alt="framed image"*/}
        {/*  style={{*/}
        {/*    height: "150px",*/}
        {/*    width: "auto",*/}
        {/*  }}*/}
        {/*/>*/}
        <ImageFrame imageDetails={image}/>
        {socialButtons()}

        <div style={{display: "flex", width: "100%", maxWidth: 516}}>
          <div
            style={{
              minWidth: "200px",
              width: "100%",
              paddingTop: "0.75em",
              paddingRight: "0.5em",
            }}
          >
            <PikchaTextField
              disabled={true}
              type="text"
              name="nickName"
              value={imageCopyLink}
            />
          </div>
          <div className="copy-link-button-box">
            <PikchaButton
              noPadding
              style={{
                minWidth: 40,
                marginBottom: "0.5em",
                padding: 3,
                marginRight: 0,
              }}
              onClick={() => copyLink()}
            >
              <AttachmentIcon/>
            </PikchaButton>
            <span
              style={{
                color: "grey",
                fontSize: typography.tiny,
                fontWeight: typography.fontWeightSemiMedium,
                fontFamily: typography.fontFamilyMontserrat,
              }}
            >
                Copy
            </span>
          </div>
        </div>

      </div>
    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  setAlert,
})(SharingModal);
