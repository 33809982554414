import React from 'react';
import { TypographyBody, TYPOGRAPHY_BODY_SIZE, colors } from "@pikcha/typography";
import isMobile from "../../components/Responsive/isMobile";
import isTablet from "../../components/Responsive/isTablet";

interface BenefitObject {
  title: string,
  description: string
}

interface Benefit {
  benefit: BenefitObject
}

const BenefitBulletPoint = ({benefit}: Benefit) => {
  const { title, description} = benefit;
  const isOnMobile = isMobile();
  const isOnTablet = isTablet();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "1em",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px 0px #0000000D",
        margin: isOnMobile || isOnTablet ? "0.5em 0" : "0 0.5em",
        width: isOnTablet && "40%",
      }}
    >
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
        style={{
          marginTop: "1em",
          textAlign: "center"
        }}
      >
        {title}
      </TypographyBody>
      <TypographyBody
        style={{
          textAlign: "center",
          maxWidth: !isOnMobile && 220
        }}
      >
        {description}
      </TypographyBody>
    </div>
  )
}

export default () => {
  const isOnMobile = isMobile();
  const benefits = [{
    title: "Secure Payments",
    description: "100% Secure payment with 256-bit SSL Encryption"
  },{
    title: "Support artists",
    description: "Artists all over the world are supported with each purchase."
  },{
    title: "Plant trees",
    description: "Each artwork created leads to 1 tree planted."
  },{
    title: "Ready to hang",
    description: "Frames come ready-to-hang right out of the box."
  }]
  return (
    <div className="flex"
      style={{
        flexDirection: isOnMobile ? "column" : "row",
        backgroundColor: colors.Neutral.white00,
        padding: isOnMobile ? "2em 1em" : "2em 4em",
        justifyContent: "center",
        flexWrap: "wrap"
      }}
    >
      {benefits.map((benefit) =>
        <BenefitBulletPoint benefit={benefit}/>
      )}
    </div>
  )
}
