import React, {useState, useEffect} from "react";

import { useTheme } from "@material-ui/core/styles";
import { PikchaModal, PikchaImageNumber, PikchaButton } from "../../../components/baseComponents";
import {UnitFormatter} from "../../../components/NumberFormatters/UnitFormatter";
import {currencyFormat, IntlCurrencyFormatter} from "../../../components/NumberFormatters/IntlCurrencyFormatter";
import isMobile from "../../../components/Responsive/isMobile";
import {useHistory} from "react-router-dom";

export default ({
  open,
  onClose,
  onLogIn,
  onJoin,
  onContinueAsGuest,
  image,
  showBorder,
  showFrame,
  showWrap,
  capitalizeFirstLetter = () => {},
  galleryCard,
  setStartCrop,
  setClickAsGuest,
  previewImageRef,
  summary,
  setWallViewState,
  ...rest
}) => {
  const isOnMobile = isMobile();
  const { palette, typography } = useTheme();
  let expectedProductNumber = image.productNumber;
  const history = useHistory();

  useEffect(async () => {
    if(!open){
      return
    }
    setTimeout(function () {
      setWallViewState(false);
    }, 100);
  }, [previewImageRef.current, open])

  return (
    <PikchaModal
      onClose={onClose}
      open={open}
      hideCloseButton
      paperStyle={{
        overflow: "hidden",
        minHeight: 0,
        ...(isOnMobile
          ? {
              height: 610,
              maxHeight: 610,
            }
          : {
              height: 400,
              maxHeight: 400,
            }),
      }}
      {...rest}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          ...(isOnMobile
            ? {}
            : {
                display: "flex",
                justifyContent: "space-around",
              }),
        }}
      >
        <div 
          style={{
            backgroundColor: palette.common.black, 
            width: isOnMobile ? "100%" : "50%", 
            padding: "2em 1em",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <div style={{maxWidth: isOnMobile ? 315 : 250}}>
            <div
              style={{
                marginBottom: "1.5em",
                fontWeight: typography.fontWeightMedium,
                fontSize: typography.standard,
                color: palette.common.white,
              }}
            >
              LIMITED TIME OFFER
            </div>
            <div
              style={{
                marginBottom: "1.5em",
                fontWeight: typography.fontWeightMedium,
                fontSize: typography.large,
                color: palette.common.white,
                maxWidth: isOnMobile ? "100%" : 250
              }}
            >
            $50 USD off your first order when you sign-up to Pikcha
            </div>
            <div>
              <PikchaButton 
                onClick={onJoin}
                action="octonary"
              >
                Join
              </PikchaButton>
            </div>
          </div>
        </div>
        <div
          style={{
            width: isOnMobile ? "100%" : "50%",
            ...(isOnMobile
              ? {
                  marginTop: "2em",
                }
              : null),
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "315px",
              margin: "0 auto",
            }}
          >
            <div style={{fontWeight: typography.fontWeightBold}}>
              ADDED TO CART
            </div>
            <div 
              className="flex"
              style={{
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  width: 160,
                  height: 160,
                  backgroundImage: `url(${image.thumbnail})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
              <div
                style={{
                  marginTop: "1em",
                  color: palette.text.disabled,
                  fontWeight: typography.fontWeightMedium,
                  fontSize: typography.tiny,
                  minWidth: 165,
                  maxWidth: 250
                }}
              >
                <div
                  style={{
                    color: palette.text.primary,
                    fontWeight: typography.fontWeightBold,
                    fontSize: typography.standardLarge,
                  }}
                >
                  {image?.artist?.fName} {image?.artist?.lName}
                </div>
                <div
                  style={{
                    fontSize: typography.smallest,
                    marginBottom: "1em"
                  }}
                >
                  {image.imageTitle}
                </div>
                <p> · <UnitFormatter value={image.size} />"</p>
                <p> · {image.type}</p>
                <p> · {image.material}</p>
                {showBorder && <p> · {image.border}</p>}
                {showFrame && <p> · {image.frame}</p>}
                {showWrap &&
                <p> · {capitalizeFirstLetter(image.wrap)} wrap</p>}
                <p
                  style={{
                    color: palette.grey.mediumDark,
                    fontWeight: typography.fontWeightMedium,
                    fontSize: typography.tiny,
                  }}
                >
                  · {capitalizeFirstLetter(galleryCard)} gallery card
                </p>
                <PikchaImageNumber>
                  · Edition&nbsp; {expectedProductNumber} of {image.totalEditions}
                </PikchaImageNumber>
                <div
                  style={{
                    marginTop: "1em",
                    color: palette.grey.mediumDark,
                    fontWeight: typography.fontWeightMedium,
                    fontSize: typography.tiny,
                    display: "flex"
                  }}
                >
                  Total
                  <span style={{marginLeft: "auto"}}>
                    <IntlCurrencyFormatter
                      value={+image.totalPrice}
                      displayFormat={currencyFormat.code}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="flex mt-2-em"
              style={{
                justifyContent:"space-between"
              }}
            >
              <PikchaButton
                action="octonary"
                style={{
                  marginRight: "0.5em"
                }}
                onClick={() => {
                  setClickAsGuest(true);
                  onContinueAsGuest();
                  setTimeout(() => history.push("/checkout#shipping"), 3000);
                }}
              >
                Checkout as guest
              </PikchaButton>
              <PikchaButton
                action="octonary"
                onClick={() => {
                  onContinueAsGuest();
                }}
              >
                Continue shopping
              </PikchaButton>
            </div>
          </div>
        </div>
      </div>
    </PikchaModal>
  );
};
