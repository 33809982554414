import React, {Fragment} from "react";
import {connect} from "react-redux";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import Divider from "@material-ui/core/Divider";
import {useTheme} from "@material-ui/core/styles";

import {toggleSafeSearch} from "../../actions/ageRestriction";
import {isSafeSearchEnabled} from "../../selectors/account";
import {PikchaLineBreak, PikchaSideDrawerLink} from "../../components/baseComponents";
import {FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, DiscordIcon } from "../../components/extraIcons";

import "./Footer.css";
import CurrencyPicker from "../../components/CurrencyPicker/CurrencyPicker";
import PikchaLabel from "../../components/baseComponents/typography/PikchaLabel";
import {UnitPicker} from "../../components/UnitPicker/UnitPicker";
import {PikchaToggleSwitch} from "../../components/baseComponents/PikchaToggleSwitch/PikchaToggleSwitch";

const Footer = ({toggleSafeSearch, safeSearch}) => {
  const {palette, typography} = useTheme();

  const _handleSafeSearch = () => {
    toggleSafeSearch();
  };

  return (
    <Fragment>
      <div
        className="Footer-links"
        style={{
          zIndex: 100,
          minWidth: 208,
          marginTop: "auto"
        }}
      >
        <Divider light />
        <div className={'preferences-wrapper'}>
          <PikchaLabel>
            <div style={{padding: "0 1em 0.5em 0.75em"}}>Preferences</div>
          </PikchaLabel>
          <div className={"currency-picker-wrapper"}>
            <CurrencyPicker />
          </div>
          <div className={"unit-picker-wrapper"}>
            <UnitPicker />
          </div>
          <div className={"pikcha-safeSearch-wrapper"}>
            <div style={{display: 'flex', justifyContent: 'center', padding: "0px 1em 0px 0px"}}>
              <VisibilityRoundedIcon color={palette.common.black} style={{fontSize: '24px'}} />
            </div>
            <div>
              <PikchaToggleSwitch
                checked={safeSearch}
                onClick={() => {
                  _handleSafeSearch();
                }} />
            </div>
          </div>
        </div>
        <Divider light />

        <div className="Footer-linksContainer pb-half-em pt-half-em">
          <PikchaLineBreak />
          <div className="flex-center">
            <PikchaSideDrawerLink path="/policies/privacy" text="Privacy" />
            <PikchaSideDrawerLink
              path="/policies/terms-of-use"
              text="Terms of Use"
            />
          </div>
          <PikchaLineBreak />
          <div className="flex-center">
            <PikchaSideDrawerLink
              path="/policies/user-agreement"
              text="User Agreement"
            />

            <PikchaSideDrawerLink path="/policies/ip" text="IP Policy" />
          </div>
          <div className="flex-center">
            <PikchaSideDrawerLink path="/guides" text="FAQs" />
            <PikchaSideDrawerLink path="/about" text="About" />
            <PikchaSideDrawerLink path="/contact" text="Contact" />
          </div>

          <div
            className="Footer-trademarkBlock"
            style={{
              fontSize: typography.smallest,
              color: palette.grey.mediumLight,
            }}
          >
            © Pikcha Pty Ltd
          </div>
        </div>
        <div className="flex-center pb-1-em pt-1-em Footer-adjust">
          <div
            style={{
              marginLeft: "1.5em",
            }}
          >
            <a
              className="flex-center"
              href="https://www.facebook.com/Pikcha-275041869853091/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FacebookIcon />
            </a>
          </div>
          <div
            style={{
              marginLeft: "1.5em",
            }}
          >
            <a
              className="flex-center"
              href="https://twitter.com/pikcha_official"
              rel="noopener noreferrer"
              target="_blank"
            >
              <TwitterIcon />
            </a>
          </div>
          <div
            style={{
              marginLeft: "1.5em",
            }}
          >
            <a
              className="flex-center"
              href="https://www.instagram.com/pikcha/?hl=en"
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramIcon />
            </a>
          </div>
          <div
            style={{
              marginLeft: "1.5em",
            }}
          >
            <a
              className="flex-center"
              href="https://discord.gg/B9QPCzfEnv"
              rel="noopener noreferrer"
              target="_blank"
            >
              <DiscordIcon />
            </a>
          </div>
          <div
            style={{
              marginLeft: "1.5em",
            }}
          >
            <a
              className="flex-center"
              href="https://www.linkedin.com/company/pikcha-it/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkedInIcon />
            </a>
          </div>
          
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  safeSearch: isSafeSearchEnabled(state),
});

export default connect(mapStateToProps, {
  toggleSafeSearch,
})(Footer);
