import React, { useEffect, useLayoutEffect, useRef, useState, } from "react";
import axios from "axios";
import clsx from "clsx";
import { makeStyles, useTheme, withStyles, } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import isMobile from "../../components/Responsive/isMobile";
import "../../components/checkoutComponents/ShippingOptions.css";
import { useSelector } from "react-redux";
import { IntlCurrencyFormatter } from "../../components/NumberFormatters/IntlCurrencyFormatter";
import {
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  colors
} from "@pikcha/typography"

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  root: {
    padding: 4,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 50,
    width: "1rem",
    height: "1rem",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
  },
  checkedIcon: {
    backgroundColor: colors.Neutral.ink90,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: "1rem",
      height: "1rem",
      backgroundImage: `radial-gradient(${palette.common.white}, ${palette.common.white} 28%,transparent 32%)`,
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: palette.secondary.main,
    },
  },
  formControl: {
    padding: spacing(5),
    width: "100%",
  },
  label: {
    fontWeight: typography.fontWeightMedium,
    fontSize: typography.standard,
    marginBottom: "0.5em"
  },
  formControlLabel: {
    width: "100%",
    '& .muitypography-root': {
      width: '100%'
    }
  },
  table: {
    minWidth: 700,
  },
  td: {
    fontSize: 16,
  },
}));

const StyledTextField = withStyles(({ palette, typography }) => ({
  root: {
    fontFamily: typography.fontFamilyMontserrat,
    fontSize: typography.small,
    fontWeight: typography.fontWeightSemiMedium,
    color: palette.text.primary,

    padding: "0 !important",

    "& > div": {
      paddingLeft: "0 !important",
      maxHeight: 38,
    },

    "& > div > input": {
      marginLeft: 12,
      padding: "0 !important",
      fontSize: typography.small
    }
  },
}))(TextField);

const StyledAutocomplete = withStyles(({ palette, typography }) => ({
  root: {
    height: 36,
    color: palette.text.primary
  },
  option: {
    fontFamily: typography.fontFamilyMontserrat,
    fontSize: typography.smallest,
    fontWeight: typography.fontWeightSemiMedium,
    color: palette.text.secondary,
  },
}))(Autocomplete);

export default ({
  shippingCountries,
  country,
  countryError,
  onCountryChanged,
  countryState,
  countryStateError,
  onCountryStateChanged,
  refreshShippingForCountry,
  refreshShippingState,
  shipType,
  allShipTypes,
  addShipType,
  shippingCountry,
  shipState,
  allShippingStates,
  readyShoppingCartItems,
  shippingOptionError,
  setShippingOptionError
}) => {
  const { palette, typography } = useTheme();

  const classes = useStyles();
  const listRef = useRef();
  const previousScrollDiff = useRef(0);
  const [wrongStateInput, setwrongStateInput] = useState(false);

  const isOnMobile = isMobile();

  const presentmentCurrency = useSelector(state => state.shoppingCartReducer.shoppingCart?.presentmentCurrency);

  const getDefaultCountry = () => {
    let defaultCountryValue;
    if (country) {
      defaultCountryValue = (shippingCountries || []).filter((item) => {
        return item.country === country;
      });
    } else {
      defaultCountryValue = (shippingCountries || []).filter((item) => {
        return item.country === shippingCountry;
      });
    }
    return defaultCountryValue[0];
  };

  const getDefaultState = () => {
    let defaultStateValue;
    if (countryState) {
      defaultStateValue = (allShippingStates || []).filter((item) => {
        return item.label.toLowerCase() === countryState.toLowerCase();
      });
    } else {
      defaultStateValue = (allShippingStates || []).filter((item) => {
        if (shipState) {
          return item.label.toLowerCase() === shipState.toLowerCase();
        }
      });
    }
    return defaultStateValue[0];
  };

  useEffect(() => {
    const defaultStateValue = (allShippingStates || []).filter((item) => {
      if (shipState) {
        return item.label.toLowerCase() === shipState.toLowerCase();
      }
    });
    if (shipState && defaultStateValue.length === 0 && allShippingStates.length > 0) {
      setwrongStateInput(true);
    }
  }, [allShippingStates, shipState]);

  const [defaultCountry, setDefaultCountry] = useState(getDefaultCountry());
  const [defaultState, setDefaultState] = useState(getDefaultState());
  const [isCountryDirty, setisCountryDirty] = useState(false);
  const [isStateDirty, setisStateDirty] = useState(false);

  useEffect(() => {
    const newValue = getDefaultCountry();
    if (!isCountryDirty) {
      setDefaultCountry(newValue);
    }
  }, [shippingCountries, country]);

  useEffect(() => {
    const newValue = getDefaultState();
    if (!isStateDirty) {
      setDefaultState(newValue);
    }
  }, [allShippingStates, shipState, countryState]);

  useLayoutEffect(() => {
    if (!listRef || !listRef.current) return;
    listRef.current.scrollTop =
      listRef.current.scrollHeight - previousScrollDiff.current;
  }, []);


  const getShippingOptions = () => {
    if (!country) {
      return;
    }
    if (country === "United States") {
      axios.get(`api/Postal/ShippingStates/`).then(() => {
        refreshShippingState();
      });
    } else {
      refreshShippingForCountry();
    }
  };

  useEffect(() => {
    getShippingOptions();
  }, [country, readyShoppingCartItems.length, presentmentCurrency]);

  const onStateChange = ({ label, region, iso }) => {
    onCountryStateChanged({
      state: label,
      stateZone: region,
      stateCode: iso,
    });
  };

  const onShippingOptionSelected = (val, el) => {
    addShipType(el);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <TypographyBody
        size={TYPOGRAPHY_BODY_SIZE.Default_Regular}
        style={{ marginBottom: "1em" }}
      >
        Please select your shipping location.
      </TypographyBody>
      <div
        style={{
          width: "100%",
          display: isOnMobile ? undefined : "flex",
        }}
        ref={listRef}
        className="shipping-options"
      >
        <div style={{ width: "100%", maxWidth: 300 }}>
          <TypographyBody
            size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
            style={{ marginBottom: "0.5em" }}
          >
            Country
          </TypographyBody>
          {(!country || defaultCountry) && (
            <StyledAutocomplete
              noOptionsText={
                <div
                  className="full-height full-width no-select" style={{
                    fontSize: typography.small,
                    color: palette.text.secondary,
                    fontWeight: typography.fontWeightMedium
                  }}
                >
                  No options
                </div>
              }
              id="country-dropdown-desktop"
              options={shippingCountries}
              value={defaultCountry}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                setShippingOptionError(false);
                setisCountryDirty(true);
                onCountryChanged(newValue || {});
                setDefaultCountry(newValue);
                setDefaultState({});
              }}
              renderInput={(params) => (
                <StyledTextField {...params} variant="outlined" />
              )}
            />
          )}
          {countryError && (
            <span className={"text-small text-danger"}>This is required!</span>
          )}
        </div>

        {country === "United States" &&
          allShippingStates &&
          allShippingStates.length > 0 && (
            <div
              style={{
                width: "100%",
                maxWidth: 300,
                marginLeft: isOnMobile ? undefined : "1em",
                marginTop: isOnMobile ? "2em" : undefined,
              }}
              className="shipping-options-state"
            >

              <TypographyBody
                size={TYPOGRAPHY_BODY_SIZE.Small_Bold}
                style={{ marginBottom: "0.5em" }}
              >
                State
              </TypographyBody>
              {(!shipState || defaultState || wrongStateInput || isStateDirty) && (
                <StyledAutocomplete
                  id="state-dropdown-desktop"
                  options={allShippingStates}
                  value={defaultState}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    setShippingOptionError(false);
                    setisStateDirty(true);
                    onStateChange(newValue || {});
                    setDefaultState(newValue);
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} variant="outlined" />
                  )}
                />
              )}
              {countryStateError && (
                <span className={"text-small text-danger"}>
                  This is required!
                </span>
              )}
            </div>
          )}
      </div>

      <div
        style={{
          width: "100%",
          marginTop: "2em",
        }}
      >
        {shippingOptionError && (
          <TypographyBody>
            You can't ship such a large size artwork into your shipping country, please re-select your size, thank you
          </TypographyBody>
        )}
        {!shippingOptionError && country && (country === "United States" ? countryState : true) && allShipTypes && allShipTypes.price.length > 0 && (
          <RadioGroup
            aria-label="Shipping Options"
            name="Shipping Options"
            style={{
              display: "inline",
            }}
            value={shipType}
            onChange={onShippingOptionSelected}
          >
            {allShipTypes.pricePresentments?.map((item, index) => (
              <div
                style={{
                  margin: "0 0 0.4em 0.5em",
                }}
                key={index}
              >
                <FormControlLabel
                  key={index}
                  value={allShipTypes.shipType[index]}
                  classes={{ root: classes.formControlLabel }}
                  control={
                    <Radio
                      className={classes.root}
                      disableRipple
                      color="default"
                      checkedIcon={
                        <span
                          className={clsx(classes.icon, classes.checkedIcon)}
                        />
                      }
                      icon={<span className={classes.icon} />}
                    />
                  }
                  label={
                    <div
                      style={{
                        display: 'flex',
                        fontSize: '1rem'
                      }}
                    >
                      <div style={{ marginLeft: '5px', minWidth: '30px' }}>
                        {allShipTypes.estDelTime[index]}
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        business &nbsp;{allShipTypes.estDelTime[index] === "1" ? ("day") : ("days")}
                      </div>
                      <div style={{ flexGrow: isOnMobile ? '1' : '0.3', textAlignLast: 'end' }}>
                        <IntlCurrencyFormatter value={+item} />
                      </div>
                    </div>
                  }
                />
              </div>
            ))}
          </RadioGroup>
        )}
      </div>
    </div>
  );
};
