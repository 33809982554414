import React from 'react';

export default () => (
  <div
    style={{
      width: '100%',
      paddingBottom: '0.1em',
    }}
  />
);
