import React, {useRef} from 'react';
import { connect } from "react-redux";
import {setAlert} from "../actions/alert";
import axios from "axios";
import {
  getOriginSrc,
} from "../actions/cropper";
import {
  PikchaButton,
} from "../components/baseComponents";

const SvgTest = ({
  cropper,
  setAlert,
  getOriginSrc
}) => {
  const hiddenInputRef = useRef(null);

  const onUploadFileChange = (e, onDone) => {
    e.preventDefault();
    let image;

    if (e.dataTransfer) {
      image = e.dataTransfer.files;
      if (!e.target.files) e.target.files = e.dataTransfer.files
    } else if (e.target) {
      image = e.target.files;
    }
    const fileReader = new FileReader();
    if (
      e.target &&
      e.target.files &&
      e.target.files.length > 0 &&
      (e.target.files[0].type.split("/")[1] === "jpeg" ||
        e.target.files[0].type.split("/")[1] === "jpg" ||
        e.target.files[0].type.split("/")[1] === "png")
    ) {
      fileReader.onload = () => {
        const uploadedImage = new Image();

        uploadedImage.onload = function () {
          onDone(uploadedImage);
          getOriginSrc(image[0]);
        };
        uploadedImage.src = fileReader.result;
        setAlert("already uploaded", "success");
      };
      fileReader.readAsDataURL(image[0]);
    } else {
      return false;
    }
  };

  const onChangeImage = () => {
    hiddenInputRef.current.click();
  };

  const onFileChange = (event) => {
    onUploadFileChange(event, (i) => {
      if (!i) {
        onUploadFileChange();
        onChangeImage();
      } else {

      }
    });
  };

  const handleClick = () => {
    console.log(cropper.orgSrc)
    const dto = new FormData();
    dto.append("signatureImageFile", cropper.orgSrc);
    axios.post("/api/Image/converttosvg", dto, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      console.log(response)
    })
  }
  
  return (
    <div className="mt-3-em full-width">
      <input
        type="file"
        onChange={(e) => {
          onFileChange(e)
        }}
        ref={hiddenInputRef}
        style={{
          display: "none",
        }}        
      />
        <div
          className="full-width flex"
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <PikchaButton
            action="octonary"
            className="half-width pl-4-em pr-4-em mr-half-em"
            onClick={onChangeImage}
            style={{
              height: 60,
              marginTop: 16,
              width: "auto",
              marginBottom: "3em"
            }}
          >
            Select File or Drop
          </PikchaButton>

          <PikchaButton
            action="octonary"
            className="half-width pl-4-em pr-4-em mr-half-em"
            onClick={handleClick}
            style={{
              height: 60,
              marginTop: 16,
              width: "auto",
            }}
          >
            Click to Upload
          </PikchaButton>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  cropper: state.cropperReducer,
});

export default connect(mapStateToProps, {
  setAlert,
  getOriginSrc
})(SvgTest);
