import {
  ADD_TIMER,
  CHANGE_TIMER,
  REMOVE_TIMER,
  TIMER_MESSAGE,
  UPDATE_EXISTING_TIMER,
} from "../actions/types";

const initialState = {
  timers: [],
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case ADD_TIMER:
      let data = {
        data: payload.data,
        id: payload.id,
        worker: payload.worker,
        time: payload.time ? payload.time : null,
      };
      let allTimers = state.timers;
      allTimers.push(data);
      return {
        ...state,
        timers: allTimers,
      };
    case CHANGE_TIMER:
      let timers = state.timers;
      let index = timers.findIndex((x) => x.id === payload.id);
      if (index > -1) {
        if (!timers[index].worker) {
          return state;
        }
        if (payload.worker && payload.worker !== timers[index].worker) {
          timers[index].worker.terminate();
        }
        timers[index] = payload;
      }
      return {
        timers: timers,
      };
    case REMOVE_TIMER:
      let removeTimers = state.timers;
      let removeIndex = removeTimers.findIndex((x) => x.id === payload.id);
      if (removeIndex > -1) {
        removeTimers[removeIndex].worker.terminate();
        removeTimers = removeTimers.splice(removeIndex, 1);
      }
      return {
        ...state,
        timers: removeTimers,
      };
    case TIMER_MESSAGE:
      return {
        ...state,
        message: payload,
      };

    case UPDATE_EXISTING_TIMER:
      let allT = state.timers;
      let updateInd = allT.findIndex((x) => x.id === payload.id);
      if (updateInd > -1) {
        if (state.timers[updateInd].worker) {
          state.timers[updateInd].worker.terminate();
        }
        allT[updateInd] = payload;
      }
      return {
        ...state,
        timers: allT,
      };
    default:
      return state;
  }
};
