import React from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { PikchaModal} from "../../../components/baseComponents";

const FullscreenModal = ({ open, onClose, image }) => {

  return (
    <PikchaModal
      FullscreenPhotoModal
      fullWidth={false}
      onClose={onClose}
      maxWidth="lg"
      open={open}
      hideCloseButton={true}
      PaperProps={{
        style: {
          overflow: "hidden",
          position: "absolute",
          padding: 0,
          margin:0,
          maxWidth:"100%",
          background: "transparent",
          borderRadius: "0px",
        },
      }}
      minHeight={1}
    >
      <img
        src={image.watermark}
        alt="watermark"
        style={{
          height: "100%",
          maxHeight: "85vh",
          width: "auto",
        }}
      />
    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  setAlert,
})(FullscreenModal);
