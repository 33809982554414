import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { connect } from "react-redux";
import { BasketNotificationUrl } from "../../selectors/settings";

const NOTIFICATION_MS_IP = "192.168.0.3"; //TODO Store this value somewhere as a config note by Nick

// const url = `/v1/basket/notifications/image/next`;
const SignalR = ({ imageId, onReservationsUpdated, BasketNotificationUrl }) => {
  const url = `${BasketNotificationUrl}/reservations`;

  const [connection, setConnection] = useState({
    connectionState: "disconnected",
  });
  const [isSet, setIsSet] = useState(false);
  useEffect(() => {
    if (BasketNotificationUrl) {
      connect();
    }
    return () => {
      if (connection !== null && connection.baseUrl) {
        connection.close();
      }
    };
  }, [BasketNotificationUrl]);
  useEffect(() => {
    if (
      connection &&
      connection.connectionState &&
      connection.connectionState === "Connected"
    ) {
    }
  }, [connection.connectionState]);
  const connect = () => {
    let newConnection = new signalR.HubConnectionBuilder().withUrl(url).build();
    newConnection.on(imageId, function (imageInfo) {
      onNotifReceived(imageInfo);
    });
    newConnection
      .start()
      .then(function () {
        setConnection(newConnection);
        //console.log("Connection Started");

        newConnection
          .invoke("GetReservations", imageId)
          .then((response) => {
            //console.log("Method Invoked");
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch(function (err) {
        return console.error(err.toString());
      });
  };

  const onNotifReceived = (res) => {
    console.warn(res);
    if (res && res.items) {
      onReservationsUpdated(res.items);
    }
  };
  return <span />;
};
const mapStateToProps = (state) => ({
  BasketNotificationUrl: BasketNotificationUrl(state),
});

export default connect(mapStateToProps, {})(SignalR);
