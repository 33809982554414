import axios from 'axios';

import AuthorizeService from '../auth/AuthorizeService';

import { getCookie } from '../helpers/utils';

import { addTimer } from './timer';
import { setAlert } from './alert';
import {sendCriticalException} from "../helpers/utils";
import {
  REMOVE_TIMER, SHOPPING_CART_ERROR, SHOPPING_CART_LOADING, SHOPPING_CART_UPDATED,
} from './types';

export const getShoppingCart = (id) => async (dispatch, getState) => {
  try {
    const csrfToken = getCookie('XSRF-TOKEN');
    const token = await AuthorizeService.getAccessToken();
    const res = await axios.get(`api/shoppingcart/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-XSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
      },
    });

    if (res.data) {
      dispatch({
        type: SHOPPING_CART_UPDATED,
        payload: res.data,
      });
    }

    const timers = getState().timerReducer;

    const secondsToExpire = Math.max(res.data.secondsToExpire, 0);

    if (secondsToExpire >= 1) {
      const timer = {
        minutes: Math.floor(secondsToExpire / 60),
        seconds: parseInt(
          String(secondsToExpire - Math.floor(secondsToExpire / 60) * 60),
        ),
      };

      dispatch(
        addTimer(
          {
            id: res.data.id,
            time: timer,
            data: {
              basketId: res.data.id,
              secondsToExpire,
            },
          },
          timers,
        ),
      );
    }
  } catch (err) {
    dispatch({ type: SHOPPING_CART_ERROR, payload: err.response });
    dispatch(setAlert('Shopping cart failed', 'error'));
  }
};

export const addProductToShoppingCart = (id, data) => async (dispatch, getState) => {
  try {
    return await new Promise(async (resolve, reject) => {
      const csrfToken = getCookie('XSRF-TOKEN');
      const token = await AuthorizeService.getAccessToken();

      let res;
      try {
        res = await axios.post(`api/shoppingcart/${id}/addItem`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-XSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
          },
        });

        // Error message for more than 10 item in the cart
        if (res.data == 'Limit Exceeded') {
          dispatch(setAlert('You have reached the maximum number of items (10) in your cart', 'error'));
          return;
        }

        resolve(res.data);
      } catch (error) {
        dispatch({ type: SHOPPING_CART_ERROR, payload: error.response });
        console.log('addProductToShoppingCart ', error);
        dispatch(setAlert('Error adding to shopping cart', 'error'));
        sendCriticalException(`add to cart failed, endpoint: api/shoppingcart/${id}/addItem`, `userId: ${id} err-info: ${error}`);
        reject(error);
      }

      // dispatch(setAlert("Item Added To Shopping Cart", "success"));
      if (res?.data) {
        dispatch({
          type: SHOPPING_CART_UPDATED,
          payload: res.data,
        });
      }

      const timers = getState().timerReducer;

      const secondsToExpire = Math.max(res?.data?.secondsToExpire, 0);

      if (secondsToExpire >= 1) {
        const timer = {
          minutes: Math.floor(secondsToExpire / 60),
          seconds: parseInt(
            String(secondsToExpire - Math.floor(secondsToExpire / 60) * 60),
          ),
        };

        dispatch(
          addTimer(
            {
              id: res.data.id,
              time: timer,
              data: {
                basketId: res.data.id,
                secondsToExpire,
              },
            },
            timers,
          ),
        );
        return res.data;
      }
    });
  } catch (err) {
    dispatch({ type: SHOPPING_CART_ERROR, payload: err.response });
    console.log('addProductToShoppingCart 2 ', err);
    dispatch(setAlert('Error adding to shopping cart', 'error'));
    return err;
  }
};

export const attach = (shoppingCartId, userId) => async (dispatch, getState) => {
  try {
    return await new Promise(async (resolve) => {
      const csrfToken = getCookie('XSRF-TOKEN');
      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `api/shoppingcart/${shoppingCartId}/attachto/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-XSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.data) {
        dispatch({
          type: SHOPPING_CART_UPDATED,
          payload: res.data,
        });
      }

      const timers = getState().timerReducer;

      const secondsToExpire = Math.max(res.data.secondsToExpire, 0);

      if (secondsToExpire >= 1) {
        const timer = {
          minutes: Math.floor(secondsToExpire / 60),
          seconds: parseInt(
            String(secondsToExpire - Math.floor(secondsToExpire / 60) * 60),
          ),
        };

        dispatch(
          addTimer(
            {
              id: res.data.id,
              time: timer,
              data: {
                basketId: res.data.id,
                secondsToExpire,
              },
            },
            timers,
          ),
        );
      }
    });
  } catch (err) {
    dispatch({ type: SHOPPING_CART_ERROR, payload: err.response });
    console.log('attachto ', err);
    dispatch(setAlert('Error attaching to shopping cart', 'error'));
  }
};

export const notifyQueueStatus = (id) => async (dispatch) => {
  try {
    return await new Promise(async (resolve) => {
      const csrfToken = getCookie('XSRF-TOKEN');
      const token = await AuthorizeService.getAccessToken();
      const res = await axios.get(
        `api/shoppingcart/${id}/notifyQueueStatus`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-XSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
          },
        },
      );
      resolve(res.data);
    });
  } catch (err) {
    console.log('notifyQueueStatus ', err);
  }
};

export const refreshItems = (id, itemId) => async (dispatch) => {
  try {
    return await new Promise(async (resolve) => {
      const csrfToken = getCookie('XSRF-TOKEN');
      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `api/shoppingcart/${id}/extendtime`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-XSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
          },
        },
      );

      resolve(res.data);
    });
  } catch (err) {
    console.log('refreshItems ', err);
  }
};

export const removeProductFromShoppingCart = (id, shoppingItemId) => async (dispatch) => {
  try {
    const csrfToken = getCookie('XSRF-TOKEN');
    const token = await AuthorizeService.getAccessToken();
    const res = await axios.delete(
      `api/shoppingcart/${id}/removeItem/${shoppingItemId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-XSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
      },
    );

    dispatch(setAlert('Shopping Cart Item Removed', 'success'));
    if (res.data) {
      dispatch({
        type: SHOPPING_CART_UPDATED,
        payload: res.data,
      });
      dispatch({
        type: REMOVE_TIMER,
        payload: {
          id: shoppingItemId,
        },
      });
    }
  } catch (err) {
    dispatch({ type: SHOPPING_CART_ERROR, payload: err.response });
    dispatch(setAlert(err.response, 'error'));
  }
};

export const addShippingOption = (id, data, onDone, onError) => async (dispatch) => {
  try {
    const csrfToken = getCookie('XSRF-TOKEN');
    const token = await AuthorizeService.getAccessToken();
    const res = await axios.post(
      `api/shoppingcart/${id}/addShippingOption`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-XSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
      },
    );

    dispatch({
      type: SHOPPING_CART_UPDATED,
      payload: res.data,
    });
    onDone();
  } catch (err) {
    console.log('addShippingOption ', err);
    dispatch({ type: SHOPPING_CART_ERROR, payload: err.response });
    dispatch(setAlert('Error adding shipping option', 'error'));
    onError();
  }
};

export const addShippingInformation = (id, data) => async (dispatch) => {
  try {
    const csrfToken = getCookie('XSRF-TOKEN');
    const token = await AuthorizeService.getAccessToken();
    const res = await axios.post(
      `api/shoppingcart/${id}/addShippingInfo`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-XSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
      },
    );

    dispatch({
      type: SHOPPING_CART_UPDATED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: SHOPPING_CART_ERROR, payload: err.response });
    dispatch(setAlert(err.response, 'error'));
  }
};

export const addBillingInformation = (id, data) => async (dispatch) => {
  try {
    const csrfToken = getCookie('XSRF-TOKEN');
    const token = await AuthorizeService.getAccessToken();
    const res = await axios.post(
      `api/shoppingcart/${id}/addBillingInfo`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-XSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
      },
    );

    dispatch({
      type: SHOPPING_CART_UPDATED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: SHOPPING_CART_ERROR, payload: err.response });
    dispatch(setAlert(err.response, 'error'));
  }
};

export const updateTheCart = (id, dtoInfo, billingInfo, onDone, onError) => async (dispatch) => {
  try {
    const csrfToken = getCookie('XSRF-TOKEN');

    const token = await AuthorizeService.getAccessToken();
    const responseAAAA = await axios.post(
      `api/shoppingcart/${id}/addShippingInfo`,
      dtoInfo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-XSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
      },
    );

    dispatch({
      type: SHOPPING_CART_UPDATED,
      payload: responseAAAA.data,
    });

    const responseBBBB = await axios.post(
      `api/shoppingcart/${id}/addBillingInfo`,
      billingInfo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-XSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
      },
    );

    dispatch({
      type: SHOPPING_CART_UPDATED,
      payload: responseBBBB.data,
    });

    const res = await axios.get(`api/shoppingcart/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-XSRF-TOKEN': csrfToken,
        'Content-Type': 'application/json',
      },
    });

    dispatch({
      type: SHOPPING_CART_UPDATED,
      payload: res.data,
    });

    if (onDone) {
      onDone({
        shipInfoResponse: responseAAAA.data,
      });
    }
  } catch (err) {
    dispatch({ type: SHOPPING_CART_ERROR, payload: err.response });
    console.log('updateTheCart ', err);
    setAlert('Something went wrong updating the cart', 'error');
    onError(err);
  }
};

// Set Shopping Cart Loading
export const setShoppingCartLoading = () => async (dispatch) => {
  dispatch({
    type: SHOPPING_CART_LOADING,
  });
};
