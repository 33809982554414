import { fromPairs } from "lodash";

export const isUserLoggedIn = (state) => {
  let account;
  let auth;
  if (state) {
    account = state.accountReducer;
    auth = state.authReducer;
  }

  const isLoggedIn =
    auth &&
    auth.loading === false &&
    auth.isAuthenticated === true &&
    account &&
    account.loadingUser === false &&
    account.user !== null;

  return isLoggedIn;
};

export const userId = (state) => {
  let account;
  if (state) {
    account = state.accountReducer;
  }

  let user;
  if (account) {
    user = account.user;
  }

  let userId;
  if (user) {
    userId = user.id;
  }

  return userId;
};

export const isUserLoading = (state) => {
  const loggedIn = isUserLoggedIn(state);

  let account;
  let auth;
  if (state) {
    account = state.accountReducer;
    auth = state.authReducer;
  }

  let isLoading = account.loadingUser;
  if (!isLoading && !auth.failed && !loggedIn) {
    isLoading = true;
  }
  if (auth.failed && isLoading) {
    isLoading = false;
  }
  return isLoading;
};

export const isTheUserAnArtist = (state) => {
  let account;
  if (state) {
    account = state.accountReducer;
  }

  let user;
  if (account) {
    user = account.user;
  }

  let roles;
  if (user) {
    roles = user.roles;
  }

  let isTheUserAnArtist;
  if (roles) {
    isTheUserAnArtist = roles.includes("Artist");
  }

  return isTheUserAnArtist;
};

export const isSafeSearchEnabled = (state) => {
  let account;
  if (state) {
    account = state.accountReducer;
  }

  let user;
  if (account) {
    user = account.user;
  }

  const hasTheUserLoggedIn = isUserLoggedIn(state);

  let safeSearch;
  if (hasTheUserLoggedIn && user) {
    safeSearch = user.safeSearch;
  }
  if (!hasTheUserLoggedIn) {
    safeSearch = true;
  }

  return safeSearch;
};

export const hasAccountNotifications = (state) => {
  let hasAccountNotifications =
    needsEmailVerification(state) || needsPaypalVerification(state);

  return hasAccountNotifications;
};

export const needsEmailVerification = (state) => {
  let account;
  if (state) {
    account = state.accountReducer;
  }

  let needsEmailVerification = false;
  if (account && account.user) {
    const {
      user: { emailConfirmed, roles },
    } = account;
    if (roles.includes("Artist") && !emailConfirmed) {
      needsEmailVerification = true;
    }
  }

  return needsEmailVerification;
};

export const needsPaypalVerification = (state) => {
  let account;
  if (state) {
    account = state.accountReducer;
  }

  let needsPaypalVerification = false;
  if (account && account.user) {
    const {
      user: { paypalVerification, roles },
    } = account;
    if (roles.includes("Artist") && paypalVerification !== "true") {
      needsPaypalVerification = true;
    }
  }

  return needsPaypalVerification;
};

export const userFollowing = (state) => {
  let account;
  if (state) {
    account = state.accountReducer;
  }

  let user;
  if (account) {
    user = account.user;
  }

  let following;
  if (user) {
    following = user.following;
  }

  return following;
}