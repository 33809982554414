import React, { Fragment } from "react";
import { connect } from "react-redux";

import AccountRoutes from "../routes/AccountRoutes";

const Account = ({ match, account }) => {
  const isThereAUser = !!account.user;
  return (
    <Fragment style={{ width: "100%", justifyContent: "space-between" }}>
      {isThereAUser ? <AccountRoutes match={match} /> : undefined}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps)(Account);
