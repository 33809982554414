import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { PikchaButton, PikchaSpacer, PikchaTextBlock } from "../baseComponents";
import { addModalOverlay, removeModalOverlay } from "../../helpers/utils";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(({zIndex}) => ({
  root: {
    zIndex: "9999999 !important"
  },
  paper: {},
}));

export default function ConfirmAlert({
  onClose,
  value,
  handleCloseModalAndExitAlert,
  open,
  ...rest
}) {
  const {root} = useStyles();
  const _handleCloseModalAndExitAlert = (state, cancelExit) => {
    cancelExit ? addModalOverlay() : removeModalOverlay();

    handleCloseModalAndExitAlert(state, cancelExit);
  };

  return (
    <Dialog
      onEntering={addModalOverlay}
      className={`${root}`}
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      {...rest}
    >
      <DialogContent 
        style={{
          paddingTop: 20,
          paddingBottom: 8,
          paddingLeft: 24,
          paddingRight: 24
        }}
      >
        <PikchaTextBlock className="pl-half-em pr-half-em">
          Are you sure you want to exit?
        </PikchaTextBlock>
        <PikchaSpacer size={2} />
        <PikchaSpacer />
        <div className="justify-center flex-center">
          <div className="flex-grow-1 pl-half-em pr-half-em">
            <PikchaButton
              fullWidth
              small
              action="secondary"
              onClick={() => _handleCloseModalAndExitAlert(false, true)}
              noBorder
            >
              Cancel
            </PikchaButton>
          </div>
          <div className="flex-grow-1 pl-half-em pr-half-em">
            <PikchaButton
              fullWidth
              small
              onClick={() => _handleCloseModalAndExitAlert(false)}
              action="tertiary"
            >
              Yes, exit
            </PikchaButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
