import React from "react";
import { connect } from "react-redux";
import Pikcha100BannerImage from "../../../assets/images/SharePikcha100BannerImage.png";
import Artist100BannerImage from "../../../assets/images/ShareArtist100BannerImage.png";
import isMobile from "../../../components/Responsive/isMobile";

import {
  FacebookIcon,
  FacebookShareButton,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  RedditIcon,
  RedditShareButton,
  EmailIcon,
  EmailShareButton,
  PinterestIcon,
  PinterestShareButton,
  TumblrIcon,
  TumblrShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

import { useTheme } from "@material-ui/core/styles";
import SaveAltRoundedIcon from "@material-ui/icons/SaveAltRounded";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { setAlert } from "../../../actions/alert";
import { PikchaModal, PikchaButton, PikchaTextField } from "../../../components/baseComponents";

const SharingModal = ({ open, onClose, pageType, setAlert }) => {
  const isOnMobile = isMobile();
  const { typography, palette } = useTheme();
  const imageCopyLink = `${window.location.origin}/share/${pageType}`;
  const copyLink = () => {
    navigator.clipboard.writeText(imageCopyLink).then(
      () => {
        setAlert("URL has been copied", "info");
      },
      (err) => {
        setAlert("Couldn't copy image link", "error");
      }
    );
  };

  const title =  (pageType == "pikcha100")?
                "A collection of the most influential and collectible artworks on Pikcha" :
                "A collection of the most influential and collectible artists on Pikcha";

  const mediaImageForPinterest =  (pageType == "pikcha100")? 
                "https://cdn.pikcha.com/img/OG_Pikha100Sharable.png":
                "https://cdn.pikcha.com/img/OG_Artist100Sharable.png"

  const socialSharingButtonsForPikcha100 = () => (
    <div className="full-width flex mt-2-em mb-2-em" style={{flexWrap: "wrap", maxWidth: 516, }}>
      <div className="social-sharing-button-box">
        <FacebookShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          quote={title}
          url={`${window.location.origin}/share/${pageType}`}
        >
          <span
            className="Facebook-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <FacebookIcon size={46}/>
          </span>
        </FacebookShareButton>
        <span
          style={{
           
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Facebook</span> 
      </div>

      <div className="social-sharing-button-box">
        <FacebookMessengerShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/share/${pageType}`}
          appId="536438577204283"
          redirectUri={`${window.location.origin}/share/${pageType}`}
        >
          <span
            className="Messenger-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <FacebookMessengerIcon size={46}/>
          </span>
        </FacebookMessengerShareButton>
        <span
          style={{
           
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Messenger</span> 
      </div>

      <div className="social-sharing-button-box">
        <LinkedinShareButton
          url={`${window.location.origin}/share/${pageType}`}
          title="Check out my latest image on Pikcha"
          summary={title}
          source="https://www.pikcha.com"
          style={{
            marginRight: "0px",
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
        >
          <span
            className="LinkedIn-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <LinkedinIcon size={46} />
          </span>
        </LinkedinShareButton>
        <span
          style={{
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >LinkedIn</span> 
      </div>

      <div className="social-sharing-button-box">
        <TwitterShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/share/${pageType}`}
          title={title}
          hashtags={["pikcha", "pikcha100"]}
        >
          <span
            className="Twitter-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TwitterIcon size={46} />
          </span>
        </TwitterShareButton>
        <span
          style={{
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Twitter</span> 
      </div>

      <div className="social-sharing-button-box">
        <RedditShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
          url={`${window.location.origin}/share/${pageType}`}
          title={title}

          hashtags={["pikcha", "pikcha100"]}
        >
          <span
            className="Reddit-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px",
            }}
          >
            <RedditIcon size={46}/>
          </span>
        </RedditShareButton>
        <span
          style={{
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Reddit</span> 
      </div>

      <div className="social-sharing-button-box">
        <EmailShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/share/${pageType}`}
          body={title}
          subject={`Check out the ${pageType}, on Pikcha.com.`}
        >
          <span
            className="Email-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <EmailIcon size={46}/>
          </span>
        </EmailShareButton>
        <span
          style={{
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Mail</span> 
      </div>

      <div className="social-sharing-button-box">
        <PinterestShareButton
          url={`${window.location.origin}/share/${pageType}`}
          media={mediaImageForPinterest}
          description={title}
          style={{
            marginRight: "0px",
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}
        >
          <span
            className="Pinterest-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <PinterestIcon size={46} />
          </span>
        </PinterestShareButton>
        <span
          style={{
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Pinterest</span> 
      </div>

      <div className="social-sharing-button-box">
        <TumblrShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/share/${pageType}`}
          caption ={title}
          title ={pageType}
          tags ={[pageType]}  
        >
          <span
            className="Tumblr-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TumblrIcon size={46}/>
          </span>
        </TumblrShareButton>
        <span
          style={{
           
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Tumblr</span> 
      </div>

      <div className="social-sharing-button-box">
        <WhatsappShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/share/${pageType}`}
          title ={title}
        >
          <span
            className="Whatsapp-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <WhatsappIcon size={46}/>
          </span>
        </WhatsappShareButton>
        <span
          style={{
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Whatsapp</span> 
      </div>

      <div className="social-sharing-button-box">
        <TelegramShareButton
          style={{
            maxWidth: "56px",
            maxHeight: "49px",
            marginBottom: "0.75em",
          }}

          url={`${window.location.origin}/share/${pageType}`}
          title ={title}
        >
          <span
            className="Telegram-buttonWrapper SocialShare-button"
            style={{
              maxHeight: "49px"
            }}
          >
            <TelegramIcon size={46}/>
          </span>
        </TelegramShareButton>
        <span
          style={{
            color:"grey",
            fontSize: typography.tiny,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
          }}
        >Telegram</span> 
      </div>
    </div>
  );


  return (
    <PikchaModal
      fullWidth={false}
      onClose={onClose}
      maxWidth="lg"
      open={open}
      PaperProps={{
        style: {
          overflow: "hidden",
        },
      }}
      minHeight={1}
    >

      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: isOnMobile? "1.5em" : "2em",
          paddingTop: "2em",
        }}
      >
        <img
          src={(pageType == "pikcha100")? Pikcha100BannerImage:Artist100BannerImage}
          alt="Share Pikcha100 list"
          style={{
            height: "200px",
            width: "auto",
            borderRadius: "6px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
          }}
        />
       
        {socialSharingButtonsForPikcha100()}

        <div style={{display: "flex",width: "100%", maxWidth:516}}>
          <div style={{minWidth:"200px", width: "100%", paddingTop: "0.75em", paddingRight:"0.5em",}}>
            <PikchaTextField
                disabled={true}
                type="text"
                name="nickName"
                value={imageCopyLink}
              />
          </div>
          <div className="copy-link-button-box">
            <PikchaButton
              noPadding
              style={{
                minWidth: 40,
                marginBottom: "0.5em",
                padding: 3,
                marginRight: 0,
              }}
              onClick={() => copyLink()}
            >
                <AttachmentIcon/>
            </PikchaButton>
            <span
                style={{
                  color:"grey",
                  fontSize: typography.tiny,
                  fontWeight: typography.fontWeightSemiMedium,
                  fontFamily: typography.fontFamilyMontserrat,
                }}
              >
                Copy
            </span> 
          </div>
        </div>  

      </div>

    </PikchaModal>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  setAlert,
})(SharingModal);
