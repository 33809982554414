import React, { useState } from 'react'
import {
  TypographySubtitle,
  TYPOGRAPHY_SUBTITLE_COMPONENT,
  TypographyBody,
  TYPOGRAPHY_BODY_SIZE,
  TypographyButton,
  colors
} from "@pikcha/typography";
import SignaturePreview from '../../containers/modals/UploadFlowModal/SignaturePreview';
import outline from '../../assets/images/uploadArt/languageOutline.svg';
import filled from '../../assets/images/uploadArt/languageFilled.svg';
import uploadArt from '../../assets/images/uploadArt/uploadArt.png';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { PikchaIcon, PikchaLoader } from '../../components/baseComponents';

export default ({
  handleNextStep,
  createInputId,
  step3Pending,
  ...rest
}) => {

  const UploadModalToolTip = withStyles({
    tooltip: {
      background: "rgba(0, 0, 0, 0.85)",
      padding: '3em',
      maxWidth: '400px',
      width: '100%',
    }
  })(Tooltip);

  const [iconVisible, setIconVisible] = useState(true);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TypographySubtitle
          component={TYPOGRAPHY_SUBTITLE_COMPONENT.Desktop_H3_Bold}
        >
          Placement & Colour
        </TypographySubtitle>
        <UploadModalToolTip
          onOpen={() => setIconVisible(false)}
          onClose={() => setIconVisible(true)}
          interactive
          enterDelay={500}
          leaveDelay={0}
          placement="top-center"
          title={
            <>
              <TypographyBody
                style={{ color: '#fff', paddingBottom: '1em ' }}
                size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                Select the color and placement of your signature and edition number
              </TypographyBody>
              <TypographyBody
                style={{ color: '#fff', paddingBottom: '1em ' }}
                size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                Seleccione el color y la ubicación de su firma y el número de edición
              </TypographyBody>
              <TypographyBody
                style={{ color: '#fff', paddingBottom: '1em ' }}
                size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                选择您的签名和版本号的颜色和位置
              </TypographyBody>
              <TypographyBody
                style={{ color: '#fff', paddingBottom: '1em ' }}
                size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                서명 및 에디션 번호의 색상과 위치를 선택하십시오.
              </TypographyBody>
              <TypographyBody
                style={{ color: '#fff', paddingBottom: '1em ' }}
                size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                حدد لون وموضع توقيعك ورقم الإصدار
              </TypographyBody>
              <TypographyBody
                style={{ color: '#fff'}}
                size={TYPOGRAPHY_BODY_SIZE.Large_Bold}
              >
                Wählen Sie die Farbe und Platzierung Ihrer Signatur und Editionsnummer
              </TypographyBody>
            </>
          }
        >
          <span 
            className="flex"
            style={{
              alignItems: "center"
            }}
          >
            <PikchaIcon
              onMouseEnter={() => setIconVisible(false)}
              onMouseLeave={() => setIconVisible(true)}
              size="large"
              noMarginAuto
              icon={iconVisible ? <img src={outline} /> : <img src={filled} />}
              style={{
                height: "24px",
                width: "24px",
                fontSize: '24px',
                marginLeft: '0.25em'
              }}
            />
          </span>
        </UploadModalToolTip>
      </div>
      <TypographyBody
        style={{
          margin: "1em 0"
        }}
      >
        Select the placement and colour of your signature and edition number.
      </TypographyBody>
      <div
        className="mb-1-em"
        style={{
          width: "100%",
          border: `1px solid ${colors.Neutral.grey30}`,
          borderRadius: "4px",
          padding: "1em"
        }}
      >
        <TypographyBody
          size={TYPOGRAPHY_BODY_SIZE.Default_Bold}
        >
          Signature and Edition number placement
        </TypographyBody>
        <SignaturePreview
          {...rest}
        />
      </div>
      <TypographyButton
        buttonStyle={{ width: "100%", marginBottom: "1em", display: "flex" }}
        disabled={step3Pending}
        onClick={async () => {
          await createInputId();
          handleNextStep();
        }}
      >
        {step3Pending ? (
          <div style={{marginTop: "1em"}}>
            <PikchaLoader />
          </div>
        ): "Next"}
      </TypographyButton>
    </>
  )
}
