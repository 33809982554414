import React, { useEffect, useState } from "react";
import axios from "axios";
import Img from "react-image";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import {  userId } from "../../../selectors/account";
import {useHistory} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import { ICON_SIZE_DEFAULT } from "../../../helpers/constants";
import { PikchaModal, PikchaImageMetaText } from "../../../components/baseComponents";
import DeleteImageConfirmationModal from './DeleteImageConfirmationModal';
import AuthorizeService from "../../../auth/AuthorizeService";

import SoldIcon from "../../../assets/images/filter_frames-black-24dp.svg";
import MoneyIcon from "../../../assets/images/attach_money-black-24dp.svg";
import ViewIcon from "../../../assets/images/visibility-black-24dp.svg";
import VisibilityOffRoundedIcon from "@material-ui/icons/VisibilityOffRounded";
import isTablet from "../../../components/Responsive/isTablet";
import {ProductItemStats} from "../../../containers/product/ProductItem/ProductItemStats";
import ArtworkPricesModal from '../../../containers/modals/ArtworkPricesModal';

import DateSeparatePicker from '../../../components/DateSeparatePicker/DateSeparatePicker';

import {
  PikchaCheckbox,
  PikchaDropdown,
  PikchaFieldWrapper,
  PikchaLoader,
  PriceField,
  TagsPicker,
  PikchaTextField,
} from "../../../components/baseComponents";
import ResponsiveFriendlyColors from "../../../components/ImageColourPicker/ResponsiveFriendlyColors";
import DescriptionTextArea from "../../../components/DescriptionTextArea/DescriptionTextArea";
import LocationIcon from "../../../assets/images/location_on-black-24dp.svg";

const StyledLinearProgress = withStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.grey.light,
  },
}))(LinearProgress);

const EditPhotoModal = ({
  imageDetails,
  previewProgress,
  onCloseModal,
  setAlert,
  userId
}) => {
  
  const { typography, palette } = useTheme();

  const history = useHistory();

  const isOnTablet = isTablet();

  const [fields, setFields] = useState({
    allColours: [],
    allTags: [],
    allImageTypes: [],
    allCategories: [],
    selectedTags: [],
    selectedCategory: null,
    currentTag: null,
  });
  const [allTags, setAllTags] = useState([]);
  const [allColours, setAllColours] = useState([]);

  const [Title, setTitle] = useState("");
  const [Location, setLocation] = useState("");
  const [Description, setDescription] = useState("");
  const [ImageType, setImageType] = useState({
    label: null,
    value: null
  });
  const [ImageCategory, setImageCategory] = useState([]);
  const [Tags, setTags] = useState([]);
  const [Color, setColor] = useState(null);
  const [PerSale, setPerSale] = useState("");
  const [IsNudity, setIsNudity] = useState(false);
  const [ImageInfo, setImageInfo] = useState(null);
  const [DateCreated, setDateCreated] = useState("00-00-0000");

  const [ShowDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [ShowPriceEstimater, setShowPriceEstimater] = useState(false)
  const [ChosenColorId, setChosenColorId] = useState();
  const [enterPrice, setEnterPrice] = useState(false);

  const [futureDate, setFutureDate] = useState(false);

  const [DatePick, setDatePick] = useState("00-00-0000");

  useEffect(() => {
    const retrieveData = () => {
      axios({
        method: "GET",
        url: `api/image/prerequisites`,
        data: null,
      }).then((cat) => {
        const cats = [];
        const types = [];
        const colours = [];

        cat.data.categories.forEach((cat) => {
          cats.push({ label: cat.name, value: cat });
        });
        cat.data.colours.forEach((colour) => {
          colours.push(colour.hexValue);
        });
        setAllColours(colours);
        cat.data.types.forEach((type) =>
          types.push({
            label: type.name,
            value: type,
          })
        );

        setFields({
          allCategories: cats,
          allTags: [],
          allImageTypes: types,
          allColours: cat.data.colours,
        });
      });
    };
    retrieveData();
  }, []);

  useEffect(() => {
    if(ImageInfo){
      setTitle(ImageInfo.title)
      setLocation(ImageInfo.location);
      setDescription(ImageInfo.caption);
      if(ImageInfo.type){
        setImageType({
          label:ImageInfo.type.name,
          value:ImageInfo.type
        });
      }
      if(ImageInfo.categoryList){
        let categoryIdList = [];
        ImageInfo.categoryList.map((item)=>{
          categoryIdList.push(fields.allCategories.filter((cate)=>
            cate.label === item.category.name
          )[0])
        })
        setImageCategory(categoryIdList);
      }
      setTags([...ImageInfo.tags]);
      setIsNudity(ImageInfo.isNudity);
      if(ImageInfo.colour) {
        setColor(ImageInfo.colour.hexValue);
        setChosenColorId(ImageInfo.colour.hexValue);
      }
      setPerSale(ImageInfo.setPrice);
      setDateCreated(ImageInfo.dateCreated);
    }
  }, [fields.allCategories, ImageInfo]);

  useEffect(async () => {
    const token = await AuthorizeService.getAccessToken();
    axios.post(
      `${
        process.env.NODE_ENV === "production"
          ? ""
          : "https://pikcha.local:1900"
      }/v1/search/api/Search/GetMySpecificImage`,
      
      {
        imageId: imageDetails.id
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res)=>{
      setImageInfo(res.data);
    }).catch((err)=>{
      console.log("editphotomodal ", err);
    });
  }, [])

  useEffect(() => {
    const currentDate = new Date();
    const dateSelected = DatePick.split("-");
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    if(parseInt(dateSelected[2]) > currentYear){
      setFutureDate(true);
      setAlert("Please enter a valid date", "error");
    } else if(
      parseInt(dateSelected[2]) === currentYear && 
      parseInt(dateSelected[1]) > currentMonth
      ) {
        setFutureDate(true);
        setAlert("Please enter a valid date", "error");
    } else if(
      parseInt(dateSelected[2]) === currentYear && 
      parseInt(dateSelected[1]) === currentMonth &&
      parseInt(dateSelected[0]) > currentDay
    ){
      setFutureDate(true);
      setAlert("Please enter a valid date", "error");
    } else {
      setFutureDate(false);
    }
  }, [DatePick])

  const handleColourChange = (chosenColor) => {
    let colorToEmit = "";
    fields.allColours.forEach((col) => {
      if (col.hexValue === chosenColor) {
        colorToEmit = col.id;
      }
    });
    setColor(chosenColor);
    setChosenColorId(colorToEmit);
  };

  const handleTagAdd = (tag) => {
    if(tag.startsWith("#")){
      tag=tag.substring(1);
    }
    setAllTags([...allTags, tag.trim()]);
    setTags([...Tags, tag.trim()]);
  };

  const handleTagDelete = (tag, index) => {
    allTags.splice(index, 1);
    Tags.splice(index,1);
    setAllTags(allTags);
    setTags(Tags);
  };

  const handleNudityCheck = () => {
    setIsNudity(!IsNudity);
  };

  const handleDatePickerChange = (value) => {
    setDatePick(value);
  }

  const deletePhotoConfirm = async () => {
      const token = await AuthorizeService.getAccessToken();
      axios({
        method: "DELETE",
        url: `api/image/${imageDetails.id}/${userId}`,
        data: null,
        headers: {
          Authorization: `Bearer ${token}`,          },
        }).then(() => {
          setAlert("successfully deleted", "success");
          setShowDeleteConfirmation(false);
          onCloseModal();
          history.push(`/${imageDetails.artist.userHandle}`);
          window.location.reload();
        }).catch((err)=>{
          console.log("deletePhotoConfirm ", err);
          setAlert("delete failed", "error")
        })
    };

  const saveImageInfoChange = async () => {
    const token = await AuthorizeService.getAccessToken();

    if(parseFloat(PerSale) < 3){
      setAlert("Make a higher Price!", "error");
      return
    }

    let categoryIds = [];

    ImageCategory.map((item)=>{
      categoryIds.push(item.value.id);
    })

      let dto = {
        Title: Title,
        Caption: Description,
        location: Location,
        SetPrice: parseFloat(PerSale),
        Tags: Tags,
        isNudity: IsNudity,
        typeId: ImageType.value ? ImageType.value.id : null,
        colourId: ChosenColorId ? ChosenColorId : null,
        CategoryIds: categoryIds,
        dateCreated: DatePick
      };

      axios
        .put(`api/image/${imageDetails.id}`, dto, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setAlert("Image Details Updated", "success");
          //console.log(res.data);
          onCloseModal();
        })
        .catch((err)=>{
          console.log("saveImageInfoChange ", err);
          setAlert("failed to update image details", "error");
        });
    };

  return (
    <PikchaModal
      darkHeader
      fullWidth
      headerZIndex={15}
      open={true}
      minHeight="initial"
      onClose={()=>{onCloseModal()}}
      paperStyle={{
        maxWidth: 1114,
        maxHeight: "95%",
        height: "100%",
      }}
      title={'Edit Image'}
      FooterActionProps={(ImageInfo || {}).totSold === 0 ? {
        secondary: {
          buttonType: "tertiary",
          action: () => {setShowDeleteConfirmation(true)},
          text: "Delete",
        },
        primary: {
          action: () => {saveImageInfoChange()},
          text: "Save Details",
        },
        zIndex: 15
      }: {
        primary: {
          action: () => {saveImageInfoChange()},
          text: "Save Details",
        },
        zIndex: 15
      }}
    >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        marginTop: "4em"
      }}
    >
      <DeleteImageConfirmationModal
        open={ShowDeleteConfirmation}
        cancelDeleteConfirmation={() => {setShowDeleteConfirmation(false)}}
        doDeleteImage={() => {
          deletePhotoConfirm()
        }}
        thumbnail={ImageInfo ? ImageInfo.thumbnail : ''}
      />
      <ArtworkPricesModal
        open={ShowPriceEstimater}
        onClose={()=>{setShowPriceEstimater(false)}}
        aspectRatio={`${(ImageInfo || {}).aspRatioX},${(ImageInfo || {}).aspRatioY}`}
        setPrice={PerSale}
      />
      <div
        style={{
          flexGrow: 0.9,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            marginLeft: 24,
            color: "grey",
            fontSize: typography.small,
            fontWeight: typography.fontWeightSemiMedium,
            fontFamily: typography.fontFamilyMontserrat,
            paddingTop: "1em",
          }}
        >
          <span> Thumbnail </span>
        </div>
        <div
          className="ImagePreview-modalSection flex-stretch flex-grow-1 pt-1-em pb-1-em"
          style={{
            height: "100%",
            paddingTop: "unset",
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            className="ImagePreview-container ProductItem-imageFrame p-1-em"
            style={{
              width: "100%",
            }}
          >
            {ImageInfo ? (
              <Img
                className="UploadModalContainer-previewImage block"
                src={ImageInfo.thumbnail}
                alt="Image Preview"
              />
            ) : (
                <div className="flex-column full-width full-height">
                  <StyledLinearProgress
                    style={{
                      width: "100%",
                    }}
                    variant="determinate"
                    value={previewProgress}
                  />
                  <div
                    style={{
                      justifyContent: "center",
                    }}
                    className="flex-center flex-grow-1 Step2-pikchaLoader"
                  >
                    <PikchaLoader />
                  </div>
                </div>
              )}
          </div>
          <div 
            className="flex-column flex-grow-1"
            style={{
              marginTop: '1em',
              maxWidth: 400,
            }}
          >
            <ProductItemStats image={imageDetails}/>
            <div
              className="pt-half-em"
              style={{
                height: 20,
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: isOnTablet ? "520px" : "560px",
          marginRight: "1em",
        }}
      >
        <div className="form-section ModalForm pt-1-em">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PikchaTextField
                label="Title"
                required
                type="text"
                value={Title}
                onChange={({ target: { value } }) => {
                    setTitle(value);
                  }
                }
                disabled={(ImageInfo || {}).totSold > 0 ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  width: 16,
                  height: 16,
                  backgroundImage: `url(${LocationIcon})`,
                  backgroundSize: "cover",
                  marginLeft: "3.4rem",
                  marginTop: -18,
                }}
              />
              <PikchaTextField
                label="Location"
                type="text"
                value={Location}
                onChange={({ target: { value } }) => {
                  setLocation(value)
                }}
                disabled={(ImageInfo || {}).totSold > 0 ? true : false}
              />
            </Grid>
          </Grid>
          <DateSeparatePicker 
            handleDatePickerChange={(value)=>{handleDatePickerChange(value)}}
            DateCreated={DateCreated}
            Solded={(ImageInfo || {}).totSold > 0 ? true : false}
            futureDate={futureDate}
          />
          <DescriptionTextArea
            rows={4}
            value={Description || ""}
            onChange={(value)=>{
              setDescription(value)
            }}
            maxChars={600}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PikchaDropdown
                label="Image type"
                onChange={({ label, value }) => {
                  setImageType({
                    label,
                    value
                  })
                }}
                options={fields.allImageTypes}
                value={ImageType}
                placeholder=""
              />
              <PikchaFieldWrapper
                label="Colour"
                subLabel="the primary colour in this image"
                style={{
                  width: 264,
                }}
              >
                <ResponsiveFriendlyColors
                  onChange={handleColourChange}
                  colors={allColours}
                  value={Color}
                />
              </PikchaFieldWrapper>
            </Grid>
            <Grid item xs={6}>
              <PikchaDropdown
                label="Category (3)"
                onChange={(items) => {
                  if(items){
                    if(items.length<=3){
                      setImageCategory(items);
                      let categoryIdList = [];
                      items.map((item)=>{
                        categoryIdList.push(item.value.id);
                      })
                    }else {
                      items.pop();
                      setImageCategory(items);
                      let categoryIdList = [];
                      items.map((item)=>{
                        categoryIdList.push(item.value.id);
                      })
                    }
                  }else {
                    setImageCategory([]);
                  }
                }}
                isMulti={true}
                closeMenuOnSelect={false}
                options={fields.allCategories}
                value={ImageCategory}
                placeholder=""
              />
              <div className="pt-1-em">
                <TagsPicker
                  value={Tags}
                  handleTagAdd={handleTagAdd}
                  handleTagDelete={handleTagDelete}
                />
              </div>
            </Grid>
          </Grid>

          <div className="flex-center">
            <div
              style={{
                width: "50%",
              }}
            >
              <PriceField
                required
                onValueChange={(values) =>{
                    setPerSale(values.value);
                  }
                }
                style={{
                  marginRight: "1em",
                }}
                value={PerSale}
              />
              <div
                onClick={()=>{setShowPriceEstimater(true)}}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 3,
                  borderRadius: 3,
                  background: enterPrice ? "rgba(0, 0, 238, 0.05)" : null
                }}
                onMouseEnter={()=>{setEnterPrice(true)}}
                onMouseLeave={()=>{setEnterPrice(false)}}
              >
                <span 
                  className="material-icons"
                  style={{
                    fontSize: 16,
                    color: palette.link.main,
                    marginRight: 3
                  }}
                >
                  table_view
                </span>
                <span
                  style={{
                    fontWeight: typography.fontWeightSemiMedium,
                    fontSize: typography.small,
                    color: "#0000ee",
                  }}
                >
                  View artwork prices
                </span>
              </div>
            </div>

            <div className="ImageRights-checkboxContainer">
              <PikchaCheckbox
                value={IsNudity}
                checked={IsNudity}
                onClick={handleNudityCheck}
                label="This image contains nudity, graphic violence or depictions of deceased persons"
                textIcon={<VisibilityOffRoundedIcon fontSize="inherit"/>}
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  </PikchaModal>
  );
};

const mapStateToProps = (state) => ({
  userId: userId(state),
});

export default connect(mapStateToProps,{
  setAlert
})(EditPhotoModal)
