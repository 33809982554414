import React from "react";
import ContentLoader from "react-content-loader";
import { useTheme } from "@material-ui/core/styles";

export default (props) => {
  const { palette } = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={844}
      height={414}
      viewBox="0 0 844 414"
      backgroundColor={palette.grey.lighter}
      foregroundColor={palette.grey.lighter}
      {...props}
    >
      <rect x="144" y="80" rx="6" ry="6" width="696" height="344" />
      <rect x="0" y="80" rx="6" ry="6" width="126" height="100" />
      <rect x="144" y="0" rx="6" ry="6" width="80" height="44" />
      <rect x="241" y="25" rx="6" ry="6" width="120" height="16" />
      <rect x="241" y="5" rx="6" ry="6" width="80" height="8" />
    </ContentLoader>
  );
};
