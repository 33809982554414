import { HANDLE_SIDE_DRAWER } from "./types";

export const handleSideDrawer = (state) => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_SIDE_DRAWER,
      payload: state,
    });
  };
};
