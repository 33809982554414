import axios from "axios";
import AuthorizeService, {AuthenticationResultStatus,} from "../auth/AuthorizeService";
import {ApplicationPaths} from "../auth/ApiAuthorizationConstants";
import {removeModalOverlay} from "../helpers/utils";
import {
  ARTIST_FOLLOWED,
  ARTIST_NOTIFICATION_PREFERENCE_CHANGED,
  ARTIST_UNFOLLOWED,
  FROM_FOLLOWING_SECTION,
  HANDLE_ACCOUNT_LOAD_FROM_ONBOARDING,
  HANDLE_SAVED_IMAGE,
  HANDLE_SIGNATURE_UPDATE_SUCCESS,
  HANDLE_UPLOADED,
  JOYRIDE_ACTIVE_STEP,
  JOYRIDE_SHOWN,
  JOYRIDE_WAS_TRIGGERED,
  LOGGED_OUT,
  SIGNATURE_LOADED,
  SIGNATURE_LOADING,
  SIGNATURE_REMOVED,
  USER_ERROR,
  USER_LOADED,
  USER_LOADING,
  USER_SIGNATURE_UPDATED, USER_TOKEN,
  USER_UPDATED,
  GET_USED_SIGNATURE,
  USER_PAYMENT_CHANGED,
  ARTIST_FOLLOWING_NO_UPDATING,
  GET_USED_SIGNATURE_ERR
} from "./types";
import {setAlert} from "./alert";
import { noOfFollowing } from "../selectors/following";

export const loadUser = () => {
  return async (dispatch) => {
    try {
      dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();

      const res = await axios.get(`api/profile/${user.sub}/myinfo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(loadSignature());
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
    }
  };
};

export const getUserOnboarded = (dto) => {
  const {
    id,
    fName,
    lName,
    bio,
    email,
    shipAddr1,
    shipAddr2,
    shipCity,
    shipPostal,
    shipState,
    shipCountry,
    phoneNumber,
    links,
    nationality,
    preferredCurrency,
    nickName,
    userPreferences,
    userHandle
  } = dto
  return async (dispatch) => {
    try {
      dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();

      const res = await axios.put(`/api/Profile/${id}`, {
        fName: fName,
        lName: lName,
        bio: bio,
        email: email,
        shipAddr1: shipAddr1,
        shipAddr2: shipAddr2,
        shipCity: shipCity,
        shipPostal: shipPostal,
        shipState: shipState,
        shipCountry: shipCountry,
        phoneNumber: phoneNumber,
        links: links,
        nationality: nationality,
        preferredCurrency: preferredCurrency,
        nickName: nickName,
        userPreferences: userPreferences,
        userHandle: userHandle,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(loadSignature());
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
    }
  };
}

export const getUsedSignatureList = () => {
  return async (dispatch, getState) => {
    try {
      const {
        accountReducer: {token, usedSignatureStart, usedSignatureCount}
      } = getState();
      const res = await axios.get('api/profile/GetArtistPreviousSignatureList', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          skip: usedSignatureStart - 1,
          count: usedSignatureCount
        }
      });
      dispatch({
        type: GET_USED_SIGNATURE,
        payload: res.data
      })
    } catch(err) {
      dispatch({
        type: GET_USED_SIGNATURE_ERR
      })
    }
  }
}

export const updateAccountToken = (token) => {
  return (dispatch) => {
    dispatch({
      type: USER_TOKEN,
      payload: token,
    });
  }
}

export const authorizeUserForPage = () => {
  return async (dispatch) => {
    try {
      dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      !!token && dispatch({
        type: USER_TOKEN,
        payload: token,
      });
      const user = await AuthorizeService.getUser();

      let res;
      try {
        //console.log("MYINFO authorizeUserForPage");
        res = await axios.get(`api/profile/${user.sub}/myinfo`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {

        const forceRefresh = await AuthorizeService.signInSilent();
        if (
          forceRefresh &&
          forceRefresh.status === AuthenticationResultStatus.Fail
        ) {
          window.location.replace(ApplicationPaths.LogOut);
          return;
        }

        const newUser = await AuthorizeService.getUser();
        const newToken = await AuthorizeService.getAccessToken();
        try {
          res = await axios.get(`api/profile/${newUser.sub}/myinfo`, {
            headers: {
              Authorization: `Bearer ${newToken}`,
            },
          });
        } catch (anotherError) {
          window.location.replace(ApplicationPaths.LogOut);
          return;
        }
      }

      dispatch(loadSignature());
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
    }
  };
};

export const handleAccountLoadFromOnboarding = () => {
  return (dispatch) => {
    dispatch({
      type: HANDLE_ACCOUNT_LOAD_FROM_ONBOARDING,
      payload: true,
    });
  };
};

export const saveArtistDetailsStep1 = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.post(
        `api/profile/${user.sub}/promoteStep1`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(loadSignature());
      dispatch({
        type: USER_UPDATED,
        payload: res.data,
      });
      removeModalOverlay();
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
    }
  };
};

export const updateUserSignatureSVG = (formData) => {
  return dispatch => {
    dispatch({
      type: USER_SIGNATURE_UPDATED,
      payload: formData,
    })
  }
}

export const updateUserPaymentMethod = (paymentMethod) => {
  return dispatch => {
    dispatch({
      type: USER_PAYMENT_CHANGED,
      payload: paymentMethod,
    })
  }
}

export const handleUploaded = () => {
  return dispatch => {
    dispatch({
      type: HANDLE_UPLOADED
    })
  }
}

export const updateUserDetails = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.put(`api/profile/${user.sub}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      dispatch({
        type: USER_UPDATED,
        payload: res.data,
      });

      dispatch(setAlert("User Updated", "success"));

      if (res.data.isEmailUpdated) {
        window.location.reload();
      }
      return true
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
      console.log(err.response);
      if(err.response.status === 409)
      {
        console.log(err.response.status);
        dispatch(setAlert("User Name is already taken", "error"));
        return "preventClose";
      }

      else{
        console.log(err.response.status);
        dispatch(setAlert("Error updating user", "error"));
      }
      

      
    }
  };
};

export const updatePaymentDetails = ({
                                       addr1,
                                       addr2,
                                       city,
                                       postal,
                                       state,
                                       country,
                                       businessNumber,
                                       businessOption,
                                       _taxCountry,
                                       isAudGst,
                                     }) => {
  return async (dispatch) => {
    try {
      // dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();

      const {label: taxCountry} = _taxCountry;

      const formData = {
        addr1,
        addr2,
        city,
        postal,
        state,
        country,
        businessNumber,
        businessOption,
        taxCountry,
        isAudGst,
      };

      const res = await axios.put(
        `api/profile/paymentdetails/${user.sub}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );


      dispatch(setAlert("User Updated", "success"));

     
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
      dispatch(setAlert("Error updating payment information", "error"));
    }
  };
};

export const updateUserSignature = (formData) => {
  return async (dispatch) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.post(
        `api/profile/${user.sub}/UpdateSignature`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch({
        type: USER_UPDATED,
        payload: res.data,
      });


      dispatch({
        type: HANDLE_SIGNATURE_UPDATE_SUCCESS,
        payload: true,
      });
    } catch (err) {
      dispatch({type: HANDLE_SIGNATURE_UPDATE_SUCCESS, payload: false});
      /*dispatch({
        type: USER_ERROR,
        payload: "Please ensure that you've provided an updated signature",
      });*/
      /*dispatch(
        setAlert(
          "Please ensure that you've provided an" + " updated signature",
          "error"
        )
      );*/
    }
  };
};

export const updateLinks = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.put(
        `api/profile/artist/${user.sub}/links`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(loadUser());
      dispatch(setAlert("Social links updated", "info"));
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
      dispatch(setAlert(err.response, "error"));
    }
  };
};

//export const postData = formData => {
//  return async dispatch => {
//    try {
//      dispatch(setUserLoading());
//      const token = await AuthorizeService.getAccessToken();
//      const user = await AuthorizeService.getUser();
//      const res = await axios.put(`api/profile/${user.sub}`, formData, {
//        headers: {
//          Authorization: `Bearer ${token}`,
//          'Content-Type': 'application/json',
//        },
//      });
//
//      dispatch({
//        type: USER_UPDATED,
//        payload: res.data,
//      });
//      dispatch(setAlert('User Updated', 'info'));
//    }
//    catch (err) {
//      dispatch({ type: USER_ERROR, payload: err.response });
//      dispatch(setAlert(err.response, 'error'));
//    }
//  };
//};

export const updateProfilePicture = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.post(`api/profile/${user.sub}/avatar`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      dispatch({
        type: USER_UPDATED,
        payload: res.data,
      });
      dispatch(setAlert("Profile photo updated", "success"));
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
      dispatch(setAlert(err.response, "error"));
    }
  };
};
//TODO @JORDAN please check why this functions is repeating twice see line 61 msg By @Nick
/*export const saveArtistDetailsBeforePromotion = formData => {
 return async dispatch => {
 try {
 dispatch(setUserLoading());
 const token = await AuthorizeService.getAccessToken();
 const user = await AuthorizeService.getUser();
 const res = await axios.post(
 `api/profile/${user.sub}/promoteStep1`,
 formData,
 {
 headers: {
 Authorization: `Bearer ${token}`,
 "Content-Type": "application/json"
 }
 }
 );
 dispatch(loadSignature());
 dispatch({
 type: USER_UPDATED,
 payload: res.data
 });
 removeModalOverlay();
 console.log(res);
 //dispatch(setAlert("You've been promoted to an artist!", "success"));
 } catch (err) {
 console.log(err);
 dispatch({ type: USER_ERROR, payload: err.response });
 //dispatch(setAlert(err.response, "error"));
 }
 };
 };*/

export const promoteToArtist = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(setUserLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.post(
        `api/profile/${user.sub}/promote`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch({
        type: USER_UPDATED,
        payload: res.data,
      });
      const forceRefresh = await AuthorizeService.signInSilent();
      dispatch(loadUser());
      dispatch(setAlert("You're now a Pikcha artist!", "success"));
      // window.location.reload();
      // TODO @JORDAN please find a way to replace this forced reload with some better solution
    } catch (err) {
      // console.log("account, promoteToArtist ",err);
      dispatch({type: USER_ERROR, payload: err.response});
      dispatch(setAlert(err.response, "error"));
    }
  };
};

export const loadSignature = () => {
  return async (dispatch) => {
    try {
      dispatch(setSignatureLoading());
      const token = await AuthorizeService.getAccessToken();
      const user = await AuthorizeService.getUser();
      const res = await axios.get(`api/profile/${user.sub}/signature`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({
        type: SIGNATURE_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
      // console.log("account, loadSignature ", err);
      dispatch(setAlert("Error loading signature", "error"));
    }
  };
};

export const removeSignature = () => {
  return async (dispatch) => {
    dispatch({type: SIGNATURE_REMOVED})
  };
}

export const uploadImage = (formData) => {
  return async (dispatch) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      await axios.post(`api/image/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(setAlert("Successfully uploaded", "success"));
    } catch (err) {
      dispatch(setAlert(err.response, "error"));
    }
  };
};

export const followArtist = (userId, artistId, artistName) => {
  return async (dispatch, getState) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `api/profile/${userId}/artist/${artistId}/follow`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: ARTIST_FOLLOWED,
        payload: {
          userId,
          artistId,
        },
      });

      dispatch(setUserLoading());
      dispatch({
        type: USER_UPDATED,
        payload: res.data,
      });
      if (artistName) {
        dispatch(setAlert(artistName + " added to following", "info"));
      } else {
        dispatch(setAlert("User Followed", "info"));
      }

      dispatch({
        type: ARTIST_FOLLOWING_NO_UPDATING,
        payload: noOfFollowing(getState()) + 1
      })
    } catch (err) {
      // console.log("account, followArtist", err);

      dispatch({type: USER_ERROR, payload: err.response});
      dispatch(setAlert("Error following artist", "error"));
    }
  };
};

export const unfollowArtist = (userId, artistId, artistName) => {
  return async (dispatch, getState) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `api/profile/${userId}/artist/${artistId}/unfollow`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: ARTIST_UNFOLLOWED,
        payload: {
          userId,
          artistId,
        },
      });

      dispatch(setUserLoading());
      dispatch({
        type: USER_UPDATED,
        payload: res.data,
      });

      if (artistName) {
        dispatch(setAlert(artistName + " removed from following", "info"));
      } else {
        dispatch(setAlert("User Unfollowed", "info"));
      }

      dispatch({
        type: ARTIST_FOLLOWING_NO_UPDATING,
        payload: noOfFollowing(getState()) - 1
      })

    } catch (err) {
      dispatch({type: USER_ERROR, payload: err.response});
    }
  };
};

export const changeFollowingNotificationPreference = (
  userId,
  artistId,
  inApp,
  email
) => {
  return async (dispatch) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `/api/Profile/${userId}/artist/${artistId}/setPreference`,
        {
          userId,
          artistId,
          inApp,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: ARTIST_NOTIFICATION_PREFERENCE_CHANGED,
        payload: {
          userId,
          artistId,
          inApp,
          email,
        },
      });

      setAlert("Notification preference updated", "info");
    } catch (err) {
      // console.log("account, changeFollowingNotificationPreference ", err);
      dispatch(setAlert("Error updating preference", "error"));
    }
  };
};

export const handleSavedImage = (imageId) => {
  return async (dispatch) => {
    try {
      const token = await AuthorizeService.getAccessToken();
      const res = await axios.post(
        `api/image/${imageId}/saveImage`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const imageSavedResponse = res.data;

      if (imageSavedResponse === "Image Saved") {
        dispatch({
          type: HANDLE_SAVED_IMAGE,
          payload: {
            imageId,
            saved: true,
          },
        });
        dispatch(setAlert("Image Liked", "info"));
      } else if (imageSavedResponse === "Image Unsaved") {
        dispatch({
          type: HANDLE_SAVED_IMAGE,
          payload: {
            imageId,
            saved: false,
          },
        });
        dispatch(setAlert("Image removed from Liked", "info"));
      }
    } catch (err) {
      // console.log("account, handleSavedImage", err);
    }
  };
};

export const setFromFollowingSection = (state) => {
  return (dispatch) => {
    dispatch({
      type: FROM_FOLLOWING_SECTION,
      payload: state,
    });
  };
};

// Deauthenticate user
export const logout = () => {
  return async (dispatch) => {
    dispatch({type: LOGGED_OUT});
  };
};

// Set User Loading
export const setUserLoading = () => {
  return {type: USER_LOADING};
};

// Set Signature Loading
export const setSignatureLoading = () => {
  return {type: SIGNATURE_LOADING};
};

export const handleUploadJoyrideShown = () => {
  return async (dispatch) => {
    dispatch({type: JOYRIDE_SHOWN});
  };
}

export const handleJoyrideActiveStep = (step) => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_ACTIVE_STEP,
      payload: step
    });
  }
}

export const handleJoyrideWasTriggered = (payload) => {
  return (dispatch) => {
    dispatch({
      type: JOYRIDE_WAS_TRIGGERED,
      payload: payload
    });
  }
}
