import React from "react";
import {PikchaButton} from "../../../components/baseComponents";
import EditIcon from "@material-ui/icons/Edit";
import SwitchCameraRoundedIcon from "@material-ui/icons/SwitchCameraRounded";

const EditSignatureBlock = ({isOnTablet,onChangeImage, rawImageFile, handleUpdateSignature}) => {

  return (
    <>   <PikchaButton
      className="half-width mr-half-em"
      onClick={handleUpdateSignature}
      action="octonary"
      style={{
        marginTop: "16px",
        alignSelf: "flex-end",
        height: "36px",
        width: "75%",
      }}
    >
      <EditIcon
        fontSize="small"
        style={{
          marginRight: !isOnTablet ? 20 : 4
        }}
      />
      {!isOnTablet ? "Edit signature" : "Signature"}
    </PikchaButton>
      {rawImageFile && (
        <PikchaButton
          className="half-width mr-half-em"
          onClick={onChangeImage}
          action="octonary"
          style={{
            marginTop: "16px",
            alignSelf: "flex-end",
            height: "36px",
            width: "75%",
          }}
        >
          <SwitchCameraRoundedIcon
            fontSize="small"
            style={{
              marginRight: !isOnTablet ? 20 : 4
            }}
          />
          {!isOnTablet ? "Change Image" : "Image"}
        </PikchaButton>
      )}
      {!rawImageFile && (
        <div
          style={{
            marginTop: "16px",
            height: "44px",
          }}
        ></div>
      )}</>
  );
}

export default EditSignatureBlock;
