import { HANDLE_AUTH_MODAL, CLOSE_AUTH_MODAL } from "../actions/types";

const initialState = {
  open: false,
  origin: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLOSE_AUTH_MODAL:
      return {
        ...state,
        open: false,
      };
    case HANDLE_AUTH_MODAL:
      return {
        ...state,
        open: payload.state,
        origin: payload.origin,
      };
    default:
      return state;
  }
};
